import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import { Button, Form } from 'react-bootstrap';
import decryptJWT from '../../../utils/decryptJWT'
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

export default function SelectModelo(props) {
    const [grupos, setGrupos] = useState([])
    const [spinAtivo, setSpinAtivo] = useState(true);
    
    //HOOKS MODAL
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleGrupos(modelo){
        if(props.rota && props.rota == "filtroSimcard"){
            props.setFiltroModelo(modelo)
        }
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectModelo(false)
    }

    useEffect(() => {

        if (props.rota == "filtroSimcard" && props.setResetModelo) {
            props.setResetModelo(false)
        }
        async function renderGrupos(){
            setSpinAtivo(true)
            let todosGrupos = [];
            
            // carrega a modelo
            const {data} = await api.post('/m1/consultar/select',

            {
                "dados":{
                    'paramCampo1' : null,
                    'paramCampo2' : null,
                    'paramCampo3' : null,
                    'paramCampo4' : null,
                    'paramBusca': ""
                },
                "condicao":{
                    'paramRota' : props.paramRota,
                }
            });
            
            await data.dados.map((item,i)=>{
                todosGrupos.push(item);
                return true
            })

            if(todosGrupos.length > 0){
                let objetoGrupos = todosGrupos.map((item,i) => {
                    return (
                        <li key={"menu-"+item.descricao1}>
                            <div className={props.rota && props.rota == "filtroSimcard" ? "campo-select-opcao-grupo-simcard campo-select-opcao-1" : "campo-select-opcao campo-select-opcao-1" } onClick={() => handleGrupos(item.descricao1) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label">{item.descricao1}</span>
                            </div>
                            {todosGrupos[i+1]!==undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
            setSpinAtivo(false)
                setGrupos(objetoGrupos)
            }else{
                let objetoGrupos = () => {
                    return (
                        <li>
                            <div disabled className={props.rota && props.rota == "filtroSimcard" ? "campo-select-opcao-grupo-simcard campo-select-opcao-1" : "campo-select-opcao campo-select-opcao-1" }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setGrupos(objetoGrupos)
            }
        }
        renderGrupos()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            { spinAtivo && props.rota && props.rota  == "filtroSimcard" ?  <div className="componente-spinner-overlay-filtro-simcard"><div className="componente-spinner-container"><SpinerVeye/></div></div> : spinAtivo && !props.rota ? <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> : '' }
            <Button className={props.rota && props.rota == "filtroSimcard" ? "campo-texto-cor-3 campo-select-filtro-modelo" : "campo-texto-cor-3 campo-select"}onClick={() => props.setRevelarSelectModelo(false)}>
                <div className="fonte-cor-1 label-campo-marca">{props.rota && props.rota == "filtroSimcard" ? props.nomeModelo : ''}</div>
                <img className="campo-select-icone-ativado-filtro-b" src={setaDropdown()} alt="drop" />
            </Button>
            <div className={props.rota && props.rota == "filtroSimcard" ? "campo-select-triangulo-cor-1 campo-select-triangulo-3" : "campo-select-triangulo-cor-1 campo-select-triangulo"}></div>
            <div className={props.rota && props.rota == "filtroSimcard" ? "campo-texto-cor-3 campo-select-corpo-3 select-modelo-simcard" : "campo-texto-cor-3 campo-select-corpo select-modelo-simcard"}>
                <ul className="campo-select-lista">
                    {grupos}
                </ul>
            </div>
        </Form>
    )
}