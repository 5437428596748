//IMPORTAÇÕES
import React, { useState, useEffect } from "react"; /*eslint-disable*/
import { Button, Image } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Paginador, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
// import 'moment-timezone';
import moment from "moment";

import ReactDragListView from "react-drag-listview";
import { useSelector, useDispatch, connect } from "react-redux";

//IMPORTAÇÕES ICONES
import { setaDropdown } from "../../../utils/alternanciaIcones";
//IMPORTAÇÕES REDUX
import {
  modoSelecaoSimcard,
  exportarLinhas,
  revelarBarraFiltragem,
  limpadorFiltros,
  mudarFiltros,
  realizouFiltro,
  mudarManejador,
  desabilitarAcoes,
  limpouBusca,
  realizouBusca,
  desabilitarAcoesCliente,
  esconderMenuAcoesDispositivosBloqueio,
  RevelarBuscarEFiltro,
  mudarBuscaArquivo,
  mudarOrdenador,
} from "../../../redux/actions/index";

import { api, api_migracao } from "../../../conexoes/api"; /*eslint-disable*/

// COLETA CLICKSTREAM -> TODO: alterar valores
// import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
//IMPORTAÇÕES COMPONENTES
import BarraDadosCliente from "../../componentesModais/barraDadosCliente/barraDadosCliente";
import DetalhamentoLinha from "./dspDetalhamentoLinha/detalhamentoLinha";
import PaginacaoTabela from "../../../utils/paginacaoTabela";
import BarraFiltragem from "../../componentesModais/barraFiltragem/barraFiltragem";
// SPINNER
import SpinerVeye from "../../../components/spinnerVeye/spinnerVeye";
//ESTILIZACAO
import decryptJWT from "../../../utils/decryptJWT";
// import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
import "../../../css/painelDispositivos/dspSimcard/dspAcoes.css";
import BarraTabelaLegenda from "../../tabelaSelecao/barraTabelaLegenda";
import {
  clickDesselecionarCheckboxVerde,
  desselecionarTodosCheckboxes,
} from "../../tabelaSelecao/checkboxSelecao";
import {
  AtencaoBaixarArquivoLinhasNaoEncontradas,
  BotaoInvisivelExportarLinhasNaoEncontradas,
  clickBotaoInvisivelExportarLinhasNaoEncontradas,
} from "../../buscaArquivo";
import {
  auxRequisicaoMigracaoPlano /*handleRemoverImagens, handlerDesativarImagens*/,
} from "./functions";
import { formatarData } from "../../../utils/formatadorDataTabela";
import GirarSetaDropdown from "../../../utils/girarSetaDropdown";
import {
  handlerDesativarImagens,
  handleRemoverImagens,
} from "../../../utils/ordenacao";
import MensagemInformacao from "../../componentesModais/mensagens/mensagemInformacao";
import { montarXlsLinhasNaoEncontradas } from "../../../utils/exportarArquivo";

let contador = 0;
let controladorPagina = 1;
let paginaAntiga = 0;
let totalPaginas = 0;
let totalItens = 50;
let valorLimite = 50;
let totalExistente = 0;
let simcardsSelecionados = [];
let itensVar = [];
let operadorasSelecionadas = [];
let ordem = {};
let alternadorSelecao = false;

let verificadorBloqueioFinanceiro = false;

const TableprimaryKey = "cd_simcard"; // 'CD_SIMCARD'

function CarregarColunas(props) {
  let colunaSimcard;
  const [colunas, setColunas] = useState([]);
  const [render, setRender] = useState(false);

  const despacho = useDispatch();
  let ordenador = useSelector((state) => state.manejadorState.ordenador);

  async function handlerOrdenador(nomeOrdenador, nomeDiferenciado) {
    // ColetaClickstream(?) // '...HandlerOrdenador'
    if (decryptJWT("clientePrimario") != 2455) {
      let isItDescrescente = false;
      const imagem = document.getElementById(nomeOrdenador + nomeDiferenciado);
      //verifica esta mudando a forma de ordenacao no mesmo elemento
      if (
        nomeOrdenador === ordenador.split(" ASC")[0] ||
        nomeOrdenador === ordenador.split(" DESC")[0]
      ) {
        await ordenador.split(" ").map((item_ordenador, i) => {
          //verifica se existe tal item e muda para decrescente
          if (item_ordenador === "ASC") {
            //Sera tal ordenador para decrescente
            ordenador = nomeOrdenador + " DESC";
            isItDescrescente = true;
            //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem, "ASC");
          } else if (item_ordenador === "DESC") {
            //Sera tal ordenador para crescente
            ordenador = "";
            isItDescrescente = false;
            //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
            handleRemoverImagens(imagem);
          }
        });
      } else if (!isItDescrescente) {
        ordenador = nomeOrdenador + " ASC";
        handlerDesativarImagens(imagem, "DESC");
      }

      despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));
    }
  }

  useEffect(() => {
    encodeURIComponent(decryptJWT("email"));
    async function checarFiltros() {
      despacho(mudarManejador("migracaoPlanoGerenciamento"));

      verificadorBloqueioFinanceiro = false;
      despacho(esconderMenuAcoesDispositivosBloqueio(false));

      await api
        .get(
          "/m1/servicos/token/buscar_colunas_simcard?email=" +
            encodeURIComponent(decryptJWT("email"))
        )
        .then(async function (resposta) {
          colunaSimcard = resposta.data.dados.colunas;

          if (colunaSimcard && colunaSimcard.length > 0) {
            colunaSimcard = colunaSimcard?.map((col) => {
              return col;
            });
          }

          const colunasTemporario = getColunasTemporario({
            verificadorBloqueioFinanceiro,
          });

          setColunas(colunasTemporario);
          return resposta.data.dados;
        });
    }

    function getColunasTemporario({ verificadorBloqueioFinanceiro }) {
      function ContainerFormatter({ valor, row }) {
        return (
          <p
            className={"fonte-cor-1 label-tabela-1"}
            key={row && row[TableprimaryKey]}
          >
            {valor}
          </p>
        );
      }

      const formatterMBConsumo = (cell, row) => {
        let valor = "-";

        if (cell !== "undefined" && cell != null) {
          valor = (cell?.toFixed ? cell?.toFixed(2) : cell) + " MB";
        }
        return <ContainerFormatter valor={valor} row={row} />;
      };

      const formatterMBPlano = (cell, row) => {
        let valor = "-";

        if (cell != null) {
          valor = cell?.toFixed ? cell?.toFixed(2) : cell;
        }
        return <ContainerFormatter valor={valor} row={row} />;
      };

      const colunasDsp = [
        {
          dataField: "Linha",
          nome: "Linha",
          formatter: (cell, row) => {
            return <ContainerFormatter valor={cell || "-"} row={row} />;
          },
        },
        { dataField: "Iccid", nome: "ICCID" },

        { dataField: "Cliente", nome: "Cliente" }, // Nome do cliente
        { dataField: "CodigoCliente", nome: "Código do cliente" },

        { dataField: "Operadora", nome: "Operadora" },

        {
          dataField: "PLanoCliente",
          nome: "Plano cliente",
          formatter: formatterMBPlano,
        },
        {
          dataField: "PlanoOperadora",
          nome: "Plano operadora",
          formatter: formatterMBPlano,
        },

        {
          dataField: "ConsumoMes",
          nome: "Consumo",
          formatter: formatterMBConsumo,
        },
        {
          dataField: "ConsumoCicloAnterior",
          nome: "Consumo ciclo anterior",
          formatter: formatterMBConsumo,
        },
        {
          dataField: "ConsumoConsiderado",
          nome: "Consumo considerado",
          formatter: formatterMBConsumo,
        },

        {
          dataField: "StatusMigracao",
          nome: "Status migração",
          formatter: (cell, row) => {
            return (
              <ContainerFormatter
                valor={cell ? cell : "Sem migração"}
                row={row}
              />
            );
          },
        },
        {
          dataField: "DataRequisicao",
          nome: "Data de requisição",
          formatter: (cell, row) => {
            return formatarData(cell, false, false);
          },
        },
        {
          dataField: "CicloEfetivacao",
          nome: "Ciclo efetivação",
          formatter: (cell, row) => {
            return <ContainerFormatter valor={cell ? cell : "-"} row={row} />;
          },
        },
      ];

      return colunasDsp.map(
        ({ dataField, nome, formatter, usaFormatarMilhar }) => {
          let headerClasses = "nao-selecionavel";
          let nomeColuna = nome;

          let buttonOnClick = () => {
            let ordenarPor = dataField;
            return handlerOrdenador(ordenarPor, ordenarPor);
          };

          let hidden = false;

          if (!formatter) {
            formatter = (cell, row) => {
              let valor = !cell || cell === "null" ? "-" : cell;

              return <ContainerFormatter row={row} valor={valor} />;
            };
          }

          return {
            dataField,
            headerClasses,
            text: (
              <button
                className="btn-header-nome fonte-cor-1"
                onClick={buttonOnClick}
              >
                {nomeColuna}
                <Image
                  id={dataField + dataField}
                  className="d-none"
                  src={setaDropdown()}
                />
              </button>
            ),
            hidden,
            formatter,
          };
        }
      );
    }

    checarFiltros();
  }, [render]);

  return (
    <>
      {colunas.length !== 0 && (
        <TabelaMigracaoPlano
          colunas={colunas}
          render={render}
          setRender={setRender}
          conteudoBusca={props.conteudoBusca}
          filtros={props.filtros}
          buscaArquivo={props.buscaArquivo}
          ordenador={props.ordenador}
        />
      )}
    </>
  );
}

function TabelaMigracaoPlano(props) {
  //SPINNER
  const [spinAtivo, setSpinAtivo] = useState(true);
  //HOOKS TABELA
  const [itens, setItens] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [renderizar, setRenderizar] = useState(false);
  const [permissaoDescricao, setPermissaoDescricao] = useState(true);

  const [revelarMensagemInformacao, setRevelarMensagemInformacao] =
    useState(false);
  const [revelarContador, setRevelarContador] = useState(false);
  const [showCaption, setShowCaption] = useState(false);
  const [limpandoSelecao, setLimpandoSelecao] = useState(false);

  //REDUX HOOKS
  let reduxModoSelecaoSimcard = useSelector(
    (state) => state.manejadorState.modoSelecaoSimcard
  );
  let realizouFiltragem = useSelector(
    (state) => state.manejadorState.realizouFiltro
  );
  let revelarBarraFiltro = useSelector(
    (state) => state.filtrosState.revelarBarraFiltragem
  );
  let estadoExpansaoMenu = useSelector(
    (state) => state.manejadorState.expandirNavegacao
  );
  let conteudoBusca = useSelector(
    (state) => state.manejadorState.conteudoBusca
  );
  let limpouBuscaGeral = useSelector(
    (state) => state.manejadorState.limpouBusca
  );
  let realizouBuscaGeral = useSelector(
    (state) => state.manejadorState.realizouBusca
  );
  let ordenador = useSelector((state) => state.manejadorState.ordenador);
  let separadorLinha = useSelector(
    (state) => state.manejadorState.separadorLinha
  );
  let filtros = useSelector((state) => state.filtrosState.filtros);
  let limparFiltros = useSelector((state) => state.filtrosState.limparFiltros);
  const permissaoElemento = useSelector(
    (state) => state.permissaoState.permissaoElemento
  );
  const buscaArquivo = useSelector(
    (state) => state.buscaArquivoState.buscaArquivo
  );
  let revelarExportarLinhas = useSelector(
    (state) => state.manejadorState.exportarLinhas
  );

  const [dadosLinhasNaoEncontradas, setDadosLinhasNaoEncontradas] = useState([
    ["Linha", "ICCID"],
  ]);
  const [
    mostrarAlertaLinhasNaoEncontradas,
    setMostrarAlertaLinhasNaoEncontradas,
  ] = useState(false);

  const despacho = useDispatch();
  let temFiltros = false;

  useEffect(() => {
    if (
      (!filtros || filtros?.vazio) &&
      !conteudoBusca &&
      (!buscaArquivo || !Object.keys(buscaArquivo).length)
    ) {
      totalExistente = totalItens;
    }
  }, [totalItens, filtros, conteudoBusca, buscaArquivo]);

  const getFiltrosToBody = (filtros) => {
    if (filtros && !filtros?.vazio) {
      // gambiarra para resolver ticket 55/2024 (estava enviando filtros a mais com valor 'on')
      const keysFiltros = Object.keys(filtros);

      if (filtros.OPERADORA) filtros.CdOperadora = filtros.OPERADORA;

      keysFiltros.map((key) => {
        if (filtros[key] === "on" || key.includes("UNIDADE_MIN")) {
          delete filtros[key];
        }
      });
    }

    return filtros?.vazio === "vazio" ? "" : `${JSON.stringify(filtros)}`;
  };

  function onClickSelecaoParqueTodo(novoValor) {
    if (novoValor === false) {
      // limpar seleção
      desselecionarTodosCheckboxes("limparSelecao");

      setLimpandoSelecao(true);
      setShowCaption(false);
    }
    handleAlternarSelecao(novoValor);
  }

  async function handleMontarXlsLinhasNaoEncontradas(dados) {
    const dadosMontar = await montarXlsLinhasNaoEncontradas(dados);

    if (dadosMontar) {
      dadosLinhasNaoEncontradas.push(...dadosMontar);
      setMostrarAlertaLinhasNaoEncontradas(true);
    }
  }

  function handleMensagemAtencao() {
    if (mostrarAlertaLinhasNaoEncontradas) {
      clickBotaoInvisivelExportarLinhasNaoEncontradas();
      setMostrarAlertaLinhasNaoEncontradas(false);
      setDadosLinhasNaoEncontradas([["Linha", "ICCID"]]);
    }
  }

  useEffect(() => {
    function checarFiltros() {
      if (props.filtros.vazio == "vazio") {
        temFiltros = false;
      } else {
        temFiltros = true;
      }
    }
    checarFiltros();
  }, [filtros]);

  useEffect(() => {
    despacho(modoSelecaoSimcard(false));
    alternadorSelecao = reduxModoSelecaoSimcard;
    async function handlerVerificarPermissaoAcao() {
      // despacho(mudarPermissaoElementoAcao(!await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/acoes")))
      // TODO: precisa ?
      // setPermissaoDescricao(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento"))
    }
    handlerVerificarPermissaoAcao();
  }, []);

  useEffect(() => {
    if (limparFiltros) {
      despacho(revelarBarraFiltragem(false));
      if (!props.filtros.vazio) {
        despacho(mudarFiltros({ vazio: "vazio" }));
        despacho(revelarBarraFiltragem(false));
        despacho(limpadorFiltros(false));
      }
    }
  }, []);

  useEffect(() => {
    restaurarConfigsIniciaisPagina();
  }, [props.ordenador]);

  useEffect(() => {
    const controller = new AbortController();

    if (simcardsSelecionados.length === 0) {
      despacho(desabilitarAcoes(true));
    }

    const limparBuscaPorArquivo = () => despacho(mudarBuscaArquivo({}));

    async function carregarTabela() {
      let desselecionarCheckboxAposRecarregarTabela = false;

      async function getDadosTabela({ body, atualizaDados = true }) {
        setRevelarContador(false);

        const simcards_SV = await listarMigracaoPlano({
          body,
          signal: controller.signal,
        });

        if (!mudouPagina) await funcContador(simcards_SV);
        if (atualizaDados) inserirData(simcards_SV.data.simcards);

        setRevelarContador(true);
        return simcards_SV;
      }

      async function listarMigracaoPlano({ body, signal }) {
        let buscaArquivoStr = JSON.stringify(buscaArquivo);

        if (filtros?.ICCID && buscaArquivo?.chave) {
          // TODO
          buscaArquivoStr = "{}";
          limparBuscaPorArquivo();
        }

        if (buscaArquivoStr && buscaArquivoStr !== "{}") {
          body.buscaArquivo = buscaArquivoStr;
        }

        return auxRequisicaoMigracaoPlano({
          body,
          signal,
          filtros,
          separadorLinha,
        });
      }

      setSpinAtivo(true);

      //clicou no botão de limpar a busca geral e retorna tudo ao estado original
      if (limpouBuscaGeral) {
        despacho(limpouBusca(false));
        restaurarConfigsIniciaisPagina();
      }

      //clicou no botão de busca geral
      if (realizouBuscaGeral) {
        despacho(realizouBusca(false));
        restaurarConfigsIniciaisPagina();
      }

      //clicou no botão para filtro
      if (realizouFiltragem) {
        despacho(realizouFiltro(false));
        restaurarConfigsIniciaisPagina();
      }

      //necessario para pegar os dados do filtro, pois por algum motivo ele se perde e nao aciona os props.filtros
      if (temFiltros) {
        temFiltros = false;
        restaurarConfigsIniciaisPagina();
      }

      let dadosSimcard = [];
      valorLimite = 50;

      setItens([]);
      if (totalPaginas != 0) {
        contador = (paginaAtual - 1) * valorLimite;
      } else if (!props.conteudoBusca) {
        /*if (filtros.PACOTE_EXTRA && filtros.PACOTE_EXTRA == "on") { // TODO: entender porque existe essa verificação
                    delete filtros.PACOTE_EXTRA
                } if (filtros.PLANO_TIPO && filtros.PLANO_TIPO == "on") {
                    delete filtros.PLANO_TIPO
                } if (filtros.SMS_ATIVADO && filtros.SMS_ATIVADO == "on") {
                    delete filtros.SMS_ATIVADO
                } if (filtros.TECNOLOGIA && filtros.TECNOLOGIA == "on") {
                    delete filtros.TECNOLOGIA
                } if (filtros.TIPO_SIMCARD && filtros.TIPO_SIMCARD == "on") {
                    delete filtros.TIPO_SIMCARD
                } if (filtros.TRAFEGO && filtros.TRAFEGO == "on") {
                    delete filtros.TRAFEGO
                } if (filtros.ULTIMA_CONEXAO && filtros.ULTIMA_CONEXAO == "on") {
                    delete filtros.ULTIMA_CONEXAO
                }*/
        despacho(RevelarBuscarEFiltro(false));
      }

      /*if (filtros.STATUS_CONTRATO === 'ATIVO') { // corrige quando está em Dashboard e clica em 'mostrar em dispositivos' -> Ativo.
                filtros.STATUS_CONTRATO = 'Ativo*1'
            } else if(filtros.STATUS_CONTRATO === 'DEMONSTRACAO'){
                filtros.STATUS_CONTRATO = 'Demonstração*4'
            }*/

      async function funcContador(apiData) {
        // CONTADOR
        totalItens = apiData.data.item_count;

        let arrayItens = [];
        totalPaginas = Math.ceil(totalItens / valorLimite);

        for (var i = 0; i < totalItens; i++) {
          arrayItens.push(i);
        }

        itensVar = arrayItens;
        despacho(RevelarBuscarEFiltro(true));

        // ---------------------------------------
        desselecionarCheckboxAposRecarregarTabela = true;
      }

      const apenasBusca =
        props.conteudoBusca &&
        props.filtros.vazio &&
        !props.buscaArquivo?.chave;

      let trazerCount = true;

      const mudouPagina = paginaAntiga !== 0 && paginaAntiga !== paginaAtual;

      if (mudouPagina) {
        trazerCount = false;
      }

      const pagina = contador / 50 + 1;
      const busca = conteudoBusca || "";

      const [campo, ordem] = ordenador.split(" ");

      if (apenasBusca) {
        if (controladorPagina <= paginaAtual) {
          const body = {
            linhasPorPagina: valorLimite,
            pagina,
            busca,
            campo,
            ordem,
            contagem: trazerCount,
          };
          await getDadosTabela({ body });
        }
      } else if (props.buscaArquivo?.chave) {
        if (totalItens >= 1 && controladorPagina <= paginaAtual) {
          const body = {
            linhasPorPagina: valorLimite,
            pagina,
            busca,
            campo,
            ordem,
            contagem: trazerCount,
            filtro: getFiltrosToBody(filtros),
          };
          const resSimcards = await getDadosTabela({
            body,
            atualizaDados: false,
          });

          const arrSimcards = resSimcards?.data?.simcards;

          if (arrSimcards && arrSimcards.length) {
            let campo = "";
            if ("ICCID" in buscaArquivo) {
              campo = "ICCID";
            } else {
              campo = "LINHA";
            }

            const simcardsNaoEncontrados = await api_migracao.get(
              `/api/v2/migration/filter/file/notFound?campo=${campo}&chave=${buscaArquivo?.chave}`
            );

            if (!simcardsNaoEncontrados?.data?.length) return;

            let auxSimcardsNaoEncontrados = simcardsNaoEncontrados?.data.map(
              (item) => ({
                [campo]: item,
              })
            );

            handleMontarXlsLinhasNaoEncontradas(auxSimcardsNaoEncontrados);

            despacho(limpadorFiltros(true));
          }

          inserirData(arrSimcards);
        }
      } else if (totalItens >= 1 && controladorPagina <= paginaAtual) {
        const body = {
          linhasPorPagina: valorLimite,
          pagina,
          busca,
          campo,
          ordem,
          filtro: getFiltrosToBody(filtros),
          contagem: trazerCount,
        };

        await getDadosTabela({ body });
      }

      setSpinAtivo(false);

      // quando altera dados da tabela e precisa desselecionar ----------
      if (
        desselecionarCheckboxAposRecarregarTabela &&
        !simcardsSelecionados?.length
      ) {
        if (alternadorSelecao) {
          onClickSelecaoParqueTodo(false);
        } else {
          desselecionarTodosCheckboxes("alterouDadosTabela");
        }
      }
    }
    carregarTabela();

    return () => {
      controller.abort();
    };
  }, [
    paginaAtual,
    props.conteudoBusca,
    renderizar,
    props.filtros,
    props.buscaArquivo,
    ordem,
    props.ordenador,
  ]);

  function inserirData(data) {
    for (let i = 0; i < totalItens; i++) {
      if (contador === i) {
        let k = i;
        for (let j = 0; j < data?.length; j++) {
          itensVar[k] = data[j];
          k++;
        }
      }
      if (i == totalItens - 1) {
        setItens(JSON.parse(JSON.stringify(itensVar)));
      }
    }
    setSpinAtivo(false);

    if (limpandoSelecao) {
      clickDesselecionarCheckboxVerde(false);
    }
  }

  //Enconta dentro dos itens da tabela o item ao qual vai ser ter a linha expandida
  function mapearDadoDetalhamentoLinha(dados) {
    let detalhamento = "";
    itens.map((item_simcard, i) => {
      if (item_simcard[TableprimaryKey] === dados[TableprimaryKey]) {
        detalhamento = item_simcard;
      }
    });

    return [detalhamento];
  }

  const itemExpandido = {
    className: "fundo-cor-1",
    renderer: (row, rowIndex) => {
      return (
        <div
          className={
            estadoExpansaoMenu === true
              ? "expand-row-tabela-dispositivos-1"
              : "expand-row-tabela-dispositivos-2"
          }
        >
          <DetalhamentoLinha
            renderConsultaColunas={props.render}
            setRenderConsultaColunas={props.setRender}
            item={mapearDadoDetalhamentoLinha(row)[0]}
            renderizar={renderizar}
            setRenderizar={setRenderizar}
          />
        </div>
      );
    },
    expandHeaderColumnRenderer: (row, rowIndex) => (
      <div className="cabecalho-linha-expandida"></div>
    ),
    expandColumnRenderer: (rowKey) => {
      return (
        <Button
          className="botao-expandir-row"
          onClick={(e) => GirarSetaDropdown(rowKey.rowKey)}
        >
          <Image
            id={rowKey.rowKey}
            className="icone-botao-expandir-row nao-selecionavel"
            src={setaDropdown()}
          />
        </Button>
      );
    },
    showExpandColumn: permissaoDescricao, // mostra a seta para "expendir" detalhamento
    expandByColumnOnly: true,
    headerClasses: "tabela-coluna-primeira-header",
  };

  const selecaoLinhas = {
    mode: "checkbox",
    hideSelectColumn: reduxModoSelecaoSimcard,
    hideSelectAll: reduxModoSelecaoSimcard,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (limpandoSelecao) setLimpandoSelecao(false);

      if (isSelect) {
        handleSelecionarSimcard(row[TableprimaryKey]);
      } else {
        handleDesselecionarSimcard(row[TableprimaryKey]);
        if (showCaption) setShowCaption(false);
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      // checkbox VERDE
      if (limpandoSelecao) setLimpandoSelecao(false);

      if (isSelect) {
        handleSelecionarTodosSimcards(rows);
        setShowCaption(true);
      } else {
        handleDesselecionarTodosSimcards(rows);
        if (showCaption) setShowCaption(false);
      }
    },
    selectionRenderer: ({ mode, ...rest }) => {
      return (
        <>
          <input type={mode} className="input-checkbox-simcard" {...rest} />
          <label className="label-checkbox-simcard"></label>
        </>
      );
    },
    selectionHeaderRenderer: ({ mode, ...rest }) => {
      if (rest.checked && !showCaption) {
        setShowCaption(true);
      }

      return (
        // checkbox verde
        <>
          <input
            type={mode}
            className="input-checkbox-header-simcard"
            {...rest}
          />
          <label className="label-checkbox-header-simcard"></label>
        </>
      );
    },
    bgColor: { backgroundColor: "gray" },
    color: "red",
  };

  const eventosLinhas = {
    onClick: (e, row, rowIndex) => {},
  };

  function handleSelecionarTodosSimcards(rows) {
    // quando SELECIONA o "checkbox verde"
    // ColetaClickstream(?)// 'dspSimcardTabelaSimcardHandleSelecionarTodosSimcards'
    rows.map((item, i) => {
      simcardsSelecionados.push(rows[i]);
    });
    despacho(desabilitarAcoes(false));
    despacho(desabilitarAcoesCliente(true));
  }

  function handleDesselecionarTodosSimcards(rows) {
    // ColetaClickstream(?) // 'dspSimcardTabelaSimcardHandleDesselecionarTodosSimcards'
    rows.map((item, i) => {
      simcardsSelecionados.map((simcards, x) => {
        if (
          rows[i][TableprimaryKey] === simcardsSelecionados[x][TableprimaryKey]
        ) {
          simcardsSelecionados.splice(x, 1);
        }
      });
    });
    // }
    if (simcardsSelecionados.length === 0) {
      simcardsSelecionados = [];
      despacho(desabilitarAcoes(true));
    }
  }

  function handleSelecionarSimcard(simcard) {
    // ColetaClickstream(?)// 'dspSimcardTabelaSimcardHandleSelecionarSimcard'
    for (let i = 0; i < itens.length; i++) {
      if (itens[i][TableprimaryKey] === simcard) {
        simcardsSelecionados.push(itens[i]);
        operadorasSelecionadas.push(itens[i].DS_PEDIDO_OPERADORA); // TODO: verificar
        break;
      }
    }
    despacho(desabilitarAcoes(false));
    despacho(desabilitarAcoesCliente(true));
  }

  function handleDesselecionarSimcard(simcard) {
    // ColetaClickstream(?)// 'dspSimcardTabelaSimcardHandleDesselecionarSimcard'
    for (let i = 0; i < simcardsSelecionados.length; i++) {
      if (simcardsSelecionados[i][TableprimaryKey] === simcard) {
        simcardsSelecionados.splice(i, 1);
        operadorasSelecionadas.splice(i, 1);
        break;
      }
    }
    if (simcardsSelecionados.length == 0) {
      despacho(desabilitarAcoes(true));
    }
  }

  function trocarPagina(novaPagina) {
    paginaAntiga = paginaAtual;
    setPaginaAtual(novaPagina);
  }

  const opcoesPaginacao = {
    page: paginaAtual,
    showTotal: true,
    sizePerPage: valorLimite,
    firstPageTitle: "Primeira",
    lastPageTitle: "Última",
    nextPageTitle: "Proxima",
    prePageTitle: "Anterior",
    custom: true,
    onPageChange: (newPage) => {
      trocarPagina(newPage);
    },
  };

  function restaurarConfigsIniciaisPagina() {
    totalPaginas = 0;
    contador = 0;
    paginaAntiga = 0;
    controladorPagina = 1;
    totalItens = 50;
    valorLimite = 50;
    simcardsSelecionados = [];
    despacho(desabilitarAcoes(true));
    despacho(desabilitarAcoesCliente(true));
    setPaginaAtual(1);
  }

  function handleAlternarSelecao(valor) {
    // Serve para selecionar parque todo (antigo checkbox vermelho). valor=true => selecionado, valor=false => NÃO selecionado.
    alternadorSelecao = valor;
    despacho(modoSelecaoSimcard(valor));
    if (valor) {
      despacho(desabilitarAcoes(false));
      despacho(desabilitarAcoesCliente(true));
    } else {
      despacho(desabilitarAcoes(true));
      despacho(desabilitarAcoesCliente(false));
    }
    simcardsSelecionados = [];
  }

  useEffect(() => {
    if (revelarExportarLinhas) {
      async function handleExportarLinhas() {
        setSpinAtivo(true);
        const nomeRelatorio = "Relatório de migração automática de plano";

        const body = {
          tipo_arquivo: revelarExportarLinhas,
          tipo_relatorio: 30,
          filtros,
          buscaArquivo,
          buscar: conteudoBusca,
          separador: separadorLinha,
          contar: false,
        };

        if (reduxModoSelecaoSimcard) {
          const bodyParqueTodo = {
            ...body,
            descricao: nomeRelatorio + " buscando pelo parque inteiro",
          };
          cadastraRelatorio(bodyParqueTodo);
        } else {
          let auxArrayCdSimcard = [];
          simcardsSelecionados.map((simcard) => {
            const cdSimcard = simcard[TableprimaryKey];
            if (
              cdSimcard !== undefined &&
              cdSimcard !== null &&
              cdSimcard !== ""
            ) {
              auxArrayCdSimcard.push(cdSimcard);
            }
          });
          const bodySelecaoNormal = {
            ...body,
            descricao: nomeRelatorio + " buscando por linhas específicas",
            arrayCdSimcard: auxArrayCdSimcard,
          };
          await cadastraRelatorio(bodySelecaoNormal);
        }
        handleMensagemInformacaoRelatorio(true);
        despacho(exportarLinhas(false));
        setSpinAtivo(false);
      }
      handleExportarLinhas();
    }
  }, [revelarExportarLinhas]);

  const cadastraRelatorio = async (body) =>
    api_migracao.post("/api/v2/migration/export", body);

  function handleMensagemInformacaoRelatorio(confirmacao) {
    if (revelarMensagemInformacao === false) {
      setRevelarMensagemInformacao(true);
    } else {
      setRevelarMensagemInformacao(false);
    }
  }

  const getLimiteNaPagina = () =>
    contador + valorLimite <= totalItens ? contador + valorLimite : totalItens;
  const getTotalItensNaPagina = () => getLimiteNaPagina() - contador;

  return (
    <>
      {spinAtivo && (
        <div className="componente-spinner-overlay">
          <div className="componente-spinner-container">
            <SpinerVeye />
          </div>
        </div>
      )}
      <div className="container-tela">
        <BotaoInvisivelExportarLinhasNaoEncontradas
          dadosParaDownloadLinhasNaoEncontradas={dadosLinhasNaoEncontradas}
        />

        {mostrarAlertaLinhasNaoEncontradas && (
          <AtencaoBaixarArquivoLinhasNaoEncontradas
            handleMensagemAtencao={handleMensagemAtencao}
            dadosParaDownloadLinhasNaoEncontradas={dadosLinhasNaoEncontradas}
          />
        )}

        {revelarMensagemInformacao && (
          <div id="mensagem-relatorio" className="componente-modal-overlay">
            <div className="componente-modal">
              <MensagemInformacao
                handleMensagemInformacao={handleMensagemInformacaoRelatorio}
                mensagemTitulo={
                  reduxModoSelecaoSimcard
                    ? "O arquivo está sendo gerado"
                    : "O arquivo foi gerado"
                }
                mensagemParagrafo1={
                  reduxModoSelecaoSimcard
                    ? "Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de Relatórios assim que o link do mesmo estiver disponível"
                    : "Faça o download do seu arquivo na tela de Relatórios"
                }
                mensagemBotao={"Voltar"}
                marginTop={40}
              />
            </div>
          </div>
        )}

        <BarraDadosCliente />
        {revelarBarraFiltro === true && <BarraFiltragem />}

        <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <ReactDragListView.DragColumn
                onDragEnd={() => null}
                nodeSelector="th"
                ignoreSelector="th.selection-cell-header, th.expand-cell-header"
              >
                {showCaption && !spinAtivo && (
                  <BarraTabelaLegenda
                    setShowCaption={setShowCaption}
                    onClick={onClickSelecaoParqueTodo}
                    parqueTodoSelecionado={alternadorSelecao}
                    filtros={revelarBarraFiltro}
                    totalItens={totalItens}
                    getTotalItensNaPagina={getTotalItensNaPagina}
                  />
                )}

                <BootstrapTable // TABELA
                  classes={"tabela"}
                  condensed={true}
                  keyField={TableprimaryKey}
                  data={itens}
                  columns={props.colunas}
                  rowEvents={eventosLinhas}
                  selectRow={selecaoLinhas}
                  expandRow={itemExpandido}
                  bootstrap4={true}
                  bordered={false}
                  noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                  {...paginationTableProps}
                />
              </ReactDragListView.DragColumn>

              {revelarContador && (
                <PaginacaoTabela
                  setPaginaAtual={trocarPagina}
                  totalPaginas={totalPaginas}
                  totalItens={totalItens}
                  valorLimite={valorLimite}
                  paginaAtual={paginaAtual}
                  page={paginationProps.page}
                  onPageChange={paginationProps.onPageChange}
                />
              )}
            </>
          )}
        </PaginationProvider>

        {revelarContador ? ( // rodapé tabela
          <div className="itens-exibidos-tabela fonte-cor-1">
            Mostrando
            <span className="label-tabela-itens-exibidos">{contador + 1}</span>-
            <span className="label-tabela-itens-exibidos">
              {getLimiteNaPagina()}
            </span>
            de
            <span className="label-tabela-itens-exibidos">{totalItens}</span>
          </div>
        ) : (
          <div className="itens-exibidos-tabela fonte-cor-1">Carregando...</div>
        )}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    conteudoBusca: state.manejadorState.conteudoBusca,
    filtros: state.filtrosState.filtros,
    buscaArquivo: state.buscaArquivoState.buscaArquivo,
    ordenador: state.manejadorState.ordenador,
  };
}

//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(CarregarColunas));
