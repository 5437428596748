import React, { useState } from "react";
import Modal from "../../Mantine/Modal";
import Group from "../../Mantine/Group";
import Botao from "../../Mantine/Botao";
import Divider from "../../Mantine/Divider";
import DetalhamentoProvisao from "../../SimcardsVirgens/Provisionamento/detalhamentoProvisao";
import { SolicitationsService } from "../../../services/http/solicitations.service";
import Spinner from "../../spinnerVeye/spinnerVeye";
import { useQuery } from "react-query";
import * as S from "./styles";
import * as XLSX from "xlsx";

export default function Provisionamento({
  opened,
  close,
  ids,
  params,
  onDone,
}) {
  const [showInvalidItens, setShowInvalidItens] = useState(false);
  const [itens, setItens] = useState([]);
  const [validItens, setValidItens] = useState([]);
  const [dataProvisionamento, setDataProvisionamento] = useState(null);

  const { validateIccids, registerSolicitation } = SolicitationsService;
  const newIds = ids.map((id) => id.CdSimcardVirgem);
  
  const { data, isFetching } = useQuery(
    [`solicitations/checkInvalidIccids`],
    async () => await validateIccids({ ...params, cd_dsp_whitechip: newIds }),
    {
      onSuccess: (data) => {
        setDataProvisionamento(data);
        setValidItens(data.validIccids);

        if (data.invalidIccids?.length >= 1) {
          setShowInvalidItens(true);
          setItens(data.invalidIccids);
        } else {
          setItens(data.validIccids);
        }
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const title = () => {
    if (showInvalidItens) {
      return itens.length > 1
        ? `Dos ICCIDs selecionados, ${itens.length} foram retirados:`
        : "1 ICCID foi retirado";
    }
    return "Provisionamento";
  };

  const onConfirm = async () => {
    if (!showInvalidItens) {
      try {
        const body = {
          CdSimcardVirgem: validItens.map((iccid) => iccid.cd_dsp_whitechip),
          cd_crm_solicitacao_tipo: 33,
        };

        const register = await registerSolicitation(body);
        if (register.status === 200) onDone(true);
      } catch (err) {
        console.error(err);
      } finally {
        close();
      }
    } else downloadXLSX(data.invalidIccids);
  };

  const downloadXLSX = (dados) => {
    if (dados && dados.length > 0) {
      const dadosFormatados = data.invalidIccids.map((invalidIccid) => ({
        ICCID: invalidIccid.iccid,
        MOTIVO: invalidIccid.motivo,
      }));

      const ws = XLSX.utils.json_to_sheet(dadosFormatados);

      ws["!cols"] = [{ wch: 20 }, { wch: 20 }];
      ws["A1"] = { t: "s", v: "ICCID" };
      ws["B1"] = { t: "s", v: "MOTIVO" };

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ICCIDs retirados");

      const xlsxFile = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([xlsxFile], { type: "application/octet-stream" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "ICCIDs-retirados.xlsx";
      link.click();
    }
  };

  const onClose = () => {
    const { invalidIccids } = dataProvisionamento;
    if (
      (showInvalidItens && !validItens?.length) ||
      (!showInvalidItens && !invalidIccids?.length)
    )
      close();

    setItens(showInvalidItens ? validItens : invalidIccids);
    setShowInvalidItens(!showInvalidItens);
  };

  const removeBatch = (cd) => {
    if (itens?.length <= 1) return;

    setItens((prev) => prev.filter((i) => i.cd_dsp_whitechip !== cd));
    setValidItens((prev) => prev.filter((i) => i.cd_dsp_whitechip !== cd));
  };

  return (
    <div>
      {isFetching && (
        <S.OverlaySpinner>
          <Spinner />
        </S.OverlaySpinner>
      )}
      {!isFetching && (
        <Modal
          titulo={title()}
          opened={opened}
          close={close}
          onConfirm={onConfirm}
          showActionsModal={false}
          withCloseButton={!showInvalidItens}
        >
          <DetalhamentoProvisao
            itens={itens}
            showInvalidItens={showInvalidItens}
            removeBatch={removeBatch}
          />
          <Divider marginY="xl" />
          <Group position="center">
            <Botao
              label={showInvalidItens ? "Fechar" : "Voltar"}
              variant="outline"
              onClick={() => onClose()}
            />
            <Botao
              label={showInvalidItens ? "Exportar relatório" : "Confirmar"}
              variant="primary"
              onClick={() => onConfirm()}
            />
          </Group>
        </Modal>
      )}
    </div>
  );
}
