//IMPORTAÇÕES
import React, { useEffect, useState } from 'react';/*eslint-disable*/
import 'moment-timezone';/*eslint-disable*/
import {api} from '../../../../../conexoes/api';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../../utils/paginacaoTabela'
import moment from 'moment'
import SpinerVeye from '../../../../spinnerVeye/spinnerVeye.js'
import { columnValue, formatterText } from '../../../../../utils/tabela.js';

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let simcardsSelecionados = [];
let itensVar = []
let ordem = {}
let ciclo = undefined
let alternadorSelecao = false
let selecaoLinhaCabecalho = false
let verificadorBloqueioFinanceiro = false

export default function HistoricoDeAcoes(props){
    //HOOKS TABELA
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [itens, setItens] = useState([{}])
    const [valorLimite, setValorLimite] = useState(10);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [refazerChamadas, setRefazerChamadas] = useState(true);

    useEffect(()=>{
        contador = (paginaAtual - 1) * valorLimite;
        setSpinAtivo(true)
        async function carregarHistorico(){
            const paginaAtualBody = (paginaAtual - 1);
            
            if(refazerChamadas === true){
                const contagem = await api.post('/m1/consultar/fatSimcardMovimento',{
                    "cd_fat_simcard":props.cdSimcard,
                    "carregarLimit":valorLimite,
                    "paginaAtual": paginaAtualBody,
                    "contar":true
                })
                totalItens = contagem.data.dados[0].CONTADOR;
                totalPaginas = Math.ceil(totalItens / valorLimite)
                setRefazerChamadas(false)
            }
            const movimentos = await api.post('/m1/consultar/fatSimcardMovimento',{
                "cd_fat_simcard":props.cdSimcard,
                "carregarLimit":valorLimite,
                "paginaAtual": paginaAtualBody,
                "contar":false
            })
            inserirData(movimentos.data.dados)
        }
        carregarHistorico()
    },[paginaAtual])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
                itensVar = [];
            }
        }
        setSpinAtivo(false)
    }

    
    const dateFormatterColumn  = (cell) => {
        if(!cell || cell === "0000-00-00"){
            return columnValue('-');
        }else{
            const dataBr = moment(cell).format('DD/MM/YYYY - HH:mm');
            return columnValue(dataBr);
        }
    }

    const colunas = [
        {
            dataField: 'CD_SIMCARD_MOVIMENTO',
            text: 'Cód. movimento',
            hidden: true,
            headerClasses: 'nao-selecionavel',
            formatter: columnValue
        },
        {
            dataField: 'DS_PROTOCOLO',
            text: 'Protocolo',
            hidden: true,
            headerClasses: 'nao-selecionavel',
            formatter: columnValue
        },
        {
            dataField: 'CD_SOLICITACAO',
            text: 'Cód. solicitação',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: columnValue
        },
        {
            dataField: 'CD_CONTA',
            text: 'Cód. conta',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: columnValue
        },
        {
            dataField: 'DS_CONTA',
            text: 'Conta',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: formatterText,
        },
        // {
        //     dataField: 'CD_PLANO',
        //     text: 'Cód. plano',
        //     sort: true,
        //     headerClasses: 'nao-selecionavel',
        //     formatter: (cell, row) => {
        //         return columnValue(cell);
        //     }
        // },
        {
            dataField: 'DS_PLANO',
            text: 'Plano',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: formatterText,
        },
        // {
        //     dataField: 'CD_CICLO_VIDA',
        //     text: 'Cód. ciclo de vida',
        //     headerClasses: 'nao-selecionavel',
        //     formatter: (cell, row) => {
        //         return columnValue(cell);
        //     }
        // },
        {
            dataField: 'DS_CICLO_VIDA',
            text: 'Ciclo de vida',
            headerClasses: 'nao-selecionavel',
            formatter: formatterText,
        },
        {
            dataField: 'DS_SOLICITACAO_TIPO',
            text: 'Tipo de solicitação',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: formatterText,
        },
        {
            dataField: 'ANTIGO',
            text: 'Alterado de:',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                if(!row?.CD_SOLICITACAO){
                    return columnValue('Bloqueado*');
                }else{
                    return formatterText(cell)
                }
            }
        },
        {
            dataField: 'NOVO',
            text: 'Alterado para:',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                if(!row?.CD_SOLICITACAO){
                    return columnValue('Ativo*');
                }else{
                    return formatterText(cell)
                }
            }
        },
        {
            dataField: "DT_INICIO",
            text: 'Data de inicio',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: dateFormatterColumn
        },
        {
            dataField: "DT_CONCLUSAO_SOLICITACAO",
            text: 'Data de conclusão',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: dateFormatterColumn
        },
        {
            dataField: "DT_FIM",
            text: 'Data de encerramento',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: dateFormatterColumn
        }
    ]

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    return(
        <>
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }        
        <div className='container-historico-movimento-simcard'>
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <div className="container-tabela-extrato">
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                data={itens}
                                keyField={"CD_SIMCARD_MOVIMENTO"}
                                columns={colunas} 
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                extrato={true}
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                                tipo2
                            />
                        </div>
                    )
                }
            </PaginationProvider>
            <div className="itens-exibidos-tabela-extrato fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </div>
           
        </div>
        </>
    )
}