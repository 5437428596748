import { api } from "../../conexoes/api";
import decryptJWT from '../../utils/decryptJWT'

export const getNomesCategoriasColunas = (obj) => Object.keys(obj);

export async function handlerAtualizarDados({
    colunas, itenSelecionado, setColunas, 
    arraySelecionado, itensAtivos,
    categorias
 }){
    const novasColunas = getNovasColunas({ colunas, itenSelecionado, dspSimcard: true });
    setColunas(novasColunas)

   const {newCategorias, newItensAtivos} =  switchBoolValueFromCategoryItem({
        arraySelecionado, itenSelecionado, itensAtivos,
        categorias
    })
   
    return {categorias: newCategorias, itensAtivos: newItensAtivos};
}

export function getDadosColunas({colunas, itensAtivos, objCategoriasKeys, categoriasColunas }){ // Exibir Colunas
    colunas.forEach((item_dados) => {
        if(item_dados.valor === true) itensAtivos++

        const nomesCategorias = getNomesCategoriasColunas(objCategoriasKeys);

        nomesCategorias.forEach(nomeCategoria => {
            if(findColumnById(item_dados, objCategoriasKeys[nomeCategoria])) {
                if(nomeCategoria === 'Identificacao') {
                    // Solução temporária até descobrir uma forma segura de corrigir no MongoDB
                    if(item_dados.id === 'IA_UF') item_dados.nome = 'Estado';
                    if(item_dados.id === 'IA_CEP') item_dados.nome = 'CEP';
                    if(item_dados.id === 'IA_OBSERVACOES') item_dados.nome = 'Informações adicionais';
                }

                categoriasColunas[nomeCategoria].push(item_dados)
            }
        })
    })
    return { categoriasColunas };
}

export async function alterarExibirColunas({ colunasConectividade, colunasFaturas }) {
    const getColunas = async (tipoColunas) => {
        let auxCols = await api.get("/m1/servicos/token/buscar_colunas_simcard?email="+encodeURIComponent(decryptJWT('email')))
        return auxCols.data.dados[tipoColunas]
    }

    if(!colunasConectividade) {
        colunasConectividade = await getColunas('colunas');
    } else if(!colunasFaturas) {
        colunasFaturas = await getColunas('colunasFaturas');
    }
        
    return await api.post("/m1/servicos/dados/alterar_colunas_simcard/",{
        "email": decryptJWT('email'),
        "colunas": colunasConectividade,
        "colunasFaturas": colunasFaturas,
    })
}

const getNovasColunas = ({ colunas, itenSelecionado, dspSimcard=false }) => {
    let auxColuna = []

    colunas.forEach(function(e){
        const { id, nome, ordem, nivel_acesso } = e;
        let valor = e.valor;

            if(id === itenSelecionado){
                valor = !valor;
            }

            const objColuna = {
                id,
                nome,
                ordem,
                valor,
            }

            if(dspSimcard) {
                objColuna.nivel_acesso = nivel_acesso; // SÓ EM Dispositivos CONECTIVIDADE
            }

            auxColuna.push(objColuna)
        }
    )
    return auxColuna;
}

const switchBoolValueFromCategoryItem = ({
    arraySelecionado, itenSelecionado, itensAtivos,
    categorias
}) => {
    let itensCategoria = [];
    itensCategoria = categorias[arraySelecionado];

    itensCategoria?.map(async(item,i)=>{
        if(item.id === itenSelecionado){
            itensCategoria[i].valor = !itensCategoria[i].valor
            if(itensCategoria[i].valor === false) itensAtivos--
            else itensAtivos++
        }
    })

    categorias[arraySelecionado] = itensCategoria;
    return {newCategorias: categorias, newItensAtivos: itensAtivos};
}

const findColumnById = (item_dados, arrColumns) => arrColumns?.find(id => id === item_dados.id);