/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { api } from "../../conexoes/api";
import Keycloak from "keycloak-js";
import { useDispatch, useSelector, connect } from "react-redux";
import { componentRendering } from "../../redux/actions/systemControl"
import Carregando from "../spinnerVeye/spinnerVeye"
import { ReactComponent as PoweredByVeye } from "../../icones/logo/poweredByVeye.svg";
import { ReactComponent as IconeOcultarFalse } from "../../icones/misc/ocultar_false.svg";
import { ReactComponent as IconeOcultarTrue } from "../../icones/misc/ocultar_true.svg";
import { login } from "../../services/auth";
import "./painelLogin.css";
import { colunasFaturas, colunasSimcard } from "../../utils/colunas";
import decryptJWT from "../../utils/decryptJWT";

let colunasAux = []
let colunasFaturasAux = []

function PainelLogin(props){
    const [emailLogin, setEmailLogin] = useState("")
    const [senhaLogin, setSenhaLogin] = useState("")
    const [erroInformacoesLogin, setErroInformacoesLogin] = useState(false)
    const [ocultarSenha, setOcultarSenha] = useState(false)
    const [recuperarSenha, setRecuperarSenha] = useState(false)
    const [emailRecuperacao, setEmailRecuperacao] = useState(false)
    const [senhaRedefinir, setSenhaRedefinir] = useState("")
    const [ocultarSenhaRedefinir, setOcultarSenhaRedefinir] = useState(false)
    const [senhaConfirmacao, setSenhaConfirmacao] = useState("")
    const [ocultarSenhaConfirmacao, setOcultarSenhaConfirmacao] = useState(false)
    const [erroSenhaRedefinir, setErroSenhaRedefinir] = useState([false, false])
    const [tokenUrl, setTokenUrl] = useState(false)
    const [emailUrl, setEmailUrl] = useState(false)
    const [definirLogin, setDefinirLogin] = useState()
    const history = useHistory()
    const dispatchState = useDispatch()
    const componentRenderingState = useSelector(state => state.systemControlStates.componentRendering)
    const url = window.location.href

    function funcaoLimparDadosRecuperacao(){
        setSenhaRedefinir('')
        setSenhaConfirmacao('')
    }

    async function funcaoValidarTokenRecuperacao(){
        let urlVerificador = window.location.href.split('/')
        let urlSplit = urlVerificador[3].split("&")
        let urlToken = urlSplit[0].split(":")
        let urlEmail = urlSplit[1].split(":")

        setTokenUrl(urlToken[1])
        setEmailUrl(urlEmail[1])

        await api.post("/m1/servicos/dados/validar_usuario_recuperacao ", {
            "token": urlToken[1], 
            "login": urlEmail[1]
        })
        .then(async function(response){
            setRecuperarSenha("redefinirSenha")
        })
        .catch(async function(error){
            setRecuperarSenha("tokenInvalido")
        })
    }

    async function funcaoEnviarEmailRecuperacao(){
        dispatchState(componentRendering("carregando"))
        await api.get("m1/servicos/token/email_recuperacao?ds_email="+emailRecuperacao, {}, {timeout:20000})
        .then(async function(response){
            setRecuperarSenha("emailRecuperacaoEnviado")
        })
        .catch(async function(error){
            setRecuperarSenha("falhaNoEnvio")
        })
        funcaoLimparDadosRecuperacao()
        dispatchState(componentRendering([]))
    }

    async function funcaoRedefinirSenha(){
        let auxErroSenha = [false, false]
        if(senhaRedefinir.length < 4){
            auxErroSenha[0] = "A senha fornecida é muito curta"
        }
        else if(senhaRedefinir.length > 20){
            auxErroSenha[0] = "A senha fornecida é muito longa"
        }
        
        if(senhaRedefinir !== senhaConfirmacao){
            auxErroSenha[1] = "As senhas são diferentes"
        }
        else if(senhaConfirmacao.length < 4){
            auxErroSenha[1] = "A senha fornecida é muito curta"
        }
        else if(senhaConfirmacao.length > 20){
            auxErroSenha[1] = "A senha fornecida é muito longa"
        }

        setErroSenhaRedefinir(auxErroSenha)

        if(auxErroSenha[0] === false && auxErroSenha[1] === false){
            dispatchState(componentRendering("carregando"))
            await api.post('/m1/servicos/dados/usuario_recuperacao', {
                "dados":{   
                    "token":tokenUrl ,     
                    "ds_senha":senhaRedefinir,
                },     
                "condicao":{   
                    "ds_email":emailUrl,
                }
            })
            .then(async function(response){
                setRecuperarSenha('senhaRedefinida')
            })
            .catch(async function(error){
                setRecuperarSenha('falhaAoRedefinirSenha')
            })
            funcaoLimparDadosRecuperacao()
            dispatchState(componentRendering([]))
        }
    }

    async function funcaoEfetuarLogin(){
        if(emailLogin === "" || senhaLogin === ""){
            setErroInformacoesLogin("Preencha o e-mail e a senha!")
        }        
        else{
            dispatchState(componentRendering("carregando"))
            await api.post("/m1/servicos/dados/login", {
                "ds_email": emailLogin,
                "ds_senha": senhaLogin
            })
            .then(async function(response){
                await login(response.data.dados)
                await history.push("/veye", {
                    "permissao" : response.data.dados.permissao
                })
                
                localStorage.setItem('codigoClienteErpAntigo',response.data.dados.usuario.codigoClienteErpAntigo)
                localStorage.setItem('clientePrimario',decryptJWT ('clientePrimario'))
                localStorage.setItem('clienteOriginal', decryptJWT ('clienteOriginal'))
                await funcaoAtualizarColunasSimcard()
                await funcaoAtualizarColunasFaturas()
                await funcaoAtualizarColunasMongo()
                await funcaoCadastroColunasMongo()
            
                await funcaoConsultarColunasPersonalizadas()

                window.location.reload()
                setErroInformacoesLogin(false)
            })
            .catch(async function(error){
                setErroInformacoesLogin("Senha ou e-mail inválidos!")
            });
            dispatchState(componentRendering([]))
            props.setBuscarTema(!props.buscarTema) 
        }
    }

    async function funcaoEfetuarLoginAlgar(usuario){
        let loginAux
        if(usuario !== undefined){
            loginAux = usuario
        }
        else{
            loginAux = emailLogin
        }
        
        dispatchState(componentRendering("carregando"))
        await api.post('/m1/servicos/dados/login', {
            "ds_email" : loginAux,
            "ds_senha" : "",
            "algar" : true
        })
        .then(async function (response) {
            await login(response.data.dados);
            await history.push('/veye', {
                'permissao' : response.data.dados.permissao
            })

            localStorage.setItem('codigoClienteErpAntigo',response.data.dados.usuario.codigoClienteErpAntigo)
            localStorage.setItem('clientePrimario',decryptJWT ('clientePrimario'))
            await funcaoAtualizarColunasSimcardAlgar(loginAux)
            await funcaoAtualizarColunasFaturasAlgar(loginAux)
            await funcaoAtualizarColunasMongoAlgar(loginAux)
            await funcaoCadastroColunasMongoAlgar(loginAux)
            
            await funcaoConsultarColunasPersonalizadas()

            window.location.reload()
        })
        .catch(function (error) {
            setErroInformacoesLogin("Senha ou e-mail inválidos!")
        });
        dispatchState(componentRendering([]))
        props.setBuscarTema(!props.buscarTema) 
    }

    async function funcaoConsultarColunasPersonalizadas(){
        await api.get('/m1/consultar/informacoes_adicionais_customizadas_cliente?cd_pav_cliente='+decryptJWT('codigoCliente'))
        .then(function (response) {
            colunasSimcard[40].nome = response.data.dados[0].TITULO
            colunasSimcard[41].nome = response.data.dados[1].TITULO
            colunasSimcard[42].nome = response.data.dados[2].TITULO
        })
    }

    













/* ISSO PRECISA SER RETRABALHADO QUANDO DER */

    function funcaoRemoverColunasDuplicadasConectividade(colunas){
        let arrayNovo = [];
        colunas.forEach((item) => {
            let duplicados  = arrayNovo.findIndex(itemDuplicado => {
                return item.id === itemDuplicado.id;
            }) > -1;
        
            if(duplicados === false) {
                arrayNovo.push(item)
                colunasAux = arrayNovo
            }
        });
    }

    function funcaoRemoverColunasDuplicadasFaturas(colunas){
        let arrayNovo = [];
        colunas.forEach((item) => {
            let duplicados  = arrayNovo.findIndex(itemDuplicado => {
                return item.id === itemDuplicado.id;
            }) > -1;
        
            if(duplicados === false) {
                arrayNovo.push(item)
                colunasFaturasAux = arrayNovo

            }
        });
    }

    async function funcaoAtualizarColunasMongo(){
        funcaoRemoverColunasDuplicadasConectividade(colunasAux)
        funcaoRemoverColunasDuplicadasFaturas(colunasFaturasAux)

        if(colunasAux && colunasFaturasAux){
            await api.post('m1/servicos/dados/alterar_colunas_simcard', {
                "email": emailLogin,
                "colunas": colunasAux,
                "colunasFaturas": colunasFaturasAux
            })
        }
    }

    async function funcaoAtualizarColunasMongoAlgar(usuario){
        funcaoRemoverColunasDuplicadasConectividade(colunasAux)
        funcaoRemoverColunasDuplicadasFaturas(colunasFaturasAux)

        if(colunasAux && colunasFaturasAux){
            await api.post('m1/servicos/dados/alterar_colunas_simcard', {
                "email": usuario,
                "colunas": colunasAux,
                "colunasFaturas": colunasFaturasAux
            })
        }
    }

    async function funcaoAtualizarColunasSimcard() {
        const usuarioColunas = await api.post('m1/servicos/dados/buscar_colunas_simcard', {
            "email": emailLogin
        })

        if(usuarioColunas.data.dados.colunas){ //POSTO ESSE IF POR CONTA DO TEMPO DE EXECUÇÃO QUE CRASHAVA O SISTEMA NO PRIMEIRO LOGIN DO USUÁRIO CRIADO PELO MENU VIRTUEYES.
            colunasSimcard.map((colunaLogin, i) => {
                const found = usuarioColunas.data.dados.colunas.some(el => el.id === colunaLogin.id)

                if(!found){
                    usuarioColunas.data.dados.colunas.splice(i, 0, colunaLogin)
                }
                usuarioColunas.data.dados.colunas.map((coluna, i) => {
                    if(colunaLogin.id === coluna.id){
                        if(colunaLogin.nome !== coluna.nome){
                            coluna.nome = colunaLogin.nome
                        }
                        let colunaLoginIndex = colunasSimcard.indexOf(colunaLogin);
                        let colunaIndex = usuarioColunas.data.dados.colunas.indexOf(coluna);
                        if(colunaLoginIndex !== colunaIndex){
                            usuarioColunas.data.dados.colunas.splice(colunaIndex, 1)
                            usuarioColunas.data.dados.colunas.splice(colunaLoginIndex, 0, coluna)
                        }
                        //ATUALIZA O VALOR DE nivel_acesso
                        coluna.nivel_acesso = colunaLogin.nivel_acesso
                    }
                })
                let posicaoNomeIncorreto;
                const colunasTemp = JSON.parse(JSON.stringify(usuarioColunas.data.dados.colunas));
                colunasTemp.splice(40,3)
                const foundNome = colunasTemp.some((el,ei) => {
                    posicaoNomeIncorreto = ei;
                    return el.nome === colunaLogin.nome
                })

                if(!foundNome){
                    usuarioColunas.data.dados.colunas[posicaoNomeIncorreto].nome = colunaLogin.nome
                }
            })
            
            usuarioColunas.data.dados.colunas.map((coluna, i) => {
                const found = colunasSimcard.some(el => el.id === coluna.id)
                if(!found){
                    usuarioColunas.data.dados.colunas.splice(i, 1)
                }
            })
            
            colunasAux = usuarioColunas.data.dados.colunas
            funcaoOrganizarColunasSimcard(colunasAux)
        }
    }

    async function funcaoAtualizarColunasSimcardAlgar(usuario) {
        const usuarioColunas = await api.post('m1/servicos/dados/buscar_colunas_simcard', {
            email: usuario
        })

        if(usuarioColunas.data.dados.colunas){ //POSTO ESSE IF POR CONTA DO TEMPO DE EXECUÇÃO QUE CRASHAVA O SISTEMA NO PRIMEIRO LOGIN DO USUÁRIO CRIADO PELO MENU VIRTUEYES.
            colunasSimcard.map((colunaLogin, i) => {
                const found = usuarioColunas.data.dados.colunas.some(el => el.id === colunaLogin.id)
                if(!found){
                    usuarioColunas.data.dados.colunas.splice(i, 0, colunaLogin)
                }
                usuarioColunas.data.dados.colunas.map((coluna, i) => {
                    if(colunaLogin.id === coluna.id){
                        if(colunaLogin.nome !== coluna.nome){
                            coluna.nome = colunaLogin.nome
                        }
                        let colunaLoginIndex = colunasSimcard.indexOf(colunaLogin);
                        let colunaIndex = usuarioColunas.data.dados.colunas.indexOf(coluna);
                        if(colunaLoginIndex !== colunaIndex){
                            usuarioColunas.data.dados.colunas.splice(colunaIndex, 1)
                            usuarioColunas.data.dados.colunas.splice(colunaLoginIndex, 0, coluna)
                        }
                        //ATUALIZA O VALOR DE nivel_acesso
                        coluna.nivel_acesso = colunaLogin.nivel_acesso
                    }
                })
                let posicaoNomeIncorreto;
                const colunasTemp = JSON.parse(JSON.stringify(usuarioColunas.data.dados.colunas));
                colunasTemp.splice(40,3)
                const foundNome = colunasTemp.some((el,ei) => {
                    posicaoNomeIncorreto = ei;
                    return el.nome === colunaLogin.nome
                })

                if(!foundNome){
                    usuarioColunas.data.dados.colunas[posicaoNomeIncorreto].nome = colunaLogin.nome
                }
            })
            
            usuarioColunas.data.dados.colunas.map((coluna, i) => {
                const found = colunasSimcard.some(el => el.id === coluna.id)
                if(!found){
                    usuarioColunas.data.dados.colunas.splice(i, 1)
                }
            })
            
            colunasAux = usuarioColunas.data.dados.colunas
            funcaoOrganizarColunasSimcard(colunasAux)
        }
    }

    function funcaoOrganizarColunasSimcard(arrayColunas){
        colunasSimcard.map((colunasMock) => {
            arrayColunas.map((colunaLogin)=>{
                if(colunaLogin.id === colunasMock.id && colunaLogin.ordem !== colunasMock.ordem){
                    colunasMock.ordem = colunaLogin.ordem
                }
            })
        })
    }

    async function funcaoAtualizarColunasFaturas() {

        const usuarioColunas = await api.post('m1/servicos/dados/buscar_colunas_faturas', {
            "email" : emailLogin
        })

        if(usuarioColunas.data.dados.colunasFaturas) {
            colunasFaturas.map((colunaLogin, i) => {
                const found = usuarioColunas.data.dados.colunasFaturas.some(el => el.id === colunaLogin.id)
                if(!found){
                    usuarioColunas.data.dados.colunasFaturas.splice(i, 0, colunaLogin)
                }
                usuarioColunas.data.dados.colunasFaturas.map((coluna, i) => {
                    if(colunaLogin.id === coluna.id){
                        if(colunaLogin.nome !== coluna.nome){
                            coluna.nome = colunaLogin.nome
                        }
                        let colunaLoginIndex = colunasFaturas.indexOf(colunaLogin);
                        let colunaIndex = usuarioColunas.data.dados.colunasFaturas.indexOf(coluna);
                        if(colunaLoginIndex !== colunaIndex){
                            usuarioColunas.data.dados.colunasFaturas.splice(colunaIndex, 1)
                            usuarioColunas.data.dados.colunasFaturas.splice(colunaLoginIndex, 0, coluna)
                        }
                    }
                })
            })
            
            usuarioColunas.data.dados.colunasFaturas.map((coluna, i) => {
                const found = colunasFaturas.some(el => el.id === coluna.id)
                if(!found){
                    usuarioColunas.data.dados.colunasFaturas.splice(i, 1)
                }
            })

            colunasFaturasAux = usuarioColunas.data.dados.colunasFaturas
            funcaoOrganizarColunasFaturas(colunasFaturasAux)
        }else{
        }
        
    }

    async function funcaoAtualizarColunasFaturasAlgar(usuario) {

        const usuarioColunas = await api.post('m1/servicos/dados/buscar_colunas_faturas', {
            email: usuario
        })

        if(usuarioColunas.data.dados.colunasFaturas) {
            colunasFaturas.map((colunaLogin, i) => {
                const found = usuarioColunas.data.dados.colunasFaturas.some(el => el.id === colunaLogin.id)
                if(!found){
                    usuarioColunas.data.dados.colunasFaturas.splice(i, 0, colunaLogin)
                }
                usuarioColunas.data.dados.colunasFaturas.map((coluna, i) => {
                    if(colunaLogin.id === coluna.id){
                        if(colunaLogin.nome !== coluna.nome){
                            coluna.nome = colunaLogin.nome
                        }
                        let colunaLoginIndex = colunasFaturas.indexOf(colunaLogin);
                        let colunaIndex = usuarioColunas.data.dados.colunasFaturas.indexOf(coluna);
                        if(colunaLoginIndex !== colunaIndex){
                            usuarioColunas.data.dados.colunasFaturas.splice(colunaIndex, 1)
                            usuarioColunas.data.dados.colunasFaturas.splice(colunaLoginIndex, 0, coluna)
                        }
                    }
                })
            })
            
            usuarioColunas.data.dados.colunasFaturas.map((coluna, i) => {
                const found = colunasFaturas.some(el => el.id === coluna.id)
                if(!found){
                    usuarioColunas.data.dados.colunasFaturas.splice(i, 1)
                }
            })

            colunasFaturasAux = usuarioColunas.data.dados.colunasFaturas
            funcaoOrganizarColunasFaturas(colunasFaturasAux)
        }        
    }

    function funcaoOrganizarColunasFaturas(arrayColunas){
        colunasFaturas.map((colunasMock) => {
            arrayColunas.map((colunaLogin)=>{
                if(colunaLogin.id === colunasMock.id && colunaLogin.ordem !== colunasMock.ordem){
                    colunasMock.ordem = colunaLogin.ordem
                }
            })
        })
    }

    async function funcaoCadastroColunasMongo(){
        await api.post("/m1/servicos/dados/cadastrar_colunas_simcard",{
            "email": emailLogin,
            "colunas": colunasSimcard,
            "colunasFaturas": colunasFaturas
        })
    }

    async function funcaoCadastroColunasMongoAlgar(email){
        await api.post("/m1/servicos/dados/cadastrar_colunas_simcard",{
            "email": email,
            "colunas": colunasSimcard,
            "colunasFaturas": colunasFaturas,
        })
    }

/* ISSO PRECISA SER RETRABALHADO QUANDO DER */













    useEffect(() => {
        let url = window.location.href;
        let term  = "algar";
    
        if (url.toLowerCase().indexOf(term.toLowerCase()) !== -1 ) {  
            setDefinirLogin("algar")               
        }else{
            setDefinirLogin("veye")
        }

        if(url.includes('sso.algar')){
            const keycloak = Keycloak(`${process.env.PUBLIC_URL}/keycloak.json`);
            keycloak.init({onLoad: 'login-required'}).then(() => {
                setEmailLogin(keycloak.idTokenParsed.email)
                funcaoEfetuarLoginAlgar(keycloak.idTokenParsed.email)
            })    
        }
        
        if(window.location.href.indexOf("token") !== -1){
            funcaoValidarTokenRecuperacao()
        }
    },[])
    
    return (
        <div className={"container-login"}>
            {/* TELA DE CARREGANDO */}
            { componentRenderingState.includes("carregando") &&
                <div className="componente-spinner-overlay">
                    <div className="componente-spinner-container">
                        <Carregando/>
                    </div>
                </div>
            }
            { definirLogin === "algar" ?
                <>
                    {/* INFORMAÇÕES ADICIONAIS */}
                    <div className={"corpo-login-algar-esquerda"}>
                        {/* LOGO DA EMPRESA */}
                        <div className={"container-corpo-login-algar-esquerda-header"}>
                            <img className={"logo-empresa-login-algar"} src={"https://veye.s3.sa-east-1.amazonaws.com/static/media/logo-algar-login.c43fdec7.svg"} alt={"logo"}/>
                        </div>
                        <div className={"container-corpo-login-algar-esquerda-body"}>
                            { recuperarSenha === false &&
                                <>
                                    {/* CAMPO DE E-MAIL */}
                                    <div className={"container-imput-email-login-algar"}>
                                        <span className={"label-titulo-campo-login-algar"}>
                                            E-mail
                                        </span>
                                        <input className={"input-email-login-algar"}
                                            placeholder={"E-mail"}
                                            onChange={(e) => {
                                                setEmailLogin(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter"){
                                                    funcaoEfetuarLoginAlgar()
                                                }
                                            }}
                                        />
                                        { erroInformacoesLogin !== false &&
                                            <span className={"label-erro-senha-erro"}>
                                                {erroInformacoesLogin}
                                            </span>
                                        }
                                    </div>
                                    {/* CAMPO DE SENHA */}
                                    <div className={"container-imput-senha-login-algar"}>
                                        <span className={"label-titulo-campo-login-algar"}>
                                            Senha
                                        </span>
                                        <input className={"input-senha-login-algar"}
                                            placeholder={"Senha"}
                                            onChange={(e) => {
                                                setSenhaLogin(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter"){
                                                    funcaoEfetuarLoginAlgar()
                                                }
                                            }}
                                            type={ocultarSenha === true ?
                                                "text"
                                            :
                                                "password"
                                            }
                                        />
                                        <div className={"container-icone-ocultar-login-algar"}>
                                            { ocultarSenha === true ?
                                                <IconeOcultarTrue className={"icone-ocultar-login-algar"}
                                                    onClick={() => {setOcultarSenha(!ocultarSenha)}}
                                                />
                                            :
                                                <IconeOcultarFalse className={"icone-ocultar-login-algar"}
                                                    onClick={() => {setOcultarSenha(!ocultarSenha)}}
                                                />
                                            }
                                        </div>
                                    </div>
                                    {/* LINK DE RECUPERAÇÃO DE SENHA */}
                                    <span className={"label-recuperacao-senha-login-algar"}
                                        onClick={() => {
                                            funcaoLimparDadosRecuperacao()
                                            setRecuperarSenha("enviarEmailRecuperacao")
                                        }}
                                    >
                                        Esqueci minha senha
                                    </span>
                                    {/* BOTÃO DE LOGIN */}
                                    <button className={"botao-confirmar-login-algar"} 
                                        onClick={() => {
                                            funcaoEfetuarLoginAlgar()
                                        }}
                                    >
                                        Entrar
                                    </button>
                                </>
                            }
                            { recuperarSenha === "enviarEmailRecuperacao" &&
                                <>
                                    {/* RECUPERAÇÃO DE SENHA */}
                                    <span className={"titulo-conteudo-corpo-login-algar"}>
                                        Esqueceu sua senha?
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-algar"}>
                                        Enviaremos um e-mail para você redefinir sua senha. Insira o seu e-mail de cadastro abaixo.
                                    </span>
                                    <div className={"container-imput-email-login-algar"}>
                                        <span className={"label-titulo-campo-login-algar"}>
                                            E-mail de recuperação
                                        </span>
                                        <input className={"input-email-login-algar"}
                                            placeholder={"E-mail"}
                                            onChange={(e) => {
                                                setEmailRecuperacao(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter"){
                                                    funcaoEnviarEmailRecuperacao()
                                                }
                                            }}
                                        />
                                    </div>
                                    <button className={"botao-confirmar-login-algar"} 
                                        onClick={() => {
                                            funcaoEnviarEmailRecuperacao()
                                        }}
                                        disabled={ emailRecuperacao !== "" && emailRecuperacao !== undefined && emailRecuperacao !== false ?
                                            false
                                        :
                                            true
                                        }
                                    >
                                        Enviar
                                    </button>
                                </>
                            }
                            { recuperarSenha === "emailRecuperacaoEnviado" &&
                                <>
                                    {/* CONFIRMAÇÃO DO ENVIO DE E-MAIL */}
                                    <span className={"titulo-conteudo-corpo-login-algar"}>
                                        Verifique sua caixa de entrada.
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-algar"}>
                                        Enviamos um link de recuperação de senha para o e-mail registrado em sua conta.
                                    </span>
                                    <button className={"botao-confirmar-login-algar"} 
                                        onClick={() => {
                                            setRecuperarSenha(false)
                                        }}
                                    >
                                        Retornar
                                    </button>
                                </>
                            }
                            { recuperarSenha === "falhaNoEnvio" &&
                                <>
                                    {/* FALHA NO ENVIO DE E-MAIL */}
                                    <span className={"titulo-conteudo-corpo-login-algar-erro"}>
                                        Falha ao enviar email!
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-algar"}>
                                        Por favor, entre em contato com nosso suporte.
                                    </span>
                                    <button className={"botao-confirmar-login-algar"} 
                                        onClick={() => {
                                            setRecuperarSenha(false)
                                        }}
                                    >
                                        Retornar
                                    </button>
                                </>
                            }
                            { recuperarSenha === "tokenInvalido" &&
                                <>
                                    {/* TOKEN DE RECUPERAÇÃO INVÁLIDO */}
                                    <span className={"titulo-conteudo-corpo-login-algar-erro"}>
                                        Token de recuperação inválido!
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-algar"}>
                                        Por favor, entre em contato com nosso suporte.
                                    </span>
                                    <button className={"botao-confirmar-login-algar"} 
                                        onClick={() => {
                                            setRecuperarSenha(false)
                                        }}
                                    >
                                        Retornar
                                    </button>
                                </>
                            }
                            { recuperarSenha === "redefinirSenha" &&
                                <>
                                    {/* REDEFINIR SENHA */}
                                    <span className={"titulo-conteudo-corpo-login-algar"}>
                                        Defina sua nova senha.
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-algar"}>
                                        Escolha uma senha com no mínimo 4 e no máximo 20 caracteres.
                                    </span>
                                    <div className={"container-imput-nova-senha-login-algar"}>
                                        <span className={"label-titulo-campo-login-algar"}>
                                            Nova senha
                                        </span>
                                        <input className={"input-senha-login-algar"}
                                            placeholder={"Nova senha"}
                                            onChange={(e) => {
                                                setSenhaRedefinir(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter" && senhaRedefinir !== "" && senhaConfirmacao !== ""){
                                                    funcaoRedefinirSenha()
                                                }
                                            }}
                                            type={ocultarSenhaRedefinir === true ?
                                                "text"
                                            :
                                                "password"
                                            }
                                        />
                                        <div className={"container-icone-ocultar-login-algar"}>
                                            { ocultarSenhaRedefinir === true ?
                                                <IconeOcultarTrue className={"icone-ocultar-login-algar"}
                                                    onClick={() => {setOcultarSenhaRedefinir(!ocultarSenhaRedefinir)}}
                                                />
                                            :
                                                <IconeOcultarFalse className={"icone-ocultar-login-algar"}
                                                    onClick={() => {setOcultarSenhaRedefinir(!ocultarSenhaRedefinir)}}
                                                />
                                            }
                                        </div>
                                    </div>
                                    { erroSenhaRedefinir[0] !== false &&
                                        <span className={"label-recuperacao-senha-erro"}>
                                            {erroSenhaRedefinir[0]}
                                        </span>
                                    }
                                    <div className={"container-imput-confirmar-senha-login-algar"}>
                                        <span className={"label-titulo-campo-login-algar"}>
                                            Confirmar senha
                                        </span>
                                        <input className={"input-senha-login-algar"}
                                            placeholder={"Confirmar senha"}
                                            onChange={(e) => {
                                                setSenhaConfirmacao(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter" && senhaRedefinir !== "" && senhaConfirmacao !== ""){
                                                    funcaoRedefinirSenha()
                                                }
                                            }}
                                            type={ocultarSenhaConfirmacao === true ?
                                                "text"
                                            :
                                                "password"
                                            }
                                        />
                                        <div className={"container-icone-ocultar-login-algar"}>
                                            { ocultarSenhaConfirmacao === true ?
                                                <IconeOcultarTrue className={"icone-ocultar-login-algar"}
                                                    onClick={() => {setOcultarSenhaConfirmacao(!ocultarSenhaConfirmacao)}}
                                                />
                                            :
                                                <IconeOcultarFalse className={"icone-ocultar-login-algar"}
                                                    onClick={() => {setOcultarSenhaConfirmacao(!ocultarSenhaConfirmacao)}}
                                                />
                                            }
                                        </div>
                                    </div>
                                    { erroSenhaRedefinir[1] !== false &&
                                        <span className={"label-recuperacao-senha-erro"}>
                                            {erroSenhaRedefinir[1]}
                                        </span>
                                    }
                                    <button className={"botao-confirmar-login-algar"} 
                                        onClick={() => {
                                            funcaoRedefinirSenha()
                                        }}
                                        disabled={ senhaRedefinir !== "" && senhaConfirmacao !== "" ?
                                            false
                                        :
                                            true
                                        }
                                    >
                                        Redefinir
                                    </button>
                                </>
                            }
                            { recuperarSenha === "senhaRedefinida" &&
                                <>
                                    {/* CONFIRMAÇÃO DO ENVIO DE E-MAIL */}
                                    <span className={"titulo-conteudo-corpo-login-algar"}>
                                        Senha redefinida.
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-algar"}>
                                        Você já pode usar sua nova senha para efetuar seu login.
                                    </span>
                                    <button className={"botao-confirmar-login-algar"} 
                                        onClick={() => {
                                            setRecuperarSenha(false)
                                        }}
                                    >
                                        Retornar
                                    </button>
                                </>
                            }
                            { recuperarSenha === "falhaAoRedefinirSenha" &&
                                <>
                                    {/* CONFIRMAÇÃO DO ENVIO DE E-MAIL */}
                                    <span className={"titulo-conteudo-corpo-login-algar-erro"}>
                                        Falha ao redefinir senha!
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-algar"}>
                                        Por favor, entre em contato com nosso suporte.
                                    </span>
                                    <button className={"botao-confirmar-login-algar"} 
                                        onClick={() => {
                                            setRecuperarSenha(false)
                                        }}
                                    >
                                        Retornar
                                    </button>
                                </>
                            }
                        </div>
                        {/* POWERED BY VEYE */}
                        <div className={"container-corpo-login-algar-esquerda-footer"}>
                            <PoweredByVeye className={"icone-powered-by-algar-login-algar"}/>
                        </div>
                    </div>
                    {/* IMAGENS DE BOAS VINDAS */}
                    <div className={"corpo-login-algar-direita"}>
                        <img className={"detalhes-imagem-fundo-login-algar"} src={"https://veye.s3.sa-east-1.amazonaws.com/static/media/setas-login.8abbc225.svg"} alt={"detalhes"}/>
                        <img className={"imagem-fundo-login-algar"} src={"https://veye.s3.sa-east-1.amazonaws.com/static/media/fundo-login-algar.360d73dc.jpg"} alt={"fundo"}/>
                    </div>
                </>
            : definirLogin === "veye" &&
                <>
                    {/* IMAGENS DE BOAS VINDAS */}
                    <div className={"corpo-login-veye-esquerda"}>
                        { url.includes("virtueyes") ?
                            <img className={"imagem-fundo-login-veye"} src={"https://veye.s3.sa-east-1.amazonaws.com/static/media/imagem_login.a492bcf1.jpg"} alt={"fundo"}/>
                        :
                            <img className={"imagem-fundo-login-veye"} src={"https://veye.s3.sa-east-1.amazonaws.com/static/media/imagem_login.a492bcf1.jpg"} alt={"fundo"}/>
                        }
                    </div>
                
                    {/* INFORMAÇÕES ADICIONAIS */}
                    <div className={"corpo-login-veye-direita"}>
                        {/* LOGO DA EMPRESA */}
                        <div className={"container-corpo-login-veye-direita-header"}>
                            { url.includes("virtueyes") ?
                                <img className={"logo-empresa-login-veye"} src={"https://veye.s3.sa-east-1.amazonaws.com/static/media/logo_veye_novo_escuro.e9ad8b53.svg"} alt={"logo"}/>
                            :
                                <img className={"logo-empresa-login-veye"} src={"https://veye.s3.sa-east-1.amazonaws.com/icones/xperince_e_preto.svg"} alt={"logo"}/>
                            }
                        </div>
                        <div className={"container-corpo-login-veye-direita-body"}>
                            { recuperarSenha === false &&
                                <>
                                    {/* CAMPO DE E-MAIL */}
                                    <div className={"container-imput-email-login-veye"}>
                                        <input className={"input-email-login-veye"}
                                            placeholder={"E-mail"}
                                            onChange={(e) => {
                                                setEmailLogin(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter"){
                                                    funcaoEfetuarLogin()
                                                }
                                            }}
                                        />
                                        { erroInformacoesLogin !== false &&
                                            <span className={"label-erro-senha-erro"}>
                                                {erroInformacoesLogin}
                                            </span>
                                        }
                                    </div>
                                    {/* CAMPO DE SENHA */}
                                    <div className={"container-imput-senha-login-veye"}>
                                        <input className={"input-senha-login-veye"}
                                            placeholder={"Senha"}
                                            onChange={(e) => {
                                                setSenhaLogin(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter"){
                                                    funcaoEfetuarLogin()
                                                }
                                            }}
                                            type={ocultarSenha === true ?
                                                "text"
                                            :
                                                "password"
                                            }
                                        />
                                        <div className={"container-icone-ocultar-login-veye"}>
                                            { ocultarSenha === true ?
                                                <IconeOcultarTrue className={"icone-ocultar-login-veye"}
                                                    onClick={() => {setOcultarSenha(!ocultarSenha)}}
                                                />
                                            :
                                                <IconeOcultarFalse className={"icone-ocultar-login-veye"}
                                                    onClick={() => {setOcultarSenha(!ocultarSenha)}}
                                                />
                                            }
                                        </div>
                                    </div>
                                    {/* BOTÃO DE LOGIN */}
                                    <button className={"botao-confirmar-login-veye"} 
                                        onClick={() => {
                                            funcaoEfetuarLogin()
                                        }}
                                    >
                                        Entrar
                                    </button>
                                    {/* LINK DE RECUPERAÇÃO DE SENHA */}
                                    <span className={"label-recuperacao-senha-login-veye"}
                                        onClick={() => {
                                            funcaoLimparDadosRecuperacao()
                                            setRecuperarSenha("enviarEmailRecuperacao")
                                        }}
                                    >
                                        Esqueci minha senha
                                    </span>
                                </>
                            }
                            { recuperarSenha === "enviarEmailRecuperacao" &&
                                <>
                                    {/* RECUPERAÇÃO DE SENHA */}
                                    <span className={"titulo-conteudo-corpo-login-veye"}>
                                        Esqueceu sua senha?
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-veye"}>
                                        Digite seu e-mail abaixo e enviaremos um link para redefinir sua senha.
                                    </span>
                                    <div className={"container-imput-email-login-veye"}>
                                        <input className={"input-email-login-veye"}
                                            placeholder={"E-mail"}
                                            onChange={(e) => {
                                                setEmailRecuperacao(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter"){
                                                    funcaoEnviarEmailRecuperacao()
                                                }
                                            }}
                                        />
                                    </div>
                                    <button className={"botao-confirmar-login-veye"} 
                                        onClick={() => {
                                            funcaoEnviarEmailRecuperacao()
                                        }}
                                    >
                                        Enviar
                                    </button>
                                </>
                            }
                            { recuperarSenha === "emailRecuperacaoEnviado" &&
                                <>
                                    {/* CONFIRMAÇÃO DO ENVIO DE E-MAIL */}
                                    <span className={"titulo-conteudo-corpo-login-veye"}>
                                        Verifique sua caixa de entrada.
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-veye"}>
                                        Enviamos um link de recuperação de senha para o e-mail registrado em sua conta.
                                    </span>
                                    <button className={"botao-confirmar-login-veye"} 
                                        onClick={() => {
                                            setRecuperarSenha(false)
                                        }}
                                        disabled={ emailRecuperacao !== "" && emailRecuperacao !== undefined && emailRecuperacao !== false ?
                                            false
                                        :
                                            true
                                        }
                                    >
                                        Retornar
                                    </button>
                                </>
                            }
                            { recuperarSenha === "falhaNoEnvio" &&
                                <>
                                    {/* FALHA NO ENVIO DE E-MAIL */}
                                    <span className={"titulo-conteudo-corpo-login-veye-erro"}>
                                        Falha ao enviar email!
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-veye"}>
                                        Por favor, entre em contato com nosso suporte.
                                    </span>
                                    <button className={"botao-confirmar-login-veye"} 
                                        onClick={() => {
                                            setRecuperarSenha(false)
                                        }}
                                    >
                                        Retornar
                                    </button>
                                </>
                            }
                            { recuperarSenha === "tokenInvalido" &&
                                <>
                                    {/* TOKEN DE RECUPERAÇÃO INVÁLIDO */}
                                    <span className={"titulo-conteudo-corpo-login-veye-erro"}>
                                        Token de recuperação inválido!
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-veye"}>
                                        Por favor, entre em contato com nosso suporte.
                                    </span>
                                    <button className={"botao-confirmar-login-veye"} 
                                        onClick={() => {
                                            setRecuperarSenha(false)
                                        }}
                                    >
                                        Retornar
                                    </button>
                                </>
                            }
                            { recuperarSenha === "redefinirSenha" &&
                                <>
                                    {/* REDEFINIR SENHA */}
                                    <span className={"titulo-conteudo-corpo-login-veye"}>
                                        Defina sua nova senha.
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-veye"}>
                                        Escolha uma senha com no mínimo 4 e no máximo 20 caracteres.
                                    </span>
                                    <div className={"container-imput-senha-login-veye"}>
                                        <input className={"input-senha-login-veye"}
                                            placeholder={"Nova senha"}
                                            onChange={(e) => {
                                                setSenhaRedefinir(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter" && senhaRedefinir !== "" && senhaConfirmacao !== ""){
                                                    funcaoRedefinirSenha()
                                                }
                                            }}
                                            type={ocultarSenhaRedefinir === true ?
                                                "text"
                                            :
                                                "password"
                                            }
                                        />
                                        <div className={"container-icone-ocultar-login-veye"}>
                                            { ocultarSenhaRedefinir === true ?
                                                <IconeOcultarTrue className={"icone-ocultar-login-veye"}
                                                    onClick={() => {setOcultarSenhaRedefinir(!ocultarSenhaRedefinir)}}
                                                />
                                            :
                                                <IconeOcultarFalse className={"icone-ocultar-login-veye"}
                                                    onClick={() => {setOcultarSenhaRedefinir(!ocultarSenhaRedefinir)}}
                                                />
                                            }
                                        </div>
                                    </div>
                                    { erroSenhaRedefinir[0] !== false &&
                                        <span className={"label-recuperacao-senha-erro"}>
                                            {erroSenhaRedefinir[0]}
                                        </span>
                                    }
                                    <div className={"container-imput-senha-login-veye"}>
                                        <input className={"input-senha-login-veye"}
                                            placeholder={"Confirmar senha"}
                                            onChange={(e) => {
                                                setSenhaConfirmacao(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter" && senhaRedefinir !== "" && senhaConfirmacao !== ""){
                                                    funcaoRedefinirSenha()
                                                }
                                            }}
                                            type={ocultarSenhaConfirmacao === true ?
                                                "text"
                                            :
                                                "password"
                                            }
                                        />
                                        <div className={"container-icone-ocultar-login-veye"}>
                                            { ocultarSenhaConfirmacao === true ?
                                                <IconeOcultarTrue className={"icone-ocultar-login-veye"}
                                                    onClick={() => {setOcultarSenhaConfirmacao(!ocultarSenhaConfirmacao)}}
                                                />
                                            :
                                                <IconeOcultarFalse className={"icone-ocultar-login-veye"}
                                                    onClick={() => {setOcultarSenhaConfirmacao(!ocultarSenhaConfirmacao)}}
                                                />
                                            }
                                        </div>
                                    </div>
                                    { erroSenhaRedefinir[1] !== false &&
                                        <span className={"label-recuperacao-senha-erro"}>
                                            {erroSenhaRedefinir[1]}
                                        </span>
                                    }
                                    <button className={"botao-confirmar-login-veye"} 
                                        onClick={() => {
                                            funcaoRedefinirSenha()
                                        }}
                                        disabled={ senhaRedefinir !== "" && senhaConfirmacao !== "" ?
                                            false
                                        :
                                            true
                                        }
                                    >
                                        Redefinir
                                    </button>
                                </>
                            }
                            { recuperarSenha === "senhaRedefinida" &&
                                <>
                                    {/* CONFIRMAÇÃO DO ENVIO DE E-MAIL */}
                                    <span className={"titulo-conteudo-corpo-login-veye"}>
                                        Senha redefinida.
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-veye"}>
                                        Você já pode usar sua nova senha para efetuar seu login.
                                    </span>
                                    <button className={"botao-confirmar-login-veye"} 
                                        onClick={() => {
                                            setRecuperarSenha(false)
                                        }}
                                    >
                                        Retornar
                                    </button>
                                </>
                            }
                            { recuperarSenha === "falhaAoRedefinirSenha" &&
                                <>
                                    {/* CONFIRMAÇÃO DO ENVIO DE E-MAIL */}
                                    <span className={"titulo-conteudo-corpo-login-veye-erro"}>
                                        Falha ao redefinir senha!
                                    </span>
                                    <span className={"corpo-conteudo-corpo-login-veye"}>
                                        Por favor, entre em contato com nosso suporte.
                                    </span>
                                    <button className={"botao-confirmar-login-veye"} 
                                        onClick={() => {
                                            setRecuperarSenha(false)
                                        }}
                                    >
                                        Retornar
                                    </button>
                                </>
                            }
                        </div>
                        {/* POWERED BY VEYE */}
                        <div className={"container-corpo-login-veye-direita-footer"}>
                            <PoweredByVeye className={"icone-powered-by-veye-login-veye"}/>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

function mapStateToProps(state){
    return {
        "componentRenderingState": state.systemControlStates.componentRendering
    }
}

export default connect(mapStateToProps)(React.memo(PainelLogin))