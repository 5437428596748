import decryptJWT from "./decryptJWT";

export const isPortal = () => {
    return decryptJWT('fl_portal');
}

export const isClienteN2 = () => {
    if(decryptJWT('clienteNivel') == 2){
        return true;
    } else {
        return false;
    }
}