//IMPORTAÇÕES
/*eslint-disable*/
import React, {useState, useEffect, useRef} from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { Form, Col, Tabs, Tab} from 'react-bootstrap';/*eslint-disable*/
import {IconeFaturasVerde, IconeSolicitacoes, IconeSolicitacoesVerde, IconeCadastros, IconeCadastroVerde, IconeFaturas, menuFinanceiro, menuFinanceiroVerdeAtivo, menuSMS, menuSMSVerdeAtivo,     
        menuPedidos, menuPedidosVerdeAtivo, dispositivo, dispositivoVerdeAtivo, olho, olhoVerdeAtivo, admin, adminVerdeAtivo, teste, sms, pedidos, clientes,         
        dashboard, dashboardVerdeAtivo, usuarios, automacao, automacaoVerdeAtivo, relatorios, usuariosVerdeAtivo, outros, outrosVerde, relatoriosAtivo, manutencao, manutencaoVerdeAtivo 
        } from '../../../../utils/alternanciaIcones'
import decryptJWT from '../../../../utils/decryptJWT'

export default function MenuCadastroPermissoes(props) {
    const [menuModulo, setMenuModulo] = useState([])
    const [menuTela, setMenuTela] = useState([])
    const [menuElemento, setMenuElemento] = useState([])
    const [permissao, setPermissao] = useState()
    const [cdMenuModuloAtivo, setCdMenuModuloAtivo] = useState()
    const [elementoAtivadoPermissoes, setElementoAtivadoPermissoes] = useState()
    const [tabAtivadoPermissoes, setTabAtivadoPermissoes] = useState()
    const [moduloTela, setModuloTela] = useState()
    const [idElementoCor, setIdElementoCor] = useState(0)
    const [elementoTela, setElementoTela] = useState()
    const [verificadorPrimeiroModulo, setVerificadorPrimeiroModulo] = useState()
    const [verificadorPrimeiraTab, setVerificadorPrimeiraTab] = useState()
    const [renderizar, setRenderizar] = useState(false)
    const [primeiroModulo , setPrimeiroModulo] = useState()
    const [spinAtivo, setSpinAtivo] = useState(true)
    const [clienteNivel, setClienteNivel] = useState(parseInt(decryptJWT('clienteNivel')) + 1)
    const [customizado, setCustomizado] = useState(1);
    let indentificadorModuloElemento = false

    function renderIcones(icone) {
        if (icone === 'outros'){
            return outros();
        }
        if (icone === 'cadastros'){
            return IconeCadastros();
        }
        if (icone === 'solicitacoes'){
            return IconeSolicitacoes();
        }
        if (icone === 'dispositivos'){
            return dispositivo();
        }
        if (icone === "admin"){
            return admin();
        }
        if (icone === "manutencao"){
            return manutencao();
        }
        if (icone === "teste"){
            return teste();
        }
        if (icone === "virtueyes"){
            return olho();
        }
        if (icone === "financeiro"){
            return menuFinanceiro();
        }
        if (icone === "pedidos"){
            return menuPedidos();
        }
        if (icone === "sms"){
            return menuSMS();
        }
        if (icone === "sms"){
            return sms();
        }
        if (icone === "pedidos"){
            return pedidos();
        }
        if (icone === "clientes"){
            return clientes();
        }
        if (icone === "dashboard"){
            return dashboard();
        }
        if (icone === "usuarios"){
            return usuarios();
        }
        if (icone === "automacao"){
            return automacao();
        }
        if (icone === "relatorios"){
            return relatorios();
        }
        if (icone === "faturas"){
            return IconeFaturas();
        }
    }

    function renderIconesVerdes(icone) {
        if (icone === 'outros'){
            return outrosVerde();
        }
        if (icone === 'cadastros'){
            return IconeCadastroVerde();
        }
        if (icone === 'solicitacoes'){
            return IconeSolicitacoesVerde();
        }
        if (icone === 'dispositivos'){
            return dispositivoVerdeAtivo();
        }
        if (icone === "admin"){
            return adminVerdeAtivo();
        }
        if (icone === "manutencao"){
            return manutencaoVerdeAtivo();
        }
        if (icone === "virtueyes"){
            return olhoVerdeAtivo();
        }
        if (icone === "financeiro"){
            return menuFinanceiroVerdeAtivo();
        }
        if (icone === "pedidos"){
            return menuPedidosVerdeAtivo();
        }
        if (icone === "sms"){
            return menuSMSVerdeAtivo();
        }
        if (icone === "dashboard"){
            return dashboardVerdeAtivo();
        }
        if (icone === "automacao"){
            return automacaoVerdeAtivo();
        }
        if (icone === "usuarios"){
            return usuariosVerdeAtivo();
        }
        if (icone === "faturas"){
            return IconeFaturasVerde();
        }
        if (icone === "relatorios"){
            return relatoriosAtivo();
        }
    }

    function escondeDropdown(el){
        el.classList.remove("backoffice-modal-cadastro-usuario-permissoes-ativo");
        el.classList.remove("fundo-cor-3");
        el.classList.add("backoffice-modal-cadastro-usuario-permissoes-desativo");
        el.classList.add("fundo-cor-5");
    }

    function handleDropTelas(elementoId,id) {
        setVerificadorPrimeiraTab(1)

        setCdMenuModuloAtivo(id)
        
        let elemento = document.getElementById(elementoId);
        let elementosAtivos = document.querySelectorAll(".backoffice-modal-cadastro-usuario-permissoes-ativo");
        if(elementosAtivos.length > 0){
            elementosAtivos.forEach(escondeDropdown)
        }
        elemento.classList.remove("backoffice-modal-cadastro-usuario-permissoes-desativo");
        elemento.classList.remove("fundo-cor-5");
        elemento.classList.add("backoffice-modal-cadastro-usuario-permissoes-ativo");
        elemento.classList.add("fundo-cor-3");
        
        
        setIdElementoCor(elementoId + "-div")
    }

    // function handlerResetarValores(consultaPermissao){
    //     consultaPermissao.map((item,i)=>{
    //         consultaPermissao[i].map((item_consultaPermissao,ij)=>{
    //             item_consultaPermissao.ativo = 0;
    //             item_consultaPermissao.indentiPermissao = false;
    //         })
    //     })
    // }

    useEffect(() => {
        async function handleCarregarPermissoes() {
            setSpinAtivo(true)
            
            const pegarMenus = await api.post('/m1/consultar/permissao',{
                cd_pav_perfil: decryptJWT('perfil'),
                cd_pav_cliente: decryptJWT('codigoCliente'),
                cd_pav_usuario: decryptJWT('codigo'),
            })
            // const pegarMenus = await api.get('/m1/consultar/permissao?cd_pav_perfil='+decryptJWT("perfil")+"&cd_pav_cliente="+decryptJWT("codigoCliente"))
            if (pegarMenus.data.dados) {
                //RESETA OS VALORES DE PERMISSAO
                // handlerResetarValores(pegarMenus.data.dados)
                props.setPermissoes(pegarMenus.data.dados)
                setPermissao(pegarMenus.data.dados)
                setMenuModulo(pegarMenus.data.dados[1])
                setMenuTela(pegarMenus.data.dados[2])
                setMenuElemento(pegarMenus.data.dados[3])
            }
    
            setSpinAtivo(false)
        }
        handleCarregarPermissoes();
    }, []); 

    async function mudarCheckPermissao(e){
        let tipo = e.id.split('_')[1]
        let id = e.id.split('_')[2]
        if(tipo == 1){
            menuModulo.map((item_menuModulo, i)=>{
                if(item_menuModulo.id == id){
                    if(item_menuModulo.ativo === 1){
                        item_menuModulo.indentiPermissao = true
                        item_menuModulo.ativo = 0
                    }
                    else{
                        item_menuModulo.indentiPermissao = true
                        item_menuModulo.ativo = 1
                    }
                }
            })
        }
        else if(tipo == 2){
            menuTela.map((item_menuTela,i)=>{
                if(item_menuTela.id == id){
                    if(item_menuTela.ativo === 1){
                        item_menuTela.indentiPermissao = true
                        item_menuTela.ativo = 0
                    }
                    else{
                        item_menuTela.indentiPermissao = true
                        item_menuTela.ativo = 1
                    }
                }
            })
        }   
        else if(tipo == 3){
            menuElemento.map((item_menuElemento, i)=>{
                if(item_menuElemento.id == id){
                    if(item_menuElemento.ativo === 1){
                        item_menuElemento.indentiPermissao = true
                        item_menuElemento.ativo = 0
                    }
                    else{
                        item_menuElemento.indentiPermissao = true
                        item_menuElemento.ativo = 1
                    }
                }
            })
        }
        props.setPermissoes(permissao)
    }

    useEffect(()=>{
        function handlerCarregarModulos(){
            let modulo = menuModulo.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_menuModulo,i)=>{
                if(i == 0) {
                    setVerificadorPrimeiroModulo("moduloUsuario-" + item_menuModulo.cdMenu +"??"+ item_menuModulo.cdMenu)
                }
                return(
                    <li className="backoffice-modal-cadastro-usuario-lista-modulos fundo-cor-5" key={"moduloUsuario-" + item_menuModulo.cdMenu} id={"moduloUsuario-" + item_menuModulo.cdMenu} onClick={(e)=> handleDropTelas("moduloUsuario-" + item_menuModulo.cdMenu, item_menuModulo.cdMenu)}>
                        <img className={idElementoCor === ("moduloUsuario-" + item_menuModulo.cdMenu + '-div')?"backoffice-modal-cadastro-usuario-permissoes-icone":"backoffice-modal-cadastro-usuario-permissoes-icone-invisivel"} src={renderIconesVerdes(item_menuModulo.icone)} alt='ico'/>
                        <img className={idElementoCor === ("moduloUsuario-" + item_menuModulo.cdMenu + '-div')?"backoffice-modal-cadastro-usuario-permissoes-icone-invisivel":"backoffice-modal-cadastro-usuario-permissoes-icone"} src={renderIcones(item_menuModulo.icone)} alt='ico'/>
                        <span onBeforeInput to={"/" + item_menuModulo.rota}  className="backoffice-modal-cadastro-usuario-lista-modulos-label fonte-cor-1">{item_menuModulo.label}</span>
                        
                        <div className="backoffice-modal-cadastro-usuario-lista-modulos-container-switch">
                            <div class="flipswitch">
                                <input type="checkbox" name="flipswitch" defaultChecked={item_menuModulo.ativo} class="flipswitch-cb" id={'_1_'+item_menuModulo.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                <label class="flipswitch-label" for={'_1_'+item_menuModulo.id+'_switch'}>
                                    <div class="flipswitch-inner flipswitch-cor-3"></div>
                                    <div class="flipswitch-switch"></div>
                                </label>                                
                            </div>
                        </div>
                    </li>
                )
            })
            setModuloTela(modulo)
        }
        handlerCarregarModulos()
    },[cdMenuModuloAtivo,menuModulo])

    useEffect(()=>{
        function handlerCarregarElementos(){
            let verificadorFirstPassada = 1;
            let elementoTelatemporario = 
                    menuTela.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_menuTela,i)=>{
                        if(parseInt(cdMenuModuloAtivo) === parseInt(item_menuTela.menuPai)){
                            if(verificadorPrimeiraTab == 1 && verificadorFirstPassada == 1){
                                setTabAtivadoPermissoes(item_menuTela.label)
                                setElementoAtivadoPermissoes(item_menuTela.label)
                                setVerificadorPrimeiraTab(2);
                                verificadorFirstPassada++
                            }
                            return( 
                                <Tab onClick={() => setTabAtivadoPermissoes(item_menuTela.label)} tabClassName={elementoAtivadoPermissoes == item_menuTela.label? "tab-elementos-permissoes-cadastro-usuario-cliente-ativado fonte-cor-1":"tab-elementos-permissoes-cadastro-usuario-cliente-desativado fonte-cor-1"} eventKey={item_menuTela.label} title={item_menuTela.label}>
                                    <div className="campo-select-divisor-cor-1 campo-divisor"></div>

                                    {customizado === 1 &&
                                        <div className="backoffice-modal-cadastro-usuario-permissoes-container-switch">
                                            <div class="flipswitch">
                                                <input type="checkbox" name="flipswitch" defaultChecked={item_menuTela.ativo} class="flipswitch-cb" id={'_2_'+item_menuTela.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                <label class="flipswitch-label" for={'_2_'+item_menuTela.id+'_switch'}>
                                                    <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                    <div class="flipswitch-switch"></div>
                                                </label>                                
                                            </div>
                                            <span className="backoffice-modal-cadastro-usuario-permissoes-label-switch fonte-cor-1">Habilitar</span>
                                        </div>
                                    }

                                    <div className="container-tab-elementos-permissoes-cadastro-usuario-cliente">
                                        {menuElemento.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_menuElemento,i)=>{
                                            if(item_menuElemento.menuPai === item_menuTela.cdMenu){
                                                indentificadorModuloElemento = true;
                                                return(
                                                    <div className='conteudo-tab-elementos-permissoes-cadastro-usuario-cliente'>

                                                        {customizado === 1 ?
                                                            <div class="flipswitch">
                                                                <input type="checkbox" defaultChecked={item_menuElemento.ativo} disabled={!item_menuTela.ativo} name="flipswitch" class={item_menuTela.ativo?"flipswitch-cb":"flipswitch-cb-desativado"} id={'_3_'+item_menuElemento.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                                <label class="flipswitch-label" for={'_3_'+item_menuElemento.id+'_switch'}>
                                                                    <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                                    <div class="flipswitch-switch"></div>
                                                                </label>                                
                                                            </div>
                                                        :
                                                            <Image className="imagem-conteudo-tab-elementos-permissoes-cadastro-usuario-cliente" src={item_menuElemento.ativo == 1 ?confirmar(): naoPermitido()}/>
                                                        }

                                                        <span className="backoffice-modal-cadastro-usuario-permissoes-label-switch fonte-cor-1">{item_menuElemento.label}</span>
                                                        <div className="campo-select-divisor-cor-1 divisoria-cadastro-usuario-cliente"></div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </Tab>
                            )
                        }
                    })
            if(indentificadorModuloElemento){
                setElementoTela(elementoTelatemporario)
            }
            else{
                let elementoTelatemporario = 
                    menuModulo.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_modulo,i)=>{
                        if(parseInt(cdMenuModuloAtivo) === parseInt(item_modulo.cdMenu)){
                            return( menuElemento.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_menuElemento,iml)=>{
                                    if(item_menuElemento.menuPai === item_modulo.cdMenu){
                                        if(verificadorPrimeiraTab == 1 && verificadorFirstPassada == 1) {
                                            setTabAtivadoPermissoes(item_menuElemento.label)
                                            setElementoAtivadoPermissoes(item_menuElemento.label)
                                            setVerificadorPrimeiraTab(2);
                                            verificadorFirstPassada++
                                        }
                                        return(
                                            <Tab id={item_menuElemento.label} onClick={() => setTabAtivadoPermissoes(item_menuElemento.label)} tabClassName={elementoAtivadoPermissoes == item_menuElemento.label? "tab-elementos-permissoes-cadastro-usuario-cliente-ativado fonte-cor-1":"tab-elementos-permissoes-cadastro-usuario-cliente-desativado fonte-cor-1"} eventKey={item_menuElemento.label} title={item_menuElemento.label}>
                                                <div className="campo-select-divisor-cor-1 campo-divisor"></div>
                                                
                                                {customizado === 1 &&
                                                    <div className="backoffice-modal-cadastro-usuario-permissoes-container-switch">
                                                        <div class="flipswitch">
                                                            <input type="checkbox" defaultChecked={item_menuElemento.ativo} name="flipswitch" class="flipswitch-cb" id={'_3_'+item_menuElemento.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                            <label class="flipswitch-label" for={'_3_'+item_menuElemento.id+'_switch'}>
                                                                <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                                <div class="flipswitch-switch"></div>
                                                            </label>                                
                                                        </div>
                                                        <span className="backoffice-modal-cadastro-usuario-permissoes-label-switch fonte-cor-1">Habilitar</span>
                                                    </div>
                                                }

                                                <div className="container-tab-elementos-permissoes-cadastro-usuario-cliente">
                                                    {menuElemento.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_menuElemento_filho,i)=>{
                                                        if(item_menuElemento.cdMenu === item_menuElemento_filho.menuPai){
                                                            indentificadorModuloElemento = true;
                                                            return(
                                                                <div className='conteudo-tab-elementos-permissoes-cadastro-usuario-cliente'>
                                                                    {customizado === 1 ?
                                                                        <div class="flipswitch">
                                                                            <input type="checkbox" defaultChecked={item_menuElemento_filho.ativo} disabled={!item_menuElemento.ativo} name="flipswitch" class={item_menuElemento.ativo?"flipswitch-cb":"flipswitch-cb-desativado"} id={'_3_'+item_menuElemento_filho.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                                            <label class="flipswitch-label" for={'_3_'+item_menuElemento_filho.id+'_switch'}>
                                                                                <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                                                <div class="flipswitch-switch"></div>
                                                                            </label>                                
                                                                        </div>
                                                                        :
                                                                        <Image className="imagem-conteudo-tab-elementos-permissoes-cadastro-usuario-cliente" src={item_menuElemento_filho.ativo == 1 ?confirmar(): naoPermitido()}/>
                                                                    }

                                                                    <span className="backoffice-modal-cadastro-usuario-permissoes-label-switch fonte-cor-1">{item_menuElemento_filho.label}</span>
                                                                    <div className="campo-select-divisor-cor-1 divisoria-cadastro-usuario-cliente"></div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </Tab>
                                        )
                                    }
                                })
                            )
                        }
                    })
                setElementoTela(elementoTelatemporario)
            }
                
        }
        handlerCarregarElementos()
    },[cdMenuModuloAtivo,renderizar,elementoAtivadoPermissoes,tabAtivadoPermissoes])
    
    function handerAlterarTabPermissoes(k){
        setElementoAtivadoPermissoes(k)
        setTabAtivadoPermissoes(k)
    }

    useEffect(()=>{
        if(primeiroModulo == 1 && verificadorPrimeiroModulo !== ''){
            setPrimeiroModulo(2)
            handleDropTelas(verificadorPrimeiroModulo.split('??')[0], verificadorPrimeiroModulo.split('??')[1])
        }
    },[verificadorPrimeiroModulo])

    return(
        <>
            {moduloTela != undefined && elementoTela != undefined && 
                <Form.Row>    
                    <Col>
                        <div className="backoffice-modal-cadastro-usuario-container-permissoes">
                            <div className="backoffice-modal-cadastro-usuario-container-lista-modulos">
                                {
                                    moduloTela
                                }
                            </div>
                            <div className="backoffice-modal-cadastro-usuario-lista-permissoes fundo-cor-11">
                                <Tabs activeKey={tabAtivadoPermissoes} onSelect={(k) => handerAlterarTabPermissoes(k)} class="backoffice-cadastro-usuario-permissoes-tabs" id="uncontrolled-tab-example">
                                    {
                                        elementoTela
                                    }
                                </Tabs>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
            }
        </>
    )
}
