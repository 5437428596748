

import * as React from 'react';
import { ReactComponent as CustomSvg } from '../icones/icone-detalhamento-historico.svg';

function CustomIcon({ ativo }) {
  // const theme = useTheme();
  const localTema = window.localStorage.getItem('tema');

  const color = ativo
    ? '#33CC99'
    : (localTema === 'escuro' // theme.palette.mode === 'dark'
      ? 'white' : '#243B53');
  
  return (
    <CustomSvg style={{ fill: color, width: 24, height: 24, marginTop: '-12px' }} />
  );
}

function IconeHistorico({ativo, className}) {
  return <div className={className || ''} >
    <CustomIcon ativo={ativo}/>
  </div>
}

export default IconeHistorico;