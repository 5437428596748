//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { Button } from 'react-bootstrap';/*eslint-disable*/
import { alertaSucesso, alertaErro, iconeDownload } from '../../../utils/alternanciaIcones'
import { montarXlsLinhasInvalidasAcao } from '../../../utils/exportarArquivo';


export function RenderMensagemSucesso({ mensagemSucesso, handleMensagemSucesso }) {
    const { arrTexto, btnTexto, linhasInvalidas, erro } = mensagemSucesso;
    const mostrarBotaoBaixar = !!linhasInvalidas?.length;
    const [fezDownloadArquivo, setFezDownloadArquivo] = useState(false);

    function downloadArquivoLinhasInvalidas() {
        montarXlsLinhasInvalidasAcao(linhasInvalidas, erro)
    }
    
    return (
        <MensagemSucesso
            handleMensagem={handleMensagemSucesso}
            mensagemTitulo={arrTexto[0]}
            mensagemParagrafo1={arrTexto[1]}
            mensagemParagrafo2={arrTexto[2]}
            mensagemBotao={btnTexto}

            opcaoDownload={mostrarBotaoBaixar}
            // botao1Disabled={mostrarBotaoBaixar && fezDownloadArquivo !== true}
            mensagemBotao2={mostrarBotaoBaixar ? 'Baixar' : false} 
            downloadNoBotao2={() => { 
                downloadArquivoLinhasInvalidas();
                setFezDownloadArquivo(true);
            }}
        />
    )
}

export const MensagemSucesso = (props) => <MensagemAcao tipo='sucesso' {...props} />
export const MensagemErro = (props) => <MensagemAcao tipo='erro' {...props} />

// COLETA CLICKSTREAM
// import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import MensagemAcao from '../componentesModais/mensagens/mensagemAcao'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarMensagem, setRevelarMensagem] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma.
    ex: { revelarMensagem &&
            <div className="componente-modal-overlay">
                <div className="componente-modal">
                    <MensagemAcao
                        tipo="erro|sucesso"
                        handleMensagem={handleMensagem}
                        mensagemTitulo={"TITULO DA MENSAGEM"}
                        mensagemParagrafo1={"CORPO DA MENSAGEM 1"}
                        mensagemParagrafo2={"CORPO DA MENSAGEM 2"}
                        mensagemParagrafo3={"CORPO DA MENSAGEM 3"}
                        mensagemBotao={"TEXTO DO BOTÃO"}
                        mensagemBotao2={"TEXTO DO BOTÃO OPCIONAL"}
                    />
                </div>
            </div>
        }
        
    Agora crie uma função para manejar o estado da mensagem e executar a função de
    fechamento padrão caso necessário.
    ex: function handleMensagem(confirmacao){
            if(revelarMensagem === false){
                setRevelarMensagem(true)
            }else{
                setRevelarMensagem(false)
                if(confirmacao === true){
                    //ALGUMA FUNÇÃO
                }
            }
        }
    
    Ou se não precisar executar alguma outra funcao quando for verdadeiro bas apenas passar 
    para o handleMensagem (caso sucesso) o seu set trocando ela mesma de valor
    ex:
        <MensagemAcao
            tipo="sucesso"
            handleMensagem={() =>  setRevelarMensagemSucesso(!revelarMensagemSucesso)}  
            mensagemTitulo={"TITULO DA MENSAGEM"}
            mensagemParagrafo1={"CORPO DA MENSAGEM 1"}
            mensagemParagrafo2={"CORPO DA MENSAGEM 2"}
            mensagemParagrafo3={"CORPO DA MENSAGEM 3"}
            mensagemBotao={"TEXTO DO BOTÃO"}
        />  
    */

function MensagemAcao(props) { // componente genérico (no momento pode ser usado para mensagens de Sucesso e Erro)
    //HOOK PRINCIPAIS
    const [mensagemTitulo, setMensagemTitulo] = useState('')
    const [mensagemParagrafo1, setMensagemParagrafo1] = useState('')
    const [mensagemParagrafo2, setMensagemParagrafo2] = useState('')
    const [mensagemParagrafo3, setMensagemParagrafo3] = useState('')
    const [mensagemBotao, setMensagemBotao] = useState('')
    const [mensagemBotao2, setMensagemBotao2] = useState('')

    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            if(props.downloadNoBotao2) props.downloadNoBotao2(); // apenas se for download de arquivo de erros

            handleCancelar(false)
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleCancelar(confirmacao){
        if(confirmacao === true){
            props.handleMensagem(true)
        }else{
            props.handleMensagem(false)
        }
    }

    useEffect(() => {
        setMensagemTitulo(props.mensagemTitulo)
        setMensagemParagrafo1(props.mensagemParagrafo1)
        setMensagemParagrafo2(props.mensagemParagrafo2)
        setMensagemParagrafo3(props.mensagemParagrafo3)
        setMensagemBotao(props.mensagemBotao)
        setMensagemBotao2(props.mensagemBotao2)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    const getIcone = () => {
        switch(props.tipo) {
            case 'erro':
                return alertaErro();
            case 'sucesso':
                return alertaSucesso();
                default:
                    return;
        }
    }

    const msgOpcaoDownload = props.tipo === 'erro' || props.opcaoDownload;

    return(
    <div className="componente-modal-overlay">
    <div className="componente-modal">

        <div className="mensagem-overlay">
            <div className="mensagem-container">
                <div ref={modal} className="mensagem-corpo fundo-cor-6">
                    <div className="mensagem-container-icone">
                        <img className="mensagem-icone" src={getIcone()} alt="sucesso"/>
                    </div>
                    <div className="mensagem-container-titulo">
                        <div className="fonte-cor-1 label-titulo-mensagem">{mensagemTitulo}</div>
                    </div>

                    <RenderMensagemParagrafo msg={mensagemParagrafo1} />
                    <RenderMensagemParagrafo msg={mensagemParagrafo2} />
                    <RenderMensagemParagrafo msg={mensagemParagrafo3} />
                    
                    <div className="mensagem-container-botao" style={msgOpcaoDownload ? { display: 'flex', justifyContent: 'center' } : null}>

                        {!msgOpcaoDownload ? 
                            <Button className="mensagem-botao-confirmar" variant="success" size="sm" onClick={() => handleCancelar(true)}>
                                <div className="fonte-cor-4">{mensagemBotao}</div>
                            </Button>
                            :
                            <Button 
                                disabled={props.botao1Disabled}
                                className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar(true)}
                            >
                                <div className={props.botao1Disabled ? "fonte-cor-1" : "fonte-cor-2"}>{mensagemBotao}</div>
                            </Button>
                        }

                        {mensagemBotao2 && (
                            <Button
                                className="botao-confirmar" 
                                type="submit" 
                                variant="success" size="sm" onClick={() => props.downloadNoBotao2()}
                            >
                                <div className="fonte-cor-4">
                                    {mensagemBotao2}
                                    <img className="ml-2" src={iconeDownload()} alt="sucesso"/>
                                </div>
                                    
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>

    </div>
    </div>
    )
}

function RenderMensagemParagrafo({msg}) {
    return (<div>
        <div className="fonte-cor-1 label-corpo-mensagem">{msg}</div>
    </div>)
}