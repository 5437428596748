import styled, { createGlobalStyle } from "styled-components";

// FIXME:
const statusColors = {
  Falha: "#D32F2F",
  Cancelada: "#d3d3d3",
  Pendente: "#ffff00",
  Sucesso: "#33CC99",
};

export const detalhamnetoLinhaWrapper = styled.div`
  .react-bootstrap-table {
    padding: 16px !important;
  }

  .pagination-table {
    padding-left: 16px;
    .grupo-botao-paginacao-tabela-4 {
      margin-right: 20px;
    }
  }

  .pagination-description {
    margin-top: 0.16em;
    margin-left: 215px;
    font-family: RobotoRegular !important;
    font-size: 0.875em !important;
  }

  table {
    width: 100% !important;
  }

  td {
    font-family: RobotoRegular !important;
    font-size: 16px !important;
    line-height: 1.5em !important;
    color: ${({ theme }) => theme.cor9}!important;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const StatusElipse = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ status }) => statusColors[status]};
`;

export const GlobalStyles = createGlobalStyle`
  table {
    width: 100% !important;
    tbody tr {
      td.reset-expansion-style {
        padding: 0 !important;
      }
    }
  }
`;

export const SkeletonContainer = styled.div`
  padding: 16px;
`

export const OverlaySpinner = styled.div`
  position: fixed !important;
  z-index: 1000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: grid;
  justify-items: center;
  align-items: center;
`;
export const Skeleton = styled.div``;
