import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
import Calendario from '../calendario/calendario'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltrosModuloSmsTemplate(props) {
    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroDataInicial, setFiltroDataInicial] = useState('')
    const [filtroDataFinal, setFiltroDataFinal] = useState('')
    const [filtroModificacaoInicial, setFiltroModificacaoInicial] = useState('')
    const [filtroModificacaoFinal, setFiltroModificacaoFinal] = useState('')
    const [filtroPermissao, setFiltroPermissao] = useState('')
    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setFiltroPermissao(filtros.PERMISSAO)
            
            //verifica se existe algo nos valores de datas
            if(filtros.DATA_CRIACAO_INICIO !='' && filtros.DATA_CRIACAO_INICIO){
                let auxData = new Date(filtros.DATA_CRIACAO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataInicial(auxData)
            }
            if(filtros.DATA_CRIACAO_FINAL != '' && filtros.DATA_CRIACAO_FINAL){
                let auxData = new Date(filtros.DATA_CRIACAO_FINAL)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataFinal(auxData)
            }

            if(filtros.PERIODO_MODIFICACAO_INICIO != '' && filtros.PERIODO_MODIFICACAO_INICIO){
                let auxData = new Date(filtros.PERIODO_MODIFICACAO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroModificacaoInicial(auxData)
            }
            if(filtros.PERIODO_MODIFICACAO_FINAL != '' && filtros.PERIODO_MODIFICACAO_FINAL){
                let auxData = new Date(filtros.PERIODO_MODIFICACAO_FINAL)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroModificacaoFinal(auxData)
            }
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroDataInicial == '') verificador.push(1)
            if(filtroDataFinal =='') verificador.push(1)
            if(filtroModificacaoInicial =='') verificador.push(1)
            if(filtroModificacaoFinal =='') verificador.push(1)
            if(filtroPermissao == '') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 5) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroDataInicial('')
            setFiltroDataFinal('')
            setFiltroModificacaoInicial('')
            setFiltroModificacaoFinal('')
            setFiltroPermissao('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroDataInicial,filtroDataFinal,filtroModificacaoInicial,filtroModificacaoFinal,filtroPermissao,props.foiLimpado]);

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1218) /*smsTemplateTabelaDropFiltrosFiltrar*/
        if(filtroDataInicial !== '' && filtroDataFinal !== ''){
            arrayFiltrosSimcard.push("Data: entre "+moment(filtroDataInicial).format("DD-MM-YYYY")+" e "+moment(filtroDataFinal).format("DD-MM-YYYY")+"?DATA_CRIACAO_INICIO")
        }
        if(filtroModificacaoInicial !== '' && filtroModificacaoFinal !== ''){
            arrayFiltrosSimcard.push("Modificado: entre "+moment(filtroModificacaoInicial).format("DD-MM-YYYY")+" e "+moment(filtroModificacaoFinal).format("DD-MM-YYYY")+"?PERIODO_MODIFICACAO_INICIO")
        }
        if(filtroPermissao !== ''){
            if(filtroPermissao === 1){
                arrayFiltrosSimcard.push("Permissão: Todos os usuários"+"?PERMISSAO")
            }
            if(filtroPermissao === 2){
                arrayFiltrosSimcard.push("Permissão: Somente eu"+"?PERMISSAO")            
            }
        }
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-data","imagem-filtro-data"); ColetaClickstream(1210) /*smsTemplateTabelaDropFiltrosData*/}}>
                        <Form.Label  className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data </Form.Label>
                        <img id={"imagem-filtro-data"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_CRIACAO_INICIO" type="text" value={filtroDataInicial ? moment(filtroDataInicial).format("YYYY-MM-DD hh:mm:ss") : filtroDataInicial} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataInicial} setData={setFiltroDataInicial} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_CRIACAO_FINAL" type="text" value={filtroDataFinal ? moment(filtroDataFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataFinal} setData={setFiltroDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-modificacao","imagem-filtro-modificacao"); ColetaClickstream(1211) /*smsTemplateTabelaDropFiltrosUltimaModificacao*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Última modificação </Form.Label>
                        <img id={"imagem-filtro-modificacao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-modificacao"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="PERIODO_MODIFICACAO_INICIO" type="text" value={filtroModificacaoInicial ? moment(filtroModificacaoInicial).format("YYYY-MM-DD hh:mm:ss") : filtroModificacaoInicial} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroModificacaoInicial} setData={setFiltroModificacaoInicial} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="PERIODO_MODIFICACAO_FINAL" type="text" value={filtroModificacaoFinal ? moment(filtroModificacaoFinal).format("YYYY-MM-DD hh:mm:ss") : filtroModificacaoFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroModificacaoFinal} setData={setFiltroModificacaoFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-permissao","imagem-filtro-permissao"); ColetaClickstream(1212) /*smsTemplateTabelaDropFiltrosPermissao*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Permissão </Form.Label>
                        <img id={"imagem-filtro-permissao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-permissao"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroPermissao(1)}>Todos usuários
                            <input defaultChecked={handlerCheck("PERMISSAO","Todos_usuarios")} type="radio" id="PERMISSAO_TODOS_USUARIOS" name="PERMISSAO" value="Todos_usuarios"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroPermissao(2)}>Somente eu
                            <input defaultChecked={handlerCheck("PERMISSAO","Somente_eu")} type="radio" id="PERMISSAO_SOMENTE_EU" name="PERMISSAO" value="Somente_eu"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}