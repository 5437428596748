import { useState } from "react";
import { setaDropdown } from "../../../utils/alternanciaIcones";
import * as S from "./styles";
import { GlobalStyles } from "./styles";

export default function Select({
  value,
  onChange,
  placeholder,
  label,
  width,
  options,
  disabled = false,
  withAsterisk = false,
}) {
  const [searchValue, setSearchChange] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <GlobalStyles />
      <S.Select
        label={label}
        width={width}
        placeholder={placeholder}
        withAsterisk={withAsterisk}
        searchable={false}
        clearable
        withinPortal
        zIndex={9999}
        radius="xl"
        value={value}
        disabled={disabled}
        onSearchChange={setSearchChange}
        searchValue={searchValue}
        nothingFound="Nenhuma opção encontrada"
        data={options || []}
        allowDeselect
        onChange={onChange}
        onDropdownOpen={() => setIsOpen(true)}
        onDropdownClose={() => setIsOpen(false)}
        rightSection={
          <S.Img isOpen={isOpen} alt="seta-dropdown" src={setaDropdown()} />
        }
      />
    </>
  );
}
