import decryptJWT from '../../../utils/decryptJWT';

export default function verificarColunaDsp(colunaSimcard, id) {
    let mostrar = false;
    colunaSimcard.forEach((item) => {
        if (item.id === id) {
            mostrar = (item.valor && item.nivel_acesso >= decryptJWT('clienteNivel'));
        }
    })
    return mostrar
}