//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { Form} from 'react-bootstrap';
//REDUX
import { useDispatch } from 'react-redux';
import { confimarRecebimentoVirtueyes } from "../../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta'
import decryptJWT from '../../../../utils/decryptJWT'

let atualizarParent = false;

export default function FinalizarAtendimento(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    //HOOKS MODAIS
    const [revelarMensagemAtencaoConclusao, setRevelarMensagemAtencaoConclusao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const despacho = useDispatch();
    const modal = useRef();
   
    async function handleSalvar(){
        ColetaClickstream(1396) // 'solicitacaoOperadoraTabelaFinalizarAtendimentoConfirmar
        confirmarRecebimento()
    }

    async function handleCancelar() {
        setSpinAtivo(false)
        if(atualizarParent === true){
            props.setRenderizar(!props.renderizar)
        } 
        despacho(confimarRecebimentoVirtueyes(false))
    }

    useEffect (() => {
        setRevelarMensagemAtencaoConclusao(true)
    }, renderizar)

    function confirmarRecebimento() {
        setSpinAtivo(true)
        async function preencherTabela (){
            await api.post('m1/cadastrar/solicitacao/confirmar_recebimento', {
                solicitacoes: props.itensSelecionados
            })
            .then(function (response) {
                setSpinAtivo(false)
                atualizarParent = true
                handleMensagemSucesso(true)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro ao processar solicitação")
                handleMensagemErro(false)
            });
            setSpinAtivo(false)
         }
         preencherTabela()
    }

    function handleMensagemAtencaoConclusao(confirmacao){
        if(revelarMensagemAtencaoConclusao === false){
            setRevelarMensagemAtencaoConclusao(true)
            handleCancelar()
        }else{
            setRevelarMensagemAtencaoConclusao(false)
        }
        if(confirmacao === true){
            handleSalvar()
        } else {
            ColetaClickstream(1395) // 'solicitacaoOperadoraTabelaFinalizarAtendimentoCancelar
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }
    
    return(
        <Form ref={modal} className="modal-acao-processar-pedido fundo-cor-6">
            { revelarMensagemAtencaoConclusao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencaoConclusao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={"Tem certeza que deseja processar este(s) pedido(s)?"}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"As solicitações foram processadas."}
                            mensagemBotao={"Ok"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
