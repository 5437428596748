//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';/*eslint-disable*/
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectFatStatus from '../../componentesModais/selects/SelectFatStatus'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectFatStatus, setRevelarSelectFatStatus] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">Tipo de Pessoa {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectFatStatus === false &&
                <Button name="cdServico" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFatStatus(!revelarSelectFatStatus)}>
                    <div className="fonte-cor-1 label-campo">{dsServico}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectFatStatus === true &&
                <SelectFatStatus
                    setRevelarSelectFatStatus={setRevelarSelectFatStatus} //Hook para fechar a modal
                    setCdStatus={setCdStatus} //Código do Status
                    setDsStatus={setDsStatus} //Nome do Status
                    dsStatus={dsStatus}
                >
                </SelectFatStatus>
            }
        </Form.Group>*/

export default function SelectFatStatus(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS MODAL
    const [lsStatus, setLsStatus] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleStatus(codigoStatus, NomeStatus){
        props.setCdStatus(codigoStatus)
        props.setDsStatus(NomeStatus)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectFatStatus(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderStatus(){
            const {data} = await api.get('/m1/consultar/fat_fatura_status');

            if(data.dados.length > 0){
                let objetoStatus = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.cd_fat_fatura_status}>
                            <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleStatus(item.cd_fat_fatura_status, item.ds_fatura_status) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.ds_fatura_status}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsStatus(objetoStatus)
            }else{
                let objetoStatus = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsStatus(objetoStatus)
            }
            setSpinAtivo(false)
        }
        renderStatus()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-Statusiner"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectFatStatus(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsStatus}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsStatus}
                </ul>
            </div>
        </Form>
    )
}