//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Image, ProgressBar, Button, Form, ButtonToolbar, Col} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import {formatarData} from '../../../../utils/formatadorDataTabela'
import moment from 'moment'
//ICONES
import {elipseVerde, fecharJanela, lixeiraVermelho, exclamacao, setaLado, alertaAtencao, icone, setaDropdown} from '../../../../utils/alternanciaIcones';
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import {identificarValidacaoAcoes} from '../../../../utils/identificarValidacaoAcoes';
import { revelarDispositivosAlterarServicos } from "../../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemAtencaoSelect from '../../../componentesModais/mensagens/mensagemAtencaoSelect'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import SelectTela from '../../../componentesModais/selects/selectTela'
import Calendario from '../../../calendario/calendario'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import RetornarParametro from '../../../../utils/decryptJWT'
import decryptJWT from '../../../../utils/decryptJWT'
import '../../../../css/painelFaturas/fatDispositivos/fatAcoes.css'


let objetoJSON = {}
const valorLimite = 50
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let simcardSelecionados = [];
let itensVar = []

let mock=[{
    'CD_SIMCARD':1,
    'DS_DESCRICAO':'Descricao',
    'DS_STATUS':'Ativo',
    'DS_LINHA':'5198484195',
    'DS_ICCID':'1234567890'
},{
    'CD_SIMCARD':2,
    'DS_DESCRICAO':'Descricao',
    'DS_STATUS':'Ativo',
    'DS_LINHA':'5198484195',
    'DS_ICCID':'1234567890'
},{
    'CD_SIMCARD':3,
    'DS_DESCRICAO':'Descricao',
    'DS_STATUS':'Ativo',
    'DS_LINHA':'5198484195',
    'DS_ICCID':'1234567890'
},{
    'CD_SIMCARD':4,
    'DS_DESCRICAO':'Descricao',
    'DS_STATUS':'Ativo',
    'DS_LINHA':'5198484195',
    'DS_ICCID':'1234567890'
}]

export default function AlterarServico(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS]
    const [dsConta, setDsConta] = useState('Selecione')
    const [cdConta, setCdConta] = useState()
    const [dsProtocolo, setDsProtocolo] = useState("")
    const [dsDescricao, setDsDescricao] = useState();
    const [renderizar, setRenderizar] = useState(false);
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1);
    const [linhaRemover, setLinhaRemover] = useState('');
    const [categoria, setCategoria] = useState(1)
    const [arraySelecionado, setArraySelecionado] = useState('');
    const [itenSelecionado, setItenSelecionado] = useState('');
    const [controladorPagina, setControladorPagina] = useState(0)
    //HOOKS SWITCHES
    const [vozRecebidaLocal, setVozRecebidaLocal] = useState(false)
    const [vozRecebidaRoaming, setVozRecebidaRoaming] = useState(false)
    const [vozRecebidaInternacional, setVozRecebidaInternacional] = useState(false)
    const [vozRealizadaLocal, setVozRealizadaLocal] = useState(false)
    const [vozRealizadaRoaming, setVozRealizadaRoaming] = useState(false)
    const [vozRealizadaInternacional, setVozRealizadaInternacional] = useState(false)
    
    const [smsRecebidaLocal, setSmsRecebidaLocal] = useState(false)
    const [smsRecebidaRoaming, setSmsRecebidaRoaming] = useState(false)
    const [smsRecebidaInternacional, setSmsRecebidaInternacional] = useState(false)
    const [smsRealizadaLocal, setSmsRealizadaLocal] = useState(false)
    const [smsRealizadaRoaming, setSmsRealizadaRoaming] = useState(false)
    const [smsRealizadaInternacional, setSmsRealizadaInternacional] = useState(false)
    
    const [dadosRecebidaLocal, setDadosRecebidaLocal] = useState(false)
    const [dadosRecebidaRoaming, setDadosRecebidaRoaming] = useState(false)
    //OUTROS HOOKS
    const [revelarSelectTela, setRevelarSelectTela] = useState(false)
    const [dataMin, setDataMin] = useState(new Date())
    const [dataMax, setDataMax] = useState(moment().add(1, "years")._d)
    const [dataAbertura, setDataAbertura] = useState('')
    const [dataConclusao, setDataConclusao] = useState('')
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    useEffect(()=>{
        setSpinAtivo(false);
        handleLinhasSelecionadas()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    },[])

    async function handleCancelar() {
        ColetaClickstream(1152) // AlterarServicosCancelar
        setSpinAtivo(false)
        despacho(revelarDispositivosAlterarServicos(false))
    }

    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(mock.length)
        if(mock.length === 0){
            handleCancelar()
        }
    }

    function handleSalvar(){
    }

    function handlerChangeSwitich(id,array,teste){
        setItenSelecionado(id)
        setArraySelecionado(array)
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            props.setRenderizar(!props.renderizar)
            handleCancelar()
        }
        if(confirmacao){
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-protocolo"){
                setDsProtocolo(valor)
            }
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-alterar-servico fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Alterar serviço</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-alterar-ciclo-vida-1">
                            <div className="coluna-alterar-ciclo-vida-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Protocolo</div>
                                    </Form.Label>
                                    <Form.Control 
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        maxLength="255"
                                        name="protocolo" 
                                        value={dsProtocolo}
                                        onChange={e => validarNumero("campo-texto-protocolo", e.target.value)}
                                        ref={register({ required: true, min: 3, max: 255 })} 
                                        placeholder="Insira o nº do protocolo" 
                                    />
                                    {errors.protocolo && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório.</p>}
                                </Form.Group>                                
                            </div>
                            <div className="coluna-alterar-ciclo-vida-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Abertura da solicitação</div>
                                    </Form.Label>
                                    <Calendario ref={register({ required: true})} formato="DD/MM/YYYY" imputCustomisado='De:' data={dataAbertura} setData={setDataAbertura} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                    {errors.abertura && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório.</p>}
                                </Form.Group>                                
                            </div>
                            <div className="coluna-alterar-ciclo-vida-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Previsão de conclusão</div>
                                    </Form.Label>
                                    <Calendario ref={register({ required: true})} formato="DD/MM/YYYY" imputCustomisado='Até:' data={dataConclusao} setData={setDataConclusao} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                    {errors.conclusao && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório.</p>}
                                </Form.Group>                                
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Descrição</div>
                            </Form.Label>
                            <Form.Control name="descricao" className="campo-texto-cor-3 campo-texto-area" as="textarea" rows="3" maxLength="255" placeholder="Descreva aqui o motivo" ref={register({ required: true, min: 3, max: 255 })} onChange={e => setDsDescricao(e.target.value)}></Form.Control>
                            {errors.descricao && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório.</p>}
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <div className="campo-select-divisor-cor-1 divisoria-alterar-servico"></div>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-configurador-alterar-servico">
                            <div className="coluna-configurador-alterar-servico-1 fundo-cor-5">
                                { categoria !== 1 ?
                                    <div className="container-switches-configurador-alterar-servico-2" onClick={() => (setCategoria(1),setControladorPagina(0))}>
                                        <div className='fonte-cor-1 nao-selecionavel label-titulo-botao-colunas'>Voz</div>
                                    </div>
                                :
                                    <>
                                        <div className="triangulo-personalizar-alterar-servico"></div>
                                        <div className="container-switches-configurador-alterar-servico-2 fundo-cor-8" onClick={() => (setCategoria(1),setControladorPagina(0))}>
                                            <div className='fonte-cor-1 nao-selecionavel label-titulo-botao-colunas'>Voz</div>
                                        </div>
                                    </>
                                }
                                { categoria !== 2 ?
                                    <div className="container-switches-configurador-alterar-servico-2" onClick={() => (setCategoria(2),setControladorPagina(0))}>
                                        <div className='fonte-cor-1 nao-selecionavel label-titulo-botao-colunas'>SMS</div>
                                    </div>
                                :
                                    <>
                                        <div className="triangulo-personalizar-alterar-servico"></div>
                                        <div className="container-switches-configurador-alterar-servico-2 fundo-cor-8" onClick={() => (setCategoria(2),setControladorPagina(0))}>
                                            <div className='fonte-cor-1 nao-selecionavel label-titulo-botao-colunas'>SMS</div>
                                        </div>
                                    </>
                                }
                                { categoria !== 3 ?
                                    <div className="container-switches-configurador-alterar-servico-2" onClick={() => (setCategoria(3),setControladorPagina(0))}>
                                        <div className='fonte-cor-1 nao-selecionavel label-titulo-botao-colunas'>Dados</div>
                                    </div>
                                :
                                    <>
                                        <div className="triangulo-personalizar-alterar-servico"></div>
                                        <div className="container-switches-configurador-alterar-servico-2 fundo-cor-8" onClick={() => (setCategoria(3),setControladorPagina(0))}>
                                            <div className='fonte-cor-1 nao-selecionavel label-titulo-botao-colunas'>Dados</div>
                                        </div>
                                    </>
                                }
                                { categoria !== 4 ?
                                    <div className="container-switches-configurador-alterar-servico-2" onClick={() => (setCategoria(4),setControladorPagina(0))}>
                                        <div className='fonte-cor-1 nao-selecionavel label-titulo-botao-colunas'>Outros</div>
                                    </div>
                                :
                                    <>
                                        <div className="triangulo-personalizar-alterar-servico"></div>
                                        <div className="container-switches-configurador-alterar-servico-2 fundo-cor-8" onClick={() => ( setCategoria(4),setControladorPagina(0))}>
                                            <div className='fonte-cor-1 nao-selecionavel label-titulo-botao-colunas'>Outros</div>
                                        </div>
                                    </>
                                }
                            </div>

                            <div className="coluna-configurador-alterar-servico-2 fundo-cor-8">
                            { categoria === 1 &&
                                    <>
                                        <div className="grupo-botoes-alterar-servico">
                                            <div className="coluna-botoes-alterar-servico">
                                                { controladorPagina === 0 ?
                                                    <Button className="botao-tab-nova-mensagen-central-mensagens-ativado fonte-cor-1" onClick={() => setControladorPagina(0)}>
                                                        Ligações realizadas
                                                    </Button>
                                                :
                                                    <Button className="botao-tab-nova-mensagen-central-mensagens fonte-cor-1" onClick={() => setControladorPagina(0)}>
                                                        Ligações realizadas
                                                    </Button>
                                                }
                                            </div>
                                            <div className="coluna-botoes-alterar-servico">
                                                { controladorPagina === 1 ?
                                                    <Button className="botao-tab-nova-mensagen-central-mensagens-ativado fonte-cor-1" onClick={() => setControladorPagina(1)}>
                                                        Ligações recebidas
                                                    </Button>
                                                :
                                                    <Button className="botao-tab-nova-mensagen-central-mensagens fonte-cor-1" onClick={() => setControladorPagina(1)}>
                                                        Ligações recebidas
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                        <div className="campo-select-divisor-cor-1 divisoria-alterar-servico-botoes"></div>
                                        <div className="campo-select-divisor-cor-1 divisoria-alterar-servico-switches"></div>
                                        { controladorPagina === 0 &&
                                            <div className="grupo-switches-alterar-servico">
                                                <div className="container-switches-alterar-servico">
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={vozRecebidaLocal} class="flipswitch-medium-cb" id='LigacaoRealizadaLocal' onChange={(e) => setVozRecebidaLocal(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRealizadaLocal'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-exibir-colunas-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Local</div>
                                                        </div>
                                                    </div>
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={vozRecebidaRoaming} class="flipswitch-medium-cb" id='LigacaoRealizadaRoaming' onChange={(e) => setVozRecebidaRoaming(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRealizadaRoaming'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-exibir-colunas-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Roaming</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="container-switches-alterar-servico">
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={vozRecebidaInternacional} class="flipswitch-medium-cb" id='LigacaoRealizadaInternacional' onChange={(e) => setVozRecebidaInternacional(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRealizadaInternacional'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-alterar-servico-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Internacional</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        { controladorPagina === 1 &&
                                            <div className="grupo-switches-alterar-servico">
                                                <div className="container-switches-alterar-servico">
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={vozRealizadaLocal} class="flipswitch-medium-cb" id='LigacaoRecebidaLocal' onChange={(e) => setVozRealizadaLocal(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRecebidaLocal'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-exibir-colunas-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Local</div>
                                                        </div>
                                                    </div>
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={vozRealizadaRoaming} class="flipswitch-medium-cb" id='LigacaoRecebidaRoaming' onChange={(e) => setVozRealizadaRoaming(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRecebidaRoaming'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-exibir-colunas-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Roaming</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="container-switches-alterar-servico">
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={vozRealizadaInternacional} class="flipswitch-medium-cb" id='LigacaoRecebidaInternacional' onChange={(e) => setVozRealizadaInternacional(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRecebidaInternacional'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-alterar-servico-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Internacional</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }

                                { categoria === 2 &&
                                    <>
                                        <div className="grupo-botoes-alterar-servico">
                                            <div className="coluna-botoes-alterar-servico">
                                                { controladorPagina === 0 ?
                                                    <Button className="botao-tab-nova-mensagen-central-mensagens-ativado fonte-cor-1" onClick={() => setControladorPagina(0)}>
                                                        SMS realizadas
                                                    </Button>
                                                :
                                                    <Button className="botao-tab-nova-mensagen-central-mensagens fonte-cor-1" onClick={() => setControladorPagina(0)}>
                                                        SMS realizadas
                                                    </Button>
                                                }
                                            </div>
                                            <div className="coluna-botoes-alterar-servico">
                                                { controladorPagina === 1 ?
                                                    <Button className="botao-tab-nova-mensagen-central-mensagens-ativado fonte-cor-1" onClick={() => setControladorPagina(1)}>
                                                        SMS recebidas
                                                    </Button>
                                                :
                                                    <Button className="botao-tab-nova-mensagen-central-mensagens fonte-cor-1" onClick={() => setControladorPagina(1)}>
                                                        SMS recebidas
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                        <div className="campo-select-divisor-cor-1 divisoria-alterar-servico-botoes"></div>
                                        <div className="campo-select-divisor-cor-1 divisoria-alterar-servico-switches"></div>
                                        { controladorPagina === 0 &&
                                            <div className="grupo-switches-alterar-servico">
                                                <div className="container-switches-alterar-servico">
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={smsRecebidaLocal} class="flipswitch-medium-cb" id='LigacaoRealizadaLocal' onChange={(e) => setSmsRecebidaLocal(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRealizadaLocal'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-exibir-colunas-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Local</div>
                                                        </div>
                                                    </div>
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={smsRecebidaRoaming} class="flipswitch-medium-cb" id='LigacaoRealizadaRoaming' onChange={(e) => setSmsRecebidaRoaming(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRealizadaRoaming'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-exibir-colunas-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Roaming</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="container-switches-alterar-servico">
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={smsRecebidaInternacional} class="flipswitch-medium-cb" id='LigacaoRealizadaInternacional' onChange={(e) => setSmsRecebidaInternacional(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRealizadaInternacional'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-alterar-servico-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Internacional</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        { controladorPagina === 1 &&
                                            <div className="grupo-switches-alterar-servico">
                                                <div className="container-switches-alterar-servico">
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={smsRealizadaLocal} class="flipswitch-medium-cb" id='LigacaoRecebidaLocal' onChange={(e) => setSmsRealizadaLocal(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRecebidaLocal'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-exibir-colunas-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Local</div>
                                                        </div>
                                                    </div>
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={smsRealizadaRoaming} class="flipswitch-medium-cb" id='LigacaoRecebidaRoaming' onChange={(e) => setSmsRealizadaRoaming(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRecebidaRoaming'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-exibir-colunas-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Roaming</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="container-switches-alterar-servico">
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={smsRealizadaInternacional} class="flipswitch-medium-cb" id='LigacaoRecebidaInternacional' onChange={(e) => setSmsRealizadaInternacional(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRecebidaInternacional'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-alterar-servico-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Internacional</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }

                                { categoria === 3 &&
                                    <>
                                        <div className="grupo-botoes-alterar-servico">
                                            <div className="coluna-botoes-alterar-servico">
                                                { controladorPagina === 0 ?
                                                    <Button className="botao-tab-nova-mensagen-central-mensagens-ativado fonte-cor-1" onClick={() => setControladorPagina(0)}>
                                                        Tráfego de dados
                                                    </Button>
                                                :
                                                    <Button className="botao-tab-nova-mensagen-central-mensagens fonte-cor-1" onClick={() => setControladorPagina(0)}>
                                                        Tráfego de dados
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                        <div className="campo-select-divisor-cor-1 divisoria-alterar-servico-botoes"></div>
                                        <div className="campo-select-divisor-cor-1 divisoria-alterar-servico-switches"></div>
                                        { controladorPagina === 0 &&
                                            <div className="grupo-switches-alterar-servico">
                                                <div className="container-switches-alterar-servico">
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={smsRecebidaLocal} class="flipswitch-medium-cb" id='LigacaoRealizadaLocal' onChange={(e) => setSmsRecebidaLocal(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRealizadaLocal'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-exibir-colunas-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Local</div>
                                                        </div>
                                                    </div>
                                                    <div className="grupo-switches-alterar-servico">
                                                        <div className="coluna-switches-exibir-colunas-1">
                                                            <div class="flipswitch-medium">
                                                                <input type="checkbox"  checked={smsRecebidaRoaming} class="flipswitch-medium-cb" id='LigacaoRealizadaRoaming' onChange={(e) => setSmsRecebidaRoaming(e.target.checked)}/>
                                                                <label class="flipswitch-medium-label" for='LigacaoRealizadaRoaming'>
                                                                    <div class="flipswitch-medium-inner"></div>
                                                                        <div class="flipswitch-medium-switch"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-switches-exibir-colunas-2">
                                                            <div className='label-switch-exibir-coluna fonte-cor-1'>Roaming</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <div className="campo-select-divisor-cor-1 divisoria-alterar-servico"></div>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}