import React, {useState, useRef, useEffect} from 'react';
import {GoogleMap, withGoogleMap, withScriptjs, Marker, InfoWindow} from 'react-google-maps'
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import { GOOGLE_MAPS_JAVASCRIPT_API } from '../../utils/googleMapsApiKey';

function Mapa(props){
    const [pontoSelecionado, setPontoSelecionado] = useState(null)
    const ref = useRef();
    const tipo = props.props.tipo
    const handleClickFora = e => {
        if (ref.current !== null){
            if (!ref.current.contains(e.target)) {
                setPontoSelecionado(null)
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])
    
    return(
        <GoogleMap 
            defaultZoom={10} 
            defaultCenter={{lat: props.props.array[0].latitude, lng: props.props.array[0].longitude}}
        > 
            <MarkerClusterer
                averageCenter
                gridSize={30}
                maxZoom={20}
                zoomOnClick
            >
                {props.props.array.map((ponto, index) => (
                    <div ref={ref}>
                        {ponto.status === 'online'?
                            <Marker
                                icon={{
                                    url: tipo === 'historico' ? '../../icones/filled-marker-gray.svg' : '../../icones/filled-marker-green.svg',
                                    scaledSize: new window.google.maps.Size(25, 25)
                                }}
                                key={index}
                                position={{
                                    lat: ponto.latitude, 
                                    lng: ponto.longitude
                                }}
                                onClick={() => setPontoSelecionado(ponto)}
                            />
                        :
                            <Marker
                                icon={{
                                    url: tipo === 'historico' ? '../../icones/filled-marker-gray.svg' : '../../icones/filled-marker-red.svg',
                                    scaledSize: new window.google.maps.Size(25, 25)
                                }}
                                key={index}
                                position={{
                                    lat: ponto.latitude, 
                                    lng: ponto.longitude
                                }}
                                onClick={() => setPontoSelecionado(ponto)}
                            />
                        }
                    </div>
                ))}
            </MarkerClusterer>
        
            { pontoSelecionado &&
                <div>
                    <InfoWindow
                        position={{
                            lat: pontoSelecionado.latitude, 
                            lng: pontoSelecionado.longitude
                        }}
                        onCloseClick={() => setPontoSelecionado(null)}
                    >
                        <div ref={ref}>
                            <div className="info-window-google-maps-detalhamento-linha">
                                <div className="info-window-google-maps-detalhamento-titulo fonte-cor-1">Endereço: </div>
                                <div className="info-window-google-maps-detalhamento-texto-link" onClick={()=> window.open("https://www.google.com/maps/search/?api=1&query="+pontoSelecionado.latitude+","+pontoSelecionado.longitude+"", "_blank")}>Abrir em nova aba</div>
                            </div>
                            <div className="info-window-google-maps-detalhamento-linha">
                                <div className="info-window-google-maps-detalhamento-titulo fonte-cor-1">Operadora: </div>
                                <div className="info-window-google-maps-detalhamento-texto fonte-cor-1">{pontoSelecionado.operadora}</div>
                            </div>
                            <div className="info-window-google-maps-detalhamento-linha">
                                <div className="info-window-google-maps-detalhamento-coluna-1">
                                    <div className="info-window-google-maps-detalhamento-titulo-1 fonte-cor-1">Área(Lac): </div>
                                    <div className="info-window-google-maps-detalhamento-texto-1 fonte-cor-1">{pontoSelecionado.lac}</div>
                                </div>
                                <div className="info-window-google-maps-detalhamento-coluna-2">
                                    <div className="info-window-google-maps-detalhamento-titulo-1 fonte-cor-1">ID(antena): </div>
                                    <div className="info-window-google-maps-detalhamento-texto-1 fonte-cor-1">{pontoSelecionado.cid}</div>
                                </div>
                            </div>
                            <div className="info-window-google-maps-detalhamento-linha">
                                <div className="info-window-google-maps-detalhamento-titulo fonte-cor-1">Data/hora: </div>
                                <div className="info-window-google-maps-detalhamento-texto fonte-cor-1">{pontoSelecionado.data}</div>
                            </div>
                        </div>
                    </InfoWindow>
                </div>
            }
        </GoogleMap>
    )
}

const WrappedMap = withScriptjs(withGoogleMap(Mapa));

export default function MapaGoogleCluster(props){
    return (
        <div style={{width: '100%', height: '100%', zoom: '125%'}}>
            <WrappedMap
                props={props}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization&key=${GOOGLE_MAPS_JAVASCRIPT_API}&amp;`}
                loadingElement={<div style={{ height: '100%' }}/>}
                containerElement={<div style={{ height: '100%' }}/>}
                mapElement={<div style={{ height: '100%' }}/>}
            />
        </div>
    )
}