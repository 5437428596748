//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { Button} from 'react-bootstrap';/*eslint-disable*/
import { alertaInformacao } from '../../../utils/alternanciaIcones'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import MensagemInformacao from '../componentesModais/mensagens/mensagemInformacao'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma.
    ex: { revelarMensagemInformacao &&
            <div className="componente-modal-overlay">
                <div className="componente-modal">
                    <MensagemInformacao
                        handleMensagemInformacao={handleMensagemInformacao}
                        mensagemTitulo={"TITULO DA MENSAGEM"}
                        mensagemParagrafo1={"CORPO DA MENSAGEM 1"}
                        mensagemParagrafo2={"CORPO DA MENSAGEM 2"}
                        mensagemParagrafo3={"CORPO DA MENSAGEM 3"}
                        mensagemBotao={"TEXTO DO BOTÃO"}
                    />
                </div>
            </div>
        }
        
    Agora crie uma função para manejar o estado da mensagem e executar a função de
    fechamento padrão caso necessário.
    ex: function handleMensagemInformacao(confirmacao){
            if(revelarMensagemInformacao === false){
                setRevelarMensagemInformacao(true)
            }else{
                setRevelarMensagemInformacao(false)
                if(confirmacao === true){
                    //ALGUMA FUNÇÃO
                }
            }
        }*/

export default function MensagemInformacao(props) {
    //HOOK PRINCIPAIS
    const [mensagemTitulo, setMensagemTitulo] = useState('')
    const [mensagemParagrafo1, setMensagemParagrafo1] = useState('')
    const [mensagemParagrafo2, setMensagemParagrafo2] = useState('')
    const [mensagemParagrafo3, setMensagemParagrafo3] = useState('')
    const [mensagemBotao, setMensagemBotao] = useState('')
    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar(false)
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleCancelar(confirmacao){
        if(confirmacao === true){
            props.handleMensagemInformacao(true)
        }else{
            props.handleMensagemInformacao(false)
        }
    }

    useEffect(() => {
        setMensagemTitulo(props.mensagemTitulo)
        setMensagemParagrafo1(props.mensagemParagrafo1)
        setMensagemParagrafo2(props.mensagemParagrafo2)
        setMensagemParagrafo3(props.mensagemParagrafo3)
        setMensagemBotao(props.mensagemBotao)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])
    
    return(
        <div className="mensagem-overlay">
            <div className="mensagem-container" style={props.marginTop  ? { marginTop: props.marginTop } : null}>
                <div ref={modal} className="mensagem-corpo fundo-cor-6">
                    <div className="mensagem-container-icone">
                        <img className="mensagem-icone" src={alertaInformacao()} alt="sucesso"/>
                    </div>
                    <div className="mensagem-container-titulo">
                        <div className="fonte-cor-1 label-titulo-mensagem">{mensagemTitulo}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo1}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo2}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo3}</div>
                    </div>
                    <div className="mensagem-container-botao">
                        <Button className="mensagem-botao-confirmar" variant="success" size="sm" onClick={() => handleCancelar(true)}>
                            <div className="fonte-cor-4">{mensagemBotao}</div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function RenderMensagemInformacao(props) {
    return (
    <div className="componente-modal-overlay">
        <div className="componente-modal">
            <MensagemInformacao {...props} />
        </div>
    </div>
    )
}