//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Button, Form, Col } from 'react-bootstrap';
import moment from 'moment';
//ICONES
import {elipseVerde, fecharJanela, setaDropdown} from '../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
import { revelarDadosContaUsuario } from "../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../componentesModais/mensagens/mensagemErro'
import SelectUf from '../componentesModais/selects/selectUf2'
import decryptJWT from '../../utils/decryptJWT'
// COLETA CLICKSTREAM
import ColetaClickstream from '../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../spinnerVeye/spinnerVeye.js'
//CSS
import '../../css/perfilUsuario/perfilUsuario.css'
export default function DesativarDemonstracao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { handleSubmit } = useForm();
    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [habilitarEdicao, setHabilitarEdicao] = useState(true);
    const [revelarSelectUf, setRevelarSelectUf] = useState(false);
    const [dsUf, setDsUf] = useState('Selecione');
    const [phUf, setPhUf] = useState('');
    const [cdUf, setCdUf] = useState();
    
    const [emailEmpresa, setEmailEmpresa] = useState();
    const [telefone, setTelefone] = useState();
    const [nome, setNome] = useState();
    const [razaoSocial, setRazaoSocial] = useState();
    const [cep, setCep] = useState();
    const [endereco, setEndereco] = useState();
    const [numero, setNumero] = useState();
    const [bairro, setBairro] = useState();
    const [cidade, setCidade] = useState();
    const [infoAdicional, setInfoAdicional] = useState();

    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const despacho = useDispatch();
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    async function handleSalvar(){
        setSpinAtivo(true)

        await api.post('/m1/cadastrar/alteracao_cadastral', {
            "uf_sigla":dsUf == 'Selecione'?undefined:dsUf,
            "ds_email":emailEmpresa,
            "ds_telefone":telefone,
            "ds_nome":nome,
            "ds_pav_cliente":razaoSocial,
            "cep":cep,
            "logradouro":endereco,
            "numero":numero,
            "bairro":bairro,
            "cidade":cidade,
            "complemento":infoAdicional,
            "dt_created_at": moment().format('YYYY-MM-DD HH:mm:ss'),
            'cd_pav_usuario' :  decryptJWT('codigo'),
            'n0_codigo_cliente' :  decryptJWT('codigoClienteErpAntigo'),
            "ds_email_usuario": decryptJWT('email'),
            "cd_pav_cliente": decryptJWT('codigoCliente'),
        },{timeout: 20000})
        .then(function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro("Erro na solicitação")
            handleMensagemErro(false)
        });
    }

    async function handleCancelar() {
        setSpinAtivo(false)
        despacho(revelarDadosContaUsuario(false))
    }
    


    useEffect(() => {
        setSpinAtivo(true)
        async function preencherTela(){
            api.get('/m1/consultar/cliente?cd_pav_cliente='+decryptJWT('codigoCliente'))
            .then(function (resposta) {
                setDsUf(resposta.data.dados[0].uf_sigla);
                setPhUf(resposta.data.dados[0].uf_sigla);
                setCdUf(resposta.data.dados[0].cd_pav_uf);
                setEmailEmpresa(resposta.data.dados[0].ds_email);
                setTelefone(resposta.data.dados[0].ds_telefone);
                setNome(resposta.data.dados[0].ds_pav_cliente);
                setRazaoSocial(resposta.data.dados[0].ds_nome);
                setCep(resposta.data.dados[0].cep);
                setEndereco(resposta.data.dados[0].logradouro);
                setNumero(resposta.data.dados[0].numero);
                setBairro(resposta.data.dados[0].bairro);
                setCidade(resposta.data.dados[0].cidade);
                // setInfoAdicional();
            })
            .catch(function (erro) {
            })
        }
        preencherTela()
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao ===true){
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-telefone"){
                setTelefone(valor)
            }
            if(id === "campo-texto-cep"){
                setCep(valor)
            }
            if(id === "campo-texto-numero"){
                setNumero(valor)
            }
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-desativar-demonstracao fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Dados da conta</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1"/>
                {/* CORPO */}
                <Form.Row className="usuario-dados-corpo-linha">
                    <div className="corpo-dados-usuario-email">
                        <Form.Label> <div className="fonte-cor-1 label-campo">E-mail da empresa</div> </Form.Label>
                        <Form.Control
                            className="campo-texto-cor-3 campo-texto"
                            type="text"
                            maxLength="30"
                            readOnly 
                            value={emailEmpresa}
                            onChange={e => setEmailEmpresa(e.target.value)}
                        />
                    </div>
                    <Col>
                        <div>
                            <Form.Label> <div className="fonte-cor-1 label-campo">Telefone empresa</div> </Form.Label>
                            <Form.Control
                                className="campo-texto-cor-3 campo-texto"
                                type="text"
                                maxLength="30"
                                readOnly
                                value={telefone}
                                onChange={e => validarNumero("campo-texto-telefone", e.target.value)}
                            />
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row className="usuario-dados-corpo-linha">
                    <Col>
                        <div className="corpo-dados-usuario-email">
                            <Form.Label> <div className="fonte-cor-1 label-campo">Nome fantasia</div> </Form.Label>
                            <Form.Control
                                className="campo-texto-cor-3 campo-texto"
                                type="text"
                                maxLength="30"
                                readOnly
                                value={nome}
                                onChange={e => setNome(e.target.value)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <Form.Label> <div className="fonte-cor-1 label-campo">Razão social</div> </Form.Label>
                        <Form.Control
                            className="campo-texto-cor-3 campo-texto"
                            type="text"
                            maxLength="30"
                            readOnly
                            value={razaoSocial}
                            onChange={e => setRazaoSocial(e.target.value)}
                        />
                    </Col>
                </Form.Row>

                <Form.Row className="usuario-dados-corpo-linha">
                    <Col>
                        <div className="corpo-dados-usuario-email">
                            <Form.Label> <div className="fonte-cor-1 label-campo">CEP</div> </Form.Label>
                            <Form.Control
                                className="campo-texto-cor-3 campo-texto"
                                type="text"
                                maxLength="9"
                                readOnly
                                value={cep}
                                onChange={e => validarNumero("campo-texto-cep", e.target.value)}

                                />
                        </div>
                    </Col>
                    <div className="corpo-dados-usuario-endereco">
                        <Form.Label> <div className="fonte-cor-1 label-campo">Endereço</div> </Form.Label>
                        <Form.Control
                            className="campo-texto-cor-3 campo-texto"
                            type="text"
                            maxLength="30"
                            readOnly
                            value={endereco}
                            onChange={e => setEndereco(e.target.value)}
                        />
                    </div>
                    <div className="corpo-dados-usuario-numero">
                        <Form.Label> <div className="fonte-cor-1 label-campo">Número</div> </Form.Label>
                        <Form.Control
                            className="campo-texto-cor-3 campo-texto"
                            type="text"
                            maxLength="30"
                            readOnly
                            value={numero}
                            onChange={e => validarNumero("campo-texto-numero", e.target.value)}

                        />
                    </div>
                </Form.Row>

                <Form.Row className="usuario-dados-corpo-linha">
                    <Col>
                        <div className="corpo-dados-usuario-email">
                            <Form.Label> <div className="fonte-cor-1 label-campo">Bairro</div> </Form.Label>
                            <Form.Control
                                className="campo-texto-cor-3 campo-texto"
                                type="text"
                                maxLength="30"
                                readOnly
                                value={bairro}
                                onChange={e => setBairro(e.target.value)}
                                />
                        </div>
                    </Col>
                    <div className="corpo-dados-usuario-endereco">
                        <Form.Label> <div className="fonte-cor-1 label-campo">Cidade</div> </Form.Label>
                        <Form.Control
                            className="campo-texto-cor-3 campo-texto"
                            type="text"
                            maxLength="30"
                            readOnly
                            value={cidade}
                            onChange={e => setCidade(e.target.value)}
                        />
                    </div>
                    <div className="corpo-dados-usuario-numero">
                        <Form.Label> <div className="fonte-cor-1 label-campo">Estado</div> </Form.Label>
                        { revelarSelectUf === false &&
                            <Button readOnly className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectUf(!revelarSelectUf)}>
                                <div className="fonte-cor-1 label-campo">{dsUf}</div>
                                <img className={revelarSelectUf?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                            </Button>
                        }
                        { revelarSelectUf === true &&
                            <SelectUf
                                setRevelarSelectUf={setRevelarSelectUf} //Hook para fechar a modal
                                setCdUf={setCdUf} //Código do Uf
                                setDsUf={setDsUf} //Nome do Uf
                                dsUf={dsUf} 
                                phUf={phUf} //Placeholder para mostrar o valor original em caso de edição
                            >
                            </SelectUf>
                        }
                    </div>
                </Form.Row>

                <Form.Row className="usuario-dados-corpo-linha">
                    <Col>
                        <Form.Label> <div className="fonte-cor-1 label-campo">Informações adicionais (opcional)</div> </Form.Label>
                        <Form.Control
                            className="campo-texto-cor-3 campo-texto"
                            type="text"
                            maxLength="30"
                            readOnly
                            value={infoAdicional}
                            onChange={e => setInfoAdicional(e.target.value)}
                        />
                    </Col>
                </Form.Row>
                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo"/>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}