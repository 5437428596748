//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, fecharJanela, setaDropdown} from '../../../../utils/alternanciaIcones'
//MODAIS
import decryptJWT from '../../../../utils/decryptJWT'
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
import SelectFaturasCheckbox from '../../../componentesModais/selects/selectFaturasCheckbox'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import MensagemInformacao from '../../../componentesModais/mensagens/mensagemInformacao'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch } from 'react-redux';
import {conciliacaoEmLote} from "../../../../redux/actions/index";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'

export default function ConciliacaoEmLote(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();

    //HOOKS PRINCIPAIS
    const [cdOperadora, setCdOperadora] = useState();
    const [dsOperadora, setDsOperadora] = useState('Selecione');
    const [cdReferencia, setCdReferencia] = useState();
    const [dsReferencia, setDsReferencia] = useState('Selecione');
    const [cdFatura, setCdFatura] = useState();
    const [dsFatura, setDsFatura] = useState('Selecione');
    const [arrayCdFaturas, setArrayCdFaturas] = useState([])

    //HOOKS MODAIS
    const [revelarSelectOperadora, setRevelarSelectOperadora] = useState(false)
    const [revelarSelectReferencia, setRevelarSelectReferencia] = useState(false)
    const [revelarSelectFaturas, setRevelarSelectFaturas] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [revelarMensagemInformacaoConciliacaoLote, setRevelarMensagemInformacaoConciliacaoLote] = useState(false)
    const [dummy, setDummy] = useState()
    //OUTROS HOOKS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [mensagemAlerta, setMensagemAlerta] = useState('')
    const [mensagemSucesso, setMensagemSucesso] = useState('')
    const modal = useRef();
    const despacho = useDispatch();

    const cd_pav_usuario = decryptJWT('codigo')

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function handleSalvar(){
        setSpinAtivo(true)
        let auxArrayFaturas = []
        arrayCdFaturas.map(item =>{
            auxArrayFaturas += item.codigo + ","
        })
        let indexOfVirgula1 = auxArrayFaturas.lastIndexOf(',')
        auxArrayFaturas = auxArrayFaturas.substr(0, indexOfVirgula1)
        
        api.post('/m1/cadastrar/fat_conciliacao_lote', {
            "arrayFaturas":auxArrayFaturas,
        })
        setSpinAtivo(false)
        setRevelarMensagemInformacaoConciliacaoLote(true)
        // .then(async function (response) {
        //     setRevelarMensagemSucesso(true)
        //     setSpinAtivo(false)
        // })
        // .catch(function (error) {
        //     setRevelarMensagemErro(true)
        //     setSpinAtivo(false)
        // });
    }

    function handleCancelar(){
        setArrayCdFaturas([])
        despacho(conciliacaoEmLote(false))
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
    }
    

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
            }
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                handleCancelar()
            }
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
        }
    }

    function handleMensagemInformacaoConciliacaoLote(confirmacao){
        if(revelarMensagemInformacaoConciliacaoLote === false){
            setRevelarMensagemInformacaoConciliacaoLote(true)
        }else{
            handleCancelar()
        }
    }

    useEffect(() => {
        setSpinAtivo(true)
        if(arrayCdFaturas.length > 0){
            if(arrayCdFaturas.length > 0){
                if(arrayCdFaturas[0].codigo === -1){
                    arrayCdFaturas.shift()
                }
            }
            setDsFatura((arrayCdFaturas.length) + " Selecionados")
        }else{
            setDsFatura("Selecione")
        }
        setSpinAtivo(false)
    }, [revelarSelectFaturas])

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <div className="modal-editar-fatura fundo-cor-6">
            <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Conciliação em Lote</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                    <div className="grupo-cadastro-editar-fatura-1">
                        <div className="coluna-cadastro-editar-fatura-1">
                            <Form.Group>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-campo">Operadora</div>
                                </Form.Label>
                                { revelarSelectOperadora === false &&
                                    <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsOperadora}</div>
                                        <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                }
                                { revelarSelectOperadora === true &&
                                    <SelectGenerico
                                        paramRota={"dsp_operadora_2"}
                                        paramCampo1={decryptJWT("fatContas")}
                                        paramCampo2={null}
                                        paramCampo3={null}
                                        paramCampo4={null}
                                        setCodigo={setCdOperadora}
                                        setDescricao1={setDsOperadora}
                                        setDescricao2={setDummy}
                                        setDescricao3={setDummy}
                                        setDescricao4={setDummy}
                                        setRevelarSelect={setRevelarSelectOperadora}
                                        placeholder={dsOperadora}
                                        sufixo={""}
                                    >
                                    </SelectGenerico>
                                }
                            </Form.Group>
                        </div>
                        <div className="coluna-cadastro-editar-fatura-1">
                            <Form.Group>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-campo">Referência</div>
                                </Form.Label>
                                { revelarSelectReferencia === false &&
                                    <Button disabled={cdOperadora !== '' && cdOperadora !== null && cdOperadora !== undefined ? false : true} className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectReferencia(!revelarSelectReferencia)}>
                                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsReferencia}</div>
                                        <img className={revelarSelectReferencia?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                }
                                { revelarSelectReferencia === true &&
                                    <SelectGenerico
                                        paramRota={"referencia_por_operadora"}
                                        paramCampo1={cdOperadora}
                                        paramCampo2={decryptJWT("fatContas")}
                                        paramCampo3={null}
                                        paramCampo4={null}
                                        setCodigo={setCdReferencia}
                                        setDescricao1={setDsReferencia}
                                        setDescricao2={setDummy}
                                        setDescricao3={setDummy}
                                        setDescricao4={setDummy}
                                        setRevelarSelect={setRevelarSelectReferencia}
                                        placeholder={dsReferencia}
                                        sufixo={""}
                                    >
                                    </SelectGenerico>
                                }
                            </Form.Group>
                        </div>
                        <div className="coluna-cadastro-editar-fatura-1">
                            <Form.Group>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-campo">Contas</div>
                                </Form.Label>
                                { revelarSelectFaturas === false &&
                                    <Button disabled={cdReferencia !== '' && cdReferencia !== null && cdReferencia !== undefined ? false : true} className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFaturas(!revelarSelectFaturas)}>
                                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsFatura}</div>
                                        <img className={revelarSelectFaturas?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                                { revelarSelectFaturas === true &&
                                      <SelectFaturasCheckbox
                                      setRevelarselectFaturasCheckbox={setRevelarSelectFaturas} //Hook para fechar a modal
                                      arrayCdFaturas={arrayCdFaturas}
                                      dsFaturasCheckbox={dsFatura}
                                      cdOperadora={cdOperadora}
                                      dsReferencia={dsReferencia}
                                  >
                                  </SelectFaturasCheckbox>
                                }
                            </Form.Group>
                        </div>
                    </div>
                <Form.Row>
                    <Col>
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Selecione a conta, o status da fatura e informe as datas de vencimento, inicio e fim do ciclo e o mês de referência.</Tooltip>}>
                            {cdOperadora ?
                                <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            :
                                <Button  className="botao-confirmar-desabilitado" variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            } 
                        </OverlayTrigger>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>

            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Esse processo pode levar alguns minutos, você receberá um aviso quando estiver pronto e poderá consultar suas faturas em breve"}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={mensagemAlerta}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemInformacaoConciliacaoLote &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacaoConciliacaoLote}
                            mensagemTitulo={"Conciliação em andamento"}
                            mensagemParagrafo1={"Esse processo pode levar alguns minutos, você receberá um aviso quando estiver pronto e poderá consultar sua conciliação em breve"}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}