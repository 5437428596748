//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, fecharJanela, setaDropdown} from '../../../../utils/alternanciaIcones'
//MODAIS
import decryptJWT from '../../../../utils/decryptJWT'
import Calendario from '../../../calendario/calendario'
import SelectFatConta from '../../../componentesModais/selects/selectFatConta'
import SelectFatStatus from '../../../componentesModais/selects/selectFatStatus'
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import MensagemInformacao from '../../../componentesModais/mensagens/mensagemInformacao'
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarRecalcularFaturaReferencia } from "../../../../redux/actions/index";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function RecalculoFaturaReferencia(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();

    //HOOKS PRINCIPAIS
    const [cdReferencia, setCdReferencia] = useState(0);
    const [dsReferencia, setDsReferencia] = useState('Selecione');
    //HOOKS MODAIS
    const [revelarSelectFatReferencia, setRevelarSelectFatReferencia] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [dummy, setDummy] = useState()
    //OUTROS HOOKS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [mensagemAlerta, setMensagemAlerta] = useState('')
    const [mensagemSucesso, setMensagemSucesso] = useState('')
    const modal = useRef();
    const despacho = useDispatch();

    const cd_pav_usuario = decryptJWT('codigo')


    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function handleSalvar(){
        setSpinAtivo(true)
        
        api.post('/m1/cadastrar/fat_faturas_historico', {
            'cd_pav_usuario_created_at':cd_pav_usuario,
            "cd_pav_referencia":dsReferencia,
            "cd_pav_conta":"",
            "cd_pav_empresa":"",
            "arrayFaturas":"",
        })

        setSpinAtivo(false)
        setRevelarMensagemSucesso(true)
    //     .then(function (response) {
    //         setRevelarMensagemSucesso(true)
    //         setSpinAtivo(false)
    //     })
    //     .catch(function (error) {
    //         setRevelarMensagemErro(true)
    //         setSpinAtivo(false)
    //     });
    }

    function handleCancelar(){
        despacho(revelarRecalcularFaturaReferencia(false))
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
    }
    

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
            }
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                handleCancelar()
            }
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
        }
    }

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <div className="modal-editar-fatura fundo-cor-6">
            <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Recalculo por referência</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-editar-fatura-1">
                            <div className="coluna-cadastro-editar-fatura-3">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Referência</div>
                                    </Form.Label>
                                    { revelarSelectFatReferencia === false &&
                                        <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFatReferencia(!revelarSelectFatReferencia)}>
                                            <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsReferencia}</div>
                                            <img className={revelarSelectFatReferencia?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                         </Button>
                                    }
                                    { revelarSelectFatReferencia === true &&
                                        <SelectGenerico
                                            paramRota={"fat_recalcular_fatura_referencia"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT("fatContas")}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdReferencia}
                                            setDescricao1={setDsReferencia}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectFatReferencia}
                                            placeholder={dsReferencia}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Selecione uma referência.</Tooltip>}>
                            {cdReferencia ?
                                <Button className="botao-confirmar" variant="success" size="sm"  onClick={()=> handleSalvar()}>
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            :
                                <Button  className="botao-confirmar-desabilitado" variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            } 
                        </OverlayTrigger>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>

            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Recalculo em andamento"}
                            mensagemParagrafo1={"Esse processo pode levar alguns minutos, você receberá um aviso quando estiver pronto e poderá consultar suas faturas em breve"}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={mensagemAlerta}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}