//IMPORTAÇÕES
import React, {useState, useEffect, useRef} from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';
import {useForm} from 'react-hook-form';
import {Button, Form, Col, Tooltip, OverlayTrigger, Tabs, Tab} from 'react-bootstrap';
import {elipseVerde, fecharJanela, setaDropdown} from '../../../../utils/alternanciaIcones'
//MODAIS
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../../css/painelVirtueyes/usuario/estilo.css'

import {useDispatch, useSelector} from 'react-redux';
import {revelarEdicaoUsuario, revelarCadastrarUsuario} from '../../../../redux/actions/index';
import {MascaraCpf, MascaraTelefone} from '../../../../utils/mascarasCampos'
import decryptJWT from '../../../../utils/decryptJWT'
import MenuCadastroPermissoes from './permissoes'
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function CadastroPerfil(props) {
    const {register, handleSubmit} = useForm()
    const despacho = useDispatch()
    const modal = useRef()

    const [spinAtivo, setSpinAtivo] = useState(true)
    const [cdCliente, setCdCliente] = useState(undefined)
    const [dsCliente, setDsCliente] = useState('Selecione')
    const [cdPerfil, setCdPerfil] = useState(props.cdPerfil ? props.cdPerfil : undefined)
    const [dsPerfil, setDsPerfil] = useState(props.dsPerfil ? props.dsPerfil : 'Selecione')
    const [dsNome, setDsNome] = useState(props.dsNome ? props.dsNome : undefined)
    const [phNome, setPhNome] = useState(props.dsNome ? 'Original: '+props.dsNome : 'Insira o nome')
    const [dsSobrenome, setDsSobrenome] = useState(props.dsSobrenome ? props.dsSobrenome: undefined)
    const [phSobrenome, setPhSobrenome] = useState(props.dsSobrenome? 'Original: '+props.dsSobrenome : 'Insira sobrenome')
    const [dsCpf, setDsCpf] = useState(props.dsCpf ? MascaraCpf(props.dsCpf) : undefined)
    const [phCpf, setPhCpf] = useState(props.dsCpf? 'Original: '+MascaraCpf(props.dsCpf) : '000.000.000-00')
    const [dsTelefone, setDsTelefone] = useState(props.dsTelefone ? MascaraTelefone(props.dsTelefone) : undefined)
    const [phTelefone, setPhTelefone] = useState(props.dsTelefone ? 'Original: '+MascaraTelefone(props.dsTelefone) : '(00) 00000-0000')
    const [dsEmail, setDsEmail] = useState(props.dsEmail ? props.dsEmail : undefined)
    const [phEmail, setPhEmail] = useState(props.dsEmail ? 'Original: '+props.dsEmail : 'exemplo@email.com.br')
    const [flAtivo, setFlAtivo] = useState(undefined)
    const [dsSenha, setDsSenha] = useState('123456')
    const [dsConfirmacaoSenha, setDsConfirmacaoSenha] = useState('123456')
    const [permissoes, setPermissoes] = useState()
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [elementoAtivadoPrincipal, setElementoAtivadoPrincipal] = useState(1)
    const [tabAtivadoPrincipal, setTabAtivadoPrincipal] = useState(1)
    const [liberarCadastro, setLiberarCadastro] = useState(false)
    const [revelarSelectCliente, setRevelarSelectCliente] = useState(false)
    const [mensagemErro, setMensagemErro] = useState('')
    const [mensagemSucesso, setMensagemSucesso] = useState('')
    const [dummy, setDummy] = useState()
    const manejador = useSelector(state => state.manejadorState.manejador)
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    }

    useEffect(() => {
        if(props.flAtivo === 1){
            setFlAtivo(true)
        }else{
            setFlAtivo(false)
        }
    },[props.renderizar])

    useEffect(() => {
        setSpinAtivo(true)
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function handleCancelar() {
        ColetaClickstream(1263) // painelUsuariosDropAcoesCancelar
        setSpinAtivo(false)
        despacho(revelarCadastrarUsuario(false))
        despacho(revelarEdicaoUsuario(false))
    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if (confirmacao) {
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
        }
        if (confirmacao === true) {
            //ALGUMA FUNÇÃO
        }
    }

    function handerAlterarTabPrincipal(k){
        setElementoAtivadoPrincipal(k)
        setTabAtivadoPrincipal(k)
    }

    function handleCpf(valor){
        setDsCpf(MascaraCpf(valor))
    }

    function handleTelefone(valor){
        setDsTelefone(MascaraTelefone(valor))
    }
    
    async function handleCadastrar(){
        const mensagemErroSalvar = () => mostraErro('Algo deu errado, por favor tente mais tarde.')

        const mostraErro = (texto) => {
            setSpinAtivo(false)
            setMensagemErro(texto)
            handleMensagemErro(false)
        }

        ColetaClickstream(1264) // painelUsuariosDropAcoesCadastrar

        setSpinAtivo(true)
        let auxTelefone = ''

        if(dsTelefone){
            auxTelefone = dsTelefone.replaceAll("(", "")
            auxTelefone = auxTelefone.replaceAll(")", "")
            auxTelefone = auxTelefone.replaceAll("-", "")
            auxTelefone = auxTelefone.replaceAll(" ", "")
        }

        let auxCpf = ''
        if(dsCpf){
            auxCpf = dsCpf.replaceAll(".", "")
            auxCpf = auxCpf.replaceAll("-", "")
        }

        if(props.tituloJanela === 'Cadastrar'){
          const bodyCadastarUsuario = {
                "ds_pav_usuario": dsNome.trim(),
                "ds_pav_usuario_sobrenome": dsSobrenome ? dsSobrenome.trim() : '',
                "cd_pav_perfil": cdPerfil,
                "ds_cpf": auxCpf,
                "ds_email": dsEmail.trim(),
                "ds_telefone": auxTelefone,
                "fl_ativo": 1,
                "ds_senha": dsSenha,
                "cd_pav_cliente": cdCliente,
                "cd_pav_usuario_created": decryptJWT("codigo")
            }
            await api.post('/m1/cadastrar/usuario', bodyCadastarUsuario,{timeout:20000})
            .then(async function (response) {
                await api.post('/m1/cadastrar/permissao', {
                    'permissoes': permissoes,
                    'cd': 'perfil',
                    'id': cdPerfil
                },{timeout:20000})
                setMensagemSucesso('Usuario '+dsNome+' '+dsSobrenome+' cadastrado com sucesso utilizando a senha: '+dsSenha+'.')
                handleMensagemSucesso(false)
                setSpinAtivo(false)
            })
            .catch(function (error) {
                mensagemErroSalvar()
            });
        }else{
            const bodyAlterarUsuario = {
                "dados":{
                    "ds_pav_usuario": dsNome.trim(),
                    "ds_pav_usuario_sobrenome": dsSobrenome ? dsSobrenome.trim() : '',
                    "cd_pav_perfil": cdPerfil,
                    "ds_cpf": auxCpf,
                    "ds_email": dsEmail.trim(),
                    "ds_telefone": auxTelefone,
                    "fl_ativo": 1,
                    "ds_senha": dsSenha,
                },
                "condicao":{
                    "cd_pav_usuario": props.cdUsuario,
                    "cd_pav_pessoa": props.cdPessoa

                }
            }
            await api.post('/m1/alterar/usuario', bodyAlterarUsuario,{timeout:20000})
            .then(async function (response) {
                await api.post('/m1/cadastrar/permissao', {
                    'permissoes': permissoes,
                    'cd': 'perfil',
                    'id': cdPerfil
                },{timeout:20000})
                setMensagemSucesso('Usuario '+dsNome+' '+dsSobrenome+' alterado com sucesso utilizando a senha: '+dsSenha+'.')
                handleMensagemSucesso(false)
                setSpinAtivo(false)
            })
            .catch(function (error) {
                mensagemErroSalvar()
            });
        }
    }
    
    useEffect(() => {
        if(manejador === 'usuarioCliente'){
            setCdCliente(decryptJWT('codigoCliente'))
        }
        if(
            cdPerfil &&
            dsNome &&
            // dsSobrenome && dsCpf && dsTelefone && // campos opcionais
            dsEmail &&
            dsSenha === dsConfirmacaoSenha &&
            cdCliente
        ){
            setLiberarCadastro(true)
        }else{
            setLiberarCadastro(false)
        }
    }, [cdPerfil, cdCliente, dsNome, dsSobrenome, dsCpf, dsTelefone, dsEmail, dsSenha, dsConfirmacaoSenha])

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleCadastrar())}>
            {spinAtivo && <div className='componente-spinner-overlay'><div className='componente-spinner-container'><SpinerVeye/></div></div>}
            <div className='backoffice-modal-cadastro-usuario fundo-cor-6'>
                <Form.Row>
                    <Col>
                        <img className='icone-label-janela nao-selecionavel' src={elipseVerde()} alt='elipse'/>
                        <Form.Label>
                            <div className='fonte-cor-1 label-janela'>{props.tituloJanela} usuário</div>
                        </Form.Label>
                        <Button className='botao-fechar-janela' variant='danger' onClick={() => handleCancelar()}>
                            <img className='botao-fechar-janela-icone' src={fecharJanela()} alt='fechar-janela'/>
                        </Button>
                    </Col>
                </Form.Row>
                <Tabs activeKey={tabAtivadoPrincipal} onSelect={(k) => handerAlterarTabPrincipal(k)} class="backoffice-cadastro-usuario-tabs" id="uncontrolled-tab-example">
                    <Tab onClick={() => setTabAtivadoPrincipal("1")} tabClassName={elementoAtivadoPrincipal == "1"? "nao-selecionavel backoffice-cadastro-usuario-tab-ativado fonte-cor-1":"nao-selecionavel backoffice-cadastro-usuario-tab-desativado fonte-cor-1"} eventKey={"1"} title={"Dados do usuário"}>
                        { manejador !== 'usuarioCliente' &&                            
                            <Form.Row>
                                <Col>
                                    <Form.Group className='backoffice-cadastro-usuario-coluna-1'>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>Cliente *</div>
                                        </Form.Label>
                                        { revelarSelectCliente === false &&
                                            <Button className='campo-texto-cor-3 campo-select' onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className='fonte-cor-1 label-campo campo-texto-select-tamanho'>{dsCliente}</div>
                                                <img className={revelarSelectCliente?'campo-select-icone-ativado nao-selecionavel':'campo-select-icone-desativado nao-selecionavel'} src={setaDropdown()} alt='drop' />
                                            </Button>
                                        }
                                        { revelarSelectCliente === true &&
                                                <SelectGenerico
                                                    paramRota={'cliente'}
                                                    paramCampo1={null}
                                                    paramCampo2={null}
                                                    paramCampo3={null}
                                                    paramCampo4={null}
                                                    setCodigo={setCdCliente}
                                                    setDescricao1={setDsCliente}
                                                    setDescricao2={setDummy}
                                                    setDescricao3={setDummy}
                                                    setDescricao4={setDummy}
                                                    setRevelarSelect={setRevelarSelectCliente}
                                                    placeholder={dsCliente}
                                                    sufixo={''}
                                                >
                                                </SelectGenerico>
                                        }
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className='backoffice-cadastro-usuario-coluna-2'>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        }
                        <Form.Row>
                            <Col>
                                <Form.Group className='backoffice-cadastro-usuario-coluna-1'>
                                    <Form.Label>
                                        <div className='fonte-cor-1 label-campo'>Nome *</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o nome do usuário</Tooltip>}>
                                        <Form.Control
                                            value={dsNome}  
                                            className='campo-texto-cor-3 campo-texto' 
                                            type='text' 
                                            placeholder={phNome}
                                            onChange={e => setDsNome(e.target.value)} 
                                        />
                                    </OverlayTrigger>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='backoffice-cadastro-usuario-coluna-2'>
                                    <Form.Label>
                                        <div className='fonte-cor-1 label-campo'>Sobrenome</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o sobrenome do usuário</Tooltip>}>
                                        <Form.Control
                                            value={dsSobrenome}  
                                            className='campo-texto-cor-3 campo-texto' 
                                            type='text' 
                                            placeholder={phSobrenome}
                                            onChange={e => setDsSobrenome(e.target.value)} 
                                        />
                                    </OverlayTrigger>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group className='backoffice-cadastro-usuario-coluna-1'>
                                    <Form.Label>
                                        <div className='fonte-cor-1 label-campo'>CPF</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>O CPF deve possuir no mínimo 11 caracteres</Tooltip>}>
                                        <Form.Control
                                            value={dsCpf}  
                                            className='campo-texto-cor-3 campo-texto'  
                                            type='text' 
                                            placeholder={phCpf}
                                            onChange={e => handleCpf(e.target.value)}
                                            maxlength="14"
                                        />
                                    </OverlayTrigger>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='backoffice-cadastro-usuario-coluna-2'>
                                    <Form.Label>
                                        <div className='fonte-cor-1 label-campo'>Telefone</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o telefone</Tooltip>}>
                                        <Form.Control
                                            value={dsTelefone}  
                                            className='campo-texto-cor-3 campo-texto'  
                                            type='tel' 
                                            placeholder={phTelefone}
                                            onChange={e => handleTelefone(e.target.value)}
                                        />
                                    </OverlayTrigger>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group className='backoffice-cadastro-usuario-coluna-3'>
                                    <Form.Label>
                                        <div className='fonte-cor-1 label-campo'>E-mail *</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o e-mail</Tooltip>}>
                                        <Form.Control
                                            value={dsEmail}  
                                            className='campo-texto-cor-3 campo-texto'  
                                            type='email' 
                                            placeholder={phEmail}
                                            onChange={e => setDsEmail(e.target.value)}
                                            ref={register({validate: {minimo: value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) == true}})}
                                        />
                                    </OverlayTrigger>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group className='backoffice-cadastro-usuario-coluna-1'>
                                    <Form.Label>
                                        <div className='fonte-cor-1 label-campo'>Senha *</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Senhas com números, caracteres maiúsculos e minúsculos e simolos são mais seguras</Tooltip>}>
                                        <Form.Control
                                            value={dsSenha}  
                                            className='campo-texto-cor-3 campo-texto' 
                                            type='password' 
                                            placeholder='Insira uma senha'
                                            onChange={e => setDsSenha(e.target.value)} 
                                        />
                                    </OverlayTrigger>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='backoffice-cadastro-usuario-coluna-2'>
                                    <Form.Label>
                                        <div className='fonte-cor-1 label-campo'>Confirmar senha *</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Repita a senha inserida no campo anterior</Tooltip>}>
                                        <Form.Control
                                            value={dsConfirmacaoSenha}  
                                            className='campo-texto-cor-3 campo-texto' 
                                            type='password' 
                                            placeholder='Confirme a senha'
                                            onChange={e => setDsConfirmacaoSenha(e.target.value)} 
                                        />
                                    </OverlayTrigger>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Tab>
                    <Tab onClick={() => setTabAtivadoPrincipal("2")} tabClassName={elementoAtivadoPrincipal == "2"? "nao-selecionavel backoffice-cadastro-usuario-tab-ativado fonte-cor-1":"nao-selecionavel backoffice-cadastro-usuario-tab-desativado fonte-cor-1"} eventKey={"2"} title={"Permissões"}>
                        <MenuCadastroPermissoes setPermissoes={setPermissoes} setSpinAtivo={setSpinAtivo} setCdPerfil={setCdPerfil} cdPerfil={cdPerfil} setDsPerfil={setDsPerfil} dsPerfil={dsPerfil}/>
                    </Tab>
                </Tabs>

                <div style={{ color: '#D32F2F' }}>Campos obrigatórios *</div>

                <Form.Row>
                    <Col>
                        {liberarCadastro === false?
                            <Button  className={'botao-confirmar-desabilitado'} variant='success' size='sm'>
                                <div className='fonte-cor-4'>Cadastrar</div>
                            </Button>
                        :
                            <Button  type='submit' className={'botao-confirmar'} variant='success' size='sm'>
                                <div className='fonte-cor-4'>Cadastrar</div>
                            </Button>
                        }
                        <Button className='botao-cancelar' variant='success' size='sm' onClick={() => handleCancelar()}>
                            <div className='fonte-cor-2'>Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={'Sucesso'}
                            mensagemParagrafo1={mensagemSucesso}
                            mensagemBotao={'Continuar'}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={'Erro'}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={'Retornar'}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}