//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectMetodoSelecao from '../../componentesModais/selects/selectMetodoSelecao'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectMetodoSelecao, setRevelarSelectMetodoSelecao] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">MetodoSelecao {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectMetodoSelecao === false &&
                <Button name="cdMetodoSelecao" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectMetodoSelecao(!revelarSelectMetodoSelecao)}>
                    <div className="fonte-cor-1 label-campo">{dsMetodoSelecao}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectMetodoSelecao === true &&
                <SelectMetodoSelecao
                    setRevelarSelectMetodoSelecao={setRevelarSelectMetodoSelecao} //Hook para fechar a modal
                    setCdMetodoSelecao={setCdMetodoSelecao} //Código do MetodoSelecao
                    setDsMetodoSelecao={setDsMetodoSelecao} //Nome do MetodoSelecao
                    dsMetodoSelecao={dsMetodoSelecao} 
                    phMetodoSelecao={phMetodoSelecao} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectMetodoSelecao>
            }
        </Form.Group>*/

export default function SelectMetodoSelecao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleMetodoSelecao(metodoselecao, codigo){
        props.handleChangeMetodoSelecao(metodoselecao, codigo)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectMetodoSelecao(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal} >
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectMetodoSelecao(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsMetodoSelecao}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleMetodoSelecao('Geral', -1)}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Geral</span>
                        </div>
                        <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
                    </li>
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleMetodoSelecao('Login',1)}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Login</span>
                        </div>
                        <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
                    </li>
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleMetodoSelecao('Por cliente',2)}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Por cliente</span>
                        </div>
                        <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
                    </li>
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleMetodoSelecao('Por operadora',4)}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Por operadora</span>
                        </div>
                    </li>
                </ul>
            </div>
        </Form>
    )
}

