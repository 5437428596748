import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Paginador, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import "../../../css/painelDispositivos/dspSimcard/dspAcoes.css";
import * as S from "./detalhamentoStyles";
import lixeiraVermelho from "../../../icones/iconesVersaoVermelha/lixeira_vermelho.svg";
import ActionIcon from "../../Mantine/ActionIcon";
import ScrollArea from "../../Mantine/ScrollArea";

const TableprimaryKey = "cd_simcard";

export default function DetalhamentoProvisao({
  itens,
  showInvalidItens,
  removeBatch,
}) {
  const hasInvalidItens = showInvalidItens;
  const validColumn = [
    {
      dataField: "ds_iccid",
      text: "ICCID",
    },
    {
      dataField: "ds_dsp_operadora",
      text: "Operadora",
    },
    {
      dataField: "n0_plano",
      text: "Plano",
    },
    {
      dataField: "ds_simcard_tipo",
      text: "Tamanho",
    },
    {
      dataField: "remove",
      headerStyle: () => {
        return { width: "1px" };
      },
      formatter: (col, row) => {
        return (
          <ActionIcon
            alt="trash"
            variant="transparent"
            onClick={() => removeBatch(row.cd_dsp_whitechip)}
            icon={lixeiraVermelho}
          />
        );
      },
    },
  ];

  const invalidColumn = [
    {
      dataField: "iccid",
      text: "ICCID",
    },
    {
      dataField: "motivo",
      text: "Motivo",
    },
  ];

  const opcoesPaginacao = {
    showTotal: true,
    sizePerPage: itens.length,
    firstPageTitle: "Primeira",
    lastPageTitle: "Última",
    nextPageTitle: "Proxima",
    prePageTitle: "Anterior",
    custom: true,
  };

  return (
    <S.DetalhamentoProvisaoWrapper>
      <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
        {({ paginationProps, paginationTableProps }) => (
          <>
            <S.ContainerTable>
              <BootstrapTable
                classes={"tabela"}
                condensed={true}
                keyField={TableprimaryKey}
                data={itens}
                columns={hasInvalidItens ? invalidColumn : validColumn}
                bootstrap4={true}
                bordered={false}
                noDataIndication={"Nenhum item encontrado"}
                {...paginationTableProps}
              />
            </S.ContainerTable>
          </>
        )}
      </PaginationProvider>
      {!hasInvalidItens && (
        <div className="label-quantity">
          <span>
            {`${itens.length} ICCID${itens.length > 1 ? "s" : ""} selecionado${
              itens.length > 1 ? "s" : ""
            }`}
          </span>
        </div>
      )}
    </S.DetalhamentoProvisaoWrapper>
  );
}
