/*COMO USAR:
    #1 IMPORTE O COMPONENTE:
    import [NOME DO SELECT] from ('./componentes/componentensModais/selects/selectGenerico')

    #2 CRIE OS HOOKS PARA CONTROLAR O COMPONENTE:
    const [[GET REVELAR SELECT], [SET REVELAR SELECT]] = useState(false)

    #3 POSICIONE O COMPONENTE NO RETURN DA SUA FUNCAO
    <Form.Group>
        <Form.Label>
            <div className="fonte-cor-1 label-campo">[NOME DO CAMPO]</div>
        </Form.Label>´
        {[GET REVELAR SELECT] === false &&
            <Button className="campo-texto-cor-3 campo-select" onClick={() => [SET REVELAR SELECT](![GET REVELAR SELECT])}>
                <div className="fonte-cor-1 label-campo">{[VARIAVEL QUE RECEBEU A DESCRIÇÃO PRINCIPAL]}</div>
                <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
            </Button>
        }

        {[GET REVELAR SELECT] === true &&
            <[NOME DO SELECT]
                paramRota={[QUAL ROTA DO BACK VAI SER BUSCADA]}
                paramCampo1={[CAMPO PARA SER ADICIONADO AO WHERE]}
                paramCampo2={[CAMPO PARA SER ADICIONADO AO WHERE]}
                paramCampo3={[CAMPO PARA SER ADICIONADO AO WHERE]}
                paramCampo4={[CAMPO PARA SER ADICIONADO AO WHERE]}
                setCodigo={[VARIAVEL QUE VAI RECEBER O CÓDIGO]}
                setDescricao1={[VARIÁVEL QUE VAI RECEBER A DESCRIÇÃO PRINCIPAL]}
                setDescricao2={[VARIÁVEL QUE VAI RECEBER A DESCRIÇÃO SECUNDÁRIA]}
                setDescricao3={[VARIÁVEL QUE VAI RECEBER A DESCRIÇÃO SECUNDÁRIA]}
                setDescricao4={[VARIÁVEL QUE VAI RECEBER A DESCRIÇÃO SECUNDÁRIA]}
                setRevelarSelect={[SET REVELAR SELECT]}
                placeholder={[VARIÁVEL QUE RECEBEU A DESCRIÇÃO PRINCIPAL]}
                sufixo={[TEXTO PARA COMPLEMENTAR A DESCRIÇÃO PRINCIPAL E O PLACEHOLDER]}
            >
            </[NOME DO SELECT]>
        }
    </Form.Group>

    SE NECESSARIO CRIAR HOOCK const [dummy, setDummy] = useState(), E PASSAR O setDummy PARA AS setDescricao# QUE NAO SERAO USADOS
    SE NECESSARIO PASSAR null PARA OS paramCampo# QUE NAO SERAO USADOS
*/

import React, { useState, useEffect, useRef } from "react"; /*eslint-disable*/
import { api } from "../../../conexoes/api"; /*eslint-disable*/
import { Button, Form } from "react-bootstrap";

import SpinerVeye from "../../spinnerVeye/spinnerVeye.js";
import { setaDropdown } from "../../../utils/alternanciaIcones";

let temporizador = "";

export default function SelectGenerico(props) {
  const tipo = props.tipo || "autocomplete";

  const [spinAtivo, setSpinAtivo] = useState(true);
  const [lsOpcoes, setLsOpcoes] = useState([]);
  const [clickFora, setClickFora] = useState(false);
  const [paramBusca, setParamBusca] = useState("");

  const modal = useRef();

  const handleClickFora = (e) => {
    if (!modal.current.contains(e.target)) {
      handleCancelar();
    }
  };

  const handleClickDentro = () => setClickFora(false);

  function handleSelect(
    codigo,
    descricao1,
    descricao2,
    descricao3,
    descricao4
  ) {
    props.setCodigo(codigo);
    props.setDescricao1(descricao1 + props.sufixo);
    if (descricao2 != undefined && descricao2 != null) {
      props.setDescricao2(descricao2);
    } else {
      props.setDescricao2(undefined);
    }
    if (descricao3 != undefined && descricao3 != null) {
      props.setDescricao3(descricao3);
    } else {
      props.setDescricao3(undefined);
    }
    if (descricao4 != undefined && descricao4 != null) {
      props.setDescricao4(descricao4);
    } else {
      props.setDescricao4(undefined);
    }
    handleCancelar();
  }

  function handleCancelar() {
    props.setRevelarSelect(false);
  }

  useEffect(() => {
    setSpinAtivo(true);
    async function renderOpcoes() {
      let consulta;
      setLsOpcoes([]);
      if (paramBusca && props.dados) {
        const dados = props.dados.filter(
          (item) => item.descricao1.indexOf(paramBusca) > -1
        );
        consulta = { data: { dados } };
        const auxDadosFiltrados = consulta?.data?.dados;

        if (auxDadosFiltrados?.length > 0 && props.setDados) {
          props.setDadosFiltrados(auxDadosFiltrados);
        }
      } else if (props.css === "trial") {
        consulta = await api.post("/m1/consultar/fat_trial/fat_trial_select", {
          dados: {
            paramCampo1: props.paramCampo1,
            paramCampo2: props.paramCampo2,
            paramCampo3: props.paramCampo3,
            paramCampo4: props.paramCampo4,
            paramBusca: paramBusca,
          },
          condicao: {
            paramRota: props.paramRota,
          },
        });
      } else {
        consulta = await api.post("/m1/consultar/select", {
          dados: {
            paramCampo1: props.paramCampo1,
            paramCampo2: props.paramCampo2,
            paramCampo3: props.paramCampo3,
            paramCampo4: props.paramCampo4,
            paramBusca: paramBusca,
          },
          condicao: {
            paramRota: props.paramRota,
          },
        });

        if (consulta?.data?.dados?.length > 0 && props.setDados) {
          const auxDadosConsulta = consulta?.data?.dados;

          props.setDados(auxDadosConsulta);
          if (props.setDadosFiltrados)
            props.setDadosFiltrados(auxDadosConsulta);
        }
      }

      if (consulta?.data?.dados?.length > 0) {
        const auxDados = consulta?.data?.dados;
        let objetoConsulta = auxDados.map((item, i) => {
          return (
            <li key={"menu-" + item.codigo}>
              <div
                className={
                  props.css === "trial"
                    ? "campo-select-generico-opcao campo-select-opcao-4"
                    : props.rota === "filtroSimcard"
                    ? "campo-select-generico-opcao-b campo-select-opcao-b"
                    : "campo-select-generico-opcao campo-select-opcao-1"
                }
                onClick={() =>
                  handleSelect(
                    item.codigo,
                    item.descricao1,
                    item.descricao2,
                    item.descricao3,
                    item.descricao4
                  )
                }
              >
                <span
                  className={
                    props.css === "trial"
                      ? "fonte-cor-4 fonte-campo campo-select-opcao-label nao-selecionavel"
                      : "fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel"
                  }
                >
                  {item.descricao1 + props.sufixo}
                </span>
              </div>
              {auxDados[i + 1] != undefined && (
                <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
              )}
            </li>
          );
        });
        setLsOpcoes(objetoConsulta);
      } else {
        let objetoConsulta = () => {
          return (
            <li>
              <div
                disabled
                className={
                  props.css === "trial"
                    ? "campo-select-generico-opcao campo-select-opcao-4"
                    : props.rota === "filtroSimcard"
                    ? "campo-select-generico-opcao-b campo-select-opcao-b"
                    : "campo-select-generico-opcao campo-select-opcao-1"
                }
              >
                <span
                  className={
                    props.css === "trial"
                      ? "fonte-cor-4 fonte-campo campo-select-opcao-label nao-selecionavel"
                      : "fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel"
                  }
                >
                  Nenhuma informação encontrada
                </span>
              </div>
            </li>
          );
        };
        setLsOpcoes(objetoConsulta);
      }

      setSpinAtivo(false);
    }

    renderOpcoes();
    document.addEventListener("mousedown", handleClickFora);
    return () => document.removeEventListener("mousedown", handleClickFora);
  }, [paramBusca]);

  function handleBusca(valor) {
    clearTimeout(temporizador);
    temporizador = setTimeout(() => {
      if (valor.length > 1) {
        setParamBusca(valor);
      } else {
        setParamBusca("");
      }
    }, 2000);
  }

  return (
    <Form ref={modal}>
      {spinAtivo && props.rota && props.rota == "filtroSimcard" ? (
        <div className="componente-spinner-overlay-filtro-simcard">
          <div className="componente-spinner-container">
            <SpinerVeye />
          </div>
        </div>
      ) : spinAtivo && !props.rota ? (
        <div className="componente-spinner-overlay">
          <div className="componente-spinner-container">
            <SpinerVeye />
          </div>
        </div>
      ) : (
        ""
      )}
      {tipo === "autocomplete" ? (
        <>
          <Form.Control
            ref={(input) => input && input.focus()}
            name={props.paramRota}
            className={
              props.css === "trial"
                ? "campo-texto-cor-4 campo-select-generico-2"
                : props.rota === "filtroSimcard"
                ? "campo-texto-cor-3 campo-select-generico-b"
                : "campo-texto-cor-3 campo-select-generico"
            }
            placeholder={props.placeholder}
            onChange={(e) => handleBusca(e.target.value)}
          />
          <img
            className={
              props.css === "trial"
                ? "campo-select-generico-icone-ativado-2"
                : props.rota === "filtroSimcard"
                ? "campo-texto-cor-3 campo-select-generico-icone-ativado-b"
                : "campo-select-generico-icone-ativado"
            }
            src={setaDropdown()}
            alt="drop"
          />
        </>
      ) : (
        <Button
          className={
            props.rota && props.rota == "filtroSimcard"
              ? "campo-texto-cor-3 campo-select-filtro-marca"
              : "campo-texto-cor-3 campo-select"
          }
          onClick={() => props.setRevelarSelect(false)}
        >
          <div className="fonte-cor-1 label-campo-marca-2">
            {props.placeholder || "Selecione"}
          </div>
          <img
            className="campo-select-icone-ativado-filtro-b-2"
            src={setaDropdown()}
            alt="drop"
          />
        </Button>
      )}
      <div
        className={
          props.css === "trial"
            ? "campo-select-triangulo-cor-4 campo-select-triangulo"
            : props.rota === "filtroSimcard"
            ? "campo-select-triangulo-cor-1 campo-select-triangulo-2"
            : lsOpcoes.length &&
              "campo-select-triangulo-cor-1 campo-select-triangulo"
        }
      ></div>
      <div
        className={
          props.css === "trial"
            ? " campo-texto-cor-4 campo-select-corpo"
            : props.rota === "filtroSimcard"
            ? "campo-texto-cor-3 campo-select-corpo-2"
            : "campo-texto-cor-3 campo-select-corpo"
        }
      >
        <ul className="campo-select-lista">{lsOpcoes}</ul>
      </div>
    </Form>
  );
}
