import React from "react";

/* CALCULA A TOOLTIP (porcentagem) DE HOVER DOS GRAFICOS */
const TooltipGraficoPizza = ({ active, payload, arrDados, totalSimcards }) => {
    
    let percentual = null;

    if (totalSimcards === 0) {
        percentual = 100;
    }
    else if (active && payload?.length > 0) {
            percentual = `${parseFloat((payload[0].payload.value / totalSimcards) * 100).toFixed(2)}`
    }
    else if(arrDados?.length === 0) {
        percentual = 0;
    }

    if(percentual === null) return null;

    return (
        <div className="container-tooltip-grafico-pizza">
            <p className="label-tooltip-grafico-pizza">{percentual}%</p>
        </div>
    );
}

export default TooltipGraficoPizza;