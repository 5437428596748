import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import {Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import decryptJWT from '../../../../utils/decryptJWT'
// ICONES
import { elipseVerde, fecharJanela, exclamacao, setaDropdown } from '../../../../utils/alternanciaIcones'
// MODAIS
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import SelectTemplateSmsPermissao from '../../../componentesModais/selects/selectTemplateSmsPermissao'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch } from 'react-redux';
import {revelarNovoTemplateSms} from "../../../../redux/actions/index";

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
import utilsSms from '../../../../utils/sms';
import { setUncaughtExceptionCaptureCallback } from 'process';
let atualizarParent = false;

export default function NovoTemplate(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    // HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    const [dsMensagem, setDsMensagem] = useState('')
    const [dsNome, setDsNome] = useState('')
    const [cdTemplateSmsPermissao, setCdTemplateSmsPermissao] = useState('')
    const [dsTemplateSmsPermissao, setDsTemplateSmsPermissao] = useState('Selecione')
    const [phTemplateSmsPermissao, setPhTemplateSmsPermissao] = useState('Selecione')
    const [caracteresMensagem, setCaracteresMensagem] = useState(120);
    const [caracteresMensagemNome,setCaracteresMensagemNome] = useState(20);
    //HOOKS MODAIS
    const [revelarSelectTemplateSmsPermissao, setRevelarSelectTemplateSmsPermissao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const despacho = useDispatch();
    const modal = useRef();

    
    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);
    
    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function handleEnviar(){
        ColetaClickstream(1440) // SMSTemplateDropAcoesNovo
        setSpinAtivo(true)

        const bodyNovoTemplate = utilsSms.getNewBodyPost({ 
            "fl_acesso_geral": cdTemplateSmsPermissao,
            "ds_sms_template": dsNome,
            "ds_conteudo": dsMensagem,
        })

        await api.post('/m1/cadastrar/smsTemplate', bodyNovoTemplate)
        .then(function (response) {
            atualizarParent = true
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        })
    }

    async function handleCancelar(){
        if(atualizarParent === true){
            props.setRenderizar(!props.renderizar)
        }      
        despacho(revelarNovoTemplateSms(false))
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMotivo(motivo){
        setDsMensagem(motivo)
        setCaracteresMensagem(120 - motivo.length)
    }

    function handleMotivoNome(e){
        if(e.length <= 20)
        {
            setDsNome(e);
            setCaracteresMensagemNome(20 - e.length);
        } 
        if (e.length > 20)
        {
            setDsMensagem(e);
            setCaracteresMensagemNome(20 - e.length);
        } 
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleEnviar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="modal-template-sms fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Novo template</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-painel-sms-template-sms-1">
                            <div className="coluna-painel-sms-template-sms-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Nome</div>
                                    </Form.Label>
                                    <OverlayTrigger key="top" delay={{ show: 500, hide: 250 }}placement="top" overlay={<Tooltip id={`tooltip-top`}>Nome da template</Tooltip>}>
                                        <Form.Control name="ds_nome" value={dsNome} className="campo-texto-cor-3 campo-texto" type="text" placeholder="Digite aqui um nome" onChange={e => handleMotivoNome(e.target.value)} 
                                        ref={register({ required: true, min: 3, max: 20 })} maxLength="20" />
                                    </OverlayTrigger>
                                    <Form.Label className="contador-carateres-restantes">
                                         <div className="fonte-cor-1 label-caracteres-restantes">{caracteresMensagemNome} caracteres restantes</div>
                                     </Form.Label>
                                    {errors.ds_nome && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                                </Form.Group>
                            </div>
                            <div className="coluna-painel-sms-template-sms-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Permissão de uso</div>
                                    </Form.Label>
                                    { revelarSelectTemplateSmsPermissao === false &&
                                        <Button name="cdTemplateSmsPermissao" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTemplateSmsPermissao(!revelarSelectTemplateSmsPermissao)}>
                                            <div className="fonte-cor-1 label-campo">{dsTemplateSmsPermissao}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectTemplateSmsPermissao === true &&
                                        <SelectTemplateSmsPermissao
                                            setRevelarSelectTemplateSmsPermissao={setRevelarSelectTemplateSmsPermissao} //Hook para fechar a modal
                                            setCdTemplateSmsPermissao={setCdTemplateSmsPermissao} //Código do TemplateSmsPermissao
                                            setDsTemplateSmsPermissao={setDsTemplateSmsPermissao} //Nome do TemplateSmsPermissao
                                            dsTemplateSmsPermissao={dsTemplateSmsPermissao} 
                                            phTemplateSmsPermissao={phTemplateSmsPermissao} //Placeholder para mostrar o valor original em caso de edição
                                        >
                                        </SelectTemplateSmsPermissao>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Conteúdo</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Digite o conteúdo</Tooltip>}>
                                <Form.Control name="dsMensagem" value={dsMensagem} className="campo-texto-cor-3 campo-texto-area" as="textarea"
                                placeholder="Digite o conteúdo"
                                rows="3"  onChange={e => handleMotivo(e.target.value)}
                                ref={register({required: true})} maxLength="120"/>
                            </OverlayTrigger>
                            {errors.titulo && <p className="texto-campo-obrigatorio">{iconeAlerta} O campo é obrigatorio.</p>}
                            <Form.Label className="contador-carateres-restantes">
                                <div className="fonte-cor-1 label-normal">{caracteresMensagem} caracteres restantes</div>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Template cadastrado."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
