import { api, api_svsolicitacoes } from '../../../../conexoes/api';
import { limiteSolicitacoes } from '../../../../utils/solicitacoes';

export const getClientePermitidoCMP = (cdCliente) => cdCliente == 1 || cdCliente == 212 || cdCliente == 2533 || cdCliente == 2401;

export async function getDadosSimcardParqueTodoBody({
    routeM1FiltrarFatSimcard,
    conteudoBusca,
    filtros,
    buscaArquivo,
    separadorLinha,
    itensInvalidosBusca
}) {
    let tempSimcards = [];
    let tempLinhas = [];

    const urlGet = `${routeM1FiltrarFatSimcard}?acao=true&limit=${limiteSolicitacoes}&buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false&itensInvalidosBusca=${itensInvalidosBusca}`
    const consultaSimcards = await api.get(urlGet)
        .then(function (resposta) {
            return resposta.data;
        })
        .catch(function (erro) {
            throw erro;
        })

    if (consultaSimcards.dados.length > 0 && consultaSimcards.status === 200) {
        await Promise.all(
            consultaSimcards.dados.map((item) => {
                tempSimcards.push(item.CD_SIMCARD)
                tempLinhas.push(item.CALLERID)
                return true;
            })
        )
        return { tempSimcards, tempLinhas }
    } else {
        return { erro: "Erro na solicitação" }
    }
}

export async function getDadosFatSimcard({
    modoSelecaoSimcard, simcards, linhas,
    routeM1FiltrarFatSimcard,
    filtros, conteudoBusca, itensInvalidosBusca, separadorLinha, buscaArquivo
}) {
    let tempSimcards = simcards;
    let tempLinhas = linhas;

    if (modoSelecaoSimcard === true) {
        const consultaSim = await getDadosSimcardParqueTodoBody({
            routeM1FiltrarFatSimcard,
            conteudoBusca,
            filtros,
            buscaArquivo,
            separadorLinha,
            itensInvalidosBusca
        })
    
        return consultaSim;
    }
    else if (simcards.length && simcards[0].CD_SIMCARD){
        tempSimcards = []
        tempLinhas = []

        simcards.forEach(({CD_SIMCARD, CALLERID}) => {
            tempSimcards.push(CD_SIMCARD)
            tempLinhas.push(CALLERID)
        });
    }

    return { tempSimcards, tempLinhas }
}

function getStrOperadorasSemCMP(dadosCMP) {
    let strOp = '';
    const dadosOperadorasSemCMP = dadosCMP.filter(item => !item.fl_ativo);
    dadosOperadorasSemCMP.forEach((item, idx) => {
        const lastIdx = idx === (dadosOperadorasSemCMP.length-1);

        if(idx > 0) {
            strOp += lastIdx ? ' e ' : ', '
        }

        strOp += item.ds_dsp_operadora?.trim()
    })

    return strOp;
}

function getMensagemCMP(dadosCMP) {
    //  Mostrar mensagem quando habilitar botão e tiver alguma linha que nao permita CMP
    let texto = '';

    const strOp = getStrOperadorasSemCMP(dadosCMP);
    const qtdOperadorasSemCMP = dadosCMP.filter(item => !item.fl_ativo).length;
    
    if(qtdOperadorasSemCMP > 1) {
        texto += `As operadoras ${strOp} não possuem esta ação integrada com as operadoras. Ao concluir, as solicitações para estas operadoras serão criadas solicitações manuais.`
    }
    else {
        texto += `A operadora ${strOp} não possui esta ação integrada com as operadoras. Ao concluir, as solicitações para esta operadora será criada solicitação manual.`
    }

    return texto;
}

export async function verificaOperadorasCMP({ cd_sol_solicita_tipo, tempSimcards }) {
    const bodyX = {
        "solicitacaoTipo": cd_sol_solicita_tipo,
        "simcards": tempSimcards,
    }

    try {
        const dadosCMP = await api_svsolicitacoes.post('/api/v2/disponibilidadeCmp', bodyX)
        .then(res => res.data.data)
        // .catch(err => console.error(err));

        const temOperadoraCmp = !!dadosCMP.find(item => item.fl_ativo == 1); // ativar switch Integração
        const temOperadoraSemCmp = !!dadosCMP.find(item => item.fl_ativo != 1);

        let mensagem;
        if(temOperadoraCmp && temOperadoraSemCmp) {
            mensagem = getMensagemCMP(dadosCMP)
        }

        return {temOperadoraCmp, mensagem}
    }
    catch(erro) {
        return { erro }
    }
}