import React, { useState, useRef, useEffect } from 'react';/*eslint-disable*/
import { useSelector, useDispatch } from 'react-redux'
import {Button, Form, Col} from 'react-bootstrap';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/

import {api} from '../../conexoes/api';
import decryptJWT from '../../utils/decryptJWT'
import { validadorPermissaoElemento } from '../../utils/verificadorPermissaoElemento'
//ICONES
import {elipseVerde, fecharJanela} from '../../utils/alternanciaIcones';
//REDUX
import { revelarExibirColunas } from "../../redux/actions/index";
import '../../css/personalizarColunasFaturas/personalizarColunasFaturas.css'; // AQUI É DIFERENTE
import { alterarExibirColunas, getDadosColunas, getNomesCategoriasColunas, handlerAtualizarDados } from '../personalizarColunas/funcoesPersonalizarColunas';
import { objCategoriasKeys } from './dados';
import { ListaColunasCategoriaFatura, TituloBotaoColunas, TrianguloPersonalizar } from '../personalizarColunas/CardCategoriaColuna';
import { isPortal } from '../../utils/nivelCliente';

let categoriasColunas = [];
const nomesCategorias = getNomesCategoriasColunas(objCategoriasKeys);

nomesCategorias.map(key => categoriasColunas[key] = [] ); // iniciar categoriasColunas

const limpaCategorias = () => {
    Object.keys(categoriasColunas).map(key => categoriasColunas[key] = []) // esvazia todas as categorias
}

let itensAtivos = 0;
let flItensAtivos = false;

export default function PersonalizarColunas (props){


    const [arraySelecionado, setArraySelecionado] = useState('');
    const [itenSelecionado, setItenSelecionado] = useState('');
    const [colunas, setColunas] = useState(undefined)
    const [render, setRender] = useState(false)
    const despacho = useDispatch();
    const modal = useRef();
    const { handleSubmit } = useForm();
    const [categoria, setCategoria] = useState(0)
    const [permissaoFaturas, setPermissaoFaturas] = useState();

    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);

    const handleClickFora = e => {
        if (!modal?.current?.contains(e.target)) {
            handleCancelar(false)
        }
    };

    async function handleCancelar(valor) {
        if(valor === true){
            props.setRender(!props.render);
            props.setRenderizar(!props.renderizar);
        }
        despacho(revelarExibirColunas(false))
    }

    async function handleSalvar(){
        // await addData();
        alterarExibirColunas({ colunasFaturas: colunas })
        .then(function(resposta){
            handleCancelar(true)
        })
    }
    
    useEffect(() => {
        limpaCategorias();

        const handlerInserirDados = (colunas) => {
            const dados = getDadosColunas({colunas, itensAtivos, objCategoriasKeys, categoriasColunas });
            categoriasColunas = dados.categoriasColunas;
            setColunas(colunas)
        }

        async function handlerCarregarDados(){
            await api.get("/m1/servicos/token/buscar_colunas_faturas?email="+decryptJWT('email'))
            .then(function (response) {
                if(response.data.dados){
                handlerInserirDados(response.data.dados.colunasFaturas)
                }else{
                //   setLoginError(response.data.info);
                }
            })
            .catch(function (error) {
                
            });
        }
        handlerCarregarDados()
    }, []);

    useEffect(()=>{
        async function handlerPermissao(){
            setPermissaoFaturas(await validadorPermissaoElemento(permissaoElemento,"veye/dispositivos/colunasFaturas"))
        }
        handlerPermissao()
    },[permissaoElemento])

    useEffect(() => {
        async function altualizaDados() {
            if(colunas !==undefined){
                const resAtualizaDados = await handlerAtualizarDados({
                    colunas, itenSelecionado, setColunas, 
                    arraySelecionado, itensAtivos,
                    categorias: categoriasColunas
                })
    
                categoriasColunas = resAtualizaDados.categorias;
                itensAtivos = resAtualizaDados.itensAtivos;
            }
        }
        altualizaDados()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [render]);
    

    function handlerChangeSwitich(id,array){
        flItensAtivos = false
        setRender(!render)
        setItenSelecionado(id)
        setArraySelecionado(array)
    }

    const CardCategoria = ({ categoriaItem, titulo }) => {
        const itemSelecionado = categoria === categoriaItem;
        return (
            <>
                {itemSelecionado && <TrianguloPersonalizar />}
                <div
                    className={`container-switches-exibir-colunas-2 ${itemSelecionado ? 'fundo-cor-8' : ''}`}
                    onClick={() => setCategoria(categoriaItem)}
                >
                    <TituloBotaoColunas titulo={titulo} />
                </div>
            </> 
        )
    }

    const Faturas = categoriasColunas['Faturas']
    const Simcard = categoriasColunas['Simcard']
    const Conectividade = categoriasColunas['Conectividade']
    const Utilizacao = categoriasColunas['Utilizacao']
    const Contrato = categoriasColunas['Contrato']
    const Dispositivo = categoriasColunas['Dispositivo']
    // const Identificacao = categoriasColunas['Identificacao']
    const Cliente = categoriasColunas['Cliente']

    return (
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-exibir-colunas fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Exibir colunas faturas</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar(false)}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Label>
                            <div className="fonte-cor-1 label-titulo-colunas">Permissões de acesso de perfil</div>
                        </Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-exibir-colunas">
                            <div className="coluna-exibir-colunas-1 fundo-cor-5">
                                <CardCategoria titulo="Faturas" categoriaItem={1} />
                                <CardCategoria titulo="SIM card" categoriaItem={2} />
                                <CardCategoria titulo="Conectividade" categoriaItem={3} />
                                {Utilizacao?.length && <CardCategoria titulo="Utilização" categoriaItem={4} />}
                                <CardCategoria titulo="Contrato" categoriaItem={5} />
                                <CardCategoria titulo="Dispositivo" categoriaItem={6} />
                                {/* <CardCategoria titulo="Identificação" categoriaItem={?} /> */}

                                { !isPortal() &&
                                    <>
                                        <CardCategoria titulo="Cliente" categoriaItem={7} />
                                    </>
                                }
                            </div>

                            <div className="coluna-exibir-colunas-2 fundo-cor-8">
                                { categoria === 1 &&
                                    <ListaColunasCategoriaFatura
                                        listaCategoria={Faturas}
                                        categoriaKey="Faturas"
                                        handlerChangeSwitich={handlerChangeSwitich}
                                    />
                                }
                                { categoria === 2 &&
                                    <ListaColunasCategoriaFatura
                                        listaCategoria={Simcard}
                                        categoriaKey="Simcard"
                                        handlerChangeSwitich={handlerChangeSwitich}
                                    />
                                }
                                { categoria === 3 &&
                                    <ListaColunasCategoriaFatura
                                        listaCategoria={Conectividade}
                                        categoriaKey="Conectividade"
                                        handlerChangeSwitich={handlerChangeSwitich}
                                    />
                                }
                                { categoria === 4 &&
                                    <ListaColunasCategoriaFatura
                                        listaCategoria={Utilizacao}
                                        categoriaKey="Utilizacao"
                                        handlerChangeSwitich={handlerChangeSwitich}
                                    />
                                }
                                { categoria === 5 &&
                                    <ListaColunasCategoriaFatura
                                        listaCategoria={Contrato}
                                        categoriaKey="Contrato"
                                        handlerChangeSwitich={handlerChangeSwitich}
                                    />
                                }
                                { categoria === 6 &&
                                    <ListaColunasCategoriaFatura
                                        listaCategoria={Dispositivo}
                                        categoriaKey="Dispositivo"
                                        handlerChangeSwitich={handlerChangeSwitich}
                                    />
                                }
                                {/* { categoria === ? &&
                                    <ListaColunasCategoriaFatura
                                        listaCategoria={Identificacao}
                                        categoriaKey="Identificacao"
                                        handlerChangeSwitich={handlerChangeSwitich}
                                    />
                                } */}
                                { categoria === 7 &&
                                    <ListaColunasCategoriaFatura
                                        listaCategoria={Cliente}
                                        categoriaKey="Cliente"
                                        handlerChangeSwitich={handlerChangeSwitich}
                                    />
                                }
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row className="container-botoes-cancelar-confirmar">
                    <Col>
                        <>
                            <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                <div className="fonte-cor-4">Confirmar</div>
                            </Button>

                            <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar(false)}>
                                <div className="fonte-cor-2">Cancelar</div>
                            </Button>
                        </>
                    </Col>
                </Form.Row>
            </div>
        </Form>
    );
}