import * as S from "./styles";
import ellipseVerde from "../../../icones/iconesVersaoVerde/ellipse.svg";
import fechar from "../../../icones/iconesVersaoEscura/fechar_janela.svg";
import Botao from "../Botao";
import { Group } from "@mantine/core";

import { limparFiltr } from "../../../utils/alternanciaIcones";

export default function Modal({
  titulo,
  type = "default",
  children,
  size = "auto",
  width,
  minHeight,
  opened,
  close,
  onConfirm,
  handleCleanFilters,
  disableConfirm = false,
  withCloseButton = true,
  showActionsModal = true
}) {
  const isFiltro = type === "filtro";
  const isAlerta = type === "alerta";
  const isDefault = type === "default";
  const mostrarCloseButton = withCloseButton && !isFiltro && !isAlerta;

  return (
    <S.ModalMantine
      type={type}
      width={width}
      minHeight={minHeight}
      size={size}
      closeOnClickOutside={!isAlerta}
      opened={opened}
      onClose={close}
      withCloseButton={mostrarCloseButton}
      closeButtonProps={{
        children: (
          <img
            className="botao-fechar-janela-icone"
            src={fechar}
            alt="fechar-janela"
          />
        ),
      }}
      title={
        <S.TituloWrapper type={type}>
          {titulo && (
            <S.Titulo>
              <S.Elipse src={ellipseVerde} alt="elipse" />
              <S.TituloModal>{titulo}</S.TituloModal>
            </S.Titulo>
          )}

          {isFiltro ? (
            <S.BotoesFiltroWrapper>
              <Botao
                label={
                  <S.LimparFiltroLabelWrapper onClick={handleCleanFilters}>
                    <span>LIMPAR FILTROS</span>
                    <img src={limparFiltr()} alt="fechar-janela" />
                  </S.LimparFiltroLabelWrapper>
                }
                variant="limparFiltro"
              />
              <Botao label="Filtrar" variant="outline" onClick={onConfirm} />
              <Botao label="Voltar" variant="primary" onClick={close} />
            </S.BotoesFiltroWrapper>
          ) : null}
        </S.TituloWrapper>
      }
      centered
    >
      {children}

      {showActionsModal && isDefault && (
        <Group position="center">
          <Botao label="Cancelar" variant="outline" onClick={close} />
          <Botao
            label="Confirmar"
            variant="primary"
            disabled={disableConfirm}
            onClick={onConfirm}
          />
        </Group>
      )}
    </S.ModalMantine>
  );
}
