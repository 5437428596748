import React, { useEffect, useState, useRef } from 'react';/*eslint-disable*/
import { Carousel, Button, Form, Col } from 'react-bootstrap';/*eslint-disable*/
// IMPORTAÇÕES REDUX
import { useDispatch } from 'react-redux';
// PLAYER DE VIDEO
import ReactPlayer from 'react-player/youtube'

import {api} from '../../conexoes/api';/*eslint-disable*/
import { revelarTutorial, mudarManejador } from "../../redux/actions/index";
import decryptJWT from '../../utils/decryptJWT'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'
// ICONES
import { elipseVerde, fecharJanela, relogio } from '../../utils/alternanciaIcones'
// ESTILIZAÇÃO
import '../../css/painelTutoriais/painelTutoriais.css'

// SPINNER
import SpinerVeye from '../../components/spinnerVeye/spinnerVeye.js'

let contadorVideos = 0

export default function PainelTutoriais(props) {
    // HOOKS REDUX
    const despacho = useDispatch();
    // HOOKS SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    // HOOKS PRINCIPAIS
    const [carrosselVideos, setCarrosselVideos] = useState()
    const [numeroTutoriais, setNumeroTutoriais] = useState(0)
    // const [totalPaginasTutoriais, setTotalPaginasTutoriais] = useState(1)
    const modal = useRef();

    function handleCancelar(){
        despacho(revelarTutorial(false))
    }

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        setSpinAtivo(true)
        async function renderVideos(){
            const dadosVideos = await getVideos();
            
            // let {contadorVideos} = dadosVideos;
            const {arrayVideos} = dadosVideos;

            let objetoVideos = arrayVideos.map((grupoVideos) => {
                contadorVideos = 0
                return (
                    <Carousel.Item className="item-carrossel-tutoriais">
                        <div className="container-grupo-videos">
                            <Videos
                                funcaoFiltroByIdx={(i) => i < 3}
                                idxVideoClass={3}
                                grupoVideos={grupoVideos}
                            />
                        </div>
                        
                        <div className="container-grupo-videos-2">
                            <Videos
                                funcaoFiltroByIdx={(i) => i >= 3}
                                idxVideoClass={6}
                                grupoVideos={grupoVideos}
                            />
                        </div>
                    </Carousel.Item>
                );
            })
            setCarrosselVideos(<Carousel interval={null} className="carrossel-tutoriais">{objetoVideos}</Carousel>)
        }
        renderVideos()
        despacho(mudarManejador("painelTutoriais"));
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [numeroTutoriais])
  
    // function handlAlternarPaginasTutoriais(passo){
    //     if(passo === 'proximo'){
    //         if(numeroTutoriais === totalPaginasTutoriais){
    //             setNumeroTutoriais(0)
    //         }else{
    //             let numero = (numeroTutoriais + 1)
    //             setNumeroTutoriais(numero)
    //         }
    //     }else if(passo === 'anterior'){
    //         if(numeroTutoriais === 0){
    //             setNumeroTutoriais(totalPaginasTutoriais)
    //         }else{
    //             let numero = (numeroTutoriais - 1)
    //             setNumeroTutoriais(numero)
    //         }
    //     }
    // }

    return (
        <Form ref={modal} className="modal-acao-tutoriais fundo-cor-6">
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="tela-painel-tutoriais">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Tutoriais</div> 
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {/* <div className="wrapper-botoes-alternar-tutoriais"> */}
                            {carrosselVideos}
                        {/* </div> */}
                    </Col>
                </Form.Row>
            </div>
        </Form>
    );
}

function Videos({funcaoFiltroByIdx, idxVideoClass, grupoVideos}) {
    return (
    <>
        {grupoVideos.filter((grupoVideos, i) => funcaoFiltroByIdx(i)).map((videos) => {
            contadorVideos++
            const video = videos[0]
            return (
                <div className={contadorVideos === idxVideoClass ? "coluna-video-2": "coluna-video"}>
                    {/* <div className="container-titulo-video">
                        <img className="icone-titulo-video" src={logoVeye} alt="perfil" />
                        <span className="titulo-vp fonte-cor-1">{video.titulo}</p>
                    </div> */}
                    <div className="container-video">
                        <ReactPlayer url={video.url} width='100%' height='100%'/>
                    </div>
                    <div className="container-detalhes-video fundo-cor-5">

                        <div className="container-descricao-video">
                            <p className="titulo-video fonte-cor-1">{video.titulo}</p>
                            <span className="descricao-video fonte-cor-1">{video.descricao}</span>
                        </div>

                        <div className="divisora-descricao-video campo-select-divisor-cor-1"></div>

                        <div className="container-detalhes-extra-video">

                            <div className="container-uploader-video">
                                <div className="container-foto-uploader-video">
                                    <img className="foto-uploader-video" src="https://jazzaero.com.br/wp-content/uploads/2017/05/default-placeholder-profile-icon-avatar-gray-woman-90197997.jpg" alt="perfil" />
                                </div>
                                <div className="container-nome-cargo-apresentador-video">
                                    <div className="container-nome-apresentador-video">
                                        <span className="nome-apresentador-video fonte-cor-1">{video.apresentador}</span>
                                    </div>
                                    <div className="container-cargo-apresentador-video">
                                        <span className="cargo-apresentador-video fonte-cor-1">Cargo</span>
                                    </div>
                                </div>
                            </div>

                            <div className="container-relogio-tempo-video">
                                <div className="container-relogio-video">
                                    <img className="icone-relogio-video" src={relogio()} alt="tempo"/>
                                </div>
                                <div className="container-tempo-video">
                                    <span className="tempo-video fonte-cor-1">{video.tempo}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            );
        })}
    </>
    )
}

async function getVideos() {
    const clientePrimario = decryptJWT ('clientePrimario')

    const {data} = await api.get('/m1/consultar/tutorial?cd_cliente_primario='+clientePrimario);
    const listaVideos = JSON.parse(JSON.stringify(data.dados))
    let quantidadeVideos = listaVideos.length
    let arrayVideos = []
    let manejadorLabel = ""

    for(let y = 0; y < (quantidadeVideos / 6); y++){
        arrayVideos.push([])
        for(let x = 0; x < 6; x++){
            const videoAtual = listaVideos[contadorVideos]
            if(videoAtual != undefined){
                manejadorLabel = videoAtual.ds_titulo
                arrayVideos[y].push([{
                        "url": videoAtual.ds_url, 
                        "titulo": videoAtual.ds_titulo,
                        "descricao": videoAtual.ds_descricao,
                        "tempo": videoAtual.tm_tempo_video,
                        "apresentador": videoAtual.ds_nome_apresentador,
                    }
                ])
                contadorVideos++
            }
        }
    }

    return {
        contadorVideos,
        arrayVideos
    }
}