//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectModulo from '../../componentesModais/selects/selectModulo'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectModulo, setRevelarSelectModulo] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">Modulo {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectModulo === false &&
                <Button name="cdModulo" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectModulo(!revelarSelectModulo)}>
                    <div className="fonte-cor-1 label-campo">{dsModulo}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectModulo === true &&
                <SelectModulo
                    setRevelarSelectModulo={setRevelarSelectModulo} //Hook para fechar a modal
                    setCdModulo={setCdModulo} //Código do Modulo
                    setDsModulo={setDsModulo} //Nome do Modulo
                    dsModulo={dsModulo} 
                    phModulo={phModulo} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectModulo>
            }
        </Form.Group>*/

export default function SelectModulo(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [lsModulos, setLsModulos] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleModulo(codigo, modulo){
        props.setCdModulo(codigo)
        props.setDsModulo(modulo)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectModulo(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderModulos(){
            const {data} = await api.get('/m1/consultar/modulo');
            
            if(data.dados.length > 0){
                let objetoModulos = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.ds_pav_modulo}>
                            <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleModulo(item.cd_pav_modulo, item.ds_pav_modulo) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.ds_pav_modulo}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsModulos(objetoModulos)
            }else{
                let objetoModulos = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsModulos(objetoModulos)
            }
            setSpinAtivo(false)
        }
        renderModulos()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectModulo(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsModulo}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsModulos}
                </ul>
            </div>
        </Form>
    )
}