//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectRegraAutomacao from '../../componentesModais/selects/selectRegraAutomacao'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectRegraAutomacao, setRevelarSelectRegraAutomacao] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">RegraAutomacao {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectRegraAutomacao === false &&
                <Button name="cdRegraAutomacao" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectRegraAutomacao(!revelarSelectRegraAutomacao)}>
                    <div className="fonte-cor-1 label-campo">{dsRegraAutomacao}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectRegraAutomacao === true &&
                <SelectRegraAutomacao
                    setRevelarSelectRegraAutomacao={setRevelarSelectRegraAutomacao} //Hook para fechar a modal
                    setCdRegraAutomacao={setCdRegraAutomacao} //Código do RegraAutomacao
                    setDsRegraAutomacao={setDsRegraAutomacao} //Nome do RegraAutomacao
                    dsRegraAutomacao={dsRegraAutomacao} 
                    phRegraAutomacao={phRegraAutomacao} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectRegraAutomacao>
            }
        </Form.Group>*/

export default function SelectRegraAutomacao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [lsRegras, setLsRegras] = useState()
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleRegraAutomacao(codigo, nome, flag){
        props.setCdRegraAutomacao(codigo)
        props.setDsRegraAutomacao(nome)
        props.setFlRegraAutomacao(flag)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectRegraAutomacao(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderRegras(){
            const {data} = await api.get('/m1/consultar/aut_evento');
            
            if(data.dados.length > 0){
                let objetoRegras = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.ds_aut_evento}>
                            <div className="campo-select-opcao-2 campo-select-opcao-automacao campo-select-opcao-1" onClick={() => handleRegraAutomacao(item.cd_aut_evento, item.ds_aut_evento, item.fl_parametro) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.ds_aut_evento}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsRegras(objetoRegras)
            }else{
                let objetoRegras = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsRegras(objetoRegras)
            }
            setSpinAtivo(false)
        }
        renderRegras()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal} >
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectRegraAutomacao(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsRegraAutomacao}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsRegras}
                </ul>
            </div>
        </Form>
    )
}

