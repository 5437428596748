import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
import Calendario from '../calendario/calendario'
import moment from 'moment';
import decryptJWT from '../../utils/decryptJWT'
import {api} from '../../conexoes/api'

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem, maximoTagsExibidas} from '../../redux/actions'

export default function FiltrosPedidosSms(props) {
    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroFornecedor, setFiltroFornecedor] = useState('')
    const [filtroPermanenciaSemPrazo, setFiltroPermanenciaSemPrazo] = useState('')
    const [filtroQuantidadeValorPlanoMinimo, setFiltroQuantidadeValorPlanoMinimo] = useState('')
    const [filtroQuantidadeValorPlanoMaximo, setFiltroQuantidadeValorPlanoMaximo] = useState('')
    const [filtroQuantidadeSimcardsMinimo, setFiltroQuantidadeSimcardsMinimo] = useState('')
    const [filtroQuantidadeSimcardsMaximo, setFiltroQuantidadeSimcardsMaximo] = useState('')
    const [filtroQuantidadeTradeinMinimo, setFiltroQuantidadeTradeinMinimo] = useState('')
    const [filtroQuantidadeTradeinMaximo, setFiltroQuantidadeTradeinMaximo] = useState('')
    const [filtroDataInicio, setFiltroDataInicio] = useState('')
    const [filtroDataFinal, setFiltroDataFinal] = useState('')
    const [filtroDataEmissaoNfInicio, setFiltroDataEmissaoNfInicio] = useState('')
    const [filtroDataEmissaoNfFinal, setFiltroDataEmissaoNfFinal] = useState('')
    const [filtroDataAtivacaoInicio, setFiltroDataAtivacaoInicio] = useState('')
    const [filtroDataAtivacaoFinal, setFiltroDataAtivacaoFinal] = useState('')
    const [filtroDataPrazoPermanencia, setFiltroDataPrazoPermanencia] = useState('')
    const [tiposFornecedores, setTiposFornecedores] = useState([])

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    async function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setFiltroQuantidadeTradeinMinimo(filtros.QTD_TRADE_IN_MIN)
            setFiltroQuantidadeTradeinMaximo(filtros.QTD_TRADE_IN_MAX)
            setFiltroFornecedor(filtros.FORNECEDOR)
            setFiltroPermanenciaSemPrazo(filtros.PERMANENCIA_SEM_PRAZO)
            setFiltroQuantidadeSimcardsMinimo(filtros.QTD_SIMCARD_MIN)
            setFiltroQuantidadeSimcardsMaximo(filtros.QTD_SIMCARD_MAX)
            setFiltroQuantidadeValorPlanoMinimo(filtros.QTD_TRADE_IN_MIN)
            setFiltroQuantidadeValorPlanoMaximo(filtros.QTD_TRADE_IN_MAX)
            if(filtros.DT_PEDIDO_INICIO !='' && filtros.DT_PEDIDO_INICIO){
                let auxData = new Date(filtros.DT_PEDIDO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataInicio(auxData)
            }
            if(filtros.DT_PEDIDO_FIM != '' && filtros.DT_PEDIDO_FIM){
                let auxData = new Date(filtros.DT_PEDIDO_FIM)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataFinal(auxData)
            }

            if(filtros.DT_EMISSAO_NF_INICIO !='' && filtros.DT_EMISSAO_NF_INICIO){
                let auxData = new Date(filtros.DT_EMISSAO_NF_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataEmissaoNfInicio(auxData)
            }
            if(filtros.DT_EMISSAO_NF_FIM != '' && filtros.DT_EMISSAO_NF_FIM){
                let auxData = new Date(filtros.DT_EMISSAO_NF_FIM)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataEmissaoNfFinal(auxData)
            }

            if(filtros.DT_ATIVACAO_INICIO !='' && filtros.DT_ATIVACAO_INICIO){
                let auxData = new Date(filtros.DT_ATIVACAO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataAtivacaoInicio(auxData)
            }
            if(filtros.DT_ATIVACAO_FIM != '' && filtros.DT_ATIVACAO_FIM){
                let auxData = new Date(filtros.DT_ATIVACAO_FIM)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataAtivacaoFinal(auxData)
            }

            if(filtros.PERMANENCIA !='' && filtros.PERMANENCIA){
                let auxData = new Date(filtros.PERMANENCIA)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataPrazoPermanencia(auxData)
            }
        }
    }

    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    function handleFuncaoEstadosRedux(){
        if(filtroQuantidadeSimcardsMinimo !== '' && filtroQuantidadeSimcardsMaximo !== '' && filtroQuantidadeSimcardsMinimo && filtroQuantidadeSimcardsMaximo){
            arrayFiltrosSimcard.push("Quantidade de simcards entre: "+filtroQuantidadeSimcardsMinimo+" e "+filtroQuantidadeSimcardsMaximo+"?QTD_SIMCARD_MIN")
        }
        if(filtroQuantidadeTradeinMinimo !== '' && filtroQuantidadeTradeinMaximo !== '' && filtroQuantidadeTradeinMinimo && filtroQuantidadeTradeinMaximo){
            arrayFiltrosSimcard.push("Quantidade de trade-in entre: "+filtroQuantidadeTradeinMinimo+" e "+filtroQuantidadeTradeinMaximo+"?QTD_TRADE_IN_MIN")
        }
        if(filtroFornecedor !== '' && filtroFornecedor){
            arrayFiltrosSimcard.push("Fornecedor: "+filtroFornecedor+"?FORNECEDOR")
        }
        if(filtroPermanenciaSemPrazo !== '' && filtroPermanenciaSemPrazo){
            arrayFiltrosSimcard.push("Sem data de permanência"+"?PERMANENCIA_SEM_PRAZO")
        }
        if(filtroQuantidadeValorPlanoMinimo !== '' && filtroQuantidadeValorPlanoMaximo !== '' && filtroQuantidadeValorPlanoMinimo && filtroQuantidadeValorPlanoMaximo){
            arrayFiltrosSimcard.push("Quantidade  de SMS entre: "+filtroQuantidadeValorPlanoMinimo+" e "+filtroQuantidadeValorPlanoMaximo+"?VALOR_PLANO_MIN")
        }
        if(filtroDataInicio !== '' && filtroDataFinal !== '' && filtroDataInicio && filtroDataFinal){
            arrayFiltrosSimcard.push("Data entre: "+moment(filtroDataInicio).format("DD-MM-YYYY")+" e "+moment(filtroDataFinal).format("DD-MM-YYYY")+"?DT_PEDIDO_INICIO")
        }
        if(filtroDataEmissaoNfInicio !== '' && filtroDataEmissaoNfFinal !== '' && filtroDataEmissaoNfInicio && filtroDataEmissaoNfFinal){
            arrayFiltrosSimcard.push("Data de emissão da NF entre: "+moment(filtroDataEmissaoNfInicio).format("DD-MM-YYYY")+" e "+moment(filtroDataEmissaoNfFinal).format("DD-MM-YYYY")+"?DT_EMISSAO_NF_INICIO")
        }
        if(filtroDataAtivacaoInicio !== '' && filtroDataAtivacaoFinal !== '' && filtroDataAtivacaoInicio && filtroDataAtivacaoFinal){
            arrayFiltrosSimcard.push("Data de ativação entre: "+moment(filtroDataAtivacaoInicio).format("DD-MM-YYYY")+" e "+moment(filtroDataAtivacaoFinal).format("DD-MM-YYYY")+"?DT_ATIVACAO_INICIO")
        }
        if(filtroDataPrazoPermanencia !== '' && filtroDataPrazoPermanencia ){
            arrayFiltrosSimcard.push("Data de permanência: "+moment(filtroDataPrazoPermanencia).format("DD-MM-YYYY")+"?PERMANENCIA")
        }
        despacho(maximoTagsExibidas(props.setRevelarDropFiltros))
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        hendlerGetdadosFiltros()

        async function carregarFornecedor(){
            const { data } = await api.get('/m1/consultar/fat_fornecedor?cd_pav_usuario='+ decryptJWT('codigo'));
            if(data.status == 200){
                setTiposFornecedores(data.dados)
            }
        }
        carregarFornecedor()
    }, []);

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){

            if(filtroDataPrazoPermanencia =='') verificador.push(1)
            if(filtroDataAtivacaoFinal =='') verificador.push(1)
            if(filtroDataAtivacaoInicio =='') verificador.push(1)
            if(filtroDataEmissaoNfFinal =='') verificador.push(1)
            if(filtroDataEmissaoNfInicio =='') verificador.push(1)
            if(filtroQuantidadeTradeinMaximo =='') verificador.push(1)
            if(filtroQuantidadeTradeinMinimo =='') verificador.push(1)
            if(filtroFornecedor =='') verificador.push(1)
            if(filtroQuantidadeSimcardsMinimo =='') verificador.push(1)
            if(filtroQuantidadeSimcardsMaximo =='') verificador.push(1)
            if(filtroQuantidadeValorPlanoMinimo =='') verificador.push(1)
            if(filtroQuantidadeValorPlanoMaximo =='') verificador.push(1)
            if(filtroDataInicio =='') verificador.push(1)
            if(filtroDataFinal =='') verificador.push(1)
            if(filtroPermanenciaSemPrazo =='') verificador.push(1)
            
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 15) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroFornecedor('')
            setFiltroQuantidadeTradeinMaximo('')
            setFiltroQuantidadeTradeinMinimo('')
            setFiltroQuantidadeSimcardsMaximo('')
            setFiltroQuantidadeSimcardsMinimo('')
            setFiltroQuantidadeValorPlanoMinimo('')
            setFiltroQuantidadeValorPlanoMaximo('')
            setFiltroDataInicio('')
            setFiltroDataFinal('')
            setFiltroDataEmissaoNfFinal('')
            setFiltroDataEmissaoNfInicio('')
            setFiltroDataAtivacaoInicio('')
            setFiltroDataAtivacaoFinal('')
            setFiltroDataPrazoPermanencia('')
            setFiltroPermanenciaSemPrazo('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroDataEmissaoNfFinal, filtroDataEmissaoNfInicio, filtroQuantidadeTradeinMinimo, filtroQuantidadeTradeinMaximo, filtroDataAtivacaoFinal, 
        filtroDataAtivacaoInicio, filtroQuantidadeSimcardsMaximo,filtroQuantidadeSimcardsMinimo, filtroFornecedor ,filtroQuantidadeValorPlanoMinimo,
        filtroQuantidadeValorPlanoMaximo, filtroDataInicio,filtroDataFinal, filtroDataPrazoPermanencia, filtroPermanenciaSemPrazo, props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-data","imagem-filtro-data")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data do periodo </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-data"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DT_PEDIDO_INICIO" type="text" value={filtroDataInicio ? moment(filtroDataInicio).format("YYYY-MM-DD hh:mm:ss") : filtroDataInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataInicio} setData={setFiltroDataInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DT_PEDIDO_FIM" type="text" value={filtroDataFinal? moment(filtroDataFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataFinal} setData={setFiltroDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-fornecedor","imagem-filtro-fornecedor")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Fornecedor </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-fornecedor"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-fornecedor"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        {/* <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroFornecedor('Vivo')}>Vivo
                            <input defaultChecked={handlerCheck("FORNECEDOR","Vivo")} type="radio" id="FORNECEDOR_VIVO" name="FORNECEDOR" value='Vivo'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroFornecedor('Claro')}>Claro
                            <input defaultChecked={handlerCheck("FORNECEDOR","Claro")} type="radio" id="FORNECEDOR_CLARO" name="FORNECEDOR" value='Claro'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroFornecedor('Tim')}>Tim
                            <input defaultChecked={handlerCheck("FORNECEDOR","Tim")} type="radio" id="FORNECEDOR_TIM" name="FORNECEDOR" value='Tim'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroFornecedor('Algar')}>Algar
                            <input defaultChecked={handlerCheck("FORNECEDOR","Algar")} type="radio" id="FORNECEDOR_ALGAR" name="FORNECEDOR" value='Algar'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroFornecedor('Oi')}>Oi
                            <input defaultChecked={handlerCheck("FORNECEDOR","Oi")} type="radio" id="FORNECEDOR_OI" name="FORNECEDOR" value='Oi'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label> */}
                        {tiposFornecedores.map((item) => (
                            <>
                                <label className="label-radio-filtros fonte-cor-1" onChange={()=> setFiltroFornecedor(item.ds_fat_fornecedor)}>{item.ds_fat_fornecedor}
                                    <input defaultChecked={handlerCheck("FORNECEDOR",item.ds_fat_fornecedor)} type="radio" id={item.cd_fat_fornecedor} name="FORNECEDOR" value={item.ds_fat_fornecedor}/>
                                    <span className="checkbox-filtros campo-texto-cor-3"></span>
                                </label>
                                <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                            </>
                        ))}
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    {/* ------------------- */}
                    </div>
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-quantidade-sms","imagem-filtro-quantidade-sms")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Valor do plano </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-quantidade-sms"}  className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-quantidade-sms"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="VALOR_PLANO_MIN"
                                        className="filtros-texto-valor-mb-sem-label campo-texto-cor-3"
                                        type="text"
                                        maxLength="10"
                                        value={filtroQuantidadeValorPlanoMinimo}
                                        onChange={e => setFiltroQuantidadeValorPlanoMinimo(e.target.value)}
                                    />
                                    {/* <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label> */}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="VALOR_PLANO_MAX"
                                        className="filtros-texto-valor-mb-sem-label campo-texto-cor-3"
                                        type="text"
                                        maxLength="10"
                                        value={filtroQuantidadeValorPlanoMaximo}
                                        onChange={e => setFiltroQuantidadeValorPlanoMaximo(e.target.value)}
                                    />
                                    {/* <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-quantidade-simcards","imagem-filtro-quantidade-simcards")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Quantidade de simcards </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-quantidade-simcards"}  className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-quantidade-simcards"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="QTD_SIMCARD_MIN"
                                        className="filtros-texto-valor-mb-sem-label campo-texto-cor-3"
                                        type="text"
                                        maxLength="10"
                                        value={filtroQuantidadeSimcardsMinimo}
                                        onChange={e => setFiltroQuantidadeSimcardsMinimo(e.target.value)}
                                    />
                                    {/* <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label> */}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="QTD_SIMCARD_MAX"
                                        className="filtros-texto-valor-mb-sem-label campo-texto-cor-3"
                                        type="text"
                                        maxLength="10"
                                        value={filtroQuantidadeSimcardsMaximo}
                                        onChange={e => setFiltroQuantidadeSimcardsMaximo(e.target.value)}
                                    />
                                    {/* <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-quantidade-tradein","imagem-filtro-quantidade-tradein")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Quantidade de trade-in </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-quantidade-tradein"}  className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-quantidade-tradein"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="QTD_TRADE_IN_MIN"
                                        className="filtros-texto-valor-mb-sem-label campo-texto-cor-3"
                                        type="text"
                                        maxLength="10"
                                        value={filtroQuantidadeTradeinMinimo}
                                        onChange={e => setFiltroQuantidadeTradeinMinimo(e.target.value)}
                                    />
                                    {/* <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label> */}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="QTD_TRADE_IN_MAX"
                                        className="filtros-texto-valor-mb-sem-label campo-texto-cor-3"
                                        type="text"
                                        maxLength="10"
                                        value={filtroQuantidadeTradeinMaximo}
                                        onChange={e => setFiltroQuantidadeTradeinMaximo(e.target.value)}
                                    />
                                    {/* <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-data-emissao","imagem-filtro-data-emissao")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data emissão da NF </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-data-emissao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data-emissao"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DT_EMISSAO_NF_INICIO" type="text" value={filtroDataEmissaoNfInicio ? moment(filtroDataEmissaoNfInicio).format("YYYY-MM-DD hh:mm:ss") : filtroDataEmissaoNfInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataEmissaoNfInicio} setData={setFiltroDataEmissaoNfInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DT_EMISSAO_NF_FIM" type="text" value={filtroDataEmissaoNfFinal? moment(filtroDataEmissaoNfFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataEmissaoNfFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataEmissaoNfFinal} setData={setFiltroDataEmissaoNfFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-data-ativacao","imagem-filtro-data-ativacao")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data da ativação </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-data-ativacao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data-ativacao"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DT_ATIVACAO_INICIO" type="text" value={filtroDataAtivacaoInicio ? moment(filtroDataAtivacaoInicio).format("YYYY-MM-DD hh:mm:ss") : filtroDataAtivacaoInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataAtivacaoInicio} setData={setFiltroDataAtivacaoInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DT_ATIVACAO_FIM" type="text" value={filtroDataAtivacaoFinal? moment(filtroDataAtivacaoFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataAtivacaoFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataAtivacaoFinal} setData={setFiltroDataAtivacaoFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-data-permanencia","imagem-filtro-data-permanencia")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data da permanência </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-data-permanencia"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data-permanencia"} className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="PERMANENCIA" type="text" value={filtroDataPrazoPermanencia ? moment(filtroDataPrazoPermanencia).format("YYYY-MM-DD hh:mm:ss") : filtroDataPrazoPermanencia} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataPrazoPermanencia} setData={setFiltroDataPrazoPermanencia} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroPermanenciaSemPrazo('semPrazo')}>Sem prazo
                            <input defaultChecked={handlerCheck("PERMANENCIA_SEM_PRAZO","Sem prazo")} type="radio" id="SEM_PRAZO" name="PERMANENCIA_SEM_PRAZO" value='Sem prazo'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                    </div>



                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}