//IMPORTAÇÕES
import React, {useState, useEffect, useRef} from 'react';
import {api} from '../../../conexoes/api';
import {Form, Col, Button} from 'react-bootstrap';
import {formatarMilhar} from '../../../utils/formatarMilhar'
import {elipseVerde, fecharJanela, atualizarVerde, exportarDescricaoVerde} from '../../../utils/alternanciaIcones';
import ModalMotivo from './components/modalMotivo'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import TabelaGenerica from '../../componentesModais/tabela/tabela'
import BarraGenerica from '../../componentesModais/barras/barraGenerica'
import {useDispatch, connect} from 'react-redux';
import {revelarAbrirTelaConciliacao} from "../../../redux/actions/index";    
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
import decryptJWT from '../../../utils/decryptJWT'
import moment from 'moment'
import '../../../css/painelFaturas/fatConciliacao/conciliacao.css'
//VARIÁVEIS DE SESSÃO
let offset = 0
let itensValidos = false
let contagemConcluida = false
let cd_fat_conciliacao = false

/*eslint-disable*/
function ModalConciliacao(props){
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [itens, setItens] = useState("");
    const limitePagina = 50
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [ordenador, setOrdenador] = useState(false)
    const [renderizar, setRenderizar] = useState(false);
    const [dadosBreadcrumbs, setDadosBreadcrumbs] = useState([])
    const cdFaturaCabecalho = props.cdFatura
    const [revelarFormatos,setRevelarFormatos] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [revelarMensagemInformacaoConciliacao, setRevelarMensagemInformacaoConciliacao] = useState(false)
    const [textoMotivo,setTextoMotivo] = useState('')
    const [revelarMotivo,setRevelarMotivo] = useState(false)
    const despacho = useDispatch()
    const mensagemErro = "Ocorreu um erro ao executar esta ação";
    const modal = useRef();

    //HANDLE FUNCTIONS

    function handleClickFora(e) {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    }

    async function handleAtualizarConciliacao(){
        //  FAZ A ATUALIZAÇÃO DA CONCILIAÇÃO ATRAVÉS DE UMA PROCEDURE EM BASE
        api.post("/m1/cadastrar/cadastrar_gestao_de_contas_conciliacao", {
            'cd_fat_fatura': props.cdFatura,
            'cd_pav_usuario': decryptJWT("codigo")
        })

        itensValidos = false
        contagemConcluida = false

        handleCancelar()
    }

    function gerarRelatorios(formato){
        /** ENVIA OS DADOS PARA O BACK-END PARA GERAÇÃO DE RELATÓRIOS
         */
        /**
         * TODO
         * 
         * COMPONENTIZAR ESSA FUNÇÃO EM FORMA DE UTILS QUE RECEBE O BODY COMO PARAMETRO
         */
        const dados = {
            codigo_cliente: decryptJWT('codigoCliente'),
            codigo_usuario: decryptJWT('codigo'),
            descricao: 'Relatório conciliação',
            tipo_arquivo: formato,
            tipo_relatorio: 10,
            cd_fat_conciliacao: cd_fat_conciliacao
        }
        api.post('/m1/cadastrar/relatorios',dados)
        handleMensagemInformacao()
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
            }
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                handleMensagemInformacaoConciliacao()
            }
        }
    }

    function handleMensagemInformacaoConciliacao(confirmacao){
        if(revelarMensagemInformacaoConciliacao === false){
            setRevelarMensagemInformacaoConciliacao(true)
        }else{
            setRevelarMensagemInformacaoConciliacao(false)
            if(confirmacao === true){
                handleAtualizarConciliacao()
            }
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarFormatos(false)
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
        }
    }

    function handleExibirMotivo(motivo){
        setTextoMotivo(motivo)
        setRevelarMotivo(true)
    }

    function handleCancelar() {
        offset = 0
        contagemConcluida = false
        cd_fat_conciliacao = false
        setSpinAtivo(false)
        props.setRenderizar(!props.renderizar)
        setRenderizar(!renderizar)
        despacho(revelarAbrirTelaConciliacao(false))
    }
''
    //USE EFFECTS

    useEffect(() => {
        //ADICIONA A MODAL O EVENT LISTENER QUE VERIFICA CLICKS FORA DA MODAL E FECHA A MODAL
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, []);

    useEffect(()=>{
        setSpinAtivo(true)
        offset = (paginaAtual - 1) * limitePagina;
        async function carregarDadosTabela(){
            if(contagemConcluida === false){
                /** DURANTE A PRIMEIRA RENDERIZACAO DA MODAL, EXECUTA A CONTAGEM DOS ITENS
                 *  E CARREGA OS DADOS DA BARRA DO CABECALHO DA MODAL
                 */

                let cabecalho = await api.post("/m1/consultar/consultar_gestao_de_contas_conciliacao", {
                    'cd_fat_fatura': cdFaturaCabecalho
                })    
   
                const dadosCabecalho = cabecalho.data.dados;

                if(dadosCabecalho.length > 0){
                    const itemCabecalho = dadosCabecalho[0]
                    cd_fat_conciliacao = itemCabecalho.CD_CONCILIACAO;
                    let auxDiferenca = itemCabecalho.VALOR_CALCULADO - itemCabecalho.VALOR_IMPORTADO
                    setDadosBreadcrumbs([
                        {'titulo': 'Fatura', 'valor': itemCabecalho.CD_FATURA},
                        {'titulo': 'Conta', 'valor': itemCabecalho.DS_CONTA},
                        {'titulo': 'Referência', 'valor': itemCabecalho.MES_REFERENCIA},
                        {'titulo': 'Início', 'valor': moment(itemCabecalho.INICIO_CICLO).format('DD/MM/YYYY')},
                        {'titulo': 'Fim', 'valor': moment(itemCabecalho.FIM_CICLO).format('DD/MM/YYYY')},
                        {'titulo': 'Valor previsto: R$ ', 'valor': formatarMilhar(itemCabecalho.VALOR_CALCULADO)},
                        {'titulo': 'Valor cobrado: R$ ', 'valor': formatarMilhar(itemCabecalho.VALOR_IMPORTADO)},
                        {'titulo': 'Valor diferença: R$ ', 'valor': formatarMilhar(auxDiferenca)}
                    ])
                }

                let contagem = await api.post('/m1/consultar/consultar_gestao_de_contas_conciliacao_recursos',{
                    'cd_fat_conciliacao': cd_fat_conciliacao
                })
                const res = contagem.data.dados
                itensValidos = res[0]?.CDS_CONCILIACAO_RECURSO?.split(',')
                contagemConcluida = true
            }
            
            if(itensValidos?.length){
                let itensValidosAux = []

                itensValidosAux = [...itensValidos]
                const itensValidosBody = itensValidosAux.slice(offset, (offset+limitePagina));

                let resposta = await api.post('/m1/consultar/consultar_gestao_de_contas_conciliacao_recursos',{
                    'cd_fat_conciliacao': cd_fat_conciliacao,
                    "itensValidos": itensValidosBody,
                    "ordenador":ordenador,
                    "limit": limitePagina,
                    "offset": offset
                })
                setItens(resposta.data.dados)
                setSpinAtivo(false)
            }
            else {
                setSpinAtivo(false)
                handleMensagemInformacaoConciliacao()
            }
        }
        carregarDadosTabela()
    },[paginaAtual, itensValidos?.length, renderizar, ordenador])
       
    return(        
        <Form ref={modal}>
            <div className="modal-acao-conciliacao fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 espacador_5 label-janela">Conciliação</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                { dadosBreadcrumbs != '' && dadosBreadcrumbs != undefined && dadosBreadcrumbs != null &&
                    <BarraGenerica
                        dados={dadosBreadcrumbs}
                    />
                }
                { itens != '' && itens != undefined && itens != null &&
                    <TabelaGenerica
                        tabela={'modalGestaoDeContasFaturasConciliacao'}
                        ordenador={ordenador}
                        setOrdenador={setOrdenador}
                        itens={itens}
                        paginaAtual={paginaAtual}
                        setPaginaAtual={setPaginaAtual}
                        limitePagina={limitePagina}
                        totalItens={itensValidos?.length || 0}
                        setItemLink={handleExibirMotivo}
                    />
                }          
                <div className="container-botao-modal-conciliacao">
                    <Button className="botao-atualizar fundo-cor-12" variant="success" size="sm" onClick={()=>handleMensagemAtencao()}>
                        <div className="texto-botao fonte-cor-1">Atualizar</div>
                        <img src={atualizarVerde()} alt='atualizar'></img>
                    </Button>
                    <div className="container-botao-relatorios-modal-conciliacao">
                        <Button className="botao-relatorio fundo-cor-12" variant="success" size="sm" onClick={()=>setRevelarFormatos(!revelarFormatos)}>
                            <div className="texto-botao fonte-cor-1">Relatório</div>
                            <img src={exportarDescricaoVerde()} alt='atualizar'></img>
                        </Button>
                        {revelarFormatos === true &&
                            <div className="container-drop-relatorio-conciliacao">                            
                                <div className="triangulo-dropdown-configuracao-cabecalho-sistema triangulo-dropdown-relatorios-conciliacao"></div>
                                <div className="drop-relatorio-conciliacao drop-relatorio-conciliacao-hover fonte-cor-1 drop-relatorio-conciliacao-top" onClick={()=>gerarRelatorios("CSV")}> CSV </div>
                                <div className="campo-select-divisor-cor-1 drop-relatorio-conciliacao-divisor"></div>
                                <div className="drop-relatorio-conciliacao drop-relatorio-conciliacao-hover fonte-cor-1"onClick={()=>gerarRelatorios("XLS")}> XLS </div>
                                <div className="campo-select-divisor-cor-1 drop-relatorio-conciliacao-divisor"></div>
                                <div className="drop-relatorio-conciliacao drop-relatorio-conciliacao-hover fonte-cor-1 drop-relatorio-conciliacao-bottom"onClick={()=>gerarRelatorios("XLSX")}> XLSX </div>
                            </div>
                        } 
                    </div>          
                </div>
                { revelarMensagemAtencao &&
                    <MensagemAtencao 
                        handleMensagemAtencao={handleMensagemAtencao}
                        mensagemTitulo={"Tem certeza?"}
                        mensagemParagrafo1={"Esta ação irá"}
                        mensagemParagrafo2={"atualizar os dados desta"}
                        mensagemParagrafo3={"conciliação"}
                        mensagemBotao={"Confirmar"} 
                    />
                }
                { revelarMensagemInformacao &&
                    <MensagemInformacao 
                        handleMensagemInformacao={handleMensagemInformacao}
                        mensagemTitulo={"O arquivo está sendo gerado"}
                        mensagemParagrafo1={"Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível"}
                        mensagemBotao={"Voltar"} 
                    />
                }
                { revelarMensagemInformacaoConciliacao &&
                    <MensagemInformacao 
                        handleMensagemInformacao={handleMensagemInformacaoConciliacao}
                        mensagemTitulo={"Conciliação em andamento"}
                        mensagemParagrafo1={"Esse processo pode levar alguns minutos, você receberá um aviso quando estiver pronto e poderá consultar sua conciliação em breve"}
                        mensagemBotao={"Confirmar"} 
                    />
                }
                { revelarMensagemSucesso &&
                    <MensagemSucesso
                        handleMensagemSucesso={handleMensagemSucesso}
                        mensagemTitulo={"Sucesso"}
                        mensagemParagrafo1={"Conciliação atualizada."}
                        mensagemBotao={"Continuar"}
                    />
                }
                { revelarMensagemErro &&
                    <MensagemErro
                        handleMensagemErro={handleMensagemErro}
                        mensagemTitulo={mensagemErro}
                        mensagemParagrafo1={"Erro"}
                        mensagemParagrafo2={mensagemErro}
                        mensagemBotao={"Retornar"}
                    />
                }
                { revelarMotivo &&
                    <div className="componente-modal-overlay">
                        <div className="componente-modal">
                            <ModalMotivo
                                setRevelarMotivo={setRevelarMotivo}
                                motivo={textoMotivo}
                            />
                        </div>
                    </div>
                }
            </div>
        </Form>
    )
}

function mapStateToProps(state) {
    return {
        conteudoBusca: state.manejadorState.conteudoBusca,
        filtros: state.filtrosState.filtros,
        visualizarDadosRefreshVar: state.virtueyesState.visualizarDadosRefresh
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(ModalConciliacao));