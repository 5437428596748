import React, { useState, useEffect } from "react";/*eslint-disable*/
import { rotinas_Telecall} from '../../../conexoes/api'/*eslint-disable*/
import {setaDropdown} from '../../../utils/alternanciaIcones'
import decryptJWT from "../../../utils/decryptJWT";
import { Button, Form } from 'react-bootstrap';
import SelectInput from "../../componentesModais/selects/selectComOpcoesPreConfiguradas";
import { apiVersions, getRotaIotTelecom, iotTelecomOptions } from "./utils";
import ModalImportarArquivoIot from "./upload/modalImportarArquivo";
import DivAtivacao from "./divAtivacao";

export default function IotTelecom(props) { // Ativação Simcard, Bloqueio e Desbloqueio temporário, Cancelamento, Realocação e Reciclagem simcard
    const [revelarSelectVersaoApi, setRevelarSelectVersaoApi] = useState(false)
    const [dsVersaoApi, setDsVersaoApi] = useState('Selecione');

    const [revelarSelectAcaoTelecall, setRevelarSelectAcaoTelecall] = useState(false)
    const [dsAcaoTelecall, setDsAcaoTelecall] = useState('Selecione');

    const [fileColumnIsLinha, setFileColumnIsLinha] = useState(false)

    const [objProdutoTelecall, setDsProdutoTelecall] = useState('Selecione');
    const [dsPerfilHlr, setDsPerfilHlr] = useState('Selecione');
    
    const [entradaSmsBloqueada,setEntradaSmsBloqueada] = useState(false)
    const [saidaSmsBloqueada,setSaidaSmsBloqueada] = useState(false)
    const [servicoGprsBloqueado,setServicoGprsBloqueado] = useState(false)
    const [chamadaRecebidaBloqueada,setChamadaRecebidaBloqueada] = useState(false)
    const [chamadaRecebidaEmRoamingBloqueada,setChamadaRecebidaEmRoamingBloqueada] = useState(false)
    const [chamadaEfetuadasBloqueada,setChamadaEfetuadasBloqueada] = useState(false)
    const [chamadaInternacionalBloqueada,setChamadaInternacionalBloqueada] = useState(false)
    const [chamadaInternacionalEmRoamingBloqueada,setChamadaInternacionalEmRoamingBloqueada] = useState(false)
    const [todasChamadasEmRoamingBloqueadas,setTodasChamadasEmRoamingBloqueadas] = useState(false)
    const [bloquearLinha,setBloquearLinha] = useState(false)
    const [dadosImportados,setDadosImportados] = useState([])

    const services = {
        setEntradaSmsBloqueada,
        setSaidaSmsBloqueada,
        setServicoGprsBloqueado,
        setChamadaRecebidaBloqueada,
        setChamadaRecebidaEmRoamingBloqueada,
        setChamadaEfetuadasBloqueada,
        setChamadaInternacionalBloqueada,
        setChamadaInternacionalEmRoamingBloqueada,
        setTodasChamadasEmRoamingBloqueadas,
        setBloquearLinha,
    }

    useEffect(() => {
        if(dsAcaoTelecall === 'Ativação' || dsAcaoTelecall === 'Reciclagem') {
            if(fileColumnIsLinha) setFileColumnIsLinha(false);
        } else if(dsAcaoTelecall !== 'Selecione'){
            if(!fileColumnIsLinha) setFileColumnIsLinha(true);
        }
    }, [dsAcaoTelecall])

    function getDescTipo(dsAcaoTelecall) {
        switch(dsAcaoTelecall) {
            case 'Bloq. Temp.':
                return 'Bloqueio Temporário'
            case 'Desbloq. Temp.':
                return 'Desbloqueio Temporário'
            case 'Cancelamento':
                return 'Cancelamento'
            default:
                return dsAcaoTelecall
        }
    }

    function BeforeDivImport(props) {
        const { revelarSelectAcaoTelecall, dsAcaoTelecall, iotTelecomOptions } = props;

        return (
            <div>
            <Form.Group>
                <Form.Label>
                    <div className="fonte-cor-1 label-campo">Core</div>
                </Form.Label>
                { !revelarSelectVersaoApi ?
                    <Button name="cdAcaoTelecall" className="campo-texto-cor-3 campo-select" 
                    onClick={() => setRevelarSelectVersaoApi(!revelarSelectVersaoApi)}
                    >
                        <div className="fonte-cor-1 label-campo">{dsVersaoApi}</div>
                        <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                    </Button>
                :
                    <SelectInput
                        setRevelarSelect={setRevelarSelectVersaoApi}
                        setDs={setDsVersaoApi}
                        ds={dsVersaoApi}
                        options={apiVersions}
                    >
                    </SelectInput>
                }
            </Form.Group>

            <Form.Group>
                <Form.Label>
                    <div className="fonte-cor-1 label-campo">Qual a ação necessária?</div>
                </Form.Label>
                { !revelarSelectAcaoTelecall ?
                    <Button name="cdAcaoTelecall" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectAcaoTelecall(!revelarSelectAcaoTelecall)}>
                        <div className="fonte-cor-1 label-campo">{dsAcaoTelecall}</div>
                        <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                    </Button>
                :
                    <SelectInput
                        setRevelarSelect={setRevelarSelectAcaoTelecall} //Hook para fechar a modal
                        setDs={setDsAcaoTelecall} //Nome do AcaoTelecall
                        ds={dsAcaoTelecall}
                        options={iotTelecomOptions}
                    >
                    </SelectInput>
                }
            </Form.Group>
        </div>
        )
    }

    // ----- template ---------------------------------------------
    const templateArquivoAtivacao = { // usado também para reciclagem
        "XLS": [["ICCID"],["'"]], // Coluna da planilha
        // "CSV": [{"ICCID": "'"}]
    }

    const templateArquivoBloqueio = {
        "XLS": [["LINHA"],["'"]],
        // "CSV": [{"LINHA": "'"}]
    }

    const templateArquivoRealocacao = {
        "XLS": [["ICCID_ANTIGO", "LINHA", "ICCID_NOVO"],["'", "'", "'"]],
        // "CSV": [{"ICCID_ANTIGO": "'", "LINHA": "'", "ICCID_NOVO": "'"}]
    }

    const getTemplate = (auxFileColumnIsLinha) => {
        if(auxFileColumnIsLinha) {
            if(dsAcaoTelecall === 'Realocação') {
                return templateArquivoRealocacao;
            } else {
                return templateArquivoBloqueio;
            }
        } else {
            return templateArquivoAtivacao;
        }
    }
    // ----- fim template ---------------------------------------------

    async function validarValores(){ 
        let arrayServicos = []
        entradaSmsBloqueada == true && arrayServicos.push({ "attribute": "0","code": "TS21"})
        saidaSmsBloqueada == true && arrayServicos.push({ "attribute": "0","code": "TS22"})
        servicoGprsBloqueado == true && arrayServicos.push({ "attribute": "1","code": "NAM"})
        chamadaRecebidaBloqueada == true && arrayServicos.push({ "attribute": "1","code": "OBI"})
        chamadaRecebidaEmRoamingBloqueada == true && arrayServicos.push({ "attribute": "2","code": "OBI"})
        chamadaEfetuadasBloqueada == true && arrayServicos.push({ "attribute": "1","code": "OBO"})
        chamadaInternacionalBloqueada == true && arrayServicos.push({ "attribute": "2","code": "OBO"})
        chamadaInternacionalEmRoamingBloqueada == true && arrayServicos.push({ "attribute": "3","code": "OBO"})
        todasChamadasEmRoamingBloqueadas == true && arrayServicos.push({ "attribute": "4","code": "OBO"})

        return arrayServicos
    }

    async function enviarAcaoTelecall() {
        let rota, body;
        const cd_pav_usuario = decryptJWT('codigo')

        let retornoServicos = await validarValores()
        // let retornoServicos = await props.validarValores()

        const bodyAtivacao = {
            "data": {
                cd_pav_usuario,
                produto: objProdutoTelecall,
                "hlr_profile": dsPerfilHlr,
                "iccids": dadosImportados,
                "services": retornoServicos,
                "freeze": bloquearLinha
            }
        }

        const bodyLinhas = {
            "data": {
                cd_pav_usuario,
                "linhas": dadosImportados,
            }
        }

        rota = getRotaIotTelecom(dsAcaoTelecall);

        if(rota === 'ativacao') {
            body = bodyAtivacao;
        } else {
            body = bodyLinhas
        }

        body.data.core = dsVersaoApi;

        await rotinas_Telecall.post('/servico/simcard/IOT_Telecom/'+rota, body)
    }

    return (
        <ModalImportarArquivoIot 
            {...props}
            tituloModal={'IOT TELECOM'} // Ativação de simcard
            BeforeDivImport={<BeforeDivImport
                {...{revelarSelectAcaoTelecall, dsAcaoTelecall, iotTelecomOptions }} 
            />}
            fileColumnIsLinha={fileColumnIsLinha}
            template={getTemplate(fileColumnIsLinha)}
            showDivImportarArquivo={(dsAcaoTelecall !== 'Selecione')}
            AfterDivImport={
                (dsAcaoTelecall === 'Ativação') ?
                    <DivAtivacao {...{
                        dsVersaoApi,
                        setDsProdutoTelecall,
                        dsPerfilHlr,
                        setDsPerfilHlr,
                        services
                    }}/> 
                :
                null
            }
            setDadosImportados={setDadosImportados}
            podeConfirmar={
                (dsVersaoApi && dsVersaoApi != 'Selecione') && 
                (dsAcaoTelecall !== 'Ativação' || (dsPerfilHlr !== 'Selecione' && dsAcaoTelecall !== 'Selecione' && objProdutoTelecall !== 'Selecione'))
            }
            validarValores={validarValores}
            salvar={enviarAcaoTelecall}
            textoMsgSucesso={`Sua solicitação de ${getDescTipo(dsAcaoTelecall)} foi enviada.`}
        />
    )
}