//IMPORTAÇÕES
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import { EstilosGlobais } from "./css/global";
import { claroTema, escuroTema } from "./css/temas";
import { isPortal } from "./utils/nivelCliente.js";
import Routers from "./routers/index.jsx";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function App() {
  const portalCliente = isPortal();
  //HOOKS

  // armazena o valor do tema (claro ou algarClaro) ou (escuro ou algarEscuro)
  // que será usado para definir com qual tema será iniciado o sistema
  // OBS.: Os temas algarClaro e algarEscuro, parecem estar em desuso, mas foi mantido
  // como item verificador para o caso de por alguma ocasião, vir com este valor.
  const localTema = localStorage.getItem("tema");
  let temaAtual = localTema || "claro";
  const isEscuro = temaAtual === "escuro";
  // Função que retorna os valores de coresTema, de acordo com o valor de @localTema
  const corTema = () => {
    if (isEscuro) return escuroTema;
    else return claroTema;
  };

  const [coresTema, setCoresTema] = useState(corTema());
  const [modoTema, setModoTema] = useState(false);
  const [buscarTema, setBuscarTema] = useState(false);
  const [flChat, setFlChat] = useState(true);

  useEffect(() => {
    setCoresTema(corTema());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buscarTema, modoTema]);

  const originalSetItem = localStorage.setItem;

  localStorage.setItem = function () {
    const event = new Event("itemInserted");
    document.dispatchEvent(event);
    originalSetItem.apply(this, arguments);
  };

  const getFlChat = () => localStorage.getItem("fl_chat");
  const isTelaLogin = () => window.location.href.includes("/login");

  const localStorageSetHandler = function () {
    if (!isTelaLogin() && getFlChat() == 1 && !portalCliente) {
      if (document.getElementById("fivehub-chat-button-container") == null) {
        setFlChat(true);
      }
    } else {
      setFlChat(false);
    }
  };

  document.addEventListener("itemInserted", localStorageSetHandler, false);

  // useEffect(() => {
  //   const handleStorageChange = () => {
  //     const flagChat = getFlChat();
  //     if (flagChat == 1 && flChat && !isTelaLogin()) {
  //       /* TODO como o mesmo código abaixo está repetido em 'index.html', 
  //               verificar se não quebra ao criar um único arquivo que tenha esse código em apenas 1 lugar. Tem riscos, principalmente porque sei se cria o 'script' abaixo em uma pasta específica
  //           */
  //       var script = document.createElement("script");
  //       script.id = "5chat_script";
  //       script.src =
  //         "https://5chat.5hub.com.br/v2/static/plugin-pipechat.min.js";
  //       script.setAttribute("data-subdomain", "virtueyes");
  //       script.setAttribute(
  //         "data-activation-key",
  //         "virtueye.63875a30305179.72989000"
  //       );
  //       document.head.appendChild(script);
  //     } else {
  //       const element = document.getElementById("5chat_script");
  //       const element2 = document.getElementById(
  //         "fivehub-chat-button-container"
  //       );
  //       if (element !== null) element.remove();
  //       if (element2 !== null) element2.remove();
  //     }
  //   };

  //   handleStorageChange();
  // }, [flChat]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={coresTema}>
        <EstilosGlobais />
        <Routers
          buscarTema={buscarTema}
          coresTema={coresTema}
          setBuscarTema={setBuscarTema}
          modoTema={modoTema}
          setModoTema={setModoTema}
        />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

function mapStateToProps(state) {
  return {
    systemThemeState: state.systemControlStates.systemTheme,
  };
}

export default connect(mapStateToProps)(React.memo(App));
