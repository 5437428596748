//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
import decrypt from '../../../utils/decryptJWT';

export default function SelectEstado(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [lsEstados, setLsEstados] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleEstado(codigo, estado){
        props.setCdEmpresa(codigo)
        props.setDsEmpresa(estado)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectEmpresa(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderEstados(){
            const {data} = await api.get('/m1/consultar/fat_empresa?cd_pav_usuario='+decrypt('codigo'));
            if(data.dados.length > 0){
                let objetoEstados = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.ds_nome_fantasia}>
                            <div className="campo-select-opcao campo-select-opcao-2" onClick={() => handleEstado(item.cd_fat_empresa, item.ds_nome_fantasia) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.ds_nome_fantasia}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsEstados(objetoEstados)
            }else{
                let objetoEstados = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsEstados(objetoEstados)
            }
            setSpinAtivo(false)
        }
        renderEstados()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectEmpresa(false)}>
                <div className="fonte-cor-1 label-campo">{props.phResponsavel}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsEstados}
                </ul>
            </div>
        </Form>
    )
}