//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Button, Form, ButtonToolbar, Row, Col } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectTipoPermissao from '../../componentesModais/selects/selectTipoPermissao'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectTipoPermissao, setRevelarSelectTipoPermissao] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">TipoPermissao {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectTipoPermissao === false &&
                <Button name="cdTipoPermissao" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoPermissao(!revelarSelectTipoPermissao)}>
                    <div className="fonte-cor-1 label-campo">{dsTipoPermissao}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectTipoPermissao === true &&
                <SelectTipoPermissao
                    setRevelarSelectTipoPermissao={setRevelarSelectTipoPermissao} //Hook para fechar a modal
                    setCdTipoPermissao={setCdTipoPermissao} //Código do TipoPermissao
                    setDsTipoPermissao={setDsTipoPermissao} //Nome do TipoPermissao
                    dsTipoPermissao={dsTipoPermissao} 
                >
                </SelectTipoPermissao>
            }
        </Form.Group>*/

export default function SelectTipoPermissao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [lsTipoPermissaos, setLsTipoPermissaos] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleTipoPermissao(codigo, tipopermissao){
        props.setCdTipoPermissao(codigo)
        props.setDsTipoPermissao(tipopermissao)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectTipoPermissao(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectTipoPermissao(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsTipoPermissao}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    <li key={"menu-Cliente"}>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleTipoPermissao("cliente", "Cliente") }>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{"Cliente"}</span>
                        </div>
                        <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
                    </li>
                    <li key={"menu-Perfil"}>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleTipoPermissao("perfil", "Perfil") }>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{"Perfil"}</span>
                        </div>
                        <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
                    </li>
                    <li key={"menu-Usuário"}>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleTipoPermissao("usuario", "Usuário") }>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{"Usuário"}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </Form>
    )
}