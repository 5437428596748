import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCicloFormatado,
  getNomeMesPeloCiclo,
  getTextoPorcentagem,
  getTextoValor,
} from "./funcoes";
import { arrGraficoVazio, PERCENTUAL, QUANTIDADE } from "./utils";

import CardHeader from "./CardHeader";
import MyPieChart from "./MyPieChart";
import TooltipGraficoPizza from "./TooltipGraficoPizza";

import { alterarGrafico } from "../../../../../redux/actions/dashboardMigracaoPlano";
import {
  formatarMilhar,
  formatarMilharSemDecimais,
} from "../../../../../utils/formatarMilhar";
import { getDadosDashboard } from "../services";
import MyBarChart from "./MyBarChart";

import BotoesGrafico from "./BotoesGrafico";
import "./CardGenerico.css";

function CardGraficoGenerico({
  titulo,
  nomeOpcao,
  InfoHeader,
  id,
  configDash,
  prefixo = "",
  sufixo = " linhas",
}) {
  const [arrayGrafico, setArrayGrafico] = useState([]);
  const [arrDadosCadaCiclo, setArrDadosCadaCiclo] = useState([]);
  const [totalLinhas, setTotalLinhas] = useState(0);

  const [botaoSelecionado, setBotaoSelecionado] = useState(QUANTIDADE);

  const arrDash = useSelector(
    (state) => state.migracaoPlanoState.dashboard[nomeOpcao]
  );

  const ciclo = getCicloFormatado(arrDash);
  const isUltimos6Ciclos = ciclo === "Últimos 6 ciclos";

  const isPercentual = botaoSelecionado === PERCENTUAL;

  const isGraficoEconomia = nomeOpcao === "Economia";

  const mostrarGraficoBarras = isUltimos6Ciclos || isGraficoEconomia;

  const despacho = useDispatch();

  const handleAlterarCiclo = async (ciclo) => {
    despacho(alterarGrafico({ [nomeOpcao]: [] })); // limpar
    setArrayGrafico([]);
    setArrDadosCadaCiclo([]);

    const res = await getDadosDashboard({ grafico: nomeOpcao, ciclo });
    despacho(alterarGrafico(res));
  };

  const getValorLegendaDonuts = (value, totalLinhas) => {
    const txtPrefixo = prefixo ? prefixo + " " : "";
    return isPercentual
      ? getTextoPorcentagem(value, totalLinhas)
      : `${txtPrefixo + getTextoValor(value || 0) + sufixo}`;
  };

  const getValorLegendaBarraEconomia = (value, totalLinhas) => {
    return isPercentual
      ? getTextoPorcentagem(value, totalLinhas, { int: true })
      : `${formatarMilhar(value) + sufixo}`;
  };

  const getValorLegendaBarra = (value, totalLinhas) => {
    return isPercentual
      ? getTextoPorcentagem(value, totalLinhas, { int: false })
      : formatarMilharSemDecimais(value || 0) + " linhas";
  };

  useEffect(() => {
    function carregarDadosGrafico() {
      if (!arrDash?.length) return;

      const dadosDash = arrDash[0];

      if (arrDash.length > 1 || isGraficoEconomia) {
        const newArrDados = arrDash.map((item, index) => {
          const aux = { ...item };

          aux.total = aux.Total;
          const ciclo = item.ciclo;

          delete aux.ciclo;

          if (isGraficoEconomia && isUltimos6Ciclos) {
            if (index < 5) delete aux.CustoExcedentePrevisto;

            if (index === arrDash.length - 1)
              delete aux.CustoExcedentePlanoMigracao;
          }

          return {
            name: getNomeMesPeloCiclo(ciclo),
            ...aux,
          };
        });

        const configPorCiclo = configDash.map(
          ({ titulo, key, cor, numBarra }) => {
            return {
              titulo, // para a legenda
              dataKey: key,
              color: cor,
              numBarra,
              value: isGraficoEconomia ? dadosDash[key] : null,
            };
          }
        );

        if (isGraficoEconomia && !isUltimos6Ciclos) {
          configPorCiclo.splice(2, 1); // tira o 'Custo excedente'
        }

        setArrDadosCadaCiclo(configPorCiclo);

        setArrayGrafico(newArrDados);
      } else {
        let total = dadosDash?.Total || dadosDash?.total;

        if (!total) {
          setArrayGrafico(arrGraficoVazio);
        } else {
          const newArrDados = configDash.map((item) => {
            const value = dadosDash[item.key];
            item.value = value;

            return item;
          });

          setArrayGrafico(newArrDados);
        }

        setTotalLinhas(total);
      }
    }

    carregarDadosGrafico();
  }, [arrDash, configDash, isGraficoEconomia, isUltimos6Ciclos, nomeOpcao]);

  return (
    <div
      className="card-dashboard-migracao-plano fundo-cor-5"
      id={`grafico-${nomeOpcao}`}
    >
      <CardHeader
        botaoSelecionado={botaoSelecionado}
        setBotaoSelecionado={setBotaoSelecionado}
        isGraficoEconomia={isGraficoEconomia}
        titulo={titulo}
        id={id}
        key={id}
        ciclo={ciclo}
        InfoHeader={isUltimos6Ciclos ? null : InfoHeader}
        handleAlterarCiclo={handleAlterarCiclo}
      />
      {!isGraficoEconomia && (
        <BotoesGrafico
          isUltimos6Ciclos={isUltimos6Ciclos}
          botaoSelecionado={botaoSelecionado}
          setBotaoSelecionado={setBotaoSelecionado}
        />
      )}
      <div
        className={
          isUltimos6Ciclos
            ? "grupo-dash-grafico-barra-migracaoPlano"
            : "grupo-dash-grafico-donuts-migracaoPlano"
        }
      >
        {mostrarGraficoBarras ? (
          <MyBarChart
            key={id}
            isUltimos6Ciclos={isUltimos6Ciclos}
            className={isGraficoEconomia ? "grafico-economia" : ""}
            data={arrayGrafico}
            arrDadosCadaCiclo={arrDadosCadaCiclo}
            getValorLegenda={
              isGraficoEconomia
                ? getValorLegendaBarraEconomia
                : getValorLegendaBarra
            }
            isPercentual={isPercentual}
            MyTooltip={() => <TooltipGraficoPizza />}
            prefixo={prefixo}
          />
        ) : (
          <div className="container-grafico-pizza-grande-migracaoPlano">
            <MyPieChart
              key={id}
              data={arrayGrafico}
              MyTooltip={() => <TooltipGraficoPizza />}
            />
          </div>
        )}

        {mostrarGraficoBarras ? (
          <div
            className={`container-legendas-grafico-barra-migracaoPlano ${
              !isUltimos6Ciclos && isGraficoEconomia ? "legenda-economia-2" : ""
            }`}
            style={
              isGraficoEconomia && isUltimos6Ciclos
                ? { maxWidth: "33%!important" }
                : {}
            }
          >
            {arrDadosCadaCiclo.map(({ titulo, value, color }, index) => {
              let valor = "";
              let auxPrefixo = "";

              if (isGraficoEconomia && !isUltimos6Ciclos) {
                if (valor !== undefined) {
                  valor = getValorLegendaBarraEconomia(value, totalLinhas);
                  auxPrefixo = prefixo;
                }
              }

              return (
                <Legenda
                  key={index}
                  titulo={titulo}
                  valor={valor}
                  backgroundColor={color}
                  prefixo={auxPrefixo}
                />
              );
            })}
          </div>
        ) : (
          <div className="container-legendas-grafico-donuts-migracaoPlano">
            {arrayGrafico.map(({ titulo, value, cor }, index) => {
              let valorLegenda = getValorLegendaDonuts(value, totalLinhas);

              return (
                <Legenda
                  key={index}
                  titulo={titulo}
                  prefixo={prefixo}
                  valor={valorLegenda}
                  backgroundColor={cor}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default CardGraficoGenerico;

function Legenda({ backgroundColor, titulo, prefixo = "", valor }) {
  return (
    <div className="linha-legenda-migracaoPlano">
      <div className="coluna-legenda-migracaoPlano-1">
        <div className="coluna-legenda-migracaoPlano-1-esfera">
          <div
            className="esfera-legenda-dashboard-migracaoPlano"
            style={{ backgroundColor }}
          />
        </div>
        <div className="coluna-legenda-migracaoPlano-1-texto">
          <span className="fonte-cor-1 texto-legenda-grafico-migracao">
            {titulo}
          </span>
        </div>
      </div>

      {valor !== false && (
        <div className="coluna-legenda-migracaoPlano-2-valor">
          <span className="fonte-cor-20 texto-legenda-box-status-migracaoPlano">
            {prefixo + valor}
          </span>
        </div>
      )}
    </div>
  );
}
