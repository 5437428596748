/** ARQUIVO PARA MONTAGEM DE TABELAS GENÉRICAS ATRAVÉS DE PARAMETROS ENVIADOS DO COMPONENTE PAI ATRAVÉS DE PROPS
 * 
 * CRIADO POR: MATEUS FOLLETTO E WESLEY LUZ
 * EM: 2020-12-08
 * 
 * ULTIMA ALTERAÇÃO: 2021-04-09
 * POR: MATEUS FOLLETTO
 * MOTIVO: Adicionadas validações para passagem de dados do tipo Link para componente pai.
 *         Adicionada documentação com instruções de como implementar o componente.
 *         Corrigida função para mover colunas quando não existem colunas de seleção e expansão.
 * 
 * ALTERAÇÃO: 2021-04-07
 * POR: MATEUS FOLLETTO E JOÃO VICTOR
 * MOTIVO: Otimizadas funções da tabela genérica.
 *         Separação entre tratativa de dados e exibição de dados, deixando apenas a exibição como responsabilidade da tabela.
 */

/** COMO USAR ESTE COMPONENTE:
 * 
 * #1 IMPORTE O COMPONENTE DE TABELA GENÉRICA NO COMPONENTE PAI:
 * import TabelaGenerica from '../../componentesModais/tabela/tabela'
 * 
 * #2 IMPLEMENTE A TABELA GENÉRICA NO RETURN DO COMPONENTE PAI UTILIZANDO OS PARAMETRÔS QUE FOREM NECESSÁRIOS:
 * { dados != '' && dados != undefined && dados != null &&
 *      <TabelaGenerica  
 *          tabela={[STRING DE IDENTIFICAÇÃO DA TABELA PARA QUERRY DE COLUNAS]}
 *          selecaoParque={[BOOLEAN DE CONTROLE PARA EXIBIÇÃO DA COLUNA E CHECKBOX DE SELEÇÃO DE PARQUE]}
 *          selecaoPagina={[BOOLEAN DE CONTROLE PARA EXIBIÇÃO DA COLUNA E CHECKBOXES DE SELEÇÃO DE PAGINA]}
 *          selecaoLinha={["RADIO"/"CHECKBOX" STRING DE IDENTIFICAÇÃO DO TIPO DE SELEÇÃO DE LINHA]}
 *          expancaoLinha={[BOOLEAN DE CONTROLE PARA EXIBIÇÃO DA COLUNA E BOTOES DE EXPANSÃO DE LINHA]}
 *          colunaAcao={["REMOVER" STRING DE IDENTIFICAÇÃO PARA COLUNA E BOTÕES DE AÇÕES]}
 *          ordenador={[GETTER DO HOOK QUE RECEBE A STRING DE ORDENAÇÃO PARA QUERRY NO BACK-END]}
 *          setOrdenador={[SETTER DO HOOK QUE RECEBE A STRING DE ORDENAÇÃO PARA QUERRY NO BACK-END]}
 *          limitePagina={[VALOR MÁXIMO DE LINHAS POR PÁGINA]}
 *          paginaAtual={[GETTER DO HOOK QUE RECEBE O VALOR DA PÁGINA ATUAL]}
 *          setPaginaAtual={[SETTER DO HOOK QUE RECEBE O VALOR DA PÁGINA ATUAL]}
 *          itens={[GETTER DO HOOK QUE RECEBE OS ITENS A SEREM EXIBIDOS NA TABELA]}
 *          setItemRemovido={[FUNCAO DO COMPONENTE PAI PARA REMOÇÃO DE ITENS DA TABELA QUE DEVE AGUARDAR APENAS 1 PARAMETRO]}
 *          setItemLink={[FUNCAO DO COMPONENTE PAI QUE RECEBE OS DADOS DA LINHA CLICADA]}
 *          itensSelecionados={[GETTER DO HOOK EM ARRAY QUE RECEBE OS DADOS DAS LINHAS SELECIONADAS EM FORMATO JSON]}
 *          setItensSelecionados={[SETTER DO HOOK EM ARRAY QUE RECEBE OS DADOS DAS LINHAS SELECIONADAS EM FORMATO JSON]}
 *          totalItens={[TOTAL DE ITENS QUE SERÃO EXIBIDOS NA TABELA]}
 *          manterMarcado={[GETTER DO HOOK EM BOOLEAN QUE CONTROLA SE AS LINHAS DEVEM SER MANTIDAS MARCADAS]}
 *          setManterMarcado={[SETTER DO HOOK EM BOOLEAN QUE CONTROLA SE AS LINHAS DEVEM SER MANTIDAS MARCADAS]}
 *          recarregarComponentePai={[GETTER DO HOOK EM BOOLEAN QUE FORÇA O COMPONENTE PAI A RENDERIZAR]}
 *          setRecarregarComponentePai={[SETTER DO HOOK EM BOOLEAN QUE FORÇA O COMPONENTE PAI A RENDERIZAR]}
 *          refazerContagem={[GETTER DO HOOK EM BOOLEAN QUE FORÇA O COMPONENTE PAI A REFAZER A CONTAGEM TOTAL DE ITENS]}
 *          setRefazerContagem={[SETTER DO HOOK EM BOOLEAN QUE FORÇA O COMPONENTE PAI A REFAZER A CONTAGEM TOTAL DE ITENS]}
 *      />
 *  }
 * 
 * #3 CRIE OS HOOKS E FUNÇÕES QUE FOREM PERTINENTES NO COMPONENTE PAI DE ACORDO COM SUAS NECESSIDADES. 
 * 
 */

/**TABELA GENERICA DEVE POSSUIR APENAS A FUNCAO DE EXIBICAO DE DADOS E CONTROLE DE BOTOES DA TABELA
 * 
 * TODA E QUALQUER TRATATIVA DE DADOS DEVE SER FEITA PELO COMPONENTE QUE INSTANCIA A TABELA
 * 
 */

//BIBLIOTECAS E UTILS
import React, {useState, useEffect} from 'react'
import {Table, Button, Image} from 'react-bootstrap'
import ReactDragListView from 'react-drag-listview'
import {api} from '../../../conexoes/api'
import decryptJWT from '../../../utils/decryptJWT'
import moment from 'moment'
import {setaDropdown, passarPaguinaDireita, passarPaguinaEsquerda, lixeiraVermelho} from '../../../utils/alternanciaIcones'
//COMPONENTES PARA MENSAGENS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
//COMPONENTES PARA EXPANSÃO DE LINHA
import BackOfficeCliente from '../../painelVirtueyes/detalhamentoCliente/detalhamentoCliente'
//ESTILIZAÇÃO
import 'bootstrap/js/src/collapse.js'
import '../../../css/componentesModais/tabela/tabela.css'

/*eslint-disable*/
function TabelaGenerica(props) {
    const [bloquearFuncoes, setBloquearFuncoes] = useState(false)
    const [lsTitulos, setLsTitulos] = useState([])
    const [lsLinhas, setLsLinhas] = useState([])
    const [arrayColunas, setArrayColunas] = useState(props.colunas)
    const [itensSelecionados, setItensSelecionados] = useState(props.itensSelecionados)
    const [marcarPaginaInteira, setMarcarPaginaInteira] = useState(false)
    const [contadorItensMarcados, setContadorItensMarcados] = useState([])
    const [contadorItens, setContadorItens] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const [reticencias, setReticencias] = useState('')
    const [render, setRender] = useState(false)
    const [manterMarcado, setManterMarcado] = useState(props.manterMarcado)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)

    useEffect(() => {
        setTotalPaginas(Math.ceil(parseInt(props.totalItens) / parseInt(props.limitePagina)))

        if(props.paginaAtual > 1){
            setContadorItens(props.limitePagina * (props.paginaAtual - 1))
        }else{
            setContadorItens(0)
        }

        setLsTitulos([])
        setLsLinhas([])
        setRender(!render)
    },[props.paginaAtual, props.itens])

    useEffect(() => {
        async function criarTabela(){

            if(manterMarcado === false){
                props.setManterMarcado(false)
            }

            //CABECALHO DA EXPANÇÃO DE LINHA COM SELEÇÃO DE PARQUE
            if(props.selecaoParque === true){
                lsTitulos.push( 
                    <th>
                        <label className="container-checkbox-tabela-generica-selecao-parque">
                            <input 
                                type="checkbox" 
                                defaultChecked={marcarPaginaInteira}
                                id={"-1"}
                                onChange={e => handleMarcarCheckboxesManualmente("-1", e.target.checked)}
                            />
                            <span className="checkmark-checkbox-tabela-generica-selecao-parque"/>
                        </label>
                    </th>
                )
            }

            //CABECALHO DA EXPAÇÃO DE LINHA SEM SELAÇÃO DE PARQUE
            if(props.selecaoParque === false && props.expancaoLinha === true){
                lsTitulos.push( 
                    <th>
                        <div>
                        </div>
                    </th>
                )
            }

            //CABEÇALHO DA SELEÇÃO DE LINHA COM SELEÇÃO DE PÁGINA
            if(props.selecaoPagina === true && props.selecaoLinha === 'checkbox'){
                lsTitulos.push(
                    <th>
                        <label className="container-checkbox-tabela-generica-selecao-pagina">
                            <input 
                                type="checkbox" 
                                defaultChecked={marcarPaginaInteira}
                                id={"-1"}
                                onChange={e => handleMarcarCheckboxesManualmente("-1", e.target.checked)}
                            />
                            <span className="checkmark-checkbox-tabela-generica-selecao-pagina"/>
                        </label>
                    </th>
                )
            }
            
            //CABEÇALHO DA SELEÇÃO DE LINHA SEM SELEÇÃO DE PÁGINA
            if(props.selecaoLinha === 'radio'){
                lsTitulos.push( 
                    <th>
                        <div>
                        </div>
                    </th>
                )
            }

            //DEMAIS CABEÇALHOS DA TABELA
            let objetoTitulos = arrayColunas.map((coluna,i) => {
                if(coluna.exibir === true){
                    return (
                        <th>
                            <button className='botao-cabecalho-tabela-generica fonte-cor-1' onClick={(e) => {handleOrdenarColuna(coluna.id); e.preventDefault()}}>
                                {coluna.nome}
                                <div className="container-icone-ordenacao-coluna-tabela-generica">
                                    <Image id={coluna.id} className="d-none" src={setaDropdown()}/>
                                </div>
                            </button>
                        </th>
                    )
                }else{//EU QUERO IMAGENS
                    return (
                        <th className='esconder-tabela-generica'>
                            <button className='botao-cabecalho-tabela-generica fonte-cor-1' onClick={(e) => {handleOrdenarColuna(coluna.id); e.preventDefault()}}>
                                {coluna.nome}
                                <div className="container-icone-ordenacao-coluna-tabela-generica">
                                    <Image id={coluna.id} className='d-none' src={setaDropdown()}/>
                                </div>
                            </button>
                        </th>
                    )
                }
            })

            lsTitulos.push(objetoTitulos)

            //CABEÇALHO DA COLUNA DE AÇÕES
            if(props.colunaAcao === 'remover'){
                lsTitulos.push( 
                    <th>
                        <div>
                        </div>
                    </th>
                )
            }


            if(props.itens.length > 0){
                //GERA AS LINHAS DA TABELA
                let objetoLinhas = props.itens.map((dado,i) => {
                    let auxLinhas = arrayColunas.map((campo, j) => {
                        // VALIDA SE A CÉLULA DEVE SER EXIBIDA PARA O USUÁRIO
                        if(campo.exibir === true){
                            if(campo.tipo === "data1"){
                                // TIPO DATA1 FORMATA O VALOR COMO DD/MM/YYYY SEM HORÁRIO
                                if(dado[campo.id] != null && dado[campo.id] != '' && dado[campo.id] != undefined){
                                    return(
                                        <td>{moment(dado[campo.id]).format("DD/MM/YYYY")}</td>
                                    )
                                }else{
                                    return(
                                        <td>-</td>
                                    )
                                }
                            }else if(campo.tipo === "dinheiro"){
                                // TIPO DINHEIRO FORMATA O VALOR EM MILHARES COM 2 CASAS DECIMAIS E CONCATENA O R$
                                if(dado[campo.id] != null && dado[campo.id] != '' && dado[campo.id] != undefined){
                                    return(
                                        <td>R$ {conveterNumeroMilhar(dado[campo.id])}</td>
                                    )
                                }else{
                                    return(
                                        <td>-</td>
                                    )
                                }
                            }else if(campo.tipo === "percentual"){
                                // TIPO PERCENTUAL CONCATENA O %
                                if(dado[campo.id] != null && dado[campo.id] != '' && dado[campo.id] != undefined){
                                    return(
                                        <td>{dado[campo.id]} %</td>
                                    )
                                }else{
                                    return(
                                        <td>0 %</td>
                                    )
                                }
                            }else if(campo.tipo === "link"){
                                // TIPO LINK ENVIA VALOR DO CAMPO PARA FUNÇÃO NO COMPONENTE PAI
                                if(dado[campo.id] != null && dado[campo.id] != '' && dado[campo.id] != undefined){
                                    let auxLink = dado[campo.id]
                                    if(dado[campo.id].length > 49){
                                        auxLink = dado[campo.id].slice(0,49) + "..."
                                    }
                                    return(
                                        <td className="link-tabela-generica" onClick={() => props.setItemLink(dado[campo.id])}>{auxLink}</td>
                                    )
                                }else{
                                    return(
                                        <td>-</td>
                                    )
                                }
                            }else{
                                // APENAS RETORNA O VALOR DA CÉLULA
                                if(dado[campo.id] != null && dado[campo.id] != '' && dado[campo.id] != undefined){
                                    return(
                                        <td>{dado[campo.id]}</td>
                                    )
                                }else{
                                    return(
                                        <td>-</td>
                                    )
                                }
                            }
                        }else{
                            // RETORNA A CÉLULA OCULTA, PARA QUE SEUS DADOS POSSAM SER ACESSADOS PORÉM NÃO SEJAM EXIBIDOS
                            return(
                                <td className="esconder-tabela-generica">{dado[campo.id]}</td>
                            )
                        }
                    })
                    
                    return (
                        <>
                            {/* DADOS DA LINHA */}
                            <tr id={"linha-"+dado[arrayColunas[0].id]}>
                                {props.expancaoLinha === true &&
                                    <td>
                                        <Button 
                                            className="botao-expandir-linha-tabela-generica" 
                                            onClick={e => handleGirarSetaDropdown("seta-dropdown"+dado[arrayColunas[0].id] )}
                                            data-toggle={"collapse"}
                                            data-target={".multi-collapse-"+dado[arrayColunas[0].id]}
                                            aria-controls={"multiCollapseExample-"+dado[arrayColunas[0].id]}
                                        >
                                            <Image id={"seta-dropdown"+dado[arrayColunas[0].id]} className="icone-botao-expandir-linha-tabela-generica nao-selecionavel" src={setaDropdown()}/>
                                        </Button>
                                    </td>
                                }
                                {props.expancaoLinha === false && props.selecaoParque === true &&
                                    <td>
                                        <div>
                                        </div>
                                    </td>
                                }
                                {props.selecaoLinha === 'radio' &&
                                    <td>
                                        <label className="container-radio-tabela-generica-selecao-linha">
                                            <input 
                                                type="radio" 
                                                name="radio-tabela-generica-selecao-linha" 
                                                defaultChecked={handleMarcarCheckboxesAutomaticamente(dado[arrayColunas[0].id])} 
                                                id={dado[arrayColunas[0].id]}
                                                onChange={e => handleMarcarRadioManualmente(dado[arrayColunas[0].id], e.target.checked)}
                                            />
                                            <span className="checkmark-radio-tabela-generica-selecao-linha"></span>
                                        </label>
                                    </td>
                                }
                                {props.selecaoLinha === 'checkbox' &&
                                    <td>
                                        <label className="container-checkbox-tabela-generica-selecao-linha">
                                            <input 
                                                type="checkbox" 
                                                defaultChecked={handleMarcarCheckboxesAutomaticamente(dado[arrayColunas[0].id])} 
                                                id={dado[arrayColunas[0].id]}
                                                onChange={e => handleMarcarCheckboxesManualmente(dado[arrayColunas[0].id], e.target.checked)}
                                            />
                                            <span className="checkmark-checkbox-tabela-generica-selecao-linha"/>
                                        </label>
                                    </td>
                                }
                                {auxLinhas}
                                {props.colunaAcao === 'remover' &&
                                    <td>
                                        <Button 
                                            className="botao-remover-linha-tabela-generica"
                                            onClick={() => handleMensagemAtencao(false, dado[arrayColunas[0].id])} 
                                        >
                                            <Image className="icone-botao-remover-linha-tabela-generica nao-selecionavel" src={lixeiraVermelho()}/>
                                        </Button>
                                    </td>
                                }
                            </tr>
                            
                            {/* NECESSÁRIO PARA RENDERIZAR AS CORES DE MODO APROPRIADO */}
                            {props.expancaoLinha === true &&
                                <tr>
                                </tr>
                            }

                            {/* COMPONENTE DA LINHA EXPANDIDA */}
                            {props.expancaoLinha === true &&
                                <div className={"base-linha-expandida-testes collapse multi-collapse-"+dado[arrayColunas[0].id]} id={"multiCollapseExample-"+dado[arrayColunas[0].id]}>
                                    <div className="container-linha-expandida-testes">
                                        <BackOfficeCliente 
                                            noCdCliente={dado.n0_codigo_cliente}
                                            cdCliente={dado.cd_pav_cliente}
                                            logradourocliente={dado.logradouro}
                                            bairrocliente={dado.bairro}
                                            ufcliente={dado.uf}
                                            cidadecliente={dado.cidade}
                                            cepcliente={dado.cep}
                                            vendedorcliente={dado.ds_nome}
                                            cpfVendedorCliente={dado.ds_cpf}
                                            emailVendedorCliente={dado.ds_email}
                                            telefoneVendedorCliente={dado.ds_telefone}
                                        />
                                    </div>
                                </div>
                            }

                        </>
                    );
                })

                setLsLinhas(objetoLinhas)

                setTimeout(() => {
                    AlterarLargura()
                    handleOrdenarColunaIcone()
                    if(props.selecaoLinha === 'checkbox' || props.selecaoLinha === 'radio'){
                        colorirLinhasSelecionadas()
                    }
                }, 100)
            }else{
                setBloquearFuncoes(true)
                //EM CASO DE NADA SER ENCONTRADO, GERA UMA MENSAGEM
                let objetoConsulta = () => {
                    return (
                        <div className="base-tabela-vazia-testes">
                            <div className="container-tabela-vazia-testes">
                                <div className="mensagem-tabela-vazia-teste">
                                    Nenhum dado encontrado!
                                </div>
                            </div>
                        </div>
                    );
                }

                setLsLinhas(objetoConsulta)
            }
        }
        criarTabela()
    },[render])

    function handleRemoverItem(itemRemovido){
        props.setItemRemovido(itemRemovido)
    }

    function handleGirarSetaDropdown(imagemId){
        let imagem = document.getElementById(imagemId);
    
        if(imagem.classList.contains("icone-botao-expandir-linha-tabela-generica")){
            imagem.classList.remove("icone-botao-expandir-linha-tabela-generica")
            imagem.classList.add("icone-botao-expandir-linha-tabela-generica-ativado")
        }else if(imagem.classList.contains("icone-botao-expandir-linha-tabela-generica-ativado")){
            imagem.classList.remove("icone-botao-expandir-linha-tabela-generica-ativado")
            imagem.classList.add("icone-botao-expandir-linha-tabela-generica")
        }
    }

    async function AlterarLargura(){
        //Seleciona a tabela
        let tables = document.getElementsByTagName('table')

        //Verifica se a tabela possuí alguma linha
        for (let i=0; i<tables.length;i++){
            resizableGrid(tables[i]);
        }

        //Aktera o tamanho das colunas através do mouse
        function resizableGrid(table) {
            let row = table.getElementsByTagName('tr')[0],
            cols = row ? row.children : undefined;
            if (!cols) return;
            
            let tableHeight = table.offsetHeight;
            
            for (let i=0;i<cols.length;i++){
                let div = createDiv();
                cols[i].appendChild(div);
                cols[i].style.position = 'relative';
                setListeners(div);
            }
            
            function setListeners(div){
                let pageX, curCol, nxtCol, curColWidth, nxtColWidth;
            
                div.addEventListener('mousedown', function (e) {
                    curCol = e.target.parentElement;
                    nxtCol = curCol.nextElementSibling;
                    pageX = e.pageX; 
                
                    let padding = paddingDiff(curCol);
                
                    curColWidth = curCol.offsetWidth - padding;
                    if (nxtCol){
                        nxtColWidth = nxtCol.offsetWidth - padding;
                    }
                });
            
                div.addEventListener('mouseover', function (e) {
                    e.target.style.borderRight = '0.25em solid rgba(128, 128, 128, 0.198)';
                })
            
                div.addEventListener('mouseout', function (e) {
                    e.target.style.borderRight = '';
                })
            
                document.addEventListener('mousemove', function (e) {
                    if (curCol) {
                        let diffX = e.pageX - pageX;
                    
                        if (nxtCol){
                            nxtCol.style.width = (nxtColWidth - (diffX))+'px';
                            curCol.style.width = (curColWidth + diffX)+'px';
                        }
                    }
                });
            
                document.addEventListener('mouseup', function (e) { 
                    curCol = undefined;
                    nxtCol = undefined;
                    pageX = undefined;
                    nxtColWidth = undefined;
                    curColWidth = undefined
                });
            }
            
            function createDiv(){
                let div = document.createElement('div');
                div.style.top = 0;
                div.style.right = 0;
                div.style.width = '1em';
                div.style.position = 'absolute';
                div.style.cursor = 'col-resize';
                div.style.userSelect = 'none';
                div.style.height = '20000em';
                return div;
            }
            
            function paddingDiff(col){
            
                if (getStyleVal(col,'box-sizing') == 'border-box'){
                    return 0;
                }
                
                let padLeft = getStyleVal(col,'padding-left');
                let padRight = getStyleVal(col,'padding-right');
                return (parseInt(padLeft) + parseInt(padRight));
            
            }
            
            function getStyleVal(elm,css){
                return (window.getComputedStyle(elm, null).getPropertyValue(css))
            }
        };
    }

    const handleMoverColunas = async (fromIndex, toIndex) => {
        //nao deixa colocar nas primeiras duas colunas que sao o select e o descricao de linha
        let posicaoLimite = 0
        if(props.selecaoParque === true || props.expancaoLinha === true){
            posicaoLimite++
        }
        if(props.selecaoLinha === true){
            posicaoLimite++
        }
        if(fromIndex >= posicaoLimite && toIndex >= posicaoLimite && !bloquearFuncoes){
            let columnsCopy = [];
            let clunasHidden = [];
            
            //ORDEM NO ARRAY
            await Promise.all(
                arrayColunas.map((item_colunas,i)=>{
                    if(!item_colunas.hidden && item_colunas.hidden != true){
                        columnsCopy.push(item_colunas)
                    }
                    else{
                        clunasHidden.push(item_colunas)
                    }
                })
            )
            
            //ORDEM NO ARRAY
            const item = columnsCopy.splice((fromIndex-posicaoLimite), 1)[0];
            columnsCopy.splice((toIndex-posicaoLimite), 0, item);

            //ORDEM NO ARRAY
            await Promise.all(
                clunasHidden.map((item_clunasHidden,i)=>{
                    columnsCopy.push(item_clunasHidden)
                })
            )

            // SALVA A POSIÇÃO DAS COLUNAS
            let auxColunasJSONString = JSON.stringify(columnsCopy)

            await api.post('/m1/cadastrar/col_colunas', {
                'ds_col_tabela': props.dsTabela,
                'cd_col_tabela': props.cdTabela,
                'cd_pav_usuario': decryptJWT('codigo'),
                'ds_col_ordem': auxColunasJSONString,
                'fl_padrao': 0
            })

            setArrayColunas(columnsCopy)
            setLsTitulos([])
            setRender(!render)
        }
    }

    async function handleOrdenarColuna(coluna){
        let imagem = document.getElementById(coluna)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(coluna === props.ordenador.split(' ASC')[0] || coluna === props.ordenador.split(' DESC')[0]){
            await props.ordenador.split(' ').map((item,i)=>{
                if(item === 'ASC'){
                    props.setOrdenador(coluna +' DESC')
                }
                if(item === 'DESC'){
                    props.setOrdenador('')
                }
            })
        }else{
            props.setOrdenador(coluna +' ASC')
        }
          
    }

    function handleOrdenarColunaIcone(){
        let elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-coluna-tabela-generica-DESC");
        let elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-coluna-tabela-generica-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-coluna-tabela-generica-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-coluna-tabela-generica-ASC"))
        if(props.ordenador != ""){ 
            let coluna = document.getElementById(props.ordenador.split(' ')[0])
            coluna.classList.remove("d-none")
            coluna.classList.add("icone-ordenacao-coluna-tabela-generica-"+props.ordenador.split(' ')[1])
        }
    }   

    function conveterNumeroMilhar(numero) {
        var numero = numero.toFixed(2).split('.');
        numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }

    function colorirLinhasSelecionadas(){
        itensSelecionados.filter(function(item, i){
            let checkbox = document.getElementById("linha-"+item[arrayColunas[0].id])
            if(checkbox != null){
                checkbox.classList.add("tabela-generica-linha-selecionada")
            }
        })
    }

    function handleMarcarCheckboxesAutomaticamente(codigo){
        let verificacao = false

        itensSelecionados.filter(function(item, i){
            if(codigo === item[arrayColunas[0].id]){
                verificacao = true
                contadorItensMarcados.push(codigo)
            }
        })
        if(contadorItensMarcados.length === props.itens.length){
            if(marcarPaginaInteira === false){
                setTimeout(() => {
                    let aux = document.getElementById("-1")
                    aux.click()
                }, 100)
            }
        }else{
            setMarcarPaginaInteira(false)
        }
        
        setContadorItensMarcados([])

        return verificacao
    }

    function handleMarcarRadioManualmente(codigo, checked){
        let checkbox

        props.itens.filter(function(dado,d){
            itensSelecionados.filter(function(item,i){
                if(dado[arrayColunas[0].id] === item[arrayColunas[0].id]){
                    checkbox = document.getElementById("linha-"+item[arrayColunas[0].id])
                    checkbox.classList.remove("tabela-generica-linha-selecionada")
                    itensSelecionados.splice(i, 1)
                    props.setItensSelecionados([])
                    props.setItensSelecionados(itensSelecionados)
                }
            })
        })

        if(checked === true){
            itensSelecionados.filter(function(item,i){
                itensSelecionados.splice(i, 1)
                props.setItensSelecionados([])
                props.setItensSelecionados(itensSelecionados)
            })

            props.itens.map((dado,i) => {
                if(dado[arrayColunas[0].id] === codigo){
                    checkbox = document.getElementById("linha-"+codigo)
                    checkbox.classList.add("tabela-generica-linha-selecionada")
                    itensSelecionados.push(dado)
                    props.setItensSelecionados([])
                    props.setItensSelecionados(itensSelecionados)
                }
            })
        }
    }

    function handleMarcarCheckboxesManualmente(codigo, checked){
        for (var i = contadorItensMarcados.length; i > 0; i--) {
            contadorItensMarcados.pop();
        }

        let checkbox = document.getElementById("linha-"+codigo)
        
        if(codigo == -1){
            marcarTodosCheckboxes(checked)
        }else{
            if(checked === true){
                checkbox.classList.add("tabela-generica-linha-selecionada")

                props.itens.map((dado,i) => {
                    if(dado[arrayColunas[0].id] === codigo){
                        itensSelecionados.push(dado)
                        props.setItensSelecionados([])
                        props.setItensSelecionados(itensSelecionados)
                    }
                })
                
                props.itens.map((dado,d) => {
                    itensSelecionados.map((item,i) => {
                        if(dado[arrayColunas[0].id] === item[arrayColunas[0].id]){
                            contadorItensMarcados.push(dado[arrayColunas[0].id])
                        }
                    })
                })
                
                if(contadorItensMarcados.length === props.itens.length){
                    setTimeout(() => {
                        let aux = document.getElementById("-1")
                        aux.click()
                    }, 100)
                }
            }else{
                checkbox.classList.remove("tabela-generica-linha-selecionada")

                itensSelecionados.map(function(item, index){
                    if(itensSelecionados[index] === -1){
                        itensSelecionados.splice(index, 1)
                        props.setItensSelecionados([])
                        props.setItensSelecionados(itensSelecionados)
                    }
                })
                
                itensSelecionados.map(function(item, index){
                    if(codigo == item[arrayColunas[0].id]){
                        itensSelecionados.splice(index, 1)
                        props.setItensSelecionados([])
                        props.setItensSelecionados(itensSelecionados)
                    }
                })

                if(marcarPaginaInteira === true){
                    setMarcarPaginaInteira(false)
                    setLsTitulos([])
                    setRender(!render)
                }
            }
        }
    }

    function marcarTodosCheckboxes(checked){
        setMarcarPaginaInteira(checked)
        setLsTitulos([])
        setLsLinhas([])
        if(props.manterMarcado === false){
            props.itens.filter(function(dado,d){
                itensSelecionados.filter(function(item,i){
                    if(dado[arrayColunas[0].id] === item[arrayColunas[0].id]){
                        itensSelecionados.splice(i, 1)
                        props.setItensSelecionados([])
                        props.setItensSelecionados(itensSelecionados)
                    }
                })
            })
    
            if(checked === true){
                props.itens.filter((dado,i) => {
                    itensSelecionados.push(dado)
                    props.setItensSelecionados([])
                    props.setItensSelecionados(itensSelecionados)
                })
                
                setRender(!render)
            }else{
                setRender(!render)
            }
        }else{
            setRender(!render)
            setManterMarcado(false)
        }
    }

    function buscarPagina(valor){
        const validacao = /^[0-9-'-']+$/;
        if (valor === '' || validacao.test(valor)) {
            if(valor > totalPaginas){
                props.setPaginaAtual(totalPaginas)
            }else if(valor < 1){
                props.setPaginaAtual(1)
            }else{
                props.setPaginaAtual(valor)
            }
            setReticencias('')
        }
    }

    function criadorNumeros(totalPaginas, totalItens, valorLimite, paginaAtual){
        
        function paginaAnteriorDuas (paginaAtual){
            if(paginaAtual != 1){
                props.setPaginaAtual(paginaAtual - 2);
                setReticencias('')
            }
        }
    
        function paginaSeguinteDuas (paginaAtual){
            if(totalPaginas != paginaAtual){
                props.setPaginaAtual(paginaAtual + 2);
                setReticencias('')
            }
        }
    
        function paginaPenultima (paginaAtual){
            props.setPaginaAtual(totalPaginas - 1)
            setReticencias('')
        }
    
        function paginaUltima (paginaAtual){
            props.setPaginaAtual(totalPaginas)
            setReticencias('')
        }
    
        if(totalItens > (valorLimite * 2)){
            return (
                <>
                    {paginaAtual === totalPaginas &&
                        <button className="botao-paginacao-tabela" onClick={ (e) => {paginaAnteriorDuas(paginaAtual); e.preventDefault()} }>
                            <a className="fonte-cor-1">
                                { paginaAtual - 2 }
                            </a>
                        </button> 
                    }
                    {paginaAtual >=2 &&
                        <button className="botao-paginacao-tabela" onClick={ (e) => {paginaAnterior(paginaAtual); e.preventDefault()}}>
                            <a className="fonte-cor-1">
                                { paginaAtual - 1 }
                            </a>
                        </button>
                    }
                    <button className="botao-paginacao-tabela" onClick={ (e) => e.preventDefault()}>
                        <a className={"fonte-paginacao-verde"}>
                             { paginaAtual}
                         </a>
                    </button>
                    {paginaAtual != totalPaginas &&
                        <button className="botao-paginacao-tabela" onClick={ (e) => {paginaSeguinte(totalPaginas, paginaAtual); e.preventDefault()} }>
                            <a className="fonte-cor-1">
                                { parseInt(paginaAtual) + 1 }
                            </a>
                        </button> 
                    }
                    {paginaAtual <=1 && totalPaginas > 3 && totalPaginas != 4 &&
                        <button className="botao-paginacao-tabela" onClick={ (e) => {paginaSeguinteDuas(paginaAtual); e.preventDefault()} }>
                            <a className="fonte-cor-1">
                                { parseInt(paginaAtual) + 2 }
                            </a>
                        </button>
                    }
                    {paginaAtual != totalPaginas && paginaAtual != totalPaginas-1 && paginaAtual != totalPaginas-2 && paginaAtual != totalPaginas-3 && totalPaginas != 4 &&
                        <input type={"number"} className="botao-paginacao-reticencias-tabela-generica fonte-cor-1" placeholder={'. . .'} value={reticencias} onKeyDown={e => {e.key === 'Enter' && buscarPagina(e.target.value)}} onKeyPress={e => {e.key === 'Enter' && e.preventDefault()}} onChange={e => setReticencias(e.target.value)} onBlur={() => setReticencias('')}/>
                    }
                    {paginaAtual != totalPaginas && paginaAtual != totalPaginas -1 && paginaAtual != totalPaginas -2 && totalPaginas != 4 &&
                        <button className="botao-paginacao-tabela" onClick={ (e) => {paginaPenultima(paginaAtual); e.preventDefault()} }>
                            <a className="fonte-cor-1">
                                { totalPaginas - 1 }
                            </a>
                        </button> 
                    }
                    {paginaAtual != totalPaginas && paginaAtual != totalPaginas -1 &&
                        <button className="botao-paginacao-tabela" onClick={ (e) => {paginaUltima(paginaAtual); e.preventDefault()} }>
                            <a className="fonte-cor-1">
                                { totalPaginas }
                            </a>
                        </button>
                    }
                </>
            )
        }
        else{
            return (
                <>
                    {paginaAtual >=2 &&
                        <button className="botao-paginacao-tabela" onClick={ (e) => {paginaAnterior(paginaAtual); e.preventDefault()} }>
                            <a className="fonte-cor-1">
                                { paginaAtual - 1 }
                            </a>
                        </button>
                    }
                    <button className="botao-paginacao-tabela" type='button' >
                        <a className="fonte-paginacao-verde">
                            { paginaAtual }
                        </a>
                    </button>
                    {paginaAtual < 2 && totalPaginas > 1 &&
                        <button className="botao-paginacao-tabela" onClick={ (e) => {paginaSeguinte(totalPaginas, paginaAtual); e.preventDefault()} }>
                            <a className="fonte-cor-1">
                                { paginaAtual + 1 }
                            </a>
                        </button>
                    }
                </>
            )
        }
    }

    function paginaAnterior(paginaAtual){
        if(paginaAtual != 1){
            props.setPaginaAtual(paginaAtual - 1)
            setReticencias('')
        }
    }
    
    function paginaSeguinte(totalPaginas, paginaAtual) {
        if(totalPaginas != paginaAtual){
            props.setPaginaAtual(paginaAtual + 1)
            setReticencias('')
        }
    }

    function handleMensagemAtencao(confirmacao, dado){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                handleRemoverItem(dado)
            }
        }
    }
    
    return( 
        <div className="base-tabela-teste">
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover item?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            <div className={"container-tabela-generica-"+props.dsTabela}>
                <ReactDragListView.DragColumn
                    onDragEnd={handleMoverColunas}
                    nodeSelector="th"
                    ignoreSelector="th.selection-cell-header, th.expand-cell-header"
                >
                    <Table className="tabela-generica" zebra>
                        <thead>
                            {lsTitulos}
                        </thead>
                        <tbody>
                            {lsLinhas}
                        </tbody>
                    </Table>
                </ReactDragListView.DragColumn>
            </div>
            <div className="container-contador-itens-paginacao-tabela-generica">
                <text className="container-contador-itens-tabela-generica fonte-cor-1">
                    Mostrando 
                    <span className="label-tabela-itens-exibidos">
                        {contadorItens + 1}
                    </span>
                    - 
                    <span className="label-tabela-itens-exibidos">
                        {contadorItens + props.limitePagina <= props.totalItens ? contadorItens + props.limitePagina : props.totalItens}
                    </span>
                    de 
                    <span className="label-tabela-itens-exibidos">
                        {props.totalItens}
                    </span>
                </text>
                <div className={props.modal === undefined ? "container-botao-paginacao-tabela-generica" : "container-botao-paginacao-tabela-generica-2"} >
                    <button className="botao-paginacao-primeira-ultima-tabela primeiro-item" onClick={ (e) => {props.setPaginaAtual(1); e.preventDefault()} }>
                        Primeira
                    </button>
                    <div className="botao-setas-paginacao-seta-tabela">
                        <button className="botao-paginacao-seta-tabela" onClick={ (e) => {paginaAnterior(props.paginaAtual); e.preventDefault()}}>
                            <img src={passarPaguinaEsquerda()} alt="pagina anterior"/>
                        </button>
                        {criadorNumeros(totalPaginas, props.totalItens, props.limitePagina, props.paginaAtual)}
                        <button className="botao-paginacao-seta-tabela" onClick={ (e) => {paginaSeguinte(totalPaginas, props.paginaAtual); e.preventDefault()}}>
                            <img src={passarPaguinaDireita()} alt="proxima pagina"/>
                        </button>
                    </div>
                    <button className="botao-paginacao-primeira-ultima-tabela ultimo-item" onClick={ (e) => {props.setPaginaAtual(totalPaginas); e.preventDefault()} }>
                        Última
                    </button>
                </div>
            </div>
        </div>
    )
}

function CarregarColunas(props){
    const [colunas, setColunas] = useState([])
    const [cdTabela, setCdTabela] = useState()

    useEffect(() => {
        async function carregarColunas(){
            await api.post("/m1/consultar/col_colunas", {
                'ds_col_tabela': props.tabela,
                'cd_pav_usuario': decryptJWT('codigo')
            })
            .then(async function (response) {
                setColunas(JSON.parse(response.data.dados[0].ds_col_ordem))
                setCdTabela(response.data.dados[0].cd_col_tabela)
            })
            .catch(function (error) {
                
            });
        }
        carregarColunas()
    }, [])

    return (
        <>
            {colunas.length !==0 &&
                <TabelaGenerica  
                    //VARIAVEIS DE CONTROLE
                    dsTabela={props.tabela}
                    cdTabela={cdTabela}
                    colunas={colunas}
                    selecaoParque={props.selecaoParque}
                    selecaoPagina={props.selecaoPagina}
                    selecaoLinha={props.selecaoLinha}
                    expancaoLinha={props.expancaoLinha}
                    colunaAcao={props.colunaAcao}
                    ordenador={props.ordenador}
                    setOrdenador={props.setOrdenador}
                    limitePagina={props.limitePagina}
                    paginaAtual={props.paginaAtual}
                    setPaginaAtual={props.setPaginaAtual}
                    //DADOS DA TABELA
                    itens={props.itens}
                    setItemRemovido={props.setItemRemovido}
                    setItemLink={props.setItemLink}
                    itensSelecionados={props.itensSelecionados}
                    setItensSelecionados={props.setItensSelecionados}
                    totalItens={props.totalItens}
                    manterMarcado={props.manterMarcado}
                    setManterMarcado={props.setManterMarcado}
                    recarregarComponentePai={props.recarregarComponentePai}
                    setRecarregarComponentePai={props.setRecarregarComponentePai}
                    refazerContagem={props.refazerContagem}
                    setRefazerContagem={props.setRefazerContagem}
                />
            }
        </>
    )
}

export default React.memo(CarregarColunas)