const ESTADO_INICIAL = {
    revelarNovaOperadora: false,
    revelarEditarOperadora: false,
    revelarExcluirOperadora: false,
    revelarNovoPlano: false,
    revelarEditarPlano: false,
    revelarExcluirPlano: false,
    revelarNovoFornecedor: false,
    revelarEditarFornecedor: false,
    revelarExcluirFornecedor: false,
    revelarNovoServico: false,
    revelarEditarServico: false,
    revelarExcluirServico: false,
    revelarDispositivosAlterarServicos: false,
    revelarDispositivosAlterarPlano: false,
    revelarDispositivosAtribuirLinhas: false,
    revelarDispositivosAlterarCicloDeVida: false,
    revelarResetFaturas: false,
    revelarLiberarSmsFaturas: false,
    revelarDesativarSMSFaturas: false,
    revelarDispositivosAlterarIccid: false,
    revelarAlterarICCIDFaturas: false,
    revelarDispositivosAlterarConta: false,
    revelarImportarFebrabans: false,
    revelarExcluirFebraban: false,
    revelarAssociarFatura: false,
    revelarNovoCicloVida: false,
    revelarEditarCicloVida: false,
    revelarExcluirCicloVida: false,
    revelarNovaConta: false,
    revelarEditarConta: false,
    revelarExcluirConta: false,
    revelarNovoFatPedidos: false,
    revelarEditarFatPedidos: false,
    revelarExcluirFatPedidos: false,
    revelarNovaEmpresa: false,
    revelarEditarEmpresa: false,
    revelarExcluirEmpresa: false,
    revelarEditarSolicitacao: false,
    revelarRelatorioLinhasAfetadas: false,
    revelarExcluirSolicitacao: false,
    revelarFiltrarSolicitacaoPorArquivo: false,
    revelarConcluirFatSolicitacao: false,
    limparFiltroPorArquivoFatSolicitacao:false,
    abrirModal: false,
    clicouFora: false,
    abrirSubItem: false,
    filtroPorArquivoFatSolicitacao:undefined,
    revelarMostrarEmDispositivosSolicitacao: false,
    revelarMostrarEmDispositivosSolicitacaoReset: false,
    revelarReenviarSolicitacaoFaturas: false,
    revelarAbrirTelaConciliacao: false,
    revelarCadastrarFatura: false,
    revelarEditarFatura: false,
    revelarDeletarFatura: false,
    revelarExcluirFatura: false,
    revelarContestarFatura: false,
    revelarRecalcularFatura: false,
    revelarRecalcularFaturaReferencia: false,
    revelarRecalcularFaturaConta: false,
    revelarRecalcularFaturaEmpresa: false,
    revelarRelatorioPreviewFatura: false,
    revelarRelatorioFatura: false,
    revelarRelatorioFebraban: false,
    revelarRelatorioFaturaDispositivos: false,
    revelarRelatorioConciliacao: false,
    revelarAtualizarConciliacao: false,
    revelarRelatorioNotaFiscal: false,
    revelarRelatorioNotaFiscalGeral: false,
    revelarRelatorioHistorico: false,
    valorCdFatura: false,
    revelarAlterarPrazoPermanencia: false,
    paginaDashboardFaturas: 0,
    conciliacaoEmLote: false,
    revelarRelatorioResumoFebraban: false
};

export const faturasReducer = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'NOVA_CONTA_FATURAS') {
        return {
            ...state, 
            revelarNovaConta: action.parametro
        }
    }
    else if (action.type === 'EDITAR_CONTA_FATURAS') {
        return {
            ...state, 
            revelarEditarConta: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RELATORIO_RESUMO_FEBRABAN') {
        return {
            ...state, 
            revelarRelatorioResumoFebraban: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_CONTA_FATURAS") {
        return {
            ...state,
            revelarExcluirConta: action.parametro
        }
    }
    else if (action.type === 'NOVA_OPERADORA_FATURAS') {
        return {
            ...state, 
            revelarNovaOperadora: action.parametro
        }
    }
    else if (action.type === 'EDITAR_OPERADORA_FATURAS') {
        return {
            ...state, 
            revelarEditarOperadora: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_OPERADORA_FATURAS") {
        return {
            ...state,
            revelarExcluirOperadora: action.parametro
        }
    }
    else if (action.type === 'NOVO_FORNECEDOR_FATURAS') {
        return {
            ...state, 
            revelarNovoFornecedor: action.parametro
        }
    }
    else if (action.type === 'EDITAR_FORNECEDOR_FATURAS') {
        return {
            ...state, 
            revelarEditarFornecedor: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_FORNECEDOR_FATURAS") {
        return {
            ...state,
            revelarExcluirFornecedor: action.parametro
        }
    }
    else if (action.type === 'NOVA_EMPRESA_FATURAS') {
        return {
            ...state, 
            revelarNovaEmpresa: action.parametro
        }
    }
    else if (action.type === 'EDITAR_EMPRESA_FATURAS') {
        return {
            ...state, 
            revelarEditarEmpresa: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_EMPRESA_FATURAS") {
        return {
            ...state,
            revelarExcluirEmpresa: action.parametro
        }
    }
    else if (action.type === "REVELAR_NOVO_FATPEDIDO") {
        return {
            ...state,
            revelarNovoFatPedidos: action.parametro
        }
    }
    else if (action.type === "REVELAR_EDITAR_FATPEDIDO") {
        return {
            ...state,
            revelarEditarFatPedidos: action.parametro
        }
    }
    else if (action.type === "REVELAR_EXCLUIR_FATPEDIDO") {
        return {
            ...state,
            revelarExcluirFatPedidos: action.parametro
        }
    }
    else if (action.type === 'NOVO_PLANO_FATURAS') {
        return {
            ...state, 
            revelarNovoPlano: action.parametro
        }
    }
    else if (action.type === 'EDITAR_PLANO_FATURAS') {
        return {
            ...state, 
            revelarEditarPlano: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_PLANO_FATURAS") {
        return {
            ...state,
            revelarExcluirPlano: action.parametro
        }
    }
    else if (action.type === 'NOVO_SERVICO_FATURAS') {
        return {
            ...state, 
            revelarNovoServico: action.parametro
        }
    }
    else if (action.type === 'EDITAR_SERVICO_FATURAS') {
        return {
            ...state, 
            revelarEditarServico: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_SERVICO_FATURAS") {
        return {
            ...state,
            revelarExcluirServico: action.parametro
        }
    }
    else if (action.type === 'NOVO_PLANO_FATURAS') {
        return {
            ...state, 
            revelarNovoPlano: action.parametro
        }
    }
    else if (action.type === 'EDITAR_PLANO_FATURAS') {
        return {
            ...state, 
            revelarEditarPlano: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_PLANO_FATURAS") {
        return {
            ...state,
            revelarExcluirPlano: action.parametro
        }
    }
    else if (action.type === 'NOVO_SERVICO_FATURAS') {
        return {
            ...state, 
            revelarNovoServico: action.parametro
        }
    }
    else if (action.type === 'EDITAR_SERVICO_FATURAS') {
        return {
            ...state, 
            revelarEditarServico: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_SERVICO_FATURAS") {
        return {
            ...state,
            revelarExcluirServico: action.parametro
        }
    }
    else if (action.type === "DIPOSITIVOS_FATURAS_ALTERAR_SERVICOS") {
        return {
            ...state,
            revelarDispositivosAlterarServicos: action.parametro
        }
    }
    else if (action.type === "DIPOSITIVOS_FATURAS_ALTERAR_PLANO") {
        
        return {
            ...state,
            revelarDispositivosAlterarPlano: action.parametro
        }
    } // 
    else if (action.type === "DIPOSITIVOS_FATURAS_ALTERAR_PLANO_FATURAS") {
        
        return {
            ...state,
            revelarDispositivosAlterarPlano: action.parametro
        }
    }
    //  
    else if (action.type === "DIPOSITIVOS_FATURAS_ALTERAR_PRAZO_PERMANENCIA_FATURAS") {
        
        return {
            ...state,
            revelarAlteracaoprazodepermanenciaFaturas: action.parametro
        }
    }
    else if (action.type === "DIPOSITIVOS_FATURAS_ALTERAR_CICLO_VIDA") {
        return {
            ...state,
            revelarDispositivosAlterarCicloDeVida: action.parametro
        }
    }
    else if (action.type === "DIPOSITIVOS_FATURAS_RESET") {
        return {
            ...state,
            revelarResetFaturas: action.parametro
        }
    } // revelarLiberarSmsFaturas
    else if (action.type === "DIPOSITIVOS_FATURAS_LIBERAR_SMS") {
        return {
            ...state,
            revelarLiberarSmsFaturas: action.parametro
        }
    }
    // revelarDesativarSMSFaturas
    else if (action.type === "DIPOSITIVOS_DESATIVAR_LIBERAR_SMS_FATURAS") {
        return {
            ...state,
            revelarDesativarSMSFaturas: action.parametro
        }
    }
    else if (action.type === "DIPOSITIVOS_FATURAS_ALTERAR_ICCID") {
        return {
            ...state,
            revelarDispositivosAlterarIccid: action.parametro
        }
    } // revelarAlterarICCIDFaturas
    else if (action.type === "DIPOSITIVOS_FATURAS_ALTERAR_ICCID_FATURAS") {
        return {
            ...state,
            revelarAlterarICCIDFaturas: action.parametro
        }
    }
    else if (action.type === "DIPOSITIVOS_FATURAS_ALTERAR_CONTA") {
        return {
            ...state,
            revelarDispositivosAlterarConta: action.parametro
        }
    } // 
    else if (action.type === "DIPOSITIVOS_FATURAS_ALTERAR_CONTA_FATURAS") {
        return {
            ...state,
            revelarDispositivosAlterarConta: action.parametro
        }
    }
    else if (action.type === 'REVELAR_IMPORTAR_FEBRABANS') {
        return {
            ...state, 
            revelarImportarFebrabans: action.parametro
        }
    }
    else if (action.type === 'REVELAR_EXCLUIR_FEBRABAN') {
        return {
            ...state, 
            revelarExcluirFebraban: action.parametro
        }
    }
    else if (action.type === "REVELAR_ASSOCIAR_FATURA") {
        return {
            ...state,
            revelarAssociarFatura: action.parametro
        }
    }
    else if (action.type === "REVELAR_ALTERAR_PRAZO_PERMANENCIA") {
        return {
            ...state,
            revelarAlterarPrazoPermanencia: action.parametro
        }
    }
    else if (action.type === 'NOVO_CICLO_FATURAS') {
        return {
            ...state, 
            revelarNovoCicloVida: action.parametro
        }
    }
    else if (action.type === 'EDITAR_CICLO_FATURAS') {
        return {
            ...state, 
            revelarEditarCicloVida: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_CICLO_FATURAS") {
        return {
            ...state,
            revelarExcluirCicloVida: action.parametro
        }
    }
    else if (action.type === 'NOVA_CONTA_FATURAS') {
        return {
            ...state, 
            revelarNovaConta: action.parametro
        }
    }
    else if (action.type === 'EDITAR_CONTA_FATURAS') {
        return {
            ...state, 
            revelarEditarConta: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_CONTA_FATURAS") {
        return {
            ...state,
            revelarExcluirConta: action.parametro
        }
    }
    else if (action.type === 'NOVA_EMPRESA_FATURAS') {
        return {
            ...state, 
            revelarNovaEmpresa: action.parametro
        }
    }
    else if (action.type === 'EDITAR_EMPRESA_FATURAS') {
        return {
            ...state, 
            revelarEditarEmpresa: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_EMPRESA_FATURAS") {
        return {
            ...state,
            revelarExcluirEmpresa: action.parametro
        }
    }
    else if (action.type === "REVELAR_EDITAR_SOLICITACAO") {
        return {
            ...state,
            revelarEditarSolicitacao: action.parametro
        }
    }
    else if (action.type === "REVELAR_RELATORIO_LINHAS_AFETADAS") {
        return {
            ...state,
            revelarRelatorioLinhasAfetadas: action.parametro
        }
    }
    else if (action.type === "REVELAR_EXCLUIR_SOLICITACAO") {
        return {
            ...state,
            revelarExcluirSolicitacao: action.parametro
        }
    }
    else if (action.type === "REVELAR_FILTRAR_SOLICITACAO_POR_ARQUIVO") {
        return {
            ...state,
            revelarFiltrarSolicitacaoPorArquivo: action.parametro
        }
    }
    else if (action.type === "MUDAR_FILTRO_POR_ARQUIVO_FAT_SOLICITACAO") {
        return {
            ...state,
            filtroPorArquivoFatSolicitacao: action.parametro
        }
    }
    else if (action.type === "LIMPAR_FILTRO_POR_ARQUIVO_FAT_SOLICITACAO") {
        return {
            ...state,
            limparFiltroPorArquivoFatSolicitacao: action.parametro
        }
    }
    else if (action.type === "REVELAR_MOSTRAR_EM_DISPOSITIVO_SOLICITACAO") {
        return {
            ...state,
            revelarMostrarEmDispositivosSolicitacao: action.parametro
        }
    }
    else if (action.type === "REVELAR_REENVIAR_SOLICITACAO_FATURAS") {
        return {
            ...state,
            revelarReenviarSolicitacaoFaturas: action.parametro
        }
    }
    else if (action.type === "REVELAR_MOSTRAR_EM_DISPOSITIVO_SOLICITACAO_RESET") {
        return {
            ...state,
            revelarMostrarEmDispositivosSolicitacaoReset: action.parametro
        }
    }
    else if (action.type === "ABRIR_MODAL") {
        return {
            ...state,
            abrirModal: action.parametro
        }
    }
    else if (action.type === "CLICOU_FORA") {
        return {
            ...state,
            clicouFora: action.parametro
        }
    }
    else if (action.type === "ABRIR_SUB_ITEM") {
        return {
            ...state,
            abrirSubItem: action.parametro
        }
    }
    else if (action.type === "REVELAR_CONCLUIR_FAT_SOLICITACAO") {
        return {
            ...state,
            revelarConcluirFatSolicitacao: action.parametro
        }
    }
    else if (action.type === 'REVELAR_ABRIR_TELA_CONCILIACAO') {
        return {
            ...state, 
            revelarAbrirTelaConciliacao: action.parametro
        }
    }
    else if (action.type === 'REVELAR_CADASTRAR_FATURA') {
        return {
            ...state, 
            revelarCadastrarFatura: action.parametro
        }
    }
    else if (action.type === 'REVELAR_EDITAR_FATURA') {
        return {
            ...state, 
            revelarEditarFatura: action.parametro
        }
    }
    else if (action.type === 'REVELAR_DELETAR_FATURA') {
        return {
            ...state, 
            revelarDeletarFatura: action.parametro
        }
    }
    else if (action.type === 'REVELAR_EXCLUIR_FATURA') {
        return {
            ...state, 
            revelarExcluirFatura: action.parametro
        }
    }
    else if (action.type === 'REVELAR_CONTESTAR_FATURA') {
        return {
            ...state, 
            revelarContestarFatura: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RECALCULAR_FATURA') {
        return {
            ...state, 
            revelarRecalcularFatura: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RECALCULAR_FATURA_CONTA') {
        return {
            ...state, 
            revelarRecalcularFaturaConta: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RECALCULAR_FATURA_REFERENCIA') {
        return {
            ...state, 
            revelarRecalcularFaturaReferencia: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RECALCULAR_FATURA_EMPRESA') {
        return {
            ...state, 
            revelarRecalcularFaturaEmpresa: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RELATORIO_PREVIEW_FATURA') {
        return {
            ...state, 
            revelarRelatorioPreviewFatura: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RELATORIO_FATURA') {
        return {
            ...state, 
            revelarRelatorioFatura: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RELATORIO_NOTA_FISCAL') {
        return {
            ...state, 
            revelarRelatorioNotaFiscal: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RELATORIO_NOTA_FISCAL_GERAL') {
        return {
            ...state, 
            revelarRelatorioNotaFiscalGeral: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RELATORIO_HISTORICO') {
        return {
            ...state, 
            revelarRelatorioHistorico: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RELATORIO_FEBRABAN') {
        return {
            ...state, 
            revelarRelatorioFebraban: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RELATORIO_FATURA_DISPOSITIVOS') {
        return {
            ...state, 
            revelarRelatorioFaturaDispositivos: action.parametro
        }
    }
    else if (action.type === 'REVELAR_RELATORIO_CONCILIACAO') {
        return {
            ...state, 
            revelarRelatorioConciliacao: action.parametro
        }
    }
    else if (action.type === "REVELAR_ATUALIZAR_CONCILIACAO") {
        return {
            ...state,
            revelarAtualizarConciliacao: action.parametro
        }
    }
    else if (action.type === "VALOR_CD_FATURA") {
        return {
            ...state,
            valorCdFatura: action.parametro
        }
    }
    else if (action.type === "PAGINA_DASHBOARD_FATURAS") {
        return {
            ...state,
            paginaDashboardFaturas: action.parametro
        }
    }
    else if (action.type === "CONCIALIACAO_EM_LOTE") {
        return {
            ...state,
            conciliacaoEmLote: action.parametro
        }
    }
    else {
        return state;
    }
}