//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Form } from 'react-bootstrap';/*eslint-disable*/
import '../../../css/componentesModais/selects/Select.css'

// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'


/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectSolicitacaoTipo from '../../componentesModais/selects/selectSolicitacaoTipo'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectSolicitacaoTipo, setRevelarSelectSolicitacaoTipo] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label className="label">
                <div className="fonte-cabecalho">SolicitacaoTipo {iconeAsterisco}</div>
            </Form.Label>
            <Button name="cdSolicitacaoTipo" className="campo-select-modal" onClick={() => setRevelarSelectSolicitacaoTipo(!revelarSelectSolicitacaoTipo)}>
                <div className="campo-select-modal-label">{dsSolicitacaoTipo}</div>
                <img className="campo-select-modal-icone" src={setaDropdown()} alt="drop" />
            </Button>
            { revelarSelectSolicitacaoTipo === true &&
                <SelectSolicitacaoTipo
                    setRevelarSelectSolicitacaoTipo={setRevelarSelectSolicitacaoTipo} //Hook para fechar a modal
                    setCdSolicitacaoTipo={setCdSolicitacaoTipo} //Código do SolicitacaoTipo
                    setDsSolicitacaoTipo={setDsSolicitacaoTipo} //Nome do SolicitacaoTipo
                    phSolicitacaoTipo={phSolicitacaoTipo} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectSolicitacaoTipo>
            }
        </Form.Group>*/

export default function SelectSolicitacaoTipo(props) {
    const [lsSolicitacaoTipos, setLsSolicitacaoTipos] = useState([])
    //HOOKS MODAL
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleSolicitacaoTipo(SolicitacaoTipo,cdSolicitacaoTipo){
        props.setDsSolicitacaoTipo(SolicitacaoTipo)
        props.setCdSolicitacaoTipo(cdSolicitacaoTipo)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectSolicitacaoTipo(false)
    }

    useEffect(() => {
        async function renderSolicitacaoTipos(){
            const {data} = await api.get('/m1/consultar/tipo_solicitacao');
            
            if(data.dados.length > 0){
                let objetoSolicitacaoTipos = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.cd_crm_solicitacao_tipo}>
                            <div className="select-pav-menu-tipo-solicitacao campo-select-opcao-2" onClick={() => handleSolicitacaoTipo(item.ds_crm_solicitacao_tipo, item.cd_crm_solicitacao_tipo) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label-tipo-solicitacao nao-selecionavel">{item.ds_crm_solicitacao_tipo}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsSolicitacaoTipos(objetoSolicitacaoTipos)
            }else{
                let objetoSolicitacaoTipos = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsSolicitacaoTipos(objetoSolicitacaoTipos)
            }
            setSpinAtivo(false)
        }
        renderSolicitacaoTipos()
    }, [])
    
    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsSolicitacaoTipos}
                </ul>
            </div>
        </Form>
    )
}