//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import { Button, Form } from 'react-bootstrap';
import decryptJWT from '../../../utils/decryptJWT'
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectCliente from '../../componentesModais/selects/selectCliente'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectCliente, setRevelarSelectCliente] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label className="label">
                <div className="fonte-cabecalho">cliente {iconeAsterisco}</div>
            </Form.Label>
            <Button name="cdCliente" className="campo-select-modal" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                <div className="campo-select-modal-label">{dsCliente}</div>
                <img className="campo-select-modal-icone" src={setaDropdown()} alt="drop" />
            </Button>
            { revelarSelectCliente === true &&
                <SelectCliente
                    setRevelarSelectCliente={setRevelarSelectCliente} //Hook para fechar a modal
                    setCdCliente={setCdCliente} //Código do cliente
                    setDsCliente={setDsCliente} //Nome do cliente
                    phCliente={phCliente} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectCliente>
            }
        </Form.Group>*/

export default function SelectGrupos(props) {
    const [grupos, setGrupos] = useState([])
    const [spinAtivo, setSpinAtivo] = useState(true);
    
    //HOOKS MODAL
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleGrupos(codigo, grupo){
        if(props.rota && props.rota == "filtroSimcard"){
            props.setFiltroGrupo(grupo+"*"+codigo)
            props.setCdGrupo(codigo)
        }else{
            props.setNomeGrupo(grupo)
            props.setCdGrupo(codigo)
        }
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectGrupo(false)
    }

    useEffect(() => {
        if (props.rota == "filtroSimcard" && props.setResetGrupo) {
            props.setResetGrupo(false)
        }
        async function renderGrupos(){
            setSpinAtivo(true)
            let todosGrupos = [];
            //carrega os 3 grupos padroes
            const gruposPadroes = await api.get('/m1/consultar/simcard_grupo?fl_padrao=1')
            todosGrupos = gruposPadroes.data.dados;
            
            // carrega o grupos do cliente
            const {data} = await api.get('/m1/consultar/simcard_grupo?fl_padrao=0&&cd_pav_cliente='+parseInt(decryptJWT('codigoCliente')))
            await data.dados.map((item,i)=>{
                todosGrupos.push(item);
                return true
            })

            if(todosGrupos.length > 0){
                let objetoGrupos = todosGrupos.map((item,i) => {
                    return (
                        <li key={"menu-"+item.ds_nome}>
                            <div className={props.rota && props.rota == "filtroSimcard" ? "campo-select-opcao-grupo-simcard campo-select-opcao-1" : "campo-select-opcao campo-select-opcao-1" } onClick={() => handleGrupos(item.cd_dsp_simcard_grupo, item.ds_nome) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label">{item.ds_nome}</span>
                            </div>
                            {todosGrupos[i+1]!==undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
            setSpinAtivo(false)
                setGrupos(objetoGrupos)
            }else{
                let objetoGrupos = () => {
                    return (
                        <li>
                            <div disabled className={props.rota && props.rota == "filtroSimcard" ? "campo-select-opcao-grupo-simcard campo-select-opcao-1" : "campo-select-opcao campo-select-opcao-1" }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setGrupos(objetoGrupos)
            }
        }
        renderGrupos()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            { spinAtivo && props.rota && props.rota  == "filtroSimcard" ?  <div className="componente-spinner-overlay-filtro-simcard"><div className="componente-spinner-container"><SpinerVeye/></div></div> : spinAtivo && !props.rota ? <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> : '' }
            <Button className={props.rota && props.rota == "filtroSimcard" ? "campo-texto-cor-3 campo-select-filtro-grupo" : "campo-texto-cor-3 campo-select"}onClick={() => props.setRevelarSelectGrupo(false)}>
                <div className="fonte-cor-1 label-campo-marca">{props.rota && props.rota == "filtroSimcard" ? props.nomeGrupo.split('*')[0] : props.nomeGrupo}</div>
                <img className="campo-select-icone-ativado-filtro-b" src={setaDropdown()} alt="drop" />
            </Button>
            <div className={props.rota && props.rota == "filtroSimcard" ? "campo-select-triangulo-cor-1 campo-select-triangulo-3" : "campo-select-triangulo-cor-1 campo-select-triangulo"}></div>
            <div className={props.rota && props.rota == "filtroSimcard" ? "campo-texto-cor-3 campo-select-corpo-3" : "campo-texto-cor-3 campo-select-corpo"}>
                <ul className="campo-select-lista">
                    {grupos}
                </ul>
            </div>
        </Form>
    )
}