import { api_migracao } from "../../../../conexoes/api";

export const getDadosDashboard = async ({ grafico, ciclo }) => {
  grafico =
    grafico && grafico !== "todos"
      ? grafico
      : "PlanoReal,Economia,Status,Excedente";
  ciclo = checkCurrentMonthYear(ciclo || "atual");

  const params = `?grafico=${grafico}&ciclo=${ciclo}`;

  try {
    const response = await api_migracao.get(
      `/api/v2/migration/dashboard${params}`
    );

    if (response.status === 200) {
      let dados = response.data;

      if (dados.Economia) {
        dados.Economia = dados.Economia.map((item) => {
          item.CustoPlanoMigracao = item.CustoPlanoMigracao || 0;
          if (ciclo === "historico")
            item.CustoExcedentePlanoMigracao =
              item.CustoExcedentePlanoMigracao || 0;
          item.CustoExcedentePrevisto = item.CustoExcedentePrevisto || 0;

          // TODO: Revisar cálculo com Rudimar
          item.Economia =
            item.CustoOriginal -
            (item.CustoPlanoMigracao +
              item.CustoExcedentePrevisto +
              (item.CustoExcedentePlanoMigracao || 0));

          item.Total = item.Total || item.CustoOriginal;

          return item;
        });
      }

      return dados;
    }
  } catch (error) {
    return false;
  }
};

export const getCiclosDisponiveis = async () => {
  try {
    const response = await api_migracao.get(
      "/api/v2/migration/dashboard/cicles"
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

function checkCurrentMonthYear(input) {
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentYear = String(currentDate.getFullYear()).slice(-2);

  const currentMonthYear = currentYear + currentMonth;

  return input === currentMonthYear ? "atual" : input;
}
