//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import { useForm } from "react-hook-form";
import { Button, Form, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import {validacaoAcaoPedidoSms} from './validarPedidoSms';
//ICONES
import {elipseVerde, fecharJanela, lixeiraVermelho} from '../../../utils/alternanciaIcones';
import {formatarMilhar} from '../../../utils/formatarMilhar'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarProcessarPedido } from "../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemAtencaoSelect from '../../componentesModais/mensagens/mensagemAtencaoSelect'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'

export default function ProcessarPedido(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { handleSubmit } = useForm();
    //HOOKS PRINCIPAIS
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1);
    const [linhaRemover, setLinhaRemover] = useState('');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [pedidos, setPedidos] = useState([]);
    const [itens, setItens] = useState([])
    const [itensInvalidos, setItensInvalidos] = useState([])
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemAtencaoConclusao, setRevelarMensagemAtencaoConclusao] = useState(false)
    const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] = useState(false)
    const [revelarMensagemAtencaoContemItemInvalido, setRevelarMensagemAtencaoContemItemInvalido] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();

    let itensTemp = []

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const colunas = [{
        dataField: 'CODIGO_CLIENTE',
        text: 'Cliente',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'NUMERO_PEDIDO',
        headerClasses: 'nao-selecionavel',
        text: 'ID do pedido',
        sort: true, 
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'PEDIDO_STATUS',
        headerClasses: 'nao-selecionavel',
        text: 'Status',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'QTD_SMS',
        headerClasses: 'nao-selecionavel',
        text: 'Quantidade  de SMS',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell ? cell.toFixed(2) : cell} MB</p>;
        }
    },
    {
        dataField: 'VALOR',
        headerClasses: 'nao-selecionavel',
        text: 'Valor',
        sort: true,
        formatter: (cell, row) => {
            const valor = parseFloat(cell);
            return <p className="fonte-cor-1 label-tabela-1 container-valor-pedido"> {(!valor || isNaN(valor)) ? <><p className="valor-rs-pedido">R$</p> <p className="fonte-cor-1 valor-pedido"> 0.00 </p></> : <><p className="valor-rs-pedido">R$</p> <p className="fonte-cor-1 valor-pedido"> {formatarMilhar(valor)}</p></>} </p>;
        }
    },
    {
        dataField: 'acoes',
        text: '',
        sort: false,
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]


    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.NUMERO_PEDIDO);
        }
    }
    
    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itensTemp.length)
    }

    function handleRemoverLinha(IDPEDIDO){
        setRevelarMensagemAtencao(false)
        itensTemp = itens
        itens.forEach(function(e, index){
            if(IDPEDIDO == e.NUMERO_PEDIDO){
                itens.splice(index, 1);
            }
        })
        setItens(itensTemp)
        handleIdPedidos()
        handleLinhasSelecionadas()
    }

    async function handleSalvar(){
        handleMensagemSucesso(false)
    }

    async function handleCancelar() {
        ColetaClickstream(1411) // pedidoSmsTabelaPedidoSmsDropAcoesProcessarPedidoCancelar
        itensTemp = []
        setSpinAtivo(false)
        despacho(revelarProcessarPedido(false))
        props.setRenderizar(!props.renderizar)
    }

    function handleIdPedidos(){
        let idPedidosSelecionados = []
        for (let i = 0; i < itensTemp.length; i++) {
            idPedidosSelecionados.push(itensTemp[i].NUMERO_PEDIDO)
        }
        setPedidos(idPedidosSelecionados)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function preencherTabela (){
            const validacao = await validacaoAcaoPedidoSms(props.itensSelecionados, 1)
            setItens(validacao.itensValidos)
            setItensInvalidos(validacao.itensInvalidos)
            itensTemp = validacao.itensValidos

            handleLinhasSelecionadasIncapacitadas();
            handleLinhasSelecionadas();
            handleIdPedidos();
            setSpinAtivo(false)
        }
        preencherTabela()
        
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [props.renderizar]);

    async function enviarProcessarPedido(){
        ColetaClickstream(1412) // pedidoSmsTabelaPedidoSmsDropAcoesProcessarPedidoConfirmar
        setSpinAtivo(true)
        await api.post('/m1/alterar/sms_pedido', {
            "dados":{
                'cd_sms_pedido_status': 1,
            },
            "condicao":{
                'cd_sms_pedido': pedidos,
            }
        })
        .then(function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro(error.response.data.info)
            handleMensagemErro(false)
        });
    }

    //verifica se o usuario selecionou linhas extras que nao sao permitidas na acao e aciona um alerta
    function handleLinhasSelecionadasIncapacitadas(){
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if(itensTemp.length === 0){
            setRevelarMensagemAtencaoInvalido(true)
        }
        else if(itensTemp.length < props.itensSelecionados.length){
            setRevelarMensagemAtencaoContemItemInvalido(true)
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(confirmacao ===true){
            handleRemoverLinha(linhaRemover)
        }
    }
    
    function handleMensagemAtencaoConclusao(confirmacao){
        if(revelarMensagemAtencaoConclusao === false){
            setRevelarMensagemAtencaoConclusao(true)
        }else{
            setRevelarMensagemAtencaoConclusao(false)
        }
        if(confirmacao ===true){
            handleSalvar()
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }
    
    return(
        <Form ref={modal} onSubmit={handleSubmit(() => enviarProcessarPedido())}>
            <div className="modal-acao-processar-pedido fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Processar pedido</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-tabela-modal">
                            <BootstrapTable
                                classes="tabela-transparente-modais"
                                condensed={true}
                                keyField='NUMERO_PEDIDO' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                bootstrap4={true}
                            />
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} linhas selecionadas</span>
                    </Col>
                </Form.Row>
                <Form.Row className="espaco-ativar-linha">
                    <Col>
                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Seu(s) pedido(s) foram processados."}
                            mensagemBotao={"Ok"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoConclusao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencaoConclusao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={"Tem certeza que deseja processar este(s) pedido(s)?"}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover pedido?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencaoSelect
                            handleMensagemAtencao={() =>  setRevelarMensagemAtencaoInvalido(!revelarMensagemAtencaoInvalido),handleCancelar}
                            mensagemTitulo={"Ops!!!"}
                            mensagemParagrafo1="Alguns pedidos ja foram processados ou o status atual do mesmo não permite executar esta ação."
                            mensagemParagrafo2={"Os seguintes pedidos foram removidos:"}
                            dados={itensInvalidos}
                            mensagemBotao={"Voltar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoContemItemInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencaoSelect
                            handleMensagemAtencao={() =>  setRevelarMensagemAtencaoContemItemInvalido(!revelarMensagemAtencaoContemItemInvalido)}
                            mensagemTitulo={"Ops!!!"}
                            mensagemParagrafo1="Alguns pedidos ja foram processados ou o status atual do mesmo não permite executar esta ação."
                            mensagemParagrafo2={"Os seguintes pedidos foram removidos:"}
                            dados={itensInvalidos}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
