// fatDispositivos - (lista de "id"s do) objeto colunasFaturasInicial em src/utils/colunas.js
export const objCategoriasKeys = {
    Faturas: [ "DS_FORNECEDOR" ],
    Simcard: [
        "CALLERID",
        "ICCID",
        "DS_OPERADORA",
        // "IMSI",
        "DS_CONTA",
        "DS_CICLO_VIDA",
        "DS_PLANO",
        "DS_PEDIDO_OPERADORA",
        "DS_NOTA_FISCAL",
    ],
    Conectividade: [
        "DS_STATUS_SOLIC",
        "DATA_DESBLOQUEIO",
        "FAT_N2_VALOR_PLANO",
        // "ON_OFF",
        // "ULTIMO_EVENTO",
        // "IP",
        // "TECNOLOGIA",
    ],
    // Utilizacao: [ "NUMPACOTES", "CONSUMO_DIA_MB" ],
    Contrato: [
        "DATA_COMPRA",
        "DATA_ATIVACAO",
        "DATA_PRAZO",
        "SMS" // SMS_ATIVADO
    ],
    Dispositivo: [
        // "IMEI",
        "IA_MARCA_DISPOSITIVO",
        "IA_MODELO_DISPOSITIVO"
    ],
    // Identificacao: [],
    Cliente: [
        "IA_NOME",
        "IA_ENDERECO",
        "IA_CIDADE",
        "IA_UF",
        "IA_CEP",
        "IA_OBSERVACOES",
    ]
}
