//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import { Image, Form, Col } from 'react-bootstrap';/*eslint-disable*/
//ICONES
import { IconeFaturasVerde, IconeFaturas, iconeHistorico, historicoVerde, iconeNota, notaVerde } from '../../../../utils/alternanciaIcones'

import Preview from '../detalhamento/preview/preview'
import NotasFiscais from '../detalhamento/notasFiscais/notasFiscais'
import Historico from '../detalhamento/historico/historico'

// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye'
//IMPORTAÇÕES REDUX
import { useSelector } from 'react-redux';
import { expandirNavegacao } from "../../../../redux/actions/index";

export default function DetalhamentoCliente(props) {
    //HOOKS
    const [renderizar, setRenderizar] = useState(false)
    //HOOKS COMPONENTES
    const [revelarPreview, setRevelarPreview] = useState(false)
    const [revelarNotasFiscais, setRevelarNotasFiscais] = useState(false)
    const [revelarHistorico, setRevelarHistorico] = useState(false)
    const [idMenu, setIdMenu] = useState('-1')
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);

    useEffect(() => {
        handleRenderTela('1')
    },[renderizar])

    function handleRenderTela(tela){
        setSpinAtivo(true)
        setIdMenu(tela)
        if (tela === '1'){
            setRevelarPreview(true)
            setRevelarNotasFiscais(false)
            setRevelarHistorico(false)
        }
        if (tela === '2'){
            setRevelarNotasFiscais(true)
            setRevelarPreview(false)
            setRevelarHistorico(false)
        }
        if (tela === '3'){
            setRevelarHistorico(true)
            setRevelarPreview(false)
            setRevelarNotasFiscais(false)
        }
        setSpinAtivo(false)
    }

    return(
            <div className="container-detalhamento-faturas fundo-cor-1">
                {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div>}
                <div className="container-navegacao-detalhamento-faturas">
                    <div className="triangulo-navegacao-detalhamento-cliente campo-select-triangulo-cor-3"></div>
                    <div className="corpo-navegacao-detalhamento-faturas fundo-cor-5">
                        <ul className="lista-navegacao-detalhamento-cliente">
                            <li key={"1"} onClick={() => handleRenderTela('1')}>
                                { idMenu === "1" ?
                                    <div className="container-item-navegacao-detalhamento-cliente-ativado detalhamento-hover fundo-cor-7" onClick={e => handleRenderTela("1")}>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                            <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={IconeFaturasVerde()}/>
                                        </div>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                            <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('1')}>Detalhamento</span>
                                        </div>
                                    </div>
                                :
                                    <div className="container-item-navegacao-detalhamento-cliente-desativado detalhamento-hover " onClick={e => handleRenderTela("1")}>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                            <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={IconeFaturas()}/>
                                        </div>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                            <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('1')}>Detalhamento</span>
                                        </div>
                                    </div>
                                }
                            </li>
                            <li key={"2"} onClick={() => handleRenderTela('2')}>
                                { idMenu === "2" ?
                                    <div className="container-item-navegacao-detalhamento-cliente-ativado detalhamento-hover fundo-cor-7" onClick={e => handleRenderTela("2")}>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                            <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={notaVerde()}/>
                                        </div>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                            <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('2')}>Notas Fiscais</span>
                                        </div>
                                    </div>
                                :
                                    <div className="container-item-navegacao-detalhamento-cliente-desativado detalhamento-hover" onClick={e => handleRenderTela("2")}>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                            <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={iconeNota()}/>
                                        </div>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                            <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('2')}>Notas Fiscais</span>
                                        </div>
                                    </div>
                                }
                            </li>
                            <li key={"3"} onClick={() => handleRenderTela('3')}>
                                { idMenu === "3" ?
                                    <div className="container-item-navegacao-detalhamento-cliente-ativado detalhamento-hover fundo-cor-7" onClick={e => handleRenderTela("3")}>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                            <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={historicoVerde()}/>
                                        </div>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                            <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('3')}>Histórico</span>
                                        </div>
                                    </div>
                                :
                                    <div className="container-item-navegacao-detalhamento-cliente-desativado detalhamento-hover" onClick={e => handleRenderTela("3")}>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                            <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={iconeHistorico()}/>
                                        </div>
                                        <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                            <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('3')}>Histórico</span>
                                        </div>
                                    </div>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="conteudo-detalhamento-cliente">
                    {revelarPreview &&
                        <div className="container-tela-conteudo-detalhamento-cliente">
                            <div className={estadoExpansaoMenu===true?"container-tela-corpo-detalhamento-cliente-1":"container-tela-corpo-detalhamento-cliente-2"}>
                                <Preview
                                    cdFatura={props.cdFatura}
                                    setCdFatura={props.setCdFatura}
                                    setMensagemInformacaoTitulo={props.setMensagemInformacaoTitulo}
                                    setMensagemInformacaoCorpo={props.setMensagemInformacaoCorpo}
                                    handleMensagemInformacao={props.handleMensagemInformacao}
                                />
                            </div>
                        </div>
                    }
                    {revelarNotasFiscais &&
                        <div className="container-tela-conteudo-detalhamento-cliente">
                            <div className={estadoExpansaoMenu===true?"container-tela-corpo-detalhamento-cliente-1":"container-tela-corpo-detalhamento-cliente-2"}>
                                <NotasFiscais 
                                    cdFatura={props.cdFatura}
                                    setCdFatura={props.setCdFatura}
                                    setMensagemInformacaoTitulo={props.setMensagemInformacaoTitulo}
                                    setMensagemInformacaoCorpo={props.setMensagemInformacaoCorpo}
                                    handleMensagemInformacao={props.handleMensagemInformacao}
                                />
                            </div>
                        </div>
                    }
                    {revelarHistorico &&
                        <div className="container-tela-conteudo-detalhamento-cliente">
                            <div className={estadoExpansaoMenu===true?"container-tela-corpo-detalhamento-cliente-1":"container-tela-corpo-detalhamento-cliente-2"}>
                                <Historico
                                    cdFatura={props.cdFatura}
                                    setCdFatura={props.setCdFatura}
                                    setMensagemInformacaoTitulo={props.setMensagemInformacaoTitulo}
                                    setMensagemInformacaoCorpo={props.setMensagemInformacaoCorpo}
                                    handleMensagemInformacao={props.handleMensagemInformacao}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
    )
}