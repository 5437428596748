//BIBLIOTECAS
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';/*eslint-disable*/
import {formatarData} from '../../../../utils/formatadorDataTabela'
//OUTROS
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js';
import '../../../../css/painelVirtueyes/detalhamentoSolicitacao/clientes/tabela.css'

export default function DetalhamentoSolicitacaoCliente(props) {
    //HOOKS
    const [itens, setItens] = useState('')
   //SPINNER
   const [spinAtivo, setSpinAtivo] = useState(true);

    useEffect(() => {
        async function handleCarregarSimcards(){
            setSpinAtivo(true) 
            const dataPeriodo = await api.get('m1/consultar/solicitacao_simcard?cd_crm_solicitacao='+props.cdSolicitacao);
            setSpinAtivo(false) 
            if(dataPeriodo.data.dados != undefined){
                setItens(dataPeriodo.data.dados)
            }
        }
        handleCarregarSimcards();
    }, [])
    
    const colunas = [{
        dataField: 'LINHA',
        text: 'SIM card',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'OPERADORA',
        text: 'Operadora',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'RAZAO_SOCIAL',
        text: 'Razão social',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'nome-fantasia',
        text: 'Nome fantasia',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{props.nomeFantasia}</p>;
        }
    },
    {
        dataField: 'ICCID',
        text: 'ICCID',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'SIMCARD_TIPO',
        text: 'Tipo de SIM card',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            if(cell != null){
                if(cell===0){
                    return <p className="fonte-cor-1 label-tabela-1">Normal</p>
                }
                else{
                    return <p className="fonte-cor-1 label-tabela-1">Triplo corte</p>
                }
            }
            else{
                return <p className="fonte-cor-1 label-tabela-1">Sem informação</p>;
            }
        }
    },
    {
        dataField: 'GEO',
        text: 'Tecnologia',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            const split = cell.split(',')
            const tecnologia = split[2]
            return <p className="fonte-cor-1 label-tabela-1">{(tecnologia != '' && tecnologia != 'NN') ? tecnologia : "-"}</p>;
        }
    },
    {
        dataField: 'CONSUMO_MB',
        text: 'Consumo',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell+'MB' : "-"}</p>;
        }
    },
    {
        dataField: 'ULTIMO_EVENTO',
        text: 'Último evento',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return formatarData(cell);
        }
    },]
     
    return(
        <> 
         { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="campo-select-triangulo-cor-2 campo-select-triangulo"></div>
            <div id="tela-descricao-visao-geral-soliciatacao-cliente">
                <div className="bg-background-2 container-caixas-descricao-solicitacao-cliente">
                    <div className="container-caixas-tela-descricao-solicitacao-cliente fundo-cor-3">
                        <div className="caixa-descricao-solicitacao-cliente-dados">
                        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                            <BootstrapTable
                            
                                hover
                                classes='tabela-transparente-header-fixo'
                                keyField='operadora'
                                headerClasses={ "class-centralizar"}
                                data={ itens }
                                columns={ colunas}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                bootstrap4 = {true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}