//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectInput from '../../componentesModais/selects/selectComOpcoesPreConfiguradas'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectInput, setRevelarSelect] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">AcaoTelecall {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectInput === false &&
                <Button name="cdAcaoTelecall" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelect(!revelarSelectInput)}>
                    <div className="fonte-cor-1 label-campo">{ds}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectInput === true &&
                <SelectInput
                    setRevelarSelect={setRevelarSelect} //Hook para fechar a modal
                    setCdAcaoTelecall={setCdAcaoTelecall} //Código do AcaoTelecall
                    setDs={setDs} //Nome do AcaoTelecall
                    ds={ds} 
                    phAcaoTelecall={phAcaoTelecall} //Placeholder para mostrar o valor original em caso de edição
                    options={[
                        { text: '...', value: '...'  },
                        ...
                    ]}
                >
                </SelectInput>
            }
        </Form.Group>*/

export default function SelectInput(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleAcaoTelecall(acaoTelecall){

        props.setDs(acaoTelecall)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelect(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    function SelectOption({ optionObj }) {
        const { optionText, valueAction } = optionObj;

        return (<li>
            <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleAcaoTelecall(valueAction)}>
                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{optionText}</span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
        </li>)
    }

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelect(false)}>
                <div className="fonte-cor-1 label-campo">{props.ds}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista" style={{ width: '100%', marginRight: 10 }}>

                    {props.options.map(item => {
                        return <SelectOption key={item.value} optionObj={{ optionText: item.text, valueAction: item.value  }}/>
                    })}

                    
                </ul>
            </div>
        </Form>
    )
}