import React, { useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { setaDropdown } from "../../../utils/alternanciaIcones";

export default function SelectDisponibilidade(props) {
  const modal = useRef();

  // eslint-disable-next-line
  let rangeFiltro = {};

  function range(rangeFiltro) {
    props.setInputRevelado(rangeFiltro.range);
    props.handleRange(rangeFiltro.range);
    handleCancelar();
  }

  function handleCancelar() {
    props.setRevelarSelectRange(false);
  }

  /*
            <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => range(rangeFiltro = {
                range: "Últimas 24 horas"
              })}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Últimas 24 horas
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => range(rangeFiltro = {
                range: "Últimas 48 horas"
              })}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Últimas 48 horas
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
    */

  return (
    <Form ref={modal}>
      <Button
        name="rangeFiltro"
        className="campo-select-rangeFiltro-margin campo-texto-cor-3 campo-select"
        onClick={() => props.setRevelarSelectRange(false)}
      >
        <div className="fonte-cor-1 label-campo">{props.rangeFiltro}</div>
        <img
          className="campo-select-icone-ativado"
          src={setaDropdown()}
          alt="drop"
        />
      </Button>
      <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
      <div className="campo-texto-cor-3 campo-select-corpo">
        <ul className="campo-select-lista">
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => range(rangeFiltro = {
                range: "Últimos 7 dias"
              })}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Últimos 7 dias
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => range(rangeFiltro = {
                range: "Últimos 15 dias"
              })}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Últimos 15 dias
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => range(rangeFiltro = {
                range: "Últimos 30 dias"
              })}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Últimos 30 dias
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => range(rangeFiltro = {
                range: "Ciclo atual"
              })}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Ciclo atual
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => range(rangeFiltro = {
                range: "Ciclo anterior"
              })}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Ciclo anterior
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
        </ul>
      </div>
    </Form>
  );
}