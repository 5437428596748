//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import MapaOpenStreet from './mapaOpenStreet'/*eslint-disable*/
import '../../../css/painelDispositivos/dspMapa/mapa.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function MapaCalor(props) {
    const [latCenter, setLatCenter] = useState(0);
    const [lngCenter, setLngCenter] = useState(0);
    let array = props.array
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(1147) // mapaDistribuicao
            setColetaUsabilidade(false)
        }
    },[])
    
    useEffect(() => {
        async function handleLatitudeLongitude(){
            //setar os valores maximos de latitude e longitude para saber o posicionamento da tabela
            let latitudeMin = array[array.length -1].latitude;
            let latitudeMax = array[array.length -1].latitude;
            let longitudeMin = array[array.length -1].longitude;
            let longitudeMax = array[array.length -1].longitude;

            Object.keys(array).forEach(function (item, i) {
                //verifica se a latitude esta negativa, se estiver a forma de tratar as variaveis e diferente
                if(array[item].latitude / -array[item].latitude == 1){
                    //seta a latitude minima
                    if(array[item].latitude > latitudeMin){
                        latitudeMin = array[item].latitude
                    }
                    //seta a latitude maxima
                    if(array[item].latitude < latitudeMax){
                        latitudeMax = array[item].latitude
                    }
                }else{
                    //seta a latitude minima
                    if(array[item].latitude < latitudeMin){
                        latitudeMin = array[item].latitude
                    }
                    //seta a latitude maxima
                    if(array[item].latitude > latitudeMax){
                        latitudeMax = array[item].latitude
                    }
                }
                //verifica se a longitude esta negativa
                if(array[item].longitude / -array[item].longitude == 1){
                    //seta a longitude minima
                    if(array[item].longitude > longitudeMin){
                        longitudeMin = array[item].longitude
                    }
                    //seta a longitude maxima
                    if(array[item].longitude < longitudeMax){
                        longitudeMax = array[item].longitude
                    }
                }else{
                    //seta a longitude minima
                    if(array[item].longitude < longitudeMin){
                        longitudeMin = array[item].longitude
                    }
                    //seta a longitude maxima
                    if(array[item].longitude > longitudeMax){
                        longitudeMax = array[item].longitude
                    }
                }
                setLatCenter((latitudeMax + latitudeMin) /2)
                setLngCenter((longitudeMax + longitudeMin) /2)
            });
        }
        handleLatitudeLongitude()
    }, [])

    let newArray = array.map(function(item, i){
        return {latitude: array[i]['latitude'], longitude: array[i]['longitude']}
    })

    return(
        <div className="container-mapa-generico">
            {!props.mapa &&
                <MapaOpenStreet array={newArray} latCenter={latCenter} lngCenter={lngCenter} mapaTipo={2}/>
            }

            {props.mapa &&
                <MapaOpenStreet array={newArray} latCenter={latCenter} lngCenter={lngCenter} mapaTipo={2}/>
                // <MapaGoogle array={newArray} latCenter={latCenter} lngCenter={lngCenter} mapaTipo={2}/>
            }
        </div>
    )
}