import { ScrollArea as ScrollAreaMantine } from "@mantine/core";
import styled from "styled-components";

export const ScrollArea = styled(ScrollAreaMantine)`
    .mantine-ScrollArea-thumb {
        background-color: ${({ theme }) => theme.cor22} !important;
    }
    .mantine-ScrollArea-scrollbar {
        background-color:transparent;
        &:hover {
            background-color: transparent;
        }
    }
`;