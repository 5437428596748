import { isTemaClaro } from "./dashboard/CardGenerico/utils";

const branco = "#FFFFFF";
const vermelho = "#D32F2F";
const vermelho2 = "#E57373";
const verde = "#33CC99";
const verde2 = "#13805B";
const verde3 = "#62E5BA";
const verde4 = "#4CAF50";
const verde5 = "#81C784";

const branco2 = "#F8FAFB";
const corTextoAzul = "#243B53";

// -------------------------------------
export function getDetalhamentoGraficoBarra() {
  const temaClaro = isTemaClaro();

  const strokeColor = temaClaro ? "#000000" : branco;

  const verde = "#36D896";
  const vermelho = "#cd2d37";
  const cinzaClaro = "#ECF0EE";
  const cinzaEscuro = "#1C1E1D";

  const detalhamento = {
    graficoBarra: {
      corFundo: temaClaro ? cinzaClaro : cinzaEscuro,
      verde,
      vermelho,
      strokeColor,
    },
  };

  return detalhamento.graficoBarra;
}
// -------------------------------------

export function getCoresGraficoMigracaoPlano(grafico, isUltimos6Ciclos) {
  const temaClaro = isTemaClaro();

  const AZUL_CLARO = "#00B0FF";
  const AZUL_ESCURO = "#4FC3F7";
  const VERMELHO = "#D32F2F";
  const VERDE = "#4CAF50";
  const VERDE_CLARO = "#33CC99";
  const VERDE_ESCURO = "#81C784";
  const AMARELO = "#FBC02D";
  const AMARELO_CLARO = "#FFC107";
  const AMARELO_ESCURO = "#FFD54F";
  const LARANJA_CLARO = "#ED6C02";
  const LARANJA_ESCURO = "#FFB74D";
  const ROXO = "#AB47BC";
  const ROXO_ESCURO = "#9575CD";
  const AMARELO_ESCURO_2 = "#FFEB3B";
  const VERMELHO_ESCURO = "#E57373";

  const cores = {
    PlanoReal: {
      temaClaro: {
        azul: AZUL_CLARO,
        amarelo: AMARELO,
        roxo: ROXO,
      },
      temaEscuro: {
        azul: AZUL_ESCURO,
        amarelo: AMARELO_ESCURO_2,
        roxo: ROXO_ESCURO,
      },
    },
    Economia: {
      temaClaro: {
        azul: AZUL_CLARO,
        laranja: LARANJA_CLARO,
        amarelo: AMARELO_CLARO,
        vermelho: VERMELHO,
        verde: VERDE,
      },
      temaEscuro: {
        azul: AZUL_ESCURO,
        amarelo: isUltimos6Ciclos ? AMARELO_ESCURO : AMARELO_CLARO,
        laranja: isUltimos6Ciclos ? LARANJA_ESCURO : LARANJA_CLARO,
        vermelho: VERMELHO_ESCURO,
        verde: VERDE_ESCURO,
      },
    },
    Excedente: {
      temaClaro: {
        laranja: isUltimos6Ciclos ? AMARELO_CLARO : LARANJA_CLARO,
        vermelho: VERMELHO,
        verde: VERDE,
      },
      temaEscuro: {
        laranja: isUltimos6Ciclos ? AMARELO_ESCURO : LARANJA_CLARO,
        vermelho: VERMELHO_ESCURO,
        verde: VERDE_ESCURO,
      },
    },
    Status: {
      temaClaro: {
        azul: AZUL_CLARO,
        vermelho: VERMELHO,
        verde: VERDE_CLARO,
      },
      temaEscuro: {
        azul: AZUL_ESCURO,
        vermelho: VERMELHO_ESCURO,
        verde: VERDE_CLARO,
      },
    },
  };

  const coresTema = cores[grafico];
  const tema = temaClaro ? "temaClaro" : "temaEscuro";

  return coresTema[tema];
}

export function getCorFundoExibicaoSimultanea() {
  return isTemaClaro() ? "#F4F6F5" : "#1E1E1E";
}

export function getCorFundoGrafico() {
  return isTemaClaro() ? "#FFFFFF" : "#171717";
}

export function getCorFundoTooltip() {
  return isTemaClaro() ? "#eee" : "#1C1E1D";
}

export function getCorTexto() {
  return isTemaClaro() ? corTextoAzul : branco2;
}

export function getCoresInfoHeader() {
  const temaClaro = isTemaClaro();

  return {
    texto: temaClaro ? "#5F768E" : "#D9E2EC",
    vermelho: temaClaro ? vermelho : vermelho2,
    verdeEconomia: temaClaro ? verde4 : verde5,
    verdeExcedente: temaClaro ? verde4 : verde5,
  };
}

export function getCoresOpcoesMenuGrafico() {
  const temaEscuro = !isTemaClaro();

  return {
    backgroundColor: temaEscuro ? "#333" : branco,
    corTexto: getCorTexto(),
    corIconeSeta: temaEscuro ? branco2 : "#0000008A",
  };
}

export const selecionarCicloEstilosCoresTema = {
  claro: {
    calendarContainer: { color: corTextoAzul },
    calendarMonth: {
      disabled: {
        color: "#4F627561",
      },
      current: {
        backgroundColor: "transparent",
        color: "#13805B",
      },
      selected: {
        backgroundColor: "#33CC994D",
        color: "#243B53",
      },
    },
  },

  escuro: {
    calendarContainer: { color: branco2 },
    calendarMonth: {
      disabled: {
        color: "#F8FAFB80",
      },
      current: {
        backgroundColor: "",
        color: "#62E5BA",
      },
      selected: {
        backgroundColor: "#33CC9966",
        color: "#F8FAFB",
      },
    },
  },
};
