import * as S from "./styles";
import { GlobalStyles } from "./styles";

export default function Divider({
  variant,
  size = "xs",
  marginY = "xs",
  label,
  labelPosition,
  orientation,
  color = "#0000001F",
}) {
  return (
    <>
      <GlobalStyles />
      <S.Divider
        color={color}
        my={marginY}
        orientation={orientation}
        label={label}
        labelPosition={labelPosition}
        size={size}
        variant={variant}
      />
    </>
  );
}
