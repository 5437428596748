//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import {api} from '../../conexoes/api';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';/*eslint-disable*/
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';/*eslint-disable*/
import RetornarParametro from '../../utils/decryptJWT'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarPermissaoElementoAcao, limpadorFiltros, revelarExcluirRegra, revelarHabilitarRegra, revelarDesabilitarRegra, mudarManejador, desabilitarAcoes, limpouBusca, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro} from "../../redux/actions/index";//IMPORTAÇÕES COMPONENTES
import MensagemSucesso from '../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../componentesModais/mensagens/mensagemErro'
import PaginacaoTabela from '../../utils/paginacaoTabela'
import NovaRegra from './novaRegra/novaRegra'
import EditarRegra from './editarRegra/editarRegra'
import BarraDadosCliente from '../componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../componentesModais/barraFiltragem/barraFiltragem'
import { validadorPermissaoElemento } from '../../utils/verificadorPermissaoElemento'
//ESTILIZAÇÃO
import '../../css/painelAutomacao/tabelaAutomacao.css'
// SPINNER
import SpinerVeye from '../../components/spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

const valorLimite = 3
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function TabelaAutomacao(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(0);
    const [valorLimite, setValorLimite] = useState(50);  
   
    const [cdAcao, setCdAcao] = useState([])
    const [cdRegraAcao, setCdRegraAcao] = useState([])
    const [flAtivoRegraAcao, setFlAtivoRegraAcao] = useState([])
    const [cdUsuario, setCdUsuario] = useState()
    const [dsParametro, setDsParametro] = useState()
    const [cdAutEvento, setCdAutEvento] = useState()
    const [dsAutEvento, setDsAutEvento] = useState()
    const [cdAutEventoParametro, setCdAutEventoParametro] = useState()
    const [cdAutEventoParametroValor, setCdAutEventoParametroValor] = useState()
    const [dsValor, setDsValor] = useState()
    const [cdAutRegra, setCdAutRegra] = useState()
    const [cdAutRegraNivel, setCdAutRegraNivel] = useState()
    const [cdAutRegraParametroValor, setCdAutRegraParametroValor] = useState()
    const [cdAutRegraUsuario, setCdAutRegraUsuario] = useState()
    const [flParametro, setFlParametro] = useState()

    const [mensagemErro, setMensagemErro] = useState('')
    const [mensagemSucesso, setMensagemSucesso] = useState('')
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const codigoAntigo = RetornarParametro("codigoCliente")
    let itensVar = []
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarNovaRegra = useSelector(state => state.automacaoState.revelarNovaRegra);
    let revelarEditarRegra = useSelector(state => state.automacaoState.revelarEditarRegra);
    let revelarExcluirRegraHook = useSelector(state => state.automacaoState.revelarExcluirRegra);
    let revelarHabilitarRegraHook = useSelector(state => state.automacaoState.revelarHabilitarRegra);
    let revelarDesabilitarRegraHook = useSelector(state => state.automacaoState.revelarDesabilitarRegra);
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);

    let temFiltros = false;
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    const despacho = useDispatch();

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(501) // 'painelAutomacaoTabelaAutomacao'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio' || filtros.vazio === undefined) {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        async function handlerVerificarPermissaoAcao(){
            despacho(mudarPermissaoElementoAcao( !await validadorPermissaoElemento(permissaoElemento,"veye/automacao/acoes") ))
        }
        handlerVerificarPermissaoAcao()
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("moduloAutomacao"));
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            } 

            if(conteudoBusca  && !temFiltros){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/aut_regra?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    filtros: filtros,
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/aut_regra", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                

                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/aut_regra?paginaAtual='+contador+'&carregarLimit='+valorLimite);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

    const colunas = [
        {
            dataField: 'cd_aut_regra',
            headerClasses: 'nao-selecionavel',
            text: 'Cód. Regra',
            hidden: true,
            formatter: (cell, row) => {
                return <p className={row.FL_ATIVO_REGRA == 0 ? "fonte-cor-7 label-tabela-1" : "fonte-cor-1 label-tabela-1"}>{cell !== '' && cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_aut_evento',
            headerClasses: 'nao-selecionavel',
            text: 'Regra',
            sort: true,
            formatter: (cell, row) => {
                return <p className={row.FL_ATIVO_REGRA == 0 ? "fonte-cor-7 label-tabela-1" : "fonte-cor-1 label-tabela-1"}>{cell !== '' && cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'PARAMETRO',
            headerClasses: 'nao-selecionavel',
            text: 'Parâmetro',
            sort: true,
            formatter: (cell, row) => {
                return <p className={row.FL_ATIVO_REGRA == 0 ?"fonte-cor-7 label-tabela-1" : "fonte-cor-1 label-tabela-1"}>{cell !== '' && cell !== undefined && cell !== null ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'cd_aut_regra_nivel',
            headerClasses: 'nao-selecionavel',
            text: 'Nível',
            sort: true,
            formatter: (cell, row) => {
                if(cell === 3){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-verde"/><p className={row.FL_ATIVO_REGRA == 0 ? "fonte-cor-7 label-tabela-1" : "fonte-cor-1 label-tabela-1"}>Normal</p></div>)
                }else if(cell === 2){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-amarela"/><p className={row.FL_ATIVO_REGRA == 0 ? "fonte-cor-7 label-tabela-1" : "fonte-cor-1 label-tabela-1"}>Atenção</p></div>)
                }else if(cell === 1){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-vermelha"/><p className={row.FL_ATIVO_REGRA == 0 ? "fonte-cor-7 label-tabela-1" : "fonte-cor-1 label-tabela-1"}>Urgente</p></div>)
                }else{
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-cinza"/><p className={row.FL_ATIVO_REGRA == 0 ? "fonte-cor-7 label-tabela-1" : "fonte-cor-1 label-tabela-1"}>-</p></div>)
                }
            }
        },
        {
            dataField: 'ACAO',
            headerClasses: 'nao-selecionavel',
            text: 'Forma de envio',
            sort: true,
            formatter: (cell, row) => {
                return <p className={row.FL_ATIVO_REGRA == 0 ? "fonte-cor-7 label-tabela-1" : "fonte-cor-1 label-tabela-1"}>{cell !== '' && cell !== undefined && cell != null ? cell.replace(',', ', ').trim() : "-"}</p>;
            }
        }
    ]

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionarRegra(
                    rowIndex, row
                )
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    function handleSelecionarRegra(rowIndex, row){
        ColetaClickstream(506) // 'painelAutomacaoTabelaAutomacaoHandleSelecionarRegra'
        let codigosAcao=[]
        row.CD_ACAO.split(",").map((item) => {
            codigosAcao.push(item)
        })
        let codigosRegraAcao=[]
        row.CD_REGRA_ACAO.split(",").map((item) => {
            codigosRegraAcao.push(item)
        })
        let flagRegraAcao=[]
        row.FL_ATIVO_REGRA_ACAO.split(",").map((item) => {
            flagRegraAcao.push(item)
        })
        setCdAcao(codigosAcao)
        setCdRegraAcao(codigosRegraAcao)
        setFlAtivoRegraAcao(flagRegraAcao)
        setCdUsuario(row.CD_USUARIO)
        setDsParametro(row.PARAMETRO)
        setCdAutEvento(row.cd_aut_evento)
        setDsAutEvento(row.ds_aut_evento)
        setCdAutEventoParametro(row.cd_aut_evento_parametro)
        setCdAutEventoParametroValor(row.cd_aut_evento_parametro_valor)
        setDsValor(row.ds_valor)
        setCdAutRegra(row.cd_aut_regra)
        setCdAutRegraNivel(row.cd_aut_regra_nivel)
        setCdAutRegraParametroValor(row.cd_aut_regra_parametro_valor)
        setCdAutRegraUsuario(row.cd_aut_regra_usuario)
        setFlParametro(parseInt(row.fl_parametro))
        despacho(desabilitarAcoes(false));
    }
    
    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            let codigosAcao=[]
            itens[rowIndex].CD_ACAO.split(",").map((item) => {
                codigosAcao.push(item)
            })
            let codigosRegraAcao=[]
            itens[rowIndex].CD_REGRA_ACAO.split(",").map((item) => {
                codigosRegraAcao.push(item)
            })
            let flagRegraAcao=[]
            row.FL_ATIVO_REGRA_ACAO.split(",").map((item) => {
                flagRegraAcao.push(item)
            })
            setCdAcao(codigosAcao)
            setCdRegraAcao(codigosRegraAcao)
            setFlAtivoRegraAcao(flagRegraAcao)
            setCdUsuario(itens[rowIndex].CD_USUARIO)
            setDsParametro(itens[rowIndex].PARAMETRO)
            setCdAutEvento(itens[rowIndex].cd_aut_evento)
            setDsAutEvento(itens[rowIndex].ds_aut_evento)
            setCdAutEventoParametro(itens[rowIndex].cd_aut_evento_parametro)
            setCdAutEventoParametroValor(itens[rowIndex].cd_aut_evento_parametro_valor)
            setDsValor(itens[rowIndex].ds_valor)
            setCdAutRegra(itens[rowIndex].cd_aut_regra)
            setCdAutRegraNivel(itens[rowIndex].cd_aut_regra_nivel)
            setCdAutRegraParametroValor(itens[rowIndex].cd_aut_regra_parametro_valor)
            setCdAutRegraUsuario(itens[rowIndex].cd_aut_regra_usuario)
            setFlParametro(parseInt(row.fl_parametro))
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function inserirData(data){
        // setItens('')
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                itensVar[k] = data[j]
                k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }

    async function contarItens(){
        let arrayItens = []
        let apiData = [];
        if (controladorPagina <= paginaAtual && filtros.vazio) { 
            apiData = await api.get('/m1/consultar/aut_regra?buscar='+conteudoBusca+'&contar=true');
        }else{
            const dados = {
                paguinaAtual: contador,
                carregarLimit: valorLimite,
                filtros: filtros,
                contar: true
            }
            apiData = await api.post("/m1/filtrar/aut_regra", dados);
        }
        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        despacho(desabilitarAcoes(true));
        setPaginaAtual(1)
    }

    useEffect(() => {
        if(cdAutRegra > 0){
            setSpinAtivo(true)
            async function handleExcluirRegra(){
                await api.get('/m1/deletar/aut_regra?cd_aut_regra='+cdAutRegra)
                .then(function (response) {
                    setMensagemSucesso("Regra deletada")
                    setRevelarMensagemSucesso(true)
                    despacho(revelarExcluirRegra(false))
                    setRenderizar(!renderizar)
                    setSpinAtivo(false)
                })
                .catch(function (error) {
                    despacho(revelarExcluirRegra(false))
                    setMensagemErro(error.response.data.info)
                    setRevelarMensagemErro(true)
                    setSpinAtivo(false)
                });
            }
            handleExcluirRegra()
        }
    }, [revelarExcluirRegraHook])

    useEffect(() => {
        if(cdAutRegra > 0){
            setSpinAtivo(true)
            async function handleDesabilitarRegra(){
                await api.post('/m1/alterar/aut_regra/aut_regra_normal',{
                    dados:{
                        fl_ativo: '0'
                    },
                    condicao: {
                        cd_aut_regra: cdAutRegra
                    }
                })
                .then(function (response) {
                    setMensagemSucesso("Regra desabilitada")
                    setRevelarMensagemSucesso(true)
                    despacho(revelarDesabilitarRegra(false))
                    setRenderizar(!renderizar)
                    setSpinAtivo(false)
                })
                .catch(function (error) {
                    despacho(revelarDesabilitarRegra(false))
                    setMensagemErro(error.response.data.info)
                    setRevelarMensagemErro(true)
                    setSpinAtivo(false)
                });
            }
            handleDesabilitarRegra()
        }
    }, [revelarDesabilitarRegraHook])

    useEffect(() => {
        if(cdAutRegra > 0){
            setSpinAtivo(true)
            async function handleHabilitarRegra(){
                await api.post('/m1/alterar/aut_regra/aut_regra_normal',{
                    dados:{
                        fl_ativo: '1'
                    },
                    condicao: {
                        cd_aut_regra: cdAutRegra
                    }
                })
                .then(function (response) {
                    setMensagemSucesso("Regra habilitada")
                    setRevelarMensagemSucesso(true)
                    despacho(revelarHabilitarRegra(false))
                    setRenderizar(!renderizar)
                    setSpinAtivo(false)
                })
                .catch(function (error) {
                    despacho(revelarHabilitarRegra(false))
                    setMensagemErro(error.response.data.info)
                    setRevelarMensagemErro(true)
                    setSpinAtivo(false)
                });
            }
            handleHabilitarRegra()
        }
    }, [revelarHabilitarRegraHook])

    function handleMensagemSucesso(confirmacao){
        ColetaClickstream(511) // 'painelAutomacaoTabelaAutomacaoHandleMensagemSucesso'
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    return(
        <div className='container-tela'>
            <BarraDadosCliente/>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={() =>  setRevelarMensagemSucesso(!revelarMensagemSucesso)}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={mensagemSucesso}
                            mensagemBotao={"Continuar"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Ocorreu um erro. Tente novamente mais tarde."}
                            mensagemParagrafo2={"Caso o erro persista, entre em contato com o Suporte."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {revelarNovaRegra === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <NovaRegra
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                        />
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            {revelarEditarRegra === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <EditarRegra
                            cdAcao={cdAcao}
                            cdRegraAcao={cdRegraAcao}
                            flAtivoRegraAcao={flAtivoRegraAcao}
                            cdUsuario={cdUsuario}
                            dsParametro={dsParametro}
                            cdAutEvento={cdAutEvento}
                            dsAutEvento={dsAutEvento}
                            cdAutEventoParametro={cdAutEventoParametro}
                            cdAutEventoParametroValor={cdAutEventoParametroValor}
                            dsValor={dsValor}
                            cdAutRegra={cdAutRegra}
                            cdAutRegraNivel={cdAutRegraNivel}
                            cdAutRegraParametroValor={cdAutRegraParametroValor}
                            cdAutRegraUsuario={cdAutRegraUsuario}
                            renderizar={renderizar}
                            flParametro={flParametro}
                            setRenderizar={setRenderizar}
                        />
                    </div>
                </div>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_aut_regra' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                           <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        conteudoBusca: state.manejadorState.conteudoBusca, 
        filtros: state.filtrosState.filtros, 
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaAutomacao));