
import React from 'react';
import moment from 'moment'

export default function Timeline(props){
    return (
        <>
            <div className="container-pedaco-tooltip-timeline">
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">Status:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.status}</p>
                </div>
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">Inicio:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.dataInicio === '-' ? '-' : moment(props.dataInicio).format("DD/MM/YYYY HH:mm:ss")}</p>
                </div>
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">Fim:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.dataFim === '-' ? '-' : moment(props.dataFim).format("DD/MM/YYYY HH:mm:ss")}</p>
                </div>
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">Duração:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.duracao}</p>
                </div>
            </div>
            <div className="container-pedaco-tooltip-timeline">
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">Tráfego total em MB:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.trafegoMb}</p>
                </div>
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">Tráfego total em KB:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.trafegoKb}</p>
                </div>
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">Pacotes enviados:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.pacotesEnviados}</p>
                </div>
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">Pacotes recebidos:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.pacotesRecebidos}</p>
                </div>
            </div>
            <div className="container-pedaco-tooltip-timeline-2">
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">Tecnologia:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.tecnologia}</p>
                </div>
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">Imei:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.imei}</p>
                </div>
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">APN:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.apn}</p>
                </div>
                <div className="linha-tooltip-timeline">
                    <p className="titulo-tooltip-timeline fonte-cor-1">IP:</p>
                    <p className="texto-tooltip-timeline fonte-cor-1">{props.ip}</p>
                </div>
            </div>
        </>
    )
};