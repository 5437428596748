//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import {formatarData} from '../../../../utils/formatadorDataTabela'
//ICONES
import {elipseVerde, fecharJanela, setaDropdown} from '../../../../utils/alternanciaIcones';

//REDUX
import { useDispatch } from 'react-redux';
import { novaSolicitacaoVirtueyes } from "../../../../redux/actions/index";
//MODAIS
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import SelectOperadora from '../../../componentesModais//selects/selectOperadora'
import decryptJWT from '../../../../utils/decryptJWT'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'
//CSS
import "../../../../css/painelVirtueyes/solicitacaoOperadora/novaSolicitacao/novaSolicitacao.css"
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'

let atualizarParent = true

export default function AtivarLinhas(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const { handleSubmit } = useForm();
    //HOOKS PRINCIPAIS
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [itens, setItens] = useState([])
    const [cdOperadora, setCdOperadora] = useState([])
    const [dsOperadora, setDsOperadora] = useState("Selecione")
    const [revelarSelectOperadora, setRevelarSelectOperadora] = useState(false)
    const [revelarValidacaoOperadora, setRevelarValidacaoOperadora] = useState(false);
    const [revelarTabela, setRevelarTabela] = useState(false);
    const [renderizar, setRenderizar] = useState(false);
    const [simcardsSolicitacoes, setSimcardsSolicitacoes] = useState([]);

    //HOOKS MODAIS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemErroSemLinhas, setRevelarMensagemErroSemLinhas] = useState(false)
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    const colunas = [{
        dataField: 'CODIGO_CLIENTE',
        text: 'Cód. cliente',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'RAZAO_SOCIAL',
        text: 'Razão social',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'NOME_FANTASIA',
        text: 'Nome fantasia',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'LINHA',
        text: 'SIM card',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'ICCID',
        text: 'ICCID',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'OPERADORA',
        text: 'Operadora',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'ULTIMO_EVENTO',
        text: 'Último evento',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return formatarData(cell);
        }
    },
    {
        dataField: 'SIMCARD_TIPO',
        text: 'Tipo de SIM card',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            if(cell != null){
                if(cell===0){
                    return <p className="fonte-cor-1 label-tabela-1">Normal</p>
                }
                else{
                    return <p className="fonte-cor-1 label-tabela-1">Triplo corte</p>
                }
            }
            else{
                return <p className="fonte-cor-1 label-tabela-1">Sem informação</p>;
            }
        }
    },
    {
        dataField: 'GEO',
        headerClasses: 'nao-selecionavel',
        text: 'Tecnologia',
        sort: true,
        formatter: (cell, row) => {
            const split = cell.split(',')
            const tecnologia = split[2]
            return <p className="fonte-cor-1 label-tabela-1">{(tecnologia != '' && tecnologia != 'NN') ? tecnologia : "-"}</p>;
        }
    },
    {
        dataField: 'CD_SOLICITACAO_CLIENTE',
        text: 'ID solicitação cliente',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'DT_INICIO',
        text: 'Data solicitação cliente',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return formatarData(cell);
        }
    },]

    function handleSalvar(){
        ColetaClickstream(1393) // 'solicitacaoOperadoraTabelaNovaSolicitacaoConfirmar
        if(simcardsSolicitacoes.length > 0){
            salvarSolicitacao();
        }
        else{
            handleMensagemErroSemLinhas(false)
        }
    }

    async function salvarSolicitacao(){
        setSpinAtivo(true)
        await api.post('/m1/cadastrar/solicitacao', {
            'fl_operadora': 1,
            'ds_email' : decryptJWT('email'),
            'simcardsSolicitacoes' : simcardsSolicitacoes,
            'cd_crm_solicitacao_tipo' : 4,
            'cd_crm_solicitacao_status' :  1,
            'ds_crm_solicitacao' :  'Solicitação de Manutenção',
            'dt_inicio' :  moment().format('YYYY-MM-DD HH:mm:ss'),
            'dt_fim' :  undefined,
            'dt_previsao' :  undefined,
            'cd_dsp_operadora': cdOperadora,
            'cd_pav_usuario_created' :  decryptJWT('codigo'),
            ds_rota_permissao:"Não possiu",
        }, {timeout: 20000})
        .then(function (response) {
            setSpinAtivo(false)
            atualizarParent = true
            handleMensagemSucesso(true)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro("Erro na solicitação")
            handleMensagemErro(false)
        });
    }

    async function handleCancelar() {
        ColetaClickstream(1394) // 'solicitacaoOperadoraTabelaNovaSolicitacaoCancelar
        setSpinAtivo(false)
        if(atualizarParent === true){
            props.setRenderizar(!props.renderizar)
        } 
        despacho(novaSolicitacaoVirtueyes(false))
    }

    function handleIdSimcardsSolicitacoes(dados){
        let array = []
        for (let i = 0; i < dados.length; i++) {
            const body = {
                simcard: dados[i].CD_DSP_SIMCARD,
                cd_crm_solicitacao_cliente: dados[i].CD_SOLICITACAO_CLIENTE
            }
            array.push(body)
        }
        setSimcardsSolicitacoes(array);
    }

    function carregar() {
        setSpinAtivo(true)
        async function preencherTabela (){
            const dataPeriodo = await api.get('m1/consultar/solicitacao_simcard?buscarTodasCliente=true&cd_dsp_operadora='+cdOperadora);
            if(dataPeriodo.data.dados != undefined){
                setItens(dataPeriodo.data.dados)
                setQuantidadeLinhasSelecionados(dataPeriodo.data.dados.length)
                handleIdSimcardsSolicitacoes(dataPeriodo.data.dados);
            }
        }
        preencherTabela()
        setSpinAtivo(false)        
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErroSemLinhas(confirmacao){
        if(revelarMensagemErroSemLinhas === false){
            setRevelarMensagemErroSemLinhas(true)
        }else{
            setRevelarMensagemErroSemLinhas(false)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
    }
    
    function buscarLinhas(){
        if(dsOperadora == "Selecione"){
            setRevelarValidacaoOperadora(true);
        }
        else{
            let modal = document.querySelector('.modal-nova-solicitacao');
            modal.setAttribute('style', 'width: 53.9375em !important')
            setRevelarTabela(true);
            carregar();
        }
    }

    function handleSelectOperadora(){
        setRevelarSelectOperadora(!revelarSelectOperadora)
        setRevelarValidacaoOperadora(false)
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-nova-solicitacao fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Nova solicitação</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="nova-solicitacao-select-operadora">       
                            <Form.Label><div className="fonte-cor-1 label-normal">Operadora</div></Form.Label>
                            { revelarSelectOperadora === false &&
                                <Button className="campo-texto-cor-3 campo-select" onClick={handleSelectOperadora}>
                                    <div className="fonte-cor-1 label-campo">{dsOperadora}</div>
                                    <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }
                            {revelarValidacaoOperadora &&
                                <span className="span-validacao-buscar-simcards-solicitacoes">Selecione uma operadora</span>
                            }
                            { revelarSelectOperadora&&
                                <SelectOperadora
                                    setRevelarSelectOperadora={setRevelarSelectOperadora} //Hook para fechar a modal
                                    setDsOperadora={setDsOperadora} 
                                    setCdOperadora={setCdOperadora} 
                                    dsOperadora={dsOperadora} 
                                    cdOperadora={cdOperadora} 
                                    phOperadora={"Selecione"} //Placeholder para mostrar o valor original em caso de edição
                                />
                            }
                        </div>
                     </Col>
                     <Col>
                        <div className="nova-solicitacao-burcar-linha-operadora">
                            <Button className="botao-confirmar-burcar-linha" variant="success" size="sm" onClick={() => buscarLinhas()}>
                                <div className="fonte-cor-4">Buscar linhas</div>
                            </Button>
                        </div>
                     </Col>
                </Form.Row>
                {revelarTabela &&
                    <Form.Row>
                        <Col>
                            <div className="container-tabela-modal">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    condensed={true}
                                    keyField='id' 
                                    data={itens} 
                                    columns={colunas} 
                                    bootstrap4={true}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                />
                            </div>
                        </Col>
                    </Form.Row>
                }
                {revelarTabela &&
                    <Form.Row>
                        <Col>
                            <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} linhas selecionadas</span>
                        </Col>
                    </Form.Row>
                }
                <Form.Row className="espaco-ativar-linha">
                    <Col>
                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErroSemLinhas &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErroSemLinhas}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Nenhuma linha selecionada para esta solicitação"}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
