//IMPORTAÇÕES
import React, { Component } from "react";
import Dropzone from "react-dropzone";/*eslint-disable*/
import { Button, Card, Dropdown, Accordion, Image } from  "react-bootstrap";/*eslint-disable*/
import { uniqueId } from 'lodash';
import { CSVLink } from 'react-csv'
import {api} from '../conexoes/api';
import {CircularProgressbar} from "react-circular-progressbar";
import XLSX  from 'xlsx'

//ICONES
import { MdCheckCircle, MdError } from "react-icons/md";
import { importarArquivo} from '../utils/alternanciaIcones';

//CSS
import "react-circular-progressbar/dist/styles.css";

// SPINNER
import SpinerVeye from '../components/spinnerVeye/spinnerVeye'
import { exportarXlsx } from "../utils/exportarArquivo";

let spinAtivo = false 

var rotasPreservadas = []

export default class ImportarArquivoVeye extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadedFiles: [],
            dadosArquivo: [],
            retornoUpload: [],
            nomeArquivo: props.nomeArquivo,
            setDataAtivacao: props.setDataAtivacao,
            extensaoArquivo: '',
            dados: [],
            dadosJSONXLS: props.templateXLS,
            dadosJSONCSV: props.templateCSV,
        };
    }

    renderDragMessage = (isDragActive) => {
        if (!isDragActive) {
        return <div className="fonte-branca-tema-branco UploadMessage"> Importar </div>;
        }
        return <div className="UploadMessage" type="success"> Solte aqui </div>;
    };

    componentDidUpdate(prevProps, prevState, sS){
        if(this.state.dados != [] && this.state.dados != prevState.dados){
            this.props.onChange(this.state.dados, this.state.nomeArquivo, this.state.extensaoArquivo);
            this.handleProcessarUpload();
        }
    }

    handleUpload = files => {
        const funcao = async file => {
            const reader = new FileReader()
            reader.onload = (evt) => {
                const data = evt.target.result;
                const readedData = XLSX.read(data, {type: 'array'});
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                const dados = XLSX.utils.sheet_to_json(ws, [{header:1}]);
                
                const objetoFile = {
                    file, 
                    id: uniqueId(), 
                    name: file.name, 
                    progress: 0, 
                    uploaded: false,
                    error: false, 
                    url: null, 
                    dados: dados
                }
                this.setState({
                    nomeArquivo: file.name,
                    extensaoArquivo: file.name.split('.')[1],
                    dados: dados,
                    retornoUpload: this.state.retornoUpload.concat(objetoFile),
                    uploadedFiles: this.state.uploadedFiles.concat(objetoFile)
                })  
                
            };
            reader.readAsArrayBuffer(file)
        }

        const getFuncaoRetorno = async () => {
            return Promise.all(files.map(file => funcao(file)));
        } 

        getFuncaoRetorno().then(dados =>{
            return;
        })
    };

    handleProcessarUpload = () => {
        const arquivos = this.state.uploadedFiles;
        arquivos.map(arquivo => {
            if(arquivo.dados.length == 0){
                this.setState({
                    uploadedFiles: this.state.uploadedFiles.splice(arquivo.id, 1),
                    dadosArquivo: this.state.dadosArquivo.splice(arquivo.id, 1)
                })
            }
            else{
                this.processUpload(arquivo)
            }
        })
    }

    updateFile = (id, data) => {
        this.setState({
        uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
            return id === uploadedFile.id
            ? { ...uploadedFile, ...data }
            : uploadedFile;
        })
        });
    };

    dadosArquivo = (id, data) => {
        this.setState({
        dadosArquivo: this.state.dadosArquivo.map(uploadedFile => {
            return id === uploadedFile.id
            ? { ...uploadedFile, ...data }
            : uploadedFile;
        })
        });
    };

  processUpload = (uploadedFile) => {
    const data = new FormData();
    data.append("file", uploadedFile.file, uploadedFile.name);
    api.post("m1/importar/importarDescricao", data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          this.updateFile(uploadedFile.id, {
            progress
          });
        }
      })
      .then(response => {
        if (response.data.status === 200) {
           this.updateFile(uploadedFile.id, {
            uploaded: true,
            id: response.data._id,
            url: response.data.url
          });

          this.setState({
            dadosArquivo: this.state.dadosArquivo.concat(response.data.dados)
          });
          rotasPreservadas.push(response.data.dados.path)
        }
        else{
          this.updateFile(uploadedFile.id, {
            error: true
          });
        }
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data._id,
          url: response.data.url
        });
        
      })
      .catch((error) => {
        this.updateFile(uploadedFile.id, {
          error: true
        });
      });
  };

  handleDelete = async e => {
    e.preventDefault()
    let arquivos = this.state.uploadedFiles
    let deletar = this.state.dadosArquivo
    const id = e.target.id
    spinAtivo = true
    this.setState({
      uploadedFiles: [],
      dadosArquivo: []
    })
    spinAtivo = false
    if(deletar != undefined && deletar.length > 0){
      await api.get(`m1/deletar/importarDescricao?path=`+deletar[id].path)
        .then(response => {
          spinAtivo = false
          arquivos.splice(id, 1);
          deletar.splice(id, 1);
        })
        .catch((error) => {
          spinAtivo = false
        });
    }

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => this.state.uploadedFiles[e] !== e),
      dadosArquivo: this.state.dadosArquivo.filter(file => this.state.dadosArquivo[e] !== e),
      setDataAtivacao: ''
    });
  };

  async Cancelar() {
    await rotasPreservadas.map(async (item, i)=>{
      await api.get(`m1/deletar/importarDescricao?path=`+rotasPreservadas[i])
        .then(response => {
          rotasPreservadas.splice(i, 1);
        })
      })
    rotasPreservadas = []
  }
  
  render() {
    return (
      <Card className="CardPrincipal-importar-arquivo fundo-cor-6">
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
        <div className="div-Template-importar-descricao">
          <Dropdown alignRight variant="success">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle className="botaoTemplate-importar-arquivo bt-dropdown-template-importar-arquivo" as={Button} variant="link" eventKey="1"> <div className="UploadMessage fonte-cor-1"> Template </div> </Accordion.Toggle>
              <Accordion.Collapse className="botaoTemplate-importar-arquivo menu-dropdown-template-pedidos" eventKey="1">
                <>
                  <div className="triangulo-dropdown-configuracao-cabecalho-sistema triangulo-dropdown-template"></div>
                  <Button className="botaoDownload fonte-cor-1" onClick={() => exportarXlsx(this.state.dadosJSONXLS, "template.xls")}> XLS </Button>
                    <Dropdown.Divider className="template-border"/>
                  <CSVLink filename="template.csv" className="botaoDownload fonte-cor-1" data={this.state.dadosJSONCSV}> CSV </CSVLink>
                </>
              </Accordion.Collapse>
            </Accordion>
          </Dropdown>

          {/* Botao para selecionar arquivos  */}
          <Dropzone onDropAccepted={this.handleUpload}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <Button className="botaoImportar"
                {...getRootProps()}
                isDragActive={isDragActive}
              >
                <input {...getInputProps()} />
                {this.renderDragMessage(isDragActive)}
                <Image className="imagemImportar" src={importarArquivo()} />
              </Button>
            )}

          </Dropzone>
        </div>

        {!!this.state.uploadedFiles.length && (
          <ListaArquivos files={this.state.uploadedFiles} onDelete={(e) => this.handleDelete(e)} />
        )}
      </Card>
    );
  }
}


// funcao para mostrar na tela a lista de arquivos importados 
function ListaArquivos ({ files, onDelete })  {
  return (
    <div className="Container">
      {files.map((uploadedFile,i) => (
        <p className="tituloLista-importar-arquivo"> Arquivo selecionado
        <li className="lista-importar-arquivo" key={uploadedFile.id}>
              <p className="listaNome-importar-arquivo">{uploadedFile.name}</p>
          <div>
            {!uploadedFile.uploaded &&
              !uploadedFile.error && (
                <CircularProgressbar
                  styles={{
                    root: { width: 24 },
                    path: { stroke: "#36D896" }
                  }}
                  strokeWidth={10}
                  percentage={uploadedFile.progress}
                />
              )}
            {uploadedFile.uploaded && <MdCheckCircle size={24} color="#36D896"/>}
            {uploadedFile.error && <MdError size={24} color="#e57878"/>}
            
          </div>
          <div className="botaoLixeira">
                {uploadedFile.readableSize}{" "}
                {!uploadedFile.id &&
                  <button className="excluir" onClick={(e) => onDelete(e)}>
                    <div className="lixeira" id={i}/> 
                  </button>
                }
              </div>
        </li>
        </p>
      ))}
    </div>
)}

