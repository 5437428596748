//IMPORTAÇÕES
/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react'; /*eslint-disable*/
import { api } from '../../../conexoes/api';/*eslint-disable*/
import Switch from '@mui/material/Switch'
import { green } from '@mui/material/colors'
import { alpha, styled } from '@mui/material/styles'


import { useForm } from 'react-hook-form';
import { Button, Form, Col, Tooltip, OverlayTrigger, Tabs, Tab } from 'react-bootstrap';
import {elipseVerde, fecharJanela, importarLogo, setaDropdown, uploadVerde} from '../../../utils/alternanciaIcones'
//MODAIS
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from "../../componentesModais/mensagens/mensagemErro"
import SelectGenerico from '../../componentesModais/selects/selectGenerico'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import { useDispatch } from 'react-redux';
import "../../../css/painelParceiros/cadParceiro/estilo.css"
//import '../estilo.css'
import { MascaraCpf, MascaraCnpj, MascaraTelefone, MascaraCep } from '../../../utils/mascarasCampos'
import { revelarNovoCliente } from "../../../redux/actions/index";
import { revelarEditarCliente } from "../../../redux/actions/index";
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js';
//ICONES
import {lixeiraVermelho, importarArquivo, defaultImage} from '../../../utils/alternanciaIcones'
import {Checkbox} from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

//import MenuCadastroPermissoes from './permissoes'
const axios = require('axios');

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: green[600],
        '&:hover': {
            backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: green[600],
    },
}));


const defaultColor = '#36D896';
const branco = '#FFFFFF';
const azul = '#003769';

export default function CadastroCliente(props) {
    const { register, handleSubmit, errors } = useForm()
    const despacho = useDispatch()
    const modal = useRef()
    const inputImportRef = useRef()
    const inputLogoEscuroImportRef = useRef()
    const inputImagemFundoImportRef = useRef()
    const [spinAtivo, setSpinAtivo] = useState(true)
    const [logoPadraoNome, setLogoPadraoNome] = useState("Selecione");
    const [logoEscuroNome, setLogoEscuroNome] = useState("Selecione");
    const [imagemFundoNome, setImagemFundoNome] = useState("Selecione");
    const [imagemPadrao, setImagemPadrao] = useState(false);
    const localTema = localStorage.getItem('tema')
    // const uploadImage = {imagem_fundo: "", imagem_fundo_nome: ""};
    const [uploadImage, setUploadImage] = useState({imagem_fundo: "", imagem_fundo_nome: ""});

    // VARIAVEIS CADASTRO
    const [dsNomeCliente, setDsNomeCliente] = useState()
    const [dsIDCliente, setDsIDCliente] = useState()
    const [dsIDParceiro, setDsIDParceiro] = useState()
    const [dsSubdominio, setSubdominio] = useState()
    const [dsImagem, setDsImagem] = useState()
    const [chatbotAtivo, setChatbotAtivo] = React.useState (false)
    const radioStyle = {
        color: '#33CC99',
        '&.Mui-checked': {
            color: '#33CC99',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '18px !important',
        },
    }

    const [form, setForm] = useState({})
    const [formErrors, setFormErrors] = useState({})
    const [logoChanged, setLogoChanged] = useState(false)
    const [logoEscuroChanged, setLogoEscuroChanged] = useState(false)
    const [imagemFundoChanged, setImagemFundoChanged] = useState(false)
    const [fileType, setFileType] = useState('')
    const [logoBorder, setLogoBorder] = useState('')

    const [phOperadoras, setPhOperadoras] = useState('Selecione')
    const [cdOperadoras, setCdOperadoras] = useState([])
    const [dsOperadoras, setDsOperadoras] = useState([])

    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [elementoAtivadoPrincipal, setElementoAtivadoPrincipal] = useState(1)
    const [tabAtivadoPrincipal, setTabAtivadoPrincipal] = useState(1)
    const [dsMensagemSucesso, setDsMensagemSucesso] = useState('Cadastro efetuado com sucesso.')
    const [dsMensagemErro, setDsMensagemErro] = useState('Algo deu errado, tente novamente. Se o erro persistir entre em contato com o suporte.')
    const [liberarCadastro, setLiberarCadastro] = useState(true)
    const [flAtivo, setFlAtivo] = useState(true)
    const [tituloJanela, setTituloJanela] = useState("Cadastrar")
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]:value
        })
        if(!!formErrors[field]){
            setFormErrors({
                ...formErrors,
                [field]:null
            })
        }
    }

    useEffect(() => {
        setField('fl_chat', false);
        setSpinAtivo(true)
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    useEffect( () => {
        if (props.tituloJanela === 'Editar') {
            //TROCA O TITULO DA JANELA
            setTituloJanela('Editar')
            //CARREGA OS CAMPOS COM OS VALORES ORIGINAIS

            setImagemPadrao(props.imagemFundoPadrao);
            if(props.dsImagem){
                setLogoPadraoNome(props.dsImagem.toString());
            }
            if(props.dsLogoEscuro){
                setLogoEscuroNome(props.dsLogoEscuro.toString());

            }
            if (props.dsImagemFundo){
                setImagemFundoNome(props.dsImagemFundo.toString());
            }

            let partnerPayload = {
                'cd_pav_cliente_parceiro': props.dsIDParceiro,
                'cd_pav_cliente': props.dsIDCliente,
                'ds_sub_dominio': props.dsSubdominio,
                'nome': props.dsNomeCliente,
                'logo': props.dsImagem,
                'logoChanged': '',
                'fl_chat': false,
                'cor_botoes': props.dsCorBotoes,
                'cor_fundo': props.dsCorFundo,
                'cor_letra': props.dsCorLetra,
                'logo_escuro': props.dsLogoEscuro,
                'imagem_fundo': props.dsImagemFundo,
                'usar_imagem_fundo_padrao': imagemPadrao
            }

            setForm(partnerPayload);

            setUploadImage({...uploadImage, imagem_fundo: '', imagem_fundo_nome: form.imagem_fundo})

            setDsNomeCliente(props.dsNomeCliente)
            setDsIDCliente(props.dsIDCliente)
            setSubdominio(props.dsSubdominio)
            setDsImagem(props.dsImagem)
            setDsIDParceiro(props.dsIDParceiro);
            setChatbotAtivo(Boolean(props.chatbotAtivo))
        }
        else {
            setDefaultColors();
        }
    }, [])

    function setDefaultColors() {
        setForm({
            ...form,
            'cor_botoes': defaultColor,
            'cor_fundo': defaultColor
        })
    }

    async function handleCancelar(){
        setSpinAtivo(false)
        if (tituloJanela === "Cadastrar") {
            ColetaClickstream(1433) // dropAcoesrevelarCadastroClienteNovoCancelar
            despacho(revelarNovoCliente(false))
        } else if (tituloJanela === "Editar") {
            ColetaClickstream(1433)
            despacho(revelarEditarCliente(false))
        }
    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if (confirmacao) {
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
        }
        if (confirmacao === true) {
            //ALGUMA FUNÇÃO
        }
    }

    function handerAlterarTabPrincipal(k) {
        setElementoAtivadoPrincipal(k)
        setTabAtivadoPrincipal(k)
    }

    function handleCpf(valor) {
        setDsCpf(MascaraCpf(valor))
    }

    function handleCnpj(valor) {
        setDsCnpj(MascaraCnpj(valor))
    }

    function handleTelefone(valor) {
        setDsTelefone(MascaraTelefone(valor))
    }

    function handleCep(valor) {
        setDsCep(MascaraCep(valor))
    }

    useEffect(() => {
        let placeholderAux = false
        cdOperadoras.forEach(function (item, index) {
            if (item === -1) {
                placeholderAux = true
            }
        })
        if (placeholderAux === true) {
            setPhOperadoras(cdOperadoras.length - 1 + " Selecionados")
        }
        else if (cdOperadoras.length === 1) {
            setPhOperadoras(dsOperadoras[0])
        }
        else if (cdOperadoras.length === 0) {
            setPhOperadoras("Selecione")
        }
        else {
            setPhOperadoras(cdOperadoras.length + " Selecionados")
        }
    }, [cdOperadoras.length, cdOperadoras[0], cdOperadoras[cdOperadoras.length - 1]])

    async function handlerConsultarCep(cep) {
        let auxCep = cep.replace('-', '')
        setSpinAtivo(true)
        await api.get('/m1/consultar/cep?cep=' + auxCep)
            .then(function (response) {
                if (response.data.status == 200) {
                    if (response.data.dados == 'Cep Invalido') {
                        setDsMensagemErro('Cep Invalido.')
                        setDsLogradouro('')
                        setDsBairro('')
                        setDsCidade('')
                        setDsEstado('Selecione')
                        setCdEstado('')
                        handleMensagemErro()
                    }
                    else {
                        setDsLogradouro(response.data.dados.logradouro)
                        setDsBairro(response.data.dados.bairro)
                        setDsCidade(response.data.dados.localidade)
                        setDsEstado(response.data.dados.uf)
                        setCdEstado(response.data.dados.cd_pav_uf)
                    }
                }
                setSpinAtivo(false)
                return response
            })
            .catch(function (error) {
                setDsMensagemErro('Algo deu errado ao tentar consultar o CEP. Por favor, tente novamente mais tarde.')
                handleMensagemErro()
                setSpinAtivo(false)
                return error
            })

    }

    async function teste() {
        setFlAtivo(!flAtivo)
    }

    async function handleConsultarCnpj(cnpj) {
        let auxCnpj = cnpj.replaceAll('.', '')
        auxCnpj = auxCnpj.replaceAll('/', '')
        auxCnpj = auxCnpj.replaceAll('-', '')
        setSpinAtivo(true)
        await axios.get(`https://cors-anywhere.herokuapp.com/http://www.receitaws.com.br/v1/cnpj/` + auxCnpj)
            .then(function (resposta) {
                if (!resposta.data.status == "ERRO") {
                    setDsNomeFantasia(resposta.data.fantasia)
                    setDsRazaoSocial(resposta.data.nome)
                    setDsEmail(resposta.data.email)
                    setDsTelefone(resposta.data.telefone)
                    let auxCep = resposta.data.cep
                    auxCep = auxCep.replace("-", "")
                    auxCep = auxCep.replace(".", "")
                    handlerConsultarCep(auxCep)
                }
                setSpinAtivo(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
            });
    }

    async function handleCadastrar(partnerObj) {
        ColetaClickstream(1434) // dropAcoesrevelarCadastroClienteNovoCadastrar

        setSpinAtivo(true)


        await api.post('/m1/cadastrar/cliente_parceiro', partnerObj
        , { timeout: 20000 })
            .then(async function (response) {
                setDsMensagemSucesso('Parceiro ' + form.nome + ' cadastrado com sucesso.')
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setDsMensagemErro(error.response.data.info)
                handleMensagemErro(false)
            });
    }


    function validatePartnerForm(partnerObj){

        const urlRegexReserved = /^(?:(?:https?|ftp|www):\/\/)?(?!.*[@&$%#|^~<>+()?\\[\]])[^\s]*(?:\.com|\.veye)[^\s]*$/;;
        const numberRegex = /^[0-9]+$/;
        let newErrors = {}

        // if (!partnerObj?.cd_pav_cliente || partnerObj?.cd_pav_cliente === ''){
        //     newErrors = {
        //         ...newErrors,
        //         ['cd_pav_cliente']: 'O ID é obrigatório.',
        //     }
        // } else if (numberRegex.test(partnerObj?.cd_pav_cliente) === false){
        //     newErrors = {
        //         ...newErrors,
        //         ['cd_pav_cliente']: 'O ID deve ser um número (0-9).',
        //     }
        //
        // }
        if (!partnerObj?.ds_sub_dominio || partnerObj?.ds_sub_dominio === ''){
            newErrors = {
                ...newErrors,
                ['ds_sub_dominio']: 'O subdominio é obrigatório'
            }
        }
        // else if (urlRegexReserved.test(partnerObj.ds_sub_dominio) === false) {
        //     newErrors = {
        //         ...newErrors,
        //         ['ds_sub_dominio']: 'a URL precisa ser um subdominio veye válido'
        //     }
        // }

        if (!partnerObj?.nome || partnerObj?.nome === ''){
            newErrors = {
                ...newErrors,
                ['nome']: 'O nome do cliente é obrigatório'
            }
        }
        if(!partnerObj?.logo && fileType && fileType !== 'image/svg+xml'){
            newErrors = {
                ...newErrors,
                ['logo']: 'Erro no upload. O arquivo deve ser uma imagem no formato SVG'
            }

        } else if ( !partnerObj?.logo && tituloJanela !== 'Editar'){
            newErrors = {
                ...newErrors,
                ['logo']: 'Você precisa fazer upload do logo'
            }
        } else if (!partnerObj?.logo && tituloJanela === 'Editar' && logoChanged){
            newErrors = {
                ...newErrors,
                ['logo']: 'Você precisa fazer upload do logo'
            }
        } else if (!partnerObj.logo_escuro && tituloJanela === 'Editar' && logoEscuroChanged){
            newErrors = {
                ...newErrors,
                ['logo']: 'Você precisa fazer upload do logo no modo escuro'
            }
        } else if(!partnerObj.logo_escuro && tituloJanela !== 'Editar'){
            newErrors = {
                ...newErrors,
                ['logo']: 'Você precisa fazer upload do logo no modo escuro'
            }
        } else if (!partnerObj.imagem_fundo && tituloJanela === 'Editar' && imagemFundoChanged && !imagemPadrao){
            newErrors = {
                ...newErrors,
                ['logo']: 'Você precisa fazer upload da imagem de fundo ou selecionar a opção de imagem padrão'
            }
        } else if (!partnerObj.imagem_fundo && tituloJanela !== 'Editar' && !imagemPadrao){
            newErrors = {
                ...newErrors,
                ['logo']: 'Você precisa fazer upload da imagem de fundo ou selecionar a opção de imagem padrão'
            }
        }
        if(!partnerObj.cor_letra){
            newErrors = {
                ...newErrors,
                ['cor_letra']: 'Você precisa selecionar uma opção de cor de letra'
            }
        }
        if(!validarHexadecimal(partnerObj.cor_botoes)){
            newErrors = {
                ...newErrors,
                ['cor_botoes']: 'Você precisa digitar um codigo hex (#FFFFFF) de cor para os botões'
            }
        }
        if (!validarHexadecimal(partnerObj.cor_fundo)){
            newErrors = {
                ...newErrors,
                ['cor_fundo']: 'Você precisa digitar um codigo hex (#FFFFFF) de cor para o fundo'
            }
        }

        return newErrors
    }

    function handleSalvar() {

        // let partnerPayload = {
        //     'cd_pav_cliente_parceiro': undefined,
        //     'cd_pav_cliente': dsIDCliente,
        //     'ds_sub_dominio': dsSubdominio,
        //     'nome': dsNomeCliente,
        //     'logo': dsImagem,
        //     'fl_chat': chatbotAtivo,
        // }

        form.usar_imagem_fundo_padrao = imagemPadrao;


        const errors = validatePartnerForm(form);


        if(Object.keys(errors).length) {
            setFormErrors(errors)
        } else {
            if (tituloJanela === 'Editar') {
                ColetaClickstream(1311)
                handleAlterar(form)
            } else {
                ColetaClickstream(1312)
                handleCadastrar(form)
            }
        }
    }

    async function handleAlterar(partnerObj) {
        partnerObj.cd_pav_cliente_parceiro = dsIDParceiro
        setSpinAtivo(true)

        if(!logoChanged && tituloJanela === 'Editar'){
            form.logo = ''
        }
        if (!logoEscuroChanged && tituloJanela === 'Editar'){
            form.logo_escuro = ''
        }
        if (!imagemFundoChanged && tituloJanela === 'Editar'){
            form.imagem_fundo = ''
        }

        await api.post('/m1/alterar/cliente_parceiro',partnerObj)
            .then(function (response) {
                setDsMensagemSucesso('Parceiro '+form.nome+' alterado com sucesso')
                setSpinAtivo(false)
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setDsMensagemErro(error.response.data.info)
                setRevelarMensagemErro(true)
                setSpinAtivo(false)
            });
    }

    // useEffect(() => {
    //     if (
    //         dsNomeCliente != '' &&
    //         dsIDCliente != '' &&
    //         dsSubdominio != '' &&
    //         dsImagem != undefined
    //     ) {
    //         setLiberarCadastro(true)
    //     } else {
    //         setLiberarCadastro(false)
    //     }
    // }, [dsNomeCliente, dsIDCliente, dsSubdominio, dsImagem, chatbotAtivo])




    function handleImportLogo(inputRef, field) {
        if(field === 'logo' && form.logo){
            return
        } else if (field === 'logo_escuro' && form.logo_escuro){
            return
        } else if (field === 'imagem_fundo' && form.imagem_fundo){
            return
        }
        inputRef.current.click();
    }

    function handleRemoveLogo(inputRef, field) {
        inputRef.current.value = '';
        if(field === 'logo'){
            setLogoPadraoNome('Selecione')
            setLogoChanged(true);
        } else if (field === 'logo_escuro'){
            setLogoEscuroNome('Selecione')
            setLogoEscuroChanged(true)
        } else if (field === 'imagem_fundo'){
            setImagemFundoNome('Selecione')
            setImagemFundoChanged(true)
        }
        setUploadImage({...uploadImage, imagem_fundo: '', imagem_fundo_nome: ''})
        setField(field, '')
    }

    const handleActiveStatus = (event) => {
        setField('fl_chat', event.target.checked)
    }

    function handleFileChange(evento, field) {
        let file = evento.target.files[0]


        if ((field !== 'imagem_fundo' && file && file?.type === 'image/svg+xml') || field === 'imagem_fundo'){
            if(field === 'logo'){
                setLogoPadraoNome(file.name)
                setLogoChanged(true);
            } else if (field === 'logo_escuro'){
                setLogoEscuroNome(file.name)
                setLogoEscuroChanged(true)
            } else if (field === 'imagem_fundo'){
                setImagemFundoNome(file.name)
                setImagemFundoChanged(true)
            }

            const reader = new FileReader()

            reader.onload = (e) => {
                const base64String = e.target.result;
                setField(field, base64String);
                if(field === 'imagem_fundo'){
                    setUploadImage({...uploadImage, imagem_fundo: base64String, imagem_fundo_nome: file.name})
                }

            }
            reader.readAsDataURL(file)
            // file = '';
        } else {
            if(file){
                console.error('erro ao fazer o upload da imagem', file.type);
                setFileType(file.type)
            }else {
                console.error('erro ao fazer o upload da imagem')
            }
            setField(field, '');

        }
    }

    function parseButtonText(buttonText) {
        if (buttonText === 'Editar'){
            return 'Salvar Edição'
        } else {
            return buttonText
        }
    }

    function truncarTexto(texto, tamanho){
        if(texto.length > tamanho){
            return texto.substring(0, tamanho) + '...'
        } else {
            return texto
        }
    }

    function buildLogoSelectButton(labelText, field, inputRef, logoName, formField, maxTruncate){
        return (
            <Form.Group className="cadastro-parceiro-cliente image-upload-btn">
                  <Form.Label>
                      <div className='fonte-cor-1 label-campo'>{labelText}</div>
                  </Form.Label>
                  <>
                      <input
                          disabled={field==='imagem_fundo'&&imagemPadrao}
                          type="file"
                          accept={field==='imagem_fundo'? "image/jpeg, image/jpg" : ".svg"}
                          style={{display: 'none'}}
                          ref={inputRef}
                          onChange={e => handleFileChange(event, field)}
                      />
                      <div
                          className={formField
                              ? 'campo-texto-cor-3 campo-imagem-parceiros cad-parceiros-large campo-cursor-default'
                              :'campo-texto-placeholder campo-imagem-parceiros cad-parceiros-large campo-cursor-pointer'}
                          onClick={e => handleImportLogo(inputRef, field)}
                      >
                          <div className={'logo-btn-texto'}>{truncarTexto(logoName, maxTruncate)}</div>
                          {!formField ?
                              <img className={'campo-cursor-pointer'} src={uploadVerde()}/>
                              :
                              <img className={'campo-cursor-pointer'} onClick={e => handleRemoveLogo(inputRef, field) } src={lixeiraVermelho()}/>
                          }

                      </div>
                  </>
            </Form.Group>
        )
    }

    function handleImagemPadrao(checkStatus){
        setImagemPadrao(checkStatus)
        if(checkStatus == true){
            inputImagemFundoImportRef.current.value = '';
            setField('imagem_fundo', '')
            setImagemFundoNome('Selecione');
            setImagemPadrao(checkStatus)
        } else {
            setField('imagem_fundo', uploadImage.imagem_fundo)
            if(uploadImage.imagem_fundo_nome){
                setImagemFundoNome(uploadImage.imagem_fundo_nome)
            }else {
                setImagemFundoNome('Selecione')
            }
        }
    }

    function buildCheckbox(labelText, checked, handleCheckbox){
        return (
            <div className={'checkbox-container-imagem-fundo'}>
                <FormControlLabel
                    sx={ localTema !== 'claro' ? {
                        color: branco,
                        '& .MuiSvgIcon-root': {
                            fontSize: '25px !important',
                        },
                        '&.Mui-checked': {color: '#33CC99'}} : {
                        '& .MuiSvgIcon-root': {
                            fontSize: '25px !important',
                        },
                        '&.Mui-checked': {color: '#33CC99'}
                    }}
                    control={<Checkbox checked={checked}/>}
                    label={labelText}
                    onChange={e => handleCheckbox(e.target.checked)}
                />
            </div>

        )
    }

    function limparHexadecimal(input) {
        // Remover caracteres não válidos (qualquer coisa que não seja 0-9, A-F, a-f ou #)
        const regex = /[^0-9A-Fa-f#]/g;
        const resultado = input.replace(regex, '');
    
        // Limitar a string a seis caracteres (além do # no início)
        const hexLimpo = resultado.slice(0, 7);
        return hexLimpo;
    }

    function validarHexadecimal(input) {
        // Expressão regular para verificar se a entrada é um hexadecimal válido
        const regex = /^#[0-9A-Fa-f]{6}$/;
    
        if (regex.test(input)) {
            return true;
        } else {
            return false;
        }
    }

    function buildColorPicker(labelText, fieldName, fieldValue, errorField){

        const changeColor = (fieldName, newColor) => setField(fieldName, newColor);

        const changeHexValue = (e) => {
            const newColor = limparHexadecimal(e.target.value);
            changeColor(fieldName, newColor);
        }

        const handleChangeCheckboxColor = (newValue) => {
            if(newValue) {
                changeColor(fieldName, defaultColor);
            }
            else {
                changeColor(fieldName, '');
            }
        }

        const checked = (fieldValue === defaultColor);

        return (
        <div className={'color-picker-container'}>
                <div>
                <Form.Group className="cadastro-parceiro-cliente color-picker">
                    <Form.Label>
                        <div className='fonte-cor-1 label-campo'>{labelText}</div>
                    </Form.Label>
                    <>
                        <Form.Control
                            // onClick={() => {if(!fieldValue){setField(fieldName, '#')}}} // não pode ter isso, porque pode atrapalhar se o usuário for colar o valor
                            value={fieldValue}
                            className='campo-texto-cor-3 campo-texto-parceiros cad-parceiros-large'
                            type='text'
                            placeholder={branco}
                            onChange={changeHexValue}
                            isInvalid={!!errorField}
                        />
                    </>
                </Form.Group>
                <input
                    type="color"
                    onChange={changeHexValue}
                    className={'color-display-input'}
                />
                <div style={fieldValue? {backgroundColor: fieldValue}: {backgroundColor: branco}} className={'color-display'}></div>
            </div>
            {buildCheckbox(
                'Manter cor padrão do sistema',
                checked,
                handleChangeCheckboxColor
            )}
        </div>
        )
    }



    return (
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            {spinAtivo && <div className='componente-spinner-overlay'><div className='componente-spinner-container'><SpinerVeye /></div></div>}
            <div className='backoffice-modal-cadastro-parceiro fundo-cor-6'>
                <Form.Row>
                    <Col>
                        <img className='icone-label-janela nao-selecionavel' src={elipseVerde()} alt='elipse' />
                        <Form.Label>
                            <div className='fonte-cor-1 label-janela'>{tituloJanela} White Label</div>
                        </Form.Label>
                        <Button className='botao-fechar-janela' variant='danger' onClick={() => handleCancelar()}>
                            <img className='botao-fechar-janela-icone' src={fecharJanela()} alt='fechar-janela' />
                        </Button>
                    </Col>
                </Form.Row>

                <div className={'modal-wrapper-cadastro'}>
                    <div className={'cadastro-campos'}>
                        <div className='backoffice-cadastro-parceiro-linha-1'>
                            <Form.Group className="cadastro-parceiro-cliente">
                                <Form.Label>
                                    <div className='fonte-cor-1 label-campo'>Nome do Parceiro</div>
                                </Form.Label>
                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                overlay={<Tooltip id={`tooltip-bottom`}>Insira o nome do
                                                    cliente</Tooltip>}>
                                    <>
                                        <Form.Control

                                            value={form.nome}
                                            className='campo-texto-cor-3 campo-texto-parceiros cad-parceiros-large'
                                            type='text'
                                            placeholder='Insira o nome do parceiro'
                                            onChange={e => setField('nome', e.target.value)}
                                            isInvalid={!!formErrors.nome}
                                        />
                                        <Form.Control.Feedback type="invalid">O nome do parceiro é obrigatório</Form.Control.Feedback>
                                    </>

                                </OverlayTrigger>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    <div className='fonte-cor-1 label-campo'>ID do Cliente</div>
                                </Form.Label>
                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                overlay={<Tooltip id={`tooltip-bottom`}>Insira o ID do
                                                    cliente</Tooltip>}>
                                    <>
                                        <Form.Control
                                            value={form.cd_pav_cliente}
                                            className='campo-texto-cor-3 campo-texto-parceiros cad-parceiros-small'
                                            type='number'
                                            placeholder='Insira o ID'
                                            onChange={e => setField('cd_pav_cliente', e.target.value)}
                                            isInvalid={!!formErrors.cd_pav_cliente}
                                        />
                                        <Form.Control.Feedback type="invalid">{formErrors.cd_pav_cliente}</Form.Control.Feedback>
                                    </>

                                </OverlayTrigger>
                            </Form.Group>


                        </div>
                        <div className='backoffice-cadastro-parceiro-linha-1'>
                            <Form.Group className="cadastro-parceiro-cliente">
                                <Form.Label>
                                    <div className='fonte-cor-1 label-campo'>Subdomínio</div>
                                </Form.Label>
                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                overlay={<Tooltip id={`tooltip-bottom`}>Insira o nome do
                                                    cliente</Tooltip>}>
                                    <>
                                        <Form.Control
                                            value={form.ds_sub_dominio}
                                            className='campo-texto-cor-3 campo-texto-parceiros cad-parceiros-large'
                                            type='text'
                                            placeholder='Insira o nome do parceiro'
                                            onChange={e => setField('ds_sub_dominio', e.target.value)}
                                            isInvalid={!!formErrors.ds_sub_dominio}
                                        />
                                        <Form.Control.Feedback type='invalid'>{formErrors.ds_sub_dominio}</Form.Control.Feedback>
                                    </>
                                </OverlayTrigger>
                            </Form.Group>
                        </div>
                        <div className='backoffice-cadastro-parceiro-linha-1'>
                            {buildLogoSelectButton(
                                'Logo Principal',
                                'logo',
                                inputImportRef,
                                logoPadraoNome,
                                form.logo,
                                26)}
                            {buildLogoSelectButton(
                                'Logo para Modo Escuro',
                                'logo_escuro',
                                inputLogoEscuroImportRef,
                                logoEscuroNome, form.logo_escuro,
                                26)}
                        </div>
                        <div className='backoffice-cadastro-parceiro-linha-1'>
                            {buildLogoSelectButton('Imagem de Fundo',
                                'imagem_fundo',
                                inputImagemFundoImportRef,
                                imagemFundoNome,
                                form.imagem_fundo,
                                55)}
                        </div>
                        {buildCheckbox(
                            'Manter imagem padrão do sistema', 
                            imagemPadrao,
                            handleImagemPadrao
                        )}

                        <div className='linha-container-cores'>
                            {buildColorPicker(
                                'Cor de fundo',
                                'cor_fundo',
                                form.cor_fundo,
                                formErrors.cor_fundo
                            )}
                            {buildColorPicker('Cor dos botões e links',
                                'cor_botoes',
                                form.cor_botoes,
                                formErrors.cor_botoes
                            )}
                        </div>
                        <div className='backoffice-cadastro-parceiro-linha-1'>
                            <div className={'radio-group-container'}>
                                <div style={{marginTop: '4px', marginLeft: '10px'}} className={'fonte-cor-1 label-campo'}>Cor do texto de apoio:</div>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={form.cor_letra}
                                    onChange={e => setField('cor_letra', e.target.value)}
                                >
                                    <div className={'support-text-row'}>
                                        <FormControlLabel sx={localTema !== 'claro'? {color: '#FFFFFF'} : {}} value="#FFFFFF" control={<Radio checked={form.cor_letra === '#FFFFFF'} sx={radioStyle} />} label="Texto Claro" />
                                        <FormControlLabel sx={localTema !== 'claro'? {color: '#FFFFFF'} : {}} value="#003769" control={<Radio checked={form.cor_letra === '#003769'} sx={radioStyle} />} label="Texto Escuro" />
                                    </div>
                                </RadioGroup>
                            </div>
                        </div>
                        <div className={'error-column'}>
                            { !!formErrors.cor_fundo &&
                                <div className='descricao-erro-logo'>{formErrors.cor_fundo}</div>
                            }
                            { !!formErrors.cor_botoes &&
                                <div className='descricao-erro-logo'>{formErrors.cor_botoes}</div>
                            }
                            { !!formErrors.cor_letra &&
                                <div className='descricao-erro-logo'>{formErrors.cor_letra}</div>
                            }
                            { !!formErrors.logo &&
                                <div className='descricao-erro-logo'>{formErrors.logo}</div>
                            }
                        </div>
                    </div>



                    <div className={'botoes-de-acao'}>
                        <Form.Row>
                            <Col>
                                {liberarCadastro === false ?
                                    <Button type='submit' className={'botao-confirmar-desabilitado'} variant='success' size='sm'>
                                        <div className='fonte-cor-4'>{parseButtonText(tituloJanela)}</div>
                                    </Button>
                                    :
                                    <Button type='submit' className={'botao-confirmar'} variant='success' size='sm'>
                                        <div className='fonte-cor-4'>{parseButtonText(tituloJanela)} </div>
                                    </Button>
                                }
                                <Button className='botao-cancelar' variant='success' size='sm' onClick={() => handleCancelar()}>
                                    <div className='fonte-cor-2'>Cancelar</div>
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>


            </div>
            {revelarMensagemSucesso &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={'Sucesso'}
                            mensagemParagrafo1={dsMensagemSucesso}
                            mensagemBotao={'Continuar'}
                        />
                    </div>
                </div>
            }
            {revelarMensagemErro &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={'Erro'}
                            mensagemParagrafo1={dsMensagemErro}
                            mensagemBotao={'Retornar'}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
