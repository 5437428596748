import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
import Calendario from '../calendario/calendario'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltrosVirtueyesCliente(props) {
    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroDataInicial, setFiltroDataInicial] = useState('')
    const [filtroDataFinal, setFiltroDataFinal] = useState('')
    const [filtroStatus, setFiltroStatus] = useState('')
    const [filtroTipo, setFiltroTipo] = useState('')
    let arrayFiltrosSms = []
    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
 
    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
        
            setFiltroStatus(filtros.STATUS)
            setFiltroTipo(filtros.TIPO)
            //verifica se existe algo nos valores de datas
            if(filtros.DATA_INICIO !='' && filtros.DATA_INICIO){
                let auxData = new Date(filtros.DATA_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataInicial(auxData)
            }
            if(filtros.DATA_FINAL != '' && filtros.DATA_FINAL){
                let auxData = new Date(filtros.DATA_FINAL)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataFinal(auxData)
            }
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroDataInicial == '') verificador.push(1)
            if(filtroDataFinal =='') verificador.push(1)
            if(filtroStatus == '') verificador.push(1)
            if(filtroTipo =='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 4) return false
            else return true
        }

    
        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroDataInicial('')
            setFiltroDataFinal('')
            setFiltroStatus('')
            setFiltroTipo('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroDataInicial,filtroDataFinal,filtroStatus,filtroTipo,props.foiLimpado]);

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1217) /*smsMensagensDropFiltrosFiltrar*/
        if(filtroDataInicial !== '' && filtroDataInicial && filtroDataFinal !== '' && filtroDataFinal){
            arrayFiltrosSms.push("Data Início: Período de "+moment(filtroDataInicial).format("DD-MM-YYYY")+" até "+moment(filtroDataFinal).format("DD-MM-YYYY")+"?DATA_INICIO")
        }
        if(filtroStatus !== '' && filtroStatus){
            arrayFiltrosSms.push("Status: "+filtroStatus+"?STATUS")
        }
        if(filtroTipo !== '' && filtroTipo){
            arrayFiltrosSms.push("Tipo: "+filtroTipo+"?TIPO")
        }
       
        despacho(filtrosArrayBarra(arrayFiltrosSms))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }
      
    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-data","imagem-filtro-data"); ColetaClickstream(1207) /*smsMensagensDropFiltrosData*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data </Form.Label>
                        <img id={"imagem-filtro-data"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_INICIO" type="text" value={filtroDataInicial ? moment(filtroDataInicial).format("YYYY-MM-DD HH:mm:ss") : filtroDataInicial} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataInicial} setData={setFiltroDataInicial} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_FINAL" type="text" value={filtroDataFinal ? moment(filtroDataFinal).format("YYYY-MM-DD HH:mm:ss") : filtroDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataFinal} setData={setFiltroDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-status","imagem-filtro-status"); ColetaClickstream(1208) /*smsMensagensDropFiltrosStatus*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Status </Form.Label>
                        <img id={"imagem-filtro-status"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus("Entregue")}>Entregue
                            <input defaultChecked={handlerCheck("STATUS","Entregue")} type="radio" id="TIPO_1" name="STATUS" value="Entregue"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus("Não Entregue")}>Não entregue
                            <input defaultChecked={handlerCheck("STATUS","Não entregue")} type="radio" id="TIPO_2" name="STATUS" value="Não entregue"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus("Pendente")}>Pendente
                            <input defaultChecked={handlerCheck("STATUS","Pendente")} type="radio" id="TIPO_3" name="STATUS" value="Pendente"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus("Rejeitado")}>Rejeitado
                            <input defaultChecked={handlerCheck("STATUS","Rejeitado")} type="radio" id="TIPO_4" name="STATUS" value="Rejeitado"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus("Expirado")}>Expirado
                            <input defaultChecked={handlerCheck("STATUS","Expirado")} type="radio" id="TIPO_5" name="STATUS" value="Expirado"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-tipo","imagem-filtro-tipo"); ColetaClickstream(1209) /*smsMensagensDropFiltrosTipo*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Tipo </Form.Label>
                        <img id={"imagem-filtro-tipo"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-tipo"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTipo("Envio com Recebimento")}>Envio com recebimento
                            <input defaultChecked={handlerCheck("TIPO","Envio com Recebimento")} type="radio" id="TIPO_COM_RECEBIMENTO" name="TIPO" value="Envio com Recebimento"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTipo("Envio")}>Envio sem recebimento
                            <input defaultChecked={handlerCheck("TIPO","Envio")} type="radio" id="TIPO_SEM_RECEBIMENTO" name="TIPO" value="Envio"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>

                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}