import domtoimage from 'dom-to-image'
import {saveAs} from 'file-saver'


export function exportarImagemDashSimcards(grafico){
    let node = undefined
    let botaoMenuOpcoes = undefined
    let elX;
    let elementName;
    let nomeArquivo = ''

    switch(grafico) {
        case "exportarSimcardsSemComunicar":
            elementName = 'grafico-simcards-sem-comunicar';
            nomeArquivo = 'Simcards-sem-comunicar.png'
            break;
        case "exportarConsumo": 
            elementName = 'grafico-simcards-consumo';
            nomeArquivo = 'Simcards-consumo.png'
            break;
        case "exportarSimcardsOnline": 
            elementName = 'grafico-simcards-online';
            nomeArquivo = 'Simcards-online.png'
            break;
        case "exportarSimcardsStatus": 
            elementName = 'grafico-simcards-status';
            nomeArquivo = 'Simcards-status.png'
            break;
        case "exportarSimcardsOffline":
            elementName = 'grafico-simcards-offline';
            nomeArquivo = 'Simcards-offline.png'
            break;
        case "exportarCicloAtual":
            elementName = 'grafico-ciclo-atual';
            nomeArquivo = 'Ciclo-atual.png'
            break;
        case "exportarOperadoras":
            elementName = 'grafico-simcards-operadoras';
            nomeArquivo = 'Operadoras.png'
            break;
        case "exportarTecnologias":
            elementName = 'grafico-tecnologias';
            nomeArquivo = 'Tecnologias.png'
            break;
        case "exportarStatusDeContrato":
            elementName = 'grafico-status-contrato';
            nomeArquivo = 'Status-do-contrato.png'
            break;
        default: 
            break;
    }

    if(grafico) {
        botaoMenuOpcoes = document.querySelector(`#${elementName} button`)
        // botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";

        elX = document.querySelector(`.menu-opcoes-dashboard-fundo`)
        elX.hidden = true;

        node = document.getElementById(elementName)
    }

    domtoimage.toBlob(node)
        .then(function (blob) {
            saveAs(blob, nomeArquivo)
            botaoMenuOpcoes.style.visibility = "visible";
            elX.hidden = false;
        })
        .catch(function (error) {
            console.error('Erro ao exportar imagem', error);
    });
}

export function exportarImagemDashSms(grafico){ 
    let node = undefined
    let botaoMenuOpcoes = undefined
    let nomeArquivo = ''
    let elementName;
    
    if(grafico === "exportarSmsEnvios"){
        elementName = 'grafico-sms-envios'
        nomeArquivo = 'Sms-envios.png'
    }
    else if(grafico === "exportarSmsEstoque"){
        elementName = 'grafico-sms-estoque'
        nomeArquivo = 'Sms-estoque.png'
    }
   else  if(grafico === "exportarSmsUltimaRecarga"){
        elementName = 'grafico-sms-ultima-recarga'
        nomeArquivo = 'Sms-ultima-recarga.png'
    }
    else  if(grafico === "exportarSmsStatus"){
        elementName = 'grafico-sms-status'
        nomeArquivo = 'Sms-status.png'
    }

    botaoMenuOpcoes = document.querySelector(`#${elementName} button`)
    botaoMenuOpcoes.click()
    botaoMenuOpcoes.style.visibility = "hidden";
    node = document.getElementById(elementName)

    domtoimage.toBlob(node)
        .then(function (blob) {
            saveAs(blob, nomeArquivo)
            botaoMenuOpcoes.style.visibility = "visible";
        })
        .catch(function (error) {
            console.error('Erro ao exportar imagem', error);
    });
}
