import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';
import { useForm } from "react-hook-form";
import {Button, Form, Col, Dropdown, Accordion, Image} from 'react-bootstrap';
import { CSVLink } from 'react-csv'
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
import decryptJWT from '../../../../utils/decryptJWT'
import moment from 'moment'
import {elipseVerde, fecharJanela, alertaVermelho, setaDropdown, importarArquivo} from '../../../../utils/alternanciaIcones'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import Calendario from '../../../calendario/calendario'
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
import { useDispatch } from 'react-redux';
import {revelarNovoFatPedidos, revelarEditarFatPedidos} from "../../../../redux/actions/index";
import ImportarArquivoVeye from '../../../importarArquivo';
//SELECT
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
import { exportarXlsx } from '../../../../utils/exportarArquivo.js';
//ATUALIZADOR DA TABELA
let atualizarParent = false;

//MOCKS

const dadosJSONXLS = [
    [
        'linha',
        'iccid'
    ],
    [
        "'",
        "'"
    ]
]
const dadosJSONCSV = [
    {
        "linha": "'",
        "iccid": "'",
    }
]
let primeiraVez = true;
export default function NovoPedido(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    // HOOKS VALIDAÇÂO
    const { errors } = useForm();

    const importarArquivoRef = useRef(null)
   
    const [tituloJanela, setTituloJanela] = useState('Novo');

    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState()

    const [cdFornecedor, setCdFornecedor] = useState();
    const [dsFornecedor, setDsFornecedor] = useState('Selecione');
    const [cdOperadora, setCdOperadora] = useState();
    const [dsOperadora, setDsOperadora] = useState('Selecione');
    const [cdConta, setCdConta] = useState();
    const [dsConta, setDsConta] = useState('Selecione');
    const [cdPlano, setCdPlano] = useState();
    const [dsPlano, setDsPlano] = useState('Selecione');
    const [cdTempoPermanencia, setCdTempoPermanencia] = useState();
    const [dsTempoPermanencia, setDsTempoPermanencia] = useState('Selecione');
    const [cdCicloVida, setCdCicloVida] = useState();
    const [dsCicloVida, setDsCicloVida] = useState('Selecione');
    const [bloquearCadastrar, setBloquearCadastrar] = useState(false)
  
    const [dataEmissaoNota, setDataEmissaoNota] = useState("");
    const [dataAtivacao, setDataAtivacao] = useState("");
    const [numeroNota, setNumeroNota] = useState("");
    const [numeroPedido, setNumeroPedido] = useState("");
    const [arquivo, setArquivo] = useState([])
    const [nomeArquivo, setNomeArquivo] = useState('')
    const [extensaoArquivo, setExtensaoArquivo] = useState('')
    const [dadosSimcardsRepetidos, setDadosSimcardsRepetidos] = useState([]);
    const [countSimcardsRepetidos, setCountSimcardsRepetidos] = useState(0);
    const [faturasEmAberto, setFaturasEmAberto] = useState([])
    //HOOKS MENSAGENS
    const [revelarMensagemSucessoEdicao, setRevelarMensagemSucessoEdicao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemErroSemDados, setRevelarMensagemErroSemDados] = useState(false)
    const [revelarMensagemErroSimcardsRepetidos, setRevelarMensagemErroSimcardsRepetidos] = useState(false)
    //HOOKS SELECTS
    const [dummy, setDummy] = useState('')
    const [revelarSelectFornecedor, setRevelarSelectFornecedor] = useState(false)
    const [revelarSelectOperadora, setRevelarSelectOperadora] = useState(false)
    const [revelarSelectConta, setRevelarSelectConta] = useState(false)
    const [revelarSelectPlano, setRevelarSelectPlano] = useState(false)
    const [revelarSelectTempoPermanencia, setRevelarSelectTempoPermanencia] = useState(false)
    const [revelarSelectCicloDeVida, setRevelarSelectCicloDeVida] = useState(false)
    //OUTROS HOOKS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const despacho = useDispatch();
    const modal = useRef();
    
    const iconeAlerta = (
        <img
          className="icone-alerta-filtro-arquivo"
          src={alertaVermelho()}
          alt="alerta"
        />
    );
      
    primeiraVez = false;
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };
    
    useEffect(()=>{
        setSpinAtivo(true)
        if(props.tituloJanela === 'Editar'){
            setTituloJanela("Editar")
            setCdFornecedor(props.cdFornecedor)
            setDsFornecedor(props.dsFornecedor)
            setCdOperadora(props.cdOperadora)
            setDsOperadora(props.dsOperadora)
            setCdConta(props.cdConta)
            setDsConta(props.dsConta)
            setCdPlano(props.cdPlano)
            setDsPlano(props.dsPlano)
            setCdCicloVida(props.cdCicloVida)
            setDsCicloVida(props.dsCicloVida)
            setCdTempoPermanencia(props.cdTempoPermanencia)
            setDsTempoPermanencia(props.dsTempoPermanencia)
            setNumeroNota(props.numeroNota)
            setDataEmissaoNota(new Date(props.dataEmissaoNota))
            setDataAtivacao(new Date(props.dataAtivacao))
            setNumeroPedido(props.numeroPedido)
            // setQuantidadeTradein(props.quantidadeTradein)
        }

        if(props.tituloJanela === "Cadastrar"){
            setTituloJanela("Novo")
        }

        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    },[])

    useEffect(()=>{
        function dataAtivacao(){
            if(dsCicloVida === "Ativo*" && dataEmissaoNota != ""){
                setDataAtivacao(new Date(dataEmissaoNota))
            }else{
                setDataAtivacao()
            }
        }
        dataAtivacao()
    },[dsCicloVida,dataEmissaoNota])
    

    async function handleCadastrarPedido(){
        ColetaClickstream(1226) /*dropAcoesRevelarNovoFatPedidosCadastrar*/

        setSpinAtivo(true)

        await api.post('/m1/cadastrar/fat_pedido', {
            "cd_fat_fornecedor": cdFornecedor,
            "cd_fat_plano": cdPlano,
            "cd_fat_conta": cdConta,
            "cd_fat_ciclo_vida": cdCicloVida,
            "ds_pedido_operadora": numeroPedido,
            "simcards": arquivo,
            "n0_quantidade_trade_in": 0,
            "ds_nota_fiscal": numeroNota,
            "dt_emissao_nf": moment(dataEmissaoNota).format("YYYY-MM-DD hh:mm:ss"),
            "dt_ativacao": moment(dataAtivacao).format("YYYY-MM-DD hh:mm:ss"),
            "permanencia": cdTempoPermanencia,
            "ds_arquivo": nomeArquivo,
            "cd_pav_usuario": decryptJWT('codigo')
        })
        .then(function (resposta) {
            setSpinAtivo(false)
            importarArquivoRef.current.Cancelar()
            if(resposta.data.status == 200){
                if(resposta.data.info && resposta.data.info !== ''){
                    setMensagemErro(resposta.data.info)
                    setRevelarMensagemErro(true)
                }else{
                    setRevelarMensagemSucesso(true)
                }
            }
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro("Erro no pedido")
            setRevelarMensagemErro(true)
        });

        setSpinAtivo(false)
    }
        
    async function handleEnviar(){
        setSpinAtivo(true)

        // const ativacao = dsCicloVida === "Ativo" ? moment(dataEmissaoNota).format("YYYY-MM-DD hh:mm:ss") : moment(dataAtivacao).format("YYYY-MM-DD hh:mm:ss")

        setBloquearCadastrar(true)
        
        const body = {
            "cd_fat_conta":cdConta,
            "dt_condicao":dataAtivacao
        }
        setSpinAtivo(false);
        handleCadastrarPedido();
        /*
        * Comentado por peformance, a ação padrão era recalcular a fatura após o novo pedido, mas
        * esta ação causava instabilidade severa, impossibilitando usuários de cadastrarem seus pedidos.
        * Data do comentário: 20/04/23
        */

        /*
        const faturasAbertas = await api.post('/m1/consultar/fat_faturas/faturas_nao_pagas', body)
        if(faturasAbertas.data.dados.length > 0){
            setSpinAtivo(false)
            setFaturasEmAberto(faturasAbertas.data.dados)
            setRevelarMensagemAtencao(true)
        }
        else{
            setSpinAtivo(false)
            handleCadastrarPedido();
        }
        */
    }

    async function handleRecalcularFaturas(){
        setSpinAtivo(true)
        const recalculos = await api.post('/m1/cadastrar/fat_faturas/fatura_preview', {
            "arrayFaturas": faturasEmAberto
        })
        if(recalculos.data.status == 200){
            setSpinAtivo(false)
            handleCadastrarPedido()
        }
        else{
            setSpinAtivo(false)
            setMensagemErro("Erro ao recalcular faturas")
            setRevelarMensagemErro(true)
        }
    }

    function handleMensagemErroSemDados(confirmacao){
        if(revelarMensagemErroSemDados === false){
            setRevelarMensagemErroSemDados(true)
        }else{
            setRevelarMensagemErroSemDados(false)
        }
        if(confirmacao === true ){
        }
    }

    function handleCancelar(){
        ColetaClickstream(1225) /*dropAcoesRevelarNovoFatPedidosCancelar*/
        if(importarArquivoRef.current != null){
        importarArquivoRef.current.Cancelar()
        }
        if(atualizarParent === true){
            props.setRenderizar(!props.renderizar)
        } 
        despacho(revelarEditarFatPedidos(false))
        despacho(revelarNovoFatPedidos(false))
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
            }
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                handleRecalcularFaturas()
            }
        }
    }

    function handleMensagemErroSimcardsRepetidos(confirmacao){
        if(revelarMensagemErroSimcardsRepetidos === false){
            setRevelarMensagemErroSimcardsRepetidos(true)
        }else{
            setRevelarMensagemErroSimcardsRepetidos(false)
            if(confirmacao === true){
                handleDownloadSimcardsRepetidos()
            }
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucessoEdicao(confirmacao){
        if(revelarMensagemSucessoEdicao === false){
            setRevelarMensagemSucessoEdicao(true)
        }else{
            setRevelarMensagemSucessoEdicao(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function exportarXlsXlsx(){
        let arrayDeArraysParaExportar = []
        arrayDeArraysParaExportar.push(["Linha"])
        dadosSimcardsRepetidos.map(simcard =>{
            arrayDeArraysParaExportar.push(["'"+simcard.LINHA])
        })

        let nomeDoArquivo = "pedido-linhas-repetidas.csv";
        if(extensaoArquivo === 'xls'){
            nomeDoArquivo = "pedido-linhas-repetidas.xls";
        }
        exportarXlsx(arrayDeArraysParaExportar, nomeDoArquivo)
    }

    function handleDownloadSimcardsRepetidos(){
        exportarXlsXlsx();
        setNomeArquivo('');
        setExtensaoArquivo('');
        importarArquivoRef.current.Cancelar()
    }

    async function realizarValidacao(dados, nomeArquivo, extensaoArquivo){
        if(dados.length > 0){
            setSpinAtivo(true)
            setArquivo(dados)
            setNomeArquivo(nomeArquivo)
            setExtensaoArquivo(extensaoArquivo)
            await api.post("m1/consultar/fat_pedido/validacao_pedidos_simcard", {
                simcards:dados,
                ds_conta:dsConta})
            .then(function(resposta) {
                if(resposta.data.dados.length > 0){
                    setCountSimcardsRepetidos(resposta.data.dados.length)
                    setDadosSimcardsRepetidos(resposta.data.dados)
                    setRevelarMensagemErroSimcardsRepetidos(true)
                    setSpinAtivo(false)
                }
                else{
                    setSpinAtivo(false)
                }
                return resposta;
            })
            .catch(function(erro){
                setSpinAtivo(false)
                throw erro.stack;
            })      
        }
        else{
            setRevelarMensagemErroSemDados(true)
        }
    }

    // const colunasSimcardsRepetidos = 
    // [
    //     {
    //         dataField: 'linha',
    //         text: 'Linha',
    //         headerClasses: 'nao-selecionavel',
    //         formatter: (cell, row) => {
    //             return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
    //         }
    //     }
    // ]

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-numero-nf"){
                setNumeroNota(valor)
            }
            if(id === "campo-texto-numero-pedido"){
                setNumeroPedido(valor)
            }
        }
    }
    
    useEffect(()=>{
        if(!primeiraVez){
            setCdOperadora()
            setDsOperadora('Selecione')
            setCdConta()
            setDsConta('Selecione')
            setCdPlano()
            setDsPlano('Selecione')
        }
    },[dsFornecedor])
    useEffect(()=>{
        if(!primeiraVez){
            setCdConta()
            setDsConta('Selecione')
            setCdPlano()
            setDsPlano('Selecione')
        }
    },[dsOperadora])
    useEffect(()=>{
        if(!primeiraVez){
            setCdPlano()
            setDsPlano('Selecione')
        }
    },[dsConta])


    return(
        <div ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="modal-novo-pedido-faturas fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{tituloJanela} pedido</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-painel-pedidos-faturas-1">
                            <div className="coluna-painel-pedidos-faturas-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Fornecedor</div>
                                    </Form.Label>
                                    {revelarSelectFornecedor === false &&
                                        <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFornecedor(!revelarSelectFornecedor)}>
                                            <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsFornecedor}</div>
                                            <img className={revelarSelectFornecedor?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }

                                    {revelarSelectFornecedor === true &&
                                        <SelectGenerico
                                            paramRota={"fat_fornecedor"}
                                            paramCampo1={decryptJWT('codigo')}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdFornecedor}
                                            setDescricao1={setDsFornecedor}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectFornecedor}
                                            placeholder={dsFornecedor}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                    }
                                </Form.Group>
                            </div>
                            <div className="coluna-painel-pedidos-faturas-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Operadora</div>
                                    </Form.Label>
                                    {revelarSelectOperadora === false &&
                                        <Button disabled={cdFornecedor !== '' && cdFornecedor !== null && cdFornecedor !== undefined ? false : true} className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                            <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsOperadora}</div>
                                            <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }

                                    {revelarSelectOperadora === true &&
                                        <SelectGenerico
                                            paramRota={"fat_operadora_por_fornecedor"}
                                            paramCampo1={decryptJWT('codigo')}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={cdFornecedor}
                                            paramCampo4={null}
                                            setCodigo={setCdOperadora}
                                            setDescricao1={setDsOperadora}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectOperadora}
                                            placeholder={dsOperadora}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                    }
                                </Form.Group>
                            </div>
                            <div className="coluna-painel-pedidos-faturas-3">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Conta</div>
                                    </Form.Label>
                                    {revelarSelectConta === false &&
                                        <Button disabled={cdOperadora !== '' && cdOperadora !== null && cdOperadora !== undefined ? false : true} className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectConta(!revelarSelectConta)}>
                                            <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsConta}</div>
                                            <img className={revelarSelectConta?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }

                                    {revelarSelectConta === true &&
                                        <SelectGenerico
                                            paramRota={"conta_por_operadora_pedido"}
                                            paramCampo1={decryptJWT('codigo')}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={decryptJWT('fatContas')}
                                            paramCampo4={cdOperadora}
                                            setCodigo={setCdConta}
                                            setDescricao1={setDsConta}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectConta}
                                            placeholder={dsConta}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-painel-pedidos-faturas-1">
                            <div className="coluna-painel-pedidos-faturas-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Plano</div>
                                    </Form.Label>
                                    {revelarSelectPlano === false &&
                                        <Button disabled={cdOperadora !== '' && cdOperadora !== null && cdOperadora !== undefined && cdFornecedor !== '' && cdFornecedor !== null && cdFornecedor !== undefined ? false : true} className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectPlano(!revelarSelectPlano)}>
                                            <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsPlano}</div>
                                            <img className={revelarSelectPlano?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }

                                    {revelarSelectPlano === true &&
                                        <SelectGenerico
                                            paramRota={"plano_por_operadora_por_fornecedor"}
                                            paramCampo1={decryptJWT('codigo')}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={cdOperadora}
                                            paramCampo4={cdFornecedor}
                                            setCodigo={setCdPlano}
                                            setDescricao1={setDsPlano}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectPlano}
                                            placeholder={dsPlano}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                    }
                                </Form.Group>
                            </div>
                            <div className="coluna-painel-pedidos-faturas-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">N° da NF</div>
                                    </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        maxLength="30" 
                                        name="ds_numero_nota"                          
                                        value={numeroNota}
                                        onChange={e => validarNumero("campo-texto-numero-nf", e.target.value)}
                                        placeholder="Digite o número da NF"
                                    />
                                </Form.Group>
                            </div>
                            {errors.ds_numero_nota && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                            <div className="coluna-painel-pedidos-faturas-3">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Data da emissão da NF</div>
                                    </Form.Label>
                                    <Calendario imputCustomisado='' formato="DD/MM/YYYY" data={dataEmissaoNota} setData={setDataEmissaoNota} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-painel-pedidos-faturas-1">
                            <div className="coluna-painel-pedidos-faturas-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">N° do pedido</div>
                                    </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        maxLength="30" 
                                        name="ds_numero_pedido"
                                        value={numeroPedido}
                                        onChange={e => validarNumero("campo-texto-numero-pedido", e.target.value)}
                                        placeholder="Digite o número do pedido"
                                    />
                                </Form.Group>
                            </div>
                            {errors.ds_numero_pedido && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                            <div className="coluna-painel-pedidos-faturas-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Tempo de permanência</div>
                                    </Form.Label>
                                    {revelarSelectTempoPermanencia === false &&
                                        <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTempoPermanencia(!revelarSelectTempoPermanencia)}>
                                            <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTempoPermanencia}</div>
                                            <img className={revelarSelectTempoPermanencia?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }

                                    {revelarSelectTempoPermanencia === true &&
                                        <SelectGenerico
                                            paramRota={"fat_tempo_permanencia"}
                                            paramCampo1={null}
                                            paramCampo2={null}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTempoPermanencia}
                                            setDescricao1={setDsTempoPermanencia}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTempoPermanencia}
                                            placeholder={dsTempoPermanencia}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                    }
                                </Form.Group>
                            </div>
                            
                            <div className="coluna-painel-pedidos-faturas-3">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Status</div>
                                    </Form.Label>
                                    {revelarSelectCicloDeVida === false &&
                                        <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCicloDeVida(!revelarSelectCicloDeVida)}>
                                            <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCicloVida}</div>
                                            <img className={revelarSelectCicloDeVida?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }

                                    {revelarSelectCicloDeVida === true &&
                                        <SelectGenerico
                                            paramRota={"fat_ciclo_de_vida"}
                                            paramCampo1={decryptJWT('codigo')}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdCicloVida}
                                            setDescricao1={setDsCicloVida}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectCicloDeVida}
                                            placeholder={dsCicloVida}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-painel-pedidos-faturas-1">
                            <div className="coluna-painel-pedidos-faturas-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Data de ativação</div>
                                    </Form.Label>
                                        <Calendario imputCustomisado='' formato="DD/MM/YYYY" data={dataAtivacao} setData={setDataAtivacao} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                    </Form.Group>
                            </div>
                            <div className="coluna-painel-pedidos-faturas-2">

                            </div>
                            <div className="coluna-painel-pedidos-faturas-3">
                                
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                {/* <Form.Row>
                    <Col>
                        <div className="grupo-painel-pedidos-faturas-2">
                            <div className="coluna-painel-pedidos-faturas-3">
                            <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Quantidade de trade-in</div>
                                    </Form.Label>
                                        <Select onChange={onChangeTradein} value={selectedTradein} onInputChange={handleInputChange}
                                        placeholder={quantidadeTradein === undefined ? "Selecione" :
                                        quantidadeTradein === 1 ? quantidadeTradein + " mês" : quantidadeTradein  + " meses"}
                                        classNamePrefix="Selecione" options={arrayTradein} />
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row> */}
                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo-conta"/>
                <Form.Row>
                    <Col>
                    {dsConta === 'Selecione' ? 
                        <div className="div-importar-pedido-faturas-2">
                            <Dropdown alignRight variant="success">
                                <Accordion defaultActiveKey="0">
                                <Accordion.Toggle className="botaoTemplate-importar-arquivo bt-dropdown-template-importar-arquivo" as={Button} variant="link" eventKey="1"> <div className="UploadMessage fonte-cor-1"> Template </div> </Accordion.Toggle>
                                <Accordion.Collapse className="botaoTemplate-importar-arquivo menu-dropdown-template-pedidos" eventKey="1">
                                    <>
                                    <div className="triangulo-dropdown-configuracao-cabecalho-sistema triangulo-dropdown-template"></div>
                                    <Button className="botaoDownload fonte-cor-1" onClick={() => exportarXlsx(dadosJSONXLS, "template.xls")}> XLS </Button>
                                        <Dropdown.Divider className="template-border"/>
                                    <CSVLink filename="template.csv" className="botaoDownload fonte-cor-1" data={dadosJSONCSV}> CSV </CSVLink>
                                    </>
                                </Accordion.Collapse>
                                </Accordion>
                            </Dropdown>
                            <Button disabled className="botaoImportarDesabilitado">
                                <div className="fonte-branca-tema-branco UploadMessage"> Importar </div>
                                <Image className="imagemImportar" src={importarArquivo()} />
                            </Button>
                        </div>    
                        :
                        <div className="div-importar-pedido-faturas">
                            <ImportarArquivoVeye 
                                ref={importarArquivoRef}
                                templateXLS = {dadosJSONXLS}
                                templateCSV = {dadosJSONCSV}
                                nomeArquivo = {nomeArquivo}
                                setDataAtivacao = {setDataAtivacao}
                                onChange = {realizarValidacao}
                            />
                        </div>
                    }
                        
                        <p className="label-alerta-pedidos-margem fonte-cor-3 label-alerta">{iconeAlerta} Os tipos de arquivos suportados são XLSX, XLS e CSV.</p>
                    </Col>
                </Form.Row>
                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo-conta"/>
                <Form.Row className="espaco-reativar-linha">
                    <Col>
                        <>
                            {cdOperadora !== '' && cdOperadora !== undefined && cdOperadora !== null && 
                            cdFornecedor !== '' && cdFornecedor != undefined && cdFornecedor !== null && 
                            cdConta !== '' && cdConta !== undefined && cdConta !== null && 
                            cdPlano !== '' && cdPlano !== undefined && cdPlano !== null && 
                            cdCicloVida !== '' && cdCicloVida !== undefined && cdCicloVida !== null && 
                            numeroNota !== '' && numeroNota !== undefined && numeroNota !== null && 
                            numeroPedido !== '' && numeroPedido !== undefined && numeroPedido !== null && 
                            dataAtivacao !== '' && dataAtivacao !== undefined && dataAtivacao !== null && 
                            dataEmissaoNota !== '' && dataEmissaoNota !== undefined && dataEmissaoNota !== null && 
                            nomeArquivo !== '' && nomeArquivo !== undefined && nomeArquivo !== null &&
                            cdTempoPermanencia !== '' && cdTempoPermanencia !== undefined && cdTempoPermanencia !== null ?
                                <Button className="botao-confirmar" variant="success" size="sm" onClick={() => handleEnviar()}
                                disabled={bloquearCadastrar}>
                                    <div className="fonte-cor-4" id="btnCadastrarPedidoFornecedor">Cadastrar</div>
                                </Button>
                            : 
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">Cadastrar</div>
                                </Button>
                            }
                            <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2">Cancelar</div>
                            </Button>
                        </>
                    </Col>
                </Form.Row>
                
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Pedido efetuado com sucesso."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={"Essa conta possui faturas em aberto"}
                            mensagemParagrafo2={"que serão recalculadas."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemSucessoEdicao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucessoEdicao}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Endereço alterado com sucesso."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErroSimcardsRepetidos &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        {/* <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                            </div>
                            <div className="fonte-cor-1 label-janela">{dadosSimcardsRepetidos.length} linha(s) do seu arquivo já existem</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={dadosSimcardsRepetidos}
                                    columns={colunasSimcardsRepetidos}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="fonte-cor-1">Ao clicar em 'Confirmar' será realizado o download de um arquivo com as linhas repetidas</div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleDownloadSimcardsRepetidos()}>
                                    <div className="fonte-cor-2">Confirmar</div>
                                </Button>
                            </div>
                        </div> */}
                         <MensagemErro
                            handleMensagemErro={handleMensagemErroSimcardsRepetidos}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Seu pedido possui "+countSimcardsRepetidos+" linhas já existentes."}
                            mensagemParagrafo3={"Ao clicar em 'Confirmar' será realizado o download de um arquivo com as linhas repetidas"}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErroSemDados &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErroSemDados}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"O arquivo não possui dados"}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
        </div>
    )
}