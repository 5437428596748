import { api_svdispositivos } from "../../conexoes/api";
import { requestResolver } from "../../utils/requestResolver";
import { formatBusca } from "../../utils/genericFormatters";

const getParams = (busca, separadorLinha) => {
  if (!busca) return "";
  return formatBusca(busca, separadorLinha);
};

export const SolicitationsService = {
  getWhitechip: (
    pagina,
    linhasPorPagina,
    filtros = {},
    busca,
    buscaArquivo = {},
    separadorLinhaBusca,
    contagem
  ) => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/solicitations`, {
        params: {
          pagina,
          linhasPorPagina,
          contagem,
          filtro: filtros.vazio ? undefined : filtros,
          busca: busca ? getParams(busca, separadorLinhaBusca) : undefined,
          buscaArquivo: buscaArquivo.chave ? buscaArquivo : undefined,
        },
      })
    );
  },
  getDetails: (id, pagina, linhasPorPagina, contagem) => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/${id}/solicitations`, {
        params: {
          pagina,
          linhasPorPagina,
          contagem,
        },
      })
    );
  },
  getCarrierFilter: () => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/solicitations/list/carriers`)
    );
  },
  getUserFilter: () => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/solicitations/list/users`)
    );
  },
  getStatusFilter: () => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/solicitations/list/status`)
    );
  },
  createReport: (id, type) => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/${id}/solicitations/export`, {
        params: { tipo_arquivo: type.toLowerCase() },
      })
    );
  },
  createPeriodReport: (period) => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/solicitations/export/period`, {
        params: { periodo: period },
      })
    );
  },
  // #region Provisionamento
  validateIccids: (params) => {
    const {
      cd_dsp_whitechip,
      filtros,
      busca,
      separadorLinhaBusca,
      buscaArquivo,
      fl_parque_todo = false,
    } = params;

    return requestResolver(
      api_svdispositivos.post(
        `/api/v2/whitechip/solicitations/checkInvalidIccids`,
        {
          cd_dsp_whitechip,
          fl_parque_todo,
          filtros: filtros?.vazio ? undefined : filtros,
          busca: busca ? getParams(busca, separadorLinhaBusca) : undefined,
          buscaArquivo: buscaArquivo?.chave ? buscaArquivo : undefined,
        }
      )
    );
  },
  registerSolicitation: (body) => {
    return api_svdispositivos.post(
      `/api/v2/whitechip/solicitations/register`,
      body
    );
  },
  //#endregion Provisionamento

  // #region Dispositivos
  getDevices: (idSolicitation) => {
    return api_svdispositivos.get(`/api/v2/whitechip/solicitations/devices`, {
      params: {
        CdSolicitacao: idSolicitation,
      },
    });
  },
  //#endregion Dispositivos
};
