import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem, maximoTagsExibidas} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

function Filtros(props) {
    const [nome,setNome] = useState('')
    const [descricao,setDescricao] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []
    

    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            setNome(filtros.ds_pav_tela)
            setDescricao(filtros.ds_pav_descricao)
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);


    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1369) /*pavTelaTabelaGridDropFiltrosFiltrar*/
        if(nome !== '' && nome)arrayFiltrosSimcard.push("Tela: "+nome+"?ds_pav_tela")
        if(descricao !== '' && descricao)arrayFiltrosSimcard.push("Descrição: "+descricao+"?ds_descricao")

        despacho(maximoTagsExibidas(props.setRevelarDropFiltros))
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(nome == '') verificador.push(1)
            if(descricao =='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 2) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setNome('')
            setDescricao('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [nome,descricao,props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
                <div className="campo-select-triangulo-menor-cor-2"></div>
                <div className="drop-acoes-corpo fundo-cor-7">
                    <div className="drop-acoes-lista-menu">
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-tela", "imagem-filtro-tela"); ColetaClickstream(1367) /*pavTelaTabelaGridDropFiltrosNomeDaTela*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Nome da tela </Form.Label>
                            <img id={"imagem-filtro-tela"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div id={"filtro-tela"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <Form.Control
                                name="ds_pav_tela"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="45"
                                placeholder="Nome da tela"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                            />
                            <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        </div>
                        {/* fim do campo  */}
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-descricao","imagem-filtro-descricao"); ColetaClickstream(1368) /*pavTelaTabelaGridDropFiltrosDescricao*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Descrição </Form.Label>
                            <img id={"imagem-filtro-descricao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div id={"filtro-descricao"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                            <Form.Control
                                name="ds_descricao"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="45"
                                value={descricao}
                                placeholder="Descrição da tela"
                                onChange={(e) => setDescricao(e.target.value)}
                            />
                        </div>
                        {/* fim do campo  */}
                        <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                        <div className="botao-central-filtros">
                            <Form.Row>
                                <Col>
                                    <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                        Filtrar
                                    </Button>
                                    <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                        Limpar
                                    </Button>
                                </Col>
                            </Form.Row>
                        </div>
                    </div>
                </div>
            </Form>
    );
}

export default Filtros;
