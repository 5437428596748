import React from 'react';
import { alertaAmarelo } from '../../../utils/alternanciaIcones';

import './TextoAtencao.css';

export default function TextoAtencao({texto}) {
    return (
        <div className={'template-disclaimer'}>
            <img style={{maxWidth: '24px!important'}} src={alertaAmarelo()} alt='ícone alerta' />
            <div className={'template-disclaimer-text'}>{texto}</div>
        </div>
    )
}