const ESTADO_INICIAL = {
    revelarDownloadRelatorio: false,
    revelarGerarRelatorio: false,
}

export const relatorioReducer = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'REVELAR_DOWNLOAD_RELATORIO') {
        return {
            ...state,
            revelarDownloadRelatorio : action.parametro
        };
    }else if (action.type === 'PAGINA_GERAR_RELATORIO'){
        return {
            ...state,
            revelarGerarRelatorio : action.parametro
        }
    }else{
        return state
    }
}