import { TextInput as TextInputMantine } from "@mantine/core";
import styled from "styled-components";

export const Input = styled(TextInputMantine)`
  .mantine-Input-input {
    border: none !important;
    color: ${({ theme }) => theme.cor9}!important;
    background-color: ${({ theme }) => theme.cor27}!important;
    height: 38px;
    font-size: 16px;

    ::placeholder {
      color: ${({ theme }) => theme.cor39}!important;
    }
  }
  
  .mantine-InputWrapper-label {
    color: ${({ theme }) => theme.cor9} !important;
    font-size: 20px !important;
    background-color: transparent !important;
  }
`;
