import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
import Calendario from '../calendario/calendario'
import moment from 'moment';
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem } from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltrosModuloSmsTemplate(props) {
    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroDataInicial, setFiltroDataInicial] = useState('')
    const [filtroDataFinal, setFiltroDataFinal] = useState('')
    const [filtroOperacao, setFiltroOperacao] = useState('')
    const [filtroValorMinimo, setFiltroValorMinimo] = useState('')
    const [filtroValorMaximo, setFiltroValorMaximo] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            if(filtros.EMISSAO_INICIO !== '' && filtros.EMISSAO_INICIO){
                let auxData = new Date(filtros.EMISSAO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataInicial(auxData)
            }
            if(filtros.EMISSAO_FINAL !== '' && filtros.EMISSAO_FINAL){
                let auxData = new Date(filtros.EMISSAO_FINAL)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataFinal(auxData)
            }
            setFiltroOperacao(filtros.OPERACAO)
            setFiltroValorMinimo(filtros.VALOR_MIN)
            setFiltroValorMaximo(filtros.VALOR_MAX)
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1216) /*pedidosSimcardTabelaDropFiltrosFiltrar*/
        if(filtroDataInicial !== '' && filtroDataFinal !== '' && filtroDataInicial && filtroDataFinal) {
            arrayFiltrosSimcard.push("Emissão: entre "+moment(filtroDataInicial).format("DD-MM-YYYY")+" e "+moment(filtroDataFinal).format("DD-MM-YYYY")+"?EMISSAO_INICIO")
        }
        if(filtroOperacao !== '' && filtroOperacao)arrayFiltrosSimcard.push("Operação: "+filtroOperacao+"?OPERACAO")
        if(filtroValorMinimo !== '' && filtroValorMaximo !== '' && filtroValorMinimo && filtroValorMaximo){
            arrayFiltrosSimcard.push("Valor entre "+filtroValorMinimo+" e "+filtroValorMaximo+"?VALOR_MIN")
        }

        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

      //verifica se os radios butons estao ativos
      function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroDataInicial == '') verificador.push(1)
            if(filtroDataFinal == '') verificador.push(1)
            if(filtroOperacao == '') verificador.push(1)
            if(filtroValorMinimo == '') verificador.push(1)
            if(filtroValorMaximo == '') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 5) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroDataInicial('')
            setFiltroDataFinal('')
            setFiltroOperacao('')
            setFiltroValorMinimo('')
            setFiltroValorMaximo('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroDataInicial, filtroDataFinal, filtroOperacao, filtroValorMinimo, filtroValorMaximo, props.foiLimpado]);

    useEffect(() => {
         filtroDataFinal < filtroDataInicial ? setFiltroDataFinal('') : ''
    } , [filtroDataFinal , filtroDataInicial])

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-valor-minimo"){
                setFiltroValorMinimo(valor)
            }
            if(id === "campo-texto-valor-maximo"){
                setFiltroValorMaximo(valor)
            }
        }
    }

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-data","imagem-filtro-data"); ColetaClickstream(1213) /*pedidosSimcardTabelaDropFiltrosDataDaEmissao*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data da emissão </Form.Label>
                        <img alt="seta" id={"imagem-filtro-data"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="EMISSAO_INICIO" type="text" value={filtroDataInicial ? moment(filtroDataInicial).format("YYYY-MM-DD hh:mm:ss") : filtroDataInicial} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataInicial} setData={setFiltroDataInicial} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="EMISSAO_FINAL" type="text" value={filtroDataFinal ? moment(filtroDataFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataFinal} setData={setFiltroDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-operacao","imagem-filtro-operacao"); ColetaClickstream(1214) /*pedidosSimcardTabelaDropFiltrosOperacao*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Operação </Form.Label>
                        <img alt="seta" id={"imagem-filtro-operacao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-operacao"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperacao("Reposição")}>Reposição
                            <input defaultChecked={handlerCheck("OPERACAO","Reposição")} type="radio" id="OPERACAO_REPOSICAO" name="OPERACAO" value="Reposição"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperacao("Pré-ativação")}>Pré-ativação
                            <input defaultChecked={handlerCheck("OPERACAO","Pré-ativação")} type="radio" id="OPERACAO_PRE_ATIVACAO" name="OPERACAO" value="Pré-ativação"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperacao("Ativação")}>Ativação
                            <input defaultChecked={handlerCheck("OPERACAO","Ativação")} type="radio" id="OPERACAO_ATIVACAO" name="OPERACAO" value="Ativação"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-valor","imagem-filtro-valor"); ColetaClickstream(1215) /*pedidosSimcardTabelaDropFiltrosValor*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Valor </Form.Label>
                        <img alt="seta" id={"imagem-filtro-valor"}  className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-valor"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <label className="filtros-label-real fonte-cor-1 campo-texto-cor-3">R$</label>
                                    <Form.Control
                                        name="VALOR_MIN"
                                        className="filtros-texto-valor-real filtros-texto-valor-real campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        value={filtroValorMinimo}
                                        onChange={e => validarNumero("campo-texto-valor-minimo", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <label className="filtros-label-real fonte-cor-1 campo-texto-cor-3">R$</label>
                                    <Form.Control
                                        name="VALOR_MAX"
                                        className="filtros-texto-valor-real filtros-texto-valor-real campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        value={filtroValorMaximo}
                                        onChange={e => validarNumero("campo-texto-valor-maximo", e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}