//IMPORTAÇÕES
import React, { useEffect, useState } from 'react';/*eslint-disable*/
import 'moment-timezone';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import moment from 'moment'

import {api_migracao} from '../../../../../conexoes/api';/*eslint-disable*/
import PaginacaoTabela from '../../../../../utils/paginacaoTabela'
import SpinerVeye from '../../../../spinnerVeye/spinnerVeye'
import { columnValue, formatterText } from '../../../../../utils/tabela';

let contador = 0
let totalPaginas = 0
let totalItens = 0
let itensVar = []
const valorLimite = 10;

export default function HistoricoDeAcoes(props){
    //HOOKS TABELA
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [itens, setItens] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [refazerChamadas, setRefazerChamadas] = useState(true);

    const temPaginacao = itens.length > 0;
    

    useEffect(()=>{
        contador = (paginaAtual - 1) * valorLimite;
        setSpinAtivo(true)
        async function carregarHistorico(){

            let params = "CdSimcard="+ props.cdSimcard
                + "&pagina="+ paginaAtual //paginaAtualBody
                + "&linhasPorPagina="+ valorLimite
            
            if(refazerChamadas === true){
                const movimentosDados = await consultarFatSimcardMovimento(params + "&contagem=true");
                totalItens = movimentosDados.item_count;
                totalPaginas = Math.ceil(totalItens / valorLimite)
                setRefazerChamadas(false)
                inserirData(movimentosDados.requisicoes)
            }
            else {
                const movimentosDados2 = await consultarFatSimcardMovimento(params + "&contagem=false");
                inserirData(movimentosDados2.requisicoes)
            }

        }
        carregarHistorico()
    },[paginaAtual])

    async function consultarFatSimcardMovimento(params) {
        const resp = await api_migracao.get('/api/v2/migration/history?' + params)
        return resp.data;
    }

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
                itensVar = [];
            }
        }
        setSpinAtivo(false)
    }

    
    const dateFormatterColumn  = (cell) => {
        if(!cell || cell === "0000-00-00"){
            return columnValue('-');
        }else{
            const dataBr = moment(cell).format('DD/MM/YYYY - HH:mm');
            return columnValue(dataBr);
        }
    }

    const colunas = [
        {
            dataField: "DataRequisicao",
            text: 'Data da requisição',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: dateFormatterColumn
        },
        {
            dataField: 'CicloEfetivacao',
            text: 'Ciclo de efetivação',
            // sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: formatterText,
        },
        {
            dataField: 'PlanoCliente',
            text: 'Plano cliente',
            // sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: formatterText,
        },
        {
            dataField: 'PlanoOperadora',
            text: 'Plano operadora',
            // sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: formatterText,
        },
        {
            dataField: 'StatusMigracao',
            text: 'Status migração',
            // sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: formatterText,
        },
    ]

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }
    
    return(
        <>
            {spinAtivo && 
                <div className="componente-spinner-overlay">
                    <div className="componente-spinner-container">
                        <SpinerVeye/>
                    </div>
                </div> 
            }        
        <div className='container-historico-movimento-simcard'>
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <div className="container-tabela-extrato">
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                data={itens}
                                keyField={"DataRequisicao"}
                                columns={colunas} 
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            {temPaginacao &&
                                <PaginacaoTabela
                                extrato={true}
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                                tipo2
                                />
                            }
                        </div>
                    )
                }
            </PaginationProvider>
            {temPaginacao && 
                <div className="itens-exibidos-tabela-extrato fonte-cor-1">
                    Mostrando 
                    <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                        - 
                    <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                        de 
                    <span className="label-tabela-itens-exibidos">{totalItens}</span>
                </div>
            }
           
        </div>
        </>
    )
}