
import { api } from "../../../conexoes/api"

export async function validacaoAcaoPedidoSms(pedidosSelecionados, codigoAcao){
    let contador = 0
    let contadorExcluidos = 0
    let itensValidos = []
    let itensInvalidos = []
    await Promise.all(pedidosSelecionados.map(async (item, i)=>{
        const { data } = await api.get('/m1/consultar/sms_pedido?sms9.cd_sms_pedido='+item.NUMERO_PEDIDO);
        if(data.status === 200 && data.dados.length > 0){
            const codigo_status = data.dados[0].CODIGO_STATUS
            if(codigo_status === codigoAcao){
                itensInvalidos[contadorExcluidos] = item.NUMERO_PEDIDO
                contadorExcluidos++ 
            }else if(codigo_status === 2 && codigoAcao === 1){
                itensValidos[contador] = item
                contador++
            }else if(codigo_status === 2 && codigoAcao === 3){
                itensValidos[contador] = item
                contador++
            }else if(codigo_status === 1 && codigoAcao === 4){
                itensValidos[contador] = item
                contador++
            }else{
                itensInvalidos[contadorExcluidos] = item.NUMERO_PEDIDO
                contadorExcluidos++ 
            }
        }        
    }))
    return {itensValidos, itensInvalidos}
}