const ESTADO_INICIAL = {
    buscaArquivo: {}, // {vazio: 'vazio'},
    limparBuscaArquivo: true,
};

export const buscaArquivoReducer = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'MUDAR_BUSCA_ARQUIVO') {
        return {
            ...state, 
            buscaArquivo: action.parametro
        }
    } else if (action.type === "LIMPAR_BUSCA_ARQUIVO") { 
        return {
            ...state,
            limparBuscaArquivo: action.parametro
        }
    } else {
        return state;
    }
}
