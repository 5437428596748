import axios from "axios";
const config = require('../configuracoes.json')
const api_google = axios.create({
    baseURL: config.google.ip
});

api_google.interceptors.request.use(async config => {
    return config;
});

export {api_google}