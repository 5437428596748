//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectTipoPlanoFaturas from '../../componentesModais/selects/selectTipoPlanoFaturas'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectTipoPlanoFaturas, setRevelarSelectTipoPlanoFaturas] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">TipoPlanoFaturas {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectTipoPlanoFaturas === false &&
                <Button name="cdTipoPlanoFaturas" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoPlanoFaturas(!revelarSelectTipoPlanoFaturas)}>
                    <div className="fonte-cor-1 label-campo">{dsTipoPlanoFaturas}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectTipoPlanoFaturas === true &&
                <SelectTipoPlanoFaturas
                    setRevelarSelectTipoPlanoFaturas={setRevelarSelectTipoPlanoFaturas} //Hook para fechar a modal
                    setCdTipoPlanoFaturas={setCdTipoPlanoFaturas} //Código do TipoPlanoFaturas
                    setDsTipoPlanoFaturas={setDsTipoPlanoFaturas} //Nome do TipoPlanoFaturas
                    dsTipoPlanoFaturas={dsTipoPlanoFaturas}
                >
                </SelectTipoPlanoFaturas>
            }
        </Form.Group>*/

export default function SelectTipoPlanoFaturas(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleTipoPlanoFaturas(codigo, nome){
        props.setCdTipoPlanoFaturas(codigo)
        props.setDsTipoPlanoFaturas(nome)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectTipoPlanoFaturas(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectTipoPlanoFaturas(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsTipoPlanoFaturas}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleTipoPlanoFaturas(1,"Pacote")}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Pacote</span>
                        </div>
                        <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
                    </li>
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleTipoPlanoFaturas(2,"Sob demanda")}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Sob demanda</span>
                        </div>
                        <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
                    </li>
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleTipoPlanoFaturas(3,"Outro")}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel"> Outro</span>
                        </div>
                    </li>
                </ul>
            </div>
        </Form>
    )
}