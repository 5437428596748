//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api_s3} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdCheckCircle, MdError } from "react-icons/md";
import decryptJWT from './../../../../utils/decryptJWT';
import ConversorBytes from './../../../../utils/conversorBytes';

//ICONES
import {importarArquivo, elipseVerde, fecharJanela, setaDropdown, alertaVermelho, editar, menuPedidos} from '../../../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
//MODAIS
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//CSS
import '../../../../css/painelFaturas/fatFebraban/tabela.css'
import { revelarImportarFebrabans, revelarAssociarFatura } from '../../../../redux/actions';

export default function ImportarFabrabans(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [tituloJanela, setTituloJanela] = useState('Nova');
    const [arquivo, setArquivo] = useState([]);
    const [nomeArquivo, setNomeArquivo] = useState([]);
    const [sizeFile, setSizeFile] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    // const [mensagemTempo, setMensagemTempo] = useState(false) 
    const [mensagemTamanhoExcedido, setMensagemTamanhoExcedido] = useState(false) 
    const [mensagemDuplicidade, setMensagemDuplicidade] = useState(false) 
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();
    
    const iconeAlerta = (
        <img
          className="icone-alerta-filtro-arquivo"
          src={alertaVermelho()}
          alt="alerta"
        />
      );

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    useEffect(() => {
        setSpinAtivo(true)
        if(props.tituloJanela === 'Editar'){
          
        }
        if (props.tituloJanela === 'Concluir'){
            setTituloJanela('Concluir')
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    useEffect(() => {
        const soma = somarArr(sizeFile)
        setTotalSize(soma)
    }, [arquivo, sizeFile, totalSize])

    async function handleSalvar(){
        ColetaClickstream(1246) // 'fatFebrabanTabelaDropAcoesImportarFebrabansCadastrar'
        setSpinAtivo(true)
        
        // setTimeout(() => {
        //     if(spinAtivo === false){
        //         setMensagemTempo(true) // mostra após 10seg
        //     }
        // }, 1000)

        for(let i = 0; i < arquivo.length; i++) {
            let data = new FormData();
            data.append('file', arquivo[i]);
            data.append('cd_pav_usuario', decryptJWT('codigo'));
            data.append("cd_pav_cliente", decryptJWT('codigoCliente'));
            data.append('cd_fat_tipo_febraban', 2);  // ESTA CHUMBADO COMO 2 POIS A VALIDAÇÃO DA VERSÃO É FEITA NA PROCEDURE
            api_s3.post("s3/upload/febraban", data)
                .then(response => {
                    if (response.status === 200){
                        handleMensagemSucesso(true);
                        // setMensagemTempo(false) 
                        setSpinAtivo(false)
                    }else{
                        // setMensagemTempo(false) 
                        setSpinAtivo(false)
                    }
                })
                .catch((error) => {
                    error.response.data !== undefined ? setMensagemErro(error.response.data.info) : setMensagemErro("Erro ao fazer o upload")
                    handleMensagemErro(true)
                    setSpinAtivo(false)
                });
        }
    }

    async function handleCancelar() {
        ColetaClickstream(1245) // 'fatFebrabanTabelaDropAcoesImportarFebrabansCancelar'
        setSpinAtivo(false)
        props.setRenderizar(!props.renderizar)
        despacho(revelarImportarFebrabans(false))
        despacho(revelarAssociarFatura(false))
        setArquivo([]);
        setSizeFile([]);
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao ===true){
            }
        }
    }

    // function handleMensagemAtencaoAlerta(confirmacao){ 
    //     if(mensagemTempo === false){
    //         setMensagemTempo(true) 
    //     }else{
    //         setMensagemTempo(false) 
    //         if(confirmacao === true){
    //         }
    //     }
    // }

    function handleMensagemTamanhoExcedido(confirmacao){
        if(mensagemTamanhoExcedido === false){
            setMensagemTamanhoExcedido(true)
        }else{
            setMensagemTamanhoExcedido(false)
            if(confirmacao === true){
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
        }
    }    

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    // handleMensagemDuplicidade
    function handleMensagemDuplicidade(confirmacao){
        if(mensagemDuplicidade === false){
            setMensagemDuplicidade(true)
        }else{
            setMensagemDuplicidade(false)
            if(confirmacao){
            }
        }
        
    }   

    function validarArquivo(nomeArquivo){
        if(nomeArquivo.slice(-3).toLowerCase() === "txt" || nomeArquivo.slice(-3).toLowerCase() === "zip") {
            setRevelarMensagemErro(false)
            return true
        }else{
            setMensagemErro("O arquivo não é do tipo .txt ou .zip")
            setRevelarMensagemErro(true)
            return false
        }
    }

    // remove o item da lista
    function removeItemList(array, index) {
        array.splice(index, 1);
        return array
    }

    // remove o item da lista de nome
    function removeNameList(array, index) {
        const newArr = array
        newArr.splice(index, 1);
        return newArr
    }

    // remove o item da lista
    function removeSizeList(array, index) {
        array.splice(index, 1);
        return array
    }

    // adiciona a elemento lista de array
    function increment(array, el) {
        array.push(el)
        return array;
    }

    // somar os elementos de um array
    function somarArr(array) {
        return array.reduce((total, el) => total + el, 0)
    }  
    
    function verificaDuplicidade(array, el) {
        return array.find((item) => item === el) !== undefined;
    }

    //Funcao para ler os dados do arquivo
    function handleLerArquivo(event){
        // como aceita múltiplas seleções, verifica a quantidade de arquivos inseridos
        for (let q = 0; q < event.target.files.length; q++) {
            if(event.target.files[q] !== undefined) {
                let files = event.target.files[q];
                let sizeActual = 0          

                // valida a extensão do arquivo .txt ou .zip
                const val = validarArquivo(files.name)
                if (val === false) {
                    return
                }

                // verifica se o arquivo já está contido no array evitando duplicidade
                const duplicado = verificaDuplicidade(nomeArquivo, files.name)
                const soma = somarArr(sizeFile)
                setTotalSize(soma)
                sizeActual = Number(files.size) + Number(soma)            
                
                if (ConversorBytes(files.size, 'B', 'MB') > 500) {
                    setMensagemTamanhoExcedido(true)
                } else if(ConversorBytes(sizeActual, 'B', 'MB') > 500) {
                    setMensagemTamanhoExcedido(true)
                }  else if(duplicado === true ) {
                    setMensagemDuplicidade(true)
                } else {               
                    if (totalSize === 0) {
                        setTotalSize(files.size)
                    }       
                    setSizeFile(increment(sizeFile, files.size));   
                    setNomeArquivo(increment(nomeArquivo, files.name));                      
                    setArquivo(increment(arquivo, files));         
                }            
            }
        }                   
        setRenderizar(!renderizar) 
    };

    function handleDelete(e, indice) {

        e.preventDefault();

        // pega a posição do indice no array de arquivos
        const posicao = nomeArquivo.indexOf(indice)

        // remove o item da lista de NOME de arquivos
        const newItens = removeItemList([...arquivo], posicao)
        setArquivo(newItens)

        // remove o item da lista de TAMANHO baseado na posição do arquivo
        const newSize = removeSizeList([...sizeFile], posicao)
        setSizeFile(newSize)

        // remove o item da lista de nomes
        const newName = removeNameList([...nomeArquivo], posicao)
        setNomeArquivo(newName)
        
        // remove o item da lista de somatoria
        const soma = somarArr(newSize)
        setTotalSize(soma)

        setRenderizar(!renderizar)
    };
    
    const ListFiles = arquivo.map((item, i) => 
        <>
            <p className="titulo-lista-pedidos-fatura fonte-cor-1">
                <li className="lista-pedidos-faturas campo-texto-cor-3">
                        <p className="lista-nome-pedidos-pedidos">{arquivo[i].name}</p>
                        <div>
                            <MdCheckCircle size={24} color="#36D896"/>
                        </div>
                    <div className="botaoLixeira">
                        <button className="excluir" onClick={(e) => handleDelete(e, arquivo[i].name)}>
                            <div className="lixeira"/> 
                        </button>
                    </div>
                </li>
            </p>
        </>
    );

    function ListaArquivos ({ files }) {
        return (
            <>
            {files.length != 0 &&
            <div className="Container qtdArquivosModal">
                <p className="titulo-lista-pedidos-fatura fonte-cor-1"> Arquivo{files.length > 1 ? 's' : ''} selecionado{files.length > 1 ? 's' : ''}</p>
                    {ListFiles}                    
            </div>
            }  
            </>
        )
    }

    const handleClickOutroBotao = (e) =>{
        const importar = document.getElementById('importar-febraban')
        importar.click();
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-importar-febrabans fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Importar febrabans
                            </div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                
                <Form.Row className="usuario-dados-corpo-linha">
                    <Col>
                        <Form.Label> <div className="fonte-cor-1 label-campo">Arquivos</div> </Form.Label>
                        <div>
                            <input multiple id="importar-febraban" type="file" onChange={(e) => handleLerArquivo(e)} className="input-importar-febraban"/>
                            <Button className="btn-importar-febraban" onClick={(e) => handleClickOutroBotao(e)}>
                                <div className="fonte-cor-4">Importar</div>
                                <img className="img-importar-febraban" src={importarArquivo()} />
                            </Button>
                        </div>
                    </Col>
                </Form.Row>

                <ListaArquivos files={arquivo}/>
                
                <p className="label-alerta-pedidos-margem fonte-cor-3 label-alerta-febraban">{iconeAlerta} Os tipos de arquivos suportados são '.zip e .txt'</p>

                {sizeFile.length > 0 &&
                    <p className="label-alerta-pedidos-margem fonte-cor-3 label-alerta-febraban"> Restam { (500 - ConversorBytes(totalSize, 'B', 'MB')).toFixed(2) } MB livres</p>
                }

                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo"/>
                <Form.Row className="espaco-reativar-linha">
                    <Col>
                        <>
                        {arquivo.length > 0 ?
                                <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">{tituloJanela}</div>
                                </Button>
                            :
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">{tituloJanela}</div>
                                </Button>
                            }
                            <Button className={"botao-cancelar"} variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2">Cancelar</div>
                            </Button>
                        </>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Upload realizado"}
                            mensagemParagrafo1={"O arquivo febraban será processado em breve."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover Linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
              {/* {mensagemTempo &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencaoAlerta}
                            mensagemTitulo={"Seu arquivo está sendo carregado, aguarde."}
                            mensagemBotao={"OK"}
                        />
                    </div>
                </div>
            } */}
            {mensagemTamanhoExcedido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemTamanhoExcedido}
                            mensagemTitulo={"O tamanho máximo do upload é de 500MB."}
                            mensagemBotao={"OK"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}                            
                        />
                    </div>
                </div>
            }
            {mensagemDuplicidade &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemDuplicidade}
                            mensagemTitulo={"Este arquivo já está na lista de importação."}
                            mensagemBotao={"OK"}
                            semBotaoCancelar
                        />
                    </div>
                </div>
            }
        </Form>
    )
}