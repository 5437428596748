import { api_svsolicitacoes } from "../../../../conexoes/api";

export async function getDadosReenviarSolicitacaoFat(cd_crm_solicitacao) {
    return await api_svsolicitacoes.post("/api/v2/reenviarSolicitacao", { cd_crm_solicitacao })
    .then(thenPadrao)
    .catch(catchPadrao)
}

const thenPadrao = (response) => {
    if(response.status === 200) {
        return response?.data?.dados;;
    }
    return false;
}

const catchPadrao = (erro) => {
    console.error({erro})
    return false;
}