//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../../conexoes/api';
import { useForm } from "react-hook-form";/*eslint-disable*/
import moment from 'moment';
import { Button, Form, Col } from 'react-bootstrap';
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, fecharJanela, setaDropdown} from '../../../../utils/alternanciaIcones'
//MODAIS
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
import SelectGenericoCheck from '../../../componentesModais/selects/selectGenericoCheckbox'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemInformacao from '../../../componentesModais/mensagens/mensagemInformacao'
// SPINNER
import Calendario from '../../../calendario/calendario'
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarNovoPlano, revelarEditarPlano, revelarGerarRelatorios } from "../../../../redux/actions/index";
import decryptJWT from '../../../../utils/decryptJWT';
import '../../../../css/painelFaturas/fatRelatorios/tabela.css'

export default function CadastroOperadora(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const {handleSubmit} = useForm();
    //HOOKS PRINCIPAIS
    const [cdPlano, setCdPlano] = useState()
    const [dsNomePlano, setDsNomePlano] = useState()
    const [cdFornecedor, setCdFornecedor] = useState()
    const [n0Simcards, setN0Simcards] = useState(0)
    const [arrayServicos, setArrayServicos] = useState([])
    const [arrayServicosDeletar, setArrayServicosDeletar] = useState([])
    const [dsValorTotal, setDsValorTotal] = useState(0)
    //HOOKS PLACEHOLDERS
    const [phNomePlano, setPhNomePlano] = useState('Digite o nome do plano')
    const [phMensagemSucesso, setPhMensagemSucesso] = useState('cadastrado')
    const [bloquearAcoes, setBloquearAcoes] = useState(true)
    //HOOKS MODAIS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    //OUTROS HOOKS
    const [linhaRemover, setLinhaRemover] = useState('');
    const [tituloJanela, setTituloJanela] = useState('Cadastro');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const modal = useRef();
    const despacho = useDispatch();


    // HOOKS NOVO 
    const [revelarSelectCliente, setRevelarSelectCliente] = useState(false);
    const [revelarSelectOperadora, setRevelarSelectOperadora] = useState(false);
    const [revelarSelectStatus, setRevelarSelectStatus] = useState(false);
    const [revelarSelectTipoFormato, setRevelarSelectTipoFormato] = useState(false);
    const [revelarSelectTipoRelatorio, setRevelarSelectTipoRelatorio] = useState(false);
    const [dsCliente, setDsCliente] = useState('Selecione');
    const [cdCliente, setCdCliente] = useState('');
    const [cdOperadora, setCdOperadora] = useState()
    const [dsOperadora, setDsOperadora] = useState('Selecione')
    const [cdStatus, setCdStatus] = useState()
    const [dsStatus, setDsStatus] = useState('Selecione')
    const [cdTipoFormato, setCdTipoFormato] = useState()
    const [dsTipoFormato, setDsTipoFormato] = useState('Selecione')
    const [cdTipoRelatorio, setCdTipoRelatorio] = useState()
    const [dsTipoRelatorio, setDsTipoRelatorio] = useState('Selecione')
    const [dummy, setDummy] = useState('');
    const [inicioCiclo, setInicioCiclo] = useState("")
    const [fimCiclo, setFimCiclo] = useState("")
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState()
    const cd_pav_usuario = decryptJWT('codigo')

    const [arrayDummy,setArrayDummy] = useState([])
    const [arrayCdCliente,setArrayCdCliente] = useState([])
    const [arrayDsCliente,setArrayDsCliente] = useState([])
    const [arrayNomeFantasia,setArrayNomeFantasia] = useState([])
    const [arrayCdOperadora,setArrayCdOperadora] = useState([])
    const [arrayDsOperadora,setArrayDsOperadora] = useState([])
    const [arrayApelidoOperadora,setArrayApelidoOperadora] = useState([])
    const [arrayCdStatus,setArrayCdStatus] = useState([])
    const [arrayDsStatus,setArrayDsStatus] = useState([])
    const [arrayStatusOperadora,setArrayStatusOperadora] = useState([])

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        setSpinAtivo(true)
        if (props.tituloJanela === 'Editar'){
            async function carregarServicos(){
                setSpinAtivo(true)
                let buscaServicos = await api.get('/m1/consultar/fat_plano/servico_por_plano?cd_fat_plano='+props.cdPlano);
                buscaServicos.data.dados.map((item)=>{
                    arrayServicos.push({
                        'cd_fat_servico': item.cd_fat_servico,
                        'ds_fat_servico': item.ds_fat_servico,
                        'cd_fat_tipo': item.cd_fat_tipo,
                        'ds_tipo': item.ds_tipo,
                        'cd_fat_unidade': item.cd_fat_unidade,
                        'ds_unidade': item.ds_unidade,
                        'cd_fat_categoria': item.cd_fat_categoria,
                        'ds_categoria': item.ds_categoria,
                        'ds_quantidade': item.ds_quantidade,
                        'n2_valor': item.n2_valor,
                        'fl_status': item.fl_status
                    })
                })
                // carregarValorTotal()
                
                setSpinAtivo(false)
            }
            carregarServicos()
            //TROCA O TITULO DA JANELA
            setTituloJanela('Editar')
            //CARREGA OS CAMPOS COM OS VALORES ORIGINAIS
            // setCdPlano(props.cdPlano)
            // setDsNomePlano(props.dsNomePlano)
            // setCdFornecedor(props.cdFornecedor)
            // setDsFornecedor(props.dsFornecedor)
            // setCdOperadora(props.cdOperadora)
            // setDsOperadora(props.dsOperadora)
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            // setPhNomePlano('Original: '+props.dsNomePlano)
            // setPhMensagemSucesso('editado')
            let valorTotalAux = 0
            let n0SimcardsAux = 0
            props.arrayServicos.map((item,i) => {
                valorTotalAux = (valorTotalAux + parseFloat(item.ds_valor))
                n0SimcardsAux = (n0SimcardsAux + parseFloat(item.n0_simcards))
            })
            setDsValorTotal(valorTotalAux)
            setN0Simcards(n0SimcardsAux)
        }
        if (props.tituloJanela === 'Cadastrar'){
            setTituloJanela('Cadastrar')
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function removerTodos(array){
        let indice = array.indexOf(-1);
        array.splice(indice, 1);
        indice = array.indexOf(-1);
    }

    async function handleSalvar(){
        removerTodos(arrayCdCliente)
        removerTodos(arrayCdOperadora)
        removerTodos(arrayCdStatus)

        await api.post('/m1/cadastrar/relatorios', {
            'cd_cliente' : arrayCdCliente,
            'ds_cliente' : arrayDsCliente,
            'cd_operadora' : arrayCdOperadora,
            'ds_operadora' : arrayDsOperadora,
            'cd_status' : arrayCdStatus,
            'ds_status' : arrayDsStatus,
            'cd_tipo_formato' : cdTipoFormato,
            'ds_tipo_formato' : dsTipoFormato,
            'cd_tipo_relatorio' : cdTipoRelatorio,
            'ds_tipo_relatorio' : dsTipoRelatorio,
            'cd_tipo_solicitacao' : 18,
            "dt_inicio": moment(inicioCiclo).format("YYYY-MM-DD HH:mm:ss"),
            "dt_fim": moment(fimCiclo).format("YYYY-MM-DD HH:mm:ss"),
            'cd_pav_usuario' : decryptJWT('codigo'),
            "cd_pav_cliente": decryptJWT('codigoCliente'),
            'data_criacao': moment().format('DD/MM/YYYY - HH:mm')
        },)
        // .then(function (response) {
            setRevelarMensagemInformacao(true)
            // setSpinAtivo(false)
        // })
        // .catch(function (error) {
        //     setMensagemErro(error)
        //     setRevelarMensagemErro(true)
        //     setSpinAtivo(false)
        // });
    }

    function handleCancelar(){
        despacho(revelarGerarRelatorios(false))
        setRevelarMensagemInformacao(false)
        setRevelarMensagemErro(false)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            props.setRenderizar(!props.renderizar)
            handleCancelar()
            if(confirmacao === true){
                handleCancelar()
            }
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(!bloquearAcoes){
            if(revelarMensagemAtencao === false){
                setRevelarMensagemAtencao(true)
            }else{
                setRevelarMensagemAtencao(false)
            }
            if(confirmacao === true ){
            }
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                despacho(revelarGerarRelatorios(false))
            }
        }
    }

    useEffect(()=>{
        if(props.tituloJanela === 'Editar'){   
            handlerVerificarEdicao()     
        }
    },[])

    async function handlerVerificarEdicao(){
        await api.get('/m1/consultar/fat_plano_validador?cd_fat_plano='+props.cdPlano)
        .then((response)=>{
            if(response.data.status == 200){
                if(response.data.dados){
                    setBloquearAcoes(false)
                }
                if(!response.data.dados){
                    setBloquearAcoes(true)
                }
            }
            else{
                setBloquearAcoes(true)
            }
        })
        .catch((response)=>{
            setBloquearAcoes(false)
        })
    }

    useEffect(()=>{
        function renderVariaveis(){
            setCdTipoFormato('')
            setDsTipoFormato('Selecione')
            setDsCliente('Selecione')
            setCdCliente('')
            setDsOperadora('Selecione')
            setCdOperadora('')
            setCdStatus('')
            setDsStatus('Selecione')
            setInicioCiclo('')
            setFimCiclo('')
            setArrayDummy([])
            setArrayCdCliente([])
            setArrayDsCliente([])
            setArrayNomeFantasia([])
            setArrayCdOperadora([])
            setArrayDsOperadora([])
            setArrayApelidoOperadora([])
            setArrayCdStatus([])
            setArrayDsStatus([])
            setArrayStatusOperadora([])
        }
        renderVariaveis()
    },[dsTipoRelatorio])

    useEffect(()=>{
        let placeholderAux = false
        arrayCdCliente.forEach(function(item, index){
            if(item === -1){
                placeholderAux = true
            }
        })
        if(placeholderAux === true){
            setDsCliente(arrayCdCliente.length - 1 + " Selecionados")
        }
        else if(arrayCdCliente.length === 1){
            setDsCliente(arrayDsCliente[0])
        }
        else if(arrayCdCliente.length === 0){
            setDsCliente("Selecione")
        }
        else{
            setDsCliente(arrayCdCliente.length + " Selecionados")
        }
    },[arrayCdCliente.length, arrayCdCliente[0], arrayCdCliente[arrayCdCliente.length - 1]])

    useEffect(()=>{
        let placeholderAux = false
        arrayCdOperadora.forEach(function(item, index){
            if(item === -1){
                placeholderAux = true
            }
        })
        if(placeholderAux === true){
            setDsOperadora(arrayCdOperadora.length - 1 + " Selecionados")
        }
        else if(arrayCdOperadora.length === 1){
            setDsOperadora(arrayDsOperadora[0])
        }
        else if(arrayCdOperadora.length === 0){
            setDsOperadora("Selecione")
        }
        else{
            setDsOperadora(arrayCdOperadora.length + " Selecionados")
        }
    },[arrayCdOperadora.length, arrayCdOperadora[0], arrayCdOperadora[arrayCdOperadora.length - 1]])

    useEffect(()=>{
        let placeholderAux = false
        arrayCdStatus.forEach(function(item, index){
            if(item === -1){
                placeholderAux = true
            }
        })
        if(placeholderAux === true){
            setDsStatus(arrayCdStatus.length - 1 + " Selecionados")
        }
        else if(arrayCdStatus.length === 1){
            setDsStatus(arrayDsStatus[0])
        }
        else if(arrayCdStatus.length === 0){
            setDsStatus("Selecione")
        }
        else{
            setDsStatus(arrayCdStatus.length + " Selecionados")
        }
    },[arrayCdStatus.length, arrayCdStatus[0], arrayCdStatus[arrayCdStatus.length - 1]])

       

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-cadastro-planos-faturas fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Gerar relatórios</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row className="container-tipo-relatorio-1">
                    <Col>
                        <Form.Label>
                            <div className="fonte-cor-1 label-campo">Tipo de relatório</div>
                        </Form.Label>
                        {revelarSelectTipoRelatorio === false &&
                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoRelatorio(!revelarSelectTipoRelatorio)}>
                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoRelatorio}</div>
                                <img className={revelarSelectTipoRelatorio?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                            </Button>
                        }
                        {revelarSelectTipoRelatorio === true &&
                            <SelectGenerico
                                paramRota={"tipo_relatorios"}
                                paramCampo1={cd_pav_usuario}
                                paramCampo2={decryptJWT('codigoCliente')}
                                paramCampo3={null}
                                paramCampo4={null}
                                setCodigo={setCdTipoRelatorio}
                                setDescricao1={setDsTipoRelatorio}
                                setDescricao2={setDummy}
                                setDescricao3={setDummy}
                                setDescricao4={setDummy}
                                setRevelarSelect={setRevelarSelectTipoRelatorio}
                                placeholder={dsTipoRelatorio}
                                sufixo={""}
                            />
                        }
                    </Col>
                </Form.Row>
                {dsTipoRelatorio === "Contestação" &&
                    <>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                            <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        {revelarSelectCliente === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectCliente === true &&
                                            <SelectGenericoCheck
                                                paramRota={"cliente_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdCliente}
                                                arrayDescricao1={arrayDsCliente}
                                                arrayDescricao2={arrayNomeFantasia}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectCliente}
                                                placeholder={dsCliente}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Operadora</div>
                                        </Form.Label>
                                        {revelarSelectOperadora === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsOperadora}</div>
                                                <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }

                                        {revelarSelectOperadora === true &&
                                            <SelectGenericoCheck
                                                paramRota={"operadora_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdOperadora}
                                                arrayDescricao1={arrayDsOperadora}
                                                arrayDescricao2={arrayApelidoOperadora}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectOperadora}
                                                placeholder={dsOperadora}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Status</div>
                                        </Form.Label>
                                        {revelarSelectStatus === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectStatus(!revelarSelectStatus)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsStatus}</div>
                                                <img className={revelarSelectStatus?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectStatus === true &&
                                            <SelectGenericoCheck
                                                paramRota={"status_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdStatus}
                                                arrayDescricao1={arrayDsStatus}
                                                arrayDescricao2={arrayStatusOperadora}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectStatus}
                                                placeholder={dsStatus}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                            <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data inicio</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data fim</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={fimCiclo} setData={setFimCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="container-campo-texto-2">
                                        {/* DIV COLOCADA SO PARA FICAR CENTRALIZADO OS CAMPOS NO FRONT */}
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsCliente !="Selecione" && dsOperadora !="Selecione" && dsStatus !="Selecione" && dsTipoRelatorio != "Selecione" && dsTipoFormato != "Selecione") ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }
                    
                {dsTipoRelatorio === "Pedidos" &&
                    <>
                        <Form.Row className="container-tipo-relatorio">
                            <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                            <Col>
                                <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        {revelarSelectCliente === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectCliente === true &&
                                            <SelectGenericoCheck
                                                paramRota={"cliente_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdCliente}
                                                arrayDescricao1={arrayDsCliente}
                                                arrayDescricao2={arrayNomeFantasia}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectCliente}
                                                placeholder={dsCliente}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data inicio</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data fim</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={fimCiclo} setData={setFimCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsCliente !="Selecione" && dsTipoFormato != "Selecione") ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }

                {dsTipoRelatorio === "Notas Fiscais" &&
                    <>
                        <Form.Row className="container-tipo-relatorio">
                            <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                            <Col>
                                <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Operadora</div>
                                        </Form.Label>
                                            {revelarSelectOperadora === false &&
                                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                                    <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsOperadora}</div>
                                                    <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                                </Button>
                                            }
                                            {revelarSelectOperadora === true &&
                                                 <SelectGenericoCheck
                                                    paramRota={"operadora_relatorio_contestacao"}
                                                    paramCampo1={cd_pav_usuario}
                                                    paramCampo2={decryptJWT('codigoCliente')}
                                                    paramCampo3={null}
                                                    paramCampo4={null}
                                                    arrayCodigo={arrayCdOperadora}
                                                    arrayDescricao1={arrayDsOperadora}
                                                    arrayDescricao2={arrayApelidoOperadora}
                                                    arrayDescricao3={arrayDummy}
                                                    arrayDescricao4={arrayDummy}
                                                    setRevelarSelect={setRevelarSelectOperadora}
                                                    placeholder={dsOperadora}
                                                    sufixo={""}
                                                />
                                            }
                                    </div>
                                    <div className="container-campo-texto-3">
                                        <div className="container-data-calendario-2">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Mês/ano</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="YYYY/MM" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsOperadora !="Selecione" && dsTipoFormato != "Selecione" && inicioCiclo != "") ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }

                {dsTipoRelatorio === "Solicitações" &&
                    <>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                            <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        {revelarSelectCliente === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectCliente === true &&
                                            <SelectGenericoCheck
                                                paramRota={"cliente_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdCliente}
                                                arrayDescricao1={arrayDsCliente}
                                                arrayDescricao2={arrayNomeFantasia}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectCliente}
                                                placeholder={dsCliente}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data inicio</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data fim</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={fimCiclo} setData={setFimCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsCliente !="Selecione" && dsTipoFormato != "Selecione") ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }

                {dsTipoRelatorio === "SMS" &&
                    <>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                            <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        {revelarSelectCliente === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectCliente === true &&
                                            <SelectGenericoCheck
                                                paramRota={"cliente_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdCliente}
                                                arrayDescricao1={arrayDsCliente}
                                                arrayDescricao2={arrayNomeFantasia}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectCliente}
                                                placeholder={dsCliente}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Operadora</div>
                                        </Form.Label>
                                        {revelarSelectOperadora === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsOperadora}</div>
                                                <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }

                                        {revelarSelectOperadora === true &&
                                            <SelectGenericoCheck
                                                paramRota={"operadora_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdOperadora}
                                                arrayDescricao1={arrayDsOperadora}
                                                arrayDescricao2={arrayApelidoOperadora}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectOperadora}
                                                placeholder={dsOperadora}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Mês/ano</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="YYYY/MM" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsCliente !="Selecione" && dsOperadora !="Selecione" && dsStatus !="Selecione" && dsTipoRelatorio != "Selecione" && dsTipoFormato != "Selecione") ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }
            </div>
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"Relatório solicitado!"}
                            mensagemParagrafo1={"Pode demorar alguns minutos para o link de download ser disponibilizado. Verifique seu e-mail ou atualize a pagina dentro de alguns minutos"}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}