const ESTADO_INICIAL = {
    atualizarNotificacoesCabecalho: false,
    revelarNotificacaoCabecalhoSelecionada: false,
    notificacaoCabecalhoSelecionada:undefined,
    revelarNotificacaoTabela:false,
}

  export const notificacoesReducer = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'ATUALIZAR_NOTIFICACOES_CABECALHO') {
        return {
            ...state,
            atualizarNotificacoesCabecalho : action.parametro
          };

    }
 
   else if (action.type === 'REVELAR_NOTIFICACAO_CABECALHO_SELECIONADA') {
        return {
            ...state,
            revelarNotificacaoCabecalhoSelecionada : action.parametro
          };

    }
    else if (action.type === 'ATUALIZAR_NOTIFICACAO_CABECALHO_SELECIONADA') {
        return {
            ...state,
            notificacaoCabecalhoSelecionada : action.parametro
          };

    }
    else if (action.type === 'REVELAR_NOTIFICACAO_TABELA') {
      return {
          ...state,
          revelarNotificacaoTabela : action.parametro
        };

  }
    else{
        return state
    }
}