import * as S from "./styles";

import { IMaskInput } from 'react-imask';
export default function Input({
  placeholder,
  label,
  variant = "filled",
  onBlur,
  value,
  onChange,
  disabled,
  defaultValue = null,
  type = "text",
  mask,
  withAsterisk = false,
}) {
  return (
    <S.Input
      type={type}
      placeholder={placeholder}
      label={label}
      disabled={disabled}
      variant={variant}
      radius="xl"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      withAsterisk={withAsterisk}
      component={IMaskInput}
      mask={mask}
    />
  );
}
