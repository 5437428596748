import * as S from "./styles";

const isEscuro = () => {
  const tema = localStorage.getItem("tema");
  return tema === "escuro" ? true : false;
};

export default function Tooltip({
  label = "teste label",
  position = "top",
  withArrow = true,
  children,
  multiline = true,
  width = 400,
}) {
  const isDarkMode = isEscuro();
  const backgroundColor = isDarkMode ? "#2E2E2E" : "#F4F6F5";
  const textColor = isDarkMode ? "#FFF" : "#000";

  const style = {
    textAlign: "center",
    whiteSpace: "normal",
    color: textColor,
  };

  const labelView = <div style={style}>{label}</div>;

  return (
    <S.Tooltip
      width={width}
      multiline={multiline}
      label={labelView}
      color={backgroundColor}
      withArrow={withArrow}
      position={position}
      transitionProps={{ duration: 500 }}
    >
      {children}
    </S.Tooltip>
  );
}
