//BIBLIOTECAS
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import {Button, Form,  Col, Tooltip, OverlayTrigger} from 'react-bootstrap';/*eslint-disable*/
import {api} from '../../../../../conexoes/api'
import { useSelector } from 'react-redux'
import { validadorPermissaoElemento } from '../../../../../utils/verificadorPermissaoElemento'
//COMPONENTES
import MensagemSucesso from '../../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../../../componentesModais/mensagens/mensagemAtencao'
import SelectUf from '../../../../componentesModais/selects/selectUf'
import {elipseVerde, setaDropdown} from "../../../../../utils/alternanciaIcones";

// SPINNER
import SpinerVeye from '../../../../../components/spinnerVeye/spinnerVeye.js'

import decryptJWT from '../../../../../utils/decryptJWT'
import './descricao.css';
let camposBanco = []

export default function DescricaoCliente(props) {
    const [camposPersonalizados, setCamposPersonalizados] = useState([])
    const [renderizar, setRenderizar] = useState(false)
    const [dsMarca, setDsMarca] = useState('')
    const [phMarca, setPhMarca] = useState('')
    const [dsModelo, setDsModelo] = useState('')
    const [phModelo, setPhModelo] = useState('')
    const [dsNome, setDsNome] = useState('')
    const [phNome, setPhNome] = useState('')
    const [dsEndereco, setDsEndereco] = useState('')
    const [phEndereco, setPhEndereco] = useState('')
    const [dsCidade, setDsCidade] = useState('')
    const [phCidade, setPhCidade] = useState('')
    const [dsCep, setDsCep] = useState('')
    const [phCep, setPhCep] = useState('')
    const [dsUf, setDsUf] = useState('Selecione')
    const [cdUf, setCdUf] = useState('Selecione')
    const [phUf, setPhUf] = useState('Selecione')
    const [dsInfoAdicionais, setDsInfoAdicionais] = useState('')
    const [phInfoAdicionais, setPhInfoAdicionais] = useState('')
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [desativarBotaoNovaInformacaoPersonalizada, setDesativarBotaoNovaInformacaoPersonalizada] = useState(false)
    const [revelarSelectUf, setRevelarSelectUf] = useState(false)
    const [existeInfoAdicional, setExisteInfoAdicional] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro. Tente novamente mais tarde.")
    const [cdInfoSimcard, setCdInfoSimcard] = useState()
    const [editarTitulo, setEditarTitulo] = useState('')
    const [renderizarCampoPersonalizado, setRenderizarCampoPersonalizado] = useState(false)
    const [permissaoDescricaoEditar, setPermissaoDescricaoEditar] = useState()
    const [erroTitulo, setErroTitulo] = useState(false)
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);

    const cd_simcard = props.cdSimcard;
    
    let camposPersonalizadosDados = []

    useEffect(()=>{
        async function handlerVerificarPermissaoElementos(){
            setPermissaoDescricaoEditar(await validadorPermissaoElemento(permissaoElemento,"veye/dispositivos/detalhamento/editarDescricao"))
        }
        handlerVerificarPermissaoElementos()
    },[])
    
    useEffect(() => {
        async function carregarInfo(){
            setSpinAtivo(true)
            const { data } = await api.get('/m1/consultar/fat_simcard_informacoes_adicionais?n0_msisdn='+props.row[0].CALLERID+"&n0_iccid="+props.row[0].ICCID);
            if (data.dados.length > 0) {
                setExisteInfoAdicional(true)
                setDsCep(data.dados[0].ds_cep)
                setPhCep("Original: "+data.dados[0].ds_cep)
                setDsCidade(data.dados[0].ds_cidade)
                setPhCidade("Original: "+data.dados[0].ds_cidade)
                setDsNome(data.dados[0].ds_cliente)
                setPhNome("Original: "+data.dados[0].ds_cliente)
                setDsEndereco(data.dados[0].ds_endereco)
                setPhEndereco("Original: "+data.dados[0].ds_endereco)
                setDsInfoAdicionais(data.dados[0].tx_obs)
                setPhInfoAdicionais("Original: "+data.dados[0].tx_obs)
                setDsMarca(data.dados[0].ds_marca)
                setPhMarca("Original: "+data.dados[0].ds_marca)
                setDsModelo(data.dados[0].ds_modelo)
                setPhModelo("Original: "+data.dados[0].ds_modelo)
                setDsUf(data.dados[0].ds_uf)
                setPhUf("Original: "+data.dados[0].ds_uf)
            }
            setSpinAtivo(false)
        }
        carregarInfo();
    }, [renderizar,permissaoDescricaoEditar])

    function handleCancelar(){
        setRenderizar(!renderizar)
    }
    
    async function salvar(){
        setSpinAtivo(true)
       
        if(existeInfoAdicional){
            const dados =
            {
                "tx_obs":dsInfoAdicionais, 
                "ds_marca": dsMarca, 
                "ds_modelo":dsModelo,
                "ds_info_coluna":dsInfoAdicionais,
                "ds_cliente":dsNome,
                "ds_uf":dsUf,
                "ds_cidade":dsCidade,
                "ds_endereco":dsEndereco,
                "ds_cep":dsCep,
                "n0_codigo_cliente": decryptJWT("codigoClienteErpAntigo"),
            }
            const condicao = {
                "n0_msisdn": props.row[0].CALLERID,  
                "n0_iccid": props.row[0].ICCID
            }
            const body ={dados, condicao}

            await api.post('/m1/alterar/fat_simcard_informacoes_adicionais', body)
            .then(function (response) {
                setRenderizar(!renderizar)
                setSpinAtivo(false)
                setRevelarMensagemSucesso(true)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setRevelarMensagemSucesso(true)
            }); 
           }
        else{
            await api.post('/m1/cadastrar/fat_simcard_informacoes_adicionais', {
                "cd_fat_simcard":cd_simcard,  
                "n0_msisdn": props.row[0].CALLERID,  
                "n0_iccid": props.row[0].ICCID,
                "n0_codigo_cliente": decryptJWT("codigoClienteErpAntigo"),
                "tx_obs":dsInfoAdicionais, 
                "ds_marca": dsMarca, 
                "ds_modelo":dsModelo,
                "ds_info_coluna":dsInfoAdicionais,
                "ds_cliente":dsNome,
                "ds_uf":dsUf,
                "ds_cidade":dsCidade,
                "ds_endereco":dsEndereco,
                "ds_cep":dsCep
            })
            .then(function (response) {
                setRenderizar(!renderizar)
                setSpinAtivo(false)
                setExisteInfoAdicional(true)
                setRevelarMensagemSucesso(true)

            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro(error)
                setRevelarMensagemErro(true)
            });
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(confirmacao){
            handlerSalvar()
        }
    }

    async function handlerSalvar(){
        await salvar()
        setRevelarMensagemSucesso(!revelarMensagemSucesso)
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
        }
        if(confirmacao){
            setRevelarMensagemSucesso(false)
            props.setRenderizar(!props.renderizar)
        }
    }
    
    return(
        <Form>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="container-descricao-linha">
                <div className="container-descricao-linha-telas">
                    <div className="container-descricao-linha-cliente fundo-cor-7">
                        <Form.Row>
                            <Col>
                                <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-janela">Cliente</div>
                                </Form.Label>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <div className="grupo-descricao-linha-cliente-1">
                                    <div className="coluna-descricao-linha-cliente-1">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Nome</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Nome do cliente</Tooltip>}>
                                                    <Form.Control disabled={!permissaoDescricaoEditar} placeholder={phNome === null || phNome === 'null' ? "" : phNome} value={dsNome} className="campo-texto-cor-3 campo-texto" type="text" maxlength="100" onChange={e => setDsNome(e.target.value)}/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="coluna-descricao-linha-cliente-2">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Endereço</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Endereço do cliente</Tooltip>}>
                                                    <Form.Control disabled={!permissaoDescricaoEditar} placeholder={phEndereco === null || phEndereco === 'null' ? "" : phEndereco} value={dsEndereco} className="campo-texto-cor-3 campo-texto" type="text" maxlength="100" onChange={e => setDsEndereco(e.target.value)}/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <div className="grupo-descricao-linha-cliente-2">
                                    <div className="coluna-descricao-linha-cliente-3">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Cidade</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Cidade do cliente</Tooltip>}>
                                                    <Form.Control disabled={!permissaoDescricaoEditar} placeholder={phCidade === null || phCidade === 'null' ? "" : phCidade} value={dsCidade} className="campo-texto-cor-3 campo-texto" type="text" maxlength="100" onChange={e => setDsCidade(e.target.value)}/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="coluna-descricao-linha-cliente-4">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Estado</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                { revelarSelectUf === false &&
                                                    <Button disabled={!permissaoDescricaoEditar} className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectUf(!revelarSelectUf)}>
                                                        <div className="fonte-cor-1 label-campo">{dsUf}</div>
                                                        <img className={revelarSelectUf?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                                    </Button>
                                                }
                                                { revelarSelectUf === true &&
                                                    <SelectUf
                                                        setRevelarSelectUf={setRevelarSelectUf} //Hook para fechar a modal
                                                        setDsUf={setDsUf} //Nome do Uf
                                                        setCdUf={setCdUf} //Nome do Uf
                                                        dsUf={dsUf} 
                                                        phUf={phUf} //Placeholder para mostrar o valor original em caso de edição
                                                    >
                                                    </SelectUf>
                                                }
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="coluna-descricao-linha-cliente-5">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">CEP</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>CEP do cliente</Tooltip>}>
                                                    <Form.Control disabled={!permissaoDescricaoEditar} placeholder={phCep === null || phCep === 'null' ? "" : phCep} value={dsCep} className="campo-texto-cor-3 campo-texto" type="text" maxlength="10" onChange={e => setDsCep(e.target.value)}/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <div>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Informações adicionais</div>
                                        </Form.Label>
                                    </div>
                                    <div>
                                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Informações Adicionais do Cliente</Tooltip>}>
                                            <Form.Control disabled={!permissaoDescricaoEditar} placeholder={phInfoAdicionais === null || phInfoAdicionais === 'null' ? "" : phInfoAdicionais} value={dsInfoAdicionais} className="campo-texto-cor-3 campo-texto" type="text" maxlength="500" onChange={e => setDsInfoAdicionais(e.target.value)}/>
                                        </OverlayTrigger>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </div>

                    <div className="container-descricao-linha-dispositivos fundo-cor-7">
                        <Form.Row>
                            <Col>
                                <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-janela">Dispositivos</div>
                                </Form.Label>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <div>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Marca</div>
                                        </Form.Label>
                                    </div>
                                    <div>
                                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Marca do Simcard</Tooltip>}>
                                            <Form.Control disabled={!permissaoDescricaoEditar} placeholder={phMarca === null || phMarca === 'null' ? "" : phMarca} value={dsMarca} className="campo-texto-cor-3 campo-texto" type="text" maxlength="100" onChange={e => setDsMarca(e.target.value)}/>
                                        </OverlayTrigger>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <div>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Modelo</div>
                                        </Form.Label>
                                    </div>
                                    <div>
                                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Modelo do Simcard</Tooltip>}>
                                            <Form.Control disabled={!permissaoDescricaoEditar} placeholder={phModelo === null || phModelo === 'null' ? "" : phModelo} value={dsModelo} className="campo-texto-cor-3 campo-texto" type="text" maxlength="100" onChange={e => setDsModelo(e.target.value)}/>
                                        </OverlayTrigger>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </div>

                </div>
                <div className="footer-bottons-fat-dispositivos-descricao">
                    <Button className="botao-confirmar" variant="success" size="sm" onClick={() => {setRevelarMensagemAtencao(true)}}>
                        <div className="fonte-cor-4">Salvar</div>
                    </Button>
                    <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                        <div className="fonte-cor-2">Cancelar</div>
                    </Button>
                </div>
            </div>
            { revelarMensagemSucesso === true &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"A alteração foi realizada com sucesso."}
                    mensagemBotao={"Prosseguir"}
                />
            }
            { revelarMensagemErro === true && 
                <MensagemErro
                    handleMensagemErro={() =>  setRevelarMensagemErro(!revelarMensagemErro)}
                    mensagemTitulo={"Erro"}
                    mensagemParagrafo1={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
            { revelarMensagemAtencao === true && 
                <MensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Atenção"}
                    mensagemParagrafo1={"Tem certeza que deseja salvar?"}
                    mensagemBotao={"Confirmar"}
                />
            }
        </Form>
    )
}