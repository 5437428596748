export const apiVersions = [
    { text: 'Antigo', value: 'antigo' },
    { text: 'Novo', value: 'novo' },
]

export const iotTelecomOptions = [
    { text: 'Ativação Simcards', value: 'Ativação', rota: 'ativacao' },
    { text: 'Bloqueio Temporário', value: 'Bloq. Temp.', rota: 'bloqueio_temp' },
    { text: 'Desbloqueio Temporário ', value: 'Desbloq. Temp.', rota: 'desbloqueio_temp' },
    { text: 'Cancelamento', value: 'Cancelamento', rota: 'cancelamento' },
    { text: 'Realocação', value: 'Realocação', rota: 'realocacao' },
    { text: 'Reciclagem simcard', value: 'Reciclagem', rota: 'reciclagem' },
]

export function getRotaIotTelecom(dsAcaoTelecall) {
    const find = iotTelecomOptions.find(item => item.value === dsAcaoTelecall);
    if(find) {
        return find.rota;
    } else {
        return false;
    }
}