//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { Image, Col, Button, Form } from 'react-bootstrap';/*eslint-disable*/
import { api } from '../../../conexoes/api';/*eslint-disable*/
import RetornarParametro from '../../../utils/decryptJWT';
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//MAPA
import '../../../css/painelDispositivos/dspMapa/mapa.css'
import MapaSituacao from './mapaSituacao'
import MapaCalor from './mapaCalor'
import MapaAgrupamento from './mapaCluster'
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import { revelarMapa } from "../../../redux/actions/index";
//ICONES
import { setaDropdown, fecharJanela, googleBrands, mapMarked, alertaAtencao, elipseVerde } from '../../../utils/alternanciaIcones';
//COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
// SELECT
import SelectTipoMapa from '../../componentesModais/selects/selectTipoMapa'
//MODAIS
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'

const routeM1FiltrarSimcard = '/m1/filtrar/simcard';

export default function Mapa(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    const [mapa, setMapa] = useState(false);
    const [mapaCalor, setMapaCalor] = useState(false);
    const [mapaCluster, setMapaCluster] = useState(false);
    const [mapaSituacao, setMapaSituacao] = useState(false);
    const [revelarSelectTipoMapa, setRevelarSelectTipoMapa] = useState(false)
    const [cdTipoMapa, setCdTipoMapa] = useState(1);
    const [dsTipoMapa, setDsTipoMapa] = useState('Status de conexão');
    const [objLatLong, setObjLatLong] = useState([]);
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const despacho = useDispatch();
    const modal = useRef();

    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    const [renderSelecaoTudo, setRenderSelecaoTudo] = useState(modoSelecaoSimcard);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);

    /* LEGENDA
     * [0] LATITUDE DOUBLE (LATITUDE)
     * [1] LONGITUDE DOUBLE (LONGITUDE)
     * [2] RADIO STRING (TECNOLOGIA)
     * [3] MCC INTEGER (PAÍS)
     * [4] MNC INTEGER (OPERADORA)
     * [5] LAC INTEGER (LOCATION AREA CODE, código de localização da área)
     * [6] CID INTEGER (CELL ID, é a area geral dentro do país + a antena em si, Celltower ID is a generally unique number used to identify each Base transceiver station (BTS) or sector of a BTS within a Location area code)
    */

    const handleClickFora = e => {
        if (modal.current != undefined) {
            if (!modal.current.contains(e.target)) {
                handleCancelar()
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function handlerSetdados(simcardsSelecionados) {
        setSpinAtivo(true)
        let objetoAux = []
        let passo = 100 // Variável que define o tamnho do lote da requisição ao back e o tamanho mínimo de simcards para fazer a conulta por lotes

        // Exclui os simcards que não tem informação de antena
        simcardsSelecionados = simcardsSelecionados.filter(item => item['GEOLOCATION'] != "")

        if (simcardsSelecionados.length <= passo) {
            objetoAux = await Promise.all(simcardsSelecionados.map(async (item, i) => {
                return await api.post('/m1/consultar/geolocation/geolocation_detalhamento', {
                    "geolocation": item['GEOLOCATION']
                })
                    .then((resposta) => {
                        if (resposta.data.dados.length > 0 && resposta.data.dados != null) { // != undefined
                            let arrayLatLong = resposta.data.dados[0].geo_concat.split(",")
                            return {
                                latitude: parseFloat(arrayLatLong[0]),
                                longitude: parseFloat(arrayLatLong[1]),
                                radio: arrayLatLong[2],
                                mcc: parseInt(arrayLatLong[3]),
                                mnc: parseInt(arrayLatLong[4]),
                                lac: parseInt(arrayLatLong[5]),
                                cid: parseInt(arrayLatLong[6]),
                                status: item['ON_OFF'],
                                data: item['ULTIMO_EVENTO'],
                                ds_linha: item['CALLERID'],
                                operadora: resposta.data.dados[0].LAT_LONG_OPERADORA
                            }
                        }

                    })
            }))

            setObjLatLong(objetoAux)
        } else {
            let fatiaSimcardsSelecionados = []
            for (let cont = 0; cont < simcardsSelecionados.length; cont = cont + passo) {
                fatiaSimcardsSelecionados = simcardsSelecionados.slice(cont, cont + passo)
                let geolocations = fatiaSimcardsSelecionados.map((item) => { return item['GEOLOCATION'] })
                let variavel = await api.post('/m1/consultar/geolocation/geolocation_porlote', {
                    "geolocation": JSON.stringify(geolocations)
                })
                    .then((resposta) => {
                        if (resposta.data.dados != undefined && resposta.data.dados != null) {
                            let dadosSimcardAntena = (resposta.data.dados).map((item, i) => {
                                let antena = item.geo_concat.split(",")
                                return {
                                    latitude: parseFloat(antena[0]),
                                    longitude: parseFloat(antena[1]),
                                    radio: antena[2],
                                    mcc: parseInt(antena[3]),
                                    mnc: parseInt(antena[4]),
                                    lac: parseInt(antena[5]),
                                    cid: parseInt(antena[6]),
                                    status: simcardsSelecionados[cont + i]['ON_OFF'],
                                    data: simcardsSelecionados[cont + i]['ULTIMO_EVENTO'],
                                    ds_linha: simcardsSelecionados[cont + i]['CALLERID'],
                                    operadora: item.LAT_LONG_OPERADORA
                                }
                            })
                            return dadosSimcardAntena
                        }

                    })
                if (variavel === undefined) {
                    objetoAux = objetoAux.concat([])
                } else {
                    objetoAux = objetoAux.concat(variavel)
                }
            }
            if (objetoAux.length === 0) {
                handleMensagemErro()
            } else {
                setObjLatLong(objetoAux)
            }


        }
        setSpinAtivo(false)
    }

    useEffect(() => {
        handlerSetdados(props.simcardsSelecionados)
    }, [])

    useEffect(() => {
        if (modoSelecaoSimcard) {
            handlerConsultar();
        }
    }, [])

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
            handleCancelar()
        }
    }

    async function handlerConsultar() {
        setSpinAtivo(true)


        const urlGet = `${routeM1FiltrarSimcard}?buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false`
        await api.get(urlGet)
            .then(function (resposta) {
                if (resposta.data.status == 200 && resposta.data.dados.length > 0) {
                    setObjLatLong(handlerSetdados(resposta.data.dados))
                    setRenderSelecaoTudo(false)
                } else {
                    setSpinAtivo(false)
                }
            })
            .catch(function (erro) {
                setSpinAtivo(false)
            })


    }

    async function handleCancelar() {
        ColetaClickstream(1145) // mapaCancelar
        despacho(revelarMapa(false))
    }

    useEffect(() => {
        async function selecionarMapa(valor) {
            switch (valor) {
                case 1:
                    setDsTipoMapa('Status de conexão')
                    setMapaSituacao(true)
                    setMapaCalor(false)
                    setMapaCluster(false)
                    break
                case 2:
                    setDsTipoMapa('Distribuição')
                    setMapaSituacao(false)
                    setMapaCalor(true)
                    setMapaCluster(false)
                    break
                case 3:
                    setDsTipoMapa('Agrupamento')
                    setMapaSituacao(false)
                    setMapaCalor(false)
                    setMapaCluster(true)
                    break
            }
        }
        selecionarMapa(cdTipoMapa)
    }, [dsTipoMapa, cdTipoMapa, mapa])

    function MensagemAtencaoMapa({descricao, cssClass=''}) {
        return (
            <div className="mensagem-overlay">
                <div className={"mensagem-container "+cssClass}>
                    <div className="mensagem-corpo fundo-cor-6">
                        <div className="mensagem-container-icone">
                            <img className="mensagem-icone" src={alertaAtencao()} alt="atencao" />
                        </div>
                        <div className="mensagem-container-titulo">
                            <div className="fonte-cor-1 label-corpo-mensagem">Atenção</div>
                        </div>
                        <div>
                            <div className="fonte-cor-1 label-corpo-mensagem">{descricao}</div>
                        </div>
                        <div className="mensagem-container-botao">
                            <Button className="mensagem-botao-confirmar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-4">Voltar</div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="container-modal-mapa fundo-cor-6">
                {spinAtivo && <div className="componente-spinner-container-mapa"><SpinerVeye /></div>}
                {objLatLong.length > 0 ?
                    <div ref={modal} className="container-interno-modal-mapa fundo-cor-6">
                        <Form.Row >
                            <Col>
                                <div className="grupo-mostrar-no-mapa-1">
                                    <div className="coluna-mostrar-no-mapa-1">
                                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse" />
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-janela">Mapa: {dsTipoMapa}</div>
                                        </Form.Label>
                                    </div>
                                    <div className="coluna-mostrar-no-mapa-2">
                                        {/* COMENTADO POIS O GOOGLE MAP ESTÁ CAUSANDO TELA PRETA E PRECISA SER REFATORADO. NÃO É RELACIONADO A ALTERAÇÂO DOS MAPAS DO DETALHAMENTO DE LINHA */}
                                        <Button variant="" className="botao-trocar-mapa-mostrar-no-mapa" onClick={() => setMapa(!mapa)}>
                                            {mapa ? <Image className="icone-botao-trocar-mapa-mostrar-no-mapa" src={googleBrands()} alt="google-maps" /> : <Image className="icone-botao-trocar-mapa-mostrar-no-mapa" src={mapMarked()} alt="google-maps" />}
                                        </Button>
                                    </div>
                                    <div className="coluna-mostrar-no-mapa-3">
                                        <Form.Group>
                                            {revelarSelectTipoMapa === false &&
                                                <Button name="cdTipoMapa" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoMapa(!revelarSelectTipoMapa)}>
                                                    <div className="fonte-cor-1 label-campo">Alterar tipo de mapa</div>
                                                    <img className={revelarSelectTipoMapa ? "campo-select-icone-ativado nao-selecionavel" : "campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                                </Button>
                                            }
                                            {revelarSelectTipoMapa === true &&
                                                <SelectTipoMapa
                                                    setRevelarSelectTipoMapa={setRevelarSelectTipoMapa} //Hook para fechar a modal
                                                    setCdTipoMapa={setCdTipoMapa} //Código do TipoMapa
                                                    setDsTipoMapa={setDsTipoMapa} //Nome do TipoMapa
                                                    dsTipoMapa={dsTipoMapa}
                                                >
                                                </SelectTipoMapa>
                                            }
                                        </Form.Group>
                                    </div>
                                    <div className="coluna-mostrar-no-mapa-4">
                                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela" />
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="corpo-mapa">
                            {(objLatLong.length && objLatLong[0]) ? (
                                <>
                                {mapaSituacao &&
                                    <MapaSituacao array={objLatLong} mapa={mapa} />
                                }
                                {mapaCalor &&
                                    <MapaCalor array={objLatLong} mapa={mapa} />
                                }
                                {mapaCluster &&
                                    <MapaAgrupamento array={objLatLong} mapa={mapa} />
                                } 
                                </>
                            ) : 
                                <MensagemAtencaoMapa descricao='Sem informações!' />
                            }
                            
                        </div>
                    </div>
                    :
                    <>
                        {modoSelecaoSimcard ?
                            <div className="container-interno-modal-mapa fundo-cor-6">
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-mostrar-no-mapa-1">
                                            <div className="coluna-mostrar-no-mapa-1">
                                                <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse" />
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-janela">Mapa: {dsTipoMapa}</div>
                                                </Form.Label>
                                            </div>
                                            <div className="coluna-mostrar-no-mapa-2">
                                                {/* COMENTADO POIS O GOOGLE MAP ESTÁ CAUSANDO TELA PRETA E PRECISA SER REFATORADO. NÃO É RELACIONADO A ALTERAÇÂO DOS MAPAS DO DETALHAMENTO DE LINHA */}
                                                <Button variant="" className="botao-trocar-mapa-mostrar-no-mapa" onClick={() => setMapa(!mapa)}>
                                                    {mapa ? <Image className="icone-botao-trocar-mapa-mostrar-no-mapa" src={googleBrands()} alt="google-maps" /> : <Image className="icone-botao-trocar-mapa-mostrar-no-mapa" src={mapMarked()} alt="google-maps" />}
                                                </Button>
                                            </div>
                                            <div className="coluna-mostrar-no-mapa-3">
                                                <Form.Group>
                                                    {revelarSelectTipoMapa === false &&
                                                        <Button name="cdTipoMapa" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoMapa(!revelarSelectTipoMapa)}>
                                                            <div className="fonte-cor-1 label-campo">Alterar tipo de mapa</div>
                                                            <img className={revelarSelectTipoMapa ? "campo-select-icone-ativado nao-selecionavel" : "campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                                        </Button>
                                                    }
                                                    {revelarSelectTipoMapa === true &&
                                                        <SelectTipoMapa
                                                            setRevelarSelectTipoMapa={setRevelarSelectTipoMapa} //Hook para fechar a modal
                                                            setCdTipoMapa={setCdTipoMapa} //Código do TipoMapa
                                                            setDsTipoMapa={setDsTipoMapa} //Nome do TipoMapa
                                                            dsTipoMapa={dsTipoMapa}
                                                        >
                                                        </SelectTipoMapa>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="coluna-mostrar-no-mapa-4">
                                                <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                                                    <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela" />
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <div className="corpo-mapa">
                                </div>
                            </div>
                            :
                            <>
                                {!spinAtivo && 
                                <MensagemAtencaoMapa
                                    descricao='Os SIM cards selecionados não contém coordenadas ou dados de localização!'
                                    cssClass='mensagem-atencao-mapa'
                                />
                                }
                            </>
                        }
                    </>
                }
                {revelarMensagemErro &&
                    <div className="componente-modal-overlay">
                        <div className="componente-modal">
                            <MensagemErro
                                handleMensagemErro={handleMensagemErro}
                                mensagemTitulo={"Erro"}
                                mensagemParagrafo1={mensagemErro}
                                mensagemBotao={"Retornar"}
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
