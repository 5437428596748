//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye'
//COMPONENTES

import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import '../../../../css/painelFaturas/fatSolicitacoes/tabela.css'

let contador = 0
let controladorPagina = 1
let totalPaginas = 1
let totalItens = 10

export default function Preview(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS TABELA
    let itensVar = []
    const [itens, setItens] = useState([{}]) // useState([])
    const [valorLimite, setValorLimite] = useState(5);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [refazerChamadas, setRefazerChamadas] = useState(true);

    useEffect(()=>{
        
        setSpinAtivo(true)
        contador = (paginaAtual - 1) * valorLimite;
        async function carregarSolicitacao(){
            if(refazerChamadas === true){
                const contagem = await api.post('/m1/consultar/fat_solicitacao_simcard_alteracao/simcard_alteracoes',{
                    "cd_fat_solicitacao":props.cdSolicitacao,
                    "carregarLimit":valorLimite,
                    "paginaAtual":contador,
                    "contar":true
                })
                totalItens = contagem.data.dados[0].CONTADOR;
                totalPaginas = Math.ceil(totalItens / valorLimite)
                setRefazerChamadas(false)
            }
            const preview = await api.post('/m1/consultar/fat_solicitacao_simcard_alteracao/simcard_alteracoes',{
                "cd_fat_solicitacao":props.cdSolicitacao,
                "carregarLimit":valorLimite,
                "paginaAtual":contador,
                "contar":false
            })
            inserirData(preview.data.dados)
        }
        carregarSolicitacao()
    },[paginaAtual])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)
    }

    const verificaSeEscondeColuna = (tipoSolicitacao) => {
        return ["Manutenção", "Reset", "Bloquear SMS", "Liberar SMS"].includes(tipoSolicitacao)
    }
    // const verificaSeEscondeColunaStatus = (tipoSolicitacao) => {
    //     return ["Manutenção", "Reset", "Bloquear SMS"].includes(tipoSolicitacao)
    // }

    const escondeColunaAlteradoDePara = verificaSeEscondeColuna(props.tipoSolicitacao)
    // const escondeColunaAlteradoDeParaStatus = verificaSeEscondeColunaStatus(props.tipoSolicitacao)

    const renderRowItem = (cell) => <p className="fonte-cor-1 label-tabela-1">{cell ?? "-"}</p>;

    const colunas = [
        {
            dataField: 'LINHA',
            headerClasses: 'nao-selecionavel',
            text: 'Linha',
            sort: true,
            formatter: (linha) => renderRowItem(linha)
        },
        {
            dataField: 'ANTIGO',
            hidden: escondeColunaAlteradoDePara,
            headerClasses: 'nao-selecionavel',
            text: 'Alterado de',
            sort: true,
            formatter: (alteradoDe) => renderRowItem(alteradoDe)
        },
        {
            dataField: 'NOVO',
            hidden: escondeColunaAlteradoDePara,
            headerClasses: 'nao-selecionavel',
            text: 'Alterado para',
            sort: true,
            formatter: (alteradoPara) => renderRowItem(alteradoPara)
        },
        {
            dataField: 'Status',
            // hidden: escondeColunaAlteradoDeParaStatus,
            headerClasses: 'nao-selecionavel',
            text: 'Status da Alteração',
            sort: true,
            formatter: (statusAlteracao, row) => {
                
                let statusStr = '-';
                if(row?.Erro) {
                    statusStr = `Falha - ${statusAlteracao}`
                } else {
                    statusStr = statusAlteracao  
                }
                // else if(row.Erro === 0 && row.NOVO && ["Concluída", "Concluída parcial"].includes(props.statusSolicitacao)) {
                //     statusStr = 'Sucesso'
                // }
                return renderRowItem(statusStr);
            }
        },
    ]

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page) }
    }
    

    return(
        <>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className='container-tabela-preview'>
                <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <div className="tabela-transparente-header-fixo">
                                <BootstrapTable
                                    hover={true}
                                    condensed={true}
                                    data={itens}
                                    keyField={"cd_fat_fat_preview"}
                                    columns={colunas} 
                                    bootstrap4={true}
                                    bordered={false}
                                    noDataIndication={!spinAtivo && "-"}
                                    // noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    { ...paginationTableProps }
                                />
                                <PaginacaoTabela
                                    preview={true}
                                    setPaginaAtual={setPaginaAtual}
                                    totalPaginas={totalPaginas}
                                    totalItens={totalItens}
                                    valorLimite={valorLimite}
                                    paginaAtual={paginaAtual}
                                    page={paginationProps.page}
                                    onPageChange={paginationProps.onPageChange}
                                />
                            </div>
                        )
                    }
                </PaginationProvider>
                <text className="itens-exibidos-tabela-solicitacoes-2 fonte-cor-1">
                    Mostrando 
                    <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                        - 
                    <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                        de 
                    <span className="label-tabela-itens-exibidos">{totalItens}</span>
                </text>
            </div>
        </>
    )
}