import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'

export default function FiltrosPedidosSms(props) {
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroQuantidadeSmsMinimo, setFiltroQuantidadeSmsMinimo] = useState('')
    const [filtroQuantidadeSmsMaximo, setFiltroQuantidadeSmsMaximo] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    async function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setFiltroQuantidadeSmsMinimo(filtros.QUANTIDADE_MIN)
            setFiltroQuantidadeSmsMaximo(filtros.QUANTIDADE_MAX)
        }
    }

    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    function handleFuncaoEstadosRedux(){
        if(filtroQuantidadeSmsMinimo !== '' && filtroQuantidadeSmsMaximo !== '' && filtroQuantidadeSmsMinimo && filtroQuantidadeSmsMaximo)
            arrayFiltrosSimcard.push("Quantidade de Simcards entre: "+filtroQuantidadeSmsMinimo+" e "+filtroQuantidadeSmsMaximo+"?QUANTIDADE_MIN")
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroQuantidadeSmsMinimo =='') verificador.push(1)
            if(filtroQuantidadeSmsMaximo =='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 2) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroQuantidadeSmsMinimo('')
            setFiltroQuantidadeSmsMaximo('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroQuantidadeSmsMinimo, filtroQuantidadeSmsMaximo, props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-quantidade-simcards","imagem-filtro-quantidade-simcards")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Quantidade de simcards </Form.Label>
                        <img id={"imagem-filtro-quantidade-simcards"}  className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-quantidade-simcards"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="QUANTIDADE_MIN"
                                        className="filtros-texto-valor-mb-texto campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        value={filtroQuantidadeSmsMinimo}
                                        onChange={e => setFiltroQuantidadeSmsMinimo(e.target.value)}
                                    />  
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="QUANTIDADE_MAX"
                                        className="filtros-texto-valor-mb-texto campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        value={filtroQuantidadeSmsMaximo}
                                        onChange={e => setFiltroQuantidadeSmsMaximo(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}