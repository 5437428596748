//BIBLIOTECAS
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import { Form, Col } from 'react-bootstrap';/*eslint-disable*/
import {api} from '../../../../conexoes/api'
import BootstrapTable from 'react-bootstrap-table-next';
import {formatarData} from '../../../../utils/formatadorDataTabela'
import {formatarMilhar} from '../../../../utils/formatarMilhar'
//ICONES
import { elipseVerde } from "../../../../utils/alternanciaIcones";
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'

export default function VisaoGeral(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS
    const [itensResumo, setItensResumo] = useState('')
    const [itensPedido, setItensPedido] = useState('')
    
    useEffect(() => {
        setSpinAtivo(true)
        async function handleCarregarResumo(){
            const dataResumo = await api.get('/m1/consultar/visao_geral_resumo?n0_codigo_cliente='+props.noCdCliente);
            if(dataResumo.data.dados !== undefined){
                setItensResumo(dataResumo.data.dados)
            }
        }
        handleCarregarResumo();
        async function handleCarregarPedidos(){
            const dataPeriodo = await api.get('m1/consultar/visao_geral_ultimos_pedidos?n0_codigo_cliente='+props.noCdCliente);
            if(dataPeriodo.data.dados !== undefined){
                setItensPedido(dataPeriodo.data.dados)
            }
        }
        handleCarregarPedidos();
        setSpinAtivo(false)
    }, [])
    
    const colunasResumo = [{
        dataField: 'CD_CLIENTE',
        text: 'Cód. cliente',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        hidden: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{props.cdCliente}</p>;
        }
    },
    {
        dataField: 'operadora',
        text: 'Operadora',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell !== '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'plano',
        text: 'Plano',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell  || isNaN(cell) ? parseFloat(cell).toFixed(2)+' MB':"-"}</p>;
        }
    },
    {
        dataField: 'valor',
        text: 'Valor',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell  || isNaN(cell) ? 'R$ '+parseFloat(cell).toFixed(2):"-"}</p>;
        }
    },
    {
        dataField: 'quantidade',
        text: 'Quantidade de SIM cards',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell !== '' ? formatarMilhar(cell) : "-"}</p>;
        }
    }]

    const colunasPedido = [{
        dataField: 'cd_cliente',
        text: 'Cod. cliente',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        hidden: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{props.cdCliente}</p>;
        }
    },
    {
        dataField: 'OPERADORA',
        text: 'Operadora',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        hidden: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'CORTE',
        text: 'Tipo de SIM card',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'PLANO_MB',
        text: 'Plano',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell ? parseFloat(cell).toFixed(2) + "MB":"-"}</p>;
        }
    },
    {
        dataField: 'VALOR',
        text: 'Valor',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell ? "R$ " + parseFloat(cell).toFixed(2).replace(".",",") :"-"}</p>;
        }
    },
    {
        dataField: 'LINHAS',
        text: 'Quantidade de SIM cards',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell ? formatarMilhar(parseInt(cell)) : "-"}</p>;
        }
    },
    {
        dataField: 'TIPO',
        text: 'Plano tipo',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        align: 'center',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'DATA',
        text: 'Data',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        align: 'center',
        formatter: (cell, row) => {
            return  formatarData(cell);
        }
    }]
    
    return(
        <Form>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="container-visao-geral">
                <div className="container-visao-geral-telas">
                    <div className="container-visao-geral-resumo fundo-cor-7">
                        <Form.Row>
                            <Col>
                                <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-janela">Resumo</div>
                                </Form.Label>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <div className="container-tabela-visao-geral">
                                    <BootstrapTable
                                        hover
                                        classes='tabela-transparente-header-fixo'
                                        keyField='operadora'
                                        headerClasses={ "class-centralizar"}
                                        data={ itensResumo }
                                        columns={ colunasResumo }
                                        noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                        bootstrap4 = {true}
                                    />
                                </div>
                            </Col>
                        </Form.Row>
                    </div>

                    <div className="container-visao-geral-pedido fundo-cor-7">
                        <Form.Row>
                            <Col>
                                <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-janela">Últimos pedidos</div>
                                </Form.Label>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <div className="container-tabela-visao-geral">
                                    <BootstrapTable
                                        hover
                                        classes='tabela-transparente-header-fixo'
                                        keyField='operadora'
                                        headerClasses={ "class-centralizar"}
                                        data={ itensPedido }
                                        columns={ colunasPedido }
                                        noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                        bootstrap4 = {true}
                                    />
                                </div>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    )
}