//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../conexoes/api';/*eslint-disable*/
import { Button, Form, Col, Image } from 'react-bootstrap';/*eslint-disable*/
import moment from 'moment';
import Dropzone from "react-dropzone";
//ICONES
import {elipseVerde, fecharJanela, camera} from '../../utils/alternanciaIcones';
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import { revelarPerfilContaUsuario, imagemPerfil } from "../../redux/actions/index";
//MODAIS
import MensagemInformacao from '../componentesModais/mensagens/mensagemInformacao'
import MensagemErro from '../componentesModais/mensagens/mensagemErro'
import decryptJWT from '../../utils/decryptJWT'
// COLETA CLICKSTREAM
import ColetaClickstream from '../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../spinnerVeye/spinnerVeye.js'
//CSS
import '../../css/perfilUsuario/perfilUsuario.css'
export default function DesativarDemonstracao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [perfil, setPerfil] = useState('');
    const [ultimoAcesso, setUltimoAcesso] = useState();
    //HOOKS MODAIS
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();
   
    let imagem = useSelector(state => state.manejadorState.imagemPerfil);

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    async function handleCancelar() {
        setSpinAtivo(false)
        despacho(revelarPerfilContaUsuario(false))
    }

    async function handleCarregarDados(){
        let cliente;
        let email;
        await api.get("/m1/consultar/usuario?cd_pav_usuario="+ decryptJWT('codigo'))
            .then((resolve)=>{
                if(resolve.data.status === 200 && resolve.data.dados.length != 0){
                    cliente = resolve.data.dados[0].cd_pav_cliente
                    email = resolve.data.dados[0].ds_email
                    setNome(resolve.data.dados[0].ds_pav_usuario)
                    setEmail(resolve.data.dados[0].ds_email)
                    setPerfil(resolve.data.dados[0].ds_pav_perfil)
                    if(resolve.data.dados[0].bl_imagem != null && imagem === null){
                        imagem = resolve.data.dados[0].bl_imagem
                    }
                    setSpinAtivo(false)
                }
                else{
                    setMensagemErro('Ocorreu um erro ao consultar seus dados')
                    handleMensagemErro(false)
                    setSpinAtivo(false)
                }
            })
        await api.get("/m1/consultar/cliente?cd_pav_cliente="+ decryptJWT('codigoCliente'))
            .then((resolve)=>{
                if(resolve.data.status === 200 && resolve.data.dados.length != 0){
                    if(resolve.data.dados[0].ds_cnpj == ""){
                        setCpfCnpj(resolve.data.dados[0].ds_cpf)
                    }
                    
                    if(resolve.data.dados[0].ds_cpf == ""){
                        setCpfCnpj(resolve.data.dados[0].ds_cnpj)
                    }
                    
                    setEmpresa(resolve.data.dados[0].ds_pav_cliente)
                    setSpinAtivo(false)
                }
                else{
                    setMensagemErro('Ocorreu um erro ao consultar seus dados')
                    handleMensagemErro(false)
                    setSpinAtivo(false)
                }
            })
        await api.get("/m1/servicos/token/consultar_login?ds_email="+ email)
            .then((resolve)=>{
                if(resolve.data.status === 200 && resolve.data.dados.length != 0){
                    setUltimoAcesso(resolve.data.dados.createdAt)
                    setSpinAtivo(false)
                }
                else{
                    setMensagemErro('Ocorreu um erro ao consultar seus dados')
                    setSpinAtivo(false)
                    handleMensagemErro(false)
                }
            })
    }
    


    useEffect(() => {
        setSpinAtivo(true)
        handleCarregarDados()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);


    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    
    function handleLerArquivo(files){
        let reader = new FileReader();
        if (files.size <= 1048576 && files.type.split('/')[0] === "image" && files.type.split('/')[0] !== "gif" && files.type.split('/')[0] !== "psd"){
            reader.readAsDataURL(files);
            reader.onloadend = () => {
                handleAlterar(reader.result)
            }
        }else{
            handleMensagemInformacao()
        }
    };

    async function handleAlterar(novaImagem){
        setSpinAtivo(true)
        await api.post('/m1/alterar/usuario', {
            "dados":{
                'bl_imagem': novaImagem
            },
            "condicao":{
                'cd_pav_usuario': decryptJWT("codigo"),
            }
        })
        .then(function (response) {
            imagem = novaImagem
            despacho(imagemPerfil(novaImagem))
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    return(
        <Form ref={modal}>
            <div className="modal-acao-desativar-demonstracao fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Meu perfil</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col className="container-imagem-perfil">
                        <Form.Row>
                            {imagem != ''?
                                <img className="icone-usuario-perfil" src={imagem} />
                            :
                                <img className="icone-usuario-perfil" src="https://jazzaero.com.br/wp-content/uploads/2017/05/default-placeholder-profile-icon-avatar-gray-woman-90197997.jpg" alt="perfil" />
                            }
                            <Dropzone onDrop={acceptedFiles => handleLerArquivo(acceptedFiles[0])}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Button className="botao-importar-foto">
                                                <Image className="icone-botao-importar-foto" src={camera()} />
                                            </Button>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            <div className="label-dados-perfil-usuario">
                                <Form.Label> <div className="fonte-cor-1 label-janela-perfil label-overflow">{nome}</div> </Form.Label>
                            </div>
                        </Form.Row>
                    </Col>
                    <Col>
                        <div>
                            <div>
                                <Form.Label className="label-dados-perfil-usuario-login"> <div className="fonte-cor-1 label-campo">Login:</div> </Form.Label>
                                <Form.Label> <div className="fonte-cor-2 label-campo">{email}</div> </Form.Label>
                            </div>
                            <div className="label-dados-perfil-usuario-ultimo-acesso">
                                <Form.Label className="label-dados-perfil-usuario-login"> <div className="fonte-cor-1 label-campo">Perfil e acesso:</div> </Form.Label>
                                <Form.Label> <div className="fonte-cor-2 label-campo">{perfil}</div> </Form.Label>
                            </div>
                            <div className="label-dados-perfil-usuario-ultimo-acesso">
                                <Form.Label className="label-dados-perfil-usuario-login"> <div className="fonte-cor-1 label-campo">Último acesso:</div> </Form.Label>
                                <Form.Label> <div className="fonte-cor-2 label-campo">{ultimoAcesso && moment(ultimoAcesso).format('DD/MM/YYYY - HH:mm')}</div> </Form.Label>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo"/>
                <Form.Row className="espaco-reativar-linha">
                    <Col>
                        <div className="container-empresa-cpf">
                            <div className="label-dados-perfil-usuario-empresa">
                                <Form.Label className="label-dados-perfil-usuario-login"> <div className="fonte-cor-1 label-campo">Empresa:</div> </Form.Label>
                                <Form.Label> <div className="fonte-cor-2 label-campo">{empresa}</div> </Form.Label>
                            </div>
                            <div className="label-dados-perfil-usuario-cpf">
                                <Form.Label className="label-dados-perfil-usuario-login"> <div className="fonte-cor-1 label-campo">CNPJ:</div> </Form.Label>
                                <Form.Label> <div className="fonte-cor-2 label-campo">{cpfCnpj}</div> </Form.Label>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"Aviso"}
                            mensagemParagrafo1={"A imagem selecionada é muito grande ou está em um formato não suportado."}
                            mensagemParagrafo2={"Por favor, selecione uma imagem com tamanho máximo de até 1Mb."}
                            mensagemBotao={"Ok"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}