//IMPORTAÇÕES
import { useForm } from "react-hook-form";/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';
import {  Button, Form, Col } from 'react-bootstrap';
//ICONES
import {elipseVerde, fecharJanela, exclamacao} from '../../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
import { revelarCancelamentoDeConta } from "../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import {formatarMilhar} from '../../../utils/formatarMilhar';
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'

import RetornarParametro from '../../../utils/decryptJWT'
import decryptJWT from '../../../utils/decryptJWT'


export default function CancelamentoConta(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);

    const { register, handleSubmit, errors } = useForm();
    const [dsMotivo, setDsMotivo] = useState("");
    const [caracteresMensagem, setCaracteresMensagem] = useState(1000);

    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [valorTotalMulta, setValorTotalMulta] = useState(0);
    const [valorMulta, setValorMulta] = useState(0);
    const [valorQueima, setValorQueima] = useState(0);
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();

    const codigoClienteErp = RetornarParametro('codigoClienteErpAntigo')
    const codigoCliente = RetornarParametro('codigoCliente')

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleMotivo(motivo){
        setDsMotivo(motivo)
        setCaracteresMensagem(1000 - motivo.length)
    }

    async function handleSalvar(){
        ColetaClickstream(1121) // cancelamentoDeContaConfirmar

        setSpinAtivo(true)
        let temporario = new Date();
        let dataHora = temporario.toISOString().substring(0, 10) +' '+ temporario.getHours()+':'+ temporario.getMinutes()+':'+ temporario.getSeconds();

        await api.post('/m1/cadastrar/solicitacao', {
            'fl_operadora': 0,
            'cd_crm_solicitacao_tipo' : 19,
            'cd_crm_solicitacao_status' :  1,
            'ds_crm_solicitacao' :  dsMotivo,
            'dt_inicio' :  dataHora,
            'dt_fim' :  undefined,
            'dt_previsao' :  undefined,
            ds_rota_permissao:"veye/dispositivos/acoes/cancelamentoConta", 
            'valor_total_multa': valorTotalMulta,
            'valor_queima': valorQueima,
            'valor_multa': valorMulta
        })
        .then(function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro("Erro na solicitação")
            handleMensagemErro(false)
        });
    }

    async function handleCancelar() {
        ColetaClickstream(1122) // cancelamentoDeContaCancelar
        setSpinAtivo(false)
        despacho(revelarCancelamentoDeConta(false))
        props.setRenderizar(!props.renderizar)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function consultarMulta (){
            const multa = await api.get("/m1/consultar/cancelamento_conta/multa?codigo_cliente="+codigoCliente)
                .then(function (resposta) {
                    return resposta.data.dados;
                })
                .catch(function (erro) {
                    throw erro;
                })
            setValorTotalMulta(multa.valor_total.toFixed(2));
            setValorMulta(multa.valor_total_multa.toFixed(2));
            setValorQueima(multa.valor_total_queima.toFixed(2));
            setSpinAtivo(false)
         }
         consultarMulta()
        
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [props.renderizar]);

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(confirmacao === true){
            handleSalvar()
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }


    return(
        <Form ref={modal} onSubmit={handleSubmit(() => setRevelarMensagemAtencao(true))}>
            <div className="modal-acao-cancelamento-de-conta fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Cancelamento de conta</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cancelamento-de-conta-1">
                            <div className="coluna-cancelamento-de-conta-2">
                                <div className="campo-texto-valor-cancelamento-de-conta campo-texto-cor-3 campo-texto">
                                    <span className="label-valor-cancelamento-de-conta-1 fonte-cor-1 negrito">Valor total</span>
                                        {valorTotalMulta > 0 ? <span className="label-valor-cancelamento-de-conta-2 fonte-cor-3 negrito"> R$ {formatarMilhar(valorTotalMulta)} </span> : <span className="label-valor-cancelamento-de-conta-2 fonte-cor-2 negrito"> R$ 0,00</span>}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Motivo</div>
                            </Form.Label>
                            <Form.Control className="campo-texto-cor-3 campo-texto-area" as="textarea" rows="3" name="motivo_cancelamento" ref={register({required: true})} maxLength="1000" placeholder="Descreva aqui o motivo" onChange={e => handleMotivo(e.target.value)}></Form.Control>
                            {errors.motivo_cancelamento && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Informe o motivo.</p>}
                            <Form.Label className="contador-carateres-restantes">
                                <div className="fonte-cor-1 label-normal">{caracteresMensagem} caracteres restantes</div>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row className="label-termos-cancelamento-de-conta-2">
                    <Col>
                        <span><span className="fonte-cor-3 label-normal negrito">Atenção:</span><span className="fonte-cor-1 label-normal"> este tipo de solicitação só é atendida dentro do horário comercial (8h - 17:30h).</span></span>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={"O cancelamento de conta pode demorar para ser efetuado."}
                            mensagemParagrafo2={"Deseja confirmar?"}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}