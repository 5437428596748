import { api_migracao } from "../../../conexoes/api";

export async function auxRequisicaoMigracaoPlano({ body, signal, filtros, separadorLinha }) {

    const urlFiltrarSimcard = '/api/v2/migration/list';

    const fazRequisicaoPOST = async () => {
        api_migracao.post(urlFiltrarSimcard, body, { signal });
    }

    const fazRequisicaoGET = async () => {
        let urlGet = urlFiltrarSimcard + '?' + getParams(body, separadorLinha);
        return api_migracao.get(urlGet, { signal })
    }

    /* OBS: Requisição via GET é mais simples e mais RÁPIDA. 
        Entretanto, se a URL é muito grande dá erro 414 (Request URI too large) OU 431 (Request Header Fields Too Large) em PRODUÇÃO (ex: url com tamanho muito maior do que 5000 caracteres).
        Isso acontecia quando selecionava um pedido simcard -> selecionava um peddo com +250 linhas -> ações -> mostrar em dispositivos
        Por isso foi desenvolvida a solução abaixo
    */

    const limiteGetEvitarErro414 = 250;
    if((filtros?.ICCID?.length > limiteGetEvitarErro414) || (filtros?.LINHA?.length > limiteGetEvitarErro414)) {
        return fazRequisicaoPOST();
    } else {
        return  fazRequisicaoGET();
    }
}

const getParams = (body, separadorLinha) => { 
    // ex: recebe { key1: 'teste', key2: true } => 'key1=teste&key2=true'
    if(body.busca){
        if(separadorLinha === `enter`){
            body.busca = body.busca.replaceAll(`\\n`, ';')
            body.busca = body.busca.replaceAll(' ', ';')
        }
        if(separadorLinha === `espaco`){
            body.busca = body.busca.replaceAll(' ', ';')
        }
        if(separadorLinha === `,`){
            body.busca = body.busca.replaceAll(',', ';')
        }
        if(separadorLinha === `|`){
            body.busca = body.busca.replaceAll('|', ';')
        }
    }
    const keys = Object.keys(body);
    let strParams = '';
    for(let idx=0; idx<keys.length; idx++) {
        const key = keys[idx];
        if(idx > 0) strParams += '&';
        strParams += `${key}=${body[key]}`;
    }
    return strParams;
}
