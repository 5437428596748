import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
import decryptJWT from '../../utils/decryptJWT'
//COMPONENTES
import SelectGenerico from '../../components/componentesModais/selects/selectGenerico'
import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltrosVirtueyesCliente(props) {
    const [revelarSelectEstado, setRevelarSelectEstado] = useState(false)
    const [revelarSelectCidade, setRevelarSelectCidade] = useState(false)
    const [revelarSelectCliente, setRevelarSelectCliente] = useState(false)
    const [revelarSelectResponsavel, setRevelarSelectResponsavel] = useState(false)
    const [cdEstado, setCdEstado] = useState('')
    const [dsEstado, setDsEstado] = useState('Selecione')
    const [cdCliente, setCdCliente] = useState('')
    const [dsCliente, setDsCliente] = useState('Selecione')
    const [cdCidade, setCdCidade] = useState('')
    const [dsCidade, setDsCidade] = useState('Selecione')
    const [cdResponsavel , setCdResponsavel] = useState('')
    const [dsResponsavel , setDsResponsavel] = useState('Selecione')
    const [dummy , setDummy] = useState('Selecione')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []
    async function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setDsEstado(filtros.ch_sigla)
            setDsCidade(filtros.ds_pav_cidade)
            setDsCliente(filtros.ds_pav_cliente)
            setDsResponsavel(filtros.ds_vendedor)
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    function handleFuncaoEstadosRedux(){
        ; ColetaClickstream(1427) /*virtuTabelaClientesTabelaClientesDropFiltrosFiltrar*/
        if(dsEstado !== 'Selecione' && dsEstado)arrayFiltrosSimcard.push("Estado: "+dsEstado+"?ch_sigla")
        if(dsCidade !== 'Selecione' && dsCidade)arrayFiltrosSimcard.push("Cidade: "+dsCidade+"?ds_pav_cidade")
        if(dsCliente !== 'Selecione' && dsCliente)arrayFiltrosSimcard.push("Cliente: "+dsCliente+"?ds_pav_cliente")
        if(dsResponsavel !== 'Selecione' && dsResponsavel)arrayFiltrosSimcard.push("Responsavel: "+dsResponsavel+"?ds_vendedor")
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(dsEstado== 'Selecione') verificador.push(1)
            if(dsCidade== 'Selecione') verificador.push(1)
            if(dsCliente== 'Selecione') verificador.push(1)
            if(dsResponsavel== 'Selecione') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 4) return false
            return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()){
                props.setFoiSelecionado(true);
            }else{
                props.setFoiSelecionado(false);
            }
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setCdCliente(-1)
            setDsCliente('Selecione')
            setCdEstado(-1)
            setDsEstado('Selecione')
            setCdCidade(-1)
            setDsCidade('Selecione')
            setCdResponsavel(-1)
            setDsResponsavel('Selecione')
            props.setFoiLimpado(false)
        }
        
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [dsCliente,dsEstado,dsCidade,dsResponsavel,props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-nome","imagem-filtro-nome"); ColetaClickstream(1424) /*virtuTabelaClientesTabelaClientesDropFiltrosEstado*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Estado </Form.Label>
                        <img id={"imagem-filtro-nome"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-nome"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <>
                            <Form.Group className="container-select-filtro">
                                <Form.Control id="ch_sigla" name="ch_sigla" type="text" value={dsEstado == 'Selecione' ? '': dsEstado} className="d-none"/>
                                {revelarSelectEstado === false &&
                                    <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectEstado(!revelarSelectEstado)}>
                                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsEstado}</div>
                                        <img className={revelarSelectEstado?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                                {revelarSelectEstado === true &&
                                    <SelectGenerico
                                        paramRota={"uf"}
                                        paramCampo1={cdEstado}
                                        paramCampo2={null}
                                        paramCampo3={null}
                                        paramCampo4={null}
                                        setCodigo={setCdEstado}
                                        setDescricao1={setDsEstado}
                                        setDescricao2={setDummy}
                                        setDescricao3={setDummy}
                                        setDescricao4={setDummy}
                                        setRevelarSelect={setRevelarSelectEstado}
                                        placeholder={dsEstado}
                                        sufixo={""}
                                    >
                                    </SelectGenerico>
                                }
                                {/* { revelarSelectEstado === false &&
                                    <Button name="cdEstado" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectEstado(!revelarSelectEstado)}>
                                        <div className="fonte-cor-1 label-campo">{dsEstado}</div>
                                        <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                                { revelarSelectEstado === true &&
                                    <SelectEstado
                                        setRevelarSelectEstado={setRevelarSelectEstado} //Hook para fechar a modal
                                        setCdEstado={setCdEstado} //Código do estado
                                        setDsEstado={setDsEstado} //Nome do estado
                                        phEstado={phEstado} //Placeholder para mostrar o valor original em caso de edição
                                    >
                                    </SelectEstado>
                                } */}
                            </Form.Group>
                        </>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-tipo-pessoa", "imagem-filtro-tipo-pessoa"); ColetaClickstream(1425) /*virtuTabelaClientesTabelaClientesDropFiltrosCidade*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Cidade </Form.Label>
                        <img id={"imagem-filtro-tipo-pessoa"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-tipo-pessoa"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Control id="ds_pav_cidade" name="ds_pav_cidade" type="text" value={dsCidade == 'Selecione' ? '': dsCidade} className="d-none"/>
                        {cdEstado > 0 ?
                            <Form.Group className="container-select-filtro">
                                {revelarSelectCidade === false &&
                                    <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCidade(!revelarSelectCidade)}>
                                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCidade}</div>
                                        <img className={revelarSelectCidade?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                                {revelarSelectCidade === true &&
                                    <SelectGenerico
                                        paramRota={"cidade"}
                                        paramCampo1={cdEstado}
                                        paramCampo2={null}
                                        paramCampo3={null}
                                        paramCampo4={null}
                                        setCodigo={setCdCidade}
                                        setDescricao1={setDsCidade}
                                        setDescricao2={setDummy}
                                        setDescricao3={setDummy}
                                        setDescricao4={setDummy}
                                        setRevelarSelect={setRevelarSelectCidade}
                                        placeholder={dsCidade}
                                        sufixo={""}
                                    >
                                    </SelectGenerico>
                                }
                            </Form.Group>
                        :
                            <Form.Group className="container-select-filtro">
                                { revelarSelectEstado === false &&
                                    <Button disabled={true} name="cdCidade" className="campo-texto-cor-3 campo-select">
                                        <div className="fonte-cor-1 label-campo">{dsCidade}</div>
                                        <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                            </Form.Group>
                        }
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    {/* <div className="drop-filtros-menu" // OBS: removido filtro por Clientes porque não faz sentido ter na página virtueyes/virtuClientes (BUG 3852)
                        onClick={e => {props.dropFiltros(e, "filtro-clientes-cliente", "imagem-filtro-clientes-cliente"); ColetaClickstream(1426) 
                        //virtuTabelaClientesTabelaClientesDropFiltrosAtendidoPor
                    }}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Cliente </Form.Label>
                        <img id={"imagem-filtro-clientes-cliente"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-clientes-cliente"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Control id="ds_pav_cliente" name="ds_pav_cliente" type="text" value={dsCliente == 'Selecione' ? '': dsCliente} className="d-none"/>
                            <Form.Group className="container-select-filtro">
                                {revelarSelectCliente === false &&
                                    <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                        <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                                {revelarSelectCliente === true &&
                                    <SelectGenerico
                                        paramRota={"clientes_do_cliente"}
                                        paramCampo1={decryptJWT('codigoClienteErpAntigo')}
                                        paramCampo2={null}
                                        paramCampo3={null}
                                        paramCampo4={null}
                                        setCodigo={setCdCliente}
                                        setDescricao1={setDsCliente}
                                        setDescricao2={setDummy}
                                        setDescricao3={setDummy}
                                        setDescricao4={setDummy}
                                        setRevelarSelect={setRevelarSelectCliente}
                                        placeholder={dsCliente}
                                        sufixo={""}
                                    >
                                    </SelectGenerico>
                                }
                            </Form.Group>
                    </div> */}
                    {/* ------------------- */}
                    {/* <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-cnpj", "imagem-filtro-cnpj")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Atendido por </Form.Label>
                        <img id={"imagem-filtro-cnpj"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-cnpj"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <Form.Control id="ds_vendedor" name="ds_vendedor" type="text" value={dsResponsavel == 'Selecione' ? '' : dsResponsavel} className="d-none"/>
                        <Form.Group className="container-select-filtro">
                            { revelarSelectResponsavel === false &&
                                <Button name="cdResponsavel" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectResponsavel(!revelarSelectResponsavel)}>
                                    <div className="fonte-cor-1 label-campo-select">{dsResponsavel}</div>
                                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                </Button>
                            } 
                            { revelarSelectResponsavel === true &&
                                <SelectResponsavel
                                    setRevelarSelectResponsavel={setRevelarSelectResponsavel} //Hook para fechar a modal
                                    setCdResponsavel={setCdResponsavel} //Código do responsavel
                                    setDsResponsavel={setDsResponsavel} //Nome do responsavel
                                    phResponsavel={phResponsavel} //Placeholder para mostrar o valor original em caso de edição
                                >
                                </SelectResponsavel>
                            }
                        </Form.Group>
                    </div> */}
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    )
}