//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
import decryptJWT from '../../../utils/decryptJWT'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectFatPlano from '../../componentesModais/selects/SelectFatPlano'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectFatPlano, setRevelarSelectFatPlano] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">Tipo de Pessoa {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectFatPlano === false &&
                <Button name="cdServico" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFatPlano(!revelarSelectFatPlano)}>
                    <div className="fonte-cor-1 label-campo">{dsServico}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectFatPlano === true &&
                <SelectFatPlano
                    setRevelarSelectFatPlano={setRevelarSelectFatPlano} //Hook para fechar a modal
                    setCdPlano={setCdPlano} //Código do Plano
                    setDsPlano={setDsPlano} //Nome do Plano
                    dsPlano={dsPlano}
                >
                </SelectFatPlano>
            }
        </Form.Group>*/

export default function SelectFatPlano(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS MODAL
    const [lsPlano, setLsPlano] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handlePlano(codigoPlano, NomePlano){
        props.setCdPlano(codigoPlano)
        props.setDsPlano(NomePlano)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectFatPlano(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderPlano(){
            const {data} = await api.get('/m1/consultar/fat_plano?cd_pav_usuario='+decryptJWT('codigo'));

            if(data.dados.length > 0){
                let objetoPlano = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.cd_fat_plano}>
                            <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handlePlano(item.cd_fat_plano, item.ds_plano) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.ds_plano}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsPlano(objetoPlano)
            }else{
                let objetoPlano = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsPlano(objetoPlano)
            }
            setSpinAtivo(false)
        }
        renderPlano()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-planoiner"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectFatPlano(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsPlano}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsPlano}
                </ul>
            </div>
        </Form>
    )
}