//BIBLIOTECAS
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import {formatarData} from '../../../../utils/formatadorDataTabela'
import {Image, Tooltip, OverlayTrigger} from 'react-bootstrap'
//ICONES
import { elipseAzul, elipseVerde, elipseVermelho } from "../../../../utils/alternanciaIcones";
//OUTROS
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js';
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao';
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso';
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro';
import '../../../../css/painelVirtueyes/detalhamentoSolicitacao/operadora/tabela.css'

export default function VisaoGeral(props) {
    //HOOKS
    const [itens, setItens] = useState('')
    const [linhaSolicitacao, setLinhaSolicitacao] = useState({});
    const [renderizar, setRenderizar] = useState(false);
    const [solicitacaoModalTxt, setSolicitacaoModalTxt] = useState('reenvio da solicitação')
    const [revelarMensagemAtencaoReenviarSolicitacao, setRevelarMensagemAtencaoReenviarSolicitacao] = useState(false)
    const [revelarMensagemSucessoSolicitacaoReenvio, setRevelarMensagemSucessoSolicitacaoReenvio] = useState(false)
    const [revelarMensagemErroSolicitacaoReenvio, setRevelarMensagemErroSolicitacaoReenvio] = useState(false)
    
    //SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);

    useEffect(() => {
        async function handleCarregarSimcards(){
            setSpinAtivo(true) 
            const dataPeriodo = await api.get('m1/consultar/solicitacao_simcard?cd_crm_solicitacao_operadora='+props.cdSolicitacao+'&linhasReset='+props.linhasReset);
            setSpinAtivo(false) 
            if(dataPeriodo.data.dados != undefined){
                setItens(dataPeriodo.data.dados)
            }
        }
        handleCarregarSimcards();
    }, [renderizar])

    const colunas = [
        {
            dataField: 'CODIGO_CLIENTE',
            text: 'Cód. cliente',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }   
        },
        {
            dataField: 'RAZAO_SOCIAL',
            text: 'Razão social',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'NOME_FANTASIA',
            text: 'Nome fantasia',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'LINHA',
            text: 'SIM card',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ICCID',
            text: 'ICCID',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'OPERADORA',
            text: 'Operadora',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'SIMCARD_TIPO',
            text: 'Tipo de SIM card',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                if(cell != null){
                    if(cell===0){
                        return <p className="fonte-cor-1 label-tabela-1">Normal</p>
                    }
                    else{
                        return <p className="fonte-cor-1 label-tabela-1">Triplo corte</p>
                    }
                }
                else{
                    return <p className="fonte-cor-1 label-tabela-1">Sem informação</p>;
                }
            }
        },
        {
            dataField: 'TECNOLOGIA',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            text: 'Tecnologia',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'GEO',
            text: 'Tecnologia - descontinuado',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            hidden: true,
            formatter: (cell, row) => {
                const split = cell.split(',')
                const tecnologia = split[2]
                return <p className="fonte-cor-1 label-tabela-1">{(tecnologia != '' && tecnologia != 'NN') ? tecnologia : "-"}</p>;
            }
        },
        {
            dataField: 'CD_SOLICITACAO_CLIENTE',
            text: 'ID solicitação cliente',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'CD_SOLICITACAO_OPERADORA',
            text: 'ID solicitação operadora',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            hidden: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'DT_INICIO',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            text: 'Data solicitação cliente',
            sort: true,
            formatter: (cell, row) => {
                return formatarData(cell);
            }
        },
        {
            dataField: 'CD_SOLICITACAO_SIMCARD',
            text: 'Solicitacao simcard',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            hidden: true,            
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_status_api',
            headerClasses: 'nao-selecionavel',
            text: 'Status API',
            sort: true,
            formatter: (cell, row) => {
                if(cell != '' && cell != undefined){
                    if(cell == 'Enviado'){
                        return <p className="fonte-cor-1 label-tabela-1 btn-status-tabela"><Image className="img-eclipse-status" src={ elipseAzul() }/> {cell}</p>
                    }else if(cell == 'Retornado'){
                        return <p className="fonte-cor-1 label-tabela-1"><Image className="img-eclipse-status" src={ elipseVerde() }/> {cell}</p>
                    }else{
                        return (
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={'tooltip-bottom'}>Refazer solicitação</Tooltip>}>
                                <button className="fonte-cor-1 label-tabela-1 btn-status-tabela-erro" onClick={() => handleReenviarSolicitacao(row)}><Image className="img-eclipse-status" src={ elipseVermelho() }/> {cell}</button>
                            </OverlayTrigger>
                        )
                    }
                }
                else{
                    return <p className="fonte-cor-1 label-tabela-1">-</p>;
                }
            }
        },
    ]   

    const colunas2 = [
        
        {
            dataField: 'CD_SOLICITACAO_CLIENTE',
            text: 'ID solicitação cliente',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        
        {
            dataField: 'LINHA',
            text: 'MSISDN',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ICCID',
            text: 'ICCID',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'OPERADORA',
            text: 'Operadora',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'TECNOLOGIA',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            text: 'Tecnologia',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'GEO',
            text: 'Tecnologia - descontinuado',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            hidden: true,
            formatter: (cell, row) => {
                const split = cell.split(',')
                const tecnologia = split[2]
                return <p className="fonte-cor-1 label-tabela-1">{(tecnologia != '' && tecnologia != 'NN') ? tecnologia : "-"}</p>;
            }
        },
        {
            dataField: 'CD_SOLICITACAO_OPERADORA',
            text: 'ID solicitação operadora',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            hidden: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'DT_INICIO',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            text: 'Data solicitação cliente',
            sort: true,
            formatter: (cell, row) => {
                return formatarData(cell);
            }
        },
        {
            dataField: 'CD_SOLICITACAO_SIMCARD',
            text: 'Solicitacao simcard',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            hidden: true,            
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_status_api',
            headerClasses: 'nao-selecionavel',
            text: 'Status API',
            sort: true,
            formatter: (cell, row) => {
                if(cell != '' && cell != undefined){
                    if(cell == 'Enviado'){
                        return <p className="fonte-cor-1 label-tabela-1 btn-status-tabela"><Image className="img-eclipse-status" src={ elipseAzul() }/> {cell}</p>
                    }else if(cell == 'Retornado'){
                        return <p className="fonte-cor-1 label-tabela-1"><Image className="img-eclipse-status" src={ elipseVerde() }/> {cell}</p>
                    }else{
                        return (
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={'tooltip-bottom'}>Refazer solicitação</Tooltip>}>
                                <button className="fonte-cor-1 label-tabela-1 btn-status-tabela-erro" onClick={() => handleReenviarSolicitacao(row)}><Image className="img-eclipse-status" src={ elipseVermelho() }/> {cell}</button>
                            </OverlayTrigger>
                        )
                    }
                }
                else{
                    return <p className="fonte-cor-1 label-tabela-1">-</p>;
                }
            }
        },
    ]   

    function handleReenviarSolicitacao(linha){
        setLinhaSolicitacao(linha);
        setSolicitacaoModalTxt(props.getSolModalTxt(props.descSolicitacao))
        setRevelarMensagemAtencaoReenviarSolicitacao(true);
    }

    function handleMensagemAtencaoReenviarSolicitacao(confirmacao){
        if(revelarMensagemAtencaoReenviarSolicitacao === false){
            setRevelarMensagemAtencaoReenviarSolicitacao(true)
        }else{
            setRevelarMensagemAtencaoReenviarSolicitacao(false)
        }
        if(confirmacao){
            reenviarSolicitacao();
        }
    }

    function handleMensagemSucessoSolicitacaoReenvio(confirmacao){
        if(revelarMensagemSucessoSolicitacaoReenvio === false){
            setRevelarMensagemSucessoSolicitacaoReenvio(true)
        }else{
            setRevelarMensagemSucessoSolicitacaoReenvio(false)
        }
        if(confirmacao){
            setRenderizar(!renderizar)
        }
    }

    function handleMensagemErroSolicitacaoReenvio(confirmacao){
        if(revelarMensagemErroSolicitacaoReenvio === false){
            setRevelarMensagemErroSolicitacaoReenvio(true)
        }else{
            setRevelarMensagemErroSolicitacaoReenvio(false)
        }
    }

    async function reenviarSolicitacao(){
        setSpinAtivo(true)

        const body = { ...linhaSolicitacao, CD_TIPO_SOLICITACAO: props.cdTipoSolicitacao }
        const respSolicitacao = props.refazerSolicitacao(body)
        
        if(respSolicitacao){
            setRevelarMensagemSucessoSolicitacaoReenvio(true)
            setSpinAtivo(false)
        }
        else{
            setRevelarMensagemErroSolicitacaoReenvio(true)
            setSpinAtivo(false)
        }
    }
    
    return(
        <>
         { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="campo-select-triangulo-cor-2 campo-select-triangulo"></div>
            <div id="tela-descricao-visao-geral-soliciatacao-cliente">
               {!props.linhasReset && <div className="bg-background-2 container-caixas-descricao-solicitacao-cliente"> 
                  <div className="container-caixas-tela-descricao-solicitacao-cliente fundo-cor-3">
                        <div className="caixa-descricao-solicitacao-cliente-dados">
                            <BootstrapTable
                                hover
                                classes='tabela-transparente-header-fixo'
                                keyField='operadora'
                                headerClasses={ "class-centralizar"}
                                data={ itens }
                                columns={ colunas}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                bootstrap4 = {true}
                            />
                        </div>
                    </div>
                </div> }
                {props.linhasReset && <div className="bg-background-2 container-caixas-descricao-solicitacao-cliente"> 
                  <div className="container-caixas-tela-descricao-solicitacao-cliente-2 fundo-cor-3">
                        <div className="caixa-descricao-solicitacao-cliente-dados">
                            <BootstrapTable
                                hover
                                classes='tabela-transparente-header-fixo'
                                keyField='operadora'
                                headerClasses={ "class-centralizar"}
                                data={ itens }
                                columns={ colunas2}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                bootstrap4 = {true}
                            />
                        </div>
                    </div>
                </div> }
            </div>
            { revelarMensagemAtencaoReenviarSolicitacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencaoReenviarSolicitacao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo2={"Deseja tentar novamente efetuar"}
                            mensagemParagrafo3={`o ${solicitacaoModalTxt} desta linha?`} 
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemSucessoSolicitacaoReenvio &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucessoSolicitacaoReenvio}
                            mensagemTitulo={"Sucesso!"}
                            mensagemParagrafo1={`O ${solicitacaoModalTxt} desta linha foi reenviado`}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErroSolicitacaoReenvio &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={() => handleMensagemErroSolicitacaoReenvio}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Não foi possivel reenviar"}
                            mensagemParagrafo2={`o ${solicitacaoModalTxt} desta linha`}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
        </>
    )
}