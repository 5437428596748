
import React, { useState } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api'
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Tooltip, OverlayTrigger, FormControl } from 'react-bootstrap';
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import SelectGenerico from '../../componentesModais/selects/selectGenerico'
//CSS
import '../../../css/painelFaturas/fatTrial/trial.css'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

//IMPORTAÇÕES REDUX
import { connect } from 'react-redux';
import {telaTrial, logoVeye, exclamacao, setaDropdown} from "../../../utils/alternanciaIcones";
const axios = require('axios');

let emailExistente = false
let cnpjExistente = false
let senhaErro = false

function Trial() {
    //HOOKS REDUX
    const { register, handleSubmit, errors } = useForm();
    const [spinAtivo, setSpinAtivo] = useState(false);
    const [nomeEmpresa, setNomeEmpresa] = useState('')
    const [dsNomeFantasia, setDsNomeFantasia] = useState()
    const [dsRazaoSocial, setDsRazaoSocial] = useState()
    const [dsCnpj, setDsCnpj] = useState("")
    const [dsEmail, setDsEmail] = useState('')
    const [dsEmailEmpresa, setDsEmailEmpresa] = useState('')
    const [dsSenha, setDsSenha] = useState('')
    const [dsSenhaConfirmar, setDsSenhaConfirmar] = useState('')
    const [switchTermo, setSwitchTermo] = useState(false)
    const [dsTelefone, setDsTelefone] = useState("")
    const [dsNumero, setDsNumero] = useState("")
    const [dsCep, setDsCep] = useState("")
    const [dsCepValidador, setDsCepValidador] = useState(false)
    const [dsCepErroInfo, setDsCepErroInfo] = useState('')
    const [dsLogradouro, setDsLogradouro] = useState('')
    const [dsBairro, setDsBairro] = useState('')
    const [dsCidade, setDsCidade] = useState('')
    const [dsUf, setDsUf] = useState('Selecione')
    const [cdUf, setCdUf] = useState('')
    const [dsPais, setDsPais] = useState('Selecione')
    const [cdPais, setCdPais] = useState('')
    const [dsComplemento, setDsComplemento] = useState()
    const [dsInformacoesAdicionais, setDsInformacoesAdicionais] = useState()
    const [avancar, setAvancar] = useState(-1)
    const [phMensagemSucesso, setPhMensagemSucesso] = useState('cadastrado')
    const [dummy, setDummy] = useState('')
    //HOOKS MODAIS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro interno");
    const [revelarSelectUf, setRevelarSelectUf] = useState(false);
    const [revelarSelectPais, setRevelarSelectPais] = useState(false);
   
    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    async function verificarTelaAcesso() {
        if(dsEmail != '' && dsSenha != '' && dsSenhaConfirmar != ''){ 
            setSpinAtivo(true)
            await api.post('/m1/consultar/fat_trial/fat_trial_verificar_email', {
                'ds_email': dsEmail,
            })
            .then(function (response) {
                emailExistente = false
            })
            .catch(function (error) {
                if(error.response.data.status === 401){
                    emailExistente = true
                }else{
                    emailExistente = false
                }
            });

            if(dsSenha != dsSenhaConfirmar){
                senhaErro = true
            }else{
                senhaErro = false
            }
            if(emailExistente === false && senhaErro === false){
                setAvancar(1)
                setSpinAtivo(false)

            }else{
                setSpinAtivo(false)
            }
        }
    }

    async function verificarTelaEmpresa(){
        if(dsCnpj != ''){
            setSpinAtivo(true)
            await api.post('/m1/consultar/fat_trial/fat_trial_verificar_cnpj', {
                'ds_cnpj': dsCnpj,
            })
            .then(function (response) {
                cnpjExistente = false
            })
            .catch(function (error) {
               if(error.response.data.status === 401){
                    cnpjExistente = true
                }else{
                    cnpjExistente = false
                }
            });

            if(cnpjExistente === false){
                setAvancar(2)
                setSpinAtivo(false)

            }else{
                setSpinAtivo(false)
            }
        }
    }

    async function handleSalvar(){
        setSpinAtivo(true)
        await api.post('/m1/cadastrar/fat_trial', {
            'ds_razao_social': dsRazaoSocial,
            'ds_nome_fantasia': dsNomeFantasia,
            'ds_cnpj': dsCnpj,
            'ds_email': dsEmail,
            'ds_email_empresa': dsEmailEmpresa,
            'ds_senha': dsSenha,
            'ds_telefone': dsTelefone,
            'ds_nome': nomeEmpresa,
            'ds_pav_logradouro': dsLogradouro,
            'n0_numero': dsNumero,
            'ds_pav_bairro': dsBairro,
            'ds_pav_cidade': dsCidade,
            'ds_pav_cep': dsCep,
            'cd_pav_uf': cdUf,
            'cd_pav_pais': cdPais,
            'cd_pav_pais': 1,
            'ds_complemento': dsComplemento,
            'ds_observacoes': dsInformacoesAdicionais,
        },)
        .then(function (response) {
            setSpinAtivo(false)
            setAvancar(3)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.dados)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
            setAvancar(-1)
        });
    }
  
    async function handleCnpj(){
        setSpinAtivo(true)

        await axios.get("https://receitaws.com.br/v1/cnpj/"+dsCnpj,
            {headers:{
                Authorization: 'Bearer 0b024610753b920cf930ec5c0db5a695863a6cdb1eb8ee47f5ea6383dda1c912',
            }
        })
        .then(function (resposta) {
            if(resposta.data.status != "ERROR"){
                setDsNomeFantasia(resposta.data.fantasia)
                setDsRazaoSocial(resposta.data.nome)
                setDsEmail(resposta.data.email)
                setDsTelefone(resposta.data.telefone)
                let auxCep = resposta.data.cep
                auxCep = auxCep.replace("-","")
                auxCep = auxCep.replace(".","")
                handlerConsultarCep(auxCep)
            } 
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
        });
    }

    async function handlerConsultarCep(){
        setSpinAtivo(true)
        await api.get('/m1/consultar/fat_trial/fat_trial_cep?cep='+dsCep)
        .then(function (response) {
            if(response.data.status == 200){
                if(response.data.dados == 'Cep Invalido'){
                    setDsCepValidador(true)
                    setDsCepErroInfo(response.data.dados)
                    setDsBairro('')
                    setDsCidade('')
                    setCdUf(-1)
                    setDsLogradouro('')
                    setSpinAtivo(false)
                }
                else{
                    setDsCep(dsCep)
                    setDsBairro(response.data.dados.bairro)
                    setDsCidade(response.data.dados.localidade)
                    setCdUf(response.data.dados.cd_pav_uf)
                    setDsUf(response.data.dados.uf)
                    setDsLogradouro(response.data.dados.logradouro)
                    setSpinAtivo(false)
                }
            }
            return response
        })
        .catch(function (error) {
            if(error == 'Error: Request failed with status code 400'){
                setDsCepValidador(true)
                setDsCepErroInfo("Cep Invalido")
                setSpinAtivo(false)
            }
            else{
                setDsCepValidador(true)
                setDsCepErroInfo("Erro interno")
                setSpinAtivo(false)
            }
            return error
        })
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
            }
        }
    }

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-cnpj"){
                setDsCnpj(valor)
            }
            if(id === "campo-texto-telefone"){
                setDsTelefone(valor)
            }
            if(id === "campo-texto-cep"){
                setDsCep(valor)
            }
            if(id === "campo-texto-numero"){
                setDsNumero(valor)
            }
        }
    }

    async function handleDownloadTermo(){
        const downloadLink = document.createElement("a");
        const fileName = "contrato.pdf";
        downloadLink.target = "_blank"
        const url = window.location.href.split('/')
        downloadLink.href = url[0]+"//"+url[2]+"/files/contrato_faturas.pdf";
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return (
        <>
            <div className="container-principal-trial">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <div className="container-logo-lateral">
                    <img alt="veye" className="imagem-trial-veye" src={logoVeye()}></img>
                    <img alt="spinner" className="imagem-trial-spinner" src={telaTrial()}></img>
                </div>
                <div className="container-formulario-trial">
                    <Form onSubmit={handleSubmit(() => handleSalvar())}>
                        <div className="container-principal-barra-trial">
                            <div className="modal-cadastro-trial-2">
                                <div className="grupo-barra-processo">
                                    <div className="container-barra-elipse">
                                        <label className={avancar === -1 ? "label-barra-progresso-trial" : "label-barra-progresso-trial-default"}>Acesso</label>
                                        <div className={avancar === -1 ? 'elipse-trial-verde elipse-trial-1':"elipse-trial-1"}>
                                        </div>
                                        <div className="barra-trial-1"></div>
                                    </div>
                                    <div className="container-barra-elipse">
                                    <label className={avancar === 1 ? "label-barra-progresso-trial" : "label-barra-progresso-trial-default"}>Empresa</label>
                                        <div className={avancar === 1 ? 'elipse-trial-verde elipse-trial-2':"elipse-trial-2"}>
                                        </div>
                                        <div className="barra-trial-2"></div>
                                    </div>
                                    <div className="container-barra-elipse">
                                    <label className={avancar === 2 ? "label-barra-progresso-trial" : "label-barra-progresso-trial-default"}>Endereço</label>
                                        <div className={avancar === 2 ? 'elipse-trial-verde elipse-trial-3':"elipse-trial-3"}>
                                        </div>
                                        <div className="barra-trial-2"></div>
                                        <label className={avancar === 3 ? "label-barra-progresso-trial-2" : "label-barra-progresso-trial-default"}>Confirmação</label>
                                        <div className={avancar === 3 ? 'elipse-trial-verde elipse-trial-4':"elipse-trial-4"}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {avancar === -1 &&
                            <div className="modal-cadastro-trial">
                                <Form.Row>
                                    <Col>
                                        <Form.Label>
                                            <div className="fonte-cor-10 label-modal-trial">ACESSO</div>
                                        </Form.Label>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-cadastro-trial-1">
                                            <div className="coluna-cadastro-trial-1">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">Login(e-mail)</div> </Form.Label>
                                                    <Form.Control
                                                        className="campo-texto-cor-4 campo-texto-trial"
                                                        type="text" 
                                                        placeholder="E-mail" 
                                                        onChange={e =>setDsEmail(e.target.value)}
                                                        value = {dsEmail}
                                                        ref={register({validate: {minimo: value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) == true}})}
                                                    />
                                                </Form.Group>
                                                {emailExistente === true &&
                                                    <h6 className="mensagem-erro-senha-diferente-trial">O e-mail já em uso. </h6>
                                                }
                                            </div>
                                            <div className="coluna-cadastro-trial-1">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">Senha</div> </Form.Label>
                                                    <FormControl 
                                                        className="campo-texto-cor-4 campo-texto-trial"
                                                        placeholder="Senha"
                                                        type='password'
                                                        onChange={e => setDsSenha(e.target.value)}
                                                        value = {dsSenha}
                                                        maxLength="20"
                                                        autocomplete="off"
                                                    />
                                                </Form.Group>
                                                {senhaErro == true &&
                                                    <h6 className="mensagem-erro-senha-diferente-trial"> As senhas não são iguais. </h6>
                                                }
                                            </div>
                                            <div className="coluna-cadastro-trial-1">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">Senha</div> </Form.Label>
                                                    <FormControl 
                                                        className="campo-texto-cor-4 campo-texto-trial"
                                                        placeholder="Confirmar senha"
                                                        type='password'
                                                        onChange={e => setDsSenhaConfirmar(e.target.value)}
                                                        value = {dsSenhaConfirmar}
                                                        maxLength="20"
                                                        autocomplete={false}
                                                    />
                                                </Form.Group>
                                                {senhaErro == true &&
                                                    <h6 className="mensagem-erro-senha-diferente-trial"> As senhas não são iguais. </h6>
                                                }
                                            </div>
                                            <div className="coluna-cadastro-trial-9">
                                                <Form.Group>
                                                    <div class="container-switch-trial">
                                                        <input type='checkbox'
                                                             class="input-checkbox-header-trial"
                                                             id="aceitarTermos"
                                                             name="aceitarTermos"
                                                             onChange={(e) => setSwitchTermo(e.target.checked)}/>
                                                        <label for="aceitarTermos" class="label-checkbox-trial"></label>
                                                    </div>
                                                    <p className="campo-texto-acordo-switch">Li e estou de acordo com os
                                                        <p className="termos-adesao-cor" onClick={() => handleDownloadTermo()}>&nbsp;termos de adesão.</p>
                                                    </p>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        {(dsEmail != '' && dsSenha != '' && dsSenhaConfirmar != '' && switchTermo != false) ?
                                            <div className="container-botao-confirmar">
                                                <Button className="botao-confirmar botao-confirmar-trial" variant="success" size="sm" onClick={()=> verificarTelaAcesso()}>
                                                    <div className="fonte-cor-11">Confirmar</div>
                                                </Button>
                                            </div>
                                        :
                                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Preencha o e-mail e a senha.</Tooltip>}>
                                                <div className="container-botao-confirmar">
                                                    <Button className="botao-confirmar-desabilitado-2" size="sm">
                                                        <div className="fonte-cor-11">Confirmar</div>
                                                    </Button>
                                                </div>
                                            </OverlayTrigger>
                                        }
                                    </Col>
                                </Form.Row>
                            </div>
                        }
                        {avancar === 1 &&
                            <div className="modal-cadastro-trial">
                                <Form.Row>
                                    <Col>
                                        <Form.Label>
                                            <div className="fonte-cor-10 label-modal-trial">EMPRESA</div>
                                        </Form.Label>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-cadastro-trial-1">
                                            <div className="coluna-cadastro-trial-2">
                                                <Form.Label> <div className="fonte-cor-10 label-campo">CNPJ</div> </Form.Label>
                                                <Form.Group>
                                                    <Form.Control
                                                        name="dscnpj" 
                                                        value={dsCnpj}
                                                        className="campo-texto-cor-4 campo-texto-trial-2"
                                                        type="text" 
                                                        onChange={e => validarNumero("campo-texto-cnpj", e.target.value)}
                                                        ref={register({ required: true, pattern: /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[\-]?[0-9]{2})/ })}
                                                        maxlength="14"
                                                    />
                                                    <Button className="botao-confirmar botao-confirmar-preencher" variant="success" size="sm" onClick={()=> handleCnpj()}>
                                                        <div className="fonte-cor-11 label-botao-preencher">Auto preencher</div>
                                                    </Button>

                                                    {errors.dscnpj && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O CNPJ deve ser informado no seguinte formato 00.000.000/0000-00</p>}
                                                    {cnpjExistente === true &&
                                                     <p className="fonte-cor-3 label-alerta">{iconeAlerta} O CNPJ já está em uso</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="coluna-cadastro-trial-1">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">Nome fantasia</div> </Form.Label>
                                                    <Form.Control
                                                        className="campo-texto-cor-4 campo-texto-trial"
                                                        type="text"
                                                        value={dsNomeFantasia}
                                                        onChange={e => setDsNomeFantasia(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="coluna-cadastro-trial-1">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">Razão social</div> </Form.Label>
                                                    <Form.Control
                                                        className="campo-texto-cor-4 campo-texto-trial"
                                                        type="text"
                                                        value={dsRazaoSocial}
                                                        onChange={e => setDsRazaoSocial(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <div className="coluna-cadastro-trial-1">
                                            <Form.Group>
                                                <Form.Label> <div className="fonte-cor-10 label-campo">Telefone</div> </Form.Label>
                                                <Form.Control
                                                    className="campo-texto-cor-4 campo-texto-trial"
                                                    type="text"
                                                    value={dsTelefone}
                                                    onChange={e => validarNumero("campo-texto-telefone", e.target.value)}
                                                    maxlength="12"
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="grupo-cadastro-trial-1">
                                            <div className="coluna-cadastro-trial-1">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">Nome</div> </Form.Label>
                                                    <OverlayTrigger key="top" delay={{ show: 500, hide: 250 }}placement="top" overlay={<Tooltip id={`tooltip-top`}>Nome da empresa</Tooltip>}>
                                                        <Form.Control
                                                            name="ds_nome"
                                                            value={nomeEmpresa} 
                                                            className="campo-texto-cor-4 campo-texto-trial" 
                                                            type="text"
                                                            onChange={e => setNomeEmpresa(e.target.value)}
                                                            maxlength="100"
                                                        />
                                                    </OverlayTrigger>
                                                </Form.Group>
                                            </div>
                                            <div className="coluna-cadastro-trial-1">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">E-mail</div> </Form.Label>
                                                    <Form.Control
                                                        className="campo-texto-cor-4 campo-texto-trial"
                                                        type="text"
                                                        value={dsEmailEmpresa}
                                                        onChange={e => setDsEmailEmpresa(e.target.value)}
                                                        ref={register({validate: {minimo: value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) == true}})}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        {(dsRazaoSocial != '' && dsNomeFantasia != '' 
                                        && dsCnpj != '' && dsEmailEmpresa != '' && dsTelefone != '' && nomeEmpresa != '') ?
                                            <div className="container-botao-confirmar">
                                                <Button className="botao-confirmar botao-confirmar-trial" variant="success" size="sm" onClick={()=> verificarTelaEmpresa()}>
                                                    <div className="fonte-cor-11">Avançar</div>
                                                </Button>
                                            </div>
                                        :
                                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Preencha o CNPJ, a Razão Social, Nome Fantasia, E-mail, Telefone e o Nome.</Tooltip>}>
                                                <div className="container-botao-confirmar">
                                                    <Button className="botao-confirmar-desabilitado-2" size="sm">
                                                        <div className="fonte-cor-11">Avançar</div>
                                                    </Button>
                                                </div>
                                            </OverlayTrigger>
                                        }
                                    </Col>
                                </Form.Row>
                            </div>
                        }
                        {avancar === 2 &&
                            <div className="modal-cadastro-trial">
                            <Form.Row>
                                <Col>
                                    <Form.Label>
                                        <div className="fonte-cor-10 label-modal-trial">ENDEREÇO</div>
                                    </Form.Label>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <div className="grupo-cadastro-trial-1">
                                    <div className="coluna-cadastro-trial-1">
                                        <Form.Label> <div className="fonte-cor-10 label-campo">CEP</div> </Form.Label>
                                        <Form.Group>
                                            <Form.Control 
                                                name="ds_cep"
                                                value={dsCep} 
                                                className="campo-texto-cor-4 campo-texto-trial-2"
                                                type="text" 
                                                onChange={e => validarNumero("campo-texto-cep", e.target.value)}
                                                ref={register({ required: true, min: 3, max: 9 })} 
                                                maxlength="9"
                                            />
                                            <Button className="botao-confirmar botao-confirmar-preencher" variant="success" size="sm" onClick={()=> handlerConsultarCep()}>
                                                <div className="fonte-cor-11 label-botao-preencher">Auto preencher</div>
                                            </Button>
                                            {dsCepValidador && <p className="fonte-cor-3 label-alerta">{iconeAlerta} {dsCepValidador?dsCepErroInfo:"O campo é obrigatorio."}</p>}
                                        </Form.Group>
                                    </div>
                                </div>
                            </Form.Row>
                            <Form.Row>
                                    <Col>
                                        <div className="grupo-cadastro-trial-1">
                                            <div className="coluna-cadastro-trial-1">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">Logradouro</div> </Form.Label>
                                                    <Form.Control
                                                        className="campo-texto-cor-4 campo-texto-trial"
                                                        type="text"
                                                        value={dsLogradouro}
                                                        onChange={e => setDsLogradouro(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-cadastro-trial-2">
                                            <div className="coluna-cadastro-trial-3">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">Número</div> </Form.Label>
                                                    <Form.Control
                                                        className="campo-texto-cor-4 campo-texto-trial"
                                                        type="text"
                                                        value={dsNumero}
                                                        onChange={e => validarNumero("campo-texto-numero", e.target.value)}

                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="coluna-cadastro-trial-4">
                                                <Form.Group>
                                                    <Form.Label>
                                                        <div className="fonte-cor-10 label-campo">Complemento</div>
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="campo-texto-cor-4 campo-texto-trial"
                                                        type="text"
                                                        value={dsComplemento}
                                                        onChange={e => setDsComplemento(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-cadastro-trial-2">
                                            <div className="coluna-cadastro-trial-5">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">Bairro</div> </Form.Label>
                                                    <Form.Control
                                                        className="campo-texto-cor-4 campo-texto-trial"
                                                        type="text"
                                                        value={dsBairro}
                                                        onChange={e => setDsBairro(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="coluna-cadastro-trial-6">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">Cidade</div> </Form.Label>
                                                    <Form.Control
                                                        className="campo-texto-cor-4 campo-texto-trial"
                                                        type="text"
                                                        value={dsCidade}
                                                        onChange={e => setDsCidade(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-cadastro-trial-2">
                                            <div className="coluna-cadastro-trial-7">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">UF</div> </Form.Label>
                                                    { revelarSelectUf === false &&
                                                        <Button className="campo-texto-cor-4 campo-select-trial" onClick={() => setRevelarSelectUf(!revelarSelectUf)}>
                                                            <div className="fonte-cor-10  campo-texto-select-tamanho-trial">{dsUf}</div>
                                                            <img className={revelarSelectUf?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado-2 nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                                        </Button>
                                                    }
                                                    { revelarSelectUf === true &&
                                                        <SelectGenerico
                                                            css='trial'
                                                            paramRota={'uf'}
                                                            paramCampo1={null}
                                                            paramCampo2={null}
                                                            paramCampo3={null}
                                                            paramCampo4={null}
                                                            setCodigo={setCdUf}
                                                            setDescricao1={setDsUf}
                                                            setDescricao2={setDummy}
                                                            setDescricao3={setDummy}
                                                            setDescricao4={setDummy}
                                                            setRevelarSelect={setRevelarSelectUf}
                                                            placeholder={dsUf}
                                                            sufixo={""}
                                                        >
                                                        </SelectGenerico>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="coluna-cadastro-trial-8">
                                                <Form.Group>
                                                    <Form.Label> <div className="fonte-cor-10 label-campo">País</div> </Form.Label>
                                                    { revelarSelectPais === false &&
                                                        <Button className="campo-texto-cor-4 campo-select-trial" onClick={() => setRevelarSelectPais(!revelarSelectPais)}>
                                                            <div className="fonte-cor-10  campo-texto-select-tamanho-trial">{dsPais}</div>
                                                            <img className={revelarSelectPais?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado-2 nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                                        </Button>
                                                    }
                                                    { revelarSelectPais === true &&
                                                        <SelectGenerico
                                                            css='trial'
                                                            paramRota={'pais'}
                                                            paramCampo1={null}
                                                            paramCampo2={null}
                                                            paramCampo3={null}
                                                            paramCampo4={null}
                                                            setCodigo={setCdPais}
                                                            setDescricao1={setDsPais}
                                                            setDescricao2={setDummy}
                                                            setDescricao3={setDummy}
                                                            setDescricao4={setDummy}
                                                            setRevelarSelect={setRevelarSelectPais}
                                                            placeholder={dsPais}
                                                            sufixo={""}
                                                        >
                                                        </SelectGenerico>
                                                    }
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        {(dsLogradouro != '' && dsNumero != '' && dsBairro != '' && 
                                        dsCidade != '' && dsCep != '' && dsUf != 'Selecione' &&  dsPais != 'Selecione') ?
                                            <div className="container-botao-confirmar">
                                                <Button type="submit" className="botao-confirmar botao-confirmar-trial" variant="success" size="sm">
                                                    <div className="fonte-cor-11">Avançar</div>
                                                </Button>
                                            </div>
                                        :
                                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Preencha o CEP, Logradouro, Número, Bairro, Cidade, UF e País.</Tooltip>}>
                                                <div className="container-botao-confirmar">
                                                    <Button className="botao-confirmar-desabilitado-2" size="sm">
                                                        <div className="fonte-cor-11">Avançar</div>
                                                    </Button>
                                                </div>
                                            </OverlayTrigger>
                                        }
                                       
                                    </Col>
                                </Form.Row>
                            </div>
                        }
                        {avancar === 3 &&
                            <div className="modal-cadastro-trial-1">
                                <Form.Row>
                                    <div className="fonte-cor-10 label-modal-trial-2">VERIFIQUE SEU E-MAIL!</div>
                                    <p className="fonte-cor-10 label-modal-trial-3">Em breve nós o enviaremos uma confirmação para seu e-mail.</p>
                                    <Link className="label-modal-trial-4" to="/login" href="/login">Retornar ao login.</Link>
                                </Form.Row>
                               
                            </div>
                        }
                        { revelarMensagemSucesso &&
                            <div className="componente-modal-overlay">
                                <div className="componente-modal">
                                    <MensagemSucesso
                                        handleMensagemSucesso={handleMensagemSucesso}
                                        mensagemTitulo={"Sucesso"}
                                        mensagemParagrafo1={"Empresa "+ dsRazaoSocial +" "+ phMensagemSucesso +"."}
                                        mensagemBotao={"Continuar"}
                                    />
                                </div>
                            </div>
                        }
                        { revelarMensagemErro &&
                            <div className="componente-modal-overlay">
                                <div className="componente-modal">
                                    <MensagemErro
                                        handleMensagemErro={handleMensagemErro}
                                        mensagemTitulo={"Erro"}
                                        mensagemParagrafo1={mensagemErro}
                                        mensagemBotao={"Retornar"}
                                    />
                                </div>
                            </div>
                        }
                    </Form>
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return { manejador: state.manejadorState.manejador,
             revelarMensagemCabecalho: state.mensagensState.revelarMensagemCabecalhoSelecionada
    };
} 

export default connect(mapStateToProps)(Trial);