//BIBLIOTECAS
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import { Form, Col, Button, ButtonToolbar } from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import {api} from '../../../../conexoes/api'
import {formatarData} from '../../../../utils/formatadorDataTabela'
import {validadorPermissaoElemento} from '../../../../utils/verificadorPermissaoElemento'
//ICONES
import {  editarVerde } from "../../../../utils/alternanciaIcones";
//MENSAGENS
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch,useSelector } from 'react-redux';
import { revelarAlterarAdicionarLogin, visualizarDadosRefresh } from "../../../../redux/actions/index";

import AlterarLogin from '../../alterarLogin/alterarLogin'

export default function InformacoesLogin(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS
    const [itens, setItens] = useState([])
    const [renderizar, setRenderizar] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    // const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro. Por favor tente novamente mais tarde.")
    const [mensagemErro2, setMensagemErro2] = useState("")
    const [nome, setNome] = useState()
    const [sobrenome, setSobrenome] = useState()
    const [cpf, setCpf] = useState()
    const [cnpj, setCnpj] = useState()
    const [email, setEmail] = useState()
    const [telefone, setTelefone] = useState()
    const [cdPerfil, setCdPerfil] = useState()
    const [dsPerfil, setDsPerfil] = useState()
    const [cdUsuario, setCdUsuario] = useState()
    const [envioSelecionado, setEnvioSelecionado] = useState()
    const [carregarUsuario, setCarregarUsuario] = useState(true)
    const [permissaoCarregarUsuario, setPermissaoCarregarUsuario] = useState()
    const despacho = useDispatch();
    const reduxRevelarAlterarAdicionarLogin =  useSelector(state => state.virtueyesState.revelarAlterarAdicionarLogin);
    // const visualizarDadosRefreshVar = useSelector(state => state.virtueyesState.visualizarDadosRefresh);
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);

    useEffect(() => {
        setSpinAtivo(true)
        async function handleCarregarInformacoesLogin(){
            const data = await api.get('m1/consultar/usuario/painel_virtueyes_clientes_usuarios?cd_pav_cliente='+props.cdCliente);
            if(data.data.dados != undefined){
                setItens(data.data.dados)
            }
        }
        handleCarregarInformacoesLogin();
        setSpinAtivo(false)
    }, [renderizar])

    const colunas = [{
        dataField: 'cd_pav_usuario',
        text: 'Cód. Usuário',
        headerClasses: 'nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{cell}</p>;
        }
    },
    {
        dataField: 'ds_pav_usuario',
        text: 'Nome',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1">
                <img className="informacoes-login-usuario-img" src={row.bl_imagem != null ? row.bl_imagem : "https://jazzaero.com.br/wp-content/uploads/2017/05/default-placeholder-profile-icon-avatar-gray-woman-90197997.jpg"} alt="perfil" />{cell}
            </p>;
        }
    },
    {
        dataField: 'ds_email',
        text: 'E-mail',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{cell}</p>;
        }
    },
    {
        dataField: 'ds_telefone',
        text: 'Telefone',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{cell}</p>;
        }
    },
    {
        dataField: 'ds_pav_perfil',
        text: 'Perfil',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{cell}</p>;
        }
    },
    {
        dataField: 'ds_cliente_texto',
        text: 'Cliente',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{cell}</p>;
        }
    },
    {
        dataField: 'ultimo_acesso',
        text: 'Último acesso',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            if(cell){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                    cell = cell[0]
                }
            }
            return formatarData(cell);
        }
    },
    {
        dataField: 'fl_ativo',
        text: 'Status',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return (
                <p className="label-tabela-1">
                    {row.fl_ativo === 1 ? 
                        <p className="status-detalhemento-cliente fonte-cor-2">Ativo</p>
                    : 
                        <p className="status-detalhemento-cliente fonte-cor-3">Desativado</p>
                    }
                </p>
            )
        }
    },
    {
        dataField: '',
        text: 'Ações',
        headerClasses: 'nao-selecionavel',
        classes: 'nao-selecionavel',
        formatter: (cell, row) => {
            return (
                <ButtonToolbar>
                    <Button variant="info" className="botoes-tabela-acoes" size="sm" onClick={() => handleAbrirEdicaoUsuario()}>
                        <img className="icone-botao-acao-tabela" src={editarVerde()} alt="editar-linha"/>
                    </Button>
                </ButtonToolbar>
            )
        }
    }]

    function handleAbrirEdicaoUsuario(){
        despacho(revelarAlterarAdicionarLogin(true))
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setNome(row.ds_pav_usuario)
            setSobrenome(row.ds_pav_usuario_sobrenome)
            setEmail(row.ds_email)
            setTelefone(row.ds_telefone)
            setCdPerfil(row.cd_pav_perfil)
            setDsPerfil(row.ds_pav_perfil)
            setCdUsuario(row.cd_pav_usuario)
            setCpf(row.ds_cpf)
            setCnpj(row.ds_cnpj)
        }
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                setCarregarUsuario(false)
                setEnvioSelecionado(row)
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };
    useEffect(()=>{
        async function handlerPermissoes(){
            setPermissaoCarregarUsuario(await validadorPermissaoElemento(permissaoElemento,"veye/virtueyes/clientes/carregarUsuaruio"))
        }
        handlerPermissoes()
    },[])

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    
    //*  comentado pois o carregar usuario ainda nao esta pronto
    // function handleMensagemSucesso(confirmacao) {
    //     if (revelarMensagemSucesso === false) {
    //         setRevelarMensagemSucesso(true);
    //     } else {
    //         setRevelarMensagemSucesso(false);
    //         despacho(visualizarDadosRefresh(!visualizarDadosRefreshVar));
    //         if (confirmacao === true) {
    //             window.location.reload()
    //             // props.handleAtualizar()
    //         }else{
    //             window.location.reload()
    //             // props.handleAtualizar()
    //         }
    //     }
    // }
    
    return(
        <div className="container-informacoes-login">
            {reduxRevelarAlterarAdicionarLogin === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <AlterarLogin
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            codigo_cliente={props.noCdCliente}
                            nome={nome}
                            sobrenome={sobrenome}
                            email={email}
                            telefone={telefone}
                            cdPerfil={cdPerfil}
                            dsPerfil={dsPerfil}
                            cdUsuario={cdUsuario}
                            cpf={cpf}
                            cnpj={cnpj}
                        />
                    </div>
                </div>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div>}
            <div className="container-informacoes-login-telas">
                <div className="container-informacoes-login-resumo fundo-cor-7">
                    <Form.Row>
                        <Col>
                            <div className="container-tabela-informacoes-login">
                                <BootstrapTable
                                    hover
                                    classes='tabela-transparente-header-fixo'
                                    keyField='cd_pav_usuario'
                                    data={ itens }
                                    columns={ colunas }
                                    rowEvents={eventosLinhas}
                                    selectRow={ selecaoLinhas }
                                    noDataIndication="Nenhum item encontrado"
                                    bootstrap4 = {true}
                                />
                            </div>
                        </Col>
                    </Form.Row>
                </div>
            </div>
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemParagrafo2={mensagemErro2}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {/* {revelarMensagemSucesso && (
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Usuário " + envioSelecionado.ds_pav_usuario + " foi carregado."}
                            mensagemBotao={"OK"}
                        />
                    </div>
                </div>
            )} */}
        </div>
    )
}