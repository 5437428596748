import React, { useState, useEffect } from "react";/*eslint-disable*/
import {api} from '../../conexoes/api'/*eslint-disable*/
import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";
import { setaDropdown } from '../../utils/alternanciaIcones';
import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
//COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltroFaturasCicloVida(props) {

    const [filtroStatusInicial, setFiltroStatusInicial] = useState('')
    const [filtroStatusAtivo, setFiltroStatusAtivo] = useState('')
    const [filtroFatura, setFiltroFatura] = useState('')
    const [filtroAutomacao, setFiltroAutomacao] = useState('')
    const [filtroCancelamento, setFiltroCancelamento] = useState('')
    const [itensEvento, setItensEvento] = useState([])

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []
    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setFiltroStatusInicial(filtros.STATUS_INICIAL)
            setFiltroStatusAtivo(filtros.STATUS_ATIVO)
            setFiltroFatura(filtros.STATUS_FATURA)
            setFiltroAutomacao(filtros.STATUS_AUTOMACAO)
            setFiltroCancelamento(filtros.STATUS_CANCELAMENTO)
        }
    }

    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    useEffect(() => {
        handlerCarregarEventos()
    }, []);

    async function handlerCarregarEventos(){
        await api.get("/m1/consultar/aut_evento")
            .then((response)=>{
                if(response.data.status == 200){
                    setItensEvento(response.data.dados)
                }
            })
    }

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1309) /*fatCicloVidaTabelaDropFiltrosFiltrar*/
        if(filtroStatusInicial !== '' && filtroStatusInicial)arrayFiltrosSimcard.push("Status Inicial: "+filtroStatusInicial+"?STATUS_INICIAL")
        if(filtroStatusAtivo !== '' && filtroStatusAtivo)arrayFiltrosSimcard.push("Status Ativo: "+filtroStatusAtivo+"?STATUS_ATIVO")
        if(filtroFatura !== '' && filtroFatura)arrayFiltrosSimcard.push("Fatura: "+filtroFatura+"?STATUS_FATURA")
        if(filtroAutomacao !== '' && filtroAutomacao)arrayFiltrosSimcard.push("Automação: "+filtroAutomacao+"?STATUS_AUTOMAÇÃO")
        if(filtroCancelamento !== '' && filtroCancelamento)arrayFiltrosSimcard.push("Cancelamento: "+filtroCancelamento+"?STATUS_CANCELAMENTO")
       
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroStatusInicial == '') verificador.push(1)
            if(filtroStatusAtivo == "") verificador.push('')
            if(filtroFatura == "") verificador.push('')
            if(filtroAutomacao == "") verificador.push('')
            if(filtroCancelamento == "") verificador.push('')
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 5) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroStatusInicial("")
            setFiltroStatusAtivo("")
            setFiltroFatura("")
            setFiltroAutomacao("")
            setFiltroCancelamento("")
            props.setFoiLimpado(false)
        }
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroStatusAtivo,filtroStatusInicial,filtroFatura, filtroAutomacao, filtroCancelamento,props.foiLimpado]);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }
    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
          
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-status-inicial","imagem-filtro-status-inicial"); ColetaClickstream(1304) /*fatCicloVidaTabelaDropFiltrosStatusInicial*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Status inicial </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-status-inicial"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-status-inicial"} className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatusInicial("Sim")}>Sim
                            <input defaultChecked={handlerCheck("STATUS_INICIAL","Sim")} type="radio" id="STATUS_INICIAL_SIM" name="STATUS_INICIAL" value="Sim"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatusInicial("Não")}>Não
                            <input defaultChecked={handlerCheck("STATUS_INICIAL","Não")} type="radio" id="STATUS_INICIAL_NAO" name="STATUS_INICIAL" value="Não"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                     {/* ------------------- */}
                     <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-status-ativo","imagem-filtro-status-ativo"); ColetaClickstream(1305) /*fatCicloVidaTabelaDropFiltrosStatusAtivo*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Status ativo </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-status-ativo"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-status-ativo"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatusAtivo("sim")}>Sim
                            <input defaultChecked={handlerCheck("STATUS_ATIVO","Sim")} type="radio" id="STATUS_ATIVO_SIM" name="STATUS_ATIVO" value="Sim"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatusAtivo("Não")}>Não
                            <input defaultChecked={handlerCheck("STATUS_ATIVO","Não")} type="radio" id="STATUS_ATIVO_NAO" name="STATUS_ATIVO" value="Não"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-fatura","imagem-filtro-fatura"); ColetaClickstream(1306) /*fatCicloVidaTabelaDropFiltrosFatura*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Fatura</Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-fatura"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-fatura"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros" onChange={() => setFiltroFatura("Sim")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Sim</div>
                            <input defaultChecked={handlerCheck("STATUS_FATURA","Sim")} type="radio" id="STATUS_FATURA_SIM" name="STATUS_FATURA" value="Sim"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroFatura("Não")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Não</div>
                            <input defaultChecked={handlerCheck("STATUS_FATURA","Não")} type="radio" id="STATUS_FATURA_NAO" name="STATUS_FATURA" value="Não"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-automacao","imagem-filtro-automacao"); ColetaClickstream(1307) /*fatCicloVidaTabelaDropFiltrosAutomacao*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Automação</Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-automacao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-automacao"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros" onChange={() => setFiltroAutomacao("Sim")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Sim</div>
                            <input defaultChecked={handlerCheck("STATUS_AUTOMACAO","Sim")} type="radio" id="STATUS_AUTOMACAO_SIM" name="STATUS_AUTOMACAO" value="Sim"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroAutomacao("Não")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Não</div>
                            <input defaultChecked={handlerCheck("STATUS_AUTOMACAO","Não")} type="radio" id="STATUS_AUTOMACAO_NAO" name="STATUS_AUTOMACAO" value="Não"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-cancelamento","imagem-filtro-cancelamento"); ColetaClickstream(1308) /*fatCicloVidaTabelaDropFiltrosCancelamento*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Cancelamento</Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-cancelamento"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-cancelamento"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros" onChange={() => setFiltroCancelamento("Sim")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Sim</div>
                            <input defaultChecked={handlerCheck("STATUS_CANCELAMENTO","Sim")} type="radio" id="STATUS_CANCELAMENTO_SIM" name="STATUS_CANCELAMENTO" value="Sim"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroCancelamento("Não")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Não</div>
                            <input defaultChecked={handlerCheck("STATUS_CANCELAMENTO","Não")} type="radio" id="STATUS_CANCELAMENTO_NAO" name="STATUS_CANCELAMENTO" value="Não"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                    </div>
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}