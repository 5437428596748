import React from 'react';
import { Button, Form, Col } from 'react-bootstrap';
import { elipseVerde, fecharJanela } from '../../../../utils/alternanciaIcones';

const LabelJanela = ({tituloModal, alterarCicloDeVida}) => {
    const tituloClass = alterarCicloDeVida ? 'label-janela-alteracao-ciclo' : 'label-janela'

    return (
        <div className={`fonte-cor-1 ${tituloClass}`}>{tituloModal}</div>
    )
}

function CloseModalButton({ handleCancelar }) {
    return (
        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
        </Button>
    )
}

export function FatModalHeader({
    type=false,
    tituloModal,
    clientePermitido,
    podeHabilitarSwitch,
    revelarSelecaoCmp,
    setRevelarSelecaoCmp,
    handleCancelar
}) {
    const alterarCicloDeVida = type === 'alterarCicloDeVida';
    const divisorClass = `fonte-cor-1 ${alterarCicloDeVida ? 'properties-alteracao-ciclo' : 'properties'}`;
    const labelIntegracao = alterarCicloDeVida ? 'label-integracao-alteracao-ciclo' : 'label-integracao';
    let elipseClass = "icone-label-janela nao-selecionavel";
    let textoManualClass = 'label-cmp';
    
    const corTexto = podeHabilitarSwitch ? 'fonte-cor-1' : 'fonte-cor-19'

    if(alterarCicloDeVida) {
        if(clientePermitido) {
            elipseClass = "icone-label-janela-alteracao-ciclo nao-selecionavel";
        }

        textoManualClass = 'label-cmp-alteracao-ciclo'
    }

    return (
        <Form.Row>
            <Col>
                <img className={elipseClass} src={elipseVerde()} alt="elipse" />

                <Form.Label>
                {clientePermitido ?
                    <Form.Row> 
                    <Col>
                        <LabelJanela tituloModal={tituloModal} alterarCicloDeVida={alterarCicloDeVida} />
                        <div className={divisorClass}>|</div>                          
                        <div className={`${corTexto} ${textoManualClass}`}>Manual</div>
                    </Col>
                    <Col>
                        {podeHabilitarSwitch ?  // checkbox ativo
                            <div className="flipswitch-grupo">
                                <input checked={revelarSelecaoCmp} id="switchIgnorarLinhas" type="checkbox" name="flipswitch" className="flipswitch-cmp" onChange={(e) => setRevelarSelecaoCmp(e.target.checked)} />
                                <label htmlFor="switchIgnorarLinhas" className="flipswitch-label">
                                    <div className={revelarSelecaoCmp ? "flipswitch-cmp flipswitch-cor-1-cmp-habilitado" : "flipswitch-cmp flipswitch-cor-1-cmp"}></div>
                                    <div className="flipswitch-switch"></div>
                                </label>
                            </div>
                            : // checkbox desabilitado
                            <div className="flipswitch-grupo">
                                <input type="checkbox" className="flipswitch-cmp" disabled />
                                <label htmlFor="switchIgnorarLinhas" className="flipswitch-label">
                                    <div className={"flipswitch-cmp flipswitch-cor-1-cmp-desabilitado"}></div>
                                    <div className="flipswitch-switch"></div>
                                </label>
                            </div>
                            }
                    </Col>
                    <div className={`${corTexto} ${labelIntegracao}`}>
                        Integração Operadora
                    </div>                              
                    </Form.Row>
                    : 
                        <LabelJanela tituloModal={tituloModal} />
                    }
                </Form.Label>
                
                <CloseModalButton handleCancelar={handleCancelar} />
            </Col>
        </Form.Row>
    )
}