/*eslint-disable*/
import { faShekelSign } from "@fortawesome/free-solid-svg-icons";

const ESTADO_INICIAL = {
    permissaoElemento: [],
    permissaoElementoAcao:false,
    permissaoElementoOutrosConfiguracoesExibirColunas:false,
    permissaoElementoOutrosConfiguracoesConfigurarGrupos:false,
    permissaoElementoOutrosInformacoesConsultarCobertura:false,
    permissaoElementoOutrosMensagensVisualizarMensagens:false,
};

export const breadCrumbs = (state = ESTADO_INICIAL, action) => {
    if (action.type === "BREAD_CRUMB") {
        return {
            ...state,
            breadCrumbsObj: action.parametro
        }
    }
    else {
        return state;
    }
}

export const permissao = (state = ESTADO_INICIAL, action) => {
    if (action.type === "PERMISSAO_ELEMENTO") {
        return {
            ...state,
            permissaoElemento: action.parametro
        }
    }
    else if (action.type === "PERMISSAO_ELEMENTO_ACAO") {
        return {
            ...state,
            permissaoElementoAcao: action.parametro
        }
    }
    else if (action.type === "PERMISSAO_ELEMENTO_OUTROS_CONFIGURACOES_EXIBIR_COLUNAS") {
        return {
            ...state,
            permissaoElementoOutrosConfiguracoesExibirColunas: action.parametro
        }
    }
    else if (action.type === "PERMISSAO_ELEMENTO_OUTROS_CONFIGURACOES_CONFIGURAR_GRUPOS") {
        return {
            ...state,
            permissaoElementoOutrosConfiguracoesConfigurarGrupos: action.parametro
        }
    }
    else if (action.type === "PERMISSAO_ELEMENTO_OUTROS_INFORMACOES_CONSULTAR_COBERTURA") {
        return {
            ...state,
            permissaoElementoOutrosInformacoesConsultarCobertura: action.parametro
        }
    }
    else if (action.type === "PERMISSAO_ELEMENTO_OUTROS_MENSAGENS_VISUALIZAR_MENSAGENS") {
        return {
            ...state,
            permissaoElementoOutrosMensagensVisualizarMensagens: action.parametro
        }
    }
    else {
        return state;
    }
}