//BIBLIOTECAS
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import { Form, Col } from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import {api} from '../../../../conexoes/api'
import {formatarData} from '../../../../utils/formatadorDataTabela'
import {formatarMilhar} from '../../../../utils/formatarMilhar';
//ICONES
import { elipseVerde } from "../../../../utils/alternanciaIcones";
//MENSAGENS
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch } from 'react-redux';


export default function DetalhamentoConta(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS
    const [itens, setItens] = useState([])
    const [renderizar, setRenderizar] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro. Por favor tente novamente mais tarde.")

    useEffect(() => {
        setSpinAtivo(true)
        async function carregarFaturas(){
            const data = await api.get('m1/consultar/fat_faturas/fatura_por_conta?cd_fat_conta='+props.cd_fat_conta);
            if(data.data.dados != undefined){
                setItens(data.data.dados)
            }
            setSpinAtivo(false)
        }
        carregarFaturas();
    }, [renderizar])

    const colunas = [{
        dataField: 'cd_fat_fatura',
        text: 'Cód. Fatura',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{cell}</p>;
        }
    },
    {
        dataField: 'ds_fatura_status',
        text: 'Status',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{cell}</p>;
        }
    }, 
    {
        dataField: 'n2_valor_total',
        text: 'Valor Previsto',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{formatarMilhar(cell)}</p>;
        }
    }, 
    {
        dataField: 'ds_mes_referencia',
        text: 'Referência',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{cell}</p>;
        }
    },
    {
        dataField: 'dt_vencimento',
        text: 'Vencimento',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            if(cell){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                    cell = cell[0]
                }
            }
            return formatarData(cell, true);
        }
    },
    {
        dataField: 'dt_inicio_ciclo',
        text: 'Início do Ciclo',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            if(cell){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                    cell = cell[0]
                }
            }
            return formatarData(cell, true);
        }
    },
    {
        dataField: 'dt_fim_ciclo',
        text: 'Fim do Ciclo',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            if(cell){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                    cell = cell[0]
                }
            }
            return formatarData(cell, true);
        }
    }]

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    
    return(
        <div className="container-informacoes-login">
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div>}
            <div className="container-informacoes-login-telas">
                <div className="container-informacoes-login-resumo fundo-cor-7">
                    <Form.Row>
                        <Col>
                            <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela">Faturas</div>
                            </Form.Label>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <div className="container-tabela-informacoes-login">
                                <BootstrapTable
                                    hover
                                    classes='tabela-transparente-header-fixo'
                                    keyField='cd_fat_conta'
                                    data={ itens }
                                    columns={ colunas }
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                        </Col>
                    </Form.Row>
                </div>
            </div>
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </div>
    )
}