/*eslint-disable*/
import React from "react"

export function formatarMilhar(numero) {
    numero = parseFloat(numero)
    if(numero == 0){
        return '0,00'
    }
    if(numero && !isNaN(numero)){
        var numero = numero.toFixed(2).split('.');
        numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }
}

export function formatarMilharSemDecimais(numero) {
    if(numero == 0){
        return '0'
    }
    if(numero && !isNaN(numero)){
        var numero = numero.toFixed(0).split('.');
        numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }
}