//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';
import {api} from '../../../conexoes/api';
import {formatarData} from '../../../utils/formatadorDataTabela'
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
//COMPONENTES 
import CadastroGit from './cadastro'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, limpouBusca, limpadorFiltros, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro, desabilitarAcoes, revelarNovoRelease } from "../../../redux/actions/index";
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelAdministrativo/pavGit/pavGit.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function Grid(props){
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    /*eslint-disable*/
    const [valorLimite, setValorLimite] = useState(50);
    //HOOKS VARIÁVEIS
    /*eslint-disable*/
    const [NomeTag, setNomeTag] = useState('');
    const [MensagemTag, setMensagemTag] = useState('');
    const [TargetTag, setTargetTag] = useState('');
    const [ReleaseTag, setReleaseTag] = useState('');
    const [CommitTag, setCommitTag] = useState('');
    const [flAtivo, setFlAtivo] = useState(0);
    const [isSelect, setIsSelect] = useState(false);
    //HOOKS MENESAGENS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    //HOOKS MODAL
    const [revelarCadastro, setRevelarCadastro] = useState(false)
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let NovoRelease =  useSelector(state => state.manejadorState.revelarNovoRelease);

    let temFiltros = false;

    let itensVar = []
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(124) // 'pavGitTabelaGrid'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("release"))
        despacho(desabilitarAcoes(false));

        async function carregarTabela(){
             //clicou no botão de limpar a busca geral e retorna tudo ao estado original
             if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }   

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }   


            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const { data } = await api.get('/m1/consultar/git/release');
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/git", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/git/release');

                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/git/release');
            }else{
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    contar: true
                }
                apiData = await api.post("/m1/filtrar/git", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados.length / valorLimite)
                totalItens = apiData.data.dados.length;
                for( var i = 0 ; i < apiData.data.dados.length; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    const colunas = [{
        dataField: 'name',
        text: 'Nome da Release',
        sort: true,
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        formatter: (cell, row) => {
            if(!cell){
                return <p className="fonte-cor-1 label-tabela-1">-</p>
            }else{
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        }
    },
    {
        dataField: 'tag_name',
        text: 'Nome da Tag',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            if(!cell){
                return <p className="fonte-cor-1 label-tabela-1">-</p>
            }else{
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        }
    },
    {
        dataField: 'description',
        text: 'Descrição',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            if(!cell){
                return <p className="fonte-cor-1 label-tabela-1">-</p>
            }else{
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        }
    },
    {
        dataField: 'projeto',
        text: 'Projeto',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            if(!cell){
                return <p className="fonte-cor-1 label-tabela-1">-</p>
            }else{
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        }
    },
    {
        dataField: 'created_at',
        text: 'Data de criação',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            if(!cell){
                return <p className="fonte-cor-1 label-tabela-1">-</p>
            }else{
                return <p className="fonte-cor-1 label-tabela-1">{formatarData(cell)}</p>;
            }
        }
    },
    {
        dataField: 'assets',
        text: 'Assets',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            if(!cell){
                return <p className="fonte-cor-1 label-tabela-1">-</p>
            }else{
                return <p className="fonte-cor-1 label-tabela-1">{JSON.stringify(cell)}</p>;
            }
        }
    },
    {
        dataField: 'author',
        text: 'Autor',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            if(!cell){
                return <p className="fonte-cor-1 label-tabela-1">-</p>
            }else{
                return <p className="fonte-cor-1 label-tabela-1">{JSON.stringify(cell)}</p>;
            }
        }
    },
    {
        dataField: 'commit',
        text: 'Commit Json',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            if(!cell){
                return <p className="fonte-cor-1 label-tabela-1">-</p>
            }else{
                return <p className="fonte-cor-1 label-tabela-1">{JSON.stringify(cell)}</p>;
            }
        }
    }]

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            setIsSelect(isSelect)
            if(isSelect){
                setNomeTag(row.name);
                setMensagemTag(row.message);
                setTargetTag(row.target);
                setReleaseTag(row.release);
                setCommitTag(row.commit);
                despacho(desabilitarAcoes(false));
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id="TAG" name="TAG"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };
    
    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setNomeTag(row.name);
            setMensagemTag(row.message);
            setTargetTag(row.target);
            setReleaseTag(row.release);
            setCommitTag(row.commit);
            setFlAtivo(row.fl_ativo)
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    

    function colunaAcoes(){
        ColetaClickstream(105) // 'colunaAcoes'
        return(
            <p className="acoes">Ações</p>
        );
    }

    function handleCancelar(){
        ColetaClickstream(98) // 'handleCancelar'
        setRevelarMensagemAtencao(false)
        setRevelarMensagemErro(false)
        setRevelarMensagemSucesso(false)
        setRevelarCadastro(!revelarCadastro)
        revelarNovoRelease(false)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
        }
        if(confirmacao === true){
            setRenderizar(!renderizar)
        }
    }

    return(
        <>
        <BarraDadosCliente/>
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
            { NovoRelease === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroGit
                            tituloJanela={'Cadastrar'}
                            setRevelarCadastro={setRevelarCadastro}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        >
                        </CadastroGit>
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='name' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"Usuário deletado."}
                    mensagemBotao={"Continuar"}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
        </>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(Grid));