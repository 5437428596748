//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';
import {api} from '../../../conexoes/api';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import {formatarData} from '../../../utils/formatadorDataTabela'
//IMPORTAÇÕES ICONES
import { passarPaguinaDireita, passarPaguinaEsquerda } from '../../../utils/alternanciaIcones'
import {formatarMilhar} from '../../../utils/formatarMilhar'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { limpadorFiltros, mudarFiltros, mudarManejador, desabilitarAcoes, desabilitarAcoesCliente, limpouBusca, realizouBusca, revelarBarraFiltragem, realizouFiltro } from "../../../redux/actions/index";
//IMPORTAÇÕES COMPONENTES
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import ProcessarPedido from './processarPedido'
import ConcluirPedido from './concluirPedido'
import CancelarPedido from './cancelarPedido'
import NovoPedido from './novoPedido'
//ESTILIZAÇÃO
import '../../../css/painelVirtueyes/pedidoSms/pedidoSms.css'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let pedidosSelecionados = [];

function TabelaPedidosSms(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    /*eslint-disable*/
    const [valorLimite, setValorLimite] = useState(50);
    const [noCdCliente, setNoCdCliente] = useState(-1);
    const [cdCliente, setCdCliente] = useState(-1);
    const [dsCliente, setDsCliente] = useState('NULL');
    const [mensagemErro, setMensagemErro] = useState('')
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    let itensVar = []
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let revelarProcessarPedido =  useSelector(state => state.virtueyesState.revelarProcessarPedido);
    let revelarConcluirPedido =  useSelector(state => state.virtueyesState.revelarConcluirPedido);
    let revelarCancelarPedido =  useSelector(state => state.virtueyesState.revelarCancelarPedido);
    let novoPedido = useSelector(state => state.virtueyesState.revelarNovoPedidoSmsVirtueyes);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let temFiltros = false;
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);

    const despacho = useDispatch();
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(397) // 'pedidoSmsTabelaPedidoSms'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio' || filtros.vazio === undefined) {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(desabilitarAcoes(true));
        // despacho(desabilitarAcoesCliente(false));
        despacho(mudarManejador("tabelaPedidosSms"));
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            } 

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/sms_pedido?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    filtros: filtros
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/sms_pedido", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/sms_pedido?paginaAtual='+contador+'&carregarLimit='+valorLimite);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, filtros])

    const colunas = [{
            dataField: 'CODIGO_CLIENTE',
            headerClasses: 'nao-selecionavel',
            text: 'Cód. cliente',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'RAZAO_SOCIAL',
            headerClasses: 'nao-selecionavel',
            text: 'Razão social',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'NOME_FANTASIA',
            headerClasses: 'nao-selecionavel',
            text: 'Nome fantasia',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'USUARIO_EMAIL',
            headerClasses: 'nao-selecionavel',
            text: 'Usuário solicitante',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'NUMERO_PEDIDO',
            headerClasses: 'nao-selecionavel',
            text: 'ID do pedido',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'PEDIDO_STATUS',
            headerClasses: 'nao-selecionavel',
            text: 'Status',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'CONTA',
            headerClasses: 'nao-selecionavel',
            text: 'Conta',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'QTD_SMS',
            headerClasses: 'nao-selecionavel',
            text: 'Quantidade de SMS',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'VALOR',
            headerClasses: 'nao-selecionavel',
            text: 'Valor',
            sort: true,
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <div className="fonte-cor-1 label-tabela-1 container-valor-pedido"> {(!valor || isNaN(valor)) ? <><p className="valor-rs-pedido">R$</p> <p className="fonte-cor-1 valor-pedido"> 0.00 </p></> : <><p className="valor-rs-pedido">R$</p> <p className="fonte-cor-1 valor-pedido"> {formatarMilhar(valor)}</p></>} </div>;
            }
        },
        {
            dataField: 'DATA_PEDIDO',
            headerClasses: 'nao-selecionavel',
            text: 'Data do pedido',
            sort: true,
            formatter: (cell, row) => {
                if(cell){
                    cell = cell.replace('T', ' ').split('.')
                    if(cell.length > 0){
                        cell = cell[0]
                    }
                }
                return formatarData(cell);
            }
        }
    ]


    const selecaoLinhas = {
        mode: 'checkbox' ,
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionarPedido(row.NUMERO_PEDIDO)
            }else{
                handleDesselecionarPedido(row.NUMERO_PEDIDO)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if(isSelect){
                handleSelecionarTodosPedidos()
            }else{
                handleDesselecionarTodosPedidos()
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-simcard" { ...rest }/>
                    <label class="label-checkbox-simcard"></label>
                </>
            )
        },
        selectionHeaderRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-header-simcard" { ...rest }/>
                    <label class="label-checkbox-header-simcard"></label>
                </>
            )
        },
        bgColor: 'row-index-bigger-than-2101'
    };

    function handleSelecionarTodosPedidos(){
        ColetaClickstream(402) // 'pedidoSmsTabelaPedidoSmsHandleSelecionarTodosPedidos'
        itens.map((item,i)=>{
            if(itens[i].NUMERO_PEDIDO){
                pedidosSelecionados.push(itens[i]);
            }
        })
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
    }

    function handleDesselecionarTodosPedidos(){
        ColetaClickstream(403) // 'pedidoSmsTabelaPedidoSmsHandleDesselecionarTodosPedidos'
        pedidosSelecionados = []
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(false));
    }

    function handleSelecionarPedido(pedido){
        ColetaClickstream(404) // 'pedidoSmsTabelaPedidoSmsHandleSelecionarPedido'
        for (let i = 0; i < itens.length; i++) {
            if (itens[i].NUMERO_PEDIDO === pedido){
                pedidosSelecionados.push(itens[i]);
                break;
            }
        }
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
    }

    function handleDesselecionarPedido(pedido){
        ColetaClickstream(405) // 'pedidoSmsTabelaPedidoSmsHandleDesselecionarPedido'
        for (let i = 0; i < pedidosSelecionados.length; i++) {
            if (pedidosSelecionados[i].NUMERO_PEDIDO === pedido){
                pedidosSelecionados.splice(i, 1);
                break;
            }
        }
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(false));
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setNoCdCliente(row.n0_codigo_cliente);
            setCdCliente(row.codigo);
            setDsCliente(row.cliente);
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function paginaPrimeira ({page, onPageChange}){
        onPageChange(1);
    }

    function paginaAnteriorDuas ({page, onPageChange}){
        if(paginaAtual !== 1){
            onPageChange(page - 2);
        }
    }

    function paginaAnterior ({page, onPageChange}){
        if(paginaAtual !== 1){
            onPageChange(page - 1);
        }
    }

    function paginaSeguinte ({page, onPageChange}) {
        if(totalPaginas !== paginaAtual){
            onPageChange(page + 1);
        }
    }

    function paginaSeguinteDuas ({page, onPageChange}){
        if(totalPaginas !== paginaAtual){
            onPageChange(page + 2);
        }
    }

    function paginaPenultima ({page, onPageChange}){
        onPageChange(totalPaginas - 1)
    }

    function paginaUltima ({page, onPageChange}){
        onPageChange(totalPaginas)
    }

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }

    async function contarItens(){
        let arrayItens = []
        let apiData = [];
        if (controladorPagina <= paginaAtual && filtros.vazio) {
            apiData = await api.get('/m1/consultar/sms_pedido?contar=true&buscar='+conteudoBusca);
        }else{
            const dados = {
                paguinaAtual: contador,
                carregarLimit: valorLimite,
                buscar:conteudoBusca,
                filtros: filtros,
                contar: true
            }
            apiData = await api.post("/m1/filtrar/sms_pedido", dados);
        }
        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        pedidosSelecionados = [];
        setPaginaAtual(1)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    return(
        <div className='container-tela'>
            <BarraDadosCliente/>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={() =>  setRevelarMensagemSucesso(!revelarMensagemSucesso)}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"O cliente "+dsCliente+","}
                            mensagemParagrafo2={"cód. "+cdCliente+" foi carregado."}
                            mensagemBotao={"OK"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Ocorreu um erro. Tente novamente mais tarde."}
                            mensagemParagrafo2={"Caso o erro persista, entre em contato com o Suporte."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {novoPedido === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <NovoPedido
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            itensSelecionados={pedidosSelecionados}
                        />
                    </div>
                </div>
            }
            {revelarProcessarPedido === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <ProcessarPedido
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            itensSelecionados={pedidosSelecionados}
                        />
                    </div>
                </div>
            }
            {revelarConcluirPedido === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <ConcluirPedido
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            itensSelecionados={pedidosSelecionados}
                        />
                    </div>
                </div>
            }
            {revelarCancelarPedido === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CancelarPedido
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            itensSelecionados={pedidosSelecionados}
                        />
                    </div>
                </div>
            }
            {revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='NUMERO_PEDIDO' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={selecaoLinhas}
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                           <div className="grupo-botao-paginacao-tabela" >
                                <button className="botao-paginacao-primeira-ultima-tabela" onClick={ () => paginaPrimeira(paginationProps)}>
                                    Primeira
                                </button>
                                <div className="botao-setas-paginacao-seta-tabela">
                                    <button className="botao-paginacao-seta-tabela" onClick={ () => paginaAnterior(paginationProps)}>
                                        <img  src={passarPaguinaEsquerda()} alt="proxima paguina"/>
                                    </button>
                                    {botoesPaginacaoGrande(paginationProps)}
                                    <button className="botao-paginacao-seta-tabela" onClick={ () => paginaSeguinte(paginationProps)}>
                                        <img  src={passarPaguinaDireita()} alt="proxima paguina"/>
                                    </button>
                                </div>
                                <button className="botao-paginacao-primeira-ultima-tabela" onClick={ () => paginaUltima(paginationProps)}>
                                    Última
                                </button>
                            </div>
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
        </div>
    )
    function botoesPaginacaoGrande(paginationProps){
        if(totalItens > (valorLimite * 2)){
            return (
                <>
                    {paginaAtual === totalPaginas &&
                        <button className="botao-paginacao-tabela" onClick={ () => paginaAnteriorDuas(paginationProps) }>
                            <a className="fonte-cor-1">
                                { paginaAtual - 2 }
                            </a>
                        </button> 
                    }
                    {paginaAtual >=2 &&
                        <button className="botao-paginacao-tabela" onClick={ () => paginaAnterior(paginationProps) }>
                            <a className="fonte-cor-1">
                                { paginaAtual - 1 }
                            </a>
                        </button>
                    }
                    <button className="botao-paginacao-tabela" >
                        <a className="fonte-cor-1">
                            { paginaAtual }
                        </a>
                    </button>
                    {paginaAtual !== totalPaginas &&
                        <button className="botao-paginacao-tabela" onClick={ () => setPaginaAtual(paginaAtual + 1) }>
                            <a className="fonte-cor-1">
                                { paginaAtual + 1 }
                            </a>
                        </button> 
                    }
                    {paginaAtual <=1 && totalPaginas > 3 &&
                        <button className="botao-paginacao-tabela" onClick={ () => paginaSeguinteDuas(paginationProps) }>
                            <a className="fonte-cor-1">
                                { paginaAtual + 2 }
                            </a>
                        </button>
                    }
                    {paginaAtual !== totalPaginas && paginaAtual !== totalPaginas-1 && paginaAtual !== totalPaginas-2 && paginaAtual !== totalPaginas-3 &&
                        <button className="botao-paginacao-tabela">
                            <a className="fonte-cor-1">
                                . . .
                            </a>
                        </button> 
                    }
                    { paginaAtual !== totalPaginas && paginaAtual !== totalPaginas -1 && paginaAtual !== totalPaginas -2 &&
                        <button className="botao-paginacao-tabela" onClick={ () => paginaPenultima(paginationProps) }>
                            <a className="fonte-cor-1">
                                { totalPaginas - 1 }
                            </a>
                        </button> 
                    }
                    { paginaAtual !== totalPaginas && paginaAtual !== totalPaginas -1 &&
                        <button className="botao-paginacao-tabela" onClick={ () => paginaUltima(paginationProps) }>
                            <a className="fonte-cor-1">
                                { totalPaginas }
                            </a>
                        </button>
                    }
                </>
            )
        }
        else{
            return (
                <>
                    {paginaAtual >=2 &&
                        <button className="botao-paginacao-tabela" onClick={ () => paginaAnterior(paginationProps) }>
                            <a className="fonte-cor-1">
                                { paginaAtual - 1 }
                            </a>
                        </button>
                    }
                    <button className="botao-paginacao-tabela" >
                        <a className="fonte-cor-1">
                            { paginaAtual }
                        </a>
                    </button>
                    {paginaAtual < 2 && totalPaginas > 1 &&
                        <button className="botao-paginacao-tabela" onClick={ () => paginaSeguinte(paginationProps) }>
                            <a className="fonte-cor-1">
                                { paginaAtual + 1 }
                            </a>
                        </button>
                    }
                </>
            )
        }
    }
}

function mapStateToProps(state) {
    return { 
        conteudoBusca: state.manejadorState.conteudoBusca, 
        filtros: state.filtrosState.filtros, 
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaPedidosSms));