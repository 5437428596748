//BIBLIOTECAS
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import { Form, Col } from 'react-bootstrap';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import { Label, Cell, Tooltip, Pie, PieChart } from 'recharts';
import BootstrapTable from 'react-bootstrap-table-next';
import {formatarMilhar} from '../../../utils/formatarMilhar'
//ICONES
import { elipseVerde } from '../../../utils/alternanciaIcones';
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

export default function DetalhamentoPlanosFaturas(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS
    const [itens, setItens] = useState('')
    const localTema = localStorage.getItem('tema');
    const [legendaGrafico, setLegendaGrafico] = useState();
    const [valorTotalGrafico, setValorTotalGrafico] = useState(0);
    const [valoresGrafico, setValoresGrafico] = useState();

    const arrayCores = [
        '#36D896', '#FDCB62', '#FC5F0E', '#CD2D37', '#1F8EFA','#FF5F58', '#640EDF', '#1FDFD9', '#B947DB', '#F02E7A',
        '#36D896', '#FDCB62', '#FC5F0E', '#CD2D37', '#1F8EFA','#FF5F58', '#640EDF', '#1FDFD9', '#B947DB', '#F02E7A',
        '#36D896', '#FDCB62', '#FC5F0E', '#CD2D37', '#1F8EFA','#FF5F58', '#640EDF', '#1FDFD9', '#B947DB', '#F02E7A'
    ];

    useEffect(() => {
        async function handleCarregarDetalhamento(){
            setSpinAtivo(true)
            let arrayValoresGrafico = [];
            let porcentagem = [];
            let detalhamentoGrafico=[]
            const resultadoBuscaServicos = await api.get('/m1/consultar/fat_plano/servico_por_plano?cd_fat_plano='+props.cdPlano);

            setItens(resultadoBuscaServicos.data.dados)
            detalhamentoGrafico = resultadoBuscaServicos.data.dados

            resultadoBuscaServicos.data.dados.map((item)=>{
                arrayValoresGrafico.push({name: item.ds_fat_servico, value: item.n2_valor})
            },)

            setValoresGrafico(arrayValoresGrafico);

            // calcula o valor total dos valores vindo do banco
            let calcuValorTotal = 0;

            arrayValoresGrafico.map((item)=>{
                calcuValorTotal = (calcuValorTotal + item.value);
            })

            setValorTotalGrafico(calcuValorTotal);

            // mapeia os valores vindo e divide pelo valor total e calcula a % de cada valor dentro do valor total para criar o grafico de %
            resultadoBuscaServicos.data.dados.map((item)=>{
                porcentagem.push({percentual:((item.n2_valor / calcuValorTotal) * 100).toFixed(2), cor: "background-color:" + arrayCores[porcentagem.length], nome: item.ds_fat_servico})
            })

            // MAP QUE MONTA A LEGENDA
            let objetoGrupos = porcentagem.map((item) => {
                return (
                    <Form.Row className="form-row-sem-margem">
                        <Col className="form-row-sem-margem">
                            <div className="grupo-legenda-percentual-gastos-1">
                                <div className="coluna-legenda-percentual-gastos-1">
                                    <div className="esfera-legenda-percentual-gastos" Style={item.cor}/>
                                </div>
                                <div className="coluna-legenda-percentual-gastos-2">
                                    <span className="nome-legenda-percentual-gastos fonte-cor-1">
                                        {item.nome}
                                    </span>
                                </div>
                                <div className="coluna-legenda-percentual-gastos-3">
                                    <span className="porcentagem-legenda-percentual-gastos fonte-cor-1">
                                        {item.percentual}%
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                );
            })
            setLegendaGrafico(objetoGrupos)
            setSpinAtivo(false)
        }
        handleCarregarDetalhamento();
    }, [])
    
    //FUNÇÃO QUE MUDA A COR DO TEXTO DO GRAFICO, O COMPONENTE NAO ACEITA CLASSNAME ENTAO TEM QUE SER FEITO ATRAVEZ DE UMA FUNÇÃO.
    function CustomLabel({viewBox, total, valorSoma}){
        const {cx} = viewBox;
        return (
            <>
                <text x={cx} y={70} fill={localTema =='claro'?"#000000":'#FFFFFF'} textAnchor="middle" dominantBaseline="central">
                    <tspan className="texto-total-grafico" alignmentBaseline="middle">{total}</tspan>
                </text>
                <text className="container-texto-soma-total" x={cx} y={90} fill={localTema =='claro'?"#000000":'#FFFFFF'} textAnchor="middle" dominantBaseline="central">
                    <tspan className="texto-soma-total">{"R$ " + valorSoma}</tspan>
                </text>
            </>
        )
    } 


    const colunas = [{
            dataField: 'cd_fat_plano_servico',
            text: 'Cód. serviço',
            hidden: true,
            headerClasses: 'nao-selecionavel',
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent',width: '130px',}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_fat_servico',
            text: 'Serviço',
            sort: true,
            headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent',width: '130px',}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_categoria',
            headerClasses: 'nao-selecionavel',
            text: 'Categoria',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent', width: '130px'}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_unidade',
            headerClasses: 'nao-selecionavel',
            text: 'Unidade',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent', width: '130px'}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_quantidade',
            headerClasses: 'nao-selecionavel',
            text: 'Quantidade',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent', width: '130px'}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_tipo',
            headerClasses: 'nao-selecionavel',
            text: 'Tipo',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent', width: '128px'}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'n2_valor',
            headerClasses: 'nao-selecionavel',
            text: 'Valor',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent', width: '128px'}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">R$ {cell?formatarMilhar(cell):'-'}</p>;
            }
        },
        {
            dataField: 'fl_status',
            headerClasses: 'nao-selecionavel',
            text: 'Status',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent', width: '128px'}
            },
            formatter: (cell, row) => {
                return (
                    <div className="container-switch-detalhamento-planos-faturas">
                        <div class="flipswitch-planos-faturas">
                            <input id={'_0_'+row.cd_fat_plano_servico+'_switch'} defaultChecked={parseInt(cell)} type="checkbox" name="flipswitch" class="flipswitch-cb" onChange={(e) => alterarStatusServico(e.target.checked, row.cd_fat_plano_servico, row.cd_fat_servico)}/>
                            <label class="flipswitch-label" for={'_0_'+row.cd_fat_plano_servico+'_switch'}>
                                <div class="flipswitch-inner flipswitch-cor-3"></div>
                                <div class="flipswitch-switch"></div>
                            </label>
                        </div>
                    </div>
                )
            }
        },
    ]

    async function alterarStatusServico(valor, codigoRelacao, codigoServico){
        let statusParaEnvio
        if(valor === true){
            statusParaEnvio = '1'
        }else{
            statusParaEnvio = '0'
        }
        
        setSpinAtivo(true)
        
        await api.post('/m1/alterar/fat_plano/servico_por_plano', {
            "dados":{
                'fl_status' : statusParaEnvio
            },
            "condicao":{
                'cd_fat_plano' : props.cdPlano,	
                'cd_fat_servico' : codigoServico,	
                'cd_fat_plano_servico' : codigoRelacao,	
            }
        })
        .then(function (response) {
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
        });
    }

    const TooltipGraficoPizzaConsumo = ({ active, payload }) => {
        if (active && payload!==null && payload.length > 0) {
            return (
                <div className="container-tooltip-grafico-pizza">
                    <p className="label-tooltip-grafico-pizza">R$ {payload[0].value?formatarMilhar(payload[0].value):'-'}</p>
                </div>
            );
        }
        return null;
    }
    
    return(
        <>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="triangulo-detalhamento-planos-faturas campo-select-triangulo-cor-2"></div>
            <div className="container-itens-expandido">
                <div className="resumo-fatura fundo-cor-7">
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                            <img className="icone-label-filtro-financeiro" src={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela-financeiro"> Resumo dos serviços </div>
                            </Form.Label>
                        </Col>
                    </Form.Row>
                    <div className="container-conteudo-resumo-faturas">
                        <div>
                        <BootstrapTable
                            classes="tabela-transparente-header-fixo"
                            condensed={true}
                            keyField='cd_fat_plano_servico'
                            data={itens}
                            columns={colunas}
                            bordered={false} 
                        />
                        </div>  
                    </div>
                </div>
                <div className="percentual-gastos fundo-cor-7">
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                            <img className="icone-label-filtro-financeiro" src={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela-financeiro"> Percentual de gastos </div>
                            </Form.Label>
                        </Col>
                    </Form.Row> 
                    <Form.Row className="form-row-sem-margem">
                        <Col className="form-row-sem-margem">
                            <div className="container-percentual-gastos">
                                <div className="container-conteudo-Percentual-gastos">
                                    {valoresGrafico &&
                                        <PieChart width={200} height={160}  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                            <Pie
                                                data={valoresGrafico} 
                                                cx={90}
                                                cy={80} 
                                                innerRadius={55}
                                                outerRadius={77} 
                                                fill="#505050"
                                                paddingAngle={0}
                                                stroke={0}
                                            >
                                            <Label content={<CustomLabel total={'Total'} valorSoma={valorTotalGrafico?formatarMilhar(valorTotalGrafico):'-'}/>} value="Total" position="centerBottom" />
                                            {
                                                valoresGrafico.map((entry, index) => <Cell fill={arrayCores[index % arrayCores.length]}/>)
                                            }
                                            </Pie>
                                            <Tooltip content={<TooltipGraficoPizzaConsumo/>}/>
                                        </PieChart>
                                    }
                                </div>
                                <div className="container-legenda-percentual-gastos">
                                    {legendaGrafico}
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                </div>
            </div>
        </>
    )
}