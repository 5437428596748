import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Button, Col } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
//COMPONENTES
import SelectTipoSolicitacao from '../../components/componentesModais/selects/SelectTipoSolicitacao'
import Calendario from '../../components/calendario/calendario'
import moment from 'moment';
import {api} from '../../conexoes/api'
import FiltroArquivo from './filtroArquivo';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem, maximoTagsExibidas} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltrosVirtueyesCliente(props) {
    const [revelarSelectSolicitacaoTipo, setRevelarSelectSolicitacaoTipo] = useState(false)
    const [DsSolicitacaoTipo, setDsSolicitacaoTipo] = useState("Selecione")
    const [cdSolicitacaoTipo, setCdSolicitacaoTipo] = useState()
    const [filtroStatus , setFiltroStatus] = useState('')
    const [filtroCriacaoDataInicio , setFiltroCriacaoDataInicio] = useState('')
    const [filtroCriacaoDataFinal , setFiltroCriacaoDataFinal] = useState('')
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())
    const [renderizarFiltroArquivo, setRenderizarFiltroArquivo] = useState(false)

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    async function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            if(filtros.STATUS_SOLICITACAO == '1') setFiltroStatus("Aberta")
            if(filtros.STATUS_SOLICITACAO == '2') setFiltroStatus("Concluída")
            if(filtros.STATUS_SOLICITACAO == '3') setFiltroStatus("Cancelada")
            if(filtros.STATUS_SOLICITACAO == '4') setFiltroStatus("Parcial")
            setCdSolicitacaoTipo(filtros.TIPO_SOLICITACAO)

            if(filtros.TIPO_SOLICITACAO!=''){
                const {data} = await api.get('/m1/consultar/tipo_solicitacao');
                data.dados.map((item_solicitacoes) => {
                    if(item_solicitacoes.cd_crm_solicitacao_tipo == filtros.TIPO_SOLICITACAO){
                        setDsSolicitacaoTipo(item_solicitacoes.ds_crm_solicitacao_tipo)
                    }
                })
            }

            //verifica se existe algo nos valores de datas
            if(filtros.DATA_CRIACAO_INICIO !='' && filtros.DATA_CRIACAO_INICIO){
                let auxData = new Date(filtros.DATA_CRIACAO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroCriacaoDataInicio(auxData)
            }
            if(filtros.DATA_CRIACAO_FIM != '' && filtros.DATA_CRIACAO_FIM ){
                let auxData = new Date(filtros.DATA_CRIACAO_FIM)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroCriacaoDataFinal(auxData)
            }
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1390)/*solicitacaoClienteTabelaDropFiltrosFiltra*/
        if(DsSolicitacaoTipo !== 'Selecione')arrayFiltrosSimcard.push("Tipo: "+DsSolicitacaoTipo+"?TIPO_SOLICITACAO")
        if(filtroStatus !== '' && filtroStatus)arrayFiltrosSimcard.push("Status: "+filtroStatus+"?STATUS_SOLICITACAO")
        if(filtroCriacaoDataInicio !== '' && filtroCriacaoDataFinal !== '' && filtroCriacaoDataInicio && filtroCriacaoDataFinal)
            arrayFiltrosSimcard.push("Criação: entre "+moment(filtroCriacaoDataInicio).format("DD-MM-YYYY")+" e "+moment(filtroCriacaoDataFinal).format("DD-MM-YYYY")+"?DATA_CRIACAO_INICIO")

        despacho(maximoTagsExibidas(props.setRevelarDropFiltros))
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }
    
    useEffect(() => {
        
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(cdSolicitacaoTipo=='') verificador.push(1)
            if(filtroCriacaoDataInicio=='') verificador.push(1)
            if(filtroCriacaoDataFinal=='') verificador.push(1)
            if(filtroStatus=='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 4) return false
            return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()){
                props.setFoiSelecionado(true);
            }
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setDsSolicitacaoTipo("Selecione")
            setCdSolicitacaoTipo('')
            setFiltroCriacaoDataInicio('')
            setFiltroCriacaoDataFinal('')
            setFiltroStatus('')
            props.setFoiLimpado(false)
        }
        
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroStatus,cdSolicitacaoTipo,filtroCriacaoDataFinal,filtroCriacaoDataInicio,props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="drop-acoes-triangulo campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-por-arquivo","imagem-filtro-por-arquivo"); setRenderizarFiltroArquivo(true)}}> 
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Filtro por arquivo </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-por-arquivo"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-por-arquivo"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        {renderizarFiltroArquivo &&
                            <div className="componente-modal-overlay-filtro-arquivo-virtueyes-clientes">
                                <div className="componente-modal-overlay-botao-filtro-virtueyes-clientes">
                                    <div className="componente-modal-overlay-botao-filtro-triangulo-sombra-filtro-arquivo">
                                        <div className="componente-modal-filtro-arquivo-container-virtueyes-clientes">
                                            <FiltroArquivo
                                                renderizar={renderizarFiltroArquivo}
                                                setRenderizar={setRenderizarFiltroArquivo}
                                                handleFuncaoEstadosRedux={handleFuncaoEstadosRedux}
                                                enviarFiltros={props.enviarFiltros}
                                                arrayFiltrosSimcard={arrayFiltrosSimcard}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-nome", 'imagem-filtro-nome'); ColetaClickstream(1387)/*solicitacaoClienteTabelaDropFiltrosTipo*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Tipo </Form.Label>
                        <img id={"imagem-filtro-nome"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-nome"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Group className="container-select-filtro">
                            <Form.Control name="TIPO_SOLICITACAO" type="text" value={cdSolicitacaoTipo} className="d-none" onChange={(e)=> setCdSolicitacaoTipo(e.target.value)}/>
                            <Button name="cdEstado" className="campo-texto-cor-3 label-elipses campo-select" onClick={() => setRevelarSelectSolicitacaoTipo(!revelarSelectSolicitacaoTipo)}>
                                <div className="fonte-cor-1 label-campo">{DsSolicitacaoTipo}</div>
                                <img className={revelarSelectSolicitacaoTipo?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                            </Button>

                            { revelarSelectSolicitacaoTipo === true &&
                                <SelectTipoSolicitacao
                                    setRevelarSelectSolicitacaoTipo={setRevelarSelectSolicitacaoTipo} //Hook para fechar a modal
                                    setDsSolicitacaoTipo={setDsSolicitacaoTipo} //Nome do estado
                                    phSolicitacaoTipo={"Selecione"} //Placeholder para mostrar o valor original em caso de edição
                                    setCdSolicitacaoTipo={setCdSolicitacaoTipo}
                                >
                                </SelectTipoSolicitacao>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-status", 'imagem-filtro-status'); ColetaClickstream(1388)/*solicitacaoClienteTabelaDropFiltrosStatus*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Status </Form.Label>
                        <img id={"imagem-filtro-status"}className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Aberta')}>Aberta
                            <input defaultChecked={handlerCheck("STATUS_SOLICITACAO","1")} type="radio" id="STATUS_ABERTO" name="STATUS_SOLICITACAO" value="1"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Parcial')}>Parcial
                            <input defaultChecked={handlerCheck("STATUS_SOLICITACAO","4")} type="radio" id="STATUS_PARCIAL" name="STATUS_SOLICITACAO" value="4"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Concluída')}>Concluída
                            <input defaultChecked={handlerCheck("STATUS_SOLICITACAO","2")} type="radio" id="STATUS_CONCLUIDA" name="STATUS_SOLICITACAO" value="2"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Cancelada')}>Cancelada
                            <input defaultChecked={handlerCheck("STATUS_SOLICITACAO","3")} type="radio" id="STATUS_FINALIZADA" name="STATUS_SOLICITACAO" value="3"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-aquisicao", "imagem-filtro-aquisicao"); ColetaClickstream(1389)/*solicitacaoClienteTabelaDropFiltrosDataDeCriacao*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data de criação </Form.Label>
                        <img id={"imagem-filtro-aquisicao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-aquisicao"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        {/* Componente Calendário */}
                        <div className="conteudo-calendario-filtro-solicitacao-cliente">
                            <Form.Control name="DATA_CRIACAO_INICIO" type="text" value={filtroCriacaoDataInicio? moment(filtroCriacaoDataInicio).format("YYYY-MM-DD hh:mm:ss") : filtroCriacaoDataInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroCriacaoDataInicio} setData={setFiltroCriacaoDataInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro-solicitacao-cliente">
                            <Form.Control name="DATA_CRIACAO_FIM" type="text" value={filtroCriacaoDataFinal ? moment(filtroCriacaoDataFinal).format("YYYY-MM-DD hh:mm:ss") : filtroCriacaoDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroCriacaoDataFinal} setData={setFiltroCriacaoDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}