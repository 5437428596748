import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Button, Col } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
//COMPONENTES
import Calendario from '../../components/calendario/calendario'
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'
import { limpaDataInvalida } from "../../utils/dateTime";


export default function FiltrosVirtueyesCliente(props) {
    const [filtroStatus , setFiltroStatus] = useState('')
    const [filtroAquisicaoDataInicio , setFiltroAquisicaoDataInicio] = useState('')
    const [filtroAquisicaoDataFinal , setFiltroAquisicaoDataFinal] = useState('')
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())

    function handleChangeFiltroAquisicaoDataInicio(v) {
        setFiltroAquisicaoDataInicio(limpaDataInvalida(v))
    }

    function handleChangeFiltroAquisicaoDataFinal(v) {
        setFiltroAquisicaoDataFinal(limpaDataInvalida(v))
    }
    
    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    async function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            if(filtros.STATUS_SOLICITACAO == "1"){
                setFiltroStatus("Aberta")
            }
            if(filtros.STATUS_SOLICITACAO == "2"){
                setFiltroStatus("Concluída")
            }
            //verifica se existe algo nos valores de datas
            if(filtros.DATA_CRIACAO_INICIO !=''){
                let auxData = new Date(filtros.DATA_CRIACAO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                handleChangeFiltroAquisicaoDataInicio(auxData)
            }
            if(filtros.DATA_CRIACAO_FIM != ''){
                let auxData = new Date(filtros.DATA_CRIACAO_FIM)
                // auxData.setDate(auxData.getDate()+1)
                handleChangeFiltroAquisicaoDataFinal(auxData)
            }
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1400) /*solicitacaoOperadoraTabelaDropFiltrosFiltrar*/
        if(filtroAquisicaoDataInicio !== '' && filtroAquisicaoDataFinal !== '' && filtroAquisicaoDataInicio && filtroAquisicaoDataFinal)
            arrayFiltrosSimcard.push("Criação: entre "+moment(filtroAquisicaoDataInicio).format("DD-MM-YYYY")+" e "+moment(filtroAquisicaoDataFinal).format("DD-MM-YYYY")+"?DATA_CRIACAO_INICIO")
        if(filtroStatus !== '' && filtroStatus)arrayFiltrosSimcard.push("Status: " + filtroStatus+"?STATUS_SOLICITACAO")

        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    
    useEffect(() => {
        
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroAquisicaoDataInicio=='') verificador.push(1)
            if(filtroAquisicaoDataFinal=='') verificador.push(1)
            if(filtroStatus=='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 3) return false
            return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()){
                props.setFoiSelecionado(true);
            }
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            handleChangeFiltroAquisicaoDataInicio('')
            handleChangeFiltroAquisicaoDataFinal('')
            setFiltroStatus('')
            props.setFoiLimpado(false)
        }
        
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroStatus,filtroAquisicaoDataFinal,filtroAquisicaoDataInicio,props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="drop-acoes-triangulo campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu"> 
                    {/* campo da data de solicitacao  */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-aquisicao", "imagem-filtro-aquisicao"); ColetaClickstream(1398) /*solicitacaoOperadoraTabelaDropFiltrosDataDeCriacao*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data de criação </Form.Label>
                        <img id={"imagem-filtro-aquisicao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-aquisicao"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-selecione-calendario-filtros fonte-cor-5">Selecione o período:</label>
                        {/* Componente Calendário */}
                        <div className="conteudo-calendario-filtro-solicitacao-cliente">
                            <Form.Control name="DATA_CRIACAO_INICIO" type="text" value={filtroAquisicaoDataInicio? moment(filtroAquisicaoDataInicio).format("YYYY-MM-DD hh:mm:ss") : filtroAquisicaoDataInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroAquisicaoDataInicio} setData={handleChangeFiltroAquisicaoDataInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro-solicitacao-cliente">
                            <Form.Control name="DATA_CRIACAO_FIM" type="text" value={filtroAquisicaoDataFinal ? moment(filtroAquisicaoDataFinal).format("YYYY-MM-DD hh:mm:ss") : filtroAquisicaoDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroAquisicaoDataFinal} setData={handleChangeFiltroAquisicaoDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    {/* campo para o status  */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-status", 'imagem-filtro-status'); ColetaClickstream(1399) /*solicitacaoOperadoraTabelaDropFiltrosStatus*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Status </Form.Label>
                        <img id={"imagem-filtro-status"}className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-5" onChange={() => setFiltroStatus('Aberta')}>Aberta
                            <input defaultChecked={handlerCheck("STATUS_SOLICITACAO","1")} type="radio" id="STATUS_ABERTO" name="STATUS_SOLICITACAO" value="1"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-5" onChange={() => setFiltroStatus('Concluída')}>Concluída
                            <input defaultChecked={handlerCheck("STATUS_SOLICITACAO","2")} type="radio" id="STATUS_CONCLUIDA" name="STATUS_SOLICITACAO" value="2"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}