import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
import { MascaraMonetarioSemCifrao } from '../../utils/mascarasCampos.js'
import Calendario from '../calendario/calendario'
import moment from 'moment';
import decryptJWT from '../../utils/decryptJWT'

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem, filtros, maximoTagsExibidas} from '../../redux/actions'
import SelectGenerico from '../componentesModais/selects/selectGenerico'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltrosFaturasSimcard(props) {
    const [revelarSelectCicloVida, setRevelarSelectCicloVida] = useState(false)
    const [revelarSelectFornecedor, setRevelarSelectFornecedor] = useState(false)
    const [revelarSelectPlano, setRevelarSelectPlano] = useState(false)
    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState('1990')
    const [dataMax, setDataMax] = useState('2099')
    //HOOKS DOS VALORES DOS FILTROS20
    const [dummy, setDummy] = useState('')
    const [dsConta, setDsConta] = useState('Selecione')
    const [dsPlano, setDsPlano] = useState('Selecione')
    const [cdPlano, setCdPlano] = useState('')
    const [cdConta, setCdConta] = useState()
    const [dsOperadora, setDsOperadora] = useState('Selecione')
    const [cdOperadora, setCdOperadora] = useState()
    const [dsCicloVida, setDsCicloVida] = useState('Selecione')
    const [cdCicloVida, setCdCicloVida] = useState('')
    const [dsFornecedor, setDsFornecedor] = useState('Selecione')
    const [cdFornecedor, setCdFornecedor] = useState('')
    const [filtroValorMinimo, setFiltroValorMinimo] = useState('')
    const [filtroValorMaximo, setFiltroValorMaximo] = useState('')
    const [filtroDataCompraInicio, setFiltroDataCompraInicio] = useState('')
    const [filtroDataCompraFinal, setFiltroDataCompraFinal] = useState('')
    const [filtroDataContratoInicio, setFiltroDataContratoInicio] = useState('')
    const [filtroDataContratoFinal, setFiltroDataContratoFinal] = useState('')
    const [filtroDataAtivacaoInicio, setFiltroDataAtivacaoInicio] = useState('')
    const [filtroDataAtivacaoFinal, setFiltroDataAtivacaoFinal] = useState('')
    const [filtroCliente, setFiltroCliente] = useState('');
    
    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []
    const cd_pav_usuario = decryptJWT('codigo')


    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            
            setFiltroValorMinimo(filtros.VALOR_MIN)
            setFiltroValorMaximo(filtros.VALOR_MAX)
            setFiltroCliente(filtros.DS_CLIENTE)
            
            if(filtros.DATA_COMPRA_INICIO !='' && filtros.DATA_COMPRA_INICIO){
                let auxData = new Date(filtros.DATA_COMPRA_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataCompraInicio(auxData)
            }
            if(filtros.DATA_COMPRA_FIM != '' && filtros.DATA_COMPRA_FIM){
                let auxData = new Date(filtros.DATA_COMPRA_FIM)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataCompraFinal(auxData)
            }

            if(filtros.DATA_CONTRATO_INICIO !='' && filtros.DATA_CONTRATO_INICIO){
                let auxData = new Date(filtros.DATA_CONTRATO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataContratoInicio(auxData)
            }
            if(filtros.DATA_CONTRATO_FIM != '' && filtros.DATA_CONTRATO_FIM){
                let auxData = new Date(filtros.DATA_CONTRATO_FIM)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataContratoFinal(auxData)
            }

            if(filtros.DATA_ATIVACAO_INICIO !='' && filtros.DATA_ATIVACAO_INICIO){
                let auxData = new Date(filtros.DATA_ATIVACAO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataAtivacaoInicio(auxData)
            }
            if(filtros.DATA_ATIVACAO_FIM != '' && filtros.DATA_ATIVACAO_FIM){
                let auxData = new Date(filtros.DATA_ATIVACAO_FIM)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataAtivacaoFinal(auxData)
            }
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroValorMinimo == '') verificador.push(1)
            if(filtroValorMaximo == '') verificador.push(1)
            if(filtroDataAtivacaoFinal == '') verificador.push(1)
            if(filtroDataAtivacaoInicio == '') verificador.push(1)
            if(filtroDataCompraFinal == '') verificador.push(1)
            if(filtroDataCompraInicio == '') verificador.push(1)
            if(filtroDataContratoFinal == '') verificador.push(1)
            if(filtroDataContratoInicio == '') verificador.push(1)
            if(cdCicloVida == '') verificador.push(1)
            if(cdFornecedor == '') verificador.push(1)
            if(cdPlano == '') verificador.push(1)
            if(filtroCliente == '') verificador.push(1)

            
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 12) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()){
                props.setFoiSelecionado(true);
            }
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setDsConta('')
            setCdConta('')
            setDsPlano('')
            setCdPlano('')
            setDsOperadora('')
            setCdOperadora('')
            setDsCicloVida('')
            setCdCicloVida('')
            setDsFornecedor('')
            setCdFornecedor('')
            setFiltroValorMinimo('')
            setFiltroValorMaximo('')
            setFiltroDataCompraInicio('')
            setFiltroDataCompraFinal('')
            setFiltroDataContratoInicio('')
            setFiltroDataContratoFinal('')
            setFiltroDataAtivacaoInicio('')
            setFiltroDataAtivacaoFinal('')
            setFiltroCliente('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [dsPlano,cdPlano,dsConta,cdConta,dsOperadora,cdOperadora,dsCicloVida,cdCicloVida,cdFornecedor,dsFornecedor,filtroValorMinimo,filtroValorMaximo,filtroDataCompraInicio,filtroDataCompraFinal,filtroDataContratoInicio,filtroDataContratoFinal,filtroDataAtivacaoInicio,filtroDataAtivacaoFinal,filtroCliente, props.foiLimpado]);

    
    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1254) /*fatFaturasDispositivosDropFiltrosFiltrar*/
        if(dsPlano !== 'Selecione')arrayFiltrosSimcard.push("Plano: "+dsPlano+"?CD_PLANO")
        if(dsFornecedor !== 'Selecione')arrayFiltrosSimcard.push("Fornecedor: "+dsFornecedor+"?CD_FORNECEDOR")
        if(dsCicloVida !== 'Selecione')arrayFiltrosSimcard.push("Ciclo de Vida: "+dsCicloVida+"?CD_CICLO_VIDA")
        if(dsConta !== 'Selecione')arrayFiltrosSimcard.push("Conta: "+dsConta+"?DS_CONTA")
        if(dsOperadora !== 'Selecione')arrayFiltrosSimcard.push("Operadora: "+dsOperadora+"?DS_OPERADORA")
        if(filtroDataCompraInicio !== '' && filtroDataCompraFinal !== '' && filtroDataCompraInicio && filtroDataCompraFinal){
            arrayFiltrosSimcard.push("Data de compra: entre "+moment(filtroDataCompraInicio).format("DD-MM-YYYY")+" e "+moment(filtroDataCompraFinal).format("DD-MM-YYYY")+"?DATA_COMPRA_INICIO")
        }
        if(filtroDataContratoInicio !== '' && filtroDataContratoFinal !== '' && filtroDataContratoInicio && filtroDataContratoFinal){
            arrayFiltrosSimcard.push("Prazo do contrato: entre "+moment(filtroDataContratoInicio).format("DD-MM-YYYY")+" e "+moment(filtroDataContratoFinal).format("DD-MM-YYYY")+"?DATA_CONTRATO_INICIO")
        }
        if(filtroDataAtivacaoInicio !== '' && filtroDataAtivacaoFinal !== '' && filtroDataAtivacaoInicio && filtroDataAtivacaoFinal){
            arrayFiltrosSimcard.push("Data de ativação: entre "+moment(filtroDataAtivacaoInicio).format("DD-MM-YYYY")+" e "+moment(filtroDataAtivacaoFinal).format("DD-MM-YYYY")+"?DATA_ATIVACAO_INICIO")
        }
        if(filtroValorMinimo !== '' && filtroValorMaximo !== '' && filtroValorMinimo && filtroValorMaximo){
            arrayFiltrosSimcard.push("Valor: entre "+filtroValorMinimo+" R$ e "+filtroValorMaximo+" R$"+"?VALOR_MIN")
        }else if(filtroValorMinimo !== '' && filtroValorMinimo){
            arrayFiltrosSimcard.push("Valor: a partir de "+filtroValorMinimo+" R$"+"?VALOR_MIN")
        }else if(filtroValorMaximo !== '' && filtroValorMaximo){
            arrayFiltrosSimcard.push("Valor: até "+filtroValorMaximo+" R$"+"?VALOR_MAX")
        }
        if(filtroCliente !== '' && filtroCliente){
            arrayFiltrosSimcard.push("Cliente: "+filtroCliente+"?DS_CLIENTE");
        }
        despacho(maximoTagsExibidas(props.setRevelarDropFiltros))
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-valor","imagem-filtro-valor"); ColetaClickstream(1247) /*fatFaturasDispositivosDropFiltrosValor*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Valor </Form.Label>
                        <img id={"imagem-filtro-valor"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-valor"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <label className="filtros-label-mb-esquerda fonte-cor-1 campo-texto-cor-3">R$</label>
                                    <Form.Control
                                        name="VALOR_MIN"
                                        className="filtros-texto-valor-mb-esquerda campo-texto-cor-3"
                                        type="text"
                                        value={filtroValorMinimo}
                                        onChange={e => setFiltroValorMinimo( MascaraMonetarioSemCifrao(e.target.value) )} 
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <label className="filtros-label-mb-esquerda fonte-cor-1 campo-texto-cor-3">R$</label>
                                    <Form.Control
                                        name="VALOR_MAX"
                                        className="filtros-texto-valor-mb-esquerda campo-texto-cor-3"
                                        type="text"
                                        value={filtroValorMaximo}
                                        onChange={e => setFiltroValorMaximo( MascaraMonetarioSemCifrao(e.target.value) )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-ativacao","imagem-filtro-ativacao"); ColetaClickstream(1248) /*fatFaturasDispositivosDropFiltrosDataDeAtivacao*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Data de ativação </Form.Label>
                        <img id={"imagem-filtro-ativacao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-ativacao"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_ATIVACAO_INICIO" type="text" value={filtroDataAtivacaoInicio ? moment(filtroDataAtivacaoInicio).format("YYYY-MM-DD hh:mm:ss") : filtroDataAtivacaoInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataAtivacaoInicio} setData={setFiltroDataAtivacaoInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_ATIVACAO_FIM" type="text" value={filtroDataAtivacaoFinal? moment(filtroDataAtivacaoFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataAtivacaoFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataAtivacaoFinal} setData={setFiltroDataAtivacaoFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-cliente","imagem-filtro-ativacao"); ColetaClickstream(1248) /*fatFaturasDispositivosDropFiltrosDataDeAtivacao*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Cliente </Form.Label>
                        <img id={"imagem-filtro-cliente"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-cliente"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Nome do Cliente:</label>
                        <div className="col-sm-6">
                                <div className="d-flex">
                                    <Form.Control
                                        name="DS_CLIENTE"
                                        className="filtros-texto-cliente campo-texto-cor-3"
                                        type="text"
                                        value={filtroCliente}
                                        onChange={e => setFiltroCliente(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-contrato","imagem-filtro-contrato"); ColetaClickstream(1249) /*fatFaturasDispositivosDropFiltrosPrazoDoContrato*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Prazo do contrato </Form.Label>
                        <img id={"imagem-filtro-contrato"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-contrato"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_CONTRATO_INICIO" type="text" value={filtroDataContratoInicio ? moment(filtroDataContratoInicio).format("YYYY-MM-DD hh:mm:ss") : filtroDataContratoInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataContratoInicio} setData={setFiltroDataContratoInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_CONTRATO_FIM" type="text" value={filtroDataContratoFinal? moment(filtroDataContratoFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataContratoFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataContratoFinal} setData={setFiltroDataContratoFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-compra","imagem-filtro-compra"); ColetaClickstream(1250) /*fatFaturasDispositivosDropFiltrosDataDeCompra*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Data de compra </Form.Label>
                        <img id={"imagem-filtro-compra"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-compra"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_COMPRA_INICIO" type="text" value={filtroDataCompraInicio ? moment(filtroDataCompraInicio).format("YYYY-MM-DD hh:mm:ss") : filtroDataCompraInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataCompraInicio} setData={setFiltroDataCompraInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_COMPRA_FIM" type="text" value={filtroDataCompraFinal? moment(filtroDataCompraFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataCompraFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataCompraFinal} setData={setFiltroDataCompraFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                     <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-ciclovida", 'imagem-filtro-ciclovida'); ColetaClickstream(1251) /*fatFaturasDispositivosDropFiltrosCicloDeVida*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Ciclo de Vida </Form.Label>
                        <img id={"imagem-filtro-ciclovida"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-ciclovida"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Group className="select-generico-filtro">
                            <Form.Control name="CD_CICLO_VIDA" type="text" value={cdCicloVida} className="d-none" onChange={(e)=> setCdCicloVida(e.target.value)}/>
                            {revelarSelectCicloVida === false &&
                                <Button  className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCicloVida(!revelarSelectCicloVida)}>
                                    <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCicloVida}</div>
                                    <img className={revelarSelectCicloVida?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }
                            {revelarSelectCicloVida === true &&
                                <SelectGenerico
                                    paramRota={"fat_ciclo_de_vida_2"}
                                    paramCampo1={cd_pav_usuario}
                                    paramCampo2={null}
                                    paramCampo3={null}
                                    paramCampo4={null}
                                    setCodigo={setCdCicloVida}
                                    setDescricao1={setDsCicloVida}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectCicloVida}
                                    placeholder={dsCicloVida}
                                    sufixo={""}
                                >
                                </SelectGenerico>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div> 
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-fornecedor", 'imagem-filtro-fornecedor'); ColetaClickstream(1252) /*fatFaturasDispositivosDropFiltrosFornecedor*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Fornecedor </Form.Label>
                        <img id={"imagem-filtro-fornecedor"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-fornecedor"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Group className="select-generico-filtro">
                            <Form.Control name="CD_FORNECEDOR" type="text" value={cdFornecedor} className="d-none" onChange={(e)=> setCdFornecedor(e.target.value)}/>
                            {revelarSelectFornecedor === false &&
                                <Button  className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFornecedor(!revelarSelectFornecedor)}>
                                    <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsFornecedor}</div>
                                    <img className={revelarSelectFornecedor?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }
                            {revelarSelectFornecedor === true &&
                                <SelectGenerico
                                    paramRota={"fat_fornecedor"}
                                    paramCampo1={cd_pav_usuario}
                                    paramCampo2={null}
                                    paramCampo3={null}
                                    paramCampo4={null}
                                    setCodigo={setCdFornecedor}
                                    setDescricao1={setDsFornecedor}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectFornecedor}
                                    placeholder={dsFornecedor}
                                    sufixo={""}
                                >
                                </SelectGenerico>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div> 
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-plano", 'imagem-filtro-plano'); ColetaClickstream(1253) /*fatFaturasDispositivosDropFiltrosPlano*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Plano </Form.Label>
                        <img id={"imagem-filtro-plano"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-plano"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Group className="select-generico-filtro">
                            <Form.Control name="CD_PLANO" type="text" value={cdPlano} className="d-none" onChange={(e)=> setCdPlano(e.target.value)}/>
                            {revelarSelectPlano === false &&
                                <Button  className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectPlano(!revelarSelectPlano)}>
                                    <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsPlano}</div>
                                    <img className={revelarSelectPlano?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }
                            {revelarSelectPlano === true &&
                                <SelectGenerico
                                    paramRota={"fat_plano_por_operadora_e_por_fornecedor"}
                                    paramCampo1={cd_pav_usuario}
                                    paramCampo2={null}
                                    paramCampo3={null}
                                    paramCampo4={null}
                                    setCodigo={setCdPlano}
                                    setDescricao1={setDsPlano}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectPlano}
                                    placeholder={dsPlano}
                                    sufixo={""}
                                >
                                </SelectGenerico>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div> 
                    {/* ------------------- */}
                    {/* <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-operadora", 'imagem-filtro-operadora')}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Operadora </Form.Label>
                        <img id={"imagem-filtro-operadora"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-operadora"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Group className="operadorainer-select-filtro">
                            <Form.Control name="OPERADORA" type="text" value={cdOperadora} className="d-none" onChange={(e)=> setCdOperadora(e.target.value)}/>
                            <Button name="cdEstado" className="campo-texto-cor-3 label-elipses campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                <div className="fonte-cor-1 label-campo">{dsOperadora}</div>
                                <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                            </Button>

                            { revelarSelectOperadora === true &&
                                <SelectOperadora
                                    setRevelarSelectOperadora={setRevelarSelectOperadora}
                                    setDsOperadora={setDsOperadora}
                                    setCdOperadora={setCdOperadora}
                                >
                                </SelectOperadora>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div> */}
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}