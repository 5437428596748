//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {api} from '../../../../../conexoes/api';/*eslint-disable*/
import { Button, Dropdown, Accordion, Form, Col } from 'react-bootstrap';/*eslint-disable*/
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, importarArquivo} from '../../../../../utils/alternanciaIcones'
import {formatarMilhar} from '../../../../../utils/formatarMilhar'
// SPINNER
import SpinerVeye from '../../../../spinnerVeye/spinnerVeye'
//COMPONENTES
import { useDispatch, useSelector, connect } from 'react-redux';
import { revelarRelatorioNotaFiscal } from "../../../../../redux/actions/index";
import decryptJWT from '../../../../../utils/decryptJWT'
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../../utils/paginacaoTabela'
import {formatarData} from '../../../../../utils/formatadorDataTabela'

let contadorNotasFiscais = 0
let totalPaginasNotasFiscais = 1
let totalItensNotasFiscais = 10

export default function Preview(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS TABELA
    let itensVarNotasFiscais = []
    const [itensNotasFiscais, setItensNotasFiscais] = useState([])
    const [valorLimite, setValorLimite] = useState(10);
    const [paginaAtualNotasFiscais, setPaginaAtualNotasFiscais] = useState(1);
    const [refazerChamadasNotasFiscais, setRefazerChamadasNotasFiscais] = useState(true);
    const despacho = useDispatch();
    
    let controladorRelatorioNotaFiscal = useSelector(state => state.faturasState.revelarRelatorioNotaFiscal);

    useEffect(()=>{
        setSpinAtivo(true)
        contadorNotasFiscais = (paginaAtualNotasFiscais - 1) * valorLimite;
        async function carregarHistorico(){
            if(refazerChamadasNotasFiscais === true){
                const contagem = await api.post('/m1/consultar/fatFebraban/arquivo_reg_80',{
                    "cd_fat_fatura":props.cdFatura,
                    "carregarLimit":valorLimite,
                    "paginaAtual":contadorNotasFiscais,
                    "contar":true
                })
                totalPaginasNotasFiscais = Math.ceil(contagem.data.dados[0].CONTADOR / valorLimite)
                totalItensNotasFiscais = contagem.data.dados[0].CONTADOR;
                setRefazerChamadasNotasFiscais(false)
            }
            const notaFiscal = await api.post('/m1/consultar/fatFebraban/arquivo_reg_80',{
                "cd_fat_fatura":props.cdFatura,
                "carregarLimit":valorLimite,
                "paginaAtual":contadorNotasFiscais,
                "contar":false
            })

            inserirDataNotasFiscais(notaFiscal.data.dados)
        }
        carregarHistorico()
    },[paginaAtualNotasFiscais])

    useEffect(()=>{
        async function buscarDadosRelatorioNotaFiscal(){
            if(controladorRelatorioNotaFiscal === "XLS" || controladorRelatorioNotaFiscal ===  "XLSX" || controladorRelatorioNotaFiscal ===  "CSV"){
                const dados = {
                    codigo_cliente: decryptJWT('codigoCliente'),
                    codigo_usuario: decryptJWT('codigo'),
                    descricao: 'Relatório de preview de notas fiscais',
                    tipo_arquivo: controladorRelatorioNotaFiscal,
                    tipo_relatorio: 12,
                    cd_fat_fatura: props.cdFatura
                }
                api.post('/m1/cadastrar/relatorios',dados)
                props.setMensagemInformacaoTitulo("O arquivo está sendo gerado")
                props.setMensagemInformacaoCorpo("Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível")
                props.handleMensagemInformacao(true)
                despacho(revelarRelatorioNotaFiscal(false))
            }else{
                despacho(revelarRelatorioNotaFiscal(false))
            }
        }
        buscarDadosRelatorioNotaFiscal()
    }, [controladorRelatorioNotaFiscal])
    
    function inserirDataNotasFiscais(data){
        for(let i=0;i<totalItensNotasFiscais;i++){
            if (contadorNotasFiscais === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVarNotasFiscais[k] = data[j]
                    k++
                }
            }
            if(i == (totalItensNotasFiscais-1)){
                setItensNotasFiscais(JSON.parse(JSON.stringify(itensVarNotasFiscais)))
            }
        }
        setSpinAtivo(false)
    }

    const colunasNotasFiscais = [
        {
            dataField: 'cd_fat_arquivo_febraban_reg_80',
            text: 'Cód. preview',
            hidden: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_numero_nf',
            text: 'Nº da nota fiscal',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_valor_nf_com_impostos',
            text: 'Valor',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-simcard"> {(!valor || isNaN(valor)) ? 'R$ 0.00' : <><p className="valor-rs-detalhamento-nota-fiscal">R$</p> <p className="fonte-cor-1 valor-detalhamento-nota-fiscal"> {formatarMilhar(valor)}</p></>} </p>;
            }
        },
        {
            dataField: 'ds_data_emissao',
            text: 'Data',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return formatarData(cell,true);
            }
        }
    ]

    const opcoesPaginacaoNotasFiscais = {
        page: paginaAtualNotasFiscais,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtualNotasFiscais(page)}
    }

    async function selecionarFormato(formato, tipo){
        props.setCdFatura(props.cdFatura)
        despacho(revelarRelatorioNotaFiscal(formato))
        
        const exportar = document.getElementById('botao-formatos')
        exportar.click();
    }

    return(
        <>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
            <div className="container-itens-expandido-faturas">
                <div className="detalhamento-fat-faturas-1 fundo-cor-7">
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                            <div className="container-preview-fatura-titulo">
                                <div className="coluna-preview-fatura-titulo">
                                    <img className="icone-label-filtro-financeiro" src={elipseVerde()} alt="elipse"/>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-janela-financeiro"> Notas Fiscais </div>
                                    </Form.Label>
                                </div>
                                <div className="coluna-preview-fatura-titulo">
                                    <Dropdown alignRight variant="success">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Toggle id="botao-formatos" className="bt-dropdown-formato-preview" as={Button} variant="link" eventKey="1"></Accordion.Toggle>
                                            <Accordion.Collapse className="menu-dropdown-formatos-preview" eventKey="1">
                                                <>
                                                <div className="triangulo-dropdown-configuracao-cabecalho-sistema triangulo-dropdown-template-detalhamento"></div>
                                                <div className="drop-formato-preview-opcao drop-formato-preview-hover fonte-cor-1" onClick={() => selecionarFormato("CSV")}> CSV </div>
                                                    <Dropdown.Divider className="template-border"/>
                                                <div className="drop-formato-preview-opcao drop-formato-preview-hover fonte-cor-1" onClick={() => selecionarFormato("XLS")}> XLS </div>
                                                    <Dropdown.Divider className="template-border"/>
                                                <div className="drop-formato-preview-opcao drop-formato-preview-hover fonte-cor-1" onClick={() => selecionarFormato("XLSX")}> XLSX </div>
                                            </>
                                            </Accordion.Collapse>
                                        </Accordion>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                    <Form.Row className="form-row-sem-margem">
                            <div className="linha-detalhamento-fat-faturas">
                                <PaginationProvider pagination={Paginador(opcoesPaginacaoNotasFiscais)} >
                                    {
                                        ({
                                            paginationProps,
                                            paginationTableProps
                                        }) => (
                                            <div className="tabela-preview">
                                                <BootstrapTable
                                                    classes="tabela-transparente-header-fixo"
                                                    hover={true}
                                                    condensed={true}
                                                    data={itensNotasFiscais}
                                                    keyField={"cd_fat_arquivo_febraban_reg_80"}
                                                    columns={colunasNotasFiscais} 
                                                    bootstrap4={true}
                                                    bordered={false}
                                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                    { ...paginationTableProps }
                                                />
                                                <PaginacaoTabela
                                                    preview={true}
                                                    setPaginaAtual={setPaginaAtualNotasFiscais}
                                                    totalPaginas={totalPaginasNotasFiscais}
                                                    totalItens={totalItensNotasFiscais}
                                                    valorLimite={valorLimite}
                                                    paginaAtual={paginaAtualNotasFiscais}
                                                    page={paginationProps.page}
                                                    onPageChange={paginationProps.onPageChange}
                                                />
                                            </div>
                                        )
                                    }
                                </PaginationProvider>
                                <text className="itens-exibidos-tabela-faturas fonte-cor-1">
                                    Mostrando 
                                    <span className="label-tabela-itens-exibidos">{contadorNotasFiscais + 1}</span>
                                        - 
                                    <span className="label-tabela-itens-exibidos">{ contadorNotasFiscais + valorLimite <= totalItensNotasFiscais ? contadorNotasFiscais + valorLimite : totalItensNotasFiscais }</span>
                                        de 
                                    <span className="label-tabela-itens-exibidos">{totalItensNotasFiscais}</span>
                                </text>
                            </div>
                    </Form.Row> 
                </div>
            </div>
        </>
    )
}