import { manejadorReducer } from "./manejadorReducer";
import { filtrosReducer } from "./filtrosReducer";
import { buscaArquivoReducer } from "./buscaArquivoReducer";
import { virtueyesReducer } from "./virtueyesReducer";
import { smsReducer } from "./smsReducer";
import { pedidosReducer } from "./pedidosReducer";
import { financeiroReducer } from "./financeiroReducer";
import { automacaoReducer } from "./automacaoReducer";
import { mensagensReducer } from "./mensagensReducer";
import { menuUsuarios } from './menuUsuarios'
import { notificacoesReducer } from "./notificacoesReducer";
import { breadCrumbs, permissao } from './permissaoReducer'
import { faturasReducer } from './faturasReducer'
import { relatorioReducer } from './relatorioReducer'
import { cadastrosReducer } from './cadastros'
import { SystemControl } from "./systemControl"
import { combineReducers } from "redux";
import { migracaoPlanoReducer } from "./migracaoPlanoReducer";

export const Reducers = combineReducers({
  "manejadorState": manejadorReducer,
  "filtrosState": filtrosReducer,
  "buscaArquivoState": buscaArquivoReducer,
  "virtueyesState": virtueyesReducer,
  "smsState": smsReducer,
  "pedidosState": pedidosReducer,
  "financeiroState": financeiroReducer,
  "automacaoState": automacaoReducer,
  "mensagensState": mensagensReducer,
  "menuUsuarioState": menuUsuarios,
  "notificacoesState": notificacoesReducer,
  "breadCrumbsState": breadCrumbs,
  "permissaoState": permissao,
  "faturasState": faturasReducer,
  "relatorioState": relatorioReducer,
  "cadastrosState": cadastrosReducer,
  "systemControlStates": SystemControl,
  "migracaoPlanoState": migracaoPlanoReducer,
});