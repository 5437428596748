//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, fecharJanela, setaDropdown} from '../../../utils/alternanciaIcones'
//MODAIS
import decryptJWT from '../../../utils/decryptJWT'
// import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'

// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import {revelarRelatorioConsumo} from "../../../redux/actions/index";

// COLETA CLICKSTREAM
import ColetaClickstream from '../../clickstreamAnalise/clickstreamColeta.js'
import SelectInput from '../../componentesModais/selects/selectComOpcoesPreConfiguradas';

export default function RelatorioConsumo(props) {
    let reduxModoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);

    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();

    //HOOKS PRINCIPAIS
    const [dsReferencia, setDsReferencia] = useState('Selecione');

    //HOOKS MODAIS
    const [selectOptions, setSelectOptions] = useState(false)
    const [revelarSelectReferencia, setRevelarSelectReferencia] = useState(false)
    // const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    //OUTROS HOOKS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [mensagemAlerta, setMensagemAlerta] = useState('')
    // const [mensagemSucesso, setMensagemSucesso] = useState('')
    const modal = useRef();
    const despacho = useDispatch();

    /* gerar opções select referencia ------------------------------------------------- */
    function getOpcoesReferencia(qtdOpcoes) {
      const date = new Date()
      const arrOpcoes = [];
  
      const getOpcao = (i) => {
          if(i > 0) {
              date.setMonth(date.getMonth() - 1)
          }
          const aux = date.toISOString().substring(0, 7).replace('-', '/');
          arrOpcoes.push({ text: aux, value: aux })
      }
  
      for(let i=0; i<qtdOpcoes; i++) {
          getOpcao(i)
      }
  
      return arrOpcoes;
    }

    useEffect(() => {
      const qtdOpcoes = 4;
      setSelectOptions(getOpcoesReferencia(qtdOpcoes))
    }, [])
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };
    /* fim gerar opções select referencia ------------------------------------------------- */

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    const getCicloPorReferencia = (referencia) => { // ex: recebe '2023/01'
        const [year, month] = referencia.split('/')
        const subYear = year.substr(2, 4);
        return (subYear + month) // ex: retorna '2301'
    }

    async function handleSalvar(){
        setSpinAtivo(true)
        const tipo_arquivo = 'XLSX'; //revelarrelatorioConsumo
        let bodyRelatorio = {};
        const ciclo_escolhido = getCicloPorReferencia(dsReferencia);
        const simcardsSelecionados = props.simcardsSelecionados;
        const filtros = props.filtros;
        const buscaArquivo = props.buscaArquivo;
        const buscar = props.conteudoBusca;
        const separador = props.separadorLinha;

        if (reduxModoSelecaoSimcard) { //&& revelarexportarOperadora == false) {
            bodyRelatorio = {
                descricao: 'Relatório de Consumo buscando pelo parque inteiro',
                tipo_arquivo,
                tipo_relatorio: 26, // RELATÓRIO CONSUMO
                ciclo_escolhido,
                filtros,
                buscaArquivo,
                buscar,
                separador,
                contar: false
            }

        // } else if (reduxModoSelecaoSimcard && revelarexportarOperadora == true) { }

        } else {
            let auxArrayCdSimcard = []
            simcardsSelecionados.map((simcard) => {
                if (simcard.CD_SIMCARD !== undefined && simcard.CD_SIMCARD !== null && simcard.CD_SIMCARD !== '') {
                    auxArrayCdSimcard.push(simcard.CD_SIMCARD)
                } else {
                    auxArrayCdSimcard.push(simcard.FAT_CD_SIMCARD)
                }
            })
            bodyRelatorio = {
                descricao: 'Relatório de Consumo buscando por linhas específicas',
                tipo_arquivo,
                tipo_relatorio: 26,  // RELATÓRIO CONSUMO
                ciclo_escolhido,
                // tipo_relatorioOperadora: 23,
                arrayCdSimcard: auxArrayCdSimcard,
                filtros,
                buscaArquivo,
                buscar,
                separador,
                contar: false,
                // operadoraAtual: revelarexportarOperadora
            }
            // despacho(revelarexportarOperadora(undefined))
        }

        api.post('/m1/cadastrar/relatorios', bodyRelatorio)

        setSpinAtivo(false)
        setRevelarMensagemInformacao(true)
    }

    function handleCancelar(){
        despacho(revelarRelatorioConsumo(false))
        // setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
    }
    

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
            }
        }
    }
    
    // function handleMensagemSucesso(confirmacao){
    //     if(revelarMensagemSucesso === false){
    //         // setRevelarMensagemSucesso(true)
    //     }else{
    //         // setRevelarMensagemSucesso(false)
    //         handleCancelar()
    //         props.setRenderizar(!props.renderizar)
    //         if(confirmacao === true){
    //             handleCancelar()
    //         }
    //     }
    // }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            handleCancelar()
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <div className="modal-editar-fatura fundo-cor-6">
            <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Relatório de Consumo</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                    <div className="grupo-cadastro-editar-fatura-1">
                        <div className="coluna-cadastro-editar-fatura-1">
                            <Form.Group>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-campo">Referência</div>
                                </Form.Label>
                                { revelarSelectReferencia === false &&
                                    <Button 
                                      className="campo-texto-cor-3 campo-select" 
                                      onClick={() => setRevelarSelectReferencia(!revelarSelectReferencia)}
                                    >
                                      <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsReferencia}</div>
                                      <img className={revelarSelectReferencia?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                                { revelarSelectReferencia === true &&
                                  <SelectInput
                                    setRevelarSelect={setRevelarSelectReferencia} //Hook para fechar a modal
                                    setDs={setDsReferencia} // value da opção escolhida
                                    ds={dsReferencia}
                                    options={selectOptions}
                                  />
                                }
                            </Form.Group>
                        </div>
                    </div>
                <Form.Row>
                    <Col>
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Selecione o ano/mês de Referência.</Tooltip>}>
                            {dsReferencia != 'Selecione' ?
                                <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            :
                                <Button  className="botao-confirmar-desabilitado" variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            } 
                        </OverlayTrigger>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
{/* 
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Esse processo pode levar alguns minutos, quando estiver pronto você poderá consultar em 'Relatórios'"}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            } */}
            { revelarMensagemErro &&
              <div className="componente-modal-overlay">
                <div className="componente-modal">
                  <MensagemErro
                      handleMensagemErro={handleMensagemErro}
                      mensagemTitulo={"Erro!"}
                      mensagemParagrafo1={mensagemErro}
                      mensagemBotao={"Retornar"}
                  />
                </div>
              </div>
            }
            { revelarMensagemInformacao &&
              <div className="componente-modal-overlay">
                <div className="componente-modal">
                  <MensagemInformacao
                      handleMensagemInformacao={handleMensagemInformacao}
                      mensagemTitulo={"Atenção"}
                      mensagemParagrafo1={mensagemAlerta}
                      mensagemBotao={"Continuar"}
                  />
                </div>
              </div>
            }
            { revelarMensagemInformacao &&
                <div id="mensagem-relatorio" className="componente-modal-overlay">
                  <div className="componente-modal">
                    <MensagemInformacao
                      handleMensagemInformacao={handleMensagemInformacao}
                      mensagemTitulo={"O arquivo está sendo gerado"}
                      mensagemParagrafo1={"Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível"}
                      mensagemBotao={"Voltar"}
                      marginTop={80}
                    />
                  </div>
                </div>
            }
        </Form>
    )
}