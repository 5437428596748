import * as S from "./styles";

export default function Modal({
  children,
  grow = false,
  columns,
  colSize = [12],
}) {
  if(!Array.isArray(children)) children = [children]

  return (
    <>
      <S.Grid>
        {children &&
          children.map((child, index) => (
            <S.Grid.Col
              key={index}
              span={colSize[index]}
              style={{ minWidth: "42px" }}
            >
              {child}
            </S.Grid.Col>
          ))}
      </S.Grid>
    </>
  );
}
