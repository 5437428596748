import React, { useState, useEffect } from "react";
import Modal from "../../Mantine/Modal";
import MultiSelect from "../../Mantine/MultiSelect";
import * as S from "./styles";
import { useQuery } from "react-query";
import { SolicitationsService } from "../../../services/http/solicitations.service";
import Grid from "../../Mantine/Grid";
import { useGlobal } from "../../../stores/useGlobal";
import { filtrosArrayBarra } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { formatStringToNumberArray } from "../../../utils/genericFormatters";

const WIDTH_COLUMNS = [4, 4, 4];

const DEFAULT_FILTERS_VALUES = {
  carriers: [],
  status: [],
  users: [],
};

export default function FiltroSolicitacoesSimcardsVirgens({ opened, close }) {
  const { filtros, setFiltros, setRevelarBarraFiltro } = useGlobal();
  const despacho = useDispatch();
  let arraySimcardFilters = [];

  const [filterOptions, setFilterOptions] = useState({
    carriers: [],
    status: [],
    users: [],
  });
  const [localFilters, setLocalFilters] = useState(DEFAULT_FILTERS_VALUES);
  const { getCarrierFilter, getUserFilter, getStatusFilter } =
    SolicitationsService;

  useQuery(
    ["solicitations/list/carriers"],
    async () => {
      const [carriers, users, status] = await Promise.all([
        getCarrierFilter(),
        getUserFilter(),
        getStatusFilter(),
      ]);

      setFilterOptions({
        carriers: (carriers || [])?.map((item) => ({
          value: item.CdOperadora,
          label: item.Operadora,
        })),
        status: (status || [])?.map((item) => ({
          value: item.CdStatus,
          label: item.Status,
        })),
        users: (users || [])?.map((item) => ({
          value: item.CdUsuario,
          label: item.Usuario,
        })),
      });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleLocalFiltroChange = (fieldPath, value) => {
    const [parent, child] = fieldPath.split(".");

    setLocalFilters((prev) => {
      if (!child) {
        return {
          ...prev,
          [parent]: value,
        };
      }

      return {
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value,
        },
      };
    });
  };

  const formattedFilter = () => {
    const addFilters = (localFilterKey, filterOptionKey, prefix = "") => {
      const filteredItems = filterOptions[filterOptionKey].filter((item) =>
        localFilters[localFilterKey].includes(item.value)
      );
      const formattedItems = filteredItems.map(
        (item) => `${prefix}${item.label?.trim()} _${item.value}`
      );

      arraySimcardFilters.push(...formattedItems);
    };

    if (localFilters.carriers?.length)
      addFilters("carriers", "carriers", "carrierWhiteChip: ");
    if (localFilters.status?.length)
      addFilters("status", "status", "statusWhiteChip: ");
    if (localFilters.users?.length)
      addFilters("users", "users", "userWhiteChip: ");
    if (localFilters.preActivationDeadLine)
      arraySimcardFilters.push(
        `preActivationWhiteChip: ${localFilters.preActivationDeadLine} Dias`
      );

    despacho(filtrosArrayBarra(arraySimcardFilters));
  };

  const formatValue = (value) => {
    if (!value.length) return undefined;
    return value.join(",");
  };

  const handleFilter = () => {
    const filteredData = {
      CdOperadora: formatValue(localFilters.carriers),
      CdStatus: formatValue(localFilters.status),
      CdUsuario: formatValue(localFilters.users),
    };

    const finalFiltros = Object.fromEntries(
      Object.entries(filteredData).filter(([_, value]) => value !== undefined)
    );

    setFiltros(finalFiltros);
    setRevelarBarraFiltro(true);
    formattedFilter();
    close();
  };

  const handleCleanFilters = () => {
    setLocalFilters(DEFAULT_FILTERS_VALUES);
    setFiltros({ vazio: "vazio" });
    despacho(filtrosArrayBarra([]));
    setRevelarBarraFiltro(false);
  };

  useEffect(() => {
    if (!filtros.vazio) {
      const auxFilter = { ...DEFAULT_FILTERS_VALUES };

      if (filtros?.CdOperadora)
        auxFilter.carriers = formatStringToNumberArray(filtros.CdOperadora);

      if (filtros?.CdStatus)
        auxFilter.status = formatStringToNumberArray(filtros.CdStatus);

      if (filtros?.CdUsuario)
        auxFilter.users = formatStringToNumberArray(filtros.CdUsuario);

      setLocalFilters(auxFilter);
    }
  }, [filtros]);

  return (
    <Modal
      width="790px"
      type="filtro"
      titulo="Filtros"
      opened={opened}
      close={close}
      onConfirm={handleFilter}
      handleCleanFilters={handleCleanFilters}
    >
      <S.Container>
        <S.Card>
          <Grid colSize={WIDTH_COLUMNS}>
            <MultiSelect
              label="Operadora"
              value={localFilters.carriers}
              options={filterOptions?.carriers}
              onChange={(value) => handleLocalFiltroChange("carriers", value)}
            />
            <MultiSelect
              label="Status"
              value={localFilters.status}
              options={filterOptions?.status}
              onChange={(value) => handleLocalFiltroChange("status", value)}
            />
            <MultiSelect
              label="Usuários"
              value={localFilters.users}
              options={filterOptions?.users}
              onChange={(value) => handleLocalFiltroChange("users", value)}
            />
          </Grid>
        </S.Card>
      </S.Container>
    </Modal>
  );
}
