//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import {Image, Button} from 'react-bootstrap';
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import decryptJWT from '../../../utils/decryptJWT';
import Conciliacao from '../fatConciliacao/conciliacao'
import RecalcularFaturaReferencia from './acoes/recalcularFaturaReferencia'
import RecalcularFaturaConta from './acoes/recalcularFaturaConta'
import RecalcularFaturaEmpresa from './acoes/recalcularFaturaEmpresa'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from 'react-redux';

import 
{ 
    limpadorFiltros, revelarRelatorioPreviewFatura, revelarRelatorioFatura, 
    mudarOrdenador, mudarManejador, desabilitarAcoes, 
    desabilitarAcoesCliente, limpouBusca, realizouBusca, mudarFiltros, revelarBarraFiltragem, 
    realizouFiltro, revelarDeletarFatura, revelarEditarFatura, valorCdFatura, revelarAbrirTelaConciliacao, 
    revelarContestarFatura, revelarRelatorioNotaFiscalGeral,revelarRecalcularFatura, RevelarBuscarEFiltro
} from "../../../redux/actions/index";

//IMPORTAÇÕES COMPONENTES
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';
import CadastroFatura from './acoes/cadastroFatura'
import ConciliacaoEmLote from './acoes/conciliacaoEmLote'
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
import ContestarFatura from './acoes/contestar'
import Detalhamento from './detalhamento/detalhamentoFaturas'
import {formatarData} from '../../../utils/formatadorDataTabela'
import {formatarMilhar} from '../../../utils/formatarMilhar'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import {setaDropdown} from '../../../utils/alternanciaIcones';
import GirarSetaDropdown from '../../../utils/girarSetaDropdown'
import PersonalizarColunasFaturas from '../../personalizarColunasFaturas/personalizarColunasFaturas'
//ESTILIZAÇÃO
import '../../../css/painelFaturas/fatFaturas/tabela.css'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function TabelaFaturas(props){
    /*eslint-disable*/
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);  

    const [cdFatura, setCdFatura] = useState();
    const [cdStatusContestacao, setCdStatusContestacao] = useState();
    const [cdConta, setCdConta] = useState();
    const [dsConta, setDsConta] = useState();
    const [cdOperadora, setCdOperadora] = useState();
    const [cdStatus, setCdStatus] = useState();
    const [dsStatus, setDsStatus] = useState();
    const [dtVencimento, setdtVencimento] = useState();
    const [dtInicioCiclo, setDtInicioCiclo] = useState();
    const [dtFimCiclo, setDtFimCiclo] = useState();
    const [dtContestacao, setDtContestacao] = useState();
    const [dsProtocolo, setDsProtocolo] = useState();
    const [dtFiltro, setDtFiltro] = useState();
    const [dsReferencia, setDsReferencia] = useState();
    const [dtPagamento, setDtPagamento] = useState();
    const [n2ValorPago, setN2ValorPago] = useState();
    const [n2ValorTotal, setN2ValorTotal] = useState();
    const [diaVencimento, setDiaVencimento] = useState('');
    const [diaInicio, setDiaInicio] = useState('');
    const [dtAssociacaoFebraban, setDtAssociacaoFebraban] = useState();
    const [dtAssociacao, setDtAssociacao] = useState();
    const [mensagemInformacaoTitulo, setMensagemInformacaoTitulo] = useState('')
    const [mensagemInformacaoCorpo, setMensagemInformacaoCorpo] = useState('')
    const [mensagemErro, setMensagemErro] = useState('')
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemSucessoRecalcular, setRevelarMensagemSucessoRecalcular] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemAtencaoRecalcular, setRevelarMensagemAtencaoRecalcular] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [revelarModalContestacao, setRevelarModalContestacao] = useState(false)
    const [revelarMensagemAtencaoEdicao, setRevelarMensagemAtencaoEdicao] = useState(false)
    const [revelarModalEdicao, setRevelarModalEdicao] = useState(false)
    let itensVar = []

    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let editarFatura =  useSelector(state => state.faturasState.revelarEditarFatura);
    let cadastrarFatura = useSelector(state => state.faturasState.revelarCadastrarFatura);
    let conciliacaoEmLote = useSelector(state => state.faturasState.conciliacaoEmLote)
    let deletarFatura = useSelector(state => state.faturasState.revelarDeletarFatura);
    let excluirFatura = useSelector(state => state.faturasState.revelarExcluirFatura);
    let abrirTelaConciliacao = useSelector(state => state.faturasState.revelarAbrirTelaConciliacao)
    let contestarFatura =  useSelector(state => state.faturasState.revelarContestarFatura);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);
    let revelarExibirColunasFaturas = useSelector(state => state.faturasState.revelarExibirColunasFaturas);
    let revelarRecalcular = useSelector(state => state.faturasState.revelarRecalcularFatura)
    let revelarRecalcularReferencia = useSelector(state => state.faturasState.revelarRecalcularFaturaReferencia)
    let revelarRecalcularConta = useSelector(state => state.faturasState.revelarRecalcularFaturaConta)
    let revelarRecalcularEmpresa = useSelector(state => state.faturasState.revelarRecalcularFaturaEmpresa)
    let relatorioFatura = useSelector(state => state.faturasState.revelarRelatorioFatura);
    let controladorRelatorioFatura = useSelector(state => state.faturasState.revelarRelatorioPreviewFatura);
    let controladorRelatorioNotaFiscalGeral = useSelector(state => state.faturasState.revelarRelatorioNotaFiscalGeral);
    let limparFiltros = useSelector(state => state.filtrosState.limparFiltros);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let temFiltros = false;
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    const despacho = useDispatch();
    const cd_pav_usuario = decryptJWT('codigo')
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(772) // 'fatFaturasTabela'
            setColetaUsabilidade(false)
        }
    },[])

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(true));
        setPaginaAtual(1)
    }

    useEffect(()=>{
        async function buscarDadosRelatorioFaturas(){
            if(controladorRelatorioFatura === "XLS" || controladorRelatorioFatura ===  "XLSX" || controladorRelatorioFatura ===  "CSV"){
                const dados = {
                    codigo_cliente: decryptJWT('codigoCliente'),
                    codigo_usuario: decryptJWT('codigo'),
                    descricao: 'Relatório de preview de faturas',
                    tipo_arquivo: controladorRelatorioFatura,
                    tipo_relatorio: 8,
                    filtros: filtros,
                    buscar: conteudoBusca,
                    separador: '',
                    contar: false,
                    arrayContas: decryptJWT("fatContas"),
                    cdFatura: cdFatura
                }
                api.post('/m1/cadastrar/relatorios',dados)
                setMensagemInformacaoTitulo("O arquivo está sendo gerado")
                setMensagemInformacaoCorpo("Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível")
                handleMensagemInformacao(true)
                despacho(revelarRelatorioPreviewFatura(false))
            }else{
                despacho(revelarRelatorioPreviewFatura(false))
            }
        }
        buscarDadosRelatorioFaturas()
    }, [controladorRelatorioFatura])

    useEffect(()=>{
        async function buscarDadosRelatorioFaturas(){
            if(relatorioFatura === "XLS" || relatorioFatura ===  "XLSX" || relatorioFatura ===  "CSV"){
                const dados = {
                    codigo_cliente: decryptJWT('codigoCliente'),
                    codigo_usuario: decryptJWT('codigo'),
                    descricao: 'Relatório de faturas',
                    tipo_arquivo: relatorioFatura,
                    tipo_relatorio: 7,
                    filtros: filtros,
                    buscar: conteudoBusca,
                    separador: '',
                    contar: false,
                    arrayContas: decryptJWT("fatContas"),
                    cdFatura: cdFatura
                }
                api.post('/m1/cadastrar/relatorios',dados)
                setMensagemInformacaoTitulo("O arquivo está sendo gerado")
                setMensagemInformacaoCorpo("Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível")
                handleMensagemInformacao(true)
                despacho(revelarRelatorioFatura(false))
            }else{
                despacho(revelarRelatorioFatura(false))
            }
        }
        buscarDadosRelatorioFaturas()
    }, [relatorioFatura])

    useEffect(()=>{
        async function buscarDadosRelatorioNotaFiscalGeral(){
            if(controladorRelatorioNotaFiscalGeral === "XLS" || controladorRelatorioNotaFiscalGeral ===  "XLSX" || controladorRelatorioNotaFiscalGeral ===  "CSV"){
                const dados = {
                    codigo_cliente: decryptJWT('codigoCliente'),
                    codigo_usuario: decryptJWT('codigo'),
                    descricao: 'Relatório de notas fiscais geral',
                    tipo_arquivo: controladorRelatorioNotaFiscalGeral,
                    tipo_relatorio: 13,
                    filtros: filtros,
                    buscar: conteudoBusca,
                    separador: '',
                    contar: false,
                    arrayContas: decryptJWT("fatContas")
                }
                api.post('/m1/cadastrar/relatorios',dados)
                setMensagemInformacaoTitulo("O arquivo está sendo gerado")
                setMensagemInformacaoCorpo("Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível")
                handleMensagemInformacao(true)
                despacho(revelarRelatorioNotaFiscalGeral(false))
            }else{
                despacho(revelarRelatorioNotaFiscalGeral(false))
            }
        }
        buscarDadosRelatorioNotaFiscalGeral()
    }, [controladorRelatorioNotaFiscalGeral])

    useEffect(()=>{
        restaurarConfigsIniciaisPagina()
    },[ordenador])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio' || filtros.vazio === undefined) {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(limparFiltros){
            if(!filtros.vazio){
                despacho(mudarFiltros({vazio: 'vazio'}))
                despacho(revelarBarraFiltragem(false))
                despacho(limpadorFiltros(false))
            }
        }
    }, [])
    
    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("fatFaturas"));
        despacho(desabilitarAcoes(true));

        async function carregarTabela(){
            await api.post("m1/cadastrar/fat_faturas/faturas_novas", {
                contas: Array.isArray(decryptJWT("fatContas"))?decryptJWT("fatContas"):decryptJWT("fatContas").split(',')
            })

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            } 

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    despacho(RevelarBuscarEFiltro(false))
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const queryString = 'paginaAtual='+contador+'&carregarLimit='+valorLimite+'&ordenador='+ordenador+'&buscar='+conteudoBusca+'&arrayContas='+decryptJWT("fatContas")
                    
                    const { data } = await api.get('/m1/consultar/fat_faturas?'+queryString);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    despacho(RevelarBuscarEFiltro(false))
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    filtros: filtros,
                    ordenador: ordenador,
                    arrayContas: decryptJWT("fatContas")
                }
                if (controladorPagina <= paginaAtual) {
                    
                    const { data } = await api.post("/m1/filtrar/fat_faturas", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    despacho(RevelarBuscarEFiltro(false))
                    await contarItens();
                    despacho(RevelarBuscarEFiltro(true))
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    
                    const queryString = 'paginaAtual='+contador+'&ordenador='+ordenador+'&carregarLimit='+valorLimite+'&arrayContas='+decryptJWT("fatContas")
                    const { data } = await api.get('/m1/consultar/fat_faturas?'+queryString);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
        contarItens()

    }, [paginaAtual, conteudoBusca, renderizar, filtros, ordenador])

    function inserirData(data){
        
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)
    }

    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual && filtros.vazio) {
            
            apiData = await api.get('/m1/consultar/fat_faturas?contar="true"&ordenador='+ordenador+'&buscar='+conteudoBusca+'&arrayContas='+decryptJWT("fatContas"));
        }else{
            const dados = {
                paginaAtual: contador,
                carregarLimit: valorLimite,
                buscar:conteudoBusca,
                filtros: filtros,
                contar: true,
                ordenador:ordenador,
                arrayContas: decryptJWT("fatContas")
            }
            
            apiData = await api.post("/m1/filtrar/fat_faturas", dados);
        }
        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }


    const colunas = [
        {
            dataField: 'cd_fat_fatura',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("cd_fat_fatura",'cd_fat_fatura')}>
            Cód. Fatura
            <Image id="cd_fat_faturacd_fat_fatura" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : -1}</p>;
            }
        },
        {
            dataField: 'ds_conta',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_conta",'ds_conta')}>
            Conta
            <Image id="ds_contads_conta" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : -1}</p>;
            }
        },
        {
            dataField: 'ds_apelido',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_apelido",'ds_apelido')}>
            Operadora
            <Image id="ds_apelidods_apelido" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : -1}</p>;
            }
        },
        {
            dataField: 'ds_fornecedor',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_fornecedor",'ds_fornecedor')}>
            Fornecedor
            <Image id="ds_fornecedords_fornecedor" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : -1}</p>;
            }
        },
        {
            dataField: 'dt_vencimento',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_vencimento",'dt_vencimento')}>
            Vencimento
            <Image id="dt_vencimentodt_vencimento" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return formatarData(cell,true);
            },
        },
        {
            dataField: 'ds_mes_referencia',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_mes_referencia",'ds_mes_referencia')}>
            Referência
            <Image id="ds_mes_referenciads_mes_referencia" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : -1}</p>;
            },
        },
        {
            dataField: 'cd_fat_fatura_status',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("cd_fat_fatura_status",'cd_fat_fatura_status')}>
            Status
            <Image id="cd_fat_fatura_statuscd_fat_fatura_status" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                if(cell === 1){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-verde"/><p className="fonte-cor-1 label-tabela-1">Paga</p></div>);
                }else if(cell === 2){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-vermelha"/><p className="fonte-cor-1 label-tabela-1">Vencida</p></div>);
                }else if(cell === 3){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-azul"/><p className="fonte-cor-1 label-tabela-1">Contestada</p></div>);
                }else{
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-amarela"/><p className="fonte-cor-1 label-tabela-1">Aberta</p></div>);
                }
            }
        },
        {
            dataField: 'dt_associacao',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_associacao",'dt_associacao')}>
            Febraban
            <Image id="dt_associacaodt_associacao" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return formatarData(cell,true);
            },
        },
        {
            dataField: 'dt_calculado',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_calculado",'dt_calculado')}>
            Último recálculo
            <Image id="dt_calculadodt_calculado" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {                         
                return formatarData(cell,true);
            },
        },
        {
            dataField: 'dt_conciliacao',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_conciliacao",'dt_conciliacao')}>
            Última conciliação
            <Image id="dt_conciliacaodt_conciliacao" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return formatarData(cell);
            },
        },
        {
            dataField: 'dt_inicio_ciclo',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_inicio_ciclo",'dt_inicio_ciclo')}>
            Início do ciclo
            <Image id="dt_inicio_ciclodt_inicio_ciclo" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {                         
                return formatarData(cell,true);
            },
        },
        {
            dataField: 'dt_fim_ciclo',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_fim_ciclo",'dt_fim_ciclo')}>
            Fim do ciclo
            <Image id="dt_fim_ciclodt_fim_ciclo" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {                         
                return formatarData(cell,true);
            },
        },
        {
            dataField: 'n0_qtd_cobrada',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n0_qtd_cobrada",'n0_qtd_cobrada')}>
            Total de linhas
            <Image id="n0_qtd_cobradan0_qtd_cobrada" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : '-'}</p>;
            }
        },
        {
            dataField: 'ticketMedio',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ticketMedio",'ticketMedio')}>
            Ticket médio
            <Image id="ticketMedioticketMedio" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                const valor = parseFloat((row.n2_valor_total / row.n0_qtd_cobrada));
                return <p className="fonte-cor-1 label-tabela-1">R$ {(!valor || isNaN(valor)) ? '0.00' : formatarMilhar(valor)} </p>;
            },
        },
        {
            dataField: 'n2_valor_previsto',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n2_valor_previsto",'n2_valor_previsto')}>
            Valor previsto
            <Image id="n2_valor_previston2_valor_previsto" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1">R$ {(!valor || isNaN(valor)) ? '0.00' : formatarMilhar(valor)} </p>;
                
            },
        },
        {
            dataField: 'n2_valor_cobrado',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n2_valor_cobrado",'n2_valor_cobrado')}>
            Valor cobrado
            <Image id="n2_valor_cobradon2_valor_cobrado" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1">R$ {(!valor || isNaN(valor)) ? '0.00' : formatarMilhar(valor)} </p>;
            },
        },
        {
            dataField: 'valorDiferenca',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("valorDiferenca",'valorDiferenca')}>
            Valor diferença
            <Image id="valorDiferencavalorDiferenca" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                const valor = (parseFloat(row.n2_valor_previsto - row.n2_valor_cobrado));
                if(valor>0){
                    return <p className="fonte-cor-2 label-tabela-1">R$ {formatarMilhar(valor)}</p>;
                }else if(valor<0){
                    return <p className="fonte-cor-3 label-tabela-1">R$ {formatarMilhar(valor)}</p>;
                }else{
                    return <p className="fonte-cor-1 label-tabela-1">R$ {formatarMilhar(valor)}</p>;
                }
            },
        },
        {
            dataField: 'n2_valor_pago',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n2_valor_pago",'n2_valor_pago')}>
            Valor pago
            <Image id="n2_valor_pagon2_valor_pago" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1">R$ {(!valor || isNaN(valor)) ? '0.00' : formatarMilhar(valor)} </p>;
            },
        },
        {
            dataField: 'dt_contestacao',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_contestacao",'dt_contestacao')}>
            Data de contestação
            <Image id="dt_contestacaodt_contestacao" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {                         
                return formatarData(cell,true);
            },
        },
        {
            dataField: 'ds_protocolo_contestacao',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_protocolo_contestacao",'ds_protocolo_contestacao')}>
            Protocolo de contestação
            <Image id="ds_protocolo_contestacaods_protocolo_contestacao" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {                         
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : '-'}</p>;
            },
        },
        {
            dataField: 'status_contestacao',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("status_contestacao",'status_contestacao')}>
            Status da contestação
            <Image id="status_contestacaostatus_contestacao" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {                         
                if(cell === 2){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-verde"/><p className="fonte-cor-1 label-tabela-1">Concluída</p></div>);
                }else if(cell === 3){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-vermelha"/><p className="fonte-cor-1 label-tabela-1">Cancelada</p></div>);
                }else if(cell === 4){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-azul"/><p className="fonte-cor-1 label-tabela-1">Em Andamento</p></div>);
                }else if(cell === 1){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-amarela"/><p className="fonte-cor-1 label-tabela-1">Aberta</p></div>);
                }else{
                    return <p className="fonte-cor-1 label-tabela-1">-</p>;
                }
            },
        },
        {
            dataField: 'dt_filtro',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_filtro",'dt_filtro')}>
            Data de filtro
            <Image id="dt_filtrodt_filtro" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {                         
                return formatarData(cell,true);
            },
        },
        {
            dataField: 'dt_pagamento',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_pagamento",'dt_pagamento')}>
            Data de pagamento
            <Image id="dt_pagamentodt_pagamento" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {                         
                return formatarData(cell,true);
            },
        },
    ]

    const itemExpandido = {
        className: 'fundo-cor-1',
        renderer: (row, rowIndex) => (
            <div className={estadoExpansaoMenu===true?"expand-row-fat-faturas-1":"expand-row-fat-faturas-2"}>
                <Detalhamento 
                    cdFatura={row.cd_fat_fatura}
                    setCdFatura={setCdFatura}
                    setMensagemInformacaoTitulo={setMensagemInformacaoTitulo}
                    setMensagemInformacaoCorpo={setMensagemInformacaoCorpo}
                    handleMensagemInformacao={handleMensagemInformacao}
                />
            </div>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="tela-background"></div>
        ),
        expandColumnRenderer: ( rowKey ) => {
            return (
                <Button className="botao-expandir-row" onClick={e => GirarSetaDropdown( rowKey.rowKey )}>
                    <Image id={ rowKey.rowKey } className="icone-botao-expandir-row nao-selecionavel" src={setaDropdown()}/>
                </Button>
            );
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        headerClasses: 'tabela-coluna-primeira-header',
    };

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));        
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionarFatura(
                    row.cd_fat_fatura,
                    row.cd_fat_conta,
                    row.ds_conta,
                    row.cd_fat_fatura_status,
                    row.dt_vencimento,
                    row.dt_inicio_ciclo,
                    row.dt_fim_ciclo,
                    row.dt_contestacao,
                    row.ds_protocolo_contestacao,
                    row.dt_filtro,
                    row.ds_mes_referencia,
                    row.dt_pagamento,
                    row.n2_valor_pago,
                    row.dt_associacao,
                    row.cd_dsp_operadora,
                    row.n0_dia_vencimento,
                    row.n0_dia_inicial_ciclo,
                    row.status_contestacao,
                    row.n2_valor_total
                )
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    function handleSelecionarFatura(cdFatura, cdConta, dsConta, cdStatus, dtVencimento,
        dtInicioCiclo, dtFimCiclo, dtContestacao, dsProtocolo, dtFiltro, dsReferencia,
        dtPagamento, n2ValorPago, dtAssociacao, cdOperadora, nDiaVencimento, nDiaInicio, statusContestacao,n2ValorTotal
        ){
        setCdFatura(cdFatura)
        despacho(valorCdFatura(cdFatura))
        setCdConta(cdConta)
        setDsConta(dsConta)
        setCdStatus(cdStatus)
        if(cdStatus === 1){
            setDsStatus('Paga')
        }else if(cdStatus === 2){
            setDsStatus('Vencida')
        }else if(cdStatus === 3){
            setDsStatus('Contestada')
        }else{
            setDsStatus('Aberta')
        }
        
        setdtVencimento(dtVencimento)
        setDtInicioCiclo(dtInicioCiclo)
        setDtFimCiclo(dtFimCiclo)
        setDtContestacao(dtContestacao)
        setDsProtocolo(dsProtocolo)
        setDtFiltro(dtFiltro)
        setDsReferencia(dsReferencia)
        setDtPagamento(dtPagamento)
        setN2ValorPago(n2ValorPago)
        setDtAssociacaoFebraban(dtAssociacao)
        setDtAssociacao(dtAssociacao)
        setCdOperadora(cdOperadora)
        setDiaVencimento(nDiaVencimento);
        setDiaInicio(nDiaInicio);
        setCdStatusContestacao(statusContestacao);
        setN2ValorTotal(n2ValorTotal)
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                setRenderizar(!renderizar)
            }
        }
    }

    function handleMensagemSucessoRecalcular(confirmacao){
        if(revelarMensagemSucessoRecalcular === false){
            setRevelarMensagemSucessoRecalcular(true)
        }else{
            setRevelarMensagemSucessoRecalcular(false)
            if(confirmacao === true){
                setRenderizar(!renderizar)
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            despacho(revelarDeletarFatura(false))
            if(confirmacao === true){
                handleDeletar()
            }
        }
    }

    function handleMensagemAtencaoEdicao(confirmacao){
        if(revelarMensagemAtencaoEdicao === false){
            setRevelarMensagemAtencaoEdicao(true)
        }else{
            setRevelarMensagemAtencaoEdicao(false)
            if(confirmacao === true){
                setRevelarModalEdicao(true)
            }else{
                despacho(revelarEditarFatura(false))
            }
        }
    }

    function handleMensagemAtencaoRecalcular(confirmacao){
        if(revelarMensagemAtencaoRecalcular === false){
            setRevelarMensagemAtencaoRecalcular(true)
        }else{
            setRevelarMensagemAtencaoRecalcular(false)
            despacho(revelarRecalcularFatura(false))
            if(confirmacao === true){
                handleRecalcularFatura()
            }
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    async function handleDeletar(){
        setSpinAtivo(true)
        await api.get('/m1/deletar/fat_faturas?cd_fat_fatura='+cdFatura)
        .then(function (response) {
            despacho(revelarDeletarFatura(false))
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)

        })
        .catch(function (error) {
            setMensagemErro("Erro ao deletar fatura!")
            despacho(revelarDeletarFatura(false))
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        })
    }

    function handleRecalcularFatura(){
        setSpinAtivo(true)
        api.post("m1/cadastrar/fat_faturas/fatura_preview", {
            "cd_fat_fatura": cdFatura
        })
        .then(async function (resposta) {
            api.post('/m1/cadastrar/fat_faturas_historico', {
                'cd_fat_fatura':cdFatura,
                'cd_fat_fatura_historico_tipo':3,
                'n2_valor_cobrado':resposta.data.dados[0].n2_valor_total,
                'cd_pav_usuario_created_at':cd_pav_usuario
            })
            setRevelarMensagemSucessoRecalcular(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            
            setMensagemErro("Erro ao recalcular fatura")
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        })
    }

    useEffect(() => {
        if(abrirTelaConciliacao){
            if(dtAssociacaoFebraban === null){
                despacho(revelarAbrirTelaConciliacao(false))
                setMensagemInformacaoTitulo('Informação')
                setMensagemInformacaoCorpo('Esta fatura não possui febraban associado')
                handleMensagemInformacao(true)
            }
        }
    }, [abrirTelaConciliacao])

    useEffect(()=>{
        if(contestarFatura === true){
            if((dtAssociacao !== '' && dtAssociacao !== null && dtAssociacao !== undefined) && (cdStatusContestacao === '' || cdStatusContestacao === null || cdStatusContestacao === undefined || cdStatusContestacao === 2 || cdStatusContestacao === 3)){
                
                setRevelarModalContestacao(true)
            }else{
                
                despacho(revelarContestarFatura(false))
                setRevelarModalContestacao(false)
                setRevelarMensagemInformacao(true)
            }
        }
    },[revelarModalContestacao, contestarFatura])

    useEffect(() => {
        if(revelarRecalcular){
            setRevelarMensagemAtencaoRecalcular(true)
        }
    }, [revelarRecalcular])

    useEffect(() => {
        if(editarFatura === true && cdStatus === 1){
            setRevelarMensagemAtencaoEdicao(true)
        }else if(editarFatura === true && (cdStatus === 2 || cdStatus === 3|| cdStatus === 4)){
            setRevelarModalEdicao(true)
        }
    },[editarFatura])

    return(
        <div className='container-tela'>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"A fatura "}
                            mensagemParagrafo2={"cód. "+ cdFatura +" foi deletada."}
                            mensagemBotao={"OK"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemSucessoRecalcular &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucessoRecalcular}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"A fatura "}
                            mensagemParagrafo2={"cód. "+ cdFatura +" foi recalculada."}
                            mensagemBotao={"Confirmar"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemAtencaoRecalcular &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">  
                        <MensagemAtencao 
                            handleMensagemAtencao={handleMensagemAtencaoRecalcular}
                            mensagemTitulo={"Recalcular"}
                            mensagemParagrafo1={"Tem certeza que deseja recalcular"}
                            mensagemParagrafo2={"a fatura " + cdFatura + "?"}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoEdicao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">  
                        <MensagemAtencao 
                            handleMensagemAtencao={handleMensagemAtencaoEdicao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={"Você está prestes a editar uma fatura que já está considerada 'finalizada'"}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Ocorreu um erro. Tente novamente mais tarde."}
                            mensagemParagrafo2={"Caso o erro persista, entre em contato com o Suporte."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { deletarFatura === true &&
                <MensagemConfirmarExclusao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Deletar fatura "+cdFatura}
                />
            }
            { cadastrarFatura &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroFatura
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                            tipoModal={'Cadastrar'}
                            setRevelarModalEdicao={setRevelarModalEdicao}
                        />
                        </div>
                </div>
            }
            { conciliacaoEmLote &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <ConciliacaoEmLote
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                            // setRevelarModalEdicao={setRevelarModalEdicao}
                        />
                        </div>
                </div>
            }
            { revelarModalContestacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <ContestarFatura
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            cdFatura={cdFatura}
                            cdOperadora={cdOperadora}
                            setRevelarModalContestacao={setRevelarModalContestacao}
                            n2ValorTotal={n2ValorTotal}
                        >
                        </ContestarFatura>
                    </div>
                </div>
            }
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}  
                            mensagemTitulo={mensagemInformacaoTitulo}
                            mensagemParagrafo1={mensagemInformacaoCorpo}
                            mensagemBotao={"Voltar"}
                        /> 
                    </div>
                </div>
            }
            { revelarModalEdicao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroFatura
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                            tipoModal={'Editar'}
                            cdFatura={cdFatura}
                            cdConta={cdConta}
                            dsConta={dsConta}
                            cdStatus={cdStatus}
                            dsStatus={dsStatus}
                            dtVencimento={dtVencimento}
                            dtInicioCiclo={dtInicioCiclo}
                            dtFimCiclo={dtFimCiclo}
                            dtContestacao={dtContestacao}
                            dsProtocolo={dsProtocolo}
                            dtFiltro={dtFiltro}
                            dsReferencia={dsReferencia}
                            dtPagamento={dtPagamento}
                            n2ValorPago={n2ValorPago}
                            diaVencimento={diaVencimento}
                            diaInicio={diaInicio}
                            setRevelarModalEdicao={setRevelarModalEdicao}
                        />
                    </div>
                </div>
            }
            { abrirTelaConciliacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <Conciliacao
                            cdFatura={cdFatura}
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                        />
                    </div>
                </div>
            }
            { revelarRecalcularReferencia &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <RecalcularFaturaReferencia
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                        />
                    </div>
                </div>
            }
            { revelarRecalcularConta &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <RecalcularFaturaConta
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                        />
                    </div>
                </div>
            }
            { revelarRecalcularEmpresa &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <RecalcularFaturaEmpresa
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                        />
                    </div>
                </div>
            }
            { revelarExibirColunasFaturas === true &&
                <>
                    <div className="componente-modal-overlay">
                        <div className="componente-modal">
                            <PersonalizarColunasFaturas
                                render={props.render}
                                setRender={props.setRender}
                                setRenderizar={setRenderizar}
                                renderizar={renderizar}
                            >
                            </PersonalizarColunasFaturas>
                        </div>
                    </div>
                </>
            }

            <BarraDadosCliente/>
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_fat_fatura' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                expandRow={itemExpandido}
                                selectRow={selecaoLinhas}
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                           <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        conteudoBusca: state.manejadorState.conteudoBusca, 
        filtros: state.filtrosState.filtros, 
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaFaturas));