import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Button, Col } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
//COMPONENTES
import {api} from '../../conexoes/api'
import decryptJWT from '../../utils/decryptJWT'

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'


export default function FiltrosVirtueyesCliente(props) {
    const [filtroStatus , setFiltroStatus] = useState('')
    const [filtroPerfil , setFiltroPerfil] = useState('')
    const [perfils , setPerfils] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    async function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            if(filtros.STATUS == '1' ) setFiltroStatus("Ativo")
            if(filtros.STATUS == '0' ) setFiltroStatus("Inativo")
            setFiltroPerfil(filtros.PERFIL)
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
        handlerConsultarPerfil()
    }, []);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1268) /*painelUsuariosTabelaDropFiltrosFiltrar*/
        if(filtroPerfil !== '' && filtroPerfil)arrayFiltrosSimcard.push('Perfil: '+filtroPerfil.split('*')[0]+"?PERFIL")
        if(filtroStatus !== '' && filtroStatus)arrayFiltrosSimcard.push("Status: "+filtroStatus+"?STATUS")

        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    async function handlerConsultarPerfil(){
        let consultaPerfilPadrao = await api.get('/m1/consultar/perfil?fl_atribuivel=1&fl_customizado=0');
        let consulta = await api.get('/m1/consultar/perfil?cliente_relacionamento='+decryptJWT("codigoCliente"));
        if(consulta.data.status == 200){
            await consulta.data.dados.map((item_consultaPerfilPadrao,i)=>{
                consultaPerfilPadrao.data.dados.push(item_consultaPerfilPadrao)
            })
        }

        let objetoPerfils = consultaPerfilPadrao.data.dados.map((item,i) => {
            return (
                <>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <label className="label-radio-filtros fonte-cor-5" onChange={() => setFiltroPerfil(item.ds_pav_perfil+'*'+item.cd_pav_perfil)}>{item.ds_pav_perfil}
                        <input defaultChecked={handlerCheck("PERFIL",item.ds_pav_perfil+'*'+item.cd_pav_perfil)} type="radio" id={"PERFIL_"+item.cd_pav_perfil} name="PERFIL" value={item.ds_pav_perfil+'*'+item.cd_pav_perfil}/>
                        <span className="checkbox-filtros campo-texto-cor-3"></span>
                    </label>
                </>
            );
        })
        setPerfils(objetoPerfils)
    }

    
    useEffect(() => {
        
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroStatus=='') verificador.push(1)
            if(filtroPerfil=='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 2) return false
            return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()){
                props.setFoiSelecionado(true);
            }
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroStatus('')
            setFiltroPerfil('')
            props.setFoiLimpado(false)
        }
        
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroStatus,filtroPerfil,props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="drop-acoes-triangulo campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu"> 
                    {/* campo para o status  */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-status", 'imagem-filtro-status'); ColetaClickstream(1267) /*painelUsuariosTabelaDropFiltrosStatus*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Status </Form.Label>
                        <img id={"imagem-filtro-status"}className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-5" onChange={() => setFiltroStatus('Ativo')}>Ativo
                            <input defaultChecked={handlerCheck("STATUS","1")} type="radio" id="STATUS_ATIVO" name="STATUS" value="1"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-5" onChange={() => setFiltroStatus('Inativo')}>Inativo
                            <input defaultChecked={handlerCheck("STATUS","0")} type="radio" id="STATUS_INATIVO" name="STATUS" value="0"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}