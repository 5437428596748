import React, { useState } from "react";

import {api, api_s3} from '../../../conexoes/api';
import decryptJWT from '../../../utils/decryptJWT'
import ModalImportarArquivo from "../modalImportarArquivo/importarArquivo";
import { revelarAtivarLinhasAWS } from "../../../redux/actions";
import { montarXlsErros } from "../../../utils/validacaoImportarArquivo";

// import '../../../css/painelDispositivos/dspImportarDescricao/ImportarDesc.css';

let idInserido = '';

const dadosJSONXLS_dsp = [
    [
        "linha",
        "cod_cliente",
        "operadora"
    ],
    [
        "'",
        "'",
        "'"
    ]
]

const dadosJSONCSV_dsp = [
    {
        "linha": "'",
        "cod_cliente": "'",
        "operadora": "'"
    }
]

const cssClassesListaArquivosDsp = {
    div_class: "container-importar-descricao-lista-arquivos",
    p_class: "tituloLista-importar-arquivo",
    li_class: "lista-importar-arquivo",
    li_p_class: "listaNome-importar-arquivo",
}

export default function AtivacaoLinhasAWS(props) {
  const [dadosArquivoErros, setDadosArquivoErros] = useState(false);
  const [spinAtivo, setSpinAtivo] = useState(false);

  async function processUploadDsp(uploadedFile, thisObj) {
    // ColetaClickstream(??) // importar...Upload
    setSpinAtivo(true);
    let arquivos = uploadedFile
    let data = new FormData();
    data.append('file', arquivos.file);
    data.append('cd_usuario', decryptJWT('codigo'));
    
    data.append('cd_pav_evento_parametro_tipo', 7); // sem isso não funciona
    // OU
    // data.append('onlyReadFile', true);

    const config = {
            onUploadProgress: e => {
            const progress = parseInt(Math.round((e.loaded * 100) / e.total));
    
            thisObj.updateFile(uploadedFile.id, {
              progress
            });
          }
    }

    // await api_s3.post("s3/leitura/arquivo", data)
    await api_s3.post("s3/leitura/arquivo", data, config)
    .then(response => {
      setSpinAtivo(false);
      if (response.data.status === 200) {
        idInserido = response.data.idInserido
          thisObj.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data._id,
          url: response.data.url
        });
        thisObj.setState({
          dadosArquivo: thisObj.state.dadosArquivo.concat(response.data.dados)
        });
      }
      else{
        setSpinAtivo(false);
        thisObj.updateFile(uploadedFile.id, {
          error: true,
          uploaded: false,
        });
      }
      thisObj.updateFile(uploadedFile.id, {
        uploaded: true,
        id: response.data._id,
        url: response.data.url
      });
      
    })
    .catch((error) => {
      setSpinAtivo(false);
      thisObj.updateFile(uploadedFile.id, {
        error: true
      });
    });
  }

  async function uploadTabelaDsp() {
    setDadosArquivoErros(false) // limpar pra não gerar esse mesmo arquivo numa outra tentativa

    // ColetaClickstream(???) // importar...Confirmar

    const rota = "m1/cadastrar/linhas_aws";

    const body = {
      idInserido,
      'cd_pav_cliente': decryptJWT('codigoCliente'),
      'n0_codigo_cliente': decryptJWT('codigoClienteErpAntigo'),
    }

    return await api.post(rota, body)
  }

  function getMensagemErroSalvar(error) {
    const responseData = error?.response?.data;
    if(!responseData) return 'Erro no servidor';

    const { dados } = responseData;
    const dadosErros = dados?.erros;
    const qtdErros = dadosErros?.length;
    const { qtdItensCorretos } = dados;
    let mensagemErro;

    if(qtdErros) {
      setDadosArquivoErros(dadosErros); 
      
      if(qtdErros) {
        if(qtdItensCorretos) { // Erro em algumas linhas
          mensagemErro = `Foram encontrados erros em ${qtdErros} linhas do seu arquivo. Todas as demais linha foram ativadas. Clique em baixar para verificar e corrigir os erros encontrados.`;
        }
        else { // Erro em todas as linhas
          mensagemErro = 'Foram encontrados erros em TODAS as linhas do seu arquivo. Verifique seu arquivo e tente novamente.'
        }
      }
    } else {
      if(dados.mensagem) {
        return dados.mensagem;
      }
      else { // Erro não identificado
        mensagemErro = 'O arquivo contém erros que impedem a leitura pelo sistema, por favor verifique se seu arquivo está correto e envie novamente.';
      }
    }
    return mensagemErro;
  }

    return (
      <ModalImportarArquivo
        dispositivos
        ativacaoLinhasAWS
        apenasXLSX
        titulo="Ativação linhas - AWS"
        revelarModal={revelarAtivarLinhasAWS}
        setSpinAtivo={setSpinAtivo}
        spinAtivo={spinAtivo}
        dadosJSONXLS={dadosJSONXLS_dsp}
        dadosJSONCSV={dadosJSONCSV_dsp}
        processUpload={processUploadDsp}
        cssClassesListaArquivos={cssClassesListaArquivosDsp}
        renderizar={props.renderizar}
        setRenderizar={props.setRenderizar}
        uploadTabela={uploadTabelaDsp}
        getMensagemErroSalvar={getMensagemErroSalvar}
        temDadosArquivoErros={!!dadosArquivoErros}
        downloadArquivoErros={() => downloadArquivoErros(dadosArquivoErros)}
        mensagemSucesso={'Solicitação enviada com sucesso'}
      />
    )
}


function downloadArquivoErros(dadosArquivoErros) {
  const cabecalhoArquivoErros = [...dadosJSONXLS_dsp[0], 'ERROS', 'nº linha no arquivo']

  montarXlsErros([cabecalhoArquivoErros, ...dadosArquivoErros], 'Erros arquivo linhas - AWS')
}