import domtoimage from 'dom-to-image'
import {saveAs} from 'file-saver'


export function exportarImagemDashSimcards(grafico){
    let node = undefined
    let botaoMenuOpcoes = undefined
    let nomeArquivo = ''
    if(grafico === "exportarSimcardsSemComunicar"){
        botaoMenuOpcoes = document.querySelector("#grafico-simcards-sem-comunicar button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-simcards-sem-comunicar')
        nomeArquivo = 'Simcards-sem-comunicar.png'
    }
    else if(grafico === "exportarSimcardsOnline"){ 
        botaoMenuOpcoes = document.querySelector("#grafico-simcards-online button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-simcards-online')
        nomeArquivo = 'Simcards-online.png'
    }
   else  if(grafico === "exportarSimcardsOffline"){
        botaoMenuOpcoes = document.querySelector("#grafico-simcards-offline button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-simcards-offline')
        nomeArquivo = 'Simcards-offline.png'
    }
    else  if(grafico === "exportarSimcardsComPacoteExtra"){
        botaoMenuOpcoes = document.querySelector("#grafico-simcards-pacote-extra button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-simcards-pacote-extra')
        nomeArquivo = 'Simcards-com-pacote-extra.png'
    }
    else  if(grafico === "exportarStatusDeTrafego"){
        botaoMenuOpcoes = document.querySelector("#grafico-status-trafego button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-status-trafego')
        nomeArquivo = 'Status-de-trafego.png'
    }
    else  if(grafico === "exportarCicloAtual"){
        botaoMenuOpcoes = document.querySelector("#grafico-ciclo-atual button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-ciclo-atual')
        nomeArquivo = 'Ciclo-atual.png'
    }
    else  if(grafico === "exportarTotalDeSimcards"){
        botaoMenuOpcoes = document.querySelector("#grafico-simcards-total button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-simcards-total')
        nomeArquivo = 'Total-simcards.png'
    }
    else  if(grafico === "exportarStatusDeContrato"){
        botaoMenuOpcoes = document.querySelector("#grafico-status-contrato button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-status-contrato')
        nomeArquivo = 'Status-de-contrato.png'
    }
    else  if(grafico === "exportarPlanosContratados"){
        botaoMenuOpcoes = document.querySelector("#grafico-planos-contratados button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-planos-contratados')
        nomeArquivo = 'Planos-contratados.png'
    }

    domtoimage.toBlob(node)
        .then(function (blob) {
            saveAs(blob, nomeArquivo)
            botaoMenuOpcoes.style.visibility = "visible";
        })
        .catch(function (error) {
            console.error('Erro ao exportar imagem', error);
    });
}

export function exportarImagemDashFaturas(grafico){ 
    let node = undefined
    let botaoMenuOpcoes = undefined
    let nomeArquivo = ''

    if(grafico === "grafico-faturas-por-status"){
        botaoMenuOpcoes = document.querySelector("#grafico-faturas-por-status button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-faturas-por-status')
        nomeArquivo = 'Faturas-por-status.png'
    }
    else if(grafico === "grafico-faturas-aberto-por-operadora"){
        botaoMenuOpcoes = document.querySelector("#grafico-faturas-aberto-por-operadora button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-faturas-aberto-por-operadora')
        nomeArquivo = 'Faturas-aberto-por-operadora.png'
    }
    else if(grafico === "grafico-faturas-em-aberto-por-operadora"){
        botaoMenuOpcoes = document.querySelector("#grafico-faturas-em-aberto-por-operadora button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-faturas-em-aberto-por-operadora')
        nomeArquivo = 'Faturas-aberto-por-operadora.png'
    }
    else if(grafico === "grafico-simcards-que-nao-estao-faturanto-irao-faturar"){
        botaoMenuOpcoes = document.querySelector("#grafico-simcards-que-nao-estao-faturanto-irao-faturar button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-simcards-que-nao-estao-faturanto-irao-faturar')
        nomeArquivo = 'Faturas-aberto-por-operadora.png'
    }
    else if(grafico === "grafico-contestacoes-por-operadora"){
        botaoMenuOpcoes = document.querySelector("#grafico-contestacoes-por-operadora button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-contestacoes-por-operadora')
        nomeArquivo = 'Faturas-aberto-por-operadora.png'
    }
    else if(grafico === "grafico-valor-contestacoes-por-operadora"){
        botaoMenuOpcoes = document.querySelector("#grafico-valor-contestacoes-por-operadora button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-valor-contestacoes-por-operadora')
        nomeArquivo = 'Faturas-aberto-por-operadora.png'
    }
    else if(grafico === "grafico-simcards-por-operadora"){
        botaoMenuOpcoes = document.querySelector("#grafico-simcards-por-operadora button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-simcards-por-operadora')
        nomeArquivo = 'Faturas-aberto-por-operadora.png'
    }
    else if(grafico === "grafico-simcards-por-plano"){
        botaoMenuOpcoes = document.querySelector("#grafico-simcards-por-plano button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-simcards-por-plano')
        nomeArquivo = 'Faturas-aberto-por-operadora.png'
    }
    else if(grafico === "grafico-simcards-por-status-ciclo-vida"){
        botaoMenuOpcoes = document.querySelector("#grafico-simcards-por-status-ciclo-vida button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-simcards-por-status-ciclo-vida')
        nomeArquivo = 'Faturas-aberto-por-operadora.png'
    }

    domtoimage.toBlob(node)
        .then(function (blob) {
            saveAs(blob, nomeArquivo)
            botaoMenuOpcoes.style.visibility = "visible";
        })
        .catch(function (error) {
            console.error('Erro ao exportar imagem', error);
    });
}


export function exportarImagemDashSms(grafico){ 
    let node = undefined
    let botaoMenuOpcoes = undefined
    let nomeArquivo = ''

    if(grafico === "exportarSmsEnvios"){
        botaoMenuOpcoes = document.querySelector("#grafico-sms-envios button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-sms-envios')
        nomeArquivo = 'Sms-envios.png'
    }
    else if(grafico === "exportarSmsEstoque"){
        botaoMenuOpcoes = document.querySelector("#grafico-sms-estoque button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-sms-estoque')
        nomeArquivo = 'Sms-estoque.png'
    }
   else  if(grafico === "exportarSmsUltimaRecarga"){
        botaoMenuOpcoes = document.querySelector("#grafico-sms-ultima-recarga button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-sms-ultima-recarga')
        nomeArquivo = 'Sms-ultima-recarga.png'
    }
    else  if(grafico === "exportarSmsStatus"){
        botaoMenuOpcoes = document.querySelector("#grafico-sms-status button")
        botaoMenuOpcoes.click()
        botaoMenuOpcoes.style.visibility = "hidden";
        node = document.getElementById('grafico-sms-status')
        nomeArquivo = 'Sms-status.png'
    }

    domtoimage.toBlob(node)
        .then(function (blob) {
            saveAs(blob, nomeArquivo)
            botaoMenuOpcoes.style.visibility = "visible";
        })
        .catch(function (error) {
            console.error('Erro ao exportar imagem', error);
    });
}
