import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import {Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
// ICONES
import { elipseVerde, fecharJanela, exclamacao, setaDropdown } from '../../../../utils/alternanciaIcones'
// MODAIS
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import SelectTemplateSms from '../../../componentesModais/selects/selectTemplateSms'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch } from 'react-redux';
import {revelarEnviarSmsModulo} from "../../../../redux/actions/index";
import decryptJWT from '../../../../utils/decryptJWT'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'
//ESTILIZAÇÃO
import '../../../../css/painelSms/smsMensagens/enviarSms/enviarSms.css'
import utilsSms from '../../../../utils/sms';

export default function EnviarSms(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    // HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();

    const [dsCallerIds, setCallerIds] = useState('')
    const [dsMensagem, setDsMensagem] = useState('')
    const [dsTemplateSms, setDsTemplateSms] = useState('Selecione')
    const [saldoReal, setSaldoReal] = useState(0)
    const [creditosNecessarios, setCreditosNecessarios] = useState(0)
    const [caracteresMensagem, setCaracteresMensagem] = useState(120);
    //HOOKS MODAIS
    const [revelarSelectTemplateSms, setRevelarSelectTemplateSms] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");

    const cdSmsContaUsuario = utilsSms.getCdSmsContaUsuario();

    const despacho = useDispatch();
    const modal = useRef();
    
    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);
    
    useEffect(() => {

        async function carregarSaldo(){
            const query = utilsSms.getNewQueryGet("paginaAtual=0&carregarLimit=1")
            await api.get('/m1/consultar/smsExtrato?'+query)
                .then(function (response) {
                    if (response.data.status == 200 && response.data.dados[0] != undefined){
                        setSaldoReal(response.data.dados[0].n0_saldo_real)
                    } else {
                        setSaldoReal(0);
                    }
                })
        }
        carregarSaldo()

        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function handleCreditosGastos(){
         
        if(dsCallerIds && dsCallerIds != ''){
            let creditosGastos = dsCallerIds.split(';').length * 2 ;
            setCreditosNecessarios(creditosGastos)
        }
        else
           setCreditosNecessarios(0)
    }

    async function handleEnviar(){
        ColetaClickstream(1205) /*dropAcoesRevelarEnviarSmsModuloConfirmar*/
        setSpinAtivo(true)
        /*
        validação feita para avisar o usuário que só é possível caractéres alfa númericos,
        sem caractéres especiais tais quais +55(51)99999-5555
        */
        let arrayCallerIds = dsCallerIds.split(';');
        let regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        let arrayRegex = arrayCallerIds.map((callerId)=>{
            if(regex.test(callerId)){
                return true
            }else{
                return false
            }
        });

        if(arrayRegex.includes(true)){
            setMensagemErro("Utilize somente caracteres alfanuméricos.")
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        }else{
            if(creditosNecessarios <= saldoReal){
                const bodyEnviarSms = utilsSms.getNewBodyPost({
                    'lista_callerIds': arrayCallerIds,
                    'ds_mensagem': dsMensagem,
                    'cd_sms_tipo': 2 
                })

                await api.post('/m1/cadastrar/smsEnvio', bodyEnviarSms,).then(function (response) {
                    setRevelarMensagemSucesso(true)
                    setSpinAtivo(false)
                }).catch(function (error) {
                    setMensagemErro(error.response.data.info)
                    setRevelarMensagemErro(true)
                    setSpinAtivo(false)
                });
            }else{
                setMensagemErro("Créditos insuficientes")
                setRevelarMensagemErro(true)
                setSpinAtivo(false)
            }
        }
    }

    function handleCancelar(){
        ColetaClickstream(1204) /*dropAcoesRevelarEnviarSmsModuloCancelar*/
        despacho(revelarEnviarSmsModulo(false))
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    
    function handleMotivo(motivo){
        setDsMensagem(motivo)
        setCaracteresMensagem(120 - motivo.length)
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleEnviar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="modal-envio-sms fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Enviar</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Linhas</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira as linhas</Tooltip>}>
                                <Form.Control name="dsCallerIds" value={dsCallerIds} className="campo-texto-cor-3 campo-texto-area" as="textarea"
                                placeholder="Insira as linhas separadas por ; | Não é necessário colocar o 55 (DDI) do Brasil | Utilize somente caracteres alfanuméricos"
                                rows="2" maxlength="500" onChange={e =>(setCallerIds(e.target.value))} onBlur={e=>(handleCreditosGastos())}/>
                            </OverlayTrigger>
                            {errors.dsCallerIds && <p className="texto-campo-obrigatorio">{iconeAlerta} O campo é obrigatorio.</p>}
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-painel-sms-enviar-sms-1">
                            <div className="coluna-painel-sms-enviar-sms-2">
                                <Form.Label>
                                    <div className="fonte-cor-1 label-campo">Template </div>
                                </Form.Label>
                                { revelarSelectTemplateSms === false &&
                                    <Button name="cdTemplateSms" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTemplateSms(!revelarSelectTemplateSms)}>
                                        <div className="fonte-cor-1 label-campo">{dsTemplateSms}</div>
                                        <img className={revelarSelectTemplateSms?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                                { revelarSelectTemplateSms === true &&
                                    <SelectTemplateSms
                                        setRevelarSelectTemplateSms={setRevelarSelectTemplateSms} //Hook para fechar a modal
                                        setDsMensagem={setDsMensagem} 
                                        setDsTemplateSms={setDsTemplateSms} 
                                        dsTemplateSms={dsTemplateSms}
                                        cd_sms_conta_usuario={cdSmsContaUsuario}
                                    >
                                    </SelectTemplateSms>
                                }
                            </div>
                            <div className="coluna-painel-sms-enviar-sms-3">
                                <div className="grupo-painel-sms-enviar-sms-2">
                                    <div>
                                        <span className="label-valor-cancelamento-reposicao-1 fonte-cor-1 negrito">Créditos necessários: </span>
                                        <span className="label-valor-cancelamento-reposicao-2 fonte-cor-3 negrito">{creditosNecessarios}</span>
                                    </div>
                                    <div>
                                        <span className="label-valor-cancelamento-reposicao-1 fonte-cor-1 negrito">Créditos disponíveis: </span>  
                                        <span className="label-valor-cancelamento-reposicao-2 fonte-cor-2 negrito">{saldoReal} </span> 
                                    </div>     
                                </div>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Mensagem</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Digite sua mensagem</Tooltip>}>
                                <Form.Control name="dsMensagem" value={dsMensagem} className="campo-texto-cor-3 campo-texto-area" as="textarea"
                                placeholder="Digite sua mensagem"
                                rows="3" maxlength="1000" onChange={e => handleMotivo(e.target.value)}
                                ref={register({required: true})} maxlength="120"/>
                            </OverlayTrigger>
                            {errors.titulo && <p className="texto-campo-obrigatorio">{iconeAlerta} O campo é obrigatorio.</p>}
                            <Form.Label className="contador-carateres-restantes">
                                <div className="fonte-cor-1 label-normal">{caracteresMensagem} caracteres restantes</div>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button type="submit" className="botao-confirmar" variant="success" size="sm" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault()}}>
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"SMS Enviado."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}