//BIBLIOTECAS
import React, {useState, useEffect} from 'react';/*eslint-disable*/
import {Card, Button, Form, ButtonToolbar, Col, Accordion, Tooltip, OverlayTrigger} from 'react-bootstrap';/*eslint-disable*/
import {api, api_svdispositivos} from '../../../../conexoes/api'
import {useSelector} from 'react-redux'
import {validadorPermissaoElemento} from '../../../../utils/verificadorPermissaoElemento'
//COMPONENTES
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import SelectUf from '../../../componentesModais/selects/selectUf'
import {
    elipseVerde,
    cancelar,
    confirmar,
    confirmarDisabled,
    editarVerde,
    setaDropdown
} from "../../../../utils/alternanciaIcones";

// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'

import decryptJWT from '../../../../utils/decryptJWT'
import {isPortal} from "../../../../utils/nivelCliente";


let camposBanco = []

export default function DescricaoCliente(props) {

    const clientePortal = isPortal()
    const [camposPersonalizados, setCamposPersonalizados] = useState([])
    const [renderizar, setRenderizar] = useState(false)
    const [dsMarca, setDsMarca] = useState('')
    const [phMarca, setPhMarca] = useState('')
    const [dsModelo, setDsModelo] = useState('')
    const [phModelo, setPhModelo] = useState('')
    const [dsNome, setDsNome] = useState('')
    const [phNome, setPhNome] = useState('')
    const [dsEndereco, setDsEndereco] = useState('')
    const [phEndereco, setPhEndereco] = useState('')
    const [dsCidade, setDsCidade] = useState('')
    const [phCidade, setPhCidade] = useState('')
    const [dsCep, setDsCep] = useState('')
    const [phCep, setPhCep] = useState('')
    const [cdUf, setCdUf] = useState('')
    const [dsUf, setDsUf] = useState('Selecione')
    const [phUf, setPhUf] = useState('Selecione')
    const [dsInfoAdicionais, setDsInfoAdicionais] = useState('')
    const [phInfoAdicionais, setPhInfoAdicionais] = useState('')
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [desativarBotaoNovaInformacaoPersonalizada, setDesativarBotaoNovaInformacaoPersonalizada] = useState(false)
    const [revelarSelectUf, setRevelarSelectUf] = useState(false)
    const [existeInfoAdicional, setExisteInfoAdicional] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro. Tente novamente mais tarde.")
    const [editarTitulo, setEditarTitulo] = useState('')
    const [renderizarCampoPersonalizado, setRenderizarCampoPersonalizado] = useState(false)
    const [permissaoDescricaoEditar, setPermissaoDescricaoEditar] = useState()
    const [erroTitulo, setErroTitulo] = useState(false)
    const [dsMarcaModem, setDsMarcaModem] = useState('')
    const [dsModeloModem, setDsModeloModem] = useState('')

    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);

    const cd_simcard = props.cdSimcard;

    // let camposPersonalizadosDados = []

    useEffect(() => {
        async function handlerVerificarPermissaoElementos() {
            setPermissaoDescricaoEditar(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento/editarDescricao"))
        }

        handlerVerificarPermissaoElementos()
    }, [])


    useEffect(() => {
        async function buscarInfo() {
            setSpinAtivo(true)

            // const { data } = await api.get('/m1/consultar/informacoes_adicionais_customizadas_simcard?n0_msisdn=' + props.row[0].FULLCALLERID + "&n0_iccid=" + props.row[0].ICCID + '&cd_pav_cliente=' + decryptJWT('codigoCliente'));
            // camposBanco = data.dados;



            let CD_CLIENTE = decryptJWT('codigoCliente')
            let CLIENTE_CODIGO = decryptJWT('codigoClienteErpAntigo')
            if(clientePortal){
                CLIENTE_CODIGO = props.row[0].CLIENTE_CODIGO_N3;
            }

            const {data} = await api_svdispositivos.get(`/api/v2/devices/simcard/55${props.row[0].CALLERID}/description?n0_imei=${props.IMEI}&n0_iccid=${props.row[0].ICCID}&codigocliente=${CLIENTE_CODIGO}&cd_pav_cliente=${CD_CLIENTE}&cd_dsp_simcard=${props.row[0].CD_SIMCARD}`)

            const informacoesAdicionais = data?.InformacoesAdicionais
            camposBanco = data?.InformacoesAdicionaisCustomizadas

            if (informacoesAdicionais) {
                setExisteInfoAdicional(true)
                setDsCep(informacoesAdicionais.ds_cep)
                setPhCep("Original: " + informacoesAdicionais.ds_cep)
                setDsCidade(informacoesAdicionais.ds_cidade)
                setPhCidade("Original: " + informacoesAdicionais.ds_cidade)
                setDsNome(informacoesAdicionais.ds_cliente)
                setPhNome("Original: " + informacoesAdicionais.ds_cliente)
                setDsEndereco(informacoesAdicionais.ds_endereco)
                setPhEndereco("Original: " + informacoesAdicionais.ds_endereco)
                setDsInfoAdicionais(informacoesAdicionais.tx_obs)
                setPhInfoAdicionais("Original: " + informacoesAdicionais.tx_obs)
                setDsMarca(informacoesAdicionais.ds_marca)
                setPhMarca("Original: " + informacoesAdicionais.ds_marca)
                setDsModelo(informacoesAdicionais.ds_modelo)
                setPhModelo("Original: " + informacoesAdicionais.ds_modelo)
                setDsUf(informacoesAdicionais.ds_uf)
                setPhUf("Original: " + informacoesAdicionais.ds_uf)
                setDsMarcaModem(informacoesAdicionais.ds_marca_imei)
                setDsModeloModem(informacoesAdicionais.ds_modelo_imei)
            }

            if (camposBanco.length >= 3) {
                setDesativarBotaoNovaInformacaoPersonalizada(true)
            } else {
                setDesativarBotaoNovaInformacaoPersonalizada(false)
            }
            renderizarCamposPersonalizados()
            setSpinAtivo(false)
        }

        buscarInfo();

    }, [renderizar, permissaoDescricaoEditar])

    function handleCancelar() {
        setRenderizar(!renderizar)
    }

    useEffect(() => {
        renderizarCamposPersonalizados()
    }, [editarTitulo, renderizarCampoPersonalizado])

    function handleMensagemAtencao(confirmacao) {
        if (revelarMensagemAtencao === false) {
            setRevelarMensagemAtencao(true)
        } else {
            setRevelarMensagemAtencao(false)
        }
        if (confirmacao) {
            handlerSalvar()
        }
    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemAtencao === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)
        }
        if (confirmacao) {
            setRevelarMensagemSucesso(false)
            props.setRenderizar(!props.renderizar)
        }
    }

    function botaoPressionado(event, index) {
        if (event.key === "Enter") {
            handlerSalvarTitulo(index)
        }
    }

    async function handlerSalvarTitulo(index) {
        if (camposBanco[index].TITULO != "") {
            let verificador = " " + camposBanco[index].TITULO + " "
            await api.post('/m1/alterar/informacoes_adicionais_customizadas_cliente', {
                dados: {
                    'ds_titulo': verificador,
                    "n0_codigo_cliente": decryptJWT("codigoClienteErpAntigo"),
                },
                condicao: {
                    'cd_dsp_informacoes_adicionais_customizadas_cliente': camposBanco[index].ID_INFO_CLIENTE
                }
            })
        } else {
            setErroTitulo(true);
        }

        setEditarTitulo('')
        props.setRenderConsultaColunas(!props.renderConsultaColunas)
    }

    async function handlerChangeTitle(dado, id) {
        await camposBanco.map((item_camposBanco, ic) => {
            if (item_camposBanco.ID_INFO_CLIENTE == id) {
                item_camposBanco.TITULO = dado
            }
        })
        setRenderizarCampoPersonalizado(!renderizarCampoPersonalizado)
    }

    function renderizarCamposPersonalizados() {

        let a = 0
        let carregarCampos = camposBanco.map((i, index) => {

            let codigoCampo = i.ID_INFO_SIMCARD
            let valorCampo = i.DESCRICAO
            return (
                <Form.Row key={codigoCampo}>
                    <Col>
                        <Form.Group>
                            <div className="grupo-descricao-linha-personalizado-1">
                                <div className="coluna-descricao-linha-personalizado-1">
                                    {editarTitulo === i.ID_INFO_CLIENTE ?
                                        <Form.Control
                                            className="campo-texto-cor-personalizado-transparente"
                                            value={i.TITULO}
                                            key={codigoCampo}
                                            disabled={!permissaoDescricaoEditar}
                                            onKeyDown={e => botaoPressionado(e, index)}
                                            onChange={(e) => handlerChangeTitle(e.target.value, i.ID_INFO_CLIENTE)}
                                        />
                                        :
                                        <Form.Label className="label-campo-personalizado fonte-cor-1"
                                                    key={codigoCampo}>{i.TITULO}</Form.Label>
                                    }
                                    {erroTitulo &&
                                        <h6 className="mensagem-erro-login"> Preencha todos os campos! </h6>
                                    }
                                </div>
                                {editarTitulo === i.ID_INFO_CLIENTE ?
                                    <div className="coluna-descricao-linha-personalizado-2">
                                        <div classNam="grupo-salvar-cancelar-edicao-personalizado-titulo">
                                            {i.TITULO === '' ?
                                                <Button
                                                    className=" espacador-botoes-titulo-personalizado botao-adicionar-pedidos-novo-simcard margem-esquerda-pedidos-novo-simcard"
                                                    variant="success" size="sm">
                                                    <img className="icone-botao-adicionar-pedidos-novo-simcard"
                                                         src={confirmarDisabled()} alt="salvar"/>
                                                </Button>
                                                :
                                                <Button
                                                    className=" espacador-botoes-titulo-personalizado botao-adicionar-pedidos-novo-simcard margem-esquerda-pedidos-novo-simcard"
                                                    variant="success" size="sm"
                                                    onClick={() => handlerSalvarTitulo(index)}>
                                                    <img className="icone-botao-adicionar-pedidos-novo-simcard"
                                                         src={confirmar()} alt="salvar"/>
                                                </Button>
                                            }
                                            <Button
                                                className=" espacador-botoes-titulo-personalizado botao-adicionar-pedidos-novo-simcard"
                                                variant="success" size="sm"
                                                onClick={() => handleCancelarTitulo(i.TITULO)}>
                                                <img className="icone-botao-adicionar-pedidos-novo-simcard"
                                                     src={cancelar()} alt="cancelar"/>
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    <Button disabled={!permissaoDescricaoEditar}
                                            className=" espacador-botoes-titulo-personalizado-2 botao-editar-titulo-personalizado"
                                            variant="success" onClick={e => setEditarTitulo(i.ID_INFO_CLIENTE)}>
                                        <img className="icone-botao-editar-titulo-personalizado" src={editarVerde()}
                                             alt="editar"/>
                                    </Button>
                                }
                            </div>
                            <Form.Control disabled={!permissaoDescricaoEditar} className="campo-texto-cor-3 campo-texto"
                                          type="text" key={codigoCampo}
                                          defaultValue={valorCampo === undefined || valorCampo === "undefined" ? '' : valorCampo}
                                          onChange={e => manajeadorValoresCamposPersonalizados(i.ID_INFO_CLIENTE, e.target.value)}/>
                        </Form.Group>
                    </Col>
                </Form.Row>
            )
        })
        setCamposPersonalizados([...carregarCampos])
    }

    function handleCancelarTitulo() {
        setEditarTitulo('')
        setRenderizar(!renderizar)
    }

    async function editarCampoPersonalizado(DESCRICAO, ID_INFO_SIMCARD) {
        let verificador = DESCRICAO === undefined || DESCRICAO === 'undefined' ? '' : DESCRICAO
        await api.post('/m1/alterar/informacoes_adicionais_customizadas_simcard', {
            "dados": {
                'ds_descricao': verificador,
                "n0_codigo_cliente": decryptJWT("codigoClienteErpAntigo"),
            },
            "condicao": {
                'cd_dsp_informacoes_adicionais_customizadas_simcard': ID_INFO_SIMCARD,
            }
        },);
    }

    async function cadastrarCampoPersonalizado(DESCRICAO, ID_INFO_CLIENTE, i) {
        let verificador = DESCRICAO === undefined || DESCRICAO === 'undefined' ? '' : DESCRICAO
        await api.post('/m1/cadastrar/informacoes_adicionais_customizadas_simcard', {
            cd_dsp_simcard: cd_simcard,
            "n0_msisdn": props.row[0].FULLCALLERID,
            "n0_iccid": props.row[0].ICCID,
            "n0_codigo_cliente": decryptJWT("codigoClienteErpAntigo"),
            ds_descricao: verificador,
            cd_dsp_informacoes_adicionais_customizadas_cliente: ID_INFO_CLIENTE,
            'ds_ordem': i + 1
        },);
    }

    async function manajeadorValoresCamposPersonalizados(codigoItem, valorCampo) {
        await camposBanco.map((item_camposBanco, i) => {
            if (item_camposBanco.ID_INFO_CLIENTE === codigoItem) {
                item_camposBanco.DESCRICAO = valorCampo;
            }
        })
        setRenderizarCampoPersonalizado(!renderizarCampoPersonalizado)
    }

    async function handlerSalvar() {

        setSpinAtivo(true)

        // if (existeInfoAdicional) {
        const dados =
            {
                "cd_dsp_simcard": props.row[0].CD_SIMCARD,
                "tx_obs": dsInfoAdicionais,
                "ds_marca": dsMarca,
                "ds_modelo": dsModelo,
                "ds_info_coluna": dsInfoAdicionais,
                "ds_cliente": dsNome,
                "ds_uf": dsUf,
                "ds_cidade": dsCidade,
                "ds_endereco": dsEndereco,
                "ds_cep": dsCep,
                "n0_codigo_cliente": decryptJWT('codigoClienteErpAntigo')
            }
        const condicao = {
            "n0_msisdn": props.row[0].FULLCALLERID,
            "n0_iccid": props.row[0].ICCID,
            "n0_codigo_cliente": decryptJWT('codigoClienteErpAntigo')
        }
        const informacoesAdicionais = {dados, condicao}

        const informacoesAdicionaisCustomizadas = camposBanco.map((item, i) => {
            return {
                "dados": {
                    'ds_descricao': item.DESCRICAO,
                    "n0_codigo_cliente": decryptJWT("codigoClienteErpAntigo"),
                },
                "condicao": {
                    'cd_dsp_informacoes_adicionais_customizadas_simcard': item.ID_INFO_SIMCARD,
                }
            }
        })

        await api_svdispositivos.put(`/api/v2/devices/simcard/55${props.row[0].CALLERID}/description`, {
            informacoesAdicionaisCustomizadas,
            informacoesAdicionais
        })
            // await api.post('/m1/alterar/simcard_informacoes_adicionais', informacoesAdicionais)
            .then(function (response) {
                setRenderizar(!renderizar)
                setSpinAtivo(false)
                setRevelarMensagemSucesso(true)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setRevelarMensagemSucesso(true)
            });
    }

    function validarNumero(id, valor) {
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if (id === "campo-texto-cep") {
                setDsCep(valor)
            }
        }
    }

    return (
        <Form>
            {spinAtivo && <div className="componente-spinner-overlay">
                <div className="componente-spinner-container"><SpinerVeye/></div>
            </div>}
            <div className="container-descricao-linha">
                <div className="container-descricao-linha-telas">
                    <div className="container-descricao-linha-cliente fundo-cor-7">
                        <Form.Row>
                            <Col>
                                <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-janela">Cliente</div>
                                </Form.Label>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <div className="grupo-descricao-linha-cliente-1">
                                    <div className="coluna-descricao-linha-cliente-1">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Nome</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                                overlay={<Tooltip id={`tooltip-bottom`}>Nome do
                                                                    cliente</Tooltip>}>
                                                    <Form.Control disabled={!permissaoDescricaoEditar}
                                                                  placeholder={phNome === null || phNome === 'null' ? "" : phNome}
                                                                  value={dsNome}
                                                                  className="campo-texto-cor-3 campo-texto" type="text"
                                                                  maxlength="100"
                                                                  onChange={e => setDsNome(e.target.value)}/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="coluna-descricao-linha-cliente-2">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Endereço</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                                overlay={<Tooltip id={`tooltip-bottom`}>Endereço do
                                                                    cliente</Tooltip>}>
                                                    <Form.Control disabled={!permissaoDescricaoEditar}
                                                                  placeholder={phEndereco === null || phEndereco === 'null' ? "" : phEndereco}
                                                                  value={dsEndereco}
                                                                  className="campo-texto-cor-3 campo-texto" type="text"
                                                                  maxlength="100"
                                                                  onChange={e => setDsEndereco(e.target.value)}/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <div className="grupo-descricao-linha-cliente-2">
                                    <div className="coluna-descricao-linha-cliente-3">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Cidade</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                                overlay={<Tooltip id={`tooltip-bottom`}>Cidade do
                                                                    cliente</Tooltip>}>
                                                    <Form.Control disabled={!permissaoDescricaoEditar}
                                                                  placeholder={phCidade === null || phCidade === 'null' ? "" : phCidade}
                                                                  value={dsCidade}
                                                                  className="campo-texto-cor-3 campo-texto" type="text"
                                                                  maxlength="100"
                                                                  onChange={e => setDsCidade(e.target.value)}/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="coluna-descricao-linha-cliente-4">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Estado</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                {revelarSelectUf === false &&
                                                    <Button disabled={!permissaoDescricaoEditar}
                                                            className="campo-texto-cor-3 campo-select"
                                                            onClick={() => setRevelarSelectUf(!revelarSelectUf)}>
                                                        <div className="fonte-cor-1 label-campo">{dsUf}</div>
                                                        <img
                                                            className={revelarSelectUf ? "campo-select-icone-ativado nao-selecionavel" : "campo-select-icone-desativado nao-selecionavel"}
                                                            src={setaDropdown()} alt="drop"/>
                                                    </Button>
                                                }
                                                {revelarSelectUf === true &&
                                                    <SelectUf
                                                        setRevelarSelectUf={setRevelarSelectUf} //Hook para fechar a modal
                                                        setDsUf={setDsUf} //Nome do Uf
                                                        dsUf={dsUf}
                                                        setCdUf={setCdUf}
                                                        cdUf={cdUf}
                                                        phUf={phUf} //Placeholder para mostrar o valor original em caso de edição
                                                    >
                                                    </SelectUf>
                                                }
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="coluna-descricao-linha-cliente-5">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">CEP</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                                overlay={<Tooltip id={`tooltip-bottom`}>CEP do
                                                                    cliente</Tooltip>}>
                                                    <Form.Control id="campo-texto-cep"
                                                                  disabled={!permissaoDescricaoEditar}
                                                                  placeholder={phCep === null || phCep === 'null' ? "" : phCep}
                                                                  value={dsCep}
                                                                  className="campo-texto-cor-3 campo-texto" type="text"
                                                                  maxlength="10"
                                                                  onChange={e => validarNumero('campo-texto-cep', e.target.value)}/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <div>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Informações adicionais</div>
                                        </Form.Label>
                                    </div>
                                    <div>
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>Informações Adicionais
                                                            do Cliente</Tooltip>}>
                                            <Form.Control disabled={!permissaoDescricaoEditar}
                                                          placeholder={phInfoAdicionais === null || phInfoAdicionais === 'null' ? "" : phInfoAdicionais}
                                                          value={dsInfoAdicionais}
                                                          className="campo-texto-cor-3 campo-texto" type="text"
                                                          maxlength="500"
                                                          onChange={e => setDsInfoAdicionais(e.target.value)}/>
                                        </OverlayTrigger>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </div>

                    <div className="container-descricao-linha-dispositivos fundo-cor-7">
                        <Form.Row>
                            <Col>
                                <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-janela">Dispositivos</div>
                                </Form.Label>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <div className="grupo-descricao-linha-marca-modelo">
                                    <div className="coluna-marca-modelo-1">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Marca</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                                overlay={<Tooltip id={`tooltip-bottom`}>Marca do
                                                                    Simcard</Tooltip>}>
                                                    <Form.Control disabled={!permissaoDescricaoEditar}
                                                                  placeholder={phMarca === null || phMarca === 'null' ? "" : phMarca}
                                                                  value={dsMarca}
                                                                  className="campo-texto-cor-3 campo-texto" type="text"
                                                                  maxlength="100"
                                                                  onChange={e => setDsMarca(e.target.value)}/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="coluna-marca-modelo-2">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Modelo</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                                overlay={<Tooltip id={`tooltip-bottom`}>Modelo do
                                                                    Simcard</Tooltip>}>
                                                    <Form.Control disabled={!permissaoDescricaoEditar}
                                                                  placeholder={phModelo === null || phModelo === 'null' ? "" : phModelo}
                                                                  value={dsModelo}
                                                                  className="campo-texto-cor-3 campo-texto" type="text"
                                                                  maxlength="100"
                                                                  onChange={e => setDsModelo(e.target.value)}/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-janela">Modem</div>
                                </Form.Label>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <div className="grupo-descricao-linha-marca-modelo">
                                    <div className="coluna-marca-modelo-1">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Marca</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                                overlay={<Tooltip id={`tooltip-bottom`}>Marca do
                                                                    Modem</Tooltip>}>
                                                    <Form.Control disabled={true}
                                                                  placeholder="Campo será preenchido automaticamente"
                                                                  value={dsMarcaModem}
                                                                  className="campo-texto-disabled campo-texto"
                                                                  type="text"/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="coluna-marca-modelo-2">
                                        <Form.Group>
                                            <div>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Modelo</div>
                                                </Form.Label>
                                            </div>
                                            <div>
                                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                                overlay={<Tooltip id={`tooltip-bottom`}>Modelo do
                                                                    Modem</Tooltip>}>
                                                    <Form.Control disabled={true}
                                                                  placeholder="Campo será preenchido automaticamente"
                                                                  value={dsModeloModem}
                                                                  className="campo-texto-disabled campo-texto"
                                                                  type="text"/>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                    </div>

                    <div className="container-descricao-linha-personalizado fundo-cor-7">
                        <Form.Row>
                            <Col>
                                <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-janela">Personalizado</div>
                                </Form.Label>
                            </Col>
                        </Form.Row>
                        {camposPersonalizados}
                    </div>
                </div>
                <div>
                    <Button className="botao-confirmar" variant="success" size="sm" onClick={() => {
                        setRevelarMensagemAtencao(true)
                    }}>
                        <div className="fonte-cor-4">Salvar</div>
                    </Button>
                    <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                        <div className="fonte-cor-2">Cancelar</div>
                    </Button>
                </div>
            </div>
            {revelarMensagemSucesso === true &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"A alteração foi realizada com sucesso."}
                    mensagemBotao={"Prosseguir"}
                />
            }
            {revelarMensagemErro === true &&
                <MensagemErro
                    handleMensagemErro={() => setRevelarMensagemErro(!revelarMensagemErro)}
                    mensagemTitulo={"Erro"}
                    mensagemParagrafo1={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
            {revelarMensagemAtencao === true &&
                <MensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Atenção"}
                    mensagemParagrafo1={"Tem certeza que deseja salvar?"}
                    mensagemBotao={"Confirmar"}
                />
            }
        </Form>
    )
}