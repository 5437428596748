//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';

/* bibliotecas retiradas porque não estão sendo usadas
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading'
*/

// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectUrl from '../../componentesModais/selects/selectUrl'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectUrl, setRevelarSelectUrl] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label className="label">
                <div className="fonte-cabecalho">url {iconeAsterisco}</div>
            </Form.Label>
            <Button name="cdUrl" className="campo-select-modal" onClick={() => setRevelarSelectUrl(!revelarSelectUrl)}>
                <div className="campo-select-modal-label">{dsUrl}</div>
                <img className="campo-select-modal-icone" src={setaDropdown()} alt="drop" />
            </Button>
            { revelarSelectUrl === true &&
                <SelectUrl
                    setRevelarSelectUrl={setRevelarSelectUrl} //Hook para fechar a modal
                    setDsUrl={setDsUrl} //Nome do url
                    phUrl={phUrl} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectUrl>
            }
        </Form.Group>*/

export default function SelectUrl(props) {
    const [lsUrls, setLsUrls] = useState([])
    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();


    //OUTROS
    let cont = 0

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleUrl(Url){
        props.setDsUrl(Url)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectUrl(false)
    }

    useEffect(() => {
        async function renderUrls(){
            const listaUrls = props.vetorUrls
            let cont = 0
            let objetoUrls = listaUrls.map((item,i) => {
                return (
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleUrl(item) }>
                            <span className="label-elipses fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item}</span>
                        </div>
                        {listaUrls[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        {(listaUrls.length <= ++cont) ? null : props.setSpinAtivo(false) }
                    </li>
                );
            })
            setLsUrls(objetoUrls)
        }
        renderUrls()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            <Button name="cdUrl" className="campo-texto-cor-3 label-elipses campo-select" onClick={() => props.setRevelarSelectUrl(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsUrl}</div>
                <img className="campo-select-icone-ativado nao-selecionavel" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1  campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsUrls}
                </ul>
            </div>
        </Form>
    )
}