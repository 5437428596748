import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from "styled-components";

export const AvisoWrapper = styled.div`
  position: relative;
  justify-self: end;
`;

export const IconeTrigger = styled(InfoOutlinedIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.cor38};
`;

export const Texto = styled.span`
  color: ${({ theme }) => theme.cor49};
  font-size: 14px;
  font-weight: 400;
`;

export const ConteudoAlerta = styled.div`
  position: absolute;
  right: 0;
  top: 40px;
  width: 375px;
  min-height: 172px;
  cursor: pointer;
  border-radius: 11px;
  padding: 20px;
  box-shadow: 0px 1px 5px 0px #0000001f;
  z-index: 999;
  background-color: ${({ theme }) => theme.cor55};
  color: ${({ theme }) => theme.cor49};
`;

export const Titulo = styled.h1`
  font-size: 16px;
  font-weight: 700;
`;

export const TituloWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
