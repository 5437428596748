//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import decryptJWT from '../../../utils/decryptJWT'
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, fecharJanela, exclamacao, setaDropdown} from '../../../utils/alternanciaIcones'
//MODAIS
import SelectMultiPais from '../../componentesModais/selects/selectMultiPais'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelFaturas/fatOperadoras/fatOperadora.css'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarNovaOperadora, revelarEditarOperadora } from "../../../redux/actions/index";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function CadastroOperadora(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [cdOperadora, setCdOperadora] = useState()
    const [dsApelido, setDsApelido] = useState()
    const [dsNome, setDsNome] = useState()
    const [cdTipoFebraban, setCdTipoFebraban] = useState(0)
    const [n0Codigo, setN0Codigo] = useState(0)
    const [n0Ddi, setN0Ddi] = useState()
    const [dsPais, setDsPais] = useState()
    const [flPadrao, setFlPadrao] = useState()
    const [arrayPais, setArrayPais] = useState([])
    //HOOKS PLACEHOLDERS
    const [phApelido, setPhApelido] = useState()
    const [phNome, setPhNome] = useState()
    const [phDdi, setPhDdi] = useState()
    const [phPais, setPhPais] = useState()
    const [phMensagemSucesso, setPhMensagemSucesso] = useState('cadastrada')
    //HOOKS MODAIS
    const [revelarSelectMultiPais, setRevelarSelectMultiPais] = useState(false)
    const [revelarSelectModulo, setRevelarSelectModulo] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const [tituloJanela, setTituloJanela] = useState('Cadastro');
    const [caracteresMensagem, setCaracteresMensagem] = useState(500);
    const [clickFora, setClickFora] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const modal = useRef();
    const despacho = useDispatch();

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    useEffect(() => {
        setSpinAtivo(true)
        if (props.tituloJanela === 'Editar'){
            //TROCA O TITULO DA JANELA
            setTituloJanela('Editar')
            //CARREGA OS CAMPOS COM OS VALORES ORIGINAIS
            setCdOperadora(props.cdOperadora)
            setDsApelido(props.dsApelido)
            setDsNome(props.dsNome)
            setCdTipoFebraban(props.cdTipoFebraban)
            setN0Codigo(props.n0Codigo)
            setN0Ddi(props.n0Ddi)
            setFlPadrao(props.flPadrao)
            if(props.cdPais !== undefined && props.cdPais !== null && props.cdPais !== ''){
                let auxDsPais = props.dsPais.split(',')
                let auxCdPais = props.cdPais.split(',')
                setArrayPais(auxCdPais)
                setDsPais(auxDsPais.length + " Selecionados")
                setPhPais('Original: '+auxDsPais.length);
            }else{
                setDsPais("Selecione")
                setPhPais('Original: Selecione');
            }
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhApelido('Original: '+props.dsApelido);
            setPhNome('Original: '+props.dsNome);
            setPhDdi('Original: '+props.n0Ddi);
            setPhMensagemSucesso('editada')
        }
        if (props.tituloJanela === 'Cadastrar'){
            setTituloJanela('Cadastrar')
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhApelido('Digite o pseudônimo');
            setPhNome('Digite o nome');
            setPhDdi('Digite o DDI');
            setPhPais('Selecione');
            setDsPais('Selecione');
            setPhMensagemSucesso('cadastrada')
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    //FAZ O MANEJO 
    function handleSalvar(){
        if (tituloJanela === 'Cadastrar'){
            handleCadastrar()
        }else{
            handleAlterar()
        }
    }

    async function handleAlterar(){
        ColetaClickstream(1274) // 'dropAcoesRevelarNovaOperadoraEditar'
        setSpinAtivo(true)
        await api.post('/m1/alterar/fat_operadora', {
            "dados":{
                'ds_apelido' : dsApelido.trim(),
                'ds_dsp_operadora' : dsNome.trim(),
                'cd_fat_tipo_febraban' : cdTipoFebraban,
                'n0_codigo' : n0Codigo,
                'n0_ddi' : n0Ddi,
                'fl_padrao' : 0,
                'arrayPais' : arrayPais,
                'cd_pav_usuario_created': decryptJWT('codigo'),
                'cd_pav_usuario': decryptJWT('codigo')
            },
            "condicao":{
                'cd_dsp_operadora' : cdOperadora,	
            }
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    async function handleCadastrar(){
        ColetaClickstream(1273) // 'dropAcoesRevelarNovaOperadoraCadastrar'
        setSpinAtivo(true)
        await api.post('/m1/cadastrar/fat_operadora', {
            'ds_apelido' : dsApelido.trim(),
            'ds_dsp_operadora' : dsNome.trim(),
            'cd_fat_tipo_febraban' : cdTipoFebraban,
            'n0_codigo' : n0Codigo,
            'n0_ddi' : n0Ddi,
            'arrayPais' : arrayPais,
            'fl_padrao' : 0,
            'cd_pav_usuario': decryptJWT('codigo'),
            "cd_pav_cliente": decryptJWT('codigoCliente')
        },)
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    function handleCancelar(){
        ColetaClickstream(1272) // 'dropAcoesRevelarNovaOperadoraCancelar'
        despacho(revelarNovaOperadora(false))
        despacho(revelarEditarOperadora(false))
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            handleCancelar()
            if(confirmacao === true){
                handleCancelar()
            }
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            props.setRenderizar(!props.renderizar)
            handleCancelar()
            if(confirmacao === true){
                handleCancelar()
            }
        }
    }

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-ddi"){
                setN0Ddi(valor)
            }
        }
    }

    useEffect(()=>{
        if(arrayPais.length>0){
            setDsPais(arrayPais.length+" Selecionados")
        }
    },[revelarSelectMultiPais])

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <div className="modal-cadastro-operadora fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{tituloJanela} operadora</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-operadora-1">
                            <div className="coluna-cadastro-operadora-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Nome</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Nome da Operadora</Tooltip>}>
                                        <Form.Control name="dsNome" value={dsNome}  className="campo-texto-cor-3 campo-texto" type="text" maxlength="25" placeholder={phNome} onChange={e => setDsNome(e.target.value)} 
                                        ref={register({validate: {minimo: value => value.length > 1}})}/>
                                    </OverlayTrigger>
                                    {errors.dsNome && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-operadora-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Pseudônimo</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Como a Operadora é popularmente conhecieda</Tooltip>}>
                                        <Form.Control name="dsApelido" value={dsApelido}  className="campo-texto-cor-3 campo-texto" type="text" maxlength="25" placeholder={phApelido} onChange={e => setDsApelido(e.target.value)} 
                                        ref={register({validate: {minimo: value => value.length > 1}})}/>
                                    </OverlayTrigger>
                                    {errors.dsApelido && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-operadora-3">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">DDI</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>O código DDI da Operadora</Tooltip>}>
                                        <Form.Control name="n0Ddi" value={n0Ddi}  className="campo-texto-cor-3 campo-texto" type="text" maxlength="25" placeholder={phDdi} onChange={e => validarNumero("campo-texto-ddi", e.target.value)} 
                                        ref={register({validate: {minimo: value => value.length > 1}})}/>
                                    </OverlayTrigger>
                                    {errors.n0Ddi && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-operadora-4">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">País</div>
                                    </Form.Label>
                                    { revelarSelectMultiPais === false &&
                                        <Button name="cdModulo" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectMultiPais(!revelarSelectMultiPais)}>
                                            <div className="fonte-cor-1 label-campo">{dsPais}</div>
                                            <img className={revelarSelectMultiPais?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectMultiPais === true &&
                                        <SelectMultiPais
                                            setRevelarSelectMultiPais={setRevelarSelectMultiPais} //Hook para fechar a modal
                                            arrayPais={arrayPais}
                                            dsPais={dsPais}
                                        >
                                        </SelectMultiPais>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <div className="campo-select-divisor-cor-1 divisoria-cadastro-operadoras-faturas"></div>
                </Form.Row>
                <Form.Row>
                    <Col>
                            {(dsApelido !== undefined && dsNome !== undefined && n0Ddi !== undefined && arrayPais.length > 0) ?
                                <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">{tituloJanela}</div>
                                </Button>
                            :
                                <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Preencha o Nome, Pseudônimo, DDI e País</Tooltip>}>
                                    <Button className="botao-confirmar-desabilitado" size="sm">
                                        <div className="fonte-cor-4">{tituloJanela}</div>
                                    </Button>
                                </OverlayTrigger>
                            }
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Operadora "+dsNome+" "+phMensagemSucesso+"."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}