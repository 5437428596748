//IMPORTAÇÕES
import React from "react";/*eslint-disable*/

//CSS
import "react-circular-progressbar/dist/styles.css";

import { revelarImportarClientesUsuarios } from "../../../redux/actions/index";
import ModalImportarArquivo from "./importarArquivo";

export default function ModalImportarClientesUsuarios(props) {
    const aviso = "Ao preencher o template, para manter um campo inalterado, deixe o campo em branco. Para deixar um campo vazio, digite a tag: <apagar/>";

    return (
        <ModalImportarArquivo
            aviso={aviso}
            importarDescricao
            revelarModal={revelarImportarClientesUsuarios}
            impedirMultiplosArquivos
            atualizarTabelaAposErro
            {...props}
        />
    )
}
