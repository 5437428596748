//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectTela from '../../componentesModais/selects/selectTela'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectTela, setRevelarSelectTela] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">Tela {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectTela === false &&
                <Button name="cdTela" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTela(!revelarSelectTela)}>
                    <div className="fonte-cor-1 label-campo">{dsTela}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectTela === true &&
                <SelectTela
                    setRevelarSelectTela={setRevelarSelectTela} //Hook para fechar a modal
                    setCdTela={setCdTela} //Código do Tela
                    setDsTela={setDsTela} //Nome do Tela
                    dsTela={dsTela} 
                    phTela={phTela} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectTela>
            }
        </Form.Group>*/

export default function SelectTela(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [lsTelas, setLsTelas] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleTela(codigo, tela){
        props.setCdTela(codigo)
        props.setDsTela(tela)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectTela(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderTelas(){
            const {data} = await api.get('/m1/consultar/tela');
            
            if(data.dados.length > 0){
                let objetoTelas = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.ds_pav_tela}>
                            <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleTela(item.cd_pav_tela, item.ds_pav_tela) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.ds_pav_tela}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsTelas(objetoTelas)
            }else{
                let objetoTelas = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsTelas(objetoTelas)
            }
            setSpinAtivo(false)
        }
        renderTelas()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectTela(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsTela}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsTelas}
                </ul>
            </div>
        </Form>
    )
}