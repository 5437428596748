//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import { api } from '../../../conexoes/api';
import { useForm } from "react-hook-form";/*eslint-disable*/
import { ProgressBar, Button, Form, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { formatarData } from '../../../utils/formatadorDataTabela'
//ICONES
import { elipseVerde, fecharJanela, lixeiraVermelho, alertaAtencao } from '../../../utils/alternanciaIcones';
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector } from 'react-redux';
import { revelarLiberarSms } from "../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemAtencaoSelect from '../../componentesModais/mensagens/mensagemAtencaoSelect'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import { identificarValidacaoAcoes } from '../../../utils/identificarValidacaoAcoes';
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import RetornarParametro from '../../../utils/decryptJWT'
import decryptJWT from '../../../utils/decryptJWT'
import CheckboxAutorizar from '../../CheckboxAutorizar/checkbox';

const valorLimite = 50
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let simcardSelecionados = [];
let itensVar = []

const routeM1FiltrarSimcard = '/m1/filtrar/simcard';

export default function SmsLiberar(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [dsAplicacao, setDsAplicacao] = useState("")
    const [dsFinalidade, setDsFinalidade] = useState("")
    const [dsModelo, setDsModelo] = useState("")
    const [dsMediaSms, setDsMediaSms] = useState(0)
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1);
    const [linhaRemover, setLinhaRemover] = useState('');
    const [renderizar, setRenderizar] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [simcards, setSimcards] = useState([]);
    const [linhas, setLinhas] = useState([]);
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] = useState(false)
    const [revelarMensagemAtencaoContemItemInvalido, setRevelarMensagemAtencaoContemItemInvalido] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [itens, setItens] = useState([])
    const [itensInvalidos, setItensInvalidos] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensInvalidosBusca, setItensInvalidosBusca] = useState(false);
    const [checkboxAutorizar, setCheckboxAutorizar] = useState(false);
    
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();
    const mensagemLinhasRecusadas = "Algumas linhas já tem uma solicitação igual em aberto ou o contrato delas não permite esta ação!"
    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    const codigoCliente = RetornarParametro('codigoClienteErpAntigo')
    const codigosClientes = RetornarParametro('codigoCliente')

    let itensTemp = []
    let itensInvalidosTemp = []

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    const colunasItensInvalidos = [{
        dataField: 'CALLERID',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'ds_motivo',
        text: 'Motivo',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },]

    const colunas = [{
        dataField: 'CD_SIMCARD',
        text: 'Código',
        headerClasses: 'operadorHeader nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'OPERADORA',
        text: 'Operadora',
        headerClasses: 'operadorHeader nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'CALLERID',
        headerClasses: 'nao-selecionavel',
        text: 'Linha',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ICCID',
        headerClasses: 'nao-selecionavel',
        text: 'ICCID',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'PLANO',
        headerClasses: 'nao-selecionavel',
        text: 'Plano',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell} MB</p>;
        }
    },
    {
        dataField: 'CONSUMO_PERCENTUAL',
        text: 'Consumo (%)',
        headerClasses: 'centralizar nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            let styleProgressBar = '';
            if (!cell || cell == '')
                cell = 0;

            styleProgressBar = '#36D896'

            return (
                <ProgressBar className='barra-progresso-container'>
                    <ProgressBar style={{ 'backgroundColor': styleProgressBar }} now={cell} />
                    <span className="texto-barra-progresso fonte-cor-1">{cell === undefined ? '0.00%' : cell.toFixed(2) + '%'}</span>
                </ProgressBar>
            )
        },
    },
    {
        dataField: 'ULTIMO_EVENTO',
        headerClasses: 'nao-selecionavel',
        text: 'Último evento',
        sort: true,
        formatter: (cell, row) => {
            if (cell) {
                cell = cell.replace('T', ' ').split('.')
                if (cell.length > 0) {
                    cell = cell[0]
                }
            }
            return formatarData(cell);
        }
    },
    {
        dataField: 'acoes',
        text: '',
        sort: false,
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha" />
                </Button>
            )
        }
    }]

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.CD_SIMCARD);
        }
    }

    function handleLinhasSelecionadas() {
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if (itensTemp.length === 0) {
            handleCancelar()
        }
    }

    //verifica se o usuario selecionou linhas extras que nao sao permitidas na acao e aciona um alerta
    function handleLinhasSelecionadasIncapacitadas() {
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if (itensInvalidosTemp.length > 0) {
            setRevelarMensagemAtencaoInvalido(true)
        }
    }

    async function handleRemoverLinha(CD_SIMCARD) {
        ColetaClickstream(1129) // liberarSMSRemoverLinha
        setRevelarMensagemAtencao(false)
        if (modoSelecaoSimcard) {
            let simcardNaoSelecionados = itensInvalidosBusca;
            if (itensInvalidosBusca) {
                simcardNaoSelecionados = itensInvalidosBusca;
            }
            let simcardSelecionados = simcards
            if (simcardNaoSelecionados === undefined || simcardNaoSelecionados === null || simcardNaoSelecionados === false) {
                simcardNaoSelecionados = CD_SIMCARD + ","
            }
            else {
                simcardNaoSelecionados += CD_SIMCARD + ","
            }
            setItensInvalidosBusca(simcardNaoSelecionados)

            for (let i = contador; i < simcardSelecionados.length; i++) {
                if (simcardSelecionados[i] == CD_SIMCARD) {
                    simcardSelecionados.splice(i, 1)
                }
            }

            await Promise.all(
                itensVar.map((item_itensVar, i) => {
                    if (CD_SIMCARD == item_itensVar.CD_SIMCARD) {
                        itensVar.splice(i, 1);
                    }
                })
            )
            await contarItens();
            setItens(itensVar)
            setSimcards(simcardSelecionados)
        }
        else {
            itensTemp = itens
            itens.forEach(function (e, index) {
                if (CD_SIMCARD == e.CD_SIMCARD) {
                    itens.splice(index, 1);
                }
            })
            setItens(itensTemp)
            handleIdSimcards()
            handleLinhasSelecionadas()
        }

    }


    async function handleSalvar() {
        ColetaClickstream(1130) // liberarSMSConfirmar

        let tempSimcards = simcards;
        let tempLinhas = linhas;
        setSpinAtivo(true)
        if (modoSelecaoSimcard === true) {
            tempSimcards = [];
            tempLinhas = [];

            const urlGet = `${routeM1FiltrarSimcard}?acao=true&buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false&itensInvalidosBusca=${itensInvalidosBusca}`
            const consultaSimcards = await api.get(urlGet)
                .then(function (resposta) {
                    return resposta.data;
                })
                .catch(function (erro) {
                    throw erro;
                })

            if (consultaSimcards.dados.length > 0 && consultaSimcards.status === 200) {
                await Promise.all(
                    consultaSimcards.dados.map((item, i) => {
                        tempSimcards.push(item.CD_SIMCARD)
                        tempLinhas.push(item.FULLCALLERID)
                    })
                )
            } else {
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            }
        }

        let temporario = new Date();
        let dataHora = temporario.toISOString().substring(0, 10) + ' ' + temporario.getHours() + ':' + temporario.getMinutes() + ':' + temporario.getSeconds();
        await api.post('/m1/cadastrar/solicitacao', {
            'fl_operadora': 0,
            'simcards': tempSimcards,
            'FULLCALLERID': tempLinhas,
            'cd_crm_solicitacao_tipo': 10,
            'cd_crm_solicitacao_status': 1,
            'ds_crm_solicitacao': (dsAplicacao + dsFinalidade),
            'dt_inicio': dataHora,
            'dt_fim': undefined,
            'dt_previsao': undefined,
            ds_rota_permissao: "veye/dispositivos/acoes/liberarSms",
        }, { timeout: 20000 })
            .then(function (response) {
                setSpinAtivo(false)
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            });
    }

    async function handleCancelar() {
        ColetaClickstream(1131) // liberarSMSCancelar
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
        itensVar = []
        itensInvalidosTemp = []
        simcardSelecionados = []
        setSpinAtivo(false)
        despacho(revelarLiberarSms(false))
    }

    function inserirData(data) {
        // setItens('')
        if (itensVar.length == totalItens) {
            for (let i = 0; i < totalItens; i++) {
                if (contador === i) {
                    let k = i
                    for (let j = 0; j < data.length; j++) {
                        itensVar[k] = data[j]
                        k++
                    }
                }
                if (i == (totalItens - 1)) {
                    itensTemp = JSON.parse(JSON.stringify(itensVar))
                    setItens(JSON.parse(JSON.stringify(itensVar)))
                    handleIdSimcards()
                }
            }
            handleLinhasSelecionadas()
        }
    }


    function handleIdSimcards() {
        let tempSimcards = []
        let tempLinhas = []
        for (let i = 0; i < itensTemp.length; i++) {
            tempSimcards.push(itensTemp[i].CD_SIMCARD)
            tempLinhas.push(itensTemp[i].FULLCALLERID)
        }
        simcardSelecionados = tempSimcards
        setLinhas(tempLinhas)
        setSimcards(tempSimcards)
    }

    async function handlerConsultarSimcadsBusca() {
        if (totalPaginas == 0) {
            contador = 0;
            itensVar = [];
            setItens([])
            await contarItens();
        }
        else {
            contador = (paginaAtual - 1) * valorLimite;
        }
        if (controladorPagina <= paginaAtual) {
            setSpinAtivo(true)

            const urlGet = `${routeM1FiltrarSimcard}?paguinaAtual=${contador}&carregarLimit=${valorLimite}&acao=true&buscar=${conteudoBusca}&ordenador=${props.ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false&cd_pav_usuario=${decryptJWT('codigo')}&itensInvalidosBusca=${itensInvalidosBusca}`
            const consultaSimcards = await api.get(urlGet)
                .then(function (resposta) {
                    return resposta.data.dados;
                })
                .catch(function (erro) {
                    throw erro;
                })

            setSpinAtivo(false)
            inserirData(consultaSimcards)
        }
    }
    useEffect(() => {
        if (modoSelecaoSimcard  && ( itensInvalidosBusca || paginaAtual > 1 ) ) {
            handlerConsultarSimcadsBusca()
        }
    }, [itensInvalidosBusca, paginaAtual])

    useEffect(() => {
        setSpinAtivo(true)
        async function preencherTabela() {
            const validacao = await identificarValidacaoAcoes(props.itensSelecionados, 10, {filtros, buscaArquivo}, modoSelecaoSimcard, conteudoBusca, separadorLinha)
            if (modoSelecaoSimcard) {
                itensTemp = validacao.itensValidos
                itensInvalidosTemp = validacao.itensInvalidos
                setItensInvalidos(validacao.itensInvalidos)
                if (itensInvalidosTemp.length > 0) {
                    await Promise.all(
                        validacao.itensInvalidos.map((item_validacao, i) => {
                            simcardSelecionados += item_validacao.CD_SIMCARD + ','
                        })
                    )
                    setItensInvalidosBusca(simcardSelecionados)
                    setSimcards(validacao.itensValidos)
                }
                else {
                    handlerConsultarSimcadsBusca()
                }
            }
            else {
                setItens(validacao.itensValidos)
                setItensInvalidos(validacao.itensInvalidos)
                itensTemp = validacao.itensValidos
                itensInvalidosTemp = validacao.itensInvalidos
                handleIdSimcards();
                setItensInvalidosBusca(true)
            }
            handleLinhasSelecionadasIncapacitadas();
            setSpinAtivo(false)
        }
        preencherTabela()

        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    async function contarItens() {
        let arrayItens = []
        let apiData = [];
        
        if (controladorPagina <= paginaAtual) {
            const urlGet = `${routeM1FiltrarSimcard}?paguinaAtual=${contador}&carregarLimit=${valorLimite}&acao=true&buscar=${conteudoBusca}&ordenador=${props.ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=true&cd_pav_usuario=${decryptJWT('codigo')}&itensInvalidosBusca=${itensInvalidosBusca}`
            apiData = await api.get(urlGet)
        }

        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for (var i = 0; i < apiData.data.dados[0].CONTADOR; i++) {
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange: (page) => { setPaginaAtual(page) }
    }

    function handleMensagemAtencao(confirmacao) {
        if (revelarMensagemAtencao === false) {
            setRevelarMensagemAtencao(true)
        } else {
            setRevelarMensagemAtencao(false)
            if (confirmacao === true) {
                handleRemoverLinha(linhaRemover)
            }
        }
    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if (confirmacao) {
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
        }
        if (confirmacao === true) {
            //ALGUMA FUNÇÃO
        }
    }

    function handleFecharMensagemItensInvalidos() {
        setRevelarMensagemAtencaoInvalido(!revelarMensagemAtencaoInvalido)
        if (modoSelecaoSimcard) {
            if (itens <= 0) {
                handleCancelar()
            }
        }
        else {
            if (quantidadeLinhasSelecionados <= 0) {
                handleCancelar()
            }
        }
    }

    const handleChangeCheckboxAutorizar = () => setCheckboxAutorizar(!checkboxAutorizar);

    return (
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-liberar-sms fundo-cor-6">
                {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse" />
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Liberar SMS</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela" />
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-tabela-modal">
                            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <>
                                            <BootstrapTable
                                                classes="tabela-transparente-modais"
                                                condensed={true}
                                                keyField='id'
                                                data={itens}
                                                columns={colunas}
                                                rowEvents={eventosLinhas}
                                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                bootstrap4={true}
                                                {...paginationTableProps}
                                            />
                                            {modoSelecaoSimcard &&
                                                <div className="paguinacao-tabela-modal">
                                                    <PaginacaoTabela
                                                        extrato={true}
                                                        setPaginaAtual={setPaginaAtual}
                                                        totalPaginas={totalPaginas}
                                                        totalItens={totalItens}
                                                        valorLimite={valorLimite}
                                                        paginaAtual={paginaAtual}
                                                        page={paginationProps.page}
                                                        onPageChange={paginationProps.onPageChange}
                                                    />
                                                </div>
                                            }
                                        </>
                                    )
                                }
                            </PaginationProvider>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="label-atencao-pacote-extra">
                            <span><span className="fonte-cor-3 label-normal negrito">Atenção:</span><span className="fonte-cor-1 label-normal"> este tipo de solicitação só é atendida dentro do horário comercial (8h - 17:30h).</span></span>
                        </div>
                        <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} linhas selecionadas</span>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-liberar-sms-1">
                            <div className="coluna-liberar-sms-1">
                                <Form.Group>
                                    <Form.Label className="fonte-cor-1 label-campo">Tipo de aplicação</Form.Label>
                                    <Form.Control className="campo-texto-cor-3 campo-texto" type="text" onChange={e => setDsAplicacao(e.target.value)}></Form.Control>
                                </Form.Group>
                            </div>
                            <div className="coluna-liberar-sms-2">
                                <Form.Group>
                                    <Form.Label className="fonte-cor-1 label-campo">Finalidade</Form.Label>
                                    <Form.Control className="campo-texto-cor-3 campo-texto" type="text" onChange={e => setDsFinalidade(e.target.value)}></Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-liberar-sms-2">
                            <div className="coluna-liberar-sms-3">
                                <Form.Group>
                                    <Form.Label className="fonte-cor-1 label-campo">Modelo do dispositivo</Form.Label>
                                    <Form.Control className="campo-texto-cor-3 campo-texto" type="text" onChange={e => setDsModelo(e.target.value)}></Form.Control>
                                </Form.Group>
                            </div>
                            <div className="coluna-liberar-sms-4">
                                <Form.Label>
                                    <div className="fonte-cor-1 label-campo">Média de envio de SMS</div>
                                </Form.Label>
                                <input type="range" class="custom-range" defaultValue="0" min="0" max="100" step="1" id="customRange" onChange={e => setDsMediaSms(e.target.value)} />
                            </div>
                            <div className="coluna-liberar-sms-5">
                                <div className="campo-texto-sms-liberar-sms campo-texto-cor-3 campo-texto">
                                    <span className="label-sms-liberar-sms fonte-cor-1">{dsMediaSms} mês</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row className="label-termos-liberar-sms-2">
                    <Col>
                        <span>
                            <span className="fonte-cor-3 label-normal negrito">Atenção:</span>
                            <span className="fonte-cor-1 label-normal"> Todas as linhas já saem com o RECEBIMENTO de SMS ativo, este processo irá permitir um retorno do SMS, e esse envio terá custos.</span>
                            {/* <span className="fonte-cor-1 label-normal"> o valor de cada SMS enviado será cobrado na fatura subsequente ao ciclo de utilização.</span> */}
                        </span>
                    </Col>
                </Form.Row>

                <hr class/>

                <CheckboxAutorizar
                    texto="Autorizo a ativação dos serviços de SMS, declarando ciência quanto ao preço de R$ 0,60 (sessenta centavos) por SMS enviado e me comprometendo a realizar todos os pagamentos tempestivamente, conforme fatura mensal a ser gerada exclusivamente pela operadora parceira.
                    Estou ciente, ainda, que o serviço de SMS não possui restrição de envios, e assumo exclusiva responsabilidade por eventuais anormalidades, notadamente com relação a disparos automáticos ou não autorizados, bem como pelo conteúdo das mensagens enviadas."
                    checked={checkboxAutorizar}
                    onClick={handleChangeCheckboxAutorizar}
                    className="label-termos-liberar-sms-2"
                />

                <Form.Row>
                    <Col>
                        <Button type="submit" className="botao-confirmar" variant="success" size="sm" disabled={!checkboxAutorizar}>
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm"  onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            {revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemAtencaoInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso" />
                            </div>
                            <div className="fonte-cor-1 label-janela">{itensInvalidos.length} linhas foram retiradas</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensInvalidos}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4={true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensInvalidos()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
    )
}
