//BIBLIOTECAS
/*eslint-disable*/
import React,{useState, useEffect} from 'react';
import { Form, Col } from 'react-bootstrap';/*eslint-disable*/
import {api} from '../../../../conexoes/api';
import { Label, Cell, Tooltip, Pie, PieChart } from 'recharts';
import BootstrapTable from 'react-bootstrap-table-next';
//ICONES
import { elipseVerde } from '../../../../utils/alternanciaIcones';
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'

export default function DetalhamentoFornecedor(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    // HOOKS
    const [operadoras, setOperadoras] = useState([])
    
    useEffect(() => {
        async function carregarOperadoras(){
            setSpinAtivo(true)
            let buscaOperadoras = await api.get('/m1/consultar/fat_operadora/operadora_por_fornecedor?cd_fat_fornecedor='+props.cdFornecedor);
            if(buscaOperadoras.data.dados !== undefined){
                setOperadoras(buscaOperadoras.data.dados)
            }
            setSpinAtivo(false)
        }
        carregarOperadoras()
    },[])

    const colunas = [
        {
            dataField: 'cd_dsp_operadora',
            text: 'Cód. operadora',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            hidden: true,
            align: 'center',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_dsp_operadora',
            text: 'Nome',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            align: 'center',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_apelido',
            text: 'Pseudônimo',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            align: 'center',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'n0_ddi',
            text: 'DDI',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            align: 'center',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' ? cell : "-"}</p>;
            }
        }
    ]

    return(
        <>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
            <div className="triangulo-detalhamento-fat-fornecedores campo-select-triangulo-cor-2"></div>
            <div className="container-itens-expandido">
                <div className="detalhamento-fat-fornecedores-1 fundo-cor-7">
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                            <img className="icone-label-filtro-financeiro" src={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela-financeiro"> Endereço </div>
                            </Form.Label>
                        </Col>
                    </Form.Row>
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                            <div className="linha-detalhamento-fat-fornecedores">
                                <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-detalhamento-cliente-1">
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">Endereço: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor"> {props.dsLogradouro+", "+props.dsNumero}</span>
                                            </div>
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">Bairro: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor-2"> {props.dsBairro}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-detalhamento-cliente-1">
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">Estado: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor"> {props.dsUf}</span>
                                            </div>
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">Cidade: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor-2"> {props.dsCidade}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-detalhamento-cliente-1">
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">CEP: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor-3"> {props.dsCep}</span>
                                            </div>
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">E-mail: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor-2"> {props.dsEmail}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>
                            </div>
                        </Col>
                    </Form.Row> 
                </div>

                <div className="detalhamento-fat-fornecedores-2 fundo-cor-7">
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                            <img className="icone-label-filtro-financeiro" src={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela-financeiro"> Operadoras </div>
                            </Form.Label>
                        </Col>
                    </Form.Row>
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                            <div className="linha-detalhamento-fat-fornecedores">
                                <BootstrapTable
                                    hover
                                    classes='tabela-transparente-header-fixo'
                                    keyField='cd_dsp_operadora'
                                    headerClasses={ "class-centralizar"}
                                    data={ operadoras }
                                    columns={ colunas }
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                        </Col>
                    </Form.Row> 
                </div>
            </div>
        </>
    )
}