import {api} from '../conexoes/api';

export default function decrypt(campo){

    if(!localStorage.getItem("@veye-Token"))
       return undefined
    const dadosToken = ParseJwt(localStorage.getItem("@veye-Token"))
    if(campo === ''){
        return dadosToken
    }
    return dadosToken[campo]
}

export function RetornarParametro(){
    api.post("/m1/servicos/dados/validacao_token", {
        'token' : localStorage.getItem("@veye-Token")
    }).then(function (Response) {
    })
    .catch(function (error) {
        logout();
        window.location.reload();
    });
}

function ParseJwt(token){
    let base64Url = token.split('.')[1];
    if(base64Url === undefined){
        logout()
        window.location.reload();
    }
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const logout = () => {
    const localTema = localStorage.getItem('tema');
    localStorage.clear();
    localStorage.setItem('tema', localTema)
};