//IMPORTAÇÕES
/*eslint-disable*/
import React, {useState, useEffect, useRef} from 'react'; /*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import {useForm} from 'react-hook-form';
import {Button, Form, Col, Tooltip, OverlayTrigger, Tabs, Tab} from 'react-bootstrap';
import {elipseVerde, fecharJanela, setaDropdown} from '../../../../utils/alternanciaIcones'
//MODAIS
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'
//ESTILIZACAO
import '../../../../css/painelVirtueyes/cliente/estilo.css'
import {useDispatch} from 'react-redux';
import {MascaraCpf, MascaraCnpj, MascaraTelefone, MascaraCep } from '../../../../utils/mascarasCampos'
import { revelarCadastroCliente, visualizarDadosRefresh} from "../../../../redux/actions/index";

import decryptJWT from '../../../../utils/decryptJWT'
import MenuCadastroPermissoes from './permissoes'
const axios = require('axios');

export default function CadastroCliente(props) {
    const {register, handleSubmit, errors} = useForm()
    const despacho = useDispatch()
    const modal = useRef()
    const [spinAtivo, setSpinAtivo] = useState(true)
    const [cdTipoPessoa, setCdTipoPessoa] = useState(undefined)
    const [dsTipoPessoa, setDsTipoPessoa] = useState('Selecione')
    const [dsNome, setDsNome] = useState(undefined)
    const [dsSobrenome, setDsSobrenome] = useState(undefined)
    const [dsCpf, setDsCpf] = useState(undefined)
    const [dsRazaoSocial, setDsRazaoSocial] = useState(undefined)
    const [dsNomeFantasia, setDsNomeFantasia] = useState(undefined)
    const [dsCnpj, setDsCnpj] = useState(undefined)
    const [dsTelefone, setDsTelefone] = useState(undefined)
    const [dsEmail, setDsEmail] = useState(undefined)
    const [cdOperadoras, setCdOperadoras] = useState([])
    const [dsOperadoras, setDsOperadoras] = useState([])
    const [phOperadoras, setPhOperadoras] = useState('Selecione')
    const [dsLogradouro, setDsLogradouro] = useState(undefined)
    const [dsNumero, setDsNumero] = useState(undefined)
    const [dsCep, setDsCep] = useState(undefined)
    const [dsBairro, setDsBairro] = useState(undefined)
    const [dsCidade, setDsCidade] = useState(undefined)
    const [cdEstado, setCdEstado] = useState(undefined)
    const [dsEstado, setDsEstado] = useState('Selecione')
    const [dsInformacoesAdicionais, setDsInformacoesAdicionais] = useState(undefined)
    const [permissoes, setPermissoes] = useState(undefined)
    const [revelarSelectTipoPessoa, setRevelarSelectTipoPessoa] = useState(false)
    const [revelarSelectOperadoras, setRevelarSelectOperadoras] = useState(false)
    const [revelarSelectEstado, setRevelarSelectEstado] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [elementoAtivadoPrincipal, setElementoAtivadoPrincipal] = useState(1)
    const [tabAtivadoPrincipal, setTabAtivadoPrincipal] = useState(1)
    const [dummy, setDummy] = useState()
    const [arrayDummy, setArrayDummy] = useState([])
    const [dsMensagemSucesso, setDsMensagemSucesso] = useState('Cadastro efetuado com sucesso.')
    const [dsMensagemErro, setDsMensagemErro] = useState('Algo deu errado, tente novamente. Se o erro persistir entre em contato com o suporte.')
    const [liberarCadastro, setLiberarCadastro] = useState(false)
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    }


    useEffect(() => {
        setSpinAtivo(true)
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function handleCancelar() {
        ColetaClickstream(1422) // virtuTabelaClientesTabelaClientesDropAcoesCadastroClienteCancelar
        setSpinAtivo(false)
        despacho(revelarCadastroCliente(false))
    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if (confirmacao) {
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
        }
        if (confirmacao === true) {
            //ALGUMA FUNÇÃO
        }
    }

    function handerAlterarTabPrincipal(k){
        setElementoAtivadoPrincipal(k)
        setTabAtivadoPrincipal(k)
    }

    function handleCpf(valor){
        setDsCpf(MascaraCpf(valor))
    }

    function handleCnpj(valor){
        setDsCnpj(MascaraCnpj(valor))
    }

    function handleTelefone(valor){
        setDsTelefone(MascaraTelefone(valor))
    }

    function handleCep(valor){
        setDsCep(MascaraCep(valor))
    }

    useEffect(()=>{
        let placeholderAux = false
        cdOperadoras.forEach(function(item, index){
            if(item === -1){
                placeholderAux = true
            }
        })
        if(placeholderAux === true){
            setPhOperadoras(cdOperadoras.length - 1 + " Selecionados")
        }
        else if(cdOperadoras.length === 1){
            setPhOperadoras(dsOperadoras[0])
        }
        else if(cdOperadoras.length === 0){
            setPhOperadoras("Selecione")
        }
        else{
            setPhOperadoras(cdOperadoras.length + " Selecionados")
        }
    },[cdOperadoras.length, cdOperadoras[0], cdOperadoras[cdOperadoras.length - 1]])

    async function handlerConsultarCep(cep){
        let auxCep = cep.replace('-', '')
        setSpinAtivo(true)
        await api.get('/m1/consultar/cep?cep='+auxCep)
        .then(function (response) {
            if(response.data.status == 200){
                if(response.data.dados == 'Cep Invalido'){
                    setDsMensagemErro('Cep Invalido.')
                    setDsLogradouro('')
                    setDsBairro('')
                    setDsCidade('')
                    setDsEstado('Selecione')
                    setCdEstado('')
                    handleMensagemErro()
                }
                else{
                    setDsLogradouro(response.data.dados.logradouro)
                    setDsBairro(response.data.dados.bairro)
                    setDsCidade(response.data.dados.localidade)
                    setDsEstado(response.data.dados.uf)
                    setCdEstado(response.data.dados.cd_pav_uf)
                }
            }
            setSpinAtivo(false)
            return response
        })
        .catch(function (error) {
            setDsMensagemErro('Algo deu errado ao tentar consultar o CEP. Por favor, tente novamente mais tarde.')
            handleMensagemErro()
            setSpinAtivo(false)
            return error
        })
        
    }

    async function handleConsultarCnpj(cnpj){
        let auxCnpj = cnpj.replaceAll('.', '')
        auxCnpj = auxCnpj.replaceAll('/', '')
        auxCnpj = auxCnpj.replaceAll('-', '')
        setSpinAtivo(true)
        await axios.get(`https://cors-anywhere.herokuapp.com/http://www.receitaws.com.br/v1/cnpj/`+auxCnpj)
        .then(function (resposta) {
            if(!resposta.data.status == "ERRO"){
                setDsNomeFantasia(resposta.data.fantasia)
                setDsRazaoSocial(resposta.data.nome)
                setDsEmail(resposta.data.email)
                setDsTelefone(resposta.data.telefone)
                let auxCep = resposta.data.cep
                auxCep = auxCep.replace("-","")
                auxCep = auxCep.replace(".","")
                handlerConsultarCep(auxCep)
            } 
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
        });
    }

    async function handleCadastrar(){
        ColetaClickstream(1423) // virtuTabelaClientesTabelaClientesDropAcoesCadastroClienteCadastrar
        setSpinAtivo(true)
        let auxTelefone = dsTelefone.replaceAll("(", "")
            auxTelefone = auxTelefone.replaceAll(")", "")
            auxTelefone = auxTelefone.replaceAll("-", "")
            
        let auxCep = dsCep.replaceAll(".", "")
            auxCep = auxCep.replaceAll("-", "")

        let auxNome
        let auxFantasia
        let auxCpf
        let auxCnpj

        if(cdTipoPessoa === 1){
                auxNome = dsNome.trim()+' '+dsSobrenome.trim()
                auxCpf = dsCpf.replaceAll(".", "")
                auxCpf = auxCpf.replaceAll("-", "")
        }else{
                auxNome = dsRazaoSocial.trim()
                auxFantasia = dsNomeFantasia.trim()
                auxCnpj = dsCnpj.replaceAll(".", "")
                auxCnpj = auxCnpj.replaceAll("/", "")
                auxCnpj = auxCnpj.replaceAll("-", "")
        }

        await api.post('/m1/cadastrar/cliente', {
            "cd_pav_tipo_pessoa": cdTipoPessoa,
            "ds_pav_cliente": auxNome,
            "ds_nome": auxNome,
            "ds_nome_fantasia": auxFantasia === undefined ? '' : auxFantasia,
            "ds_email": dsEmail.trim(),
            "ds_telefone": auxTelefone,
            "ds_cpf": auxCpf,
            "ds_cnpj": auxCnpj,
            "ds_pav_cidade": dsCidade.trim(),
            "ds_pav_bairro": dsBairro.trim(),
            "ds_pav_logradouro": dsLogradouro.trim(),
            "ds_pav_cep": auxCep,
            "n0_numero": dsNumero.trim(),
            "cd_pav_uf": cdEstado,
            'cd_pav_cliente_origem': decryptJWT('codigoCliente'),
            "cd_pav_pais": 1,
            "ds_informacoes_adicionais": dsInformacoesAdicionais,
            "cd_pav_usuario_created": decryptJWT("codigo"),
            'n0_nivel': parseInt(decryptJWT('clienteNivel')) + 1
        },{timeout:20000})
        .then(async function (response) {
            await api.post('/m1/cadastrar/permissao', {
                'permissoes': permissoes,
                'cd': 'cliente',
                'id': response.data.dados.idInserido
            },{timeout:20000})

            if(cdTipoPessoa === 1){
                setDsMensagemSucesso('Cliente '+dsNome+' '+dsSobrenome+' cadastrado com sucesso.')
            }else{
                setDsMensagemSucesso('Cliente '+dsNomeFantasia+' cadastrado com sucesso.')
            }
            handleMensagemSucesso(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setDsMensagemErro(error.response.data.info)
            handleMensagemErro(false)
        });
    }
    
    useEffect(() => {
        if(
            permissoes != undefined &&
            dsEmail != undefined &&
            dsTelefone != undefined && 
            dsLogradouro != undefined &&
            dsEstado != 'Selecione' &&
            dsBairro != undefined &&
            dsCidade != undefined &&
            dsCep != undefined &&
            dsNome != undefined &&
            dsSobrenome != undefined &&
            dsCpf != undefined &&
            dsNumero != undefined
        ){
            setLiberarCadastro(true)
        }else if(
            permissoes != undefined &&
            dsEmail != undefined &&
            dsTelefone != undefined && 
            dsLogradouro != undefined &&
            dsEstado != 'Selecione' &&
            dsBairro != undefined &&
            dsCidade != undefined &&
            dsCep != undefined &&
            dsRazaoSocial != undefined &&
            dsNomeFantasia != undefined &&
            dsCnpj != undefined &&
            dsNumero != undefined
        ){
            setLiberarCadastro(true)
        }else{
            setLiberarCadastro(false)
        }
    }, [permissoes,dsEmail,dsTelefone,dsLogradouro,dsEstado,dsBairro,dsCidade,dsCep,dsNome,dsSobrenome,dsCpf,dsRazaoSocial,dsNomeFantasia,dsCnpj,dsNumero])

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleCadastrar())}>
            {spinAtivo && <div className='componente-spinner-overlay'><div className='componente-spinner-container'><SpinerVeye/></div></div>}
            <div className='backoffice-modal-cadastro-cliente fundo-cor-6'>
                <Form.Row>
                    <Col>
                        <img className='icone-label-janela nao-selecionavel' src={elipseVerde()} alt='elipse'/>
                        <Form.Label>
                            <div className='fonte-cor-1 label-janela'>Cadastro de Cliente</div>
                        </Form.Label>
                        <Button className='botao-fechar-janela' variant='danger' onClick={() => handleCancelar()}>
                            <img className='botao-fechar-janela-icone' src={fecharJanela()} alt='fechar-janela'/>
                        </Button>
                    </Col>
                </Form.Row>
                <Tabs activeKey={tabAtivadoPrincipal} onSelect={(k) => handerAlterarTabPrincipal(k)} class="backoffice-cadastro-cliente-tabs" id="uncontrolled-tab-example">
                    <Tab onClick={() => setTabAtivadoPrincipal("1")} tabClassName={elementoAtivadoPrincipal == "1"? "nao-selecionavel backoffice-cadastro-cliente-tab-ativado fonte-cor-1":"nao-selecionavel backoffice-cadastro-cliente-tab-desativado fonte-cor-1"} eventKey={"1"} title={"Dados do Usuário"}>
                        <div className='backoffice-cadastro-cliente-linha-1'>
                            <div className='backoffice-cadastro-cliente-coluna-0'>
                                <Form.Group>
                                    <Form.Label>
                                        <div className='fonte-cor-1 label-campo'>Tipo de Pessoa</div>
                                    </Form.Label>
                                    { revelarSelectTipoPessoa === false &&
                                        <Button className='campo-texto-cor-3 campo-select' onClick={() => setRevelarSelectTipoPessoa(!revelarSelectTipoPessoa)}>
                                            <div className='fonte-cor-1 label-campo campo-texto-select-tamanho'>{dsTipoPessoa}</div>
                                            <img className={revelarSelectTipoPessoa?'campo-select-icone-ativado nao-selecionavel':'campo-select-icone-desativado nao-selecionavel'} src={setaDropdown()} alt='drop' />
                                        </Button>
                                    }
                                    { revelarSelectTipoPessoa === true &&
                                            <SelectGenerico
                                                paramRota={'tipo_de_pessoa'}
                                                paramCampo1={null}
                                                paramCampo2={null}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                setCodigo={setCdTipoPessoa}
                                                setDescricao1={setDsTipoPessoa}
                                                setDescricao2={setDummy}
                                                setDescricao3={setDummy}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectTipoPessoa}
                                                placeholder={dsTipoPessoa}
                                                sufixo={''}
                                            >
                                            </SelectGenerico>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                        {cdTipoPessoa === 1 &&
                            <div className='backoffice-cadastro-cliente-linha-1'>
                                <div className='backoffice-cadastro-cliente-coluna-1'>
                                    <Form.Group>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>CPF</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>O CPF deve possuir no mínimo 11 caracteres</Tooltip>}>
                                            <Form.Control
                                                value={dsCpf}  
                                                className='campo-texto-cor-3 campo-texto'  
                                                type='text' 
                                                placeholder='000.000.000-00'
                                                onChange={e => handleCpf(e.target.value)}
                                                maxlength="14"
                                            />
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                                <div className='backoffice-cadastro-cliente-coluna-2'>
                                    <Form.Group>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>Nome</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o nome do cliente</Tooltip>}>
                                            <Form.Control
                                                value={dsNome}  
                                                className='campo-texto-cor-3 campo-texto' 
                                                type='text' 
                                                placeholder='Insira o nome'
                                                onChange={e => setDsNome(e.target.value)} 
                                            />
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                                <div className='backoffice-cadastro-cliente-coluna-3'>
                                    <Form.Group>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>Sobrenome</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o sobrenome do cliente</Tooltip>}>
                                            <Form.Control
                                                value={dsSobrenome}  
                                                className='campo-texto-cor-3 campo-texto' 
                                                type='text' 
                                                placeholder='Insira sobrenome'
                                                onChange={e => setDsSobrenome(e.target.value)} 
                                            />
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                            </div>
                        }
                        {cdTipoPessoa === 2 &&
                            <div className='backoffice-cadastro-cliente-linha-1'>
                                <div className='backoffice-cadastro-cliente-coluna-1'>
                                    <Form.Group>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>CNPJ</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>O CPF deve possuir no mínimo 14 caracteres</Tooltip>}>
                                            <Form.Control
                                                value={dsCnpj}  
                                                className='campo-texto-cor-3 campo-texto'  
                                                type='text' 
                                                placeholder='00.000.000/0000-00'
                                                onChange={e => handleCnpj(e.target.value)}
                                                onBlur={e => handleConsultarCnpj(e.target.value)}
                                                maxlength="18"
                                            />
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                                <div className='backoffice-cadastro-cliente-coluna-2'>
                                    <Form.Group>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>Nome Fantasia</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o nome fantasia do cliente</Tooltip>}>
                                            <Form.Control
                                                value={dsNomeFantasia}  
                                                className='campo-texto-cor-3 campo-texto' 
                                                type='text' 
                                                placeholder='Insira o nome'
                                                onChange={e => setDsNomeFantasia(e.target.value)} 
                                            />
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                                <div className='backoffice-cadastro-cliente-coluna-3'>
                                    <Form.Group>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>Razão Social</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira a razão social do cliente</Tooltip>}>
                                            <Form.Control
                                                value={dsRazaoSocial}  
                                                className='campo-texto-cor-3 campo-texto' 
                                                type='text' 
                                                placeholder='Insira sobrenome'
                                                onChange={e => setDsRazaoSocial(e.target.value)} 
                                            />
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                            </div>
                        }
                        {cdTipoPessoa !== undefined && cdTipoPessoa !== null && cdTipoPessoa !== 0 &&
                            <>
                                <div className='backoffice-cadastro-cliente-linha-2'>
                                    <div className='backoffice-cadastro-cliente-coluna-4'>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>E-mail</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o e-mail</Tooltip>}>
                                            <Form.Control
                                                value={dsEmail}  
                                                className='campo-texto-cor-3 campo-texto'  
                                                type='email' 
                                                placeholder='exemplo@email.com.br'
                                                onChange={e => setDsEmail(e.target.value)}
                                                ref={register({validate: {minimo: value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) == true}})}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='backoffice-cadastro-cliente-coluna-5'>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>Telefone</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o telefone</Tooltip>}>
                                            <Form.Control
                                                value={dsTelefone}  
                                                className='campo-texto-cor-3 campo-texto'  
                                                type='tel' 
                                                placeholder='(00) 00000-0000'
                                                onChange={e => handleTelefone(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                </div>   
                                <div className='backoffice-cadastro-cliente-linha-2'>
                                    <div className='backoffice-cadastro-cliente-coluna-7'>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>Endereço</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o logradouro</Tooltip>}>
                                            <Form.Control
                                                value={dsLogradouro}  
                                                className='campo-texto-cor-3 campo-texto'  
                                                type='text' 
                                                placeholder='Digite o logradouro'
                                                onChange={e => setDsLogradouro(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='backoffice-cadastro-cliente-coluna-8'>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>Número</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o número</Tooltip>}>
                                            <Form.Control
                                                value={dsNumero}  
                                                className='campo-texto-cor-3 campo-texto'  
                                                type='text' 
                                                placeholder='000'
                                                onChange={e => setDsNumero(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='backoffice-cadastro-cliente-coluna-9'>
                                    <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>CEP</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>O CEP deve possuír no mínimo 8 caracteres</Tooltip>}>
                                            <Form.Control
                                                value={dsCep}  
                                                className='campo-texto-cor-3 campo-texto'  
                                                type='text' 
                                                placeholder='00000-000'
                                                onChange={e => handleCep(e.target.value)}
                                                onBlur={e => handlerConsultarCep(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className='backoffice-cadastro-cliente-linha-2'>
                                    <div className='backoffice-cadastro-cliente-coluna-10'>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>Bairro</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o bairro</Tooltip>}>
                                            <Form.Control
                                                value={dsBairro}  
                                                className='campo-texto-cor-3 campo-texto'  
                                                type='text' 
                                                placeholder='Digite o bairro'
                                                onChange={e => setDsBairro(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='backoffice-cadastro-cliente-coluna-11'>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>Cidade</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira a cidade</Tooltip>}>
                                            <Form.Control
                                                value={dsCidade}  
                                                className='campo-texto-cor-3 campo-texto'  
                                                type='text' 
                                                placeholder='Digite a cidade'
                                                onChange={e => setDsCidade(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='backoffice-cadastro-cliente-coluna-12'>
                                        <Form.Label>
                                            <div className='fonte-cor-1 label-campo'>Estado</div>
                                        </Form.Label>
                                        { revelarSelectEstado === false &&
                                            <Button className='campo-texto-cor-3 campo-select' onClick={() => setRevelarSelectEstado(!revelarSelectEstado)}>
                                                <div className='fonte-cor-1 label-campo campo-texto-select-tamanho'>{dsEstado}</div>
                                                <img className={revelarSelectEstado?'campo-select-icone-ativado nao-selecionavel':'campo-select-icone-desativado nao-selecionavel'} src={setaDropdown()} alt='drop' />
                                            </Button>
                                        }
                                        { revelarSelectEstado === true &&
                                            <SelectGenerico
                                                paramRota={'pav_estado'}
                                                paramCampo1={null}
                                                paramCampo2={null}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                setCodigo={setCdEstado}
                                                setDescricao1={setDsEstado}
                                                setDescricao2={setDummy}
                                                setDescricao3={setDummy}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectEstado}
                                                placeholder={dsEstado}
                                                sufixo={''}
                                            >
                                            </SelectGenerico>
                                        }
                                    </div>
                                </div>                                           
                                <div className='backoffice-cadastro-cliente-linha-2'>
                                <div className='backoffice-cadastro-cliente-coluna-13'>
                                    <Form.Label>
                                        <div className='fonte-cor-1 label-campo'>Informações adicionais (opcional)</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira informações como apartamento, sala comercial, pontos de referência, etc</Tooltip>}>
                                        <Form.Control
                                            value={dsInformacoesAdicionais}  
                                            className='campo-texto-cor-3 campo-texto'  
                                            type='text' 
                                            placeholder='Insira informações como apartamento, sala comercial, pontos de referência, etc'
                                            onChange={e => setDsInformacoesAdicionais(e.target.value)}
                                        />
                                    </OverlayTrigger>
                                </div>
                            </div>   
                            </>
                        }
                    </Tab>
                    <Tab onClick={() => setTabAtivadoPrincipal("2")} tabClassName={elementoAtivadoPrincipal == "2"? "nao-selecionavel backoffice-cadastro-cliente-tab-ativado fonte-cor-1":"nao-selecionavel backoffice-cadastro-cliente-tab-desativado fonte-cor-1"} eventKey={"2"} title={"Permissões"}>
                        <MenuCadastroPermissoes setPermissoes={setPermissoes}/>
                    </Tab>
                </Tabs>
                <Form.Row>
                    <Col>
                        {liberarCadastro === false?
                            <Button  className={'botao-confirmar-desabilitado'} variant='success' size='sm'>
                                <div className='fonte-cor-4'>Cadastrar</div>
                            </Button>
                        :
                            <Button  type='submit' className={'botao-confirmar'} variant='success' size='sm'>
                                <div className='fonte-cor-4'>Cadastrar</div>
                            </Button>
                        }
                        <Button className='botao-cancelar' variant='success' size='sm' onClick={() => handleCancelar()}>
                            <div className='fonte-cor-2'>Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={'Sucesso'}
                            mensagemParagrafo1={dsMensagemSucesso}
                            mensagemBotao={'Continuar'}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={'Erro'}
                            mensagemParagrafo1={dsMensagemErro}
                            mensagemBotao={'Retornar'}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
