import { corDash as coresPadrao } from "./../../../../../utils/dashboard";

export const isTemaClaro = () => {
    const localTema = localStorage.getItem('tema');
    return localTema === 'claro'
}

export const arrGraficoVazio = [{ "value": 0, "cor": isTemaClaro() ? coresPadrao.cinza : coresPadrao.cinzaEscuro }];

export const QUANTIDADE = 'quantidade';
export const PERCENTUAL = 'percentual';