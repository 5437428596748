import React, { useEffect, useState } from 'react';
import { TimeSeries, TimeRangeEvent, TimeRange } from "pondjs";
import { Charts, ChartContainer, ChartRow, EventChart, Resizable } from "react-timeseries-charts";
import LegendaTimeline from './legendaTimeline'

export default function Timeline(props) {

    const [status, setStatus] = useState('-')
    const [dataInicio, setDataInicio] = useState('-')
    const [dataFim, setDataFim] = useState('-')
    const [duracao, setDuracao] = useState('-')
    const [trafegoKb, setTrafegoKb] = useState('-')
    const [trafegoMb, setTrafegoMb] = useState('-')
    const [downloadKb, setDownloadKb] = useState('-')
    const [uploadKb, setUploadKb] = useState('-')
    const [pacotesRecebidos, setPacotesRecebidos] = useState('-')
    const [pacotesEnviados, setPacotesEnviados] = useState('-')
    const [apn, setApn] = useState('-')
    const [ip, setIp] = useState('-')
    const [imei, setImei] = useState('-')
    const [tecnologia, setTecnologia] = useState('-')
    const [terminoConexao, seTerminoConexao] = useState('-')
    const events = props.dados.map((data) =>
        new TimeRangeEvent(new TimeRange(new Date(data.dataInicio), new Date(data.dataFim)), data)

    );
    const series = new TimeSeries({ name: "outages", events });



    function outageEventStyleFunc(event, state) {
        const color = event.get("status") === "Online" ? "#36D896" : "#EE423D";
        switch (state) {
            case "normal":
                return {
                    fill: color
                };
            case "hover":
                return {
                    fill: color,
                    opacity: 0.4
                };
            case "selected":
                return {
                    fill: color
                };
            default:
        }
    }

    const [tracker, setTracker] = useState(null)
    const [timerange, setTimerange] = useState(series.timerange())

    useEffect(() => {
        setTracker(tracker)
    }, [tracker])

    function handleTimeRangeChange(e) {
        setTimerange(e)
    }

    function handleTooltip(pdataInicio, pdataFim, pduracao, ptrafegoKb, ptrafegoMb, pdownloadKb, puploadKb, ppacotesRecebidos, ppacotesEnviados, papn, pip, pimei, ptecnologia, pterminoConexao, pstatus) {
        setDataInicio(pdataInicio)
        setDataFim(pdataFim)
        setDuracao(pduracao)
        setTrafegoKb(ptrafegoKb)
        setTrafegoMb(ptrafegoMb)
        setDownloadKb(pdownloadKb)
        setUploadKb(puploadKb)
        setPacotesRecebidos(ppacotesRecebidos)
        setPacotesEnviados(ppacotesEnviados)
        setApn(papn)
        setIp(pip)
        setImei(pimei)
        setTecnologia(ptecnologia)
        seTerminoConexao(pterminoConexao)
        setStatus(pstatus)
    }

    return (
        <div className="container-timeseries-grafico-tooltip">
            <div className="container-timeseries-tooltip">
                <LegendaTimeline
                    dataInicio={dataInicio}
                    dataFim={dataFim}
                    duracao={duracao}
                    trafegoKb={trafegoKb}
                    trafegoMb={trafegoMb}
                    downloadKb={downloadKb}
                    uploadKb={uploadKb}
                    pacotesRecebidos={pacotesRecebidos}
                    pacotesEnviados={pacotesEnviados}
                    apn={apn}
                    ip={ip}
                    imei={imei}
                    tecnologia={tecnologia}
                    terminoConexao={terminoConexao}
                    status={status}
                />
            </div>
            <div className="container-timeseries-grafico">
                <Resizable>
                    <ChartContainer
                        timeRange={timerange}
                        enablePanZoom={true}
                        onTimeRangeChanged={(e) => handleTimeRangeChange(e)}
                    >
                        <ChartRow height="60">
                            <Charts>
                                <EventChart
                                    series={series}
                                    size={45}
                                    style={outageEventStyleFunc}
                                    onSelectionChange={(e) => handleTooltip(
                                        e.get("dataInicio"),
                                        e.get("dataFim"),
                                        e.get("duracao"),
                                        e.get("trafegoKb"),
                                        e.get("trafegoMb"),
                                        e.get("downloadKb"),
                                        e.get("uploadKb"),
                                        e.get("pacotesRecebidos"),
                                        e.get("pacotesEnviados"),
                                        e.get("apn"),
                                        e.get("ip"),
                                        e.get("imei"),
                                        e.get("tecnologia"),
                                        e.get("terminoConexao"),
                                        e.get("status")
                                    )}
                                />
                            </Charts>
                        </ChartRow>
                    </ChartContainer>
                </Resizable>
            </div>
        </div>
    )
};