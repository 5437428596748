/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { api } from "../../../../conexoes/api.js";
import {
  iconeMensagemSucesso,
  olhoDesligado,
  olhoLigado,
} from "../../../../utils/alternanciaIcones.js";
import MensagemErro from "../../../componentesModais/mensagens/mensagemErro.js";
import MensagemSucesso from "../../../componentesModais/mensagens/mensagemSucesso.js";
import SpinerVeye from "../../../spinnerVeye/spinnerVeye.js";
import { useDispatch } from "react-redux";
import iconeGerarSenha from "../../../../icones/gerar-senha.svg";
import fechar from "../../../../icones/iconesVersaoEscura/fechar_janela.svg";
import ellipseVerde from "../../../../icones/iconesVersaoVerde/ellipse.svg";
import {
  revelarEditarUsuario,
  revelarNovoUsuario,
} from "../../../../redux/actions/index";
import decryptJWT from "../../../../utils/decryptJWT.js";
import ColetaClickstream from "../../../clickstreamAnalise/clickstreamColeta.js";
import "../../../../css/painelAdministrativo/pavUsuario/pavUsuario.css";
import * as S from "./styles.js";

function gerarSenhaAleatoria() {
  const letrasMaiusculas = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const letrasMinusculas = "abcdefghijklmnopqrstuvwxyz";
  const numeros = "0123456789";
  const caracteresEspeciais = "!@#$%^&*()_+{}[]:;,.?~\\-|<>=";

  const todosCaracteres =
    letrasMaiusculas + letrasMinusculas + numeros + caracteresEspeciais;

  const gerarCaracter = (grupo) =>
    grupo.charAt(Math.floor(Math.random() * grupo.length));

  let senha = [
    gerarCaracter(letrasMaiusculas),
    gerarCaracter(numeros),
    gerarCaracter(letrasMinusculas),
    gerarCaracter(caracteresEspeciais),
  ];

  for (let i = senha.length; i <= 11; i++) {
    senha.push(
      todosCaracteres.charAt(Math.floor(Math.random() * todosCaracteres.length))
    );
  }

  return senha.join("");
}

export default function CadastroUsuario(props) {
  const [spinAtivo, setSpinAtivo] = useState(false);
  const [abrirSnackBar, setAbrirSnackBar] = useState(false);
  const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false);
  const [revelarMensagemErro, setRevelarMensagemErro] = useState(false);
  const [mostrarSenha, setMostrarSenha] = useState("password");
  const [mensagemErro, setMensagemErro] = useState(
    "Ocorreu um erro ao executar esta ação"
  );

  const { tituloJanela } = props;

  const isEditar = tituloJanela === "Editar";
  const phMensagemSucesso = isEditar ? "editado" : "cadastrado";

  const despacho = useDispatch();

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { dirty },
  } = useForm();

  const {
    dsCliente,
    cdCliente,
    cdUsuario,
    dsUsuario,
    dsEmail,
    dsSobrenome,
    dsTelefone,
  } = watch([
    "dsCliente",
    "cdCliente",
    "cdUsuario",
    "dsUsuario",
    "dsEmail",
    "dsSobrenome",
    "dsTelefone",
  ]);

  useEffect(() => {
    setSpinAtivo(true);
    if (tituloJanela === "Editar") {
      const {
        dsCliente,
        cdCliente,
        cdUsuario,
        dsUsuario,
        dsEmail,
        dsSobrenome,
        dsTelefone,
        flAtivo,
      } = props;

      reset({
        dsCliente,
        cdCliente,
        cdUsuario,
        dsUsuario,
        dsEmail,
        dsSobrenome,
        dsTelefone,
        flAtivo,
      });
    }

    setSpinAtivo(false);
  }, [tituloJanela, props, reset]);

  function handleSalvar(data) {
    if (!dirty) return;

    if (tituloJanela === "Cadastrar") {
      ColetaClickstream(1373);
      handleCadastrar(data);
    } else {
      ColetaClickstream(1374);
      handleAlterar(data);
    }
  }

  async function handleAlterar(data) {
    let camposAlterados = {};

    if (props.dsUsuario !== dsUsuario) {
      camposAlterados.ds_pav_usuario = dsUsuario;
    }
    if (props.dsSobrenome !== dsSobrenome) {
      camposAlterados.ds_pav_usuario_sobrenome = dsSobrenome;
    }
    if (props.dsTelefone !== dsTelefone) {
      camposAlterados.ds_telefone = dsTelefone;
    }
    if (props.dsEmail !== dsEmail) {
      camposAlterados.ds_email = dsEmail;
    }
    if (data.dsSenha) {
      camposAlterados.ds_senha = data.dsSenha;
      camposAlterados.fl_padrao_senha = 1;
    }

    setSpinAtivo(true);

    await api
      .post("/m1/alterar/usuario", {
        dados: {
          cd_pav_cliente: cdCliente,
          fl_ativo: data.flAtivo ? 1 : 0,
          cd_pav_usuario_updated: decryptJWT("codigo"),
          ...camposAlterados,
        },
        condicao: {
          cd_pav_usuario: cdUsuario,
        },
      })
      .then(() => setRevelarMensagemSucesso(true))
      .catch((error) => {
        setMensagemErro(error.response.data.info);
        setRevelarMensagemErro(true);
      })
      .finally(() => setSpinAtivo(false));
  }

  async function handleCadastrar(data) {
    setSpinAtivo(true);
    await api
      .post("/m1/cadastrar/usuario", {
        ds_pav_usuario: data.dsUsuario.trim(),
        ds_email: data.dsEmail.trim(),
        ds_senha: data.dsSenha,
        cd_pav_cliente: cdCliente,
        ds_telefone: data.dsTelefone.trim(),
        ds_pav_usuario_sobrenome: data.dsSobrenome.trim(),
        cd_pav_usuario_created: decryptJWT("codigo"),
        fl_ativo: data.flAtivo ? 1 : 0,
      })
      .then(() => setRevelarMensagemSucesso(true))
      .catch((error) => {
        setSpinAtivo(false);
        setMensagemErro(error.response.data.info);
        setRevelarMensagemErro(true);
      })
      .finally(() => setSpinAtivo(false));
  }

  function handleCancelar() {
    ColetaClickstream(1372); // dropAcoesRevelarNovoUsuarioCancelar
    setRevelarMensagemSucesso(false);
    setRevelarMensagemErro(false);
    despacho(revelarNovoUsuario(false));
    despacho(revelarEditarUsuario(false));
    props.setRevelarEdicao(false);
  }

  const exibirIcone = useMemo(() => {
    return mostrarSenha === "password" ? (
      <img src={olhoDesligado()} alt="olho desligado" />
    ) : (
      <img src={olhoLigado()} alt="olho ligado" />
    );
  }, [mostrarSenha]);

  function handleMensagemErro(confirmacao) {
    if (revelarMensagemErro === false) {
      setRevelarMensagemErro(true);
    } else {
      setRevelarMensagemErro(false);
      if (confirmacao === true) {
        //ALGUMA FUNÇÃO
      }
    }
  }

  function handleMensagemSucesso(confirmacao) {
    if (revelarMensagemSucesso === false) {
      setRevelarMensagemSucesso(true);
    } else {
      setRevelarMensagemSucesso(false);
      if (confirmacao === true) {
        handleCancelar();
      }
      handleCancelar();
    }
  }

  const handleGerarSenha = () => {
    const senha = gerarSenhaAleatoria();

    setValue("dsSenha", senha);

    navigator.clipboard.writeText(senha).then(() => {
      setAbrirSnackBar(true);
    });
  };

  const handleSwitchChange = (event) => {
    setValue("flAtivo", event.target.checked);
  };

  const handleClose = () => setAbrirSnackBar(false);

  const handleVerSenha = () =>
    setMostrarSenha((prev) => (prev === "password" ? "text" : "password"));

  return (
    <S.Overalay>
      <S.Modal>
        {spinAtivo && (
          <div className="componente-spinner-overlay">
            <div className="componente-spinner-container">
              <SpinerVeye />
            </div>
          </div>
        )}
        <S.TituloWrapper>
          <S.Elipse src={ellipseVerde} alt="elipse" />
          <S.TituloModal> {tituloJanela} usuário</S.TituloModal>
          <S.BotaoFechar onClick={handleCancelar}>
            <img
              className="botao-fechar-janela-icone"
              src={fechar}
              alt="fechar-janela"
            />
          </S.BotaoFechar>
        </S.TituloWrapper>
        <S.Form onSubmit={handleSubmit(handleSalvar)}>
          <S.GrupoInput>
            <S.InputLabel>Cliente</S.InputLabel>
            <S.ClienteSelecionado>{dsCliente}</S.ClienteSelecionado>
          </S.GrupoInput>
          <S.Linha>
            <S.GrupoInput>
              <S.InputLabel>Nome*</S.InputLabel>
              <S.Input
                autocomplete="off"
                name="dsUsuario"
                type="text"
                placeholder="******"
                maxlength={100}
                ref={register({
                  validate: { minimo: (value) => value.length > 1 },
                })}
              />
            </S.GrupoInput>
            <S.GrupoInput>
              <S.InputLabel>Sobrenome</S.InputLabel>
              <S.Input
                autoComplete="off"
                name="dsSobrenome"
                type="text"
                placeholder="Insira o sobrenome"
                maxLength={100}
                ref={register({
                  required: !isEditar ? "Sobrenome é obrigatório" : false,
                  validate: (value) => {
                    if (value && value.length <= 1) {
                      return "Mínimo de 2 caracteres";
                    }
                    return true;
                  },
                })}
              />
            </S.GrupoInput>
          </S.Linha>
          <S.Linha linhaEmail>
            <S.GrupoInput>
              <S.InputLabel>Telefone</S.InputLabel>
              <S.Input
                name="dsTelefone"
                type="text"
                placeholder="Insira o telefone"
                minLength={11}
                maxlength={11}
                ref={register({
                  required: !isEditar ? "Telefone é obrigatório" : false,
                  validate: !isEditar
                    ? {
                        minimo: (value) =>
                          value.length >= 11 || "Mínimo de 11 caracteres",
                        maximo: (value) =>
                          value.length <= 11 || "Máximo de 11 caracteres",
                      }
                    : undefined,
                })}
              />
            </S.GrupoInput>
            <S.GrupoInput>
              <S.InputLabel>E-mail*</S.InputLabel>
              <S.Input
                name="dsEmail"
                type="text"
                placeholder="Insira o e-mail"
                maxlength={100}
                ref={register({
                  validate: {
                    minimo: (value) =>
                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) === true,
                  },
                })}
              />
            </S.GrupoInput>
            <S.GrupoInput>
              <S.InputLabel>Ativo</S.InputLabel>
              <S.CustomSwitch
                aria-label="flAtivo"
                disableRipple
                name="flAtivo"
                inputRef={register}
                checked={watch("flAtivo")}
                onChange={handleSwitchChange}
              />
            </S.GrupoInput>
          </S.Linha>
          <S.Aviso>*Campos obrigatórios</S.Aviso>
          <S.Divisor />
          <S.Linha>
            <S.GrupoInput>
              <S.InputLabel>Senha</S.InputLabel>
              <S.InputWrapper>
                <S.Input
                  autoComplete="off"
                  type={mostrarSenha}
                  disabled
                  placeholder="Insira a senha"
                  name="dsSenha"
                  ref={register}
                />
                <S.BotaoVerSenha type="button" onClick={handleVerSenha}>
                  {exibirIcone}
                </S.BotaoVerSenha>
              </S.InputWrapper>
            </S.GrupoInput>
            <S.BotaoGerarSenha type="button" onClick={handleGerarSenha}>
              <img alt="gerar_senha" src={iconeGerarSenha} />
              GERAR SENHA
            </S.BotaoGerarSenha>
          </S.Linha>
          <S.Divisor />
          <S.BotoesWrapper>
            <S.BotaoCancelar onClick={() => handleCancelar()}>
              CANCELAR
            </S.BotaoCancelar>
            <S.BotaoConfirmar
              disabled={!dirty || !dsUsuario || !dsEmail}
              type="submit"
            >
              CONFIRMAR
            </S.BotaoConfirmar>
          </S.BotoesWrapper>
        </S.Form>
        {revelarMensagemSucesso && (
          <div className="componente-modal-overlay">
            <div className="componente-modal">
              <MensagemSucesso
                handleMensagemSucesso={handleMensagemSucesso}
                mensagemTitulo="Sucesso"
                mensagemParagrafo1={`Usuário ${dsUsuario} ${phMensagemSucesso}.`}
                mensagemBotao="Continuar"
              />
            </div>
          </div>
        )}
        {revelarMensagemErro && (
          <div className="componente-modal-overlay">
            <div className="componente-modal">
              <MensagemErro
                handleMensagemErro={handleMensagemErro}
                mensagemTitulo={"Erro!"}
                mensagemParagrafo1={mensagemErro}
                mensagemBotao={"Retornar"}
              />
            </div>
          </div>
        )}
      </S.Modal>
      <S.CustomSnackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={abrirSnackBar}
        onClose={handleClose}
        message={
          <S.MensagemWrapper>
            <img src={iconeMensagemSucesso()} alt="icone_sucesso" />
            <span>
              A senha foi gerada e copiada para a área de transferência.
            </span>
          </S.MensagemWrapper>
        }
      ></S.CustomSnackbar>
    </S.Overalay>
  );
}
