import styled, { css } from "styled-components";

export const Overaly = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 14px 1fr auto;
  align-items: center;
  gap: 16px;
`;

export const TituloModal = styled.h1`
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  color: ${({ theme }) => theme.cor49};
`;

export const Modal = styled.div`
  position: absolute;
  margin: auto auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  border-radius: 8px;
  width: 430px;
  background-color: ${({ theme }) => theme.cor23};
`;

export const MensagensErroWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 384px;
  height: 320px;
  background-color: ${({ theme }) => theme.cor60};
  border-radius: 8px;
  padding: 12px;
`;

export const ConteudoModal = styled.div``;

export const Elipse = styled.img`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.cor11};
`;

export const SenhasWrapper = styled.div`
  display: grid;
  width: max-content;
  gap: 24px;
  margin-top: 24px;
`;

export const InputGroup = styled.div`
  display: grid;
  gap: 16px;
`;

export const InputWrapper = styled.div`
  width: 380px;
  height: 40px;
  position: relative;
  border-radius: 24px;
  padding: 8px 12px;
  color: ${({ theme }) => theme.cor49};
  background-color: ${({ theme }) => theme.cor27};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ erro }) =>
    erro &&
    css`
      border: 1px solid #d32f2f;
    `}
`;

export const InputLabel = styled.label`
  color: ${({ theme }) => theme.cor49};
  font-size: 1.25em;
  margin: 0;
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  font-size: 18px;
  line-height: 1.0625em;
  outline: none;
  width: 100%;
  color: ${({ theme }) => theme.cor49};

  ::placeholder {
    color: ${({ theme }) => theme.cor56};
  }
`;

export const BotaoVerSenha = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  outline: none !important;
`;

export const TituloMensagem = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.cor49};
`;

export const ValidacoesWrapper = styled.div`
  display: grid;
  gap: 6px;
`;

export const GrupoValidacao = styled.div`
  font-size: 16px;
  display: flex;
  gap: 8px;
  color: ${({ theme }) => theme.cor49};

  ${({ subGrupo }) =>
    subGrupo &&
    css`
      padding-left: 20px;
    `}

  p {
    margin: 0;
  }

  img {
    margin-top: 6px;
    width: 15px;
    height: 15px;
  }
`;

export const VerSenhaImg = styled.img`
  width: 25px;
  height: auto;
`;

export const BotoesWrapper = styled.div`
  margin-top: 2em;
  display: flex;
  gap: 24px;
  justify-content: flex-end;
`;

export const BotaoConfirmar = styled.button`
  background-color: ${({ theme }) => theme.cor11};
  border: none;
  font-family: RobotoBold;
  font-size: 1em;
  font-weight: 700;
  height: 2.625em;
  min-width: 8.75em;
  padding: 0 1.875em;
  box-shadow: none;
  border-radius: 3.125em;
  outline: none;
  color: ${({ theme }) => theme.cor1};

  :disabled {
    background-color: ${({ theme }) => theme.cor61};
    color: ${({ theme }) => theme.cor62};
  }
`;

export const BotaoCancelar = styled.button`
  font-family: RobotoBold;
  font-size: 1em;
  font-weight: 700;
  height: 2.625em;
  min-width: 8em;
  padding: 0 1.875em;
  border: 0.09375em solid ${({ theme }) => theme.cor11};
  color: ${({ theme }) => theme.cor11};
  background-color: transparent;
  box-shadow: none;
  border-radius: 3.125em;
  outline: none !important;
`;

export const BotaoFechar = styled.button`
  height: 2.1875em;
  width: 2.1875em;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none !important;
`;
