import React from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import styled from "styled-components";

const TooltipContent = styled.div`
  max-width: 200px;
`;

export const CustomTooltip = ({ text, children, visible }) => {
  return (
    <Tooltip
      visible={visible}
      overlay={<TooltipContent>{text}</TooltipContent>}
      placement="bottom"
      trigger="hover"
      destroyTooltipOnHide={true}
      mouseEnterDelay={0.3}
      overlayInnerStyle={{
        color: "#FFF",
        borderRadius: "5px",
        background: "#5b605d",
        fontSize: "14px",
        width: "max-content",
        minHeight: "auto",
        padding: "4px 8px",
      }}
    >
      {children}
    </Tooltip>
  );
};
