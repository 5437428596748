import React from 'react'
import MensagemAtencao from './mensagemAtencao'

export default function MensagemConfirmarExclusao({handleMensagemAtencao, mensagemTitulo}) {
  return (
    <MensagemAtencao
        handleMensagemAtencao={handleMensagemAtencao}
        mensagemTitulo={mensagemTitulo}
        mensagemParagrafo1={"Registros deletados não poderão ser recuperados."}
        mensagemBotao={"Deletar"}
    />
  )
}