//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import {Form} from 'react-bootstrap';/*eslint-disable*/
//REDUX
import { useDispatch } from 'react-redux';
import { reenviarEmailVirtueyes } from "../../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta'

export default function FinalizarAtendimento(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS PRINCIPAIS

    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [renderizar, setRenderizar] = useState(false);
    const [revelarMensagemAtencaoConclusao, setRevelarMensagemAtencaoConclusao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const despacho = useDispatch();
    const modal = useRef();

    async function handleSalvar(){
        ColetaClickstream(1392) // 'solicitacaoOperadoraTabelaReenviarEmailReenviar
        reenviar()
    }

    async function handleCancelar() {
        setSpinAtivo(false)
        despacho(reenviarEmailVirtueyes(false))
        props.setRenderizar(!props.renderizar)
    }

    useEffect (() => {
        setRevelarMensagemAtencaoConclusao(true)
    }, renderizar)

    function reenviar() {
        setSpinAtivo(true)
        async function reenviarEmails (){
            await api.post('m1/cadastrar/solicitacao/reenviar_email', {
                solicitacoes: props.itensSelecionados
            })
            .then(function (response) {
                setSpinAtivo(false)
                handleMensagemSucesso(true)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro ao reenviar emails")
                handleMensagemErro(false)
            });
        }
        reenviarEmails()
    }
    
    function handleMensagemAtencaoConclusao(confirmacao){
        if(revelarMensagemAtencaoConclusao === false){
            setRevelarMensagemAtencaoConclusao(true)
            handleCancelar()
        }else{
            setRevelarMensagemAtencaoConclusao(false)
        }
        if(confirmacao === true){
            handleSalvar();
        } else {
            ColetaClickstream(1391) // 'solicitacaoOperadoraTabelaReenviarEmailCancelar
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
    }
    
    return(
        <Form ref={modal}>
            { revelarMensagemAtencaoConclusao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencaoConclusao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={"Tem certeza que deseja reenviar este(s) e-mail(s)?"}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"E-mail(s) reenviado(s)."}
                            mensagemBotao={"Ok"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro ao reenviar e-mail(s)"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
