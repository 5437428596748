//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import { Button, Form } from 'react-bootstrap';
import decryptJWT from '../../../utils/decryptJWT'
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
  
export default function SelectFaturasCheckbox(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [lsFaturasCheckboxs, setLsFaturasCheckboxs] = useState([])
    const modal = useRef();
    let todosUsuarios = []

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleFaturasCheckbox(codigo, label, checkbox){
        if(codigo === -1){
            handleSelecionarTodos(checkbox)
        }
        else{
            if(checkbox === true){
                props.arrayCdFaturas.push({codigo: codigo, descricao1: label})
            }else{
                props.arrayCdFaturas.forEach(function(e, index){
                    if(codigo == e.codigo){
                        props.arrayCdFaturas.splice(index, 1);
                    }
                })
            }
        }
    }

    function handleSelecionarTodos(checked){
        const length = props.arrayCdFaturas.length
        let i =0
        while(i < length){
            props.arrayCdFaturas.pop()
            i++
        }
  
        if(checked === true){
            todosUsuarios.forEach(function(item,i){
                props.arrayCdFaturas.push({codigo: item.codigo, descricao1: item.descricao1})
        })
       }
    }

    function handleCancelar(){
        props.setRevelarselectFaturasCheckbox(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderFaturaCheckboxs(){
            const {data} = await api.post("/m1/consultar/select",{
                "dados":{
                    "paramCampo1" : props.cdOperadora,
                    "paramCampo2" : props.dsReferencia,
                    "paramCampo3" : decryptJWT("fatContas"),
                    "paramCampo4" : "",
                    "paramBusca": ""
                },
                "condicao":{
                    "paramRota" : "fatura_por_operadora_referencia",
                }
            })

            data.dados.unshift({"codigo":-1,"descricao1":"Todos"})
            todosUsuarios=data.dados

            if(data.dados.length > 0){
                let objetoFaturaCheckboxs = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.codigo}>
                            <div className="campo-select-opcao-checkbox campo-select-opcao-1">
                                <label className="container-checkbox-item-select">
                                    <input id={item.codigo} type="checkbox" defaultChecked={handleMarcarCheckboxes(item.codigo) ? true : false} onChange={e => handleFaturasCheckbox(item.codigo, item.descricao1, e.target.checked)}/>
                                    <span className="chekmark-checkbox-item-select"></span>
                                </label>
                                <span className="fonte-cor-1 nao-selecionavel">{item.descricao1}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsFaturasCheckboxs(objetoFaturaCheckboxs)
            }else{
                let objetoFaturaCheckboxs = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsFaturasCheckboxs(objetoFaturaCheckboxs)
            }

            setSpinAtivo(false)
        }
        renderFaturaCheckboxs()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function handleMarcarCheckboxes(codigo){
        let verificacao = false
        props.arrayCdFaturas.forEach(function(e, index){
            if(codigo === e.codigo){
                verificacao = true
            }
        })
        return verificacao
    }

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => handleCancelar()}>
                <div className="fonte-cor-1 label-campo">{props.dsFaturasCheckbox}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo triangulo-select-absoluto"></div>
            <div className="campo-texto-cor-3 campo-select-perfis-checkbox-corpo">
                <ul className="campo-select-lista">
                    {lsFaturasCheckboxs}
                </ul>
            </div>
        </Form>
    )
}