import { Col, Form } from "react-bootstrap";

import { Aviso } from "./Aviso";
import OpcoesMenuGrafico from "./OpcoesMenuGrafico";

export default function CardHeader({
  id,
  titulo,
  ciclo,
  InfoHeader,
  handleAlterarCiclo,
}) {
  const isAnaliseDeEconomia = titulo === "Análise de economia";
  const isAnaliseDeExcedentes = titulo === "Análise de excedentes";

  const mostrarLinha = isAnaliseDeExcedentes || isAnaliseDeEconomia;

  const isUltimos6Ciclos = ciclo === "Últimos 6 ciclos";

  return (
    <div>
      <Form.Row className="cardHeader-grafico-migracaoPlano">
        <Col className="cardHeader-grafico-migracaoPlano-col">
          <Form.Label className="titulo-grafico-migracaoPlano">
            <div className="fonte-cor-1 cardHeader-titulo">{titulo}</div>
          </Form.Label>

          <OpcoesMenuGrafico id={id} handleAlterarCiclo={handleAlterarCiclo} />
        </Col>
        <div className="cardHeader-divisor campo-select-divisor-cor-3" />
        <div
          className={`${
            isUltimos6Ciclos
              ? "cardHeader-text-ciclo-dunets"
              : "cardHeader-text-ciclo"
          } fonte-cor-1`}
        >
          {ciclo || "-"}
          {InfoHeader && <InfoHeader />}
          {isAnaliseDeEconomia && <Aviso />}
        </div>

        {mostrarLinha && !isUltimos6Ciclos && (
          <div className="segundo-divisor campo-select-divisor-cor-3" />
        )}
      </Form.Row>
    </div>
  );
}
