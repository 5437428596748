import styled from "styled-components";
import { Skeleton as SkeletonMantine } from "@mantine/core";

export const Skeleton = styled(SkeletonMantine)`
  &.mantine-Skeleton-root.mantine-Skeleton-visible {
    &::before {
      background: ${({ theme }) => theme.skeleton[0]}!important;
    }    
    &::after {
      background: ${({ theme }) => theme.skeleton[1]}!important;
    }
  }
`;