//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectValidadePlano from '../../componentesModais/selects/selectValidadePlano'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectValidadePlano, setRevelarSelectValidadePlano] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">ValidadePlano {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectValidadePlano === false &&
                <Button name="cdValidadePlano" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectValidadePlano(!revelarSelectValidadePlano)}>
                    <div className="fonte-cor-1 label-campo">{dsValidadePlano}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectValidadePlano === true &&
                <SelectValidadePlano
                    setRevelarSelectValidadePlano={setRevelarSelectValidadePlano} //Hook para fechar a modal
                    setCdValidadePlano={setCdValidadePlano} //Código do ValidadePlano
                    setDsValidadePlano={setDsValidadePlano} //Nome do ValidadePlano
                    dsValidadePlano={dsValidadePlano} 
                    phValidadePlano={phValidadePlano} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectValidadePlano>
            }
        </Form.Group>*/

export default function SelectValidadePlano(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleValidadePlano(validadePlano){

        if(validadePlano === 0) {
            props.setDsValidadePlano('Sem fidelidade')
        } else {
            props.setDsValidadePlano(validadePlano)
        }
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectValidadePlano(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectValidadePlano(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsValidadePlano}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleValidadePlano(12)}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">12 meses</span>
                        </div>
                        <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
                    </li>
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleValidadePlano(24)}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">24 meses</span>
                        </div>
                        <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
                    </li>
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleValidadePlano(36)}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">36 meses</span>
                        </div>
                    </li>
                    <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleValidadePlano(0)}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Sem fidelidade</span>
                        </div>
                    </li>
                </ul>
            </div>
        </Form>
    )
}