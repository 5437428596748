//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { api, api_svsolicitacoes } from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { ProgressBar, Button, Form, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import {formatarData} from '../../../../utils/formatadorDataTabela'
//ICONES
import {lixeiraVermelho, alertaAtencao} from '../../../../utils/alternanciaIcones';
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import { revelarDesativarSMSFaturas } from "../../../../redux/actions/index";
//MODAIS
import { RenderMensagemAtencao } from '../../../componentesModais/mensagens/mensagemAtencao'
// import {identificarValidacaoAcoes} from '../../../../utils/identificarValidacaoAcoes';
// COLETA CLICKSTREAM

// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'

// import RetornarParametro from '../../../../utils/decryptJWT'
import decryptJWT from '../../../../utils/decryptJWT'

import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import { getClientePermitidoCMP, getDadosFatSimcard, verificaOperadorasCMP } from '../utils/funcoes';
import { FatModalHeader } from '../utils/componentesModal';
import { MensagemErro } from '../../../componentesModais/mensagens/mensagemAcao';

const valorLimite = 50
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let simcardSelecionados = [];
let itensVar = []

const routeM1FiltrarFatSimcard = '/m1/filtrar/fatSimcard';

export default function SmsBloquear(props) {
    const cd_sol_solicita_tipo = 12;
    const cdCliente = decryptJWT('codigoCliente')

    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { handleSubmit } = useForm();
    //HOOKS PRINCIPAIS
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(0);
    const [linhaRemover, setLinhaRemover] = useState('');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [simcards, setSimcards] = useState([]);
    const [linhas, setLinhas] = useState([]);
    
    const [todosSimcards, setTodosSimcards] = useState([]);
    const [todasLinhas, setTodasLinhas] = useState([]);
    const [verificouCMP, setVerificouCMP] = useState(false);
    const [temCMP, setTemCMP] = useState(false);
    const [mensagemCMP, setMensagemCMP] = useState(false);
    const [revelarMensagemCMP, setRevelarMensagemCMP] = useState(false);

    //HOOKS MODAIS
    const [customMensagemAtencao, setCustomMensagemAtencao] = useState('')
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] = useState(false)
    // const [revelarMensagemAtencaoContemItemInvalido, setRevelarMensagemAtencaoContemItemInvalido] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [itens, setItens] = useState([])
    const [itensInvalidos, setItensInvalidos] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensInvalidosBusca, setItensInvalidosBusca] = useState(false);
    const [revelarSelecaoCmp, setRevelarSelecaoCmp] = useState(false)

    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();

    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    // const codigoCliente = RetornarParametro('codigoClienteErpAntigo')
    // const codigosClientes = RetornarParametro('codigoCliente')

    let itensTemp = []
    
    let itensInvalidosTemp = []
    
    async function getOperadorasCMP() {
        if(verificouCMP || !simcards.length) return;

        const { tempSimcards, tempLinhas, erro } = await getDadosFatSimcard({
            modoSelecaoSimcard, simcards, linhas,
            routeM1FiltrarFatSimcard,
            filtros, conteudoBusca, itensInvalidosBusca, separadorLinha, buscaArquivo
        })

        if(erro) {
            setSpinAtivo(false)
            setMensagemErro(erro)
            handleMensagemErro(false)
        }

        setTodosSimcards(tempSimcards)
        setTodasLinhas(tempLinhas)

        setVerificouCMP(true);

        const {temOperadoraCmp,mensagem} = await verificaOperadorasCMP({ cd_sol_solicita_tipo, tempSimcards });

        if(mensagem) {
            setMensagemCMP(mensagem);
            if(!temOperadoraCmp) setRevelarMensagemCMP(true);
        }

        if(temOperadoraCmp) {
            setTemCMP(true);
        }
        else if(temCMP) {
            setTemCMP(false);
        }
    }

    useEffect(() => {
        getOperadorasCMP();
    }, [simcards]);

    useEffect(() => {
        if(revelarSelecaoCmp && mensagemCMP) setRevelarMensagemCMP(true);
    }, [revelarSelecaoCmp, mensagemCMP])

    function handleMensagemCMP(){
        if(revelarMensagemCMP === false){
            setRevelarMensagemCMP(true)
        }else{
            setRevelarMensagemCMP(false)
        }
    }

    const clientePermitido = getClientePermitidoCMP(cdCliente);

    const podeHabilitarSwitch = clientePermitido && temCMP;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const colunasItensInvalidos = [{
        dataField: 'CALLERID',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'ds_motivo',
        text: 'Motivo',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },]

    const colunas = [{
        dataField: 'CD_SIMCARD',
        text: 'Código',
        headerClasses: 'nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'DS_OPERADORA',
        text: 'Operadora',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'CALLERID',
        headerClasses: 'nao-selecionavel',
        text: 'Linha',
        sort: true, 
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    
    {
        dataField: 'ICCID',
        headerClasses: 'nao-selecionavel',
        text: 'ICCID',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'PLANO',
        headerClasses: 'nao-selecionavel',
        text: 'Plano',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell} MB</p>;
        }
    },
    // {
    //     dataField: 'CONSUMO_PERCENTUAL',
    //     text: 'Consumo (%)',
    //     headerClasses: 'centralizar nao-selecionavel',
    //     sort: true,
    //     formatter: (cell, row) =>{
    //         let styleProgressBar = '';
    //         if(!cell || cell == '')
    //            cell = 0;

    //         styleProgressBar = '#36D896'  
            
    //         return (
    //             <ProgressBar className='barra-progresso-container'>
    //                 <ProgressBar style={{'backgroundColor': styleProgressBar}} now={cell} />
    //                 <span className="texto-barra-progresso fonte-cor-1">{cell === undefined ? '0.00%' : cell.toFixed(2)+'%'}</span>
    //             </ProgressBar>
    //         )
    //     },
    // },
    // {
    //     dataField: 'ULTIMO_EVENTO',
    //     headerClasses: 'nao-selecionavel',
    //     text: 'Último evento',
    //     sort: true,
    //     formatter: (cell, row) => {
    //         if(cell){
    //             cell = cell.replace('T', ' ').split('.')
    //             if(cell.length > 0){
    //                cell = cell[0]
    //             }
    //           }
    //         return formatarData(cell);
    //     }
    // },
    {
        dataField: 'acoes',
        text: '',
        sort: false,
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.CD_SIMCARD);
        }
    }

    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if(itensTemp.length === 0){
            handleCancelar()
        }
    }

    //verifica se o usuario selecionou linhas extras que nao sao permitidas na acao e aciona um alerta
    function handleLinhasSelecionadasIncapacitadas(){
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if(itensInvalidosTemp.length > 0){
            setRevelarMensagemAtencaoInvalido(true)
        }
    }

    async function handleRemoverLinha(CD_SIMCARD){
        
        setRevelarMensagemAtencao(false)
        if(modoSelecaoSimcard){
            let simcardNaoSelecionados = itensInvalidosBusca;
            if(itensInvalidosBusca){
                simcardNaoSelecionados = itensInvalidosBusca;
            }
            let simcardSelecionados = simcards
            if(simcardNaoSelecionados === undefined || simcardNaoSelecionados === null || simcardNaoSelecionados === false){
                simcardNaoSelecionados = CD_SIMCARD + ","
            }
            else{
                simcardNaoSelecionados += CD_SIMCARD + ","
            }
            setItensInvalidosBusca(simcardNaoSelecionados)

            
            for (let i = contador; i < simcardSelecionados.length; i++) {
                if(simcardSelecionados[i] == CD_SIMCARD){
                    simcardSelecionados.splice(i, 1)
                }
            }

            await Promise.all(
                itensVar.map((item_itensVar,i)=>{
                    if(CD_SIMCARD == item_itensVar.CD_SIMCARD){
                        itensVar.splice(i, 1);
                    }
                })
            )
            await contarItens();
            setItens(itensVar)
            setSimcards(simcardSelecionados)
        }
        else{
            itensTemp = itens
            itens.forEach(function(e, index){
                if(CD_SIMCARD == e.CD_SIMCARD){
                    itens.splice(index, 1);
                }
            })
            setItens(itensTemp)
            handleIdSimcards()
            handleLinhasSelecionadas()
        }
                    
    }


    async function handleSalvar(){
        setSpinAtivo(true)

        let temporario = new Date();
        let dataHora = temporario.toISOString().substring(0, 10) +' '+ temporario.getHours()+':'+ temporario.getMinutes()+':'+ temporario.getSeconds();
       
        // await api.post('/m1/cadastrar/solicitacao', {
            await api_svsolicitacoes.post('/api/v2/solicitacao', {
                'fl_operadora': 0,
                'simcards' : modoSelecaoSimcard ? todosSimcards : itens.map((item) => { return item.CD_SIMCARD }), // tempSimcards,
                'FULLCALLERID': todasLinhas,
                'ds_solicitacao': 'Bloquear SMS',
                'cd_fat_solicitacao_tipo' : 11, // crm
                cd_sol_solicita_tipo,
                'cd_fat_solicitacao_status' :  1, // crm
                'ds_fat_solicitacao' :  "Bloquear Mensagens", // crm
                'dt_inicio' :  dataHora,
                'dt_fim' :  undefined,
                'dt_previsao' :  undefined,
                'cd_pav_usuario_created': decryptJWT('codigo'),
                'fl_cmp': revelarSelecaoCmp,
                ds_rota_permissao:"veye/dispositivos/acoes/bloquearSms",
            }, {timeout: 20000})
            .then(function (response) {
                setSpinAtivo(false)
                props.handleRespostaSolicitacao({response});
                // handleMensagemSucesso(false)
            })
            .catch(function (error) {
                const { atencao, erro } = props.handleRespostaSolicitacao({error});
                if(atencao) {
                    setCustomMensagemAtencao(atencao)
                    setRevelarMensagemAtencao(true)
                }
                else if(erro) {
                    setMensagemErro("Erro na solicitação")
                    handleMensagemErro(false)
                }
                setSpinAtivo(false)
            });
    }

    function limparModal() {
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
        itensVar = []
        itensInvalidosTemp = []
        simcardSelecionados = []
    }

    async function handleCancelar() {
        limparModal();
        setSpinAtivo(false)
        despacho(revelarDesativarSMSFaturas(false))
    }
    
    function inserirData(data){
        // setItens('')
        if(itensVar.length == totalItens){
            for(let i=0;i<totalItens;i++){
                if (contador === i){
                    let k = i
                    for(let j=0;j<data.length;j++){
                        itensVar[k] = data[j]
                        k++
                    }
                }
                if(i == (totalItens-1)){
                    itensTemp = JSON.parse(JSON.stringify(itensVar))
                    setItens(JSON.parse(JSON.stringify(itensVar)))
                    handleIdSimcards()
                }
            }
            handleLinhasSelecionadas()
        }
    }
    

    function handleIdSimcards(){
        let tempSimcards = []
        let tempLinhas = []
        for (let i = 0; i < itensTemp.length; i++) {
            tempSimcards.push(itensTemp[i].CD_SIMCARD)
            tempLinhas.push(itensTemp[i].FULLCALLERID)
        }
        simcardSelecionados = tempSimcards 
        setLinhas(tempLinhas)
        setSimcards(tempSimcards)
    }

    async function handlerConsultarSimcadsBusca(){
        if(totalPaginas == 0){
            contador = 0;
            itensVar = [];
            setItens([])
            await contarItens();
        }
        else{
            contador = (paginaAtual - 1) * valorLimite;
        }
        if (controladorPagina <= paginaAtual) {
            setSpinAtivo(true)

            const urlGet = `${routeM1FiltrarFatSimcard}?paguinaAtual=${contador}&carregarLimit=${valorLimite}&acao=true&buscar=${conteudoBusca}&ordenador=${props.ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false&cd_pav_usuario=${decryptJWT('codigo')}&itensInvalidosBusca=${itensInvalidosBusca}`
            const consultaSimcards =  await api.get(urlGet)
            .then(function(resposta){
                return resposta.data.dados;
            })
            .catch(function(erro){
                throw erro;
            })

            inserirData(consultaSimcards)
            setSpinAtivo(false)
        }
    }
    useEffect(()=>{
        // if(modoSelecaoSimcard && itensInvalidosBusca){
        if(modoSelecaoSimcard){ // assim porque não tem validação
            handlerConsultarSimcadsBusca()
        }
    },[itensInvalidosBusca,paginaAtual])

    useEffect(() => {
        setSpinAtivo(true)
        // async function preencherTabela (){
        //     const validacao = await identificarValidacaoAcoes(props.itensSelecionados,11,filtros,modoSelecaoSimcard,conteudoBusca,separadorLinha)
        //     if(modoSelecaoSimcard){
        //         itensTemp = validacao.itensValidos
        //         itensInvalidosTemp = validacao.itensInvalidos
        //         setItensInvalidos(validacao.itensInvalidos)
        //         if(itensInvalidosTemp.length > 0){
        //             await Promise.all(
        //                 validacao.itensInvalidos.map((item_validacao,i)=>{
        //                     simcardSelecionados += item_validacao.CD_SIMCARD + ','
        //                 })
        //             )
        //             setItensInvalidosBusca(simcardSelecionados)
        //             setSimcards(validacao.itensValidos)
        //         }
        //         else{
        //             handlerConsultarSimcadsBusca()
        //         }
        //     }
        //     else{
        //         setItens(validacao.itensValidos)
        //         setItensInvalidos(validacao.itensInvalidos)
        //         itensTemp = validacao.itensValidos
        //         itensInvalidosTemp = validacao.itensInvalidos
        //         handleIdSimcards();
        //         setItensInvalidosBusca(true)
        //     }
        //     handleLinhasSelecionadasIncapacitadas();
        //     setSpinAtivo(false)
        //  }
        //  preencherTabela()

         setItens(props.itensSelecionados)
         setSimcards(props.itensSelecionados)
         setQuantidadeLinhasSelecionados( props.itensSelecionados.length )
         
        if(props.itensSelecionados.length > 0) {
            setSpinAtivo(false)
        } // se não, é parque todo, vai chamar requisição através do useEffect e vai carregar dados

        document.addEventListener('mousedown', handleClickFora);

        return () => {
            limparModal();
            document.removeEventListener('mousedown', handleClickFora);
        }
    }, []);

    
    async function contarItens(){
        let arrayItens = []
        let apiData = [];

        if (controladorPagina <= paginaAtual ) { 
            const urlGet = `${routeM1FiltrarFatSimcard}?acao=true&buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=true&itensInvalidosBusca=${itensInvalidosBusca}`
            apiData =  await api.get(urlGet)
            .then(function(resposta){
                return resposta;
            })
            .catch(function(erro){
                throw erro;
            })
        }

        totalItens = apiData.data.dados[0].CONTADOR;
        totalPaginas = Math.ceil(totalItens / valorLimite)
        for( var i = 0 ; i < totalItens; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            setCustomMensagemAtencao('');
            if(confirmacao ===true){
                handleRemoverLinha(linhaRemover)
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    function handleFecharMensagemItensInvalidos(){
        setRevelarMensagemAtencaoInvalido(!revelarMensagemAtencaoInvalido)
        if(modoSelecaoSimcard){
            if(itens <= 0){
                handleCancelar()
            }
        }
        else{
            if(quantidadeLinhasSelecionados <= 0){
                handleCancelar()
            }
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-bloquear-sms fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                
                <FatModalHeader
                    tituloModal='Bloquear SMS'
                    clientePermitido={clientePermitido}
                    podeHabilitarSwitch={podeHabilitarSwitch}
                    revelarSelecaoCmp={revelarSelecaoCmp}
                    setRevelarSelecaoCmp={setRevelarSelecaoCmp}
                    handleCancelar={handleCancelar}
                />
                <Form.Row>
                    <Col>
                        <div className="container-tabela-modal">
                            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <>
                                            <BootstrapTable
                                                classes="tabela-transparente-modais"
                                                condensed={true}
                                                keyField='id' 
                                                data={itens} 
                                                columns={colunas} 
                                                rowEvents={eventosLinhas}
                                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                bootstrap4={true}
                                                { ...paginationTableProps }
                                            />
                                            {modoSelecaoSimcard &&
                                                <div className="paguinacao-tabela-modal">
                                                    <PaginacaoTabela
                                                        extrato={true}
                                                        setPaginaAtual={setPaginaAtual}
                                                        totalPaginas={totalPaginas}
                                                        totalItens={totalItens}
                                                        valorLimite={valorLimite}
                                                        paginaAtual={paginaAtual}
                                                        page={paginationProps.page}
                                                        onPageChange={paginationProps.onPageChange}
                                                    />
                                                </div>
                                            }
                                        </>
                                    )
                                }
                            </PaginationProvider>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {/* <div className="label-atencao-pacote-extra">
                            <span><span className="fonte-cor-3 label-normal negrito">Atenção:</span><span className="fonte-cor-1 label-normal"> este tipo de solicitação só é atendida dentro do horário comercial (8h - 17:30h).</span></span>
                        </div> */}
                        <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} linhas selecionadas</span>
                    </Col>
                </Form.Row>
                <Form.Row className="espaco-bloquear-sms">
                    <Col>
                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>

            { revelarMensagemCMP &&
                <RenderMensagemAtencao
                    handleMensagemAtencao={handleMensagemCMP}
                    mensagemTitulo={'Atenção'}
                    mensagemParagrafo1={mensagemCMP}
                    mensagemBotao={"FECHAR"}
                />
            }

            { revelarMensagemAtencao && customMensagemAtencao && 
                <RenderMensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={customMensagemAtencao}
                    mensagemBotao={"Retornar"}
                />
            }

            { revelarMensagemAtencao && !customMensagemAtencao && 
                <RenderMensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Remover linha?"}
                    mensagemBotao={"Remover"}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagem={handleMensagemErro}
                    mensagemTitulo={"Erro"}
                    mensagemParagrafo1={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
            { revelarMensagemAtencaoInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                            </div>
                            <div className="fonte-cor-1 label-janela">{itensInvalidos.length} linhas foram retiradas</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensInvalidos}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensInvalidos()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
    )
}