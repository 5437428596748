import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { Card, OverlayTrigger, Tooltip, Button, Form, ButtonToolbar, Col } from 'react-bootstrap';/*eslint-disable*/

//ICONES
import {elipseVerde, fecharJanela, exclamacao} from '../../../../utils/alternanciaIcones'

// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'


export default function MaisInformacoes(props) {
    
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [ip, setIp] = useState()
    const [body, setBody] = useState()
    const [query, setQuery] = useState()
    const [token, setToken] = useState()
    const [content, setContent] = useState()
    const [user, setUser] = useState()
   
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };
    useEffect(() => {
        setIp(props.maisInfo.ip)
        setBody(props.maisInfo.body)
        if(props.maisInfo.headers.split('"Bearer ')[1]){
            let auxToken = props.maisInfo.headers.split('"Bearer ')[1]
                auxToken = auxToken.split('","')[0]
            setToken(auxToken)
        }
        if(props.maisInfo.headers.split('content-type":"')[1] != undefined){
            let auxContent = props.maisInfo.headers.split('content-type":"')[1]
                auxContent = auxContent.split('","')[0]
            setContent(auxContent)
        }
        if(props.maisInfo.headers.split('user-agent":"')[1] != undefined){
            let auxUser = props.maisInfo.headers.split('user-agent":"')[1]
                auxUser = props.maisInfo.headers.split('","')[1]
            setUser(auxUser)
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])
    
    
    function handleCancelar(){
        props.setRenderizar(!props.renderizar)
        props.setRevelarMaisInformacoes(false)
    }
    let dado = props.maisInfo.headers
    let dado2 = props.maisInfo.headers[0].host
    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="modal-mais-informacoes fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Mais informações</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">IP</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}></Tooltip>}>
                                <Form.Control value={ip} id="texto" className="campo-texto-cor-3 campo-texto" type="text"/>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Body</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}></Tooltip>}>
                                <Form.Control value={body} id="texto" className="campo-texto-cor-3 campo-texto" type="text"/>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Query</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}></Tooltip>}>
                                <Form.Control value={query} id="texto" className="campo-texto-cor-3 campo-texto" type="text"/>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Token</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}></Tooltip>}>
                                <Form.Control value={token}id="texto" className="campo-texto-cor-3 campo-texto-area" as="textarea" rows="3"/>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Content type</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}></Tooltip>}>
                                <Form.Control value={content} id="texto" className="campo-texto-cor-3 campo-texto" type="text"/>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">User agent</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}></Tooltip>}>
                                <Form.Control value={user} id="texto" className="campo-texto-cor-3 campo-texto" type="text"/>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
        </Form>
    )
}