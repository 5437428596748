import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { Card, OverlayTrigger, Tooltip, Button, Form, ButtonToolbar, Col } from 'react-bootstrap';/*eslint-disable*/
//ICONES
import {elipseVerde, fecharJanela, exclamacao} from '../../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'
//ESTLIZACAO
import '../../../../css/painelAdministrativo/logs/acessoLog/acessoLog.css'

export default function MaisInformacoes(props) {
    const modal = useRef();
    const [clickFora, setClickFora] = useState(false);
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);
    
    useEffect(() => {
        setSpinAtivo(true)
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    
    function handleCancelar(){
        props.setRenderizar(!props.renderizar)
        props.setRevelarMaisInformacoes(false)
    }

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="modal-mais-informacoes fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Mais informações</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">IP</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>IP utilizado para acessar.</Tooltip>}>
                                <Form.Control className="campo-texto-cor-3 campo-texto" type="text" value={props.maisInfo.ip}/>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Token</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Rota</Tooltip>}>
                                <Form.Control className="campo-texto-cor-3 campo-texto-area" as="textarea" rows="3" value={props.maisInfo.token}/>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
        </Form>
    )
}