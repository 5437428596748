//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col } from 'react-bootstrap';
import moment from 'moment';
import decryptJWT from '../../../../utils/decryptJWT'
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, fecharJanela} from '../../../../utils/alternanciaIcones'
//MODAIS
import Calendario from '../../../calendario/calendario'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarContestarFatura } from "../../../../redux/actions/index";

export default function ContestarFatura(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const [dataMin, setDataMin] = useState()
    const [dataMax, setDataMax] = useState()
    const { handleSubmit } = useForm();
    //HOOKS PRINCIPAIS
    const [dataAbertura, setDataAbertura] = useState();
    const [dataConclusao, setDataConclusao] = useState();
    const [dsDescricao, setDsDescricao] = useState();
    const [dsProtocolo, setDsProtocolo] = useState();
    //HOOKS MODAIS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const modal = useRef();
    const despacho = useDispatch();

    const cd_pav_usuario = decryptJWT('codigo')


    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    //FAZ O MANEJO 
    async function handleSalvar(){
        setSpinAtivo(true)
        await api.post('/m1/cadastrar/fat_solicitacao', {
            "cd_pav_cliente":decryptJWT('codigo'), //CORRIGIR: TROCAR PARA PEGAR O CD DO CLIENTE DO TOKEN
            "modoSelecao":"Contestação",
            "ds_solicitacao":"Contestação",
            "cd_fat_solicitacao_tipo":18,
            "cd_fat_solicitacao_status":1,
            "dt_inicio":moment(dataAbertura).format('YYYY-MM-DD 00:00:00'),  
            "dt_prazo_conclusao":moment(dataConclusao).format('YYYY-MM-DD 00:00:00'),
            "ds_motivo":dsDescricao,
            "cd_pav_usuario_created": decryptJWT('codigo'),
            "cd_fat_fatura": props.cdFatura,
            "cd_dsp_operadora":props.cdOperadora,
            "ds_protocolo_contestacao": dsProtocolo,
            "cd_sol_solicitacao_tipo":1000
        })
        .then(function (response) {
            api.post('/m1/cadastrar/fat_faturas_historico', {
                'cd_fat_fatura': props.cdFatura,
                'cd_fat_fatura_historico_tipo':7,
                'n2_valor_cobrado':props.n2ValorTotal,
                'cd_pav_usuario_created_at':cd_pav_usuario
            })
            handleMensagemSucesso(true)
            despacho(revelarContestarFatura(false))
            setSpinAtivo(false)
        })
        .catch(function (error) {
            
            setMensagemErro(error.response.data.info)
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    function handleCancelar(){
        despacho(revelarContestarFatura(false))
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
        props.setRevelarModalContestacao(false)
        props.setRenderizar(!props.renderizar)
    }
    

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
            }
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <div className="modal-editar-fatura fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Contestar fatura</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-editar-fatura-1">
                            <div className="coluna-cadastro-editar-fatura-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Data de abertura</div>
                                    </Form.Label>
                                    <Form.Control type="text" value={dataAbertura ? moment(dataAbertura).format("YYYY-MM-DD") : dataAbertura} className="d-none"/>
                                    <Calendario formato="DD/MM/YYYY" data={dataAbertura} setData={setDataAbertura} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-editar-fatura-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Previsão de conclusão</div>
                                    </Form.Label>
                                    <Form.Control type="text" value={dataConclusao ? moment(dataConclusao).format("YYYY-MM-DD") : dataConclusao} className="d-none"/>
                                    <Calendario formato="DD/MM/YYYY" data={dataConclusao} setData={setDataConclusao} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-editar-fatura-1">
                            <div className="coluna-cadastro-editar-fatura-3">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Protocolo de contestação</div>
                                    </Form.Label>
                                    <Form.Control className="campo-texto-cor-3 campo-texto" placeholder="Insira o protocolo da contestação" onChange={e => setDsProtocolo(e.target.value)}></Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-editar-fatura-1">
                            <div className="coluna-cadastro-editar-fatura-3">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Motivo</div>
                                    </Form.Label>
                                    <Form.Control name="descricao" className="campo-texto-cor-3 campo-texto-area" as="textarea" rows="3" maxLength="255" placeholder="Descreva aqui o motivo" onChange={e => setDsDescricao(e.target.value)}></Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {(dataConclusao !== '' && dataConclusao !== undefined && dataConclusao !== null ) && (dataAbertura !== '' && dataAbertura !== undefined && dataAbertura !== null ) ?
                            <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                                <div className="fonte-cor-4">Confirmar</div>
                            </Button>
                        :
                            <Button className="botao-confirmar-desabilitado" size="sm">
                                <div className="fonte-cor-4">Confirmar</div>
                            </Button>
                        }
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Solicitação de contestação para a fatura "+props.cdFatura+" enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}