//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import { Image, Form } from 'react-bootstrap';/*eslint-disable*/
import { validadorPermissaoElemento } from '../../../../utils/verificadorPermissaoElemento'
import { useSelector } from 'react-redux';
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye'
//ICONES
import { iconeDescricaoAtivado, iconeConexoesAtivado, iconeConsumoAtivado, iconeConexoes, iconeConsumo, iconeDescricao } from '../../../../utils/alternanciaIcones'
//ESTILIZACAO
import '../../../../css/painelFaturas/fatDispositivos/detalhamento/detalhamentoLinha.css'
//COMPONENTES
import Descricao from './descricao/descricao'
import Historico from './historico/historico'
import Servicos from './servicos/servicos'

import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function Detalhamento(props) {
    const [idMenu, setIdMenu] = useState('-1')
    const [revelarTela, setRevelarTela] = useState()
    const [filtroRadio, setFiltroRadio] = useState()
    const [renderizar, setRenderizar] = useState(false);
    const cdSimcard = props.cdSimcard;

    const [permissaoDescricao, setPermissaoDescricao] = useState()
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    let ativarBotaoConfirmar = false;
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);
    const [objLatLong, setObjLatLong] = useState([]) 

    useEffect(()=>{
        async function handlerVerificarPermissaoElementos(){
            setPermissaoDescricao(await validadorPermissaoElemento(permissaoElemento,"veye/dispositivos/detalhamento/descricao"))
        }
        handlerVerificarPermissaoElementos()
    },[])

    function handleRenderTela(tela){
        if(tela === 'Descrição'){
            setRevelarTela('Descrição')
        }else if(tela === 'Histórico'){
            setRevelarTela('Histórico')
        }else if(tela === 'Serviços'){
            setRevelarTela('Serviços')
        }else{
            setRevelarTela('NULL')
        }
    }

    function escondeDropdown(el){
        el.classList.remove("detalhamento-linha-expandir");
        el.classList.add("detalhamento-linha-contrair");
    }

    function handleDropTelas(elementoId) {
        if(idMenu === elementoId+props.row[0].CALLERID){
            setIdMenu(elementoId+props.row[0].CALLERID)
        }else{
            setIdMenu(elementoId+props.row[0].CALLERID)
        }
        setSpinAtivo(true)
        let elementosAtivos = document.querySelectorAll(".detalhamento-linha-expandir");
        let elemento = document.getElementById(elementoId+props.row[0].CALLERID);
        elemento.classList.toggle("detalhamento-linha-expandir")
        if(elementosAtivos.length > 0){
            elementosAtivos.forEach(escondeDropdown)
        }
        setSpinAtivo(false)
    }

    return(
        <Form>
            <div className="container-detalhamento-linha fundo-cor-1">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <div className="container-navegacao-detalhamento-cliente">
                        <div className="triangulo-navegacao-detalhamento-cliente campo-select-triangulo-cor-3"></div>
                        <div className="corpo-navegacao-detalhamento-cliente fundo-cor-5">
                            <ul className="lista-navegacao-detalhamento-cliente">
                                <li key={"1"} onClick={() => handleRenderTela('Descrição')}>
                                    { idMenu === '1'+props.row[0].CALLERID ?
                                        <div className="container-item-navegacao-detalhamento-cliente-ativado detalhamento-hover fundo-cor-7" onClick={e => handleDropTelas("1")}>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={iconeDescricaoAtivado()}/>
                                            </div>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('Descrição')}>Descrição</span>
                                            </div>
                                        </div>
                                    :
                                        <div className="container-item-navegacao-detalhamento-cliente-desativado detalhamento-hover " onClick={e => handleDropTelas("1")}>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={iconeDescricao()}/>
                                            </div>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('Descrição')}>Descrição</span>
                                            </div>
                                        </div>
                                    }
                                    <ul id={"1"+props.row[0].CALLERID} className="lista-navegacao-detalhamento-cliente detalhamento-cliente-contrair">
                                    </ul>
                                </li>
                                <li key={"2"} onClick={() => handleRenderTela('Serviços')}>
                                    { idMenu === '2'+props.row[0].CALLERID ?
                                        <div className="container-item-navegacao-detalhamento-cliente-ativado detalhamento-hover fundo-cor-7" onClick={e => handleDropTelas("2")}>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={iconeConsumoAtivado()}/>
                                            </div>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('Serviços')}>Serviços</span>
                                            </div>
                                        </div>
                                    :
                                        <div className="container-item-navegacao-detalhamento-cliente-desativado detalhamento-hover " onClick={e => handleDropTelas("2")}>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={iconeConsumo()}/>
                                            </div>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('Serviços')}>Serviços</span>
                                            </div>
                                        </div>
                                    }
                                    <ul id={"2"+props.row[0].CALLERID} className="lista-navegacao-detalhamento-cliente detalhamento-cliente-contrair">
                                    </ul>
                                </li>
                                <li key={"3"} onClick={() => handleRenderTela('Histórico')}>
                                    { idMenu === '3'+props.row[0].CALLERID ?
                                        <div className="container-item-navegacao-detalhamento-cliente-ativado detalhamento-hover fundo-cor-7" onClick={e => handleDropTelas("3")}>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={iconeConexoesAtivado()}/>
                                            </div>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('Histórico')}>Hist. ações</span>
                                            </div>
                                        </div>
                                    :
                                        <div className="container-item-navegacao-detalhamento-cliente-desativado detalhamento-hover " onClick={e => handleDropTelas("3")}>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={iconeConexoes()}/>
                                            </div>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('Histórico')}>Hist. ações</span>
                                            </div>
                                        </div>
                                    }
                                    <ul id={"3"+props.row[0].CALLERID} className="lista-navegacao-detalhamento-cliente detalhamento-cliente-contrair">
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* DESCRICAO */}
                    { revelarTela === 'Descrição' &&
                        <div className="container-tela-sem-filtro-detalhamento-linha">
                            <div className="container-tela-conteudo-detalhamento-linha">
                                <div className="container-tela-corpo-detalhamento">
                                    <Descricao renderConsultaColunas={props.renderConsultaColunas} 
                                        setRenderConsultaColunas={props.setRenderConsultaColunas} 
                                        setRenderizar={props.setRenderizar} 
                                        renderizar={props.renderizar} 
                                        cdSimcard={cdSimcard} 
                                        row={props.row}
                                    ></Descricao>
                                </div>
                            </div>
                        </div>
                    }
                    { revelarTela === 'Serviços' &&
                        <div className="container-tela-sem-filtro-detalhamento-linha">
                            <div className="container-tela-conteudo-detalhamento-linha">
                                <div className="container-tela-corpo-detalhamento">
                                    <Servicos
                                        spinAtivo={spinAtivo}
                                        cdSimcard={props.cdSimcard}
                                        cdPlano={props.cdPlano}
                                        setRenderizar={setRenderizar}
                                        renderizar={renderizar}
                                    ></Servicos> 
                                </div>
                            </div>
                        </div>
                    }
                    { revelarTela === 'Histórico' &&
                        <div className="container-tela-sem-filtro-detalhamento-linha">
                            <div className="container-tela-conteudo-detalhamento-linha">
                                <div className="container-tela-corpo-detalhamento">
                                    <Historico
                                        cdSimcard={props.cdSimcard}
                                        setRenderizar={setRenderizar}
                                        renderizar={renderizar}
                                    ></Historico> 
                                </div>
                            </div>
                        </div>
                    }
                </Form.Row>
            </div>
        </Form>
    )
}