/*eslint-disable*/
import React from 'react';
export async function validadorPermissaoElemento(permissao,elemento){

    let verificador = false
    await Promise.all(
        permissao.map((item_elemento,i)=>{
            if(item_elemento.rota == elemento){
                verificador = true
            }
        })
    )
    
    return verificador
}