import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
import Calendario from '../calendario/calendario'
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'

export default function FiltrosMensagens(props) {
    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroDataInicial, setFiltroDataInicial] = useState('')
    const [filtroDataFinal, setFiltroDataFinal] = useState('')
    const [filtroNivel, setFiltroNivel] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []
    
    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            if(filtros.DATA_INICIO !='' && filtros.DATA_INICIO){
                let auxData = new Date(filtros.DATA_INICIO)
                auxData.setDate(auxData.getDate()+1)
                setFiltroDataInicial(auxData)
            }
            if(filtros.DATA_FIM != '' && filtros.DATA_FIM){
                let auxData = new Date(filtros.DATA_FIM)
                auxData.setDate(auxData.getDate()+1)
                setFiltroDataFinal(auxData)
            }
            if(filtros.NIVEL == '1') setFiltroNivel("Urgente")
            if(filtros.NIVEL == '2') setFiltroNivel("Crítico")
            if(filtros.NIVEL == '3') setFiltroNivel("Atenção")
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    function handleFuncaoEstadosRedux(){
        if(filtroDataInicial !== '' && filtroDataFinal !== '' && filtroDataInicial && filtroDataFinal && moment(filtroDataInicial)._isValid && moment(filtroDataFinal)._isValid){
            arrayFiltrosSimcard.push("Data: "+moment(filtroDataInicial).format("DD-MM-YYYY")+" até "+moment(filtroDataFinal).format("DD-MM-YYYY")+"?DATA_INICIO")
        }
        if(filtroNivel !== '' && filtroNivel)arrayFiltrosSimcard.push("Nível: "+filtroNivel+"?NIVEL")

        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroDataInicial == '') verificador.push(1)
            if(filtroDataFinal =='') verificador.push(1)
            if(filtroNivel == '') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 3) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroDataInicial('')
            setFiltroDataFinal('')
            setFiltroNivel('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroDataInicial,filtroDataFinal,filtroNivel,props.foiLimpado]);

    // TODO: Ver se é viável jogar essa função no 'utils/filtros.js' e refatorar para todos os lugares que repete.
    //verifica se os radios butons estao ativos
     function handlerCheck(element, nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-data","imagem-filtro-data")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-data"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_INICIO" type="text" value={filtroDataInicial ? moment(filtroDataInicial).format("YYYY-MM-DD hh:mm:ss") : filtroDataInicial} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataInicial} setData={setFiltroDataInicial} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_FIM" type="text" value={filtroDataFinal ? moment(filtroDataFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataFinal} setData={setFiltroDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-nivel","imagem-filtro-nivel")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Nível </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-nivel"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-nivel"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros" onChange={() => setFiltroNivel("Atenção")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Atenção<div className="filtro-mensagem-importancia-esfera-verde"/></div>
                            <input defaultChecked={handlerCheck("NIVEL","3")} type="radio" id="NIVEL_NORMAL" name="NIVEL" value="3"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroNivel("Crítico")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Crítico<div className="filtro-mensagem-importancia-esfera-amarela"/></div>
                            <input defaultChecked={handlerCheck("NIVEL","2")} type="radio" id="NIVEL_ATENCAO" name="NIVEL" value="2"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroNivel("Urgente")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Urgente<div className="filtro-mensagem-importancia-esfera-vermelha"/></div>
                            <input defaultChecked={handlerCheck("NIVEL","1")} type="radio" id="NIVEL_URGENTE" name="NIVEL" value="1"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}