//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Calendario from '../../../calendario/calendario'
import moment from 'moment';

//ICONES
import {elipseVerde, fecharJanela, exclamacao, setaDropdown} from '../../../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
//MODAIS
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import decryptJWT from '../../../../utils/decryptJWT'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
//SELECT
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//CSS
import '../../../../css/painelFaturas/fatContestacao/tabela.css'
import { revelarEditarSolicitacao } from '../../../../redux/actions/index';

let arrayStatus = [
    {value: 'Aberta', label: 'Aberta', codigo: 1},
    {value: 'Concluída', label: 'Concluída', codigo: 2},
    {value: 'Cancelada', label: 'Cancelada', codigo: 3},
    {value: 'Em Andamento', label: 'Em Andamento', codigo: 4} 
]

export default function EditarSolicitacao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [valorLimite, setValorLimite] = useState(50);// eslint-disable-next-line

    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");

    
    const [cdSolicitacao, setCdSolicitacao]= useState()
    const [tituloJanela, setTituloJanela] = useState('Nova');
    const [dataMin, setDataMin] = useState(1990)// eslint-disable-next-line
    const [dataMax, setDataMax] = useState()// eslint-disable-next-line
    const [previsaoConclusao, setPrevisaoConclusao] = useState("")
    const [dataConclusao, setDataConclusao] = useState("")
    const [protocoloContestacao, setProtocoloContestacao] = useState()
    const [motivoAlteracao, setMotivoAlteracao] = useState()
    const [cdStatus, setCdStatus] = useState();
    const [dsStatus, setDsStatus] = useState('Selecione');
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //HOOKS SELECTS
    const [dummy, setDummy] = useState('')
    const [revelarSelectCicloDeVida, setRevelarSelectCicloDeVida] = useState(false)
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };
    
    const handleClickDentro = () => setClickFora(false);

    useEffect(() => {
        setSpinAtivo(true)
        if(props.tituloJanela === 'Editar'){
            setTituloJanela('Editar')
            setCdSolicitacao(props.cdSolicitacao)
            setProtocoloContestacao(props.protocoloContestacao)
            setPrevisaoConclusao(new Date(props.dataPrazoConclusao))
            if(props.dataFim !== '' && props.dataFim !== null && props.dataFim !== undefined){
                setDataConclusao(new Date(props.dataFim))
            }
            setMotivoAlteracao(props.motivo)
            setCdStatus(props.cdStatus)
            setDsStatus(props.dsStatus)
        }
       
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    useEffect(()=>{
        async function carregarTabela(){
            if(cdSolicitacao!=undefined){
                const {data} = await api.get("/m1/consultar/fat_solicitacao_alteracao?cd_fat_solicitacao="+cdSolicitacao)
                setItens(data.dados)
            }
        }
        carregarTabela()
    },[cdSolicitacao])

    const colunas = [
        {
            dataField: 'ds_solicitacao_status',
            headerClasses: 'nao-selecionavel',
            text: 'Status',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_descricao',
            headerClasses: 'nao-selecionavel',
            text: 'Motivo de edição',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_protocolo',
            headerClasses: 'nao-selecionavel',
            text: "Protocolo",
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'dt_prazo_conclusao',
            headerClasses: 'nao-selecionavel',
            text: "Previsão de conclusão",
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'dt_alteracao',
            headerClasses: 'nao-selecionavel',
            text: "Data alteração",
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_login',
            headerClasses: 'nao-selecionavel',
            text: "Usuário",
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
    ]
    
    async function handleSalvar(){
        setSpinAtivo(true)
       
        if(tituloJanela === "Editar"){
            await api.post('/m1/alterar/fat_solicitacao', {
                "dados":{
                    "cd_fat_solicitacao_status": cdStatus,
                    "ds_protocolo_contestacao":protocoloContestacao,
                    "dt_fim":moment(dataConclusao).format("YYYY-MM-DD hh:mm:ss"),
                    "dt_prazo_conclusao":moment(previsaoConclusao).format("YYYY-MM-DD hh:mm:ss"),
                    "ds_motivo": motivoAlteracao,
                    "cd_pav_usuario_created": decryptJWT("codigo")
                },
                "condicao":{
                    'cd_fat_solicitacao': cdSolicitacao,
                }
            },{timeout: 20000})
            .then(function (response) {
                setSpinAtivo(false)
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            });
        }
        setSpinAtivo(false)
    }

    async function handleCancelar() {
        setSpinAtivo(false)
        props.setRenderizar(!props.renderizar)
        despacho(revelarEditarSolicitacao(false))
    }
    
    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao ===true){
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }


    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-desativar-demonstracao fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Editar solicitação</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                {/* CORPO */}
                <Form.Row className="usuario-dados-corpo-linha">
                    <div className="corpo-dados-solicitacao">
                        <Form.Label>
                            <div className="fonte-cor-1 label-campo">Status</div>
                        </Form.Label>
                        {revelarSelectCicloDeVida === false &&
                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCicloDeVida(!revelarSelectCicloDeVida)}>
                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsStatus}</div>
                                <img className={revelarSelectCicloDeVida?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                            </Button>
                        }

                        {revelarSelectCicloDeVida === true &&
                            <SelectGenerico
                                paramRota={"fat_solicitacao_status"}
                                paramCampo1={null}
                                paramCampo2={null}
                                paramCampo3={null}
                                paramCampo4={null}
                                setCodigo={setCdStatus}
                                setDescricao1={setDsStatus}
                                setDescricao2={setDummy}
                                setDescricao3={setDummy}
                                setDescricao4={setDummy}
                                setRevelarSelect={setRevelarSelectCicloDeVida}
                                placeholder={dsStatus}
                                sufixo={""}
                            >
                            </SelectGenerico>
                        }
                    </div>
                    <Col>
                        <div>
                            <Form.Label> <div className="fonte-cor-1 label-campo">Protocolo de contestação</div> </Form.Label>
                            <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="top" overlay={<Tooltip className={'tooltip-campos-solicitacao'} id={`tooltip-top`}>Digite o Protocolo de contestação</Tooltip>}>
                                <Form.Control
                                    className="campo-texto-cor-3 campo-texto"
                                    type="text"
                                    maxLength="30"
                                    value={protocoloContestacao}
                                    name="ds_protocolo_contestacao"
                                    onChange={e => setProtocoloContestacao(e.target.value)}
                                    placeholder="Digite o protocolo da contestacao"
                                />
                            </OverlayTrigger>
                        </div>
                    </Col>
                </Form.Row>

                <Form.Row className="usuario-dados-corpo-linha">
                    <div className="corpo-dados-solicitacao">
                        <Form.Label> <div className="fonte-cor-1 label-campo">Previsão de conclusão</div> </Form.Label>
                        <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="top" overlay={<Tooltip className={'tooltip-campos-solicitacao'} id={`tooltip-top`}>Selecione a previsão de conclusão</Tooltip>}>
                            <div className="calendario-z-index">
                                <Calendario data={previsaoConclusao} setData={setPrevisaoConclusao} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                            </div>
                        </OverlayTrigger>
                        {/* {errors.ds_email_empresa  && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>} */}
                    </div>
                    <Col>
                        <div>
                            <Form.Label> <div className="fonte-cor-1 label-campo">Data de conclusão </div> </Form.Label>
                            <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="top" overlay={<Tooltip className={'tooltip-campos-solicitacao'} id={`tooltip-top`}>Selecione a data de alteração</Tooltip>}>
                                <Calendario data={dataConclusao} setData={setDataConclusao} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                            </OverlayTrigger>
                            {/* {errors.ds_telefone_empresa && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>} */}
                        </div>
                    </Col>
                </Form.Row>

                <Form.Row className="usuario-dados-corpo-linha">
                    <div className="corpo-textarea-solicitacao">
                        <Form.Label> <div className="fonte-cor-1 label-campo">Motivo da alteração</div> </Form.Label>
                        <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="top" overlay={<Tooltip className={'tooltip-campos-solicitacao'} id={`tooltip-top`}>Digite o motivo da alteração</Tooltip>}>
                            <Form.Control
                                className="campo-texto-cor-3 campo-texto-area"
                                as="textarea"
                                rows="3" 
                                maxlength="500"
                                value={motivoAlteracao}
                                name="ds_telefone_empresa"
                                onChange={e => setMotivoAlteracao(e.target.value)}
                                placeholder="Motivo da alteração"
                                ref={register({ required: true, min: 3})}
                            />
                        </OverlayTrigger>
                    </div>
                </Form.Row>
                <Form.Row className="espaco-reativar-linha">
                    <Col>
                        <>
                            {(cdStatus !== "" && previsaoConclusao != "" && dataConclusao != "") ?
                                <Button  type="submit" className={"botao-confirmar"} variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            :
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            }
                            <Button className={"botao-cancelar"} variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2" >Cancelar</div>
                            </Button>
                        </>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover Linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}