import { api } from "../../conexoes/api";
import decryptJWT from "../../utils/decryptJWT";

export async function getListaParceiros(parceiro) {
  try {
    let parceiroObj = await api.get(
      "/m1/servicos/pre_login_cliente_parceiro?rota=" + parceiro
    );
    return parceiroObj.data;
  } catch (e) {
    console.error("erro ao entrar na m1/servicos/prelogin", e);
    return [];
  }
}

export async function tokenRecuperacaoOk(token, login) {
  const confirmacao = await api
    .post("/m1/servicos/dados/validar_usuario_recuperacao ", { token, login })
    .then(function () {
      return true;
    })
    .catch(function () {
      return false;
    });
  return confirmacao;
}

export async function getInformacoesAdicionaisCustomizadasClientes() {
  return await api.get(
    "/m1/consultar/informacoes_adicionais_customizadas_cliente?cd_pav_cliente=" +
      decryptJWT("codigoCliente")
  );
}

export async function postUsuarioRecuperacao({
  tokenUrl,
  novaSenha,
  senhaAtual,
  emailUrl,
  primeiroAcesso,
}) {
  const condicao = {
    ds_email: emailUrl,
  };

  if (primeiroAcesso) {
    condicao.fl_padrao_senha = 1;
  }

  const dados = {
    token: tokenUrl,
    ds_senha: novaSenha,
  };

  if (senhaAtual) {
    dados.ds_senha_atual = senhaAtual;
  }

  return await api.post("/m1/servicos/dados/usuario_recuperacao", {
    dados: dados,
    condicao: condicao,
  });
}

export async function postServicosDadosLoginAlgar(user) {
  return await api.post("/m1/servicos/dados/login", {
    ds_email: user,
    ds_senha: "",
    algar: true,
  });
}

export async function postServicosDadosLogin({ email, password, token }) {
  return await api.post("/m1/servicos/dados/login", {
    ds_email: email,
    ds_senha: password,
    ds_token: token,
  });
}

export async function alterarColunasSimcard({
  email,
  colunasAux,
  colunasFaturasAux,
}) {
  return await api.post("m1/servicos/dados/alterar_colunas_simcard", {
    email: email,
    colunas: colunasAux,
    colunasFaturas: colunasFaturasAux,
  });
}

export async function getEmailRecuperacao(emailRecuperarSenha) {
  try {
    await api.get(
      "m1/servicos/token/email_recuperacao?ds_email=" + emailRecuperarSenha,
      {},
      { timeout: 20000 }
    );

    return true;
  } catch {
    return false;
  }
}

export async function cadastrarColunasSimcard({
  email,
  colunasSimcard,
  colunasFaturas,
}) {
  return await api.post("/m1/servicos/dados/cadastrar_colunas_simcard", {
    email: email,
    colunas: colunasSimcard,
    colunasFaturas: colunasFaturas,
  });
}

export async function getColunasSimcard(email) {
  return await api.post("m1/servicos/dados/buscar_colunas_simcard", {
    email,
  });
}

export async function getColunasFaturas(email) {
  return await api.post("m1/servicos/dados/buscar_colunas_faturas", {
    email: email,
  });
}
