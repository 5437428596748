//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';/*eslint-disable*/
import decryptJWT from '../../../utils/decryptJWT'
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectValidadePlano from '../../componentesModais/selects/selectValidadePlano'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectValidadePlano, setRevelarSelectValidadePlano] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">ValidadePlano {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectValidadePlano === false &&
                <Button name="cdValidadePlano" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectValidadePlano(!revelarSelectValidadePlano)}>
                    <div className="fonte-cor-1 label-campo">{dsValidadePlano}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectValidadePlano === true &&
                <SelectValidadePlano
                    setRevelarSelectValidadePlano={setRevelarSelectValidadePlano} //Hook para fechar a modal
                    setCdValidadePlano={setCdValidadePlano} //Código do ValidadePlano
                    setDsValidadePlano={setDsValidadePlano} //Nome do ValidadePlano
                    dsValidadePlano={dsValidadePlano} 
                    phValidadePlano={phValidadePlano} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectValidadePlano>
            }
        </Form.Group>*/

export default function SelectDiasBloqueioTemporario(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [listaDiasBloqueio, setListaDiasBloqueio] = useState([])

    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleDiasBloqueio(diasBloqueio){
        props.setDsDiasBloqueio(diasBloqueio)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectDiasBloqueioTemporario(false)
    }

    function renderDiasBloqueio(){
        if(listaDiasBloqueio.length){
            let dias = listaDiasBloqueio.map(function(item, i){
                return (
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleDiasBloqueio(item.n0_dias)}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.n0_dias} dias</span>
                        </div>
                        {listaDiasBloqueio[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                </li>
                )
            })
            
            return dias;
        }else{
            let dias = listaDiasBloqueio.map(function(item, i){
                return (
                    <li>
                        <div disabled className="campo-select-opcao campo-select-opcao-1">
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                        </div>
                    </li>
                );
            })
            
            return dias;
        }

    }

    useEffect(() => {

         async function carregarDias(){
            setSpinAtivo(true)

            const resultado = await api.get("/m1/consultar/bloqueio_temporario_dias_cliente?n0_codigo_cliente="+decryptJWT("codigoClienteErpAntigo"))
            .then(function(resposta){
                return resposta.data;
            })
            .catch(function(erro){
                throw erro.stack;
            })

            if(resultado.status == 200 && resultado.dados.length > 0){
               setListaDiasBloqueio(resultado.dados)
            }
            else
               setListaDiasBloqueio([{n0_dias:120}])
            
            setSpinAtivo(false)
         }
         carregarDias()
        
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectDiasBloqueioTemporario(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsDiasBloqueio}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {renderDiasBloqueio()}
                </ul>
            </div>
        </Form>
    )
}