import React from 'react'
import decryptJWT from '../../utils/decryptJWT'

export const TituloBotaoColunas = ({titulo}) => <div className='fonte-cor-1 nao-selecionavel label-titulo-botao-colunas'>{titulo}</div>

export const TrianguloPersonalizar = () => <div className="triangulo-personalizar"></div>

export function ListaColunasCategoria({ listaCategoria, categoriaKey, handlerChangeSwitich, isFaturas=false }) {
    return (
        <div className="container-switches-exibir-colunas">
            {listaCategoria?.map((item, idx) => 
                <>
                    {(isFaturas || item.nivel_acesso >= decryptJWT('clienteNivel')) &&  
                        <ItemColunasCategoria
                            item={listaCategoria[idx]}
                            categoriaKey={categoriaKey}
                            handlerChangeSwitich={handlerChangeSwitich}
                        />
                    }
                </>
            )}
        </div>
    )
}

export function ListaColunasCategoriaFatura(props) {
    return <ListaColunasCategoria {...props} isFaturas />
}

export function ItemColunasCategoria({ item, categoriaKey, handlerChangeSwitich }) {
    const { id, nome, valor } = item;

    return (
        <div className="grupo-switches-exibir-colunas" key={`${categoriaKey}-${id}`}>
            <div className="coluna-switches-exibir-colunas-1">
                <div class="flipswitch-medium">
                    <input type="checkbox" checked={valor} class="flipswitch-medium-cb" id={id} onChange={(e) => handlerChangeSwitich(e.target.id,categoriaKey,e.target.checked)}/>
                    <label class="flipswitch-medium-label" for={id}>
                        <div class="flipswitch-medium-inner"></div>
                        <div class="flipswitch-medium-switch"></div>
                    </label>
                </div>
            </div>
            <div className="coluna-switches-exibir-colunas-2">
                <div className='label-switch-exibir-coluna fonte-cor-1'>{nome}</div>
            </div>
        </div>
    )
}