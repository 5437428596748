import React from "react";
import BotaoVerde from "../BotaoVerde/BotaoVerde";
import { PERCENTUAL, QUANTIDADE } from "./utils";

const BotoesGrafico = ({
  botaoSelecionado,
  setBotaoSelecionado,
  isUltimos6Ciclos,
}) => {
  const percentualAtivo = botaoSelecionado === PERCENTUAL;

  return (
    <div
      style={{ marginTop: isUltimos6Ciclos && "0px" }}
      className="container-botoes-grafico "
    >
      <BotaoVerde
        ativo={!percentualAtivo}
        onClick={() => setBotaoSelecionado(QUANTIDADE)}
      >
        Quantidade de linhas
      </BotaoVerde>
      <BotaoVerde
        ativo={percentualAtivo}
        onClick={() => setBotaoSelecionado(PERCENTUAL)}
      >
        Percentual de linhas
      </BotaoVerde>
    </div>
  );
};

export default BotoesGrafico;
