//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/ 
import { useForm } from "react-hook-form";
import {Button, Form, Col} from 'react-bootstrap';
import moment from 'moment';
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, fecharJanela, setaDropdown} from '../../../utils/alternanciaIcones'
//MODAIS
import SelectGenerico from '../../componentesModais/selects/selectGenerico'
import SelectGenericoCheck from '../../componentesModais/selects/selectGenericoCheckbox'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
// SPINNER
import Calendario from '../../calendario/calendario'
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

//REDUX
import {useDispatch} from 'react-redux';
import {revelarGerarRelatorio} from "../../../redux/actions/index";
import decryptJWT from '../../../utils/decryptJWT';

import ColetaClickstream from  '../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function CadastroOperadora(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const {handleSubmit} = useForm();
    //HOOKS MODAIS
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    //OUTROS HOOKS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const modal = useRef();
    const despacho = useDispatch();


    // HOOKS NOVO 
    const [revelarSelectCliente, setRevelarSelectCliente] = useState(false);
    const [revelarSelectOperadora, setRevelarSelectOperadora] = useState(false);
    const [revelarSelectReferencia, setRevelarSelectReferencia] = useState(false);
    const [revelarSelectStatus, setRevelarSelectStatus] = useState(false);
    const [revelarSelectTipoFormato, setRevelarSelectTipoFormato] = useState(false);
    const [revelarSelectTipoRelatorio, setRevelarSelectTipoRelatorio] = useState(false);
    const [cdCliente, setCdCliente] = useState('');
    const [dsCliente, setDsCliente] = useState('Selecione');
    const [cdOperadora, setCdOperadora] = useState()
    const [dsOperadora, setDsOperadora] = useState('Selecione')
    const [cdReferencia, setCdReferencia] = useState()
    const [dsReferencia, setDsReferencia] = useState('Selecione')
    const [cdStatus, setCdStatus] = useState()
    const [dsStatus, setDsStatus] = useState('Selecione')
    const [cdTipoFormato, setCdTipoFormato] = useState()
    const [dsTipoFormato, setDsTipoFormato] = useState('Selecione')
    const [cdTipoRelatorio, setCdTipoRelatorio] = useState()
    const [dsTipoRelatorio, setDsTipoRelatorio] = useState('Selecione')
    const [dummy, setDummy] = useState('');
    const [inicioCiclo, setInicioCiclo] = useState("")
    const [fimCiclo, setFimCiclo] = useState("")
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState()
    const cd_pav_usuario = decryptJWT('codigo')

    const [arrayDummy,setArrayDummy] = useState([])
    const [arrayCdCliente,setArrayCdCliente] = useState([])
    const [arrayDsCliente,setArrayDsCliente] = useState([])
    const [arrayNomeFantasia,setArrayNomeFantasia] = useState([])
    const [arrayCdOperadora,setArrayCdOperadora] = useState([])
    const [arrayDsOperadora,setArrayDsOperadora] = useState([])
    const [arrayCdReferencia,setArrayCdReferencia] = useState([])
    const [arrayDsReferencia,setArrayDsReferencia] = useState([])
    const [arrayApelidoOperadora,setArrayApelidoOperadora] = useState([])
    const [arrayCdStatus,setArrayCdStatus] = useState([])
    const [arrayDsStatus,setArrayDsStatus] = useState([])
    const [arrayStatusOperadora,setArrayStatusOperadora] = useState([])

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function handleSalvar(){
        const dados = {
            codigo_cliente: decryptJWT('codigoCliente'),
            codigo_usuario: decryptJWT('codigo'),
            arrayContas: decryptJWT("fatContas"),
            descricao: 'Relatório de faturas',
            tipo_arquivo: dsTipoFormato,
            tipo_relatorio: cdTipoRelatorio,
            contar: false,
            cd_tipo_solicitacao : 19,
            cd_cliente: arrayCdCliente,
            ds_cliente: arrayDsCliente,
            cd_operadora: arrayCdOperadora,
            ds_operadora: arrayDsOperadora,
            cd_referencia: cdReferencia,
            ds_referencia: dsReferencia,
            cd_status: arrayCdStatus,
            ds_status: arrayDsStatus,
            dt_inicio: moment(inicioCiclo).format("YYYY-MM-DD HH:mm:ss"),
            dt_fim: moment(fimCiclo).format("YYYY-MM-DD HH:mm:ss"),
            data_criacao: moment().format('DD/MM/YYYY - HH:mm'),
        }
        api.post('/m1/cadastrar/relatorios',dados)
        .then(function (response) {
            setArrayCdCliente([])
            setArrayCdOperadora([])
            setArrayCdStatus([])
        })
        .catch(function (error) {
            
        })
        setRevelarMensagemInformacao(true)
        
    }

    function handleCancelar(){
        ColetaClickstream(1192) // 'gerarRelatorioCancelar'
        despacho(revelarGerarRelatorio(false))
        props.setRenderizar(!props.renderizar)
        // props.setRefazerChamadas(true)
        setRevelarMensagemInformacao(false)
        setRevelarMensagemErro(false)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }
    
    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
            handleCancelar()
        }
    }

    useEffect(()=>{
        function renderVariaveis(){
            setCdTipoFormato('')
            setDsTipoFormato('Selecione')
            setCdCliente('')
            setDsCliente('Selecione')
            setCdOperadora('')
            setDsOperadora('Selecione')
            setCdReferencia('')
            setDsReferencia('Selecione')
            setCdStatus('')
            setDsStatus('Selecione')
            setInicioCiclo('')
            setFimCiclo('')
            setArrayDummy([])
            setArrayCdCliente([])
            setArrayDsCliente([])
            setArrayNomeFantasia([])
            setArrayCdOperadora([])
            setArrayDsOperadora([])
            setArrayCdReferencia([])
            setArrayDsReferencia([])
            setArrayApelidoOperadora([])
            setArrayCdStatus([])
            setArrayDsStatus([])
            setArrayStatusOperadora([])
        }
        renderVariaveis()
    },[dsTipoRelatorio])

    useEffect(()=>{
        if(cdOperadora !== undefined && cdOperadora !== null){
            // if(arrayCdOperadora.length > 0) setArrayCdOperadora([cdOperadora])
            // if(arrayDsOperadora.length > 0) setArrayDsOperadora([dsOperadora]) 
            if(arrayCdOperadora.length > 0){
                arrayCdOperadora = []
            }
            setArrayCdOperadora([cdOperadora])
            setArrayDsOperadora([dsOperadora]) 
        }
    },[cdOperadora])

    useEffect(()=>{
        let placeholderAux = false
        arrayCdReferencia.forEach(function(item, index){
            if(item === -1){
                placeholderAux = true
            }
        })
        if(placeholderAux === true){
            setDsReferencia(arrayCdReferencia.length - 1 + " Selecionados")
        }
        else if(arrayCdReferencia.length === 1){
            setDsReferencia(arrayDsReferencia[0])
        }
        else if(arrayCdReferencia.length === 0){
            setDsReferencia("Selecione")
        }
        else{
            setDsReferencia(arrayCdReferencia.length + " Selecionados")
        }
    },[JSON.stringify(arrayCdReferencia)])

    useEffect(()=>{
        let placeholderAux = false
        arrayCdCliente.forEach(function(item, index){
            if(item === -1){
                placeholderAux = true
            }
        })
        if(placeholderAux === true){
            setDsCliente(arrayCdCliente.length - 1 + " Selecionados")
        }
        else if(arrayCdCliente.length === 1){
            setDsCliente(arrayDsCliente[0])
        }
        else if(arrayCdCliente.length === 0){
            setDsCliente("Selecione")
        }
        else{
            setDsCliente(arrayCdCliente.length + " Selecionados")
        }
    },[JSON.stringify(arrayCdCliente)])

    useEffect(()=>{
        let placeholderAux = false
        arrayCdOperadora.forEach(function(item, index){
            if(item === -1){
                placeholderAux = true
            }
        })
        if(placeholderAux === true){
            setDsOperadora(arrayCdOperadora.length - 1 + " Selecionados")
        }
        else if(arrayCdOperadora.length === 1){
            setDsOperadora(arrayDsOperadora[0])
        }
        else if(arrayCdOperadora.length === 0){
            setDsOperadora("Selecione")
        }
        else{
            setDsOperadora(arrayCdOperadora.length + " Selecionados")
        }
    },[JSON.stringify(arrayCdOperadora)])

    useEffect(()=>{
        let placeholderAux = false
        arrayCdStatus.forEach(function(item, index){
            if(item === -1){
                placeholderAux = true
            }
        })
        if(placeholderAux === true){
            setDsStatus(arrayCdStatus.length - 1 + " Selecionados")
        }
        else if(arrayCdStatus.length === 1){
            setDsStatus(arrayDsStatus[0])
        }
        else if(arrayCdStatus.length === 0){
            setDsStatus("Selecione")
        }
        else{
            setDsStatus(arrayCdStatus.length + " Selecionados")
        }
    },[JSON.stringify(arrayCdStatus)])

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-cadastro-planos-faturas fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Gerar Relatórios</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row className="container-tipo-relatorio-1">
                    <Col>
                        <Form.Label>
                            <div className="fonte-cor-1 label-campo">Tipo de Relatório</div>
                        </Form.Label>
                        {revelarSelectTipoRelatorio === false &&
                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoRelatorio(!revelarSelectTipoRelatorio)}>
                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoRelatorio}</div>
                                <img className={revelarSelectTipoRelatorio?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                            </Button>
                        }
                        {revelarSelectTipoRelatorio === true &&
                            <SelectGenerico
                                paramRota={"tipo_relatorios"}
                                paramCampo1={cd_pav_usuario}
                                paramCampo2={decryptJWT('codigoCliente')}
                                paramCampo3={null}
                                paramCampo4={null}
                                setCodigo={setCdTipoRelatorio}
                                setDescricao1={setDsTipoRelatorio}
                                setDescricao2={setDummy}
                                setDescricao3={setDummy}
                                setDescricao4={setDummy}
                                setRevelarSelect={setRevelarSelectTipoRelatorio}
                                placeholder={dsTipoRelatorio}
                                sufixo={""}
                            />
                        }
                    </Col>
                </Form.Row>
                {dsTipoRelatorio === "Contestação" &&
                    <>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                            <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        {revelarSelectCliente === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectCliente === true &&
                                            <SelectGenericoCheck
                                                paramRota={"cliente_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdCliente}
                                                arrayDescricao1={arrayDsCliente}
                                                arrayDescricao2={arrayNomeFantasia}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectCliente}
                                                placeholder={dsCliente}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Operadora</div>
                                        </Form.Label>
                                        {revelarSelectOperadora === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsOperadora}</div>
                                                <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }

                                        {revelarSelectOperadora === true &&
                                            <SelectGenericoCheck
                                                paramRota={"operadora_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdOperadora}
                                                arrayDescricao1={arrayDsOperadora}
                                                arrayDescricao2={arrayApelidoOperadora}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectOperadora}
                                                placeholder={dsOperadora}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Status</div>
                                        </Form.Label>
                                        {revelarSelectStatus === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectStatus(!revelarSelectStatus)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsStatus}</div>
                                                <img className={revelarSelectStatus?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectStatus === true &&
                                            <SelectGenericoCheck
                                                paramRota={"status_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdStatus}
                                                arrayDescricao1={arrayDsStatus}
                                                arrayDescricao2={arrayStatusOperadora}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectStatus}
                                                placeholder={dsStatus}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                            <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data inicio</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data fim</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={fimCiclo} setData={setFimCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="container-campo-texto-2">
                                        {/* DIV COLOCADA SO PARA FICAR CENTRALIZADO OS CAMPOS NO FRONT */}
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsCliente !="Selecione" && dsOperadora !="Selecione" && dsStatus !="Selecione" && dsTipoRelatorio != "Selecione" && dsTipoFormato != "Selecione") ?
                                    <Button onClick={()=>ColetaClickstream(1193) /*gerarRelatorioExportarRelatorio*/} type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }
                    
                {dsTipoRelatorio === "Pedidos" &&
                    <>
                        <Form.Row className="container-tipo-relatorio">
                            <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                            <Col>
                                <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        {revelarSelectCliente === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectCliente === true &&
                                            <SelectGenericoCheck
                                                paramRota={"cliente_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdCliente}
                                                arrayDescricao1={arrayDsCliente}
                                                arrayDescricao2={arrayNomeFantasia}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectCliente}
                                                placeholder={dsCliente}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data inicio</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data fim</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={fimCiclo} setData={setFimCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsCliente !="Selecione" && dsTipoFormato != "Selecione") ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }

                {dsTipoRelatorio === "Notas Fiscais" &&
                    <>
                        <Form.Row className="container-tipo-relatorio">
                            <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                            <Col>
                                <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Operadora</div>
                                        </Form.Label>
                                            {revelarSelectOperadora === false &&
                                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                                    <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsOperadora}</div>
                                                    <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                                </Button>
                                            }
                                            {revelarSelectOperadora === true &&
                                                 <SelectGenericoCheck
                                                    paramRota={"operadora_relatorio_contestacao"}
                                                    paramCampo1={cd_pav_usuario}
                                                    paramCampo2={decryptJWT('codigoCliente')}
                                                    paramCampo3={null}
                                                    paramCampo4={null}
                                                    arrayCodigo={arrayCdOperadora}
                                                    arrayDescricao1={arrayDsOperadora}
                                                    arrayDescricao2={arrayApelidoOperadora}
                                                    arrayDescricao3={arrayDummy}
                                                    arrayDescricao4={arrayDummy}
                                                    setRevelarSelect={setRevelarSelectOperadora}
                                                    placeholder={dsOperadora}
                                                    sufixo={""}
                                                />
                                            }
                                    </div>
                                    <div className="container-campo-texto-3">
                                        <div className="container-data-calendario-2">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Mês/Ano</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="YYYY/MM" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsOperadora !="Selecione" && dsTipoFormato != "Selecione" && inicioCiclo != "") ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }

                {dsTipoRelatorio === "Solicitações" &&
                    <>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                            <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        {revelarSelectCliente === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectCliente === true &&
                                            <SelectGenericoCheck
                                                paramRota={"cliente_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdCliente}
                                                arrayDescricao1={arrayDsCliente}
                                                arrayDescricao2={arrayNomeFantasia}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectCliente}
                                                placeholder={dsCliente}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data inicio</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data fim</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={fimCiclo} setData={setFimCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsCliente !="Selecione" && dsTipoFormato != "Selecione") ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }

                {dsTipoRelatorio === "SMS" &&
                    <>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                            <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        {revelarSelectCliente === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectCliente === true &&
                                            <SelectGenericoCheck
                                                paramRota={"cliente_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdCliente}
                                                arrayDescricao1={arrayDsCliente}
                                                arrayDescricao2={arrayNomeFantasia}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectCliente}
                                                placeholder={dsCliente}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Operadora</div>
                                        </Form.Label>
                                        {revelarSelectOperadora === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsOperadora}</div>
                                                <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }

                                        {revelarSelectOperadora === true &&
                                            <SelectGenericoCheck
                                                paramRota={"operadora_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdOperadora}
                                                arrayDescricao1={arrayDsOperadora}
                                                arrayDescricao2={arrayApelidoOperadora}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectOperadora}
                                                placeholder={dsOperadora}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Mês/Ano</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="YYYY/MM" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsCliente !="Selecione" && dsOperadora !="Selecione" && dsStatus !="Selecione" && dsTipoRelatorio != "Selecione" && dsTipoFormato != "Selecione") ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }
                {dsTipoRelatorio === "Movimento" &&
                    <>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        {revelarSelectCliente === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectCliente === true &&
                                            <SelectGenericoCheck
                                                paramRota={"cliente_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdCliente}
                                                arrayDescricao1={arrayDsCliente}
                                                arrayDescricao2={arrayNomeFantasia}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectCliente}
                                                placeholder={dsCliente}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Operadora</div>
                                        </Form.Label>
                                        {revelarSelectOperadora === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsOperadora}</div>
                                                <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }

                                        {revelarSelectOperadora === true &&
                                            <SelectGenericoCheck
                                                paramRota={"operadora_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdOperadora}
                                                arrayDescricao1={arrayDsOperadora}
                                                arrayDescricao2={arrayApelidoOperadora}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectOperadora}
                                                placeholder={dsOperadora}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data inicio</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data fim</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={fimCiclo} setData={setFimCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="container-campo-texto-2">
                                        {/* DIV COLOCADA SO PARA FICAR CENTRALIZADO OS CAMPOS NO FRONT */}
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsCliente !="Selecione" && dsTipoFormato != "Selecione") ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }

                {dsTipoRelatorio === "Contestação por Operadora" &&
                    <>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto">
                                    <div className="container-campo-texto-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        {revelarSelectCliente === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectCliente === true &&
                                            <SelectGenericoCheck
                                                paramRota={"cliente_relatorio_contestacao"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                arrayCodigo={arrayCdCliente}
                                                arrayDescricao1={arrayDsCliente}
                                                arrayDescricao2={arrayNomeFantasia}
                                                arrayDescricao3={arrayDummy}
                                                arrayDescricao4={arrayDummy}
                                                setRevelarSelect={setRevelarSelectCliente}
                                                placeholder={dsCliente}
                                                sufixo={""}
                                            />
                                        }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Operadora</div>
                                        </Form.Label>
                                            {revelarSelectOperadora === false &&
                                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                                    <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsOperadora}</div>
                                                    <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                                </Button>
                                            }
                                            {revelarSelectOperadora === true &&
                                                 <SelectGenerico
                                                    paramRota={"dsp_operadora_2"}
                                                    paramCampo1={decryptJWT("fatContas")}
                                                    paramCampo2={null}
                                                    paramCampo3={null}
                                                    paramCampo4={null}
                                                    setCodigo={setCdOperadora}
                                                    setDescricao1={setDsOperadora}
                                                    setDescricao2={setDummy}
                                                    setDescricao3={setDummy}
                                                    setDescricao4={setDummy}
                                                    setRevelarSelect={setRevelarSelectOperadora}
                                                    placeholder={dsOperadora}
                                                    sufixo={""}
                                                />
                                            }
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Referência</div>
                                        </Form.Label>
                                            {revelarSelectReferencia === false &&
                                                <Button disabled={cdOperadora != undefined && cdOperadora != null && cdOperadora != '' ? false : true} className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectReferencia(!revelarSelectReferencia)}>
                                                    <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsReferencia}</div>
                                                    <img className={revelarSelectReferencia?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                                </Button>
                                            }
                                            {revelarSelectReferencia === true &&
                                                 <SelectGenerico
                                                    paramRota={"referencia_por_operadora"}
                                                    paramCampo1={cdOperadora}
                                                    paramCampo2={decryptJWT("fatContas")}
                                                    paramCampo3={null}
                                                    paramCampo4={null}
                                                    setCodigo={setCdReferencia}
                                                    setDescricao1={setDsReferencia}
                                                    setDescricao2={setDummy}
                                                    setDescricao3={setDummy}
                                                    setDescricao4={setDummy}
                                                    setRevelarSelect={setRevelarSelectReferencia}
                                                    placeholder={dsReferencia}
                                                    sufixo={""}
                                                />
                                            }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                        <Form.Row className="container-botoes-cancelar-confirmar">
                            <Col>
                                <>
                                    {(dsCliente !="Selecione" && dsTipoFormato != "Selecione") ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Exportar Relatório</div>
                                        </Button>
                                    }
                                </>
                            </Col>
                        </Form.Row>
                    </>
                }
            </div>
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"O arquivo está sendo gerado"}
                            mensagemParagrafo1={"Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível"}
                            mensagemBotao={"Voltar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}