import { selecionarCicloEstilosCoresTema as estilosCoresTema } from "../../../cores";
import { isTemaClaro } from "../utils";

export function getEstilosCalendarMonthByNewClasses(arrNewClasses) {
    const estilosCores = isTemaClaro() ? estilosCoresTema.claro : estilosCoresTema.escuro;
    let estilosCalendarMonth =  {};

    const { calendarMonth } = estilosCores;
    for(let i=0; i<arrNewClasses.length; i++) {
        estilosCalendarMonth = {...estilosCalendarMonth, ...calendarMonth[arrNewClasses[i]]}
    }

    return estilosCalendarMonth;
}