const ESTADO_INICIAL = {
  revelarNovoPedidoSimcard: false,
  revelarMostrarEmDispositivos: false,
  revelarExportarDetalhamento: false,
  qtdSimcardsPedido: 0,
  bodyCancelamentoComReposicao: {},
};

export const pedidosReducer = (state = ESTADO_INICIAL, action) => {
  if (action.type === "REVELAR_NOVO_PEDIDO_SIMCARD") {
    return {
      ...state,
      revelarNovoPedidoSimcard: action.parametro,
    };
  } else if (action.type === "REVELAR_MOSTRAR_EM_DISPOSITIVOS") {
    return {
      ...state,
      revelarMostrarEmDispositivos: action.parametro,
    };
  } else if (action.type === "REVELAR_EXPORTAR_DETALHAMENTO") {
    return {
      ...state,
      revelarExportarDetalhamento: action.parametro,
    };
  } else if (action.type === "QTD_SIMCARDS_PEDIDO") {
    return {
      ...state,
      qtdSimcardsPedido: action.parametro,
    };
  } else if (action.type === "BODY_CANCELAMENTO_REPOSICAO") {
    return {
      ...state,
      bodyCancelamentoComReposicao: action.parametro,
    };
  } else {
    return state;
  }
};
