//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { Button } from 'react-bootstrap';/*eslint-disable*/
import { alertaErro, downloadBranco } from '../../../utils/alternanciaIcones'

// COLETA CLICKSTREAM
// import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import MensagemErro from '../componentesModais/mensagens/mensagemErro'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma.
    ex: { revelarMensagemErro &&
            <div className="componente-modal-overlay">
                <div className="componente-modal">
                    <MensagemErro
                        handleMensagemErro={handleMensagemErro}
                        mensagemTitulo={"TITULO DA MENSAGEM"}
                        mensagemParagrafo1={"CORPO DA MENSAGEM 1"}
                        mensagemParagrafo2={"CORPO DA MENSAGEM 2"}
                        mensagemParagrafo3={"CORPO DA MENSAGEM 3"}
                        mensagemBotao={"TEXTO DO BOTÃO"}
                        mensagemBotao2={"TEXTO DO BOTÃO OPCIONAL"}
                    />
                </div>
            </div>
        }
        
    Agora crie uma função para manejar o estado da mensagem e executar a função de
    fechamento padrão caso necessário.
    ex: function handleMensagemErro(confirmacao){
            if(revelarMensagemErro === false){
                setRevelarMensagemErro(true)
            }else{
                setRevelarMensagemErro(false)
                if(confirmacao === true){
                    //ALGUMA FUNÇÃO
                }
            }
        }*/

export default function MensagemErro(props) {
    //HOOK PRINCIPAIS
    const [mensagemTitulo, setMensagemTitulo] = useState('')
    const [mensagemParagrafo1, setMensagemParagrafo1] = useState('')
    const [mensagemParagrafo2, setMensagemParagrafo2] = useState('')
    const [mensagemParagrafo3, setMensagemParagrafo3] = useState('')
    const [mensagemBotao, setMensagemBotao] = useState('')
    const [mensagemBotao2, setMensagemBotao2] = useState('')

    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            if(props.downloadNoBotao2) props.downloadNoBotao2(); // apenas se for download de arquivo de erros

            handleCancelar(false)
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleCancelar(confirmacao){
        if(confirmacao === true){
            props.handleMensagemErro(true)
        }else{
            props.handleMensagemErro(false)
        }
    }

    useEffect(() => {
        setMensagemTitulo(props.mensagemTitulo)
        setMensagemParagrafo1(props.mensagemParagrafo1)
        setMensagemParagrafo2(props.mensagemParagrafo2)
        setMensagemParagrafo3(props.mensagemParagrafo3)
        setMensagemBotao(props.mensagemBotao)
        setMensagemBotao2(props.mensagemBotao2)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return(
        <div className="mensagem-overlay">
            <div className="mensagem-container">
                <div ref={modal} className="mensagem-corpo fundo-cor-6">
                    <div className="mensagem-container-icone">
                        <img className="mensagem-icone" src={alertaErro()} alt="sucesso"/>
                    </div>
                    <div className="mensagem-container-titulo">
                        <div className="fonte-cor-1 label-titulo-mensagem">{mensagemTitulo}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo1}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo2}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo3}</div>
                    </div>
                    
                    <div className="mensagem-container-botao" style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button 
                            disabled={props.botao1Disabled}
                            className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar(true)}
                        >
                            <div className={props.botao1Disabled ? "fonte-cor-1" : "fonte-cor-2"}>{mensagemBotao}</div>
                        </Button>

                        {mensagemBotao2 && (
                            <Button
                                className="botao-confirmar" 
                                type="submit" 
                                variant="success" size="sm" onClick={() => props.downloadNoBotao2()}
                            >
                                <div /*className="fonte-cor-1"*/>
                                    {mensagemBotao2}
                                    <img className="ml-2" src={downloadBranco()} alt="sucesso"/>
                                </div>
                                    
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export function RenderMensagemErro(props) {
    return (
    <div className="componente-modal-overlay">
        <div className="componente-modal">
            <MensagemErro {...props} />
        </div>
    </div>
    )
}