//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectOperadora from '../../componentesModais/selects/selectOperadora'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectOperadora, setRevelarSelectOperadora] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">Operadora {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectOperadora === false &&
                <Button name="cdOperadora" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                    <div className="fonte-cor-1 label-campo">{dsOperadora}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectOperadora === true &&
                <SelectOperadora
                    setRevelarSelectOperadora={setRevelarSelectOperadora} //Hook para fechar a modal
                    setCdOperadora={setCdOperadora} //Código do Operadora
                    setDsOperadora={setDsOperadora} //Nome do Operadora
                    dsOperadora={dsOperadora} 
                    phOperadora={phOperadora} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectOperadora>
            }
        </Form.Group>*/

export default function SelectOperadoraFaturas(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [lsOperadoras, setLsOperadoras] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleOperadora(Operadora,cdOperadora){
        props.setDsOperadora(Operadora)
        props.setCdOperadora(cdOperadora)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectOperadoraFaturas(false)
    }

    useEffect(() => {
        async function renderOperadoras(){
            const {data} = await api.get('/m1/consultar/operadora/operadora_faturas');
            if(data.dados.length > 0){
                let objetoOperadoras = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.cd_dsp_operadora}>
                            <div className="campo-select-opcao campo-select-opcao-3" onClick={() => handleOperadora(item.ds_dsp_operadora, item.cd_dsp_operadora) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.ds_dsp_operadora}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsOperadoras(objetoOperadoras)
            }else{
                let objetoOperadoras = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-3">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsOperadoras(objetoOperadoras)
            }
        }
        renderOperadoras()
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal} >
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectOperadoraFaturas(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsOperadora}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="modal-select-operadora">
                <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
                <div className="campo-texto-cor-3 campo-select-corpo">
                    <ul className="campo-select-lista">
                        {lsOperadoras}
                    </ul>
                </div>
            </div>
        </Form>
    )
}