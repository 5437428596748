//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import {formatarData} from '../../../utils/formatadorDataTabela'
import {formatarMilhar} from '../../../utils/formatarMilhar'
//ICONES
import {elipseVerde, fecharJanela, lixeiraVermelho, exclamacao, setaDropdown, alertaAtencao} from '../../../utils/alternanciaIcones';
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import { revelarAtivarDemonstracao } from "../../../redux/actions/index";
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../utils/paginacaoTabela'
//MODAIS
import SelectNovoPlano from '../../componentesModais/selects/selectPlano'
import SelectValidadePlano from '../../componentesModais/selects/selectValidadePlano'
import SelectPlanoTipo from '../../componentesModais/selects/selectPlanoTipo'

import {identificarValidacaoAcoes} from '../../../utils/identificarValidacaoAcoes';

//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'

import RetornarParametro from '../../../utils/decryptJWT'
import decryptJWT from '../../../utils/decryptJWT'

const valorLimite = 50
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let simcardSelecionados = [];
let itensVar = []

const routeM1FiltrarSimcard = '/m1/filtrar/simcard';

export default function AtivarDemonstracao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [dsNovoPlano, setDsNovoPlano] = useState('Selecione');
    const [cdNovoPlano, setCdNovoPlano] = useState();
    const [dsValidadePlano, setDsValidadePlano] = useState('Selecione');
    const [dsValor, setDsValor] = useState('');
    const [dsPlanoTipo, setDsPlanoTipo] = useState('Selecione');
    const [cdPlanoTipo, setCdPlanoTipo] = useState();
    const [dsMotivo, setDsMotivo] = useState('');
    const [caracteresMensagem, setCaracteresMensagem] = useState(1000);
    // const [switchCompartilhado, setSwitchCompartilhado] = useState(false);
    // const [switchNaoAlterarPlano, setSwitchNaoAlterarPlano] = useState(false);
    // const [switchNaoAlterarValor, setSwitchNaoAlterarValor] = useState(false);
    const [renderizar, setRenderizar] = useState(false);
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1);
    const [linhaRemover, setLinhaRemover] = useState('');
    const [revelarSelectValidadePlano, setRevelarSelectValidadePlano] = useState(false)
    const [revelarSelectNovoPlano, setRevelarSelectNovoPlano] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [simcards, setSimcards] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensInvalidosBusca, setItensInvalidosBusca] = useState(false);
    const [linhas, setLinhas] = useState([]);
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] = useState(false)
    const [revelarMensagemAtencaoContemItemInvalido, setRevelarMensagemAtencaoContemItemInvalido] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [itens, setItens] = useState([])
    const [itensInvalidos, setItensInvalidos] = useState([])
    const [operadoras, setOperadoras] = useState([])
    const [beforeRevelarSelectNovoPlano, setBeforeRevelarSelectNovoPlano] = useState(false)
    const [revelarSelectPlanoTipo, setRevelarSelectPlanoTipo] = useState(false)

    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();
    const mensagemLinhasRecusadas = "Algumas linhas já tem uma solicitação igual em aberto ou o contrato delas não permite esta ação!"
    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    const codigoCliente = RetornarParametro('codigoClienteErpAntigo')
    const codigosClientes = RetornarParametro('codigoCliente')

    let itensTemp = []
    let itensInvalidosTemp = []

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    const colunasItensInvalidos = [{
        dataField: 'CALLERID',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'ds_motivo',
        text: 'Motivo',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },]

    const colunas = [{
        dataField: 'CD_SIMCARD',
        text: 'Código',
        headerClasses: 'nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'OPERADORA',
        text: 'Operadora',
        headerClasses: 'nao-selecionavel',
        sort: true,
        headerWrapperClasses: 'operadoraFinal',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'CALLERID',
        headerClasses: 'nao-selecionavel',
        text: 'Linha',
        sort: true, 
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ICCID',
        headerClasses: 'nao-selecionavel',
        text: 'ICCID',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'PLANO',
        headerClasses: 'nao-selecionavel',
        text: 'Plano',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell ? cell.toFixed(2) : cell} MB</p>;
        }
    },
    {
        dataField: 'VALOR_PLANO',
        headerClasses: 'nao-selecionavel',
        text: 'Valor',
        sort: true,
        formatter: (cell, row) => {
            const valor = parseFloat(cell);
            return <p className="fonte-cor-1 label-tabela-1 container-valor-modal"> {(!valor || isNaN(valor)) ? 'R$ 0.00' : <><p className="valor-rs-modal">R$</p> <p className="fonte-cor-1 valor-modal"> {formatarMilhar(valor)}</p></>} </p>;
        }
    },
    {
        dataField: 'DATA_ATIVACAO',
        headerClasses: 'nao-selecionavel',
        text: 'Ativação',
        sort: true,
        formatter: (cell, row) => {
            if(!cell || cell == '')
               return <p className="fonte-cor-1 label-tabela-1">'-'</p>;

            return formatarData(cell);
        }
    },
    {
        dataField: 'DATA_PRAZO_CONTRATO',
        headerClasses: 'nao-selecionavel',
        text: 'Prazo contrato',
        sort: true,
        formatter: (cell, row) => {
            if(!cell || cell == '')
               return <p className="fonte-cor-1 label-tabela-1">'-'</p>;

            return formatarData(cell);
        }
    },
    {
        dataField: 'acoes',
        text: '',
        sort: false,
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.CD_SIMCARD);
        }
    }


    function handleMotivo(motivo){
        setDsMotivo(motivo)
        setCaracteresMensagem(1000 - motivo.length)
    }

    function handleValidarCampoValor(valor){
        const filtro = /^[0-9\,\.\b]+$/;
        if (valor === '' || filtro.test(valor)) {
            let valorFormatado = ((valor).replace(".", ","))
            setDsValor(valorFormatado)
        }
    }
    
    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if(itensTemp.length === 0){
            handleCancelar()
        }
    }

    //verifica se o usuario selecionou linhas extras que nao sao permitidas na acao e aciona um alerta
    function handleLinhasSelecionadasIncapacitadas(){
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if(itensInvalidosTemp.length > 0){
            setRevelarMensagemAtencaoInvalido(true)
        }
    }

    async function handleRemoverLinha(CD_SIMCARD){
        ColetaClickstream(1123) // ativarDemonstracaoRemoverLinha
        setRevelarMensagemAtencao(false)
        if(modoSelecaoSimcard){
            let simcardNaoSelecionados = itensInvalidosBusca;
            if(itensInvalidosBusca){
                simcardNaoSelecionados = itensInvalidosBusca;
            }
            let simcardSelecionados = simcards
            if(simcardNaoSelecionados === undefined || simcardNaoSelecionados === null || simcardNaoSelecionados === false){
                simcardNaoSelecionados = CD_SIMCARD + ","
            }else{
                simcardNaoSelecionados += CD_SIMCARD + ","
            }
            setItensInvalidosBusca(simcardNaoSelecionados)

            for (let i = contador; i < simcardSelecionados.length; i++) {
                if(simcardSelecionados[i] == CD_SIMCARD){
                    simcardSelecionados.splice(i, 1)
                }
            }

            await Promise.all(
                itensVar.map((item_itensVar,i)=>{
                    if(CD_SIMCARD == item_itensVar.CD_SIMCARD){
                        itensVar.splice(i, 1);
                    }
                })
            )
            await contarItens();
            setItens(itensVar)
            setSimcards(simcardSelecionados)
        }
        else{
            itensTemp = itens
            itens.forEach(function(e, index){
                if(CD_SIMCARD == e.CD_SIMCARD){
                    itens.splice(index, 1);
                }
            })
            setItens(itensTemp)
            handleIdSimcards()
            handleLinhasSelecionadas()
        }
                    
    }


    async function handleSalvar(){
        ColetaClickstream(1124) // ativarDemonstracaoConfirmar

        let tempSimcards = simcards;
        let tempLinhas = linhas;
        setSpinAtivo(true)
        if(modoSelecaoSimcard === true){
            tempSimcards = [];
            tempLinhas = [];

             const urlGet = `${routeM1FiltrarSimcard}?acao=true&buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false&itensInvalidosBusca=${itensInvalidosBusca}`
            const consultaSimcards =  await api.get(urlGet)
            .then(function(resposta){
                return resposta.data;
            })
            .catch(function(erro){
                throw erro;
            })
          
           if(consultaSimcards.dados.length > 0 && consultaSimcards.status === 200){
                await Promise.all( 
                    consultaSimcards.dados.map((item, i) => {
                        tempSimcards.push(item.CD_SIMCARD)
                        tempLinhas.push(item.FULLCALLERID)
                    })
                )
           }else{
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
           }
        }


        let temporario = new Date();
        let dataHora = temporario.toISOString().substring(0, 10) +' '+ temporario.getHours()+':'+ temporario.getMinutes()+':'+ temporario.getSeconds();
        ////Removido Valicação/////
        await api.post('/m1/cadastrar/solicitacao', {
                'fl_operadora': 0,
                'simcards' : tempSimcards,
                'FULLCALLERID': tempLinhas,
                'cd_crm_solicitacao_tipo' : 24,
                'cd_crm_solicitacao_status' :  1,
                'ds_crm_solicitacao' :  dsMotivo,
                'dt_inicio' :  dataHora,
                'dt_fim' :  undefined,
                'dt_previsao' :  undefined,
                'cd_pav_usuario_created' :  decryptJWT('codigo'),
                'novoPlano': dsNovoPlano,
                'valorMensal' : dsValor,
                'validadePlano' : dsValidadePlano,
                'compartilhado': dsPlanoTipo,
                ds_rota_permissao:"veye/dispositivos/acoes/ativarDemonstracao",
            },{timeout: 20000})
            .then(function (response) {
                setSpinAtivo(false)
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            });
        
    }

    async function handleCancelar() {
        ColetaClickstream(1125) // ativarDemonstracaoCancelar
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
        itensVar = []
        itensInvalidosTemp = []
        simcardSelecionados = []
        setSpinAtivo(false)
        despacho(revelarAtivarDemonstracao(false))
    }

    function inserirData(data){
        // setItens('')
        if(itensVar.length == totalItens){
            for(let i=0;i<totalItens;i++){
                if (contador === i){
                    let k = i
                    for(let j=0;j<data.length;j++){
                        itensVar[k] = data[j]
                        k++
                    }
                }
                if(i == (totalItens-1)){
                    itensTemp = JSON.parse(JSON.stringify(itensVar))
                    setItens(JSON.parse(JSON.stringify(itensVar)))
                    handleIdSimcards()
                }
            }
            handleLinhasSelecionadas()
        }
    }
    

    function handleIdSimcards(){
        let tempSimcards = []
        let tempLinhas = []
        for (let i = 0; i < itensTemp.length; i++) {
            tempSimcards.push(itensTemp[i].CD_SIMCARD)
            tempLinhas.push(itensTemp[i].FULLCALLERID)
        }
        simcardSelecionados = tempSimcards 
        setLinhas(tempLinhas)
        setSimcards(tempSimcards)
    }
    
    async function handlerConsultarSimcadsBusca(){
        if(totalPaginas == 0){
            contador = 0;
            itensVar = [];
            setItens([])
            await contarItens();
        }
        else{
            contador = (paginaAtual - 1) * valorLimite;
        }
        if (controladorPagina <= paginaAtual) {
            setSpinAtivo(true)

            const urlGet = `${routeM1FiltrarSimcard}?paguinaAtual=${contador}&carregarLimit=${valorLimite}&acao=true&buscar=${conteudoBusca}&ordenador=${props.ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false&cd_pav_usuario=${decryptJWT('codigo')}&itensInvalidosBusca=${itensInvalidosBusca}`
            const consultaSimcards =  await api.get(urlGet)
            .then(function(resposta){
                return resposta.data.dados;
            })
            .catch(function(erro){
                throw erro;
            })

            setSpinAtivo(false)
            inserirData(consultaSimcards)
        }

    }
    useEffect(()=>{
        if(modoSelecaoSimcard && itensInvalidosBusca){
            handlerConsultarSimcadsBusca()
        }
    },[itensInvalidosBusca,paginaAtual])

    useEffect(() => {
        setSpinAtivo(true)
        async function preencherTabela (){
            const validacao = await identificarValidacaoAcoes(props.itensSelecionados,24,{filtros,buscaArquivo},modoSelecaoSimcard,conteudoBusca,separadorLinha)
            if(modoSelecaoSimcard){
                itensTemp = validacao.itensValidos
                itensInvalidosTemp = validacao.itensInvalidos
                setItensInvalidos(validacao.itensInvalidos)
                if(itensInvalidosTemp.length > 0){
                    await Promise.all(
                        validacao.itensInvalidos.map((item_validacao,i)=>{
                            simcardSelecionados += item_validacao.CD_SIMCARD + ','
                        })
                    )
                    setItensInvalidosBusca(simcardSelecionados)
                    setSimcards(validacao.itensValidos)
                }
                else{
                    handlerConsultarSimcadsBusca()
                }
            }
            else{
                setItens(validacao.itensValidos)
                setItensInvalidos(validacao.itensInvalidos)
                itensTemp = validacao.itensValidos
                itensInvalidosTemp = validacao.itensInvalidos
                handleIdSimcards();
                setItensInvalidosBusca(true)
            }
            handleLinhasSelecionadasIncapacitadas();
            setSpinAtivo(false)
         }
         preencherTabela()


        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    
    async function contarItens(){
        let arrayItens = []
        let apiData = [];

        if (controladorPagina <= paginaAtual ) { 
            const urlGet = `${routeM1FiltrarSimcard}?acao=true&buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=true&itensInvalidosBusca=${itensInvalidosBusca}`
            apiData =  await api.get(urlGet)
            .then(function(resposta){
                return resposta;
            })
            .catch(function(erro){
                throw erro;
            })
        }

        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao ===true){
                handleRemoverLinha(linhaRemover)
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    function handleFecharMensagemItensInvalidos(){
        setRevelarMensagemAtencaoInvalido(!revelarMensagemAtencaoInvalido)
        if(modoSelecaoSimcard){
            if(itens <= 0){
                handleCancelar()
            }
        }
        else{
            if(quantidadeLinhasSelecionados <= 0){
                handleCancelar()
            }
        }
    }

    async function handlerVerificarLinhas(){
        await Promise.all(
            itens.map((item,i)=>{
                if(item.CD_OPERADORA){
                    if(item.CD_OPERADORA != operadoras[--i] ){
                        operadoras.push(item.CD_OPERADORA)
                    }
                }
            })
        )

        setRevelarSelectNovoPlano(true)
    }

    useEffect(()=>{
        if(beforeRevelarSelectNovoPlano){
            handlerVerificarLinhas()
        }
        else{
            setRevelarSelectNovoPlano(false)
        }
    },[beforeRevelarSelectNovoPlano])

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-ativar-demonstracao fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Ativar demonstração</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-tabela-modal">
                            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <>
                                            <BootstrapTable
                                                classes="tabela-transparente-modais"
                                                condensed={true}
                                                keyField='id' 
                                                data={itens} 
                                                columns={colunas} 
                                                rowEvents={eventosLinhas}
                                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                bootstrap4={true}
                                                { ...paginationTableProps }
                                            />
                                            {modoSelecaoSimcard &&
                                                <div className="paguinacao-tabela-modal">
                                                    <PaginacaoTabela
                                                        extrato={true}
                                                        setPaginaAtual={setPaginaAtual}
                                                        totalPaginas={totalPaginas}
                                                        totalItens={totalItens}
                                                        valorLimite={valorLimite}
                                                        paginaAtual={paginaAtual}
                                                        page={paginationProps.page}
                                                        onPageChange={paginationProps.onPageChange}
                                                    />
                                                </div>
                                            }
                                        </>
                                    )
                                }
                            </PaginationProvider>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} linhas selecionadas</span>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-ativar-demonstracao-1">
                            <div className="coluna-ativar-demonstracao-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Novo plano</div>
                                    </Form.Label>
                                    { revelarSelectNovoPlano === false &&
                                        <Button name="cdNovoPlano" className="campo-texto-cor-3 campo-select" onClick={() => setBeforeRevelarSelectNovoPlano(!beforeRevelarSelectNovoPlano)}>
                                            <div className="fonte-cor-1 label-campo">{dsNovoPlano}</div>
                                            <img className={revelarSelectNovoPlano?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectNovoPlano === true &&
                                        <SelectNovoPlano
                                            setRevelarSelectNovoPlano={setRevelarSelectNovoPlano} //Hook para fechar a modal
                                            setDsNovoPlano={setDsNovoPlano} //Nome do NovoPlano
                                            dsNovoPlano={dsNovoPlano} 
                                            setCdNovoPlano={setCdNovoPlano}
                                            operadoras={operadoras}
                                        >
                                        </SelectNovoPlano>
                                    }
                                </Form.Group>
                            </div>
                            <div className="coluna-alterar-plano-valor-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Plano tipo</div>
                                    </Form.Label>
                                    { revelarSelectPlanoTipo === false &&
                                        <Button name="cdNovoPlano" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectPlanoTipo(!revelarSelectPlanoTipo)}>
                                           <div className="fonte-cor-1 label-campo">{dsPlanoTipo}</div>
                                            <img className={revelarSelectPlanoTipo?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectPlanoTipo === true &&
                                        <SelectPlanoTipo
                                            setRevelarSelectPlanoTipo={setRevelarSelectPlanoTipo} //Hook para fechar a modal
                                            setDsPlanoTipo={setDsPlanoTipo} //Nome do PlanoTipo
                                            dsPlanoTipo={dsPlanoTipo} 
                                            setCdPlanoTipo={setCdPlanoTipo}
                                        >
                                        </SelectPlanoTipo>
                                    }
                                </Form.Group>
                            </div>
                            {/* <div className="coluna-ativar-demonstracao-2">
                                <div className="container-switch-ativar-demonstracao">
                                    <div class="flipswitch">
                                        <input type="checkbox" name="flipswitch" class="flipswitch-cb" id="switchCompartilhado" onChange={() => setSwitchCompartilhado(!switchCompartilhado)}/>
                                        <label class="flipswitch-label" for="switchCompartilhado">
                                            <div class="flipswitch-inner flipswitch-cor-1"></div>
                                            <div class="flipswitch-switch"></div>
                                        </label>
                                    </div>
                                    <div className="fonte-cor-1 label-campo label-termos-ativar-demonstracao"> Compartilhado</div>
                                </div>
                            </div> */}
                            <div className="coluna-ativar-demonstracao-3">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Valor do plano</div>
                                    </Form.Label>
                                    <Form.Control className="campo-texto-cor-3 campo-texto"
                                        placeholder="R$ 0,00"
                                        name="valor"
                                        id="valor"
                                        onChange={e => handleValidarCampoValor(e.target.value)}
                                        value={dsValor}
                                        maxLength="100"
                                        ref={register({required: true})}
                                    />
                                    {errors.valor && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Informe o valor.</p>}
                                </Form.Group>
                            </div>
                            <div className="coluna-ativar-demonstracao-4">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Validade plano</div>
                                    </Form.Label>
                                    { revelarSelectValidadePlano === false &&
                                        <Button name="cdValidadePlano" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectValidadePlano(!revelarSelectValidadePlano)}>
                                            <div className="fonte-cor-1 label-campo">{dsValidadePlano}</div>
                                            <img className={revelarSelectValidadePlano?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectValidadePlano === true &&
                                        <SelectValidadePlano
                                            setRevelarSelectValidadePlano={setRevelarSelectValidadePlano} //Hook para fechar a modal
                                            setDsValidadePlano={setDsValidadePlano} //Nome do ValidadePlano
                                            dsValidadePlano={dsValidadePlano} 
                                        >
                                        </SelectValidadePlano>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                {/* <Form.Row>
                    <Col>
                        <div className="container-switch-ativar-demonstracao">
                            <div class="flipswitch">
                                <input type="checkbox" name="flipswitch" class="flipswitch-cb" id="switchNaoAlterarPlano" onChange={() => setSwitchNaoAlterarPlano(!switchNaoAlterarPlano)}/>
                                <label class="flipswitch-label" for="switchNaoAlterarPlano">
                                    <div class="flipswitch-inner flipswitch-cor-1"></div>
                                    <div class="flipswitch-switch"></div>
                                </label>
                            </div>
                            <div className="fonte-cor-1 label-campo label-termos-ativar-demonstracao"> Não alterar plano</div>
                        </div>
                    </Col>
                    <Col>
                        <div className="container-switch-ativar-demonstracao">
                            <div class="flipswitch">
                                <input type="checkbox" name="flipswitch" class="flipswitch-cb" id="switchNaoAlterarValor" onChange={() => setSwitchNaoAlterarValor(!switchNaoAlterarValor)}/>
                                <label class="flipswitch-label" for="switchNaoAlterarValor">
                                    <div class="flipswitch-inner flipswitch-cor-1"></div>
                                    <div class="flipswitch-switch"></div>
                                </label>
                            </div>
                            <div className="fonte-cor-1 label-campo label-termos-ativar-demonstracao"> Não alterar valor</div>
                        </div>
                    </Col>
                </Form.Row> */}
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo espaco-motivo-ativar-demonstracao">Motivo</div>
                            </Form.Label>
                            <Form.Control className="campo-texto-cor-3 campo-texto-area" as="textarea" rows="3" name="motivo_ativar_demonstracao" ref={register({required: true})} maxlength="1000" placeholder="Descreva aqui o motivo" onChange={e => handleMotivo(e.target.value)}></Form.Control>
                            {errors.motivo_ativar_demonstracao && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Informe o motivo.</p>}
                            <Form.Label className="contador-carateres-restantes">
                                <div className="fonte-cor-1 label-normal">{caracteresMensagem} caracteres restantes</div>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <>
                            {(dsNovoPlano != 'Selecione' && dsValor != '' && dsValidadePlano != 'Selecione' && dsPlanoTipo != 'Selecione' && dsMotivo != '') ?
                                <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            :
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            }
                            <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2">Cancelar</div>
                            </Button>
                        </>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                            </div>
                            <div className="fonte-cor-1 label-janela">{itensInvalidos.length} linhas foram retiradas</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensInvalidos}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensInvalidos()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
        
    )
}
