import React, { useState, useEffect } from 'react';/*eslint-disable*/
import '../../css/pavFiltros/filtros.css'
import { Form, Button, Col } from "react-bootstrap";
import { setaDropdown } from '../../utils/alternanciaIcones';
import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'
        
function Filtros(props) {
            
    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []


    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setFiltroValorMinimo(filtros.VALOR_MIN)
            setFiltroValorMaximo(filtros.VALOR_MAX)
            setFiltroN0SimcardsMinimo(filtros.N0SIMCARDS_MIN)
            setFiltroN0SimcardsMaximo(filtros.N0SIMCARDS_MAX)
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }
    
    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1299) /*fatPlanosTabelaDropFiltrosFiltrar*/
        if(filtroValorMinimo !== '' && filtroValorMaximo !== '' && filtroValorMinimo && filtroValorMaximo){
            arrayFiltrosSimcard.push("Valor: entre R$ "+filtroValorMinimo+" e R$ "+filtroValorMaximo+"?VALOR_MIN")
        }
        if(filtroN0SimcardsMinimo !== '' && filtroN0SimcardsMaximo !== '' && filtroN0SimcardsMinimo && filtroN0SimcardsMaximo){
            arrayFiltrosSimcard.push("Número de simcards: entre "+filtroN0SimcardsMinimo+" e "+filtroN0SimcardsMaximo+"?N0SIMCARDS_MIN")
        }
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    //HOOKS DOS VALORES DOS FILTROS
    const [filtroValorMinimo, setFiltroValorMinimo] = useState('')
    const [filtroValorMaximo, setFiltroValorMaximo] = useState('')
    const [filtroN0SimcardsMinimo, setFiltroN0SimcardsMinimo] = useState('')
    const [filtroN0SimcardsMaximo, setFiltroN0SimcardsMaximo] = useState('')

    useEffect(() => {
        
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroValorMinimo=='') verificador.push(1) 
            if(filtroValorMaximo=='') verificador.push(1) 
            if(filtroN0SimcardsMinimo=='') verificador.push(1) 
            if(filtroN0SimcardsMaximo=='') verificador.push(1) 
            //tamanho (34) é igual a quantidade de if que tem acima
            if(verificador.length === 4) return false
            return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()){
                props.setFoiSelecionado(true);
            }
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroValorMinimo('')
            setFiltroValorMaximo('')
            setFiltroN0SimcardsMinimo('')
            setFiltroN0SimcardsMaximo('')
            props.setFoiLimpado(false)
        }
        
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroValorMinimo,filtroValorMaximo,filtroN0SimcardsMinimo,filtroN0SimcardsMaximo,props.foiLimpado]);
    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-valor","imagem-filtro-valor"); ColetaClickstream(1297) /*fatPlanosTabelaDropFiltrosValor*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Valor </Form.Label>
                        <img id={"imagem-filtro-valor"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-valor"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <label className="filtros-label-mb-esquerda fonte-cor-1 campo-texto-cor-3">R$</label>
                                    <Form.Control
                                        name="VALOR_MIN"
                                        className="filtros-texto-valor-mb-esquerda campo-texto-cor-3"
                                        type="text"
                                        maxLength="255"
                                        value={filtroValorMinimo}
                                        onChange={e => setFiltroValorMinimo(e.target.value)}
                                    />
                                </div>
                                
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <label className="filtros-label-mb-esquerda fonte-cor-1 campo-texto-cor-3">R$</label>
                                    <Form.Control
                                        name="VALOR_MAX"
                                        className="filtros-texto-valor-mb-esquerda campo-texto-cor-3"
                                        type="text"
                                        maxLength="255"
                                        value={filtroValorMaximo}
                                        onChange={e => setFiltroValorMaximo(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-n0simcards","imagem-filtro-n0simcards"); ColetaClickstream(1298) /*fatPlanosTabelaDropFiltrosNumeroDeSIMcards*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Número de SIM cards </Form.Label>
                        <img id={"imagem-filtro-n0simcards"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-n0simcards"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="N0SIMCARDS_MIN"
                                        className="filtros-texto-valor-mb-texto campo-texto-cor-3"
                                        type="text"
                                        maxLength="255"
                                        value={filtroN0SimcardsMinimo}
                                        onChange={e => setFiltroN0SimcardsMinimo(e.target.value)}
                                    />
                                    {/* <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label> */}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="N0SIMCARDS_MAX"
                                        className="filtros-texto-valor-mb-texto campo-texto-cor-3"
                                        type="text"
                                        maxLength="255"
                                        value={filtroN0SimcardsMaximo}
                                        onChange={e => setFiltroN0SimcardsMaximo(e.target.value)}
                                    />
                                    {/* <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}

export default Filtros;
