/*eslint-disable*/
import emailValidator from "email-validator"; /*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Button,
  Carousel,
  Col,
  Form,
  FormControl,
  Image,
  InputGroup,
} from "react-bootstrap";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";
import logo from "../../icones/iconesVersaoClara/logo_veye_novo_escuro.svg";
import olhoDesativo from "../../icones/iconesVersaoEscura/icone-olho-desligado-cinza.svg";
import olhoAtivo from "../../icones/iconesVersaoEscura/icone-olho-ligado-cinza.svg";
import logoVirtueyes from "../../icones/iconesVersaoEscura/logo_virtueyes_branco.svg";
import imagem1 from "../../icones/imagem_login.jpg";
import decryptJWT from "../../utils/decryptJWT";
import MensagemErro from "../componentesModais/mensagens/mensagemErro";
import ColetaClickstream from "../../components/clickstreamAnalise/clickstreamColeta.js";
import SpinerVeye from "../../components/spinnerVeye/spinnerVeye.js";
import { login } from "../../services/auth";
import { logoVeye } from "../../utils/alternanciaIcones";
import { colunasFaturas, colunasSimcard } from "../../utils/colunas";
import { useMediaQuery } from "react-responsive";
import RedefinirSenha from "./RedefinirSenha/index.jsx";
import {
  alterarColunasSimcard,
  cadastrarColunasSimcard,
  getColunasFaturas,
  getColunasSimcard,
  getEmailRecuperacao,
  getInformacoesAdicionaisCustomizadasClientes,
  getListaParceiros,
  postServicosDadosLogin,
  tokenRecuperacaoOk,
} from "./requisicoes";

import "../../css/login/login.css";

let colunasAux = [];
let colunasFaturasAux = [];

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function TelaLogin() {
  const [icons, setIcons] = useState(true);
  const [mostrarEsconder, setMostrarEsconder] = useState("password");
  const [carregando, setCarregando] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [spinAtivo, setSpinAtivo] = useState(false);
  const [emailRecuperarSenha, setEmailRecuperarSenha] = useState("");
  const [mostrarRecuperarSenha, setMostrarRecuperarSenha] = useState("padrao");
  const [emailUrl, setEmailUrl] = useState("");
  const [tokenUrl, setTokenUrl] = useState("");
  const [mostrarEmailSenha, setMostrarEmailSenha] = useState(true);
  const [revelarMensagemTokenInvalido, setRevelarMensagemTokenInvalido] =
    useState(false);
  const [parceiroData, setParceiroData] = useState();
  const [parceiro, setParceiro] = useState("Veye");
  const [logoParceiro, setLogoParceiro] = useState(logo);
  const [primeiroAcesso, setPrimeiroAcesso] = useState(false);

  const isNovaSenha = mostrarRecuperarSenha === "novaSenha";
  const isSenhaRedefinida = mostrarRecuperarSenha === "senha_redefinida";
  const isParceiro = parceiro !== "Veye";

  const isMobile = useMediaQuery({ maxWidth: 829 });
  const history = useHistory();

  //UseEffect que Verifica o hostname do URL
  useEffect(() => {
    let parceiroData;
    getListaParceiros(window.location.host).then((response) => {
      parceiroData = response;
      if (parceiroData.dados) {
        setParceiroData(parceiroData.dados);
        setParceiro(parceiroData.dados.NomeDoParceiro);
        setLogoParceiro(parceiroData.dados.Logo);
        localStorage.setItem("fl_chat", parceiroData.dados.ChatBot);
      } else {
        setParceiro("Veye");
      }
      setCarregando(true);
    });
  }, []);

  async function redefinirSenhaEffect() {
    if (window.location.href.indexOf("token") != -1) {
      let verificaUrl = window.location.href.split("/");
      let verificaUrl2 = verificaUrl[3].split("&");
      let verificaUrlToken = verificaUrl2[0].split(":");
      let verificaUrlEmail = verificaUrl2[1].split(":");
      setTokenUrl(verificaUrlToken[1]);
      setEmailUrl(verificaUrlEmail[1]);
      const validacaoTokenEmail = await tokenRecuperacaoOk(
        verificaUrlToken[1],
        verificaUrlEmail[1]
      );
      if (validacaoTokenEmail === false) {
        setRevelarMensagemTokenInvalido(true);
      } else {
        setMostrarRecuperarSenha("novaSenha");
        setMostrarEmailSenha(false);
      }
    }
  }

  useEffect(() => {
    redefinirSenhaEffect();
  }, []);

  async function handlerConsultarColunasPersonalizadas() {
    await getInformacoesAdicionaisCustomizadasClientes().then(function (
      response
    ) {
      colunasSimcard[40].nome = response.data.dados[0].TITULO;
      colunasSimcard[41].nome = response.data.dados[1].TITULO;
      colunasSimcard[42].nome = response.data.dados[2].TITULO;
    });
  }

  async function efetuarLogin() {
    if (localStorage.hasOwnProperty("keyOperadora")) {
      localStorage.removeItem("keyOperadora");
    }

    if (localStorage.hasOwnProperty("keyStatusContrato")) {
      localStorage.removeItem("keyStatusContrato");
    }

    setLoginError(false);

    if (!email || !password) {
      setLoginError(false);

      return;
    }

    setSpinAtivo(true);
    grecaptcha.enterprise.ready(async () => {
      const chavePublica = "6Ldfx5cpAAAAABK8SEa_sHyuDN_7hMOjV0CPtQ6A"; // OBS: quando alterar aqui tem que alterar em /public/index.html também

      const token = await grecaptcha.enterprise.execute(chavePublica, {
        action: "login",
      });

      await postServicosDadosLogin({ email, password, token })
        .then(async function (response) {
          const { dados } = response.data;

          login(dados);

          if (dados.usuario.fl_padrao_senha) {
            setMostrarRecuperarSenha("novaSenha");
            setPrimeiroAcesso(true);
            setMostrarEmailSenha(false);
            setSpinAtivo(false);

            return;
          }

          await history.push("/veye", {
            permissao: dados.permissao,
          });

          localStorage.setItem(
            "codigoClienteErpAntigo",
            dados.usuario.codigoClienteErpAntigo
          );
          localStorage.setItem(
            "clientePrimario",
            decryptJWT("clientePrimario")
          );
          localStorage.setItem(
            "clienteOriginal",
            decryptJWT("clienteOriginal")
          );

          if (parceiro === "Veye") {
            localStorage.setItem("fl_chat", dados.usuario.fl_chat);
          }

          await handleAtualizarColunasSimcard();
          await handleAtualizarColunasFaturas();
          await atualizarColunasMongo();
          await cadastroColunasMongo();
          await handlerConsultarColunasPersonalizadas();

          // if (localStorage.getItem("clientePrimario")) {
          //   window.location.reload();
          // }
        })
        .catch(function () {
          setSpinAtivo(false);
          setLoginError(true);
        });
    });
  }

  function removerColunasDuplicadasConectividade(colunas) {
    let arrayNovo = [];
    colunas.forEach((item) => {
      let duplicados =
        arrayNovo.findIndex((itemDuplicado) => {
          return item.id == itemDuplicado.id;
        }) > -1;

      if (duplicados === false) {
        arrayNovo.push(item);
        colunasAux = arrayNovo;
      }
    });
  }

  function removerColunasDuplicadasFaturas(colunas) {
    let arrayNovo = [];
    colunas.forEach((item) => {
      let duplicados =
        arrayNovo.findIndex((itemDuplicado) => {
          return item.id == itemDuplicado.id;
        }) > -1;

      if (duplicados === false) {
        arrayNovo.push(item);
        colunasFaturasAux = arrayNovo;
      }
    });
  }

  async function atualizarColunasMongo() {
    removerColunasDuplicadasConectividade(colunasAux);
    removerColunasDuplicadasFaturas(colunasFaturasAux);

    if (colunasAux && colunasFaturasAux) {
      ColetaClickstream(1439); // login
      await alterarColunasSimcard({ email, colunasAux, colunasFaturasAux });
    }
  }

  async function handleAtualizarColunasSimcard() {
    const usuarioColunas = await getColunasSimcard(email);

    const dadosColunasSimcard = usuarioColunas.data.dados.colunas;

    if (dadosColunasSimcard) {
      //POSTO ESSE IF POR CONTA DO TEMPO DE EXECUÇÃO QUE CRASHAVA O SISTEMA NO PRIMEIRO LOGIN DO USUÁRIO CRIADO PELO MENU VIRTUEYES.
      colunasSimcard.map((colunaLogin, i) => {
        const found = dadosColunasSimcard.some((el) => el.id == colunaLogin.id);
        if (!found) {
          dadosColunasSimcard.splice(i, 0, colunaLogin);
        }
        dadosColunasSimcard.map((coluna) => {
          if (colunaLogin.id == coluna.id) {
            if (colunaLogin.nome != coluna.nome) {
              coluna.nome = colunaLogin.nome;
            }
            let colunaLoginIndex = colunasSimcard.indexOf(colunaLogin);
            let colunaIndex = dadosColunasSimcard.indexOf(coluna);
            if (colunaLoginIndex != colunaIndex) {
              dadosColunasSimcard.splice(colunaIndex, 1);
              dadosColunasSimcard.splice(colunaLoginIndex, 0, coluna);
            }
            //ATUALIZA O VALOR DE nivel_acesso
            coluna.nivel_acesso = colunaLogin.nivel_acesso;
          }
        });
        let posicaoNomeIncorreto;
        const colunasTemp = JSON.parse(JSON.stringify(dadosColunasSimcard));
        colunasTemp.splice(40, 3);
        const foundNome = colunasTemp.some((el, ei) => {
          posicaoNomeIncorreto = ei;
          return el.nome == colunaLogin.nome;
        });

        if (!foundNome) {
          dadosColunasSimcard[posicaoNomeIncorreto].nome = colunaLogin.nome;
        }
      });

      dadosColunasSimcard.map((coluna, i) => {
        const found = colunasSimcard.some((el) => el.id == coluna.id);
        if (!found) {
          dadosColunasSimcard.splice(i, 1);
        }
      });

      colunasAux = dadosColunasSimcard;
      organizarColunasSimcard(colunasAux);
    } else {
    }
  }

  function organizarColunasSimcard(arrayColunas) {
    colunasSimcard.map((colunasMock) => {
      arrayColunas.map((colunaLogin) => {
        if (
          colunaLogin.id === colunasMock.id &&
          colunaLogin.ordem !== colunasMock.ordem
        ) {
          colunasMock.ordem = colunaLogin.ordem;
        }
      });
    });
  }

  async function handleAtualizarColunasFaturas() {
    const usuarioColunas = await getColunasFaturas(email);

    const dadosColunasFaturas = usuarioColunas?.data?.dados?.colunasFaturas;

    if (dadosColunasFaturas) {
      colunasFaturas.map((colunaLogin, i) => {
        const found = dadosColunasFaturas.some((el) => el.id == colunaLogin.id);
        if (!found) {
          dadosColunasFaturas.splice(i, 0, colunaLogin);
        }
        dadosColunasFaturas.map((coluna) => {
          if (colunaLogin.id == coluna.id) {
            if (colunaLogin.nome != coluna.nome) {
              coluna.nome = colunaLogin.nome;
            }
            let colunaLoginIndex = colunasFaturas.indexOf(colunaLogin);
            let colunaIndex = dadosColunasFaturas.indexOf(coluna);
            if (colunaLoginIndex != colunaIndex) {
              dadosColunasFaturas.splice(colunaIndex, 1);
              dadosColunasFaturas.splice(colunaLoginIndex, 0, coluna);
            }
          }
        });
      });

      dadosColunasFaturas.map((coluna, i) => {
        const found = colunasFaturas.some((el) => el.id == coluna.id);
        if (!found) {
          dadosColunasFaturas.splice(i, 1);
        }
      });

      colunasFaturasAux = dadosColunasFaturas;
      organizarColunasFaturas(colunasFaturasAux);
    } else {
    }
  }

  function organizarColunasFaturas(arrayColunas) {
    colunasFaturas.map((colunasMock) => {
      arrayColunas.map((colunaLogin) => {
        if (
          colunaLogin.id === colunasMock.id &&
          colunaLogin.ordem !== colunasMock.ordem
        ) {
          colunasMock.ordem = colunaLogin.ordem;
        }
      });
    });
  }

  function validarEmail(email) {
    let validade = emailValidator.validate(email);
    setEmailError(!validade);

    return validade;
  }

  async function enviarEmail() {
    setSpinAtivo(true);
    await getEmailRecuperacao(emailRecuperarSenha);

    setEmailError(false);
    setSpinAtivo(false);
    setMostrarRecuperarSenha("confirmacao_email");

    setEmailRecuperarSenha("");
  }

  async function cadastroColunasMongo() {
    await cadastrarColunasSimcard({ email, colunasSimcard, colunasFaturas });
  }

  function iconVizualizar() {
    if (mostrarEsconder === "password") setMostrarEsconder("text");
    return <img alt="olhoAtivo" className="olhoAtivo" src={olhoAtivo} />;
  }

  function iconNaoVizualizar() {
    if (mostrarEsconder === "text") setMostrarEsconder("password");
    return (
      <img alt="olhoDesativo" className="olhoDesativo" src={olhoDesativo} />
    );
  }

  function botaoPressionado(event) {
    if (event.key === "Enter") efetuarLogin();
  }

  function IrParaLogin() {
    history.push("/login");
    setMostrarRecuperarSenha("padrao");
    setMostrarEmailSenha(true);
  }

  function handleMensagemErro() {
    if (revelarMensagemTokenInvalido === false) {
      setRevelarMensagemTokenInvalido(true);
    } else {
      setRevelarMensagemTokenInvalido(false);
    }
  }

  return (
    <>
      {carregando ? (
        <div id="WrapperTelaLogin" className="WrapperTelaLogin">
          {revelarMensagemTokenInvalido && (
            <div className="componente-modal-overlay">
              <div className="componente-modal">
                <MensagemErro
                  handleMensagemErro={handleMensagemErro}
                  mensagemTitulo={"Token inválido!"}
                  mensagemBotao={"Retornar"}
                />
              </div>
            </div>
          )}
          <div
            id="CorpoTelaLogin"
            className={
              isNovaSenha ? "CorpoTelaLoginNovaSenha" : "CorpoTelaLogin"
            }
          >
            <Form.Row
              className={
                isNovaSenha || isSenhaRedefinida
                  ? "container-form-row-cabecalho-nova-senha"
                  : "container-form-row-cabecalho"
              }
            >
              <Col>
                <div
                  className={
                    isNovaSenha || isSenhaRedefinida
                      ? "container-principal-nova-senha"
                      : "container-principal"
                  }
                >
                  <div
                    className={
                      isNovaSenha || isSenhaRedefinida
                        ? "container-logo-nova-senha"
                        : "container-logo"
                    }
                  >
                    {isParceiro ? (
                      <Image
                        className={
                          isNovaSenha || isSenhaRedefinida
                            ? "logoVeye-nova-senha"
                            : "logoParceiro"
                        }
                        src={logoParceiro}
                        alt="Parceiro"
                      />
                    ) : (
                      <Image
                        className={
                          isNovaSenha || isSenhaRedefinida
                            ? "logoVeye-nova-senha"
                            : "logoVeye"
                        }
                        src={logoVeye()}
                        alt="V.eye"
                      />
                    )}
                    {!isParceiro && (
                      <div
                        className={
                          isNovaSenha || isSenhaRedefinida
                            ? "texto-logo-nova-senha"
                            : "texto-logo"
                        }
                      >
                        Gestão inteligente de conectividade
                      </div>
                    )}
                  </div>
                  {mostrarEmailSenha === true && (
                    <div className="container-campo-email-senha">
                      <div className="container-email">
                        <Form.Control
                          className="campo-email"
                          type="email"
                          placeholder="E-mail"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          onKeyDown={(e) => botaoPressionado(e)}
                          name="campoEmail"
                          id="campoEmail"
                        />
                        {loginError && <TextoDadosInvalidos />}
                      </div>
                      <div className="container-campo-senha">
                        <InputGroup className="container-senha">
                          <FormControl
                            className="campo-senha"
                            placeholder="Senha"
                            type={mostrarEsconder}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            onKeyDown={(e) => botaoPressionado(e)}
                            name="campoSenha"
                            id="campoSenha"
                            maxLength="20"
                          />
                          <Button
                            variant=""
                            className="verSenha"
                            onClick={() => setIcons(!icons)}
                          >
                            {icons ? iconNaoVizualizar() : iconVizualizar()}
                          </Button>
                        </InputGroup>
                        <div className="container-recuperar-senha">
                          <Button
                            style={{
                              color: isParceiro
                                ? parceiroData?.cor_botoes
                                : "#36D896",
                            }}
                            className="LinkRecuperarSenha"
                            onClick={() =>
                              setMostrarRecuperarSenha("recuperar_senha")
                            }
                          >
                            Esqueci minha senha
                          </Button>
                        </div>
                      </div>

                      <div className="container-entrar">
                        <Button
                          style={{
                            backgroundColor: isParceiro
                              ? parceiroData?.cor_botoes
                              : "#36D896",
                          }}
                          className="btnEntrar"
                          variant="outline-light"
                          onClick={efetuarLogin}
                          onKeyDown={(e) => botaoPressionado(e)}
                        >
                          Entrar
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Form.Row>
            <Form.Row className="container-form-row-imagens">
              <Col className="col-imagens">
                <div className="container-principal-imagens">
                  <div className="container-carousel">
                    <Carousel
                      bsPrefix="carousel"
                      controls={false}
                      indicators={false}
                      interval={2000}
                    >
                      <Carousel.Item className="container-itens">
                        <Image
                          style={{ height: "1060px" }}
                          width={"1500px"}
                          className={"imagem-carousel"}
                          src={
                            parceiroData?.usar_imagem_fundo_padrao === 1
                              ? imagem1
                              : isParceiro
                              ? parceiroData?.link_imagem_fundo
                              : imagem1
                          }
                        />
                        <Carousel.Caption></Carousel.Caption>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                  <div
                    style={
                      isMobile
                        ? { backgroundColor: parceiroData?.cor_fundo }
                        : {}
                    }
                    className={
                      isNovaSenha || isSenhaRedefinida
                        ? "container-fundo-verde-nova-senha"
                        : "container-fundo-verde"
                    }
                  >
                    <Carousel
                      bsPrefix="carousel"
                      controls={false}
                      indicators={false}
                      interval={1}
                    >
                      <Carousel.Item>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1194.557"
                          height="1065"
                          viewBox="0 0 1194.557 1065"
                        >
                          <path
                            fill={
                              isParceiro
                                ? parceiroData?.cor_fundo
                                : "rgb(54,216,150)"
                            }
                            fill-rule="evenodd"
                            d="M128.45876289 458.38917526C222.88144329 136.1443299 282.34976569 0 282.34976569 0h912.20693534v1065H0s34.03608247-284.36597938 128.45876289-606.61082474z"
                          />
                        </svg>

                        <Carousel.Caption className="conteudo-carousel">
                          {mostrarRecuperarSenha === "padrao" && (
                            <div className="container-textos">
                              {!isParceiro && (
                                <Image
                                  className="logoVirtueyes"
                                  src={logoVirtueyes}
                                  alt=""
                                />
                              )}
                              <div className="container-texto-1">
                                <p
                                  style={
                                    isParceiro
                                      ? { color: parceiroData?.cor_letra }
                                      : {}
                                  }
                                  className="texto-1"
                                >
                                  Bem-vindo à Gestão Inteligente de
                                  Conectividade
                                </p>
                              </div>
                              {parceiro === "Veye" ? (
                                <>
                                  <div className="container-texto-2">
                                    <p className="texto-3">
                                      Com a plataforma V.eye você eleva sua
                                    </p>
                                    <p className="texto-3">
                                      experiência em gestão de faturas,
                                    </p>
                                    <p className="texto-3">
                                      parque de SIMCards e configuração
                                    </p>
                                    <p className="texto-3">
                                      de equipamentos via SMS.
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                          {mostrarRecuperarSenha === "recuperar_senha" && (
                            <div className="container-textos container-textos-recuperar">
                              <div className="container-texto-recuperar-senha">
                                <p className="texto-recuperar-senha">
                                  Esqueceu sua senha?
                                </p>
                              </div>
                              <div className=" container-texto-2 container-texto-2-recuperar">
                                <span
                                  style={
                                    isParceiro
                                      ? { color: parceiroData?.cor_letra }
                                      : {}
                                  }
                                  className="texto-3"
                                >
                                  Digite seu e-mail abaixo e enviaremos um link
                                  para redefinir sua senha.
                                </span>
                              </div>

                              <CampoEmailRecuperarSenha
                                parceiro={parceiro}
                                setEmailRecuperarSenha={setEmailRecuperarSenha}
                                parceiroData={parceiroData}
                                emailRecuperarSenha={emailRecuperarSenha}
                                emailError={emailError}
                                validarEmail={validarEmail}
                                enviarEmail={enviarEmail}
                              />
                            </div>
                          )}
                          {mostrarRecuperarSenha === "confirmacao_email" && (
                            <MensagemConfirmacaoEmail
                              setMostrarRecuperarSenha={
                                setMostrarRecuperarSenha
                              }
                              classNames={{
                                container:
                                  "container-textos container-verifique-email",
                                divTitulo: "container-texto-recuperar-senha",
                                titulo: "texto-confimacao-texto-1",
                                divDescricao: "container-confirmacao-texto-2",
                                descricao: "texto-3",
                                botao: "btnVoltarHomepage",
                              }}
                            />
                          )}
                          {isNovaSenha && (
                            <RedefinirSenha
                              primeiroAcesso={primeiroAcesso}
                              setMostrarRecuperarSenha={
                                setMostrarRecuperarSenha
                              }
                              tokenUrl={tokenUrl}
                              emailUrl={emailUrl}
                            />
                          )}
                          {isSenhaRedefinida && (
                            <div className="container-textos container-textos-redefinida">
                              <div className="container-texto-1">
                                <p className="texto-senha-redefinida">
                                  Senha definida com sucesso!
                                </p>
                              </div>
                              <div className="container-texto-2 container-texto-2-redefinida">
                                <div className="container-2-texto-2-redefinida">
                                  <span className="texto-3">
                                    Você já pode usar sua nova senha para
                                    efetuar seu login.
                                  </span>
                                </div>
                                <Button
                                  id="SenhaRdefinidaSucesso"
                                  className="btnIrLogin"
                                  variant="outline-light"
                                  onClick={() => IrParaLogin()}
                                >
                                  Ir para o login
                                </Button>
                              </div>
                            </div>
                          )}
                        </Carousel.Caption>
                      </Carousel.Item>
                    </Carousel>
                    <div
                      className={
                        isNovaSenha || isSenhaRedefinida
                          ? "container-nova-senha-fixa"
                          : "container-nova-senha-fixa-sem-tamanho"
                      }
                    >
                      <Image
                        className="imagem-carousel-nova-senha-fixa"
                        src={imagem1}
                      />
                    </div>
                  </div>
                  <div
                    style={
                      isParceiro
                        ? { backgroundColor: parceiroData?.cor_fundo }
                        : {}
                    }
                    className="fundo-verde-2"
                  />
                </div>
              </Col>
            </Form.Row>
            {spinAtivo && (
              <div className="componente-spinner-overlay">
                <div className="componente-spinner-container">
                  <SpinerVeye />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Lottie name="animacao" options={defaultOptions} />
      )}
    </>
  );
}

function TextoDadosInvalidos() {
  return (
    <h6 className="mensagem-erro-login-vermelho">
      Por favor, digite um e-mail ou uma senha válida
    </h6>
  );
}

function CampoEmailRecuperarSenha({
  parceiro,
  setEmailRecuperarSenha,
  parceiroData,
  emailRecuperarSenha,
  emailError,
  enviarEmail,
  validarEmail,
}) {
  let buttonStyle = {};
  let emailFieldStyle = {};

  if (parceiro !== "Veye") {
    buttonStyle = {
      backgroundColor: parceiroData?.cor_botoes,
      color: parceiroData?.cor_letra,
    };
    emailFieldStyle = {
      backgroundColor: parceiroData?.cor_fundo,
      color: parceiroData?.cor_letra,
    };
  }

  return (
    <>
      <Form.Control
        style={emailFieldStyle}
        className="campo-email-recuperar-senha"
        type="email"
        placeholder="Digite seu e-mail"
        onChange={(e) => {
          const value = e.target.value;
          setEmailRecuperarSenha(value);
          validarEmail(value);
        }}
      />

      {emailError && (
        <h6 className="mensagem-erro-recuperar-senha">
          Formato de e-mail inválido.
        </h6>
      )}

      {emailError || emailRecuperarSenha === "" ? (
        <Button
          disabled
          style={buttonStyle}
          className="btnEnviar"
          variant="outline-light"
        >
          Enviar
        </Button>
      ) : (
        <Button
          style={buttonStyle}
          className="btnEnviar"
          variant="outline-light"
          onClick={() => enviarEmail()}
        >
          Enviar
        </Button>
      )}
    </>
  );
}

function MensagemConfirmacaoEmail({ classNames, setMostrarRecuperarSenha }) {
  return (
    <div className={classNames.container}>
      <div className={classNames.divTitulo}>
        <p className={classNames.titulo}> Link enviado. </p>
      </div>
      <div className={classNames.divDescricao}>
        <span className={classNames.descricao}>
          Caso este e-mail esteja cadastrado em nossa base de dados, será
          enviado um link de recuperação. Verifique sua caixa de e-mail.
        </span>
      </div>

      <hr className="linha-divisoria-MensagemConfirmacaoEmail" />
      <span
        className={`${classNames.descricao} text-rodape-MensagemConfirmacaoEmail`}
      >
        Caso não tenha recebido o e-mail com o link, verifique sua caixa de
        spam.
      </span>

      {classNames.divBotao ? (
        <div className={classNames.divBotao}>
          <BotaoVoltar
            classNameBtn={classNames.botao}
            setMostrarRecuperarSenha={setMostrarRecuperarSenha}
          />
        </div>
      ) : (
        <BotaoVoltar
          classNameBtn={classNames.botao}
          setMostrarRecuperarSenha={setMostrarRecuperarSenha}
        />
      )}
    </div>
  );
}

function BotaoVoltar({ classNameBtn, setMostrarRecuperarSenha }) {
  return (
    <Button
      className={classNameBtn}
      variant="outline-light"
      onClick={() => setMostrarRecuperarSenha("padrao")}
    >
      Voltar a página inicial
    </Button>
  );
}
