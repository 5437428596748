//BIBLIOTECAS
import React,{useState, useEffect} from 'react';
import { Form, Col } from 'react-bootstrap';/*eslint-disable*/
import {api} from '../../conexoes/api';/*eslint-disable*/
import { Label, Cell, Tooltip, Pie, PieChart } from 'recharts';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
//ICONES
import { elipseVerde } from '../../utils/alternanciaIcones';
import {formatarMilhar} from '../../utils/formatarMilhar'
import SpinerVeye from '../spinnerVeye/spinnerVeye.js';

export default function DetalhamentoFinanceiro(props) {
    //HOOKS
    const [itens, setItens] = useState('')
    const localTema = localStorage.getItem('tema');
    const [legendaGrafico, setLegendaGrafico] = useState();
    const [valorTotalGrafico, setValorTotalGrafico] = useState(0);
    const [valoresGrafico, setValoresGrafico] = useState();

   //SPINNER
   const [spinAtivo, setSpinAtivo] = useState(true);

    const arrayCores = [
        '#36D896', '#FDCB62', '#FC5F0E', '#CD2D37', '#1F8EFA','#FF5F58', '#640EDF', '#1FDFD9', '#B947DB', '#F02E7A',
        '#36D896', '#FDCB62', '#FC5F0E', '#CD2D37', '#1F8EFA','#FF5F58', '#640EDF', '#1FDFD9', '#B947DB', '#F02E7A',
        '#36D896', '#FDCB62', '#FC5F0E', '#CD2D37', '#1F8EFA','#FF5F58', '#640EDF', '#1FDFD9', '#B947DB', '#F02E7A'
    ];

    useEffect(() => {
        async function handleCarregarSimcards(){
            setSpinAtivo(true) 
            let arrayValoresGrafico = [];
            let porcentagem = [];
            let detalhamentoGrafico=[]

            const { data } = await api.get('/m1/consultar/financeiro?fatura='+props.fatura.FATURA+"&n0_cliente="+props.fatura.CLIENTE);
            setSpinAtivo(false) 
            setItens(data.dados);

            detalhamentoGrafico = data.dados

            // calcula o valor total dos valores vindo do banco
            let calcuValorTotal = props.fatura.VALOR;
            let valorTotalSimcard = calcuValorTotal;

            detalhamentoGrafico.forEach((item)=> valorTotalSimcard -= item.VALOR_TOTAL)

            const qtdSims = props.fatura.QUANTIDADE
            // FAZER CÁLCULO ABAIXO PARA CORRIGIR NA TABELA "Resumo da fatura"
            detalhamentoGrafico.unshift({
                'SERVICO': 'Simcard', 
                'QUANTIDADE': qtdSims,
                'VALOR_UNITARIO': valorTotalSimcard / qtdSims,
                'DESCONTO': props.fatura.DESCONTO,
                'VALOR_TOTAL': valorTotalSimcard // props.fatura.VALOR
            })
            
            detalhamentoGrafico.forEach((item)=>{
                arrayValoresGrafico.push({name: item.SERVICO, value: item.VALOR_TOTAL})
            },)

            setValoresGrafico(arrayValoresGrafico);

            setValorTotalGrafico(calcuValorTotal);

            // mapeia os valores vindo e divide pelo valor total e calcula a % de cada valor dentro do valor total para criar o grafico de %
            detalhamentoGrafico.map((item)=>{
                porcentagem.push({percentual:((item.VALOR_TOTAL / calcuValorTotal) * 100).toFixed(2), cor: "background-color:" + arrayCores[porcentagem.length], nome: item.SERVICO})
            })

            // MAP QUE MONTA A LEGENDA
            let objetoGrupos = porcentagem.map((item) => {
                return (
                    <Form.Row className="form-row-sem-margem">
                        <Col className="form-row-sem-margem">
                            <div className="grupo-legenda-percentual-gastos-1">
                                <div className="coluna-legenda-percentual-gastos-1">
                                    <div className="esfera-legenda-percentual-gastos" Style={item.cor}/>
                                </div>
                                <div className="coluna-legenda-percentual-gastos-2">
                                    <span className="nome-legenda-percentual-gastos fonte-cor-1">
                                        {item.nome}
                                    </span>
                                </div>
                                <div className="coluna-legenda-percentual-gastos-3">
                                    <span className="porcentagem-legenda-percentual-gastos fonte-cor-1">
                                        {item.percentual}%
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                );
            })
            setLegendaGrafico(objetoGrupos)
        }
        handleCarregarSimcards();
    }, [])
    
    //FUNÇÃO QUE MUDA A COR DO TEXTO DO GRAFICO, O COMPONENTE NAO ACEITA CLASSNAME ENTAO TEM QUE SER FEITO ATRAVEZ DE UMA FUNÇÃO.
    function CustomLabel({viewBox, total, valorSoma}){
        const {cx, cy} = viewBox;
        return (
            <>
                <text x={cx} y={70} fill={localTema =='claro'?"#000000":'#FFFFFF'} textAnchor="middle" dominantBaseline="central">
                    <tspan className="texto-total-grafico" alignmentBaseline="middle">{total}</tspan>
                </text>
                <text className="container-texto-soma-total" x={cx} y={90} fill={localTema =='claro'?"#000000":'#FFFFFF'} textAnchor="middle" dominantBaseline="central">
                    <tspan className="texto-soma-total">{"R$ " + valorSoma}</tspan>
                </text>
            </>
        )
    } 


    const colunas = [
        {
            dataField: 'SERVICO',
            text: 'Serviços',
            sort: true,
            headerClasses: 'tabela-coluna-primeira-header nao-selecionavel fundo-cor-7',
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent',width: '130px',}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'QUANTIDADE',
            text: 'Quantidade',
            sort: true,
            headerClasses: 'tabela-coluna-primeira-header nao-selecionavel fundo-cor-7',
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent', width: '130px'}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1 texto-td-tabela-centralizado">{formatarMilhar(cell)}</p>;
            }
        },
        {
            dataField: 'VALOR_UNITARIO',
            headerClasses: 'nao-selecionavel fundo-cor-7',
            text: 'Valor',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent', width: '130px'}
            },
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-financeiro"> {(!valor || isNaN(valor)) ? <><p className="valor-rs-financeiro">R$</p> <p className="fonte-cor-1 valor-financeiro2"> 0.00 </p></> : <><p className="valor-rs-financeiro">R$</p> <p className="fonte-cor-1 valor-financeiro2"> {formatarMilhar(valor)}</p></>} </p>;
            }
        },
        {
            dataField: 'DESCONTO',
            headerClasses: 'nao-selecionavel fundo-cor-7',
            text: 'Desconto',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent', width: '130px'}
            },
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-financeiro"> {(!valor || isNaN(valor)) ? <><p className="valor-rs-financeiro">R$</p> <p className="fonte-cor-1 valor-financeiro2"> 0.00 </p></> : <><p className="valor-rs-financeiro">R$</p> <p className="fonte-cor-1 valor-financeiro2"> {formatarMilhar(valor)}</p></>} </p>;
            }
        },
        {
            dataField: 'VALOR_TOTAL',
            headerClasses: 'nao-selecionavel fundo-cor-7',
            text: 'Valor total',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {background: 'transparent', width: '128px'}
            },
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-financeiro"> {(!valor || isNaN(valor)) ? <><p className="valor-rs-financeiro">R$</p> <p className="fonte-cor-1 valor-financeiro2"> 0.00 </p></> : <><p className="valor-rs-financeiro">R$</p> <p className="fonte-cor-1 valor-financeiro2"> {formatarMilhar(valor)}</p></>} </p>;
            }
        },
    ]

    const TooltipGraficoPizzaConsumo = ({ active, payload }) => {
        if (active && payload!==null && payload.length > 0) {
            const getLegendaSimcards = () => ((itens[0]?itens[0].QUANTIDADE:0) + ' SIM cards');
            
            const name = payload[0].name;
            const auxLengenda = (name === 'Simcard') ? getLegendaSimcards() : name;

            return (
                <div className="container-tooltip-grafico-pizza">
                    <p className="label-tooltip-grafico-pizza">{auxLengenda}</p>
                </div>
            );
        }
        return null;
    }
    return(
        <>
          { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="triangulo-navegacao-financeiro campo-select-triangulo-cor-2"></div>
            <div className="container-itens-expandido">
                <div className="resumo-fatura fundo-cor-7">
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                            <img className="icone-label-filtro-financeiro" src={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela-financeiro"> Resumo da fatura </div>
                            </Form.Label>
                        </Col>
                    </Form.Row>
                    <div className="container-conteudo-resumo-faturas">
                        <div>
                        <BootstrapTable
                            classes="tabela-transparente-header-fixo"
                            condensed={true}
                            keyField='FATURA'
                            data={itens}
                            columns={colunas}
                            bootstrap4={true}
                            noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                            bordered={false} 
                            headerClasses="fundo-cor-7" 
                        />
                        </div>  
                    </div>
                </div>
                <div className="percentual-gastos fundo-cor-7">
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                            <img className="icone-label-filtro-financeiro" src={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela-financeiro"> Percentual de gastos </div>
                            </Form.Label>
                        </Col>
                    </Form.Row> 
                    <Form.Row className="form-row-sem-margem">
                        <Col className="form-row-sem-margem">
                            <div className="container-percentual-gastos">
                                <div className="container-conteudo-Percentual-gastos">
                                    {valoresGrafico &&
                                        <PieChart width={200} height={160}  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                            <Pie
                                                data={valoresGrafico} 
                                                cx={90}
                                                cy={80} 
                                                innerRadius={55}
                                                outerRadius={77} 
                                                fill="#505050"
                                                paddingAngle={0}
                                                stroke={0}
                                            >
                                            <Label content={<CustomLabel total={'Total'} valorSoma={formatarMilhar(valorTotalGrafico)}/>} value="Total" position="centerBottom" />
                                            {
                                                valoresGrafico.map((entry, index) => <Cell fill={arrayCores[index % arrayCores.length]}/>)
                                            }
                                            </Pie>
                                            <Tooltip content={<TooltipGraficoPizzaConsumo/>}/>
                                        </PieChart>
                                    }
                                </div>
                                <div className="container-legenda-percentual-gastos">
                                    {legendaGrafico}
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                </div>
            </div>
        </>
    )
}