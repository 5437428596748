import styled from "styled-components";

export const DetalhamentoProvisaoWrapper = styled.div`
  td {
    font-family: RobotoRegular !important;
    font-size: 16px !important;
    line-height: 1.5em !important;
    color: ${({ theme }) => theme.cor9}!important;
  }

  .label-quantity {
    display: flex;
    justify-content: flex-end;
    color: ${({ theme }) => theme.cor41}!important;
  }
`;

export const ContainerTable = styled.div`
  max-height: 230px;
  min-height: 95px;
  overflow-y: auto;
  overflow-x: hidden;
`;
