import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createJSONStorage, persist } from "zustand/middleware";

export const useGlobal = create(
  immer(
    persist(
      (set) => ({
        filtros: { vazio: "vazio" },
        busca: "",
        buscaArquivo: {},
        revelarBarraFiltro: false,
        revelarDadosCliente: false,
        separadorLinhaBusca: "espaco",
        exportarRelatorio: undefined,
        mostrarAcao: undefined,
        selecionarParqueInteiro: false,
        removerAcao: undefined,
        setRemoverAcao: (value) =>
          set((state) => {
            state.removerAcao = value;
          }),
        setFiltros: (value) =>
          set((state) => {
            state.filtros = value;
          }),
        setRevelarBarraFiltro: (value) =>
          set((state) => {
            state.revelarBarraFiltro = value;
          }),
        setRevelarDadosCliente: (value) =>
          set((state) => {
            state.revelarDadosCliente = value;
          }),
        setBusca: (value) =>
          set((state) => {
            state.busca = value;
          }),
        setBuscaArquivo: (value) =>
          set((state) => {
            state.buscaArquivo = value;
          }),
        setSeparadorLinhaBusca: (value) =>
          set((state) => {
            state.separadorLinhaBusca = value;
          }),
        setExportarRelatorio: (value) =>
          set((state) => {
            state.exportarRelatorio = value;
          }),
        setSelecionarParqueInteiro: (value) =>
          set((state) => {
            state.selecionarParqueInteiro = value;
          }),
        setMostrarAcao: (value) =>
          set((state) => {
            state.mostrarAcao = value;
          }),
      }),
      {
        name: "geral",
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => ({
          revelarDadosCliente: state.revelarDadosCliente,
        }),
      }
    )
  )
);
