import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Button, Col } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
//COMPONENTES
import Calendario from '../../components/calendario/calendario'
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'


export default function FiltrosVirtueyesCliente(props) {
    const [filtroStatus , setFiltroStatus] = useState('')
    const [filtroCriacaoDataInicio , setFiltroCriacaoDataInicio] = useState('')
    const [filtroCriacaoDataFinal , setFiltroCriacaoDataFinal] = useState('')
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    async function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            if(filtros.STATUS == '0') setFiltroStatus("Ativo")
            if(filtros.STATUS == '1') setFiltroStatus("Bloqueado")

            //verifica se existe algo nos valores de datas
            if(filtros.ATIVACAO_INICIO !='' && filtros.ATIVACAO_INICIO){
                let auxData = new Date(filtros.ATIVACAO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroCriacaoDataInicio(auxData)
            }
            if(filtros.ATIVACAO_FINAL != '' && filtros.ATIVACAO_FINAL){
                let auxData = new Date(filtros.ATIVACAO_FINAL)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroCriacaoDataFinal(auxData)
            }
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1385) /*solicitacaoBloqueioTabelaDropFiltrosFiltrar*/
        if(filtroStatus !== '' && filtroStatus)arrayFiltrosSimcard.push("Status: "+filtroStatus+"?STATUS")
        if(filtroCriacaoDataInicio !== '' && filtroCriacaoDataFinal !== '' && filtroCriacaoDataInicio && filtroCriacaoDataFinal)
            arrayFiltrosSimcard.push("Data: entre "+moment(filtroCriacaoDataInicio).format("DD-MM-YYYY")+" e "+moment(filtroCriacaoDataFinal).format("DD-MM-YYYY")+"?ATIVACAO_INICIO")

        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }
    
    useEffect(() => {
        
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroCriacaoDataInicio=='') verificador.push(1)
            if(filtroCriacaoDataFinal=='') verificador.push(1)
            if(filtroStatus=='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 3) return false
            return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()){
                props.setFoiSelecionado(true);
            }
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroCriacaoDataInicio('')
            setFiltroCriacaoDataFinal('')
            setFiltroStatus('')
            props.setFoiLimpado(false)
        }
        
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroStatus,filtroCriacaoDataFinal,filtroCriacaoDataInicio,props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="drop-acoes-triangulo campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-status", 'imagem-filtro-status'); ColetaClickstream(1383) /*solicitacaoBloqueioTabelaDropFiltrosStatus*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Status </Form.Label>
                        <img id={"imagem-filtro-status"}className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Ativo')}>Ativo
                            <input defaultChecked={handlerCheck("STATUS","0")} type="radio" id="STATUS_ABERTO" name="STATUS" value="0"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Bloqueado')}>Bloqueado
                            <input defaultChecked={handlerCheck("STATUS","1")} type="radio" id="STATUS_PARCIAL" name="STATUS" value="1"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-aquisicao", "imagem-filtro-aquisicao"); ColetaClickstream(1384) /*solicitacaoBloqueioTabelaDropFiltrosData*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data</Form.Label>
                        <img id={"imagem-filtro-aquisicao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-aquisicao"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro-solicitacao-cliente">
                            <Form.Control name="ATIVACAO_INICIO" type="text" value={filtroCriacaoDataInicio? moment(filtroCriacaoDataInicio).format("YYYY-MM-DD hh:mm:ss") : filtroCriacaoDataInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroCriacaoDataInicio} setData={setFiltroCriacaoDataInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro-solicitacao-cliente">
                            <Form.Control name="ATIVACAO_FINAL" type="text" value={filtroCriacaoDataFinal ? moment(filtroCriacaoDataFinal).format("YYYY-MM-DD hh:mm:ss") : filtroCriacaoDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroCriacaoDataFinal} setData={setFiltroCriacaoDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}