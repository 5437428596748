import React from "react";
import { useSelector } from "react-redux";
import { getCoresGraficoMigracaoPlano } from "../cores";
import CardGraficoGenerico from "./CardGenerico";
import { getCicloFormatado } from "./CardGenerico/funcoes";

const nomeOpcao = "Status";

export default function GraficoStatusMigracao({ revelarOpcoesGrafico }) {
  const arrDash = useSelector(
    (state) => state.migracaoPlanoState.dashboard[nomeOpcao]
  );
  const ciclo = getCicloFormatado(arrDash);

  const isUltimos6Ciclos = ciclo === "Últimos 6 ciclos";

  const cores = getCoresGraficoMigracaoPlano(nomeOpcao, isUltimos6Ciclos);

  const configDash = [
    {
      titulo: "Sucesso",
      key: "Sucesso",
      cor: "#33CC99",
    },
    {
      titulo: "Falha",
      key: "Falha",
      cor: cores.vermelho,
    },
    {
      titulo: "Sem migração",
      key: "SemMigracao",
      cor: cores.azul,
    },
  ];

  return (
    <CardGraficoGenerico
      titulo="Status das migrações"
      nomeOpcao={nomeOpcao}
      id={4}
      key={4}
      revelarOpcoesGrafico={revelarOpcoesGrafico}
      configDash={configDash}
    />
  );
}
