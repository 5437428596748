import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
import Calendario from '../calendario/calendario'
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltrosPedidosSms(props) {
    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroStatus, setFiltroStatus] = useState('')
    const [filtroQuantidadeSmsMinimo, setFiltroQuantidadeSmsMinimo] = useState('')
    const [filtroQuantidadeSmsMaximo, setFiltroQuantidadeSmsMaximo] = useState('')
    const [filtroDataInicio, setFiltroDataInicio] = useState('')
    const [filtroDataFinal, setFiltroDataFinal] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    async function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setFiltroStatus(filtros.STATUS)
            setFiltroQuantidadeSmsMinimo(filtros.PACOTES_MIN)
            setFiltroQuantidadeSmsMaximo(filtros.PACOTES_MAX)
            if(filtros.ULTIMA_CONEXAO_INICIO !='' && filtros.ULTIMA_CONEXAO_INICIO){
                let auxData = new Date(filtros.ULTIMA_CONEXAO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataInicio(auxData)
            }
            if(filtros.ULTIMA_CONEXAO_FINAL != '' && filtros.ULTIMA_CONEXAO_FINAL){
                let auxData = new Date(filtros.ULTIMA_CONEXAO_FINAL)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataFinal(auxData)
            }
        }
    }

    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1417) /*pedidoSmsTabelaPedidoSmsDropFiltrosFiltrar*/
        if(filtroStatus !== '')arrayFiltrosSimcard.push("Status: "+filtroStatus+"?STATUS")
        if(filtroQuantidadeSmsMinimo !== '' && filtroQuantidadeSmsMaximo !== '' && filtroQuantidadeSmsMinimo && filtroQuantidadeSmsMaximo)
            arrayFiltrosSimcard.push("Quantidade  de SMS entre: "+filtroQuantidadeSmsMinimo+" e "+filtroQuantidadeSmsMaximo+"?PACOTES_MIN")
        if(filtroDataInicio !== '' && filtroDataFinal !== '' && filtroDataInicio && filtroDataFinal)
            arrayFiltrosSimcard.push("Data entre: "+moment(filtroDataInicio).format("DD-MM-YYYY")+" e "+moment(filtroDataFinal).format("DD-MM-YYYY")+"?ULTIMA_CONEXAO_INICIO")
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroStatus == '') verificador.push(1)
            if(filtroQuantidadeSmsMinimo =='') verificador.push(1)
            if(filtroQuantidadeSmsMaximo =='') verificador.push(1)
            if(filtroDataInicio =='') verificador.push(1)
            if(filtroDataFinal =='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 5) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroStatus('')
            setFiltroQuantidadeSmsMinimo('')
            setFiltroQuantidadeSmsMaximo('')
            setFiltroDataInicio('')
            setFiltroDataFinal('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroStatus, filtroQuantidadeSmsMinimo, filtroQuantidadeSmsMaximo, filtroDataInicio, filtroDataFinal, props.foiLimpado]);

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-quantidade-minimo"){
                setFiltroQuantidadeSmsMinimo(valor)
            }
            if(id === "campo-texto-quantidade-maximo"){
                setFiltroQuantidadeSmsMaximo(valor)
            }
        }
    }
    ColetaClickstream(397) /*pedidoSmsTabelaPedidoSms*/
    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-data","imagem-filtro-data"); ColetaClickstream(1414) /*pedidoSmsTabelaPedidoSmsDropFiltrosDataDoPeriodo*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data do periodo </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-data"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="ULTIMA_CONEXAO_INICIO" type="text" value={filtroDataInicio ? moment(filtroDataInicio).format("YYYY-MM-DD hh:mm:ss") : filtroDataInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataInicio} setData={setFiltroDataInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="ULTIMA_CONEXAO_FINAL" type="text" value={filtroDataFinal? moment(filtroDataFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataFinal} setData={setFiltroDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-status","imagem-filtro-status"); ColetaClickstream(1415) /*pedidoSmsTabelaPedidoSmsDropFiltrosSatus*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Satus </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-status"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Pago')}>Pago
                            <input defaultChecked={handlerCheck("STATUS","Pago")} type="radio" id="STATUS_PAGO" name="STATUS" value='Pago'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Processado')}>Processado
                            <input defaultChecked={handlerCheck("STATUS","Processado")} type="radio" id="STATUS_PROCESSADO" name="STATUS" value='Processado'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Cancelado')}>Cancelado
                            <input defaultChecked={handlerCheck("STATUS","Cancelado")} type="radio" id="STATUS_CANCELADO" name="STATUS" value='Cancelado'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div> 
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-quantidade-sms","imagem-filtro-quantidade-sms"); ColetaClickstream(1416) /*pedidoSmsTabelaPedidoSmsDropFiltrosQuantidadeDeSMS*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Quantidade de SMS </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-quantidade-sms"}  className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-quantidade-sms"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="PACOTES_MIN"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        value={filtroQuantidadeSmsMinimo}
                                        onChange={e => validarNumero("campo-texto-quantidade-minimo", e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3">SMS</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="PACOTES_MAX"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        value={filtroQuantidadeSmsMaximo}
                                        onChange={e => validarNumero("campo-texto-quantidade-maximo", e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3">SMS</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}