export function alterarDadosDashboardMigracaoPlano(parametro) {
    return {
        type: "DADOS_DASHBOARD_MIGRACAO_PLANO",
        parametro
    }
}

export function alterarGrafico(parametro) {
    return {
        type: "GRAFICO_ESPECIFICO_MIGRACAO_PLANO",
        parametro
    }
}