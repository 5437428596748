import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';/*eslint-disable*/
import 'moment-timezone';
import moment from 'moment';
// ICONES
import { elipseVerde, fecharJanela, exclamacao, logoRedondo } from '../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch,useSelector } from 'react-redux';
import {revelarMensagemCabecalhoSelecionada, atualizarMensagemCabecalhoSelecionada, revelarMensagemTabela, atualizarMensagensCabecalho as atualizarMensagensCabecalhoFunc, atualizarMensagensCabecalho} from "../../redux/actions/index";
//ESTILIZAÇÃO
import '../../css/painelMensagens/painelMensagens.css'
import { api } from '../../conexoes/api.js';

export default function Mensagem(props) {
    
    //HOOKS MODAIS
    const [clickFora, setClickFora] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [corpoMensagem, setCorpoMensagem] = useState('');
    const [dataHora, setDataHora] = useState('');
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();
    let mensagemCabecalhoSelecionada = useSelector(state => state.mensagensState.mensagemCabecalhoSelecionada);
    let revelarMensagemTabela = useSelector(state => state.mensagensState.revelarMensagemTabela);
    const manejador = useSelector(state => state.manejadorState.manejador)
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);
    useEffect(() => {
        if(mensagemCabecalhoSelecionada ){
            setTitulo(mensagemCabecalhoSelecionada.ds_titulo)
            // setCorpoMensagem('<span class="fonte-texto-mensagem fonte-cor-1" >'+mensagemCabecalhoSelecionada.tx_texto.split('<figure')[0]+' </span>')
            setCorpoMensagem('<span class="fonte-texto-mensagem fonte-cor-1" >'+mensagemCabecalhoSelecionada.tx_texto+' </span>')
            setDataHora(moment(mensagemCabecalhoSelecionada.dt_created_at).format("DD/MM/YYYY - H:mm"))
         }
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function handleCancelar(){
        despacho(revelarMensagemCabecalhoSelecionada(false))
        despacho(atualizarMensagemCabecalhoSelecionada(undefined))
        if(!revelarMensagemTabela){
            props.handleRevelarMensagens(true)

            if(manejador === 'telaInicial' && mensagemCabecalhoSelecionada.fl_lido == 0) { // nova mensagem ao logar
                const cd_msg_mensagem_plataforma = mensagemCabecalhoSelecionada.cd_msg_mensagem;
                const bodyAlteracao ={
                    condicao:{
                        cd_msg_mensagem_plataforma
                    },
                    dados:{
                        fl_lido:1
                    }
                }
                await api.post('/m1/alterar/mensagem',bodyAlteracao);
                despacho(atualizarMensagensCabecalhoFunc(!atualizarMensagensCabecalho))
            }
        }
    }

    function dataURItoBlob(dataURI, callback) {
        var base64String = dataURI.split(',')[1]
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        var buffer = new ArrayBuffer(base64String.length);
        var Uint8 = new Uint8Array(buffer);

        for (var i = 0; i < base64String.length; i++) {
            Uint8[i] = base64String.charCodeAt(i);
        }
        // var blob = new Blob([buffer], {type: mimeString});
        var blob = new Blob([buffer]);
        
        let canvas = document.getElementById('canvasImagem')
        let ctx = canvas.getContext('2d')

        let img = new Image(ctx)
        img.onload = function(){
            ctx.drawImage(img, 0, 0)
        }
        img.src = URL.createObjectURL(blob)
        return img;
    }
    
    return(
        <Form ref={modal}>
            <div className="modal-mensagem fundo-cor-6">
                <Form.Row>
                    <Col>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <div className="grupo-mensagem-1">
                        <div className="coluna-mensagem-1">
                            <div className="container-logo-titulo-mensagem">
                            <img className="icone-logo-redondo-mensagem" src={logoRedondo()} alt="logo redondo"/>
                            <div className="titulo-mensagem">
                                <span className="fonte-titulo-mensagem fonte-cor-1">  
                                    {titulo}  
                                </span>
                            </div>
                            </div>
                        </div>
                        <div className="coluna-mensagem-2">
                            <div className="data-hora-mensagem">
                            <span className="fonte-data-hora-mensagem fonte-cor-1">
                                {dataHora}
                            </span>
                            <img className="icone-elipse-mensagem" src={elipseVerde()} alt="elipse"/>
                            </div>
                        </div>
                    </div>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-mensagem">
                            <div className="texto-mensagem" dangerouslySetInnerHTML={{__html:corpoMensagem}} >
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                
            </div>
        </Form>
    )
}
