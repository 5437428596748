import styled, { css } from "styled-components";
import Snackbar from "@mui/material/Snackbar";

export const Container = styled.div`
  margin-left: 14em;
  margin-bottom: 14em;

  input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export const SenhasWrapper = styled.div`
  display: grid;
  width: max-content;
  gap: 20px;
`;

export const InputWrapper = styled.div`
  width: 380px;
  height: 40px;
  position: relative;
  border-radius: 24px;
  padding: 8px 12px;
  color: #fff;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ erro }) =>
    erro &&
    css`
      border: 1px solid #d32f2f;
    `}
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  font-size: 18px;
  line-height: 1.0625em;
  outline: none;
  width: 100%;

  ::placeholder {
    color: #4f627561;
  }
`;

export const BotaoVerNovaSenha = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  outline: none;
`;

export const MensagemErro = styled.span`
  color: ${({ erro }) => (!erro ? "#006b40" : "#d32f2f")};
  font-weight: 600;
  margin-top: 4px;
  width: 367px;
  text-align: start;
`;

export const MensagensErroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 380px;
  border-radius: 8px;
  text-align: left;
`;

export const CamposWrapper = styled.div`
  display: grid;
  gap: 20px;
  width: max-content;
`;

export const TituloMensagem = styled.span`
  font-size: 16px;
  color: #000;
`;

export const ValidacoesWrapper = styled.div`
  display: grid;
  gap: 6px;
`;

export const GrupoValidacao = styled.div`
  font-size: 16px;
  display: flex;
  gap: 8px;
  color: #000;

  ${({ subGrupo }) =>
    subGrupo &&
    css`
      padding-left: 20px;
    `}

  p {
    margin: 0;
  }

  img {
    margin-top: 6px;
    width: 16px;
    height: 16px;
  }
`;

export const VerSenhaImg = styled.img`
  width: 25px;
  height: auto;
`;

export const BotaoConcluir = styled.button`
  height: 40px;
  width: 125px;
  border-radius: 6.25em;
  background-color: #fff;
  color: #36d896;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
  border: none;
  box-shadow: none;
  padding: 0em;

  :disabled {
    background-color: #cfd8dc;
    color: #00000042;
  }
`;

export const Titulo = styled.p`
  text-align: left;
  width: 8em;
  color: #fff;
  font-family: ObjectivityBold;
  font-size: 3.375em;
  font-weight: 550;
  line-height: 3.875rem;
  letter-spacing: 0.01em;
`;

export const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  ".MuiSnackbarContent-root": {
    background:
      "linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #D32F2F",
    color: "#243B53",
    marginBottom: "20px",
  },
}));

export const MensagemWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
