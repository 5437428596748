//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api, api_svdispositivos} from '../../../conexoes/api';
// import { useForm } from "react-hook-form";
import { Button, Form, Col } from 'react-bootstrap';
import "../../../css/painelDispositivos/dspGrupo/grupo.css";
// import TabelaGenerica from '../../componentesModais/tabela/tabela'
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
import decryptJWT from '../../../utils/decryptJWT'
// import {identificarValidacaoAcoes} from '../../../utils/identificarValidacaoAcoes';

//ICONES
import {elipseVerde, fecharJanela} from '../../../utils/alternanciaIcones';
//IMPORTAÇÕES REDUX
import { useDispatch,useSelector } from 'react-redux';
import { revelarAlterarGrupo } from "../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
// import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
import { RenderMensagemSucesso } from '../../componentesModais/mensagens/mensagemAcao';

import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
//COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
 
import SelectGrupos from '../../componentesModais/selects/selectAlterarGrupos';
import {setaDropdown} from '../../../utils/alternanciaIcones';

// let contador = 0 
let linhasValidas = []

const routeM1FiltrarSimcard = '/m1/filtrar/simcard';

export default function AlterarGrupos(props) {
    //HOOKS VALIDAÇÂO
    // const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [renderizar, setRenderizar] = useState(false);
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1)
    // const [linhaRemover, setLinhaRemover] = useState('');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    // const [simcards, setSimcards] = useState([]);
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();

    const [nome, setNome] = useState();
    const [nomeGrupo, setNomeGrupo] = useState('Selecione');
    const [cdGrupo, setCdGrupo] = useState();
    const [revelarSelectGrupo,setRevelarSelectGrupo] = useState(false)
    const [numeroLinhasValidas,setNumeroLinhasValidas] = useState(-1)
    const [validarSalvar,setValidarSalvar] = useState(true)
    const [itens, setItens] = useState([])
    const [mensagemSucesso, setMensagemSucesso] = useState(false)
    // const [paginaAtual,setPaginaAtual] = useState(1)
    // const [ordenador,setOrdenador] = useState("")
    
    const cdCliente = decryptJWT('codigoCliente')
    const cd_pav_usuario = decryptJWT('codigo')

    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itens.length)
        if(itens.length === 0){
            handleCancelar()
        }
    }

    function handleRemoverLinha(CD_SIMCARD){
        ColetaClickstream(1150) // alterarGrupoRemoverLinha
        setRevelarMensagemAtencao(false)
        itens.forEach(function(e, index){
            if(CD_SIMCARD == e.CD_SIMCARD){
                    itens.splice(index, 1);
            }
        })
        handleLinhasSelecionadas()
    }

    async function handleCancelar() {
        ColetaClickstream(1151) // alterarGrupoCancelar
        despacho(revelarAlterarGrupo(false))
        setNumeroLinhasValidas(-1)
        linhasValidas = []
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(confirmacao ===true){
           //ALGUMA FUNCAO
        }
    }

    function handleMensagemSucesso(confirmacao, arrMsgSucesso){
        if(mensagemSucesso === false){
            setMensagemSucesso(arrMsgSucesso)
        }else{
            setMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }
    useEffect(() => {
        //ADICIONA A MODAL O EVENT LISTENER QUE VERIFICA CLICKS FORA DA MODAL E FECHA A MODAL
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, []);

    async function handleSalvar(){
        // const controller2 = new AbortController();
        // let dadosSimcard = []
        setSpinAtivo(true)
        if(validarSalvar){
            setValidarSalvar(false)
            /*if (modoSelecaoSimcard) {
                const urlGet = `${routeM1FiltrarSimcard}?buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false`
                dadosSimcard = await api.get(urlGet, { signal: controller2.signal })
                linhasValidas =  dadosSimcard.data.dados.map((i) => i.CD_SIMCARD)
            }*/

            const body = {
                'cd_dsp_simcard_grupo': cdGrupo,
                modoSelecaoSimcard,
                'cd_dsp_simcard': linhasValidas,
            }

            if(modoSelecaoSimcard) {
                if(conteudoBusca) body.busca = conteudoBusca.replaceAll(separadorLinha, ',');
                if(buscaArquivo) body.buscaArquivo = buscaArquivo;
                if(filtros && !filtros.vazio) body.filtro = filtros;
            }

            // api.post('/m1/alterar/simcard', { 
            api_svdispositivos.post('api/v2/devices/altrerarGrupoConectividade', body)
            .then(function (response) {
                setSpinAtivo(false)
                handleRespostaSolicitacao({response});
            })
            .catch(function (error) {
                if(error.response){
                    setSpinAtivo(false)
                    setMensagemErro(error.response.data.info)
                    handleMensagemErro(false)
                }
            });
        }
    }

    function handleRespostaSolicitacao({response, error}) { 
        let arrMsgSucesso = {
            arrTexto: ["Sucesso", "Solicitação enviada"],
            btnTexto: "Continuar"
        };

        if(response) { // sucesso

            let erro = false;
            const data = response?.data
            let linhasInvalidas = data?.linhasInvalidas || data?.linhasComErro;

            if(data?.linhasComErro) {
                erro = true;
            }

            const qtdInvalidas = linhasInvalidas?.length
            if(qtdInvalidas) {
                arrMsgSucesso = {
                    arrTexto: [
                        "Atenção",
                        `Foram encontradas ${qtdInvalidas} linhas com ERRO na sua solicitação. Todas as demais linhas foram alteradas com sucesso.`,
                        "Clique em baixar para verificar as linhas inválidas.",
                    ],
                    btnTexto: "Fechar",
                    linhasInvalidas,
                    erro
                }
            } 

            handleMensagemSucesso(false, arrMsgSucesso);
        }
        else if(error) {
            const resp = error?.response;
            const respDados = resp?.data
            const mensagemErro = respDados?.info;
            
            if(mensagemErro && [400, 401].includes(resp?.status)) {
                return { atencao: mensagemErro }
            }
            else {
                console.error(error)
                return { erro:true }
            }
        }
    }

    useEffect(()=>{
        setSpinAtivo(true)
        setNumeroLinhasValidas(-1)
        linhasValidas = []

        async function carregarLinhas(){
            let apiData = []
            if(modoSelecaoSimcard === true){
                if (filtros.vazio){
                    apiData = await api.post('/m1/consultar/simcard',{
                        "codigo_cliente":cdCliente,
                        "like":conteudoBusca,
                        "contar":true,
                        "separador":separadorLinha
                    })
                    setNumeroLinhasValidas(apiData.data.dados[0].CONTADOR)
                }else{
                    const urlGet = `${routeM1FiltrarSimcard}?buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=true&codigo_cliente=${cdCliente}`
                    apiData = await api.get(urlGet)
                  
                    setNumeroLinhasValidas(apiData.data.dados[0].CONTADOR)
                }
                setSpinAtivo(false)
            }else{
                if(modoSelecaoSimcard === false){
                    props.itensSelecionados.map(({CD_SIMCARD, CALLERID, ICCID})=>{
                        linhasValidas.push({
                            'cd_dsp_simcard': CD_SIMCARD,
                            'msisdn': CALLERID,
                            'iccid': ICCID
                        })
                    })
                    setNumeroLinhasValidas(props.itensSelecionados.length)
                }
                setSpinAtivo(false)
            }
        }
        carregarLinhas()
    },[renderizar])

    return(
        <Form ref={modal}>
            <div className="modal-alterar-grupo fundo-cor-6">
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela"> Alterar grupo </div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{numeroLinhasValidas} linhas selecionadas</span>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label><div className="fonte-cor-1 label-campo">Grupo</div></Form.Label>
                            {revelarSelectGrupo == false &&
                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectGrupo(!revelarSelectGrupo)}>
                                    <div className="fonte-cor-1 label-campo">{nomeGrupo}</div>
                                    <img className={revelarSelectGrupo?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }
                            { revelarSelectGrupo === true &&
                                <SelectGrupos
                                    setRevelarSelectGrupo={setRevelarSelectGrupo}
                                    setPhGrupo={"Selecione"} 
                                    setNomeGrupo={setNomeGrupo}
                                    setCdGrupo={setCdGrupo}
                                    nomeGrupo={nomeGrupo}
                                >
                                </SelectGrupos>
                            } 
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                    {nomeGrupo != 'Selecione' ?
                        <Button className="botao-confirmar" variant="success" size="sm" onClick={()=> handleSalvar()}>
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                    :
                        <Button className="botao-confirmar-desabilitado" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                    }
                        <Button className="botao-cancelar" variant="danger" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>

            { mensagemSucesso && 
                <RenderMensagemSucesso {...{mensagemSucesso, handleMensagemSucesso}} />
            }

            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={mensagemErro}
                            mensagemParagrafo1={"Algo deu errado!"}
                            mensagemParagrafo2={"Por favor, tente novamente."}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}




