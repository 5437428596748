import { Divider as DividerMantine } from "@mantine/core";
import styled, { createGlobalStyle } from "styled-components";

export const Divider = styled(DividerMantine)``;

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
  }
`;
