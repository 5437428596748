export function requestResolver(promise) {
  return promise
    .then((res) => {
      if (res?.data && "data" in res?.data) {
        return res?.data?.data;
      }

      return res?.data;
    })
    .catch((err) => {
      const {
        response: { data },
      } = err;

      const formattedError = new Error();

      if (data) {
        formattedError.message = data?.message?.includes("prisma")
          ? "Something went wrong."
          : data?.message;
      }

      throw formattedError;
    });
}
