import styled from "styled-components";

export const OverlaySpinner = styled.div`
  position: fixed !important;
  z-index: 1000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: grid;
  justify-items: center;
  align-items: center;
`;