//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';
import { useForm } from "react-hook-form";/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next'/*eslint-disable*/;
import { Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, fecharJanela, setaDropdown, editarVerde, editarCinza, cancelar, confirmar, adicionarMaisVerde, lixeiraVermelho} from '../../../utils/alternanciaIcones'
//MODAIS
import SelectTipoPlanoFaturas from '../../componentesModais/selects/selectTipoPlanoFaturas'
import SelectUnidadePlanoFaturas from '../../componentesModais/selects/selectUnidadePlanoFaturas'
import SelectCategoriaPlanoFaturas from '../../componentesModais/selects/selectCategoriaPlanoFaturas'
import SelectFornecedorPlanoFaturas from '../../componentesModais/selects/selectFornecedorPlanoFaturas'
import SelectFatOperadora from '../../componentesModais/selects/SelectFatOperadora'
import SelectServico from '../../componentesModais/selects/selectFatServico'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelFaturas/fatPlanos/fatPlanos.css'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarNovoPlano, revelarEditarPlano, revelarExcluirPlano } from "../../../redux/actions/index";
import decryptJWT from '../../../utils/decryptJWT';
import {formatarMilhar} from '../../../utils/formatarMilhar';
let primeiraVez = true;
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
export default function CadastroOperadora(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [cdPlano, setCdPlano] = useState()
    const [dsNomePlano, setDsNomePlano] = useState()
    const [cdFornecedor, setCdFornecedor] = useState()
    const [dsFornecedor, setDsFornecedor] = useState('Selecione')
    const [cdServico, setCdServico] = useState()
    const [cdServicoEditado, setCdServicoEditado] = useState()
    const [dsServico, setDsServico] = useState('Selecione')
    const [cdTipo, setCdTipo] = useState()
    const [dsTipo, setDsTipo] = useState('Selecione')
    const [cdUnidade, setCdUnidade] = useState()
    const [dsUnidade, setDsUnidade] = useState('Selecione')
    const [cdCategoria, setCdCategoria] = useState()
    const [dsCategoria, setDsCategoria] = useState('Selecione')
    const [cdOperadora, setCdOperadora] = useState()
    const [dsOperadora, setDsOperadora] = useState('Selecione')
    const [n0Simcards, setN0Simcards] = useState(0)
    const [dsValor, setDsValor] = useState()
    const [arrayServicos, setArrayServicos] = useState([])
    const [arrayServicosDeletar, setArrayServicosDeletar] = useState([])
    const [dsValorTotal, setDsValorTotal] = useState(0)
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(0)
    const [dsQuantidade, setDsQuantidade] = useState(0)
    const [flStatus, setFlStatus] = useState(0)
    const [isSelect, setIsSelect] = useState(false);
    //HOOKS PLACEHOLDERS
    const [phNomePlano, setPhNomePlano] = useState('Digite o nome do plano')
    const [phValor, setPhValor] = useState()
    const [phMensagemSucesso, setPhMensagemSucesso] = useState('cadastrado')
    const [cdServicoAux, setCdServicoAux] = useState()
    const [dsServicoAux, setDsServicoAux] = useState()
    const [cdTipoAux, setCdTipoAux] = useState()
    const [dsTipoAux, setDsTipoAux] = useState('Selecione')
    const [cdUnidadeAux, setCdUnidadeAux] = useState()
    const [dsUnidadeAux, setDsUnidadeAux] = useState('Selecione')
    const [cdCategoriaAux, setCdCategoriaAux] = useState()
    const [dsCategoriaAux, setDsCategoriaAux] = useState('Selecione')
    const [dsValorAux, setDsValorAux] = useState()
    const [dsQuantidadeAux, setDsQuantidadeAux] = useState(0)
    const [bloquearAcoes, setBloquearAcoes] = useState(true)
    //HOOKS MODAIS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [revelarSelectTipoPlanoFaturas, setRevelarSelectTipoPlanoFaturas] = useState(false)
    const [revelarSelectUnidadePlanoFaturas, setRevelarSelectUnidadePlanoFaturas] = useState(false)
    const [revelarSelectCategoriaPlanoFaturas, setRevelarSelectCategoriaPlanoFaturas] = useState(false)
    const [revelarSelectFornecedorPlanoFaturas, setRevelarSelectFornecedorPlanoFaturas] = useState(false)
    const [RevelarSelectFornecedorOperadora, setRevelarSelectFornecedorOperadora] = useState(false)
    const [revelarSelectServico, setRevelarSelectServico] = useState(false)
    //OUTROS HOOKS
    const [linhaRemover, setLinhaRemover] = useState('');
    const [servicoSelecionado, setServicoSelecionado] = useState()
    const [edicaoAtivada, setEdicaoAtivada] = useState()
    const [tituloJanela, setTituloJanela] = useState('Cadastro');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const modal = useRef();
    const despacho = useDispatch();

    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        setSpinAtivo(true)
        if (props.tituloJanela === 'Editar'){
            let buscaServicos
            async function carregarServicos(){
                setSpinAtivo(true)
                buscaServicos = await api.get('/m1/consultar/fat_plano/servico_por_plano?cd_fat_plano='+props.cdPlano);
                buscaServicos.data.dados.map((item)=>{
                    setCdFornecedor(item.cd_fat_fornecedor)
                    setDsFornecedor(item.ds_fornecedor)
                    setCdOperadora(item.cd_fat_operadora)
                    setDsOperadora(item.ds_operadora)
                    arrayServicos.push({
                        'cd_fat_servico': item.cd_fat_servico,
                        'ds_fat_servico': item.ds_fat_servico,
                        'cd_fat_tipo': item.cd_fat_tipo,
                        'ds_tipo': item.ds_tipo,
                        'cd_fat_unidade': item.cd_fat_unidade,
                        'ds_unidade': item.ds_unidade,
                        'cd_fat_categoria': item.cd_fat_categoria,
                        'ds_categoria': item.ds_categoria,
                        'ds_quantidade': item.ds_quantidade,
                        'n2_valor': formatarMilhar(item.n2_valor),
                        'fl_status': item.fl_status,
                        'ds_fornecedor': item.ds_fornecedor,
                        'ds_operadora': item.ds_operadora
                    })
                })
                handleLinhasSelecionadas()
                carregarValorTotal()
                
                setSpinAtivo(false)
            }
            carregarServicos()
            //TROCA O TITULO DA JANELA
            setTituloJanela('Editar')
            //CARREGA OS CAMPOS COM OS VALORES ORIGINAIS
            setCdPlano(props.cdPlano)
            setDsNomePlano(props.dsNomePlano)
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhNomePlano('Original: '+props.dsNomePlano)
            setPhMensagemSucesso('editado')
            let valorTotalAux = 0
            let n0SimcardsAux = 0
            props.arrayServicos.map((item,i) => {
                valorTotalAux = valorTotalAux.toString()
                valorTotalAux = (parseFloat(valorTotalAux) + parseFloat(item.ds_valor))
                n0SimcardsAux = (n0SimcardsAux + parseFloat(item.n0_simcards))
            })
            setDsValorTotal(formatarMilhar(valorTotalAux))
            setN0Simcards(n0SimcardsAux)
        }
        if (props.tituloJanela === 'Cadastrar'){
            setTituloJanela('Cadastrar')
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function carregarValorTotal(){
        let valorTotalAux = 0
        // let n0SimcardsAux = 0
        arrayServicos.map((item,i) => {
            let auxItem = item.n2_valor.toString().replace(',','.')
            valorTotalAux = valorTotalAux.toString()
            valorTotalAux = (parseFloat(valorTotalAux) + parseFloat(auxItem))
            // n0SimcardsAux = (n0SimcardsAux + parseFloat(item.n0_simcards))
        })
        setDsValorTotal(formatarMilhar(valorTotalAux))
        // setN0Simcards(n0SimcardsAux)
    }

    function handleAdicionarItem(){
        if(dsUnidade != 'Selecione' && dsCategoria != 'Selecione' && dsTipo != 'Selecione' && dsQuantidade > 0 && dsValor != 0 && dsValor != '' ){
            let auxValidarAdicao = false
            arrayServicos.forEach(function(e, index){
                if(cdServico === e.cd_fat_servico){
                    auxValidarAdicao = true
                }
            })
            
            if(auxValidarAdicao){
                setRevelarMensagemInformacao(true)
            }else{
                arrayServicos.push({
                    'cd_fat_servico': cdServico,
                    'ds_fat_servico': dsServico,
                    'cd_fat_tipo': cdTipo,
                    'ds_tipo': dsTipo,
                    'cd_fat_unidade': cdUnidade,
                    'ds_unidade': dsUnidade,
                    'cd_fat_categoria': cdCategoria,
                    'ds_categoria': dsCategoria,
                    'ds_quantidade': dsQuantidade,
                    'n2_valor': dsValor,
                    'fl_status': 1
                })
            }
            setDsServico('Selecione')
            setDsTipo('Selecione')
            setCdTipo('')
            setDsUnidade('Selecione')
            setCdUnidade('')
            setDsCategoria('Selecione')
            setCdCategoria('')
            setDsQuantidade(0)
            setDsValor('')
            setPhValor('')
        }else{
            setEdicaoAtivada(true)
        }
        handleLinhasSelecionadas()
        carregarValorTotal()
    }

    function handleEditarItem(){
        if(dsUnidade !== 'Selecione' && dsCategoria !== 'Selecione' && dsTipo !== 'Selecione' && dsQuantidade > 0 && dsValor != 0 && dsValor != '' ){
            
            arrayServicos.push({
                'cd_fat_servico': cdServicoEditado,
                'ds_fat_servico': dsServico,
                'cd_fat_tipo': cdTipo,
                'ds_tipo': dsTipo,
                'cd_fat_unidade': cdUnidade,
                'ds_unidade': dsUnidade,
                'cd_fat_categoria': cdCategoria,
                'ds_categoria': dsCategoria,
                'ds_quantidade': dsQuantidade,
                'n2_valor': dsValor,
                'fl_status': 1
            })
            
            arrayServicos.forEach(function(e, index){
                if(cdServico === e.cd_fat_servico){
                    if (props.tituloJanela === 'Editar'){
                        arrayServicosDeletar.push(arrayServicos[index])
                        arrayServicos.splice(index, 1);
                    }else{
                        arrayServicos.splice(index, 1);
                    }
                }
            })
            
            setDsServico('Selecione')
            setDsTipo('Selecione')
            setCdTipo('')
            setDsUnidade('Selecione')
            setCdUnidade('')
            setDsCategoria('Selecione')
            setCdCategoria('')
            setDsQuantidade(0)
            setDsValor('')
            setPhValor('')
            setEdicaoAtivada(false)
            setServicoSelecionado(false)
            handleLinhasSelecionadas()
            carregarValorTotal()
        }
    }

    function handleCriarItem(){
        if(dsUnidade !== 'Selecione' && dsCategoria !== 'Selecione' && dsTipo !== 'Selecione' && dsQuantidade > 0 && dsValor != 0 && dsValor != '' ){

            arrayServicos.push({
                'cd_fat_servico': "ZZZ"+arrayServicos.length,
                'ds_fat_servico': dsServico,
                'cd_fat_tipo': cdTipo,
                'ds_tipo': dsTipo,
                'cd_fat_unidade': cdUnidade,
                'ds_unidade': dsUnidade,
                'cd_fat_categoria': cdCategoria,
                'ds_categoria': dsCategoria,
                'ds_quantidade': dsQuantidade,
                'n2_valor': dsValor,
                'fl_status': 1
            })
                        
            setDsServico('Selecione')
            setDsTipo('Selecione')
            setCdTipo('')
            setDsUnidade('Selecione')
            setCdUnidade('')
            setDsCategoria('Selecione')
            setCdCategoria('')
            setDsQuantidade(0)
            setDsValor('')
            setPhValor('')
            setEdicaoAtivada(false)
            setServicoSelecionado(false)
            handleLinhasSelecionadas()
            carregarValorTotal()
        }else{
            setDsServico('')
            setEdicaoAtivada(true)
        }
    }

    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(arrayServicos.length)
    }

    //FAZ O MANEJO 
    function handleSalvar(){
        if (tituloJanela === 'Cadastrar'){
            ColetaClickstream(1295) // fatPlanosTabelaDropAcoesNovoPlanoCadastrar
            handleCadastrar()
        }else{
            ColetaClickstream(1296) // fatPlanosTabelaDropAcoesNovoPlanoEditar
            handleAlterar()
        }
    }

    async function handleAlterar(){
        setSpinAtivo(true)
        let auxValorTotal = dsValorTotal.replace(',','.')
        auxValorTotal = auxValorTotal.replace(' ','')
        await api.post('/m1/alterar/fat_plano', {
            "dados":{
                'ds_plano' : dsNomePlano,
                'cd_fat_fornecedor' : cdFornecedor,
                'n2_valor_plano' : auxValorTotal,
                'cd_dsp_operadora' : cdOperadora,
                'arrayServicos' : arrayServicos,
                'arrayServicosDeletar' : arrayServicosDeletar,
                'cd_pav_usuario' : decryptJWT('codigo'),
                'cd_pav_cliente': decryptJWT('codigoCliente')
            },
            "condicao":{
                'cd_fat_plano' : cdPlano,
            }
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    async function handleCadastrar(){
        setSpinAtivo(true)
        let auxValorTotal = dsValorTotal.replace(',','.')
        auxValorTotal = auxValorTotal.replace(' ','')

        await api.post('/m1/cadastrar/fat_plano', {
            'ds_plano' : dsNomePlano,
            'cd_fat_fornecedor' : cdFornecedor,
            'n2_valor_plano' : auxValorTotal,
            'cd_dsp_operadora' : cdOperadora,
            'arrayServicos' : arrayServicos,
            'cd_pav_usuario' : decryptJWT('codigo'),
            "cd_pav_cliente": decryptJWT('codigoCliente')
        },)
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    function handleCancelar(){
        ColetaClickstream(1294) // 'fatPlanosTabelaDropAcoesNovoPlanoCancelar'
        despacho(revelarNovoPlano(false))
        despacho(revelarEditarPlano(false))
        despacho(revelarExcluirPlano(false))
        setRevelarMensagemAtencao(false)
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
    }

    const colunas = [{
        dataField: 'cd_fat_servico',
        text: 'Cód. Serviço',
        hidden: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_fat_servico',
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        text: 'Serviço',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_tipo',
        headerClasses: 'nao-selecionavel',
        text: 'Tipo',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_unidade',
        headerClasses: 'nao-selecionavel',
        text: 'Unidade',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_categoria',
        headerClasses: 'nao-selecionavel',
        text: 'Categoria',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_quantidade',
        headerClasses: 'nao-selecionavel',
        text: 'Quantidade',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'n2_valor',
        headerClasses: 'nao-selecionavel',
        text: 'Valor',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">R$ {cell}</p>;
        }
    },
    {
        dataField: 'acoes',
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        text: '',
        sort: false,
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.cd_fat_servico);
        }
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            setIsSelect(isSelect)
            if(isSelect){
                setCdServicoAux(row.cd_fat_servico)
                setDsServicoAux(row.ds_fat_servico)
                setCdTipoAux(row.cd_fat_tipo)
                setDsTipoAux(row.ds_tipo)
                setCdUnidadeAux(row.cd_fat_unidade)
                setDsUnidadeAux(row.ds_unidade)
                setCdCategoriaAux(row.cd_fat_categoria)
                setDsCategoriaAux(row.ds_categoria)
                setDsQuantidadeAux(row.ds_quantidade)
                setDsValorAux(row.n2_valor)
                setFlStatus(1)
                setServicoSelecionado(true)
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            props.setRenderizar(!props.renderizar)
            handleCancelar()
            if(confirmacao === true){
                handleCancelar()
            }
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(props.tituloJanela != 'Editar'){
            if(revelarMensagemAtencao === false){
                setRevelarMensagemAtencao(true)
            }else{
                setRevelarMensagemAtencao(false)
            }
            if(confirmacao === true ){
                handleRemoverLinha(linhaRemover)
            }
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    async function handleRemoverLinha(codigo){
        setRevelarMensagemAtencao(false)
        arrayServicos.forEach(function(e, index){
            if(codigo === e.cd_fat_servico){
                if (props.tituloJanela === 'Editar'){
                    arrayServicosDeletar.push(arrayServicos[index])
                    arrayServicos.splice(index, 1);
                }else{
                    arrayServicos.splice(index, 1);
                }
            }
        })
        handleLinhasSelecionadas()
        carregarValorTotal()
    }
    

    function ativarEdicao(){
        setEdicaoAtivada(true)
        setCdServico(cdServicoAux)
        setCdServicoEditado(cdServicoAux+"A"+arrayServicos.length)
        setDsServico(dsServicoAux)
        setCdTipo(cdTipoAux)
        setDsTipo(dsTipoAux)
        setCdUnidade(cdUnidadeAux)
        setDsUnidade(dsUnidadeAux)
        setCdCategoria(cdCategoriaAux)
        setDsCategoria(dsCategoriaAux)
        setDsQuantidade(dsQuantidadeAux)
        setDsValor(dsValorAux)
        setPhValor(dsValorAux)
        setFlStatus(1)
    }

    function desativarEdicao(){
        setEdicaoAtivada(false)
        setDsServico('Selecione')
        setDsTipo('Selecione')
        setCdTipo('')
        setDsUnidade('Selecione')
        setCdUnidade('')
        setDsCategoria('Selecione')
        setCdCategoria('')
        setDsQuantidade(0)
        setDsValor('')
        setPhValor('')
        setFlStatus(0)
    }

    useEffect(()=>{
        if(props.tituloJanela === 'Editar'){   
            handlerVerificarEdicao()     
        }
    },[])

    async function handlerVerificarEdicao(){
        await api.get('/m1/consultar/fat_plano_validador?cd_fat_plano='+props.cdPlano)
        .then((response)=>{
            if(response.data.status == 200){
                if(response.data.dados){
                    setBloquearAcoes(false)
                }
                if(!response.data.dados){
                    setBloquearAcoes(true)
                }
            }
            else{
                    setBloquearAcoes(true)
            }
        })
        .catch((response)=>{
                    setBloquearAcoes(false)
        })
    }

    function handleValidarCampoValor(valor){
        const filtro = /^[0-9\,\.\b]+$/;
        if (valor === '' || filtro.test(valor)) {
            let valorFormatado = ((valor).replace(",", "."))
            setDsValor(valorFormatado)
        }
    }

    useEffect(()=>{
        if(!primeiraVez){
            setDsOperadora('Selecione')
            setCdOperadora()
        }
        primeiraVez = false;
    },[cdFornecedor])

    function formatarReal(value){
        setDsValor(value
        .replace(/\D/g,"")
        .replace(/(\d{1})(\d{14})$/,"$1.$2")
        .replace(/(\d{1})(\d{11})$/,"$1.$2")
        .replace(/(\d{1})(\d{8})$/,"$1.$2")
        .replace(/(\d{1})(\d{5})$/,"$1.$2")
        .replace(/(\d{0})(\d{1,2})$/,"$1,$2"))
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-cadastro-planos-faturas fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{tituloJanela} plano</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-planos-faturas-1">
                            <div className="coluna-cadastro-planos-faturas-1">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Plano</div> </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        value={dsNomePlano}
                                        placeholder={phNomePlano}
                                        onChange={e => setDsNomePlano(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-planos-faturas-2-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className={!bloquearAcoes?"fonte-cor-6 label-campo":"fonte-cor-1 label-campo"}>Fornecedor</div>
                                    </Form.Label>
                                    { revelarSelectFornecedorPlanoFaturas === false && bloquearAcoes &&
                                        <Button name="cdFornecedorPlanoFaturas" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFornecedorPlanoFaturas(!revelarSelectFornecedorPlanoFaturas)}>
                                            <div className="fonte-cor-1 label-campo">{dsFornecedor}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { !bloquearAcoes &&
                                        <Button name="cdFornecedorPlanoFaturas" className="campo-texto-cor-3 campo-select" disabled>
                                            <div className="fonte-cor-1 label-campo">{dsFornecedor}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectFornecedorPlanoFaturas === true && bloquearAcoes &&
                                        <SelectFornecedorPlanoFaturas
                                            setRevelarSelectFornecedorPlanoFaturas={setRevelarSelectFornecedorPlanoFaturas} //Hook para fechar a modal
                                            setCdFornecedorPlanoFaturas={setCdFornecedor} //Código do FornecedorPlanoFaturas
                                            setDsFornecedorPlanoFaturas={setDsFornecedor} //Nome do FornecedorPlanoFaturas
                                            dsFornecedorPlanoFaturas={dsFornecedor}
                                        >
                                        </SelectFornecedorPlanoFaturas>
                                    }
                                </Form.Group>
                            </div>
                            {cdFornecedor ? 
                                <div className="coluna-cadastro-planos-faturas-2-2">
                                    <Form.Group>
                                        <Form.Label>
                                            <div className={!bloquearAcoes?"fonte-cor-6 label-campo":"fonte-cor-1 label-campo"}>Operadora</div>
                                        </Form.Label>
                                        { RevelarSelectFornecedorOperadora === false && bloquearAcoes &&
                                            <Button name="cdPacote" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFornecedorOperadora(!RevelarSelectFornecedorOperadora)}>
                                                <div className="fonte-cor-1 label-campo">{dsOperadora}</div>
                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { !bloquearAcoes &&
                                            <Button name="cdFornecedorPlanoFaturas" className="campo-texto-cor-3 campo-select" disabled>
                                                <div className="fonte-cor-1 label-campo">{dsOperadora}</div>
                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        
                                        { RevelarSelectFornecedorOperadora === true && bloquearAcoes &&
                                            <SelectFatOperadora
                                                setRevelarSelectFornecedorOperadora={setRevelarSelectFornecedorOperadora}
                                                setCdOperadora={setCdOperadora}
                                                setDsOperadora={setDsOperadora}
                                                dsOperadora={dsOperadora}
                                                cdFornecedor={cdFornecedor}
                                            >
                                            </SelectFatOperadora>
                                        }
                                    </Form.Group>
                                </div>
                            :
                                <div className="coluna-cadastro-planos-faturas-2-2">
                                    <Form.Group>
                                        <Form.Label>
                                            <div className={!bloquearAcoes?"fonte-cor-6 label-campo":"fonte-cor-1 label-campo"}>Operadora</div>
                                        </Form.Label>
                                        <Button name="cdOperadora" className="campo-texto-cor-3 campo-select">
                                            <div className="fonte-cor-6 label-campo">Selecione</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    </Form.Group>
                                </div>
                            }
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <div className="campo-select-divisor-cor-1 divisoria-cadastro-operadoras-faturas"></div>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-planos-faturas-1">
                            {!edicaoAtivada ?
                                <div className="coluna-cadastro-planos-faturas-3">
                                    <Form.Group>
                                        <Form.Label>
                                            <div className={!bloquearAcoes?"fonte-cor-6 label-campo":"fonte-cor-1 label-campo"}>Serviço</div>
                                        </Form.Label>
                                        { revelarSelectServico === false && bloquearAcoes &&
                                            <Button name="cdServico" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectServico(!revelarSelectServico)}>
                                                <div className="fonte-cor-1 label-campo">{dsServico}</div>
                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { !bloquearAcoes &&
                                            <Button name="cdFornecedorPlanoFaturas" className="campo-texto-cor-3 campo-select" disabled>
                                                <div className="fonte-cor-1 label-campo">{dsServico}</div>
                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { revelarSelectServico === true && bloquearAcoes &&
                                            <SelectServico
                                                setRevelarSelectServico={setRevelarSelectServico} //Hook para fechar a modal
                                                setCdServico={setCdServico} //Código do Servico
                                                setDsServico={setDsServico} //Nome do Servico
                                                setCdTipo={setCdTipo} //Código do Tipo
                                                setDsTipo={setDsTipo} //Nome do Tipo
                                                setCdCategoria={setCdCategoria} //Código do Categoria
                                                setDsCategoria={setDsCategoria} //Nome do Categoria
                                                setCdUnidade={setCdUnidade} //Código do Unidade
                                                setDsUnidade={setDsUnidade} //Nome do Unidade
                                                setDsQuantidade={setDsQuantidade} //Nome do Quantidade
                                                setDsValor={setDsValor} //Código dN2Valor
                                                dsServico={dsServico}
                                            >
                                            </SelectServico>
                                        }
                                    </Form.Group>
                                </div>
                            :
                                <div className="coluna-cadastro-planos-faturas-3">
                                    <Form.Group>
                                        <Form.Label> <div className="fonte-cor-1 label-campo">Serviço</div> </Form.Label>
                                        <Form.Control
                                            className="campo-texto-cor-3 campo-texto"
                                            type="text"
                                            value={dsServico}
                                            placeholder="Digite o nome do serviço"
                                            onChange={e => setDsServico(e.target.value)}
                                        />
                                    </Form.Group>
                                </div>
                            }
                            {/* <div className="coluna-cadastro-planos-faturas-10">
                                {servicoSelecionado && !edicaoAtivada && bloquearAcoes ?
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Editar serviço selecionado.</Tooltip>}>
                                        <Button className="botao-editar-pedidos-novo-simcard" variant="success" size="sm" onClick={() => ativarEdicao()}>
                                            <img className="icone-botao-editar-pedidos-novo-simcard" src={editarVerde()} alt="fechar-janela"/>
                                        </Button>
                                    </OverlayTrigger>
                                :
                                    <Button className="botao-editar-pedidos-novo-simcard-cinza" variant="success" size="sm">
                                        <img className="icone-botao-editar-pedidos-novo-simcard" src={editarCinza()} alt="fechar-janela"/>
                                    </Button>
                                }
                            </div> */}
                            <div className="coluna-cadastro-planos-faturas-11">
                                {!edicaoAtivada ?
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Limpar.</Tooltip>}>
                                        <Button className="botao-adicionar-pedidos-novo-simcard" variant="success" size="sm" disabled={!bloquearAcoes} onClick={() => desativarEdicao()}>
                                            <img className="icone-botao-adicionar-pedidos-novo-simcard" src={cancelar()} alt="fechar-janela"/>
                                        </Button>
                                    </OverlayTrigger>
                                :
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>{!servicoSelecionado?"Cancelar criação.":"Cancelar edição."}</Tooltip>}>
                                        <Button className="botao-adicionar-pedidos-novo-simcard" variant="success" size="sm" onClick={() => desativarEdicao()}>
                                            <img className="icone-botao-adicionar-pedidos-novo-simcard" src={cancelar()} alt="fechar-janela"/>
                                        </Button>
                                    </OverlayTrigger>
                                }
                            </div>
                            {!edicaoAtivada ?
                                <div className="coluna-cadastro-planos-faturas-12">
                                    {dsServico !== "Selecione" ?
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Adicionar serviço selecionado.</Tooltip>}>
                                            <Button className="botao-adicionar-pedidos-novo-simcard botao-adicionar-pedidos-novo-simcard-espacador" variant="success" size="sm" onClick={() => handleAdicionarItem()}>
                                                <img className="icone-botao-adicionar-pedidos-novo-simcard" src={confirmar()} alt="fechar-janela"/>
                                            </Button>
                                        </OverlayTrigger>
                                    :
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Criar novo serviço.</Tooltip>}>
                                            <Button hidden="true" className="botao-adicionar-pedidos-novo-simcard margem-esquerda-pedidos-novo-simcard" variant="success" size="sm" disabled={!bloquearAcoes} onClick={() => handleCriarItem()}>
                                                <img className="icone-botao-adicionar-pedidos-novo-simcard" src={adicionarMaisVerde()} alt="fechar-janela"/>
                                            </Button>
                                        </OverlayTrigger>
                                    }
                                </div>
                            :
                                <div className="coluna-cadastro-planos-faturas-12">
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>{!servicoSelecionado?"Confirmar novo serviço.":"Confirmar edição do serviço."}</Tooltip>}>
                                        <Button className="botao-adicionar-pedidos-novo-simcard margem-esquerda-pedidos-novo-simcard" variant="success" size="sm" onClick={() => {!servicoSelecionado?handleCriarItem():handleEditarItem()}}>
                                            <img className="icone-botao-adicionar-pedidos-novo-simcard" src={confirmar()} alt="fechar-janela"/>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            }
                            {edicaoAtivada &&
                                <div className="coluna-cadastro-planos-faturas-4">
                                    <Form.Group>
                                        <Form.Label>
                                            <div className={!bloquearAcoes?"fonte-cor-6 label-campo":"fonte-cor-1 label-campo"}>Tipo</div>
                                        </Form.Label>
                                        { revelarSelectTipoPlanoFaturas === false && bloquearAcoes &&
                                            <Button name="cdTipoPlanoFaturas" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoPlanoFaturas(!revelarSelectTipoPlanoFaturas)}>
                                                <div className="fonte-cor-1 label-campo">{dsTipo}</div>
                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { !bloquearAcoes &&
                                            <Button name="cdFornecedorPlanoFaturas" className="campo-texto-cor-3 campo-select" disabled>
                                                <div className="fonte-cor-1 label-campo">{dsTipo}</div>
                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { revelarSelectTipoPlanoFaturas === true && bloquearAcoes &&
                                            <SelectTipoPlanoFaturas
                                                setRevelarSelectTipoPlanoFaturas={setRevelarSelectTipoPlanoFaturas} //Hook para fechar a modal
                                                setCdTipoPlanoFaturas={setCdTipo} //Código do TipoPlanoFaturas
                                                setDsTipoPlanoFaturas={setDsTipo} //Nome do TipoPlanoFaturas
                                                dsTipoPlanoFaturas={dsTipo}
                                            >
                                            </SelectTipoPlanoFaturas>
                                        }
                                    </Form.Group>
                                </div>
                            }
                        </div>
                    </Col>
                </Form.Row>
                {edicaoAtivada &&
                    <Form.Row>
                        <Col>
                            <div className="grupo-cadastro-planos-faturas-1">
                                <div className="coluna-cadastro-planos-faturas-6">
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Categoria</div>
                                        </Form.Label>
                                        { revelarSelectCategoriaPlanoFaturas === false &&
                                            <Button name="cdCategoriaPlanoFaturas" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCategoriaPlanoFaturas(!revelarSelectCategoriaPlanoFaturas)}>
                                                <div className="fonte-cor-1 label-campo">{dsCategoria}</div>
                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { revelarSelectCategoriaPlanoFaturas === true &&
                                            <SelectCategoriaPlanoFaturas
                                                setRevelarSelectCategoriaPlanoFaturas={setRevelarSelectCategoriaPlanoFaturas} //Hook para fechar a modal
                                                setCdCategoriaPlanoFaturas={setCdCategoria} //Código do CategoriaPlanoFaturas
                                                setDsCategoriaPlanoFaturas={setDsCategoria} //Nome do CategoriaPlanoFaturas
                                                dsCategoriaPlanoFaturas={dsCategoria}
                                            >
                                            </SelectCategoriaPlanoFaturas>
                                        }
                                    </Form.Group>
                                </div>
                                <div className="coluna-cadastro-planos-faturas-7">
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Quantidade</div>
                                    </Form.Label>
                                    <input type="range" class="custom-range" value={dsQuantidade} defaultValue="0" min="0" max="100"  step="1" id="customRange" onChange={e => setDsQuantidade(e.target.value)}/>
                                </div>
                                <div className="coluna-cadastro-planos-faturas-8">
                                    <div className="">
                                        {/* <span className="label-simcard-novo-pedido fonte-cor-1">{dsQuantidade}</span> */}
                                        <Form.Control name="ds_responsavel" value={dsQuantidade} className="campo-texto-simcard-novo-pedido campo-texto-cor-3 campo-texto" type="text" placeholder="0" onChange={e => setDsQuantidade(e.target.value)} ></Form.Control>
                                    </div>
                                </div>
                                <div className="coluna-cadastro-planos-faturas-9">
                                    <Form.Group>
                                        <Form.Label> <div className="fonte-cor-1 label-campo">Valor</div> </Form.Label>
                                        <Form.Control
                                            className="campo-texto-cor-3 campo-texto"
                                            value={dsValor}
                                            id="valorMoeda"
                                            placeholder="Digite o valor"
                                            onChange={e => formatarReal(e.target.value)}
                                            ref={register({ required: true, min: 3, max: 15 })} maxlength="15"/>
                                    </Form.Group>
                                </div>
                                <div className="coluna-cadastro-planos-faturas-5">
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Unidade</div>
                                        </Form.Label>
                                        { revelarSelectUnidadePlanoFaturas === false &&
                                            <Button name="cdUnidadePlanoFaturas" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectUnidadePlanoFaturas(!revelarSelectUnidadePlanoFaturas)}>
                                                <div className="fonte-cor-1 label-campo">{dsUnidade}</div>
                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { revelarSelectUnidadePlanoFaturas === true &&
                                            <SelectUnidadePlanoFaturas
                                                setRevelarSelectUnidadePlanoFaturas={setRevelarSelectUnidadePlanoFaturas} //Hook para fechar a modal
                                                setCdUnidadePlanoFaturas={setCdUnidade} //Código do UnidadePlanoFaturas
                                                setDsUnidadePlanoFaturas={setDsUnidade} //Nome do UnidadePlanoFaturas
                                                dsUnidadePlanoFaturas={dsUnidade}
                                            >
                                            </SelectUnidadePlanoFaturas>
                                        }
                                    </Form.Group>
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                }
                { arrayServicos.length > 0 &&
                    <Form.Row>
                        <div className="campo-select-divisor-cor-1 divisoria-cadastro-operadoras-faturas"></div>
                    </Form.Row>
                }
                { arrayServicos.length > 0 &&
                    <Form.Row>
                        <Col>
                            <div className="container-tabela-modal">
                                <BootstrapTable 
                                    classes="tabela-transparente-modais"
                                    keyField='cd_fat_servico'
                                    condensed={true}
                                    data={arrayServicos}
                                    selectRow={ selecaoLinhas }
                                    columns={colunas}
                                    rowEvents={eventosLinhas}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4={true}
                                />
                            </div>
                        </Col>
                    </Form.Row>
                }
                { arrayServicos.length > 0 &&
                    <Form.Row>
                        <Col>
                            <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} serviço(s) adicionado(s)</span>
                        </Col>
                    </Form.Row>
                }
                { arrayServicos.length > 0 &&
                    <Form.Row>
                        <Col>
                            <div className="grupo-cadastro-planos-faturas-1">
                                <div className="coluna-cadastro-planos-faturas-13">
                                    <div className="campo-texto-valor-adicionar-pacote-extra campo-texto-cor-3 campo-texto">
                                        <span className="label-valor-adicionar-pacote-extra-1 fonte-cor-1 negrito">Valor Total</span>
                                        <span className="label-valor-adicionar-pacote-extra-2 fonte-cor-3 negrito"> R$ {dsValorTotal} </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                }
                <Form.Row>
                    <div className="campo-select-divisor-cor-1 divisoria-cadastro-operadoras-faturas"></div>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {(arrayServicos.length > 0 && dsNomePlano != '' && cdFornecedor > 0 && cdOperadora > 0) ?
                            <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                                <div className="fonte-cor-4">{tituloJanela}</div>
                            </Button>
                        :
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Preencha o nome, fornecedor, operadora e insira um serviço.</Tooltip>}>
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">{tituloJanela}</div>
                                </Button>
                            </OverlayTrigger>
                        }
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Plano "+dsNomePlano+" cadastrado."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover serviço?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"Negado"}
                            mensagemParagrafo1={"Este serviço já foi adicionado ao plano."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}