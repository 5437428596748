const ESTADO_INICIAL = {
    visualizarDadosRefresh: false,
    revelarConsultarSimcard: false,
    revelarHistoricoAcessos: false,
    revelarCadastrarUsuario: false,
    revelarEdicaoUsuario: false,
    revelarClonarUsuario: false,
    revelarAdicionarLogin: false,
    revelarLogReset: false,
    revelarAlterarAdicionarLogin: false,
    revelarDoppelgangerCliente: false,
    revelarProcessarPedido: false,
    revelarConcluirPedido: false,
    revelarCancelarPedido: false,
    desabilitarAcoesCliente: true,
    desabilitarEditar: false,
    solicitacaConcluir: false,
    solicitacaCancelar: false,
    solicitacaReenviarProtocolo: false,
    solicitacaReenviarTrocaOcorrenciaVirtueyes: false,
    revelarEnviarProtocoloProtheus: false,
    confimarRecebimentoVirtueyes: false,
    reenviarEmailVirtueyes:false,
    novaSolicitacaoVirtueyes:false,
    solicitacaBloquearVirtueyes:false,
    solicitacaRemoverBloqueioVirtueyes:false,
    solicitacaEnviarAvisoVirtueyes:false,
    solicitacaExportarVirtueyes:false,
    revelarDadosContaUsuario:false,
    revelarPerfilContaUsuario:false,
    revelarAlterarLogin:false,
    revelarAlterarSenha:false,
    revelarNovoPedidoSmsVirtueyes: false,
    revelarCadastroCliente: false,
    revelarCadastroClienteVirtueyes: false
}

export const virtueyesReducer = (state = ESTADO_INICIAL, action) => {
     if (action.type === 'VISUALIZAR_DADOS_REFRESH'){
        return {
            ...state,
            visualizarDadosRefresh : action.parametro
        }
    }
    else if (action.type === 'REVELAR_HISTORICO_ACESSO'){
        return {
            ...state,
            revelarHistoricoAcessos : action.parametro
        }
    }
    else if (action.type === 'REVELAR_CADASTRAR_USUARIO'){
        return {
            ...state,
            revelarCadastrarUsuario : action.parametro
        }
    }
    else if (action.type === 'REVELAR_EDICAO_USUARIO'){
        return {
            ...state,
            revelarEdicaoUsuario : action.parametro
        }
    } else if (action.type === 'REVELAR_CLONAR_USUARIO'){
        return {
            ...state,
            revelarClonarUsuario : action.parametro
        }
    } else if (action.type === 'REVELAR_DADOS_CONTA_USUARIO'){
        return {
            ...state,
            revelarDadosContaUsuario : action.parametro
        }
    }
    else if (action.type === 'REVELAR_PERFIL_CONTA_USUARIO'){
        return {
            ...state,
            revelarPerfilContaUsuario : action.parametro
        }
    }
    else if (action.type === 'REVELAR_ALTERAR_LOGIN'){
        return {
            ...state,
            revelarAlterarLogin : action.parametro
        }
    }
    else if (action.type === 'REVELAR_ALTERAR_SENHA'){
        return {
            ...state,
            revelarAlterarSenha : action.parametro
        }
    }
    else if (action.type === 'REVELAR_ADICIONAR_LOGIN'){
        return {
            ...state,
            revelarAdicionarLogin : action.parametro
        }
    }
    else if (action.type === 'REVELAR_LOG_RESET'){
        return {
            ...state,
            revelarLogReset : action.parametro
        }
    }
    else if (action.type === 'REVELAR_ALTERAR_ADICIONAR_LOGIN'){
        return {
            ...state,
            revelarAlterarAdicionarLogin : action.parametro
        }
    }
    else if (action.type === 'REVELAR_CONSULTAR_SIMCARD'){
        return {
            ...state,
            revelarConsultarSimcard : action.parametro
        }
    }
    else if (action.type === 'REVELAR_DOPPELGANGER_CLIENTE'){
        return {
            ...state,
            revelarDoppelgangerCliente : action.parametro
        }
    }
    else if (action.type === 'MUDAR_DOPPLEGANGER_CLIENTE'){
        return {
            ...state,
            revelarDoppelgangerCliente : action.parametro
        }
    }
    else if (action.type === 'REVELAR_PROCESSAR_PEDIDO'){
        return {
            ...state,
            revelarProcessarPedido : action.parametro
        }
    }
    else if (action.type === 'REVELAR_CONCLUIR_PEDIDO'){
        return {
            ...state,
            revelarConcluirPedido : action.parametro
        }
    }
    else if (action.type === 'REVELAR_CANCELAR_PEDIDO'){
        return {
            ...state,
            revelarCancelarPedido : action.parametro
        }
    }
    
    else if (action.type === 'DESABILITAR_ACOES_CLIENTE'){
        return {
            ...state,
            desabilitarAcoesCliente : action.parametro
        }
    }
    else if (action.type === 'DESABILITAR_EDITAR'){
        return {
            ...state,
            desabilitarEditar : action.parametro
        }
    }
    else if (action.type === 'SOLICITACAO_CONCLUIR_CENTRAL_ACOES'){
        return {
            ...state,
            solicitacaConcluir : action.parametro
        }
    }
    else if (action.type === 'SOLICITACAO_CANCELAR_CENTRAL_ACOES'){
        return {
            ...state,
            solicitacaCancelar : action.parametro
        }
    }
    else if (action.type === 'SOLICITACAO_REENVIAR_PROTOCOLO_CENTRAL_ACOES'){
        return {
            ...state,
            solicitacaReenviarProtocolo : action.parametro
        }
    }
    else if (action.type === 'SOLICITACAO_REENVIAR_TROCA_OCORRENCIA'){
        return {
            ...state,
            solicitacaReenviarTrocaOcorrenciaVirtueyes : action.parametro
        }
    }
    else if (action.type === 'CONFIRMAR_RECEBIMENTO_CENTRAL_ACOES'){
        return {
            ...state,
            confimarRecebimentoVirtueyes : action.parametro
        }
    }
    else if (action.type === 'REENVIAR_EMAIL_CENTRAL_ACOES'){
        return {
            ...state,
            reenviarEmailVirtueyes : action.parametro
        }
    }
    else if (action.type === 'NOVA_SOLICITACAO_CENTRAL_ACOES'){
        return {
            ...state,
            novaSolicitacaoVirtueyes : action.parametro
        }
    }
    else if (action.type === 'SOLICITACAO_BLOQUEAR_CENTRAL_ACOES'){
        return {
            ...state,
            solicitacaBloquearVirtueyes : action.parametro
        }
    }
    else if (action.type === 'SOLICITACAO_REMOVER_BLOQUEIO_CENTRAL_ACOES'){
        return {
            ...state,
            solicitacaRemoverBloqueioVirtueyes : action.parametro
        }
    }
    else if (action.type === 'SOLICITACAO_ENVIAR_AVISO_CENTRAL_ACOES'){
        return {
            ...state,
            solicitacaEnviarAvisoVirtueyes : action.parametro
        }
    }
    else if (action.type === 'SOLICITACAO_EXPORTAR_CENTRAL_ACOES'){
        return {
            ...state,
            solicitacaExportarVirtueyes : action.parametro
        }
        
    }
    else if (action.type === "REVELAR_NOVO_PEDIDO_SMS_VIRTUEYES") {
        return {
            ...state,
            revelarNovoPedidoSmsVirtueyes: action.parametro
        }
    }

    else if(action.type === "REVELAR_CADASTRO_CLIENTE") {
        return {
            ...state,
            revelarCadastroCliente: action.parametro
        }
    }
    else if (action.type === "REVELAR_CADASTRO_CLIENTE_VIRTUEYES") {
        return {
            ...state,
            revelarCadastroClienteVirtueyes: action.parametro
        }
    }
    else{
        return state
    }
}