import * as S from "./styles";

export default function NumberInput({
  placeholder,
  label,
  variant = "filled",
  onChange,
  value,
  hideControls = true,
  defaultValue = null,
  disabled = false,
  withAsterisk = false,
}) {
  return (
    <S.NumberInput
      withAsterisk={withAsterisk}
      placeholder={placeholder}
      defaultValue={defaultValue}
      label={label}
      variant={variant}
      radius="xl"
      value={value}
      onChange={onChange}
      disabled={disabled}
      hideControls={hideControls}
    />
  );
}
