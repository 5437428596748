//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col } from 'react-bootstrap';
//ICONES
import {elipseVerde, fecharJanela, exclamacao} from '../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
import { revelarAlterarLogin } from "../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../componentesModais/mensagens/mensagemErro'
import decryptJWT from '../../utils/decryptJWT' 
// COLETA CLICKSTREAM
import ColetaClickstream from '../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../spinnerVeye/spinnerVeye.js'
//CSS
import '../../css/perfilUsuario/perfilUsuario.css'
export default function DesativarDemonstracao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { handleSubmit, register, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [habilitarEdicao, setHabilitarEdicao] = useState(true);
    const [email, setEmail] = useState('');
    const [nome, setNome] = useState('');

    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();
    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(()=>{
        setSpinAtivo(true)
        async function handleCarregarDados(){
            await api.get("/m1/consultar/usuario?cd_pav_usuario="+ decryptJWT("codigo")) 
                .then((resolve)=>{
                    if(resolve.data.status === 200 && resolve.data.dados.length != 0){
                        setEmail(resolve.data.dados[0].ds_email)
                        setNome(resolve.data.dados[0].ds_pav_usuario)
                    }
                    setSpinAtivo(false)
                })
        }
        handleCarregarDados()
    },[])

    useEffect(()=>{
        if(email !== '' && nome !== '') setHabilitarEdicao(false)
        else setHabilitarEdicao(true)
    },[email,nome])


    async function handleSalvar(){
        setSpinAtivo(true)
        await api.post('/m1/alterar/usuario', {
            "dados":{
                "ds_email":email,
                "ds_pav_usuario":nome
            },
            "condicao":{
                "cd_pav_usuario": decryptJWT('codigo')
            }
        },{timeout: 20000})
        .then(function (response) {
            setSpinAtivo(false)
            handleCancelar()
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro("Erro na alteracao de email")
            handleMensagemErro(false)
        });
    }

    async function handleCancelar() {
        setSpinAtivo(false)
        despacho(revelarAlterarLogin(false))
    }
    


    useEffect(() => {
        setSpinAtivo(true)
        async function preencherTabela (){
            setSpinAtivo(false)
        }
         preencherTabela()
        
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao ===true){
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            // handleCancelar()
            handleSalvar()
        }
        if(confirmacao){
        }
    }

    async function handleReconstruirToken(){
        

        const request = "/m1/servicos/alterar_token?login="+decryptJWT('email')+"&email="+email+"&codigo="+decryptJWT('codigo')+
        "&codigoCliente="+decryptJWT('codigoCliente')+"&codigoClienteErpAntigo="+decryptJWT('codigoClienteErpAntigo')+
        "&nomeDeUsuario="+nome+"&perfil="+decryptJWT('perfil')+"&cdSmsContaUsuario="+decryptJWT('cdSmsContaUsuario')+
        "&id="+decryptJWT("id")+"&clienteNivel="+decryptJWT('clienteNivel')+"&tipoPerfil=" +decryptJWT("tipoPerfil")
        +"&clientePrimario=" +decryptJWT("clientePrimario")
        await api.get(request)
        .then(function(response){
            localStorage.setItem('@veye-Token', response.data.dados.token)
            handleMensagemSucesso()
        })
        .catch(function(error){

        })
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleReconstruirToken())}>
            <div className="modal-alterar-senha fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Alterar login</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row className="usuario-dados-corpo-linha">
                    <Col>
                        <div className="corpo-dados-usuario-email">
                            <Form.Label> <div className="fonte-cor-1 label-campo">E-mail</div> </Form.Label>
                            <Form.Control
                                className="campo-texto-cor-3 campo-texto"
                                type="text"
                                maxLength="255"
                                name="ds_email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                ref={register({required: true,validate: {minimo: value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) == true}})}
                            />
                            {errors.ds_email && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Insira um email valido.</p>}
                        </div>
                    </Col>
                    <Col>
                        <div className="corpo-dados-usuario-email">
                            <Form.Label> <div className="fonte-cor-1 label-campo">Nome de saudação</div> </Form.Label>
                            <Form.Control
                                className="campo-texto-cor-3 campo-texto"
                                type="text"
                                maxLength="255"
                                value={nome}
                                name="ds_nome"
                                onChange={e => setNome(e.target.value)}
                                ref={register({required: true,validate: {minimo: value => value.length > 1}})}
                            />
                            {errors.ds_nome && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row className="espaco-reativar-linha">
                    <Col>
                        <Button  type="submit" className={habilitarEdicao?"botao-confirmar-desabilitado":"botao-confirmar"} variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Seu login foi alterado."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover Linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}