//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import { useForm } from "react-hook-form";
import { Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
//ICONES
import { elipseVerde, fecharJanela, setaDropdown, exclamacao } from '../../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
import { revelarAlterarAdicionarLogin } from "../../../redux/actions/index";
//MODAIS
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import SelectPerfilVirtueyes from '../../componentesModais/selects/selectPerfilVirtueyes'
import ColetaClickstream from '../../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
import decryptJWT from '../../../utils/decryptJWT'

export default function AlterarLogin(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [dsPerfil, setDsPerfil] = useState(props.dsPerfil);
    const [cdPerfil, setCdPerfil] = useState(props.cdPerfil);
    const [customizado, setCustomizado] = useState(0);
    const [revelarSelectPerfil, setRevelarSelectPerfil] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [mensagemErro2, setMensagemErro2] = useState("Ocorreu um erro ao executar esta ação");
    
    const [nome, setNome] = useState(props.nome)
    const [sobrenome, setSobrenome] = useState(props.sobrenome)
    const [email, setEmail] = useState(props.email)
    const [telefone, setTelefone] = useState(props.telefone)
    const [usuarioAtivo, setUsuarioAtivo] = useState(true)
    

    //HOOKS MODAIS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();
    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    async function handleSalvar() {

        setSpinAtivo(true)
        let fl_ativo;
        if(usuarioAtivo) fl_ativo = 1
        else fl_ativo = 0
        await api.post('/m1/alterar/usuario', {
            "dados":{
                "ds_pav_usuario": nome,
                "ds_pav_usuario_sobrenome": sobrenome,
                "ds_email": email.trim(),
                "fl_ativo":fl_ativo,
                "ds_cpf":props.cpf,
                "ds_cnpj":props.cnpj,
                "ds_senha": "123456",
                "cd_pav_cliente": props.codigo_cliente,
                "cd_pav_usuario_updated": decryptJWT("codigo"),
                "cd_pav_perfil": cdPerfil, 
                "ds_telefone":telefone
            },
            "condicao":{
                'cd_pav_usuario': props.cdUsuario,
            }
        },{timeout:20000})
        .then(async function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro(error.response.data.info)
            setMensagemErro2("Ocorreu um erro ao alterar o usuario")
            handleMensagemErro(false)
        });
    }

    async function handleCancelar() {
        setSpinAtivo(false)
        despacho(revelarAlterarAdicionarLogin(false))
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [props.renderizar]);

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if (confirmacao) {
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
        }
        if (confirmacao === true) {
            //ALGUMA FUNÇÃO
        }
    }
    
    return (
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-adicionar-login-virtueyes fundo-cor-6">
                {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse" />
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Editar usuário</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela" />
                        </Button>
                    </Col>
                </Form.Row>

                <div className="adicionar-alterar-login-virtueyes-grupo">
                    <div className="adicionar-alterar-login-virtueyes-coluna-1">
                        <Form.Label>
                            <div className="fonte-cor-1 label-campo">Nome</div>
                        </Form.Label>
                    </div>
                    <div className="adicionar-alterar-login-virtueyes-coluna-2">
                        <Form.Label>
                            <div className="fonte-cor-1 label-campo">Sobrenome</div>
                        </Form.Label>
                    </div>
                </div>
                <div className="adicionar-alterar-login-virtueyes-grupo login-menu-virtueyes-espacador">
                    <div className="adicionar-alterar-login-virtueyes-coluna-1">
                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Nome</Tooltip>}>
                            <Form.Control
                                name="ds_nome"
                                className="campo-texto-cor-3 campo-texto"
                                maxlength="250"
                                placeholder={"Original: "+props.nome} 
                                value={nome}
                                onChange={e => setNome(e.target.value)}
                                ref={register({validate: {minimo: value => value.length > 1}})}
                            />
                        </OverlayTrigger>
                        {errors.ds_nome && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                    </div>
                    <div className="adicionar-alterar-login-virtueyes-coluna-2">
                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Sobrenome</Tooltip>}>
                            <Form.Control
                                name="ds_sobrenome"
                                className="campo-texto-cor-3 campo-texto"
                                maxlength="250"
                                placeholder={"Original: "+props.sobrenome} 
                                value={sobrenome}
                                onChange={e => setSobrenome(e.target.value)}
                                ref={register({validate: {minimo: value => value.length > 1}})}
                            />
                        </OverlayTrigger>
                        {errors.ds_sobrenome && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                    </div>
                </div>
                <div className="adicionar-alterar-login-virtueyes-grupo">
                        <Form.Label>
                            <div className="fonte-cor-1 label-campo">E-mail</div>
                        </Form.Label>
                </div>
                <div className="adicionar-alterar-login-virtueyes-grupo login-menu-virtueyes-espacador">
                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Email</Tooltip>}>
                            <Form.Control
                                name="ds_email"
                                className="campo-texto-cor-3 campo-texto"
                                maxlength="250"
                                placeholder={"Original: "+props.email} 
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                ref={register({validate: {minimo: value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) === true}})}
                            />
                        </OverlayTrigger>
                        {errors.ds_email && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Insira um email valido.</p>}
                </div>
                <div className="adicionar-alterar-login-virtueyes-grupo">
                    <div className="adicionar-alterar-login-virtueyes-coluna-1">
                        <Form.Label>
                            <div className="fonte-cor-1 label-campo">Telefone (opcional)</div>
                        </Form.Label>
                    </div>
                    <div className="adicionar-alterar-login-virtueyes-coluna-2">
                        <Form.Label>
                            <div className="fonte-cor-1 label-campo">Perfil</div>
                        </Form.Label>
                    </div>
                </div>
                <div className="adicionar-alterar-login-virtueyes-grupo">
                    <div className="adicionar-alterar-login-virtueyes-coluna-1">
                        <Form.Control
                            className="campo-texto-cor-3 campo-texto"
                            maxlength="250"
                            placeholder={"Original: "+props.telefone} 
                            value={telefone}
                            onChange={e => setTelefone(e.target.value)}
                        />
                    </div>
                    <div className="adicionar-alterar-login-virtueyes-coluna-2">
                            {revelarSelectPerfil === false &&
                                <Button name="cdPerfil" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectPerfil(!revelarSelectPerfil)}>
                                    <div className="fonte-cor-1 label-campo">{dsPerfil}</div>
                                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                </Button>
                            }
                            {revelarSelectPerfil === true &&
                                <SelectPerfilVirtueyes
                                    setRevelarSelectPerfil={setRevelarSelectPerfil}
                                    setCdPerfil={setCdPerfil}
                                    setDsPerfil={setDsPerfil}
                                    dsPerfil={dsPerfil}
                                    setCustomizado={setCustomizado}
                                >
                                </SelectPerfilVirtueyes>
                            }
                    </div>
                </div>
                <Form.Row>
                    <Col className="col-confirmar-cancelar-cadastro-usuario-cliente">
                        <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            {revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={'Usuário alterado com sucesso'}
                            mensagemParagrafo2={'Senha resetada para: 123456'}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemParagrafo2={mensagemErro2}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
