//IMPORTAÇÕES
import React, { useEffect, useRef, useState } from 'react';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Tooltip, OverlayTrigger, Col, Image } from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import {api} from '../../../conexoes/api';
//ICONES
import { elipseVerde, lupa, fecharJanela, setaDropdown } from '../../../utils/alternanciaIcones';
//COMPONENTES
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
import decryptJWT from '../../../utils/decryptJWT'

let destinatariosSelecionados = [];

export default function ConsultarSimcard(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [itens, setItens] = useState(props.envios)
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(0)
    const [conteudoBusca, setConteudoBusca] = useState('')
    const [filtro, setFiltro] = useState('vazio')
    const [filtrar, setFiltrar] = useState(false)
    const [renderizar, setRenderizar] = useState(true)
    const [revelarDropFiltros, setRevelarDropFiltros] = useState(false)
    const [liberarReenvio, setLiberarReenvio] = useState(false)
    //HOOKS MENSAGENS
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    //OUTROS HOOKS
    const modal = useRef();
    const [clickFora, setClickFora] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    
    const colunas = [{
        dataField: 'codigo',
        headerClasses: 'nao-selecionavel',
        text: 'Cód. cliente',
        sort: true,
        formatter: (cell, row) => {
            if(cell && cell != null && cell != undefined){
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    },
    {
        dataField: 'razao_social',
        headerClasses: 'nao-selecionavel',
        text: 'Razão social',
        sort: true,
        formatter: (cell, row) => {
            if(cell && cell.trim() != '' && cell != null && cell != undefined){
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    },
    {
        dataField: 'nome',
        headerClasses: 'nao-selecionavel',
        text: 'Nome',
        sort: true,
        formatter: (cell, row) => {
            if(cell && cell.trim() != '' && cell != null && cell != undefined){
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    },
    {
        dataField: 'sobrenome',
        headerClasses: 'nao-selecionavel',
        text: 'Sobrenome',
        sort: true,
        formatter: (cell, row) => {
            if(cell && cell.trim() != '' && cell != null && cell != undefined){
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    },
    {
        dataField: 'email',
        headerClasses: 'nao-selecionavel',
        text: 'E-mail',
        sort: true,
        formatter: (cell, row) => {
            if(cell.trim() != '' && cell != null && cell != undefined){
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    },
    {
        dataField: 'telefone',
        headerClasses: 'nao-selecionavel',
        text: 'Telefone',
        sort: true,
        formatter: (cell, row) => {
            if(cell && cell.trim() != '' && cell != null && cell != undefined){
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    },
    {
        dataField: 'enviado',
        headerClasses: 'nao-selecionavel',
        text: 'Status',
        sort: true,
        formatter: (cell, row) => {
            if(cell === 1){
                return (<div className="tabela-central-mensagens-container-esfera"><div className="tabela-central-mensagens-esfera-verde"/><p className="fonte-cor-1 label-tabela-1">Enviado</p></div>)
            }else if(cell === 0){
                return (<div className="tabela-central-mensagens-container-esfera"><div className="tabela-central-mensagens-esfera-vermelha"/><p className="fonte-cor-1 label-tabela-1">Não enviado</p></div>)
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    }]

    const selecaoLinhas = {
        mode: 'checkbox' ,
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionarDestinatario(row)
            }else{
                handleDesselecionarDestinatario(row)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if(isSelect){
                handleSelecionarTodosDestinatarios()
            }else{
                handleDesselecionarTodosDestinatarios()
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <div>
                    <input type={mode} class="input-checkbox-simcard" { ...rest }/>
                    <label class="label-checkbox-simcard"></label>
                </div>
            )
        },
        selectionHeaderRenderer: ({ mode, ...rest }) => {
            return (
                <div>
                    <input type={mode} class="input-checkbox-header-simcard" { ...rest }/>
                    <label class="label-checkbox-header-simcard"></label>
                </div>
            )
        },
        bgColor: 'row-index-bigger-than-2101'
    };

    function handleSelecionarTodosDestinatarios(){
        itens.map((item,i)=>{
            if(item.email){
                destinatariosSelecionados.push(itens[i]);
            }
        })
        setLiberarReenvio(true)
    }

    function handleDesselecionarTodosDestinatarios(){
        destinatariosSelecionados = []
        setLiberarReenvio(false)
    }

    function handleSelecionarDestinatario(destinatario){
        for (let i = 0; i < itens.length; i++) {
            if (itens[i].email === destinatario.email){
                destinatariosSelecionados.push(itens[i]);
                break;
            }
        }
        if (destinatariosSelecionados.length > 0){
            setLiberarReenvio(true)
        }
    }

    function handleDesselecionarDestinatario(destinatario){
        for (let i = 0; i < destinatariosSelecionados.length; i++) {
            if (destinatariosSelecionados[i].email === destinatario.email){
                destinatariosSelecionados.splice(i, 1);
                break;
            }
        }
        if (destinatariosSelecionados.length <=0){
            setLiberarReenvio(false)
        }
    }

    function dropFiltros(event, elementoId, imagemId){
        let elemento = document.getElementById(elementoId);
        let imagem = document.getElementById(imagemId);
        let imagemClasses = document.querySelectorAll('.drop-acoes-menu-icone-ativado');
        let classes =  document.querySelectorAll('.dropdown-ativado-filtros');

        if(elemento.classList.contains("dropdown-desativado-filtros")){
            if(classes.length > 0){
                classes.forEach(item => item.classList.remove("dropdown-desativado-filtros"));
                classes.forEach(item => item.classList.add("dropdown-ativado-filtros"));

                imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-desativado"));
                imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-ativado"));
            }
            elemento.classList.add("dropdown-ativado-filtros")
            elemento.classList.remove("dropdown-desativado-filtros")

            imagem.classList.add("drop-acoes-menu-icone-ativado")
            imagem.classList.remove("drop-acoes-menu-icone-desativado")
        }
        // se o filtro estiver ativado e for clicado em cima do mesmo filtro ele fecha
        else if(elemento.classList.contains("dropdown-ativado-filtros")){
            if(classes.length > 0){
                classes.forEach(item => item.classList.add("dropdown-desativado-filtros"));
                classes.forEach(item => item.classList.remove("dropdown-ativado-filtros"));

                imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-desativado"));
                imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-ativado"));
            }
            elemento.classList.add("dropdown-desativado-filtros")
            elemento.classList.remove("dropdown-ativado-filtros")

            imagem.classList.add("drop-acoes-menu-icone-desativado")
            imagem.classList.remove("drop-acoes-menu-icone-ativado")
        }

        if(elemento.classList.contains("dropdown-ativado-filtros")){
            if(classes.length > 0){
                classes.forEach(item => item.classList.add("dropdown-desativado-filtros"));
                classes.forEach(item => item.classList.remove("dropdown-ativado-filtros"));

                imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-desativado"));
                imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-ativado"));
            }
            classes.forEach(item => item.classList.add("dropdown-desativado-filtros"));
            classes.forEach(item => item.classList.remove("dropdown-ativado-filtros"));
        }
    }
    
    useEffect(() => {
        setSpinAtivo(true)
        handleLinhasSelecionadas()
        setSpinAtivo(false)
    }, [itens])
    
    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itens.length)
    }
    
    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
        }
    }
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };
    
    const handleClickDentro = () => setClickFora(false);

    async function handleCancelar() {
        setRevelarMensagemErro(false)
        props.setRevelarModalReenvio(false)
        props.setRenderizar(!props.renderizar)
    }

    async function handlePesquisar() {
        let auxBusca = []
        let auxFiltro = ''
        if(filtro === "false"){
            auxFiltro = false
        }
        if(filtro === "true"){
            auxFiltro = true
        }
        setSpinAtivo(true)
        if(conteudoBusca != '' && conteudoBusca != undefined){
            props.envios.map((envios) => {
                if(filtro != 'vazio'){
                    if((envios.codigo.split(conteudoBusca).length > 1 || envios.email.toUpperCase().split(conteudoBusca.toUpperCase()).length > 1 
                    || envios.nome.toUpperCase().split(conteudoBusca.toUpperCase()).length > 1 || envios.razao_social.toUpperCase().split(conteudoBusca.toUpperCase()).length > 1 
                    || envios.sobrenome.toUpperCase().split(conteudoBusca.toUpperCase()).length > 1 || envios.telefone.split(conteudoBusca).length > 1) && envios.enviado === auxFiltro){
                        auxBusca.push(envios)
                    }
                }else{
                    if((envios.codigo.split(conteudoBusca).length > 1 || envios.email.toUpperCase().split(conteudoBusca.toUpperCase()).length > 1 
                    || envios.nome.toUpperCase().split(conteudoBusca.toUpperCase()).length > 1 || envios.razao_social.toUpperCase().split(conteudoBusca.toUpperCase()).length > 1 
                    || envios.sobrenome.toUpperCase().split(conteudoBusca.toUpperCase()).length > 1 || envios.telefone.split(conteudoBusca).length > 1)){
                        auxBusca.push(envios)
                    }
                }
            })
            setItens(auxBusca)
        }else{
            if(filtro != 'vazio'){
                props.envios.map((envios) => {
                    if(envios.enviado === auxFiltro){
                        auxBusca.push(envios)
                    }
                })
                setItens(auxBusca)
            }else{
                setItens(props.envios)
            }
        }
        setSpinAtivo(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function carregarTabela(){
            setItens(props.envios)
        }
        carregarTabela()
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    useEffect(() => {
        
        let auxFiltrar = []
        let auxFiltro = ''
        if(filtro === "false"){
            auxFiltro = false
        }
        if(filtro === "true"){
            auxFiltro = true
        }
        if(filtrar === true){
            props.envios.map((envios) => {
                if(envios.enviado === auxFiltro){
                    auxFiltrar.push(envios)
                }
            })
            setItens(auxFiltrar)
        }else{
            setItens(props.envios)
        }
    }, [filtrar])

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function botaoPressionado(event) {
        if(event.key === "Enter"){
            handlePesquisar()
            setConteudoBusca(event.target.value)
            event.preventDefault()
        }
    }

    async function handleSalvar() {
        await api.post("m1/cadastrar/fila_mensagem", {
            email_remetente: decryptJWT("email"),
            conteudo:props.envios.conteudo,
            status: 1,
            nivel: props.envios.nivel,
            tipo: props.envios.tipo,
            envios: destinatariosSelecionados,
            exibirAoEntrar: props.envios.exibirAoEntrar,
            mensagemReduzida: props.envios.mensagemReduzida
        })
        .then(function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro("Erro na solicitação")
            handleMensagemErro(false)
        })
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-envios-central-mensagens fundo-cor-6">
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarDropFiltros === true && <div className="componente-modal-overlay" onClick={() => setRevelarDropFiltros(false)}/> }
            <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Envios</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-botoes-nova-mensagem-central-mensagens">
                            <div className="coluna-1-envios-central-mensagens">
                                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Pesquisar</Tooltip>}>
                                    <Button className="botao-barra-pesquisa-pesquisar fundo-cor-10" onClick={() => handlePesquisar()}>
                                        <Image className="icone-botao-barra-pesquisa-pesquisar" src={lupa()} />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                            <div className="coluna-2-envios-central-mensagens">
                                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Digite algo a ser pesquisado</Tooltip>}>
                                    <Form.Control onKeyDown={e => botaoPressionado(e)} className="campo-barra-pesquisa-modal-envios-central-mensagens fonte-cor-1 fundo-cor-10" type="text" value={conteudoBusca} onChange={(e) => setConteudoBusca(e.target.value)} placeholder={"Buscar por cód. cliente, razão social, nome, sobrenome, e-mail"}/>
                                </OverlayTrigger>
                            </div>
                            <div className="coluna-3-envios-central-mensagens">
                                <Button className="botao-filtro-central-mensagens fundo-cor-10" onClick={() => setRevelarDropFiltros(!revelarDropFiltros)}>
                                    <Form.Label  className="label-filtros-acoes-cabecalho-sistema fonte-cor-1">Filtros</Form.Label>
                                    <Image className="icone-filtros-cabecalho-sistema" src={"/icones/icone-filtro-verde.svg"} />
                                </Button>
                                { revelarDropFiltros === true &&
                                    <>
                                        <div className="container-filtros-central-mensagens">
                                            <div className="campo-select-triangulo-menor-cor-3"></div>
                                            <div className="drop-acoes-corpo fundo-cor-10">
                                                <div className="drop-acoes-lista-menu">
                                                    <div className="drop-filtros-menu" onClick={e => dropFiltros(e, "filtro-status","imagem-filtro-status")}>
                                                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Satus </Form.Label>
                                                        <img id={"imagem-filtro-status"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                                                    </div>
                                                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                                                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                                                        <label className="label-radio-filtros" onChange={() => setFiltro("true")}>
                                                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Enviado<div className="filtro-central-mensagens-importancia-esfera-verde"/></div>
                                                            <input defaultChecked={filtro === "true" ? true : false} type="radio" id="STATUS_ENVIADO" name="status" value="3"/>
                                                            <span className="checkbox-filtros campo-texto-cor-2"></span>
                                                        </label>
                                                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                                                        <label className="label-radio-filtros" onChange={() => setFiltro("false")}>
                                                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Não enviado<div className="filtro-central-mensagens-importancia-esfera-vermelha"/></div>
                                                            <input defaultChecked={filtro === "false" ? true : false} type="radio" id="STATUS_NAO_ENVIADO" name="status" value="1"/>
                                                            <span className="checkbox-filtros campo-texto-cor-2"></span>
                                                        </label>
                                                    </div>
                                                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                                                    <div className="botao-central-filtros">
                                                        <Form.Row>
                                                            <Col>
                                                                <Button disabled={filtro != 'vazio' ?false:true} onClick={() => (setFiltrar(true), setItens([]))} className={filtro != 'vazio' ?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                                                    Filtrar
                                                                </Button>
                                                                <Button disabled={filtro != 'vazio' ?false:true} onClick={() => (setFiltrar(false), setFiltro("vazio"), setItens([]))} className={filtro != 'vazio' ?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                                                    Limpar
                                                                </Button>
                                                            </Col>
                                                        </Form.Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-tabela-nova-mensagem-central-mensagens">
                            <BootstrapTable 
                                classes="tabela-transparente-modais"
                                keyField='email'
                                condensed={true}
                                data={itens}
                                columns={colunas}
                                selectRow={selecaoLinhas}
                                rowEvents={eventosLinhas}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                bootstrap4={true}
                            />
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} usuário(s) selecionado(s)</span>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="campo-select-divisor-cor-1 nova-editar-regra-automacao-divisor"></div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {liberarReenvio === false ?
                            <Button className="botao-confirmar-desabilitado" size="sm">
                                <div className="fonte-cor-4">Reenviar</div>
                            </Button>
                        :
                            <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                <div className="fonte-cor-4">Reenviar</div>
                            </Button>
                        } 
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Mensagens reenviadas"}
                            mensagemBotao={"Finalizar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={mensagemErro}
                            mensagemParagrafo1={"Algo deu errado!"}
                            mensagemParagrafo2={"Por favor, tente mais tarde."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}