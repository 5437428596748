import { api_svdispositivos, api } from "../../conexoes/api";
import { requestResolver } from "../../utils/requestResolver";

export const OrdersService = {
  postOrder: (body) => {
    return requestResolver(
      api_svdispositivos.post(`/api/v2/whitechip/iccids/order`, body)
    );
  },
  getCarriersOrder: () => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/iccids/order/carriers`)
    );
  },
  getPlansOrder: (CdOperadora) => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/iccids/order/plan`, {
        params: { CdOperadora },
      })
    );
  },
  getSimcardsTypeOrder: (CdOperadora) => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/iccids/order/simcardType`, {
        params: { CdOperadora },
      })
    );
  },
  getAddress: (CdCliente) => {
    return requestResolver(
      api.get(`/m1/consultar/cliente_endereco_entrega`, {
        params: { cd_pav_cliente: CdCliente },
      })
    );
  },
  getUf: () => {
    return requestResolver(
      api.get(`/m1/consultar/uf`)
    );
  },
  getCep: (cep) => {
    return requestResolver(
      api.get(`/m1/consultar/cep`, {
        params: { cep },
      })
    );
  },
};
