export function formatarMilharReais(numero) 
{
    if(numero === 0){
        return '0,00'
    }
    if(numero && !isNaN(numero)){
        numero = numero.toFixed(2).split('.');
        numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }
}