//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import {identificarValidacaoTesteConexao, identificarValidacaoAcoes} from '../../../utils/identificarValidacaoAcoes';/*eslint-disable*/
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';

//ICONES
import {elipseVerde, fecharJanela, exclamacao, alertaAtencao} from '../../../utils/alternanciaIcones';
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector } from 'react-redux';
import { revelarTesteConexao } from "../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
// COLETA CLICKSTREAM
// import ColetaClickstream from '../../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../utils/paginacaoTabela'

import RetornarParametro from '../../../utils/decryptJWT'

import decryptJWT from '../../../utils/decryptJWT'
import { getColunasTesteConexao } from './columnInfo';

import '../../../css/painelDispositivos/testeConectividade/teste.css';
import Countdown from './Countdown.jsx';
import ExportarRelatorioTeste from './ExportarRelatorioTeste.jsx';


const valorLimite = 50
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let simcardSelecionados = [];
let itensVar = []
let voltouPagina = false;

const routeM1FiltrarSimcard = '/m1/filtrar/simcard';

export default function Manutencao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    // const [dsMotivo, setDsMotivo] = useState("");
    const [pagina2, setPagina2] = useState(false);
    const [colunas, setColunas] = useState([]);
    const [textoConfirmar, setTextoConfirmar] = useState('Avançar');
    const [caracteresMensagem, setCaracteresMensagem] = useState(1000);
    const [renderizar, setRenderizar] = useState(false);
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1)
    const [linhaRemover, setLinhaRemover] = useState('');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [simcards, setSimcards] = useState([]);
    const [linhas, setLinhas] = useState([]);
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [tituloMensagemAlerta, setTituloMensagemAlerta] = useState('')
    const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    // const [voltouPagina, setVoltouPagina] = useState(false)
    const [itens, setItens] = useState([])
    const [itensPag2, setItensPag2] = useState([])
    const [itensInvalidos, setItensInvalidos] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensInvalidosBusca, setItensInvalidosBusca] = useState(false);
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const [jaResetou, setJaResetou] = useState(false);
    const [segundosEspera, setSegundosEspera] = useState(0);
    const [mensagemRodape, setMensagemRodape] = useState('');
    const [testouTodos, setTestouTodos] = useState(false);
    
    const despacho = useDispatch();
    const modal = useRef();

    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    const codigoCliente = RetornarParametro('codigoClienteErpAntigo')
    const codigosClientes = RetornarParametro('codigoCliente')

    let itensTemp = []
    let itensInvalidosTemp = []

    const iconeAlerta = <img className="icone-alerta mr-1" src={exclamacao()} alt='exclamacao'/>;
    useEffect(() => {
        setSpinAtivo(true)
        async function preencherTabela (){
            let arraySim = itens.length > 0 ? itens : props.itensSelecionados;

            if(props.totalItensPreSelecao <=valorLimite && arraySim?.length === 0) {
                await handlerConsultarSimcadsBusca();
                arraySim = itens;
            }
            const validacao = await identificarValidacaoTesteConexao(arraySim, props.totalItensPreSelecao); // selecionou parque todo ou apenas algumas linhas
            if(!validacao) {
                setSpinAtivo(false)
                return;
            }
            if(modoSelecaoSimcard){
                itensTemp = validacao.itensValidos
                // setItens(itensTemp)
                itensInvalidosTemp = validacao.itensInvalidos
                setItensInvalidos(validacao.itensInvalidos)

                if(itensInvalidosTemp.length > 0){
                    await Promise.all(
                        validacao.itensInvalidos.map((item_validacao,i)=>{
                            simcardSelecionados += item_validacao.CD_SIMCARD + ','
                        })
                    )
                    setItensInvalidosBusca(simcardSelecionados)
                    setSimcards(validacao.itensValidos)
                }
                else{
                    handlerConsultarSimcadsBusca()
                }
            }
            else{
                setItens(validacao.itensValidos)
                setItensInvalidos(validacao.itensInvalidos)
                itensTemp = validacao.itensValidos
                itensInvalidosTemp = validacao.itensInvalidos
                handleIdSimcards();
                setItensInvalidosBusca(true)
            }
            setTituloMensagemAlerta(`${!itensTemp.length > 0 ? 'Todas as' : itensInvalidosTemp.length} linhas foram retiradas`);
            handleLinhasSelecionadasIncapacitadas();
            setSpinAtivo(false)
         }
         preencherTabela()
        
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]); 

    
    async function contarItens(){
        let arrayItens = []
        let apiData = [];

        if (controladorPagina <= paginaAtual ) { 
            const urlGet = `${routeM1FiltrarSimcard}?acao=true&buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=true&itensInvalidosBusca=${itensInvalidosBusca}`
            apiData =  await api.get(urlGet)
            .then(function(resposta){
                return resposta;
            })
            .catch(function(erro){
                throw erro;
            })

            // apiData = await api.post(routeM1FiltrarSimcard,{
            //     "itensInvalidosBusca":itensInvalidosBusca,
            //     "buscar":conteudoBusca,
            //     "separador":separadorLinha,
            //     "filtros":filtros,
            //     "contar":true,
            //     "acao":true,
            // })
        }

        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }


    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const colunasItensInvalidos = [{
        dataField: 'CALLERID',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        hidden: (itensInvalidos.length === 0 || !itensInvalidos[0].CALLERID),
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'ds_motivo',
        text: 'Motivo',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    ]

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.CD_SIMCARD);
        }
    }

    // function handleMotivo(motivo){
    //     setDsMotivo(motivo)
    //     setCaracteresMensagem(1000 - motivo.length)
    // }
    
    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if(itensTemp.length === 0){
            handleCancelar()
        }
    }

     //verifica se o usuario selecionou linhas extras que nao sao permitidas na acao e aciona um alerta
     function handleLinhasSelecionadasIncapacitadas(){
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if(itensInvalidosTemp.length > 0){
            setRevelarMensagemAtencaoInvalido(true)
        }
    }

    async function handleRemoverLinha(CD_SIMCARD){
        setRevelarMensagemAtencao(false)
        if(modoSelecaoSimcard){
            let simcardNaoSelecionados = itensInvalidosBusca;
            if(itensInvalidosBusca){
                simcardNaoSelecionados = itensInvalidosBusca;
            }
            let simcardSelecionados = simcards
            if(simcardNaoSelecionados === undefined || simcardNaoSelecionados === null || simcardNaoSelecionados === false){
                simcardNaoSelecionados = CD_SIMCARD + ","
            }
            else{
                simcardNaoSelecionados += CD_SIMCARD + ","
            }
            setItensInvalidosBusca(simcardNaoSelecionados)

            for (let i = contador; i < simcardSelecionados.length; i++) {
                if(simcardSelecionados[i] == CD_SIMCARD){
                    simcardSelecionados.splice(i, 1)
                }
            }

            await Promise.all(
                itensVar.map((item_itensVar,i)=>{
                    if(CD_SIMCARD == item_itensVar.CD_SIMCARD){
                        itensVar.splice(i, 1);
                    }
                })
            )
            await contarItens();
            setItens(itensVar)
            setSimcards(simcardSelecionados)
        }
        else{
            itensTemp = itens
            itens.forEach(function(e, index){
                if(CD_SIMCARD == e.CD_SIMCARD){
                    itens.splice(index, 1);
                }
            })
            setItens(itensTemp)
            handleIdSimcards()
            handleLinhasSelecionadas()
        }
                    
    }

    const getStrGsmGprs = (strStart, dado) => { // quando mudar qualquer coisa aqui, tomar cuidado com a função linhaOfflineOuTesteFalhou
        if(dado === `${strStart}_UP`) {
            return `${strStart} Up`;
        } else if(dado === `${strStart}_DOWN`) {
            return `${strStart} Down`;
        } else {
            return dado;
        }
    }

    async function getDadosTesteConexaoDe2Em2() {
        try {
            let novosItens = itens;
            let limite = linhas.length;
            if(limite === 0) limite = 1;
            for(let i=0; i<limite; i++) { // de 2 em 2
                const auxLinhas = linhas[i];
                let linhasStr = auxLinhas.toString();

                // colocar || !pagina2 na linha abaixo ?
                if(!linhasStr || simcardSelecionados.length === 0 || voltouPagina) break; // parar de buscar quando não existem mais linhas OU quando fecha o modal

                await api.get('/m1/consultar/gsm_gprs?msisdn='+linhasStr)
                .then(function (response) {
                    // setSpinAtivo(false)
                    // handleMensagemSucesso(false)
                    const dadosResposta = response?.data?.dados.map(item => JSON.parse(item));
                    const arrayTratado = [];
                    if(response?.status == 200 && dadosResposta) {
    
                        for(let i=0; i<dadosResposta.length; i ++) { // tratar cada item do array
                            const item = dadosResposta[i];
                            const key = i%2 === 0 ? 'gsm' : 'gprs';
    
                            const newObj = { msisdn: item.msisdn };
    
                            if(item[key]) {
                                    const valor = item[key];
                                    newObj[key] = valor === 'ERROR' ? 'Erro na consulta' : valor;
                            } else {
                                newObj[key] = 'Erro na consulta'; // foi definido essa ser sempre a "mensagem padrão" para erro
                                // if(item.status === 404) {
                                //     newObj[key] = 'Linha não encontrada';
                                // }
                            }
                            arrayTratado.push(newObj);
                        }
    
                        const dadosTratados = [];
                        for(let i=0; i<arrayTratado.length; i+=2) {
                            const item1 = arrayTratado[i];
                            const item2 = arrayTratado[i+1];
                            if(item1.msisdn === item2.msisdn) {
                                dadosTratados.push({ ...item1, ...item2 })
                            }
                        }
    
                        novosItens = novosItens.map(simcard => {
                            const find = dadosTratados.find(resp => {
                                if(simcard.FULLCALLERID == resp.msisdn) {
                                    return true;
                                }
                            })
                            if(find) {
                                return {
                                    ...simcard,
                                    gsm: getStrGsmGprs('GSM', find.gsm),
                                    gprs: getStrGsmGprs('GPRS', find.gprs)
                                }
                            } else {
                                return simcard;
                            }
                        })
                        setItensPag2(novosItens);
                    }
                })
                .catch(function (error) {
                    console.error({ error });
                    // setSpinAtivo(false)
                    // handleMensagemErro(false)
                });
            }

            setTestouTodos(true);
        } catch(erro) {
            console.error(erro);
        }

    }

    async function handleSalvar(){
        if(pagina2) {
            handleCancelar()
            return;
        }

        setPagina2(true);
        setItensPag2(itens);
        setColunas(getColunasTesteConexao(true, false));
        setTextoConfirmar('Concluir');
        voltouPagina = false;

        // setSpinAtivo(true)

        getDadosTesteConexaoDe2Em2();
    }

    async function handleCancelar() {
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
        itensVar = []
        itensInvalidosTemp = []
        simcardSelecionados = []
        despacho(revelarTesteConexao(false))
    }

    function inserirData(data){
        // setItens('')
        if(itensVar.length == totalItens){
            for(let i=0;i<totalItens;i++){
                if (contador === i){
                    let k = i
                    for(let j=0;j<data.length;j++){
                        itensVar[k] = data[j]
                        k++
                    }
                }
                if(i == (totalItens-1)){
                    itensTemp = JSON.parse(JSON.stringify(itensVar))
                    setItens(JSON.parse(JSON.stringify(itensVar))) // seleciona parque todo ?
                    handleIdSimcards()
                }
            }
            handleLinhasSelecionadas()
        }
    }
    
    function handleIdSimcards(){
        let tempSimcards = []
        let tempLinhas = []
        for (let i = 0; i < itensTemp.length; i++) {
            tempSimcards.push(itensTemp[i].CD_SIMCARD)
            tempLinhas.push(itensTemp[i].FULLCALLERID)
        }
        simcardSelecionados = tempSimcards 
        setLinhas(tempLinhas)
        setSimcards(tempSimcards)
    }

    async function handlerConsultarSimcadsBusca(){
        if(props.totalItensPreSelecao > valorLimite || (props.itens?.length > 0 || props.itensSelecionados.length > 0)) {
            return; // não é para consultar, porque já foi definido que o limite é 50 "para teste de coneectividade". se tirar o return, vai gerar muitos bugs
        }

        if(totalPaginas == 0){
            contador = 0;
            itensVar = [];
            setItens([])
            await contarItens(); 
        }
        else{
            contador = (paginaAtual - 1) * valorLimite;
        }
        if (controladorPagina <= paginaAtual) {
            setSpinAtivo(true)

            const urlGet = `${routeM1FiltrarSimcard}?paguinaAtual=${contador}&carregarLimit=${valorLimite}&acao=true&buscar=${conteudoBusca}&ordenador=${props.ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false&cd_pav_usuario=${decryptJWT('codigo')}&itensInvalidosBusca=${itensInvalidosBusca}`
            const consultaSimcards =  await api.get(urlGet)
            .then(function(resposta){
                return resposta.data.dados;
            })
            .catch(function(erro){
                throw erro;
            })
            
            // const consultaSimcards = await api.post(routeM1FiltrarSimcard,{
            //     "itensInvalidosBusca":itensInvalidosBusca,
            //     "buscar":conteudoBusca,
            //     "separador":separadorLinha,
            //     "carregarLimit":valorLimite,
            //     "paguinaAtual":contador,
            //     "filtros":filtros,
            //     "contar":false,
            //     "acao":true
            // },{timeout:20000})
            // .then(function(resposta){
            //     return resposta.data.dados;
            // })
            // .catch(function(erro){
            //     throw erro.stack;
            // })

            setSpinAtivo(false)
            inserirData(consultaSimcards)
        }
    }
    
    useEffect(()=>{
        if(modoSelecaoSimcard && itensInvalidosBusca) { //&& itensInvalidosBusca !== true){
            handlerConsultarSimcadsBusca()
        }
    },[itensInvalidosBusca,paginaAtual])

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao ===true){
                handleRemoverLinha(linhaRemover)
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            // handleCancelar()
        }
        // if(confirmacao){ // fecha modal
        //     props.setRenderizar(!props.renderizar)
        // }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    function handleFecharMensagemItensInvalidos(){
        setRevelarMensagemAtencaoInvalido(!revelarMensagemAtencaoInvalido)
        if(modoSelecaoSimcard){
            if(itens <= 0){
                handleCancelar()
            }
        }
        else{
            if(quantidadeLinhasSelecionados <= 0){
                handleCancelar()
            }
        }
    }

    const getColunasPagina1 = () => setColunas(getColunasTesteConexao(false, handleMensagemAtencao));
    useEffect(() => {
        getColunasPagina1();
    }, [])

    function voltarPagina() {
        setPagina2(false);
        voltouPagina = true;
        getColunasPagina1();
        setTextoConfirmar('Avançar');
        setTestouTodos(false);
    }

    function setValoresColunaReset(auxItensPag2, arrReferencia, valorReset) {
        // let auxItensPag2 = [ ...itensPag2 ];
        const getDsMotivo = (ds_motivo) => ds_motivo === 'Ultima solicita foi em menos de 6h' ? 'Excedeu limite' : ds_motivo;

        auxItensPag2 = auxItensPag2.map(itemP2 => {
            const filtrou = arrReferencia.filter(arrRef => arrRef.CD_SIMCARD === itemP2.CD_SIMCARD)
            if(filtrou?.length > 0) {
                return { ...itemP2, ENVIOU_SOLICITACAO_RESET: valorReset || getDsMotivo(filtrou[0].ds_motivo) }
            }
            return itemP2;
        })
        setItensPag2(auxItensPag2);
        return auxItensPag2;
    }

    async function resetarLinhasComErro(){
        let auxMensagemReset = '';

        setSpinAtivo(true)

        const testeCarregou = (item) => item.gsm && item.gprs;
        const linhaOfflineOuTesteFalhou = (item) => item.gsm != 'GSM Up' || item.gprs != 'GPRS Up';  // teste falhou ou linha está offline
        const linhasOff = itensPag2.filter(item => testeCarregou(item) && linhaOfflineOuTesteFalhou(item))

        const validacao = await identificarValidacaoAcoes(linhasOff,4,{filtros,buscaArquivo},modoSelecaoSimcard,conteudoBusca,separadorLinha)
        const { itensValidos, itensInvalidos } = validacao;
        const auxItensP2 = setValoresColunaReset(itensPag2, itensInvalidos)
        const qtdLinhasReset = itensValidos.length;

        if(qtdLinhasReset > valorLimite) {
            return setSpinAtivo(false);
        }

        if(qtdLinhasReset === 0) {
            auxMensagemReset = 'Nenhuma linha foi enviada para Reset.';
            if(itensInvalidos?.length === 1 && itensInvalidos[0].ds_motivo) {
                auxMensagemReset += ' Motivo: ' + itensInvalidos[0].ds_motivo;
            }
            setMensagemRodape(auxMensagemReset)
            return setSpinAtivo(false);
        }

        setSegundosEspera(qtdLinhasReset * 3);

        const bodyReset = {
            'fl_operadora': 0,
            'simcards' : itensValidos.map(item => item.CD_SIMCARD), //simcards,
            'FULLCALLERID': itensValidos.map(item => item.FULLCALLERID), //linhas,
            'cd_crm_solicitacao_tipo' : 4, // Reset
            'cd_crm_solicitacao_status' :  1,
            'ds_crm_solicitacao' : 'Resetar linhas VIVO com erro ou GSM/GPRS Down, após Teste de Conectividade', //dsMotivo,
            'dt_inicio' :  moment().format('YYYY-MM-DD HH:mm:ss'),
            'dt_fim' :  undefined,
            'dt_previsao' :  undefined,
            ds_rota_permissao:"veye/dispositivos/acoes/solicitarReset",
        }

        const getTextoPlural = (qtd, texto) => `${texto}${qtd > 1 ? 's' : ''}`; // ex: (qtd=1, texto=linha) => "linha" / (qtd=2, texto=liha) => "linhas"

        const qtdInvalidos = itensInvalidos.length;
        if(qtdInvalidos > 0) {
            auxMensagemReset += qtdInvalidos + ` ${getTextoPlural(qtdInvalidos, 'linha')} ${getTextoPlural(qtdInvalidos, 'inválida')} para Reset.`
        }

        await api.post('/m1/cadastrar/solicitacao', bodyReset)
        .then(function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
            setJaResetou(true);

            const qtdValidos = itensValidos.length;
            setValoresColunaReset(auxItensP2, itensValidos, 'OK')
            auxMensagemReset += ` Solicitação de Reset enviada para ${qtdValidos} ${getTextoPlural(qtdValidos, 'linha')}.`;
        })
        .catch(function (error) {
            setSpinAtivo(false)
            handleMensagemErro(false)
            setValoresColunaReset(auxItensP2, itensValidos, 'Erro')
            auxMensagemReset += ' Erro ao enviar solicitação de Reset.'
        });

        setMensagemRodape(auxMensagemReset);
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-reset fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Teste de conectividade</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-tabela-modal">
                        {colunas.length > 0 && (
                            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <>
                                            <BootstrapTable
                                                classes="tabela-transparente-modais"
                                                condensed={true}
                                                keyField='id' 
                                                data={pagina2 ? itensPag2 : itens} 
                                                columns={colunas}
                                                rowEvents={eventosLinhas}
                                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                bootstrap4={true}
                                                { ...paginationTableProps }
                                            />
                                            {modoSelecaoSimcard &&
                                                <div className="paguinacao-tabela-modal">
                                                    <PaginacaoTabela
                                                        extrato={true}
                                                        setPaginaAtual={setPaginaAtual}
                                                        totalPaginas={totalPaginas}
                                                        totalItens={totalItens}
                                                        valorLimite={valorLimite}
                                                        paginaAtual={paginaAtual}
                                                        page={paginationProps.page}
                                                        onPageChange={paginationProps.onPageChange}
                                                    />
                                                </div>
                                            }
                                        </>
                                    )
                                }
                            </PaginationProvider>
                        )}
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} linhas selecionadas</span>
                    </Col>
                </Form.Row>
                <Form.Row className="teste-conectividade-box-botoes">
                    <Col>
                        <Form.Group>
                            {/* {<p className="fonte-cor-3 label-alerta">{iconeAlerta} Selecione no máximo 50 linhas.</p>} */}
                            {mensagemRodape && <p className="fonte-cor-3 label-alerta">{iconeAlerta} {mensagemRodape}</p>}

                            {pagina2 && (
                                <>
                                    <ExportarRelatorioTeste itensPag2={itensPag2} testouTodos={testouTodos} />
                                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className=" tooltip-informacoes" id={'tooltip-bottom'}>Reseta todas as linhas que apresentam falha na conexão</Tooltip>}>
                                    <Button onClick={resetarLinhasComErro} disabled={jaResetou || !testouTodos} className="botao-confirmar" variant="success" size="sm">
                                        <div className="fonte-cor-4">Resetar Linhas</div> {/* Resetar linhas com erro de conectividade */}
                                    </Button>
                                    </OverlayTrigger>
                                </>
                            )}
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">{textoConfirmar}</div> 
                        </Button>
                        {segundosEspera ? <Button className="botao-cancelar botao-voltar-desabilitado" size="sm" disabled={true}>
                                <Countdown
                                    invisible
                                    segundosEspera={segundosEspera}
                                    setSegundosEspera={setSegundosEspera}
                                />
                                <div className="botao-voltar-desabilitado-texto">Voltar</div>
                            </Button>
                             : (
                            <Button className="botao-cancelar" variant="success" size="sm" onClick={() => {
                                if(!pagina2) {
                                    handleCancelar()
                                } else { // voltar para página anterior no modal
                                    voltarPagina();
                                    // const auxItensZerados = [...itens].map(item => { // zerar valores de 'teste de conectividade'
                                    //     delete item.gsm;
                                    //     delete item.gprs;
                                    // });
                                    // setItens([itens[0]])
                                }
                            }}>
                                <div className="fonte-cor-2">{pagina2 ? 'Voltar' : 'Cancelar'}</div>
                            </Button>
                        )}
                    </Col>
                </Form.Row>
            </div>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={`Solicitação de Reset enviada. Por favor, efetue o teste novamente após ${segundosEspera} segundos.`} // Sua solicitação foi enviada. 
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                            </div>
                            <div className="fonte-cor-1 label-janela">{tituloMensagemAlerta}</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensInvalidos}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensInvalidos()}>
                                    <div className="fonte-cor-2">OK</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
    )
}