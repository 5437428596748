import React from "react";
import { elipseVerde } from "../../../utils/alternanciaIcones";
import { Form, Col } from "react-bootstrap";
import Preview from "./preview";

export default function Detalhamento({ cdSolicitacao }) {
  return (
    <>
      <div>
        <Col>
          <img
            className="icone-label-detalhamento-solicitacao"
            src={elipseVerde()}
            alt="elipse"
          />
          <Form.Label>
            <div className="fonte-cor-1 label-janela-financeiro">
              Detalhamento
            </div>
          </Form.Label>
        </Col>
        <Preview cdSolicitacao={cdSolicitacao} />
      </div>
    </>
  );
}
