//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import { api } from '../../../../conexoes/api';
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';/*eslint-disable*/
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, fecharJanela, exclamacao, setaDropdown} from '../../../../utils/alternanciaIcones'
//MODAIS
import SelectUf from '../../../componentesModais/selects/selectUf2'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../../css/painelFaturas/fatEmpresas/tabela.css'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarNovaEmpresa, revelarEditarEmpresa } from "../../../../redux/actions/index";
import decryptJWT from '../../../../utils/decryptJWT';

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'
const axios = require('axios');

export default function CadastroEmpresa(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    const [dsCepValidador, setDsCepValidador] = useState(false)
    const [dsCepErroInfo, setDsCepErroInfo] = useState('')
    //HOOKS PRINCIPAIS
    const [cdEmpresa, setcdEmpresa] = useState()
    const [dsNomeFantasia, setDsNomeFantasia] = useState()
    const [dsRazaoSocial, setDsRazaoSocial] = useState()
    const [dsCnpj, setDsCnpj] = useState("")
    const [dsEmail, setDsEmail] = useState()
    const [dsTelefone, setDsTelefone] = useState("")
    const [dsNumero, setDsNumero] = useState("")
    const [dsCep, setDsCep] = useState("")
    const [dsLogradouro, setDsLogradouro] = useState()
    const [dsBairro, setDsBairro] = useState()
    const [dsCidade, setDsCidade] = useState()
    const [cdCep, setCdCep] = useState()
    const [cdLogradouro, setCdLogradouro] = useState()
    const [cdBairro, setCdBairro] = useState()
    const [cdCidade, setCdCidade] = useState()
    const [dsUf, setDsUf] = useState()
    const [cdUf, setCdUf] = useState()
    const [cdEndereco, setCdEndereco] = useState()
    const [dsComplemento, setDsComplemento] = useState()
    const [dsInformacoesAdicionais, setDsInformacoesAdicionais] = useState()
    //HOOKS PLACEHOLDERS
    const [phNomeFantasia, setPhNomeFantasia] = useState()
    const [phRazaoSocial, setPhRazaoSocial] = useState()
    const [phCnpj, setPhCnpj] = useState()
    const [phEmail, setPhEmail] = useState()
    const [phTelefone, setPhTelefone] = useState()
    const [phCep, setPhCep] = useState()
    const [phLogradouro, setPhLogradouro] = useState()
    const [phNumero, setPhNumero] = useState()
    const [phBairro, setPhBairro] = useState()
    const [phCidade, setPhCidade] = useState()
    const [phUf, setPhUf] = useState()
    const [phComplemento, setPhComplemento] = useState()
    const [phInformacoesAdicionais, setPhInformacoesAdicionais] = useState()
    const [phMensagemSucesso, setPhMensagemSucesso] = useState('cadastrada')
    //HOOKS MODAIS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarSelectUf, setRevelarSelectUf] = useState(false);
    //OUTROS HOOKS
    const [tituloJanela, setTituloJanela] = useState('Cadastro');
    const [caracteresMensagem, setCaracteresMensagem] = useState(500);
    const [clickFora, setClickFora] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const modal = useRef();
    const despacho = useDispatch();

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    useEffect(() => {
        setSpinAtivo(true)
        if (props.tituloJanela === 'Editar'){
            //TROCA O TITULO DA JANELA
            setTituloJanela('Editar')
            //CARREGA OS CAMPOS COM OS VALORES ORIGINAIS
            setcdEmpresa(props.cdEmpresa)
            setDsNomeFantasia(props.dsNomeFantasia)
            setDsRazaoSocial(props.dsRazaoSocial)
            setDsCnpj(props.dsCnpj)
            setDsEmail(props.dsEmail)
            setDsTelefone(props.dsTelefone)
            setDsNumero(props.dsNumero)
            setDsCep(props.dsCep)
            setDsLogradouro(props.dsLogradouro)
            setDsBairro(props.dsBairro)
            setDsCidade(props.dsCidade)
            setCdCep(props.cdCep)
            setCdLogradouro(props.cdLogradouro)
            setCdBairro(props.cdBairro)
            setCdCidade(props.cdCidade)
            setDsUf(props.dsUf)
            setCdUf(props.cdUf)
            setCdEndereco(props.cdEndereco)
            setDsInformacoesAdicionais(props.dsInformacoesAdicionais)
            setDsComplemento(props.dsComplemento)
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhNomeFantasia('Original: '+props.dsNomeFantasia)
            setPhRazaoSocial('Original: '+props.dsRazaoSocial)
            setPhCnpj('Original: '+props.dsCnpj)
            setPhEmail('Original: '+props.dsEmail)
            setPhTelefone('Original: '+props.dsTelefone)
            setPhCep('Original: '+props.dsCep)
            setPhLogradouro('Original: '+props.dsLogradouro)
            setPhNumero('Original: '+props.dsNumero)
            setPhBairro('Original: '+props.dsBairro)
            setPhCidade('Original: '+props.dsCidade)
            setPhComplemento('Original: '+props.dsComplemento)
            setPhUf(props.dsUf)
            setPhInformacoesAdicionais('Original: '+props.dsInformacoesAdicionais)
            setPhMensagemSucesso('editado')
        }
        if (props.tituloJanela === 'Cadastrar'){
            setTituloJanela('Cadastrar')
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhNomeFantasia('Digite o nome fantasia')
            setPhRazaoSocial('Digite a razão social')
            setPhCnpj('00.000.000/0000-00')
            setPhEmail('email@email.com.br')
            setPhTelefone('(00)000000000')
            setPhCep('Digite o cep')
            setPhLogradouro('Digite o logradouro')
            setPhNumero('000')
            setPhBairro('Digite o bairro')
            setPhCidade('Digite a cidade')
            setPhUf('Selecione')
            setDsUf('Selecione')
            setPhInformacoesAdicionais('Outras informações')
            setPhComplemento('Complemento')
            setPhMensagemSucesso('cadastrado')
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    //FAZ O MANEJO 
    function handleSalvar(){
        if (tituloJanela === 'Cadastrar'){
            handleCadastrar()
        }else{
            handleAlterar()
        }
    }

    async function handleAlterar(){
        ColetaClickstream(1271) // fatEmpresasTabelaDropAcoesRevelarNovaEmpresaAlterar
        setSpinAtivo(true)
        await api.post('/m1/alterar/fat_empresa', {
            "dados":{
                'ds_razao_social': dsRazaoSocial,
                'ds_nome_fantasia': dsNomeFantasia,
                'ds_cnpj': dsCnpj,
                'ds_email': dsEmail,
                'ds_telefone': dsTelefone,
                'ds_pav_logradouro': dsLogradouro,
                'n0_numero': dsNumero,
                'ds_pav_bairro': dsBairro,
                'ds_pav_cidade': dsCidade,
                'ds_pav_cep': dsCep,
                'cd_pav_uf': cdUf,
                'cd_pav_pais': 1,
                'ds_complemento': dsComplemento,
                'ds_observacoes': dsInformacoesAdicionais,
                'cd_pav_usuario': decryptJWT('codigo')
            },
            "condicao":{
                'cd_pav_cep' : cdCep,
                'cd_pav_logradouro' : cdLogradouro,
                'cd_pav_bairro' : cdBairro,
                'cd_pav_cidade' : cdCidade,
                'cd_fat_endereco' : cdEndereco,
                'cd_fat_empresa' : cdEmpresa	
            }
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    async function handleCadastrar(){
        ColetaClickstream(1270) // fatEmpresasTabelaDropAcoesRevelarNovaEmpresaCadastrar
        setSpinAtivo(true)
      

        await api.post('/m1/cadastrar/fat_empresa', {
            'ds_razao_social': dsRazaoSocial,
            'ds_nome_fantasia': dsNomeFantasia,
            'ds_cnpj': dsCnpj,
            'ds_email': dsEmail,
            'ds_telefone': dsTelefone,
            'ds_pav_logradouro': dsLogradouro,
            'n0_numero': dsNumero,
            'ds_pav_bairro': dsBairro,
            'ds_pav_cidade': dsCidade,
            'ds_pav_cep': dsCep,
            'cd_pav_uf': cdUf,
            'cd_pav_pais': 1,
            'ds_complemento': dsComplemento,
            'ds_observacoes': dsInformacoesAdicionais,
            'cd_pav_usuario': decryptJWT('codigo'),
            "cd_pav_cliente": decryptJWT('codigoCliente')
        },)
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    function handleCancelar(){
        ColetaClickstream(1269) // fatEmpresasTabelaDropAcoesRevelarNovaEmpresaCancelar
        despacho(revelarNovaEmpresa(false))
        despacho(revelarEditarEmpresa(false))
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
        props.setRenderizar(!props.renderizar)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }

    async function handleCnpj(cnpj){
        setSpinAtivo(true)
        await axios.get(`https://cors-anywhere.herokuapp.com/http://www.receitaws.com.br/v1/cnpj/`+dsCnpj)
        .then(function (resposta) {
            if(!resposta.data.status == "ERRO"){
                setDsNomeFantasia(resposta.data.fantasia)
                setDsRazaoSocial(resposta.data.nome)
                setDsEmail(resposta.data.email)
                setDsTelefone(resposta.data.telefone)
                let auxCep = resposta.data.cep
                auxCep = auxCep.replace("-","")
                auxCep = auxCep.replace(".","")
                handlerConsultarCep(auxCep)
            } 
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
        });
    }

    async function handlerConsultarCep(cep){
        await api.get('/m1/consultar/cep?cep='+cep)
        .then(function (response) {
            if(response.data.status == 200){
                if(response.data.dados == 'Cep Invalido'){
                    setDsCepValidador(true)
                    setDsCepErroInfo(response.data.dados)
                    setDsBairro('')
                    setDsCidade('')
                    setDsUf('Selecione')
                    setCdUf(-1)
                    setDsLogradouro('')
                }
                else{
                    setDsCep(cep)
                    setDsBairro(response.data.dados.bairro)
                    setDsCidade(response.data.dados.localidade)
                    setDsUf(response.data.dados.uf)
                    setCdUf(response.data.dados.cd_pav_uf)
                    setDsLogradouro(response.data.dados.logradouro)
                }
            }
            return response
        })
        .catch(function (error) {
            if(error == 'Error: Request failed with status code 400'){
                setDsCepValidador(true)
                setDsCepErroInfo("Cep Invalido")
            }
            else{
                setDsCepValidador(true)
                setDsCepErroInfo("Erro interno")
            }
            return error
        })
    }

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-cnpj"){
                setDsCnpj(valor)
            }
            if(id === "campo-texto-telefone"){
                setDsTelefone(valor)
            }
            if(id === "campo-texto-cep"){
                setDsCep(valor)
            }
            if(id === "campo-texto-numero"){
                setDsNumero(valor)
            }
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-cadastro-fornecedor-faturas fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{tituloJanela} empresa</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-fornecedor-faturas-1">
                            <div className="coluna-cadastro-fornecedor-faturas-3">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">CNPJ</div> </Form.Label>
                                    <Form.Control name="dscnpj" value={dsCnpj} className="campo-texto-cor-3 campo-texto" type="text" placeholder={phCnpj} onChange={e => validarNumero("campo-texto-cnpj", e.target.value)} onBlur={e=> (handleCnpj(e.target.value))}
                                    ref={register({ required: true, pattern: /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[\-]?[0-9]{2})/ })} maxlength="14"/>
                                    {errors.dscnpj && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O CNPJ deve ser informado no seguinte formato 00.000.000/0000-00</p>}
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-fornecedor-faturas-1">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Nome fantasia</div> </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        value={dsNomeFantasia}
                                        placeholder={phNomeFantasia}
                                        onChange={e => setDsNomeFantasia(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-fornecedor-faturas-2">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Razão social</div> </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        value={dsRazaoSocial}
                                        placeholder={phRazaoSocial}
                                        onChange={e => setDsRazaoSocial(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-fornecedor-faturas-1">
                            <div className="coluna-cadastro-fornecedor-faturas-4">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">E-mail</div> </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        value={dsEmail}
                                        placeholder={phEmail}
                                        onChange={e => setDsEmail(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-fornecedor-faturas-5">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Telefone</div> </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        value={dsTelefone}
                                        placeholder={phTelefone}
                                        onChange={e => validarNumero("campo-texto-telefone", e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-fornecedor-faturas-1-1">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">CEP</div> </Form.Label>
                                    <OverlayTrigger key="top" delay={{ show: 500, hide: 250 }}placement="top" overlay={<Tooltip id={`tooltip-top`}>Cep</Tooltip>}>
                                        <Form.Control name="ds_cep" value={dsCep} className="campo-texto-cor-3 campo-texto" type="text" placeholder={phCep} onChange={e => validarNumero("campo-texto-cep", e.target.value)}
                                        ref={register({ required: true, min: 3, max: 9 })} onBlur={(e) => handlerConsultarCep(e.target.value)} maxlength="9"/>
                                    </OverlayTrigger>
                                    {dsCepValidador && <p className="fonte-cor-3 label-alerta">{iconeAlerta} {dsCepValidador?dsCepErroInfo:"O campo é obrigatorio."}</p>}
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-fornecedor-faturas-1">
                            <div className="coluna-cadastro-fornecedor-faturas-7">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Endereço</div> </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        value={dsLogradouro}
                                        placeholder={phLogradouro}
                                        onChange={e => setDsLogradouro(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-fornecedor-faturas-8">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Número</div> </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        value={dsNumero}
                                        placeholder={phNumero}
                                        onChange={e => validarNumero("campo-texto-numero", e.target.value)}

                                    />
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-fornecedor-faturas-6-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Complemento</div>
                                    </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        value={dsComplemento}
                                        placeholder={phComplemento}
                                        onChange={e => setDsComplemento(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-fornecedor-faturas-1">
                            <div className="coluna-cadastro-fornecedor-faturas-9">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Bairro</div> </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        value={dsBairro}
                                        placeholder={phBairro}
                                        onChange={e => setDsBairro(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-fornecedor-faturas-10">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Cidade</div> </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        value={dsCidade}
                                        placeholder={phCidade}
                                        onChange={e => setDsCidade(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-fornecedor-faturas-11">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Estado</div> </Form.Label>
                                    { revelarSelectUf === false &&
                                        <Button  className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectUf(!revelarSelectUf)}>
                                            <div className="fonte-cor-1 label-campo">{dsUf}</div>
                                            <img className={revelarSelectUf?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectUf === true &&
                                        <SelectUf
                                            setRevelarSelectUf={setRevelarSelectUf}
                                            setCdUf={setCdUf}
                                            setDsUf={setDsUf}
                                            dsUf={dsUf}
                                            phUf={phUf}
                                        >
                                        </SelectUf>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-fornecedor-faturas-1">
                            <div className="coluna-cadastro-fornecedor-faturas-12">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Informações adicionais (opcional)</div> </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        value={dsInformacoesAdicionais}
                                        placeholder={phInformacoesAdicionais}
                                        onChange={e => setDsInformacoesAdicionais(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <div className="campo-select-divisor-cor-1 divisoria-cadastro-operadoras-faturas"></div>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {(dsRazaoSocial != '' && dsNomeFantasia != '' && dsCnpj != '' && dsEmail != '' &&
                        dsTelefone != '' && dsLogradouro != '' && dsNumero != '' && dsBairro != '' && 
                        dsCidade != '' && dsCep != '' && cdUf != '') ?
                            <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                <div className="fonte-cor-4">{tituloJanela}</div>
                            </Button>
                        :
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Preencha a Razão Social, Nome Fantasia, CNPJ, E-mail, Telefone, Endereço, Número, Bairro, Cidade, Cep e Estado.</Tooltip>}>
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">{tituloJanela}</div>
                                </Button>
                            </OverlayTrigger>
                        }
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Empresa "+ dsRazaoSocial +" "+ phMensagemSucesso +"."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}