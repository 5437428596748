//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import { api } from '../../../conexoes/api';
import { useForm } from "react-hook-form";/*eslint-disable*/
import { ProgressBar, Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { formatarData } from '../../../utils/formatadorDataTabela'
//ICONES
import { elipseVerde, fecharJanela, exclamacao, lixeiraVermelho, setaLado, setaDropdown, alertaAtencao } from '../../../utils/alternanciaIcones';
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import { revelarEnviarSms } from "../../../redux/actions/index";
import SelectTemplateSms from '../../componentesModais/selects/selectTemplateSms'
//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../utils/paginacaoTabela'
// import decryptJWT from '../../../utils/decryptJWT'
import utilsSms from '../../../utils/sms';

const valorLimite = 50
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let simcardSelecionados = [];
let itensVar = []

const routeM1FiltrarSimcard = '/m1/filtrar/simcard';

export default function SmsEnviar(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [dsConta, setDsConta] = useState("Selecione");
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1);
    const [linhaRemover, setLinhaRemover] = useState('');
    const [renderizar, setRenderizar] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [simcards, setSimcards] = useState([]);
    const [simcardsLinhas, setSimcardsLinhas] = useState([]);
    const [switchHabilitarResposta, setSwitchHabilitarResposta] = useState(false);
    const [dsMensagem, setDsMensagem] = useState('')
    const [dsTemplateSms, setDsTemplateSms] = useState('Selecione')
    const [saldoReal, setSaldoReal] = useState(0)
    const [creditosNecessarios, setCreditosNecessarios] = useState(0)
    const [caracteresMensagem, setCaracteresMensagem] = useState(120);
    //HOOKS MODAIS
    const [revelarSelectTemplateSms, setRevelarSelectTemplateSms] = useState(false)
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] = useState(false)
    const [revelarMensagemAtencaoContemItemInvalido, setRevelarMensagemAtencaoContemItemInvalido] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [itens, setItens] = useState([])
    const [itensInvalidos, setItensInvalidos] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensInvalidosBusca, setItensInvalidosBusca] = useState('');
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();
    const mensagemLinhasRecusadas = "Algumas linhas já tem uma solicitação igual em aberto ou o contrato delas não permite esta ação!"
    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao' />;
    const cdSmsContaUsuario = utilsSms.getCdSmsContaUsuario(); 
    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    // const codigoCliente = decryptJWT('codigoClienteErpAntigo')

    let itensTemp = []
    let itensInvalidosTemp = []

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    const colunasItensInvalidos = [{
        dataField: 'CALLERID',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'ds_motivo',
        text: 'Motivo',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },]

    const colunas = [{
        dataField: 'CD_SIMCARD',
        text: 'Código',
        headerClasses: 'nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'OPERADORA',
        text: 'Operadora',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'CALLERID',
        headerClasses: 'nao-selecionavel',
        text: 'Linha',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ICCID',
        headerClasses: 'nao-selecionavel',
        text: 'ICCID',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'PLANO',
        headerClasses: 'nao-selecionavel',
        text: 'Plano',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell} MB</p>;
        }
    },
    {
        dataField: 'CONSUMO_PERCENTUAL',
        text: 'Consumo (%)',
        headerClasses: 'centralizar nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            let styleProgressBar = '';
            if (!cell || cell == '')
                cell = 0;

            styleProgressBar = '#36D896'

            return (
                <ProgressBar className='barra-progresso-container'>
                    <ProgressBar style={{ 'backgroundColor': styleProgressBar }} now={cell} />
                    <span className="texto-barra-progresso fonte-cor-1">{cell === undefined ? '0.00%' : cell.toFixed(2) + '%'}</span>
                </ProgressBar>
            )
        },
    },
    {
        dataField: 'ULTIMO_EVENTO',
        headerClasses: 'nao-selecionavel',
        text: 'Último evento',
        sort: true,
        formatter: (cell, row) => {
            if (cell) {
                cell = cell.replace('T', ' ').split('.')
                if (cell.length > 0) {
                    cell = cell[0]
                }
            }
            return formatarData(cell);
        }
    },
    {
        dataField: 'acoes',
        text: '',
        sort: false,
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha" />
                </Button>
            )
        }
    }]

    function handleCreditosGastos(habilitarResposta) {
        if (simcardsLinhas) {
            if (habilitarResposta != undefined) {
                let creditosGastos = simcardsLinhas.length * (habilitarResposta === true ? 2 : 1);
                setCreditosNecessarios(creditosGastos)
            }
            else {
                let creditosGastos = simcardsLinhas.length * (switchHabilitarResposta === true ? 2 : 1);
                setCreditosNecessarios(creditosGastos)
            }

        }
        else
            setCreditosNecessarios(0)
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.CD_SIMCARD);
        }
    }

    function handleMotivo(motivo) {
        setDsMensagem(motivo)
        setCaracteresMensagem(120 - motivo.length)
    }

    function handleLinhasSelecionadas() {
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if (itensTemp.length === 0) {
            handleCancelar()
        }
    }

    //verifica se o usuario selecionou linhas extras que nao sao permitidas na acao e aciona um alerta
    function handleLinhasSelecionadasIncapacitadas() {
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if (itensInvalidosTemp.length > 0) {
            setRevelarMensagemAtencaoInvalido(true)
        }
    }

    async function handleRemoverLinha(CD_SIMCARD) {
        ColetaClickstream(1135) // enviarSMSRemoverLinha
        setRevelarMensagemAtencao(false)
        if (modoSelecaoSimcard) {
            let simcardNaoSelecionados = itensInvalidosBusca;
            if (itensInvalidosBusca) {
                simcardNaoSelecionados = itensInvalidosBusca;
            }
            let simcardSelecionados = simcards
            if (simcardNaoSelecionados === undefined || simcardNaoSelecionados === null || simcardNaoSelecionados === false) {
                simcardNaoSelecionados = CD_SIMCARD + ","
            }
            else {
                simcardNaoSelecionados += CD_SIMCARD + ","
            }
            setItensInvalidosBusca(simcardNaoSelecionados)

            for (let i = contador; i < simcardSelecionados.length; i++) {
                if (simcardSelecionados[i] == CD_SIMCARD) {
                    simcardSelecionados.splice(i, 1)
                }
            }

            await Promise.all(
                itensVar.map((item_itensVar, i) => {
                    if (CD_SIMCARD == item_itensVar.CD_SIMCARD) {
                        itensVar.splice(i, 1);
                    }
                })
            )
            await contarItens();
            setItens(itensVar)
            setSimcards(simcardSelecionados)
        }
        else {
            itensTemp = itens
            itens.forEach(function (e, index) {
                if (CD_SIMCARD == e.CD_SIMCARD) {
                    itens.splice(index, 1);
                }
            })
            setItens(itensTemp)
            handleIdSimcards()
            handleLinhasSelecionadas()
        }

    }

    async function handleEnviar() {
        ColetaClickstream(1137) // enviarSMSEnviar
        setSpinAtivo(true)
        // if (creditosNecessarios < saldoReal) {

            const bodyEnviarSms = utilsSms.getNewBodyPost({
                'lista_callerIds': simcardsLinhas,
                'ds_mensagem': dsMensagem,
                'cd_sms_tipo': switchHabilitarResposta == true ? 2 : 1,
                'cd_sms_conta_usuario_created': cdSmsContaUsuario
            })

            await api.post('/m1/cadastrar/smsEnvio', bodyEnviarSms)
                .then(function (response) {
                    setRevelarMensagemSucesso(true)
                    setSpinAtivo(false)
                })
                .catch(function (error) {
                    setMensagemErro(error.response.data.info)
                    setRevelarMensagemErro(true)
                    setSpinAtivo(false)
                });
        // } else {
        //     setMensagemErro("Créditos insuficientes")
        //     setRevelarMensagemErro(true)
        //     setSpinAtivo(false)
        // }
    }

    async function handleCancelar() {
        ColetaClickstream(1136) // enviarSMSCancelar
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
        itensVar = []
        itensInvalidosTemp = []
        simcardSelecionados = []
        setSpinAtivo(false)
        despacho(revelarEnviarSms(false))
    }

    function inserirData(data) {
        // setItens('')
        if (itensVar.length == totalItens) {
            for (let i = 0; i < totalItens; i++) {
                if (contador === i) {
                    let k = i
                    for (let j = 0; j < data.length; j++) {
                        itensVar[k] = data[j]
                        k++
                    }
                }
                if (i == (totalItens - 1)) {
                    itensTemp = JSON.parse(JSON.stringify(itensVar))
                    setItens(JSON.parse(JSON.stringify(itensVar)))
                    handleIdSimcards()
                }
            }
            handleLinhasSelecionadas()
        }
    }


    function handleIdSimcards() {
        let tempSimcards = []
        for (let i = 0; i < itensTemp.length; i++) {
            tempSimcards.push(itensTemp[i].CD_SIMCARD)
        }
        simcardSelecionados = tempSimcards
        setSimcards(tempSimcards)
    }

    function handleLinhasSimcards(consultaSimcards) {
        let tempLinhas = []
        for (let i = 0; i < consultaSimcards.length; i++) {
            tempLinhas.push(consultaSimcards[i].CALLERID)
        }
        setSimcardsLinhas(tempLinhas)
        setCreditosNecessarios(tempLinhas.length)
    }

    async function handlerConsultarSimcadsBusca() {
        if (totalPaginas == 0) {
            contador = 0;
            itensVar = [];
            setItens([])
            await contarItens();
        }
        else {
            contador = (paginaAtual - 1) * valorLimite;
        }
        if (controladorPagina <= paginaAtual) {
            setSpinAtivo(true)

            const urlGet = `${routeM1FiltrarSimcard}?paguinaAtual=${contador}&carregarLimit=${valorLimite}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=,&contar=false`
            const consultaSimcards = await api.get(urlGet)
                .then(function (resposta) {
                    return resposta.data.dados;
                })
                .catch(function (erro) {
                    throw erro;
                })

            setSpinAtivo(false)
            inserirData(consultaSimcards)
        }
    }

    async function handleConsultarSimcardsBuscaLinha() {
        if (totalPaginas == 0) {
            contador = 0;
            itensVar = [];
            setItens([])
            await contarItens();
        }
        else {
            contador = (paginaAtual - 1) * valorLimite;
        }
        if (controladorPagina <= paginaAtual) {
            setSpinAtivo(true)

            const urlGet = `${routeM1FiltrarSimcard}?paguinaAtual=${contador}&acao=true&carregarLimit=${valorLimite}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=,&contar=false`
            const consultaSimcards = await api.get(urlGet)
                .then(function (resposta) {
                    return resposta.data.dados;
                })
                .catch(function (erro) {
                    throw erro;
                })

            setSpinAtivo(false)
            handleLinhasSimcards(consultaSimcards)
        }
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function preencherTabela() {
            if (modoSelecaoSimcard) {
                handlerConsultarSimcadsBusca()
                handleConsultarSimcardsBuscaLinha()

            }
            else {
                setItens(props.itensSelecionados)
                itensTemp = props.itensSelecionados
                handleIdSimcards();
                handleLinhasSimcards(props.itensSelecionados)
                handleLinhasSelecionadas()
            }
            handleLinhasSelecionadasIncapacitadas();
            setSpinAtivo(false)
        }
        preencherTabela()

        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    useEffect(() => {
        async function carregarSaldo() {
            setSpinAtivo(true)
            const query = utilsSms.getNewQueryGet("paginaAtual=0&carregarLimit=1")
            await api.get('/m1/consultar/smsExtrato?' + query)
                .then(function (response) {
                    if (response.data.status == 200 && response.data.dados[0] != undefined){
                        setSaldoReal(response.data.dados[0].n0_saldo_real)
                    } else {
                        setSaldoReal(0);
                    }
                })
            setSpinAtivo(false)
        }
        carregarSaldo()

        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    async function contarItens() {
        setSpinAtivo(true)
        let arrayItens = [];
        let apiData = [];
        if (controladorPagina <= paginaAtual) {
            const urlGet = `${routeM1FiltrarSimcard}?acao=true&buscar=${itensInvalidosBusca}&separador=,&contar=true&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}`
            apiData = await api.get(urlGet)
        }

        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for (var i = 0; i < apiData.data.dados[0].CONTADOR; i++) {
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    // async function contarItens(){
    //     let arrayItens = []
    //     let apiData = [];
    //     if (controladorPagina <= paginaAtual ) { 
    //         apiData = await api.post('/m1/consultar/simcard',{
    //             "like":itensInvalidosBusca,
    //             "contar":true,
    //             "acao":true,
    //             "separador":','
    //         })
    //     }
    //     totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
    //     totalItens = apiData.data.dados[0].CONTADOR;
    //     for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
    //         arrayItens.push(i)
    //     }
    //     itensVar = arrayItens
    // }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange: (page) => { setPaginaAtual(page) }
    }

    function handleMensagemAtencao(confirmacao) {
        if (revelarMensagemAtencao === false) {
            setRevelarMensagemAtencao(true)
        } else {
            setRevelarMensagemAtencao(false)
            if (confirmacao === true) {
                handleRemoverLinha(linhaRemover)
            }
        }
    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if (confirmacao) {
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
        }
        if (confirmacao === true) {
            //ALGUMA FUNÇÃO
        }
    }

    function handleFecharMensagemItensInvalidos() {
        setRevelarMensagemAtencaoInvalido(!revelarMensagemAtencaoInvalido)
        if (modoSelecaoSimcard) {
            if (simcards <= 0) {
                handleCancelar()
            }
        }
        else {
            if (quantidadeLinhasSelecionados <= 0) {
                handleCancelar()
            }
        }
    }

    return (
        <Form ref={modal} onSubmit={handleSubmit(() => handleEnviar())}>
            <div className="modal-acao-enviar-sms fundo-cor-6">
                {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse" />
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Enviar SMS</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela" />
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-tabela-modal">
                            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <>
                                            <BootstrapTable
                                                classes="tabela-transparente-modais"
                                                condensed={true}
                                                keyField='id'
                                                data={itens}
                                                columns={colunas}
                                                rowEvents={eventosLinhas}
                                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                bootstrap4={true}
                                                {...paginationTableProps}
                                            />
                                            {modoSelecaoSimcard &&
                                                <div className="paguinacao-tabela-modal">
                                                    <PaginacaoTabela
                                                        extrato={true}
                                                        setPaginaAtual={setPaginaAtual}
                                                        totalPaginas={totalPaginas}
                                                        totalItens={totalItens}
                                                        valorLimite={valorLimite}
                                                        paginaAtual={paginaAtual}
                                                        page={paginationProps.page}
                                                        onPageChange={paginationProps.onPageChange}
                                                    />
                                                </div>
                                            }
                                        </>
                                    )
                                }
                            </PaginationProvider>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} linhas selecionadas</span>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-enviar-sms-1">
                            <div className="coluna-painel-sms-enviar-sms-1">
                                <div className="container-switch-cancelamento-reposicao">
                                    <div class="flipswitch">
                                        <input type="checkbox" name="flipswitch" class="flipswitch-cb" id="permitirConfirmacao" onChange={() => (setSwitchHabilitarResposta(!switchHabilitarResposta), handleCreditosGastos(!switchHabilitarResposta))} />
                                        <label class="flipswitch-label" for="permitirConfirmacao">
                                            <div class="flipswitch-inner flipswitch-cor-1"></div>
                                            <div class="flipswitch-switch"></div>
                                        </label>
                                    </div>
                                    <div className="fonte-cor-1 label-normal label-termos-cancelamento-reposicao-1"> Habilitar resposta</div>
                                </div>
                            </div>
                            <div className="coluna-enviar-sms-3">
                                <Form.Label>
                                    <div className="fonte-cor-1 label-campo">Template </div>
                                </Form.Label>
                                {revelarSelectTemplateSms === false &&
                                    <Button name="cdTemplateSms" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTemplateSms(!revelarSelectTemplateSms)}>
                                        <div className="fonte-cor-1 label-campo">{dsTemplateSms}</div>
                                        <img className={revelarSelectTemplateSms ? "campo-select-icone-ativado nao-selecionavel" : "campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                                {revelarSelectTemplateSms === true &&
                                    <SelectTemplateSms
                                        setRevelarSelectTemplateSms={setRevelarSelectTemplateSms}
                                        setDsMensagem={setDsMensagem}
                                        setDsTemplateSms={setDsTemplateSms}
                                        dsTemplateSms={dsTemplateSms}
                                        cd_sms_conta_usuario={cdSmsContaUsuario}
                                    >
                                    </SelectTemplateSms>
                                }
                            </div>
                            <div className="coluna-painel-sms-enviar-sms-3">
                                <div className="grupo-painel-sms-enviar-sms-2">
                                    <div>
                                        <span className="label-valor-cancelamento-reposicao-1 fonte-cor-1 negrito">Créditos necessários: </span>
                                        <span className="label-valor-cancelamento-reposicao-2 fonte-cor-3 negrito">{creditosNecessarios}</span>
                                    </div>
                                    <div>
                                        <span className="label-valor-cancelamento-reposicao-1 fonte-cor-1 negrito">Créditos disponíveis: </span>
                                        <span className="label-valor-cancelamento-reposicao-2 fonte-cor-2 negrito">{saldoReal} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Mensagem</div>
                            </Form.Label>
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Digite sua mensagem</Tooltip>}>
                                <Form.Control name="dsMensagem" value={dsMensagem} className="campo-texto-cor-3 campo-texto-area" as="textarea"
                                    placeholder="Digite sua mensagem"
                                    rows="3" onChange={e => handleMotivo(e.target.value)}
                                    ref={register({ required: true })} maxlength="120" />
                            </OverlayTrigger>
                            {errors.titulo && <p className="texto-campo-obrigatorio">{iconeAlerta} O campo é obrigatorio.</p>}
                            <Form.Label className="contador-carateres-restantes">
                                <div className="fonte-cor-1 label-normal">{caracteresMensagem} caracteres restantes</div>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button type="submit" className="botao-confirmar" variant="success" size="sm" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}>
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            {revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"SMS Enviado."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemAtencaoInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso" />
                            </div>
                            <div className="fonte-cor-1 label-janela">{itensInvalidos.length} linhas foram retiradas</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensInvalidos}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4={true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensInvalidos()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
    )
}