//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
//ICONES
import {elipseVerde, fecharJanela, exclamacao} from '../../../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
//MODAIS
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'

import decryptJWT from '../../../../utils/decryptJWT'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//CSS
import '../../../../css/painelFaturas/fatCicloVida/tabela.css'
import { revelarNovoCicloVida, revelarEditarCicloVida } from '../../../../redux/actions';

export default function NovaEmpresa(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");

    const [cdCicloVida, setCdCicloVida] = useState(-1);
    const [status, setStatus] = useState()
    const [statusOperadora, setStatusOperadora] = useState()
    const [switchStatusInicial, setSwitchStatusInicial] = useState(false)
    const [switchStatusAtivo, setSwitchStatusAtivo] = useState(false)
    const [switchFatura, setSwitchFatura] = useState(false)
    const [switchAutomacao, setSwitchAutomacao] = useState(false)
    const [switchCancelamento, setSwitchCancelamento] = useState(false)
    const [tituloJanela, setTituloJanela] = useState('Nova');
    
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const despacho = useDispatch();
    const modal = useRef();
    

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        setSpinAtivo(true)
        if (props.tituloJanela === 'Editar'){
            //TROCA O TITULO DA JANELA
            setTituloJanela('Editar')
            //CARREGA OS CAMPOS COM OS VALORES ORIGINAIS
            setCdCicloVida(props.cdCicloVida)
            setStatus(props.status)
            setStatusOperadora(props.statusOperadora)
            setSwitchStatusInicial(props.statusInicial)
            setSwitchStatusAtivo(props.statusAtivo)
            setSwitchFatura(props.fatura)
            setSwitchAutomacao(props.automacao)
            setSwitchCancelamento(props.cancelamento)
        }
        if (props.tituloJanela === 'Cadastrar'){
            setTituloJanela('Cadastrar')
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function handleSalvar(){
        setSpinAtivo(true)
        if(props.tituloJanela === 'Editar'){
            ColetaClickstream(1302) // dropAcoesRevelarEditarCicloVidaEditar
            await api.post('/m1/alterar/fat_ciclo_vida', {
                "dados":{
                    "ds_status": status, 
                    "ds_status_operadora": statusOperadora, 
                    "fl_status_ativo": switchStatusAtivo ? 1 : 0,
                    "fl_status_inicial": switchStatusInicial ? 1 : 0, 
                    "fl_fatura": switchFatura ? 1 : 0, 
                    "fl_automacao": switchAutomacao ? 1 : 0, 
                    "fl_cancelamento": switchCancelamento ? 1 : 0,
                    "fl_padrao": '0',
                    "cd_pav_usuario": decryptJWT('codigo')
                },
                "condicao":{
                    "cd_fat_ciclo_vida": cdCicloVida
                }
            },{"timeout": 20000})
            .then(function (response) {
                setSpinAtivo(false)
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            });
        }else{
            ColetaClickstream(1301) // fatCicloVidaTabelaDropAcoesNovoCicloVidaCadastrar
            await api.post('/m1/cadastrar/fat_ciclo_vida', {
                "ds_status": status, 
                "ds_status_operadora": statusOperadora, 
                "fl_status_ativo":switchStatusAtivo ? 1 : 0,
                "fl_status_inicial": switchStatusInicial ? 1 : 0, 
                "fl_fatura": switchFatura ? 1 : 0, 
                "fl_automacao": switchAutomacao ? 1 : 0, 
                "fl_cancelamento": switchCancelamento ? 1 : 0,
                "fl_padrao": '0',
                "cd_pav_usuario": decryptJWT('codigo'),
                "cd_pav_cliente": decryptJWT('codigoCliente')
            },{"timeout": 20000})
            .then(function (response) {
                setSpinAtivo(false)
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            });
        }
    }

    async function handleCancelar() {
        ColetaClickstream(1300) // fatCicloVidaTabelaDropAcoesNovoCicloVidaCancelar
        setSpinAtivo(false)
        despacho(revelarNovoCicloVida(false))
        despacho(revelarEditarCicloVida(false))
    }
    


    useEffect(() => {
        setSpinAtivo(true)
        async function preencherTela(){
          
        }
        preencherTela()
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao ===true){
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            props.setRenderizar(!props.renderizar)
            handleCancelar()
        }
        if(confirmacao){
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-faturas-ciclo-vida fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{tituloJanela} ciclo de vida</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row className="usuario-dados-corpo-ciclo-de-vida-2">
                    <div className="corpo-dados-conta-numero-conta">
                        <Form.Label> <div className="fonte-cor-1 label-campo">Status</div> </Form.Label>
                        <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="bottom" overlay={<Tooltip className={'tooltip-campos-fatura'} id={`tooltip-bottom`}>Digite o status</Tooltip>}> 
                            <Form.Control
                                className="campo-texto-cor-3 campo-texto"
                                type="text"
                                maxLength="30"
                                name="ds_status_status"
                                value={status}
                                onChange={e => setStatus(e.target.value)}
                                placeholder="Digite o status"
                                ref={register({ required: true, min: 3})}
                            />
                        </OverlayTrigger>
                        {errors.ds_status_status && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                    </div>
                    <div className="corpo-dados-conta-numero-conta">
                        <Form.Label> <div className="fonte-cor-1 label-campo">Status da operadora</div> </Form.Label>
                        <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="bottom" overlay={<Tooltip className={'tooltip-campos-fatura'} id={`tooltip-bottom`}>Digite o status da operadora</Tooltip>}> 
                            <Form.Control
                                className="campo-texto-cor-3 campo-texto"
                                type="text"
                                maxLength="30"
                                name="ds_status_operadoraa"
                                value={statusOperadora}
                                onChange={e => setStatusOperadora(e.target.value)}
                                placeholder="Digite o status da operadora"
                                ref={register({ required: true, min: 3})}
                            />
                        </OverlayTrigger>
                        {errors.ds_status_operadoraa && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                    </div>
                </Form.Row>
                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo-conta"/>
                <Form.Row className="usuario-dados-corpo-ciclo-de-vida">
                    <div className="container-principal-switch">
                        <div className="container-coluna-switches-1">
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Regula se este Ciclo de Vida pode ser associado à Simcards ao importar um Pedido</Tooltip>}>
                                <div className="container-termo-adesao">
                                    <div class="switchDiv">
                                        <div  class="flipswitch-grupo">
                                            <input checked={switchStatusInicial} id="switchStatusInicial" type="checkbox" name="flipswitch" class="flipswitch-cb" onChange={(e) => setSwitchStatusInicial(e.target.checked)}/>
                                            <label for="switchStatusInicial" class="flipswitch-label">
                                                <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                <div class="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo fonte-cor-1"> Status inicial </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                            
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Regula se Simcards neste Ciclo de Vida são considerados ativos</Tooltip>}>
                                <div className="container-termo-adesao">
                                    <div class="switchDiv">
                                        <div  class="flipswitch-grupo">
                                            <input checked={switchStatusAtivo} id="switchStatusAtivo" type="checkbox" name="flipswitch" class="flipswitch-cb" onChange={(e) => setSwitchStatusAtivo(e.target.checked)}/>
                                            <label for="switchStatusAtivo" class="flipswitch-label">
                                                <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                <div class="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo fonte-cor-1"> Status ativo </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                            
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Regula se Simcards neste Ciclo de Vida devem faturar</Tooltip>}>
                                <div className="container-termo-adesao">
                                    <div class="switchDiv">
                                        <div  class="flipswitch-grupo">
                                            <input checked={switchFatura} id="switchFatura" type="checkbox" name="flipswitch" class="flipswitch-cb" onChange={(e) => setSwitchFatura(e.target.checked)}/>
                                            <label for="switchFatura" class="flipswitch-label">
                                                <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                <div class="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo fonte-cor-1"> Fatura </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </div>
                        <div className="container-coluna-switches-2">
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Regula se Simcards neste Ciclo de Vida deve participar dos processos de automação e agendamento</Tooltip>}>
                                <div className="container-termo-adesao">
                                    <div class="switchDiv">
                                        <div  class="flipswitch-grupo">
                                            <input checked={switchAutomacao} id="switchAutomacao" type="checkbox" name="flipswitch" class="flipswitch-cb" onChange={(e) => setSwitchAutomacao(e.target.checked)}/>
                                            <label for="switchAutomacao" class="flipswitch-label">
                                                <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                <div class="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo fonte-cor-1"> Automação </div>
                                    </div>
                                </div>
                            </OverlayTrigger>

                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Regula se Simcards neste Ciclo de Vida são considerados cancelados</Tooltip>}>
                                <div className="container-termo-adesao">
                                    <div class="switchDiv">
                                        <div  class="flipswitch-grupo">
                                            <input checked={switchCancelamento} id="switchCancelamento" type="checkbox" name="flipswitch" class="flipswitch-cb" onChange={(e) => setSwitchCancelamento(e.target.checked)}/>
                                            <label for="switchCancelamento" class="flipswitch-label">
                                                <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                <div class="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo fonte-cor-1"> Cancelamento </div>
                                    </div>
                                </div>
                            </OverlayTrigger>

                        </div>
                    </div>
                </Form.Row>
               <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo-conta"/>
                <Form.Row className="container-botoes-cancelar-confirmar">
                    <Col>
                        <>
                            {(status != '' && statusOperadora != '') ?
                                <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">{tituloJanela}</div>
                                </Button>
                            :
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">{tituloJanela}</div>
                                </Button>
                            }
                            <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2">Cancelar</div>
                            </Button>
                        </>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Ciclo de vida cadastrado."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}