//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';
import {api} from '../../conexoes/api';
import {Image, Button} from 'react-bootstrap';
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import {formatarData} from '../../utils/formatadorDataTabela'
//IMPORTAÇÕES ICONES
import { setaDropdown } from '../../utils/alternanciaIcones'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, desabilitarAcoesCliente, revelarMostrarEmDispositivosSolicitacao, revelarMostrarEmDispositivosSolicitacaoReset, filtrosArrayBarra, recarregarBarraFiltragem, limpadorFiltros, desabilitarAcoes, limpouBusca, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro } from "../../redux/actions/index";
//IMPORTAÇÕES COMPONENTES
import MensagemSucesso from '../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../componentesModais/mensagens/mensagemAtencao';
import BarraDadosCliente from '../componentesModais/barraDadosCliente/barraDadosCliente'
import DetalhamentoSolicitacaoOperadora from '../painelVirtueyes/detalhamentoSolicitacao/operadora/tabela'
// import NovaSolicitacao from '../painelVirtueyes/solicitacaoOperadora/novaSolicitacao/novaSolicitacao'
import FinalizarAtendimento from '../painelVirtueyes/solicitacaoOperadora/finalizarAtendimento/finalizarAtendimento'
import ReenviarEmail from '../painelVirtueyes/solicitacaoOperadora/reenviarEmail/reenviarEmail'
import PaginacaoTabela from '../../utils/paginacaoTabela'
import BarraFiltragem from '../componentesModais/barraFiltragem/barraFiltragem'
import GirarSetaDropdown from '../../utils/girarSetaDropdown'
import LogReset from '../painelVirtueyes/solicitacaoOperadora/acoes/logReset'
//ESTILIZAÇÃO
import '../../css/painelVirtueyes/detalhamentoSolicitacao/operadora/tabelaReset.css'
// SPINNER
import SpinerVeye from '../spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let dadosSelecionados = [];
let itensVar = []


function TabelaResetTrucks(props){
    /*eslint-disable*/
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState([]);
    const [linhasReenvioSolicitacao, setLinhasReenvioSolicitacao] = useState([]);
    const [solicitacaoReenvioSolicitacao, setSolicitacaoReenvioSolicitacao] = useState({});
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50); // eslint-disable-next-line
    //HOOKS VARIÁVEIS
    //HOOKS MENSAGENS
    const [solicitacaoModalTxt, setSolicitacaoModalTxt] = useState('reenvio da solicitação')
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação"); // eslint-disable-next-line
    const [mensagemSucesso, setMensagemSucesso] = useState('') // eslint-disable-next-line
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencaoReenviarSolicitacao, setRevelarMensagemAtencaoReenviarSolicitacao] = useState(false)
    const [revelarMensagemAtencaoParcialReenviarSolicitacao, setRevelarMensagemAtencaoParcialReenviarSolicitacao] = useState(false)
    const [revelarMensagemSucessoSolicitacaoReenvio, setRevelarMensagemSucessoSolicitacaoReenvio] = useState(false)
    const [revelarMensagemErroSolicitacaoReenvio, setRevelarMensagemErroSolicitacaoReenvio] = useState(false)
    const [cdSolicitacao, setCdSolicitacao]= useState()
    const [mostrarDispositivos, setMostrarDispositivos]= useState(false)

    //REDUX HOOKS
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    // let novaSolicitacao = useSelector(state => state.virtueyesState.novaSolicitacaoVirtueyes);
    let reenviarEmailVirtueyes = useSelector(state => state.virtueyesState.reenviarEmailVirtueyes);
    let confimarRecebimentoVirtueyes = useSelector(state => state.virtueyesState.confimarRecebimentoVirtueyes);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarLogReset =  useSelector(state => state.virtueyesState.revelarLogReset);
    let temFiltros = false;
    let revelarMostrarEmDispositivo =  useSelector(state => state.faturasState.revelarMostrarEmDispositivosSolicitacao);
    let revelarMostrarEmDispositivoReset =  useSelector(state => state.faturasState.revelarMostrarEmDispositivosSolicitacaoReset);
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)

    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(430) // 'solicitacaoOperadoraTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        restaurarConfigsIniciaisPagina()
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(false));
        despacho(mudarManejador("solicitacaoReset"));
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/solicitacao?painel_virtueyes=true&fl_operadora=1&paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+'&linhasReset='+true);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    fl_operadora:1,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    painel_virtueyes:true,
                    linhasReset: true
                }
                
                if (controladorPagina <= paginaAtual && !revelarMostrarEmDispositivoReset) {
                    const { data } = await api.post("/m1/filtrar/solicitacao", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/solicitacao?painel_virtueyes=true&fl_operadora=1&paginaAtual='+contador+'&carregarLimit='+valorLimite+'&linhasReset='+true);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, filtros, renderizar])

    const itemExpandido = {
        className: 'fundo-cor-1 expand-row-solicitacao',
        renderer: (row, rowIndex) => (
            <div className="container-row-expand-central-acoes-solicitacao-operadora">
                <DetalhamentoSolicitacaoOperadora 
                    cdSolicitacao={row.CD_SOLICITACAO}
                    linhasReset={true}
                    cdTipoSolicitacao={row.CD_TIPO_SOLICITACAO}
                    descSolicitacao={row.DESC_SOLICITACAO}
                    refazerSolicitacao={refazerSolicitacao}
                    getSolModalTxt={getSolModalTxt}
                    razaoSocial={row.RAZAO_SOCIAL}
                    nomeFantasia={row.NOME_FANTASIA}
                    renderizar={true}
                />
            </div>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="cabecalho-linha-expandida"></div>
        ),
        expandColumnRenderer: ( rowKey ) => {
            return (
                <Button className="botao-expandir-row" onClick={e => GirarSetaDropdown( rowKey.rowKey )}>
                    <Image id={ rowKey.rowKey } className="icone-botao-expandir-row nao-selecionavel" src={setaDropdown()}/>
                </Button>
            );
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        headerClasses: 'tabela-coluna-primeira-header',
    };

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        dadosSelecionados = [];
        despacho(desabilitarAcoes(true));
        setPaginaAtual(1)
    }

    const selecaoLinhas = {
        mode: 'checkbox' ,
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionar(row.CD_SOLICITACAO)
            }else{
                handleDesselecionar(row.CD_SOLICITACAO)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if(isSelect){
                handleSelecionarTodos()
            }else{
                handleDesselecionarTodos()
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-simcard" { ...rest }/>
                    <label class="label-checkbox-simcard"></label>
                </>
            )
        },
        selectionHeaderRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-header-simcard" { ...rest }/>
                    <label class="label-checkbox-header-simcard"></label>
                </>
            )
        },
        bgColor: 'row-index-bigger-than-2101'
    };

    function handleSelecionarTodos(){
        ColetaClickstream(436) // 'solicitacaoOperadoraTabelaHandleSelecionarTodos'
        itens.map((item,i)=>{
            if(itens[i].CD_SOLICITACAO){
                dadosSelecionados.push(itens[i].CD_SOLICITACAO);
            }
        })
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
        despacho(mudarManejador("solicitacaoReset"));
    }

    function handleDesselecionarTodos(){
        ColetaClickstream(437) // 'solicitacaoOperadoraTabelaHandleDesselecionarTodos'
        dadosSelecionados = []
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(false));
    }

    function handleSelecionar(simcard){
        despacho(mudarManejador("solicitacaoReset"));
        ColetaClickstream(438) // 'solicitacaoOperadoraTabelaHandleSelecionar'
        for (let i = 0; i < itens.length; i++) {
            if (itens[i].CD_SOLICITACAO == simcard){
                dadosSelecionados.push(itens[i].CD_SOLICITACAO);
                break;
            }
    }
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
    }

    useEffect(()=>{
        async function consultarItens(){
            let arrayIccid=[]
            let arrayIdSolicitacaoInicio=[]
            let arrayIdSolicitacaoFinal=[]
            let arrayIdSolicitacaoFormatar=[]
            await api.get('m1/consultar/solicitacao_simcard?cd_crm_solicitacao_operadora='+dadosSelecionados+'&linhasReset='+true)
                .then(async function (response) {
                    if(response.data.status == 200){
                        await response.data.dados.map((item_consulta,i)=>{
                            let auxIccid = "'"+item_consulta.ICCID+"'"
                            let auxIdSolicitacao = ""+item_consulta.CD_SOLICITACAO_CLIENTE+""
                            arrayIccid.push(auxIccid)
                            arrayIdSolicitacaoInicio.push(auxIdSolicitacao)
                        })
                        arrayIdSolicitacaoFinal = arrayIdSolicitacaoInicio.filter((este, i) => arrayIdSolicitacaoInicio.indexOf(este) === i);
                        arrayIdSolicitacaoFormatar = arrayIdSolicitacaoFinal.join(",  ")

                        despacho(mudarFiltros({ICCID_RESET: arrayIccid}))
                        despacho(filtrosArrayBarra(['Código da Solicitação: '+arrayIdSolicitacaoFormatar+'?ICCID']))
                        despacho(revelarBarraFiltragem(true))
                        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
                        despacho(limpadorFiltros(false))
                        //aciona o event click para mudar de paguina
                        const link = document.getElementById('link-dispositivos')
                        link.click();
                        despacho(revelarMostrarEmDispositivosSolicitacao(false))
                        despacho(revelarMostrarEmDispositivosSolicitacaoReset(true))
                    }
                })
                .catch(function (error) {
                    return error
                })
        }
        if(revelarMostrarEmDispositivo){
            ColetaClickstream(1443) // SolicitacoesDropAcoesMostrarEmDispossitivos
            consultarItens()
        }
    }, [revelarMostrarEmDispositivo])

    function handleDesselecionar(simcard){
        ColetaClickstream(439) // 'solicitacaoOperadoraTabelaHandleDesselecionar'
        for (let i = 0; i < dadosSelecionados.length; i++) {
            if (dadosSelecionados[i] == simcard){
                dadosSelecionados.splice(i, 1);
                break;
            }
        }
        if(dadosSelecionados.length == 0){
            despacho(desabilitarAcoes(true));
            despacho(desabilitarAcoesCliente(false));
        }
    }

    const colunas = [
        {
            dataField: 'CD_SOLICITACAO',
            headerClasses: 'nao-selecionavel',
            text: 'ID',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'DATA_CRIACAO',
            headerClasses: 'nao-selecionavel',
            text: 'Data',
            sort: true,
            formatter: (cell, row) => {
                return formatarData(cell);
            }
        },
        {
            dataField: 'DESC_STATUS_SOLICITACAO',
            headerClasses: 'nao-selecionavel',
            text: 'Status',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'DESC_SOLICITACAO',
            headerClasses: 'nao-selecionavel',
            text: 'Descrição',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
    ]

    function getSolModalTxt(desc) {
        let auxTxt
        if(desc) {
            const strRetirar = 'Solicitação de'
            auxTxt = desc;
            if(desc.indexOf(strRetirar) > -1) {
                auxTxt = desc.substr(strRetirar.length+1, desc.length)
            }
        } else {
            auxTxt = 'reenvio da solicitação'
        }

        return `"${auxTxt}"`
    }

    async function handleReenviarSolicitacao(solicitacao){
        if(!solicitacao) return;
        const { CD_SOLICITACAO, CD_TIPO_SOLICITACAO } = solicitacao

        setSpinAtivo(true) 

        const queryString = 'cd_crm_solicitacao_operadora='+CD_SOLICITACAO+'&cd_crm_solicitacao_tipo='+CD_TIPO_SOLICITACAO+'&erradas=true&linhasReset='+true;
        
        const dataPeriodo = await api.get('/m1/consultar/solicitacao_simcard/api_simcards?'+queryString);
        setSpinAtivo(false) 

        if(dataPeriodo.data.dados != undefined){
            setLinhasReenvioSolicitacao(dataPeriodo.data.dados)
            setSolicitacaoReenvioSolicitacao(solicitacao)
            setSolicitacaoModalTxt(getSolModalTxt(solicitacao.DESC_SOLICITACAO))
            setRevelarMensagemAtencaoReenviarSolicitacao(true);
        }
        else{
            setRevelarMensagemErroSolicitacaoReenvio(true)
        }
    }

    function handleMensagemAtencaoReenviarSolicitacao(confirmacao){
        if(revelarMensagemAtencaoReenviarSolicitacao === false){
            setRevelarMensagemAtencaoReenviarSolicitacao(true)
        }else{
            setRevelarMensagemAtencaoReenviarSolicitacao(false)
        }
        if(confirmacao){
            reenviarSolicitacao();
        }
    }

    async function handleMensagemAtencaoParcialReenviarSolicitacao(confirmacao){
        if(revelarMensagemAtencaoParcialReenviarSolicitacao === false){
            setRevelarMensagemAtencaoParcialReenviarSolicitacao(true)
        }else{
            setRevelarMensagemAtencaoParcialReenviarSolicitacao(false)
        }
        if(confirmacao){
            await handleAtualizarStatusSolicitacao()
            setRenderizar(!renderizar)
        }
    }

    async function handleMensagemSucessoSolicitacaoReenvio(confirmacao){
        if(revelarMensagemSucessoSolicitacaoReenvio === false){
            setRevelarMensagemSucessoSolicitacaoReenvio(true)
        }else{
            setRevelarMensagemSucessoSolicitacaoReenvio(false)
        }
        if(confirmacao){
            await handleAtualizarStatusSolicitacao()
            setRenderizar(!renderizar)
        }
    }

    function handleMensagemErroSolicitacaoReenvio(confirmacao){
        if(revelarMensagemErroSolicitacaoReenvio === false){
            setRevelarMensagemErroSolicitacaoReenvio(true)
        }else{
            setRevelarMensagemErroSolicitacaoReenvio(false)
        }
    }

    // TODO: refatorar (código repetido)
    const refazerSolicitacao = async function(linha){
        let bodySolicitacao = {
            "operadora": linha.OPERADORA,
            "tecnologia": linha.TECNOLOGIA,
            "cd_crm_solicitacao_tipo": solicitacaoReenvioSolicitacao.CD_TIPO_SOLICITACAO,
            "cd_crm_solicitacao": linha.CD_SOLICITACAO_OPERADORA,
            "cd_crm_solicitacao_simcard": linha.CD_SOLICITACAO_SIMCARD,
            "reenvio": true
        }
        if(linha.OPERADORA == "VIVO"){
            bodySolicitacao.iccid = linha.ICCID
        }
        else if(linha.OPERADORA == "TIM"){
            bodySolicitacao.linha = linha.LINHA

        } else if(linha.OPERADORA == "NEO"){ // Virtueyes/Telecall
            let msisdnStr = linha.LINHA.toString()
            let msisdn = linha.LINHA;

            if(msisdnStr.length > 11 && msisdnStr.substr(0, 2) == '55') {
                msisdn = parseInt(msisdnStr.slice(2, msisdnStr.length))
            }
            bodySolicitacao.linha = msisdn
        }

        const retornoSolicitacao = await api.post("/m1/cadastrar/solicitacao/operadora", bodySolicitacao)
            .then((response) => {
                return response.data
            }).catch((error) => {
                return error.response
            })

        if(retornoSolicitacao.status == 200){
            return true;
        }
        else{
            return false;
        }
    }

    async function reenviarSolicitacao(){
        setSpinAtivo(true)

        const resultado = await Promise.all(linhasReenvioSolicitacao.map(linha => refazerSolicitacao(linha)))

        if(resultado.every(checkArrayTrue)){
            setRevelarMensagemSucessoSolicitacaoReenvio(true)
            setSpinAtivo(false)
        }
        else{
            setRevelarMensagemAtencaoParcialReenviarSolicitacao(true)
            setSpinAtivo(false)
        }
    }

    function checkArrayTrue(e, i, a){
        return e == true;
    }

    async function handleAtualizarStatusSolicitacao(){ 
        if(!solicitacaoReenvioSolicitacao) return;
        const { CD_SOLICITACAO, CD_TIPO_SOLICITACAO } = solicitacaoReenvioSolicitacao
        let queryString = 'cd_crm_solicitacao_operadora='+CD_SOLICITACAO+'&cd_crm_solicitacao_tipo='+CD_TIPO_SOLICITACAO+'&linhasReset='+true
        const consultaSolicitacao = await api.get('/m1/consultar/solicitacao_simcard/api_simcards?'+queryString)

        if(consultaSolicitacao.data.status == 200){

            let erros = 0;
            let retornados = 0;
            let enviados = 0;
            let statusSolicitacao = 1;

            consultaSolicitacao.data.dados.map(simcard => {
                const statusSolicitacao = simcard.STATUS_API;
                if(statusSolicitacao == "Erro"){
                    erros++;
                }
                else if(statusSolicitacao == "Retornado"){
                    retornados++;
                }
                else if(statusSolicitacao == "Enviado"){
                    enviados++;
                }
            })

            let tamanhoArray = consultaSolicitacao.data.dados.length;
            if(erros == tamanhoArray){
                status = "Erro";
            }
            else if(retornados == tamanhoArray){
                status = "Retornado";
                statusSolicitacao = 2;
            }
            else if(enviados == tamanhoArray){
                status = "Enviado";
            }
            else{
                status = "Parcial";
            }
            await api.post('/m1/alterar/solicitacao', {
                dados: {
                    ds_status_api: status,
                    cd_crm_solicitacao_status: statusSolicitacao
                },
                condicao: {
                    cd_crm_solicitacao: solicitacaoReenvioSolicitacao.CD_SOLICITACAO
                }
            })
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function inserirData(data){
        if(!revelarMostrarEmDispositivoReset){
            for(let i=0;i<totalItens;i++){
                if (contador === i){
                    let k = i
                    for(let j=0;j<data.length;j++){
                        itensVar[k] = data[j]
                        k++
                    }
                }
                if(i == (totalItens-1)){
                    setItens(JSON.parse(JSON.stringify(itensVar)))
                }
            }
        }
    }

    async function contarItens(){
        let arrayItens = []
        let apiData;
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/solicitacao?painel_virtueyes=true&fl_operadora=1&contar=true&buscar='+conteudoBusca+'&linhasReset='+true);
            } else{
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar: conteudoBusca,
                    filtros: filtros,
                    fl_operadora : 1,
                    painel_virtueyes:true,
                    contar: true,
                    linhasReset: true
                }
                apiData = await api.post("/m1/filtrar/solicitacao", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }   

    return(
        <div className='container-tela'>
            <BarraDadosCliente/>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            {revelarLogReset === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <LogReset
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            itensSelecionados={dadosSelecionados}
                        />
                    </div>
                </div>
            }
            <Link id='link-dispositivos' className="d-none" to={"/veye/dispositivos"}/>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={() =>  setRevelarMensagemSucesso(!revelarMensagemSucesso)}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={mensagemSucesso}
                            mensagemBotao={"OK"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={() =>   setRevelarMensagemErro(!revelarMensagemErro)}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Ocorreu um erro. Tente novamente mais tarde."}
                            mensagemParagrafo2={"Caso o erro persista, entre em contato com o Suporte."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoReenviarSolicitacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencaoReenviarSolicitacao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo2={`Deseja mesmo refazer o ${solicitacaoModalTxt} das`} 
                            mensagemParagrafo3={"linhas com erro nesta solicitação?"}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoParcialReenviarSolicitacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencaoParcialReenviarSolicitacao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo2={`Não foi possivel efetuar o ${solicitacaoModalTxt}`}
                            mensagemParagrafo3={"para todas as linhas da solicitação"}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemSucessoSolicitacaoReenvio &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucessoSolicitacaoReenvio}
                            mensagemTitulo={"Sucesso!"}
                            mensagemParagrafo1={`${solicitacaoModalTxt} efetuado`}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErroSolicitacaoReenvio &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={() => handleMensagemErroSolicitacaoReenvio}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Não foi possivel reenviar"}
                            mensagemParagrafo2={`o ${solicitacaoModalTxt} das linhas`}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            {/* {novaSolicitacao === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <NovaSolicitacao
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        />
                    </div>
                </div>
            } */}
            {confimarRecebimentoVirtueyes === true &&
                <FinalizarAtendimento
                    renderizar={renderizar}
                    setRenderizar={setRenderizar}
                    itensSelecionados={dadosSelecionados}
                />
            }
            {reenviarEmailVirtueyes === true &&
                <ReenviarEmail
                    renderizar={renderizar}
                    setRenderizar={setRenderizar}
                    itensSelecionados={dadosSelecionados}
                />
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                classes="tabela"
                                condensed={true}
                                keyField='CD_SOLICITACAO' 
                                data={itens} 
                                columns={colunas} 
                                expandRow={itemExpandido}
                                selectRow={ selecaoLinhas }
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                           <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        conteudoBusca: state.manejadorState.conteudoBusca, 
        filtros: state.filtrosState.filtros, 
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaResetTrucks));