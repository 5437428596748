import React, { useCallback, useEffect, useState } from "react";
import { postUsuarioRecuperacao } from "../requisicoes";
import olhoDesativo from "../../../icones/iconesVersaoEscura/icone-olho-desligado-cinza.svg";
import olhoAtivo from "../../../icones/iconesVersaoEscura/icone-olho-ligado-cinza.svg";
import checkCircle from "../../../icones/check-circle-escuro.svg";
import erroCircle from "../../../icones/erro-cirle-escuro.svg";
import alertaInformacao from "../../../icones/iconesMensagens/alerta_informacao_vermelho.svg";
import disableCircle from "../../../icones/disable-circle-claro-sem-opacidade.svg";
import outlinedCircleEscuro from "../../../icones/outlined-circle.svg";
import decryptJWT from "../../../utils/decryptJWT";
import { useHistory } from "react-router-dom";
import { logout } from "../../../services/auth";

import * as S from "./styles";

const REGEX_MIN_CARACTERES = /^.{8,}$/;
const REGEX_MAIUSCULAS = /[A-Z]/;
const REGEX_MINUSCULAS = /[a-z]/;
const REGEX_NUMEROS = /[0-9]/;
const REGEX_CARACTERES_ESPECIAIS = /[!@#$%^&*()_+{}[\]:;,.?~\-|<>=]/;

function validarSenhaComGrupos(senha) {
  let gruposValidos = 0;

  if (REGEX_MAIUSCULAS.test(senha)) gruposValidos++;
  if (REGEX_MINUSCULAS.test(senha)) gruposValidos++;
  if (REGEX_NUMEROS.test(senha)) gruposValidos++;
  if (REGEX_CARACTERES_ESPECIAIS.test(senha)) gruposValidos++;

  return gruposValidos >= 3;
}

export default function RedefinirSenha({
  setMostrarRecuperarSenha,
  tokenUrl,
  emailUrl,
  primeiroAcesso,
}) {
  const [mostrarSenha, setMostrarSenha] = useState({
    novaSenha: "password",
    confirmarSenha: "password",
    senhaAtual: "password",
  });

  const [formValues, setFormValues] = useState({
    senha: "",
    confirmarSenha: "",
    senhaAtual: "",
  });

  const [erro, setErro] = useState({
    faltaMaiuscula: false,
    faltaMinuscula: false,
    faltaNumero: false,
    faltaMinChar: false,
    faltaEspecial: false,
    faltaMinGrupo: false,
    senhaDiferente: false,
    senhaIgualAnterior: false,
    senhaAtualInvalida: false,
  });

  const history = useHistory();

  const disableConfirm =
    erro.faltaMinGrupo ||
    erro.faltaMinChar ||
    erro.senhaDiferente ||
    erro.senhaIgualAnterior;

  const inputErro =
    formValues.senha.length > 0 &&
    formValues.confirmarSenha.length > 0 &&
    formValues.senha !== formValues.confirmarSenha;

  useEffect(() => {
    const handleBeforeUnload = () => limparLocalStorage();

    // * executa quando o botao de voltar ou proximo do navegador for clicado
    const onEventBotaoVoltar = history.listen((location, action) => {
      if (action === "POP") limparLocalStorage();
    });

    //* beforeunload executa uma funcao quando a tela é desmontada, antes do reload(f5)
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      onEventBotaoVoltar();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [history]);

  useEffect(() => {
    const validarSenha = () => {
      const faltaMinChar = !REGEX_MIN_CARACTERES.test(formValues.senha);
      const faltaMaiuscula = !REGEX_MAIUSCULAS.test(formValues.senha);
      const faltaMinuscula = !REGEX_MINUSCULAS.test(formValues.senha);
      const faltaNumero = !REGEX_NUMEROS.test(formValues.senha);
      const faltaEspecial = !REGEX_CARACTERES_ESPECIAIS.test(formValues.senha);

      const senhaDiferente = !(
        formValues.senha &&
        formValues.confirmarSenha &&
        formValues.senha === formValues.confirmarSenha
      );

      const faltaMinGrupo = !validarSenhaComGrupos(formValues.senha);

      if (primeiroAcesso) {
        const senhaIgualAnterior =
          formValues.senha === "" || formValues.senha === formValues.senhaAtual;

        setErro((prev) => ({
          ...prev,
          senhaIgualAnterior,
        }));
      }

      setErro((prev) => ({
        ...prev,
        faltaMinChar,
        faltaMaiuscula,
        faltaMinuscula,
        faltaNumero,
        faltaEspecial,
        senhaDiferente,
        faltaMinGrupo,
      }));
    };

    validarSenha();
  }, [
    primeiroAcesso,
    formValues.senhaAtual,
    formValues.senha,
    formValues.confirmarSenha,
  ]);

  const handleMostrarSenha = useCallback((campo) => {
    setMostrarSenha((prevState) => ({
      ...prevState,
      [campo]: prevState[campo] === "password" ? "text" : "password",
    }));
  }, []);

  const exibirIcone = useCallback(
    (campo) => {
      return mostrarSenha[campo] === "password" ? (
        <S.VerSenhaImg alt="olhoDesativo" src={olhoDesativo} />
      ) : (
        <S.VerSenhaImg alt="olhoAtivo" src={olhoAtivo} />
      );
    },
    [mostrarSenha]
  );

  const limparLocalStorage = () => logout();

  async function handleRedefinirSenha(event) {
    event.preventDefault();

    if (!erro.senhaInvalida && !erro.senhaDiferente) {
      const token = tokenUrl || localStorage.getItem("@veye-Token");
      const email = emailUrl || decryptJWT("email");

      try {
        await postUsuarioRecuperacao({
          tokenUrl: token,
          novaSenha: formValues.senha,
          emailUrl: email,
          senhaAtual: formValues.senhaAtual,
          primeiroAcesso,
        });

        limparLocalStorage();
        setMostrarRecuperarSenha("senha_redefinida");
      } catch (error) {
        if (error.response.data.info === "Erro na senha de acesso.") {
          setErro((prev) => ({ ...prev, senhaAtualInvalida: true }));
        }

        console.error("Erro ao redefinir a senha", error);
      }
    }
  }

  const handleChange = (e) => {
    const caracteresProibidos = ["'", '"', "/", "\\", "`", "´", "¨", " "];

    const { name, value } = e.target;

    const valorFormatado = value
      .split("")
      .filter((char) => !caracteresProibidos.includes(char))
      .join("");

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: valorFormatado,
    }));
  };

  const getIcon = (type) => {
    const campos = [
      "faltaMaiuscula",
      "faltaMinuscula",
      "faltaNumero",
      "faltaEspecial",
    ];

    const isAllErro =
      erro.faltaMinGrupo &&
      erro.faltaMaiuscula &&
      erro.faltaMinuscula &&
      erro.faltaNumero &&
      erro.faltaEspecial;

    if (isAllErro && campos.includes(type)) return outlinedCircleEscuro;

    if (erro[type]) {
      if (!erro.faltaMinGrupo && campos.includes(type)) {
        return disableCircle;
      }

      return erroCircle;
    }

    return checkCircle;
  };

  const handleChangeSenhaAtual = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleClose = () =>
    setErro((prev) => ({ ...prev, senhaAtualInvalida: false }));

  return (
    <S.Container>
      <div className="container-texto-1">
        <S.Titulo>Defina sua nova senha</S.Titulo>
      </div>
      <S.CamposWrapper>
        <S.SenhasWrapper>
          {primeiroAcesso && (
            <S.InputWrapper erro={erro.senhaAtualInvalida}>
              <S.Input
                autoComplete="off"
                placeholder="Senha atual"
                type={mostrarSenha.senhaAtual}
                minLength={8}
                maxLength={20}
                name="senhaAtual"
                value={formValues.senhaAtual}
                onChange={handleChangeSenhaAtual}
              />
              <S.BotaoVerNovaSenha
                onClick={() => handleMostrarSenha("senhaAtual")}
              >
                {exibirIcone("senhaAtual")}
              </S.BotaoVerNovaSenha>
            </S.InputWrapper>
          )}
          <S.InputWrapper erro={inputErro}>
            <S.Input
              autoComplete="off"
              placeholder="Nova senha"
              type={mostrarSenha.novaSenha}
              minLength={8}
              maxLength={20}
              name="senha"
              value={formValues.senha}
              onChange={handleChange}
            />
            <S.BotaoVerNovaSenha
              onClick={() => handleMostrarSenha("novaSenha")}
            >
              {exibirIcone("novaSenha")}
            </S.BotaoVerNovaSenha>
          </S.InputWrapper>
          <S.InputWrapper erro={inputErro}>
            <S.Input
              autoComplete="new-password"
              placeholder="Confirmar nova senha"
              type={mostrarSenha.confirmarSenha}
              minLength={8}
              maxLength={20}
              name="confirmarSenha"
              value={formValues.confirmarSenha}
              onChange={handleChange}
            />
            <S.BotaoVerNovaSenha
              onClick={() => handleMostrarSenha("confirmarSenha")}
            >
              {exibirIcone("confirmarSenha")}
            </S.BotaoVerNovaSenha>
          </S.InputWrapper>
        </S.SenhasWrapper>
        <S.MensagensErroWrapper>
          <S.TituloMensagem>A nova senha deve conter:</S.TituloMensagem>
          <S.ValidacoesWrapper>
            <S.GrupoValidacao>
              <img alt="min_char" src={getIcon("faltaMinChar")} />
              Entre 8 e 20 caracteres;
            </S.GrupoValidacao>
            <S.GrupoValidacao>
              <img alt="grupos" src={getIcon("faltaMinGrupo")} />
              <p> Uso de pelo menos três dos quatro grupos de caracteres:</p>
            </S.GrupoValidacao>
            <S.GrupoValidacao subGrupo>
              <img alt="maiusculo" src={getIcon("faltaMaiuscula")} />
              <p>Letras maiúsculas (A-Z)</p>
            </S.GrupoValidacao>
            <S.GrupoValidacao subGrupo>
              <img alt="minusculo" src={getIcon("faltaMinuscula")} />
              <p>Letras minúsculas (a-z)</p>
            </S.GrupoValidacao>
            <S.GrupoValidacao subGrupo>
              <img alt="numero" src={getIcon("faltaNumero")} />
              <p>Números (0-9)</p>
            </S.GrupoValidacao>
            <S.GrupoValidacao subGrupo>
              <img alt="char_especiais" src={getIcon("faltaEspecial")} />
              <p>Caracteres especiais (ex.: !@#$%^&*)</p>
            </S.GrupoValidacao>
            <S.GrupoValidacao>
              <img alt="senha_iguais" src={getIcon("senhaDiferente")} />
              <p>As senhas devem ser iguais.</p>
            </S.GrupoValidacao>
            {primeiroAcesso && (
              <S.GrupoValidacao>
                <img alt="senha_atual" src={getIcon("senhaIgualAnterior")} />
                <p> A nova senha deve ser diferente da anterior</p>
              </S.GrupoValidacao>
            )}
          </S.ValidacoesWrapper>
        </S.MensagensErroWrapper>
        <S.BotaoConcluir
          disabled={disableConfirm}
          onClick={handleRedefinirSenha}
        >
          Concluir
        </S.BotaoConcluir>
      </S.CamposWrapper>
      <S.CustomSnackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={erro.senhaAtualInvalida}
        onClose={handleClose}
        message={
          <S.MensagemWrapper>
            <img src={alertaInformacao} alt="icone_info" />
            <span>Senha atual incorreta.</span>
          </S.MensagemWrapper>
        }
      />
    </S.Container>
  );
}
