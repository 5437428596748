//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';
import { useForm } from "react-hook-form";
import { Button, Form, Col } from 'react-bootstrap';
import moment from 'moment';
//REDUX
import { useDispatch } from 'react-redux';
import { revelarNovaConta, revelarEditarConta } from '../../../../redux/actions';
//MENSAGEM
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
//SELECT
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
//CALENDARIO
import Calendario from '../../../calendario/calendario'
//DECRIPTADOR DO TOKEN
import decryptJWT from '../../../../utils/decryptJWT'
//COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
//SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//CSS
import '../../../../css/painelFaturas/fatContas/tabela.css'
//ICONES
import {elipseVerde, fecharJanela, setaDropdown} from '../../../../utils/alternanciaIcones';
import decrypt from '../../../../utils/decryptJWT';

let primeiraVez = true;

export default function NovaEmpresa(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit } = useForm();
    //HOOKS PRINCIPAIS'
    const [cdConta, setCdConta] = useState(-1);
    const [inicioCiclo, setInicioCiclo] = useState("")
    const [fimCiclo, setFimCiclo] = useState("")
    const [numeroConta, setNumeroConta] = useState("")
    const [prazoPagamento, setPrazoPagamento] = useState("")
    const [vencimento, setVencimento] = useState("")
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState()
    const [tituloJanela, setTituloJanela] = useState('Nova');

    const [cdFornecedor, setCdFornecedor] = useState()
    const [dsFornecedor, setDsFornecedor] = useState('Selecione')
    const [cdOperadora, setCdOperadora] = useState()
    const [dsOperadora, setDsOperadora] = useState('Selecione')
    const [cdEmpresa, setCdEmpresa] = useState()
    const [dsEmpresa, setDsEmpresa] = useState('Selecione')
    //HOOKS MENSAGENS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    //HOOKS SELECTS
    const [revelarSelectFornecedor, setRevelarSelectFornecedor] = useState(false);
    const [revelarSelectOperadora, setRevelarSelectOperadora] = useState(false);
    const [revelarSelectEmpresa, setRevelarSelectEmpresa] = useState(false);
    const [dummy, setDummy] = useState()
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();

    const cd_pav_usuario = decryptJWT('codigo')

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        setSpinAtivo(true)
        if (props.tituloJanela === 'Editar'){
            setTituloJanela('Editar')
            setCdConta(props.cdConta)
            setNumeroConta(props.numeroConta)
            setDsFornecedor(props.dsFornecedor)
            setDsOperadora(props.dsOperadora)
            setDsEmpresa(props.dsEmpresa)
            setInicioCiclo(props.inicioCiclo)
            setVencimento(props.vencimento)
            setPrazoPagamento(props.prazoPagamento)
        }
        if (props.tituloJanela === 'Cadastrar'){
            setTituloJanela('Cadastrar')
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    useEffect(()=>{
        function calcularDiferenca(){
            if(tituloJanela === "Cadastrar"){
                const inicio = moment(inicioCiclo).format('YYYY-MM-DD')
                const diferenca = moment(vencimento).diff(moment(inicio), 'months')
                setPrazoPagamento(diferenca)
            }
        }
        calcularDiferenca()
    },[vencimento])


    useEffect(()=>{
        function calcularCiclo(){
            if(tituloJanela === "Cadastrar"){
                const fim =  moment(inicioCiclo).add(1, "months").subtract(1, "days").format("MM/DD/YYYY");
                setFimCiclo(fim)
            }
            if(tituloJanela === "Editar"){
                let auxMes = moment().format("YYYY-MM-DD")
                let auxDia = moment().format("DD")
                let auxData = auxMes.replace(auxDia,inicioCiclo)

                const fim =  moment(auxData).add(1, "months").subtract(1, "days").format("DD");
                setFimCiclo(fim)
            }
        }
        calcularCiclo()
    },[inicioCiclo])
    
    async function handleSalvar(){
        ColetaClickstream(1283) // dropAcoesRevelarNovaContaCadastrar
        setSpinAtivo(true)
        if(tituloJanela === "Cadastrar"){
            await api.post('/m1/cadastrar/fat_conta', {
                "cd_fat_empresa": cdEmpresa,
                "cd_fat_fornecedor": cdFornecedor,
                "cd_dsp_operadora": cdOperadora,
                "ds_conta": numeroConta,
                "n0_dia_inicial_ciclo": moment(inicioCiclo).format("DD"),
                "n0_dia_vencimento": moment(vencimento).format("DD"),
                "n0_meses_sequentes": prazoPagamento,
                "fl_automatizar": 1,
                "cd_pav_usuario": cd_pav_usuario,
                "cd_pav_cliente": decryptJWT('codigoCliente')
            },{timeout: 20000})
            .then(async function (response) {
                if(response.data.info && response.data.info !== ''){
                    setSpinAtivo(false)
                    setMensagemErro(response.data.info)
                    setRevelarMensagemErro(true)
                }else{
                    let fatContas = []
                    const contasUsuario = await api.get("m1/consultar/fat_conta/contas_usuario?cd_pav_usuario="+decryptJWT('codigo'))
                    await Promise.all (contasUsuario.data.dados.map((item)=>{
                        fatContas.push(item.cd_fat_conta)
                    }))
                    if(contasUsuario.data.dados.length == 0){
                        fatContas = [0]
                    }

                    const request = "/m1/servicos/alterar_token?login="+decryptJWT('login')+"&email="+decryptJWT('email')+"&codigo="+decryptJWT('codigo')+
                                    "&codigoCliente="+decryptJWT('codigoCliente')+"&codigoClienteErpAntigo="+decryptJWT('codigoClienteErpAntigo')+"&codigoClienteTexto="+decrypt('codigoClienteErpAntigo')+
                                    "&nomeDeUsuario="+decryptJWT('nomeDeUsuario')+"&perfil="+decryptJWT('perfil')+"&cdSmsContaUsuario="+decryptJWT('cdSmsContaUsuario')+
                                    "&id="+decryptJWT("id")+"&fatContas="+fatContas+"&clienteNivel="+decryptJWT("clienteNivel")+"&tipoPerfil=" +decryptJWT("tipoPerfil")
                                    +"&clientePrimario=" +decryptJWT("clientePrimario")
                    await api.get(request)
                    .then(function(response){
                        localStorage.setItem('@veye-Token', response.data.dados.token)
                    })
                    .catch(function(error){
                    })

                    setSpinAtivo(false)
                    handleMensagemSucesso(false)
                }
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            });
        }
        
        setSpinAtivo(true)
    }

    async function handleCancelar() {
        ColetaClickstream(1282) // dropAcoesRevelarNovaContaCancelar
        primeiraVez = true
        setSpinAtivo(false)
        despacho(revelarNovaConta(false))
        despacho(revelarEditarConta(false))

    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            props.setRenderizar(!props.renderizar)
            handleCancelar()
        }
        if(confirmacao){
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        }else{
            setRevelarMensagemErro(false)
            setSpinAtivo(false)
        }
        if(confirmacao ===true){
            setSpinAtivo(false)
            //ALGUMA FUNÇÃO
        }
    }

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-numero-conta"){
                setNumeroConta(valor)
            }
        }
    }

    const selectStyleDisabled = {
        control: styles => ({
            ...styles, 
            pointerEvents: "none", 
            border: "none", 
            borderRadius: "5em", 
            minHeight: "1.5em",
            backgroundColor: "#343736",
            opacity: "0.5"
        }),
        indicatorsContainer: styles => ({
            ...styles,
            maxHeight: "1.85em"
        })
    }

    useEffect(()=>{
        if(!primeiraVez){
            setDsOperadora('Selecione')
            setCdOperadora()
        }
        primeiraVez = false;
    },[cdFornecedor])


    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-faturas-cadastro-conta fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{tituloJanela} conta</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <div className="faturas-nova-conta-grupo-1">
                    <div className="faturas-nova-conta-coluna-1">
                        <Form.Row className="usuario-dados-corpo-conta">
                            <div className="corpo-dados-conta">
                                <Form.Label> <div className="fonte-cor-1 label-campo">Número da conta</div> </Form.Label>
                                {tituloJanela === "Cadastrar" ?
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        maxLength="30" 
                                        name="ds_numero_conta"
                                        value={numeroConta}
                                        onChange={e => validarNumero("campo-texto-numero-conta", e.target.value)}
                                        ref={register({ required: true, min: 3})}
                                        placeholder="Digite o número da conta"

                                    />
                                :
                                    <Form.Control
                                        className="campo-texto-faturas-contas-disabled  campo-texto-cor-3 campo-texto"
                                        type="number"                      
                                        value={numeroConta}
                                        disabled
                                        placeholder="Digite o número da conta"
                                    />
                                }
                            </div>
                            <div className="corpo-dados-conta">
                                {tituloJanela === "Cadastrar" ?
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Fornecedor</div>
                                        </Form.Label>
                                        {revelarSelectFornecedor === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFornecedor(!revelarSelectFornecedor)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsFornecedor}</div>
                                                <img className={revelarSelectFornecedor?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }

                                        {revelarSelectFornecedor === true &&
                                            <SelectGenerico
                                                paramRota={"fat_fornecedor"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                setCodigo={setCdFornecedor}
                                                setDescricao1={setDsFornecedor}
                                                setDescricao2={setDummy}
                                                setDescricao3={setDummy}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectFornecedor}
                                                placeholder={dsFornecedor}
                                                sufixo={""}
                                            >
                                            </SelectGenerico>
                                        }
                                    </Form.Group>
                                :
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Fornecedor</div>
                                        </Form.Label>
                                        <Form.Control
                                            className="campo-texto-faturas-contas-disabled campo-texto-cor-3 campo-texto"
                                            type="text" 
                                            disabled
                                            value={dsFornecedor}
                                            placeholder="Fim do ciclo"
                                        />
                                    </Form.Group>
                                }
                            </div>
                        </Form.Row>
                        {/* <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo-conta"/> */}
                        <Form.Row className="usuario-dados-corpo-conta">
                            <div className="corpo-dados-conta">
                                {tituloJanela === "Cadastrar" ?
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Operadora</div>
                                        </Form.Label>
                                        {revelarSelectOperadora === false &&
                                            <Button disabled={cdFornecedor !== '' && cdFornecedor !== null && cdFornecedor !== undefined ? false : true} className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsOperadora}</div>
                                                <img className={revelarSelectOperadora?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }

                                        {revelarSelectOperadora === true &&
                                            <SelectGenerico
                                                paramRota={"fat_operadora_por_fornecedor"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={cdFornecedor}
                                                paramCampo4={null}
                                                setCodigo={setCdOperadora}
                                                setDescricao1={setDsOperadora}
                                                setDescricao2={setDummy}
                                                setDescricao3={setDummy}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectOperadora}
                                                placeholder={dsOperadora}
                                                sufixo={""}
                                            >
                                            </SelectGenerico>
                                        }
                                    </Form.Group>
                                :
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Operadora</div>
                                        </Form.Label>
                                        <Form.Control
                                            className="campo-texto-faturas-contas-disabled campo-texto-cor-3 campo-texto"
                                            type="text"
                                            disabled
                                            value={dsOperadora}
                                            placeholder="Fim do ciclo"
                                        />
                                    </Form.Group>
                                }

                            </div>
                            <div className="corpo-dados-conta">
                                {tituloJanela === "Cadastrar" ?
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Empresa</div>
                                        </Form.Label>
                                        {revelarSelectEmpresa === false &&
                                            <Button disabled={cdOperadora !== '' && cdOperadora !== null && cdOperadora !== undefined ? false : true} className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectEmpresa(!revelarSelectEmpresa)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsEmpresa}</div>
                                                <img className={revelarSelectEmpresa?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }

                                        {revelarSelectEmpresa === true &&
                                            <SelectGenerico
                                                paramRota={"fat_empresa"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                setCodigo={setCdEmpresa}
                                                setDescricao1={setDsEmpresa}
                                                setDescricao2={setDummy}
                                                setDescricao3={setDummy}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectEmpresa}
                                                placeholder={dsEmpresa}
                                                sufixo={""}
                                            >
                                            </SelectGenerico>
                                        }
                                    </Form.Group>
                                :
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Empresa</div>
                                        </Form.Label>
                                        <Form.Control
                                            className="campo-texto-faturas-contas-disabled campo-texto-cor-3 campo-texto"
                                            type="text"   
                                            disabled
                                            value={dsEmpresa}
                                            placeholder="Fim do ciclo"
                                        />
                                    </Form.Group>
                                }
                            </div>
                        </Form.Row>
                        {/* <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo-conta"/> */}
                        <Form.Row className="usuario-dados-corpo-conta">
                            <div className="corpo-dados-conta">
                                {tituloJanela === "Cadastrar" ?
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Inicio do ciclo</div>
                                        </Form.Label>
                                        <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                    </Form.Group>
                                :
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Inicio do ciclo</div>
                                        </Form.Label>
                                        <Form.Control
                                            className="campo-texto-faturas-contas-disabled campo-texto-cor-3 campo-texto"
                                            type="text"
                                            disabled
                                            value={"Dia " + inicioCiclo}
                                            placeholder="Inicio do ciclo"
                                        />
                                    </Form.Group>
                                }
                                </div>
                            <div className="corpo-dados-conta">
                                {tituloJanela === "Cadastrar" ?
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Fim do ciclo</div>
                                        </Form.Label>
                                        <Form.Control
                                            className="campo-texto-faturas-contas-disabled campo-texto-cor-3 campo-texto"
                                            type="text"
                                            maxLength="30"    
                                            disabled
                                            value={fimCiclo === "" || fimCiclo === null || fimCiclo === undefined ? "" : moment(fimCiclo).format("DD/MM/YYYY")}
                                            placeholder="Fim do ciclo"
                                        />
                                    </Form.Group>
                                :
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Fim do ciclo</div>
                                        </Form.Label>
                                        <Form.Control
                                            className="campo-texto-faturas-contas-disabled campo-texto-cor-3 campo-texto"
                                            type="text"
                                            disabled
                                            value={"Dia " + fimCiclo}
                                            placeholder="Fim do ciclo"
                                        />
                                    </Form.Group>
                                }
                            </div>
                        </Form.Row>
                        {/* <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo-conta"/> */}
                        <Form.Row className="usuario-dados-corpo-conta">
                            <div className="corpo-dados-conta">
                                <Form.Label> <div className="fonte-cor-1 label-campo">Data de vencimento</div> </Form.Label>
                                {tituloJanela === "Cadastrar" ?
                                    <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={vencimento} setData={setVencimento} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                :
                                    <Form.Control
                                        className="campo-texto-faturas-contas-disabled campo-texto-cor-3 campo-texto"
                                        type="text"
                                        disabled
                                        value={"Dia " + vencimento}
                                        placeholder="Inicio do ciclo"
                                    />
                                }
                            </div>
                            <div className="corpo-dados-conta">
                                <Form.Label> <div className="campo-texto-faturas-contas-disabled fonte-cor-1 label-campo">Prazo de pagamento</div> </Form.Label>
                                {tituloJanela === "Cadastrar" ?
                                    <Form.Control
                                        className="campo-texto-faturas-contas-disabled campo-texto-cor-3 campo-texto"
                                        type="text"
                                        maxLength="30"
                                        disabled
                                        value={prazoPagamento === "" || prazoPagamento === null || prazoPagamento === undefined ? "" :  prazoPagamento + " mês (es)"  }
                                        onChange={e => setPrazoPagamento(e.target.value)}
                                        placeholder="Prazo de pagamento"
                                    />
                                :
                                    <Form.Control
                                        className="campo-texto-faturas-contas-disabled campo-texto-cor-3 campo-texto"
                                        type="text"
                                        disabled
                                        value={prazoPagamento + " mês (es)"}
                                        placeholder="Prazo de pagamento"
                                    />
                                }
                            </div>
                        </Form.Row>
                    </div>
                    <div className="faturas-nova-conta-coluna-2">
                        <Form.Row className="usuario-dados-corpo-conta-2">
                            <div className="imagem-fatura-fundo">
                                <div className="container-imagem-fatura-1">
                                    <label className="label-imagem-fatura-1 cor-texto-imagem-fatura">
                                        {dsOperadora === undefined || dsOperadora === "Selecione" ? "Operadora" : dsOperadora}
                                    </label>
                                </div>
                                <div className="container-imagem-fatura-2">
                                    <label className="label-imagem-fatura-2 cor-texto-imagem-fatura">
                                        {dsFornecedor === undefined || dsFornecedor === "Selecione" ? "Fornecedor" : dsFornecedor}
                                    </label>
                                </div>
                                <div className="container-imagem-fatura-3">
                                    <label className="label-imagem-fatura-2 cor-texto-imagem-fatura">
                                        {dsEmpresa === undefined || dsEmpresa === "Selecione" ? "Titular" : dsEmpresa}
                                    </label>
                                </div>
                                <div className="container-imagem-fatura-4">
                                    <label className="label-imagem-fatura-2 cor-texto-imagem-fatura">
                                        {numeroConta === undefined || numeroConta === "" ? "Conta: xxxxxx" : "Conta: "+numeroConta}
                                    </label>
                                </div>
                                <div className="container-imagem-fatura-5">
                                    {tituloJanela === "Cadastrar" ?
                                        <label className="label-imagem-fatura-2 cor-texto-imagem-fatura">
                                            {inicioCiclo === undefined || inicioCiclo === "" ? "Ciclo: xx/xx/xxxx - xx/xx/xxxx" : "Ciclo: "+moment(inicioCiclo).format("DD/MM/YYYY")+" - "+moment(fimCiclo).format("DD/MM/YYYY")}
                                        </label>
                                    :
                                        <label className="label-imagem-fatura-2 cor-texto-imagem-fatura">
                                            {inicioCiclo === undefined || inicioCiclo === "" ? "Ciclo: xx/xx/xxxx - xx/xx/xxxx" : "Ciclo: "+inicioCiclo+" - "+fimCiclo}
                                        </label>
                                    }
                                </div>
                                <div className="container-imagem-fatura-6">
                                    {tituloJanela === "Cadastrar" ?
                                        <label className="label-imagem-fatura-2 cor-texto-imagem-fatura">
                                            {vencimento === undefined || vencimento === ""  ? "Vencimento: xx/xx/xxxx" : "Vencimento: "+moment(vencimento).format("DD/MM/YYYY")}
                                        </label>
                                    :
                                        <label className="label-imagem-fatura-2 cor-texto-imagem-fatura">
                                            {vencimento === undefined || vencimento === ""  ? "Vencimento: xx/xx/xxxx" : "Vencimento: "+vencimento}
                                        </label>
                                    }
                                </div>
                                {tituloJanela === "Cadastrar" &&
                                    <div className="container-imagem-fatura-7">
                                        {tituloJanela === "Cadastrar" ?
                                            <label className="label-imagem-fatura-2 cor-texto-imagem-fatura">
                                                {fimCiclo === undefined || fimCiclo === ""  ? "Referência: 00/0000" : "Referência: "+moment(fimCiclo).format("MM/YYYY")}
                                            </label>
                                        :
                                            <label className="label-imagem-fatura-2 cor-texto-imagem-fatura">
                                                {fimCiclo === undefined || fimCiclo === ""  ? "Referência: 00/0000" : "Referência: "+fimCiclo}
                                            </label>
                                        }
                                    </div>
                                }
                                <div className="container-imagem-fatura-8">
                                    <div className="codigo-barras-imagem-fatura-1 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-2 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-3 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-4 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-5 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-1 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-2 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-3 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-4 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-1 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-2 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-3 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-4 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-5 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-1 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-2 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-3 codigo-barras-imagem-fatura-cor"/>
                                    <div className="codigo-barras-imagem-fatura-4 codigo-barras-imagem-fatura-cor"/>
                                </div>
                                <div className="container-imagem-fatura-9">
                                    <label className="label-imagem-fatura-3 cor-texto-imagem-fatura">
                                        R$
                                    </label>
                                    <label className="label-imagem-fatura-4 cor-texto-imagem-fatura">
                                        X.XXX,XX
                                    </label>
                                </div>
                            </div>
                        </Form.Row>
                    </div>
                </div>
                <div className="drop-dados-conta-divisora-1 campo-select-divisor-cor-1"/>
                <Form.Row className="container-botoes-cancelar-confirmar">
                    <Col>
                        <>
                            {(numeroConta !== '' && numeroConta !== undefined && numeroConta !== null && 
                            cdFornecedor !== '' && cdFornecedor != undefined && cdFornecedor !== null && 
                            cdEmpresa !== '' && cdEmpresa !== undefined && cdEmpresa !== null && 
                            cdOperadora !== '' && cdOperadora !== undefined && cdOperadora !== null && 
                            inicioCiclo !== '' && inicioCiclo !== undefined && inicioCiclo !== null && 
                            vencimento !== '' && vencimento !== undefined && vencimento !== null) ?
                               <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">{tituloJanela}</div>
                                </Button>
                            :
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">{tituloJanela}</div>
                                </Button>
                            }
                            <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2">Cancelar</div>
                            </Button>
                        </>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Conta "+numeroConta+" cadastrada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}