import React from "react";
import { useSelector } from "react-redux";

import { formatarMilharSemDecimais } from "../../../../utils/formatarMilhar";
import { getCoresGraficoMigracaoPlano, getCoresInfoHeader } from "../cores";
import CardGraficoGenerico from "./CardGenerico";
import { getCicloFormatado } from "./CardGenerico/funcoes";
import "./GraficoAnaliseDeExcedentes.css";

const nomeOpcao = "Excedente";

export default function GraficoAnaliseDeExcedentes({ revelarOpcoesGrafico }) {
  const arrDash = useSelector(
    (state) => state.migracaoPlanoState.dashboard[nomeOpcao]
  );
  const ciclo = getCicloFormatado(arrDash);

  const isUltimos6Ciclos = ciclo === "Últimos 6 ciclos";

  const cores = getCoresGraficoMigracaoPlano(nomeOpcao, isUltimos6Ciclos);

  const configDash = [
    {
      titulo: "Linhas não excedentes",
      key: "linhasSemExcedente",
      cor: cores.verde,
    },
    {
      titulo: "Linhas excedentes",
      key: "linhasComExcedente",
      cor: cores.vermelho,
    },
    {
      titulo: "Linhas com previsão de excedente",
      key: "linhasComPrevisaoDeExcedente",
      cor: cores.laranja,
    },
  ];

  const dados = arrDash?.length ? arrDash[0] : {};

  return (
    <CardGraficoGenerico
      titulo="Análise de excedentes"
      nomeOpcao={nomeOpcao}
      InfoHeader={() => <InfoHeader {...dados} />}
      id={3}
      key={3}
      revelarOpcoesGrafico={revelarOpcoesGrafico}
      configDash={configDash}
    />
  );
}

function InfoHeader({ linhasSemExcedente, linhasComExcedente }) {
  const cores = getCoresInfoHeader();

  function Box({ titulo, valor, descricao, cor }) {
    return (
      <div className="infoHeader-box" style={{ color: cores.texto }}>
        <h6>{titulo}</h6>
        <span style={{ color: cor }}>
          {formatarMilharSemDecimais(valor || 0)}
        </span>
        <p>{descricao}</p>
      </div>
    );
  }

  return (
    <div className="container-infoHeader-analise-excedentes">
      <Box
        titulo="Não Excedentes"
        valor={linhasSemExcedente}
        descricao="Total de linhas"
        cor={cores.verdeExcedente}
      />
      <Box
        titulo="Excedentes"
        valor={linhasComExcedente}
        descricao="Total de linhas"
        cor={cores.vermelho}
      />
    </div>
  );
}
