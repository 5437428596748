//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { Button, Form, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Radio from "@mui/material/Radio";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
//REDUX
import { useDispatch, useSelector } from 'react-redux';

import {
    revelarAtribuirLinhas,
    revelarDesatribuirLinhas
} from "../../../../redux/actions/index";

//ICONES
import {
    elipseVerde,
    fecharJanela,
    lixeiraVermelho,
    alertaAtencao,
    exportarDescricaoVerde,
    importarArquivo,
    iconeExportarCinza,
    iconeImportarCinza,
    iconeSucessoVerdeBright, alertaVermelho
} from '../../../../utils/alternanciaIcones';

//MODAIS
import MensagemInformacao from '../../../componentesModais/mensagens/mensagemInformacao'
import { RenderMensagemAtencao } from '../../../componentesModais/mensagens/mensagemAtencao'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
import '../../../../css/painelFaturas/fatDispositivos/fatAcoes.css'
import { MensagemErro } from '../../../componentesModais/mensagens/mensagemAcao';
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import TextoAtencao from '../../modal/TextoAtencao.js';

import {api, api_svdispositivos} from '../../../../conexoes/api';/*eslint-disable*/
import { exportarXlsx, extrairArquivoParaBusca, montarXlsLinhasInvalidasAcaoAtribuir } from '../../../../utils/exportarArquivo';
import decryptJWT from '../../../../utils/decryptJWT'

const valorLimite = 50
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let itensVar = []

const atribuirTemplate = 'atribuir-template';
const atribuirSelecionadas = 'atribuir-selecionadas';

const nomeArquivoPadrao = 'exemplo-arquivo.xls'

const greenDefault = '#33CC99';

export default function AtribuirLinhas(props) {
    const {acaoModal} = props;
    const acaoAtribuir = (acaoModal === 'Atribuir');
    const acaoDesatribuir = (acaoModal === 'Desatribuir');

    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    //HOOKS PRINCIPAIS]

    const [dadosDownloadErros, setDadosDownloadErros] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('')
    const [arquivoSalvo, setArquivoSalvo] = useState(undefined)
    const [listaLinhas, setListaLinhas] = useState([]);
    const [arrayDadosExportacaoXlsXlsx, setarrayDadosExportacaoXlsXlsx] = useState([['Linha','ICCID'],["'","'"]])
    const [listaClientes, setListaClientes] = useState([]);
    const [nomeArquivo, setNomeArquivo] = useState(nomeArquivoPadrao);
    const [arquivo, setArquivo] = useState();
    const [clienteID, setClienteID] = useState('')
    const [selectedRadio, setSelectedRadio] = useState(atribuirSelecionadas);
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(0);
    const [itens, setItens] = useState([])
    const [itensInvalidos, setItensInvalidos] = useState([])
    const [itensInvalidosContador, setItensInvalidosContador] = useState([])
    const [itensCancelados, setItensCancelados] = useState([])
    const [linhasValidasAux, setLinhasValidasAux] = useState([])
    const [cdSimcardsArray, setCdSimcardsArray] = useState([])
    const [linhasInvalidasAux, setLinhasInvalidasAux] = useState([])
    const [linhasCanceladasAux, setLinhasCanceladasAux] = useState([])
    //OUTROS HOOKS
    const [paginaAtual, setPaginaAtual] = useState(1);

    const [mensagemConfirmacao, setMensagemConfirmacao] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] = useState(false)
    const [revelarMensagemAtencaoCancelados, setRevelarMensagemAtencaoCancelados] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");

    const despacho = useDispatch();
    const modal = useRef();
    const manejador = useSelector(state => state.manejadorState.manejador)

    const localTema = localStorage.getItem('tema')
    const temaEscuro = (localTema === 'escuro')
    const classRadioLabel = temaEscuro ? 'atribuir-radio-label-noturno':'atribuir-radio-label';

    const XLSX = require('xlsx');


    const radioButtonSxStyle = { 
        '& .MuiSvgIcon-root': {
            fontSize: '20px !important',
        },
        color: greenDefault,
        '&.Mui-disabled': {
            color:  temaEscuro ? '#fff' : '#212529',
        },
        '&.Mui-checked': {
            color: greenDefault,
        },
    }

    let dadosArquivo

    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);

    const qtdListaLinhas = listaLinhas?.length;

    const nenhumaLinhaSelecionada = (!props.itensSelecionados?.length && !modoSelecaoSimcard);

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
            props.setRenderizar(!props.renderizar)
        }
    }

    function extrairPropriedades(array) {
        const resultado = [];

        for (const item of array) {
            const { cd_pav_cliente, ds_pav_cliente, n0_nivel } = item;
            resultado.push({ id: cd_pav_cliente, label: ds_pav_cliente, nivel: n0_nivel });
        }

        return resultado;
    }



    //USE-EFFECTS - COMPONENT LIFECYCLE

    //GET CLIENT DATA
    useEffect(() => {
        async function fetchClienteData() {
            setSpinAtivo(true)
            let clienteData = await api.get('/m1/consultar/cliente?paginaAtual=0&carregarLimit=1000&cd_pav_cliente_not=true');
            clienteData = extrairPropriedades(clienteData.data.dados)
            clienteData = clienteData.filter(cliente => cliente.nivel > 2)
            setListaClientes(clienteData);
        }
        fetchClienteData()
        setSpinAtivo(false)
    }, []);

    //GET INITIAL STATES
    useEffect(() => {
        if (nenhumaLinhaSelecionada) {
            setSelectedRadio(atribuirTemplate)
        }
        else {
            setSelectedRadio(atribuirSelecionadas)

            if(props.itensSelecionados?.length) {
                setListaLinhas(props.itensSelecionados)
            }
        }

    }, []);


    useEffect(() => {
        if(selectedRadio === atribuirSelecionadas){
            if(props.itensSelecionados){
                setListaLinhas(props.itensSelecionados)
            } else {
                setListaLinhas([]);
            }
        }
        else if(selectedRadio === atribuirTemplate){
            if(arquivoSalvo){
                setListaLinhas(arquivoSalvo)
            }
            else {
                setListaLinhas([]);
            }

        }
    }, [selectedRadio]);


    function limparModal() {
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
        itensVar = []
        // itensInvalidosTemp = []
        // simcardSelecionados = []
    }

    async function handlerIniciarTela() {
        const {itensSelecionados} = props;

        if(!itensSelecionados || itensSelecionados?.length === 0){
            setSpinAtivo(false);
            return
        }
        setSpinAtivo(true);

        limparModal();
        await contarItens()

        if (!modoSelecaoSimcard) {
            itensSelecionados.map((simcard) => {
                if (!linhasInvalidasAux.includes(simcard.CD_SIMCARD) && !linhasValidasAux.includes(simcard)) {
                    linhasValidasAux.push(simcard)
                    cdSimcardsArray.push(simcard.CD_SIMCARD)
                }
            })
            setItens(linhasValidasAux)
            totalPaginas = Math.ceil(linhasValidasAux.length / 50)
            setQuantidadeLinhasSelecionados(linhasValidasAux.length)
        }

        if (linhasInvalidasAux.length > 0) {
            setRevelarMensagemAtencaoInvalido(true)
        }

        if (linhasCanceladasAux.length > 0) {
            setRevelarMensagemAtencaoCancelados(true)
        }

        setSpinAtivo(false);
    }

    useEffect(() => {
        async function carregarTabela() {

            contador = (paginaAtual - 1) * valorLimite;

            await handlerIniciarTela()

            if (modoSelecaoSimcard === true) {
                await buscarSimcards()
            }


        }
        carregarTabela()
        document.addEventListener('mousedown', handleClickFora);

        return () => {
            limparModal();
            document.removeEventListener('mousedown', handleClickFora);
        }
    }, [paginaAtual])

    function downloadArquivoLinhasInvalidas(linhas) {
        montarXlsLinhasInvalidasAcaoAtribuir(linhas)
    }

    async function buscarSimcards() { // se selecionou "parque todo" -> abriu modal
        const isEmptyObject = (obj) => !obj || !Object.keys(obj)?.length;

        const semFiltros = (isEmptyObject(filtros) || filtros.vazio === 'vazio');
        const semBusca = !conteudoBusca && isEmptyObject(buscaArquivo);

        if(semFiltros && semBusca) {
            handleMensagemErro(false, "Realize uma busca ou filtro. Também é possível Atribuir linhas por arquivo")
            return;
        }

        setSpinAtivo(true);

        if (controladorPagina <= paginaAtual) {
            let pagina = paginaAtual - 1;

            const urlGet = `/api/v2/devices/simcard?paguinaAtual=${pagina}&limit=10000&offset=0&busca=${conteudoBusca}&ordenador=${props.ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&cd_pav_usuario=${decryptJWT('codigo')}&linhasCanceladas=${linhasCanceladasAux}&linhasInvalidas=${linhasInvalidasAux}`

            const consultaSimcards =  await api_svdispositivos.get(urlGet)
            .then(function(resposta){
                return resposta.data.devices;
            })
            .catch(function(erro){
                throw erro;
            })

            setListaLinhas(consultaSimcards)

            inserirData(consultaSimcards)

            consultaSimcards.map((simcard) => {
                if (!linhasInvalidasAux.includes(simcard.CD_SIMCARD) && !linhasValidasAux.includes(simcard)) {
                    linhasValidasAux.push(simcard)
                    cdSimcardsArray.push(simcard.CD_SIMCARD)
                }
            })

            setItens(linhasValidasAux)

            setSpinAtivo(false)
        }
    }

    async function handleCancelar() {
        limparModal();
        ColetaClickstream(1153) // alterarPlanoCancelar
        setSpinAtivo(false)
        if(acaoAtribuir){
            despacho(revelarAtribuirLinhas(false))
        } else if (acaoDesatribuir){
            despacho(revelarDesatribuirLinhas(false))
        }

    }

    const colunasItensInvalidos = [{
        dataField: 'callerid',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'motivo',
        text: 'Motivo',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    }]

    async function contarItens() {
        let arrayItens = [];
        let busca = conteudoBusca.replace(",", ";").replace("|", ";").replace(" ", ";").replace("\n", ";");
        const urlGet = `/api/v2/devices/simcard?paguinaAtual=${contador}&carregarLimit=${valorLimite}&busca=${busca}&ordenador=${props.ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=true&cd_pav_usuario=${decryptJWT('codigo')}&linhasCanceladas=${linhasCanceladasAux}&linhasInvalidas=${linhasInvalidasAux}`;
        
        const apiData = await api_svdispositivos.get(urlGet)
        .then(function(resposta){
            return resposta;
        })
        .catch(function(erro){
            throw erro;
        })

        totalItens = apiData.data.devices[0].CONTADOR;
        totalPaginas = Math.ceil(totalItens / valorLimite)
        setQuantidadeLinhasSelecionados(totalItens)
        
        for (var i = 0; i < totalItens; i++) {
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    function inserirData(data) {
        // setItens('')
        if (itensVar.length == totalItens) {
            for (let i = 0; i < totalItens; i++) {
                if (contador === i) {
                    let k = i
                    for (let j = 0; j < data.length; j++) {
                        itensVar[k] = data[j]
                        k++
                    }
                }
                if (i == (totalItens - 1)) {
                    // itensTemp = JSON.parse(JSON.stringify(itensVar))
                    
                    setItens(JSON.parse(JSON.stringify(itensVar)))
                }
            }
        }
    }

    function handleMensagemConfirmacao(confirmacao) {
        if (mensagemConfirmacao === false) {
            setMensagemConfirmacao(true)
        } else {
            setMensagemConfirmacao(false)

            if(!confirmacao) handleCancelar();
        }

        if (confirmacao === true) {
            downloadArquivoLinhasInvalidas(dadosDownloadErros);
            handleAtribuir()
        }
    }

    function handleMensagemInformacao(confirmacao) {
        if (revelarMensagemInformacao === false) {
            setRevelarMensagemInformacao(true)
        } else {
            setRevelarMensagemInformacao(false)
            handleCancelar()
        }
        if (confirmacao) {
            handleCancelar()
        }
    }

    function handleMensagemErro(confirmacao, msg) {
        if (!confirmacao) {
            setMensagemErro(msg)
        }

        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
        }
    }

    function handleFecharMensagemItensInvalidos() {
        setRevelarMensagemAtencaoInvalido(!revelarMensagemAtencaoInvalido)
        if (modoSelecaoSimcard) {
            if (itens <= 0) {
                handleCancelar()
            }
        }
        else {
            if (quantidadeLinhasSelecionados <= 0) {
                handleCancelar()
            }
        }
    }

    function handleFecharMensagemItensCancelados() {
        setRevelarMensagemAtencaoCancelados(!revelarMensagemAtencaoCancelados)
        if (modoSelecaoSimcard) {
            if (itens <= 0) {
                handleCancelar()
            }
        }
        else {
            if (quantidadeLinhasSelecionados <= 0) {
                handleCancelar()
            }
        }
    }

    function exportarXlsXlsx(){
        ColetaClickstream(1168) /*dropFiltrosFiltroPorArquivoExportarXlsXlsx*/
        
        exportarXlsx(arrayDadosExportacaoXlsXlsx, "Template.xls")
    }

    function handleSelectedRadio(value) {
        setSelectedRadio(value);
    }


    function validateFileExtension(fileName){
        const allowedExtensions =  /(\.xlsx|\.xls)$/i;
        if (!allowedExtensions.exec(fileName)) {
            handleMensagemErro(false, "Tipo de arquivo inválido. Por favor, selecione um arquivo XLS ou XLSX.")
            return false;
        }
        return true;
    }


    async function getLinhasByArquivo(arquivoImportado) {
        setSpinAtivo(true)

        const { dadosArquivo, erroExportar } = await extrairArquivoParaBusca({
            arquivoImportado,
            manejador
        })

        if(dadosArquivo) {
            await api_svdispositivos.get(
                '/api/v2/devices/simcard?busca=&contar=true&limit=1000&offset=0'
                + '&buscaArquivo='+ JSON.stringify(dadosArquivo)
            )
                .then(response => {
                    const dataDevices = response.data.devices;
                    if(dataDevices.length < 1){ 
                        setArquivoSalvo([])
                    }
                    setArquivoSalvo(dataDevices) 
                    setListaLinhas(dataDevices)
                })
                .catch((erro) => {
                    console.error({ erro })
                })
            setSpinAtivo(false)
        }
        else if(erroExportar) {
            handleMensagemErro(false, erroExportar?.response?.data?.msg)
        }

    }

    function handleImportarArquivo(event) {
        let arquivoImportado = event.target.files[0];
    
        if (arquivoImportado) 
        {
            if (validateFileExtension(arquivoImportado.name)) 
            {
                setNomeArquivo(arquivoImportado.name)
                setArquivo(arquivoImportado)
                const reader = new FileReader();
                reader.onload = function(e) 
                {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.Sheets[workbook.SheetNames[0]];
                    const linhas = XLSX.utils.sheet_to_json(sheetName, { header: 1 }).length;
                    if (linhas <= 1000) 
                    {
                        getLinhasByArquivo(arquivoImportado);
                    } else 
                    {
                        handleMensagemErro(false, 'O limite é de até 1000 linhas por atribuição.')
                    }
                };
                reader.readAsArrayBuffer(arquivoImportado);
            }
        }
    }

    function handleClickRef(e) {
        const clickRef = document.getElementById('atribuir-arquivo');
        clickRef.click();
    }

    function validateClientId(value) {
        if(value?.id){
            setClienteID(value.id)
        } else {
            setClienteID(undefined)
        }
    }

    function deleteFile() {
        setNomeArquivo(nomeArquivoPadrao)
        setArquivo(undefined);
        setArquivoSalvo(undefined);
        setListaLinhas([])
    }

    function parseDisclaimer(acaoModal) {
        if(acaoAtribuir){
            return 'atribuição'
        } else if (acaoDesatribuir){
            return 'desatribuição'
        }
    }


    function validateForm(){
        const nomeAcao = acaoModal.toLowerCase();

        if(!clienteID && acaoAtribuir){
            handleMensagemErro(false, 'Por favor, selecione um cliente para'+' '+ nomeAcao +' '+'as linhas.')
            return false
        }
        else if (selectedRadio === atribuirTemplate && !arquivoSalvo){
            handleMensagemErro(false, 'Você precisa importar um arquivo para'+' '+ nomeAcao +' '+'as linhas.') 
            return false
        }
        else if (selectedRadio === atribuirTemplate && arquivoSalvo && arquivoSalvo.length === 0){
            handleMensagemErro(false, 'O arquivo não contém linhas ativas para'+' '+ parseDisclaimer(acaoModal)+'. '+'Verifique o arquivo.')
            return false
        }
        else if (!listaLinhas || qtdListaLinhas === 0){
            handleMensagemErro(false, 'Você precisa'+' '+ nomeAcao +' '+'ao menos uma linha para continuar - selecione na tabela ou importe um arquivo.') 
            return false
        }
        else {
            return true
        }
    }


    function parseModalMessage(acaoModal) {
        if(acaoAtribuir){
            return 'As linhas foram atribuidas com sucesso.'
        } else if(acaoDesatribuir){
            return 'As linhas foram desatribuidas com sucesso.'
        }
    }

    function handleMensagemFinal(responseData){
        const statusCode = responseData.status;

        if(statusCode === 200){
            if(responseData.linhasAfetadas) { // atenção
                setMensagemSucesso( parseModalMessage(acaoModal))
                handleMensagemSucesso(false)
            }
        }
        else {
            handleMensagemErro(false, parseModalMessage(acaoModal))
        }
    }

    async function atribuirLinhas(params) {
        try{
            await api_svdispositivos.post('/api/v2/devices/simcardTransferN3', params).then(response => {
                handleMensagemFinal(response.data)
            })
        }
        catch (error) {
            const errorData = error.response?.data;
            let arrMensagem = [errorData?.message];

            if (errorData?.data?.linhasInvalidas) {
                setDadosDownloadErros(errorData.data.linhasInvalidas)
                arrMensagem[0] += " O download do arquivo será feito após clicar em Confirmar.";

                arrMensagem.push(" Deseja prosseguir com a associação das outras linhas?")
            }

            if(errorData?.data?.retirarLinhasInvalidasParaContinuar) {
                const linhasNaoAssociadas = listaLinhas.filter(simcard => !simcard.CLIENTE_NOME)
    
                setListaLinhas(linhasNaoAssociadas)
                setMensagemConfirmacao(arrMensagem);
            }
            else {
                handleMensagemErro(false, errorData.message)
            }
        }

    }

    async function desatribuirLinhas(params) {
        try {
           await api_svdispositivos.put('/api/v2/devices/simcardTransferN3', params).then(response => {
                handleMensagemFinal(response.data)
            })
        } catch (error) {
            if(error.response.status === 400){
                handleMensagemErro(false, error.response.data.message)
            }
        }
    }


    async function handleFinalizar() {
        setSpinAtivo(true);
        let arrayDspSimCard = listaLinhas.map(linha => linha.CD_SIMCARD)
        let data = {
            "cd_pav_cliente_n3": acaoAtribuir ? clienteID : undefined,
            "simcards": arrayDspSimCard,
        }

        if(acaoAtribuir){
            await atribuirLinhas(data)
        } else if (acaoDesatribuir){
            await desatribuirLinhas(data)
        }

        setSpinAtivo(false);
    }

    async function handleAtribuir() {
        if(validateForm()){
            handleFinalizar();
        }
    }

    return (
        <Form ref={modal} /*onSubmit={handleSubmit(() => handleSalvar())}*/>
            <div className="modal-atribuir-linhas fundo-cor-6">
                {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse" />
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{acaoModal} linhas</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela" />
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <div className={'atribuir-linha-wrapper'}>
                        {acaoAtribuir &&
                            <>
                                <div className={'atribuir-selected-row-cliente'}>
                                    <Autocomplete
                                        disablePortal
                                        id={temaEscuro ? 'combo-box-demo-noturno' : 'combo-box-demo'}
                                        className={temaEscuro ? 'select-tipo-cliente-noturno' : 'select-tipo-cliente'}
                                        options={listaClientes}
                                        onChange={(event, value) => validateClientId(value)}
                                        sx={{width: 500, height: 55}}
                                        openText={false}
                                        closeText={false}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                sx={{
                                                    borderColor: greenDefault,
                                                    height: '55 !important',
                                                    fontSize: '20px !important'
                                                }}
                                                placeholder={'Selecione o cliente'}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={temaEscuro?'atribuir-divider-noturno':'atribuir-divider'}></div>
                            </>
                        }
                        <div className={'atribuir-template-row'}>
                            <div className={'atribuir-radio'}>
                                <Radio
                                    disabled={nenhumaLinhaSelecionada}
                                    checked={selectedRadio === atribuirSelecionadas}
                                    onChange={(e) => handleSelectedRadio(e.target.value)}
                                    value={atribuirSelecionadas}
                                    name={'atribuir-selecionadas-radio'}
                                    sx={radioButtonSxStyle}
                                ></Radio>
                                <div className={classRadioLabel}>{acaoModal} linhas selecionadas</div>
                            </div>
                            <div className={'atribuir-radio'}>
                                <Radio
                                    checked={selectedRadio === atribuirTemplate}
                                    onChange={(e) => handleSelectedRadio(e.target.value)}
                                    value={atribuirTemplate}
                                    name={'atribuir-template-radio'}
                                    sx={radioButtonSxStyle}
                                ></Radio>
                                <div className={classRadioLabel}>{acaoModal} linhas por arquivo</div>
                            </div>
                        </div>

                        <div>
                            {selectedRadio === atribuirTemplate
                                ?
                                <>
                                    <div className={'template-row-action-btns'}>
                                        <div className={'template-action-btn tmp-btn'}
                                             onClick={() => exportarXlsXlsx()}>
                                            <div className={'template-action-btn-text'}>Template</div>
                                            <img src={exportarDescricaoVerde()}/>
                                        </div>
                                        <div className={'template-action-btn upload-btn'}
                                             onClick={(e) => handleClickRef(e)}>
                                            <input type="file" id="atribuir-arquivo"
                                                   className={'input-arquivo-atribuir'}
                                                   onChange={(e) => handleImportarArquivo(e)}></input>
                                            <div className={'template-action-btn-text'}>Importar</div>
                                            <img src={importarArquivo()}/>
                                        </div>
                                    </div>
                                    {nomeArquivo &&
                                        <div className={'template-row-file-field'}>
                                            <div>{nomeArquivo}</div>
                                            <img onClick={(e) => deleteFile()} className={'delete-file-btn'}
                                                 src={lixeiraVermelho()}/>
                                        </div>
                                    }
                                </>
                                //SE NÃO ESTIVER NA OPÇÃO CORRETA, MOSTRA BOTÕES DESABILITADOS.
                                :
                                <div className={'template-row-action-btns'}>
                                    <div className={temaEscuro?'template-action-btn btn-disable-noturno':'template-action-btn btn-disable'}>
                                        <div className={'template-action-btn-text'}>Template</div>
                                        <img src={iconeExportarCinza()}/>
                                    </div>
                                    <div className={temaEscuro?'template-action-btn btn-disable-noturno':'template-action-btn btn-disable'}>
                                        <div className={'template-action-btn-text'}>Importar</div>
                                        <img src={iconeImportarCinza()}/>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={'disclaimer-qtd-linhas-wrapper'}>
                                {qtdListaLinhas
                                    ?
                                    <div className={'disclaimer-qtd-linhas'}>
                                        <img className={'icone-disclaimer sucess-icon'} src={iconeSucessoVerdeBright()} />
                                        <div className={'disclaimer-qtd-linhas-label sucess-icon'}>
                                            {qtdListaLinhas} linhas selecionadas para {parseDisclaimer(acaoModal)}
                                        </div>
                                    </div>

                                    :
                                    <div className={'disclaimer-qtd-linhas'}>
                                        <img className={'icone-disclaimer error-icon'} src={alertaVermelho()} />
                                        <div className={'disclaimer-qtd-linhas-label error-icon'}>
                                            Nenhuma linha selecionada para {parseDisclaimer(acaoModal)} - Selecione um arquivo ou linhas
                                        </div>
                                    </div>

                                }
                        </div>

                        <TextoAtencao texto={`Ao ${acaoModal.toLowerCase()} linhas por arquivos, as linhas selecionadas serão desconsideradas`} />

                        {(selectedRadio === atribuirTemplate) && (<>
                            <TextoAtencao texto={`Os tipos de arquivos suportados são .xls e .xlsx`} />
                            <TextoAtencao texto={`O arquivo deve conter um cabeçalho com apenas uma coluna com 'LINHA' ou 'ICCID'`} />
                        </>)}
                        
                        <div className={'action-buttons-wrapper'}>
                            <div onClick={(e) => handleCancelar()} className={'cancel-action-btn'}>Cancelar</div>
                            <div onClick={(e) => handleAtribuir()} className={'handler-action-btn'}>Concluir</div>
                        </div>

                    </div>
                </Form.Row>
            </div>

            {revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"A solicitação está sendo gerada!"}
                            mensagemParagrafo1={"Esse processo levará alguns minutos, assim que estiver pronto você receberá uma mensagem de conclusão pela plataforma."}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }

            { mensagemConfirmacao && 
                <RenderMensagemAtencao
                    handleMensagemAtencao={handleMensagemConfirmacao}
                    mensagemTitulo={'Atenção!'}
                    mensagemParagrafo1={mensagemConfirmacao[0]}
                    mensagemParagrafo2={mensagemConfirmacao[1]}
                    mensagemBotao={"Confirmar"}
                />
            }

            {revelarMensagemErro &&
                <MensagemErro
                    handleMensagem={handleMensagemErro}
                    mensagemTitulo={"Erro"}
                    mensagemParagrafo1={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={mensagemSucesso}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemAtencaoInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso" />
                            </div>
                            <div className="fonte-cor-1 label-janela">{itensInvalidosContador.length} linhas foram retiradas</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensInvalidos}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensInvalidos()}>
                                    <div className="fonte-cor-2">Continuar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {revelarMensagemAtencaoCancelados &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso" />
                            </div>
                            <div className="fonte-cor-1 label-janela">Você estará executando uma solicitação para {itensCancelados.length} linhas que constam como canceladas ou bloqueadas.</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensCancelados}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensCancelados()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {revelarMensagemAtencaoCancelados &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso" />
                            </div>
                            <div className="fonte-cor-1 label-janela">Você estará executando uma solicitação para {itensCancelados.length} linhas que constam como canceladas ou bloqueadas.</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensCancelados}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensCancelados()}>
                                    <div className="fonte-cor-2">Continuar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
    )
}