import React, { PureComponent } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { corDash as cor } from "../../../../../utils/dashboard";
import {
  getCorFundoGrafico,
  getCorFundoTooltip,
  getCorTexto,
} from "../../cores";

export default class MyBarChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: null,
    };
  }

  handleMouseMove = (e) => {
    if (e && e.activeTooltipIndex !== undefined) {
      this.setState({ activeIndex: e.activeTooltipIndex });
    }
  };

  handleMouseLeave = () => {
    this.setState({ activeIndex: null });
  };

  render() {
    const {
      data,
      arrDadosCadaCiclo,
      getValorLegenda,
      className,
      prefixo,
      isUltimos6Ciclos,
    } = this.props;
    const { activeIndex } = this.state;

    const tema = localStorage.getItem("tema");
    const axisColor = tema === "escuro" ? cor.branco : cor.preto;

    const barSize = isUltimos6Ciclos ? 17 : 80;
    const barGap = isUltimos6Ciclos ? 5 : 40;

    const temValorNegativo = data.some((item) =>
      Object.values(item).some(
        (value) => typeof value === "number" && value < 0
      )
    );

    return (
      <ResponsiveContainer
        debounce={300}
        width="100%"
        height="100%"
        className={className}
      >
        <BarChart
          style={{
            backgroundColor: getCorFundoGrafico(),
            borderRadius: "10px",
          }}
          innerRadius="60%"
          outerRadius="80%"
          stackOffset="sign"
          fill={cor.cinzaEscuro}
          paddingAngle={0}
          stroke={0}
          data={data}
          barSize={barSize}
          barGap={barGap}
          margin={{
            top: 25,
            right: 25,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis
            tick={{ fontSize: "13px" }}
            dataKey="name"
            stroke={axisColor}
          />
          <YAxis tick={{ fontSize: "13px" }} stroke={axisColor} />
          {temValorNegativo && <ReferenceLine y={0} stroke={axisColor} />}
          <Tooltip
            cursor={{ fill: "transparent" }}
            active={true}
            allowEscapeViewBox={{ x: true, y: true }}
            payload={[data[activeIndex]]}
            content={(props) => (
              <CustomTooltip
                {...{
                  ...props,
                  prefixo,
                  arrDadosCadaCiclo,
                  getValorLegenda,
                }}
              />
            )}
          />

          {arrDadosCadaCiclo?.map(({ dataKey, color, numBarra }) => (
            <Bar
              key={dataKey}
              dataKey={dataKey}
              stackId={numBarra}
              fill={color}
              layout="vertical"
              onMouseMove={this.handleMouseMove}
              onMouseLeave={this.handleMouseLeave}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

function CustomTooltip(props) {
  const {
    payload,
    label,
    active,
    prefixo,
    getValorLegenda,
    arrDadosCadaCiclo,
  } = props;

  if (active && payload !== null && payload.length > 0) {
    const Label = ({ children, color, cabecalho }) => {
      return (
        <div className="tooltip-label-grafico-migracaoPlano">
          {!cabecalho && (
            <div
              className="esfera-tooltip-dashboard-migracaoPlano"
              style={{ backgroundColor: color }}
            ></div>
          )}
          <p style={{ color }}>{children}</p>
        </div>
      );
    };

    return (
      <div
        className="container-tooltip-grafico-migracaoPlano"
        style={{ backgroundColor: getCorFundoTooltip() }}
      >
        <Label color={getCorTexto()} cabecalho={true}>
          {label}
        </Label>
        {payload.map((item, index) => {
          const { color, value } = item;

          let total = 0;
          arrDadosCadaCiclo.forEach(ciclo => {
            total += item.payload[ciclo.dataKey] || 0;
          })

          const displayValue = `${prefixo || ""} ${getValorLegenda(
            value,
            total
          )}`;

          return (
            <Label key={index} color={color}>
              {displayValue}
            </Label>
          );
        })}
      </div>
    );
  }
  return null;
}
