//IMPORTAÇÕES
import React from 'react';/*eslint-disable*/
import {Form, Row, Col, Button} from 'react-bootstrap';
//ICONES
import {elipseVerde, fecharJanela,} from '../../../../utils/alternanciaIcones';
import '../../../../css/painelFaturas/fatConciliacao/components/modalMotivo.css'

export default function ModalMotivo(props){
    function handleCancelar() {
        props.setRevelarMotivo(false)
    }
    return(
    <Form>
        <div className="modal-motivo-conciliacao fundo-cor-6">
            <Form.Row>
                <Col>
                    <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                    <Form.Label>
                        <div className="fonte-cor-1 espacador_5 label-janela">Motivo</div>
                    </Form.Label>
                    <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                        <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                    </Button>
                </Col>
            </Form.Row>
            <Form.Row>
                <div className="modal-motivo-texto">
                    <p className="fonte-cor-1">{props.motivo}</p>
                </div>
            </Form.Row>
        </div>
    </Form>
    )
}


