import React from 'react'
import { Button } from 'react-bootstrap'
import moment from 'moment';

import utilsLocalStorage from '../../../utils/localStorage';
import {downloadVerde} from '../../../utils/alternanciaIcones';
import { exportarXlsx } from '../../../utils/exportarArquivo';

function ExportarRelatorioTeste({ itensPag2, testouTodos }) {
    function getArrayDadosExportacao() { // Dados 'Teste de Conectividade'
        const dados = itensPag2.map(item => {
            const { CALLERID, ICCID, ON_OFF, ULTIMO_EVENTO, gsm, gprs, ENVIOU_SOLICITACAO_RESET } = item;

            return [ CALLERID, ICCID, ON_OFF, ULTIMO_EVENTO, gsm, gprs, (ENVIOU_SOLICITACAO_RESET || '-') ];
        })

        const nomeUsuario = utilsLocalStorage.getUsuarioNomeCompleto() || 'NOME_USUARIO';
        const usuarioArr = ['Usuário:', nomeUsuario];
        const dataHora = moment().format("DD/MM/YYYY HH:mm:ss") || '00/00/0000 00:00:00';
        const dataHoraArr = ['Data/Hora:', dataHora];
        const colunasLinha3 = ['Linha', 'ICCID', 'Status', 'Última conexão', 'Status GSM', 'Status GPRS', 'Reset'];

        const colunasRelatorio = [usuarioArr, dataHoraArr, colunasLinha3];

        return [ ...colunasRelatorio, ...dados ];
    }

    function exportarXlsXlsx(){
        const arrayDadosExportacaoXlsXlsx = getArrayDadosExportacao();

        // ColetaClickstream(???)
        exportarXlsx(arrayDadosExportacaoXlsXlsx, "Relatorio_Teste_Conectividade.xlsx", "Teste de Conectividade")
    }

    return (
        <Button
            onClick={exportarXlsXlsx}
            disabled={!testouTodos}
            className="botao-confirmar botao-confirmar-branco"
            size="sm"
        >
            <div>Exportar relatório do teste</div>
            <img className="img-exportar-relatorio-teste" alt="ícone download" src={downloadVerde()} />
        </Button>
    )
}

export default ExportarRelatorioTeste