//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';
import { useForm } from "react-hook-form";
import { Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { elipseVerde, fecharJanela, exclamacao,setaDropdown } from '../../../utils/alternanciaIcones'
//MODAIS
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import SelectGenerico from '../../componentesModais/selects/selectGenerico'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelCadastros/perfil/tabela.css'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarEditarPerfil, revelarNovoPerfil } from "../../../redux/actions/index";

export default function CadastroPerfil(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();/*eslint-disable*/
    //HOOKS PRINCIPAIS
	const [cdPerfil, setCdPerfil] = useState(0)
    const [dsPerfil, setDsPerfil] = useState('');
    const [dsDescricao, setDsDescricao] = useState('');
    const [switchFlVirtueyes, setSwitchFlVirtueyes] = useState(false);
    const [switchFlAtribuivel, setSwitchFlAtribuivel] = useState(false);
    const [switchFlAtivo, setSwitchFlAtivo] = useState(false);
    const [switchFlCustomizado, setSwitchFlCustomizado] = useState(false);
    const [revelarSelectCliente, setRevelarSelectCliente] = useState(false);
    const [cdCliente, setCdCliente] = useState(undefined);
    const [dsCliente, setDsCliente] = useState('');
    const [n0_precedencia, setN0_precedencia] = useState('');
    const [dumb, setDumb] = useState('');
    //HOOKS PLACEHOLDERS
    const [phPerfil, setPhPerfil] = useState('');
    const [phDescricao, setPhDescricao] = useState('');
    const [phMensagemSucesso, setPhMensagemSucesso] = useState('cadastrado')
    //HOOKS MODAIS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const [tituloJanela, setTituloJanela] = useState('Cadastro');
    const [caracteresMensagem, setCaracteresMensagem] = useState(500);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const modal = useRef();
    const despacho = useDispatch();

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        setSpinAtivo(true)
        if (props.tituloJanela === 'Editar'){
            //TROCA O TITULO DA JANELA
            setTituloJanela('Editar')
            //CARREGA OS CAMPOS COM OS VALORES ORIGINAIS
			setCdPerfil(props.cdPerfil)
            setDsPerfil(props.dsPerfil)
            setDsDescricao(props.dsDescricao)

            setSwitchFlVirtueyes(props.flVirtueyes == 1 ? true : false)
            setSwitchFlAtribuivel(props.flAtribuivel == 1 ? true : false)
            setSwitchFlAtivo(props.flAtivo == 1 ? true : false)
            setSwitchFlCustomizado(props.flCustomizado == 1 ? true : false)
            setCdCliente(props.cd_pav_cliente)
            setDsCliente(props.ds_pav_cliente)
            setN0_precedencia(props.n0_precedencia)

            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhPerfil('Original: '+props.dsPerfil)
            setPhDescricao('Original: '+props.dsDescricao)
            setPhMensagemSucesso('editado')
        }
        if (props.tituloJanela === 'Cadastrar'){
            setTituloJanela('Cadastrar')
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhPerfil('Perfil')
            setPhDescricao('Descrição')
            setPhMensagemSucesso('cadastrado')
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    //FAZ O MANEJO 
    function handleSalvar(){
        if (tituloJanela === 'Cadastrar'){
            handleCadastrar()
        }else{
            handleAlterar()
        }
    }

    async function handleAlterar(){
        setSpinAtivo(true)
        await api.post('/m1/alterar/perfil', {
            "dados":{
                'ds_pav_perfil': dsPerfil.trim(),
                'ds_descricao': dsDescricao,
                'cd_pav_cliente': cdCliente,
                'fl_virtueyes': switchFlVirtueyes ? 1 : 0,
                'fl_atribuivel': switchFlAtribuivel ? 1 : 0,
                'bl_pav_ativo': switchFlAtivo ? 1 : 0,
                'fl_customizado': switchFlCustomizado ? 1 : 0,
                'n0_precedencia': n0_precedencia
            },
            "condicao":{
                'cd_pav_perfil': cdPerfil,
            }
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    async function handleCadastrar(){
        setSpinAtivo(true)
        await api.post('/m1/cadastrar/perfil', {
            'ds_pav_perfil': dsPerfil.trim(),
            'ds_descricao': dsDescricao,
            'cd_pav_cliente': cdCliente,
            'fl_virtueyes': switchFlVirtueyes ? 1 : 0,
            'fl_atribuivel': switchFlAtribuivel ? 1 : 0,
            'bl_pav_ativo': switchFlAtivo ? 1 : 0,
            'fl_customizado': switchFlCustomizado ? 1 : 0,
            'n0_precedencia': n0_precedencia
        },)
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    function handleCancelar(){
        despacho(revelarNovoPerfil(false))
        despacho(revelarEditarPerfil(false))
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
        props.setRenderizar(!props.renderizar)
        props.setRevelarCadastro(false)
        props.setRevelarEdicao(false)
    }

    function handleDescricao(valor){
        setDsDescricao(valor)
        setCaracteresMensagem(500 - valor.length)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }

    function validarNumero(valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            setN0_precedencia(valor)
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="modal-cadastro-perfil fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{tituloJanela} perfil</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group className="cadastro-perfil-imput-select">
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Nome</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Perfil</Tooltip>}>
                                <Form.Control name="dsPerfil" value={dsPerfil}  className="campo-texto-cor-3 campo-texto" type="text" placeholder={phPerfil} onChange={e => setDsPerfil(e.target.value)} 
                                ref={register({validate: {minimo: value => value.length > 1}})} maxlength="100"/>
                            </OverlayTrigger>
                            {errors.dsPerfil && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="cadastro-perfil-imput-select">
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Precedência</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Nivel hierárquico (o padrão é 7 para perfis de cliente) </Tooltip>}>
                                <Form.Control 
                                    name="n0_precedencia" 
                                    value={n0_precedencia}  
                                    className="campo-texto-cor-3 campo-texto" 
                                    type="text" 
                                    placeholder={n0_precedencia}  
                                    onChange={e => validarNumero(e.target.value)} 
                                ref={register({validate: {minimo: value => value.length >= 1}})} maxlength="2"/>
                            </OverlayTrigger>
                            {errors.n0_precedencia && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="cadastro-perfil-imput-select">
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Cliente</div>
                            </Form.Label>
                            { revelarSelectCliente === false &&
                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                    <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                    <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }
                            { revelarSelectCliente === true &&
                                    <SelectGenerico
                                        paramRota={"cliente"}
                                        paramCampo1={null}
                                        paramCampo2={null}
                                        paramCampo3={null}
                                        paramCampo4={null}
                                        setCodigo={setCdCliente}
                                        setDescricao1={setDsCliente}
                                        setDescricao2={setDumb}
                                        setDescricao3={setDumb}
                                        setDescricao4={setDumb}
                                        setRevelarSelect={setRevelarSelectCliente}
                                        placeholder={dsCliente}
                                        sufixo={""}
                                    >
                                    </SelectGenerico>
                            }
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Referece a se o perfil será para uso interno</Tooltip>}>
                                <div className="container-termo-adesao">
                                    <div class="switchDiv">
                                        <div  class="flipswitch-grupo">
                                            <input checked={switchFlVirtueyes} id="switchStatusInicial" type="checkbox" name="flipswitch" class="flipswitch-cb" onChange={(e) => setSwitchFlVirtueyes(e.target.checked)}/>
                                            <label for="switchStatusInicial" class="flipswitch-label">
                                                <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                <div class="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo fonte-cor-1"> Virtueyes </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Referece a se o perfil será padrão de uso do cliente</Tooltip>}>
                                <div className="container-termo-adesao">
                                    <div class="switchDiv">
                                        <div  class="flipswitch-grupo">
                                            <input checked={switchFlAtribuivel} id="switchFlAtribuivel" type="checkbox" name="flipswitch" class="flipswitch-cb" onChange={(e) => setSwitchFlAtribuivel(e.target.checked)}/>
                                            <label for="switchFlAtribuivel" class="flipswitch-label">
                                                <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                <div class="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo fonte-cor-1"> Atribuivel </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Referece a se o perfil está ativo</Tooltip>}>
                                <div className="container-termo-adesao">
                                    <div class="switchDiv">
                                        <div  class="flipswitch-grupo">
                                            <input checked={switchFlAtivo} id="switchFlAtivo" type="checkbox" name="flipswitch" class="flipswitch-cb" onChange={(e) => setSwitchFlAtivo(e.target.checked)}/>
                                            <label for="switchFlAtivo" class="flipswitch-label">
                                                <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                <div class="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo fonte-cor-1"> Ativo </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Referece a se o perfil será para o cliente</Tooltip>}>
                                <div className="container-termo-adesao">
                                    <div class="switchDiv">
                                        <div  class="flipswitch-grupo">
                                            <input checked={switchFlCustomizado} id="switchFlCustomizado" type="checkbox" name="flipswitch" class="flipswitch-cb" onChange={(e) => setSwitchFlCustomizado(e.target.checked)}/>
                                            <label for="switchFlCustomizado" class="flipswitch-label">
                                                <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                <div class="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo fonte-cor-1"> Customizado </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Descrição</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Descrição</Tooltip>}>
                                <Form.Control className="campo-texto-cor-3 campo-texto-area" as="textarea" rows="3" maxlength="500" placeholder={phDescricao != null ? "" : phDescricao} defaultValue={dsDescricao} onChange={e => handleDescricao(e.target.value)}></Form.Control>
                            </OverlayTrigger>
                            <Form.Label className="contador-carateres-restantes">
                                <div className="fonte-cor-1 label-normal">{caracteresMensagem} caracteres restantes</div>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">{tituloJanela}</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Perfil "+dsPerfil+" "+phMensagemSucesso+"."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}