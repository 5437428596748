//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import decryptJWT from '../../../../utils/decryptJWT'
import moment from 'moment'
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
//ICONES
import {elipseVerde, fecharJanela, setaDropdown, exclamacao, editar} from '../../../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
//MODAIS
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//CSS
import '../../../../css/painelFaturas/fatFebraban/tabela.css'
import { revelarImportarFebrabans, revelarAssociarFatura  } from '../../../../redux/actions';
import decrypt from '../../../../utils/decryptJWT';

let arrayOperadoraFebraban = []
let arrayContaFebraban = []
let arrayReferenciaFebraban = []

export default function ImportarFabrabans(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [mostrarAssociados, setMostrarAssociados] = useState(false)    
    const [cdOperadora, setCdOperadora] = useState(0)
    const [dsOperadora, setDsOperadora] = useState("Selecione")
    const [cdConta, setCdConta] = useState(0)
    const [dsConta, setDsConta] = useState("Selecione")
    const [cdFaturaReferencia, setCdFaturaReferencia] = useState(0)
    const [dsReferencia, setDsReferencia] = useState("Selecione")
    const [revelarSelectOperadora, setRevelarSelectOperadora] = useState(false)
    const [revelarSelectConta, setRevelarSelectConta] = useState(false)
    const [revelarSelectReferencia, setRevelarSelectReferencia] = useState(false)
    const [dummy, setDummy] = useState()
    const [n2ValorTotal, setN2ValorTotal] = useState('');

    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();

    const cd_pav_usuario = decryptJWT('codigo')

    const axios = require('axios');

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    },[])

    async function handleSalvar(){
        setSpinAtivo(true)
        ColetaClickstream(1162) // associarFaturaConfirmar
        await api.post('/m1/cadastrar/fatFebrabanAssociar', {
            "dados":{
                "ds_operadora": dsOperadora,
                "ds_conta": dsConta,
                "cd_conta": cdConta,
                "dt_associacao": moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                "ds_referencia": dsReferencia,
            },
            "condicao":{
                "cd_fat_febraban": props.cdFatFebraban,
            }

        },{timeout: 20000})
        .then(async function (response) {
            await api.post('/m1/cadastrar/fat_faturas_historico', {
                'cd_fat_fatura':cdFaturaReferencia,
                'cd_fat_fatura_historico_tipo':5,
                'n2_valor_cobrado':n2ValorTotal,
                'cd_pav_usuario_created_at':cd_pav_usuario
            })
            setSpinAtivo(false)
            handleMensagemSucesso(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro("Erro na solicitação")
            handleMensagemErro(false)
        });

        setSpinAtivo(false)
    }

    async function handleCancelar() {
        ColetaClickstream(1163) // associarFaturaCancelar
        setSpinAtivo(false)
        props.setRenderizar(!props.renderizar)
        despacho(revelarImportarFebrabans(false))
        despacho(revelarAssociarFatura(false))
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao ===true){
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-importar-febrabans fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela"> Associar fatura</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                {/* CORPO */}
                <Form.Row className="usuario-dados-corpo-linha">
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Arquivo</div>
                            </Form.Label>
                            <Form.Control 
                                defaultValue={props.nomeArquivo} 
                                // name="protocolo" 
                                className="campo-texto-cor-3 campo-texto" 
                                maxLength="255" 
                                placeholder="Insira o valor pago" 
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row className="usuario-dados-corpo-linha">
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Operadora</div>
                            </Form.Label>
                            {revelarSelectOperadora === false &&
                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                    <div className="fonte-cor-1 label-campo">{dsOperadora}</div>
                                    <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }

                            {revelarSelectOperadora === true &&
                                <SelectGenerico
                                    paramRota={'dsp_operadora'}
                                    paramCampo1={decryptJWT('codigo')}
                                    paramCampo2={null}
                                    paramCampo3={null}
                                    paramCampo4={null}
                                    setCodigo={setCdOperadora}
                                    setDescricao1={setDsOperadora}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectOperadora}
                                    placeholder={dsOperadora}
                                    sufixo={""}
                                >
                                </SelectGenerico>
                            }
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row className="usuario-dados-corpo-linha">
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Conta</div>
                            </Form.Label>
                            {cdOperadora === 0 &&
                                <Button className="campo-texto-cor-3 campo-select">
                                    <div className="fonte-cor-1 label-campo">{dsConta}</div>
                                    <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }
                            {revelarSelectConta === false && cdOperadora > 0 &&
                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectConta(!revelarSelectConta)}>
                                    <div className="fonte-cor-1 label-campo">{dsConta}</div>
                                    <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }

                            {revelarSelectConta === true && 
                                <SelectGenerico
                                    paramRota={'fat_conta_por_operadora'}
                                    paramCampo1={decryptJWT('codigo')}
                                    paramCampo2={decryptJWT('codigoCliente')}
                                    paramCampo3={decryptJWT('fatContas')}
                                    paramCampo4={cdOperadora}
                                    setCodigo={setCdConta}
                                    setDescricao1={setDsConta}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectConta}
                                    placeholder={dsConta}
                                    sufixo={""}
                                >
                                </SelectGenerico>
                            }
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row className="usuario-dados-corpo-linha">
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Referência</div>
                            </Form.Label>
                            {cdConta === 0 &&
                                <Button className="campo-texto-cor-3 campo-select">
                                    <div className="fonte-cor-1 label-campo">{dsReferencia}</div>
                                    <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }

                            {revelarSelectReferencia === false && cdConta > 0 &&
                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectReferencia(!revelarSelectReferencia)}>
                                    <div className="fonte-cor-1 label-campo">{dsReferencia}</div>
                                    <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }

                            {revelarSelectReferencia === true && 
                                <SelectGenerico
                                    paramRota={'referencia_febraban'}
                                    paramCampo1={cdConta}
                                    paramCampo2={mostrarAssociados}
                                    paramCampo3={null}
                                    paramCampo4={null}
                                    setCodigo={setCdFaturaReferencia}
                                    setDescricao1={setDsReferencia}
                                    setDescricao2={setN2ValorTotal}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectReferencia}
                                    placeholder={dsReferencia}
                                    sufixo={""}
                                >
                                </SelectGenerico>
                            }
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row className="usuario-dados-corpo-linha">
                    <div className="container-switch-febraban">
                        <div className="fonte-cor-1 label-normal label-termos-febraban"> Mostrar associadas: </div>
                        <div class="flipswitch">
                            <input type="checkbox" name="flipswitch" class="flipswitch-cb" id="permitirConfirmacao" onChange={() => setMostrarAssociados(!mostrarAssociados)}/>
                            <label class="flipswitch-label" for="permitirConfirmacao">
                                <div class="flipswitch-inner flipswitch-cor-1"></div>
                                <div class="flipswitch-switch"></div>
                            </label>
                        </div>
                    </div>
                </Form.Row>
                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo"/>
                <Form.Row className="espaco-reativar-linha">
                    <Col>
                        <>
                            {(dsOperadora != 'Selecione' && dsConta != 'Selecione' && dsReferencia != 'Selecione') ?
                                <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">Cadastrar</div>

                                </Button>
                            :
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">Cadastrar</div>

                                </Button>
                            }
                            <Button className={"botao-cancelar"} variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2">Cancelar</div>
                            </Button>
                        </>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Seu Febraban foi associado a fatura."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}