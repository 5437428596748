//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Form, Button } from 'react-bootstrap';/*eslint-disable*/
import decryptJWT from '../../../utils/decryptJWT'
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'

export default function SelectGrupos(props) {
    const [grupos, setGrupos] = useState([])
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleGrupos(codigo, grupo){
        props.setNomeGrupo(grupo)
        // props.setCdGrupo(codigo)
        props.setCdGrupoTemplate(codigo)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectGrupo(false)
    }

    useEffect(() => {
        async function renderGrupos(){
            let todosGrupos = [];
            //carrega os 3 grupos padroes
            const gruposPadroes = await api.get('/m1/consultar/simcard_grupo?fl_padrao=1')
            todosGrupos = gruposPadroes.data.dados;
            
            // carrega o grupos do cliente
            const {data} = await api.get('/m1/consultar/simcard_grupo?fl_padrao=0&&cd_pav_cliente='+parseInt(decryptJWT('codigoCliente'))) 
            await data.dados.map((item,i)=>{
                todosGrupos.push(item);
            })
           
            if(data.dados.length > 0){
                let objetoGrupos = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.ds_nome}>
                            <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleGrupos(item.cd_dsp_simcard_grupo, item.ds_nome) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.ds_nome}</span>
                            </div>
                            {todosGrupos[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setGrupos(objetoGrupos)
            }else{
                let objetoGrupos = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setGrupos(objetoGrupos)
            }
        }
        renderGrupos()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            <Button className="campo-select fundo-cor-7" onClick={() => props.setRevelarSelectGrupo(false)}>
                <div className="fonte-cor-1 label-campo">{props.nomeGrupo}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-2 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista  fundo-cor-7">
                    {grupos}
                </ul>
            </div>
        </Form>
    )
}