import React from "react";
import { useSelector } from "react-redux";

import { formatarMilhar } from "../../../../utils/formatarMilhar";
import { getCoresGraficoMigracaoPlano, getCoresInfoHeader } from "../cores";
import CardGraficoGenerico from "./CardGenerico";
import { getCicloFormatado } from "./CardGenerico/funcoes";
import "./GraficoAnaliseDeExcedentes.css";

const nomeOpcao = "Economia";

export default function GraficoAnaliseDeExcedentes({ revelarOpcoesGrafico }) {
  const arrDash = useSelector(
    (state) => state.migracaoPlanoState.dashboard[nomeOpcao]
  );

  const ciclo = getCicloFormatado(arrDash);

  const isUltimos6Ciclos = ciclo === "Últimos 6 ciclos";

  const cores = getCoresGraficoMigracaoPlano(nomeOpcao, isUltimos6Ciclos);

  const configDash = [
    {
      titulo: "Custo inicial",
      key: "CustoOriginal",
      cor: cores.azul,
      numBarra: 1,
    },
    {
      titulo: "Custo atual",
      key: "CustoPlanoMigracao",
      cor: cores.vermelho,
      numBarra: 2,
    },
    {
      titulo: "Custo excedente",
      key: "CustoExcedentePlanoMigracao",
      cor: cores.laranja,
      numBarra: 2,
    },
    {
      titulo: "Custo excedente previsto",
      key: "CustoExcedentePrevisto",
      cor: cores.amarelo,
      numBarra: 2,
    },
    {
      titulo: "Economia",
      key: "Economia",
      cor: cores.verde,
      numBarra: 2,
    },
  ];

  const dados = arrDash?.length ? arrDash[0] : {};

  return (
    <CardGraficoGenerico
      titulo="Análise de economia"
      nomeOpcao={nomeOpcao}
      InfoHeader={() => <InfoHeader {...dados} />}
      id={2}
      key={2}
      revelarOpcoesGrafico={revelarOpcoesGrafico}
      configDash={configDash}
      prefixo="R$"
      sufixo=""
    />
  );
}

function InfoHeader({ Economia }) {
  const isPrejuizo = Economia < 0;
  const cores = getCoresInfoHeader();

  return (
    <div className="container-infoHeader-analise-excedentes">
      <div className="infoHeader-box" style={{ color: cores.texto }}>
        <h6>{isPrejuizo ? "Prejuízo de" : "Economia de"}</h6>
        <span
          style={{ color: isPrejuizo ? cores.vermelho : cores.verdeEconomia }}
        >
          R$ {formatarMilhar(Economia || 0)}
        </span>
        <p className="fonte-cor-18">
          Diferença entre custo original, custo operadora e excedente previsto
        </p>
      </div>
    </div>
  );
}
