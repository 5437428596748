//IMPORTAÇÕES
import React, { Component } from "react";/*eslint-disable*/
import Dropzone from "react-dropzone";/*eslint-disable*/
import { Button, Card, Dropdown, Accordion, Image } from  "react-bootstrap";/*eslint-disable*/
import { uniqueId } from 'lodash';
import { CSVLink } from 'react-csv'
import {CircularProgressbar} from "react-circular-progressbar";

//ICONES
import { MdCheckCircle, MdError } from "react-icons/md";
import { importarArquivo, fecharJanela } from '../../../utils/alternanciaIcones';

//CSS
import "react-circular-progressbar/dist/styles.css";

//REDUX
import { useDispatch } from 'react-redux';
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

import ColetaClickstream from '../../clickstreamAnalise/clickstreamColeta.js'
import { MensagemErro, MensagemSucesso } from "../mensagens/mensagemAcao";
import { exportarXlsx } from "../../../utils/exportarArquivo.js";

let clickDentro = false

function exportarXlsXlsx(dadosJSONXLS, importarDescricao){
  if(importarDescricao) ColetaClickstream(1168);

  exportarXlsx(dadosJSONXLS, "template.xlsx")
}

function Sair(props){
  const despacho = useDispatch();

  async function handleSair() {
    despacho(props.revelarModal(false))
  }

  return <Button id="click-sair-modal" className="botao-fechar-janela" variant="danger" onClick={() => handleSair()}>
            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
          </Button>;
}

export default class ModalImportarArquivo extends Component {

    constructor(props) {
    super(props);
    this.state = {
      uploadedFiles: [],
      dadosArquivo: [],
      revelarMensagemSucesso: false,
      revelarMensagemErro: false,
      mensagemErro: ''
    };
  }

  uploadFiles = (uploadedFiles) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
    });

    uploadedFiles.forEach(this.processUpload);
  }

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
        return id === uploadedFile.id 
        ? { ...uploadedFile, ...data }
          : uploadedFile;
      })
    });
  };
  
  dadosArquivo = (id, data) => {
    this.setState({
      dadosArquivo: this.state.dadosArquivo.map(uploadedFile => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      })
    });
  };

  handleSairClick = () =>{
    const sair = document.getElementById('click-sair-modal')
    if(sair != null && clickDentro){
      clickDentro = false;
      sair.click()
    }
  }

  componentDidMount(){
    document.addEventListener('mousedown', this.handleSairClick);
    return () => document.removeEventListener('mousedown', this.handleSairClick);
  }

  
  uploadTabela = async (state) => {
    this.props.setSpinAtivo(true)

    let newState = {};

  await this.props.uploadTabela()
    .then(response => {

      if(response?.respostaValidada) {
          this.props.setSpinAtivo(false)
          this.handleDelete(); // se há erro, excluir arquivo local automaticamente

          newState = response.respostaValidada;

          if(this.props.atualizarTabelaAposErro) {
            this.atualizarTabela();
          }
      }
      else if(response.status === 200) {

        newState = {
          revelarMensagemSucesso: true
        }
      }
    })
    .catch((error) => {
      this.props.setSpinAtivo(false)

      newState = {
        revelarMensagemErro: true, 
        mensagemErro: error?.response?.data?.dados || ''
      }

      const auxErro = error?.data?.dados;

      if(this.props.getMensagemErroSalvar) {
        newState.mensagemErro = this.props.getMensagemErroSalvar(error);

        this.handleDelete(); // se há erro, excluir arquivo local automaticamente
      }
      else if(typeof auxErro === 'string') {
        newState.mensagemErro = auxErro;
      }
    });

    this.setState({
      ...newState,
      fezDownloadArquivoErros: false,
    })
  }

  processUpload = async (uploadedFile) => this.props.processUpload(uploadedFile, this);

  handleSetar () {
    this.setState({
        revelarMensagemSucesso: true
      })
  }

  handleDelete = async e => {
    this.setState({
      uploadedFiles: [],
      dadosArquivo: []
    });
  };

  async Cancelar() {
    if(this.props.cancelarCallback) this.props.cancelarCallback();

    clickDentro = true; 
    this.handleSairClick();
  }

 atualizarTabela = () => {
  this.props.setRenderizar(!this.props.renderizar);
 }

 handleMensagemSucesso = (confirmacao) =>{
   if(this.state.revelarMensagemSucesso === false){
     this.setState({
       revelarMensagemSucesso: true
      })
    }
    else {
      this.setState({
          revelarMensagemSucesso: false
      })
      if(this.props.faturas || !this.props.dispositivos) {
        this.atualizarTabela();
      }

      this.Cancelar()
    }

    if(confirmacao && this.props.dispositivos){
      this.atualizarTabela();
    }
  }
  
  handleMensagemErro = () => {
    if(this.state.revelarMensagemErro === false){
      this.setState({
        revelarMensagemErro: true,
      })
    }
    else {
      this.setState({
        revelarMensagemErro: false
      })
    }
  }

  render() {
    const mostrarBotaoBaixar = this.props.temDadosArquivoErros && this.props.downloadArquivoErros;
    const totalArquivos = this.state.dadosArquivo.length==0;
    const arquivosCarregados = !!this.state.uploadedFiles.length;

    return (
      <Card className="CardPrincipal fundo-cor-6">
        { this.props.spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
        <h1 className="titulo fonte-cor-1"> <div className="elipse"/> 
          {this.props.titulo}
          <div className='btn-sair-importar-descricao'>
            <Sair revelarModal={this.props.revelarModal}/>
          </div>
        </h1>
        
        <div className="div-Template">
          {this.props.apenasXLSX ? (
            <Button
              className="botaoTemplate bt-dropdown-template"
              onClick={() => exportarXlsXlsx(this.props.dadosJSONXLS, this.props.importarDescricao)}
            >
              <div className="UploadMessage fonte-cor-1"> Template </div>
            </Button>
          )
           : 
          (
            <Dropdown alignRight variant="success">
              <Accordion defaultActiveKey="0">
                <Accordion.Toggle
                  onClick={() => {if(this.props.accordionToggleOnClick) this.props.accordionToggleOnClick()}}
                  className="botaoTemplate bt-dropdown-template" as={Button} variant="link" eventKey="1"
                >
                  <div className="UploadMessage fonte-cor-1"> Template </div>
                </Accordion.Toggle>
                <Accordion.Collapse className="botaoTemplate  menu-dropdown-template" eventKey="1">
                  <>
                    <div className="triangulo-dropdown-configuracao-cabecalho-sistema triangulo-dropdown-template"></div>
                    <Button
                      filename="template.xlsx"
                      className="botaoDownload fonte-cor-1"
                      data={this.props.dadosJSONXLS}
                      onClick={() => exportarXlsXlsx(this.props.dadosJSONXLS, this.props.importarDescricao)}
                    >
                      XLSX
                    </Button>
                    <Dropdown.Divider className="template-border"/>
                    <CSVLink
                      onClick = {() => {if(this.props.csvLinkOnClick) this.props.csvLinkOnClick()}}
                      filename="template.csv"
                      className="botaoDownload fonte-cor-1"
                      data={this.props.dadosJSONCSV}
                    >
                      CSV
                    </CSVLink>
                  </>
                </Accordion.Collapse>
              </Accordion>
            </Dropdown>
          )}

            <BotaoImportar
              uploadFiles={this.uploadFiles}
              arquivosCarregados={arquivosCarregados}
              impedirMultiplosArquivos={this.props.impedirMultiplosArquivos}
            />
        </div>

        {this.props.aviso && <div className="divAviso">
          <div className="simboloAviso"/>
          <p className="textoAviso fonte-cor-1"> {this.props.aviso} </p>
        </div>}
        
        {arquivosCarregados && (
          <ListaArquivos
            files={this.state.uploadedFiles}
            onDelete={(e) => this.handleDelete(e)}
            cssClasses={this.props.cssClassesListaArquivos}
            onClickBotaoExcluir={this.props.onClickBotaoExcluir}
          />
        )}

        <div className="divBotoes">
          <Button
            disabled={(totalArquivos)?true:false}
            id="botaoConfirmar" 
            className={(totalArquivos)?"botao-confirmar-desabilitado":"botao-confirmar"}  
            type="submit" 
            onClick={(e)=> {this.uploadTabela(e); if(this.props.onConfirmarUpload) this.props.onConfirmarUpload()}}
          >
            <div className="fonte-cor-4">Confirmar</div>
          </Button>

          <Button className="botao-cancelar" type="submit" onClick={()=> this.Cancelar()}>
            <div className="fonte-cor-2">Cancelar</div>
          </Button>

          {this.state.revelarMensagemSucesso &&
            <MensagemSucesso
              handleMensagem={this.handleMensagemSucesso}
              mensagemTitulo={"Sucesso"}
              mensagemParagrafo1={this.props?.mensagemSucesso || "Seu arquivo foi importado"}
              mensagemBotao={"OK"}
            />
          }

          {this.state.revelarMensagemErro &&
            <MensagemErro
                handleMensagem={this.handleMensagemErro}
                mensagemTitulo={"Erro no arquivo"}
                mensagemParagrafo1={this.state.mensagemErro}
                mensagemBotao={"Voltar"}
                  botao1Disabled={mostrarBotaoBaixar && this.state.fezDownloadArquivoErros !== true}
                mensagemBotao2={mostrarBotaoBaixar ? 'Baixar' : false}
                downloadNoBotao2={() => {
                  this.props.downloadArquivoErros();
                  this.setState({
                    fezDownloadArquivoErros: true
                  })
                }}
            />
          }
        </div>
      </Card>
    );
  }
}

function BotaoImportar(props) {
  const renderDragMessage = (isDragActive) => {
    if (!isDragActive) {
      return <div className="fonte-branca-tema-branco UploadMessage">Importar</div>;
    }
    return <div className="UploadMessage" type="success"> Solte aqui </div>;
  };

  const handleUpload = (files) => {
    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      progress: 0,
      uploaded: false,
      error: false,
      url: null
    }));

    props.uploadFiles(uploadedFiles)
  };

  return ( /* Botao 'Importar' para selecionar arquivos  */
    <Dropzone onDropAccepted={handleUpload}>
    {({ getRootProps, getInputProps, isDragActive }) => (
      <Button className="botaoImportar"
        {...getRootProps()}
        // isDragActive={isDragActive} // comentei pra resolver warning
        disabled={props.arquivosCarregados && props.impedirMultiplosArquivos}
      >
        <input {...getInputProps()} />
        {renderDragMessage(isDragActive)}
        <Image className="imagemImportar" src={importarArquivo()} />
      </Button>
    )}
  </Dropzone>
  )
}

// funcao para mostrar na tela a lista de arquivos importados 
function ListaArquivos ({ files, onDelete, cssClasses, onClickBotaoExcluir })  {
  const { div_class, p_class, li_class, li_p_class } = cssClasses;

  return (
    <div className={div_class}>
      {files.map((uploadedFile,i) => (
        <div key={i} className={p_class}> Arquivo Selecionado
        <li className={li_class} key={uploadedFile.id}>
              <p className={li_p_class}>{uploadedFile.name}</p>
          <div>
            {!uploadedFile.uploaded &&
              !uploadedFile.error && (
                <CircularProgressbar
                  styles={{
                    root: { width: 24 },
                    path: { stroke: "#36D896" }
                  }}
                  strokeWidth={10}
                  percentage={uploadedFile.progress}
                />
              )}
            {uploadedFile.uploaded && <MdCheckCircle size={24} color="#36D896"/>}
            {uploadedFile.error && <MdError size={24} color="#e57878"/>}
            
          </div>

          <div onClick = {() => {if(onClickBotaoExcluir) onClickBotaoExcluir()}} className="botaoLixeira">
                {uploadedFile.readableSize}{" "}
                {!uploadedFile.id && (
                  <button className="excluir" onClick={(e) => onDelete(e)}>
                    <div className="lixeira" id={i}/> 
                  </button>
                )}
              </div>
        </li>
        </div>
      ))}
    </div>
)}

