import React, { useState, useRef } from 'react';/*eslint-disable*/
import { api } from '../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { elipseVerde, fecharJanela, setaDropdown } from '../../../utils/alternanciaIcones'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao';
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
import SelectDisponibilidade from '../../componentesModais/selects/selectDisponibilidade';
import { useDispatch, useSelector } from 'react-redux';
import { revelarRelatorioDisponibilidade } from "../../../redux/actions/index";
import decryptJWT from '../../../utils/decryptJWT';

export default function RelatorioDisponibilidade(props) {
    const valorLimite = 1000;

    const [revelarSelectRange, setRevelarSelectRange] = useState(false);
    const [inputRevelado, setInputRevelado] = useState('Selecione');
    const [dsRange, setDsRange] = useState();
    let reduxModoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    const [spinAtivo, setSpinAtivo] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false);
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false);
    const [mensagemErro, setMensagemErro] = useState(`A quantidade de simcards deve ser entre: 1 e ${valorLimite}. Selecione novamente sem exceder o limite.`);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [contractId, setContractId] = useState(decryptJWT('codigoClienteErpAntigo'));
    const modal = useRef();
    const despacho = useDispatch();

    async function handleSalvar() {
        setSpinAtivo(true)
        const tipo_arquivo = 'XLSX';
        let bodyRelatorio = {};
        const rangeSelecionado = getRange();
        const simcardsSelecionados = props.simcardsSelecionados;
        const filtros = props.filtros;
        const buscaArquivo = props.buscaArquivo;
        const buscar = props.conteudoBusca;
        const separador = props.separadorLinha;

        if (reduxModoSelecaoSimcard) {
            bodyRelatorio = {
                descricao: 'Relatório de Disponibilidade buscando pelo parque inteiro',
                tipo_arquivo,
                tipo_relatorio: 27,
                rangeSelecionado,
                filtros,
                buscaArquivo,
                buscar,
                separador,
                contar: false,
                contractId
            }
        } else {
            let auxArrayCdSimcard = []
            simcardsSelecionados.map((simcard) => {
                if (simcard.CD_SIMCARD !== undefined && simcard.CD_SIMCARD !== null && simcard.CD_SIMCARD !== '') {
                    auxArrayCdSimcard.push(simcard.CD_SIMCARD)
                } else {
                    auxArrayCdSimcard.push(simcard.FAT_CD_SIMCARD)
                }
            })
            bodyRelatorio = {
                descricao: 'Relatório de Disponibilidade buscando por linhas específicas',
                tipo_arquivo,
                tipo_relatorio: 27,
                rangeSelecionado,
                arrayCdSimcard: auxArrayCdSimcard,
                filtros,
                buscaArquivo,
                buscar,
                separador,
                contar: false,
                contractId
            }
        }

        if (simcardsSelecionados.length <= valorLimite) {
            api.post('/m1/cadastrar/relatorios', bodyRelatorio);
            setSpinAtivo(false);
            setRevelarMensagemInformacao(true);
        } else {
            handleMensagemErro(false);
        }
    }

    function handleCancelar() {
        despacho(revelarRelatorioDisponibilidade(false));
        setRevelarMensagemErro(false);
    }


    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true);
        } else {
            setRevelarMensagemErro(false)
            if (confirmacao === true) {
                handleCancelar();
            }
        }
    }

    function handleMensagemInformacao(confirmacao) {
        if (revelarMensagemInformacao === false) {
            setRevelarMensagemInformacao(true)
        } else {
            setRevelarMensagemInformacao(false)
            handleCancelar();
        }
    }


    function handleRange(range) {
        setDsRange(range)
    }

    function getRange() {
        let rangeSelecionado = dsRange;
        return rangeSelecionado;
    }

    return (
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
            <div className="modal-editar-fatura fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse" />
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Relatório de Disponibilidade</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela" />
                        </Button>
                    </Col>
                </Form.Row>
                <div className="grupo-cadastro-editar-fatura-1">
                    <div className="coluna-cadastro-editar-fatura-1">
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Filtro</div>
                            </Form.Label>
                            {revelarSelectRange === false &&
                                <Button className="campo-select-cancelamento campo-texto-cor-3 campo-select"
                                    onClick={() => setRevelarSelectRange(true)}
                                    ref={register({ required: true })} onChange={e => handleRange(e.target.value)}>

                                    <div className="fonte-cor-1 label-campo">
                                        {inputRevelado}
                                    </div>

                                    <img className={revelarRelatorioDisponibilidade ?
                                        "campo-select-icone-ativado nao-selecionavel" :
                                        "campo-select-icone-desativado nao-selecionavel"}
                                        src={setaDropdown()} alt="drop" />

                                </Button>
                            }
                            {revelarSelectRange &&
                                <SelectDisponibilidade
                                    setRevelarSelectRange={setRevelarSelectRange}
                                    setInputRevelado={setInputRevelado}
                                    handleRange={handleRange}
                                    inputRevelado={inputRevelado}
                                    valorLimite={valorLimite}
                                >
                                </SelectDisponibilidade>
                            }
                        </Form.Group>
                    </div>
                </div>
                <Form.Row>
                    <Col>
                            {dsRange != 'Selecione' ?
                                <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                                :
                                <Button className="botao-confirmar-desabilitado" variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            }
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            {revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Todas as linhas foram retiradas"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={mensagemAlerta}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemInformacao &&
                <div id="mensagem-relatorio" className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"O arquivo está sendo gerado"}
                            mensagemParagrafo1={"Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível"}
                            mensagemBotao={"Voltar"}
                            marginTop={80}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}