//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import {Image, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import decryptJWT from '../../../utils/decryptJWT'
import { formatarMilhar } from '../../../utils/formatarMilhar';
//COMPONENTES
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import { formatarMilharReais } from '../../../utils/formatarMilharReais'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { revelarRelatorioConciliacao, revelarDoppelgangerCliente, valorCdFatura, visualizarDadosRefresh, mudarOrdenador, mudarManejador, limpouBusca, limpadorFiltros, realizouBusca, revelarBarraFiltragem, realizouFiltro, mudarFiltros, desabilitarAcoes} from "../../../redux/actions/index";
// SPINNER
import {setaDropdown} from '../../../utils/alternanciaIcones';
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelFaturas/fatConciliacao/tabela.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let cd_fat_conciliacao;

function TabelaConciliacao(props){
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    //HOOKS VARIÁVEIS
    const [cdOperadora, setCdOperadora] = useState()
    const [dsApelido, setDsApelido] = useState()
    const [dsConta, setDsConta] = useState()
    const [valorPrevisto, setValorPrevisto] = useState()
    const [valorDiferenca, setValorDiferenca] = useState()
    const [valorCobrado, setValorCobrado] = useState()
    const [inicioCiclo, setInicioCiclo] = useState()
    const [fimCiclo, setFimCiclo] = useState()
    const [mesReferencia, setMesReferencia] = useState()
    const [cdFaturaCabecalho, setCdFaturaCabecalho] = useState()
    const [cdFatConciliacao, setCdFatConciliacao] = useState()
    const [atualizarConciliacao, setAtualizarConciliacao] = useState()
    const [n2ValorTotal, setN2ValorTotal] = useState(0)
    //HOOKS MENSAGENS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)

    const cd_pav_usuario = decryptJWT('codigo')

    
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let valorCdFaturaState = useSelector(state => state.faturasState.valorCdFatura);
    let revelarAtualizarConciliacao = useSelector(state => state.faturasState.revelarAtualizarConciliacao)
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let temFiltros = false;
    let visualizarDadosRefreshVar = useSelector(state => state.virtueyesState.visualizarDadosRefresh);
    let controladorRelatorioConciliacao = useSelector(state => state.faturasState.revelarRelatorioConciliacao);
    let itensVar = []

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(()=>{
        if(revelarAtualizarConciliacao){
            setRevelarMensagemAtencao(true)
        }
    },[revelarAtualizarConciliacao])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("faturasConciliacao"))
        despacho(desabilitarAcoes(true))

        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const queryString = 'paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+'&cd_fat_conciliacao='+cd_fat_conciliacao
                    const { data } = await api.get('/m1/consultar/fat_conciliacao_recurso?'+queryString);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    cd_fat_conciliacao: cd_fat_conciliacao
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/fat_conciliacao_recurso", dados);
                    inserirData(data.dados)
                }
            }
            else { 
                let cdFatura = valorCdFaturaState
                if(localStorage.getItem("cdFatura") == "false" || localStorage.getItem("cdFatura") == "null" || localStorage.getItem("cdFatura") == null || !localStorage.getItem("cdFatura")) {
                    localStorage.setItem("cdFatura", cdFatura)
                }
                if(!cdFatura){
                    cdFatura = localStorage.getItem("cdFatura")
                    despacho(valorCdFatura(cdFatura))
                }
                await api.get("/m1/consultar/fat_conciliacao?cd_fat_fatura=" + cdFatura)
                .then(async function (resposta) {
                    if(resposta.data.dados.length > 0){
                        setCdFaturaCabecalho(resposta.data.dados[0].CD_FATURA)
                        setValorPrevisto(resposta.data.dados[0].VALOR_CALCULADO)
                        let auxDiferenca = resposta.data.dados[0].VALOR_CALCULADO - resposta.data.dados[0].VALOR_IMPORTADO
                        setValorDiferenca(auxDiferenca)
                        setValorCobrado(resposta.data.dados[0].VALOR_IMPORTADO)
                        setN2ValorTotal(resposta.data.dados[0].VALOR_TOTAL)
                        setDsConta(resposta.data.dados[0].DS_CONTA)
                        setInicioCiclo(resposta.data.dados[0].INICIO_CICLO)
                        setFimCiclo(resposta.data.dados[0].FIM_CICLO)
                        setMesReferencia(resposta.data.dados[0].MES_REFERENCIA)
                        cd_fat_conciliacao = resposta.data.dados[0].CD_CONCILIACAO;
                    
                        if(totalPaginas == 0){
                            await contarItens();
                        }else{
                            contador = (paginaAtual - 1) * valorLimite;
                        }
                        if (controladorPagina <= paginaAtual) {
                            const queryString = 'paginaAtual='+contador+'&carregarLimit='+valorLimite+'&cd_fat_conciliacao='+cd_fat_conciliacao
                            const { data } = await api.get('/m1/consultar/fat_conciliacao_recurso?'+queryString);
                            inserirData(data.dados)
                        }
                    }
                    if(resposta.data.dados.length == 0){
                        await api.post("/m1/cadastrar/fat_conciliacao", {
                            'cd_fat_fatura': cdFatura,
                            'cd_pav_usuario': decryptJWT("codigo")
                        })
                        .then(()=>{
                            api.post('/m1/cadastrar/fat_faturas_historico', {
                                'cd_fat_fatura':cdFatura,
                                'cd_fat_fatura_historico_tipo':6,
                                'n2_valor_cobrado':n2ValorTotal,
                                'cd_pav_usuario_created_at':cd_pav_usuario
                            })
                            setSpinAtivo(false)
                            handleMensagemSucesso(false)
                            setRenderizar(!renderizar)
                        })
                    }
                }) 
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros, atualizarConciliacao])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/fat_conciliacao_recurso?contar=true&buscar='+conteudoBusca+'&cd_fat_conciliacao='+cd_fat_conciliacao);
            }else{
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar: conteudoBusca,
                    filtros: filtros,
                    contar: true,
                    cd_fat_conciliacao: cd_fat_conciliacao
                }
                apiData = await api.post("/m1/filtrar/fat_conciliacao_recurso", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    const colunas = [{
        dataField: 'LINHA',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_linha",'ds_linha')}>
        Linha
        <Image id="ds_linhads_linha" className='d-none' src={setaDropdown()}/>
        </button>,
        headerClasses: 'nao-selecionavel',
        headerStyle: (colum, colIndex) => {
            return { width: '130px'}
        },
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'VALOR_CALCULADO',
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_valor_previsto",'ds_valor_previsto')}>
        Valor previsto
        <Image id="ds_valor_previstods_valor_previsto" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">R$ {cell}</p>;
        }
    },
    {
        dataField: 'VALOR_IMPORTADO',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_valor_cobrado",'ds_valor_cobrado')}>
        Valor cobrado
        <Image id="ds_valor_cobradods_valor_cobrado" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">R$ {cell}</p>;
        }
    },
    {
        dataField: 'VALOR_DIFERENCA',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_valor_diferenca",'ds_valor_diferenca')}>
        Valor diferença
        <Image id="ds_valor_diferencads_valor_diferenca" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            if(cell>0){
                return <p className="fonte-cor-2 label-tabela-1">R$ {formatarMilhar(cell)}</p>;
            }else if(cell<0){
                return <p className="fonte-cor-3 label-tabela-1">R$ {formatarMilhar(cell)}</p>;
            }else{
                return <p className="fonte-cor-1 label-tabela-1">R$ {formatarMilhar(cell)}</p>;
            }
        }
    },
    {
        dataField: 'TIPO_DIFERENCA',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_tipo",'ds_tipo')}>
        Tipo
        <Image id="ds_tipods_tipo" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'MOTIVO',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_motivo",'ds_motivo')}>
        Motivo
        <Image id="ds_motivods_motivo" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'CD_ERRO',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_erro",'ds_erro')}>
        Erro
        <Image id="ds_errods_erro" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'DS_ERRO',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_tipo_erro",'ds_tipo_erro')}>
        Tipo erro
        <Image id="ds_tipo_errods_tipo_erro" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'CD_SOLICITACAO',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_solicitacao",'ds_solicitacao')}>
        Solicitação
        <Image id="ds_solicitacaods_solicitacao" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'DS_SOLICITACAO',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_solicitacao_descricao",'ds_solicitacao_descricao')}>
        Solicitação descrição
        <Image id="ds_solicitacao_descricaods_solicitacao_descricao" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'DT_SOLICITACAO_FIM',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_solicitacao_fim",'ds_solicitacao_fim')}>
        Solicitação fim
        <Image id="ds_solicitacao_fimds_solicitacao_fim" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'VALOR_DE',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_alteracao_de",'ds_alteracao_de')}>
        Alteração de:
        <Image id="ds_alteracao_deds_alteracao_de" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'VALOR_PARA',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_alteracao_para",'ds_alteracao_para')}>
        Alteração para:
        <Image id="ds_alteracao_parads_alteracao_para" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'DS_PROTOCOLO',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_protocolo",'ds_protocolo')}>
        Protocolo
        <Image id="ds_protocolods_protocolo" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    }]

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));        
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            despacho(revelarDoppelgangerCliente(false))
            despacho(visualizarDadosRefresh(!visualizarDadosRefreshVar))
            if(confirmacao === true){
            }
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                setRevelarMensagemAtencao(false)
                handleAtualizarConciliacao()
                setAtualizarConciliacao(true)
            }
        }
    }

    async function handleAtualizarConciliacao(){
        await api.post("/m1/cadastrar/fat_conciliacao", {
            'cd_fat_fatura': valorCdFaturaState,
            'cd_pav_usuario': decryptJWT("codigo")
        })
        .then(async function (response) {
            api.post('/m1/cadastrar/fat_faturas_historico', {
                'cd_fat_fatura':cdFaturaCabecalho,
                'cd_fat_fatura_historico_tipo':6,
                'n2_valor_cobrado':n2ValorTotal,
                'cd_pav_usuario_created_at':cd_pav_usuario
            })
            setRevelarMensagemAtencao(false)
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setRevelarMensagemAtencao(false)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        })
    }

    useEffect(()=>{
        async function buscarDadosRelatorioFaturas(){
            if(controladorRelatorioConciliacao === "XLS" || controladorRelatorioConciliacao ===  "XLSX" || controladorRelatorioConciliacao ===  "CSV"){
                const dados = {
                    codigo_cliente: decryptJWT('codigoCliente'),
                    codigo_usuario: decryptJWT('codigo'),
                    descricao: 'Relatório conciliação',
                    tipo_arquivo: controladorRelatorioConciliacao,
                    tipo_relatorio: 10,
                    buscar: conteudoBusca,
                    cd_fat_conciliacao: cd_fat_conciliacao
                }
                api.post('/m1/cadastrar/relatorios',dados)
                handleMensagemInformacao(true)
                despacho(revelarRelatorioConciliacao(false))
            }else{
                despacho(revelarRelatorioConciliacao(false))
            }
        }
        buscarDadosRelatorioFaturas()
    }, [controladorRelatorioConciliacao])
    
    return(

        <div className='container-tela'>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <BarraDadosCliente/>
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            { revelarMensagemAtencao &&
                <MensagemAtencao 
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Tem certeza?"}
                    mensagemParagrafo1={"Esta ação irá"}
                    mensagemParagrafo2={"atualizar os dados desta"}
                    mensagemParagrafo3={"conciliação"}
                    mensagemBotao={"Confirmar"} 
                />
            }
            {/* CABECALHO */}
            <div className="barra-dados-cabecalho-conciliacao fundo-cor-5">
                <div className="divisora-vertical-barra-dados"/>
                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{valorCdFaturaState}</Tooltip>}>
                    <div className="barra-dados-caixa">
                        <span className="fonte-cor-1">Fatura: {cdFaturaCabecalho}</span>
                    </div>
                </OverlayTrigger>
                <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{dsConta}</Tooltip>}>
                    <div className="barra-dados-caixa">
                            <span className="fonte-cor-1">Conta: {dsConta}</span>
                    </div>
                </OverlayTrigger>
                <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{mesReferencia}</Tooltip>}>
                    <div className="barra-dados-caixa">
                        <span className="fonte-cor-1">Referência: {mesReferencia}</span>
                    </div>
                </OverlayTrigger> 
                <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{inicioCiclo}</Tooltip>}>
                    <div className="barra-dados-caixa">
                        <span className="fonte-cor-1">Início: {inicioCiclo}</span>
                    </div>
                </OverlayTrigger>
                <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{fimCiclo}</Tooltip>}>
                    <div className="barra-dados-caixa">
                        <span className="fonte-cor-1">Fim: {fimCiclo}</span>
                    </div>
                </OverlayTrigger>
                <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={
                <Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{valorPrevisto === undefined ? '0.00' : formatarMilharReais(valorPrevisto)}</Tooltip>}>
                    <div className="barra-dados-caixa">
                        <span className="fonte-cor-1">Valor previsto: R$ {valorPrevisto === undefined ? '0.00' : formatarMilharReais(valorPrevisto)}</span>
                    </div>
                </OverlayTrigger>
                <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={
                <Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{valorCobrado === undefined ? '0.00' : formatarMilharReais(valorCobrado)}</Tooltip>}>
                    <div className="barra-dados-caixa">
                        <span className="fonte-cor-1">Valor cobrado: R$ {valorCobrado === undefined ? '0.00' : formatarMilharReais(valorCobrado)}</span>
                    </div>
                </OverlayTrigger>
                <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{valorDiferenca === undefined ? '0.00' : formatarMilharReais(valorDiferenca)}</Tooltip>}>
                    <div className="barra-dados-caixa">
                        <span className="fonte-cor-1">Valor diferença: R$ {valorDiferenca === undefined ? '0.00' : formatarMilharReais(valorDiferenca)}</span>
                    </div>
                </OverlayTrigger>
                <div className="divisora-vertical-barra-dados"/>
            </div>
            {/* CABECALHO */}
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='ds_linha' 
                                data={itens}
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                // selectRow={ selecaoLinhas }
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"O arquivo está sendo gerado"}
                            mensagemParagrafo1={"Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível"}
                            mensagemBotao={"Voltar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"Conciliação atualizada."}
                    mensagemBotao={"Continuar"}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        conteudoBusca: state.manejadorState.conteudoBusca,
        filtros: state.filtrosState.filtros,
        visualizarDadosRefreshVar: state.virtueyesState.visualizarDadosRefresh
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaConciliacao));