import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Form } from "react-bootstrap";

import { api } from "../../conexoes/api";
import { exclamacao, lixeiraVermelho } from "../../utils/alternanciaIcones";

export function RenderInputRows({
    itens,
    itensArray,
    setItensArray,
    setMensagemErro,
    setRevelarMensagemErro,
    mostrarBotaoNovoLote,
}){
    const localTema = localStorage.getItem('tema')
    const temaEscuro = (localTema === 'escuro');

    const [tipoSIMcardOptions, setTipoSIMcardOptions] = useState([]);

    const [operadoraOptions, setOperadoraOptions] = useState([])
    const [todosPlanos, setTodosPlanos] = useState([])

    function getPlanos(cd_dsp_operadora) {
        let planoOptions = []

        if(!cd_dsp_operadora) return planoOptions;

        const planosOperadora = todosPlanos.filter(p => p.cd_dsp_operadora === cd_dsp_operadora)
        planoOptions = parseOptionsObject(planosOperadora, 'plano')
        return planoOptions
    }

    useEffect(() => {
        async function fetchModalData(){
            const operadoraData = await api.get('/m1/consultar/operadora');
            if(operadoraData.data.dados.length){

                setOperadoraOptions(parseOptionsObject(operadoraData.data.dados, 'operadora'))
            }

            const planoData = await api.get('/m1/consultar/planos');
            if(planoData.data.dados.length){
                setTodosPlanos(planoData.data.dados)
                
            }
            const tipoSIMcardData = await api.get('/m1/consultar/simcard_tipo_operadora');
            if(tipoSIMcardData.data.dados.length){
                setTipoSIMcardOptions(extrairItensUnicos(tipoSIMcardData.data.dados));
            }
        }
        fetchModalData()
    }, []);

    function parseOptionsObject(dados, tipo) {
        // eslint-disable-next-line default-case
        switch (tipo) {
            case 'operadora':
                dados = dados.filter(item => item.ds_apelido).map(item => {
                    return { id: item.cd_dsp_operadora, label: item.ds_apelido }
                })
                break;

            case 'plano':
                dados = dados.filter(item => item.ds_dsp_plano).map(item => {
                    return { id: item.cd_dsp_plano, label: item.ds_dsp_plano }
                })
                break;
        }
        return dados;
    }

    function extrairItensUnicos(array) {
        let arrayTipos = [...new Set(['Normal', ...array.map(item => item.ds_simcard_tipo)])];
        return arrayTipos;
    }

    function handleOperadoraInput(value, index) {
        let newArray = [...itensArray]
        newArray[index].cd_dsp_operadora = value.id;
        newArray[index].OPERADORA = value.label;
        setItensArray(newArray);

        handlePlanoInput({}, index) // limpa Plano quando altera Operadora
    }

    function handlePlanoInput(value, index) {
        let newArray = [...itensArray]
        newArray[index].cd_dsp_plano = value.id;
        newArray[index].PLANO = value.label;
        setItensArray(newArray);
    }

    function handleTipoSimcardInput(value, item, index) {
        let newArray = [...itensArray]
        newArray[index].TAMANHO = value;
        setItensArray(newArray);
    }

    function handleQuantidadeSimcards(value, item, index) {
        let newArray = [...itensArray]
        newArray[index].NUMERO_SIMCARDS = value;
        setItensArray(newArray);
    }

    function deleteInputRow(item, index) {
        let newArray = [...itensArray]
        newArray = newArray.filter((lote, i) => i !== index);
        setItensArray([...newArray]);
    }

    function updateItensArray() {
        const ultimoLote = itensArray[itensArray.length - 1];
        const newCdPedido = ultimoLote.cd_pedido + 1;

        if(!ultimoLote.OPERADORA || !ultimoLote.PLANO || !ultimoLote.TAMANHO || !ultimoLote.NUMERO_SIMCARDS ) {
            setMensagemErro('Preencha os detalhes do lote anterior');
            setRevelarMensagemErro(true);
            return;
        } else if (ultimoLote.NUMERO_SIMCARDS <= 0) {
            setMensagemErro('A quantidade de simcards deve ser maior que 0');
            setRevelarMensagemErro(true);
            return;
        }

        const newItem = {
            'cd_pedido': newCdPedido,
            'OPERADORA': '',
            "cd_dsp_operadora": '',
            'PLANO': '',
            "cd_dsp_plano": '',
            'TAMANHO': '',
            'NUMERO_SIMCARDS': ''
        };

        setItensArray([...itensArray, newItem]);
    }

    return (
        <>
            <div className={'input-row-novo-pedido-wrapper'}>
                {itens.map((item, index) => {
                    const operadoraSelecionada = item.OPERADORA;

                    return (
                    <div className={'input-row-novo-pedido'} key={index}>
                        <div className={'fonte-cor-1'} style={{flex: 0.1}}>{(index +1)}.</div>
                        <div style={{flex: 1.5}} className={'input-place'}>
                            <MyAutocomplete
                                temaEscuro={temaEscuro}
                                value={operadoraSelecionada || ''} 
                                options={operadoraOptions}
                                disableClearable={true}
                                onChange={(event, value) => handleOperadoraInput(value, index)}
                                renderInputParams={{
                                    placeholder: 'Selecione a operadora'
                                }}
                            />
                            {!operadoraSelecionada && <AvisoCampoObrigatorio />}
                        </div>
                        <div style={{flex: 1.5}} className={'input-place'}>
                            <MyAutocomplete
                                temaEscuro={temaEscuro}
                                disabled={!operadoraSelecionada}
                                value={item.PLANO || ''}
                                options={getPlanos(item.cd_dsp_operadora)}
                                disableClearable={true}
                                onChange={(event, value) => handlePlanoInput(value, index)}
                                renderInputParams={{
                                    placeholder: 'Selecione o plano'
                                }}
                            />
                            {operadoraSelecionada && !item.PLANO && <AvisoCampoObrigatorio />}
                        </div>
                        <div style={{flex: 1.3}} className={'input-place'}>
                            <MyAutocomplete
                                temaEscuro={temaEscuro}
                                disabled={!operadoraSelecionada}
                                value={item.TAMANHO  || ''}
                                disableClearable={true}
                                options={tipoSIMcardOptions}
                                onChange={(event, value) => handleTipoSimcardInput(value, item, index)}
                                renderInputParams={{
                                    placeholder: 'Selecione o tamanho'
                                }}
                            />
                            {operadoraSelecionada && !item.TAMANHO && <AvisoCampoObrigatorio />}
                        </div>
                        <div style={{flex: 1.2}} className={'input-place'}>
                            <MyAutocomplete
                                temaEscuro={temaEscuro}
                                disabled={!operadoraSelecionada}
                                value={item.NUMERO_SIMCARDS || ''}
                                options={[]}
                                noOptionsText={'Digite a quantidade'}
                                freeSolo={true}
                                onInputChange={(event, value) => handleQuantidadeSimcards(value, item, index)}
                                renderInputParams={{
                                    type: 'number',
                                    placeholder: 'Digite a quantidade'
                                }}
                            />
                            {operadoraSelecionada && !item.NUMERO_SIMCARDS && <AvisoCampoObrigatorio />}
                        </div>
                        {itens.length > 1 &&
                            <div style={{flex: 0.1, marginRight: '16px'}}>
                                <img onClick={event => deleteInputRow(item, index)} className={'input-row-delete'} height={24} src={lixeiraVermelho()} alt="excluir" />
                            </div>
                        }

                    </div>
                )})}
            </div>
            <div className={'input-row-novo-pedido-newlote'}>
                <div className={'fonte-cor-1 new-lote-row'}>{(itens.length + 1)}.</div>
                {mostrarBotaoNovoLote &&
                    <Form.Row>
                        <div className={'button-novo-lote-wrapper'}>
                            <div className={localTema == 'claro' ? 'button-novo-lote':'button-novo-lote-dark'} onClick={() => updateItensArray()}>
                                <div>NOVO LOTE</div>
                            </div>
                        </div>

                    </Form.Row>
                }
            </div>


        </>
    )
}


function MyAutocomplete({ renderInputParams, temaEscuro, ...props }) {

    const autocomplete = {
        id: temaEscuro ? 'combo-box-demo-noturno' : 'combo-box-demo',
        className: temaEscuro ? 'select-tipo-cliente-noturno' : 'select-tipo-cliente',
        sx: {
            borderColor: '#33CC99',
            height: '47px !important',
            fontSize: '20px !important'
        }
    }

    return (
        <Autocomplete
            disablePortal
            id={autocomplete.id}
            className={autocomplete.className}
            {...props}
            sx={{width: '100%', height: 55}}
            renderInput={(params) => <TextField {...{...params, ...renderInputParams}} sx={autocomplete.sx} />}
        />
    )
}

export const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

export function AvisoCampoObrigatorio () {
    return <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>
}