//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';
import { useForm } from "react-hook-form";
import { Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {elipseVerde, fecharJanela, exclamacao, setaDropdown} from '../../../utils/alternanciaIcones'
//MODAIS
import SelectModulo from '../../componentesModais/selects/selectModulo'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelAdministrativo/pavTela/pavTela.css'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarNovoTela, revelarEditarTela } from "../../../redux/actions/index";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function CadastroTela(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [cdTela, setCdTela] = useState(0);
    const [cdModulo, setCdModulo] = useState(-1);
    const [dsModulo, setDsModulo] = useState('Selecione');
    const [dsRota, setDsRota] = useState('');
    const [dsNome, setDsNome] = useState('');
    const [dsDescricao, setDsDescricao] = useState('');
    //HOOKS PLACEHOLDERS
    const [phModulo, setPhModulo] = useState('');
    const [phRota, setPhRota] = useState('');
    const [phNome, setPhNome] = useState('');
    const [phDescricao, setPhDescricao] = useState('');
    const [phMensagemSucesso, setPhMensagemSucesso] = useState('cadastrada')
    //HOOKS MODAIS
    const [revelarSelectModulo, setRevelarSelectModulo] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)/*eslint-disable*/
    //OUTROS HOOKS
    const [tituloJanela, setTituloJanela] = useState('Cadastro');
    const [caracteresMensagem, setCaracteresMensagem] = useState(500);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const modal = useRef();
    const despacho = useDispatch();

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };
    
    useEffect(() => {
        setSpinAtivo(true)
        if (props.tituloJanela === 'Editar'){
            //TROCA O TITULO DA JANELA
            setTituloJanela('Editar')
            //CARREGA OS CAMPOS COM OS VALORES ORIGINAIS
            setCdTela(props.cdTela);
            setCdModulo(props.cdModulo);
            setDsModulo(props.dsModulo);
            setDsRota(props.dsRota);
            setDsNome(props.dsNome);
            setDsDescricao(props.dsDescricao);
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhModulo('Original: '+props.dsModulo);
            setPhRota('Original: /'+props.dsRota);
            setPhNome('Original: '+props.dsNome);
            setPhDescricao('Original: '+props.dsDescricao);
            setPhMensagemSucesso('editada')
        }
        if (props.tituloJanela === 'Cadastrar'){
            setTituloJanela('Cadastrar')
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhModulo('Selecione');
            setPhRota('/rota');
            setPhNome('Nome');
            setPhDescricao('Descrição');
            setPhMensagemSucesso('cadastrada')
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    //FAZ O MANEJO 
    function handleSalvar(){
        if (tituloJanela === 'Cadastrar'){
            ColetaClickstream(1365) // pavTelaTabelaGridDropAcoesNovoTelaCadastrar
            handleCadastrar()
        }else{
            ColetaClickstream(1366) // pavTelaTabelaGridDropAcoesNovoTelaEditar
            handleAlterar()
        }
    }

    async function handleAlterar(){
        setSpinAtivo(true)
        await api.post('/m1/alterar/tela', {
            "dados":{
                'cd_pav_modulo' : cdModulo,
                'ds_rota' : dsRota.trim(),
                'ds_pav_tela' : dsNome.trim(),
                'ds_descricao' : dsDescricao.trim()
                },
            "condicao":{
                'cd_pav_tela' : cdTela,	
               }
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    async function handleCadastrar(){
        setSpinAtivo(true)
        await api.post('/m1/cadastrar/tela', {
            'cd_pav_modulo' : cdModulo,
            'ds_rota' : dsRota.trim(),
            'ds_pav_tela' : dsNome.trim(),
            'ds_descricao' : dsDescricao.trim()
        },)
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    function handleCancelar(){
        ColetaClickstream(1364) // pavTelaTabelaGridDropAcoesNovoTelaCancelar
        despacho(revelarNovoTela(false))
        despacho(revelarEditarTela(false))
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
        props.setRenderizar(!props.renderizar)
        props.setRevelarCadastro(false)
        props.setRevelarEdicao(false)
    }
    

    function handleDescricao(valor){
        setDsDescricao(valor)
        setCaracteresMensagem(500 - valor.length)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <div className="modal-cadastro-tela fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{tituloJanela} tela</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-tela-1">
                            <div className="coluna-cadastro-tela-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Módulo</div>
                                    </Form.Label>
                                    { revelarSelectModulo === false &&
                                        <Button name="cdModulo" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectModulo(!revelarSelectModulo)}>
                                            <div className="fonte-cor-1 label-campo">{dsModulo}</div>
                                            <img className={revelarSelectModulo?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectModulo === true &&
                                        <SelectModulo
                                            setRevelarSelectModulo={setRevelarSelectModulo} //Hook para fechar a modal
                                            setCdModulo={setCdModulo} //Código do Modulo
                                            setDsModulo={setDsModulo} //Nome do Modulo
                                            dsModulo={dsModulo} 
                                            phModulo={phModulo} //Placeholder para mostrar o valor original em caso de edição
                                        >
                                        </SelectModulo>
                                    }
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-tela-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Rota</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Rota</Tooltip>}>
                                        <Form.Control name="dsRota" value={dsRota}  className="campo-texto-cor-3 campo-texto" type="text" maxlength="25" placeholder={phRota} onChange={e => setDsRota(e.target.value)} 
                                        ref={register({validate: {minimo: value => value.length > 1}})}/>
                                    </OverlayTrigger>
                                    {errors.dsRota && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Nome</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Nome</Tooltip>}>
                                <Form.Control name="dsNome" value={dsNome}  className="campo-texto-cor-3 campo-texto" type="text" placeholder={phNome} onChange={e => setDsNome(e.target.value)} 
                                ref={register({validate: {minimo: value => value.length > 1}})} maxlength="100"/>
                            </OverlayTrigger>
                            {errors.dsNome && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Descrição</div>
                            </Form.Label>
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Descrição</Tooltip>}>
                                <Form.Control className="campo-texto-cor-3 campo-texto-area" as="textarea" rows="3" maxlength="500" placeholder={phDescricao} defaultValue={dsDescricao} onChange={e => handleDescricao(e.target.value)}></Form.Control>
                            </OverlayTrigger>
                            <Form.Label className="contador-carateres-restantes">
                                <div className="fonte-cor-1 label-normal">{caracteresMensagem} caracteres restantes</div>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">{tituloJanela}</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Tela "+dsNome+" "+phMensagemSucesso+"."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}