/*eslint-disable*/
import React, { useState } from "react"; /*eslint-disable*/
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import decryptJWT from "./utils/decryptJWT";

import { useDarkMode } from "./utils/alterarModoEscuroClaro";
import { claroTema, escuroTema } from "./css/temas";
import { ThemeProvider } from "styled-components";

function Index() {
  let id = window.location.host.includes("localhost")
    ? "70154f6b-cff8-44ca-9c4a-aa3ba78f3c3f"
    : "b5b85e9a-1c9f-4f5a-ae83-ef96915891f6";

  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;

  OneSignal.push(() => {
    OneSignal.init(
      {
        appId: id,
      },
      OneSignal.sendTag("email", decryptJWT("login"), (tagsSent) => {
        // Callback called when tag has finished sending
      })
    );
  });

  const [tema, alterarModoEscuro, componentMounted] = useDarkMode();
  const [render, setRender] = useState(false);
  let modoTema;

  if (tema === "claro") {
    modoTema = claroTema;
  } else if (tema === "escuro") {
    modoTema = escuroTema;
  } else {
    modoTema = claroTema;
  }

  return (
    <>
      {componentMounted && (
        <Provider store={store}>
          <ThemeProvider theme={modoTema}>
            {/* <EstilosGlobais/> */}
            <App
              tema={tema}
              alterarModoEscuro={alterarModoEscuro}
              setRenderSistema={setRender}
              renderSistema={render}
            />
          </ThemeProvider>
        </Provider>
      )}
    </>
  );
}

ReactDOM.render(<Index />, document.getElementById("root"));
