//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import { Image, Form } from 'react-bootstrap';
import { validadorPermissaoElemento } from '../../../../utils/verificadorPermissaoElemento'
import { useSelector } from 'react-redux';
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye' 
//ICONES
import { iconeConsumoAtivado, iconeConsumo } from '../../../../utils/alternanciaIcones'
//ESTILIZACAO
import '../../../../css/painelDispositivos/dspDetalhamentoLinha/detalhamentoLinha.css'
import './detalhamento.css'
//COMPONENTES
import Historico from './historico/historico'
import GraficoConsumoCiclos from './graficoConsumoCiclos/graficoConsumoCiclos'
import IconeHistorico from '../../../../utils/alternanciaIcones2';

const textoHistorico = 'Histórico';
const textoUltimos6Ciclos = 'Últimos 6 ciclos';

export default function DetalhamentoLinha(props) {
    const Linha = props.item.Linha;
    const idMenuHistorico = '1';
    const idMenuConsumo = '2';

    const [idMenu, setIdMenu] = useState('-1')
    const [revelarTela, setRevelarTela] = useState()
    const [permissaoUltimo6Ciclo, setPermissaoUltimo6Ciclo] = useState()
    const permissaoHistorico = true;

    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);

    const [mostrarBotaoDownload, setMostrarBotaoDownload] = useState([]) // botão download "relatório""

    useEffect(() => {
        async function handlerVerificarPermissaoElementos() {
            setPermissaoUltimo6Ciclo(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento/graficoConsumoUltimo6Ciclos"))
        }

        handlerVerificarPermissaoElementos()
    }, [])

    function handleRenderTela(tela) { 
        if (tela === textoUltimos6Ciclos) {
            setRevelarTela(textoUltimos6Ciclos)
        }
        else if(tela === textoHistorico){ 
            setRevelarTela(textoHistorico) 
        }
        else {
            setRevelarTela('NULL')
        }
    }

    function abreGraficoConsumo() {
        handleDropTelas(idMenuConsumo);
        handleRenderTela(textoUltimos6Ciclos);
    }

    function abreHistorico() {
        handleDropTelas(idMenuHistorico);
        handleRenderTela(textoHistorico);
    }

    function handleDropTelas(elementoId) {
        if(!mostrarBotaoDownload) {
            setMostrarBotaoDownload(true);
        }

        const auxIdMenu = elementoId + Linha;

        if (idMenu === auxIdMenu) {
            setIdMenu(auxIdMenu)
        } else {
            setIdMenu(auxIdMenu)
        }
    }

    return (
        <Form>
            <div className="container-detalhamento-linha fundo-cor-1">
                {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
                <Form.Row>
                    <div className="container-navegacao-detalhamento-linha">
                        <div className="triangulo-navegacao-detalhamento-linha campo-select-triangulo-cor-3"></div>
                        <div className="corpo-navegacao-detalhamento-linha fundo-cor-5">
                            <ul className="lista-navegacao-detalhamento-linha">
                                {permissaoUltimo6Ciclo && 
                                    <li key={idMenuConsumo}>
                                        <ItemDetalhamento
                                            label='Consumo'
                                            ativado={idMenu === (idMenuConsumo + Linha)}
                                            onClick={abreGraficoConsumo}
                                            iconeAtivado={iconeConsumoAtivado}
                                            iconeDesativado={iconeConsumo}
                                        />
                                    </li>
                                }
                                 {permissaoHistorico && 
                                    <li key={idMenuHistorico}>
                                        <ItemDetalhamentoHistorico
                                            label={'Histórico'}
                                            ativado={idMenu === (idMenuHistorico + Linha)}
                                            onClick={abreHistorico}
                                        />
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>

                    
                    { revelarTela === textoHistorico && 
                        <ContainerSelecionado className='detalhamento-migracao-plano-tabela-historico'>
                            <Historico cdSimcard={props.item.cd_simcard} />
                        </ContainerSelecionado>
                    }

                    {revelarTela === textoUltimos6Ciclos &&
                        <ContainerSelecionado
                            titulo='Consumo dos últimos 6 ciclos'
                        >
                            <GraficoConsumoCiclos
                                CdSimcard={props.item.cd_simcard}
                                clientedono={props.item.CodigoCliente}
                            />
                        </ContainerSelecionado>
                    }

                </Form.Row>
            </div >
        </Form >
    )
}

function ContainerSelecionado({titulo, children, className}) {
    return (
            <div className="migracaoPlano-container-detalhamento-linha">
                <div className={`migracaoPlano-corpo-detalhamento ${className || ''}`}>
                    <div className='fonte-cor-16 titulo-grafico-ultimos-6-ciclos'>
                        {titulo}
                    </div>
                    {children}
                </div>
            </div>
    )
}


const ItemDetalhamentoHistorico = ({ label, ativado, onClick }) => (
    <>
    {ativado ? 
        <div className="container-item-navegacao-detalhamento-linha-ativado detalhamento-hover fundo-cor-7" onClick={onClick}>
            <ContainerTituloItemHistorico ativado={ativado}>
                {label}
            </ContainerTituloItemHistorico>
        </div>
        :
        <div className="container-item-navegacao-detalhamento-linha-desativado detalhamento-hover" onClick={onClick}>
            <ContainerTituloItemHistorico ativado={ativado}>
                {label}
            </ContainerTituloItemHistorico>
        </div>
    }
    </>
)

const ContainerTituloItemHistorico = ({children, ativado }) => (
    <>
        <DivIcone>
            <IconeHistorico ativo={ativado} className='nao-selecionavel icone-item-navegacao-detalhamento-linha'/>
        </DivIcone>
        <Titulo>{children}</Titulo>
    </>
)

const ItemDetalhamento = ({ label, ativado, onClick, iconeAtivado, iconeDesativado }) => (
    <>
    {ativado ? 
        <div className="container-item-navegacao-detalhamento-linha-ativado detalhamento-hover fundo-cor-7" onClick={onClick}>
            <ContainerTituloItem src={iconeAtivado()}>
                {label}
            </ContainerTituloItem>
        </div>
        :
        <div className="container-item-navegacao-detalhamento-linha-desativado detalhamento-hover" onClick={onClick}>
            <ContainerTituloItem src={iconeDesativado()}>
                {label}
            </ContainerTituloItem>
        </div>
    }
    </>
)

const ContainerTituloItem = ({children, src}) => (
    <>
        <DivIcone>
            <IconeItemDetalhamento src={src} />
        </DivIcone>
        <Titulo>{children}</Titulo>
    </>
)

const DivIcone = ({children}) => (
    <div className="coluna-item-navegacao-detalhamento-linha-1">{children}</div>
)

const IconeItemDetalhamento = ({src}) => (
    <Image className="nao-selecionavel icone-item-navegacao-detalhamento-linha" src={src} />
)

const Titulo = ({children}) => (
    <div className="coluna-item-navegacao-detalhamento-linha-2">
        <span className="label-item-navegacao-detalhamento-linha nao-selecionavel fonte-campo fonte-cor-1">
            {children}
        </span>
    </div>
)