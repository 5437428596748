//IMPORTAÇÕES
import React, { useState, useRef, useEffect } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form,  Col } from 'react-bootstrap';
import decryptJWT from '../../../utils/decryptJWT'
//SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ICONES
import { setaDropdown, elipseVerde, fecharJanela } from '../../../utils/alternanciaIcones';
//IMPORTAÇÕES REDUX
import { useDispatch } from 'react-redux';
import { revelarNovoPedidoSMS } from "../../../redux/actions/index";
import {formatarMilhar} from '../../../utils/formatarMilhar'
//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao';
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso';
import MensagemErro from '../../componentesModais/mensagens/mensagemErro';
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
// SELECT
import SelectPacote from '../../componentesModais/selects/SelectPacote';
import '../../../css/painelPedidos/pedidosSms/novopedido.css'
import {exportarCdSms} from "../../../redux/actions/index"

let atualizarParent = false;
let novoCdSmsContaUsuarioInserido

export default function NovoPedido(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false);
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false);
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false);
    const [revelarMensagemErroRequest, setRevelarMensagemErroRequest] = useState(false);
    const [mensagemErroRequest, setMensagemErroRequest] = useState(false)
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef(); 

    const cdContaSms = decryptJWT('cdSmsContaUsuario')
    const cdUsuario = decryptJWT('codigo')

    // HOKS DA TELA
    const [quantidadePacote, setQuantidadePacote] = useState('Selecione o pacote');
    const [valorPacote, setValorPacote] = useState('');
    const [cdPacote, setCdPacote] = useState();
    
    const [revelarSelectPedido, setRevelarSelectPedido] = useState(false);
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar(false)
        }
    };
    const handleClickDentro = () => setClickFora(false);

    async function handleConfirmar(){
        ColetaClickstream(1220) /*dropAcoesRevelarNovoPedidoSMSConfirmar*/
        if(quantidadePacote === "Selecione o pacote"){
            setRevelarMensagemErro(true)
        }else{
            handlerSalvar()
        }
    }

    async function handleCancelar() {
        ColetaClickstream(1219) /*dropAcoesRevelarNovoPedidoSMSCancelar*/
        if(atualizarParent === true){
            props.setRenderizar(!props.renderizar)
        } 
        despacho(revelarNovoPedidoSMS(false))
    }

    async function handlerSalvar(){
        setSpinAtivo(true)
        if(cdContaSms === undefined || cdContaSms === 'undefined'){

            await api.post('/m1/cadastrar/smsContaUsuario/criarContaUsuario',{
                "cd_pav_usuario": cdUsuario
            })
            .then(function(resposta){
                const { cd_sms_conta_usuario, cd_sms_conta_usuario_inserido } = resposta.data.dados[0];
                const existeCdSmsContaUsuario = (cd_sms_conta_usuario == undefined || cd_sms_conta_usuario == 'undefined');

                if(existeCdSmsContaUsuario){
                    novoCdSmsContaUsuarioInserido = cd_sms_conta_usuario_inserido
                }else{
                    novoCdSmsContaUsuarioInserido = cd_sms_conta_usuario
                }
                const cadastrado = api.post('/m1/cadastrar/sms_pedido',{
                    'codigoClienteErpAntigo':decryptJWT('codigoClienteErpAntigo'),
                    'nomeUsuario': decryptJWT('nomeDeUsuario'),
                    "email":decryptJWT('login'),
                    "cd_sms_conta_usuario":novoCdSmsContaUsuarioInserido,
                    "cd_pav_usuario": cdUsuario,
                    "cd_sms_pacote":cdPacote,
                    "quantidade":quantidadePacote,
                    'valor':valorPacote,
                })
                if(cadastrado) {
                    if(existeCdSmsContaUsuario ){
                        despacho(exportarCdSms(cd_sms_conta_usuario_inserido))

                    }else{
                        despacho(exportarCdSms(cd_sms_conta_usuario))
                    }
                    atualizarParent = true;
                    setRevelarMensagemSucesso(true)
                    setSpinAtivo(false)
                } else {
                    setMensagemErroRequest('Erro SQL')
                    setRevelarMensagemErroRequest(true)
                    setSpinAtivo(false)
                }
            })
            .catch(function(error){
                setSpinAtivo(false)
            })
        }else{
            await api.post('/m1/cadastrar/sms_pedido',{
                'codigoClienteErpAntigo':decryptJWT('codigoClienteErpAntigo'),
                'nomeUsuario': decryptJWT('nomeDeUsuario'),
                "email":decryptJWT('login'),
                "cd_sms_conta_usuario":cdContaSms,
                "cd_pav_usuario": cdUsuario,
                "cd_sms_pacote":cdPacote,
                "quantidade":quantidadePacote,
                'valor':valorPacote,
            }).then(function(){
                atualizarParent = true;
                setRevelarMensagemSucesso(true)
                setSpinAtivo(false)
            }).catch(function(error){
                setMensagemErroRequest(error.response.data.info)
                setRevelarMensagemErroRequest(true)
                setSpinAtivo(false)
            })
        }
    }

    useEffect(()=>{
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    },[])
   
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
            
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao){

        }
    }

    function handleMensagemErroRequest(confirmacao){
        if(revelarMensagemErroRequest === false){
            setRevelarMensagemErroRequest(true)
        }
        else{
            setRevelarMensagemErroRequest(false)
        }
        if(confirmacao){
            
        }
    }

  
    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleConfirmar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="modal-novo-pedido-sms fundo-cor-6">
                <Form.Row>
                    <Col>
                        <div className="container-cabecalho-tela">
                            <img className="icone-label-janela nao-selecionavel" src ={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela"> Novo Pedido</div>
                            </Form.Label>
                            <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                                <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                            </Button>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-novo-pedido-sms">
                            <div className='coluna-novo-pedido-sms-1'>
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Pacote</div>
                                    </Form.Label>
                                    {!revelarSelectPedido &&
                                        <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectPedido(!revelarSelectPedido)}>
                                            <div className="fonte-cor-1 label-campo">{quantidadePacote} SMS</div>
                                            <img className={revelarSelectPedido?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    {revelarSelectPedido &&
                                        <SelectPacote
                                            setRevelarSelectPedido={setRevelarSelectPedido}
                                            setQuantidadePacote={setQuantidadePacote}
                                            setCdPacote={setCdPacote}
                                            quantidadePacote={quantidadePacote}
                                            setValorPacote={setValorPacote}
                                        >
                                        </SelectPacote>
                                    }
                                </Form.Group>
                                
                            </div>
                            <div className="coluna-novo-pedido-sms-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Valor</div>
                                    </Form.Label>
                                    <div className="campo-texto-cor-3 campo-texto-valor-pedido">
                                        <div className="fonte-cor-1 div-valor-pedido"><span className="novo-pedido-sms-reais">R$   </span>{valorPacote ? formatarMilhar(valorPacote) : ''}</div>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso!"}
                            mensagemParagrafo1={"Sua solicitação foi enviada!"}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            mensagemTitulo={"Remover"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Nenhum pacote selecionado."}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErroRequest &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErroRequest}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErroRequest}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}