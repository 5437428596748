const ESTADO_INICIAL = {
    revelarEnviarSmsModulo: false,
    revelarVerConversasSms: false,
    revelarAtualizarSms: false,
    revelarNovoTemplateSms: false,
    revelarEditarTemplateSms: false,
    revelarExcluirTemplateSms: false,
    revelarRelatorioEnvioSms: false,
}

export const smsReducer = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'REVELAR_ENVIAR_SMS_MODULO') {
        return {
            ...state,
            revelarEnviarSmsModulo : action.parametro
        };
    }else if (action.type === 'REVELAR_VER_CONVERSAS_SMS'){
        return {
            ...state,
            revelarVerConversasSms : action.parametro
        }
    }else if (action.type === 'REVELAR_ATUALIZAR_SMS'){
        return {
            ...state,
            revelarAtualizarSms : action.parametro
        }
    }else if (action.type === 'REVELAR_NOVO_TEMPLATE_SMS'){
        return {
            ...state,
            revelarNovoTemplateSms : action.parametro
        }
    }else if (action.type === 'REVELAR_EDITAR_TEMPLATE_SMS'){
        return {
            ...state,
            revelarEditarTemplateSms : action.parametro
        }
    }else if (action.type === 'REVELAR_EXCLUIR_TEMPLATE_SMS'){
        return {
            ...state,
            revelarExcluirTemplateSms : action.parametro
        }
    }else if (action.type === 'REVELAR_RELATORIO_ENVIO_SMS'){
        return {
            ...state,
            revelarRelatorioEnvioSms : action.parametro
        }
    }else{
        return state
    }
}