// DspSimcard - (lista de "id"s do) objeto colunasSimcardInicial em src/utils/colunas.js
export const objCategoriasKeys = {
    Simcard: [
        "CALLERID", "ICCID", "OPERADORA", "IMSI", "TIPO_SIMCARD", "FAT_CONTA", 
        "FAT_CICLOVIDA", "FAT_PLANO", "FAT_PEDIDO", "FAT_NOTA_FISCAL"
    ],
    Conectividade: [
        "ON_OFF", "ULTIMO_EVENTO", "CONECTOU", "DESCONECTOU", "TEMPO_DURACAO", "IP", "TECNOLOGIA", "TRAFEGO", "FAT_N2_VALOR_PLANO",
        "LAT_LONG_OPERADORA"
    ],
    Utilizacao: [
        "PLANO", "CONSUMO_PERCENTUAL", "SALDO_MB", "PLANO_TIPO", "DIAS_USADOS", "CONSUMO_MB",
        "NUMCONEXOES", "NUMPACOTES", "CONSUMO_DIA_MB", "CONEXOES_DIA", "PACOTES_DIA",
    ],
    Contrato: [ 
        "SITUACAO",  "VALOR_PLANO", "DATA_COMPRA", "DATA_ATIVACAO", "DATA_PRAZO_CONTRATO", "SMS_ATIVADO", "SERVICO_ADICIONAL"
    ],
    Dispositivo: [
        "IMEI_VERIFICADOR", "IA_MODELO_IMEI", "IA_MARCA_IMEI",
    ],
    Identificacao: [
        "IA_MODELO_DISPOSITIVO", "IA_MARCA_DISPOSITIVO", "NOME_GRUPO", "SITUACAO_GRUPO", "IA_NOME", "IA_ENDERECO",
        "IA_CIDADE", "IA_UF", "IA_CEP", "IA_OBSERVACOES", "IAC_DESCRICAO_1", "IAC_DESCRICAO_2", "IAC_DESCRICAO_3",
    ],
    Cliente: [
        "CLIENTE_CODIGO", "CLIENTE_NOME",
    ]
}
