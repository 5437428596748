import React, {useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button} from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';/*eslint-disable*/

import { useDispatch, useSelector, connect } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem, filtros, maximoTagsExibidas} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

function Filtros(props) {
    const[id,setId] = useState('')
    const[nome,setNome] = useState('')
    const[descricao,setDescricao] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []


    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setNome(filtros.ds_pav_perfil)
            setId(filtros.cd_pav_perfil)
            setDescricao(filtros.ds_descricao)
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1353) /*pavPerfilTabelaGridDropFiltrosFiltrar*/
        if(id !== '' && id)arrayFiltrosSimcard.push("Id: "+id+"?cd_pav_perfil")
        if(nome !== '' && nome)arrayFiltrosSimcard.push("Nome: "+nome+"?ds_pav_perfil")
        if(descricao !== '' && descricao)arrayFiltrosSimcard.push("Descrição: "+descricao+"?ds_descricao")

        despacho(maximoTagsExibidas(props.setRevelarDropFiltros))
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(nome == '') verificador.push(1)
            if(descricao =='') verificador.push(1)
            if(id =='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 3) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setDescricao('')
            setNome('')
            setId('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [nome,descricao,id,props.foiLimpado]);

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-id-perfil"){
                setId(valor)
            }  
        }
    }

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-id","imagem-filtro-id"); ColetaClickstream(1350) /*pavPerfilTabelaGridDropFiltrosIDdoPerfil*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">ID do perfil </Form.Label>
                        <img id={"imagem-filtro-id"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-id"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Control
                            name="cd_pav_perfil"
                            className="campo-texto-cor-3 campo-texto-filtro"
                            type="text"
                            maxLength="45"
                            value={id}
                            placeholder="ID do perfil"
                            onChange={(e) => validarNumero("campo-texto-id-perfil", e.target.value)}
                        />
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>

                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-nome","imagem-filtro-nome"); ColetaClickstream(1351) /*pavPerfilTabelaGridDropFiltrosNomeDoPerfil*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Nome do Perfil </Form.Label>
                        <img id={"imagem-filtro-nome"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-nome"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Control
                            name="ds_pav_perfil"
                            className="campo-texto-cor-3 campo-texto-filtro"
                            type="text"
                            maxLength="45"
                            value={nome}
                            placeholder="Nome do perfil"
                            onChange={(e) => setNome(e.target.value)}
                        />
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>

                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-descricao","imagem-filtro-descricao"); ColetaClickstream(1352) /*pavPerfilTabelaGridDropFiltrosDescricaoDoPerfil*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Descrição do Perfil </Form.Label>
                        <img id={"imagem-filtro-descricao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-descricao"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <Form.Control
                            name="ds_descricao"
                            className="campo-texto-cor-3 campo-texto-filtro"
                            type="text"
                            maxLength="45"
                            value={descricao}
                            placeholder="Descrição do perfil"
                            onChange={(e) => setDescricao(e.target.value)}
                        />
                    </div>
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
  );
}

export default Filtros;
