//BIBLIOTECAS
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import { Form, Col, Button, ButtonToolbar } from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import {api} from '../../../../conexoes/api'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import {formatarMilhar} from '../../../../utils/formatarMilhar'
//ICONES
import { elipseVerde, editarVerde } from "../../../../utils/alternanciaIcones";
//MENSAGENS
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch,useSelector } from 'react-redux';
import { revelarAlterarAdicionarLogin } from "../../../../redux/actions/index";
import '../../../../css/painelFaturas/fatPedidos/tabela.css'

let valorLimite = 10
let itensVar = []
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 10
let contador = 0


export default function InformacoesLogin(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS
    const [itens, setItens] = useState([])
    const [renderizar, setRenderizar] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro. Por favor tente novamente mais tarde.")
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [refazerChamadas, setRefazerChamadas] = useState(true);

    const despacho = useDispatch();

    // useEffect(() => {
    //     setSpinAtivo(true)
    //     async function handleCarregarDetalhamento(){
    //         const {data} = await api.get("m1/consultar/fat_pedido/simcards_pedido?cd_fat_pedido="+props.cd_fat_pedido);
    //         
    //         if(data.dados != undefined){
    //             setItens([data.dados])
    //         }
    //     }
    //     handleCarregarDetalhamento();
    
        
    //     setSpinAtivo(false)
    // }, [])
    
    useEffect(()=>{
        setSpinAtivo(true)
        contador = (paginaAtual - 1) * valorLimite;
        async function carregarHistorico(){
            if(refazerChamadas === true){
                const contagem = await api.get("m1/consultar/fat_pedido/simcards_pedido?cd_fat_pedido="+props.cd_fat_pedido+"&paginaAtual="+contador+"&carregarLimit="+valorLimite+"&contar=true");
                
                totalPaginas = Math.ceil(contagem.data.dados[0].CONTADOR / valorLimite)
                totalItens = contagem.data.dados[0].CONTADOR;
                
                setRefazerChamadas(false)
            }
            const linhas = await api.get("m1/consultar/fat_pedido/simcards_pedido?cd_fat_pedido="+props.cd_fat_pedido+"&paginaAtual="+contador+"&carregarLimit="+valorLimite+"&contar=false");
            
            inserirData(linhas.data.dados)
        }
        carregarHistorico()
    },[paginaAtual])

  
    function inserirData(data){
        
        
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            
            if(i == (totalItens-1)){
                
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)
    }
    
    const colunas = [{
        dataField: 'DS_LINHA',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{cell}</p>;
        }
    },
    {
        dataField: 'DS_ICCID',
        text: 'ICCID',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{cell}</p>;
        }
    },
    {
        dataField: 'DS_PLANO',
        text: 'Plano',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{cell}</p>;
        }
    },
    {
        dataField: 'DS_VALOR_PLANO',
        text: 'Valor',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1 ">{'R$ ' + formatarMilhar(cell)}</p>;
        }
    }]

    function handleAbrirEdicaoUsuario(){
        despacho(revelarAlterarAdicionarLogin(true))
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            // setNome(row.ds_pav_usuario)
            // setSobrenome(row.ds_pav_usuario_sobrenome)
            // setEmail(row.ds_email)
            // setTelefone(row.ds_telefone)
            // setCdPerfil(row.cd_pav_perfil)
            // setDsPerfil(row.ds_pav_perfil)
            // setCdUsuario(row.cd_pav_usuario)
            // setCpf(row.ds_cpf)
            // setCnpj(row.ds_cnpj)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    
    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    return(
        <div className="container-informacoes-login">
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div>}
            <div className="triangulo-navegacao-detalhamento-linha-fatPedido campo-select-triangulo-cor-2"></div>
            <div className="container-informacoes-login-telas">
                <div className="container-detalhamento-fatPedido fundo-cor-7">
                    <Form.Row>
                        <Col>
                            <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela">Linhas do pedido</div>
                            </Form.Label>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <div className="container-tabela-informacoes-login">
                                            <BootstrapTable
                                                classes='tabela-transparente-header-fixo'
                                                hover={true}
                                                condensed={true}
                                                data={itens}
                                                keyField='DS_LINHA'
                                                columns={colunas}
                                                bootstrap4={true}
                                                bordered={false} 
                                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                { ...paginationTableProps }
                                            />
                                            <PaginacaoTabela
                                                extrato={true}
                                                setPaginaAtual={setPaginaAtual}
                                                totalPaginas={totalPaginas}
                                                totalItens={totalItens}
                                                valorLimite={valorLimite}
                                                paginaAtual={paginaAtual}
                                                page={paginationProps.page}
                                                onPageChange={paginationProps.onPageChange}
                                            />
                                        </div>
                                    )
                                }
                            </PaginationProvider>
                            <text className="itens-exibidos-tabela-extrato fonte-cor-1">
                                Mostrando 
                                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                                    - 
                                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                                    de 
                                <span className="label-tabela-itens-exibidos">{totalItens}</span>
                            </text>
                        </Col>
                    </Form.Row>
                </div>
            </div>
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </div>
    )
}