//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import XLSX  from 'xlsx'
import Dropzone from "react-dropzone";
//ICONES
import {elipseVerde, fecharJanela, lixeiraVermelho, setaDropdown, importarArquivo, adicionarMaisVerdeSemEllipse} from '../../../../utils/alternanciaIcones';

//REDUX
import { useDispatch } from 'react-redux';
import { revelarSolicitacaBloquearVirtueyes } from "../../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'
//CSS
import '../../../../css/painelVirtueyes/solicitacaoBloqueio/bloquearCliente/bloquearCliente.css'
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'
import decryptJWT from '../../../../utils/decryptJWT'

let atualizarParent = false;

export default function AtivarLinhas(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [caracteresMensagem, setCaracteresMensagem] = useState(1000);
    const [renderizar, setRenderizar] = useState(false);
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1);
    const [enviarEmail, setEnviarEmail] = useState(false);
    const [linhaRemover, setLinhaRemover] = useState('');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [itens, setItens] = useState([])
    const [validacaoCliente, setValidacaoCliente] = useState(false)
    const [cliente, setCliente] = useState('')
    const [arquivo, setArquivo] = useState([])
    //HOOKS MODAIS
    const [revelarValidacao, setRevelarValidacao] = useState(false);
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();

    const codigoCliente = decryptJWT('codigoCliente')

    let itensTemporarios = []

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    const colunas = [{
        dataField: 'n0_codigo_cliente',
        text: 'Cód. cliente',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },{
        dataField: 'cd_pav_cliente',
        hidden: true,
        text: 'Cód. cliente',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'ds_pav_cliente',
        text: 'Razão social',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'cd_pav_cliente',
        text: '',
        headerClasses: 'centralizar tabela-coluna-ultima-header nao-selecionavel',
        classes: 'botoes-barra-acoes',
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleRemoverLinha(cell)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]

    async function handlerConsultarArquivo(){
        if(arquivo.length > 0){
            setSpinAtivo(true)
            Promise.all(
                await arquivo.map(async (item_arquivo,i)=>{
                    setSpinAtivo(true)
                    if(item_arquivo != ''){
                        await api.get('/m1/consultar/cliente?painel_virtueyes=true&flBloqueio=true&n0_codigo_cliente='+item_arquivo)
                        .then(function (response) {
                            if(response.status == 200 && response.data.dados[0]){
                                itensTemporarios.push(response.data.dados[0])
                        }
                        })
                        .catch(function (){
                            setMensagemErro("Erro na consulta de clientes, por favor verifique se o arquivo contem apenas numeros do cliente")
                            handleMensagemErro(false)
                        })
                    }
                    setSpinAtivo(false)
                })
            )
                .then(() => {
                    setItens(itensTemporarios)   
                });
                setSpinAtivo(false)
        }
    }
    useEffect(()=>{
        handlerConsultarArquivo()
    },[arquivo])

    
    //Funcao para ler os dados do arquivo
    function handleLerArquivo(files){
        files.forEach((file) => {
            const reader = new FileReader()
            
            reader.onload = (evt) => {
                const data = evt.target.result;
                //le os dados
                const readedData = XLSX.read(data, {type: 'array'});
                //transforma em um array de json
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                const dados = XLSX.utils.sheet_to_json(ws, {header:1});
                //verifica se o esta mandando sao numeros e um arquivo certos 
                if(!isNaN(parseInt(dados[0]))){
                    setArquivo(dados)
                }
            }
            reader.readAsArrayBuffer(file)
        })
    };
    
    
    async function handlerConsultarCliente(cdCliente){
        setSpinAtivo(true)
        await api.get(cdCliente === undefined ? '/m1/consultar/cliente?painel_virtueyes=true&flBloqueio=true&n0_codigo_cliente='+cliente : '/m1/consultar/cliente?painel_virtueyes=true&flBloqueio=true&n0_codigo_cliente='+cdCliente)
        .then(function (response) {
            if(response.status == 200 && response.data.dados[0]){
                itens.push(response.data.dados[0])
                //apenas ira rendeziar se for uma consulta pelo input 
                setCliente('')    
                setValidacaoCliente(false)
                setRevelarValidacao(false)
            }
            else{
                setValidacaoCliente(true)
            }
        })
        .catch(function (error) {
            setValidacaoCliente(true)
        });
        setRenderizar(!renderizar)
        setSpinAtivo(false)
    }

    function handleSalvar(){
        if(itens.length > 0){
            ColetaClickstream(1378) // 'dropAcoesSolicitacaBloquearVirtueyesConfirmar'
            bloquear()
        }
        else{
           setRevelarValidacao(true)
        }
    }
    
    async function bloquear(){
        setSpinAtivo(true)
        let clientesArray = []
        Promise.all(
            itens.map((item_cliente,i)=>{
                clientesArray.push(item_cliente.cd_pav_cliente)
            })
        ).then(async ()=>{
            //FL_ACAO 1 E DE BLOQUEIOs
            await api.post('/m1/cadastrar/cliente_bloqueio ', {
                'cd_pav_cliente' : clientesArray,
                'cd_pav_usuario' :  decryptJWT('codigo'),
                "fl_acao":'1'
            })
            .then(async function (response) {
                if(enviarEmail){
                    async function funcao(item_itens) {
                        const resposta = await api.post('/m1/servico/enviar_email', {
                            ds_email: item_itens.ds_email,
                            tipoEmail:  "aviso_bloqueio_cliente",
                            ds_nome:  item_itens.ds_nome,
                        })
                        return resposta;
                    }
                    const getFuncao = async () => {
                        return await Promise.all(itens.map(item_itens => funcao(item_itens)));
                    }
                    getFuncao().then(function(dados){
                        setSpinAtivo(false)
                        atualizarParent = true
                        handleMensagemSucesso(false) 
                    })
                }
                else{
                    setSpinAtivo(false)
                    atualizarParent = true
                    handleMensagemSucesso(false)
                }

            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro no cadastro de bloqueio")
                handleMensagemErro(false)
            });
        })
    }

    function botaoPressionado(event) {
        if(event.key === "Enter"){
            event.preventDefault()
            handlerConsultarCliente()
        }
      }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.CD_SIMCARD);
        }
    }
    
    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itens.length)
    }
    
    function handleRemoverLinha(cd_pav_cliente){
        setRevelarMensagemAtencao(false)
        itens.forEach(function(e, index){
            if(cd_pav_cliente == e.cd_pav_cliente){
                itens.splice(index, 1);
            }
        })
        setItens(itens)
        handleLinhasSelecionadas()
    }


    async function handleCancelar() {
        ColetaClickstream(1377) // 'dropAcoesSolicitacaBloquearVirtueyesCancelar'
        itensTemporarios = []
        setSpinAtivo(false)
        if(atualizarParent === true){
            props.setRenderizar(!props.renderizar)
        } 
        despacho(revelarSolicitacaBloquearVirtueyes(false))
    }           


    useEffect(() => {    
        handleLinhasSelecionadas()

        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(confirmacao ===true){
            handleRemoverLinha(linhaRemover)
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }
    
    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-codigo"){
                setCliente(valor)
            }  
        }
    }

    return(
        <Form ref={modal}>
            <div className="modal-bloquear-cliente fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Bloquear cliente</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="nova-solicitacao-select-operadora">       
                            <Form.Label><div className="fonte-cor-1 label-campo">Cód. cliente</div></Form.Label>
                            <div className="bloqueio-input-adicionar-cliente">
                                <Form.Control 
                                    className="campo-texto-cor-3 campo-texto"
                                    placeholder="Digite o código" 
                                    value={cliente} 
                                    onKeyDown={e => botaoPressionado(e)}
                                    onChange={(e) => validarNumero("campo-texto-codigo", e.target.value)}
                                />
                                {cliente.length > 0 &&
                                    <Button className="btn-img-bloqueio-input-adicionar-cliente" onClick={() => handlerConsultarCliente()}>
                                        <img className="img-bloqueio-input-adicionar-cliente" src={adicionarMaisVerdeSemEllipse()} />
                                    </Button>
                                }
                            </div>
                            {validacaoCliente &&
                                <p className='bloqueio-input-adicionar-cliente-alerta label-normal fonte-cor-3'>
                                    Cliente invalido
                                </p>
                            }
                            {revelarValidacao &&
                                <span className="span-validacao-buscar-simcards-solicitacoes">Selecione um cliente</span>
                            }
                        </div>
                     </Col>
                     <Col>
                        <div className="novo-bloqueio-importar-cliente">
                            <Dropzone onDrop={acceptedFiles => handleLerArquivo(acceptedFiles)}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Button className="btn-importar-cliente">
                                            <div className="fonte-cor-4">Importar</div>
                                            <img className="img-importar-cliente" src={importarArquivo()} />
                                        </Button>
                                    </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                     </Col>
                </Form.Row>
                {itens.length > 0 &&
                    <>
                        <Form.Row>
                            <Col>
                                <div className="container-tabela-modal-bloqueio">
                                    <BootstrapTable
                                        classes="tabela"
                                        condensed={true}
                                        keyField='cd_pav_cliente' 
                                        data={itens} 
                                        columns={colunas} 
                                        rowEvents={eventosLinhas}
                                        bootstrap4={true}
                                    />
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} cliente(s) selecionado(s)</span>
                            </Col>
                        </Form.Row>
                    </>
                }
                <div className="btn-switch-enviar-aviso">
                    <div class="flipswitch">
                        <input type="checkbox" name="flipswitch" class="flipswitch-cb" id="switchCompartilhado" onChange={() => setEnviarEmail(!enviarEmail)}/>
                        <label class="flipswitch-label" for="switchCompartilhado">
                            <div class="flipswitch-inner flipswitch-cor-1"></div>
                            <div class="flipswitch-switch"></div>
                        </label>
                    </div>
                    <div className="fonte-cor-1 label-campo">Enviar aviso</div>
                </div>
                <Form.Row className="espaco-ativar-linha">
                    <Col>
                        <Button  className="botao-confirmar" variant="success" size="sm" onClick={() => handleSalvar()}>
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
