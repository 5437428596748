//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, fecharJanela, exclamacao, setaDropdown} from '../../../utils/alternanciaIcones'
//MODAIS
import SelectTipoPlanoFaturas from '../../componentesModais/selects/selectTipoPlanoFaturas'
import SelectUnidadePlanoFaturas from '../../componentesModais/selects/selectUnidadePlanoFaturas'
import SelectCategoriaPlanoFaturas from '../../componentesModais/selects/selectCategoriaPlanoFaturas'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelFaturas/fatServico/fatServico.css'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarNovoServico, revelarEditarServico } from "../../../redux/actions/index";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
import decryptJWT from '../../../utils/decryptJWT';

export default function CadastroOperadora(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [cdServico, setCdServico] = useState("")
    const [dsServico, setDsServico] = useState("")
    const [cdCategoria, setCdCategoria] = useState("")
    const [dsCategoria, setDsCategoria] = useState('Selecione')
    const [cdTipo, setCdTipo] = useState("")
    const [dsTipo, setDsTipo] = useState('Selecione')
    const [cdUnidade, setCdUnidade] = useState("")
    const [dsUnidade, setDsUnidade] = useState('Selecione')
    const [n2Valor, setN2Valor] = useState("")
    const [dsQuantidade, setDsQuantidade] = useState()
    //HOOKS PLACEHOLDERS
    const [phServico, setPhServico] = useState()
    const [phCategoria, setPhCategoria] = useState()
    const [phTipo, setPhTipo] = useState()
    const [phUnidade, setPhUnidade] = useState()
    const [phValor, setphValor] = useState()
    const [phQuantidade, setPhQuantidade] = useState()
    const [phMensagemSucesso, setPhMensagemSucesso] = useState()
    const [bloquearAcoes, setBloquearAcoes] = useState(true)
    //HOOKS MODAIS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarSelectTipoPlanoFaturas, setRevelarSelectTipoPlanoFaturas] = useState(false)
    const [revelarSelectUnidadePlanoFaturas, setRevelarSelectUnidadePlanoFaturas] = useState(false)
    const [revelarSelectCategoriaPlanoFaturas, setRevelarSelectCategoriaPlanoFaturas] = useState(false)
    //OUTROS HOOKS
    const [tituloJanela, setTituloJanela] = useState('Cadastro');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const modal = useRef();
    const despacho = useDispatch();
    const cd_pav_usuario = decryptJWT('codigo')
    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };
    
    useEffect(() => {
        setSpinAtivo(true)
        if (props.tituloJanela === 'Editar'){
            //TROCA O TITULO DA JANELA
            setTituloJanela('Editar')
            //CARREGA OS CAMPOS COM OS VALORES ORIGINAIS
            setCdServico(props.cdServico)
            setDsServico(props.dsServico)
            setCdCategoria(props.cdCategoria)
            setDsCategoria(props.dsCategoria)
            setCdTipo(props.cdTipo)
            setDsTipo(props.dsTipo)
            setCdUnidade(props.cdUnidade)
            setDsUnidade(props.dsUnidade)
            setN2Valor(props.n2Valor)
            setDsQuantidade(props.dsQuantidade)
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhServico('Original: '+props.dsServico)
            setPhCategoria('Original: '+props.dsCategoria)
            setPhTipo('Original: '+props.dsTipo)
            setPhUnidade('Original: '+props.dsUnidade)
            setphValor('Original: '+props.n2Valor)
            setPhQuantidade('Original: '+props.dsQuantidade)
            setPhMensagemSucesso('editado')
        }
        if (props.tituloJanela === 'Cadastrar'){
            setTituloJanela('Cadastrar')
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhServico('Digite o nome do serviço')
            setPhCategoria('Selecione')
            setPhTipo('Selecione')
            setPhUnidade('Selecione')
            setphValor('0')
            setPhQuantidade('0')
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function handleSalvar(){
        if (tituloJanela === 'Cadastrar'){
            ColetaClickstream(1290) // 'fatServicoTabelaDropAcoesNovoServicoCadastrar'
            handleCadastrar()
        }else{
            ColetaClickstream(1291) // 'fatServicoTabelaDropAcoesNovoServicoEditar'
            handleAlterar()
        }
    }

    async function handleAlterar(){
        setSpinAtivo(true)
        await api.post('/m1/alterar/fat_servico', {
            "dados":{
                'ds_fat_servico' : dsServico,
                'cd_fat_categoria' : cdCategoria,
                'ds_categoria' : dsCategoria,
                'cd_fat_tipo' : cdTipo,
                'ds_tipo' : dsTipo,
                'cd_fat_unidade' : cdUnidade,
                'ds_unidade' : dsUnidade,
                'n2_valor' : String(n2Valor).replace(',','.'),
                'ds_quantidade' : dsQuantidade,
                "cd_pav_usuario": cd_pav_usuario
            },
            "condicao":{
                'cd_fat_servico' : cdServico,	
            }
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    async function handleCadastrar(){
        setSpinAtivo(true)
        await api.post('/m1/cadastrar/fat_servico', {
            'ds_fat_servico' : dsServico,
            'cd_fat_categoria' : cdCategoria,
            'ds_categoria' : dsCategoria,
            'cd_fat_tipo' : cdTipo,
            'ds_tipo' : dsTipo,
            'cd_fat_unidade' : cdUnidade,
            'ds_unidade' : dsUnidade,
            'n2_valor' : n2Valor.replace(',','.'),
            'ds_quantidade' : dsQuantidade,
            'cd_pav_usuario' : cd_pav_usuario,
            "cd_pav_cliente": decryptJWT('codigoCliente')
        },)
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    function handleCancelar(){
        ColetaClickstream(1289) // 'fatServicoTabelaDropAcoesNovoServicoCancelar'
        despacho(revelarNovoServico(false))
        despacho(revelarEditarServico(false))
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            props.setRenderizar(!props.renderizar)
            handleCancelar()
            if(confirmacao === true){
                handleCancelar()
            }
        }
    }


    useEffect(()=>{
        document.getElementById("ds_label_quantidade").onkeypress = function(e){
            // which é o CD da tecla apertada
            let caracter = String.fromCharCode(e.which);
            if ("1234567890".indexOf(caracter) < 0)
              return false;
          };
          //bloqueia o CRTV  V NO CAMPO DE TEXTO
        document.getElementById('ds_label_quantidade').onpaste = function(){
            return false;
        }
    },[])

    function handleValidarCampoValor(valor){
        const filtro = /^[0-9\,\.\b]+$/;
        if (valor === '' || filtro.test(valor)) {
            let valorFormatado = ((valor).replace(".", ","))
            setN2Valor(valorFormatado)
        }
    }

    useEffect(()=>{
        if(props.tituloJanela === 'Editar'){   
            handlerVerificarEdicao()     
        }
    },[])

    async function handlerVerificarEdicao(){
        await api.get('/m1/consultar/fat_servico_validador?cd_fat_servico='+props.cdServico)
        .then((response)=>{
            if(response.data.status == 200){
                if(response.data.dados){
                    setBloquearAcoes(false)
                }
                if(!response.data.dados){
                    setBloquearAcoes(true)
                }
            }
            else{
                setBloquearAcoes(true)
            }
        })
        .catch((response)=>{
            setBloquearAcoes(false)
        })
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-cadastro-servicos-faturas fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{tituloJanela} serviço</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-servico-faturas-1">
                            <div className="coluna-cadastro-servico-faturas-3">
                                <Form.Group>
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Serviço</div> </Form.Label>
                                    <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="bottom" overlay={<Tooltip className={'tooltip-campos-fatura'} id={`tooltip-bottom`}>Digite o status</Tooltip>}> 
                                        <Form.Control
                                            className="campo-texto-cor-3 campo-texto"
                                            type="text"
                                            value={dsServico}
                                            placeholder="Digite o nome do serviço"
                                            onChange={e => setDsServico(e.target.value)}
                                            name="ds_servicos"
                                            ref={register({ required: true, min: 3})}
                                        />
                                 </OverlayTrigger>
                                {errors.ds_servicos && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-servico-faturas-4">
                                <Form.Group>
                                    <Form.Label>
                                        <div className={!bloquearAcoes?"fonte-cor-6 label-campo":"fonte-cor-1 label-campo"}>Tipo</div>
                                    </Form.Label>
                                    { revelarSelectTipoPlanoFaturas === false && bloquearAcoes &&
                                        <Button name="cdTipoPlanoFaturas" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoPlanoFaturas(!revelarSelectTipoPlanoFaturas)}>
                                            <div className="fonte-cor-1 label-campo">{dsTipo}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { !bloquearAcoes &&
                                        <Button name="cdFornecedorPlanoFaturas" className="campo-texto-cor-3 campo-select" disabled>
                                            <div className="fonte-cor-1 label-campo">{dsTipo}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectTipoPlanoFaturas === true && bloquearAcoes &&
                                        <SelectTipoPlanoFaturas
                                            setRevelarSelectTipoPlanoFaturas={setRevelarSelectTipoPlanoFaturas} //Hook para fechar a modal
                                            setCdTipoPlanoFaturas={setCdTipo} //Código do TipoPlanoFaturas
                                            setDsTipoPlanoFaturas={setDsTipo} //Nome do TipoPlanoFaturas
                                            dsTipoPlanoFaturas={dsTipo}
                                        >
                                        </SelectTipoPlanoFaturas>
                                    }
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-servico-faturas-5">
                                <Form.Group>
                                    <Form.Label>
                                        <div className={!bloquearAcoes?"fonte-cor-6 label-campo":"fonte-cor-1 label-campo"}>Unidade</div>
                                    </Form.Label>
                                    { revelarSelectUnidadePlanoFaturas === false && bloquearAcoes &&
                                        <Button name="cdUnidadePlanoFaturas" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectUnidadePlanoFaturas(!revelarSelectUnidadePlanoFaturas)}>
                                            <div className="fonte-cor-1 label-campo">{dsUnidade}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { !bloquearAcoes &&
                                        <Button name="cdFornecedorPlanoFaturas" className="campo-texto-cor-3 campo-select" disabled>
                                            <div className="fonte-cor-1 label-campo">{dsUnidade}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectUnidadePlanoFaturas === true && bloquearAcoes &&
                                        <SelectUnidadePlanoFaturas
                                            setRevelarSelectUnidadePlanoFaturas={setRevelarSelectUnidadePlanoFaturas} //Hook para fechar a modal
                                            setCdUnidadePlanoFaturas={setCdUnidade} //Código do UnidadePlanoFaturas
                                            setDsUnidadePlanoFaturas={setDsUnidade} //Nome do UnidadePlanoFaturas
                                            dsUnidadePlanoFaturas={dsUnidade}
                                        >
                                        </SelectUnidadePlanoFaturas>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-servico-faturas-1">
                            <div className="coluna-cadastro-servico-faturas-6">
                                <Form.Group>
                                    <Form.Label>
                                        <div className={!bloquearAcoes?"fonte-cor-6 label-campo":"fonte-cor-1 label-campo"}>Categoria</div>
                                    </Form.Label>
                                    { revelarSelectCategoriaPlanoFaturas === false && bloquearAcoes &&
                                        <Button name="cdCategoriaPlanoFaturas" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCategoriaPlanoFaturas(!revelarSelectCategoriaPlanoFaturas)}>
                                            <div className="fonte-cor-1 label-campo">{dsCategoria}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { !bloquearAcoes &&
                                        <Button name="cdFornecedorPlanoFaturas" className="campo-texto-cor-3 campo-select" disabled>
                                            <div className="fonte-cor-1 label-campo">{dsCategoria}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectCategoriaPlanoFaturas === true && bloquearAcoes &&
                                        <SelectCategoriaPlanoFaturas
                                            setRevelarSelectCategoriaPlanoFaturas={setRevelarSelectCategoriaPlanoFaturas} //Hook para fechar a modal
                                            setCdCategoriaPlanoFaturas={setCdCategoria} //Código do CategoriaPlanoFaturas
                                            setDsCategoriaPlanoFaturas={setDsCategoria} //Nome do CategoriaPlanoFaturas
                                            dsCategoriaPlanoFaturas={dsCategoria}
                                        >
                                        </SelectCategoriaPlanoFaturas>
                                    }
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-servico-faturas-7">
                                <Form.Label>
                                    <div className={!bloquearAcoes?"fonte-cor-6 label-campo":"fonte-cor-1 label-campo"}>Quantidade</div>
                                </Form.Label>
                                <input type="range" class="custom-range" disabled={!bloquearAcoes} value={dsQuantidade} defaultValue="0" min="0" max="100"  step="1" id="customRange" onChange={e => setDsQuantidade(e.target.value)}/>
                            </div>
                            <div className="coluna-cadastro-servico-faturas-8">
                                <div className="">
                                    {/* <span className="label-simcard-novo-pedido fonte-cor-1">{dsQuantidade}</span> */}
                                    <Form.Control 
                                        id="ds_label_quantidade" 
                                        name="ds_responsavel" 
                                        value={dsQuantidade} 
                                        className="campo-texto-simcard-novo-pedido campo-texto-cor-3 campo-texto" 
                                        type="text" 
                                        placeholder="0" 
                                        disabled={!bloquearAcoes}
                                        onChange={e => setDsQuantidade(e.target.value)} 
                                    />
                                </div>
                            </div>
                            <div className="coluna-cadastro-servico-faturas-9">
                                <Form.Group>
                                    <Form.Label> <div className={!bloquearAcoes?"fonte-cor-6 label-campo":"fonte-cor-1 label-campo"}>Valor</div> </Form.Label>
                                    <Form.Control
                                        className="campo-texto-cor-3 campo-texto"
                                        value={n2Valor}
                                        placeholder="Digite o valor"
                                        disabled={!bloquearAcoes}
                                        onChange={e => handleValidarCampoValor(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <div className="campo-select-divisor-cor-1 divisoria-cadastro-operadoras-faturas"></div>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {(dsServico != '' && dsTipo != "Selecione" && dsUnidade != "Selecione" && dsCategoria != "Selecione" && dsQuantidade > 0 && n2Valor != "") ?
                            <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                <div className="fonte-cor-4">{tituloJanela}</div>
                            </Button>
                        :
                            <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Preencha o nome, unidade, tipo, categoria, quantidade e valor</Tooltip>}>
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">{tituloJanela}</div>
                                </Button>
                            </OverlayTrigger>
                        }
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Serviço "+dsServico+" cadastrado."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}