import React, { useState } from "react";
import {api, api_s3} from '../../../../conexoes/api';
import decryptJWT from '../../../../utils/decryptJWT'

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'

import ModalImportarClientesUsuarios from "../../../componentesModais/modalImportarArquivo/importarClientesUsuarios.js";
import { exportarXlsx } from "../../../../utils/exportarArquivo.js";

let idInserido = '';

const dadosJSONXLS = [
  [
    'tipo_pessoa_cliente(f/j)', 
    'cnpj', 
    'nome',
    'email_cliente', 
    'telefone_cliente', 
    'nome_usuario', 
    'email_usuario'
  ],
  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]
]

const dadosJSONCSV = [...dadosJSONXLS]

const cssClassesListaArquivosDsp = {
  div_class: "container-importar-descricao-lista-arquivos",
  p_class: "tituloLista-importar-arquivo",
  li_class: "lista-importar-arquivo",
  li_p_class: "listaNome-importar-arquivo",
}

export default function ImportarClientesUsuarios(props) {
  const { telaOrigem } = props;

  const [dadosArquivoErros, setDadosArquivoErros] = useState(false);
  const [spinAtivo, setSpinAtivo] = useState(false);

  async function processUpload(uploadedFile, thisObj) {
    setSpinAtivo(true);
    let arquivos = uploadedFile
    let data = new FormData();
    data.append('file', arquivos.file);
    data.append('cd_usuario', decryptJWT('codigo'));
    data.append('cd_pav_evento_parametro_tipo', 2);

    await api_s3.post("s3/leitura/arquivo", data)
      .then(response => {
          setSpinAtivo(false);
            if (response.status === 200) {
            idInserido = response.data.idInserido
            thisObj.updateFile(uploadedFile.id, {
              uploaded: true,
              id: response.data._id,
              url: response.data.url
            });
            thisObj.setState({
              dadosArquivo: thisObj.state.dadosArquivo.concat(response.data.dados),
            });
          }else{
            setSpinAtivo(false);
          }
      })
      .catch((error) => {
        setSpinAtivo(false);
      });
  }

  async function uploadTabela() {
    // return /*await*/ api.post("m1/alterar/pavImportarClientesUsuarios", { 
    const resData = await api.post("m1/cadastrar/cliente_por_arquivo", {
      telaOrigem,
      idInserido,
      'n0_codigo_cliente':  decryptJWT('codigoClienteErpAntigo'),
    })
    .then(response => response.data);

    return { respostaValidada: tratarResposta(resData) }
  }


  function tratarResposta(responseData) {
    const arrResultado = responseData?.dados;
    let newState;

    if(!responseData)
      return 'Erro no servidor';

    if(responseData.status !== 200 || !arrResultado)
      return 'O arquivo contém erros que impedem a leitura pelo sistema, por favor verifique se seu arquivo está correto e envie novamente.'; 

      const erros = arrResultado.filter(item => item.status !== 'sucesso'); 
      const sucessos = arrResultado.filter(item => item.status === 'sucesso'); 
    
      const qtdErro = erros.length;
      const qtdSucesso = sucessos.length;
  
      if(qtdSucesso && !qtdErro) { // sucesso total
        newState = {
          revelarMensagemSucesso: true
        }
      }
      else {
        let mensagemErro = 'Foram encontrados erros em TODAS as linhas do seu arquivo. Verifique seu arquivo e tente novamente.'

        if(qtdSucesso) { // parcial
          mensagemErro = `Foram encontrados erros em ${qtdErro} linhas do seu arquivo. Todas os demais registros foram cadastrados. Clique em baixar para verificar e corrigir os erros encontrados.`;
        }

        setDadosArquivoErros(arrResultado); // OBS: se não tiver isso, não aparece o botão download

        newState = {
          revelarMensagemErro: true,
          mensagemErro
        }
      }
    
      return newState;
  }

  return (
  <ModalImportarClientesUsuarios
    faturas // ?
    titulo="Importar clientes e usuários"
    setSpinAtivo={setSpinAtivo}
    spinAtivo={spinAtivo}
    dadosJSONXLS={dadosJSONXLS}
    dadosJSONCSV={dadosJSONCSV}
    processUpload={processUpload}
    uploadTabela={uploadTabela}
    // getMensagemErroSalvar={getMensagemErroSalvar}
    temDadosArquivoErros={!!dadosArquivoErros}
    downloadArquivoErros={() => downloadArquivoErros(dadosArquivoErros)}
    cssClassesListaArquivos={cssClassesListaArquivosDsp}
    renderizar={props.renderizar}
    setRenderizar={props.setRenderizar}
    onConfirmarUpload={() => ColetaClickstream(1444) /*FaturasDispositivosDropAcoesImportarDescricao*/}
    // mensagemSucesso={'Solicitação enviada com sucesso'}
  />
  )
}

function downloadArquivoErros(dadosArquivoErros) {
  const cabecalhoArquivoErros = ['cliente', 'mensagem', 'status'];

  const dadosFormatados = dadosArquivoErros.map(item => cabecalhoArquivoErros.map(c => item[c]));

  exportarXlsx([cabecalhoArquivoErros, ...dadosFormatados], 'Erros arquivo Clientes e Usuários.xlsx')
}