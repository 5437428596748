//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {api, api_solicitacao, api_svsolicitacoes} from '../../../../conexoes/api';/*eslint-disable*/
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye'
//COMPONENTES
import {formatarData} from '../../../../utils/formatadorDataTabela'
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import '../../../../css/painelFaturas/fatSolicitacoes/tabela.css'
import { getFormatedData } from '../../../../utils/dateTime';


let contador = 0
let controladorPagina = 1
let totalPaginas = 1
let totalItens = 10

export default function Preview({ cdSolicitacao }) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS TABELA
    let itensVar = []
    const [itens, setItens] = useState([])
    const [valorLimite, setValorLimite] = useState(5);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [refazerChamadas, setRefazerChamadas] = useState(true);

    useEffect(()=>{
        
        setSpinAtivo(true)
        contador = (paginaAtual - 1) * valorLimite;
        async function carregarSolicitacao(){
            if(refazerChamadas === true){
                const preview = await api_svsolicitacoes.get(`api/v2/detalhesSolConectividade?cd_crm_solicitacao=${cdSolicitacao}`)
                setItens(preview.data.devices)
                totalPaginas = Math.ceil(preview.data.devices.length / valorLimite)
                totalItens = preview.data.devices.length;
                setRefazerChamadas(false)
            }

            inserirData(itens)
        }
        carregarSolicitacao()
    },[paginaAtual])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                // setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)
    }

    function parseTecnologia(cell) {
        if(!cell){
            return "Sem informação"
        } else {
            return cell
        }
    }

    const colunas = [
        {
            dataField: 'LINHA',
            headerClasses: 'nao-selecionavel',
            text: <div className={'btn-header-nome-tabela-nova fonte-cor-1'}>MSISDN</div>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'OPERADORA',
            headerClasses: 'nao-selecionavel',
            text: <div className={'btn-header-nome-tabela-nova fonte-cor-1'}>Operadora</div>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{ ((cell == 'undefined') || (cell == '')) ? '-' : cell}</p>;
            }
        },
        {
            dataField: 'ICCID',
            headerClasses: 'nao-selecionavel',
            text:  <div className={'btn-header-nome-tabela-nova fonte-cor-1'}>ICCID</div>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'TIPO_SIMCARD',
            headerClasses: 'nao-selecionavel',
            text:  <div className={'btn-header-nome-tabela-nova fonte-cor-1'}>Tipo de SIM card</div>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'TECNOLOGIA',
            headerClasses: 'nao-selecionavel',
            text:  <div className={'btn-header-nome-tabela-nova fonte-cor-1'}>Tecnologia</div>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{parseTecnologia(cell)}</p>;
            }
        },
        {
            dataField: 'CONSUMO_MB',
            headerClasses: 'nao-selecionavel ',
            text: <div className={'btn-header-nome-tabela-nova fonte-cor-1'}>Consumo</div>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{`${cell}/MB`}</p>;
            }
        },
        {
            dataField: 'DATA_SOLICITACAO',
            headerClasses: 'nao-selecionavel',
            text:  <div className={'btn-header-nome-tabela-nova fonte-cor-1'}>Data da solicitação</div>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{formatarData(cell,false)}</p>;
            }
        },
    ]

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => { totalItens ? setPaginaAtual(page) : setPaginaAtual(1) }
    }

    return(
        <>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className='container-tabela-preview'>
                <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <div>
                                <BootstrapTable
                                    hover={true}
                                    condensed={true}
                                    data={itens}
                                    keyField={"cd_fat_fat_preview"}
                                    columns={colunas}
                                    classes="tabela-nova"
                                    bootstrap4={true}
                                    bordered={false}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    { ...paginationTableProps }
                                />
                                <PaginacaoTabela
                                    preview={true}
                                    setPaginaAtual={setPaginaAtual}
                                    totalPaginas={totalPaginas}
                                    totalItens={totalItens}
                                    valorLimite={valorLimite}
                                    paginaAtual={paginaAtual}
                                    page={paginationProps.page}
                                    onPageChange={paginationProps.onPageChange}
                                />
                                <text className="itens-exibidos-tabela-solicitacoes-2 fonte-cor-1">
                                    Mostrando
                                    <span className="label-tabela-itens-exibidos">{totalItens ? contador + 1 : 0}</span>
                                    -
                                    <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                                    de
                                    <span className="label-tabela-itens-exibidos">{totalItens}</span>
                                </text>
                            </div>
                        )
                    }
                </PaginationProvider>

            </div>
        </>
    )
}