//IMPORTAÇÕES
import React, { useState, useRef, useEffect } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import { useForm } from "react-hook-form";
import { Button, Form,  Col } from 'react-bootstrap';
//SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ICONES
import { setaDropdown, elipseVerde, fecharJanela } from '../../../utils/alternanciaIcones';
import {formatarMilhar} from '../../../utils/formatarMilhar'
//IMPORTAÇÕES REDUX
import { useDispatch } from 'react-redux';
import { revelarNovoPedidoSmsVirtueyes } from "../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao';
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso';
import MensagemErro from '../../componentesModais/mensagens/mensagemErro';
// SELECT
import SelectGenerico from '../../componentesModais/selects/selectGenerico'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
import '../../../css/painelVirtueyes/pedidoSms/pedidoSms.css'
let atualizarParent = false;

export default function NovoPedido(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const {  handleSubmit } = useForm();
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false);
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false);
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false);
    const [revelarMensagemErroRequest, setRevelarMensagemErroRequest] = useState(false);
    const [mensagemErroRequest, setMensagemErroRequest] = useState(false)
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef(); 

    // HOKS DA TELA
    const [quantidadePacote, setQuantidadePacote] = useState('Selecione');
    const [valorPacote, setValorPacote] = useState('');
    const [cdPacote, setCdPacote] = useState();
    const [emailUsuario, setEmailUsuario] = useState('Selecione')
    const [cdUsuario, setCdUsuario] = useState()
    const [nomeUsuario, setNomeUsuario] = useState()
    const [cdErpAnrigo, setCdErpAnrigo] = useState()
    const [cdContaSms, setCdContaSms] = useState()
    const [dummy, setDummy] = useState()
    const [revelarSelectPedido, setRevelarSelectPedido] = useState(false);
    const [revelarSelectUsuario, setRevelarSelectUsuario] = useState(false);
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar(false)
        }
    };

    async function handleConfirmar(){
        if(quantidadePacote === "Selecione o pacote"){
            setRevelarMensagemErro(true)
        }else{
            handlerSalvar()
        }
    }

    async function handleCancelar() {
        ColetaClickstream(1409) // 'pedidoSmsTabelaPedidoSmsDropAcoesNovoPedidoSmsCancelar
        if(atualizarParent === true){
            props.setRenderizar(!props.renderizar)
        } 
        despacho(revelarNovoPedidoSmsVirtueyes(false))
    }

    async function handlerSalvar(){
        ColetaClickstream(1410) // 'pedidoSmsTabelaPedidoSmsDropAcoesNovoPedidoSmsConfirmar
        setSpinAtivo(true)
        if(cdContaSms === undefined){
            await api.post('/m1/cadastrar/smsContaUsuario/criarContaUsuario',{
                "cd_pav_usuario": cdUsuario
            })
            .then(function(resposta){
                api.post('/m1/cadastrar/sms_pedido',{
                    'codigoClienteErpAntigo':cdErpAnrigo,
                    "cd_sms_conta_usuario":resposta.data.dados[0].cd_sms_conta_usuario_inserido,
                    'nomeUsuario': nomeUsuario,
                    "cd_pav_usuario": cdUsuario,
                    "email":emailUsuario,
                    "cd_sms_pacote":cdPacote,
                    "quantidade":quantidadePacote,
                    'valor':valorPacote,
                }).then(function(){
                    atualizarParent = true;
                    setRevelarMensagemSucesso(true)
                    setSpinAtivo(false)
                }).catch(function(error){
                    setMensagemErroRequest(error.response.data.info)
                    setRevelarMensagemErroRequest(true)
                    setSpinAtivo(false)
                })
            })
            .catch(function(error){
                setSpinAtivo(false)
            })
        }else{
            api.post('/m1/cadastrar/sms_pedido',{
                'codigoClienteErpAntigo':cdErpAnrigo,
                "cd_sms_conta_usuario":cdContaSms,
                'nomeUsuario': nomeUsuario,
                "cd_pav_usuario": cdUsuario,
                "email":emailUsuario,
                "cd_sms_pacote":cdPacote,
                "quantidade":quantidadePacote,
                'valor':valorPacote,
            }).then(function(){
                atualizarParent = true;
                setRevelarMensagemSucesso(true)
                setSpinAtivo(false)
            }).catch(function(error){
                setMensagemErroRequest(error.response.data.info)
                setRevelarMensagemErroRequest(true)
                setSpinAtivo(false)
            })
        }
        props.setRenderizar(!props.renderizar)
    }

    useEffect(()=>{
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    },[])
   
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
            
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao){

        }
    }

    function handleMensagemErroRequest(confirmacao){
        if(revelarMensagemErroRequest === false){
            setRevelarMensagemErroRequest(true)
        }
        else{
            setRevelarMensagemErroRequest(false)
        }
        if(confirmacao){
            
        }
    }

  
    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleConfirmar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="modal-novo-pedido-sms fundo-cor-6">
                <Form.Row>
                    <Col>
                        <div className="container-cabecalho-tela">
                            <img className="icone-label-janela nao-selecionavel" src ={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela"> Novo pedido </div>
                            </Form.Label>
                            <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                                <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                            </Button>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-novo-pedido-sms">
                            <div className='coluna-novo-pedido-sms-3'>
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Usuário</div>
                                    </Form.Label>
                                    {revelarSelectUsuario === false &&
                                        <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectUsuario(!revelarSelectUsuario)}>
                                            <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{emailUsuario}</div>
                                            <img className={revelarSelectUsuario?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }

                                    {revelarSelectUsuario === true &&
                                        <SelectGenerico
                                            paramCampo={null}
                                            paramRota={"email_usuario"}
                                            setRevelarSelect={setRevelarSelectUsuario}
                                            setCodigo={setCdUsuario}
                                            setDescricao1={setEmailUsuario}
                                            setDescricao2={setNomeUsuario}
                                            setDescricao3={setCdErpAnrigo}
                                            setDescricao4={setCdContaSms}
                                            placeholder={emailUsuario}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-novo-pedido-sms">
                            <div className='coluna-novo-pedido-sms-1'>
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Pacote</div>
                                    </Form.Label>´
                                    {revelarSelectPedido === false &&
                                        <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectPedido(!revelarSelectPedido)}>
                                            <div className="fonte-cor-1 label-campo">{quantidadePacote}</div>
                                            <img className={revelarSelectPedido?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }

                                    {revelarSelectPedido === true &&
                                        <SelectGenerico
                                            paramRota={"sms_pacote"}
                                            paramCampo1={null}
                                            paramCampo2={null}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdPacote}
                                            setDescricao1={setQuantidadePacote}
                                            setDescricao2={setValorPacote}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectPedido}
                                            placeholder={quantidadePacote}
                                            sufixo={" SMS"}
                                        >
                                        </SelectGenerico>
                                    }
                                </Form.Group>
                                
                            </div>
                            <div className="coluna-novo-pedido-sms-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Valor</div>
                                    </Form.Label>
                                    <div className="campo-texto-cor-3 campo-texto-valor-pedido">
                                        <div className="fonte-cor-1 div-valor-pedido"><span className="novo-pedido-sms-reais">R$   </span>{valorPacote ? formatarMilhar(valorPacote) : '0.00'}</div>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso!"}
                            mensagemParagrafo1={"Sua solicitação foi enviada!"}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            mensagemTitulo={"Remover"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Nenhum pacote selecionado."}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErroRequest &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErroRequest}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErroRequest}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}