//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import { Button, Form} from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectNivelImportancia from '../../componentesModais/selects/selectNivelImportancia'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectNivelImportancia, setRevelarSelectNivelImportancia] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">NivelImportancia {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectNivelImportancia === false &&
                <Button name="cdNivelImportancia" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectNivelImportancia(!revelarSelectNivelImportancia)}>
                    <div className="fonte-cor-1 label-campo">{dsNivelImportancia}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectNivelImportancia === true &&
                <SelectNivelImportancia
                    setRevelarSelectNivelImportancia={setRevelarSelectNivelImportancia} //Hook para fechar a modal
                    setCdNivelImportancia={setCdNivelImportancia} //Código do NivelImportancia
                    setDsNivelImportancia={setDsNivelImportancia} //Nome do NivelImportancia
                    dsNivelImportancia={dsNivelImportancia} 
                    phNivelImportancia={phNivelImportancia} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectNivelImportancia>
            }
        </Form.Group>*/

export default function SelectNivelImportancia(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [lsNivelImportancia, setLsNivelImportancia] = useState()
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };
    
    function handleNivelImportancia(cdNivelImportancia){
        props.setCdNivelImportancia(cdNivelImportancia)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectNivelImportancia(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderNivelImportancia(){
            const {data} = await api.get('/m1/consultar/aut_regra_nivel');
            if(data.dados.length > 0){
                let objetoNivelImportancia = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.cd_aut_regra_nivel}>
                            <div className="campo-select-opcao campo-select-opcao-2 campo-select-opcao-automacao" onClick={() => handleNivelImportancia(item.cd_aut_regra_nivel) }>
                                {item.cd_aut_regra_nivel === 3 && 
                                    <div className="item-select-nivel-importancia-container-esfera">
                                        <div className="item-select-nivel-importancia-esfera-verde"/>
                                        <p className="fonte-cor-1 nao-selecionavel">{item.ds_aut_regra_nivel}</p>
                                    </div>
                                }
                                {item.cd_aut_regra_nivel === 2 && 
                                    <div className="item-select-nivel-importancia-container-esfera">
                                        <div className="item-select-nivel-importancia-esfera-amarela"/>
                                        <p className="fonte-cor-1 nao-selecionavel">{item.ds_aut_regra_nivel}</p>
                                    </div>
                                }
                                {item.cd_aut_regra_nivel === 1 && 
                                    <div className="item-select-nivel-importancia-container-esfera">
                                        <div className="item-select-nivel-importancia-esfera-vermelha"/>
                                        <p className="fonte-cor-1 nao-selecionavel">{item.ds_aut_regra_nivel}</p>
                                    </div>
                                }
                            </div>
                            {data.dados[i+1]!==undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsNivelImportancia(objetoNivelImportancia)
            }else{
                let objetoNivelImportancia = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsNivelImportancia(objetoNivelImportancia)
            }
            setSpinAtivo(false)
        }
        renderNivelImportancia()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])/*eslint-disable*/


    return(
        <Form ref={modal} >
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectNivelImportancia(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsNivelImportancia}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsNivelImportancia}
                </ul>
            </div>
        </Form>
    )
}

