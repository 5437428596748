//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { Button} from 'react-bootstrap';/*eslint-disable*/
import { alertaAtencao } from '../../../utils/alternanciaIcones'
import '../../../css/componentesModais/mensagens/mensagemAtencaoSelect.css'

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import MensagemAtencao from '../componentesModais/mensagens/mensagemAtencao'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma.
    ex: { revelarMensagemAtencao &&
            <div className="componente-modal-overlay">
                <div className="componente-modal">
                    <MensagemAtencao
                        handleMensagemAtencao={handleMensagemAtencao}
                        mensagemTitulo={"TITULO DA MENSAGEM"}
                        mensagemParagrafo1={"CORPO DA MENSAGEM 1"}
                        mensagemParagrafo2={"CORPO DA MENSAGEM 2"}
                        mensagemParagrafo3={"CORPO DA MENSAGEM 3"}
                        mensagemBotao={"TEXTO DO BOTÃO"}
                    />
                </div>
            </div>
        }
        
    Agora crie uma função para manejar o estado da mensagem e executar a função de
    fechamento padrão caso necessário.
    ex: function handleMensagemAtencao(confirmacao){
            if(revelarMensagemAtencao === false){
                setRevelarMensagemAtencao(true)
            }else{
                setRevelarMensagemAtencao(false)
                if(confirmacao === true){
                    //ALGUMA FUNÇÃO
                }
            }
        }*/

export default function MensagemAtencaoSelect(props) {
    //HOOK PRINCIPAIS
    const [lsDados, setLsDados] = useState([])
    const [mensagemTitulo, setMensagemTitulo] = useState('')
    const [mensagemParagrafo1, setMensagemParagrafo1] = useState('')
    const [mensagemParagrafo2, setMensagemParagrafo2] = useState('')
    const [mensagemParagrafo3, setMensagemParagrafo3] = useState('')
    const [mensagemBotao, setMensagemBotao] = useState('')
    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar(false)
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleCancelar(confirmacao){
        if(confirmacao === true){
            props.handleMensagemAtencao(true)
        }else{
            props.handleMensagemAtencao(false)
        }
    }

    useEffect(() => {
        setMensagemTitulo(props.mensagemTitulo)
        setMensagemParagrafo1(props.mensagemParagrafo1)
        setMensagemParagrafo2(props.mensagemParagrafo2)
        setMensagemBotao(props.mensagemBotao)
        async function renderClientes(){
            let objetoClientes = props.dados.map((item) => {
                return (
                    <li key={"menu-"+item}>
                        <div className="mensagem-campo-select-opcao campo-select-opcao-1">
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label">{item}</span>
                        </div>
                    </li>
                );
            })
            setLsDados(objetoClientes)
        }
        renderClientes()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return(
        <div className="mensagem-overlay">
            <div className="mensagem-container">
                <div ref={modal} className="mensagem-corpo fundo-cor-6">
                    <div className="mensagem-container-icone">
                        <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                    </div>
                    <div className="mensagem-container-titulo">
                        <div className="fonte-cor-1 label-titulo-mensagem">{mensagemTitulo}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo1}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo2}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo3}</div>
                    </div>
                    <div className="campo-select-corpo">
                        <ul className="campo-select-lista">
                            {lsDados}
                        </ul>
                    </div>
                    <div className="mensagem-container-botao">
                        <Button className="mensagem-botao-confirmar" variant="success" size="sm" onClick={() => handleCancelar(true)}>
                            <div className="fonte-cor-4">{mensagemBotao}</div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}