const defaultState = {
    "systemTheme":false,
    "currentScreenId":false,
    "componentRendering":[],
    "navigationExpansion": true
}

export const SystemControl = (state = defaultState, action) => {
    switch(action.type) {
        case "SYSTEM_THEME":
            return {...state, "systemTheme":action.data}
        case "CURRENT_SCREEN_ID":
            return {...state, "currentScreenId":action.data}
        case "COMPONENT_RENDERING":
            return {...state, "componentRendering":action.data}
        case "NAVIGATION_EXPANSION":
            return {...state, "navigationExpansion":action.data}
        default:
            return state;
    }
}