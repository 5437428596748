//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';
import { useForm } from "react-hook-form";
import { Card, Button, Form, ButtonToolbar, Accordion, Col } from 'react-bootstrap';
import MultiSlider from "multi-slider";
import "../../../css/painelDispositivos/dspGrupo/grupo.css";
//SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ICONES
import {setaDropdown, elipseVerde, fecharJanela, olhoLigadoGrupo, configVerde, adicionarMaisVerde, editar, lixeiraVermelho, lixeiraBranco, exclamacao} from '../../../utils/alternanciaIcones';
//IMPORTAÇÕES REDUX
import { useDispatch } from 'react-redux';
import { revelarConfigurarGrupo } from "../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao';
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso';
import MensagemErro from '../../componentesModais/mensagens/mensagemErro';
 
// SELECT
import SelectGrupos from '../../componentesModais/selects/selectConfigGrupos';

    let arrAlterar = [];
    let dadosSimcardGrupo = [];
    let gruposPadroes = [];

    let arrayFalsoAtributos = [
        {nomeAtributos:"Conexão no ciclo",valorTolerancia:0,radioButonChecked:0},
        {nomeAtributos:"Conexão no dia",valorTolerancia:0,radioButonChecked:0},
        {nomeAtributos:"Consumo no ciclo",valorTolerancia:0,radioButonChecked:0},
        {nomeAtributos:"Consumo no dia",valorTolerancia:0,radioButonChecked:0},
        {nomeAtributos:"Pacote no ciclo",valorTolerancia:0,radioButonChecked:0},
        {nomeAtributos:"Pacote no dia",valorTolerancia:0,radioButonChecked:0},
    ]

export default function ConfigurarGrupos(props) {
    //HOOKS VALIDAÇÂO
    const { handleSubmit } = useForm();
    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    const [renderizarNovaBusca, setRenderizarNovaBusca] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false);
    const [revelarMensagemSucessoNovoGrupo, setRevelarMensagemSucessoNovoGrupo] = useState(false);
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false);
    const [deletarGrupo, setDeletarGrupo] = useState();
    //SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef(); 

    // HOKS DA TELA
    let storeCdGrupo = -1;
    const [grupos, setGrupos] = useState([])
    const [nomeGrupo, setNomeGrupo] = useState('Selecione');
    /*eslint-disable*/
    const [NomeDoGrupo, setNomeDoGrupo] = useState("");
    const [cdGrupo, setCdGrupo] = useState();
    /*eslint-disable*/
    const [cdGrupoTemplate, setCdGrupoTemplate] = useState();
    const [revelarEdicao, setRevelarEdicao] = useState(false);
    const [revelarSelectGrupo, setRevelarSelectGrupo] = useState(false);
    const [revelarCampoObrigatorio, setRevelarCampoObrigatorio] = useState(false);
    const [nomeGrupoReferente, setNomeGrupoReferente] = useState();
    
    // HOKS CONFIGURAÇÃO 
    const [revelarConfiguracao, setRevelarConfiguracao] = useState(false);
    /*eslint-disable*/
    const [revelarAnalise, setRevelarAnalise] = useState(false);

    const iconeAlerta = <img className="icone-asterisco" src={exclamacao()} alt='exclamacao'/>;
    const colors = ["#36D896", "#F3CA3E","#FC5F0E", "#CD2D37"];

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar(false)
        }
    };

    async function handleNovoGrupo(){
        setSpinAtivo(true)
        await cadastrarGrupoSimcard({
            'ds_nome' : 'Novo Grupo',
            'ds_dsp_simcard_grupo' : 'Descrição do Grupo',
        })
        .then(function (response) {
            if(response.data.status == 200){
                setMensagemSucesso('Grupo cadastrado com sucesso!')
                handleMensagemSucessoNovoGrupo(false)
            }
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            handleMensagemErro(false)
        });

        setRenderizarNovaBusca(!renderizarNovaBusca)
        setRenderizar(!renderizar)
        setSpinAtivo(false)
    }
    
    async function handleAlterar(){
        const getBodyPadraoAlterarGrupo = ({item}) => ({
            "cd_dsp_simcard_grupo":item.cd_dsp_simcard_grupo,
            "ds_dsp_simcard_grupo":item.ds_dsp_simcard_grupo,
            "ds_nome":item.ds_nome,
            "fl_habilitar_analise":item.fl_habilitar_analise,
        })

        if(document.getElementById("novoNomeGrupo")){
            if(!document.getElementById("novoNomeGrupo").value){
                setRevelarCampoObrigatorio(true)
                setRenderizar(!renderizar)
                return false
            }
        }
        
        //tira os 3 grupos padroes
        dadosSimcardGrupo.splice(0,3)

        if(dadosSimcardGrupo.length){
             const arrObjEditar = dadosSimcardGrupo.filter(g => arrAlterar.includes(g.cd_dsp_simcard_grupo))

            arrObjEditar.map(async function(item,i){
                const cadastrar = { atributos:[], situacoes:[] };
                const atualizar = { atributos:[], situacoes:[] };

                item.atributos.map(async function(item_atribuidos,ia){
                    if(item_atribuidos.cd_dsp_simcard_grupo_atributo != undefined){
                        atualizar.atributos.push(item_atribuidos)
                    }
                    else{
                        cadastrar.atributos.push(item_atribuidos)
                    }
                })
                item.situacoes.map(async function(item_situacao,is){
                    if(item_situacao.cd_dsp_simcard_grupo_situacao != undefined){
                        atualizar.situacoes.push(item_situacao)
                    }
                    else{
                        cadastrar.situacoes.push(item_situacao)
                    }
                })

                const cadastrarAtributoOuSituacao = cadastrar.atributos.length || cadastrar.situacoes.length;
                const atualizarAtributoOuSituacao = atualizar.atributos.length || atualizar.situacoes.length;

                if(atualizarAtributoOuSituacao || !cadastrarAtributoOuSituacao) {
                    await alterarGrupoSimcard({
                        ...getBodyPadraoAlterarGrupo({item}),
                        ...atualizar
                    })
                }

                if(cadastrarAtributoOuSituacao) {
                    await cadastrarGrupoSimcard({
                        "cd_dsp_simcard_grupo":item.cd_dsp_simcard_grupo,
                        ...cadastrar,
                    })
                }
            })
        }
        setMensagemSucesso('Grupo configurado com sucesso!')
        handleMensagemSucesso(false)
    }

    async function handleDeletar(cd_dsp_simcard_grupo){
        setSpinAtivo(true)
        await deletarGrupoSimcard(cd_dsp_simcard_grupo)
        .then(function (response) {
            setRenderizar(!renderizar)
            setRenderizarNovaBusca(!renderizarNovaBusca)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRenderizarNovaBusca(!renderizarNovaBusca)
            handleMensagemErro(false)
        })
        setSpinAtivo(false)
    }
    
    async function handleCancelar() {
        despacho(revelarConfigurarGrupo(false))
        arrAlterar = []
    }

    async function handleVoltar() {
        setRevelarConfiguracao(false)
        setRevelarEdicao(false)
    }

    function handleMensagemAtencao(confirmacao, cd_dsp_simcard_grupo){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(!confirmacao){
            setDeletarGrupo(cd_dsp_simcard_grupo)
        }
        if(confirmacao && revelarMensagemAtencao){
            handleDeletar(deletarGrupo)
        }
        setRenderizar(!renderizar)
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)

        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
            props.setRenderizar(!props.renderizar)
        }
        setRenderizar(!renderizar)
    }

    function handleMensagemSucessoNovoGrupo(confirmacao){
        if(revelarMensagemSucessoNovoGrupo === false){
            setRevelarMensagemSucessoNovoGrupo(true)

        }else{
            setRevelarMensagemSucessoNovoGrupo(false)
        }
        setRenderizar(!renderizar)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }
    
    function handleExpandirEdicao(Param){
        setCdGrupo(Param)
        if(Param != storeCdGrupo){
            setRevelarEdicao(false)
            setRevelarConfiguracao(false)
            setNomeGrupo('Selecione')
        }
        storeCdGrupo = Param
        if(revelarEdicao === true){
            setRevelarEdicao(false)
            setRevelarConfiguracao(false)
            setNomeGrupo('Selecione')
        }else{
            setRevelarEdicao(true)
            setRevelarConfiguracao(false)
        }
    }

    function handleExpandirConfiguracao(Param, nome){
        setNomeDoGrupo(nome)
        setCdGrupo(Param)
        if(Param != storeCdGrupo){
            setRevelarConfiguracao(false)
            setRevelarEdicao(false)
        }
        storeCdGrupo = Param
        if(revelarConfiguracao === true){
            setRevelarConfiguracao(false)
            setRevelarEdicao(false)
        }else{
            setRevelarConfiguracao(true)
            setRevelarEdicao(false)
        }
    }

    let arrayRadios = ([
        {
            numeroRadio: 0,
        },
        {
            numeroRadio: 1,
        },
        {
            numeroRadio: 2,
        },
        {
            numeroRadio: 3,
        },
        {
            numeroRadio: 4,
        },
        {
            numeroRadio: 5,
        },
        {
            numeroRadio: 6,
        },
        {
            numeroRadio: 7,
        },
        {
            numeroRadio: 8,
        },
        {
            numeroRadio: 9,
        },
        {
            numeroRadio: 10,
        },
    ])


    function revelarParametro(item){
        if(item.parametros.length != 0){
            return[
                {
                    ds_dsp_simcard_grupo_atributo_parametro: item.ds_nome,
                    ds_valor: item.parametros[0].ds_valor
                }
            ]
        }
        else{
            return []
        } 
    }

    async function handleTamplate(){
        let dadosCopiadosAtributos=[];
        let dadosCopiadosSituacoes=[]; 
        if(nomeGrupo != 'Selecione' && nomeGrupo!=nomeGrupoReferente[0]){
            dadosSimcardGrupo.map(async function(item_dadosGrupo,idg){
                if(item_dadosGrupo.ds_nome == nomeGrupo){
                    await item_dadosGrupo.atributos.map(async function(item_atributo,ia){
                        dadosCopiadosAtributos.push({
                            cd_dsp_simcard_grupo: nomeGrupoReferente[1],
                            ds_dsp_simcard_grupo: item_atributo.ds_nome,
                            ds_nome: item_atributo.ds_nome,
                            n2_peso: item_atributo.n2_peso,
                            parametros: await revelarParametro(item_atributo)
                        })
                    })
                    item_dadosGrupo.situacoes.map(async function(item_situacoes,is){
                        dadosCopiadosSituacoes.push({
                            cd_dsp_simcard_grupo: nomeGrupoReferente[1],
                            ds_dsp_simcard_grupo_situacao: nomeGrupoReferente[0],
                            ds_nome: nomeGrupoReferente[0],
                            n2_intervalo_final: item_situacoes.n2_intervalo_final,
                            n2_intervalo_inicial: item_situacoes.n2_intervalo_inicial,
                            n2_ordem: item_situacoes.n2_ordem,
                        })
                    })
                }
            })
            dadosSimcardGrupo.map(async function(item_dadosGrupo,idg){
                if(item_dadosGrupo.ds_nome == nomeGrupoReferente[0]){
                    item_dadosGrupo.atributos = JSON.parse(JSON.stringify(dadosCopiadosAtributos));
                    item_dadosGrupo.situacoes = JSON.parse(JSON.stringify(dadosCopiadosSituacoes));
                }
            })
            setRenderizar(!renderizar)
            
        }
    }

    async function getGruposPadroes() {
        //carrega os 3 grupos padroes
        const resGruposPadroes = await api.get('/m1/consultar/simcard_grupo?fl_padrao=1')
        gruposPadroes = resGruposPadroes.data.dados;
    }

    async function ChamadaApi(){    
        if(!gruposPadroes.length) await getGruposPadroes();
        dadosSimcardGrupo = [...gruposPadroes]

        // carrega o grupos do cliente
        const {data} = await api.get('/m1/consultar/simcard_grupo') 
        await data.dados.map((item,i)=>{
            dadosSimcardGrupo.push(item);
        })
        setRenderizar(!renderizar)
        setSpinAtivo(false)
    }

    useEffect(() =>{
        handleTamplate()
    },[nomeGrupo])

    useEffect(() => {
        arrAlterar = []
    }, [])

    useEffect(() =>{
        ChamadaApi()
    },[renderizarNovaBusca])

    useEffect(() => {
        async function renderGrupos(){
            setGrupos(dadosSimcardGrupo.map(function(item, i){
                let atributos = item.atributos;
                let situacao = item.situacoes;

                verificarNumero()
                // função para verificar se o numero do radio é igual ao numero que vem no banco de dados 
                async function verificarNumero(){
                    arrayFalsoAtributos.map(function(item_arrayFalsoAtributo){
                        item_arrayFalsoAtributo.valorTolerancia = 0
                        item_arrayFalsoAtributo.radioButonChecked = null
                    })
                    await atributos.map(async(element, index) => {
                        await arrayFalsoAtributos.map(function(item_arrayFalsoAtributo,iafl){
                            if (element.ds_nome == item_arrayFalsoAtributo.nomeAtributos){
                                item_arrayFalsoAtributo.radioButonChecked = element.n2_peso
                                item_arrayFalsoAtributo.valorTolerancia = element.parametros[0].ds_valor
                            }
                        })
                    });
                }

                async function handleVerificarChecked(id,nome,tolerancia){
                    if(!tolerancia){
                        tolerancia = 0
                    }
                    VerificarChecked(id,nome,tolerancia)
                }

                const handleChangeValorTolerancia = ({nome, tolerancia}) => {
                    changeItemlArrayFalsoAtributo({nome, pos:'valorTolerancia', novoValor:tolerancia})
                }

                const handleChangeRadioButtonChecked = ({nome, pegarId}) => {
                    changeItemlArrayFalsoAtributo({nome, pos:'radioButonChecked', novoValor:pegarId})
                }

                const changeItemlArrayFalsoAtributo = ({nome, pos, novoValor}) => {
                    arrayFalsoAtributos.map((item_arrayFalsoAtributo)=>{
                        if(nome == item_arrayFalsoAtributo.nomeAtributos){
                            item_arrayFalsoAtributo[pos] = parseInt(novoValor);
                        } 
                    })
                }

                async function VerificarChecked(id, nome, tolerancia){
                    adicionaArrAlterar(item.cd_dsp_simcard_grupo);
                
                    let verificarExisteNome = false;
                    let peso = 0;
                    if(id != undefined){
                        peso = id.split("-")[1]; // divide a string no ("-"), cria um array, EX: de "radio-7", fica [radio,7] || o [1], pega a posção 1 do array, no caso, 7.
                    }
                    const addAtributo = ({ds_valor}) => {
                        atributos.push({ds_nome: nome, n2_peso: parseInt(peso), parametros: [{ds_valor}]})
                    }

                    await atributos.map((element,index) =>{        
                        if(element.ds_nome === nome){
                            if(id != undefined){
                                dadosSimcardGrupo[i].atributos[index].n2_peso = parseInt(peso); // faz o dadosSimcardGrupo[i].atributos[index].n2_peso receber o valor que foi clicado (let peso)
                                handleChangeRadioButtonChecked({nome, peso})
                            }
                            // verifica se a tolerancia esta vindo indefinida ou é igual a zero
                            if(tolerancia || tolerancia==0){
                                dadosSimcardGrupo[i].atributos[index].parametros[0].ds_valor = parseInt(tolerancia); // faz o dadosSimcardGrupo[i].atributos[index].n2_peso receber o valor que foi clicado (let peso)
                                handleChangeValorTolerancia({nome, tolerancia})
                            }   
                            verificarExisteNome = true;
                        }  
                        if(verificarExisteNome == false && atributos[atributos.length] == element){
                            if(tolerancia){
                                addAtributo({ds_valor: parseInt(tolerancia)})
                                handleChangeValorTolerancia({nome, tolerancia})
                            }
                            else{
                                addAtributo({ds_valor: 0})
                                handleChangeRadioButtonChecked({nome, peso})
                            }
                        }
                    });
                    // veririca se o verificarExisteNome é false e se for, retorna o push que cria um array com as informações  
                    if(verificarExisteNome === false){
                        if(tolerancia){
                            addAtributo({ds_valor: parseInt(tolerancia)})
                            handleChangeValorTolerancia({nome, tolerancia})
                        }  
                        else{
                            addAtributo({ds_valor: 0})
                        }
                    }
                    setRenderizar(!renderizar)
                }

                // função para veritificar o tempo offline e retornar para os campos
                function tempoOffline(nome,value){
                    let retorno = "";
                    atributos.map((element) =>{
                        if (element.ds_nome === nome){
                            retorno = element.n2_peso;
                        }
                    });
                    return retorno;
                }
                
                function handlerTempoOffline(nome,value){
                    adicionaArrAlterar(item.cd_dsp_simcard_grupo);

                    let verificarExisteNome = false;
                    atributos.map(async (element) =>{
                        if (element.ds_nome === nome){
                            let peso = element.n2_peso; 
                            element.n2_peso = parseInt(value);
                            if(!value){
                                peso?element.n2_peso = 0: element.n2_peso = 1;
                            }
                            verificarExisteNome = true
                        }
                    });
                    if(!verificarExisteNome){
                        atributos.push({ds_nome: nome, n2_peso: value?parseInt(value):1, parametros: []})
                    }
                    setRenderizar(!renderizar)
                }

                // verifica o array q vem do banco, pega os 3 valores e subtrai para obter os 4 valores nescesarios para a biblioteca do MultiSlider
                function VerificaSituacao(nome){
                    let retorno = "";
                    let situacaoVazia = false;
                    situacao.map((element_situacao, index) =>{
                        if (element_situacao.ds_nome === nome){
                            situacaoVazia = true;
                            let valor1 = element_situacao.n2_intervalo_inicial;
                            let valor2 = element_situacao.n2_intervalo_final - valor1;
                            let valor3 = element_situacao.n2_ordem - valor2 - valor1;
                            let valor4 = 10 - valor3 - valor2 - valor1;
                            retorno = [valor1, valor2, valor3, valor4];
                        }
                    });
                    if(situacaoVazia === false){
                        situacao.push({
                            ds_nome: nome,
                            n2_intervalo_final:0,
                            n2_intervalo_inicial: 0,
                            n2_ordem: 0,
                        })
                        retorno = [2, 3, 3, 2];
                    }
                    return retorno;
                }

                function handlerVerificaSituacao(nome,valor){
                    let verificarExisteNome = false;
                    situacao.map((element_situacao, index) =>{
                        if (element_situacao.ds_nome === nome){
                            element_situacao.n2_intervalo_inicial = valor[0];
                            element_situacao.n2_intervalo_final = valor[0] + valor[1];
                            element_situacao.n2_ordem = valor[0] + valor[1] + valor[2];
                            verificarExisteNome = true

                            adicionaArrAlterar(item.cd_dsp_simcard_grupo);
                        }
                        return true
                    });
                    if(!verificarExisteNome){
                        situacao.push({
                            ds_nome: nome,
                            n2_intervalo_final:valor[0],
                            n2_intervalo_inicial: valor[0] + valor[1],
                            n2_ordem: valor[0] + valor[1] + valor[2],
                            cd_dsp_simcard_grupo: item.cd_dsp_simcard_grupo
                        })

                        adicionaArrAlterar(item.cd_dsp_simcard_grupo);
                    }
                    setRenderizar(!renderizar)
                }

                function adicionaArrAlterar(cdGrupo) {
                    const found = arrAlterar.find(item => item === cdGrupo)

                    if(!found) {
                        arrAlterar.push(cdGrupo)
                    }
                }

                function handlerEditar(nome,dsNome,dspSimcard,cdGrupo){
                    dadosSimcardGrupo.map(function(item_dsp,idsp){
                        if(cdGrupo == item_dsp.cd_dsp_simcard_grupo){
                            if(dsNome||dsNome==""){
                                item_dsp.ds_nome = dsNome // altera campo "Nome"
                                adicionaArrAlterar(cdGrupo)
                            }
                            if(dspSimcard||dspSimcard == ""){
                                item_dsp.ds_dsp_simcard_grupo = dspSimcard // altera campo "Descrição"
                                adicionaArrAlterar(cdGrupo)
                            }
                        }
                        return true
                    })
                    setRenderizar(!renderizar)
                }

                function handlerflAtivo(nome){
                    dadosSimcardGrupo.map(function(item_dsp,idsp){
                        if(item_dsp.ds_nome == nome){
                            adicionaArrAlterar(item.cd_dsp_simcard_grupo);
                            let valor = item_dsp.fl_habilitar_analise
                            valor ? item_dsp.fl_habilitar_analise=0 : item_dsp.fl_habilitar_analise=1
                        }
                        return true
                    })
                    setRenderizar(!renderizar)
                }
                
                function HandleGirarImagem(imagemId){
                    setRevelarSelectGrupo(!revelarSelectGrupo)
                    girarImagem(imagemId);
                }

                function LabelCampoVisualizar({value}) {
                    return <Form.Label>
                        <div className="fonte-cor-1 label-campo-visualizar">{value}</div>
                    </Form.Label>
                }

                function LabelCampo({value, myClass}) {
                    return <Form.Label>
                        <div className={`fonte-cor-1 label-campo ${myClass || ''}`}>{value}</div>
                    </Form.Label>
                }

                function CampoTexto({value}) {
                    return <Form.Control className="fundo-cor-7 campo-texto" disabled value={value} type="text"/>
                }

                function MySwitch({ on, item }) {
                    const disabled = item.fl_padrao?true:false;

                    if(on) {
                        return <div  className="flipswitch-online">
                            <input  className="flipswitch-cb-online" disabled={disabled} checked={tempoOffline("Usar Média")} id="usarMedia" type="checkbox" name="flipswitch" onChange={()=> handlerTempoOffline("Usar Média")}/>
                            <label htmlFor="usarMedia" className="flipswitch-label-online">
                                <div className="flipswitch-inner-online flipswitch-cor-2"></div>
                                <div className="flipswitch-switch-online"></div>
                            </label>
                        </div>
                    }
                    else {
                        return <div  className="flipswitch-grupo">
                            <input disabled={disabled} checked={item.fl_habilitar_analise} id={item.cd_dsp_simcard_grupo} type="checkbox" name="flipswitch" className="flipswitch-cb" onChange={(e) => handlerflAtivo(item.ds_nome)}/>
                            <label  htmlFor={item.cd_dsp_simcard_grupo} className="flipswitch-label">
                                <div className="flipswitch-inner flipswitch-cor-2"></div>
                                <div className="flipswitch-switch"></div>
                            </label>
                        </div>
                    }
                }

                return (
                    <>
                    <div key='configurarGrupo' onClick={() => setCdGrupo(item.cd_dsp_simcard_grupo)} className="fundo-cor-10 campo-cadastrar-grupo">
                        <div key={item.cd_dsp_simcard_grupo} onClick={() => setCdGrupo(item.cd_dsp_simcard_grupo)} className="fonte-cor-1 campo-grupo">{item.ds_nome}</div>
                    </div>
                        {(item.cd_dsp_simcard_grupo == 1) || (item.cd_dsp_simcard_grupo == 2) || (item.cd_dsp_simcard_grupo == 3) ?
                            <>
                                <ButtonToolbar className="btn-toolbar-grupos">
                                    <BtnVisualizarGrupo item={item} />
                                    <BtnApagarGrupo item={item} disabled />
                                </ButtonToolbar>
                                {revelarEdicao && cdGrupo === item.cd_dsp_simcard_grupo &&
                                    <div div className="editar-corpo-grupos menu-dropdown-configuracao-cabecalho-sistema fundo-cor-10">
                                    <div className="select-pav-triangulo-grupo select-pav-triangulo-grupo-editar"></div>
                                        <BotaoFechar />
                                        <Form.Group className="container-visualizar-grupos">
                                            <LabelCampoVisualizar value='Nome' />
                                            <CampoTexto value={item.ds_nome} />
                                            
                                            <LabelCampoVisualizar value='Descrição' />
                                            <CampoTexto value={item.ds_dsp_simcard_grupo} />

                                            <LabelCampoVisualizar value='Template' />
                                            <CampoTexto value={item.ds_nome} />
                                        </Form.Group>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <ButtonToolbar className="btn-toolbar-grupos">
                                    <BtnVisualizarGrupo item={item} editar />
                                    <BtnApagarGrupo item={item} />
                                </ButtonToolbar>

                                {revelarEdicao && cdGrupo === item.cd_dsp_simcard_grupo &&
                                    <div className="editar-corpo-grupos fundo-cor-10">
                                        <div className="select-pav-triangulo-grupo select-pav-triangulo-grupo-editar"></div>
                                        <Form.Row>
                                            <Col>
                                                <BotaoFechar myClass='btn-fechar-editar-grupo' />
                                                <Form.Group>
                                                    <LabelCampo value='Nome' myClass='label-campo-nome' />
                                                    
                                                    <Form.Control id="novoNomeGrupo" className="fundo-cor-7 fonte-cor-1 campo-texto" value={item.ds_nome} type="text" onChange={e => handlerEditar(item.ds_nome,e.target.value,undefined,item.cd_dsp_simcard_grupo)}/>
                                                    <>
                                                        { revelarCampoObrigatorio &&
                                                            <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório</p>
                                                        }
                                                    </>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col>
                                                <Form.Group>
                                                        <LabelCampo value='Descrição' />
                                                    <Form.Control id="novoNomeGrupo" className="fundo-cor-7 fonte-cor-1 campo-texto" value={item.ds_dsp_simcard_grupo} type="text" onChange={e => handlerEditar(item.ds_nome,undefined,e.target.value,item.cd_dsp_simcard_grupo)}/>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col>
                                                <div className="grupo-cadastro-cliente-1">
                                                    <div className="coluna-cadastro-grupos-1">
                                                        <Form.Group>
                                                                <LabelCampo value='Template' />
                                                            { revelarSelectGrupo === false &&
                                                                <Button className="campo-select fonte-cor-1 fundo-cor-7" onClick={() => setRevelarSelectGrupo(!revelarSelectGrupo)}>
                                                                    <LabelCampo value={nomeGrupo} />
                                                                    <img className={revelarSelectGrupo?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                                                </Button>
                                                            }
                                                            {revelarSelectGrupo &&
                                                                <SelectGrupos
                                                                    setRevelarSelectGrupo={setRevelarSelectGrupo}
                                                                    setPhGrupo={"Selecione"} 
                                                                    setNomeGrupo={setNomeGrupo}
                                                                    setCdGrupo={setCdGrupo}
                                                                    setCdGrupoTemplate={setCdGrupoTemplate}
                                                                    nomeGrupo={nomeGrupo}
                                                                >
                                                                    {setNomeGrupoReferente([item.ds_nome,item.cd_dsp_simcard_grupo])}
                                                                </SelectGrupos>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    </div>
                                }
                            </>
                        }

                        {revelarConfiguracao && cdGrupo === item.cd_dsp_simcard_grupo &&
                            <div className="configuracao-corpo-grupo menu-dropdown-configuracao-cabecalho-sistema fundo-cor-10">
                            <div className="select-pav-triangulo-grupo"/>
                                <BotaoFechar />
                                <Form.Group>
                                    <div className="container-termo-adesao">
                                        <div className="switchDiv">
                                                <MySwitch on={false} item={item} />
                                                <div className="texto-grupo fonte-cor-1"> Habilitar análise de situações </div>
                                        </div>
                                    </div>
                                    {item.fl_habilitar_analise ?
                                        <>
                                            <div className="analise-corpo-grupo item-modal-select-semHover">
                                                <div className="select-pav-divisora-configuracao-grupos2"/>
                                                <div className=" container-cabecalho-tela-grupo-titulos">
                                                    <img className="icone-label-janela-grupo-atributo" alt="elipseVerde" src={elipseVerde()}/>
                                                    <Form.Label className="label-janela-grupo">
                                                        <span className="texto-grupo fonte-cor-1">Atributos</span>
                                                    </Form.Label>
                                                </div>
                                                <div className="select-pav-divisora-analise-grupos"/>

                                                {arrayFalsoAtributos.map((item_atribuidos,ia) =>
                                                    <>
                                                        <Accordion defaultActiveKey="1">                                                          
                                                            <Accordion.Toggle onClick={() => HandleGirarImagem("girarSeta"+item_atribuidos.nomeAtributos)} className="fundo-cor-10 fonte-cor-1 accordion-analisar-situacao" as={Card.Header} eventKey="0"> {item_atribuidos.nomeAtributos}
                                                                <img id={"girarSeta"+item_atribuidos.nomeAtributos} className="drop-acoes-menu-icone-desativado" src={setaDropdown()} alt="drop" />
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                            <>
                                                                <label className="fonte-cor-1 campo-texto-peso"> Peso </label>
                                                                <label className="fonte-cor-1 texto-tolerancia"> Tolerância </label>
                                                                <div className="fonte-cor-1 barra-analise">
                                                                {arrayRadios.map((item_radio, ir) => {
                                                                    return(
                                                                        <>
                                                                            <label className="container-grafico-peso"> <p className={"numerosRadio" + item_radio.numeroRadio}> <p className={item_atribuidos.radioButonChecked == item_radio.numeroRadio ? "numerosRadioBold" + item_radio.numeroRadio : "numerosRadio" + item_radio.numeroRadio}>{item_radio.numeroRadio} </p>  </p>
                                                                                <input 
                                                                                    disabled={item.fl_padrao?true:false}
                                                                                    id={"radio-" + item_radio.numeroRadio+'-'+item_atribuidos.nomeAtributos} 
                                                                                    className="graficoPeso" 
                                                                                    defaultChecked={item_atribuidos.radioButonChecked == item_radio.numeroRadio?true:false} 
                                                                                    name={"grafico-peso-conexao-ciclo-"+item_atribuidos.nomeAtributos} 
                                                                                    type="radio" 
                                                                                    onClick={(e)=>VerificarChecked(e.target.id, item_atribuidos.nomeAtributos)}
                                                                                />
                                                                                <span className="checkmark-grafico-peso checkmark0-grafico-peso "></span> 
                                                                            </label>
                                                                        </>
                                                                    )
                                                                })}
                                                                    <Form.Control type="number" disabled={item.fl_padrao?true:false} id="novoNomeGrupo" value={item_atribuidos.valorTolerancia} className="fonte-cor-1 fundo-cor-7 campo-texto-tolerancia" placeholder="50%" name="tolerancia" maxLength="3" onChange={e => handleVerificarChecked(undefined,item_atribuidos.nomeAtributos, e.target.value)}/>
                                                                    <label className="campo-porcentagem-tolerancia">%</label>
                                                                </div>
                                                            </>
                                                            </Accordion.Collapse>
                                                        </Accordion>
                                                        
                                                        <div className="select-pav-divisora-analise-grupos"/>
                                                    </>
                                                )} 


                                                <Accordion  defaultActiveKey="1">
                                                    <Accordion.Toggle onClick={() => HandleGirarImagem("girarSetaOffline")} className="fundo-cor-10 fonte-cor-1 accordion-analisar-situacao" as={Card.Header} eventKey="0"> Tempo offiline
                                                        <img id="girarSetaOffline" className="drop-acoes-menu-icone-desativado" src={setaDropdown()} alt="drop" />
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0">
                                                    <>
                                                        <Form.Label>
                                                            <div className="fonte-cor-1 fonte-texto-grupo-offline">Dias offline para considerar exceção</div>
                                                        </Form.Label>
                                                        <Form.Control type="number" disabled={item.fl_padrao?true:false} value={tempoOffline("Dias Offline")} className="fonte-cor-1 fundo-cor-7 campo-texto-dias-offline" name="tolerancia" id="novoNomeGrupo" maxLength="3" onChange={e => handlerTempoOffline("Dias Offline",e.target.value)}/>

                                                            <Form.Label>
                                                            <div className="fonte-cor-1 fonte-texto-grupo-offline">Minutos offline para considerar peso</div>
                                                        </Form.Label>
                                                        <Form.Control type="number" disabled={item.fl_padrao?true:false} value={tempoOffline("Minutos Offline")} className="fonte-cor-1 fundo-cor-7 campo-texto-minutos-offline" name="tolerancia" id="novoNomeGrupo" maxLength="3" onChange={e => handlerTempoOffline("Minutos Offline",e.target.value)}/>
                                                    
                                                        <div className="switchDiv-online">
                                                            <div className="fonte-cor-1 fonte-texto-grupo-media-offline"> Usar média geral </div>
                                                            <MySwitch on item={item} />
                                                        </div>
                                                    </>
                                                    
                                                    </Accordion.Collapse>
                                                </Accordion>
                                                
                                                <div className="select-pav-divisora-configuracao-grupos2"/>
                                                <div className=" container-cabecalho-tela-grupo-titulos">
                                                    <img className="icone-label-janela-grupo" alt="Elipse verde" src={elipseVerde()}/>
                                                    <Form.Label className="label-janela-grupo">
                                                        <span className="texto-grupo fonte-cor-1">Situações</span>
                                                    </Form.Label>
                                                    <div className="barra-situacao">
                                                        <div className="barra-slider">
                                                            <MultiSlider
                                                                colors={colors}
                                                                onChange={(e) => {!item.fl_padrao&&handlerVerificaSituacao(item.ds_nome, e)}}
                                                                values={VerificaSituacao(item.ds_nome)}
                                                                style={{strokeWidth: "22"}}
                                                            />
                                                        </div>
                                                        <div className="fonte-cor-1 barra-numeros">
                                                            <label className="numeros-situacao0"> 0</label>
                                                            <label className="numeros-situacao"> 1</label>
                                                            <label className="numeros-situacao"> 2</label>
                                                            <label className="numeros-situacao"> 3</label>
                                                            <label className="numeros-situacao"> 4</label>
                                                            <label className="numeros-situacao"> 5</label>
                                                            <label className="numeros-situacao"> 6</label>
                                                            <label className="numeros-situacao"> 7</label>
                                                            <label className="numeros-situacao"> 8</label>
                                                            <label className="numeros-situacao"> 9</label>
                                                            <label className="numeros-situacao10">10</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                </Form.Group>
                            </div>
                        }
                    </>
                );
            }))
        }
        renderGrupos()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [revelarEdicao, revelarSelectGrupo, revelarConfiguracao, revelarAnalise, renderizar])
    

    function BtnVisualizarGrupo(props) {
        let obj = { alt: 'olhoLigadoGrupo', src: olhoLigadoGrupo }
        if(props.editar) {
            obj = { alt: 'editar', src: editar }
        }
        return <Button className="visualizar-grupo" onClick={() => handleExpandirEdicao(props.item.cd_dsp_simcard_grupo)}> 
            <img className="bt-editar-grupo" alt={obj.alt} src={obj.src()}/>
        </Button>
    }

    function BtnApagarGrupo({ disabled, item }) {
        let props = { disabled }
        let imgObj = { alt: 'lixeiraVermelho', src: lixeiraVermelho }

        if(disabled) {
            imgObj = { alt: 'lixeiraBranco', src: lixeiraBranco }
        } else {
            props = { onClick: () => handleMensagemAtencao(false, item.cd_dsp_simcard_grupo) }
        }

        return <Button className="visualizar-grupo" {...props}> 
            <img className="bt-apagar-grupo" alt={imgObj.alt} src={imgObj.src()}/>
        </Button>
    }

    // function BtnExpandirConfiguracao({item}) {
    //     return <Button className="visualizar-grupo" onClick={() => handleExpandirConfiguracao(item.cd_dsp_simcard_grupo, item.ds_nome)}> 
    //         <img className="bt-configuracao-grupo" alt="configVerde" src={configVerde()}/>
    //     </Button>
    // }

    function BotaoFechar({onClick, myClass}) {
        return <Button className={`botao-fechar-janela ${myClass || ''}`} variant="danger" onClick={onClick || handleVoltar}><img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/></Button>
    }

    return(
        
        <Form ref={modal} onSubmit={handleSubmit()}>
            <div className="modal-configurar-grupo fundo-cor-7">
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <div className="container-cabecalho-tela">
                            <img className="icone-label-janela nao-selecionavel" src ={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela"> Configurar grupo </div>
                            </Form.Label>
                            <BotaoFechar onClick={handleCancelar} />
                        </div>
                        <div className="grupos-mapeados"> {grupos.length!==0?grupos:<></>} </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button className="cadastrar-grupo"> <img className="bt-adicionar-grupo" alt="Novo Grupo" onClick={()=> handleNovoGrupo()}  src={adicionarMaisVerde()}/></Button>
                        
                        <Button id="btnCadastrar" type="submit" className="botao-confirmar" variant="success" size="sm" onClick={()=> handleAlterar()}>
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="danger" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso!"}
                            mensagemParagrafo1={mensagemSucesso}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemSucessoNovoGrupo &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucessoNovoGrupo}
                            mensagemTitulo={"Sucesso!"}
                            mensagemParagrafo1={mensagemSucesso}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={()=> handleMensagemAtencao(true)}
                            mensagemTitulo={"Remover grupo?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={mensagemErro}
                            mensagemParagrafo1={"Algo deu errado!"}
                            mensagemParagrafo2={"Por favor, tente novamente."}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}

const cadastrarGrupoSimcard = (body) => api.post('/m1/cadastrar/simcard_grupo', body);

const alterarGrupoSimcard = (body) => api.post('/m1/alterar/simcard_grupo', body);

const deletarGrupoSimcard = (cd_dsp_simcard_grupo) => api.get('/m1/deletar/simcard_grupo?cd_dsp_simcard_grupo='+ cd_dsp_simcard_grupo);

function girarImagem(imagemId){
    let imagem = document.getElementById(imagemId);
    let imagemClasses = document.querySelectorAll('.drop-acoes-menu-icone-desativado');
    let classes =  document.querySelectorAll('.dropdown-ativado-filtros');
    if(imagem.classList.contains("drop-acoes-menu-icone-desativado")){
        // if(classes.length > 0){
        //     imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-desativado"));
        //     imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-ativado"));
        // }
        imagem.classList.add("drop-acoes-menu-icone-ativado")
        imagem.classList.remove("drop-acoes-menu-icone-desativado")
    }
    // se o filtro estiver ativado e for clicado em cima do mesmo filtro ele fecha
    else if(imagem.classList.contains("drop-acoes-menu-icone-ativado")){

        if(classes.length > 0){
            classes.forEach(item => item.classList.add("dropdown-desativado-filtros"));
            classes.forEach(item => item.classList.remove("dropdown-ativado-filtros"));

            imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-desativado"));
            imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-ativado"));
        }

        imagem.classList.add("drop-acoes-menu-icone-desativado")
        imagem.classList.remove("drop-acoes-menu-icone-ativado")
    }

    if(imagem.classList.contains("drop-acoes-menu-icone-ativado")){

        if(classes.length > 0){
            classes.forEach(item => item.classList.add("dropdown-desativado-filtros"));
            classes.forEach(item => item.classList.remove("dropdown-ativado-filtros"));

            imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-desativado"));
            imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-ativado"));
        }
        classes.forEach(item => item.classList.add("dropdown-desativado-filtros"));
        classes.forEach(item => item.classList.remove("dropdown-ativado-filtros"));
    }
}