import React, { useState, useEffect } from "react";/*eslint-disable*/
import {api} from '../../conexoes/api';/*eslint-disable*/
import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";
import { setaDropdown } from '../../utils/alternanciaIcones';
import Calendario from '../calendario/calendario'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem, maximoTagsExibidas} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltroCentralMensagens(props) {
    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState('1990')
    const [dataMax, setDataMax] = useState(new Date())
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroDataInicio, setFiltroDataInicio] = useState('')
    const [filtroDataFinal, setFiltroDataFinal] = useState('')
    const [filtroNivel, setFiltroNivel] = useState('')
    const [filtroFormaEnvio, setFiltroFormaEnvio] = useState('')
    const [filtroStatus, setFiltroStatus] = useState('')
    const [itensEvento, setItensEvento] = useState([])

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            if(filtros.status === "3") setFiltroStatus('Enviado')
            if(filtros.status === "2") setFiltroStatus('Parcialmente enviado')
            if(filtros.status === "1") setFiltroStatus('Não enviado')
            if(filtros.tipo === "Email") setFiltroFormaEnvio('E-mail')
            if(filtros.tipo === "Push") setFiltroFormaEnvio('Notificação')
            if(filtros.tipo === "Plataforma") setFiltroFormaEnvio('Plataforma')
            if(filtros.nivel === "1") setFiltroNivel('Normal')
            if(filtros.nivel === "2") setFiltroNivel('Atenção')
            if(filtros.nivel === "3") setFiltroNivel('Urgente')
            if(filtros.createdAtInicio !='' && filtros.createdAtInicio){
                let auxData = new Date(filtros.createdAtInicio)
                auxData.setDate(auxData.getDate()+1)
                setFiltroDataInicio(auxData)
            }
            if(filtros.createdAtFim != '' && filtros.createdAtFim){
                let auxData = new Date(filtros.createdAtFim)
                auxData.setDate(auxData.getDate()+1)
                setFiltroDataFinal(auxData)
            }
        }
    }

    useEffect(() => {
        hendlerGetdadosFiltros()

        handlerCarregarEventos()        
    }, []);

    async function handlerCarregarEventos(){
        await api.get("/m1/consultar/aut_evento")
            .then((response)=>{
                if(response.data.status == 200){
                    setItensEvento(response.data.dados)
                }
            })
    }

    function handleFuncaoEstadosRedux(){
        ; ColetaClickstream(1407) /*centralMensagensTabelaDropFiltrosFiltrar*/
        if(filtroStatus !== '' && filtroStatus)arrayFiltrosSimcard.push("Status: "+filtroStatus+"?status")
        if(filtroFormaEnvio !== '' && filtroFormaEnvio)arrayFiltrosSimcard.push("Forma de envio: "+filtroFormaEnvio+"?tipo")
        if(filtroNivel !== '' && filtroNivel)arrayFiltrosSimcard.push("Nivel: "+filtroNivel+"?nivel")
        if(filtroDataInicio !== '' && filtroDataFinal !== '' && filtroDataInicio && filtroDataFinal)
            arrayFiltrosSimcard.push("Período entre: "+moment(filtroDataInicio).format("DD-MM-YYYY")+" e "+moment(filtroDataFinal).format("DD-MM-YYYY")+"?createdAtInicio")
        
        despacho(maximoTagsExibidas(props.setRevelarDropFiltros))
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroDataInicio == '') verificador.push(1)
            if(filtroDataFinal == '') verificador.push(1)
            if(filtroStatus == '') verificador.push(1)
            if(filtroFormaEnvio == '') verificador.push(1)
            if(filtroNivel == '') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 5) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroDataInicio('')
            setFiltroDataFinal('')
            setFiltroStatus('')
            setFiltroFormaEnvio('')
            setFiltroNivel('')
            props.setFoiLimpado(false)
        }
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroStatus,filtroFormaEnvio,filtroNivel,filtroDataFinal, filtroDataInicio,props.foiLimpado]);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }
    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-data","imagem-filtro-data"); ColetaClickstream(1403) /*centralMensagensTabelaDropFiltrosPeriodo*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Período </Form.Label>
                        <img id={"imagem-filtro-data"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="createdAtInicio" type="text" value={filtroDataInicio ? moment(filtroDataInicio).format("YYYY-MM-DD HH:mm:ss") : filtroDataInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataInicio} setData={setFiltroDataInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="createdAtFim" type="text" value={filtroDataFinal? moment(filtroDataFinal).format("YYYY-MM-DD HH:mm:ss") : filtroDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataFinal} setData={setFiltroDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-nivel","imagem-filtro-nivel"); ColetaClickstream(1404) /*centralMensagensTabelaDropFiltrosNivel*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Nível </Form.Label>
                        <img id={"imagem-filtro-nivel"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-nivel"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros" onChange={() => setFiltroNivel("Normal")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Normal<div className="filtro-automacao-importancia-esfera-verde"/></div>
                            <input defaultChecked={handlerCheck("nivel","3")} type="radio" id="NIVEL_NORMAL" name="nivel" value="3"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroNivel("Atenção")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Atenção<div className="filtro-automacao-importancia-esfera-amarela"/></div>
                            <input defaultChecked={handlerCheck("nivel","2")} type="radio" id="NIVEL_ATENCAO" name="nivel" value="2"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroNivel("Urgente")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Urgente<div className="filtro-automacao-importancia-esfera-vermelha"/></div>
                            <input defaultChecked={handlerCheck("nivel","1")} type="radio" id="NIVEL_URGENTE" name="nivel" value="1"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-forma-envio","imagem-filtro-forma-envio"); ColetaClickstream(1405) /*centralMensagensTabelaDropFiltrosFormaDeEnvio*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Forma de envio </Form.Label>
                        <img id={"imagem-filtro-forma-envio"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-forma-envio"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroFormaEnvio("E-mail")}>E-mail
                            <input defaultChecked={handlerCheck("tipo","Email")} type="radio" id="FORMA_ENVIO_EMAIL" name="tipo" value="Email"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroFormaEnvio("Notificação")}>Notificação
                            <input defaultChecked={handlerCheck("tipo","Push")} type="radio" id="FORMA_ENVIO_NOTIFICACAO" name="tipo" value="Push"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroFormaEnvio("Plataforma")}>Plataforma
                            <input defaultChecked={handlerCheck("tipo","Plataforma")} type="radio" id="FORMA_ENVIO_PLATAFORMA" name="tipo" value="Plataforma"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-status","imagem-filtro-status"); ColetaClickstream(1406) /*centralMensagensTabelaDropFiltrosSatus*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Satus </Form.Label>
                        <img id={"imagem-filtro-status"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroStatus("Enviado")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Enviado<div className="filtro-central-mensagens-importancia-esfera-verde"/></div>
                            <input defaultChecked={handlerCheck("status","3")} type="radio" id="STATUS_ENVIADO" name="status" value="3"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroStatus("Parcialmente enviado")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Parc. enviado<div className="filtro-central-mensagens-importancia-esfera-amarela"/></div>
                            <input defaultChecked={handlerCheck("status","2")} type="radio" id="STATUS_PARCIAL" name="status" value="2"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroStatus("Não enviado")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Não enviado<div className="filtro-central-mensagens-importancia-esfera-vermelha"/></div>
                            <input defaultChecked={handlerCheck("status","1")} type="radio" id="STATUS_NAO_ENVIADO" name="status" value="1"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}