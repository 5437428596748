
//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import moment from 'moment';
// ICONES
import { elipseVerde, fecharJanela, setaDropdown } from '../../../../utils/alternanciaIcones'
// MODAIS
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import MensagemInformacao from '../../../componentesModais/mensagens/mensagemInformacao'
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao';
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
import SelectGenericoCheck from '../../../componentesModais/selects/selectGenericoCheckbox'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
// COMPONENTES 
import Calendario from '../../../calendario/calendario'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarRelatorioEnvioSms } from "../../../../redux/actions/index";
import decryptJWT from '../../../../utils/decryptJWT';


export default function RelatorioEnvioSms(props){
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit } = useForm();
    //HOOKS MODAIS
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false);
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false);
    //OUTROS HOOKS
    const modal = useRef();
    const despacho = useDispatch();

    const [revelarSelectTipoFormato, setRevelarSelectTipoFormato] = useState(false);
    const cd_pav_usuario = decryptJWT('codigo')
    
    const [cdTipoFormato, setCdTipoFormato] = useState()
    const [dsTipoFormato, setDsTipoFormato] = useState('Selecione')
    const [dummy, setDummy] = useState('')
    const [inicioCiclo, setInicioCiclo] = useState("")
    const [fimCiclo, setFimCiclo] = useState("")
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState()
    const [cdCliente, setCdCliente] = useState('');
    const [dsCliente, setDsCliente] = useState('Selecione');




    const [cdStatus, setCdStatus] = useState()
    const [dsStatus, setDsStatus] = useState('Selecione')
    const [revelarSelectStatus, setRevelarSelectStatus] = useState(false);
    const [arrayCdStatus,setArrayCdStatus] = useState([])
    const [arrayDsStatus,setArrayDsStatus] = useState([])
    const [arrayDummy,setArrayDummy] = useState([])


//    const [contractId, setContractId] = useState(localStorage.getItem("codigoClienteErpAntigo"));

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function handleSalvar() {
        setSpinAtivo(true)
        let bodyRelatorio = {};

        bodyRelatorio = {
            descricao: 'Relatório de Envio de SMS',
            tipo_arquivo: dsTipoFormato,
            tipo_relatorio: 28,
            codigo_cliente: decryptJWT('codigoCliente'),
            codigo_usuario: decryptJWT('codigo'),
            cd_status: arrayCdStatus,
            ds_status: arrayDsStatus,
            dt_inicio: moment(inicioCiclo).format("YYYY-MM-DD HH:mm:ss"),
            dt_fim: moment(fimCiclo).format("YYYY-MM-DD HH:mm:ss"),
            data_criacao: moment().format('DD/MM/YYYY - HH:mm'),
        }

        if (bodyRelatorio.dt_inicio == bodyRelatorio.dt_fim)
        {
            let [dt_fim_data,dt_fim_hora] = bodyRelatorio.dt_fim.split(" ");
        
            bodyRelatorio.dt_fim =  `${dt_fim_data} 23:59:59`;
        }

        api.post('/m1/cadastrar/relatorios', bodyRelatorio);
        setSpinAtivo(false);
        setRevelarMensagemInformacao(true);

    }

    function handleCancelar() {
        despacho(revelarRelatorioEnvioSms(false));
        setRevelarMensagemInformacao(false);
        setRevelarMensagemErro(false);
    }


    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true);
        } else {
            setRevelarMensagemErro(false)
            if (confirmacao === true) {
                handleCancelar();
            }
        }
    }

    function handleMensagemInformacao() {
        if (revelarMensagemInformacao === false) {
            setRevelarMensagemInformacao(true)
        } else {
            setRevelarMensagemInformacao(false)
            handleCancelar();
        }
    }

    useEffect(()=>{

        let placeholderAux = false
        arrayCdStatus.forEach(function(item, index){
            if(item === -1){
                placeholderAux = true
            }
        })
        if(placeholderAux === true){
            setDsStatus(arrayCdStatus.length - 1 + " Selecionados")
        }
        else if(arrayCdStatus.length === 1){
            setDsStatus(arrayDsStatus[0])
        }
        else if(arrayCdStatus.length === 0){
            setDsStatus("Selecione")
        }
        else{
            setDsStatus(arrayCdStatus.length + " Selecionados")
        }
    },[JSON.stringify(arrayCdStatus)])

    return (
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
            <div className="modal-editar-fatura fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse" />
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Relatório de Envio SMS</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela" />
                        </Button>
                    </Col>
                </Form.Row>

                <div className="container-tipo-relatorio-1 form-row">
                    <Form.Label>
                        <div className="fonte-cor-1 label-campo">Status</div>
                    </Form.Label>
                    {revelarSelectStatus === false &&
                        <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectStatus(!revelarSelectStatus)}>
                            <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsStatus}</div>
                            <img className={revelarSelectStatus?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                        </Button>
                    }
                    {revelarSelectStatus === true &&
                        <SelectGenericoCheck
                            paramRota={"sms_status"}
                            paramCampo1={cd_pav_usuario}
                            paramCampo2={decryptJWT('codigoCliente')}
                            paramCampo3={null}
                            paramCampo4={null}
                            arrayCodigo={arrayCdStatus}
                            arrayDescricao1={arrayDsStatus}
                            arrayDescricao2={arrayDummy}
                            arrayDescricao3={arrayDummy}
                            arrayDescricao4={arrayDummy}
                            setRevelarSelect={setRevelarSelectStatus}
                            placeholder={dsStatus}
                            sufixo={""}
                        />
                    }
                </div>

                <Form.Row className="container-tipo-relatorio">
                            <Col>
                            <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data inicio</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={inicioCiclo} setData={setInicioCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="container-campo-texto-2">
                                        <div className="container-data-calendario">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Data fim</div>
                                                </Form.Label>
                                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={fimCiclo} setData={setFimCiclo} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="container-campo-texto-2">
                                        {/* DIV COLOCADA SO PARA FICAR CENTRALIZADO OS CAMPOS NO FRONT */}
                                    </div>
                                </div>
                            </Col>
                </Form.Row>

                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                
                <Form.Row className="container-tipo-relatorio">
                            <Col>
                                <div className="cointaner-principal-campo-texto-2">
                                    <div className="container-campo-texto-2">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Formato do arquivo</div>
                                        </Form.Label>
                                        {revelarSelectTipoFormato === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoFormato(!revelarSelectTipoFormato)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsTipoFormato}</div>
                                                <img className={revelarSelectTipoFormato?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectTipoFormato === true &&
                                            <SelectGenerico
                                            paramRota={"formato_relatorios"}
                                            paramCampo1={cd_pav_usuario}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdTipoFormato}
                                            setDescricao1={setDsTipoFormato}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectTipoFormato}
                                            placeholder={dsTipoFormato}
                                            sufixo={""}
                                        >
                                        </SelectGenerico>
                                        }
                                    </div>
                                </div>
                            </Col>
                </Form.Row>

                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-relatorios"/>
                
                <Form.Row className="container-botoes-cancelar-confirmar">
                    <Col>
                        <>
                            {(dsStatus !="Selecione" && dsTipoFormato != "Selecione") ?
                            <Button /*onClick={()=>ColetaClickstream(1193) gerarRelatorioExportarRelatorio}*/ type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">Exportar Relatório</div>
                                </Button>
                            :
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">Exportar Relatório</div>
                                </Button>
                            }
                        </>
                    </Col>
                </Form.Row>

            </div>
            {revelarMensagemInformacao &&
                <div id="mensagem-relatorio" className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"O arquivo está sendo gerado"}
                            mensagemParagrafo1={"Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível"}
                            mensagemBotao={"Voltar"}
                            marginTop={80}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}