
//IMPORTAÇÕES
import React, { useRef } from 'react';/*eslint-disable*/
import '../../css/spinnerVeye/spinnerVeye.css'

export default function SpinnerVeye() {
    
    const modal = useRef();

    return(
        <div className="confirmacao-overlay">
            <div className="container-confirmacao" ref={modal}>
            <svg xmlns="http://www.w3.org/2000/svg" className="svg-spinnner-veye" viewBox="0 0 1776.742 1446.15">
            <g id="Grupo_25" data-name="Grupo 25" transform="translate(172.137 -241.278)">
              <path className="elementos-spinner elemento-spinner-1" id="Caminho_15" data-name="Caminho 15" d="M518.989,720.211c-170.221-51.7-369.617-99.367-571.233-119.251a49.393,49.393,0,0,0-37.867,12.585l-62.467,56.424c-16.43,14.842-21.412,39.065-11.221,58.761,36.5,70.551,82.022,139.039,131.677,203.6C199.5,1233.483,526.159,1445.479,889.02,1555.677c170.22,51.694,369.612,99.366,571.227,119.25a49.4,49.4,0,0,0,37.866-12.585l62.467-56.424c16.432-14.843,21.414-39.068,11.223-58.766-36.5-70.55-82.023-139.035-131.675-203.594C1208.509,1042.4,881.847,830.409,518.989,720.211Z" transform="translate(0 9.231)" fill="none" stroke="#00d994" strokeMiterlimit="10" strokeWidth="6"/>
              <path className="elementos-spinner elemento-spinner-2" id="Caminho_16" data-name="Caminho 16" d="M620.653,640.744c-157.179-47.606-341.3-91.5-527.477-109.788a45.69,45.69,0,0,0-34.971,11.6l-57.694,52c-15.175,13.678-19.779,36-10.373,54.149C23.836,713.718,65.858,776.819,111.7,836.3c213.834,277.466,515.45,472.76,850.508,574.242,157.179,47.606,341.3,91.5,527.472,109.788a45.682,45.682,0,0,0,34.97-11.6l57.694-52c15.176-13.68,19.781-36,10.373-54.154-33.7-65-75.718-128.1-121.558-187.585C1257.325,937.521,955.71,742.227,620.653,640.744Z" transform="translate(3.964 7.434)" fill="none" stroke="#00d994" strokeMiterlimit="10" strokeWidth="6"/>
              <path className="elementos-spinner elemento-spinner-3" id="Caminho_17" data-name="Caminho 17" d="M715.755,564.385c-139.913-42.483-303.806-81.659-469.525-98A40.6,40.6,0,0,0,215.1,476.732L163.759,523.1c-13.505,12.2-17.6,32.1-9.224,48.292,30,57.981,67.417,114.265,108.23,167.321,190.377,247.494,458.876,421.715,757.128,512.275,139.911,42.482,303.8,81.657,469.519,98a40.6,40.6,0,0,0,31.125-10.343l51.346-46.371c13.507-12.2,17.6-32.108,9.225-48.3-30-57.98-67.417-114.261-108.228-167.317C1282.5,829.166,1014,654.945,715.755,564.385Z" transform="translate(8.199 5.776)" fill="none" stroke="#00d994" strokeMiterlimit="10" strokeWidth="6"/>
              <path className="elementos-spinner elemento-spinner-4" id="Caminho_18" data-name="Caminho 18" d="M795.923,490.959c-126.627-38.444-274.957-73.9-424.941-88.68a36.748,36.748,0,0,0-28.169,9.36L296.342,453.6c-12.223,11.038-15.929,29.053-8.349,43.7,27.154,52.471,61.015,103.4,97.953,151.42,172.3,223.972,415.3,381.633,685.231,463.584,126.626,38.444,274.954,73.9,424.936,88.681a36.749,36.749,0,0,0,28.169-9.362l46.471-41.964c12.225-11.038,15.931-29.056,8.35-43.705-27.154-52.47-61.015-103.4-97.95-151.416C1308.854,730.572,1065.852,572.91,795.923,490.959Z" transform="translate(11.637 4.13)" fill="none" stroke="#00d994" strokeMiterlimit="10" strokeWidth="6"/>
              <path className="circulo-spinner" id="Caminho_19" data-name="Caminho 19" d="M1412.32,443.735c56.217,0,101.79-45.324,101.79-101.232s-45.573-101.225-101.79-101.225S1310.53,286.6,1310.53,342.5s45.572,101.232,101.79,101.232" transform="translate(37.998 0)" fill="#36d896"/>
            </g>
          </svg>
            </div>
        </div>
    )
}