import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import {Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment'
import {Step, StepLabel, Stepper} from "@mui/material";
//REDUX
import { useDispatch, useSelector } from 'react-redux';

import {revelarNovoPedidoSimcard, mudarQtdSimcardPedido} from "../../../../redux/actions/index";
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import decryptJWT from '../../../../utils/decryptJWT'
// ICONES
import { elipseVerde, fecharJanela, lixeiraVermelho, setaDropdown } from '../../../../utils/alternanciaIcones'
// MODAIS
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import SelectFormaEnvio from '../../../componentesModais/selects/selectFormaEnvio'
import SelectTipoEndereco from '../../../componentesModais/selects/selectTipoEndereco'
import SelectUf from '../../../componentesModais/selects/selectUf2'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'


// COLETA USABILIDADE
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'
import { AvisoCampoObrigatorio, iconeAlerta, RenderInputRows } from '../../../adicionarSimcards/RenderInputs.js';
import BotoesDadosEnvio from '../../../dadosDeEnvio/BotoesDadosEnvio';

let atualizarParent = false;

let itensTemp = []

export default function NovoTemplate(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    // HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    const [cdOperadora, setCdOperadora] = useState(-1)
    const [dsOperadora, setDsOperadora] = useState('Selecione')
    const [cdNovoPlano, setCdNovoPlano] = useState(-1)
    const [dsNovoPlano, setDsNovoPlano] = useState('Selecione')
    const [cdTamanhoSimcard, setCdTamanhoSimcard] = useState(-1)
    const [dsTamanhoSimcard, setDsTamanhoSimcard] = useState('Selecione')
    const [revelarSelectTamanhoSimcard, setRevelarSelectTamanhoSimcard] = useState(false)
    const [dsQuantidade, setDsQuantidade] = useState(0)
    const [itens, setItens] = useState([])
    const [dsResponsavel, setDsResponsavel] = useState('')
    const [cdFormaEnvio, setCdFormaEnvio] = useState(-1)
    const [dsFormaEnvio, setDsFormaEnvio] = useState('Selecione')
    const [revelarSelectFormaEnvio, setRevelarSelectFormaEnvio] = useState(false)
    const [cdTipoEndereco, setCdTipoEndereco] = useState(-1)
    const [dsTipoEndereco, setDsTipoEndereco] = useState('Selecione')
    const [revelarSelectTipoEndereco, setRevelarSelectTipoEndereco] = useState(false)
    const [dsCep, setDsCep] = useState('')
    const [dsCepValidador, setDsCepValidador] = useState(false)
    const [dsCepErroInfo, setDsCepErroInfo] = useState('')
    const [dsEndereco, setDsEndereco] = useState('')
    const [dsNumero, setDsNumero] = useState('')
    const [dsBairro, setDsBairro] = useState('')
    const [dsCidade, setDsCidade] = useState('')
    const [cdEstado, setCdEstado] = useState(-1)
    const [dsNomeFantasia, setDsNomeFantasia] = useState('')
    const [dsOutroEnvio, setdsOutroEnvio] = useState('')
    const [dsEstado, setDsEstado] = useState('Selecione')
    const [revelarSelectUf, setRevelarSelectUf] = useState(false)
    const [infoAdicionais, setInfoAdicionais] = useState('');

    const [erroOperadora, setErroOperadora] = useState(false);
    const [erroPlano, setErroPlano] = useState(false);
    const [erroTamanho, setErroTamanho] = useState(false);
    const [errpQuantidade, setErrpQuantidade] = useState(false);
    //HOOKS MODAIS
    const [pedidos, setPedidos] = useState([]);
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(0)
    const [revelarMensagemSucessoEdicao, setRevelarMensagemSucessoEdicao] = useState(false)
    const [revelarMensagemErroEdicao, setRevelarMensagemErroEdicao] = useState(false)
    const [revelarMensagemErroAdicao, setRevelarMensagemErroAdicao] = useState(false)
    const [revelarMensagemSucessoAdicao, setRevelarMensagemSucessoAdicao] =useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    //OUTROS HOOKS
    const [blEditarEndereco, setBlEditarEndereco] = useState(false)
    const [blAdicionarEndereco, setBlAdicionarEndereco] = useState(false)
    const [linhaRemover, setLinhaRemover] = useState('');
    const [clickFora, setClickFora] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const despacho = useDispatch();
    const modal = useRef();
    const [itensArray, setItensArray] = useState([{'cd_pedido': 1,'OPERADORA': '', "cd_dsp_operadora": '', 'PLANO': '', "cd_dsp_plano": '', 'TAMANHO': '','NUMERO_SIMCARDS': ''}])
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)
    //STEPPER
    const localTema = localStorage.getItem('tema')
    const [stepActive, setStepActive] = useState(0)
    const steps = ['Listagem de SIM Cards', 'Dados do envio'];
    const [dsTelefone, setDsTelefone] = useState('');
    const [erroTelefone, setErroTelefone] = useState(true);

    const mostrarBotaoAdicionarEndereco = blEditarEndereco === false && blAdicionarEndereco === false;
    const mostrarBotacaoCancelarAdicionarEndereco = blEditarEndereco === false && blAdicionarEndereco === true;

    const stepsStyle = {
        "& .Mui-active": {
            "& .MuiStepIcon-root": {
                color: '#33CC99',
                fontSize: "1.5rem !important"
            },
        },
        "& .Mui-completed": {
            "& .MuiStepIcon-root": {
                color: '#33CC99',
                fontSize: "1.5rem !important"
            },
        },
        "& .Mui-disabled": {
            "& .MuiStepIcon-root": {
                fontSize: "1.5rem !important"
            }
        },
    }
    const stepLabelStyleEscuro = {
        "& .MuiStepLabel-label":{
            color: "#FFF !important",
            "& .Mui-active": {
                color: "#FFF !important"
            }
        }
    }

    const regexTelefone = /^(?:\+?\d{1,3}\s?)?(\(?\d{2}\)?\s?)?\d{4,5}[-\s]?\d{4}$/;
    const validarTelefone = (telefone) => {
        if (!telefone) return 'Informe o telefone para contato.';
        if (!regexTelefone.test(telefone)) return 'Telefone inválido.';
        return '';
    };

    const handleTelefone = (e) => {
        setDsTelefone(e);
        const erro = validarTelefone(e);
        setErroTelefone(erro);
    };

    let qtdSimcardsPedido = useSelector(state => state.pedidosState.qtdSimcardsPedido);
    let bodyCancelamentoReposicao = useSelector(state => state.pedidosState.bodyCancelamentoComReposicao)

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(1091) // 'novoPedido'
            setColetaUsabilidade(false)
        }
    },[])


    function handleAdicionarItemPedido(){
        if(dsOperadora !== 'Selecione' && dsNovoPlano !== 'Selecione' && dsTamanhoSimcard !== 'Selecione' && dsQuantidade > 0 ){
            itens.push({'cd_pedido':(itens.length+1),'OPERADORA': dsOperadora, "cd_dsp_operadora": cdOperadora, 'PLANO': dsNovoPlano, "cd_dsp_plano":cdNovoPlano, 'TAMANHO': dsTamanhoSimcard,'NUMERO_SIMCARDS': dsQuantidade})
            setDsOperadora('Selecione')
            setCdOperadora(-1)
            setDsNovoPlano('Selecione')
            setCdNovoPlano(-1)
            setDsTamanhoSimcard('Selecione')
            setCdTamanhoSimcard(-1)
            setDsQuantidade(0)
            handleLinhasSelecionadas()
            //erro ao adicionar false
            setErroOperadora(false)
            setErroPlano(false)
            setErroTamanho(false)
            setErrpQuantidade(false)
        }
        else{
            if(dsOperadora == 'Selecione') setErroOperadora(true)
            else setErroOperadora(false)

            if(dsNovoPlano == 'Selecione') setErroPlano(true)
            else setErroPlano(false)

            if(dsTamanhoSimcard == 'Selecione') setErroTamanho(true)
            else setErroTamanho(false)

            if(dsQuantidade <= 0) setErrpQuantidade(true)
            else setErrpQuantidade(false)
        }
    }



    useEffect(() => {
        async function handleCarregarTabela(){
            setItens([])
            setDsQuantidade(qtdSimcardsPedido)
        }
        handleCarregarTabela()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    async function handleEnviar(){
        setSpinAtivo(true)
        const selecionouEnderecoCadastrado = cdTipoEndereco != -1;
        const cadastrandoEnderecoNovo = mostrarBotacaoCancelarAdicionarEndereco

        if(selecionouEnderecoCadastrado && !cadastrandoEnderecoNovo){
            let cadastroSolititacao = {data:{status:200,dados:[]}}
            if(props.reposicao && props.reposicao == 1){
                let body = bodyCancelamentoReposicao;
                body.itens= itens
                body.formaEnvio = dsFormaEnvio == 'Outro' ? dsOutroEnvio : dsFormaEnvio
                body.enderecoEntrega = dsEndereco
                body.responsavelRecebimento= dsResponsavel

                cadastroSolititacao = await api.post('/m1/cadastrar/solicitacao', body, {timeout: 50000})
            }
            if(cadastroSolititacao.data.status == 200){
                await api.post('/m1/cadastrar/pedido_cliente ', {
                    "ds_nome": dsResponsavel,
                    "ds_endereco": dsEndereco,
                    "ds_complemento": infoAdicionais,
                    "ds_bairro": dsBairro,
                    "ds_cidade": dsCidade,
                    "ds_pav_uf": dsEstado,
                    "ds_cep": dsCep,
                    "ds_numero": dsNumero,
                    "ds_forma_envio": dsFormaEnvio == 'Outro' ? dsOutroEnvio : dsFormaEnvio,
                    "reposicao":props.reposicao && props.reposicao == 1 ? true : false,
                    "dt_data_hora": moment().format('YYYY-MM-DD hh:mm:ss'),
                    "simcardLista": itensArray,
                    "cd_dsp_cliente_endereco_entrega":cdTipoEndereco,
                    "dsTelefone" : dsTelefone
                })
                .then(function (response) {
                    atualizarParent = true;
                    setRevelarMensagemSucesso(true)
                    setSpinAtivo(false)
                })
                .catch(function (error) {

                    setMensagemErro(error.response.data.info)
                    setRevelarMensagemErro(true)
                    setSpinAtivo(false)
                })
            }
            else{
                setMensagemErro('Houve um erro ao processar o seu pedido, tente novamente. Se o problema persistir entre em contato com o suporte')
                setRevelarMensagemErro(true)
                setSpinAtivo(false)
            }
        }
        else{
            await api.post('/m1/cadastrar/cliente_endereco_entrega', {
                "ds_nome":dsResponsavel,
                "cd_pav_cliente":decryptJWT('codigoCliente'),
                "ds_numero":dsNumero,
                "ds_endereco":dsEndereco,
                "ds_cep":dsCep,
                "cd_pav_uf":cdEstado,
                "ds_complemento":infoAdicionais,
                "ds_bairro":dsBairro,
                "ds_cidade":dsCidade,
                "fl_considerar_busca":0
            })
            .then(async function (resposta) {
                let cadastroSolititacao = {data:{status:200,dados:[]}}
                if(props.reposicao && props.reposicao == 1){
                    let body = bodyCancelamentoReposicao;
                    body.itens= itens
                    body.formaEnvio = dsFormaEnvio == 'Outro' ? dsOutroEnvio : dsFormaEnvio
                    body.enderecoEntrega = dsEndereco
                    body.responsavelRecebimento= dsResponsavel

                    cadastroSolititacao = await api.post('/m1/cadastrar/solicitacao', body, {timeout: 20000})
                }
                if(cadastroSolititacao.data.status == 200){
                    await api.post('/m1/cadastrar/pedido_cliente ', {
                        "n0_codigo_cliente": decryptJWT('codigoClienteErpAntigo'),
                        "ds_nome": dsResponsavel,
                        "ds_endereco": dsEndereco,
                        "ds_complemento": infoAdicionais,
                        "ds_bairro": dsBairro,
                        "ds_cidade": dsCidade,
                        "ds_pav_uf": dsEstado,
                        "ds_cep": dsCep,
                        "ds_numero": dsNumero,
                        "ds_forma_envio": dsFormaEnvio == 'Outro' ? dsOutroEnvio : dsFormaEnvio,
                        "dt_data_hora": moment().format('YYYY-MM-DD hh:mm:ss'),
                        "simcardLista": itensArray,
                        "cd_pav_usuario": decryptJWT('codigo'),
                        "cd_pav_cliente": decryptJWT('codigoCliente'),
                        "reposicao":props.reposicao && props.reposicao == 1 ? true : false,
                        "cd_dsp_cliente_endereco_entrega":resposta.data.dados.idInserido,
                        "dsTelefone" : dsTelefone
                    })
                    .then(function (response) {
                        atualizarParent = true;
                        setRevelarMensagemSucesso(true)
                        setSpinAtivo(false)
                    })
                    .catch(function (error) {
                        setMensagemErro(error.response.data.info)
                        setRevelarMensagemErro(true)
                        setSpinAtivo(false)
                    })
                }
                else{
                    setMensagemErro('Erro SQL')
                    setRevelarMensagemErro(true)
                    setSpinAtivo(false)
                }

            })
            .catch(function (error) {
            })
        }
        despacho(mudarQtdSimcardPedido(0))
    }

    async function handlerConsultarCep(cep){
        const consulta = await api.get('/m1/consultar/cep?cep='+cep)
            .then(function (response) {
                if(response.data.status == 200){
                    if(response.data.dados == 'Cep Invalido'){
                        setDsCepValidador(true)
                        setDsCepErroInfo(response.data.dados)
                        setDsBairro('')
                        setDsCidade('')
                        setDsEstado('Selecione')
                        setCdEstado(-1)
                        setDsEndereco('')
                    }
                    else{
                        setDsCepValidador(false)
                        setDsCep(response.data.dados.cep)
                        setDsBairro(response.data.dados.bairro)
                        setDsCidade(response.data.dados.localidade)
                        setDsEstado(response.data.dados.uf)
                        setCdEstado(response.data.dados.cd_pav_uf)
                        setDsEndereco(response.data.dados.logradouro)
                    }
                }
                return response
            })
            .catch(function (error) {
                if(error == 'Error: Request failed with status code 400'){
                    setDsCepValidador(true)
                    setDsCepErroInfo("Cep Invalido")
                }
                else{
                    setDsCepValidador(true)
                    setDsCepErroInfo("Erro interno")
                }
                return error
            })
    }

    const colunas = [{
        dataField: 'cd_pedido',
        text: 'Cód. pedido',
        headerClasses: 'nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'OPERADORA',
        headerClasses: 'nao-selecionavel',
        text: 'Operadora',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'PLANO',
        headerClasses: 'nao-selecionavel',
        text: 'Plano',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'TAMANHO',
        headerClasses: 'nao-selecionavel',
        text: 'Tamanho SIM card',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'NUMERO_SIMCARDS',
        headerClasses: 'nao-selecionavel',
        text: 'Quantidade',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'acoes',
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        text: '',
        sort: false,
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.cd_pedido);
        }
    }

    function handleRemoverLinha(cd_pedido){
        ColetaClickstream(1092) // 'novoPedidoRemoverLinha'
        setRevelarMensagemAtencao(false)
        itensTemp = itens
        itens.forEach(function(e, index){
            if(cd_pedido == e.cd_pedido){
                itens.splice(index, 1);
            }
        })
        setItens(itensTemp)
        handleIdPedidos()
        handleLinhasSelecionadas()
    }

    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itens.length)
    }

    function handleIdPedidos(){
        let tempPedidos = []
        for (let i = 0; i < itensTemp.length; i++) {
            tempPedidos.push(itensTemp[i].cd_pedido)
        }
        setPedidos(tempPedidos)
    }

    async function handleAlterarEndereco(){


        if(dsEndereco === '' || dsNumero === '' || dsCep === '' || dsBairro === '' || dsCidade === '' || dsEstado === 'Selecione'){
            setMensagemErro('Preencha todos os campos obrigatórios')
            setRevelarMensagemErro(true)
            return
        }

        setSpinAtivo(true)
        await api.post('/m1/alterar/cliente_endereco_entrega', {
            "dados":{
                "ds_nome":dsResponsavel,
                "cd_pav_cliente":decryptJWT('codigoCliente'),
                "ds_numero":dsNumero,
                "ds_endereco":dsEndereco,
                "ds_cep":dsCep,
                "cd_pav_uf":cdEstado,
                "ds_complemento":infoAdicionais,
                "ds_bairro":dsBairro,
                "ds_cidade":dsCidade,
                "fl_considerar_busca":1
            },
            "condicao":{
                "cd_dsp_cliente_endereco_entrega":cdTipoEndereco
            }
        })
        .then(function (response) {
            setRevelarMensagemSucessoEdicao(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErroEdicao(true)
            setSpinAtivo(false)
        })
    }

    async function handleAdicionarEndereco(){
        let resposta = false;

        if(dsEndereco === '' || dsNumero === '' || dsCep === '' || dsBairro === '' || dsCidade === '' || dsEstado === 'Selecione'){
            setMensagemErro('Preencha todos os campos obrigatórios')
            setRevelarMensagemErro(true)
            return false;
        }

        setSpinAtivo(true)
        await api.post('/m1/cadastrar/cliente_endereco_entrega', {
            "ds_nome":dsResponsavel,
            "cd_pav_cliente":decryptJWT('codigoCliente'),
            "ds_numero":dsNumero,
            "ds_endereco":dsEndereco,
            "ds_cep":dsCep,
            "cd_pav_uf":cdEstado,
            "ds_complemento":infoAdicionais,
            "ds_bairro":dsBairro,
            "ds_cidade":dsCidade,
            "fl_considerar_busca":1
        })
        .then(function (response) {
            setRevelarMensagemSucessoAdicao(true)
            setSpinAtivo(false)
            resposta = true;
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErroAdicao(true)
            setSpinAtivo(false)
        })
        return resposta;
    }


    function handleConfirmarAdicionarEndereco(){
        setRevelarMensagemSucessoAdicao(true)
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(confirmacao === true ){
            handleRemoverLinha(linhaRemover)
        }
    }

    function handleCancelar(){
        ColetaClickstream(1093) // 'novoPedidoCancelar'
        if(atualizarParent === true){
            props.setRenderizar(!props.renderizar)
        }
        despacho(revelarNovoPedidoSimcard(false))
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucessoEdicao(confirmacao){
        if(revelarMensagemSucessoEdicao === false){
            setRevelarMensagemSucessoEdicao(true)
        }else{
            setRevelarMensagemSucessoEdicao(false)
            setBlEditarEndereco(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucessoAdicao(confirmacao){
        if(revelarMensagemSucessoAdicao === false){
            setRevelarMensagemSucessoAdicao(true)
        }else{
            setRevelarMensagemSucessoAdicao(false)
            setBlAdicionarEndereco(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleBlAdicionarEndereco(bl){
        setDsTipoEndereco('');
        setDsCep('')
        setDsEndereco('')
        setDsNumero('')
        setDsBairro('')
        setDsCidade('')
        setDsEstado('Selecione')
        setInfoAdicionais('')
        setBlAdicionarEndereco(bl)

        if(!bl) {
            setDsTipoEndereco('Selecione')
            setCdTipoEndereco(-1)
            setDsNomeFantasia('');
        }
    }

    function handlerAlterarQuantidade(e){
        if(e<=400){
            setDsQuantidade(e)
        }
    }


    // useEffect(()=>{
    //     document.getElementById("campo-texto-quantidade").onkeypress = function(e){
    //         // which é o CD da tecla apertada
    //         let caracter = String.fromCharCode(e.which);
    //         if ("1234567890".indexOf(caracter) < 0)
    //           return false;
    //       };
    //       //bloqueia o CRTV  V NO CAMPO DE TEXTO
    //     document.getElementById('campo-texto-quantidade').onpaste = function(){
    //         return false;
    //     }
    // },[])

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-cep"){
                setDsCep(valor)
            }
            if(id === "campo-texto-numero"){
                setDsNumero(valor)
            }
        }
    }

    function parsePedidosAdicionados() {
        let loteFinalizados = itensArray.filter(item => item.OPERADORA && item.PLANO && item.TAMANHO && item.NUMERO_SIMCARDS > 0);
        return loteFinalizados
    }

    function parseTotalLinhas() {
        let loteFinalizados = parsePedidosAdicionados();
        let totalLinhas = loteFinalizados.reduce((acc, item) => acc + parseInt(item.NUMERO_SIMCARDS), 0);
        return totalLinhas;
    }

    async function handleNextStep() {
        if(stepActive === 0) {
            let loteFinalizados = parsePedidosAdicionados();
            if(loteFinalizados.length === 0) {
                setMensagemErro('Um ou mais campos do pedido estão vazios, preencha-os para prosseguir');
                setRevelarMensagemErro(true);
                return;
            } else if (loteFinalizados.length != itensArray.length){
                setMensagemErro('Preencha todos os campos do pedido para prosseguir');
                setRevelarMensagemErro(true);
                return;
            }
        }
        if(stepActive === 1) {
            if(dsResponsavel === ''){
                setMensagemErro('O campo responsável é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if (erroTelefone) {
                setMensagemErro('O campo telefone para contato é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsFormaEnvio === 'Selecione'){
                setMensagemErro('O campo forma de envio é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsFormaEnvio === 'Outro' && dsOutroEnvio === '') {
                setMensagemErro('O campo forma de envio é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsEndereco === ''){
                setMensagemErro('O campo endereço é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsNumero === ''){
                setMensagemErro('O campo número é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsCep === ''){
                setMensagemErro('O campo CEP é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsBairro === ''){
                setMensagemErro('O campo bairro é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsCidade === ''){
                setMensagemErro('O campo cidade é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsEstado === 'Selecione'){
                setMensagemErro('O campo estado é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }

            else {
                handleEnviar();
            }
        }
        setStepActive(stepActive + 1);

    }

    function handlePreviousStep() {
        if(stepActive === 0){
            handleCancelar()
        } else {
            setStepActive(stepActive - 1);
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleEnviar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="modal-novo-pedido-new fundo-cor-6">
                <div className={'modal-novo-pedido-header'}>
                    <Form.Row>
                        <Col>
                            <div className={'header-novo-pedido-title'}>
                                <div className={'header-title-left-block'}>
                                    <img className="icone-label-janela" src={elipseVerde()} alt="elipse"/>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-janela">Novo pedido</div>
                                    </Form.Label>
                                </div>
                                <div>
                                    <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                                        <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                                    </Button>
                                </div>
                            </div>


                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Stepper activeStep={stepActive} sx={stepsStyle}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel sx={localTema === 'escuro' ? stepLabelStyleEscuro : {}}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Col>
                    </Form.Row>
                    <div className={'label-wrapper-novo-pedido'}>
                        <div className={'fonte-cor-1 label-listagem-SIMcard'}>
                            <div>{stepActive == 0 ? "Listagem de SIM Cards" : "Dados de Envio"}</div>
                        </div>
                    </div>
                </div>


                <div className={'modal-novo-pedido-content'}>
                    {
                        stepActive === 0 &&
                        <>
                            <div className={'lote-row-novo-pedido'}>

                                <div className={'label-row-novo-pedido'}>
                                    <div className={'fonte-cor-1'} style={{flex:0.1}}>#</div>
                                    <div className={'fonte-cor-1 label-title-novo-pedido'} style={{flex: 1.5}}>Operadora</div>
                                    <div className={'fonte-cor-1 label-title-novo-pedido'} style={{flex: 1.5}}>Plano</div>
                                    <div className={'fonte-cor-1 label-title-novo-pedido'} style={{flex: 1.3}}>Tamanho SIM Card</div>
                                    <div className={'fonte-cor-1 label-title-novo-pedido'} style={{flex: 1.2}}>Quantidade</div>
                                </div>

                                {itensArray.length > 0 &&
                                    <RenderInputRows
                                        {...{
                                            itens: itensArray,
                                            itensArray,
                                            setItensArray,
                                            setMensagemErro,
                                            setRevelarMensagemErro,
                                            mostrarBotaoNovoLote: stepActive == 0
                                        }}
                                    />
                                }

                                <div>

                                </div>

                            </div>
                        </>
                    }

                    {
                        stepActive === 1 &&
                        <>
                            <Form.Row>
                                <Col>
                                    <div className="grupo-painel-pedidos-novo-simcard-1 margin-top-divider">
                                        <div className="coluna-painel-pedidos-novo-simcard-6">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo label-novo-pedido-endereco">Responsável</div>
                                                </Form.Label>
                                                    <Form.Control name="ds_responsavel" value={dsResponsavel} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o responsavel" onChange={e => setDsResponsavel(e.target.value)}
                                                                  ref={register({ required: true, min: 3, max: 100 })} maxlength="100"/>
                                                {dsResponsavel === '' && <AvisoCampoObrigatorio />}
                                            </Form.Group>
                                        </div>
                                        <div className="coluna-painel-pedidos-novo-simcard-6">
                                        <Form.Group>
                                            <Form.Label>
                                                <div className="fonte-cor-1 label-campo label-novo-pedido-endereco">Telefone para Contato</div>
                                            </Form.Label>
                                            <Form.Control
                                                name="telefone_contato"
                                                type="text"
                                                className="campo-texto-cor-3 campo-texto-novo"
                                                value={dsTelefone}
                                                onChange={(e) => handleTelefone(e.target.value)}
                                                placeholder='Digite o telefone'
                                            />
                                            {erroTelefone && <AvisoCampoObrigatorio />}
                                        </Form.Group>
                                        </div>
                                        <div className="coluna-painel-pedidos-novo-simcard-7">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo label-novo-pedido-endereco">Forma de envio</div>
                                                </Form.Label>
                                                { revelarSelectFormaEnvio === false &&
                                                    <Button name="cdFormaEnvio" className="campo-texto-cor-3 campo-select-novo" onClick={() => setRevelarSelectFormaEnvio(!revelarSelectFormaEnvio)}>
                                                        <div className="fonte-cor-1 label-campo">{dsFormaEnvio}</div>
                                                        <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                                    </Button>
                                                }
                                                { revelarSelectFormaEnvio === true &&
                                                    <SelectFormaEnvio
                                                        setRevelarSelectFormaEnvio={setRevelarSelectFormaEnvio} //Hook para fechar a modal
                                                        styleClass={'campo-select-novo'} // Estilo do select
                                                        setCdFormaEnvio={setCdFormaEnvio} //Código do FormaEnvio
                                                        setDsFormaEnvio={setDsFormaEnvio} //Nome do FormaEnvio
                                                        dsFormaEnvio={dsFormaEnvio}
                                                        phFormaEnvio={dsFormaEnvio} //Placeholder para mostrar o valor original em caso de edição
                                                    >
                                                    </SelectFormaEnvio>
                                                }
                                                {dsFormaEnvio === 'Selecione' && <AvisoCampoObrigatorio />}
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Control hidden={dsFormaEnvio != 'Outro'} name="ds_outro" value={dsOutroEnvio} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite outra forma de envio" onChange={e => setdsOutroEnvio(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="coluna-painel-pedidos-novo-simcard-8">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo label-novo-pedido-endereco">Tipo de endereço</div>
                                                </Form.Label>
                                                { revelarSelectTipoEndereco === false  && blEditarEndereco === false && blAdicionarEndereco === false &&
                                                    <Button name="cdTipoEndereco" className="campo-texto-cor-3 campo-select-novo" onClick={() => setRevelarSelectTipoEndereco(!revelarSelectTipoEndereco)}>
                                                        <div className="fonte-cor-1 label-campo label-campo-select-overflow">{dsTipoEndereco + dsNomeFantasia}</div>
                                                        <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                                    </Button>
                                                }
                                                { revelarSelectTipoEndereco === false && (blEditarEndereco === true || blAdicionarEndereco === true) &&
                                                    <>
                                                            <Form.Control name="ds_endereco" value={dsTipoEndereco} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o nome do Endereço" onChange={e => setDsTipoEndereco(e.target.value)}
                                                                          ref={register({ required: true, minLength: 3, maxLength: 100 })}/>
                                                        {errors.ds_endereco && <AvisoCampoObrigatorio />}
                                                    </>
                                                }
                                                { revelarSelectTipoEndereco === true &&
                                                    <SelectTipoEndereco
                                                        setRevelarSelectTipoEndereco={setRevelarSelectTipoEndereco} //Hook para fechar a modal
                                                        setCdTipoEndereco={setCdTipoEndereco} //Código do TipoEndereco
                                                        styleClass={'campo-select-novo'} // Estilo do select
                                                        setDsTipoEndereco={setDsTipoEndereco} //Nome do TipoEndereco
                                                        dsTipoEndereco={dsTipoEndereco}
                                                        phTipoEndereco={dsTipoEndereco} //Placeholder para mostrar o valor original em caso de edição
                                                        setDsCep={setDsCep}
                                                        setDsEndereco={setDsEndereco}
                                                        setDsNumero={setDsNumero}
                                                        setDsBairro={setDsBairro}
                                                        setDsCidade={setDsCidade}
                                                        setDsEstado={setDsEstado}
                                                        setInfoAdicionais={setInfoAdicionais}
                                                        setCdEstado={setCdEstado}
                                                        setDsNomeFantasia={setDsNomeFantasia}
                                                        dsNomeFantasia={dsNomeFantasia}
                                                    >
                                                    </SelectTipoEndereco>
                                                }
                                            </Form.Group>

                                        </div>

                                    </div>
                                </Col>
                            </Form.Row>
                            { (cdTipoEndereco !== -1 || blEditarEndereco === true || blAdicionarEndereco === true) &&
                                <>

                                        <Form.Row>
                                            <Col>
                                                <div className="grupo-painel-pedidos-novo-simcard-1">
                                                    <div className="coluna-painel-pedidos-novo-simcard-10">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                <div className="fonte-cor-1 label-campo">Cep</div>
                                                            </Form.Label>
                                                                <Form.Control if="campo-texto-cep" name="ds_cep" value={dsCep} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o cep" onChange={e => validarNumero("campo-texto-cep", e.target.value)}
                                                                              ref={register({ required: true, min: 3, max: 9 })} onBlur={(e) => handlerConsultarCep(e.target.value)} maxlength="9" disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true }/>
                                                            {dsCepValidador && <p className="fonte-cor-3 label-alerta">{iconeAlerta} {dsCepValidador?dsCepErroInfo:"O campo é obrigatorio."}</p>}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="coluna-painel-pedidos-novo-simcard-11">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                <div className="fonte-cor-1 label-campo">Endereço</div>
                                                            </Form.Label>
                                                                <Form.Control name="ds_endereco" value={dsEndereco} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o nome da rua" onChange={e => setDsEndereco(e.target.value)}
                                                                              ref={register({ required: true, min: 3, max: 100 })} maxlength="100" disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true }/>
                                                            {dsEndereco === '' && <AvisoCampoObrigatorio />}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="coluna-painel-pedidos-novo-simcard-12">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                <div className="fonte-cor-1 label-campo">Número</div>
                                                            </Form.Label>
                                                                <Form.Control id="campo-texto-numero" name="ds_numero" value={dsNumero} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o nº" onChange={e => validarNumero("campo-texto-numero", e.target.value)}
                                                                              ref={register({ required: true, min: 3, max: 100 })} maxlength="4" disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true }/>
                                                            {dsNumero === '' && <AvisoCampoObrigatorio />}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col>
                                                <div className="grupo-painel-pedidos-novo-simcard-1">
                                                    <div className="coluna-painel-pedidos-novo-simcard-10">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                <div className="fonte-cor-1 label-campo">Bairro</div>
                                                            </Form.Label>
                                                                <Form.Control name="ds_bairro" value={dsBairro} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o bairro" onChange={e => setDsBairro(e.target.value)}
                                                                              ref={register({ required: true, min: 3, max: 100 })} maxlength="100" disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true }/>
                                                            {dsBairro === '' && <AvisoCampoObrigatorio />}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="coluna-painel-pedidos-novo-simcard-11">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                <div className="fonte-cor-1 label-campo">Cidade</div>
                                                            </Form.Label>
                                                              <Form.Control 
                                                                name="ds_cidade" value={dsCidade} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite a cidade" onChange={e => setDsCidade(e.target.value)}
                                                                ref={register({ required: true, min: 3, max: 100 })} maxlength="100" 
                                                                disabled={
                                                                  (errors.ds_cep || dsCepValidador || dsCep.length <= 8) 
                                                                  ? true 
                                                                  : !(blEditarEndereco || blAdicionarEndereco)
                                                                } />
                                                            {dsCidade === '' && <AvisoCampoObrigatorio />}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="coluna-painel-pedidos-novo-simcard-12">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                <div className="fonte-cor-1 label-campo">Estado</div>
                                                            </Form.Label>
                                                            { revelarSelectUf === false &&
                                                                <Button disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true } name="cdUf" className="campo-texto-cor-3 campo-select-novo" onClick={() => setRevelarSelectUf(!revelarSelectUf)}>
                                                                    <div className="fonte-cor-1 label-campo">{dsEstado}</div>
                                                                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                                                </Button>
                                                            }
                                                            { revelarSelectUf === true &&
                                                                <SelectUf
                                                                    setRevelarSelectUf={setRevelarSelectUf} //Hook para fechar a modal
                                                                    styleClass={'campo-select-novo'} // Estilo do select
                                                                    setCdUf={setCdEstado} //Código do Uf
                                                                    setDsUf={setDsEstado} //Nome do Uf
                                                                    dsUf={dsEstado}
                                                                    phUf={dsEstado} //Placeholder para mostrar o valor original em caso de edição
                                                                >
                                                                </SelectUf>
                                                            }
                                                            {cdEstado == -1 && <AvisoCampoObrigatorio />}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>
                                                        <div className="fonte-cor-1 label-campo">Informações adicionais (opcional)</div>
                                                    </Form.Label>
                                                        <Form.Control name="infoAdicionais" value={infoAdicionais} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Sala, apartamento, bloco, ponto de referência"
                                                                      onChange={e => setInfoAdicionais(e.target.value)} disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true }/>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>

                                </>
                            }

                            <BotoesDadosEnvio {...{
                                mostrarBotaoAdicionarEndereco,
                                mostrarBotacaoCancelarAdicionarEndereco,
                                handleBlAdicionarEndereco,
                            }} />
                        </>

                    }
                </div>


                <div className={'modal-footer-novo-pedido'}>

                    <Form.Row>
                        <Col>
                            <div className={'resumo-pedido-linha-disclaimer'}>
                                <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{parsePedidosAdicionados().length} pedido(s) adicionado(s)</span>
                                <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{parseTotalLinhas()} linha(s) adicionada(s) aos pedidos</span>
                            </div>

                        </Col>
                    </Form.Row>
                    {parsePedidosAdicionados().length > 0  ?
                        <Form.Row>
                            <Col>
                                <Button onClick={(e) => handleNextStep()} className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">{stepActive != 1 ? "Próximo" : "Confirmar"}</div>
                                </Button>
                                <Button className="botao-cancelar" variant="success" size="sm" onClick={(e) => handlePreviousStep()}>
                                    <div className="fonte-cor-2">{stepActive != 1 ? "Cancelar" : "Voltar"}</div>
                                </Button>
                            </Col>
                        </Form.Row>
                        :
                        <Form.Row>
                            <Col>
                                <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Finalize o detalhamento do pedido para avançar.</Tooltip>}>
                                    <Button className="botao-confirmar-desabilitado" variant="success" size="sm">
                                        <div className="fonte-cor-4">Próximo</div>
                                    </Button>
                                </OverlayTrigger>
                                <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                            </Col>
                        </Form.Row>
                    }

                </div>




            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Pedido efetuado com sucesso."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemSucessoEdicao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucessoEdicao}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Endereço alterado com sucesso."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }

            { revelarMensagemSucessoAdicao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucessoAdicao}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Endereço adicionado com sucesso."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErroAdicao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemSucesso={handleMensagemSucessoAdicao}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErroEdicao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemSucesso={handleMensagemSucessoAdicao}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover item do pedido?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}