const ESTADO_INICIAL = {
    atualizarMensagensCabecalho: false,
    revelarMensagemCabecalhoSelecionada: false,
    revelarNovaMensagem:false,
    mensagemCabecalhoSelecionada:undefined,
    revelarMensagemTabela:false,
    atualizarNotificacoesCabecalho: false,
    revelarAlertaCabecalhoSelecionada: false,
    alertaCabecalhoSelecionada:undefined,
    revelarAlertaTabela:false,
}

  export const mensagensReducer = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'ATUALIZAR_MENSAGENS_CABECALHO') {
        return {
            ...state,
            atualizarMensagensCabecalho : action.parametro
          };

    }
 
   else if (action.type === 'REVELAR_MENSAGEM_CABECALHO_SELECIONADA') {
        return {
            ...state,
            revelarMensagemCabecalhoSelecionada : action.parametro
          };

    }
    else if (action.type === 'ATUALIZAR_MENSAGEM_CABECALHO_SELECIONADA') {
        return {
            ...state,
            mensagemCabecalhoSelecionada : action.parametro
          };

    }
    else if (action.type === 'REVELAR_NOVA_MENSAGEM') {
      return {
          ...state,
          revelarNovaMensagem : action.parametro
      }
    }
    else if (action.type === 'REVELAR_MENSAGEM_TABELA') {
      return {
          ...state,
          revelarMensagemTabela : action.parametro
      };
  }

  else if (action.type === 'ATUALIZAR_ALERTAS_CABECALHO') {
    return {
        ...state,
        atualizarAlertasCabecalho : action.parametro
      };

}

  else if (action.type === 'REVELAR_ALERTA_CABECALHO_SELECIONADA') {
      return {
          ...state,
          revelarAlertaCabecalhoSelecionada : action.parametro
        };

  }
  else if (action.type === 'ATUALIZAR_ALERTA_CABECALHO_SELECIONADA') {
      return {
          ...state,
          alertaCabecalhoSelecionada : action.parametro
        };

  }
  else if (action.type === 'REVELAR_ALERTA_TABELA') {
    return {
        ...state,
        revelarAlertaTabela : action.parametro
      };

    }
      else{
          return state
      }

        
    }
