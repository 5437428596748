import React from 'react'
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import './BotoesDadosEnvio.css'

export default function BotoesDadosEnvio({
    mostrarBotaoAdicionarEndereco,
    mostrarBotacaoCancelarAdicionarEndereco,
    handleBlAdicionarEndereco,
}) {

    return (
        <div className="container-botoes-adicionar-endereco">
            {mostrarBotacaoCancelarAdicionarEndereco &&
                <DivGrupo>
                    <BotaoCancelar onClick={() => handleBlAdicionarEndereco(false)} />
                </DivGrupo>
            }

            {mostrarBotaoAdicionarEndereco &&
                <DivGrupo>
                    <BotaoAdicionarMaisVerde onClick={() => handleBlAdicionarEndereco(true)} />
                </DivGrupo>
            }
        </div>
    )
}

const DivGrupo = ({children}) => {
    return (
        <div classNam="grupo-painel-pedidos-novo-simcard-2">{children}</div>
    )
}

const BotaoAdicionarMaisVerde = ({onClick}) => {
    const temaEscuro = isTemaEscuro();

    return (
        <BaseButton startIcon={<AddIcon />} onClick={onClick} 
        textColor={temaEscuro ? "black" : "#243B53"}
        bgColor={temaEscuro ? "#36D896" : "white"}
        >
            ADICIONAR ENDEREÇO
        </BaseButton>
    )
}

const BotaoCancelar = ({onClick}) => {
    const temaEscuro = isTemaEscuro();
    const red1 = "#D32F2F";
    const red2 = "#a72727";
    const lightGray = "#ababab";

    return (
        <BaseButton startIcon={<DeleteIcon />} onClick={onClick}
            textColor={temaEscuro ? lightGray : red1}
            bgColor={temaEscuro ? red2 : "white"}
        >
            REMOVER ENDEREÇO
        </BaseButton>
    )
}

const BaseButton = ({ children, startIcon, onClick, textColor, bgColor }) => {
    return (
        <Button
            variant="contained"
            color="primary"
            className='botao-adicionar-endereco'
            style={{
                background: bgColor,
                color: textColor,
            }}
            disableRipple 
            startIcon={startIcon} 
            onClick={onClick}
        >
            {children}
        </Button>
    );
};

function isTemaEscuro() {
    const localTema = localStorage.getItem('tema')
    return (localTema === 'escuro');
}