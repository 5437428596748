import { useEffect, useState } from 'react';/*eslint-disable*/
import decryptJWT from '../utils/decryptJWT'

export const useDarkMode = () => {
  const [tema, setTema] = useState(localStorage.getItem('tema'));
  const [componentMounted, setComponentMounted] = useState(false);

  const cdCliente = decryptJWT('clientePrimario')

  const setModo = mode => {
    localStorage.setItem('tema', mode)
    setTema(mode)
  };

  const alterarModoEscuro = () => {
    if(cdCliente == 2455){
      if(tema === 'algarClaro') {
        setModo('algarEscuro')
      }else {
        setModo('algarClaro')
      }
    }else {
      if(tema === 'claro') {
        setModo('escuro')
      }else {
        setModo('claro')
      }
    }
  };

  useEffect(() => {
    let localTema = localStorage.getItem('tema');
    localTema = localTema ? localTema.toLowerCase() : 'claro';

    if(cdCliente == 2455){
      if(localTema.includes('escuro')){
        setModo('algarEscuro')
      }else{
        setModo('algarClaro')
      }
    }else{
      if(localTema.includes('escuro')){
        setModo('escuro')
      }else{
        setModo('claro')
      }
    }

    setComponentMounted(true);
  }, [cdCliente]);

  return [tema, alterarModoEscuro, componentMounted]
};
