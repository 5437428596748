//IMPORTAÇÕES
import React, {useRef, useState} from "react";
import { Button, Form } from "react-bootstrap";
// ICONES
import { setaDropdown } from "../../../utils/alternanciaIcones";
export default function SelectMotivoReposicao(props) {
  //HOOKS MODAL
  const modal = useRef();
    const [selectClass, setSelectClass] = useState(props.styleClass || 'campo-select');

  // eslint-disable-next-line
  let motivoCancelamento = {};
  
  function motivo(motivoCancelamento) {
    props.setInputRevelado(motivoCancelamento.motivo);
    props.handleMotivo(motivoCancelamento.motivo);
    props.setADE_CODCAT(motivoCancelamento.ADE_CODCAT)
    props.setADE_CODCAU(motivoCancelamento.ADE_CODCAU)
    handleCancelar();
  }

  function handleCancelar() {
    props.setRevelarSelectMotivo(false);
  }

  return (
    <Form ref={modal}>
      <Button
        name="motivoCancelamento"
        className={`campo-select-motivo-margin campo-texto-cor-3 ${selectClass}`}
        onClick={() => props.setRevelarSelectMotivo(false)}
      >
        <div className="fonte-cor-1 label-campo">{props.motivo}</div>
        <img
          className="campo-select-icone-ativado"
          src={setaDropdown()}
          alt="drop"
        />
      </Button>
      <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
      <div className="campo-texto-cor-3 campo-select-corpo">
        <ul className="campo-select-lista">
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => motivo(motivoCancelamento = {
                motivo: "Extravio",
                Z03_DTEFET: Date(),
                ADE_CODCAT: "00000001",
                ADE_CODCAU: "00000022",
              })}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Extravio
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => motivo(motivoCancelamento = {
                motivo: "Substiuir operadora",
                Z03_DTEFET: Date.now(),
                ADE_CODCAT: "00000001",
                ADE_CODCAU: "00000032",
              })}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Substituir operadora
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => motivo(motivoCancelamento = {
                motivo: "Sim card com defeito",
                Z03_DTEFET: Date.now(),
                ADE_CODCAT: "00000001",
                ADE_CODCAU: "00000011",
              })}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Sim card com defeito
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
        </ul>
      </div>
    </Form>
  );
}