//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';/*eslint-disable*/
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
  
export default function SelectOperadoraCheckbox(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [lsOperadoraCheckboxs, setLsOperadoraCheckboxs] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();
    let todosUsuarios = []

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleCancelar(){
        props.setRevelarSelectOperadoraCheckbox(false)
    }

    function handleOperadoraCheckbox(codigo, label, checkbox){
        if(codigo === -1){
            handleSelecionarTodos(checkbox)
        }
        else{
            if(checkbox === true){
                props.arrayCdOperadora.push({cd_dsp_operadora: codigo, ds_dsp_operadora: label})
            }else{
                props.arrayCdOperadora.forEach(function(e, index){
                    if(codigo == e.cd_dsp_operadora){
                        props.arrayCdOperadora.splice(index, 1);
                    }
                })
            }
        }
    }

    function handleSelecionarTodos(checked){
        const length = props.arrayCdOperadora.length
        let i =0
        while(i < length){
            props.arrayCdOperadora.pop()
            i++
        }
  
        if(checked === true){
            todosUsuarios.forEach(function(operadora,i){
                props.arrayCdOperadora.push({cd_dsp_operadora: operadora.cd_dsp_operadora, ds_dsp_operadora: operadora.ds_dsp_operadora})
        })
       }
    }

    function handleCancelar(){
        props.setRevelarSelectOperadoraCheckbox(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderOperadoraCheckboxs(){
            const {data} = await api.get('/m1/consultar/operadora');
            data.dados.unshift({"cd_dsp_operadora":-1,"ds_dsp_operadora":"Todos"})
            todosUsuarios=data.dados

            if(data.dados.length > 0){
                let objetoOperadoraCheckboxs = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.cd_dsp_operadora}>
                            <div className="campo-select-opcao-checkbox campo-select-opcao-1">
                                <label className="container-checkbox-item-select">
                                    <input id={item.cd_dsp_operadora} type="checkbox" defaultChecked={handleMarcarCheckboxes(item.cd_dsp_operadora) ? true : false} onChange={e => handleOperadoraCheckbox(item.cd_dsp_operadora, item.ds_dsp_operadora, e.target.checked)}/>
                                    <span className="chekmark-checkbox-item-select"></span>
                                </label>
                                <span className="fonte-cor-1 nao-selecionavel">{item.ds_dsp_operadora}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsOperadoraCheckboxs(objetoOperadoraCheckboxs)
            }else{
                let objetoOperadoraCheckboxs = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsOperadoraCheckboxs(objetoOperadoraCheckboxs)
            }

            setSpinAtivo(false)
        }
        renderOperadoraCheckboxs()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function handleMarcarCheckboxes(codigo){
        let verificacao = false
        props.arrayCdOperadora.forEach(function(e, index){
            if(codigo === e.cd_dsp_operadora){
                verificacao = true
            }
        })
        return verificacao
    }

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => handleCancelar()}>
                <div className="fonte-cor-1 label-campo">{props.dsOperadoraCheckbox}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo triangulo-select-absoluto"></div>
            <div className="campo-texto-cor-3 campo-select-perfis-checkbox-corpo">
                <ul className="campo-select-lista">
                    {lsOperadoraCheckboxs}
                </ul>
            </div>
        </Form>
    )
}