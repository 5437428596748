import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

import './checkbox.css';

export default function CheckboxContainer({name, label, valueChecked, isChecked, onChange}) {
    const defaultGreen = '#36D896';

    return (
        <FormControlLabel
            control={
                <Checkbox
                    value={valueChecked} // NÃO PODE ESTAR VAZIO, SENÃO VAI ENVIAR value='on'
                    name={name}
                    checked={isChecked}
                    onChange={onChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                        color: defaultGreen,
                        '&.Mui-checked': {
                            color: defaultGreen,
                        },
                    }}
                />
            }
            label={<CheckboxLabel>{label}</CheckboxLabel>}
            className={'checkbox-wrapper'}
        />
    )
}

function CheckboxLabel({children}) {
    return <div className={'checkbox-title fonte-cor-1'}>{children}</div>
}