//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import { useForm } from "react-hook-form";
import { Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
//ICONES
import {elipseVerde, fecharJanela, exclamacao, setaDropdown} from '../../../utils/alternanciaIcones'
//MODAIS
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelAdministrativo/pavMenu/pavMenu.css'
import SelectGenerico from '../../componentesModais/selects/selectGenerico'
import decryptJWT from '../../../utils/decryptJWT'

import { useDispatch } from 'react-redux';
import { revelarEditarMenu, revelarNovoMenu } from "../../../redux/actions/index";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function CadastroUsuario(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS

    const [cdMenu, setCdMenu] = useState('');
    const [dsMenu, setDsMenu] = useState('');
    const [cdMenuTipo, setCdMenuTipo] = useState('')
    const [dsMenuTipo, setDsMenuTipo] = useState('')
    const [cdTipo, setCdTipo] = useState('');
    const [dsRota, setDsRota] = useState('')
    const [cdIcone, setCdIcone] = useState('')
    const [dsIcone, setDsIcone] = useState('')
    const [dsDescricao, setDsDescricao] = useState('');
    const [caracteresMensagem, setCaracteresMensagem] = useState(500);
    const [n0Nivel, setN0Nivel] = useState('')
    const [n0Ordem, setN0Ordem] = useState('')
    const [flFilho, setFlFilho] = useState(0)
    const [flAcionar, setFlAcionar] = useState(0)
    const [flAtivo, setFlAtivo] = useState(0)
    const [flPadrao, setFlPadrao] = useState(0) 

    const [cdMenuSuperior, setCdMenuSuperior] = useState('')
    const [dsMenuSuperior, setDsMenuSuperior] = useState('')
    const [cdMenuSuperiorSecundario, setCdMenuSuperiorSecundario] = useState('')
    const [dsMenuSuperiorSecundario, setDsMenuSuperiorSecundario] = useState('')
    const [dummy, setDummy] = useState('')

    //HOOKS PLACEHOLDERS
    const [phMenu, setPhMenu] = useState('');
    const [phRota, setPhRota] = useState('');
    const [phNivel, setPhNivel] = useState('');
    const [phOrdem, setPhOrdem] = useState('');
    const [phDescricao, setPhDescricao] = useState('');
    const [phMensagemSucesso, setPhMensagemSucesso] = useState('cadastrado')

    //HOOKS MODAIS NOVO
    const [revelarSelectTipo, setRevelarSelectTipo] = useState(false)
    const [revelarSelectIcone, setRevelarSelectIcone] = useState(false)
    const [revelarSelectMenuSuperior, setRevelarSelectMenuSuperior] = useState(false)
    const [revelarSelectMenuSuperiorSecundario, setRevelarSelectMenuSuperiorSecundario] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    
    //OUTROS HOOKS
    const [tituloJanela, setTituloJanela] = useState('Cadastro');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const modal = useRef();
    const despacho = useDispatch();

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };
    
    useEffect(() => {
        setSpinAtivo(true)
        if (props.tituloJanela === 'Editar'){
            //TROCA O TITULO DA JANELA
            setCdMenu(props.cdMenu)
            setCdMenuSuperior(props.cdMenuSuperior)
            setCdMenuTipo(props.cdMenuTipo)
            setCdTipo(props.cdTipo)
            setDsDescricao(props.dsDescricao)
            setDsIcone(props.dsIcone)
            setDsMenuSuperior( props.cdMenuSuperior + ' - ' + props.dsTipoSuperior)
            setDsMenu(props.dsMenu)
            setDsMenuTipo(props.dsMenuTipo)
            setDsRota(props.dsRota)
            setFlAcionar(props.flAcionar === 1 ? true : false)
            setFlAtivo(props.flAtivo === 1 ? true : false)
            setFlFilho(props.flFilho === 1 ? true : false)
            setFlPadrao(props.flPadrao === 1 ? true : false)
            setN0Nivel(props.n0Nivel)
            setN0Ordem(props.n0Ordem)
            setDsMenuSuperiorSecundario(props.dsMenuSuperior)
            // PLACEHOLDER 
            setPhNivel(props.n0Nivel)
            setPhOrdem(props.n0Ordem)
            setPhMenu(props.dsMenu)
            setPhDescricao(props.dsDescricao)
            setPhRota(props.dsRota)

            setPhMensagemSucesso('editado')
            
        }
        if (props.tituloJanela === 'Cadastrar'){
            setTituloJanela('Cadastrar')
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setDsMenuTipo('Selecione')
            setDsMenuSuperior('Selecione')
            setDsMenuSuperiorSecundario('Selecione')
            setCdIcone('Selecione')
            setPhMenu('Digite o nome do menu')
            setPhRota('Digite o nome da rota')
            setPhNivel('Digite o nível')
            setPhOrdem('Digite a ordem')
            setPhDescricao('Digite a descrição')

            setPhMensagemSucesso('cadastrado')
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    //FAZ O MANEJO 
    function handleSalvar(){
        if (tituloJanela === 'Cadastrar'){
            ColetaClickstream(1336) // 'pavMenuTabelaGridNovoMenuCadastrar
            handleCadastrar()
        }else{
            ColetaClickstream(1337) // 'pavMenuTabelaGridNovoMenuEditar
            handleAlterar()
        }
    }

    async function handleCadastrar(){
        setSpinAtivo(true)

        await api.post('/m1/cadastrar/menu', {
            'cd_tipo_superior':cdMenuSuperior,
            'cd_pav_menu_superior': cdMenuSuperiorSecundario,
            'cd_pav_menu_tipo': cdMenuTipo,
            "ds_pav_menu": dsMenu,
            "ds_rota": dsRota,
            'ds_icone':dsIcone, 
            'ds_descricao': dsDescricao, 
            'n0_nivel': n0Nivel,
            'n0_ordem': n0Ordem,
            'fl_ativo': flAtivo,
            'fl_padrao': flPadrao,
            'fl_filho': flFilho,
            'fl_acionar': flAcionar,
            'cd_usuario': decryptJWT('codigo'), 
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            props.setRenderizar(!props.renderizar)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }
  
    async function handleAlterar(){
        if (flAtivo == 'true'){
            setFlAtivo(1) 
        }else{
            setFlAtivo(0)
        }
        if (flPadrao == 'true'){
            setFlPadrao(1)
        }else{
            setFlPadrao(0)
        }
        if (flFilho == 'true'){
            setFlFilho(1)
        }else{
            setFlFilho(0)
        }
        if(flAcionar == 'true'){
            setFlAcionar(1)
        }else{
            setFlAcionar(0)
        }

        setSpinAtivo(true)
        await api.post('/m1/alterar/menu', {
            "dados":{
                'cd_pav_menu_superior': cdMenuSuperior,
                'cd_pav_menu_tipo': cdMenuTipo,
                "ds_pav_menu": dsMenu,
                "ds_rota": dsRota,
                'ds_icone': dsIcone,
                'ds_descricao': dsDescricao,
                'n0_nivel': n0Nivel,
                'n0_ordem': n0Ordem,
                'fl_ativo': flAtivo,
                'fl_padrao': flPadrao,
                'fl_filho': flFilho,
                'fl_acionar': flAcionar,
            },
            "condicao":{
                'cd_pav_menu': cdMenu,
            }
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    function handleCancelar(){
        ColetaClickstream(1335) // 'pavMenuTabelaGridNovoMenuCancelar
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
        despacho(revelarNovoMenu(false))
        despacho(revelarEditarMenu(false))
     
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                handleCancelar()
                props.handleLimparDados()
                props.setRenderizar(!props.renderizar)
            }
        }
    }

    function alterarSwitchFilho(e){
        if(e === true){
            setFlFilho(1)
        }else{
            setFlFilho(0)
        }
    }

    function alterarSwitchAcionar(e){
          if(e === true){
            setFlAcionar(1)
        }else{
            setFlAcionar(0)
        }
    }

    function alterarSwitchAtivo(e){
          if(e === true){
            setFlAtivo(1)
        }else{
            setFlAtivo(0)
        }
    }

    function alterarSwitchPadrao(e){
          if(e === true){
            setFlPadrao(1)
        }else{
            setFlPadrao(0)
        }
    }

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-nivel"){
                setN0Nivel(valor)
            }
        }

        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-ordem"){
                setN0Ordem(valor)
            }
        }
    }
    
    function handleDescricao(valor){
        setDsDescricao(valor)
        setCaracteresMensagem(500 - valor.length)
    }


    return(
        <Form aria-autocomplete="OFF" ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <div className="modal-cadastro-menu fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{tituloJanela} menu</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <div className="container-principal-menu">
                    <div className="container-menu-1">
                        <Form.Row className="form-menu">
                            <Col>
                                <div className="coluna-cadastro-menu-1">
                                    <Form.Group className="form-menu">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Nome</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Nome do menu</Tooltip>}>
                                            <Form.Control autocomplete="off" name="dsMenu" value={dsMenu} className="campo-texto-cor-3 campo-texto" type="text" placeholder={phMenu === null || phMenu === 'null'? "" : phMenu} onChange={e => setDsMenu(e.target.value)} 
                                            ref={register({validate: {minimo: value => value.length > 1}})} maxlength="100">
                                            </Form.Control>
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row className="form-menu">
                            <Col>
                                <div className="coluna-cadastro-menu-1">
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Tipo</div>
                                    </Form.Label>
                                { revelarSelectTipo === false &&
                                    <Button name="cdFormaEnvio" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipo(!revelarSelectTipo)}>
                                        <div className="fonte-cor-1 label-campo campo-texto-overflow">{dsMenuTipo}</div>
                                        <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                                { revelarSelectTipo === true &&
                                    <SelectGenerico
                                    paramRota={"menu_tipo"}
                                    paramCampo1={null}
                                    paramCampo2={null}
                                    paramCampo3={null}
                                    paramCampo4={null}
                                    setCodigo={setCdMenuTipo}
                                    setDescricao1={setDsMenuTipo}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectTipo}
                                    placeholder={dsMenuTipo}
                                    sufixo={""}
                                    >
                                    </SelectGenerico>
                                }
                                </div>
                            </Col>
                        </Form.Row>

                        {cdMenuTipo &&
                            <Form.Row className="form-menu">
                                <Col>
                                    <div className="coluna-cadastro-menu-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Tipo superior</div>
                                        </Form.Label>
                                    { revelarSelectMenuSuperior === false &&
                                        <Button name="cdFormaEnvio" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectMenuSuperior(!revelarSelectMenuSuperior)}>
                                            <div className="fonte-cor-1 label-campo campo-texto-overflow">{dsMenuSuperior}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    {revelarSelectMenuSuperior === true &&
                                        <SelectGenerico
                                        paramRota={"menu_superior"}
                                        paramCampo1={cdMenuTipo}
                                        paramCampo2={null}
                                        paramCampo3={null}
                                        paramCampo4={null}
                                        setCodigo={setCdMenuSuperior}
                                        setDescricao1={setDsMenuSuperior}
                                        setDescricao2={setDummy}
                                        setDescricao3={setDummy}
                                        setDescricao4={setDummy}
                                        setRevelarSelect={setRevelarSelectMenuSuperior}
                                        placeholder={dsMenuSuperior}
                                        sufixo={""}
                                        descricao={true}
                                        >
                                        </SelectGenerico>
                                    }
                                    </div>
                                </Col>
                            </Form.Row>
                        }
                        {cdMenuSuperior &&
                            <Form.Row className="form-menu">
                                <Col>
                                    <div className="coluna-cadastro-menu-1">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Menu superior</div>
                                        </Form.Label>
                                    { revelarSelectMenuSuperiorSecundario === false &&
                                        <Button name="cdFormaEnvio" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectMenuSuperiorSecundario(!revelarSelectMenuSuperiorSecundario)}>
                                            <div className="fonte-cor-1 label-campo campo-texto-overflow">{dsMenuSuperiorSecundario}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    {revelarSelectMenuSuperiorSecundario === true &&
                                        <SelectGenerico
                                        paramRota={"menu_superior_secundario"}
                                        paramCampo1={null}
                                        paramCampo2={null}
                                        paramCampo3={null}
                                        paramCampo4={null}
                                        setCodigo={setCdMenuSuperiorSecundario}
                                        setDescricao1={setDsMenuSuperiorSecundario}
                                        setDescricao2={setDummy}
                                        setDescricao3={setDummy}
                                        setDescricao4={setDummy}
                                        setRevelarSelect={setRevelarSelectMenuSuperiorSecundario}
                                        placeholder={dsMenuSuperiorSecundario}
                                        sufixo={""}
                                        >
                                        </SelectGenerico>
                                    }
                                    </div>
                                </Col>
                            </Form.Row>
                        }
                        <Form.Row className="form-menu">
                            <Col>
                                <div className="coluna-cadastro-menu-1">
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Icone</div>
                                    </Form.Label>
                                { revelarSelectIcone === false &&
                                    <Button name="cdFormaEnvio" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectIcone(!revelarSelectIcone)}>
                                        <div className="fonte-cor-1 label-campo campo-texto-overflow">{dsIcone}</div>
                                        <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                                { revelarSelectIcone === true &&
                                    <SelectGenerico
                                    paramRota={"pav_menu_tipo"}
                                    paramCampo1={null}
                                    paramCampo2={null}
                                    paramCampo3={null}
                                    paramCampo4={null}
                                    setCodigo={setCdMenuTipo}
                                    setDescricao1={setDsIcone}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectIcone}
                                    placeholder={dsIcone}
                                    sufixo={""}
                                    >
                                    </SelectGenerico>
                                }
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row className="form-menu">
                            <Col>
                                <div className="coluna-cadastro-menu-1">
                                    <Form.Group className="form-menu">
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Rota</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Rota</Tooltip>}>
                                            <Form.Control autocomplete="off" name="dsRota" value={dsRota} className="campo-texto-cor-3 campo-texto" type="text" placeholder={phRota === null || phRota === 'null'? "" : phRota} onChange={e => setDsRota(e.target.value)} 
                                            ref={register({validate: {minimo: value => value.length > 1}})} maxlength="100">
                                            </Form.Control>
                                        </OverlayTrigger>
                                        {errors.dsSenha && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                                    </Form.Group>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row className="form-menu">
                            <Col>
                                <div className="coluna-cadastro-menu-1">
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Número do Nível</div> </Form.Label>
                                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Digite o nível</Tooltip>}>
                                        <Form.Control
                                            className="campo-texto-cor-3 campo-texto"
                                            type="text"
                                            maxLength="30" 
                                            name="ds_numero_nivel"
                                            value={n0Nivel}
                                            onChange={e => validarNumero("campo-texto-nivel", e.target.value)}
                                            ref={register({ required: true, min: 3})}
                                            placeholder={phNivel === null || phNivel === 'null'? "" : phNivel}
                                        />
                                    </OverlayTrigger>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row className="form-menu">
                            <Col>
                                <div className="coluna-cadastro-menu-1">
                                    <Form.Label> <div className="fonte-cor-1 label-campo">Número da Ordem</div> </Form.Label>
                                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Digite a ordem</Tooltip>}>
                                        <Form.Control
                                            className="campo-texto-cor-3 campo-texto"
                                            type="text"
                                            maxLength="30" 
                                            name="ds_numero_ordem"
                                            value={n0Ordem}
                                            onChange={e => validarNumero("campo-texto-ordem", e.target.value)}
                                            ref={register({ required: true, min: 3})}
                                            placeholder={phOrdem === null || phOrdem === 'null'? "" : phOrdem}
                                        />
                                    </OverlayTrigger>
                                </div>
                            </Col>
                        </Form.Row>
                        <div className="container-principal-switches-menu">
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Tem filho</Tooltip>}>
                                <div className="container-switches-menu">
                                    <div className="switchDiv">
                                        <div  className="flipswitch-grupo">
                                            <input checked={flFilho} id="switchTemFilho" type="checkbox" name="flipswitchTemFilho" className="flipswitch-cb" onClick={(e) => alterarSwitchFilho(e.target.checked)}/>
                                            <label for="switchTemFilho" className="flipswitch-label">
                                                <div className="flipswitch-inner flipswitch-cor-1"></div>
                                                <div className="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo-menu fonte-cor-1"> Tem filho </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Acionar</Tooltip>}>
                                <div className="container-switches-menu">
                                    <div className="switchDiv">
                                        <div  className="flipswitch-grupo">
                                            <input checked={flAcionar} id="switchAcionar" type="checkbox" name="flipswitchAcionar" className="flipswitch-cb" onClick={(e) => alterarSwitchAcionar(e.target.checked)}/>
                                            <label for="switchAcionar" className="flipswitch-label">
                                                <div className="flipswitch-inner flipswitch-cor-1"></div>
                                                <div className="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo-menu fonte-cor-1"> Acionar </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </div>
                        <div className="container-principal-switches-menu">
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Ativo</Tooltip>}>
                                <div className="container-switches-menu">
                                    <div className="switchDiv">
                                        <div  className="flipswitch-grupo">
                                            <input checked={flAtivo} id="switchAtivo" type="checkbox" name="flipswitchAtivo" className="flipswitch-cb" onClick={(e) => alterarSwitchAtivo(e.target.checked)}/>
                                            <label for="switchAtivo" className="flipswitch-label">
                                                <div className="flipswitch-inner flipswitch-cor-1"></div>
                                                <div className="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo-menu fonte-cor-1"> Ativo </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip className="cores-tooltip tooltip-faturas-ciclo-vida" id={`tooltip-top`}>Padrão</Tooltip>}>
                                <div className="container-switches-menu">
                                    <div className="switchDiv">
                                        <div  className="flipswitch-grupo">
                                            <input checked={flPadrao} id="switchPadrao" type="checkbox" name="flipswitchPadrao" className="flipswitch-cb" onClick={(e) => alterarSwitchPadrao(e.target.checked)}/>
                                            <label for="switchPadrao" className="flipswitch-label">
                                                <div className="flipswitch-inner flipswitch-cor-1"></div>
                                                <div className="flipswitch-switch"></div>
                                            </label>
                                        </div>
                                            <div className="texto-grupo-menu fonte-cor-1"> Padrão </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </div>
                    </div>

                    <div className="container-menu-2">
                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}></Tooltip>}>
                                <div className='preview-menu campo-texto-cor-3'>
                                    {dsMenuSuperior != "Selecione" && dsMenuSuperiorSecundario != "Selecione" && dsMenuTipo != "Selecione" &&
                                        <div className='container-arvore-menus'>
                                            <a className="campo-texto-arvore">{dsMenuSuperior}</a>
                                            <img className="campo-select-icone-desativado-arvore" src={setaDropdown()} alt="drop" />
                                            <a className="campo-texto-arvore">{dsMenuSuperiorSecundario}</a>
                                            <img className="campo-select-icone-desativado-arvore" src={setaDropdown()} alt="drop" />
                                            <a className="campo-texto-arvore">{dsMenuTipo}</a>
                                        </div>
                                    }
                                </div>
                        </OverlayTrigger>
                        <Form.Row className="form-menu-2">
                            <Col>
                                <Form.Group className="form-menu">
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Descrição</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Descrição</Tooltip>}>
                                        <Form.Control className="campo-texto-cor-3 campo-texto-area-2" as="textarea" rows="3" maxlength="500" placeholder={phDescricao != null ? "" : phDescricao} defaultValue={dsDescricao} onChange={e => handleDescricao(e.target.value)}></Form.Control>
                                    </OverlayTrigger>
                                    <Form.Label className="contador-carateres-restantes">
                                        <div className="fonte-cor-1 label-normal">{caracteresMensagem} caracteres restantes</div>
                                    </Form.Label>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row className="form-menu-botao">
                            <Col>
                                <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">{tituloJanela}</div>
                                </Button>
                                <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
           
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Menu "+dsMenu+" "+phMensagemSucesso+"."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}