import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export const usePedidos = create(
  immer((set) => ({
    revelarMensagemSucessoPedidoSimcardVirgem: false,
      setRevelarMensagemSucessoPedidoSimcardVirgem: (value) =>
      set((state) => {
        state.revelarMensagemSucessoPedidoSimcardVirgem = value;
      }),
  }))
);
