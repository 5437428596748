/*COMO USAR:
    olhar o passo a passo de selectGenerico.js
*/

import React, { useState, useEffect, useRef } from "react"; /*eslint-disable*/
import { api_migracao } from "../../../conexoes/api"; /*eslint-disable*/
import { Button, Form } from "react-bootstrap";

import SpinerVeye from "../../spinnerVeye/spinnerVeye.js";
import { setaDropdown } from "../../../utils/alternanciaIcones";

let temporizador = "";

export default function SelectMigracaoPlano(props) {
  const propsRotaFiltroSimcard = props?.rota === "filtroSimcard";

  const classSelectOptionDiv = propsRotaFiltroSimcard
    ? "campo-select-generico-opcao-b campo-select-opcao-b"
    : "campo-select-generico-opcao campo-select-opcao-1";

  const tipo = props.tipo || "autocomplete";

  const [spinAtivo, setSpinAtivo] = useState(true);
  const [lsOpcoes, setLsOpcoes] = useState([]);
  const [paramBusca, setParamBusca] = useState("");

  const modal = useRef();

  const handleClickFora = (e) => {
    if (!modal.current.contains(e.target)) {
      handleCancelar();
    }
  };

  function handleSelect(codigo, descricao1) {
    props.setCodigo(codigo);
    props.setDescricao1(descricao1 + props.sufixo);

    handleCancelar();
  }

  function handleCancelar() {
    props.setRevelarSelect(false);
  }

  useEffect(() => {
    setSpinAtivo(true);
    async function renderOpcoes() {
      let consulta;
      setLsOpcoes([]);

      if (paramBusca && props.dados) {
        const dados = props.dados.filter(
          (item) => item.Cliente.indexOf(paramBusca) > -1
        );

        consulta = { data: { dados } };
        const auxDadosFiltrados = consulta?.data?.dados;

        if (auxDadosFiltrados?.length > 0 && props.setDados) {
          props.setDadosFiltrados(auxDadosFiltrados);
        }
      } else {
        // exemplo rota: /api/v2/migration/select/clients?cliente=Virtueyes
        let rotaDadosSelect = `/api/v2/migration/select/${props.paramRota}?cliente=`;

        if (paramBusca) {
          rotaDadosSelect += paramBusca;
        }

        consulta = await api_migracao.get(rotaDadosSelect);

        if (consulta?.data?.length > 0 && props.setDados) {
          const auxDadosConsulta = consulta?.data;

          props.setDados(auxDadosConsulta);
          if (props.setDadosFiltrados)
            props.setDadosFiltrados(auxDadosConsulta);
        }
      }

      if (consulta?.data?.length > 0) {
        const auxDados = consulta?.data;
        let objetoConsulta = auxDados.map((item, i) => {
          return (
            <li key={"menu-" + item.CodigoCliente}>
              <div
                className={classSelectOptionDiv}
                onClick={() => handleSelect(item.CodigoCliente, item.Cliente)}
              >
                <OptionLabel>{item.Cliente + props.sufixo}</OptionLabel>
              </div>
              {auxDados[i + 1] != undefined && (
                <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
              )}
            </li>
          );
        });
        setLsOpcoes(objetoConsulta);
      } else {
        let objetoConsulta = () => {
          return (
            <li>
              <div disabled className={classSelectOptionDiv}>
                <OptionLabel>Nenhuma informação encontrada</OptionLabel>
              </div>
            </li>
          );
        };
        setLsOpcoes(objetoConsulta);
      }

      setSpinAtivo(false);
    }

    renderOpcoes();
    document.addEventListener("mousedown", handleClickFora);
    return () => document.removeEventListener("mousedown", handleClickFora);
  }, [paramBusca]);

  function handleBusca(valor) {
    clearTimeout(temporizador);
    temporizador = setTimeout(() => {
      if (valor.length > 1) {
        setParamBusca(valor);
      } else {
        setParamBusca("");
      }
    }, 2000);
  }

  return (
    <Form ref={modal}>
      {spinAtivo && propsRotaFiltroSimcard ? (
        <div className="componente-spinner-overlay-filtro-simcard">
          <div className="componente-spinner-container">
            <SpinerVeye />
          </div>
        </div>
      ) : spinAtivo && !props.rota ? (
        <div className="componente-spinner-overlay">
          <div className="componente-spinner-container">
            <SpinerVeye />
          </div>
        </div>
      ) : (
        ""
      )}

      {tipo === "autocomplete" ? (
        <>
          <Form.Control
            maxlength="18"
            ref={(input) => input && input.focus()}
            name={props.paramRota}
            className={
              propsRotaFiltroSimcard
                ? "campo-texto-cor-3 campo-select-generico-b"
                : "campo-texto-cor-3 campo-select-generico"
            }
            placeholder={props.placeholder}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s-]/g, "");
            }}
            onChange={(e) => handleBusca(e.target.value)}
          />
          <img
            className={
              propsRotaFiltroSimcard
                ? "campo-texto-cor-3 campo-select-generico-icone-ativado-b"
                : "campo-select-generico-icone-ativado"
            }
            src={setaDropdown()}
            alt="drop"
          />
        </>
      ) : (
        <Button
          className={
            propsRotaFiltroSimcard
              ? "campo-texto-cor-3 campo-select-filtro-marca"
              : "campo-texto-cor-3 campo-select"
          }
          onClick={() => props.setRevelarSelect(false)}
        >
          <div className="fonte-cor-1 label-campo-marca-2">
            {props.placeholder || "Selecione"}
          </div>
          <img
            className="campo-select-icone-ativado-filtro-b-2"
            src={setaDropdown()}
            alt="drop"
          />
        </Button>
      )}
      <div
        className={
          propsRotaFiltroSimcard
            ? "campo-select-triangulo-cor-1 campo-select-triangulo-2"
            : "campo-select-triangulo-cor-1 campo-select-triangulo"
        }
      ></div>
      <div
        className={
          propsRotaFiltroSimcard
            ? "campo-texto-cor-3 campo-select-corpo-2"
            : "campo-texto-cor-3 campo-select-corpo"
        }
      >
        <ul className="campo-select-lista">{lsOpcoes}</ul>
      </div>
    </Form>
  );
}

function OptionLabel({ children }) {
  return (
    <span
      className={
        "fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel"
      }
    >
      {children}
    </span>
  );
}
