//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye'
//COMPONENTES

import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import '../../../../css/painelFaturas/fatSolicitacoes/tabela.css'
import { getFormatedData } from '../../../../utils/dateTime';

let contador = 0
let controladorPagina = 1
let totalPaginas = 1
let totalItens = 10

export default function Preview({ cdSolicitacao }) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS TABELA
    let itensVar = []
    const [itens, setItens] = useState([])
    const [valorLimite, setValorLimite] = useState(5);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [refazerChamadas, setRefazerChamadas] = useState(true);

    useEffect(()=>{
        
        setSpinAtivo(true)
        contador = (paginaAtual - 1) * valorLimite;
        async function carregarSolicitacao(){
            if(refazerChamadas === true){
                const contagem = await api.post('/m1/consultar/fat_solicitacao_alteracao',{
                    "cd_fat_solicitacao": cdSolicitacao,
                    "carregarLimit":valorLimite,
                    "paginaAtual":contador,
                    "contar":true
                })
                totalPaginas = Math.ceil(contagem.data.dados[0].CONTADOR / valorLimite)
                totalItens = contagem.data.dados[0].CONTADOR;
                setRefazerChamadas(false)
            }
            const preview = await api.post('/m1/consultar/fat_solicitacao_alteracao',{
                "cd_fat_solicitacao": cdSolicitacao,
                "carregarLimit":valorLimite,
                "paginaAtual":contador,
                "contar":false
            })
            
            inserirData(preview.data.dados)
        }
        carregarSolicitacao()
    },[paginaAtual])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)
    }

    const colunas = [
        {
            dataField: 'ds_solicitacao_status',
            headerClasses: 'nao-selecionavel',
            text: 'Status',
            sort: true,
            style: {maxWidth:'150px', overflow: 'hidden', 'text-overflow': 'ellipsis'},
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_motivo',
            headerClasses: 'nao-selecionavel',
            text: 'Motivo de edição',
            sort: true,
            style: {maxWidth:'150px', overflow: 'hidden', 'text-overflow': 'ellipsis'},
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{ ((cell == 'undefined') || (cell == '')) ? '-' : cell}</p>;
            }
        },
        {
            dataField: 'ds_protocolo_operadora',
            headerClasses: 'nao-selecionavel',
            text: "Protocolo",
            sort: true,
            style: {maxWidth:'200px', overflow: 'hidden', 'text-overflow': 'ellipsis' },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{ ((cell == 'undefined') || (cell == '')) ? '-' : cell }</p>;
            }
        },
        {
            dataField: 'dt_prazo_conclusao',
            headerClasses: 'nao-selecionavel',
            text: "Previsão de conclusão",
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{getFormatedData(cell, false)}</p>;
            }
        },
        {
            dataField: 'dt_alteracao',
            headerClasses: 'nao-selecionavel',
            text: "Data alteração",
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{getFormatedData(cell)}</p>;
            }
        },
        {
            dataField: 'ds_email',
            headerClasses: 'nao-selecionavel',
            text: "Usuário",
            sort: true,
            style: {maxWidth:'200px', overflow: 'hidden', 'text-overflow': 'ellipsis'},
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
    ]

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => { totalItens ? setPaginaAtual(page) : setPaginaAtual(1) }
    }

    return(
        <>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className='container-tabela-preview'>
                <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <div className="tabela-transparente-header-fixo">
                                <BootstrapTable
                                    hover={true}
                                    condensed={true}
                                    data={itens}
                                    keyField={"cd_fat_fat_preview"}
                                    columns={colunas} 
                                    bootstrap4={true}
                                    bordered={false}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    { ...paginationTableProps }
                                />
                                <PaginacaoTabela
                                    preview={true}
                                    setPaginaAtual={setPaginaAtual}
                                    totalPaginas={totalPaginas}
                                    totalItens={totalItens}
                                    valorLimite={valorLimite}
                                    paginaAtual={paginaAtual}
                                    page={paginationProps.page}
                                    onPageChange={paginationProps.onPageChange}
                                />
                            </div>
                        )
                    }
                </PaginationProvider>
                <text className="itens-exibidos-tabela-solicitacoes-2 fonte-cor-1">
                    Mostrando 
                    <span className="label-tabela-itens-exibidos">{totalItens ? contador + 1 : 0}</span>
                        - 
                    <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                        de 
                    <span className="label-tabela-itens-exibidos">{totalItens}</span>
                </text>
            </div>
        </>
    )
}