import dayjs from "dayjs";
const getNumber = (data) => data?.replace(/\D/g, "");

export function formatArrayToString(array) {
  return array.join(",");
}

export function formatStringToNumberArray(string) {
  return string.split(",").map(Number);
}

export function formatDateToFilter(date) {
  return dayjs(date).format("DD-MM-YYYY");
}

export function formatDateToDatePicker(date) {
  return dayjs(date, "YYYY-MM-DD").toDate();
}

export function formatDateToGetFilter(date) {
  return dayjs(date).format("YYYY-MM-DD");
}

export function formatPhone(phone) {
  return getNumber(phone);
}

export function formatCep(cep) {
  return getNumber(cep);
}

export function formatBusca(busca, separadorLinha) {
  const buscaFormatada = busca.trim().replace(/\s{2,}/g, " ");
  const separadores = {
    enter: "\\n",
    espaco: " ",
    ",": ",",
    "|": "|",
  };

  const separador = separadores[separadorLinha];
  return separador ? buscaFormatada.replaceAll(separador, ";") : buscaFormatada;
}
