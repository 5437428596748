import React, { useState, useEffect, useRef } from "react";/*eslint-disable*/
import Carregando from "../../../spinnerVeye/spinnerVeye"
import Uploader from "../../../componentesModais/uploader/uploader";
import { ReactComponent as Fechar } from "../../../../icones/misc/fechar.svg"
import {exclamacao} from '../../../../utils/alternanciaIcones'
import { useDispatch } from 'react-redux';
import { revelarIotTelecom } from "../../../../redux/actions/index";
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import './modalImportarArquivo.css';
import decryptJWT from "../../../../utils/decryptJWT";

export default function ModalImportarArquivoIot(props) {
    const {tituloModal} = props;

    const despacho = useDispatch();
    const modal = useRef()
    const [spinAtivo, setSpinAtivo] = useState(false);
    const [arquivo, setArquivo] = useState([])
    const [disabledBotao, setDisabledBotao] = useState(false)

    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    }
    
    useEffect(() => {
        setSpinAtivo(true)
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    },[])

    async function validarArquivoImportado(auxDadosImportados){
        if(auxDadosImportados === 'remover'){
            props.setDadosImportados([])
            setArquivo([])
            setDisabledBotao(false)
        }else{
            props.setDadosImportados(auxDadosImportados)
            setDisabledBotao(true)
        }
    }

    async function handleCancelar() {
        setSpinAtivo(false)
        despacho(revelarIotTelecom(false))
    }

    async function handleSalvar() {
        const codCliente = decryptJWT('codigoClienteErpAntigo')

        if((codCliente != 1) && (codCliente != 3234)) {
            setRevelarMensagemErro('Este cliente não tem permissão para acessar esta ação')
            return;
        }
 
        setSpinAtivo(true)

        await props.salvar() 
        .then(() => handleMensagemSucesso(false))
        .catch((erro) =>  {
            console.error('catch: ', {erro})
            setRevelarMensagemErro('Ocorreu um erro na requisição!')
        });

        setSpinAtivo(false)
    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if (confirmacao) {
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            const strErro = "Algo deu errado, tente novamente. Se o erro persistir entre em contato com o suporte."
            setRevelarMensagemErro(strErro)

        } else {
            setRevelarMensagemErro(false)
        }
        if (confirmacao === true) {
            //ALGUMA FUNÇÃO
        }
    }

    return(
        <div ref={modal} className="modal-ativacao-simcard fundo-cor-6">
            { spinAtivo === true && 
                <div className="componente-spinner-overlay">
                    <div className="componente-spinner-container">
                        <Carregando/>
                    </div>
                </div>
            }
             <div className="cabecalho-modal-cadastro">
                <div className="grupo-1-cabecalho-modal">
                    <div className="icone-cabecalho-modal-cadastro"/>
                    <label className="titulo-modal-cadastro">{tituloModal}</label>
                </div>
                <Fechar className="icone-botao-fechar-modal-cadastro" onClick={() => {handleCancelar()}}/>
            </div>

            <div className="selects-versao-acao">
                {props.BeforeDivImport}
            </div> 

            {props.showDivImportarArquivo && <div className="mt-2">
                <div className="campo-texto-titulo-opcoes-ativacao-1">
                    <label className='fonte-cor-1 label-campo'>Importar arquivo</label> 
                </div>
                <Uploader
                    setCarregando={setSpinAtivo}
                    callback={validarArquivoImportado}
                    template={props.template}
                    setDadosArquivo={setArquivo}
                    linha={props.fileColumnIsLinha}
                    // linha={false}
                    disabled={disabledBotao}
                />
                <p className="label-alerta-pedidos-margem fonte-cor-3 label-alerta">{iconeAlerta} Os tipos de arquivos suportados são XLSX e XLS.</p>
                <p className="label-alerta-pedidos-margem fonte-cor-3 label-alerta-filtro-arquivo">
                    {iconeAlerta} Na maioria das ações, o arquivo deve conter um cabeçalho com uma coluna com LINHA. <br/>
                    Apenas usa a coluna ICCID para Ativação</p> 
                {/* <p className="label-alerta-pedidos-margem fonte-cor-3 label-alerta-filtro-arquivo">{iconeAlerta} O arquivo deve conter um cabeçalho com uma coluna com 'linha' ou 'iccid'</p> */}

                {props.AfterDivImport}
            </div>}

             <div className="rodape-modal-cadastro">
                <button className="botao-secundario-sistema" onClick={() => handleCancelar()}>
                    Cancelar
                </button>
                {arquivo.length > 0 && props.podeConfirmar ?
                    <button className={"botao-primario-sistema"} onClick={() => handleSalvar()}>Confirmar</button>
                :
                    <button className={"botao-primario-sistema-desativado"}>Confirmar</button>
                }
            </div>
            { revelarMensagemSucesso &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={'Sucesso'}
                            mensagemParagrafo1={props.textoMsgSucesso}
                            mensagemBotao={'Continuar'}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={'Erro'}
                            mensagemParagrafo1={revelarMensagemErro}
                            mensagemBotao={'Retornar'}
                        />
                    </div>
                </div>
            }
        </div>
    )
}