//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectLogin from '../../componentesModais/selects/selectLogin'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectLogin, setRevelarSelectLogin] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label className="label">
                <div className="fonte-cabecalho">login {iconeAsterisco}</div>
            </Form.Label>
            <Button name="cdLogin" className="campo-select-modal" onClick={() => setRevelarSelectLogin(!revelarSelectLogin)}>
                <div className="campo-select-modal-label">{dsLogin}</div>
                <img className="campo-select-modal-icone" src={setaDropdown()} alt="drop" />
            </Button>
            { revelarSelectLogin === true &&
                <SelectLogin
                    setRevelarSelectLogin={setRevelarSelectLogin} //Hook para fechar a modal
                    setDsLogin={setDsLogin} //Nome do login
                    phLogin={phLogin} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectLogin>
            }
        </Form.Group>*/

export default function SelectLogin(props) {
    const [lsLogins, setLsLogins] = useState([])
    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();
    //OUTROS
    let listaLogins = []
    let cont = 0

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleLogin(Login){
        props.setDsLogin(Login)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectLogin(false)
    }

    useEffect(() => {
        async function renderLogins(){
            const listaLogins = props.vetorLogins
            let cont = 0
            let objetoLogins = await listaLogins.map((item,i) => {
                return (
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleLogin(item) }>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item}</span>
                        </div>
                        {listaLogins[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        {(listaLogins.length <= ++cont) ? null : props.setSpinAtivo(false) }
                    </li>
                );
            })
            setLsLogins(objetoLogins)
        }
        renderLogins()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            <Button name="cdLogin" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectLogin(false) }>
                <div className="fonte-cor-1 label-campo">{props.dsLogin}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsLogins}
                </ul>
            </div>
        </Form>
    )
}