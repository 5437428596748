import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Button, Col } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
//COMPONENTES
import Calendario from '../../components/calendario/calendario'
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'
// useEffect(() => {
    // const formData = new FormData(props.modal.current)
    // let isIttrue = false
    // for (var [key] of formData.entries()) {
    //     if(props.modal.current[key].value == ""){
    //         isIttrue = true
    //     }
    //     else{
    //         isIttrue = false
    //     }
    // }
//     if(isIttrue){
//         props.setFoiSelecionado(fa);
//     }
// }, [props.renderizar]);

const arrFiltrosStatusSms = [
    { id: '1', desc: 'Processando' },
    { id: '2', desc: 'Pendente' },
    { id: '3', desc: 'Cancelado' },
    { id: '4', desc: 'Pago' },
];

export default function FiltrosVirtueyesCliente(props) {
    const [filtroStatus , setFiltroStatus] = useState('')
    const [filtroCriacaoDataInicio , setFiltroCriacaoDataInicio] = useState('')
    const [filtroCriacaoDataFinal , setFiltroCriacaoDataFinal] = useState('')
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())
    const [filtroValorMinimo, setFiltroValorMinimo] = useState('')
    const [filtroValorMaximo, setFiltroValorMaximo] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    function OpcaoFiltroStatusSms({ item }) {
        const { id, desc } = item;
        const checked = filtroStatus === desc;
        return (
            <><label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus(desc)}> {desc}
                <input defaultChecked={handlerCheck("STATUS_PEDIDO_SMS", id)} type="radio" id={`STATUS_${id}`} name="STATUS_PEDIDO_SMS" value={id} checked={checked} />
                <span className="checkbox-filtros campo-texto-cor-3"></span>
            </label><div className="drop-filtros-divisora campo-select-divisor-cor-1"></div></>
        )
    }

    async function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            const opcaoStatusEscolhida = arrFiltrosStatusSms.find(item => item.id === filtros.STATUS_PEDIDO_SMS);
            if(opcaoStatusEscolhida?.desc) setFiltroStatus(opcaoStatusEscolhida.desc);
            setFiltroValorMinimo(filtros.QUANTIDADE_MIN)
            setFiltroValorMaximo(filtros.QUANTIDADE_MAX)

            //verifica se existe algo nos valores de datas
            if(filtros.DATA_PEDIDO_INICIO !='' && filtros.DATA_PEDIDO_INICIO){
                let auxData = new Date(filtros.DATA_PEDIDO_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroCriacaoDataInicio(auxData)
            }
            if(filtros.DATA_PEDIDO_FINAL != '' && filtros.DATA_PEDIDO_FINAL){
                let auxData = new Date(filtros.DATA_PEDIDO_FINAL)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroCriacaoDataFinal(auxData)
            }
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1224) /*pedidosSmsTabelaDropFiltrosFiltrar*/
        if(filtroCriacaoDataInicio !== '' && filtroCriacaoDataFinal !== '' && filtroCriacaoDataInicio && filtroCriacaoDataFinal){
            arrayFiltrosSimcard.push("Criação: entre "+moment(filtroCriacaoDataInicio).format("DD-MM-YYYY")+" e "+moment(filtroCriacaoDataFinal).format("DD-MM-YYYY")+"?DATA_PEDIDO_INICIO")
        }
        if(filtroStatus !== '' && filtroStatus)arrayFiltrosSimcard.push("Status: "+filtroStatus+"?STATUS_PEDIDO_SMS")
        if(filtroValorMinimo !== '' && filtroValorMaximo !== '' && filtroValorMinimo && filtroValorMaximo){
            arrayFiltrosSimcard.push("Quantidade entre "+filtroValorMinimo+" e "+filtroValorMaximo+"?QUANTIDADE_MIN")
        }
        
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }
    
    useEffect(() => {
        
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroCriacaoDataInicio=='') verificador.push(1)
            if(filtroCriacaoDataFinal=='') verificador.push(1)
            if(filtroStatus=='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 3) return false
            return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()){
                props.setFoiSelecionado(true);
            }
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroCriacaoDataInicio('')
            setFiltroCriacaoDataFinal('')
            setFiltroStatus('')
            props.setFoiLimpado(false)
        }
        
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroStatus,filtroCriacaoDataFinal,filtroCriacaoDataInicio,props.foiLimpado]);

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-quantidade-minimo"){
                setFiltroValorMinimo(valor)
            }
            if(id === "campo-texto-quantidade-maximo"){
                setFiltroValorMaximo(valor)
            }
        }
    }

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="drop-acoes-triangulo campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* campo para o email  */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-aquisicao", "imagem-filtro-aquisicao"); ColetaClickstream(1221) /*pedidosSmsTabelaDropFiltrosDataDeCriacao*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data de criação </Form.Label>
                        <img id={"imagem-filtro-aquisicao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-aquisicao"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1"> Selecione o período:</label>
                        {/* Componente Calendário */}
                        <div className="conteudo-calendario-filtro-solicitacao-cliente">
                            <Form.Control name="DATA_PEDIDO_INICIO" type="text" value={filtroCriacaoDataInicio? moment(filtroCriacaoDataInicio).format("YYYY-MM-DD hh:mm:ss") : filtroCriacaoDataInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroCriacaoDataInicio} setData={setFiltroCriacaoDataInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro-solicitacao-cliente">
                            <Form.Control name="DATA_PEDIDO_FINAL" type="text" value={filtroCriacaoDataFinal ? moment(filtroCriacaoDataFinal).format("YYYY-MM-DD hh:mm:ss") : filtroCriacaoDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroCriacaoDataFinal} setData={setFiltroCriacaoDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>

                    {/* campo para o email  */}
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-status", 'imagem-filtro-status'); ColetaClickstream(1222) /*pedidosSmsTabelaDropFiltrosStatus*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Status </Form.Label>
                        <img id={"imagem-filtro-status"}className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        {arrFiltrosStatusSms.map(item => <OpcaoFiltroStatusSms item={item}/>)}
                    </div>
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-valor","imagem-filtro-valor"); ColetaClickstream(1223) /*pedidosSmsTabelaDropFiltrosQuantidadeDeSMS*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Quantidade de SMS </Form.Label>
                        <img id={"imagem-filtro-valor"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-valor"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="QUANTIDADE_MIN"
                                        className="filtros-texto-valor-mb-sem-label campo-texto-cor-3"
                                        type="text"
                                        maxLength="5"
                                        value={filtroValorMinimo}
                                        onChange={e => validarNumero("campo-texto-quantidade-minimo", e.target.value)}
                                    />
                                    {/* <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label> */}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="QUANTIDADE_MAX"
                                        className="filtros-texto-valor-mb-sem-label campo-texto-cor-3"
                                        type="text"
                                        maxLength="5"
                                        value={filtroValorMaximo}
                                        onChange={e => validarNumero("campo-texto-quantidade-maximo", e.target.value)}
                                    />
                                    {/* <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label> */}
                                </div>
                            </div>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* campo para o email  */}
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1-final"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}