//IMPORTAÇÕES
import React, { useEffect, useState } from 'react';/*eslint-disable*/
import 'moment-timezone';/*eslint-disable*/
import {api} from '../../../../../conexoes/api';
import {formatarMilhar} from "../../../../../utils/formatarMilhar"
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../../utils/paginacaoTabela'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let simcardsSelecionados = [];
let itensVar = []
let ordem = {}
let ciclo = undefined
let alternadorSelecao = false
let selecaoLinhaCabecalho = false
let verificadorBloqueioFinanceiro = false

export default function Servicos(props){
    //HOOKS TABELA
    const [itens, setItens] = useState([])
    const [valorLimite, setValorLimite] = useState(10);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [refazerChamadas, setRefazerChamadas] = useState(true);

    useEffect(()=>{
        contador = (paginaAtual - 1) * valorLimite;
        async function carregarHistorico(){
            if(refazerChamadas === true){
                const contagem = await api.post('/m1/consultar/fat_plano_servico',{
                    "cd_fat_plano":props.cdPlano,
                    "carregarLimit":valorLimite,
                    "paginaAtual":contador,
                    "contar":true
                })
                totalPaginas = Math.ceil(contagem.data.dados[0].CONTADOR / valorLimite)
                totalItens = contagem.data.dados[0].CONTADOR;
                setRefazerChamadas(false)
            }
            const movimentos = await api.post('/m1/consultar/fat_plano_servico',{
                "cd_fat_plano":props.cdPlano,
                "carregarLimit":valorLimite,
                "paginaAtual":contador,
                "contar":false
            })
            setItens(movimentos.data.dados)
        }
        carregarHistorico()
    },[paginaAtual])

    const colunas = [
        {
            dataField: 'cd_fat_servico',
            text: 'Serviço',
            hidden: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_fat_servico',
            text: 'Serviço',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_categoria',
            text: 'Categoria',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_tipo',
            text: 'Tipo',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_unidade',
            text: 'Unidade',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'n2_valor',
            text: 'Valor',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">R$ {formatarMilhar(cell)}</p>;
            }
        },
        {
            dataField: 'ds_quantidade',
            text: 'Quantidade',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'fl_status',
            text: 'Status',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                if(cell === 1){
                    return <p className="fonte-cor-1 label-tabela-1">Ativado</p>;
                }else{
                    return <p className="fonte-cor-1 label-tabela-1">Desativado</p>;
                }
            }
        }
    ]

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    return(
        <div className='container-historico-movimento-simcard'>
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <div className="container-tabela-extrato">
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                data={itens}
                                keyField={"cd_fat_servico"}
                                columns={colunas} 
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={props.spinAtivo ? "carregando..." : "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                extrato={true}
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                                tipo2
                            />
                        </div>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela-extrato fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}