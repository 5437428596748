//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';/*eslint-disable*/
//MAPA
import MapaGoogle from '../../googleMap/googleMap'/*eslint-disable*/
import MapaOpenStreet from './mapaOpenStreet'
import '../../../css/painelDispositivos/dspMapa/mapa.css'

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function MapaSituacao(props) {
    const [latCenter, setLatCenter] = useState(0);
    const [lngCenter, setLngCenter] = useState(0);
    let array = props.array
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(1146) // mapaStatus
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        async function handleLatitudeLongitude(){
            if(array.length > 0){
                //setar os valores maximos de latitude e longitude para saber o posicionamento da tabela
                const firstItem = array[array.length -1]
                if(!firstItem) return;

                let latitudeMin = firstItem.latitude;
                let latitudeMax = firstItem.latitude;
                let longitudeMin = firstItem.longitude;
                let longitudeMax = firstItem.longitude;
                
                Object.keys(array).forEach(function (item, i) {
                    //verifica se a latitude esta negativa, se estiver a forma de tratar as variaveis e diferente
                    if(array[item].latitude / -array[item].latitude == 1){
                        //seta a latitude minima
                        if(array[item].latitude > latitudeMin){
                            latitudeMin = array[item].latitude
                        }
                        //seta a latitude maxima
                        if(array[item].latitude < latitudeMax){
                            latitudeMax = array[item].latitude
                        }
                    }else{
                        //seta a latitude minima
                        if(array[item].latitude < latitudeMin){
                            latitudeMin = array[item].latitude
                        }
                        //seta a latitude maxima
                        if(array[item].latitude > latitudeMax){
                            latitudeMax = array[item].latitude
                        }
                    }
                    //verifica se a longitude esta negativa
                    if(array[item].longitude / -array[item].longitude == 1){
                        //seta a longitude minima
                        if(array[item].longitude > longitudeMin){
                            longitudeMin = array[item].longitude
                        }
                        //seta a longitude maxima
                        if(array[item].longitude < longitudeMax){
                            longitudeMax = array[item].longitude
                        }
                    }else{
                        //seta a longitude minima
                        if(array[item].longitude < longitudeMin){
                            longitudeMin = array[item].longitude
                        }
                        //seta a longitude maxima
                        if(array[item].longitude > longitudeMax){
                            longitudeMax = array[item].longitude
                        }
                    }
                    setLatCenter((latitudeMax + latitudeMin) /2)
                    setLngCenter((longitudeMax + longitudeMin) /2)
                });
            }
        }
        handleLatitudeLongitude()
    }, [])

    let pontosNoMapa = array

    return(
        <div className="container-mapa-generico">
            {!props.mapa &&
                <MapaGoogle array={pontosNoMapa}/>
            }

            {props.mapa && 
                <MapaOpenStreet array={pontosNoMapa} latCenter={latCenter} lngCenter={lngCenter} mapaTipo={1}/>
            }
        </div>
    )
}
