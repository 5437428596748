//IMPORTAÇÕES
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
// import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';/*eslint-disable*/
import SunEditor from 'suneditor-react';/*eslint-disable*/
import moment from 'moment';

import imagemVerde from '../../../icones/imagemVerde.svg';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import './cadastro.css';

//ICONES
import {elipseVerde, fecharJanela} from '../../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
import { RevelarBuscarEFiltro, revelarNovaMensagem } from "../../../redux/actions/index";
//COMPONENTES
import MensagemSucesso from "../../componentesModais/mensagens/mensagemSucesso"
import MensagemErro from "../../componentesModais/mensagens/mensagemErro"
// import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
// import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
import Calendario from '../../calendario/calendario'

// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
import { api, api_s3 } from '../../../conexoes/api';
import Dropzone from 'react-dropzone';
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao';

// import ColetaClickstream from '../../clickstreamAnalise/clickstreamColeta';

// let itensTemp = []

// const limiteStrHTML = 10000;
// let imagem;

export default function CadastroVersao(props) {
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [itens, setItens] = useState([])

    const [renderizar, setRenderizar] = useState(false)
    const [dsVersao, setDsVersao] = useState('')
    const [imagemPrincipal, setImagemPrincipal] = useState('')
    const [fileName, setFileName] = useState('')
    
    // const [dsDescricao, setDsDescricao] = useState('');
    const [corpoMensagem, setCorpoMensagem] = useState('');

    //HOOKS MENSAGENS
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)

    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();
    const [spinAtivo, setSpinAtivo] = useState(false);
    // const [clickFora, setClickFora] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    // const [caracteresMensagem, setCaracteresMensagem] = useState(41);
    const [arrayTemplates, setArrayTemplates] = useState([]);
    const [imgBase64, setImgBase64] = useState([]);
    // const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState('1990')
    const [dataMax, setDataMax] = useState(new Date())
    const [dataVersao, setDataVersao] = useState('');

    // const qtdCaracteresFaltam = limiteStrHTML - corpoMensagem.length;

    // const cd_pav_usuario = decryptJWT('codigo')

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    // const handleClickDentro = () => setClickFora(false);

    async function handleCancelar() {
        // ColetaClickstream(? 1401) // centralMensagensTabelaDropAcoesNovaMensagemCancelar
        setRevelarMensagemErro(false)
        setRevelarMensagemSucesso(false)
        despacho(revelarNovaMensagem(false))
        props.fecharModal();
    }

    async function salvarImagemNaS3(base64, bodyConteudo) {
        if(base64){
            let data = new FormData();
            data.append('file', base64);
            data.append("conteudo", JSON.stringify(bodyConteudo));
            data.append('ds_tag', "[IMG1]");

        //fake
        // const linkAwsImagem = 'https://s3.sa-east-1.amazonaws.com/general-files.veye.com.br/36b6dee50eefa9c3bab251244d4d9de7eb9e2cf7.png';
        // setSpinAtivo(false)
        // fim fake

            // 1. como sei que o usuário não está alterando a imagem?
            // 2. DELETAR IMAGEM ANTIGA ANTES (na pior das hipóteses, trata como BUG separado) 

            return api_s3.post("s3/uploadImage/versionamento", data, { timeout: 30000 })
                .then(response => {
                    if (response.status === 200) {
                        // handleMensagemSucesso(true);
                        setSpinAtivo(false)
                        const linkAwsImagem = response.data.dados.Location
                        return linkAwsImagem
                    }else{
                        setSpinAtivo(false)

                    }
                })
                .catch((error) => {
                    console.error({ error })
                    setSpinAtivo(false)
                });
        }else{
            setMensagemErro("O arquivo não é do tipo .png, .jpg ou .jpeg")
            setRevelarMensagemErro(true)
        }
    }

    async function handleSalvar() {
        // ColetaClickstream(? 1402) // centralMensagensTabelaDropAcoesNovaMensagemConfirmar
        setSpinAtivo(true)

        let linkAwsImagem = '';
        let novoLinkImagemPrincipal;
        let mensagem = corpoMensagem;
        let a = mensagem.split("figure")

        let auxVerifica = corpoMensagem.indexOf("<figure")
        let styleStr = false;
        let dataAlign = false;

        if(auxVerifica > 1){
            let b = a[1].split('src=')
            let c = b[1].split('>')
            const cZeroSplit = c[0].split(',')
            let base64 = cZeroSplit[1]

            styleStr = cZeroSplit.filter(item => item.indexOf('style=') > -1);

            dataAlign = cZeroSplit[2].split(' ').filter(item => item.indexOf('data-align') > -1)

            if(c[0].indexOf('base64') > -1) {
                setImgBase64(base64)
    
                const resSalvarImagem = await salvarImagemNaS3(base64, {texto: corpoMensagem})
                if(resSalvarImagem) {
                    linkAwsImagem = resSalvarImagem;
                }

            } else if(c[0].indexOf('"http') > -1) {
                linkAwsImagem = c[0].split('"')[1]
            }
        }

        const contemTexto = (textoOriginal, parcialStr) => textoOriginal.indexOf(parcialStr) > -1;

        if(!contemTexto(imagemPrincipal, 'http')) {
            const base64p = imagemPrincipal.split(',')[1]
            const arrFileExtension = fileName.split('.');
            const extensao = '.'+arrFileExtension[arrFileExtension.length-1];

            if(extensao.length > 2 && (!arrFileExtension[0].indexOf('/svg') > -1)) { // ex: '.png'
                novoLinkImagemPrincipal = await salvarImagemNaS3(base64p, { extensao });
            }
            // if(novoLinkImagemPrincipal) {
            //     // setImagemPrincipal(novoLinkImagemPrincipal);
            // }
        }

        let index1 = corpoMensagem.indexOf("<figure")
        let index2 = corpoMensagem.indexOf("</figure>")

        let novoCorpoMensagem = ''
        if(index1 != -1 && index2 != -1){
            novoCorpoMensagem = corpoMensagem.replace(corpoMensagem.substring(index1, index2+9),' \
            <figure style="margin: 0px;"> \
            <img src="'+linkAwsImagem+`" alt="" ${styleStr || 'style=""'}></figure>`); // ${dataAlign || ''}

            setCorpoMensagem(novoCorpoMensagem);
        } else{
            novoCorpoMensagem = corpoMensagem
        }

        // if(formaEnvioPlataforma === true){
            setSpinAtivo(true)
            salvarVersao(novoCorpoMensagem, novoLinkImagemPrincipal)
        // }
    }

    async function salvarVersao(novoCorpoMensagem, novoLinkImagemPrincipal){
        let rotaSalvar = "m1/cadastrar/versionamento";

        const body = { 
            imagemPrincipal: (novoLinkImagemPrincipal || imagemPrincipal),
            textoHtml: novoCorpoMensagem, 
            versao: dsVersao, 
            data: dataVersao.length > 0 ? dataVersao.substring(0,10) : dataVersao
        }

        if(props.cdVersionamento) {
            rotaSalvar = "m1/alterar/versionamento" // editar
            body.cd_pav_release = props.cdVersionamento;
        }

        await api.post(rotaSalvar, body, { timeout: 30000 })
        .then(function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
            if(props?.cadastrou) props.cadastrou(true)
        })
        .catch(function (error) {
            console.error({ error })
            setSpinAtivo(false)
            if(error?.response?.status === 401) {
                setMensagemErro(error?.response?.data?.dados?.msg)
            } else {
                setMensagemErro("Erro na solicitação")
            }
            handleMensagemErro(false)
        })
    }

    useEffect(() => {
        despacho(RevelarBuscarEFiltro(true))

        async function carregarDadosEditar() {
            
            if(!props.cdVersionamento) {
                setDataVersao(new Date()); // data padrão
                return; // só continua se for 'Editar'
            }

            let rotaGetVersao = 'm1/consultar/versionamento'
            if(props.cdVersionamento) {
                rotaGetVersao += '?cd_pav_release='+props.cdVersionamento;
            }
            await api.get(rotaGetVersao)
                .then(res => {
                    if(res?.status === 200) {
                        const dados = res?.data?.dados[0];
                        setDsVersao(dados.ds_release);

                        setImagemPrincipal(dados.bl_imagem)
                        const auxDate = moment(dados.dt_created_at).format('MM/DD/YYYY')
                        setDataVersao(new Date(auxDate)); 

                        // setImagemBase64(dados.bl_imagem);
                        setCorpoMensagem(dados.ds_texto);
                    }
                })
                .catch(err => {
                    console.error({ err })
                    setCorpoMensagem('Erro ao consultar dados!');
                })

            setSpinAtivo(false);
        }

        carregarDadosEditar();

        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, []);

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                props.setRenderizar(!props.renderizar)
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                props.setRenderizar(!props.renderizar)
                handleCancelar()
            }
            handleCancelar()
        }
    }

    function handleAtualizarMensagem(mensagem){
        // if(mensagem.length <= limiteStrHTML) {
            setCorpoMensagem(mensagem)
        // }
    }
    
    async function handleAlterar(novaImagem){ // imagem principal
        setSpinAtivo(true)
        // await api.post('/m1/alterar/usuario', {
        //     "dados":{
        //         'bl_imagem': novaImagem
        //     },
        //     "condicao":{
        //         'cd_pav_usuario': decryptJWT("codigo"),
        //     }
        // })
        // .then(function (response) {
            setImagemPrincipal(novaImagem)
            // imagem = novaImagem
            // despacho(imagemPerfil(novaImagem))
            setSpinAtivo(false)
        // })
        // .catch(function (error) {
        //     setMensagemErro(error.response.data.info)
        //     setRevelarMensagemErro(true)
        //     setSpinAtivo(false)
        // });
    }

    function handleLerArquivo(files){
        let reader = new FileReader();
        if (files.size <= 1048576 && files.type.split('/')[0] === "image" && files.type.split('/')[0] !== "gif" && files.type.split('/')[0] !== "psd"){
            setFileName(files.name);

            reader.readAsDataURL(files);
            reader.onloadend = () => {
                handleAlterar(reader.result)
            }
        }else{
            handleMensagemInformacao()
        }
    };

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-cadastro-mensagem fundo-cor-6">
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{props.tituloJanela} Versão</div> {/* Cadastrar Versão OU Editar Versão */}
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                 
                    <Form.Row>
                        <Col className={`grupo-3-campo ${imagemPrincipal ? 'expand-container' : ''}`}>
                            <div className="grupo-nova-versao-1">
                                <div className="div-campo-versao">
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Versão</div>
                                        </Form.Label>
                                        <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Versão do sistema</Tooltip>}>
                                            <Form.Control defaultValue={dsVersao} name="ds_assunto" className="campo-texto-cor-3 campo-texto" type="text" onChange={e => setDsVersao(e.target.value)} 
                                                ref={register({validate: {minimo: value => value.length > 1}})} maxLength="9"/>
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>

                                <div>
                                    <label className="label-data-versao fonte-cor-1">Data da versão:</label>
                                    <div className="conteudo-calendario-filtro">
                                        <Form.Control name="createdAtInicio" type="text" value={dataVersao ? moment(dataVersao).format("YYYY-MM-DD") : dataVersao} className="d-none"/>
                                        <Calendario /*imputCustomisado='De:'*/ formato="DD/MM/YYYY" data={dataVersao} setData={setDataVersao} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                    </div>
                                </div>
                            </div>

                            <div className="div-campo-imagem-principal"> {/* início campo imagem ------------------------------*/}
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Imagem principal</div>
                                        </Form.Label>
                                        <Dropzone onDrop={acceptedFiles => handleLerArquivo(acceptedFiles[0])}>
                                            {({getRootProps, getInputProps}) => (
                                                <section>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <div id="div-importar-img" /*onClick={}*/>
                                                            <img className="" src={imagemVerde} alt="ícone upload"/>

                                                            <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>imagem</Tooltip>}>
                                                                <Form.Control disabled 
                                                                    defaultValue={imagemPrincipal && (fileName || '(imagem carregada)')}//{imagemPrincipal} 
                                                                    name="imagem_principal" className="campo-texto-cor-3 campo-texto" /*onChange={}*/ 
                                                                    // ref={register({validate: {minimo: value => value.length > 1}})} maxLength="9"
                                                                />
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </Form.Group>

                                    {imagemPrincipal != '' && <img id="preview-imagem-principal" src={imagemPrincipal} />}
                                </div> {/* fim campo imagem ------------------------------*/}
                        </Col>
                    </Form.Row>

                    <Form.Row id="div-mensagem-editor">
                        <Col>
                            <div className="grupo-nova-mensagem-central-mensagens-editor">
                                {renderizar == false ?
                                    <SunEditor
                                        width="100%" 
                                        height="25em"
                                        setContents={corpoMensagem}
                                        lang="pt_br"
                                        setOptions={{  buttonList:[
                                            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                            ["font", "fontColor", "hiliteColor"],
                                            ["outdent", "indent"],
                                            ["align", "horizontalRule"],
                                            ["fullScreen", "showBlocks", "codeView", "template"],
                                            "/",
                                            ["print"],
                                            ["link", "image"],
                                            ["undo", "redo"],
                                            ],
                                            templates:arrayTemplates
                                        }}
                                        onChange={e => handleAtualizarMensagem(e)}
                                        // toggleCodeView={true} 
                                            // boolean => para editar deve colocar em true antes de inserir o html do banco
                                    />
                                :
                                    <SunEditor
                                        width="100%" 
                                        height="25em"
                                        setContents={corpoMensagem}
                                        lang="pt_br"
                                        setOptions={{  buttonList:[
                                            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                            ["font"],
                                            ["outdent", "indent"],
                                            ["align", "horizontalRule"],
                                            ["fullScreen", "showBlocks", "codeView", "template"],
                                            "/",
                                            ["print"],
                                            ["link", "image"],
                                            ["undo", "redo"],
                                            ],
                                            templates:arrayTemplates
                                        }}
                                        onChange={e => handleAtualizarMensagem(e)}
                                    />
                                }
                            </div>
                            {/* <Form.Label className="contador-carateres-restantes">
                                    <div className="fonte-cor-1 label-normal">{qtdCaracteresFaltam} caracteres restantes</div>
                            </Form.Label> */}
                        </Col>
                    </Form.Row>

                    <div id="div-versao-img-observacao" className="fonte-cor-1">
                        OBS: Limite recomendável de dimensões da imagem é de 
                        até 552px (largura) x 261px (altura)
                    </div>
                
                    <Form.Row>
                        <Col>
                            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Preencha todos os dados necessários para continuar.</Tooltip>}>
                            { (corpoMensagem != '' && dsVersao != '' && dataVersao != '') ?
                                    <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                        <div className="fonte-cor-4">Salvar</div>
                                    </Button>
                                :
                                    <Button className="botao-confirmar-desabilitado" size="sm">
                                        <div className="fonte-cor-4">Salvar</div>
                                    </Button>
                                }
                            </OverlayTrigger>
                            
                            <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2">Cancelar</div>
                            </Button>
                        </Col>
                    </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={props.cdVersionamento ? "Versão atualizada" : "Versão cadastrada"}
                            mensagemBotao={"Finalizar"}
                        />
                    </div>
                </div>
            }

            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={mensagemErro}
                            // mensagemParagrafo1={"Algo deu errado!"}
                            // mensagemParagrafo2={"Por favor, tente mais tarde."}
                            // mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemInformacao &&
            <div className="componente-modal-overlay">
                <div className="componente-modal">
                    <MensagemInformacao
                        handleMensagemInformacao={handleMensagemInformacao}
                        mensagemTitulo={"Aviso"}
                        mensagemParagrafo1={"A imagem selecionada é muito grande ou está em um formato não suportado."}
                        mensagemParagrafo2={"Por favor, selecione uma imagem com tamanho máximo de até 1Mb."}
                        mensagemBotao={"Ok"}
                    />
                </div>
            </div>
        }
        </Form>
    )
     
}