import React, { useState } from "react";
import {api, api_s3} from '../../../conexoes/api';
import decryptJWT from '../../../utils/decryptJWT'

import ColetaClickstream from '../../clickstreamAnalise/clickstreamColeta.js'
import ModalImportarDescricao from "../../componentesModais/modalImportarArquivo/importarDescricao";

import '../../../css/painelDispositivos/dspImportarDescricao/ImportarDesc.css';

let idInserido = '';

const dadosJSONXLS_dsp = [
  [
    "linha",
    "cliente",
    "operadora",
    "endereco",
    "bairro",
    "cidade",
    "numero",
    "complemento",
    "uf",
    "observacoes",
    "info_coluna",
    "marca_dispositivo",
    "modelo_dispositivo",
    "numero_serial_dispositivo"
  ],
  [
    "'",
    "'",
    "'",
    "'",
    "'",
    "'",
    "'",
    "'",
    "'",
    "'",
    "'",
    "'",
    "'",
    "'"
  ]
]
const dadosJSONCSV_dsp = [
  {
    "linha": "'",
    "cliente": "'",
    "operadora": "'",
    "endereco": "'",
    "bairro": "'",
    "cidade": "'",
    "numero": "'",
    "complemento": "'",
    "uf": "'",
    "observacoes": "'",
    "info_coluna":"'",
    "marca_dispositivo": "'",
    "modelo_dispositivo": "'",
    "numero_serial_dispositivo":"'"
  }
]

const cssClassesListaArquivosDsp = {
  div_class: "container-importar-descricao-lista-arquivos",
  p_class: "tituloLista-importar-arquivo",
  li_class: "lista-importar-arquivo",
  li_p_class: "listaNome-importar-arquivo",
}

export default function ImportarDescricaoDsp(props) {
  const [spinAtivo, setSpinAtivo] = useState(false);

  async function processUploadDsp(uploadedFile, thisObj) {
    ColetaClickstream(1140) // importarDescricaoUpload
    setSpinAtivo(true);
    let arquivos = uploadedFile
    let data = new FormData();
    data.append('file', arquivos.file);
    data.append('cd_usuario', decryptJWT('codigo'));
    data.append('cd_pav_evento_parametro_tipo', 4);

    await api_s3.post("s3/leitura/arquivo", data,{
        onUploadProgress: e => {
        const progress = parseInt(Math.round((e.loaded * 100) / e.total));

        thisObj.updateFile(uploadedFile.id, {
          progress
        });
      }
    })
    .then(response => {
      setSpinAtivo(false);
      if (response.data.status === 200) {
        idInserido = response.data.idInserido
          thisObj.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data._id,
          url: response.data.url
        });
        thisObj.setState({
          dadosArquivo: thisObj.state.dadosArquivo.concat(response.data.dados)
        });
      }
      else{
        setSpinAtivo(false);
        thisObj.updateFile(uploadedFile.id, {
          error: true,
          uploaded: false,
        });
      }
      thisObj.updateFile(uploadedFile.id, {
        uploaded: true,
        id: response.data._id,
        url: response.data.url
      });
      
    })
    .catch((error) => {
      setSpinAtivo(false);
      thisObj.updateFile(uploadedFile.id, {
        error: true
      });
    });
  }

  async function uploadTabelaDsp() {
    ColetaClickstream(1139) // importarDescricaoConfirmar

    return await api.post("m1/alterar/importarDescricao", {
      idInserido,
      'cd_pav_cliente':  decryptJWT('codigoCliente'),
      'n0_codigo_cliente':  decryptJWT('codigoClienteErpAntigo'),
    })
  }

  return (
    <ModalImportarDescricao // DSP
      dispositivos
      titulo="Importar descrição"
      setSpinAtivo={setSpinAtivo}
      spinAtivo={spinAtivo}
      dadosJSONXLS={dadosJSONXLS_dsp}
      dadosJSONCSV={dadosJSONCSV_dsp}
      processUpload={processUploadDsp}
      cssClassesListaArquivos={cssClassesListaArquivosDsp}
      renderizar={props.renderizar}
      setRenderizar={props.setRenderizar}
      uploadTabela={uploadTabelaDsp}

      cancelarCallback={() => ColetaClickstream(1138) /*importarDescricaoCancelar*/}
      onClickBotaoExcluir={() => ColetaClickstream(1144)/*importarDescricaoUploadDelete*/}
      csvLinkOnClick={() => ColetaClickstream(1143)/*importarDescricaoTemplateCSV*/}
      accordionToggleOnClick={() => ColetaClickstream(1142)/*importarDescricaoTemplate*/}
    />
  )
}