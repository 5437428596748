/*COMO USAR:
    #1 IMPORTE O COMPONENTE:
    import [NOME DO SELECT] from ('./componentes/componentensModais/selects/selectGenerico')

    #2 CRIE OS HOOKS PARA CONTROLAR O COMPONENTE:
    const [[GET REVELAR SELECT], [SET REVELAR SELECT]] = useState(false)

    #3 POSICIONE O COMPONENTE NO RETURN DA SUA FUNCAO
    <Form.Group>
        <Form.Label>
            <div className="fonte-cor-1 label-campo">[NOME DO CAMPO]</div>
        </Form.Label>´
        {[GET REVELAR SELECT] === false &&
            <Button className="campo-texto-cor-3 campo-select" onClick={() => [SET REVELAR SELECT](![GET REVELAR SELECT])}>
                <div className="fonte-cor-1 label-campo">{quantidadePacote}</div>
                <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
            </Button>
        }

        {[GET REVELAR SELECT] === true &&
            <[NOME DO SELECT]
                paramRota={[QUAL ROTA DO BACK VAI SER BUSCADA]}
                paramCampo1={[CAMPO PARA SER ADICIONADO AO WHERE]}
                paramCampo2={[CAMPO PARA SER ADICIONADO AO WHERE]}
                paramCampo3={[CAMPO PARA SER ADICIONADO AO WHERE]}
                paramCampo4={[CAMPO PARA SER ADICIONADO AO WHERE]}
                arrayCodigo={[VARIAVEL QUE VAI RECEBER O CÓDIGO]}
                arrayDescricao1={[VARIÁVEL QUE VAI RECEBER A DESCRIÇÃO PRINCIPAL]}
                arrayDescricao2={[VARIÁVEL QUE VAI RECEBER A DESCRIÇÃO SECUNDÁRIA]}
                arrayDescricao3={[VARIÁVEL QUE VAI RECEBER A DESCRIÇÃO SECUNDÁRIA]}
                arrayDescricao4={[VARIÁVEL QUE VAI RECEBER A DESCRIÇÃO SECUNDÁRIA]}
                setRevelarSelect={[SET REVELAR SELECT]}
                placeholder={[VARIÁVEL QUE RECEBEU A DESCRIÇÃO PRINCIPAL]}
                sufixo={[TEXTO PARA COMPLEMENTAR A DESCRIÇÃO PRINCIPAL E O PLACEHOLDER]}
            >
            </[NOME DO SELECT>
        }
    </Form.Group>

    #4 CRIE O USEEFFECT PARA ATUALIZAR O PLACEHOLODER
    useEffect(()=>{
        let placeholderAux = false
        arrayCodigo.forEach(function(item, index){
            if(item === -1){
                placeholderAux = true
            }
        })
        if(placeholderAux === true){
            setPlaceholder(arrayCodigo.length - 1 + " Selecionados")
        }
        else if(arrayCodigo.length === 1){
            setPlaceholder(arrayDsCliente[0])
        }
        else if(arrayCodigo.length === 0){
            setPlaceholder("Selecione")
        }
        else{
            setPlaceholder(arrayCodigo.length + " Selecionados")
        }
    },[arrayCodigo.length, arrayCodigo[0], arrayCodigo[arrayCodigo.length - 1]])

    SE NECESSARIO CRIAR HOOCK const [arrayDummy, setArrayDummy] = useState([]), E PASSAR O setDummy PARA AS setDescricao# QUE NAO SERAO USADOS
    SE NECESSARIO PASSAR null PARA OS paramCampo# QUE NAO SERAO USADOS
*/

import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import {Form} from 'react-bootstrap';

import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
import {setaDropdown} from '../../../utils/alternanciaIcones'

let temporizador = ""

export default function SelectGenericoCheckbox(props) {
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [lsOpcoes, setLsOpcoes] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const [paramBusca, setParamBusca] = useState(undefined);
    const [marcarTodos, setMarcarTodos] = useState(false);
    const [paramBuscaAnterior, setParamBuscaAnterior] = useState(undefined);
    let todosItens = []

    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleSelect(codigo, descricao1, descricao2, descricao3, descricao4, checkbox){
        if(codigo == -1){
            handleSelecionarTodos(checkbox)
        }else{
            if(checkbox === true){
                props.arrayCodigo.push(codigo)
                props.arrayDescricao1.push(descricao1)
                if(descricao2 != undefined && descricao2 != null){
                    props.arrayDescricao2.push(descricao2)
                }
                if(descricao3 != undefined && descricao3 != null){
                    props.arrayDescricao3.push(descricao3)
                }
                if(descricao4 != undefined && descricao4 != null){
                    props.arrayDescricao4.push(descricao4)
                }
                // if(props.arrayCodigo.length === todosItens.length -1){
                //     handleSelecionarTodos(checkbox)
                // }
                
                let tamanhoTodosAux = todosItens.length
                let contadorTodoAux = 0

                todosItens.forEach(function(item, index){
                    props.arrayCodigo.forEach(function(codigo, indexCodigo){
                        if(item.codigo === codigo){
                            contadorTodoAux = contadorTodoAux + 1
                        }
                    })
                })
                if(contadorTodoAux === tamanhoTodosAux - 1){
                    props.arrayCodigo.push(-1)
                    props.arrayDescricao1.push('Todos')
                    setMarcarTodos(!marcarTodos)
                }
                
            }else{
                props.arrayCodigo.forEach(function(item, index){
                    if(props.arrayCodigo[index] === -1){
                        props.arrayCodigo.splice(index, 1)
                        props.arrayDescricao1.splice(index, 1)
                        props.arrayDescricao2.splice(index, 1)
                        props.arrayDescricao3.splice(index, 1)
                        props.arrayDescricao4.splice(index, 1)
                        setMarcarTodos(!marcarTodos)
                    }
                })
                
                props.arrayCodigo.forEach(function(e, index){
                    if(codigo == e){
                        props.arrayCodigo.splice(index, 1)
                    }
                })
                props.arrayDescricao1.forEach(function(e, index){
                    if(descricao1 == e){
                        props.arrayDescricao1.splice(index, 1)
                    }
                })
                props.arrayDescricao2.forEach(function(e, index){
                    if(descricao2 == e){
                        props.arrayDescricao2.splice(index, 1)
                    }
                })
                props.arrayDescricao3.forEach(function(e, index){
                    if(descricao3 == e){
                        props.arrayDescricao3.splice(index, 1)
                    }
                })
                props.arrayDescricao4.forEach(function(e, index){
                    if(descricao4 == e){
                        props.arrayDescricao4.splice(index, 1)
                    }
                })
            }
        }
    }

    function handleSelecionarTodos(checked){
        const length = props.arrayCodigo.length
        let i = 0
        if(paramBusca != undefined && paramBusca != null){
            if(checked === true){
                todosItens.forEach(function(item,i){
                    props.arrayCodigo.forEach(function(e, index){
                        if(item.codigo == e){
                            props.arrayCodigo.splice(index, 1)
                        }
                    })
                    props.arrayDescricao1.forEach(function(e, index){
                        if(item.descricao1 == e){
                            props.arrayDescricao1.splice(index, 1)
                        }
                    })
                    props.arrayDescricao2.forEach(function(e, index){
                        if(item.descricao2 == e){
                            props.arrayDescricao2.splice(index, 1)
                        }
                    })
                    props.arrayDescricao3.forEach(function(e, index){
                        if(item.descricao3 == e){
                            props.arrayDescricao3.splice(index, 1)
                        }
                    })
                    props.arrayDescricao4.forEach(function(e, index){
                        if(item.descricao4 == e){
                            props.arrayDescricao4.splice(index, 1)
                        }
                    })

                    props.arrayCodigo.push(item.codigo)
                    props.arrayDescricao1.push(item.descricao1)
                    if(item.descricao2 != undefined && item.descricao2 != null){
                        props.arrayDescricao2.push(item.descricao2)
                    }
                    if(item.descricao3 != undefined && item.descricao3 != null){
                        props.arrayDescricao3.push(item.descricao3)
                    }
                    if(item.descricao4 != undefined && item.descricao4 != null){
                        props.arrayDescricao4.push(item.descricao4)
                    }
                })
            }else{
                todosItens.forEach(function(j, indexTodos){
                    props.arrayCodigo.forEach(function(e, index){
                        if(j.codigo == e){
                            props.arrayCodigo.splice(index, 1)
                        }
                    })
                    props.arrayDescricao1.forEach(function(e, index){
                        if(j.descricao1 == e){
                            props.arrayDescricao1.splice(index, 1)
                        }
                    })
                    props.arrayDescricao2.forEach(function(e, index){
                        if(j.descricao2 == e){
                            props.arrayDescricao2.splice(index, 1)
                        }
                    })
                    props.arrayDescricao3.forEach(function(e, index){
                        if(j.descricao3 == e){
                            props.arrayDescricao3.splice(index, 1)
                        }
                    })
                    props.arrayDescricao4.forEach(function(e, index){
                        if(j.descricao4 == e){
                            props.arrayDescricao4.splice(index, 1)
                        }
                    })
                })
            }
        }else{
            while(i < length){
                props.arrayCodigo.pop()
                if(props.arrayDescricao1){
                    props.arrayDescricao1.pop()
                }
                if(props.arrayDescricao2){
                    props.arrayDescricao2.pop()
                }
                if(props.arrayDescricao3){
                    props.arrayDescricao3.pop()
                }
                if(props.arrayDescricao4){
                    props.arrayDescricao4.pop()
                }
                i++
            }
            if(checked === true){
                todosItens.forEach(function(item,i){
                    props.arrayCodigo.push(item.codigo)
                    props.arrayDescricao1.push(item.descricao1)
                    if(item.descricao2 != undefined && item.descricao2 != null){
                        props.arrayDescricao2.push(item.descricao2)
                    }
                    if(item.descricao3 != undefined && item.descricao3 != null){
                        props.arrayDescricao3.push(item.descricao3)
                    }
                    if(item.descricao4 != undefined && item.descricao4 != null){
                        props.arrayDescricao4.push(item.descricao4)
                    }
                })
            }
        }
        setMarcarTodos(!marcarTodos)
        setParamBuscaAnterior(paramBusca)
    }
    function handleMarcarCheckboxes(codigo){
        let verificacao = false        
        props.arrayCodigo.forEach(function(e, index){
            if(codigo === e){
                verificacao = true
            }
        })
        return verificacao
    }

    function handleCancelar(){
        props.setRevelarSelect(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderOpcoes(){
            setLsOpcoes([])
            let consulta = await api.post('/m1/consultar/select',{
                                "dados":{
                                    'paramCampo1' : props.paramCampo1,
                                    'paramCampo2' : props.paramCampo2,
                                    'paramCampo3' : props.paramCampo3,
                                    'paramCampo4' : props.paramCampo4,
                                    'paramBusca' : paramBusca
                                },
                                "condicao":{
                                    'paramRota' : props.paramRota,
                                }
                            });

            consulta.data.dados.unshift({codigo:-1,descricao1: 'Todos', descricao2: undefined, descricao3: undefined, descricao4: undefined})
            todosItens = consulta.data.dados
            
            props.arrayCodigo.forEach(function(item,index){
                if(props.arrayCodigo[index] === -1 && (todosItens.length !== props.arrayCodigo.length) && ((paramBusca !== paramBuscaAnterior) ||
                ((paramBusca === undefined || paramBusca === null) && (paramBuscaAnterior === undefined || paramBuscaAnterior === null)))){
                    props.arrayCodigo.splice(index, 1)
                    props.arrayDescricao1.splice(index, 1)
                    props.arrayDescricao2.splice(index, 1)
                    props.arrayDescricao3.splice(index, 1)
                    props.arrayDescricao4.splice(index, 1)
                }
            })

            let tamanhoTodosAux = todosItens.length
            let contadorTodoAux = 0

            if(paramBusca !== null && paramBusca !== undefined){
                todosItens.forEach(function(item, index){
                    props.arrayCodigo.forEach(function(codigo, indexCodigo){
                        if(item.codigo === codigo){
                            contadorTodoAux = contadorTodoAux + 1
                        }
                    })
                })
                if(contadorTodoAux === tamanhoTodosAux - 1){
                    props.arrayCodigo.push(-1)
                    props.arrayDescricao1.push('Todos')
                }
            }

            if(consulta.data.dados.length > 0){
                let objetoConsulta = consulta.data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.codigo}>
                            <div className="campo-select-generico-opcao campo-select-opcao-1">
                                <label className="container-checkbox-item-select">
                                    <input id={item.codigo} type="checkbox" defaultChecked={handleMarcarCheckboxes(item.codigo) ? true : false} onChange={e => handleSelect(item.codigo, item.descricao1, item.descricao2, item.descricao3, item.descricao4, e.target.checked)}/>
                                    <span className="chekmark-checkbox-item-select"></span>
                                </label>
                                <span className="fonte-cor-1 nao-selecionavel">{item.descricao1 + props.sufixo}</span>
                            </div>
                            {consulta.data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsOpcoes(objetoConsulta)
            }else{
                let objetoConsulta = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-generico-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsOpcoes(objetoConsulta)
            }

            setSpinAtivo(false)
        }

        renderOpcoes()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [paramBusca, marcarTodos])

    function handleBusca(valor){
        clearTimeout(temporizador)
        temporizador = setTimeout(() => {
            if(valor.length > 1){
                setParamBusca(valor)
            }else{
                setParamBusca('')
            }
        }, 2000);
    }
    
    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Form.Control ref={input => input && input.focus()} name={props.paramRota} className="campo-texto-cor-3 campo-select-generico" placeholder={props.placeholder} onChange={e=> handleBusca(e.target.value)}/>
                <img className="campo-select-generico-icone-ativado" src={setaDropdown()} alt="drop" />
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsOpcoes}
                </ul>
            </div>
        </Form>
    )
}