import styled from "styled-components";

export const ExibicaoSimultaneaWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 21px;
`;

export const ExibicaoSimultaneaContent = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 435px;
  height: 56px;
  display: grid;
  padding: 12px 16px;
  grid-template-columns: 145px 117px 117px;
  gap: 14px;
  border-radius: 11px;

  button {
    width: 117px;
  }

  p {
    color: ${({ theme }) => theme.cor54}!important;
  }
`;
