import React, { useState, useEffect } from "react";/*eslint-disable*/
import {api} from '../../conexoes/api';/*eslint-disable*/
import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";
import { setaDropdown } from '../../utils/alternanciaIcones';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltroAutomacao(props) {
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroRegra, setFiltroRegra] = useState('')
    const [filtroFormaEnvio, setFiltroFormaEnvio] = useState('')
    const [filtroNivel, setFiltroNivel] = useState('')
    const [itensEvento, setItensEvento] = useState([])

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            if(filtros.ENVIO == 6) setFiltroFormaEnvio('Email')
            if(filtros.ENVIO == 5) setFiltroFormaEnvio('Notificação')
            if(filtros.ENVIO == 3) setFiltroFormaEnvio('Plataforma')
            if(filtros.NIVEL == 1) setFiltroNivel('Normal')
            if(filtros.NIVEL == 2) setFiltroNivel('Atenção')
            if(filtros.NIVEL == 3) setFiltroNivel('Urgente')

            setFiltroRegra(filtros.REGRA)
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()

        handlerCarregarEventos()        
    }, []);

    async function handlerCarregarEventos(){
        await api.get("/m1/consultar/aut_evento")
            .then((response)=>{
                if(response.data.status == 200){
                    setItensEvento(response.data.dados)
                }
            })
    }

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1262) /*painelAutomacaoDropFiltrosFiltrar*/
        if(filtroRegra !== '')arrayFiltrosSimcard.push("Regra: "+filtroRegra.split('*')[0]+"?REGRA")
        if(filtroFormaEnvio !== '')arrayFiltrosSimcard.push("Envio: "+filtroFormaEnvio+"?ENVIO")
        if(filtroNivel !== '')arrayFiltrosSimcard.push("Nivel: "+filtroNivel+"?NIVEL")

        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroRegra == '') verificador.push(1)
            if(filtroFormaEnvio == '') verificador.push(1)
            if(filtroNivel == '') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 3) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroRegra('')
            setFiltroFormaEnvio('')
            setFiltroNivel('')
            props.setFoiLimpado(false)
        }
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroRegra,filtroFormaEnvio,filtroNivel,props.foiLimpado]);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }
    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-regra","imagem-filtro-regra"); ColetaClickstream(1259) /*painelAutomacaoDropFiltrosRegra*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Regra </Form.Label>
                        <img id={"imagem-filtro-regra"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>

                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-regra"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        {
                            itensEvento.map((item_itensEvento,i)=>{
                                return(
                                    <>
                                        <label className="label-radio-filtros label-radio-filtros-automacao fonte-cor-1" onChange={() => setFiltroRegra(item_itensEvento.ds_aut_evento+"*"+item_itensEvento.cd_aut_evento)}>{item_itensEvento.ds_aut_evento}
                                            <input defaultChecked={handlerCheck("REGRA",item_itensEvento.ds_aut_evento+"*"+item_itensEvento.cd_aut_evento)} type="radio" id="REGRA_NONE" name="REGRA" value={item_itensEvento.ds_aut_evento+"*"+item_itensEvento.cd_aut_evento}/>
                                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                                        </label>
                                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                                    </>
                                )
                            })     
                        }
                    </div>
                    
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-forma-envio","imagem-filtro-forma-envio"); ColetaClickstream(1260) /*painelAutomacaoDropFiltrosFormaDeEnvio*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Forma de envio </Form.Label>
                        <img id={"imagem-filtro-forma-envio"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-forma-envio"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroFormaEnvio("Email")}>E-mail
                            <input defaultChecked={handlerCheck("ENVIO","6")} type="radio" id="FORMA_ENVIO_EMAIL" name="ENVIO" value="6"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroFormaEnvio("Notificação")}>Notificação
                            <input defaultChecked={handlerCheck("ENVIO","5")} type="radio" id="FORMA_ENVIO_NOTIFICACAO" name="ENVIO" value="5"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroFormaEnvio("Plataforma")}>Plataforma
                            <input defaultChecked={handlerCheck("ENVIO","3")} type="radio" id="FORMA_ENVIO_PLATAFORMA" name="ENVIO" value="3"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-nivel","imagem-filtro-nivel"); ColetaClickstream(1261) /*painelAutomacaoDropFiltrosNivel*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Nível </Form.Label>
                        <img id={"imagem-filtro-nivel"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-nivel"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroNivel("Normal")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Normal<div className="filtro-automacao-importancia-esfera-verde"/></div>
                            <input defaultChecked={handlerCheck("NIVEL","3")} type="radio" id="NIVEL_NORMAL" name="NIVEL" value="3"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroNivel("Atenção")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Atenção<div className="filtro-automacao-importancia-esfera-amarela"/></div>
                            <input defaultChecked={handlerCheck("NIVEL","2")} type="radio" id="NIVEL_ATENCAO" name="NIVEL" value="2"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros" onChange={() => setFiltroNivel("Urgente")}>
                            <div className="filtro-automacao-importancia-container-esfera fonte-cor-1">Urgente<div className="filtro-automacao-importancia-esfera-vermelha"/></div>
                            <input defaultChecked={handlerCheck("NIVEL","1")} type="radio" id="NIVEL_URGENTE" name="NIVEL" value="1"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}