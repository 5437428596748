import React from 'react';
import moment from 'moment';/*eslint-disable*/
import 'moment-timezone';

export function formatarData(data,semHora,verificadorBloqueioFinanceiro){
    if(data && data != '' && data != null && data != undefined && moment(data)._isValid){
        if(semHora){
            return <p className={verificadorBloqueioFinanceiro?"fonte-cor-7 label-tabela-1":"fonte-cor-1 label-tabela-1"}>{moment(data).format('DD/MM/YYYY')}</p>
        }
        else{
            return <p className={verificadorBloqueioFinanceiro?"fonte-cor-7 label-tabela-1":"fonte-cor-1 label-tabela-1"}>{moment(data).format('DD/MM/YYYY - HH:mm')}</p>
        }
    }
    else{
        return <p className={verificadorBloqueioFinanceiro?"fonte-cor-7 label-tabela-1":"fonte-cor-1 label-tabela-1"}>-</p>
    }
}