import React, { useState, useEffect } from 'react';/*eslint-disable*/
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';/*eslint-disable*/

import { api, api_svdispositivos } from '../../../../conexoes/api'
import moment from 'moment'
import decryptJWT from '../../../../utils/decryptJWT'

export default function GraficoConsumoCicloAtual(props) {
    const [consumo, setConsumo] = useState();
    const [gsm, setGsm] = useState(props.gsm);
    const [consumoContratado, setConsumoContratado] = useState();
    const [totalConsumo, setTotalConsumo] = useState(props.consumoContratado);
    const [previsaoConsumo, setPrevisaoConsumo] = useState(props.previsaoConsumo);
    const [renderizar, setRenderizar] = useState(false);
    const [consumoPrevisao, setConsumoPrevisao] = useState();
    const localTema = localStorage.getItem('tema');

    useEffect(() => {
        async function consumoMensal() {
            let consumoLet = []
            let consumoPrevisaoLet = [{ data: '', consumo_total_bytes: '' }, { data: '', consumo_total_bytes: '' }]
            let consumoContratadoLet = []

            // const { data } = await api.get('/m1/consultar/linhaConsumoCicloAtual?gsm='+gsm);
            // const { data } = await api.get('/m1/consultar/simcard_timeline_consumo?codigo_cliente=' + decryptJWT("codigoClienteErpAntigo") + '&caller_id=' + props.gsm)

            const { data } = await api_svdispositivos.get(`/api/v2/devices/simcard/${props.gsm}/consumption?codigocliente=${decryptJWT("codigoClienteErpAntigo")}&cicloatual=true&clienteDoSimcard=${props.clienteDoSimcard}`)

            //cria 2 variaveis diferentes e passa so dados vindos da chamada
            //Json.parse obriga a copiar os dados do json em vez de apenas o caminho
            let arrayJSONgrafico = []
            data.consumoCicloAtual.map((item, i) => {
                arrayJSONgrafico.push({
                    dia: item.data, // item.dia,
                    consumo: item.consumo,
                    previsao: item.previsao,
                    contratado: props.consumoContratado
                })
            })

            setConsumoContratado(arrayJSONgrafico)
        }
        consumoMensal();
    }, []);

    async function somatorio(consumoLet, consumoPrevisaoLet, cicloAtual) {
        //Muda a forma da data e faz a soma segundo os dias
        Object.keys(consumoLet).forEach(function (item, i) {
            consumoLet[item].data = moment(consumoLet[item].data).format('DD/MM')
            if ((i - 1) == -1) {
                consumoLet[i].consumo_total_bytes = consumoLet[i].consumo_total_bytes
            } else {
                consumoLet[i].consumo_total_bytes += consumoLet[i - 1].consumo_total_bytes
            }
        });

        //transforma em MB
        Object.keys(consumoLet).forEach(function (item, i) {
            consumoLet[i].consumo_total_bytes = consumoLet[i].consumo_total_bytes / 1048576
        })
        //Pega o consumo total ate o momento
        const numeroTotalConsumo = consumoLet.slice(-1)[0].consumo_total_bytes;

        //reseta as posicoes do array
        Object.keys(consumoPrevisaoLet).forEach(function (item, i) {
            consumoPrevisaoLet[item].consumo_total_bytes = 0
        });

        //calculo de previsao
        consumoPrevisaoLet[1].consumo_total_bytes = (numeroTotalConsumo / moment().format('DD')) * cicloAtual

        setConsumo(consumoLet)
        setConsumoPrevisao(consumoPrevisaoLet)
    }

    function TooltipGraficoArea({ payload, label, active }) {
        if (active && payload !== null && payload.length > 0) {
            if (payload[0].payload.consumo !== null && payload[0].payload.consumo !== undefined) {
                return (
                    <div className="container-tooltip-grafico-area-consumo-ultimo">
                        <p className="label-tooltip-grafico-area-consumo-ultimo">{`${payload[0].payload.consumo}`}</p>
                        <div className="triangulo-tooltip-grafico-area-consumo-ultimo"></div>
                        <div className={props.revelarBarraFiltro === false ? "barra-tooltip-grafico-area-consumo-ultimo" : "barra-tooltip-grafico-area-consumo-ultimo-2"}></div>
                        <div className="elipse-tooltip-grafico-area-consumo-ultimo"></div>
                    </div>
                );
            } else {
                return (
                    <div className="container-tooltip-grafico-area-consumo-ultimo">
                        <p className="label-tooltip-grafico-area-consumo-ultimo">{`${payload[0].payload.previsao}`}</p>
                        <div className="triangulo-tooltip-grafico-area-consumo-ultimo"></div>
                        <div className="barra-tooltip-grafico-area-consumo-ultimo"></div>
                        <div className="elipse-tooltip-grafico-area-consumo-ultimo"></div>
                    </div>
                );
            }
        }
        return null;
    }

    return (
        <div className="container-grafico-ciclo-atual">
            <ResponsiveContainer>
                <AreaChart data={consumoContratado} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="verde" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#05C985" stopOpacity={0.2} />
                            <stop offset="95%" stopColor="#05C985" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="amarelo" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#FDCB62" stopOpacity={0.2} />
                            <stop offset="95%" stopColor="#FDCB62" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="vermelho" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#EE423D" stopOpacity={0.2} />
                            <stop offset="95%" stopColor="#EE423D" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="azul" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#1F8EFA" stopOpacity={0.2} />
                            <stop offset="95%" stopColor="#1F8EFA" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid stroke={localTema === 'claro' ? "#656565" : '#656565'} strokeDasharray="0" />
                    <XAxis stroke={localTema === 'claro' ? "#656565" : '#656565'} dataKey="dia" />
                    <YAxis stroke={localTema === 'claro' ? "#656565" : '#656565'} />
                    <Tooltip position={{ y: -20 }} content={<TooltipGraficoArea />} />
                    <Area type="basic" dataKey="consumo" stroke="#05C985" fillOpacity={1} fill="url(#verde)" />
                    <Area type="basic" dataKey="contratado" stroke="#FDCB62" fillOpacity={1} fill="url(#amarelo)" />
                    <Area type="basic" dataKey="previsao" stroke="#1F8EFA" fillOpacity={1} fill="url(#azul)" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}