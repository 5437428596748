//BIBLIOTECAS
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import { Form, Col } from 'react-bootstrap';/*eslint-disable*/
import {api_google} from '../../../../../conexoes/google'
//ICONES
import { elipseVerde } from "../../../../../utils/alternanciaIcones";
// SPINNER
import SpinerVeye from '../../../../../components/spinnerVeye/spinnerVeye.js'

import Mapa from '../../../../googleMap/googleMapSemInfo'
import { GOOGLE_MAPS_GEOCODING_API } from '../../../../../utils/googleMapsApiKey';

export default function DadosCliente(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS
    const [dsEndereco, setDsEndereco] = useState(props.logradourocliente)
    const [dsBairro, setDsBairro] = useState(props.bairrocliente)
    const [dsCidade, setDsCidade] = useState(props.cidadecliente)
    const [dsEstado, setDsEstado] = useState(props.ufcliente)
    const [dsCep, setDsCep] = useState(props.cepcliente)
    const [dsEmailCadastro, setDsEmailCadastro] = useState(props.emailVendedorCliente)
    const [dsNome, setDsNome] = useState(props.vendedorcliente)
    const [dsCpf, setDsCpf] = useState(props.cpfVendedorCliente)
    const [dsTelefone, setDsTelefone] = useState(props.telefoneVendedorCliente)
    const [dsEmailContato, setDsEmailContato] = useState(props.emailVendedorCliente)
    const [dadosMapa, setDadosMapa] = useState()

    const localTema = localStorage.getItem('tema')

    useEffect(() => {

        setSpinAtivo(true)

        async function geoCodeEndereco(){
            if(dsEndereco){
                dsEndereco.replace(" ", "+")
                const {data} = await api_google.get(`/geocode/json?address=${dsEndereco.replace(/ /g, "+")},+${dsCidade.replace(/ /g, "+")},+BR&key=${GOOGLE_MAPS_GEOCODING_API}`)
                setDadosMapa(data.results[0].geometry.location)
            }
        }

        async function handleCarregarDadosCliente(){
        }

        geoCodeEndereco()
        handleCarregarDadosCliente();

        setSpinAtivo(false)
    }, [])
    
    return(
        <Form>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="container-dados-cliente-novo">
                <div className={'container-dados-cliente-wrapper'}>
                    <div className={localTema === 'claro' ? 'dados-cliente' : 'dados-cliente-noturno'}>
                        <div className={'dados-cliente-cadastro'}>
                            <div className={localTema === 'claro' ? 'cadastro-label':'cadastro-label-noturno'}>Cadastro</div>
                            <div className={localTema === 'claro' ? 'cadastro-content' : 'cadastro-content-noturno'}>
                                <div className={'cadastro-content-text'}>Endereço: {dsEndereco}</div>
                                <div className={'cadastro-content-text'}>Estado: {dsEstado}</div>
                                <div className={'cadastro-content-text'}>CEP: {dsCep}</div>
                                <div className={'cadastro-content-text'}>Bairro: {dsBairro}</div>
                                <div className={'cadastro-content-text'}>Cidade: {dsCidade}</div>
                            </div>
                        </div>
                        <div className={'dados-cliente-contato'}>
                            <div className={localTema === 'claro' ? 'cadastro-label':'cadastro-label-noturno'}>Contato</div>
                            <div className={localTema === 'claro' ? 'cadastro-content border-none' : 'cadastro-content-noturno border-none'}>
                                <div className={'cadastro-content-text'}>Telefone: {dsTelefone}</div>
                                <div className={'cadastro-content-text'}>E-mail: {dsEmailContato}</div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className={'container-dados-cliente-mapa-wrapper'}>
                    <div className={localTema === 'claro' ? 'caixa-mapa-background' : 'caixa-mapa-background-noturno'}>
                        <div className={'caixa-mapa'}>
                            {dadosMapa &&
                                <Mapa array={dadosMapa} tipo={"localizacao"}/>
                            }
                        </div>
                    </div>

                </div>

                <>
                    {/*<div className="container-dados-cliente-telas">*/}
                    {/*    <div className="container-dados-cliente-cadastro fundo-cor-7">*/}
                    {/*        <Form.Row>*/}
                    {/*            <Col>*/}
                    {/*                <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>*/}
                    {/*                <Form.Label>*/}
                    {/*                    <div className="fonte-cor-1 label-janela">Cadastro</div>*/}
                    {/*                </Form.Label>*/}
                    {/*            </Col>*/}
                    {/*        </Form.Row>*/}
                    {/*        <Form.Row>*/}
                    {/*            <Col>*/}
                    {/*                <div className="caixa-detalhamento-cliente-1">*/}
                    {/*                        <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>*/}
                    {/*                        <Form.Row>*/}
                    {/*                            <Col>*/}
                    {/*                                <div className="grupo-detalhamento-cliente-1">*/}
                    {/*                                    <div className="coluna-detalhamento-cliente-1">*/}
                    {/*                                        <Form.Label className="fonte-cor-1 label-normal negrito">Endereço: </Form.Label>*/}
                    {/*                                        <span className="fonte-cor-1 label-normal label-dados-cliente"> {dsEndereco !== undefined && dsEndereco !== '' && dsEndereco !== null ? dsEndereco : "-"}</span>*/}
                    {/*                                    </div>*/}
                    {/*                                    <div className="coluna-detalhamento-cliente-1">*/}
                    {/*                                        <Form.Label className="fonte-cor-1 label-normal negrito">Bairro: </Form.Label>*/}
                    {/*                                        <span className="fonte-cor-1 label-normal label-dados-cliente-2"> {dsBairro !== undefined && dsBairro !== '' && dsBairro !== null ? dsBairro : "-"}</span>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                            </Col>*/}
                    {/*                        </Form.Row>*/}
                    {/*                        <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>*/}
                    {/*                        <Form.Row>*/}
                    {/*                            <Col>*/}
                    {/*                                <div className="grupo-detalhamento-cliente-1">*/}
                    {/*                                    <div className="coluna-detalhamento-cliente-1">*/}
                    {/*                                        <Form.Label className="fonte-cor-1 label-normal negrito">Estado: </Form.Label>*/}
                    {/*                                        <span className="fonte-cor-1 label-normal label-dados-cliente"> {dsEstado !== undefined && dsEstado !== '' && dsEstado !== null ? dsEstado : "-"}</span>*/}
                    {/*                                    </div>*/}
                    {/*                                    <div className="coluna-detalhamento-cliente-1">*/}
                    {/*                                        <Form.Label className="fonte-cor-1 label-normal negrito">Cidade: </Form.Label>*/}
                    {/*                                        <span className="fonte-cor-1 label-normal label-dados-cliente-2"> {dsCidade !== undefined && dsCidade !== '' && dsCidade !== null ? dsCidade : "-"}</span>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                            </Col>*/}
                    {/*                        </Form.Row>*/}
                    {/*                        <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>*/}
                    {/*                        <Form.Row>*/}
                    {/*                            <Col>*/}
                    {/*                                <div className="grupo-detalhamento-cliente-1">*/}
                    {/*                                    <div className="coluna-detalhamento-cliente-1">*/}
                    {/*                                        <Form.Label className="fonte-cor-1 label-normal negrito">CEP: </Form.Label>*/}
                    {/*                                        <span className="fonte-cor-1 label-normal label-dados-cliente-3"> {dsCep !== undefined && dsCep !== '' && dsCep !== null ? dsCep : "-"}</span>*/}
                    {/*                                    </div>*/}
                    {/*                                    <div className="coluna-detalhamento-cliente-1">*/}
                    {/*                                        <Form.Label className="fonte-cor-1 label-normal negrito">E-mail: </Form.Label>*/}
                    {/*                                        <span className="fonte-cor-1 label-normal label-dados-cliente-2"> {dsEmailCadastro !== undefined && dsEmailCadastro !== '' && dsEmailCadastro !== null ? dsEmailCadastro : "-"}</span>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                            </Col>*/}
                    {/*                        </Form.Row>*/}
                    {/*                        <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>*/}
                    {/*                    </div>*/}
                    {/*                <Form.Row>*/}
                    {/*                    <Col className="col-contato">*/}
                    {/*                        <img className="icone-label-janela col-contato nao-selecionavel" src={elipseVerde()} alt="elipse"/>*/}
                    {/*                        <Form.Label>*/}
                    {/*                            <div className="fonte-cor-1 label-janela label-janela-contato ">Contato</div>*/}
                    {/*                        </Form.Label>*/}
                    {/*                    </Col>*/}
                    {/*                </Form.Row>*/}
                    {/*                <Form.Row>*/}
                    {/*                    <Col>*/}
                    {/*                        <div className="caixa-detalhamento-cliente-2">*/}
                    {/*                        <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>*/}
                    {/*                            <Form.Row>*/}
                    {/*                                <Col>*/}
                    {/*                                    <div className="grupo-detalhamento-cliente-1">*/}
                    {/*                                        <div className="coluna-detalhamento-cliente-2">*/}
                    {/*                                            <Form.Label className="fonte-cor-1 label-normal negrito">Nome do responsável:</Form.Label>*/}
                    {/*                                            <span className="fonte-cor-1 label-normal label-dados-cliente"> {dsNome !== undefined && dsNome !== '' && dsNome !== null ? dsNome : "-"}</span>*/}
                    {/*                                        </div>*/}
                    {/*                                        <div className="coluna-detalhamento-cliente-1">*/}
                    {/*                                            <Form.Label className="fonte-cor-1 label-normal negrito">CPF:</Form.Label>*/}
                    {/*                                            <span className="fonte-cor-1 label-normal label-dados-cliente-2"> {dsCpf !== 'undefined' && dsCpf !== undefined && dsCpf !== '' && dsCpf !== null ? dsCpf : "-"}</span>*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                            </Form.Row>*/}
                    {/*                            <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>*/}
                    {/*                            <Form.Row>*/}
                    {/*                                <Col>*/}
                    {/*                                    <div className="grupo-detalhamento-cliente-1">*/}
                    {/*                                        <div className="coluna-detalhamento-cliente-1">*/}
                    {/*                                            <Form.Label className="fonte-cor-1 label-normal negrito">Telefone:</Form.Label>*/}
                    {/*                                            <span className="fonte-cor-1 label-normal label-dados-cliente-3"> {dsTelefone !== undefined && dsTelefone !== '' && dsTelefone !== null ? dsTelefone : "-"}</span>*/}
                    {/*                                        </div>*/}
                    {/*                                        <div className="coluna-detalhamento-cliente-1">*/}
                    {/*                                            <Form.Label className="fonte-cor-1 label-normal negrito">E-mail:</Form.Label>*/}
                    {/*                                            <span className="fonte-cor-1 label-normal label-dados-cliente-2"> {dsEmailContato !== undefined && dsEmailContato !== '' && dsEmailContato !== null ? dsEmailContato : "-"}</span>*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                            </Form.Row>*/}
                    {/*                            <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>*/}
                    {/*                        </div>*/}
                    {/*                    </Col>*/}
                    {/*                </Form.Row>*/}
                    {/*            </Col>*/}
                    {/*        </Form.Row>*/}
                    {/*        <Form.Row>*/}
                    {/*            <Col>*/}
                    {/*            <div className="caixa-detalhamento-cliente-3">*/}
                    {/*                <div className="container-dados-mapa">*/}
                    {/*                    {dadosMapa&&*/}
                    {/*                        <Mapa array={dadosMapa} tipo={"localizacao"}/>*/}
                    {/*                    }*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            </Col>*/}
                    {/*        </Form.Row>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </>

            </div>
        </Form>
    )
}