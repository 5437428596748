import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem, filtros, maximoTagsExibidas} from '../../redux/actions'

function Filtros(props) {
    const[nome,setNome] = useState('')
    const[email,setEmail] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []


    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            setNome(filtros.ds_pav_usuario)
            setEmail(filtros.ds_email)
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    function handleFuncaoEstadosRedux(){
        if(nome !== '' && nome)arrayFiltrosSimcard.push("Nome: "+nome+'?ds_pav_usuario')
        if(email !== '' && email)arrayFiltrosSimcard.push("Email: "+email+'?ds_email')

        despacho(maximoTagsExibidas(props.setRevelarDropFiltros))
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }
    

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(nome == '') verificador.push(1)
            if(email =='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 2) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setNome('')
            setEmail('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [nome,email,props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} id="form-filtro-usuario" className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-usuario-nome", "imagem-filtro-usuario-nome")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Nome do usuário </Form.Label>
                        <img id={"imagem-filtro-usuario-nome"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} alt="drop" />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-usuario-nome"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Control
                            name="ds_pav_usuario"
                            className="campo-texto-cor-3 campo-texto-filtro"
                            type="text"
                            maxLength="45"
                            value={nome}
                            placeholder="Nome do usuário"
                            onChange={(e) => setNome(e.target.value)}
                        />
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-usuario-email", "imagem-filtro-usuario-email")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">E-mail do usuário </Form.Label>
                        <img id={"imagem-filtro-usuario-email"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-usuario-email"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Control
                            name="ds_email"
                            className="campo-texto-cor-3 campo-texto-filtro"
                            type="text"
                            maxLength="45"
                            value={email}
                            placeholder="E-mail do Usuário"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit"  onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}

export default Filtros;
