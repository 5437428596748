//IMPORTAÇÕES
import React, {useState, useEffect} from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import {Button, Form, Col, Tabs, Tab, Image} from 'react-bootstrap';/*eslint-disable*/
import {IconeFaturasVerde, IconeSolicitacoes, IconeSolicitacoesVerde, IconeCadastros, IconeCadastroVerde, IconeFaturas, setaDropdown, menuFinanceiro, menuFinanceiroVerdeAtivo, menuSMS, menuSMSVerdeAtivo,     
        menuPedidos, menuPedidosVerdeAtivo, dispositivo, dispositivoVerdeAtivo, olho, olhoVerdeAtivo, admin, adminVerdeAtivo, teste, sms, pedidos, clientes, editarCinza, editarVerde,        
        dashboard, dashboardVerdeAtivo, usuarios, automacao, automacaoVerdeAtivo, relatorios, usuariosVerdeAtivo, outros, outrosVerde, adicionarMaisVerde, confirmar, cancelar, relatoriosAtivo, manutencao, manutencaoVerdeAtivo
        } from '../../../../utils/alternanciaIcones'/*eslint-disable*/
import decryptJWT from '../../../../utils/decryptJWT'/*eslint-disable*/
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'/*eslint-disable*/
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'/*eslint-disable*/
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'/*eslint-disable*/
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'/*eslint-disable*/

export default function MenuCadastroPermissoes(props) {
    const [menuModulo, setMenuModulo] = useState([])/*eslint-disable*/
    const [menuTela, setMenuTela] = useState([])/*eslint-disable*/
    const [menuElemento, setMenuElemento] = useState([])/*eslint-disable*/
    const [permissao, setPermissao] = useState()/*eslint-disable*/
    const [cdPerfil, setCdPerfil] = useState(props.cdPerfil)/*eslint-disable*/
    const [dsPerfil, setDsPerfil] = useState(props.dsPerfil)/*eslint-disable*/
    const [cdMenuModuloAtivo, setCdMenuModuloAtivo] = useState()/*eslint-disable*/
    const [elementoAtivadoPermissoes, setElementoAtivadoPermissoes] = useState()/*eslint-disable*/
    const [tabAtivadoPermissoes, setTabAtivadoPermissoes] = useState()/*eslint-disable*/
    const [moduloTela, setModuloTela] = useState()/*eslint-disable*/
    const [idElementoCor, setIdElementoCor] = useState(0)/*eslint-disable*/
    const [elementoTela, setElementoTela] = useState()/*eslint-disable*/
    const [verificadorPrimeiroModulo, setVerificadorPrimeiroModulo] = useState()/*eslint-disable*/
    const [verificadorPrimeiraTab, setVerificadorPrimeiraTab] = useState()
    const [renderizar, setRenderizar] = useState(false)/*eslint-disable*/
    const [primeiroModulo , setPrimeiroModulo] = useState()/*eslint-disable*/
    const [customizado, setCustomizado] = useState(0)/*eslint-disable*/
    const [adicionarPerfil, setAdicionarPerfil] = useState(false)/*eslint-disable*/
    const [alterarPerfil, setAlterarPerfil] = useState(false)/*eslint-disable*/
    const [revelarSelectPerfil, setRevelarSelectPerfil] = useState(false)/*eslint-disable*/
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)/*eslint-disable*/
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)/*eslint-disable*/
    const [mensagemErro, setMensagemErro] = useState('Algo deu errado, tente novamente. Se o erro persistir entre em contato com o suporte.')/*eslint-disable*/
    const [dummy, setDummy] = useState()/*eslint-disable*/
    const [spinAtivo, setSpinAtivo] = useState(true)/*eslint-disable*/
    const [clienteNivel, setClienteNivel] = useState(parseInt(decryptJWT('clienteNivel')))/*eslint-disable*/
    let indentificadorModuloElemento = false/*eslint-disable*/
    function renderIcones(icone) {
        if (icone === 'outros'){
            return outros();
        }
        if (icone === 'cadastros'){
            return IconeCadastros();
        }
        if (icone === 'solicitacoes'){
            return IconeSolicitacoes();
        }
        if (icone === 'dispositivos'){
            return dispositivo();
        }
        if (icone === "admin"){
            return admin();
        }
        if (icone === "manutecao"){
            return manutencao();
        }
        if (icone === "teste"){
            return teste();
        }
        if (icone === "virtueyes"){
            return olho();
        }
        if (icone === "financeiro"){
            return menuFinanceiro();
        }
        if (icone === "pedidos"){
            return menuPedidos();
        }
        if (icone === "sms"){
            return menuSMS();
        }
        if (icone === "sms"){
            return sms();
        }
        if (icone === "pedidos"){
            return pedidos();
        }
        if (icone === "clientes"){
            return clientes();
        }
        if (icone === "dashboard"){
            return dashboard();
        }
        if (icone === "usuarios"){
            return usuarios();
        }
        if (icone === "automacao"){
            return automacao();
        }
        if (icone === "relatorios"){
            return relatorios();
        }
        if (icone === "faturas"){
            return IconeFaturas();
        }
    }

    function renderIconesVerdes(icone) {
        if (icone === 'outros'){
            return outrosVerde();
        }
        if (icone === 'cadastros'){
            return IconeCadastroVerde();
        }
        if (icone === 'solicitacoes'){
            return IconeSolicitacoesVerde();
        }
        if (icone === 'dispositivos'){
            return dispositivoVerdeAtivo();
        }
        if (icone === 'manutecao'){
            return dispositivoVerdeAtivo();
        }
        if (icone === "admin"){
            return adminVerdeAtivo();
        }
        if (icone === "manutencao"){
            return manutencaoVerdeAtivo();
        }
        if (icone === "virtueyes"){
            return olhoVerdeAtivo();
        }
        if (icone === "financeiro"){
            return menuFinanceiroVerdeAtivo();
        }
        if (icone === "pedidos"){
            return menuPedidosVerdeAtivo();
        }
        if (icone === "sms"){
            return menuSMSVerdeAtivo();
        }
        if (icone === "dashboard"){
            return dashboardVerdeAtivo();
        }
        if (icone === "automacao"){
            return automacaoVerdeAtivo();
        }
        if (icone === "usuarios"){
            return usuariosVerdeAtivo();
        }
        if (icone === "faturas"){
            return IconeFaturasVerde();
        }
        if (icone === "relatorios"){
            return relatoriosAtivo();
        }
    }

    function escondeDropdown(el){
        el.classList.remove("backoffice-modal-cadastro-usuario-permissoes-ativo");
        el.classList.remove("fundo-cor-3");
        el.classList.add("backoffice-modal-cadastro-usuario-permissoes-desativo");
        el.classList.add("fundo-cor-5");
    }

    function handleDropTelas(elementoId,id) {
        setVerificadorPrimeiraTab(1)

        setCdMenuModuloAtivo(id)
        
        let elemento = document.getElementById(elementoId);
        let elementosAtivos = document.querySelectorAll(".backoffice-modal-cadastro-usuario-permissoes-ativo");
        if(elementosAtivos.length > 0){
            elementosAtivos.forEach(escondeDropdown)
        }
        elemento.classList.remove("backoffice-modal-cadastro-usuario-permissoes-desativo");
        elemento.classList.remove("fundo-cor-5");
        elemento.classList.add("backoffice-modal-cadastro-usuario-permissoes-ativo");
        elemento.classList.add("fundo-cor-3");
        
        
        setIdElementoCor(elementoId + "-div")
    }

    function handleDeletarElementos(consultaPermissao,consulta){
        let conter = 0;
        consultaPermissao.map((item, i)=>{
            consultaPermissao[i].map((item, j)=>{
                if(item.indentiPermissao === false){
                    consulta[i].splice(conter,1)
                    conter--
                }
                conter++;
            })
            conter = 0;
        })
    }

    function handlerResetarValores(consultaPermissao){
        consultaPermissao.map((item,i)=>{
            consultaPermissao[i].map((item_consultaPermissao,ij)=>{
                item_consultaPermissao.ativo = 0;
                item_consultaPermissao.indentiPermissao = false;
            })
        })
    }

    function handleAgrupadorPermissoes(consultaPrimeira, consultaSegunda){
        consultaPrimeira.map((usuario, i)=>{
            let contador = 0;
            consultaSegunda.map((perfil, j)=>{
                if(perfil.ds_tipo === 'SISTEMA' && usuario.ds_tipo === 'SISTEMA'){
                    if(usuario.cd_tipo === perfil.cd_tipo){
                        //verifica se a permissao de esta sobrepondo a de perfil e esta desativando um SISTEMA
                        if( usuario.bl_ativo === 0 ){
                            consultaSegunda.splice(contador,1)
                            contador--
                        }
                    }
                }
                if(perfil.ds_tipo === 'MODULO' && usuario.ds_tipo === 'MODULO'){
                    if(usuario.cd_tipo === perfil.cd_tipo){
                        //verifica se a permissao de esta sobrepondo a de perfil e esta desativando um MODULO
    
                        if( usuario.bl_ativo === 0 ){
                            consultaSegunda.splice(contador,1)
                            contador--
                        }
                    }
                }
                if(perfil.ds_tipo === 'TELA' && usuario.ds_tipo === 'TELA'){
                    if(usuario.cd_tipo === perfil.cd_tipo){
                        //verifica se a permissao de esta sobrepondo a de perfil e esta desativando um TELA
                        if( usuario.bl_ativo === 0 ){
                            consultaSegunda.splice(contador,1)
                            contador--
                        }
                    }
                }
                if(perfil.ds_tipo === 'ELEMENTO' && usuario.ds_tipo === 'ELEMENTO'){
                    if(usuario.cd_tipo === perfil.cd_tipo){
                        //verifica se a permissao de esta sobrepondo a de perfil e esta desativando um ELEMENTO
                        if( usuario.bl_ativo === 0 ){
                            consultaSegunda.splice(contador,1)
                            contador--
                        }
                    }
                }
                contador++
            })
        })
    }

    useEffect(() => {
        async function handleCarregarPermissoes() {
            setSpinAtivo(true)

            setPermissao()
            setMenuModulo([])
            setMenuTela([])
            setMenuElemento([])
            setCdMenuModuloAtivo(-1)

            if(cdPerfil != undefined && cdPerfil != 0 && customizado === 1){
                const pegarMenus = await api.post('/m1/servicos/dados/permissao', {
                    'id': decryptJWT("id")
                })
                // const pegarMenus = await api.get('/m1/consultar/permissao?cd_pav_perfil='+decryptJWT("perfil")+"&cd_pav_cliente="+decryptJWT("codigoCliente"))
                if (pegarMenus.data.dados) {
                    //RESETA OS VALORES DE PERMISSAO
                    // handlerResetarValores(pegarMenus.data.dados)
                    props.setPermissoes(pegarMenus.data.dados)
                    setPermissao(pegarMenus.data.dados)
                    setMenuModulo(pegarMenus.data.dados[1])
                    setMenuTela(pegarMenus.data.dados[2])
                    setMenuElemento(pegarMenus.data.dados[3])
                }
            }
            else if(cdPerfil != undefined && cdPerfil != 0 && customizado === 0){
                const pegarMenus = await api.get('/m1/consultar/permissao?cd_pav_perfil='+cdPerfil+"&cd_pav_cliente="+decryptJWT("codigoCliente"))
                if (pegarMenus.data.dados) {
                    const menus = JSON.parse(JSON.stringify(pegarMenus.data.dados))
                    handleDeletarElementos(pegarMenus.data.dados,menus)
                    props.setPermissoes(pegarMenus.data.dados)
                    setPermissao(menus)
                    setMenuModulo(menus[1])
                    setMenuTela(menus[2])
                    setMenuElemento(menus[3])
                }
            }
    
            setSpinAtivo(false)
        }
        handleCarregarPermissoes();
    }, [cdPerfil]); 

    async function mudarCheckPermissao(e){
        let tipo = e.id.split('_')[1]
        let id = e.id.split('_')[2]
        if(tipo == 1){
            menuModulo.map((item_menuModulo, i)=>{
                if(item_menuModulo.id == id){
                    if(item_menuModulo.ativo === 1){
                        item_menuModulo.indentiPermissao = true
                        item_menuModulo.ativo = 0
                    }
                    else{
                        item_menuModulo.indentiPermissao = true
                        item_menuModulo.ativo = 1
                    }
                }
            })
        }
        else if(tipo == 2){
            menuTela.map((item_menuTela,i)=>{
                if(item_menuTela.id == id){
                    if(item_menuTela.ativo === 1){
                        item_menuTela.indentiPermissao = true
                        item_menuTela.ativo = 0
                    }
                    else{
                        item_menuTela.indentiPermissao = true
                        item_menuTela.ativo = 1
                    }
                }
            })
        }   
        else if(tipo == 3){
            menuElemento.map((item_menuElemento, i)=>{
                if(item_menuElemento.id == id){
                    if(item_menuElemento.ativo === 1){
                        item_menuElemento.indentiPermissao = true
                        item_menuElemento.ativo = 0
                    }
                    else{
                        item_menuElemento.indentiPermissao = true
                        item_menuElemento.ativo = 1
                    }
                }
            })
        }
        props.setPermissoes(permissao)
        setRenderizar(!renderizar)
    }

    useEffect(()=>{
        function handlerCarregarModulos(){
            let modulo = menuModulo.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_menuModulo,i)=>{
                if(i == 0) {
                    setVerificadorPrimeiroModulo("moduloUsuario-" + item_menuModulo.cdMenu +"??"+ item_menuModulo.cdMenu)
                }
                return(
                    <li className="backoffice-modal-cadastro-usuario-lista-modulos fundo-cor-5" key={"moduloUsuario-" + item_menuModulo.cdMenu} id={"moduloUsuario-" + item_menuModulo.cdMenu} onClick={(e)=> handleDropTelas("moduloUsuario-" + item_menuModulo.cdMenu, item_menuModulo.cdMenu)}>
                        <img className={idElementoCor === ("moduloUsuario-" + item_menuModulo.cdMenu + '-div')?"backoffice-modal-cadastro-usuario-permissoes-icone":"backoffice-modal-cadastro-usuario-permissoes-icone-invisivel"} src={renderIconesVerdes(item_menuModulo.icone)} alt='ico'/>
                        <img className={idElementoCor === ("moduloUsuario-" + item_menuModulo.cdMenu + '-div')?"backoffice-modal-cadastro-usuario-permissoes-icone-invisivel":"backoffice-modal-cadastro-usuario-permissoes-icone"} src={renderIcones(item_menuModulo.icone)} alt='ico'/>
                        <span onBeforeInput to={"/" + item_menuModulo.rota}  className="backoffice-modal-cadastro-usuario-lista-modulos-label fonte-cor-1">{item_menuModulo.label}</span>
                        
                        {customizado === 1 &&
                            <div className="backoffice-modal-cadastro-usuario-lista-modulos-container-switch">
                                <div class="flipswitch">
                                    <input type="checkbox" name="flipswitch" defaultChecked={item_menuModulo.ativo} class="flipswitch-cb" id={'_1_'+item_menuModulo.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                    <label class="flipswitch-label" for={'_1_'+item_menuModulo.id+'_switch'}>
                                        <div class="flipswitch-inner flipswitch-cor-3"></div>
                                        <div class="flipswitch-switch"></div>
                                    </label>                                
                                </div>
                            </div>
                        }
                    </li>
                )
            })
            setModuloTela(modulo)
        }
        handlerCarregarModulos()
    },[cdMenuModuloAtivo,menuModulo])

    useEffect(()=>{
        function handlerCarregarElementos(){
            let verificadorFirstPassada = 1;
            let elementoTelatemporario = 
                    menuTela.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_menuTela,i)=>{
                        if(parseInt(cdMenuModuloAtivo) === parseInt(item_menuTela.menuPai)){
                            if(verificadorPrimeiraTab == 1 && verificadorFirstPassada == 1){
                                setTabAtivadoPermissoes(item_menuTela.label)
                                setElementoAtivadoPermissoes(item_menuTela.label)
                                setVerificadorPrimeiraTab(2);
                                verificadorFirstPassada++
                            }
                            return( 
                                <Tab onClick={() => setTabAtivadoPermissoes(item_menuTela.label)} tabClassName={elementoAtivadoPermissoes == item_menuTela.label? "tab-elementos-permissoes-cadastro-usuario-cliente-ativado fonte-cor-1":"tab-elementos-permissoes-cadastro-usuario-cliente-desativado fonte-cor-1"} eventKey={item_menuTela.label} title={item_menuTela.label}>
                                    <div className="campo-select-divisor-cor-1 campo-divisor"></div>

                                    {customizado === 1 &&
                                        <div className="backoffice-modal-cadastro-usuario-permissoes-container-switch">
                                            <div class="flipswitch">
                                                <input type="checkbox" name="flipswitch" defaultChecked={item_menuTela.ativo} class="flipswitch-cb" id={'_2_'+item_menuTela.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                <label class="flipswitch-label" for={'_2_'+item_menuTela.id+'_switch'}>
                                                    <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                    <div class="flipswitch-switch"></div>
                                                </label>                                
                                            </div>
                                            <span className="backoffice-modal-cadastro-usuario-permissoes-label-switch fonte-cor-1">Habilitar</span>
                                        </div>
                                    }

                                    <div className="container-tab-elementos-permissoes-cadastro-usuario-cliente">
                                        {menuElemento.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_menuElemento,i)=>{
                                            if(item_menuElemento.menuPai === item_menuTela.cdMenu){
                                                indentificadorModuloElemento = true;
                                                return(
                                                    <div className='conteudo-tab-elementos-permissoes-cadastro-usuario-cliente'>

                                                        {customizado === 1 ?
                                                            <div class="flipswitch">
                                                                <input type="checkbox" defaultChecked={item_menuElemento.ativo} disabled={!item_menuTela.ativo} name="flipswitch" class={item_menuTela.ativo?"flipswitch-cb":"flipswitch-cb-desativado"} id={'_3_'+item_menuElemento.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                                <label class="flipswitch-label" for={'_3_'+item_menuElemento.id+'_switch'}>
                                                                    <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                                    <div class="flipswitch-switch"></div>
                                                                </label>                                
                                                            </div>
                                                        :
                                                            <Image className="imagem-conteudo-tab-elementos-permissoes-cadastro-usuario-cliente" src={item_menuElemento.ativo == 1 ?confirmar(): naoPermitido()}/>
                                                        }

                                                        <span className="backoffice-modal-cadastro-usuario-permissoes-label-switch fonte-cor-1">{item_menuElemento.label}</span>
                                                        <div className="campo-select-divisor-cor-1 divisoria-cadastro-usuario-cliente"></div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </Tab>
                            )
                        }
                    })
            if(indentificadorModuloElemento){
                setElementoTela(elementoTelatemporario)
            }
            else{
                let elementoTelatemporario = 
                    menuModulo.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_modulo,i)=>{
                        if(parseInt(cdMenuModuloAtivo) === parseInt(item_modulo.cdMenu)){
                            return( menuElemento.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_menuElemento,iml)=>{
                                    if(item_menuElemento.menuPai === item_modulo.cdMenu){
                                        if(verificadorPrimeiraTab == 1 && verificadorFirstPassada == 1) {
                                            setTabAtivadoPermissoes(item_menuElemento.label)
                                            setElementoAtivadoPermissoes(item_menuElemento.label)
                                            setVerificadorPrimeiraTab(2);
                                            verificadorFirstPassada++
                                        }
                                        return(
                                            <Tab id={item_menuElemento.label} onClick={() => setTabAtivadoPermissoes(item_menuElemento.label)} tabClassName={elementoAtivadoPermissoes == item_menuElemento.label? "tab-elementos-permissoes-cadastro-usuario-cliente-ativado fonte-cor-1":"tab-elementos-permissoes-cadastro-usuario-cliente-desativado fonte-cor-1"} eventKey={item_menuElemento.label} title={item_menuElemento.label}>
                                                <div className="campo-select-divisor-cor-1 campo-divisor"></div>
                                                
                                                {customizado === 1 &&
                                                    <div className="backoffice-modal-cadastro-usuario-permissoes-container-switch">
                                                        <div class="flipswitch">
                                                            <input type="checkbox" defaultChecked={item_menuElemento.ativo} name="flipswitch" class="flipswitch-cb" id={'_3_'+item_menuElemento.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                            <label class="flipswitch-label" for={'_3_'+item_menuElemento.id+'_switch'}>
                                                                <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                                <div class="flipswitch-switch"></div>
                                                            </label>                                
                                                        </div>
                                                        <span className="backoffice-modal-cadastro-usuario-permissoes-label-switch fonte-cor-1">Habilitar</span>
                                                    </div>
                                                }

                                                <div className="container-tab-elementos-permissoes-cadastro-usuario-cliente">
                                                    {menuElemento.filter(modulo => modulo.ativo == 1 && modulo.indentiPermissao == true && modulo.menuPai !== null && modulo.n0_nivel >= clienteNivel).map((item_menuElemento_filho,i)=>{
                                                        if(item_menuElemento.cdMenu === item_menuElemento_filho.menuPai){
                                                            indentificadorModuloElemento = true;
                                                            return(
                                                                <div className='conteudo-tab-elementos-permissoes-cadastro-usuario-cliente'>
                                                                    {customizado === 1 ?
                                                                        <div class="flipswitch">
                                                                            <input type="checkbox" defaultChecked={item_menuElemento_filho.ativo} disabled={!item_menuElemento.ativo} name="flipswitch" class={item_menuElemento.ativo?"flipswitch-cb":"flipswitch-cb-desativado"} id={'_3_'+item_menuElemento_filho.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                                            <label class="flipswitch-label" for={'_3_'+item_menuElemento_filho.id+'_switch'}>
                                                                                <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                                                <div class="flipswitch-switch"></div>
                                                                            </label>                                
                                                                        </div>
                                                                        :
                                                                        <Image className="imagem-conteudo-tab-elementos-permissoes-cadastro-usuario-cliente" src={item_menuElemento_filho.ativo == 1 ?confirmar(): naoPermitido()}/>
                                                                    }

                                                                    <span className="backoffice-modal-cadastro-usuario-permissoes-label-switch fonte-cor-1">{item_menuElemento_filho.label}</span>
                                                                    <div className="campo-select-divisor-cor-1 divisoria-cadastro-usuario-cliente"></div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </Tab>
                                        )
                                    }
                                })
                            )
                        }
                    })
                setElementoTela(elementoTelatemporario)
            }
                
        }
        handlerCarregarElementos()
    },[cdMenuModuloAtivo,renderizar,elementoAtivadoPermissoes,tabAtivadoPermissoes])
    
    function handerAlterarTabPermissoes(k){
        setElementoAtivadoPermissoes(k)
        setTabAtivadoPermissoes(k)
    }

    useEffect(()=>{
        if(primeiroModulo == 1 && verificadorPrimeiroModulo !== ''){
            setPrimeiroModulo(2)
            handleDropTelas(verificadorPrimeiroModulo.split('??')[0], verificadorPrimeiroModulo.split('??')[1])
        }
    },[verificadorPrimeiroModulo])

    function botaoPressionado(event) {
        if(event.key === "Enter"){

            if(alterarPerfil){
                handlerAlterarPerfil()
            }
            if(adicionarPerfil && !alterarPerfil){
                handlerCadastrarPerfil()
            }
        }
    }

    useEffect(() => {
        props.setCdPerfil(cdPerfil)
    }, [cdPerfil])

    async function handlerCadastrarPerfil(){
        if(dsPerfil && dsPerfil != ''){
            props.setSpinAtivo(true)
            await api.post('/m1/cadastrar/perfil',{
                "ds_pav_perfil":dsPerfil,
                "cd_pav_cliente":decryptJWT("codigoCliente"),
                "cd_pav_usuario":decryptJWT("codigo"),
                "fl_customizado":1,
                'fl_virtueyes': 0,
                'fl_atribuivel': 0,
                'bl_pav_ativo': 1,
                'n0_precedencia':7
            },{timeout:20000})
            .then((response)=>{
                if(response.status != 200){
                    setMensagemErro(response.data.info)
                    handleMensagemErro(false)
                    setCriouUsuario(true)
                }
                else{
                    setCustomizado(1)
                    handleMensagemSucesso(false)
                    setCdPerfil(response.data.dados.idInserido)
                    setDsPerfil(dsPerfil)
                    setAdicionarPerfil(false)
                }
                props.setSpinAtivo(false)
            })
            .catch((error)=>{
                setMensagemErro('Ocorreu um erro no cadastro de novo perfil')
                handleMensagemErro(false)
                props.setSpinAtivo(false)
            })
        }
    }

    async function handlerAlterarPerfil(){
        if(dsPerfil && dsPerfil != ''){
            props.setSpinAtivo(true)
            await api.post('/m1/alterar/perfil',{
                "dados":{
                    "ds_pav_perfil":dsPerfil,
                    "cd_pav_usuario_updated":decryptJWT("codigo")
                },
                "condicao":{
                    "cd_pav_perfil":cdPerfil
                }
            })
            .then((response)=>{
                if(response.status != 200){
                    setMensagemErro(response.data.info)
                    handleMensagemErro(false)
                }
                else{
                    handleMensagemSucesso(false)
                    setDsPerfil(dsPerfil)
                    setAlterarPerfil(false)
                }
                props.setSpinAtivo(false)
            })
            .catch(()=>{
                props.setSpinAtivo(false)
            })
        }
    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)            
        }
        if (confirmacao) {
            //ALGUMA FUNÇÃO
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
        }
        if (confirmacao === true) {
            //ALGUMA FUNÇÃO
        }
    }

    return(
        <>
            <Form.Row>
                <Col>
                    <Form.Group className='backoffice-cadastro-usuario-coluna-1'>
                        <Form.Label>
                            <div className='fonte-cor-1 label-campo'>Perfil *</div>
                        </Form.Label>
                        {adicionarPerfil || alterarPerfil ?
                            <>
                                <Form.Control
                                    id="criar-perfil-usuario"
                                    className="campo-texto-cor-3 campo-texto"
                                    placeholder="Digite o nome do perfil"
                                    value={dsPerfil}
                                    onKeyDown={e => botaoPressionado(e)}
                                    onChange={e => setDsPerfil(e.target.value)}
                                />
                            </>
                        :
                            <>
                                { revelarSelectPerfil === false &&
                                    <Button className='campo-texto-cor-3 campo-select' onClick={() => setRevelarSelectPerfil(!revelarSelectPerfil)}>
                                        <div className='fonte-cor-1 label-campo campo-texto-select-tamanho'>{dsPerfil}</div>
                                        <img className={revelarSelectPerfil?'campo-select-icone-ativado nao-selecionavel':'campo-select-icone-desativado nao-selecionavel'} src={setaDropdown()} alt='drop' />
                                    </Button>
                                }
                                { revelarSelectPerfil === true &&
                                        <SelectGenerico
                                            paramRota={'perfil_cadastro'}
                                            paramCampo1={decryptJWT('codigoCliente')}
                                            paramCampo2={decryptJWT('tipoPerfil')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdPerfil}
                                            setDescricao1={setDsPerfil}
                                            setDescricao2={setCustomizado}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectPerfil}
                                            placeholder={dsPerfil}
                                            sufixo={''}
                                        >
                                        </SelectGenerico>
                                }
                            </>
                        }
                        
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='backoffice-cadastro-usuario-coluna-2'>
                        {/* CONFIRMAR A ALTERACAO DO PERFIL*/}
                        { alterarPerfil &&
                            <div className="container-botoes-cadastro-usuario-backoffice">
                                <Button className="botao-adicionar-pedidos-novo-simcard margem-esquerda-pedidos-novo-simcard" variant="success" size="sm" onClick={() => handlerAlterarPerfil()} >
                                    <img className="icone-botao-adicionar-pedidos-novo-simcard" src={confirmar()} alt="fechar-janela"/>
                                </Button>
                                <Button className="botao-adicionar-pedidos-novo-simcard" variant="success" size="sm" onClick={() => {setAlterarPerfil(false);s(dsPerfil)}}>
                                    <img className="icone-botao-adicionar-pedidos-novo-simcard" src={cancelar()} alt="fechar-janela"/>
                                </Button>
                            </div>
                        }
                        {/* ALTERAR DADOS COM O NAO CUSTOMIZADO  */}
                        { !adicionarPerfil && cdPerfil && !alterarPerfil && customizado === 1 &&
                            <div className="container-botoes-cadastro-usuario-backoffice">
                                <Button className="botao-adicionar-pedidos-novo-simcard margem-esquerda-pedidos-novo-simcard" variant="success" size="sm" onClick={() => setAdicionarPerfil(true)}>
                                    <img className="icone-botao-adicionar-pedidos-novo-simcard" src={adicionarMaisVerde()} alt="fechar-janela" />
                                </Button>
                                <Button className="botao-editar-pedidos-novo-simcard" variant="success" size="sm" onClick={() => setAlterarPerfil(true)}>
                                    <img className="icone-botao-editar-pedidos-novo-simcard" src={editarVerde()} alt="fechar-janela"/>
                                </Button>
                            </div>
                        }
                        {/* ALTERAR DADOS COM O CUSTOMIZADO  */}
                        { !adicionarPerfil && cdPerfil && !alterarPerfil && customizado === 0 &&
                            <div className="container-botoes-cadastro-usuario-backoffice">
                                <Button className="botao-adicionar-pedidos-novo-simcard margem-esquerda-pedidos-novo-simcard" variant="success" size="sm" onClick={() => setAdicionarPerfil(true)}>
                                    <img className="icone-botao-adicionar-pedidos-novo-simcard" src={adicionarMaisVerde()} alt="fechar-janela" />
                                </Button>
                                <Button className="botao-editar-pedidos-novo-simcard-cinza" variant="success" size="sm" >
                                    <img className="icone-botao-editar-pedidos-novo-simcard" src={editarCinza()} alt="fechar-janela"/>
                                </Button>
                            </div>
                        }
                        {/* CONFIRMAR O CADASTRO DO PERFIL*/}
                        { adicionarPerfil && !alterarPerfil &&
                            <div className="container-botoes-cadastro-usuario-backoffice">
                                <Button className="botao-adicionar-pedidos-novo-simcard margem-esquerda-pedidos-novo-simcard" variant="success" size="sm" onClick={() => handlerCadastrarPerfil()}>
                                    <img className="icone-botao-adicionar-pedidos-novo-simcard" src={confirmar()} alt="fechar-janela" />
                                </Button>
                                    <Button className="botao-adicionar-pedidos-novo-simcard" variant="success" size="sm" onClick={() => {setAdicionarPerfil(false);setDsPerfil('');}}>
                                    <img className="icone-botao-adicionar-pedidos-novo-simcard" src={cancelar()} alt="fechar-janela"/>
                                </Button>
                            </div>
                        }

                        {/* INICIAL */}
                        { !adicionarPerfil && !cdPerfil && !alterarPerfil && 
                            <div className="container-botoes-cadastro-usuario-backoffice">
                                <Button className="botao-adicionar-pedidos-novo-simcard margem-esquerda-pedidos-novo-simcard" variant="success" size="sm"  onClick={() => setAdicionarPerfil(true)}>
                                    <img className="icone-botao-adicionar-pedidos-novo-simcard" src={adicionarMaisVerde()} alt="fechar-janela"/>
                                </Button>
                                <Button disabled className="botao-editar-pedidos-novo-simcard-cinza" variant="success" size="sm">
                                    <img className="icone-botao-editar-pedidos-novo-simcard" src={editarCinza()} alt="fechar-janela" />
                                </Button>
                            </div>
                        }
                    </Form.Group>
                </Col>
            </Form.Row>
            {moduloTela != undefined && elementoTela != undefined && 
            <>
                { cdPerfil != undefined && 
                    <Form.Row>    
                        {spinAtivo && <div className='componente-spinner-overlay'><div className='componente-spinner-container'><SpinerVeye/></div></div>}
                        <Col>
                            <div className="backoffice-modal-cadastro-usuario-container-permissoes">
                                <div className="backoffice-modal-cadastro-usuario-container-lista-modulos fundo-cor-5">
                                    {
                                        moduloTela
                                    }
                                </div>
                                <div className="backoffice-modal-cadastro-usuario-lista-permissoes fundo-cor-11">
                                    <Tabs activeKey={tabAtivadoPermissoes} onSelect={(k) => handerAlterarTabPermissoes(k)} class="backoffice-cadastro-usuario-permissoes-tabs" id="uncontrolled-tab-example">
                                        {
                                            elementoTela
                                        }
                                    </Tabs>
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                }
            </>
            }
            { revelarMensagemSucesso &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={'Sucesso'}
                            mensagemParagrafo1={'Perfil '+dsPerfil+' cadastrado com sucesso.'}
                            mensagemBotao={'Continuar'}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={'Erro'}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={'Retornar'}
                        />
                    </div>
                </div>
            }
        </>
    )
}
