import React, { useEffect, useState } from 'react'
import { revelarReenviarSolicitacaoFaturas } from '../../../../redux/actions';
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao';
import { useDispatch } from 'react-redux';
import { getDadosReenviarSolicitacaoFat } from './funcoes';
import { RenderMensagemSucesso } from '../../../componentesModais/mensagens/mensagemSucesso';
import { RenderMensagemErro } from '../../../componentesModais/mensagens/mensagemErro';
import SpinnerVeye from '../../../spinnerVeye/spinnerVeye';

function ReenviarSolicitacaoFaturas(props) {
    const despacho = useDispatch();

    const [spinAtivo, setSpinAtivo] = useState(false); // Spinner
    const [confirmou, setConfirmou] = useState(false);
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [textoErro, setTextoErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)

    useEffect(() => {
        const statusConcluidaParcialOuFalhaTotal = [6, 7].includes(props.cdStatus);
        if(!statusConcluidaParcialOuFalhaTotal) {
            setTextoErro("Essa solicitação não possui linhas com erro para reenviar")
            setRevelarMensagemErro(true)
        }
    }, [props.renderizar, props.cdStatus])

    async function reenviarSolicitacao() {
        setSpinAtivo(true)

        const bodyReenviar = await getDadosReenviarSolicitacaoFat(props.cdSolicitacao)

        if(bodyReenviar) {
            handleMensagemSucesso();
        } else {
            mostrarMensagemErro('Erro ao criar nova solicitação')
        }

        setSpinAtivo(false)
    }

    function handleMensagemAtencao(confirmacao){
        if(confirmacao) {
            setConfirmou(true);
            reenviarSolicitacao();
        } else {
            handleCancelar();
        }
    }

    function handleMensagemSucesso(){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            finalizar()
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            handleCancelar();
        }
    }

    function handleCancelar() {
        fecharModal();
    }

    function finalizar() {
        fecharModal();
        props.setRenderizar(!props.renderizar)
    }

    function fecharModal() {
        limparDados()
        despacho(revelarReenviarSolicitacaoFaturas(false))
    }

    function limparDados() {
        setSpinAtivo(false)
        setConfirmou(false)
        setRevelarMensagemErro(false)
        setTextoErro(false)
        setRevelarMensagemSucesso(false)
    }

    function mostrarMensagemErro(texto) {
        setTextoErro(texto)
        handleMensagemErro(false);
    }

  return (
    <>
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinnerVeye/></div></div> }

        {!confirmou && <MensagemAtencao
            handleMensagemAtencao={(v) => handleMensagemAtencao(v)}
            mensagemTitulo={'Deseja reenviar a solicitação selecionada?'}
            mensagemBotao='Confirmar'
        />}

        {revelarMensagemSucesso && (
            <RenderMensagemSucesso
                handleMensagemSucesso={handleMensagemSucesso}
                mensagemTitulo={"Sucesso"}
                mensagemParagrafo1={"Solicitação enviada com sucesso."}
                mensagemBotao={"FECHAR"}
            />
        )}

        {revelarMensagemErro &&
            <>
                {confirmou ?
                    (<RenderMensagemErro
                        handleMensagemErro={handleMensagemErro}
                        mensagemTitulo={"Erro"}
                        mensagemParagrafo1={textoErro}
                        mensagemBotao={"FECHAR"}
                    />
                ) : (
                    <MensagemAtencao
                        handleMensagemAtencao={handleMensagemErro}
                        mensagemTitulo={"Atenção"}
                        mensagemParagrafo1={'Essa solicitação não possui linhas com erro para reenviar'}
                        mensagemBotao={"FECHAR"}
                        semBotaoCancelar
                    />
                )}
            </>
        }
    </>
  )
}

export default ReenviarSolicitacaoFaturas