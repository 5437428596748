import React, { useState, useRef, useEffect } from "react";
import Modal from "../../Mantine/Modal";
import Stepper from "../../Mantine/Stepper";
import Grid from "../../Mantine/Grid";
import Select from "../../Mantine/Select";
import Botao from "../../Mantine/Botao";
import NumberInput from "../../Mantine/NumberInput";
import Input from "../../Mantine/Input";
import lixeiraVermelho from "../../../icones/iconesVersaoVermelha/lixeira_vermelho.svg";
import addNovoEndereco from "../../../icones/iconesVersaoClara/add_novo_endereco.svg";
import alert from "../../../icones/iconesVersaoVermelha/alert.svg";
import Divider from "../../Mantine/Divider";
import ActionIcon from "../../Mantine/ActionIcon";
import { OrdersService } from "../../../services/http/orders.service";
import { useQuery } from "react-query";
import shippingOptions from "../../../statics/shippingOptions.json";
import decryptJWT from "../../../utils/decryptJWT";
import { usePedidos } from "../../../stores/usePedidos";
import Spinner from "../../spinnerVeye/spinnerVeye";
import { validatePhone, validateCep } from "../../../utils/genericValidators";
import { formatPhone, formatCep } from "../../../utils/genericFormatters";
import * as S from "./styles";

const baseHeaders = [
  "#",
  "Operadoras",
  "Plano",
  "Tamanho SIM Card",
  "Quantidade",
];
const tableHeaders = {
  main: baseHeaders,
  additional: baseHeaders.map((header, index) => (index === 0 ? "" : header)),
};
const fields = [
  { label: "#" },
  { label: "Operadoras", placeholder: "Selecione", options: "carriers" },
  { label: "Plano", placeholder: "Selecione", options: "plans" },
  {
    label: "Tamanho SIM Card",
    placeholder: "Selecione",
    options: "types",
  },
  { label: "Quantidade", placeholder: "Insira" },
];

const DATA_SENT = {
  responsavel: "",
  formaDeEnvio: "",
  telefone: "",
  tipoDeEndereco: "",
  nomeEndereco: "",
};

const DATA_ADDRESS = {
  numero: null,
  endereco: null,
  estado: null,
  bairro: null,
  cidade: null,
  cep: null,
  infoAdicional: null,
};

const DEFAULT_VALUE = {
  _: null,
  carriers: "",
  plans: "",
  types: "",
  quantidade: "",
  removerLote: true,
};

const colSize = ["content", 3, 3, 3, 2, "content"];
const gridDataSent = [3, 3, 3, 3];

export default function PedidoSimcardsVirgens({ opened, close }) {
  const viewport = useRef(null);
  const { setRevelarMensagemSucessoPedidoSimcardVirgem } = usePedidos();
  const codigoCliente = Number(decryptJWT("codigoCliente"));

  const scrollToBottom = () => {
    viewport?.current?.scrollTo({
      top: viewport?.current?.scrollHeight,
      behavior: "smooth",
    });
  };

  const isEscuro = () => {
    const tema = localStorage.getItem("tema");
    return tema === "escuro" ? true : false;
  };

  const defaultFieldValues = DEFAULT_VALUE;
  const DEFAULT_DATA = DATA_SENT;
  const DEFAULT_ADDRESS = DATA_ADDRESS;
  const DEFAULT_OPTIONS_PER_LINE = {
    carriers: [],
    plans: [],
    types: [],
  };

  const {
    postOrder,
    getSimcardsTypeOrder,
    getPlansOrder,
    getCarriersOrder,
    getAddress,
    getUf,
    getCep,
  } = OrdersService;

  const [isFetchingState, setIsFetchingState] = useState(false);
  const [scrollToBotton, setScrollToBotton] = useState(false);
  const [disabledNextStep, setdisabledNextStep] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const [nomeEndereco, setNomeEndereco] = useState(false);
  const [hasError, setHasError] = useState(true);
  const [options, setsOptions] = useState([DEFAULT_OPTIONS_PER_LINE]);
  const [fetchOptions, setFetchOptions] = useState(false);
  const [fetchOrder, setFetchOrder] = useState(false);
  const [indexAndValueOptions, setIndexAndValueOptions] = useState(null);
  const [addressOptions, setAddressOptions] = useState([]);
  const [ufOptions, setUfOptions] = useState([]);
  const [addressClient, setAddressClient] = useState([]);
  const [stepFields, setStepFields] = useState({
    lotes: [defaultFieldValues],
    dados: { ...DEFAULT_DATA, ...DEFAULT_ADDRESS },
  });

  useQuery(
    ["order/list/carriers"],
    async () => {
      try {
        setIsFetchingState(true);
        const carriers = await getCarriersOrder();
        const updateOptions = {
          ...DEFAULT_OPTIONS_PER_LINE,
          carriers: (carriers || [])?.map((item) => ({
            value: item.CdOperadora,
            label: item.Operadora,
          })),
        };
        setsOptions([updateOptions]);
      } finally {
        setIsFetchingState(false);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    ["order/list/address"],
    async () => {
      const [address, ufs] = await Promise.all([
        getAddress(codigoCliente),
        getUf(),
      ]);

      const updatedAddress = address.dados.map((i) => {
        const {
          ds_nome: name,
          ds_nome_fantasia: fantasia,
          cd_dsp_cliente_endereco_entrega: code,
        } = i;

        return {
          label: `${name} / ${fantasia}`,
          value: code,
          group: "Nome / Nome fantasia",
        };
      });
      const opt = ufs.dados?.map((uf) => {
        return {
          ...uf,
          label: uf.ds_pav_uf,
          value: uf.cd_pav_uf,
        };
      });
      setAddressClient(address.dados);
      setAddressOptions(updatedAddress);
      setUfOptions(opt);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { refetch } = useQuery(
    [`order/list/optionsSimcards?operadora=${indexAndValueOptions?.value}`],
    async () => {
      try {
        setIsFetchingState(true);
        const { index, value } = indexAndValueOptions;
        const [plansArr, typesArr] = await Promise.all([
          getPlansOrder(value),
          getSimcardsTypeOrder(value),
        ]);
        const plans = plansArr
          .filter((plan) => plan.CdPlano && plan.Plano)
          .map((plan) => ({
            ...plan,
            value: plan.CdPlano,
            label: plan.Plano,
          }));

        const types = typesArr
          .filter((type) => type.CdSimcardTipo && type.SimcarTipo)
          .map((type) => ({
            ...type,
            value: type.CdSimcardTipo,
            label: type.SimcarTipo,
          }));
        const newOptions = options;
        newOptions[index] = {
          ...options[index],
          plans,
          types,
        };
        setsOptions(newOptions);
      } finally {
        setIsFetchingState(false);
      }
    },
    {
      enabled: fetchOptions,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const onSubmit = async () => {
    try {
      setIsFetchingState(true);

      const { lotes, dados } = stepFields;
      const formatBatchs = lotes.map((lote, idx) => {
        const { carriers, plans, types, quantidade } = lote;
        return {
          CdOperadora: carriers,
          CdPlano: plans,
          CdSimcardTipo: types,
          Quantidade: quantidade,
          Operadora: options[idx].carriers?.find((c) => c.value == carriers)
            ?.label,
          Plano: options[idx].plans?.find((c) => c.value == plans)?.label,
          SimcarTipo: options[idx].types?.find((c) => c.value == types)?.label,
        };
      });

      const ciglaUf = ufOptions.find(
        (uf) => uf.cd_pav_uf === dados.estado
      )?.ch_sigla;
      const formatAddress = {
        ...(dados.responsavel && { Responsavel: dados.responsavel }),
        ...(dados.telefone && { telefone: formatPhone(dados.telefone) }),
        ...(dados.formaDeEnvio && { FormaDeEnvio: dados.formaDeEnvio }),
        ...(dados.nomeEndereco && { NomeEndereco: dados.nomeEndereco }),
        ...(dados.tipoDeEndereco && {
          CdClienteEnderecoEntrega: dados.tipoDeEndereco,
        }),
        ...(dados.cep && { CEP: formatCep(dados.cep) }),
        ...(dados.endereco && { Endereco: dados.endereco }),
        ...(dados.numero && { Numero: dados.numero }),
        ...(dados.bairro && { Bairro: dados.bairro }),
        ...(dados.cidade && { Cidade: dados.cidade }),
        ...(dados.estado && { UF: ciglaUf }),
        ...(dados.infoAdicional && {
          InformacoesAdicionais: dados.infoAdicional,
        }),
      };

      await postOrder({
        simcardLista: formatBatchs,
        ...formatAddress,
      });

      setFetchOrder(true);
      setRevelarMensagemSucessoPedidoSimcardVirgem(true);
    } catch (err) {
      console.error(err);
    } finally {
      close();
      setIsFetchingState(false);
    }
  };

  useEffect(() => {
    const updatedStepFields = {
      lotes: [DEFAULT_VALUE],
      dados: { ...DATA_ADDRESS, ...DATA_SENT },
    };
    setStepFields(updatedStepFields);
  }, [close]);

  const handleAddBatch = () => {
    setScrollToBotton(true);
    options.push({ carriers: options[0].carriers });
    const updatedStepFields = { ...stepFields };

    updatedStepFields.lotes = [
      ...updatedStepFields.lotes,
      { ...defaultFieldValues },
    ];
    setStepFields(updatedStepFields);
    scrollToBottom();
  };

  const handleChange = (index, field, value) => {
    if (value && field === "carriers") {
      setIndexAndValueOptions({ index, value });
      setFetchOptions(true);
    }

    setStepFields((prevStepFields) => {
      const updatedLotes = [...prevStepFields.lotes];
      updatedLotes[index] = {
        ...(field === "carriers" ? DEFAULT_VALUE : updatedLotes[index]),
        [field]: value,
      };

      return {
        ...prevStepFields,
        lotes: updatedLotes,
      };
    });
  };

  const changeData = (data) => {
    const uf = ufOptions.find((uf) => uf.ch_sigla === data.ch_sigla);

    return {
      numero: data.ds_numero,
      endereco: data.ds_endereco,
      estado: uf.cd_pav_uf,
      bairro: data.ds_bairro,
      cidade: data.ds_cidade,
      cep: data.ds_cep,
      infoAdicional: data.ds_observacoes,
    };
  };

  const handleDataChange = async (field, value) => {
    const dataCep = {};
    if (value && field === "tipoDeEndereco") {
      const data = addressClient.find(
        (i) => i.cd_dsp_cliente_endereco_entrega === Number(value)
      );

      const extraInfo = changeData(data);
      stepFields.dados = {
        ...stepFields.dados,
        ...extraInfo,
      };
      setShowAddress(data ? true : false);
    } else if (value && field === "cep") {
      if (validateCep(value)) {
        try {
          setIsFetchingState(true);
          const { dados } = await getCep(value);
          dataCep.endereco = dados.logradouro;
          dataCep.bairro = dados.bairro;
          dataCep.estado = dados.cd_pav_uf;
          dataCep.cidade = dados.localidade;
        } catch (err) {
          console.error(err);
        } finally {
          setIsFetchingState(false);
        }
      }
    }

    const updatedStepFields = {
      ...stepFields,
      dados: {
        ...stepFields.dados,
        ...dataCep,
      },
    };
    updatedStepFields.dados[field] = value;
    setStepFields(updatedStepFields);
  };

  useEffect(() => {
    if (fetchOptions) {
      refetch();
      setFetchOptions(false);
    }
  }, [fetchOptions, refetch]);

  useEffect(() => {
    if (fetchOrder) {
      setFetchOrder(false);
    }
  }, [fetchOrder]);

  useEffect(() => {
    if (scrollToBotton) scrollToBottom();
    setScrollToBotton(false);
  }, [stepFields, scrollToBotton]);

  const removeBatch = (index) => {
    const updatedStepFields = { ...stepFields };
    const updatedOptions = options;

    updatedStepFields.lotes.splice(index, 1);
    updatedOptions.splice(index, 1);
    setsOptions(updatedOptions);
    setStepFields(updatedStepFields);
  };

  const removeAddress = () => {
    setNomeEndereco(!showAddress ? true : false);
    const updatedStepFields = {
      ...stepFields,
      dados: {
        ...stepFields.dados,
        ...DEFAULT_ADDRESS,
        tipoDeEndereco: "",
        nomeEndereco: "",
      },
    };
    setShowAddress(!showAddress);
    setStepFields(updatedStepFields);
  };

  const steps = [
    {
      disabledNextStep: true,
      label: "Listagem de SIM Cards em branco",
      subtitle: "Listagem de SIM Cards em branco",
      content: (
        <>
          <form onSubmit={onSubmit} style={{ overflow: "hidden" }}>
            <S.ConteudoForm ref={viewport}>
              <Grid colSize={colSize}>{tableHeaders.main}</Grid>
              {stepFields.lotes.map((field, indexController) => (
                <>
                  {indexController > 0 && (
                    <Grid colSize={colSize}>{tableHeaders.additional}</Grid>
                  )}

                  <Grid key={indexController} colSize={colSize}>
                    {field &&
                      Object.entries(field).map(([key, value], index) =>
                        index > 0 ? (
                          key === "quantidade" ? (
                            <NumberInput
                              value={value}
                              name={`lotes.[${indexController}].${key}`}
                              placeholder={fields[index]?.placeholder}
                              defaultValue={value}
                              disabled={index > 1 && !Object.values(field)[1]}
                              onChange={(e) =>
                                handleChange(indexController, key, e)
                              }
                            />
                          ) : key === "removerLote" ? (
                            stepFields.lotes?.length > 1 ? (
                              <S.ContainerAction>
                                <ActionIcon
                                  alt="trash"
                                  variant="transparent"
                                  icon={lixeiraVermelho}
                                  onClick={() => removeBatch(indexController)}
                                />
                              </S.ContainerAction>
                            ) : null
                          ) : (
                            <Select
                              placeholder={fields[index]?.placeholder}
                              value={value}
                              options={options[indexController][key] || []}
                              disabled={index > 1 && !Object.values(field)[1]}
                              onChange={(e) =>
                                handleChange(indexController, key, e)
                              }
                            />
                          )
                        ) : (
                          `${indexController + 1}.`
                        )
                      )}
                  </Grid>
                </>
              ))}
              <div className="add-row">
                {`${(stepFields.lotes?.length || 0) + 1}.`}
                <Botao
                  style={{
                    "margin-left": `${stepFields.lotes.length < 9 ? 26 : 16}px`,
                  }}
                  variant="primary"
                  label="NOVO LOTE"
                  onClick={() => handleAddBatch()}
                />
              </div>
            </S.ConteudoForm>
          </form>
        </>
      ),
    },
    {
      label: "Dados de Envio",
      content: (
        <div className="grid-gap">
          <Grid colSize={gridDataSent}>
            <Input
              label="Responsável"
              withAsterisk={!stepFields.dados.responsavel}
              placeholder="Digite o responsável"
              value={stepFields.dados.responsavel}
              onChange={(event) =>
                handleDataChange("responsavel", event.currentTarget.value)
              }
            />
            <Input
              label="Telefone para contato"
              withAsterisk={!validatePhone(stepFields.dados.telefone)}
              placeholder="Digite o telefone"
              value={stepFields.dados.telefone}
              mask={"(51) 000000000"}
              onChange={(event) =>
                handleDataChange("telefone", event.currentTarget.value)
              }
            />
            <Select
              label="Forma de envio"
              withAsterisk={!stepFields.dados.formaDeEnvio}
              value={stepFields.dados.formaDeEnvio}
              placeholder="Selecione"
              onChange={(e) => handleDataChange("formaDeEnvio", e)}
              options={shippingOptions}
            />
            {!nomeEndereco ? (
              <Select
                label="Tipo de endereço"
                withAsterisk={!stepFields.dados.tipoDeEndereco}
                placeholder="Selecione"
                value={stepFields.dados.tipoDeEndereco}
                onChange={(e) => handleDataChange("tipoDeEndereco", e)}
                options={addressOptions || []}
              />
            ) : (
              <Input
                label="Tipo de endereço"
                withAsterisk={!stepFields.dados.nomeEndereco}
                placeholder="Digite o nome do endereço"
                value={stepFields.dados.nomeEndereco}
                onChange={(e) =>
                  handleDataChange("nomeEndereco", e.currentTarget.value)
                }
              />
            )}
          </Grid>
          {showAddress && (
            <>
              <Divider />
              <Grid colSize={[3, 6, 3]}>
                <Input
                  label="CEP"
                  withAsterisk={!validateCep(stepFields.dados.cep)}
                  placeholder="Digite o CEP"
                  value={stepFields.dados.cep}
                  disabled={stepFields.dados.tipoDeEndereco}
                  mask={"00000-000"}
                  onChange={(event) =>
                    handleDataChange("cep", event.currentTarget.value)
                  }
                />
                <Input
                  label="Endereço"
                  withAsterisk={!stepFields.dados.endereco}
                  placeholder="Digita o nome da rua"
                  value={stepFields.dados.endereco}
                  disabled={stepFields.dados.tipoDeEndereco}
                  onChange={(event) =>
                    handleDataChange("endereco", event.currentTarget.value)
                  }
                />
                <Input
                  label="Número"
                  withAsterisk={!stepFields.dados.numero}
                  placeholder="Digite o número"
                  value={stepFields.dados.numero}
                  disabled={stepFields.dados.tipoDeEndereco}
                  onChange={(event) =>
                    handleDataChange("numero", event.currentTarget.value)
                  }
                />
              </Grid>
              <Grid colSize={[4, 4, 4]}>
                <Input
                  label="Bairro"
                  withAsterisk={!stepFields.dados.bairro}
                  placeholder="Digite o bairro"
                  value={stepFields.dados.bairro}
                  disabled={stepFields.dados.tipoDeEndereco}
                  onChange={(event) =>
                    handleDataChange("bairro", event.currentTarget.value)
                  }
                />
                <Input
                  label="Cidade"
                  withAsterisk={!stepFields.dados.cidade}
                  placeholder="Digite a cidade"
                  value={stepFields.dados.cidade}
                  disabled={stepFields.dados.tipoDeEndereco}
                  onChange={(event) =>
                    handleDataChange("cidade", event.currentTarget.value)
                  }
                />
                <Select
                  label="Estado"
                  withAsterisk={!stepFields.dados.estado}
                  placeholder="Selecione"
                  value={stepFields.dados.estado}
                  disabled={stepFields.dados.tipoDeEndereco}
                  onChange={(e) => handleDataChange("estado", e)}
                  options={ufOptions}
                />
              </Grid>
              <Grid colSize={[12]}>
                <Input
                  label="Informações adicionais (opcional)"
                  placeholder="Sala, apartamento, bloco, ponto de referência"
                  value={stepFields.dados.infoAdicional}
                  onChange={(event) =>
                    handleDataChange("infoAdicional", event.currentTarget.value)
                  }
                />
              </Grid>
            </>
          )}
          {hasError && (
            <div className="alert-icon">
              <img alt="alert" src={alert} />
              <span className="validation-fields">Campos obrigatórios *</span>
            </div>
          )}

          <Botao
            onClick={() => removeAddress()}
            width={234}
            style={
              isEscuro() && !showAddress
                ? {
                    backgroundColor: "#00D994",
                    color: "#000000",
                  }
                : undefined
            }
            label={showAddress ? "REMOVER ENDEREÇO" : "ADICIONAR ENDEREÇO"}
            variant={showAddress ? "containedRemove" : "contained"}
            leftIcon={
              <img
                alt="ícone"
                src={showAddress ? lixeiraVermelho : addNovoEndereco}
              />
            }
          />
          <Divider />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const nextSteps = [];
    const enableLoteStep = stepFields.lotes.every((item) => {
      return Object.keys(item).every((key) => {
        if (["removerLote", "_"].includes(key)) return true;
        return item[key] !== "" && item[key] !== null;
      });
    });

    nextSteps.push(enableLoteStep ? false : true);

    //Validação de campos obrigatórios
    const {
      responsavel,
      formaDeEnvio,
      estado,
      cidade,
      bairro,
      cep,
      numero,
      endereco,
      telefone,
      tipoDeEndereco,
      nomeEndereco,
    } = stepFields.dados;

    let verifyFields = [
      responsavel,
      telefone,
      formaDeEnvio,
      estado,
      cidade,
      bairro,
      cep,
      numero,
      endereco,
      telefone,
    ];

    verifyFields =
      showAddress && nomeEndereco
        ? [...verifyFields, nomeEndereco]
        : [...verifyFields, tipoDeEndereco];

    const someFieldFilled =
      validatePhone(telefone) &&
      validateCep(cep) &&
      verifyFields.every((field) => field);
    nextSteps.push(someFieldFilled ? false : true);

    setHasError(someFieldFilled ? false : true);
    setdisabledNextStep(nextSteps);
  }, [stepFields, showAddress]);

  return (
    <Modal
      width="1090px"
      titulo="Novo pedido"
      opened={opened}
      close={close}
      showActionsModal={false}
    >
      <>
        {isFetchingState && (
          <S.OverlaySpinner>
            <Spinner />
          </S.OverlaySpinner>
        )}
        <Stepper
          steps={steps}
          onSubmit={onSubmit}
          disabledNextStep={disabledNextStep}
          allowNextStepsSelect={false}
          close={close}
        />
      </>
    </Modal>
  );
}
