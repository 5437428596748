//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';/*eslint-disable*/
import decryptJWT from '../../../utils/decryptJWT'
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectServico from '../../componentesModais/selects/selectServico'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectServico, setRevelarSelectServico] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">Tipo de Pessoa {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectServico === false &&
                <Button name="cdServico" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectServico(!revelarSelectServico)}>
                    <div className="fonte-cor-1 label-campo">{dsServico}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectServico === true &&
                <SelectServico
                    setRevelarSelectServico={setRevelarSelectServico} //Hook para fechar a modal
                    setCdServico={setCdServico} //Código do Servico
                    setDsServico={setDsServico} //Nome do Servico
                    dsServico={dsServico}
                    phServico={phServico} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectServico>
            }
        </Form.Group>*/

export default function SelectServico(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS MODAL
    const [lsServico, setLsServico] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleServico(codigoServico, NomeServico,codigoTipo, nomeTipo,codigoCategoria, nomeCategoria,codigoUnidade, nomeUnidade,quantidade, valor){
        props.setCdServico(codigoServico)
        props.setDsServico(NomeServico)
        props.setCdTipo(codigoTipo)
        props.setDsTipo(nomeTipo)
        props.setCdCategoria(codigoCategoria)
        props.setDsCategoria(nomeCategoria)
        props.setCdUnidade(codigoUnidade)
        props.setDsUnidade(nomeUnidade)
        props.setDsQuantidade(quantidade)
        props.setDsValor(valor)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectServico(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderServico(){
            const {data} = await api.get('/m1/consultar/fat_servico?cd_pav_usuario='+decryptJWT('codigo'));
            if(data.dados.length > 0){
                let objetoServico = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.ds_fat_servico}>
                            <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleServico(item.cd_fat_servico, item.ds_fat_servico,
                                item.cd_fat_tipo, item.ds_tipo,item.cd_fat_categoria, item.ds_categoria,item.cd_fat_unidade, item.ds_unidade,item.ds_quantidade, item.n2_valor) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.ds_fat_servico}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsServico(objetoServico)
            }else{
                let objetoServico = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsServico(objetoServico)
            }
            setSpinAtivo(false)
        }
        renderServico()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectServico(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsServico}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsServico}
                </ul>
            </div>
        </Form>
    )
}