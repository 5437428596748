//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../conexoes/api';/*eslint-disable*/
import { useForm } from 'react-hook-form';/*eslint-disable*/
import { Carousel, Button, Form, Col } from 'react-bootstrap';
import { BarChart, Bar, Sector, Label, Cell, Pie, PieChart, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import decryptJWT from '../../utils/decryptJWT'
import {exportarImagemDashSms} from './exportarImagem'
import {formatarMilhar, formatarMilharSemDecimais} from '../../utils/formatarMilhar'

//ICONES
import { elipseVerde, sinalMaiorQueVerde, reticenciasVerde, setaDropdown } from '../../utils/alternanciaIcones'
//SPINNER
import SpinerVeye from '../../components/spinnerVeye/spinnerVeye.js'
//BARRA DE FILTRAGEM
import BarraFiltragem from '../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../componentesModais/barraDadosCliente/barraDadosCliente'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, limpadorFiltros, revelarBarraFiltragem, realizouFiltro, mudarFiltros } from "../../redux/actions/index";
//ESTLIZAÇÃO
import '../../css/painelDashboard/dashboardSms.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

const arrayCores = [
    '#36D896', '#FDCB62', '#FC5F0E', '#CD2D37', '#1F8EFA','#FF5F58', '#640EDF', '#1FDFD9', '#B947DB', '#F02E7A',
    '#36D896', '#FDCB62', '#FC5F0E', '#CD2D37', '#1F8EFA','#FF5F58', '#640EDF', '#1FDFD9', '#B947DB', '#F02E7A',
    '#36D896', '#FDCB62', '#FC5F0E', '#CD2D37', '#1F8EFA','#FF5F58', '#640EDF', '#1FDFD9', '#B947DB', '#F02E7A'
];

let dadosEnvio;
let dadosEstoqueRecarga;
let dadosStatus;

let arrayGrafico7dias = [];
let arrayGrafico15dias = [];
let smsEnviados7dias = 0;
let smsRecebidos7dias = 0;
let somaSmsEnviadosRecebidos7dias = 0;
let LabelPorcentgemEnviados7dias = 0;
let LabelPorcentgemRecebidos7dias = 0;
let smsEnviados15dias = 0;
let smsRecebidos15dias = 0;
let somaSmsEnviadosRecebidos15dias = 0;
let LabelPorcentgemEnviados15dias = 0;
let LabelPorcentgemRecebidos15dias = 0;

let creditos = 0;
let reservados = 0;
let LabelPorcentagemCreditos = 0;
let LabelPorcentagemReservados = 0;
let somaCreditosRecebidos = 0;
let recarga = 0;
let dataUltimaRecarga = "";

let entregues = 0;
let pendentes = 0;
let rejeitados = 0;
let naoEntregues = 0;
let expirados = 0;
let somaTotalStatus = 0;
let labelPorcentagemEntregues = 0;
let labelPorcentagemPendentes = 0;
let labelPorcentagemRejeitados = 0;
let labelPorcentagemNaoEntregues = 0;
let labelPorcentagemExpirados = 0;


function Dasboard(props){
    //OUTROS HOOKS
    const despacho = useDispatch();
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let filtros = useSelector(state => state.filtrosState.filtros);
    const [spinAtivo, setSpinAtivo] = useState(true);
    const localTema = localStorage.getItem('tema');
    const [arrayCores, setArrayCores] = useState(["#36D896","#FDCB62","#CD2D37","#1F8EFA"])
    const [numeroMaxGrafico, setNumeroMaxGrafico] = useState()
    //HOOKS DE CONTROLE
    const [totalPaginasDashboard, setTotalPaginasDashboard] = useState(2)
    const [numeroDashboard, setNumeroDashboard] = useState(0)
    const [porcentagemEnviados7dias, setPorcentagemEnviados7dias] = useState([]);
    const [porcentagemRecebidos7dias, setPorcentagemRecebidos7dias] = useState([]);
    const [porcentagemEnviados15dias, setPorcentagemEnviados15dias] = useState([]);
    const [porcentagemRecebidos15dias, setPorcentagemRecebidos15dias] = useState([]);
    const [estoqueCreditosSms, setEstoqueCreditosSms] = useState([]);
    const [estoqueReservadorSms, setEstoqueReservadosSms] = useState([]);
    const [ultimaRecarga, setUltimaRecarga] = useState([]);
    const [statusEntregues, setStatusEntregues] = useState([]);
    const [statusPendentes, setStatusPendentes] = useState([]);
    const [statusRejeitados, setStatusRejeitados] = useState([]);
    const [statusNaoEntregues, setStatusNaoEntregues] = useState([]);
    const [statusExpirados, setStatusExpirados] = useState([]);

    const [menuOpcoesEnvio, setMenuOpcoesEnvio] = useState()
    const [menuOpcoesEstoque, setMenuOpcoesEstoque] = useState()
    const [menuOpcoesUltimaRecarga, setMenuOpcoesUltimaRecarga] = useState()
    const [menuOpcoesStatus, setMenuOpcoesStatus] = useState()
    const [revelarOpcoesEnvio, setRevelarOpcoesEnvio] = useState (false)
    const [revelarOpcoesEstoque, setRevelarOpcoesEstoque] = useState (false)
    const [revelarOpcoesUltimaRecarga, setRevelarOpcoesUltimaRecarga] = useState (false)
    const [revelarOpcoesStatus, setRevelarOpcoesStatus] = useState (false)
    const [mudarGraficoDias, setMudarGraficoDias] = useState ('7dias')


     /* GERA A LABEL DE % DOS GRAFICOS EM GRUPOS DE 5 */
     function LabelGraficosPizzaEnvios({viewBox, valorPercentual}){
        const {cx, cy} = viewBox;
        return (
            <text className="label-percentual-graficos-pizza-envios" x={cx} y="50%" fill={localTema ==='claro' || localTema ==='algarClaro'?"#000000":'#FFFFFF'} textAnchor="middle" dominantBaseline="central">
                {valorPercentual + "%"}
            </text>
        )
    }

    function LabelGraficosPizzaStatus({viewBox, valorPercentual}){
        const {cx, cy} = viewBox;
        return (
            <text className="label-percentual-graficos-pizza-status" x={cx} y="50%" fill={localTema ==='claro' || localTema ==='algarClaro'?"#000000":'#FFFFFF'} textAnchor="middle" dominantBaseline="central">
                {valorPercentual + "%"}
            </text>
        )
    }

    function handleAbrirMenuOpcoes(identificador){
        if(identificador === "envios"){
            setRevelarOpcoesEnvio(!revelarOpcoesEnvio)
            setRevelarOpcoesEstoque(false)
            setRevelarOpcoesUltimaRecarga(false)
            setRevelarOpcoesStatus(false)
        }
        if(identificador === "estoque"){
            setRevelarOpcoesEstoque(!revelarOpcoesEstoque)
            setRevelarOpcoesEnvio(false)
            setRevelarOpcoesUltimaRecarga(false)
            setRevelarOpcoesStatus(false)
        }
        if(identificador === "ultimaRecarga"){
            setRevelarOpcoesUltimaRecarga(!revelarOpcoesUltimaRecarga)
            setRevelarOpcoesEnvio(false)
            setRevelarOpcoesEstoque(false)
            setRevelarOpcoesStatus(false)
        }
        if(identificador === "status"){
            setRevelarOpcoesStatus(!revelarOpcoesStatus)
            setRevelarOpcoesEnvio(false)
            setRevelarOpcoesEstoque(false)
            setRevelarOpcoesUltimaRecarga(false)
        }
    }

    function LabelGraficosPizzaGrandeUltimaRecarga({viewBox, valorPercentual}){
        const {cx, cy} = viewBox;
        let auxTooltipTotal
        if(parseFloat(valorPercentual) > 2000000){
            auxTooltipTotal = parseFloat(valorPercentual/1000000).toFixed(1) + " milhões"
        }else if(parseFloat(valorPercentual) > 1000000){
            auxTooltipTotal = parseFloat(valorPercentual/1000000).toFixed(1) + " milhão"
        }else if(parseFloat(valorPercentual) > 1000){
            auxTooltipTotal = parseFloat(valorPercentual/1000).toFixed(1) + " mil"
        }else{
            auxTooltipTotal = formatarMilharSemDecimais(valorPercentual)
        }
        return (
            <text className="label-percentual-graficos-pizza-grande-ultima-recarga" x={cx} y="50%" fill={localTema ==='claro' || localTema ==='algarClaro'?"#000000":'#FFFFFF'} textAnchor="middle" dominantBaseline="central">
                {auxTooltipTotal + " SMS"}
            </text>
        )
    }

    function handleTrocarGrafico(dias){
        if (dias === '15dias'){
            setMudarGraficoDias('15dias')
            setRevelarOpcoesEnvio(false)
        }else{
            setMudarGraficoDias('7dias')
            setRevelarOpcoesEnvio(false)
        }

    }


    /* GERA A LABEL DE % DOS GRAFICOS EM GRUPOS DE 5 */
    function LabelGraficosPizzaEstoque({viewBox, valorPercentual}){
        const {cx, cy} = viewBox;
        return (
            <text className={`label-percentual-graficos-pizza-estoque ${valorPercentual >= 100 ? 'label-menor-grafico' : ''}`}
                x={cx} y="50%"
                fill={localTema ==='claro' || localTema ==='algarClaro'?"#000000":'#FFFFFF'} 
                textAnchor="middle" dominantBaseline="central"
            >
                {valorPercentual + "%"}
            </text>
        )
    }

    /* CONTROLA A EXPANSAO E CONTRACAO DOS DROPDOWNS DOS MENUS DE OPCOES */
    function dropMenuOpcoes(event, elementoId,imagemId){
        let elemento = document.getElementById(elementoId);
        let imagem = document.getElementById(imagemId);

        if(imagem.classList.contains("menu-opcoes-icone-desativado")){
            imagem.classList.remove("menu-opcoes-icone-desativado")
            imagem.classList.add("menu-opcoes-icone-ativado")
        }else if(imagem.classList.contains("menu-opcoes-icone-ativado")){
            imagem.classList.remove("menu-opcoes-icone-ativado")
            imagem.classList.add("menu-opcoes-icone-desativado")
        }

        if(elemento.classList.contains("dropdown-desativado")){
            elemento.classList.remove("dropdown-desativado")
            elemento.classList.add("dropdown-ativado")
        }else if(elemento.classList.contains("dropdown-ativado")){
            elemento.classList.remove("dropdown-ativado")
            elemento.classList.add("dropdown-desativado")
        }
    }

    useEffect(() => {
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(mudarManejador("dashboardSMS"));
        despacho(limpadorFiltros(true))
        setSpinAtivo(false)
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        async function renderInformacoesEnvio7dias(){
            const {data} = await api.get('m1/consultar/sms_dashboard/envios?qtd_dias=7')
            mapeiaGrafico7dias(data.dados)
            dadosEnvio = data.dados
        }
        renderInformacoesEnvio7dias()
        
        function mapeiaGrafico7dias(dados){
            let envios = dados.envios;
            arrayGrafico7dias = dados.enviosPorDia;
            arrayGrafico7dias.map((item) => {
                item.diaMes = moment(item.diaMes).format('DD/MM');
                
                envios.map((item) =>{
                    smsEnviados7dias = item.total_enviados;
                    smsRecebidos7dias = item.total_recebidos;

                    if(smsEnviados7dias === 0 && smsRecebidos7dias === 0){
                        LabelPorcentgemEnviados7dias = 0;
                        LabelPorcentgemRecebidos7dias = 0;
                        setPorcentagemEnviados7dias([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                        setPorcentagemRecebidos7dias([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    }else{
                        somaSmsEnviadosRecebidos7dias = smsEnviados7dias + smsRecebidos7dias;
                        LabelPorcentgemEnviados7dias = item.p_total_envios.toFixed(0);
                        LabelPorcentgemRecebidos7dias = item.p_total_recebidos.toFixed(0);
                        setPorcentagemEnviados7dias([{'value': smsEnviados7dias, 'cor': '#36D896'}, {'value': smsRecebidos7dias, 'cor':  localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                        setPorcentagemRecebidos7dias([{'value': smsRecebidos7dias, 'cor': '#CD2D37'}, {'value': smsEnviados7dias, 'cor':  localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    }
                })
            },)
        }
        async function renderInformacoesEnvio15dias(){
            const {data} = await api.get('m1/consultar/sms_dashboard/envios?qtd_dias=15')
            mapeiaGrafico15dias(data.dados)
            dadosEnvio = data.dados
        }
        renderInformacoesEnvio15dias()

        function mapeiaGrafico15dias(dados){
            let envios = dados.envios;
            arrayGrafico15dias = dados.enviosPorDia;
            arrayGrafico15dias.map((item) => {
                item.diaMes = moment(item.diaMes).format('DD/MM');
                
                envios.map((item) => {
                    smsEnviados15dias = item.total_enviados;
                    smsRecebidos15dias =  item.total_recebidos;

                    if(smsEnviados15dias === 0 && smsRecebidos15dias === 0){
                        LabelPorcentgemEnviados15dias = 0;
                        LabelPorcentgemRecebidos15dias = 0;
                        setPorcentagemEnviados15dias([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                        setPorcentagemRecebidos15dias([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    }else{
                        somaSmsEnviadosRecebidos15dias = smsEnviados15dias + smsRecebidos15dias;
                        LabelPorcentgemEnviados15dias = item.p_total_envios.toFixed(0);
                        LabelPorcentgemRecebidos15dias = item.p_total_recebidos.toFixed(0);
                        setPorcentagemEnviados15dias([{'value': smsEnviados15dias, 'cor': '#36D896'}, {'value': smsRecebidos15dias, 'cor':  localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                        setPorcentagemRecebidos15dias([{'value': smsRecebidos15dias, 'cor': '#CD2D37'}, {'value': smsEnviados15dias, 'cor':  localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    }
                },)
            },)
        }

        async function renderInformacoesEstoqueRecarga(){
            const {data} = await api.get('m1/consultar/sms_dashboard/estoque_recarga')
            dadosEstoqueRecarga = data.dados
            dadosEstoqueRecarga.map((item) => {
                creditos = item.estoque;
                reservados = item.reservado;
                somaCreditosRecebidos = item.total;
                recarga = item.qtd_ultima_recarga;
                
                item.dt_ultima_recarga = moment(item.dt_ultima_recarga).format('DD/MM/YYYY');
                dataUltimaRecarga = item.dt_ultima_recarga;

                LabelPorcentagemCreditos = item.p_estoque.toFixed(0)
                LabelPorcentagemReservados = item.p_reservados.toFixed(0)

                if(recarga === 0 && dataUltimaRecarga == 'Invalid date'){
                    setUltimaRecarga([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    dataUltimaRecarga = "Sem recargas";
                }else{
                    setUltimaRecarga([{'value': recarga, 'cor': '#36D896'}])
                    dataUltimaRecarga = item.dt_ultima_recarga;
                }
                
                if (creditos === 0 && reservados === 0 && somaCreditosRecebidos === 0){
                    setEstoqueCreditosSms([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    setEstoqueReservadosSms([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    LabelPorcentagemCreditos = 0;
                    LabelPorcentagemReservados = 0;
                }else{
                    setEstoqueCreditosSms([{'value': creditos, 'cor': '#36D896'}, {'value': reservados, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    setEstoqueReservadosSms([{'value': creditos, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}, {'value': reservados, 'cor':'#1F8EFA'}])
                    LabelPorcentagemCreditos = item.p_estoque.toFixed(0)
                    LabelPorcentagemReservados = item.p_reservados.toFixed(0)
                }
            },)
        }
        renderInformacoesEstoqueRecarga();

        async function renderInformacoesStatus(){
            const {data} = await api.get('m1/consultar/sms_dashboard/status')
            dadosStatus = data.dados
            dadosStatus.map((item) => {
                entregues = item.qtd_entregue;
                pendentes = item.qtd_pendente;
                rejeitados = item.qtd_rejeitado;
                naoEntregues = item.qtd_nao_entregue;
                expirados = item.qtd_expirado;
                labelPorcentagemEntregues = item.p_qtd_entregue.toFixed(0)
                labelPorcentagemPendentes = item.p_qtd_pendente.toFixed(0)
                labelPorcentagemRejeitados = item.p_qtd_rejeitado.toFixed(0)
                labelPorcentagemNaoEntregues = item.p_qtd_nao_entregue.toFixed(0)
                labelPorcentagemExpirados = item.p_qtd_expirado.toFixed(0)


                if(entregues === 0 && pendentes === 0 && rejeitados === 0 && naoEntregues === 0 && expirados === 0){
                    labelPorcentagemEntregues = 0;
                    labelPorcentagemPendentes = 0;
                    labelPorcentagemRejeitados = 0;
                    labelPorcentagemNaoEntregues = 0;
                    labelPorcentagemExpirados = 0;
                    
                    setStatusEntregues ([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}, {'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    setStatusPendentes ([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}, {'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    setStatusRejeitados ([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}, {'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    setStatusNaoEntregues ([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}, {'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    setStatusExpirados ([{'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}, {'value': 100, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                }else{
                    labelPorcentagemEntregues = item.p_qtd_entregue.toFixed(0)
                    labelPorcentagemPendentes = item.p_qtd_pendente.toFixed(0)
                    labelPorcentagemRejeitados = item.p_qtd_rejeitado.toFixed(0)
                    labelPorcentagemNaoEntregues = item.p_qtd_nao_entregue.toFixed(0)
                    labelPorcentagemExpirados = item.p_qtd_expirado.toFixed(0)

                    somaTotalStatus = entregues + pendentes + rejeitados + naoEntregues + expirados;
                    setStatusEntregues ([{'value': entregues, 'cor': '#36D896'}, {'value': somaTotalStatus, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    setStatusPendentes ([{'value': pendentes, 'cor': '#FDCB62'}, {'value': somaTotalStatus, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    setStatusRejeitados ([{'value': rejeitados, 'cor': '#FC5F0E'}, {'value': somaTotalStatus, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    setStatusNaoEntregues ([{'value': naoEntregues, 'cor': '#CD2D37'}, {'value': somaTotalStatus, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                    setStatusExpirados ([{'value': expirados, 'cor': '#1F8EFA'}, {'value': somaTotalStatus, 'cor': localTema ==='claro' || localTema ==='algarClaro'?"#BCC1BF":"#343736"}])
                }
            },)
        }
        renderInformacoesStatus();
        setSpinAtivo(false)
    }, [])
    function CustomTooltip({ payload, label, active }) {
        if (active && payload!==null && payload.length > 0) {
            return (
                <div className="container-tooltip-grafico-ciclo-ultimo">
                    <p className="label-tooltip-grafico-ciclo-ultimo"> Enviados: {payload[0].payload.enviados} </p>
                    <p className="label-tooltip-grafico-ciclo-ultimo"> Recebidos: {payload[0].payload.recebidos} </p>
                </div>
            );
        }
        return null;
    }

    return( 
        <div className="container-dashboard-tela">
            <BarraDadosCliente/>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            { numeroDashboard === 0 &&
                <div className={revelarBarraFiltro===false?"container-dashboard-topo fundo-cor-5":"container-dashboard-topo-2 fundo-cor-5"} id="grafico-sms-envios">
                    <Form.Row>
                        <Col>
                            <Form.Label className="titulo-grafico-dashboard">
                                <div className="fonte-cor-1 label-janela-dashboard-envio">Envios</div>
                            </Form.Label>
                            <Button className="bota-opcoes-dashboard-sms-envios" variant="danger" onClick={() => handleAbrirMenuOpcoes("envios")}>
                                <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                            </Button>
                            <div className="data-ciclo fonte-cor-1">
                                {mudarGraficoDias === "7dias" &&
                                    <>Últimos 7 dias</>
                                }
                                {mudarGraficoDias === "15dias" &&
                                    <>Últimos 15 dias</>
                                }
                            </div>
                        </Col>
                    </Form.Row>
                    <div className="container-graficos-topo">
                        {revelarOpcoesEnvio === true &&
                            <div className="menu-opcoes-dashboard-topo-envio">
                                <RenderMenuOpcoesEnvio
                                setRevelarOpcoesEnvio={setRevelarOpcoesEnvio}
                                revelarOpcoesEnvio={revelarOpcoesEnvio}
                                exportar="exportarSmsEnvios"
                                handleTrocarGrafico={handleTrocarGrafico}
                                />
                            </div>
                        }
                        <div className="coluna-graficos-topo-1-sms">
                            <div className="linha-grafico-topo-dashboard-sms">
                                <div className="container-grafico-pizza-topo-sms">
                                   {mudarGraficoDias === "15dias" ?
                                        <ResponsiveContainer height="100%" width="100%">
                                            <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                <Pie
                                                    data={porcentagemEnviados15dias} 
                                                    innerRadius="60%"
                                                    outerRadius="78%"
                                                    fill="#505050"
                                                    paddingAngle={0}
                                                    stroke={0}
                                                >
                                                <Label content={<LabelGraficosPizzaEnvios valorPercentual={LabelPorcentgemEnviados15dias}/>}/>
                                                    {
                                                    porcentagemEnviados15dias.map((entry, index) => <Cell fill={entry.cor}/>)
                                                    }
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                        :
                                        <ResponsiveContainer height="100%" width="100%">
                                            <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                <Pie
                                                    data={porcentagemEnviados7dias} 
                                                    innerRadius="60%"
                                                    outerRadius="78%"
                                                    fill="#505050"
                                                    paddingAngle={0}
                                                    stroke={0}
                                                >
                                                <Label content={<LabelGraficosPizzaEnvios valorPercentual={LabelPorcentgemEnviados7dias}/>}/>
                                                    {
                                                    porcentagemEnviados7dias.map((entry, index) => <Cell fill={entry.cor}/>)
                                                    }
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    }
                                    
                                </div>
                                <div className="container-legenda-grafico-pizza-topo-sms">
                                    <div className="container-titulo-legenda-grafico-pizza-topo">
                                        <span className="texto-titulo-legenda-grafico-pizza-topo fonte-cor-1"> Enviados </span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-topo">
                                        <span className="texto-corpo-legenda-grafico-pizza-topo fonte-cor-1"> {mudarGraficoDias === "15dias" ? formatarMilharSemDecimais(smsEnviados15dias) : formatarMilharSemDecimais(smsEnviados7dias)} </span>
                                    </div>
                                </div>
                            </div>
                            <div className="linha-grafico-topo-dashboard-sms">
                                <div className="container-grafico-pizza-topo-sms">
                                    {mudarGraficoDias === "15dias" ?
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={porcentagemRecebidos15dias} 
                                                        innerRadius="60%"
                                                        outerRadius="78%"
                                                        fill="#505050"
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaEnvios valorPercentual={LabelPorcentgemRecebidos15dias}/>}/>
                                                        {
                                                        porcentagemRecebidos15dias.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                            :
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={porcentagemRecebidos7dias} 
                                                        innerRadius="60%"
                                                        outerRadius="78%"
                                                        fill="#505050"
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaEnvios valorPercentual={LabelPorcentgemRecebidos7dias}/>}/>
                                                        {
                                                        porcentagemRecebidos7dias.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                    }
                                </div>
                                <div className="container-legenda-grafico-pizza-topo-sms">
                                    <div className="container-titulo-legenda-grafico-pizza-topo">
                                        <span className="texto-titulo-legenda-grafico-pizza-topo fonte-cor-1"> Recebidos </span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-topo">
                                        <span className="texto-corpo-legenda-grafico-pizza-topo fonte-cor-1"> {mudarGraficoDias === "15dias" ? smsRecebidos15dias : smsRecebidos7dias} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="coluna-graficos-topo-2">
                                <ResponsiveContainer> 
                                    <BarChart data={mudarGraficoDias === "15dias" ? arrayGrafico15dias : arrayGrafico7dias}>
                                        <CartesianGrid stroke={'transparent'} strokeDasharray="0" />
                                        <XAxis stroke={localTema ==='claro' || localTema ==='algarClaro'?"#000000":'#FFFFFF'} dataKey={"diaMes"} />
                                        <YAxis stroke={localTema ==='claro' || localTema ==='algarClaro'?"#000000":'#FFFFFF'} />
                                        <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip />}/>
                                        <Bar barSize={36} dataKey="enviados" fill="#36D896" background={{ fill: localTema ==='claro' || localTema ==='algarClaro'?"#ECF0EE":'#1C1E1D'}}/>
                                        <Bar barSize={36} dataKey="recebidos" fill="#CD2D37" background={{ fill: localTema ==='claro' || localTema ==='algarClaro'?"#ECF0EE":'#1C1E1D'}}/>
                                    </BarChart>
                                </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            }
            
            <div className={revelarBarraFiltro===false?"container-dashboard-fundo":"container-dashboard-fundo-4"}>
                { numeroDashboard === 0 &&
                    <>
                        <div className="container-dashboard-fundo-1 fundo-cor-5" id="grafico-sms-estoque">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2-estoque"> Estoque </div>
                                    </Form.Label>
                                    <Button className="bota-opcoes-dashboard-sms" variant="danger" onClick={() => handleAbrirMenuOpcoes("estoque")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button>
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande-estoque">
                                {revelarOpcoesEstoque === true &&
                                    <div className="menu-opcoes-dashboard-topo">
                                        <RenderMenuOpcoesEstoque
                                        setRevelarOpcoesEstoque={setRevelarOpcoesEstoque}
                                        revelarOpcoesEstoque={revelarOpcoesEstoque}
                                        exportar="exportarSmsEstoque"
                                        />
                                    </div>
                                }
                                <div className="container-grafico-pizza-grande-estoque-1">
                                    <ResponsiveContainer height="100%" width="100%">
                                        <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                            <Pie
                                                data={estoqueCreditosSms} 
                                                innerRadius="60%"
                                                outerRadius="80%"
                                                fill="#505050"
                                                paddingAngle={0}
                                                stroke={0}
                                            >
                                            <Label content={<LabelGraficosPizzaEstoque valorPercentual={LabelPorcentagemCreditos}/>}/>
                                                {
                                                estoqueCreditosSms.map((entry, index) => <Cell fill={entry.cor}/>)
                                                }
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="container-grafico-pizza-grande-estoque-2">
                                    <ResponsiveContainer height="100%" width="100%">
                                        <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                            <Pie
                                                data={estoqueReservadorSms} 
                                                innerRadius="60%"
                                                outerRadius="80%"
                                                fill="#505050"
                                                paddingAngle={0}
                                                stroke={0}
                                            >
                                            <Label content={<LabelGraficosPizzaEstoque valorPercentual={LabelPorcentagemReservados}/>}/>
                                                {
                                                estoqueReservadorSms.map((entry, index) => <Cell fill={entry.cor}/>)
                                                }
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande-estoque">
                                    <div className="container-legenda-estoque">
                                        <div className="linha-legenda-sem-comunicar-estoque-1">
                                            <div className="coluna-legenda-sem-comunicar-1-estoque"><div className="esfera-legenda-dashboard" style={{backgroundColor:"#36D896"}}/></div>
                                            <div className="coluna-legenda-sem-comunicar-2-estoque"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Créditos</span></div>
                                            <div className="coluna-legenda-sem-comunicar-3-estoque"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero">{formatarMilharSemDecimais(creditos)}</span></div>
                                        </div>
                                        <div className="linha-legenda-sem-comunicar-estoque-2">
                                            <div className="coluna-legenda-sem-comunicar-1-estoque"><div className="esfera-legenda-dashboard" style={{backgroundColor:"#1F8EFA"}}/></div>
                                            <div className="coluna-legenda-sem-comunicar-2-estoque"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Reservados</span></div>
                                            <div className="coluna-legenda-sem-comunicar-3-estoque"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero">{formatarMilharSemDecimais(reservados)}</span></div>
                                        </div>
                                    </div>
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Total</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">{formatarMilharSemDecimais(somaCreditosRecebidos)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-dashboard-fundo-2 fundo-cor-5" id="grafico-sms-ultima-recarga">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2-estoque"> Última recarga </div>
                                    </Form.Label>
                                    <Button className="bota-opcoes-dashboard-sms" variant="danger" onClick={() => handleAbrirMenuOpcoes("ultimaRecarga")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button>
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande">
                                {revelarOpcoesUltimaRecarga === true &&
                                    <div className="menu-opcoes-dashboard-topo">
                                        <RenderMenuOpcoesUltimaRecarga
                                        setRevelarOpcoesUltimaRecarga={setRevelarOpcoesUltimaRecarga}
                                        revelarOpcoesUltimaRecarga={revelarOpcoesUltimaRecarga}
                                        exportar="exportarSmsUltimaRecarga"
                                        />
                                    </div>
                                }
                                <div className="container-grafico-pizza-grande-recarga">
                                    <ResponsiveContainer height="100%" width="100%">
                                        <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                            <Pie
                                                data={ultimaRecarga} 
                                                innerRadius="60%"
                                                outerRadius="80%"
                                                fill="#505050"
                                                paddingAngle={0}
                                                stroke={0}
                                            >
                                            <Label content={<LabelGraficosPizzaGrandeUltimaRecarga valorPercentual={recarga}/>}/>
                                                {
                                                ultimaRecarga.map((entry, index) => <Cell fill={entry.cor}/>)
                                                }
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande">
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Data</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">{dataUltimaRecarga}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-dashboard-fundo-3 fundo-cor-5" id="grafico-sms-status">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2-estoque"> Status </div>
                                    </Form.Label>
                                    <Button className="bota-opcoes-dashboard-sms" variant="danger" onClick={() => handleAbrirMenuOpcoes("status")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button>
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande">
                                {revelarOpcoesStatus === true &&
                                    <div className="menu-opcoes-dashboard-topo">
                                        <RenderMenuOpcoesStatus
                                        setRevelarOpcoesStatus={setRevelarOpcoesStatus}
                                        revelarOpcoesStatus={revelarOpcoesStatus}
                                        dropMenuOpcoes={dropMenuOpcoes}
                                        exportar="exportarSmsStatus"
                                        />
                                    </div>
                                }
                                <div className="container-simcards-sem-comunicar-sms">
                                    <div className="container-grafico-pizza-sem-comunicar-sms">
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={statusEntregues} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill="#505050"
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaStatus valorPercentual={labelPorcentagemEntregues}/>}/>
                                                        {
                                                        statusEntregues.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={statusPendentes} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill="#505050"
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaStatus valorPercentual={labelPorcentagemPendentes}/>}/>
                                                        {
                                                        statusPendentes.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={statusRejeitados} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill="#505050"
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaStatus valorPercentual={labelPorcentagemRejeitados}/>}/>
                                                        {
                                                        statusRejeitados.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={statusNaoEntregues} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill="#505050"
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaStatus valorPercentual={labelPorcentagemNaoEntregues}/>}/>
                                                        {
                                                        statusNaoEntregues.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={statusExpirados} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill="#505050"
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaStatus valorPercentual={labelPorcentagemExpirados}/>}/>
                                                        {
                                                        statusExpirados.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className="container-legenda-sem-comunicar-sms">
                                        <div className="linha-legenda-sem-comunicar">
                                            <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:"#36D896"}}/></div>
                                            <div className="coluna-legenda-sem-comunicar-2-legenda"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Entregues</span></div>
                                            <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero">{formatarMilharSemDecimais(entregues)}</span></div>
                                        </div>
                                        <div className="linha-legenda-sem-comunicar">
                                            <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:"#FDCB62"}}/></div>
                                            <div className="coluna-legenda-sem-comunicar-2-legenda"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Pendentes </span></div>
                                            <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero">{formatarMilharSemDecimais(pendentes)}</span></div>
                                        </div>
                                        <div className="linha-legenda-sem-comunicar">
                                            <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:"#FC5F0E"}}/></div>
                                            <div className="coluna-legenda-sem-comunicar-2-legenda"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Rejeitados</span></div>
                                            <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero">{formatarMilharSemDecimais(rejeitados)}</span></div>
                                        </div>
                                        <div className="linha-legenda-sem-comunicar">
                                            <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:"#CD2D37"}}/></div>
                                            <div className="coluna-legenda-sem-comunicar-2-legenda"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Não entregues</span></div>
                                            <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero">{formatarMilharSemDecimais(naoEntregues)}</span></div>
                                        </div>
                                        <div className="linha-legenda-sem-comunicar">
                                            <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:"#1F8EFA"}}/></div>
                                            <div className="coluna-legenda-sem-comunicar-2-legenda"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Expirados</span></div>
                                            <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero">{formatarMilharSemDecimais(expirados)}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande">
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Total</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">{formatarMilharSemDecimais(somaTotalStatus)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

function RenderMenuOpcoesStatus(props){
    const modal = useRef();
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar(){
       if(props.revelarOpcoesStatus){
           props.setRevelarOpcoesStatus(!props.revelarOpcoesStatus)
       }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return (
        <Form ref={modal} className="menu-opcoes-container">
            <div className="campo-select-triangulo-cor-1 menu-opcoes-triangulo-dashboard-sms"></div>
            <div className="menu-opcoes-corpo-dashborad-sms fundo-cor-3">
                <ul className="drop-acoes-lista-menu">
                    <li>
                        <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover" onClick={e => props.dropMenuOpcoes(e, "menu-mostrar-em-dispositivos", "imagem-menu-mostrar-em-dispositivos")}>
                            <span className="drop-acoes-menu-label-dashboard-sms-status fonte-cor-1">Mostrar em SMS</span>
                            <img id={"imagem-menu-mostrar-em-dispositivos"} className="menu-opcoes-icone-desativado nao-selecionavel" src={setaDropdown()} alt="drop" />
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                        <ul id={"menu-mostrar-em-dispositivos"}  className="drop-acoes-lista-submenu dropdown-desativado">
                            <li>
                                <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover">
                                    <span  className="menu-opcoes-sub-label-dashboard-sms fonte-cor-1">Entregues</span>
                                </div>
                                <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                            </li>
                            <li>
                                <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover">
                                    <span  className="menu-opcoes-sub-label-dashboard-sms fonte-cor-1">Pendentes</span>
                                </div>
                                <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                            </li>
                            <li>
                                <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover">
                                    <span  className="menu-opcoes-sub-label-dashboard-sms fonte-cor-1">Rejeitados</span>
                                </div>
                                <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                            </li>
                            <li>
                                <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover">
                                    <span  className="menu-opcoes-sub-label-dashboard-sms fonte-cor-1">Não entregues</span>
                                </div>
                                <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                            </li>
                            <li>
                                <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover">
                                    <span  className="menu-opcoes-sub-label-dashboard-sms fonte-cor-1">Expirados</span>
                                </div>
                                <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover">
                            <span  className="drop-acoes-menu-label-dashoard-sms-exportar fonte-cor-1" onClick={() => exportarImagemDashSms(props.exportar)}>Exportar imagem</span>
                        </div>
                    </li>
                </ul>
            </div>
        </Form>
    )
}

function RenderMenuOpcoesUltimaRecarga(props){
    const modal = useRef();
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar(){
       if(props.revelarOpcoesUltimaRecarga){
           props.setRevelarOpcoesUltimaRecarga(!props.revelarOpcoesUltimaRecarga)
       }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return(
        <Form ref={modal} className="menu-opcoes-container">
            <div className="campo-select-triangulo-cor-1 menu-opcoes-triangulo-topo-dashboard-sms"></div>
            <div className="menu-opcoes-corpo-topo-dashboard-sms fundo-cor-3">
                <ul className="drop-acoes-lista-menu">
                    <li>
                        <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover">
                            <span  className="drop-acoes-menu-label-dashboard-sms fonte-cor-1" onClick={() => exportarImagemDashSms(props.exportar)}>Exportar Imagem</span>
                        </div>
                    </li>
                </ul>
            </div>
        </Form>
    )
}

function RenderMenuOpcoesEstoque(props){
    const modal = useRef();
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar(){
       if(props.revelarOpcoesEstoque){
           props.setRevelarOpcoesEstoque(!props.revelarOpcoesEstoque)
       }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return(
        <Form ref={modal} className="menu-opcoes-container">
            <div className="campo-select-triangulo-cor-1 menu-opcoes-triangulo-topo-dashboard-sms"></div>
            <div className="menu-opcoes-corpo-topo-dashboard-sms fundo-cor-3">
                <ul className="drop-acoes-lista-menu">
                    <li>
                        <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover">
                            <span  className="drop-acoes-menu-label-dashboard-sms fonte-cor-1" onClick={() => exportarImagemDashSms(props.exportar)}>Exportar Imagem</span>
                        </div>
                    </li>
                </ul>
            </div>
        </Form>
    )
}

    /* CRIA MENU DE OPCOES DOS GRAFICOS DO TOPO (QUE NUNCA MUDAM) */
    function RenderMenuOpcoesEnvio(props){
        const modal = useRef();
        const handleClickFora = e => {
            if (!modal.current.contains(e.target)) {
                handleCancelar()
            }
        };
    
        function handleCancelar(){
           if(props.revelarOpcoesEnvio){
               props.setRevelarOpcoesEnvio(!props.revelarOpcoesEnvio)
           }
        }
    
        useEffect(() => {
            document.addEventListener('mousedown', handleClickFora);
            return () => document.removeEventListener('mousedown', handleClickFora);
        }, [])

        return(
            <Form ref={modal} className="menu-opcoes-container">
                <div className="campo-select-triangulo-cor-1 menu-opcoes-triangulo-topo-dashboard-sms"></div>
                <div className="menu-opcoes-corpo-topo-dashboard-sms fundo-cor-3">
                    <ul className="drop-acoes-lista-menu">
                        <li>
                            <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover">
                                <span  className="drop-acoes-menu-label-dashboard-sms fonte-cor-1" onClick={()=> props.handleTrocarGrafico('7dias')}>Últimos 7 dias</span>
                            </div>
                            <div className="menu-opcoes-divisora-topo-dashboard-sms campo-select-divisor-cor-1"></div>
                        </li>
                        <li>
                            <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover">
                                <span  className="drop-acoes-menu-label-dashboard-sms fonte-cor-1"  onClick={()=> props.handleTrocarGrafico('15dias')}>Últimos 15 dias</span>
                            </div>
                            <div className="menu-opcoes-divisora-topo-dashboard-sms campo-select-divisor-cor-1"></div>
                        </li>
                        <li>
                            <div className="drop-acoes-menu-dashboard-sms item-drop-acoes-hover">
                                <span className="drop-acoes-menu-label-dashboard-sms fonte-cor-1" onClick={() => exportarImagemDashSms(props.exportar)}>Exportar Imagem</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </Form>
        )
    }

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
 export default connect(mapStateToProps)(React.memo(Dasboard));