//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';
import {api} from '../../conexoes/api';/*eslint-disable*/
import { Button, Image,ButtonToolbar, Row, Col} from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {formatarData} from '../../utils/formatadorDataTabela'
import decryptJWT from '../../utils/decryptJWT';
//ICONES
import {lixeiraVermelho, envelopeAberto, envelopeFechado,editarVerde, passarPaguinaDireita, passarPaguinaEsquerda} from '../../utils/alternanciaIcones';
//COMPONENTES
import MensagemAtencao from '../componentesModais/mensagens/mensagemAtencao'
import MensagemErro from '../componentesModais/mensagens/mensagemErro'
import PaginacaoTabela from '../../utils/paginacaoTabela'
import MensagemSucesso from '../componentesModais/mensagens/mensagemSucesso'
import BarraFiltragem from '../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../componentesModais/barraDadosCliente/barraDadosCliente'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, limpadorFiltros,  limpouBusca, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro,revelarMensagemCabecalhoSelecionada as revelarMensagemCabecalho , atualizarMensagemCabecalhoSelecionada, atualizarMensagensCabecalho as atualizarMensagensCabecalhoFunc, revelarMensagemTabela} from "../../redux/actions/index";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'
// SPINER
import SpinerVeye from '../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../css/painelMensagens/painelMensagens.css'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
function Grid(props){
   //SPINNER
   const [spinAtivo, setSpinAtivo] = useState(true);
   //HOOKS TABELA
   const [itens, setItens] = useState("");
   const [paginaAtual, setPaginaAtual] = useState(1);
   const [renderizar, setRenderizar] = useState(false);
   const [valorLimite, setValorLimite] = useState(50);
   //HOOKS MENSAGENS
   const [desativaAtivar, setDesativarAtivar] = useState(false);
   const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
   const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
   const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
   const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");

   //HOOKS REDUX
   const despacho = useDispatch();
   let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
   let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
   let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
   let filtros = useSelector(state => state.filtrosState.filtros);
   let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
   let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
   let atualizarMensagensCabecalho = useSelector(state => state.mensagensState.atualizarMensagensCabecalho);
   let revelarMensagemCabecalhoSelecionada = useSelector(state => state.mensagensState.revelarMensagemCabecalhoSelecionada);
   let temFiltros = false;

   let itensVar = []

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio == 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
            despacho(revelarBarraFiltragem(false))
            despacho(limpadorFiltros(false))
        }
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        //despacho redux
        despacho(mudarManejador("mensagens"));
        async function carregarTabela(){
            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            if(conteudoBusca  && !temFiltros){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens(); 
                    const { data } = await api.get('/m1/consultar/mensagem?buscar='+conteudoBusca+'&paginaAtual='+contador+'&carregarLimit='+valorLimite+'&cd_pav_usuario_destinatario='+decryptJWT('codigo'));
                    inserirData(data.dados)
                }
            }else if(temFiltros){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                await contarItens();

                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    filtros: filtros,
                    cd_pav_usuario_destinatario:decryptJWT('codigo')
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/mensagem", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/mensagem?'+'paginaAtual='+contador+'&carregarLimit='+valorLimite+'&cd_pav_usuario_destinatario='+decryptJWT('codigo'));
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros, atualizarMensagensCabecalho])

  
    function inserirData(data){
        if(data?.length === 0 && itens.length) setItens([]);

        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                itensVar[k] = data[j]
                k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/mensagem?contar=true&buscar='+conteudoBusca+'&cd_pav_usuario_destinatario='+decryptJWT('codigo'));
            }else{
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    contar: true,
                    cd_pav_usuario_destinatario:decryptJWT('codigo')
                }
                apiData = await api.post("/m1/filtrar/mensagem", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalItens = apiData.data.dados[0].CONTADOR;
                totalPaginas = Math.ceil(totalItens / valorLimite)
                for( var i = 0 ; i < totalItens; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }



    const colunas = [{
        dataField: 'cd_msg_mensagem',
        text: 'ID',
        hidden:true,
        sort: true,
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        headerStyle: (colum, colIndex) => {
            return { width: '130px'}
        },
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },

    {
        dataField: 'fl_lido',
        headerClasses: 'nao-selecionavel',
        text: '',
        sort: true,
        formatter: (cell, row) => {
            if(cell == 0)
              return (
                <div className="container-envelope-mensagem-cabecalho-sistema">
                  <Image className="icone-email-dropdown-mensagem-cabecalho-sistema" src={envelopeFechado()} />
                </div>
              )  
            else
            return  (
                <div className="container-envelope-mensagem-cabecalho-sistema">
                  <Image className="icone-email-dropdown-mensagem-cabecalho-sistema" src={envelopeAberto()} />
                </div>
              )   
        }
    },


    {
        dataField: 'ds_mensagem_nivel',
        headerClasses: 'nao-selecionavel',
        text: 'Nível',
        sort: true,
        formatter: (cell, row) => {
            if(cell && cell == 'Atenção')
               return <div className="elipse-amarelo-mensagem-tabela"></div>;
            if(cell && cell == 'Normal')
               return <div className="elipse-verde-mensagem-tabela"></div>;
            if(cell && cell == 'Urgente')
               return <div className="elipse-vermelho-mensagem-tabela"></div>;
           
        }
    },

    {
        dataField: "dt_created_at",
        headerClasses: 'nao-selecionavel',
        text: 'Data',
        sort: true,
        formatter: (cell, row) => {
            return formatarData(cell);
        }
    },
    {
        dataField: 'ds_msg_mensagem_origem',
        headerClasses: 'nao-selecionavel',
        text: 'Origem',
        sort: true,
        formatter: (cell, row) => {
               return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },

    {
        dataField: 'ds_titulo',
        headerClasses: 'nao-selecionavel',
        text: 'Assunto',
        sort: true,
        formatter: (cell, row) => {
               return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
   
    {
        dataField: 'tx_texto',
        headerClasses: 'nao-selecionavel',
        text: 'Mensagem',
        sort: true,
        formatter: (cell, row) => {
            if(cell){
                let texto = cell.replace( /(<([^>]+)>)/ig, "")
                if(texto && texto.length > 100)
                return <p className="fonte-cor-1 label-tabela-1">{texto.substring(0, 100)+'...'}</p>;
                else
                return <p className="fonte-cor-1 label-tabela-1">{texto}</p>;
           }
           return <p className="fonte-cor-1 label-tabela-1"></p>;

       }
    },

    ]

    async function abrirMensagem(mensagem){

        if(mensagem.fl_lido == 0){
            const cd_msg_mensagem = mensagem.cd_msg_mensagem;
            const bodyAlteracao ={
                condicao:{
                    cd_msg_mensagem_plataforma: cd_msg_mensagem
                },
                dados:{
                    fl_lido:1
                }
            }
            await api.post('/m1/alterar/mensagem',bodyAlteracao);
            despacho(atualizarMensagensCabecalhoFunc(!atualizarMensagensCabecalho))
        }
        

    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            abrirMensagem(row);
            despacho(atualizarMensagemCabecalhoSelecionada(row)) 
            despacho(revelarMensagemCabecalho(true)) 
            despacho(revelarMensagemTabela(true))
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    return(
        <div className='container-tela'>
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <BarraDadosCliente/>
            {revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                keyField='ID' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                bootstrap4={true}
                                bordered={false}
                                classes="tabela"
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                             <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(Grid));