import { api_svdispositivos } from "./conexoes/api";

export async function getArrsAcoesRelatorios({dados, manejador, portalCliente}) {
    let arrAcoes = [];
    const arrRelatorios = [];

    dados.forEach(item => {
        if (item.fl_relatorio) {
            arrRelatorios.push(item);
        } else {
            arrAcoes.push(item);
        }
    })

    const getTemLinhasVivo = async () => {
        let linhasVivo = JSON.parse(localStorage.getItem('temLinhasVivo'))
        if(linhasVivo !== null) return linhasVivo;

        const queryFiltroVivo = '?busca=&limit=50&offset=0&filtro={"OPERADORA":2}&buscaArquivo={}'
        const res = await api_svdispositivos.get('/api/v2/devices/simcard'+queryFiltroVivo)
        linhasVivo = !!res.data.devices.length;
        localStorage.setItem('temLinhasVivo', linhasVivo)
        return linhasVivo;
    }

    const cdTesteConectividadeVivo = 226;
    const cdSuspender = 29;
    const cdRemoverSuspensao = 26;

    const temAcaoTesteConectividadeVIVO = (arrAcoes) => arrAcoes.find(acao => acao.cd_pav_acao == cdTesteConectividadeVivo)

    if(manejador === 'dspDispositivos') {

        if(temAcaoTesteConectividadeVIVO(arrAcoes)) {
            const temLinhasVivo = await getTemLinhasVivo();
            if(!temLinhasVivo) {
                arrAcoes = arrAcoes.filter(acao => acao.cd_pav_acao != cdTesteConectividadeVivo)
            }
        }
        if(portalCliente) {
            const acoesN3 = [2, 12, 13, 10, 5, 4]; // 9
            const subAcoesN3 = [19, cdSuspender, cdRemoverSuspensao, cdTesteConectividadeVivo]; // 38

            arrAcoes = arrAcoes.filter(acao => [...acoesN3, ...subAcoesN3].includes(acao.cd_pav_acao))

            // transforma "SMS -> Enviar" para "SMS Enviar" 
            arrAcoes = arrAcoes.map(item => {
                switch(item.cd_pav_acao) {
                    /* case 9: // SMS
                        return { ...item, 
                            "cd_pav_acao": 38,
                            "ds_acao": "Enviar SMS",
                            "fl_filho": 0,
                            "ds_acao_redux": "revelarEnviarSms",
                            "ds_rota_permissao": "veye/dispositivos/acoes/enviarSms",
                        } */
                    case 5:
                        return { ...item,
                            "cd_pav_acao": cdSuspender,
                            "ds_acao": "Suspender",
                            "fl_filho": 0,
                            "ds_acao_redux": "revelarSuspenderLinha",
                            "ds_rota_permissao": "veye/dispositivos/acoes/suspenderLinhas",
                        }
                    case 4:
                        return { ...item,
                            "cd_pav_acao": cdRemoverSuspensao,
                            "ds_acao": "Remover suspensão",
                            "fl_filho": 0,
                            "ds_acao_redux": "revelarReativarLinha",
                            "ds_rota_permissao": "veye/dispositivos/acoes/removerSuspensao",
                        }
                    default: 
                        return item;
                }
            })

            const getIdx = (val) => {
                let res;
                arrAcoes.forEach((acao, idx) => {
                    if(acao.cd_pav_acao == val) {
                        res = idx
                    }
                });
                return res;
            }

            const ajustarOrdenacao = (arrAcoes) => { // gambiarra
                const idx1 = getIdx(cdSuspender);
                const idx2 = getIdx(cdRemoverSuspensao);
    
                let auxItem = arrAcoes[idx1];
                arrAcoes[idx1] = arrAcoes[idx2];
                arrAcoes[idx2] = auxItem;
                return arrAcoes;
            }

            arrAcoes = ajustarOrdenacao(arrAcoes)
        }
    }
    return [arrAcoes, arrRelatorios];
}