import React, { useState } from "react";

import { getCorFundoExibicaoSimultanea } from "../../cores";
import BotaoVerde from "../BotaoVerde/BotaoVerde";
import OpcoesMenuCiclo from "./OpcoesCiclos";

import * as S from "./styles";

const HISTORICO = "historico";

function ExibicaoSimultanea(props) {
  const [botaoSelecionado, setBotaoSelecionado] = useState("atual");

  const botaoHistoricoSelecionado = botaoSelecionado === HISTORICO;

  const alterarCiclo = (ciclo) => {
    setBotaoSelecionado(ciclo);
    props.handleAlterarCicloGeral(ciclo);
  };

  return (
    <S.ExibicaoSimultaneaWrapper>
      <S.ExibicaoSimultaneaContent
        backgroundColor={getCorFundoExibicaoSimultanea()}
      >
        <p className="fonte-cor-20">Exibição simultânea:</p>
        <BotaoVerde
          ativo={botaoHistoricoSelecionado}
          onClick={() => alterarCiclo(HISTORICO)}
        >
          Últimos 6 ciclos
        </BotaoVerde>

        <OpcoesMenuCiclo
          id="selecionar-ciclo"
          alterarCiclo={alterarCiclo}
          botaoHistoricoSelecionado={!botaoHistoricoSelecionado}
        />
      </S.ExibicaoSimultaneaContent>
    </S.ExibicaoSimultaneaWrapper>
  );
}

export default ExibicaoSimultanea;
