//IMPORTAÇÕES
import React, { Component } from "react";/*eslint-disable*/
import Dropzone from "react-dropzone";/*eslint-disable*/
import { Button, Card, Dropdown, Accordion, Image } from  "react-bootstrap";/*eslint-disable*/
import { uniqueId } from 'lodash';
import { CSVLink } from 'react-csv'
import {api} from '../../../conexoes/api';
import {CircularProgressbar} from "react-circular-progressbar";
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso';
import XLSX  from "xlsx";

//ICONES
import { MdCheckCircle, MdError } from "react-icons/md";
import { importarArquivo, fecharJanela } from '../../../utils/alternanciaIcones';

//CSS
import "react-circular-progressbar/dist/styles.css";

//REDUX
import { useDispatch } from 'react-redux';
import { revelarImportarDescricao,revelarFiltrarSolicitacaoPorArquivo, mudarFiltroPorArquivoFatSolicitacao } from "../../../redux/actions/index";

// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'

import RetornarParametro from '../../../utils/decryptJWT'

let spinAtivo = false 
let clickDentro = false

const dadosJSONXLS = [
  {
    "protocolo": "",
    "operadora": "",
   
  }
]
const dadosJSONCSV = [
  {
    "protocolo": "",
    "operadora": "",
  }
]

function Sair(props){
  const despacho = useDispatch();

  async function handleSair() {
    despacho(revelarFiltrarSolicitacaoPorArquivo(false))
  }

  return <Button id="click-sair-modal" className="botao-fechar-janela" variant="danger" onClick={() => handleSair()}>
            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
          </Button>;
}


export default class ImportarDescricao extends Component {

    constructor(props) {
    super(props);
    this.state = {
      uploadedFiles: [],
      dadosArquivo: [],
      revelarMensagemSucesso: false,
    };
    this.props.setFiltroPorArquivo(undefined)

  }

  renderDragMessage = (isDragActive) => {
    if (!isDragActive) {
      return <div className="fonte-branca-tema-branco UploadMessage"> Importar </div>;
    }
    return <div className="UploadMessage" type="success"> Solte aqui </div>;
  };

  handleUpload = files => {
    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      progress: 0,
      uploaded: false,
      error: false,
      url: null
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
    });
      uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      })
    });
  };
  dadosArquivo = (id, data) => {

    this.setState({
      dadosArquivo: this.state.dadosArquivo.map(uploadedFile => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      })
    });
  };

  handleSairClick = () =>{
    const sair = document.getElementById('click-sair-modal')
    if(sair != null && clickDentro){
      clickDentro = false;
      sair.click()
    }
  }

  componentDidMount(){
    document.addEventListener('mousedown', this.handleSairClick);
    return () => document.removeEventListener('mousedown', this.handleSairClick);
  }

 

  uploadTabela = async(state) => {

    let arquivoFiltro = this.state.dadosArquivo;
     
    const {data} = await api.post("m1/servicos/fileToJson", {arquivoFiltro})
    await api.get(`m1/deletar/importarDescricao?path=`+ arquivoFiltro[0].path)

    this.props.setFiltroPorArquivo(data.dados.filtro)
    this.state.revelarMensagemSucesso = true
   
  }

  processUpload = (uploadedFile) => {

    const data = new FormData();
    spinAtivo = true
    data.append("file", uploadedFile.file, uploadedFile.name);
    api.post("m1/importar/importarDescricao", data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress
          });
        }
      })
      .then(response => {
        if (response.data.status === 200) {
          spinAtivo = false
           this.updateFile(uploadedFile.id, {
            uploaded: true,
            id: response.data._id,
            url: response.data.url
          });
          this.setState({
            dadosArquivo: this.state.dadosArquivo.concat(response.data.dados)
          });
        }
        else{
          spinAtivo = false
          this.updateFile(uploadedFile.id, {
            error: true
          });
        }
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data._id,
          url: response.data.url
        });
        
      })
      .catch((error) => {
        spinAtivo = false
        this.updateFile(uploadedFile.id, {
          error: true
        });
      }); 
  };

  handleSetar () {
    this.setState({
        revelarMensagemSucesso: true
      })
  }

  handleDelete = async e => {

    let arquivos = this.state.uploadedFiles
    let deletar = this.state.dadosArquivo
    const id = e.target.id
    spinAtivo = true

    await api.get(`m1/deletar/importarDescricao?path=`+ deletar[id].path)
      .then(response => {
        spinAtivo = false
        arquivos.splice(id, 1);
        deletar.splice(id, 1);
      })
      .catch((error) => {
        spinAtivo = false
      });

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => this.state.uploadedFiles[e] !== e),
      dadosArquivo: this.state.dadosArquivo.filter(file => this.state.dadosArquivo[e] !== e)
    });
    
  };

  async Cancelar() {
    let deletar = this.state.dadosArquivo
    clickDentro = true
    await deletar.map(async (item, i)=>{
      await api.get(`m1/deletar/importarDescricao?path=`+deletar[i].path)
        .then(response => {
          deletar.splice(i, 1);
        })
      })
    this.handleSairClick()
  }
  
 handleMensagemSucesso = (confirmacao) =>{
   if(this.state.revelarMensagemSucesso === false){
     this.setState({
       revelarMensagemSucesso: true
      })
      }else{
          this.setState({
              revelarMensagemSucesso: false
            })
            this.Cancelar()
      }
      if(confirmacao){
        this.handleSairClick()
      }

  }
  
  render() {
    
    return (
      <Card className="CardPrincipal fundo-cor-6">
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
        <h1 className="titulo fonte-cor-1"> <div className="elipse"/> 
          Importar filtro de solicitação
          <div className='btn-sair-importar-descricao'>
            <Sair props={this.props}/>
          </div>
        </h1>
        <div className="div-Template">
          <Dropdown alignRight variant="success">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle className="botaoTemplate bt-dropdown-template" as={Button} variant="link" eventKey="1"> <div className="UploadMessage fonte-cor-1"> Template </div> </Accordion.Toggle>
              <Accordion.Collapse className="botaoTemplate  menu-dropdown-template" eventKey="1">
                <>
                  <div className="triangulo-dropdown-configuracao-cabecalho-sistema triangulo-dropdown-template"></div>
                  <CSVLink filename="template.xls" className="botaoDownload fonte-cor-1" data={dadosJSONXLS}> XLS </CSVLink>
                    <Dropdown.Divider className="template-border"/>
                  <CSVLink filename="template.csv" className="botaoDownload fonte-cor-1" data={dadosJSONCSV}> CSV </CSVLink>
                </>
              </Accordion.Collapse>
            </Accordion>
          </Dropdown>

          {/* Botao para selecionar arquivos  */}
          <Dropzone onDropAccepted={this.handleUpload}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <Button className="botaoImportar"
                {...getRootProps()}
                isDragActive={isDragActive}
              >
                <input {...getInputProps()} />
                {this.renderDragMessage(isDragActive)}
                <Image className="imagemImportar" src={importarArquivo()} />
              </Button>
            )}

          </Dropzone>
        </div>

        
        {!!this.state.uploadedFiles.length && (
          <ListaArquivos files={this.state.uploadedFiles} onDelete={(e) => this.handleDelete(e)} />
        )}

        <div className="divBotoes">
          <Button disabled={(this.state.dadosArquivo.length==0)?true:false} id="botaoConfirmar" className={(this.state.dadosArquivo.length==0)?"botao-confirmar-desabilitado":"botao-confirmar"}  type="submit" onClick={(e)=> this.uploadTabela(e)}><div className="fonte-cor-4">Confirmar</div></Button>          
          <Button className="botao-cancelar" type="submit" onClick={()=> this.Cancelar()}> <div className="fonte-cor-2">Cancelar</div> </Button>
         
          {this.state.revelarMensagemSucesso &&
            <div className="componente-modal-overlay">
                <div className="componente-modal">
                    <MensagemSucesso
                        handleMensagemSucesso={this.handleMensagemSucesso}
                        mensagemTitulo={"SUCESSO"}
                        mensagemParagrafo1={"Seu arquivo foi importado"}
                        mensagemBotao={"OK"}
                    />
                </div> 
            </div>
          }
        </div>
      </Card>
    );
  }
}

// funcao para mostrar na tela a lista de arquivos importados 
function ListaArquivos ({ files, onDelete })  {
  return (
    <div className="container-importar-descricao-lista-arquivos">
      {files.map((uploadedFile,i) => (
        <p className="tituloLista-importar-arquivo"> Arquivo selecionado
        <li className="lista-importar-arquivo" key={uploadedFile.id}>
              <p className="listaNome-importar-arquivo">{uploadedFile.name}</p>
          <div>
            {!uploadedFile.uploaded &&
              !uploadedFile.error && (
                <CircularProgressbar
                  styles={{
                    root: { width: 24 },
                    path: { stroke: "#36D896" }
                  }}
                  strokeWidth={10}
                  percentage={uploadedFile.progress}
                />
              )}
            {uploadedFile.uploaded && <MdCheckCircle size={24} color="#36D896"/>}
            {uploadedFile.error && <MdError size={24} color="#e57878"/>}
            
          </div>
          <div className="botaoLixeira">
                {uploadedFile.readableSize}{" "}
                {!uploadedFile.id && (
                  <button className="excluir" onClick={(e) => onDelete(e)}>
                    <div className="lixeira" id={i}/> 
                  </button>
                )}
              </div>
        </li>
        </p>
      ))}
    </div>
)}

