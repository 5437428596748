import {api} from '../conexoes/api';/*eslint-disable*/
import RetornarParametro from './decryptJWT';/*eslint-disable*/
const contratosPermitidos = [1,11]

export async function identificarValidacaoAcoes(simcardsSelecionados,cd_crm_solicitacao_tipo,filtrosOuBuscaArquivoObj, modoSelecaoSimcard,conteudoBusca,separadorLinha, retornarErro=false){
    /* -------------------------------------------------------- */
    let filtros;
    let buscaArquivo;

    if(filtrosOuBuscaArquivoObj?.buscaArquivo?.chave) { // ex: filtrosOuBuscaArquivoObj = { filtros, buscaArquivo }
        buscaArquivo = filtrosOuBuscaArquivoObj.buscaArquivo;
        filtros = filtrosOuBuscaArquivoObj.filtros;
    } else if(filtrosOuBuscaArquivoObj?.filtros) {
        filtros = filtrosOuBuscaArquivoObj.filtros;
    } else {
        filtros = filtrosOuBuscaArquivoObj;
    }
    /* -------------------------------------------------------- */

    let contador = 0
    let contadorExcluidos = 0
    let itensValidos = []                              
    let itensInvalidos = []     
    let simcards = []
    let entrouItensInvalidos = false

    await Promise.all(
        simcardsSelecionados.map((item_simcardsSelecionados,i)=>{
            simcards.push(
                {
                    cd_dsp_simcard:item_simcardsSelecionados.CD_SIMCARD, 
                    cd_dsp_situacao_contrato:item_simcardsSelecionados.CODIGO_SITUACAO_PROTHEUS,
                    cd_dsp_operadora: item_simcardsSelecionados.CD_OPERADORA
                }
            );
        })
    )

    const validacao = await api.post("m1/filtrar/validador_solicitacao",{
        simcards,
        cd_crm_solicitacao_tipo: cd_crm_solicitacao_tipo,
        filtros,
        buscaArquivo,
        codigo_cliente: RetornarParametro('codigoCliente'),
        conteudoBusca:conteudoBusca,
        separadorLinha:separadorLinha,
        fl_selecionar_todo_parque: modoSelecaoSimcard
    }, { timeout: 62000 })
        .then(function(resposta){
            return resposta.data;
        })
        .catch(function(erro){
            const errorObj = {
                code: erro.code,
                message: erro.message,
                name: erro.name,
                response: erro.response,
            }
            console.error(errorObj)

            if(retornarErro && (erro.code === 'ECONNABORTED' || (erro.response && erro.response.status == 504))) { // timeout
                return { erro: 'timeout' }
            }
            throw erro.stack;
        })
    if(validacao.status == 200){
        await Promise.all(validacao.dados.map(async (item, i)=>{
            if(item.cd_dsp_simcard === 0){
                itensInvalidos[contadorExcluidos] = {CD_SIMCARD: "Todos Selecionados", ds_motivo:item.ds_motivo}
                contadorExcluidos++ 
                entrouItensInvalidos = true;
            }
            if(modoSelecaoSimcard === true){
                if(!entrouItensInvalidos){
                    if(item.fl_permissao == 1){
                        itensValidos.push({CD_SIMCARD: item.cd_dsp_simcard, ds_motivo:item.ds_motivo, CALLERID: item.CALLERID})
                        contador++
                    }   
                    else{
                        itensInvalidos[contadorExcluidos] = {CD_SIMCARD: item.cd_dsp_simcard, ds_motivo:item.ds_motivo, CALLERID: item.CALLERID}
                        contadorExcluidos++ 
                    }
                }
            }
            else{
                simcardsSelecionados.map((item_simcardsSelecionados,i)=>{
        
                    if(item_simcardsSelecionados.CD_SIMCARD == item.cd_dsp_simcard){
                        //verifica se o simcard possui permissao
                        if(item.fl_permissao == 1){
                            itensValidos.push(item_simcardsSelecionados)
                            contador++
                        }   
                        else{
                            itensInvalidos[contadorExcluidos] = {CALLERID: item_simcardsSelecionados.CALLERID, CD_SIMCARD: item.cd_dsp_simcard, ds_motivo:item.ds_motivo}
                            contadorExcluidos++ 
                        }
                    }
        
                })
            }
        }))
    }

    return {itensValidos,itensInvalidos}  
}

export async function identificarValidacaoTesteConexao(simcardsSelecionados, totalItensPreSelecao=0) {
    const limite = 50;
    const validacao = { itensValidos: [], itensInvalidos: [] };

    if(simcardsSelecionados.length === 0 && totalItensPreSelecao <=limite) return;

    if(simcardsSelecionados.length > limite || simcardsSelecionados.length === 0) {
        validacao.itensInvalidos.push({
            CD_SIMCARD: "Todos Selecionados",
            ds_motivo: "A quantidade de simcards deve ser entre: 1 e "+limite + ". Selecione novamente sem exceder este limite."
        })
    } else if (validacao.itensInvalidos.length === simcardsSelecionados.length) {

        validacao.itensInvalidos.push({
            CD_SIMCARD: "Todos Selecionados",
            ds_motivo: "Teste de Conexão apenas para operadora VIVO"
        })
    } else {
        simcardsSelecionados.map(simcard => {
            if(simcard.OPERADORA === 'VIVO') {
                const { CD_SIMCARD, CALLERID,  FULLCALLERID, ICCID, ON_OFF, ULTIMO_EVENTO, TRAFEGO, CD_OPERADORA, CODIGO_SITUACAO_PROTHEUS } = simcard;
                validacao.itensValidos.push({ CD_SIMCARD, CALLERID,  FULLCALLERID, ICCID, ON_OFF, ULTIMO_EVENTO, TRAFEGO, CD_OPERADORA, CODIGO_SITUACAO_PROTHEUS });
                // validacao.itensValidos.push(simcard);
            } else {
                validacao.itensInvalidos.push({
                    CD_SIMCARD: simcard.CD_SIMCARD,
                    CALLERID: simcard.CALLERID,
                    ds_motivo: 'Teste de Conexão apenas para operadora VIVO'
                });
            }
        })
    }

    return validacao;
}
