import React, { useState, useEffect } from "react";
import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";
import { setaDropdown } from '../../utils/alternanciaIcones';
import decryptJWT from '../../utils/decryptJWT';
import SelectGenerico from '../componentesModais/selects/selectGenerico';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltrosFaturas(props) {
    /*eslint-disable*/
    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())

    const [cdCliente, setCdCliente] = useState(decryptJWT("codigo"))
    const [cdConta, setCdConta] = useState('')
    const [dsConta, setDsConta] = useState('')
    const [dsReferencia, setDsReferencia] = useState('')
    const [filtroOperadora, setFiltroOperadora] = useState('')
    const [filtrosStatus, setFiltrosStatus] = useState('')

    const [revelarSelectConta, setRevelarSelectConta] = useState(false);
    const [revelarSelectReferencia, setRevelarSelectReferencia] = useState(false);
    const [dummy, setDummy] = useState()

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            if(filtros.DT_INICIO_CICLO){
                let auxData = new Date(filtros.DT_INICIO_CICLO)
                auxData.setDate(auxData.getDate()+1)
                setFiltroDataInicioCiclo(auxData)
            }
            if(filtros.DT_FIM_CICLO){
                let auxData = new Date(filtros.DT_FIM_CICLO)
                auxData.setDate(auxData.getDate()+1)
                setFiltroDataFimCiclo(auxData)
            }
            if(filtros.DT_VENCIMENTO){
                let auxData = new Date(filtros.DT_VENCIMENTO)
                auxData.setDate(auxData.getDate()+1)
                setFiltroDataVencimento(auxData)
            }
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);
    
    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(dsConta == '') verificador.push(1)
            if(dsReferencia == '') verificador.push(1)
            if(filtroOperadora == '') verificador.push(1)
            if(filtrosStatus == '') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 4) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setCdConta('')
            setDsConta('')
            setDsReferencia('')
            setFiltroOperadora('')
            setFiltrosStatus('')

            props.setFoiLimpado(false)
        }
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [dsConta,filtrosStatus,dsReferencia,cdConta,filtroOperadora, props.foiLimpado]);

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1241) /*fatFaturasTabelaDropFiltrosFiltrar*/
        if(dsConta !== '' && dsConta !== undefined)arrayFiltrosSimcard.push("Conta: "+dsConta+"?DS_CONTA")
        if(dsReferencia !== '' && dsReferencia !== undefined)arrayFiltrosSimcard.push("Referência: "+dsReferencia+"?DS_REFERENCIA")
        if(filtroOperadora !== '' && filtroOperadora !== undefined)arrayFiltrosSimcard.push("Operadora: "+filtroOperadora+"?OPERADORA")
        if(filtrosStatus !== '' && filtrosStatus !== undefined)arrayFiltrosSimcard.push("Status: "+filtrosStatus+"?STATUS")
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-referencia","imagem-filtro-referencia"); ColetaClickstream(1238) /*fatFaturasTabelaDropFiltrosInicioDoCiclo*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Conta </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-referencia"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-referencia"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-texto-conta-filtros fonte-cor-1">Selecione o número da conta</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control id="DS_CONTA" name="DS_CONTA" type="text" value={dsConta == 'Selecione' ? '': dsConta} className="d-none"/>
                            {revelarSelectConta === false &&
                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectConta(!revelarSelectConta)}>
                                    <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsConta === ""?"Selecione":dsConta}</div>
                                    <img className={revelarSelectConta?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }
                            {revelarSelectConta === true &&
                                <SelectGenerico
                                    paramCampo={null}
                                    paramRota={"contas_faturas"}
                                    setRevelarSelect={setRevelarSelectConta}
                                    setCodigo={setCdConta}
                                    setDescricao1={setDsConta}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    placeholder={dsConta}
                                    sufixo={""}
                                >
                                </SelectGenerico>
                            }
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-conta","imagem-filtro-conta"); ColetaClickstream(1239) /*fatFaturasTabelaDropFiltrosFimDoCiclo*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Referência </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-conta"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-conta"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-texto-conta-filtros fonte-cor-1">Selecione o mês de referência</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control id="DS_REFERENCIA" name="DS_REFERENCIA" type="text" value={dsReferencia == 'Selecione' ? '': dsReferencia} className="d-none"/>
                            {revelarSelectReferencia === false &&
                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectReferencia(!revelarSelectReferencia)}>
                                    <div id="DS_REFERENCIA" name="DS_REFERENCIA" className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsReferencia === ""?"Selecione":dsReferencia}</div>
                                    <img className={revelarSelectReferencia?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }
                            {revelarSelectReferencia === true &&
                                <SelectGenerico
                                    paramRota={"referencia_faturas"}
                                    paramCampo1={decryptJWT("fatContas")}
                                    paramCampo2={null}
                                    paramCampo3={null}
                                    paramCampo4={null}
                                    setCodigo={setDummy}
                                    setDescricao1={setDsReferencia}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectReferencia}
                                    placeholder={dsReferencia}
                                    sufixo={""}
                                >
                                </SelectGenerico>
                            }
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-operadora","imagem-filtro-operadora"); ColetaClickstream(1240) /*fatFaturasTabelaDropFiltrosDataDeVencimento*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Operadora </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-operadora"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-operadora"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperadora('Vivo')}>Vivo
                            <input defaultChecked={handlerCheck("OPERADORA","Vivo")} type="radio" id="OPERADORA_VIVO" name="OPERADORA" value="Vivo"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperadora('Claro')}>Claro
                            <input defaultChecked={handlerCheck("OPERADORA","Claro")} type="radio" id="OPERADORA_CLARO" name="OPERADORA" value="Claro"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperadora('Tim')}>Tim
                            <input defaultChecked={handlerCheck("OPERADORA","Tim")} type="radio" id="OPERADORA_TIM" name="OPERADORA" value="Tim"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperadora('Algar')}>Algar
                            <input defaultChecked={handlerCheck("OPERADORA","Algar")} type="radio" id="OPERADORA_ALGAR" name="OPERADORA" value="Algar"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperadora('OI')}>OI
                            <input defaultChecked={handlerCheck("OPERADORA","OI")} type="radio" id="OPERADORA_OI" name="OPERADORA" value="OI"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperadora('Arqia')}>Arqia
                            <input defaultChecked={handlerCheck("OPERADORA","Arqia")} type="radio" id="OPERADORA_ARQIA" name="OPERADORA" value="Arqia"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-status","imagem-filtro-status")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Status </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-status"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltrosStatus('Paga')}>Paga
                            <input defaultChecked={handlerCheck("STATUS","Paga")} type="radio" id="STATUS_PAGA" name="STATUS" value="Paga"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltrosStatus('Vencida')}>Vencida
                            <input defaultChecked={handlerCheck("STATUS","Vencida")} type="radio" id="STATUS_VENCIDA" name="STATUS" value="Vencida"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltrosStatus('Contestada')}>Contestada
                            <input defaultChecked={handlerCheck("STATUS","Contestada")} type="radio" id="STATUS_CONTESTADA" name="STATUS" value="Contestada"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltrosStatus('Aberta')}>Aberta
                            <input defaultChecked={handlerCheck("STATUS","Aberta")} type="radio" id="STATUS_ABERTA" name="STATUS" value="Aberta"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}