import decryptJWT from "./decryptJWT";
import utilsLocalStorage from "./localStorage";

const utilsSms = {
    getCdSmsContaUsuario: (campo='cdSmsContaUsuario') => decryptJWT(campo) || utilsLocalStorage.getCdSmsContaUsuario(),

    getNewBodyPost: (bodySmsObj) => {
        const cdSmsContaUsuario_local = utilsLocalStorage.getCdSmsContaUsuario();

        if(cdSmsContaUsuario_local) { // Nova conta SMS
            bodySmsObj.cd_sms_conta_usuario = cdSmsContaUsuario_local;
        }
        return bodySmsObj;
    },

    getNewQueryGet: (querySmsStr) => {
        const cdSmsContaUsuario_local = utilsLocalStorage.getCdSmsContaUsuario();

        if(cdSmsContaUsuario_local) { // Nova conta SMS
            if(querySmsStr === '') {
                querySmsStr = '?'
            } else {
                querySmsStr += '&'
            }
            querySmsStr += 'cd_sms_conta_usuario='+cdSmsContaUsuario_local;
        }
        return querySmsStr;
    }
}

export default utilsSms;