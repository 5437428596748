//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import { Button, Form} from 'react-bootstrap';/*eslint-disable*/
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'/*eslint-disable*/
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectTemplateSmsPermissao from '../../componentesModais/selects/selectTemplateSmsPermissao'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectTemplateSmsPermissao, setRevelarSelectTemplateSmsPermissao] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">Permissão de uso</div>
            </Form.Label>
            { revelarSelectTemplateSmsPermissao === false &&
                <Button name="cdTemplateSmsPermissao" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTemplateSmsPermissao(!revelarSelectTemplateSmsPermissao)}>
                    <div className="fonte-cor-1 label-campo">{dsTemplateSmsPermissao}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectTemplateSmsPermissao === true &&
                <SelectTemplateSmsPermissao
                    setRevelarSelectTemplateSmsPermissao={setRevelarSelectTemplateSmsPermissao} //Hook para fechar a modal
                    setCdTemplateSmsPermissao={setCdTemplateSmsPermissao} //Código do TemplateSmsPermissao
                    setDsTemplateSmsPermissao={setDsTemplateSmsPermissao} //Nome do TemplateSmsPermissao
                    dsTemplateSmsPermissao={dsTemplateSmsPermissao} 
                    phTemplateSmsPermissao={phTemplateSmsPermissao} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectTemplateSmsPermissao>
            }
        </Form.Group>*/

export default function SelectTemplateSmsPermissao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleTemplatePermissao(cdTemplatePermissao,dsTemplatePermissao){
        props.setCdTemplateSmsPermissao(cdTemplatePermissao)
        props.setDsTemplateSmsPermissao(dsTemplatePermissao)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectTemplateSmsPermissao(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal} >
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <Button name="cdTemplateSms" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectTemplateSmsPermissao(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsTemplateSmsPermissao}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleTemplatePermissao(1,"Todos usuários")}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Todos usuários</span>
                        </div>
                        <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
                    </li>
                    <li>
                        <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleTemplatePermissao(0,"Somente eu")}>
                            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Somente eu</span>
                        </div>
                    </li>
                </ul>
            </div>
        </Form>
    )
}