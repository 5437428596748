//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import { Image } from 'react-bootstrap';/*eslint-disable*/
//ICONES
import { perfil, iconeDescricao, iconeDescricaoAtivado, perfilAtivado } from '../../../../utils/alternanciaIcones'
//COMPONENTES
import DadosCliente from './dadosCliente/dadosCliente'
import InformacoesLogin from './informacoesLogin/informacoesLogin'
import VisaoGeral from './visaoGeral/visaoGeral'
import Clientes from './clientesDoCliente/clientesDoCliente'
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'
//IMPORTAÇÕES REDUX
import { useSelector } from 'react-redux';
import '../../../../css/painelVirtueyes/detalhamentoCliente/detalhamentoCliente.css'

export default function DetalhamentoCliente(props) {
    //HOOKS
    const [renderizar, setRenderizar] = useState(false)
    //HOOKS COMPONENTES
    const [revelarDadosCliente, setRevelarDadosCliente] = useState(false)
    const [revelarVisaoGeral, setRevelarVisaoGeral] = useState(false)
    const [revelarLogin, setRevelarLogin] = useState(false)
    const [revelarClientesFilhos, setRevelarClientesFilhos] = useState(false)
    const [idMenu, setIdMenu] = useState('-1')
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);

    useEffect(() => {
        //VAZIO POR HORA
    },[renderizar])

    function handleRenderTela(tela){
        setSpinAtivo(true)
        setIdMenu(tela)
        if (tela === '1'){
            setRevelarClientesFilhos(false)
            setRevelarVisaoGeral(false)
            setRevelarLogin(false)
            setRevelarDadosCliente(true)
        }
        if (tela === '2'){
            setRevelarClientesFilhos(false)
            setRevelarDadosCliente(false)
            setRevelarLogin(false)
            setRevelarVisaoGeral(true)
        }
        if (tela === '3'){
            setRevelarClientesFilhos(false)
            setRevelarDadosCliente(false)
            setRevelarVisaoGeral(false)
            setRevelarLogin(true)
        }
        if (tela === '4'){
            setRevelarDadosCliente(false)
            setRevelarVisaoGeral(false)
            setRevelarLogin(false)
            setRevelarClientesFilhos(true)
        }
        setSpinAtivo(false)
    }

    return(
            <div className="container-detalhamento-cliente-novo fundo-cor-1">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                    <div className="container-navegacao-detalhamento-cliente-novo">
                        <div className="triangulo-navegacao-detalhamento-cliente campo-select-triangulo-cor-3"></div>
                        <div className="corpo-navegacao-detalhamento-cliente-novo fundo-cor-5">
                            <ul className="lista-navegacao-detalhamento-cliente">
                                <li key={"1"} onClick={() => handleRenderTela('1')}>
                                    { idMenu === "1" ?
                                        <div className="container-item-navegacao-detalhamento-virtueyes-cliente-ativado  fundo-cor-7" onClick={e => handleRenderTela("1")}>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={iconeDescricaoAtivado()}/>
                                            </div>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('1')}>Dados</span>
                                            </div>
                                        </div>
                                    :
                                        <div className="container-item-navegacao-detalhamento-virtueyes-cliente-desativado  " onClick={e => handleRenderTela("1")}>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={iconeDescricao()}/>
                                            </div>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('1')}>Dados</span>
                                            </div>
                                        </div>
                                    }
                                </li>
                                <div>
                                    {/*<li key={"2"} onClick={() => handleRenderTela('2')}>*/}
                                    {/*    { idMenu === "2" ?*/}
                                    {/*        <div className="container-item-navegacao-detalhamento-virtueyes-cliente-ativado  fundo-cor-7" onClick={e => handleRenderTela("2")}>*/}
                                    {/*            <div className="coluna-item-navegacao-detalhamento-cliente-1">*/}
                                    {/*                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={visaoGeralAtivado()}/>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="coluna-item-navegacao-detalhamento-cliente-2">*/}
                                    {/*                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('2')}>Visão geral</span>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    :*/}
                                    {/*        <div className="container-item-navegacao-detalhamento-virtueyes-cliente-desativado " onClick={e => handleRenderTela("2")}>*/}
                                    {/*            <div className="coluna-item-navegacao-detalhamento-cliente-1">*/}
                                    {/*                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={visaoGeral()}/>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="coluna-item-navegacao-detalhamento-cliente-2">*/}
                                    {/*                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('2')}>Visão geral</span>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    }*/}
                                    {/*</li>*/}
                                </div>
                                <li key={"3"} onClick={() => handleRenderTela('3')}>
                                    { idMenu === "3" ?
                                        <div className="container-item-navegacao-detalhamento-virtueyes-cliente-ativado  fundo-cor-7"
                                             onClick={e => handleRenderTela("3")}>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={perfilAtivado()}/>
                                            </div>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1"
                                                      onClick={() => handleRenderTela('3')}>Usuários</span>
                                            </div>
                                        </div>
                                    :
                                        <div className="container-item-navegacao-detalhamento-virtueyes-cliente-desativado "
                                             onClick={e => handleRenderTela("3")}>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-1">
                                                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={perfil()}/>
                                            </div>
                                            <div className="coluna-item-navegacao-detalhamento-cliente-2">
                                                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1"
                                                      onClick={() => handleRenderTela('3')}>Usuários</span>
                                            </div>
                                        </div>
                                    }
                                </li>
                                <div>
                                    {/*<li key={"4"} onClick={() => handleRenderTela('4')}>*/}
                                    {/*    { idMenu === "4" ?*/}
                                    {/*        <div className="container-item-navegacao-detalhamento-virtueyes-cliente-ativado  fundo-cor-7" onClick={e => handleRenderTela("4")}>*/}
                                    {/*            <div className="coluna-item-navegacao-detalhamento-cliente-1">*/}
                                    {/*                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={perfilAtivado()}/>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="coluna-item-navegacao-detalhamento-cliente-2">*/}
                                    {/*                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('4')}>Clientes</span>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    :*/}
                                    {/*        <div className="container-item-navegacao-detalhamento-virtueyes-cliente-desativado " onClick={e => handleRenderTela("4")}>*/}
                                    {/*            <div className="coluna-item-navegacao-detalhamento-cliente-1">*/}
                                    {/*                <Image className="nao-selecionavel icone-item-navegacao-detalhamento-cliente" src={perfil()}/>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="coluna-item-navegacao-detalhamento-cliente-2">*/}
                                    {/*                <span className="label-item-navegacao-detalhamento-cliente nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('4')}>Clientes</span>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    }*/}
                                    {/*</li>*/}
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="conteudo-detalhamento-cliente">
                        {revelarDadosCliente &&
                            // <div className="container-tela-sem-filtro-detalhamento-cliente">
                                <div className="container-tela-conteudo-detalhamento-cliente">
                                    {/* <Form.Row>
                                        <Col>
                                            <img className="icone-label-filtro-detalhamento-cliente" src={elipseVerde()} alt="elipse"/>
                                            <Form.Label>
                                                <div className="fonte-cor-1 label-janela">Dados do cliente</div>
                                            </Form.Label>
                                        </Col>
                                    </Form.Row> */}
                                    <div className={estadoExpansaoMenu===true?"container-tela-corpo-detalhamento-cliente-1-novo":"container-tela-corpo-detalhamento-cliente-2"}>
                                        <DadosCliente
                                            logradourocliente={props.logradourocliente}
                                            bairrocliente={props.bairrocliente}
                                            ufcliente={props.ufcliente}
                                            cidadecliente={props.cidadecliente}
                                            cepcliente={props.cepcliente}
                                            vendedorcliente={props.vendedorcliente}
                                            cpfVendedorCliente={props.cpfVendedorCliente}
                                            emailVendedorCliente={props.emailVendedorCliente}
                                            telefoneVendedorCliente={props.telefoneVendedorCliente}
                                        />
                                    </div>
                                </div>
                            // </div>
                        }
                        {revelarVisaoGeral &&
                            // <div className="container-tela-sem-filtro-detalhamento-cliente">
                                <div className="container-tela-conteudo-detalhamento-cliente">
                                    {/* <Form.Row>
                                        <Col>
                                            <img className="icone-label-filtro-detalhamento-cliente" src={elipseVerde()} alt="elipse"/>
                                            <Form.Label>
                                                <div className="fonte-cor-1 label-janela">Visão geral</div>
                                            </Form.Label>
                                        </Col>
                                    </Form.Row> */}
                                    <div className={estadoExpansaoMenu===true?"container-tela-corpo-detalhamento-cliente-1":"container-tela-corpo-detalhamento-cliente-2"}>
                                        <VisaoGeral 
                                            cdCliente={props.cdCliente}
                                            noCdCliente={props.noCdCliente}
                                        />
                                    </div>
                                </div>
                            // </div>
                        }
                        {revelarLogin &&
                            // <div className="container-tela-sem-filtro-detalhamento-cliente">
                                <div className="crud-cliente-container-tela-conteudo-detalhamento">
                                    {/* <Form.Row>
                                        <Col>
                                            <img className="icone-label-filtro-detalhamento-cliente" src={elipseVerde()} alt="elipse"/>
                                            <Form.Label>
                                                <div className="fonte-cor-1 label-janela">Informações de login</div>
                                            </Form.Label>
                                        </Col>
                                    </Form.Row> */}
                                    <div className={estadoExpansaoMenu===true?"container-tela-corpo-detalhamento-cliente-1":"container-tela-corpo-detalhamento-cliente-2"}>
                                        <InformacoesLogin
                                            cdCliente={props.cdCliente}
                                            noCdCliente={props.noCdCliente}
                                            cdTipoCliente={props.cdTipoCliente}


                                            // handleAtualizar={props.handleAtualizar}
                                        />
                                    </div>
                                </div>
                            // </div>
                        }
                        {revelarClientesFilhos &&
                            // <div className="container-tela-sem-filtro-detalhamento-cliente">
                                <div className="container-tela-conteudo-detalhamento-cliente">
                                    {/* <Form.Row>
                                        <Col>
                                            <img className="icone-label-filtro-detalhamento-cliente" src={elipseVerde()} alt="elipse"/>
                                            <Form.Label>
                                                <div className="fonte-cor-1 label-janela">Informações de login</div>
                                            </Form.Label>
                                        </Col>
                                    </Form.Row> */}
                                    <div className={estadoExpansaoMenu===true?"container-tela-corpo-detalhamento-cliente-1":"container-tela-corpo-detalhamento-cliente-2"}>
                                        <Clientes
                                            cdCliente={props.cdCliente}
                                            noCdCliente={props.noCdCliente}
                                        />
                                    </div>
                                </div>
                            // </div>
                        }
                    </div>
            </div>
    )
}