//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, fecharJanela, setaDropdown} from '../../../../utils/alternanciaIcones'
//MODAIS
import decryptJWT from '../../../../utils/decryptJWT'
import Calendario from '../../../calendario/calendario'
import SelectFatConta from '../../../componentesModais/selects/selectFatConta'
import SelectFatStatus from '../../../componentesModais/selects/selectFatStatus'
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import MensagemInformacao from '../../../componentesModais/mensagens/mensagemInformacao'
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch } from 'react-redux';
import { revelarEditarFatura, revelarCadastrarFatura } from "../../../../redux/actions/index";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function CadastroFatura(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const [dataMin, setDataMin] = useState()
    const [dataMax, setDataMax] = useState()
    const { register, handleSubmit, errors } = useForm();

    //HOOKS PRINCIPAIS
    const [cdConta, setCdConta] = useState(0);
    const [dsConta, setDsConta] = useState('Selecione');
    const [cdStatus, setCdStatus] = useState(0);
    const [dsStatus, setDsStatus] = useState('Selecione');
    const [dtVencimento, setDtVencimento] = useState('');
    const [dtInicioCiclo, setDtInicioCiclo] = useState('');
    const [dtFimCiclo, setDtFimCiclo] = useState('');
    const [diaVencimento, setDiaVencimento] = useState('');
    const [diaInicio, setDiaInicio] = useState('');
    // const [dtContestacao, setDtContestacao] = useState('');
    const [dtFiltro, setDtFiltro] = useState('');
    const [dtReferencia, setDtReferencia] = useState('');
    const [dtPagamento, setDtPagamento] = useState('');
    // const [dsProtocolo, setDsProtocolo] = useState('');
    const [n2ValorPago, setN2ValorPago] = useState('');
    //HOOKS PLACEHOLDERS
    //HOOKS MODAIS
    const [revelarSelectFatConta, setRevelarSelectFatConta] = useState(false)
    const [revelarSelectFatStatus, setRevelarSelectFatStatus] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [revelarMensagemAtencaoInicioCiclo, setRevelarMensagemAtencaoInicioCiclo] = useState(false)
    const [revelarMensagemAtencaoVencimento, setRevelarMensagemAtencaoVencimento] = useState(false)
    const [dummy, setDummy] = useState()
    //OUTROS HOOKS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [mensagemAlerta, setMensagemAlerta] = useState('')
    const [mensagemSucesso, setMensagemSucesso] = useState('')
    const modal = useRef();
    const despacho = useDispatch();

    const cd_pav_usuario = decryptJWT('codigo')


    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        setSpinAtivo(true)
        if(props.tipoModal === 'Editar'){
            if(props.cdConta !== null && props.cdConta !== undefined && props.cdConta !== ''){
                setCdConta(props.cdConta)
            }
            if(props.dsConta !== null && props.dsConta !== undefined && props.dsConta !== ''){
                setDsConta(props.dsConta)
            }
            if(props.cdStatus !== null && props.cdStatus !== undefined && props.cdStatus !== ''){
                setCdStatus(props.cdStatus)
            }
            if(props.dsStatus !== null && props.dsStatus !== undefined && props.dsStatus !== ''){
                setDsStatus(props.dsStatus)
            }
            if(props.dtVencimento !== null && props.dtVencimento !== undefined && props.dtVencimento !== '' && props.dtVencimento !== '0000-00-00 00:00:00' && props.dtVencimento !== '0000-00-00'){
                setDtVencimento(new Date(props.dtVencimento + " 00:00:00"))
            }
            if(props.dtInicioCiclo !== null && props.dtInicioCiclo !== undefined && props.dtInicioCiclo !== '' && props.dtInicioCiclo !== '0000-00-00 00:00:00' && props.dtInicioCiclo !== '0000-00-00'){
                setDtInicioCiclo(new Date(props.dtInicioCiclo + " 00:00:00"))
            }
            if(props.dtFimCiclo !== null && props.dtFimCiclo !== undefined && props.dtFimCiclo !== '' && props.dtFimCiclo !== '0000-00-00 00:00:00' && props.dtFimCiclo !== '0000-00-00'){
                setDtFimCiclo(new Date(props.dtFimCiclo))
            }
            // if(props.dtContestacao !== null && props.dtContestacao !== undefined && props.dtContestacao !== '' && props.dtContestacao !== '0000-00-00 00:00:00'){
            //     setDtContestacao(new Date(props.dtContestacao))
            // }
            if(props.dtFiltro !== null && props.dtFiltro !== undefined && props.dtFiltro !== '' && props.dtFiltro !== '0000-00-00 00:00:00' && props.dtFiltro !== '0000-00-00'){
                setDtFiltro(new Date(props.dtFiltro))
            }
            if(props.dsReferencia !== null && props.dsReferencia !== undefined && props.dsReferencia !== '' && props.dsReferencia !== '0000-00-00 00:00:00' && props.dsReferencia !== '0000-00-00'){
                setDtReferencia(new Date(props.dsReferencia))
            }
            if(props.dtPagamento !== null && props.dtPagamento !== undefined && props.dtPagamento !== '' && props.dtPagamento !== '0000-00-00 00:00:00' && props.dtPagamento !== '0000-00-00'){
                setDtPagamento(new Date(props.dtPagamento))
            }
            // if(props.dsProtocolo !== null && props.dsProtocolo !== undefined && props.dsProtocolo !== ''){
            //     setDsProtocolo(props.dsProtocolo)
            // }
            if(props.n2ValorPago !== null && props.n2ValorPago !== undefined && props.n2ValorPago !== ''){
                setN2ValorPago(props.n2ValorPago)
            }
            if(props.diaVencimento !== null && props.diaVencimento !== undefined && props.diaVencimento !== ''){
                setDiaVencimento(props.diaVencimento)
            }
            if(props.diaInicio !== null && props.diaInicio !== undefined && props.diaInicio !== ''){
                setDiaInicio(props.diaInicio)
            }
            setMensagemSucesso("Fatura "+props.cdFatura+" Editada.")
        }else{
            setMensagemSucesso("Fatura cadastrada.")
        }
        
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    //FAZ O MANEJO 
    async function handleAlterar(){
        await api.post('/m1/alterar/fat_faturas', {
            "dados":{
                'cd_fat_conta':cdConta,
                'cd_fat_fatura_status':cdStatus,
                'dt_vencimento':dtVencimento ? moment(dtVencimento).format("YYYY/MM/DD HH:mm:ss"):null,
                'dt_inicio_ciclo':dtInicioCiclo ? moment(dtInicioCiclo).format("YYYY/MM/DD HH:mm:ss"):null,
                'dt_fim_ciclo':moment(dtFimCiclo).format("YYYY/MM/DD HH:mm:ss"),
                'dt_contestacao':null,
                'ds_contestacao_protocolo':'',
                'dt_filtro':dtFiltro ? moment(dtFiltro).format("YYYY/MM/DD HH:mm:ss"):null,
                'ds_mes_referencia':moment(dtReferencia).format('YYYY/MM'),
                'dt_pagamento':dtPagamento ? moment(dtPagamento).format("YYYY/MM/DD HH:mm:ss"):null,
                'n2_valor_pago':n2ValorPago
            },
            "condicao":{
                'cd_fat_fatura' : props.cdFatura
            }
        })
        .then(async function (response) {
            api.post('/m1/cadastrar/fat_faturas_historico', {
                'cd_fat_fatura':props.cdFatura,
                'cd_fat_fatura_historico_tipo':cdStatus === 1?8:2,
                'n2_valor_cobrado':n2ValorPago,
                'cd_pav_usuario_created_at':cd_pav_usuario
            })
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    async function handleSalvar(){
        ColetaClickstream(1237) // 'dropAcoesRevelarCadastrarFaturaConfirmar'
        setSpinAtivo(true)

        await api.post('/m1/cadastrar/fat_faturas', {
            'cd_fat_conta':cdConta,
            'cd_fat_fatura_status':cdStatus,
            'dt_vencimento':dtVencimento,
            'dt_inicio_ciclo':dtInicioCiclo,
            'dt_fim_ciclo':moment(dtFimCiclo).format("YYYY/MM/DD HH:mm:ss"),
            'dt_contestacao':'',
            'ds_contestacao_protocolo':'',
            'dt_filtro':dtFiltro,
            'ds_mes_referencia':moment(dtReferencia).format('YYYY/MM'),
            'dt_pagamento':dtPagamento,
            'n2_valor_pago':n2ValorPago
        })
        .then(async function (response) {
            await api.post('/m1/cadastrar/fat_faturas_historico', {
                'cd_fat_fatura':response.data.dados.idInserido,
                'cd_fat_fatura_historico_tipo':1,
                'n2_valor_cobrado':n2ValorPago,
                'cd_pav_usuario_created_at':cd_pav_usuario
            })
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    function handleCancelar(){
        ColetaClickstream(1236) // 'dropAcoesRevelarCadastrarFaturaCancelar'
        despacho(revelarEditarFatura(false))
        despacho(revelarCadastrarFatura(false))
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
        props.setRevelarModalEdicao(false)
    }
    

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
            }
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                handleCancelar()
            }
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
            if(props.tipoModal === 'Cadastrar'){
                handleSalvar()
            }else{
                handleAlterar()
            }
        }
    }

    function handleMensagemAtencaoInicioCiclo(confirmacao){
        if(revelarMensagemAtencaoInicioCiclo === false){
            setRevelarMensagemAtencaoInicioCiclo(true)
        }else{
            setRevelarMensagemAtencaoInicioCiclo(false)
        }
    }
    
    function handleMensagemAtencaoVencimento(confirmacao){
        if(revelarMensagemAtencaoVencimento === false){
            setRevelarMensagemAtencaoVencimento(true)
        }else{
            setRevelarMensagemAtencaoVencimento(false)
        }
    }

    function handleValidarCampos(){
        if(cdStatus === 1 && (n2ValorPago === '' || n2ValorPago === null || n2ValorPago === undefined || n2ValorPago === false) && (dtPagamento === '' || dtPagamento === null || dtPagamento === undefined || dtPagamento === false)){
            setRevelarMensagemInformacao(true)
            setMensagemAlerta('Você irá '+props.tipoModal+' uma fatura como paga, porém não informou o valor nem a data de pagamento.')
        }else if(cdStatus === 1 && (n2ValorPago === '' || n2ValorPago === null || n2ValorPago === undefined || n2ValorPago === false)){
            setRevelarMensagemInformacao(true)
            setMensagemAlerta('Você irá '+props.tipoModal+' uma fatura como paga, porém não informou o valor pago.')
        }else if(cdStatus === 1 && (dtPagamento === '' || dtPagamento === null || dtPagamento === undefined || dtPagamento === false)){
            setRevelarMensagemInformacao(true)
            setMensagemAlerta('Você irá '+props.tipoModal+' uma fatura como paga, porém não informou a data de pagamento.')
        }else if(cdStatus === 2 && (dtVencimento === '' || dtVencimento === null || dtVencimento === undefined || dtVencimento === false)){
            setRevelarMensagemInformacao(true)
            setMensagemAlerta('Você irá '+props.tipoModal+' uma fatura como vencida, porém não informou a data de vencimento.')
        /* }else if(cdStatus === 3 && (dtContestacao === '' || dtContestacao === null || dtContestacao === undefined || dtContestacao === false)){
            setRevelarMensagemInformacao(true)
            setMensagemAlerta('Você irá '+props.tipoModal+' uma fatura como contestada, porém não informou a data de contestação.') */
        }else{
            if(props.tipoModal === 'Cadastrar'){
                handleSalvar()
            }else{
                handleAlterar()
            }
        }
    }

    useEffect(() => {
        if(dtVencimento !== '' && diaVencimento !== ''){
            if(moment(dtVencimento).format("DD") != diaVencimento){
                setRevelarMensagemAtencaoVencimento(true)
            }
        }
    },[dtVencimento, diaVencimento])

    useEffect(() => {
        if(dtInicioCiclo !== '' && diaInicio !== ''){
            if(moment(dtInicioCiclo).format("DD") != diaInicio){
                setRevelarMensagemAtencaoInicioCiclo(true)
            }
        }
    },[dtInicioCiclo, diaInicio])

    useEffect(() => {
        if(dtInicioCiclo !== ''){
            let auxDtFimCiclo = moment(dtInicioCiclo).add(1,"month").format("MM/DD/YYYY")
            setDtFimCiclo(moment(auxDtFimCiclo).subtract(1,'day'))
        }
    },[dtInicioCiclo])

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleValidarCampos())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <div className="modal-editar-fatura fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{props.tipoModal} fatura</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-editar-fatura-1">
                            <div className="coluna-cadastro-editar-fatura-3">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Conta</div>
                                    </Form.Label>
                                    { props.tipoModal === 'Cadastrar' && revelarSelectFatConta === false &&
                                        <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFatConta(!revelarSelectFatConta)}>
                                            <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsConta}</div>
                                            <img className={revelarSelectFatConta?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                         </Button>
                                    }
                                    { props.tipoModal === 'Cadastrar' && revelarSelectFatConta === true &&
                                        // <SelectFatConta
                                        //     setRevelarSelectFatConta={setRevelarSelectFatConta} //Hook para fechar a modal
                                        //     setCdConta={setCdConta} //Código do Conta
                                        //     setDsConta={setDsConta} //Nome do Conta
                                        //     setDiaVencimento={setDiaVencimento}
                                        //     setDiaInicio={setDiaInicio}
                                        //     dsConta={dsConta}
                                        // >
                                        // </SelectFatConta>
                                            <SelectGenerico
                                                paramRota={"fat_conta"}
                                                paramCampo1={cd_pav_usuario}
                                                paramCampo2={decryptJWT('codigoCliente')}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                setCodigo={setCdConta}
                                                setDescricao1={setDsConta}
                                                setDescricao2={setDiaInicio}
                                                setDescricao3={setDiaVencimento}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectFatConta}
                                                placeholder={dsConta}
                                                sufixo={""}
                                            >
                                            </SelectGenerico>
                                    }
                                    { props.tipoModal === 'Editar' &&
                                        <Button name="cdModulo" className="campo-texto-cor-3 campo-select">
                                            <div className="fonte-cor-1 label-campo">{dsConta}</div>
                                        </Button>
                                    }

                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-editar-fatura-1">
                            <div className="coluna-cadastro-editar-fatura-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Status</div>
                                    </Form.Label>
                                    {revelarSelectFatStatus === false &&
                                        <Button name="cdModulo" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFatStatus(!revelarSelectFatStatus)}>
                                            <div className="fonte-cor-1 label-campo">{dsStatus}</div>
                                            <img className={revelarSelectFatStatus?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    {revelarSelectFatStatus === true &&
                                        <SelectFatStatus
                                            setRevelarSelectFatStatus={setRevelarSelectFatStatus} //Hook para fechar a modal
                                            setCdStatus={setCdStatus} //Código do Status
                                            setDsStatus={setDsStatus} //Nome do Status
                                            dsStatus={dsStatus}
                                        >
                                        </SelectFatStatus>
                                    }
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-editar-fatura-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Vencimento</div>
                                    </Form.Label>
                                    <Form.Control type="text" value={dtVencimento ? moment(dtVencimento).format("YYYY/MM/DD") : dtVencimento} className="d-none"/>
                                    <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={dtVencimento} setData={setDtVencimento} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-editar-fatura-1">
                            <div className="coluna-cadastro-editar-fatura-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Inicio do ciclo</div>
                                    </Form.Label>
                                    <Form.Control type="text" value={dtInicioCiclo ? moment(dtInicioCiclo).format("YYYY/MM/DD") : dtInicioCiclo} className="d-none"/>
                                    <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={dtInicioCiclo} setData={setDtInicioCiclo} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-editar-fatura-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Fim do ciclo</div>
                                    </Form.Label>
                                    <Form.Control disabled type="text" value={dtFimCiclo != ""? moment(dtFimCiclo).format("DD/MM/YYYY"): ""} className="campo-texto-cor-3 campo-texto"/>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                {/* <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-editar-fatura-1">
                            <div className="coluna-cadastro-editar-fatura-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Data de Contestação</div>
                                    </Form.Label>
                                    <Form.Control type="text" value={dtContestacao ? moment(dtContestacao).format("YYYY/MM/DD") : dtContestacao} className="d-none"/>
                                    <Calendario imputCustomisado='' data={dtContestacao} setData={setDtContestacao} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-editar-fatura-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Protocolo</div>
                                    </Form.Label>
                                    <Form.Control data={dsProtocolo} name="protocolo" className="campo-texto-cor-3 campo-texto" maxLength="255" placeholder="Insira o nº do protocolo" onChange={e => setDsProtocolo(e.target.value)}></Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row> */}
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-editar-fatura-1">
                            <div className="coluna-cadastro-editar-fatura-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Data de filtro</div>
                                    </Form.Label>
                                    <Form.Control type="text" value={dtFiltro ? moment(dtFiltro).format("YYYY/MM/DD") : dtFiltro} className="d-none"/>
                                    <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={dtFiltro} setData={setDtFiltro} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-editar-fatura-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Mês referência</div>
                                    </Form.Label>
                                    {/* <Form.Control type="text" value={dtReferencia ? moment(dtReferencia).format("YYYY/MM") : dtReferencia} className="d-none"/> */}
                                    <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={dtReferencia} setData={setDtReferencia} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-editar-fatura-1">
                            <div className="coluna-cadastro-editar-fatura-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Data de pagamento</div>
                                    </Form.Label>
                                    <Form.Control type="text" value={dtPagamento ? moment(dtPagamento).format("YYYY/MM/DD") : dtPagamento} className="d-none"/>
                                    <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={dtPagamento} setData={setDtPagamento} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-editar-fatura-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Valor pago</div>
                                    </Form.Label>
                                    <Form.Control 
                                        value={n2ValorPago} 
                                        name="protocolo" 
                                        className="campo-texto-cor-3 campo-texto" 
                                        maxLength="255" 
                                        placeholder="Insira o valor pago" 
                                        type='number'
                                        onChange={e => setN2ValorPago(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Selecione a conta, o status da fatura e informe as datas de vencimento, inicio e fim do ciclo e o mês de referência.</Tooltip>}>
                            {cdConta && cdStatus !== 0 && dtVencimento && dtInicioCiclo && dtFimCiclo && dtReferencia ?
                                <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            :
                                <Button  className="botao-confirmar-desabilitado" variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            } 
                        </OverlayTrigger>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={mensagemSucesso}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={mensagemAlerta}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoInicioCiclo &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencaoInicioCiclo}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={"Você selecionou o dia de início do ciclo como "+ moment(dtInicioCiclo).format("DD") +", e o dia de início do ciclo da conta consta como "+ diaInicio+"."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoVencimento &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencaoVencimento}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={"Você selecionou o dia de vencimento como "+ moment(dtVencimento).format("DD") +", e o dia de vencimento da conta consta como "+ diaVencimento+"."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}