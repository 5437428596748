//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api, api_svsolicitacoes} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Button, Form, Col} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment'
import decryptJWT from '../../../../utils/decryptJWT';

import {elipseVerde, fecharJanela, lixeiraVermelho, exclamacao, alertaAtencao, setaDropdown} from '../../../../utils/alternanciaIcones';
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import { revelarAlterarcontaFaturas } from "../../../../redux/actions/index";
//MODAIS
import MensagemInformacao from '../../../componentesModais/mensagens/mensagemInformacao'
import { RenderMensagemAtencao } from '../../../componentesModais/mensagens/mensagemAtencao'
import SelectFatConta from '../../../componentesModais/selects/selectFatConta'
import Calendario from '../../../calendario/calendario'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import { MensagemErro } from '../../../componentesModais/mensagens/mensagemAcao';
import '../../../../css/painelFaturas/fatDispositivos/fatAcoes.css'

const valorLimite = 50
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let itensVar = []
let itensTemp = []

export default function AlterarConta(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS]
    const [dsConta, setDsConta] = useState('Selecione')
    const [cdConta, setCdConta] = useState()
    const [dsProtocolo, setDsProtocolo] = useState("")
    const [dsDescricao, setDsDescricao] = useState();
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(0);
    const [linhaRemover, setLinhaRemover] = useState('');
    const [itens, setItens] = useState([])
    const [itensInvalidos, setItensInvalidos] = useState([])
    const [itensInvalidosContador, setItensInvalidosContador] = useState([])

    const [itensCancelados, setItensCancelados] = useState([])
    const [linhasValidasAux, setLinhasValidasAux] = useState([])
    const [cdSimcardsArray, setCdSimcardsArray] = useState([])
    const [linhasInvalidasAux, setLinhasInvalidasAux] = useState([])
    const [linhasCanceladasAux, setLinhasCanceladasAux] = useState([])
    const [ignorarSimcardsArray, setIgnorarSimcardsArray] = useState([])
    const [arraySimcards, setArraySimcards] = useState([])
    const [controladorValidadorAcao, setControladorValidadorAcao] = useState(false)
    const [diaVencimento, setDiaVencimento] = useState('');
    const [diaInicio, setDiaInicio] = useState('');
    //OUTROS HOOKS
    const [revelarSelectFatConta, setRevelarSelectFatConta] = useState(false)
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [dataMin, setDataMin] = useState()
    const [dataMax, setDataMax] = useState()
    const [dataAbertura, setDataAbertura] = useState(new Date)
    const [dataConclusao, setDataConclusao] = useState('')

    const [customMensagemAtencao, setCustomMensagemAtencao] = useState('')
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] = useState(false)
    const [revelarMensagemAtencaoCancelados, setRevelarMensagemAtencaoCancelados] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const despacho = useDispatch();
    const modal = useRef();

    const cdCliente = decryptJWT('codigoCliente')
    const cdUsuario = decryptJWT('codigo')

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function limparModal() {
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
        itensVar = []
        itensTemp = []
    }

    async function handlerIniciarTela(){
        setSpinAtivo(true);
        await Promise.all(
            props.simcardsSelecionados.map((item) => {
                arraySimcards.push(item.CD_SIMCARD)
            })
        )
        
        limparModal();
        // await validarAcoes()
        await contarItens()

        if(!modoSelecaoSimcard){
            props.simcardsSelecionados.map((simcard)=>{
                if(!linhasInvalidasAux.includes(simcard.CD_SIMCARD) && !linhasValidasAux.includes(simcard)){
                    linhasValidasAux.push(simcard)
                    cdSimcardsArray.push(simcard.CD_SIMCARD)
                }
            })
            setItens(linhasValidasAux)
            totalPaginas = Math.ceil(linhasValidasAux.length / 50)
            setQuantidadeLinhasSelecionados(linhasValidasAux.length)
        }
        if(linhasInvalidasAux.length > 0){
            setRevelarMensagemAtencaoInvalido(true)
        }
        
        if(linhasCanceladasAux.length > 0){
            setRevelarMensagemAtencaoCancelados(true)
        }

        setSpinAtivo(false);
    }

    useEffect(()=>{
        setSpinAtivo(true);
        async function carregarTabela(){
            contador = (paginaAtual - 1) * valorLimite;

            if(controladorValidadorAcao === false){
                await handlerIniciarTela()
            }

            if(modoSelecaoSimcard === true){
                await buscarSimcards()
            }
        }
        carregarTabela()
        document.addEventListener('mousedown', handleClickFora);
        return () => {
            limparModal();
            document.removeEventListener('mousedown', handleClickFora);
        }
    },[paginaAtual])

    async function validarAcoes(){
        let linhasInvalidas = await api.post("/m1/consultar/fatValidarAcoes",{
            "cd_pav_cliente":cdCliente,
            "cd_pav_usuario":cdUsuario,
            "filtros":filtros,
            "buscaArquivo":buscaArquivo,
            "buscar":conteudoBusca,
            "separador":separadorLinha,
            "modoSelecaoSimcard":modoSelecaoSimcard,
            "arraySimcards":arraySimcards
        })
        if(linhasInvalidas.data.status !== 200){
            handleCancelar()
        }

        let auxInvalidas = linhasInvalidas.data.dados[0].filter(function (item) {
            return !this[JSON.stringify(item)] && (this[JSON.stringify(item)] = true);
        }, Object.create(null))
        
        setItensInvalidos(auxInvalidas)

        let auxInvalidasContador = auxInvalidas.filter(function (item) {
            return !this[JSON.stringify(item.cd_fat_simcard)] && (this[JSON.stringify(item.cd_fat_simcard)] = true);
        }, Object.create(null))

        setItensInvalidosContador(auxInvalidasContador)

        auxInvalidas.map((item)=>{
            linhasInvalidasAux.push(item.cd_fat_simcard)
            ignorarSimcardsArray.push(item.cd_fat_simcard)
        })

        setControladorValidadorAcao(true)
    }

    async function buscarSimcards(){
        if (controladorPagina <= paginaAtual) {
            const urlGet = `/m1/filtrar/fatSimcard?paguinaAtual=${contador}&carregarLimit=${valorLimite}&buscar=${conteudoBusca}&ordenador=${props.ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=true&cd_pav_usuario=${decryptJWT('codigo')}&linhasCanceladas=${linhasCanceladasAux}&linhasInvalidas=${linhasInvalidasAux}`
            await api.get(urlGet)
            .then(function(resposta){
                return resposta.data.dados;
            })
            .catch(function(erro){
                throw erro;
            })

            // const consultaSimcards = await api.post('/m1/filtrar/fatSimcard',{
            //     "cd_pav_usuario":decryptJWT('codigo'),
            //     "filtros":filtros,
            //     "buscar":conteudoBusca,
            //     "carregarLimit":valorLimite,
            //     "paguinaAtual":contador,
            //     "contar":true,
            //     "ordenador":props.ordenador,
            //     "separador":separadorLinha,
            //     "linhasCanceladas":linhasCanceladasAux,
            //     "linhasInvalidas":linhasInvalidasAux,
            // })
            // .then(function(resposta){
            //     return resposta.data.dados;
            // })
            // .catch(function(erro){
            //     throw erro;
            // })

            setSpinAtivo(false)
        }
    }

    async function handleCancelar() {
        ColetaClickstream(1159) // alterarContaCancelar
        setSpinAtivo(false)
        despacho(revelarAlterarcontaFaturas(false))
    }

    const colunasItensInvalidos = [{
        dataField: 'callerid',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'motivo',
        text: 'Motivo',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    }]

    const colunas = [{
        dataField: 'CD_SIMCARD',
        text: 'Código',
        headerClasses: 'nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },{
        dataField: 'DS_CONTA',
        text: 'Conta',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },{
        dataField: 'CALLERID',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },{
        dataField: 'DS_OPERADORA',
        text: 'Operadora',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },{
        dataField: 'ICCID',
        text: 'ICCID',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },{
        dataField: 'DS_CICLO_VIDA',
        text: 'Ciclo de vida',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },{
        dataField: 'DS_PLANO',
        text: 'Plano',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },{
        dataField: 'acoes',
        text: '',
        sort: false,
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    async function contarItens(invalidas){
        let arrayItens = []
        let apiData = [];

        const urlGet = `/m1/filtrar/fatSimcard?paguinaAtual=${contador}&carregarLimit=${valorLimite}&buscar=${conteudoBusca}&ordenador=${props.ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=true&cd_pav_usuario=${decryptJWT('codigo')}&linhasCanceladas=${linhasCanceladasAux}&linhasInvalidas=${linhasInvalidasAux}`
        apiData =  await api.get(urlGet)
        .then(function(resposta){
            return resposta;
        })
        .catch(function(erro){
            throw erro;
        })

        // apiData = await api.post('/m1/filtrar/fatSimcard',{
        //     "cd_pav_usuario":decryptJWT('codigo'),
        //     "buscar":conteudoBusca,
        //     "filtros":filtros,
        //     "contar":true,
        //     "ordenador":props.ordenador,
        //     "separador":separadorLinha,
        //     "linhasCanceladas":linhasCanceladasAux,
        //     "linhasInvalidas":linhasInvalidasAux,
        // }).then(function(resposta){
        //     return resposta;
        // })
        // .catch(function(erro){
        //     throw erro;
        // })
     

        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        setQuantidadeLinhasSelecionados(apiData.data.dados[0].CONTADOR)
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.CD_SIMCARD);
        }
    }

    async function handleRemoverLinha(CD_SIMCARD){
        ColetaClickstream(1160) // alterarContaRemoverLinha
        setQuantidadeLinhasSelecionados( quantidadeLinhasSelecionados - 1 )
        setRevelarMensagemAtencao(false)
        itens.forEach(function(e, index){
            if(CD_SIMCARD == e.CD_SIMCARD){
                itens.splice(index, 1);
                cdSimcardsArray.splice(index, 1);
                ignorarSimcardsArray.push(CD_SIMCARD)
            }
        })
        await contarItens()
        handleLinhasSelecionadas()
    }

    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itens.length - itensInvalidos.length)
        if(itens.length === 0){
            handleCancelar()
        }
    }

    function inserirData(data){
        // setItens('')
        if(itensVar.length == totalItens){
            for(let i=0;i<totalItens;i++){
                if (contador === i){
                    let k = i
                    for(let j=0;j<data.length;j++){
                        itensVar[k] = data[j]
                        k++
                    }
                }
                if(i == (totalItens-1)){
                    itensTemp = JSON.parse(JSON.stringify(itensVar))
                    setItens(JSON.parse(JSON.stringify(itensVar)))
                }
            }
        }
    }

    async function handleSalvar(){
        let itens2
        ColetaClickstream(1161) // alterarContaConfirmar
        setSpinAtivo(true)
        if(modoSelecaoSimcard){
            const buscaArquivoStr = JSON.stringify(buscaArquivo);
            itens2 = await api.get(`/m1/filtrar/fatSimcard?buscar=${conteudoBusca}&separador=${separadorLinha}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${buscaArquivoStr}&cd_pav_usuario=${decryptJWT('codigo')}&select=fatsim.cd_fat_simcard AS CD_SIMCARD`)
        }

        // await api.post('/m1/cadastrar/fat_solicitacao', {
        await api_svsolicitacoes.post('/api/v2/solicitacao', {
            "cd_pav_cliente": cdCliente,
            "modoSelecao":modoSelecaoSimcard,
            "ds_solicitacao":"Alteração de Conta",
            "cd_fat_solicitacao_tipo":14,
            "cd_sol_solicita_tipo": 3,
            "cd_fat_solicitacao_status":1,
            "dt_inicio":moment(dataAbertura).format('YYYY-MM-DD HH:mm:ss'),  
            "dt_prazo_conclusao": dataConclusao ? moment(dataConclusao).format('YYYY-MM-DD HH:mm:ss') : '0000-00-00 00:00:00',
            "ds_motivo":dsDescricao,
            "ds_protocolo_operadora":dsProtocolo,
            "cd_fat_conta":cdConta,
            "simcards":itens.length > 0 ? itens.map((item) => { return item.CD_SIMCARD }) : itens2.data?.dados.map((item) => { return item.CD_SIMCARD }),
            "cd_pav_usuario_created": decryptJWT('codigo'),
            "filtros":filtros,
            "buscaArquivo":buscaArquivo,
            "buscar":conteudoBusca,
            "separador":separadorLinha,
            "ignorarSimcardsArray":ignorarSimcardsArray
        })
        .then(function (response) {
            if(response.data.info === 'informacao'){
                handleMensagemInformacao(false)
            } 
            else if(response.data.info === 'oparadora_duplicada'){
                setMensagemErro("As linhas selecionadas pertencem à operadoras diferentes.")
                handleMensagemErro(false)
            }
            else{
                props.handleRespostaSolicitacao({response});
                handleMensagemSucesso(false)
            }
            
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            const { atencao, erro } = props.handleRespostaSolicitacao({error});
            if(atencao) {
                setCustomMensagemAtencao(atencao)
                setRevelarMensagemAtencao(true)
            }
            else if(erro) {
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            }
        });
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
            handleCancelar()
        }
        if(confirmacao){
            handleCancelar()
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            setCustomMensagemAtencao('');
        }
        if(confirmacao ===true){
            handleRemoverLinha(linhaRemover)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    function handleFecharMensagemItensInvalidos(){
        setRevelarMensagemAtencaoInvalido(!revelarMensagemAtencaoInvalido)
        if(modoSelecaoSimcard){
            if(itens <= 0){
                handleCancelar()
            }
        }
        else{
            if(quantidadeLinhasSelecionados <= 0){
                handleCancelar()
            }
        }
    }

    function handleFecharMensagemItensCancelados(){
        setRevelarMensagemAtencaoCancelados(!revelarMensagemAtencaoCancelados)
        if(modoSelecaoSimcard){
            if(itens <= 0){
                handleCancelar()
            }
        }
        else{
            if(quantidadeLinhasSelecionados <= 0){
                handleCancelar()
            }
        }
    }

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-protocolo"){
                setDsProtocolo(valor)
            }
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-alterar-ciclo-vida fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Alterar conta</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-alterar-ciclo-vida-1">
                            <div className="coluna-alterar-ciclo-vida-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Nova conta</div>
                                    </Form.Label>
                                    { revelarSelectFatConta === false &&
                                        <Button name="cdTela" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFatConta(!revelarSelectFatConta)}>
                                            <div className="fonte-cor-1 label-campo">{dsConta}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectFatConta === true &&
                                        <SelectFatConta
                                            setRevelarSelectFatConta={setRevelarSelectFatConta} //Hook para fechar a modal
                                            setCdConta={setCdConta} //Código do Tela
                                            setDsConta={setDsConta} //Nome do Tela
                                            setDiaVencimento={setDiaVencimento}
                                            setDiaInicio={setDiaInicio}
                                            dsConta={dsConta} 
                                        >
                                        </SelectFatConta>
                                    }
                                    {errors.status && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório.</p>}
                                </Form.Group>
                            </div>
                            {/* <div className="coluna-alterar-ciclo-vida-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Protocolo</div>
                                    </Form.Label>
                                    <Form.Control 
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        maxLength="255"
                                        name="protocolo" 
                                        value={dsProtocolo}
                                        onChange={e => setDsProtocolo(e.target.value)}
                                        placeholder="Insira o nº do protocolo" 
                                    />
                                </Form.Group>                                
                            </div> */}
                            <div className="coluna-alterar-ciclo-vida-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Abertura da solicitação</div>
                                    </Form.Label>
                                    <Calendario formato="DD/MM/YYYY" imputCustomisado='De:' data={dataAbertura} readOnly={false} setData={setDataAbertura}></Calendario>
                                    {errors.abertura && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório.</p>}
                                </Form.Group>                                
                            </div>
                            {/* <div className="coluna-alterar-ciclo-vida-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Previsão de conclusão</div>
                                    </Form.Label>
                                    <Calendario ref={register({ required: true})} formato="DD/MM/YYYY" imputCustomisado='Até:' data={dataConclusao} setData={setDataConclusao} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                    {errors.conclusao && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório.</p>}
                                </Form.Group>                                
                            </div> */}
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Descrição</div>
                            </Form.Label>
                            <Form.Control name="descricao" className="campo-texto-cor-3 campo-texto-area" as="textarea" rows="3" maxLength="255" placeholder="Descreva aqui o motivo" ref={register({ required: true, min: 3, max: 255 })} onChange={e => setDsDescricao(e.target.value)}></Form.Control>
                            {errors.descricao && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório.</p>}
                        </Form.Group>
                    </Col>
                </Form.Row>
                {modoSelecaoSimcard === false &&
                    <Form.Row>
                        <Col>
                            <div className="container-tabela-modal-faturas">
                                <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                                    {
                                        ({
                                            paginationProps,
                                            paginationTableProps
                                        }) => (
                                            <>
                                                <BootstrapTable
                                                    classes="tabela-transparente-modais"
                                                    condensed={true}
                                                    keyField='CD_SIMCARD' 
                                                    data={itens} 
                                                    columns={colunas} 
                                                    rowEvents={eventosLinhas}
                                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                    bootstrap4={true}
                                                    { ...paginationTableProps }
                                                />
                                                {modoSelecaoSimcard &&
                                                    <div className="paguinacao-tabela-modal">
                                                        <PaginacaoTabela
                                                            extrato={true}
                                                            setPaginaAtual={setPaginaAtual}
                                                            totalPaginas={totalPaginas}
                                                            totalItens={totalItens}
                                                            valorLimite={valorLimite}
                                                            paginaAtual={paginaAtual}
                                                            page={paginationProps.page}
                                                            onPageChange={paginationProps.onPageChange}
                                                        />
                                                    </div>
                                                }
                                            </>
                                        )
                                    }
                                </PaginationProvider>
                            </div>
                        </Col>
                    </Form.Row>
                }
                <Form.Row>
                    <Col>
                        <span className="fonte-cor-1 label-normal contador-linhas-acao-altera-ciclo-vida">{quantidadeLinhasSelecionados} linhas selecionadas</span>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {dsConta != 'Selecione' && dataAbertura != '' && dsDescricao !=  undefined?
                            <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                <div className="fonte-cor-4">Confirmar</div>
                            </Button>
                        :
                            <Button className="botao-confirmar-desabilitado" size="sm">
                                <div className="fonte-cor-4">Confirmar</div>
                            </Button>
                        }
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>

            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"A solicitação está sendo gerada!"}
                            mensagemParagrafo1={"Esse processo levará alguns minutos, assim que estiver pronto você receberá uma mensagem de conclusão pela plataforma."}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }

            { revelarMensagemAtencao && customMensagemAtencao && 
                <RenderMensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={customMensagemAtencao}
                    mensagemBotao={"Retornar"}
                />
            }

            { revelarMensagemAtencao && !customMensagemAtencao && 
                <RenderMensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Remover linha?"}
                    mensagemBotao={"Remover"}
                />
            }

            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagem={handleMensagemErro}
                    mensagemTitulo={"Erro"}
                    mensagemParagrafo1={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
            { revelarMensagemAtencaoInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                            </div>
                            <div className="fonte-cor-1 label-janela">{itensInvalidosContador.length} linhas foram retiradas</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensInvalidos}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensInvalidos()}>
                                    <div className="fonte-cor-2">Continuar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            
            { revelarMensagemAtencaoCancelados &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                            </div>
                            <div className="fonte-cor-1 label-janela">Você estará executando uma solicitação para {itensCancelados.length} linhas que constam como canceladas ou bloqueadas.</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensCancelados}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensCancelados()}>
                                    <div className="fonte-cor-2">Continuar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
    )
}