import { Modal } from "@mantine/core";
import styled from "styled-components";

export const ModalMantine = styled(Modal)`
  .mantine-Modal-content {
  }

  .mantine-Modal-body {
    padding: ${({ type }) =>
      type === "alerta" ? "0px" : "24px 0 0 !important"};
  }

  .mantine-Group-root {
    justify-content: flex-end;
    margin-top: 32px;
  }

  .mantine-Modal-close:hover {
    background-color: transparent;
  }

  .mantine-Modal-title {
    width: 100%;
  }

  .mantine-Modal-header {
    background-color: transparent;
    padding: 0;
  }

  .mantine-Modal-inner {
    z-index: 9999;
  }

  .mantine-Modal-content {
    background-color: ${({ theme }) => theme.cor23};
    padding: 24px;
    border-radius: 8px;
    min-height: ${({ minHeight }) => minHeight};
    width: ${({ width }) => width};
    overflow: hidden !important;
  }

  .mantine-Modal-overlay {
    z-index: 9999;
    background-color: #00000080;
  }
`;

export const TituloWrapper = styled.div`
  ${({ type }) =>
    type !== "default" &&
    `
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 16px;
  `}
`;

export const Titulo = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;
`;

export const TituloModal = styled.h1`
  font-family: RobotoRegular;
  margin: 0;
  font-weight: 100;
  font-size: 24px;
  color: ${({ theme }) => theme.cor9};
`;

export const Elipse = styled.img`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.cor11};
`;

export const BotoesFiltroWrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: flex-end;
`;

export const LimparFiltroLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
