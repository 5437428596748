import React, { useEffect, useState } from 'react';/*eslint-disable*/
import decryptJWT from '../../utils/decryptJWT'/*eslint-disable*/
import {api} from '../../conexoes/api'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador } from "../../redux/actions/index";
import { atualizarMensagensCabecalho as atualizarMensagensCabecalhoFunc, revelarMensagemCabecalhoSelecionada ,atualizarMensagensCabecalho, atualizarMensagemCabecalhoSelecionada} from "../../redux/actions/index";
import {telaInicialVeye, telaIniciaAlgar} from "../../utils/alternanciaIcones";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'
//CSS
import "../../css/telaInicial/telaInicial.css"
// SPINNER
import SpinerVeye from '../../components/spinnerVeye/spinnerVeye.js'


function TelaInicial(props) {
    //HOOKS REDUX
    const despacho = useDispatch();
    //SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    let revelarMensagemCabecalho = useSelector(state => state.mensagensState.revelarMensagemCabecalhoSelecionada);
    const cdCliente = decryptJWT('clientePrimario')
    // USABILIDADE
    // const [coletaUsabilidade, setColetaUsabilidade] = useState(false)
    
    // useEffect(() => {
    //     if(!coletaUsabilidade) {
    //         ColetaClickstream(69)
    //         setColetaUsabilidade(true)
    //     }
    // })

    
    useEffect(() => {
        setSpinAtivo(true);
        despacho(mudarManejador("telaInicial"));
    
        async function handleCarregarPermissoes() {
            const displayedMessages  = JSON.parse(localStorage.getItem('displayedMessages')) || [];

            let url = `m1/consultar/mensagem?cd_pav_usuario_destinatario=${decryptJWT('codigo')}&fl_lido=0&fl_exibir_inicio=1&paginaAtual=0&carregarLimit=10`;
            if (displayedMessages.length > 0)
            {
                url += `&displayedMessages=${encodeURIComponent(displayedMessages)}`;
            }
            const response = await api.get(url);

            if (response.data.dados.length > 0) 
            {
                response.data.dados.forEach(item => {
                    if (!displayedMessages.includes(item.cd_msg_mensagem)) 
                    {
                        despacho(atualizarMensagemCabecalhoSelecionada(item));
                        despacho(revelarMensagemCabecalhoSelecionada(true));
                        displayedMessages.push(item.cd_msg_mensagem);
                    }
                });
    
                localStorage.setItem('displayedMessages', JSON.stringify(displayedMessages));
            } else 
            {
                handleConfirmarMensagensLogin();
            }
            
            setSpinAtivo(false);
        }
    
        handleCarregarPermissoes();
    }, [revelarMensagemCabecalho]);
    

    async function handleConfirmarMensagensLogin() {
        localStorage.setItem('recebeuMensagemLogin', true)
    }
  
    /* async function abrirMensagem(mensagem){ // OBS: Não pode executar essa função porque vai fazer a mensagem aparecer e sumir em 1seg (por conta do "BUG de refresh ao logar")
        if(mensagem.fl_lido == 0){
            const cd_msg_mensagem_plataforma = mensagem.cd_msg_mensagem;
            const bodyAlteracao ={
                condicao:{
                    cd_msg_mensagem_plataforma
                },
                dados:{
                    fl_lido:1
                }
            }
            await api.post('/m1/alterar/mensagem',bodyAlteracao);
            despacho(atualizarMensagensCabecalhoFunc(!atualizarMensagensCabecalho))
        }
    } */
  
    return (
        <>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="container-tela-inicial fundo-cor-5">
                <div className='texto-bem-vindo fonte-cor-1'>Bem-vindo!</div>
                <div className="container-imagem-tela-inicial">
                    { props.imagensTema.img_inicial !== undefined &&
                        <>
                            {cdCliente == 2455 ?
                                <img alt="telaInicial" className="imagem-tela-inicial-algar" src={props.imagensTema.img_inicial}></img>
                            :
                                <img alt="telaInicial" className="imagem-tela-inicial" src={props.imagensTema.img_inicial}></img>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return { manejador: state.manejadorState.manejador,
             revelarMensagemCabecalho: state.mensagensState.revelarMensagemCabecalhoSelecionada
    };
} 

export default connect(mapStateToProps)(TelaInicial);