import Button from "@mui/material/Button";
import styled, { css } from "styled-components";

export const Botao = styled(Button)`
  outline: none !important;
  box-shadow: none !important;
  width: 153px;
  height: 32px;
  border-radius: 16px !important;
  padding: 4px !important;

  text-transform: none !important;
  font-size: 13px !important;
  font-size: 700;

  ${({ ativo }) =>
    ativo
      ? css`
          background-color: ${({ theme }) => theme.cor38} !important;
          color: ${({ theme }) => theme.cor1} !important;
        `
      : css`
          background-color: transparent !important;
          border: 1px solid ${({ theme }) => theme.cor38} !important;
          color: ${({ theme }) => theme.cor38} !important;
        `}
`;
