const ESTADO_INICIAL = {
    revelarExportarDetalhamentoFinanceiro: false,
    revelarDownloadBoleto: false,
}

export const financeiroReducer = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'REVELAR_EXPORTAR_DETALHAMENTO_FINANCEIRO'){
        return {
            ...state,
            revelarExportarDetalhamentoFinanceiro : action.parametro
        }
    }else if (action.type === 'REVELAR_DOWNLOAD_BOLETO'){
        return {
            ...state,
            revelarDownloadBoleto : action.parametro
        }    
    }else{
        return state
    }
}