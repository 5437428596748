import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'

export default function FiltrosFatConciliacao(props) {
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroTipo, setFiltroTipo] = useState('')
    const [filtroTipoErro, setFiltroTipoErro] = useState('')
    const [filtroValorPrevistoMinimo, setFiltroValorPrevistoMinimo] = useState('')
    const [filtroValorPrevistoMaximo, setFiltroValorPrevistoMaximo] = useState('')
    const [filtroValorCobradoMinimo, setFiltroValorCobradoMinimo] = useState('')
    const [filtroValorCobradoMaximo, setFiltroValorCobradoMaximo] = useState('')
    const [filtroValorDiferencaMinimo, setFiltroValorDiferencaMinimo] = useState('')
    const [filtroValorDiferencaMaximo, setFiltroValorDiferencaMaximo] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    async function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setFiltroTipo(filtros.TIPO)
            setFiltroTipoErro(filtros.TIPO_ERRO)
            setFiltroValorPrevistoMinimo(filtros.VALOR_PREVISTO_MIN)
            setFiltroValorPrevistoMaximo(filtros.VALOR_PREVISTO_MAX)
            setFiltroValorCobradoMinimo(filtros.VALOR_COBRADO_MIN)
            setFiltroValorCobradoMaximo(filtros.VALOR_COBRADO_MAX)
            setFiltroValorDiferencaMinimo(filtros.VALOR_DIFERENCA_MIN)
            setFiltroValorDiferencaMaximo(filtros.VALOR_DIFERENCA_MAX)
        }
    }

    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    function handleFuncaoEstadosRedux(){
        if(filtroTipo !== ''){
            arrayFiltrosSimcard.push("Tipo: "+filtroTipo+"?TIPO")
        }
        if(filtroTipoErro !== ''){
            arrayFiltrosSimcard.push("Tipo Erro: "+filtroTipoErro+"?TIPO_ERRO")
        }
        if(filtroValorCobradoMinimo !== '' && filtroValorCobradoMaximo !== '' && filtroValorCobradoMinimo && filtroValorCobradoMaximo){
            arrayFiltrosSimcard.push("Valor cobrado entre: "+filtroValorCobradoMinimo+" e "+filtroValorCobradoMaximo+"?VALOR_COBRADO_MIN")
        }
        if(filtroValorPrevistoMinimo !== '' && filtroValorPrevistoMaximo !== '' && filtroValorPrevistoMinimo && filtroValorPrevistoMaximo){
            arrayFiltrosSimcard.push("Valor previsto entre: "+filtroValorPrevistoMaximo+" e "+filtroValorPrevistoMaximo+"?VALOR_PREVISTO_MIN")
        }
        if(filtroValorDiferencaMinimo !== '' && filtroValorDiferencaMaximo !== '' && filtroValorDiferencaMinimo && filtroValorDiferencaMaximo){
            arrayFiltrosSimcard.push("Valor diferença entre: "+filtroValorDiferencaMinimo+" e "+filtroValorDiferencaMaximo+"?VALOR_COBRADO_MIN")
        }
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){

            if(filtroTipo =='') verificador.push(1)
            if(filtroTipoErro =='') verificador.push(1)
            if(filtroValorCobradoMinimo =='') verificador.push(1)
            if(filtroValorCobradoMaximo =='') verificador.push(1)
            if(filtroValorPrevistoMinimo =='') verificador.push(1)
            if(filtroValorPrevistoMaximo =='') verificador.push(1)
            if(filtroValorDiferencaMinimo =='') verificador.push(1)
            if(filtroValorDiferencaMaximo =='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 15) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroTipo('')
            setFiltroTipoErro('')
            setFiltroValorCobradoMaximo('')
            setFiltroValorCobradoMinimo('')
            setFiltroValorPrevistoMaximo('')
            setFiltroValorPrevistoMinimo('')
            setFiltroValorDiferencaMaximo('')
            setFiltroValorDiferencaMinimo('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroTipo, filtroTipoErro, 
        filtroValorCobradoMinimo, filtroValorCobradoMaximo,filtroValorPrevistoMinimo, filtroValorPrevistoMaximo ,filtroValorDiferencaMinimo,
        filtroValorDiferencaMaximo, props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-tipo-recurso","imagem-filtro-tipo-recurso")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Tipo </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-tipo-recurso"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-tipo-recurso"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTipo('+')}>+
                            <input defaultChecked={handlerCheck("TIPO","+")} type="radio" id="TIPO_+" name="TIPO" value='Vivo'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTipo('-')}>-
                            <input defaultChecked={handlerCheck("TIPO","-")} type="radio" id="TIPO_-" name="TIPO" value='Claro'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-tipo-erro-recurso","imagem-filtro-tipo-erro-recurso")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Tipo erro </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-tipo-erro-recurso"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-tipo-erro-recurso"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTipoErro('Ausente')}>Ausente
                            <input defaultChecked={handlerCheck("TIPO_ERRO","AUSENTE")} type="radio" id="TIPO_ERRO_AUSENTE" name="TIPO_ERRO" value='Vivo'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTipoErro('Indevida')}>Indevida
                            <input defaultChecked={handlerCheck("TIPO_ERRO","INDEVIDA")} type="radio" id="TIPO_ERRO_INDEVIDA" name="TIPO_ERRO" value='Claro'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTipoErro('Divergente')}>Divergente
                            <input defaultChecked={handlerCheck("TIPO_ERRO","DIVERGENTE")} type="radio" id="TIPO_ERRO_DIVERGENTE" name="TIPO_ERRO" value='Claro'/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-valor-cobrado","imagem-filtro-valor-cobrado")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Valor cobrado </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-valor-cobrado"}  className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-valor-cobrado"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="VALOR_COBRADO_MIN"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        value={filtroValorCobradoMinimo}
                                        onChange={e => setFiltroValorCobradoMinimo(e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="VALOR_COBRADO_MAX"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        value={filtroValorCobradoMaximo}
                                        onChange={e => setFiltroValorCobradoMaximo(e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-valor-previsto","imagem-filtro-valor-previsto")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Valor previsto </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-valor-previsto"}  className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-valor-previsto"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="VALOR_PREVISTO_MIN"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        value={filtroValorPrevistoMinimo}
                                        onChange={e => setFiltroValorPrevistoMinimo(e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="VALOR_PREVISTO_MAX"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        value={filtroValorPrevistoMaximo}
                                        onChange={e => setFiltroValorPrevistoMaximo(e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-valor-diferenca","imagem-filtro-valor-diferenca")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Valor diferença </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-valor-diferenca"}  className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-valor-diferenca"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="VALOR_DIFERENCA_MIN"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        value={filtroValorDiferencaMinimo}
                                        onChange={e => setFiltroValorDiferencaMinimo(e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="VALOR_DIFERENCA_MAX"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        value={filtroValorDiferencaMaximo}
                                        onChange={e => setFiltroValorDiferencaMaximo(e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    {/* ------------------- */}
            
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}