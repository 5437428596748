import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import {Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import decryptJWT from '../../../../utils/decryptJWT'
import 'moment-timezone';
import moment from 'moment';
// ICONES
import { elipseVerde, fecharJanela, exclamacao, setaDropdown } from '../../../../utils/alternanciaIcones'
// MODAIS
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import SelectTemplateSms from '../../../componentesModais/selects/selectTemplateSms'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch } from 'react-redux';
import {revelarVerConversasSms} from "../../../../redux/actions/index";
//ESTILIZAÇÃO
import '../../../../css/painelSms/smsMensagens/conversas/conversas.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'
import utilsSms from '../../../../utils/sms';

const axios = require('axios');

export default function ConversasSms(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    // HOOKS VALIDAÇÂO
    const [lsConversa, setLsConversa] = useState([])
    //HOOKS MODAIS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const [contador, setContador] = useState(0)
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [valorLimite, setValorLimite] = useState(8);
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();
    const cdSmsContaUsuario = decryptJWT('cdSmsContaUsuario')
    
    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);
    
    useEffect(() => {
        setSpinAtivo(true)
        async function handleCarregarConversas(){

            const query = utilsSms.getNewQueryGet('n0_msisdn='+props.msisdn+'&paginaAtual='+contador+'&carregarLimit='+valorLimite)
            const {data} = await api.get('/m1/consultar/smsConversas?'+query);
            let objetoConversa = data.dados.map((item) => {
                if(item.fl_envio === 1){
                    return (
                        <Form.Row>
                            <Col>
                                <div>
                                    <div className="triangulo-mensagem-enviada"/>
                                    <div className="container-mensagem-enviada">
                                        <div className="container-mensagem-titulo">
                                            <span className="fonte-mensagem-titulo fonte-cor-1">Envio com recebimento</span>
                                        </div>
                                        <div className="corpo-mensagem-enviada">
                                            <div className="balao-mensagem-enviada">
                                                <span className="fonte-mensagem-enviada">{item.ds_mensagem}</span>
                                            </div>
                                        </div>
                                        <div className="hora-mensagem-enviada">
                                            <span className="fonte-mensagem-hora">{moment(item.dt_created_at).format('DD/MM/YYYY - hh:mm')}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                    );
                }else{
                    return (
                        <Form.Row>
                            <Col>
                                <div className="container-mensagem-recebida-externo">
                                    <div className="container-mensagem-recebida">
                                        <div className="container-mensagem-titulo">
                                            <span className="fonte-mensagem-titulo fonte-cor-1">Resposta</span>
                                        </div>
                                        <div className="corpo-mensagem-recebida">
                                            <div className="balao-mensagem-recebida fundo-cor-5">
                                                <span className="fonte-mensagem-recebida fonte-cor-1">{item.ds_mensagem}</span>
                                            </div>
                                        </div>
                                        <div className="hora-mensagem-recebida">
                                            <span className="fonte-mensagem-hora">{moment(item.dt_created_at).format('DD/MM/YYYY - hh:mm')}</span>
                                        </div>
                                    </div>
                                    <div className="triangulo-mensagem-recebida triangulo-mensagem-recebida-cor"/>
                                </div>
                            </Col>
                        </Form.Row>
                    );
                }
            })
            setLsConversa(objetoConversa)
            setSpinAtivo(false)
        }
        handleCarregarConversas()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [paginaAtual])

    function handleCancelar(){
        ColetaClickstream(1206) /*dropAcoesRevelarVerConversasSmsCancelar*/
        despacho(revelarVerConversasSms(false))
    }
    
    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    
    function handleCarregarMais(){
        setPaginaAtual(paginaAtual+1)
        setValorLimite(valorLimite+10)
    }

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="modal-conversas-sms fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Conversas</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-lista-conversas">
                            {lsConversa}
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="botao-ver-mais-conversas-sms">
                            <Button className="botao-ver-mais-mensagens" variant="success" size="sm" onClick={() => handleCarregarMais()}>
                                <div className="fonte-cor-2">Ver mais</div>
                            </Button>
                        </div>
                    </Col>
                </Form.Row>
            </div>
        </Form>
    )
}