//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import 'moment-timezone';/*eslint-disable*/
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import { useDispatch, useSelector, connect, } from 'react-redux';
import {api} from '../../../conexoes/api';
import BootstrapTable from 'react-bootstrap-table-next';
import {formatarData} from '../../../utils/formatadorDataTabela'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import decryptJWT from '../../../utils/decryptJWT'
import {formatarMilhar} from '../../../utils/formatarMilhar'

//COMPONENTES
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import NovoPedidoSMS from './novoPedido'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

//ESTILIZAÇÃO
import '../../../css/painelPedidos/pedidosSms/tabela.css'

//REDUX
import { mudarManejador, limpouBusca, realizouBusca, limpadorFiltros, desabilitarAcoes, desabilitarAcoesCliente, mudarFiltros, filtrosArrayBarra, revelarBarraFiltragem, realizouFiltro } from "../../../redux/actions/index"
import utilsLocalStorage from '../../../utils/localStorage';

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let itensVar = []

function TabelaPedidoSms(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50); // eslint-disable-next-line
    const [envioSelecionado, setEnvioSelecionado] = useState([]) // eslint-disable-next-line
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);

    //REDUX VARIÁVEIS
    const despacho = useDispatch();
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let revelarNovoPedidoSMS = useSelector(state => state.manejadorState.revelarNovoPedidoSMS);

    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let revelarCdSms = useSelector(state => state.manejadorState.exportarCdSms); 
    let novoCdSms
    let temFiltros = false;
    
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)


    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(483) // 'pedidosSmsTabela'
            setColetaUsabilidade(false)
        }
    },[])
    
    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    //Esconde a barra de filtragem
    useEffect(() => {
        if(props.revelarBarraFiltro === true){
            despacho(revelarBarraFiltragem(false))
        }
        if(props.filtros.vazio != 'vazio'){
            despacho(revelarBarraFiltragem(false))
            despacho(mudarFiltros({vazio: 'vazio'}))
            despacho(limpadorFiltros(true))
        }
    }, [])

    function getNovoCdSms() {
        if(decryptJWT('cdSmsContaUsuario') == undefined || decryptJWT('cdSmsContaUsuario') == 'undefined' ) {
            if(revelarCdSms) {
                utilsLocalStorage.setCdSmsContaUsuario(revelarCdSms);
                return revelarCdSms;
            } else {
                return utilsLocalStorage.getCdSmsContaUsuario();
            }
    
        }else{
            return decryptJWT('cdSmsContaUsuario')
        }
    }


    useEffect(() => {
        setSpinAtivo(true)
        //despacho redux
        despacho(mudarManejador("novoPedidoSMS"));
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
        novoCdSms = getNovoCdSms();

        async function carregarTabela(){
            // //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const { data } = await api.get('/m1/consultar/sms_pedido?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+'&sms9.cd_sms_conta_usuario='+novoCdSms);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    'sms9.cd_sms_conta_usuario':novoCdSms
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/sms_pedido", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/sms_pedido?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&sms9.cd_sms_conta_usuario='+novoCdSms);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

  
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }

    async function contarItens(){
        let arrayItens = []
        let apiData = []
        novoCdSms = getNovoCdSms();

        if (controladorPagina <= paginaAtual && filtros.vazio) {
            apiData = await api.get('/m1/consultar/sms_pedido?contar=true&buscar='+conteudoBusca+'&sms9.cd_sms_conta_usuario='+novoCdSms);
        }else{
            const dados = {
                paguinaAtual: contador,
                carregarLimit: valorLimite,
                buscar:conteudoBusca,
                contar:true,
                filtros: filtros,
                'sms9.cd_sms_conta_usuario':novoCdSms
            }
            apiData = await api.post("/m1/filtrar/sms_pedido", dados);
        }
        itensVar = []
        if(apiData.data.dados){
            totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
            totalItens = apiData.data.dados[0].CONTADOR;
            for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                arrayItens.push(i)
            }
            itensVar = arrayItens
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }
     
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            setRenderizar(!renderizar)
            restaurarConfigsIniciaisPagina()
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }


    const colunas = [{
            dataField: 'NUMERO_PEDIDO',
            text: 'Pedido',
            sort: true,
            headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
            headerStyle: (colum, colIndex) => {
                return { width: '130px'}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: "DATA_PEDIDO",
            headerClasses: 'nao-selecionavel',
            text: 'Data',
            sort: true,
            formatter: (cell, row) => {
                if(cell){
                    cell = cell.replace('T', ' ').split('.')
                    if(cell.length > 0){
                        cell = cell[0]
                    }
                }
                return formatarData(cell);
            }
        },
        {
            dataField: 'PEDIDO_STATUS',
            headerClasses: 'nao-selecionavel',
            text: 'Status',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'USUARIO_EMAIL',
            headerClasses: 'nao-selecionavel',
            text: 'Usuário',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'QTD_SMS',
            headerClasses: 'nao-selecionavel',
            text: 'Quantidade de SMS',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'VALOR',
            headerClasses: 'nao-selecionavel',
            text: 'Valor',
            sort: true,
            
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-pedidosSimcard"> {(!valor || isNaN(valor)) ? <><p className="valor-rs-pedidosSimcard2">R$</p> <p className="fonte-cor-1 valor-pedidosSimcard3"> 0.00 </p></> : <><p className="valor-rs-pedidosSimcard2">R$</p> <p className="fonte-cor-1 valor-pedidosSimcard3"> {formatarMilhar(valor)}</p></>} </p>;
            }
        },
    ]
    
    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setEnvioSelecionado(row)
        }
    }
    
    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }
    
    return(
        <div className='container-tela'>
        <BarraDadosCliente/>
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }

        { revelarNovoPedidoSMS === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <NovoPedidoSMS
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        >
                        </NovoPedidoSMS>
                    </div>
                </div>
            }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"As Mensagens foram atualizadas."}
                            mensagemBotao={"OK"}
                        />
                    </div>
                </div>
            }

            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                keyField='NUMERO_PEDIDO' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                bootstrap4={true}
                                bordered={false}
                                classes="tabela"
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                             <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>

            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaPedidoSms));