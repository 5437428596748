import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Image, Button } from 'react-bootstrap';
import moment from 'moment';

import {formatarData} from '../../../utils/formatadorDataTabela'
//IMPORTAÇÕES ICONES
import { elipseVerde, elipseVermelho, elipseAzul, lixeiraVermelho } from '../../../utils/alternanciaIcones'

const colunasPadrao = [{
        dataField: 'CD_SIMCARD',
        text: 'Código',
        headerClasses: 'nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'CALLERID',
        headerClasses: 'nao-selecionavel',
        text: 'Linha',
        sort: true, 
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ICCID',
        headerClasses: 'nao-selecionavel',
        text: 'ICCID',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ON_OFF',
        headerClasses: 'nao-selecionavel',
        text: <button className='btn-header-nome fonte-cor-1'>
            Status
            {/* <Image id="STATUSSTATUS" className='d-none' src={setaDropdown()} /> */}
        </button>,
        // hidden: !verificarColuna('ON_OFF'),
        formatter: (cell, row) => {
            if (cell && cell.toLowerCase() === 'online') {
                return (
                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Inicio - {moment(row.INICIO).format('DD/MM/YYYY HH:mm:ss')}</Tooltip>}>
                        <p className={row.TRAFEGO !== "Ativo" ? "fonte-cor-7 label-tabela-1 label-tabela-tooltip" : "fonte-cor-1 label-tabela-1 label-tabela-tooltip"} key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseVerde()} /> Online</p>
                        {/* <p className={verificadorBloqueioFinanceiro || row.TRAFEGO !== "Ativo" ? "fonte-cor-7 label-tabela-1 label-tabela-tooltip" : "fonte-cor-1 label-tabela-1 label-tabela-tooltip"} key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseVerde()} /> Online</p> */}
                    </OverlayTrigger>
                )
            }
            else if (cell && cell.toLowerCase() === 'offline') {
                return (
                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Fim - {moment(row.FIM).format('DD/MM/YYYY HH:mm:ss')}</Tooltip>}>
                        <p className={row.TRAFEGO !== "Ativo" ? "fonte-cor-7 label-tabela-1 label-tabela-tooltip" : "fonte-cor-1 label-tabela-1 label-tabela-tooltip"} key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseVermelho()} /> Offline</p>
                    </OverlayTrigger>
                )
            }
            else if (cell && cell.toLowerCase() === 'estoque') {
                return (
                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Estoque</Tooltip>}>
                        <p className={row.TRAFEGO !== "Ativo" ? "fonte-cor-7 label-tabela-1 label-tabela-tooltip" : "fonte-cor-1 label-tabela-1 label-tabela-tooltip"} key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseAzul()} /> Estoque</p>
                    </OverlayTrigger>
                )
            }
        },
    },
    {
        dataField: 'ULTIMO_EVENTO',
        headerClasses: 'nao-selecionavel',
        text: 'Última conexão',
        sort: true,
        style:{
            paddingLeft: '0px'
        },
        formatter: (cell, row) => {
            if(cell){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                cell = cell[0]
                }
            }
            return formatarData(cell);
        }
    },
]

function getGsmGprsColumnInfo(info, aparecerColuna) { // info = 'gsm' ou 'gprs'
    if(info !== 'gsm' && info !== 'gprs') return;
    const auxInfo = info.toLocaleUpperCase();

    const objColuna = {
        dataField: info, //'ON_OFF',
        headerClasses: 'nao-selecionavel',
        text: <button className='btn-header-nome fonte-cor-1'>
            Status {auxInfo}
            <Image id="STATUSSTATUS" className='d-none' />
        </button>,
        formatter: (cell, row) => {
            if (cell && cell === `${auxInfo} Up`) {
                return (
                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Ativo</Tooltip>}>
                        <p className={"fonte-cor-1 label-tabela-1 label-tabela-tooltip"} key={row.CD_SIMCARD}>
                            <Image className="img-eclipse-status" src={elipseVerde()} />{auxInfo} Up</p>
                    </OverlayTrigger>
                )
            }
            else if (cell && cell === `${auxInfo} Down`) {
                return (
                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Inativo</Tooltip>}>
                        <p className={"fonte-cor-1 label-tabela-1 label-tabela-tooltip"} key={row.CD_SIMCARD}>
                            <Image className="img-eclipse-status" src={elipseVermelho()} />{auxInfo} Down</p>
                    </OverlayTrigger>
                )
            }
            else if(row[info]) {
                return (
                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={
                    <Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Erro</Tooltip>}>
                        <p className={"fonte-cor-1 label-tabela-1 label-tabela-tooltip"} key={row.CD_SIMCARD}>
                            <Image className="img-eclipse-status" src={elipseAzul()} />{row[info]}</p>
                    </OverlayTrigger>
                )
            } else {
                return (
                    <p className={"fonte-cor-7 label-tabela-1 label-tabela-tooltip"} key={row.CD_SIMCARD}>
                        carregando ...
                    </p>
                )
            }
        }
    }
    objColuna.hidden = !aparecerColuna;
    return objColuna;
}

function getResetColumnInfo() {
    return     {
        dataField: 'ENVIOU_SOLICITACAO_RESET',
        headerClasses: 'nao-selecionavel',
        text: 'Reset',
        sort: true, 
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell || '-'}</p>;
        }
    }
}

function getBotaoExcluirColumnInfo(handleMensagemAtencao) {
    return {
        dataField: 'acoes',
        text: '',
        sort: false,
        // hidden,
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }
}

export function getColunasTesteConexao(pagina2=false, handleMensagemAtencao) {
    const colunas = [...colunasPadrao];

    if(!pagina2) {
        colunas.push(getBotaoExcluirColumnInfo(handleMensagemAtencao));
    } else {
        colunas.push(getGsmGprsColumnInfo('gsm', true));
        colunas.push(getGsmGprsColumnInfo('gprs', true));
        colunas.push(getResetColumnInfo());
    }
    return colunas;
}