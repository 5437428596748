//IMPORTAÇÕES
import React, {useState} from 'react';/*eslint-disable*/

//MAPA
import '../../../css/painelDispositivos/dspMapa/mapa.css'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Map, Popup, TileLayer, Marker } from 'react-leaflet';
import HeatmapLayer from './dosOpenStreet/HeatmapLayer';
// import { addressPoints } from './realworld.10000.js'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
//ICONES
import green_icon from '../../../icones/filled-marker-green.svg'
import red_icon from '../../../icones/filled-marker-red.svg'

export default function MapaOpenStreet(props) {

    const [habilitaMapacalor, setHabilitaMapacalor] = useState(true);
    const [gradiente, setGradiente] = useState({
       ' 0.1': '#89BDE0',' 0.2': '#96E3E6',' 0.4': '#82CEB6',
       ' 0.6': '#FAF3A5',' 0.8': '#F5D98B', '1.0': '#DE9A96'
    })
    const [raio, setRaio] = useState(30)
    const [borrao, setBorrao] = useState(15)

    const createClusterCustomIcon = function (cluster) {
        return L.divIcon({
        html: `<span>${cluster.getChildCount()}</span>`,
            className: 'marker-cluster-custom',
            iconSize: L.point(40, 40, true),
        });
    };

    const redMarker = L.icon({
        iconUrl:red_icon,
        iconSize: [25, 25],
        iconAnchor: [20, 40],
    });

    const greenMarker = L.icon({
        iconUrl:green_icon,
        iconSize: [25, 25],
        iconAnchor: [20, 40],
    });

    // Usado para deixar a variáve de pontos do heatmap na maneira como a biblioteca exige
    if (props.array != undefined){
        var addressPoints = props.array.map(function(obj) {
            return Object.keys(obj).sort().map(function(key) {
                return obj[key];
            });
        });
    }
    var heatMapOpenStreet = <HeatmapLayer
                                fitBoundsOnLoad
                                fitBoundsOnUpdate
                                blur = {borrao} // A opacidade não está alterando
                                radius = {raio}
                                points={ addressPoints }
                                gradient = {gradiente}
                                longitudeExtractor={m => m[1]}
                                latitudeExtractor={m => m[0]}
                                intensityExtractor={m => parseFloat(m[2])}
                            />

    return(
        <Map center={[props.latCenter, props.lngCenter]} zoom={11} className="container-mapa-openstreet fundo-cor-6">
            <TileLayer
                url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />

            {(props.mapaTipo === 0) &&
                <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
                    {props.array.map((marca, index) => (
                        <Marker id="map" position={{ lat: marca.latitude, lng: marca.longitude }} key={index} icon= {(marca.status == 'online') ? greenMarker: redMarker}>
                            <Popup closeButton={false}>
                                <div className="container-tooltip-open-street">
                                    <div className="grupo-tooltip-open-street-1">
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Endereço: </p>
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.endereco}</p>
                                    </div>
                                    <div className="grupo-tooltip-open-street-1">
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Operadora: </p>
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.operadora}</p>
                                    </div>
                                    <div className="grupo-tooltip-open-street-1">
                                        <div className="grupo-tooltip-open-street-2">
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Área(Lac)</p>
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.lac}</p>
                                        </div>
                                        <div className="grupo-tooltip-open-street-2">
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">ID(antena): </p>
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.cid}</p>
                                        </div>
                                    </div>
                                    <div className="grupo-tooltip-open-street-1">
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Data/hora: </p>
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.data}</p>  
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                </MarkerClusterGroup>
            }

            { (props.mapaTipo === 1) &&
                <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
                    {props.array.map((marca, index) => (

                        <Marker id="map" position={{ lat: marca.latitude, lng: marca.longitude }}key={index} icon= {(marca.status == 'online') ? greenMarker: redMarker}>
                            <Popup closeButton={false}>
                                <div className="container-tooltip-open-street">
                                    <div className="grupo-tooltip-open-street-1">
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Endereço: </p>
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.endereco}</p>
                                    </div>
                                    <div className="grupo-tooltip-open-street-1">
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Operadora: </p>
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.operadora}</p>
                                    </div>
                                    <div className="grupo-tooltip-open-street-1">
                                        <div className="grupo-tooltip-open-street-2">
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Área(Lac)</p>
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.lac}</p>
                                        </div>
                                        <div className="grupo-tooltip-open-street-2">
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">ID(antena): </p>
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.cid}</p>
                                        </div>
                                    </div>
                                    <div className="grupo-tooltip-open-street-1">
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Data/hora: </p>
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.data}</p>  
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                </MarkerClusterGroup>
            }

            { (props.mapaTipo === 2)  && habilitaMapacalor && gradiente && // Esta lógica foi necessária visto que o gadiente
                heatMapOpenStreet                                            // Não estava alterando de maneira apropriada
            }

            { (props.mapaTipo === 2)  && habilitaMapacalor && !gradiente &&
                heatMapOpenStreet
            }

            {(props.mapaTipo === 3) &&
                <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
                    {props.array.map((marca, index) => (
                        <Marker id="map" position={{ lat: marca.latitude, lng: marca.longitude }} key={index} icon= {(marca.status == 'online') ? greenMarker: redMarker}>
                            <Popup closeButton={false}>
                                <div className="container-tooltip-open-street">
                                    <div className="grupo-tooltip-open-street-1">
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Endereço: </p>
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.ds_linha}</p>    
                                    </div>
                                    <div className="grupo-tooltip-open-street-1">
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Operadora: </p>
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.ds_linha}</p>    
                                    </div>
                                    <div className="grupo-tooltip-open-street-1">
                                        <div className="grupo-tooltip-open-street-2">
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Área(Lac)</p>
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.lac}</p>
                                        </div>
                                        <div className="grupo-tooltip-open-street-2">
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">ID(antena): </p>
                                            <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.cid}</p>
                                        </div>
                                    </div>
                                    <div className="grupo-tooltip-open-street-1">
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-titulo">Data/hora: </p>
                                        <p className=" fonte-cor-1 open-streets-pop-up-text-corpo">{marca.data}</p>  
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                </MarkerClusterGroup>
            }
        </Map>
    )
}