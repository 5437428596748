import React, { useState, useEffect, useRef } from 'react'; /*eslint-disable*/
import { Button } from 'react-bootstrap';
import '../../../css/componentesModais/mensagens/mensagemAtencaoSelectSms.css';

export default function MensagemAtencaoSelect(props) {
    const [lsDados, setLsDados] = useState([]);
    const [mensagemTitulo, setMensagemTitulo] = useState('');
    const [mensagemParagrafo1, setMensagemParagrafo1] = useState('');
    const [mensagemParagrafo2, setMensagemParagrafo2] = useState('');
    const [mensagemParagrafo3, setMensagemParagrafo3] = useState('');
    const [mensagemBotao, setMensagemBotao] = useState('');
    const [mensagemItem, setMensagemItem] = useState();
    const modal = useRef();

    const handleClickFora = (e) => {
        if (modal.current && !modal.current.contains(e.target)) {
            handleCancelar(false);
        }
    };

    function handleCancelar(confirmacao) {
        if (confirmacao === true) {
            props.handleMensagemAtencao(true);
        } else {
            props.handleMensagemAtencao(false);
        }
    }

    useEffect(() => {
        setMensagemTitulo(props.mensagemTitulo);
        setMensagemParagrafo1(props.mensagemParagrafo1);
        setMensagemParagrafo2(props.mensagemParagrafo2);
        setMensagemParagrafo3(props.mensagemParagrafo3);
        setMensagemBotao(props.mensagemBotao);
        setMensagemItem(props.mensagemItem);

        function renderClientes() {
            const dados = Array.isArray(props.dados) ? props.dados : [];
            const groupedDados = [];

            for (let i = 0; i < dados.length; i += 3) {
                groupedDados.push(dados.slice(i, i + 3));
            }

            setLsDados(groupedDados);
        }
        renderClientes();

        document.addEventListener('mousedown', handleClickFora);

        return () => {
            document.removeEventListener('mousedown', handleClickFora);
        };
    }, [props.dados, props.mensagemBotao, props.mensagemParagrafo1, props.mensagemParagrafo2, props.mensagemTitulo]);

    return (
        <div className="mensagem-overlay">
            <div className="mensagem-container">
                <div ref={modal} className="mensagem-corpo fundo-cor-6">
                    <div className="mensagem-container-icone">
                    </div>
                    <div className="mensagem-container-titulo">
                        <div className="fonte-cor-1 label-titulo-mensagem sms-titulo">{mensagemTitulo}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo1}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo2}</div>
                    </div>
                    <div>
                        <div className="fonte-cor-1 label-corpo-mensagem">{mensagemParagrafo3}</div>
                    </div>
                    <div className="campo-select-corpo">
                        <div>
                            <div className="fonte-cor-1 label-corpo-mensagem sms-item">{mensagemItem}</div>
                        </div>
                        {Array.isArray(lsDados) && lsDados.map((linha, index) => (
                            <div key={index} className="campo-select-lista">
                                {linha.map((item, itemIndex) => (
                                    <span key={itemIndex} className="fonte-cor-1 fonte-campo campo-select-opcao-label label-sms">
                                        {item}
                                    </span>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="mensagem-container-botao">
                        <Button className="mensagem-botao-confirmar" variant="success" size="sm" onClick={() => handleCancelar(true)}>
                            <div className="fonte-cor-4">{mensagemBotao}</div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
