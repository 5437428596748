/*eslint-disable*/
import React, {useState, useRef, useEffect} from 'react';
import {GoogleMap, withGoogleMap, withScriptjs, Marker} from 'react-google-maps'
import { GOOGLE_MAPS_JAVASCRIPT_API } from '../../utils/googleMapsApiKey';

function Mapa(props){
    return(
        <GoogleMap
            defaultZoom={10} 
            defaultCenter={{lat: props.props.array.lat, lng: props.props.array.lng}}
        >
            <div>
                <Marker
                    icon={{
                        url:'../../icones/filled-marker-red.svg',
                        scaledSize: new window.google.maps.Size(25, 25)
                    }}
                    position={{
                        lat: props.props.array.lat, 
                        lng: props.props.array.lng
                    }}
                />
            </div>
        </GoogleMap>
    )
}

const WrappedMap = withScriptjs(withGoogleMap(Mapa));

export default function MapaGoogleSemInfo(props){
    return (
        <div style={{width: '100%', height: '100%', zoom: '125%'}}>
            <WrappedMap
                props={props}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization&key=${GOOGLE_MAPS_JAVASCRIPT_API}&amp;`}
                loadingElement={<div style={{ height: '100%' }}/>}
                containerElement={<div style={{ height: '100%' }}/>}
                mapElement={<div style={{ height: '100%' }}/>}
            />
        </div>
    )
}