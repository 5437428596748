export function clickDesselecionarCheckboxVerde() {
    const elementoCheckBoxVerde = document.getElementsByClassName('selection-cell-header')
    elementoCheckBoxVerde[0].click();
}

function delay(delayInms) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(2);
      }, delayInms);
    });
}

export async function desselecionarTodosCheckboxes(origem) { // click
    await delay(origem === 'limparSelecao' ? 0: 500); // necessário aguardar carregar elementos

    try {
        const checkboxesInput = document.getElementsByClassName('input-checkbox-simcard')
        const checkboxVerdeInput = document.getElementsByClassName('label-checkbox-header-simcard')

        const checkboxesInputLen = checkboxesInput.length;

        if(!checkboxesInput[0] && !checkboxesInput[0]) {
            return;
        }

        const checkboxesAtivos = [];
        
        for(let i=0; i<checkboxesInputLen; i++) {
            const checkbox = checkboxesInput[i];
            if(checkbox.checked) checkboxesAtivos.push(checkbox);
        }

        if(checkboxesAtivos.length > 0) {
            checkboxVerdeInput[0].click()
        }
        
        const foundAtivoRestante = [];
        
        for(let i=0; i<checkboxesInputLen; i++) {
            const checkbox = checkboxesInput[i];
            if(!checkbox.checked) foundAtivoRestante.push(checkbox);
        }

    } catch {
        return;
    }
}