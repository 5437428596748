//IMPORTAÇÕES
import React, { useEffect, useRef, useState } from "react"; /*eslint-disable*/
import { useForm } from "react-hook-form"; /*eslint-disable*/
import { api } from "../../../conexoes/api";
import {
  Button,
  Form,
  Tooltip,
  OverlayTrigger,
  Col,
  Image,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
//ICONES
import {
  elipseVerde,
  fecharJanela,
  exclamacao,
} from "../../../utils/alternanciaIcones";
import lupa from "../../../icones/iconesVersaoEscura/busca_preta.svg";
//REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  revelarConsultarSimcard,
  revelarDoppelgangerCliente,
  mudarDoppleGangerCliente,
  visualizarDadosRefresh,
} from "../../../redux/actions/index";
import decryptJWT from "../../../utils/decryptJWT";
import { formatarMilhar } from "../../../utils/formatarMilhar";
import "../../../css/painelVirtueyes/consultarSimcard/consultarSimcard.css";
//COMPONENTES
import MensagemErro from "../../componentesModais/mensagens/mensagemErro";
import MensagemSucesso from "../../componentesModais/mensagens/mensagemSucesso";
// SPINNER
import SpinerVeye from "../../../components/spinnerVeye/spinnerVeye.js";
// COLETA CLICKSTREAM
import ColetaClickstream from "../../../components/clickstreamAnalise/clickstreamColeta.js";

export default function ConsultarSimcard(props) {
  // SPINNER
  const [spinAtivo, setSpinAtivo] = useState(true);
  //HOOKS VALIDAÇÂO
  const { register, errors } = useForm();
  //HOOKS PRINCIPAIS
  const [itens, setItens] = useState("");
  const [dsConteudoBusca, setDsConteudoBusca] = useState("");
  const [renderizar, setRenderizar] = useState(true); // eslint-disable-next-line
  const [cdCliente, setCdCliente] = useState();
  const [dsCliente, setDsCliente] = useState("NULL");

  //HOOKS MENSAGENS
  const [revelarMensagemErro, setRevelarMensagemErro] = useState(false);
  const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false);

  //OUTROS HOOKS
  const despacho = useDispatch();
  const modal = useRef();
  const [clickFora, setClickFora] = useState(false); // eslint-disable-next-line
  const [mensagemErro, setMensagemErro] = useState(
    "Ocorreu um erro ao executar esta ação"
  ); // eslint-disable-next-line

  const iconeAlerta = (
    <img className="icone-alerta" src={exclamacao()} alt="exclamacao" />
  );

  let revelarDoppelgangerClienteVar = useSelector(
    (state) => state.virtueyesState.revelarDoppelgangerCliente
  );
  let visualizarDadosRefreshVar = useSelector(
    (state) => state.virtueyesState.visualizarDadosRefresh
  );

  const colunas = [
    {
      dataField: "CD_SIMCARD",
      text: "ID do SIM card",
      headerClasses: "nao-selecionavel",
      hidden: true,
      formatter: (cell, row) => {
        return (
          <p className="fonte-cor-1 label-tabela-1">
            {cell !== "" ? cell : "-"}
          </p>
        );
      },
    },
    {
      dataField: "OPERADORA",
      text: "Operadora",
      headerClasses: "nao-selecionavel",
      sort: true,
      formatter: (cell, row) => {
        return (
          <p className="fonte-cor-1 label-tabela-1">
            {cell !== "" ? cell : "-"}
          </p>
        );
      },
    },
    {
      dataField: "CALLERID",
      text: "Linha",
      headerClasses: "nao-selecionavel",
      sort: true,
      formatter: (cell, row) => {
        return (
          <p className="fonte-cor-1 label-tabela-1">
            {cell !== "" ? cell : "-"}
          </p>
        );
      },
    },
    {
      dataField: "ICCID",
      text: "ICCID",
      headerClasses: "nao-selecionavel",
      sort: true,
      formatter: (cell, row) => {
        return (
          <p className="fonte-cor-1 label-tabela-1">
            {cell !== "" ? cell : "-"}
          </p>
        );
      },
    },
    {
      dataField: "PLANO_TIPO",
      text: "Plano",
      headerClasses: "nao-selecionavel",
      sort: true,
      formatter: (cell, row) => {
        return (
          <p className="fonte-cor-1 label-tabela-1">
            {cell !== "" ? cell : "-"}
          </p>
        );
      },
    },
    {
      dataField: "VALOR_PLANO",
      text: "Valor",
      headerClasses: "nao-selecionavel",
      sort: true,
      formatter: (cell, row) => {
        const valor = parseFloat(cell);
        return (
          <p className="fonte-cor-1 label-tabela-1 container-valor-consultarSimcard">
            {" "}
            {!valor || isNaN(valor) ? (
              <>
                <p className="valor-rs-consultarSimcard">R$</p>{" "}
                <p className="fonte-cor-1 valor-consultarSimcard"> 0.00 </p>
              </>
            ) : (
              <>
                <p className="valor-rs-consultarSimcard">R$</p>{" "}
                <p className="fonte-cor-1 valor-consultarSimcard">
                  {" "}
                  {formatarMilhar(valor)}
                </p>
              </>
            )}{" "}
          </p>
        );
      },
    },
    {
      dataField: "SITUACAO",
      text: "Situação",
      headerClasses: "nao-selecionavel",
      sort: true,
      formatter: (cell, row) => {
        return (
          <p className="fonte-cor-1 label-tabela-1">
            {cell !== "" ? cell : "-"}
          </p>
        );
      },
    },
    {
      dataField: "CLIENTE_CODIGO",
      text: "Cód. cliente",
      headerClasses: "nao-selecionavel",
      sort: true,
      formatter: (cell, row) => {
        setCdCliente(cell);
        setDsCliente(row.CLIENTE_NOME);
        return (
          <p
            onClick={() =>
              handleDoppelgangerCliente(row.CD_CLIENTE, cell, row.N0_NIVEL)
            }
            className="fonte-cor-3 label-tabela-2"
          >
            {cell !== "" ? cell : "-"}
          </p>
        );
      },
    },
    {
      dataField: "CLIENTE_NOME",
      text: "Razão social",
      headerClasses: "nao-selecionavel",
      sort: true,
      formatter: (cell, row) => {
        return (
          <p className="fonte-cor-1 label-tabela-1">
            {cell !== "" ? cell : "-"}
          </p>
        );
      },
    },
  ];

  async function handleDoppelgangerCliente(cdCliente, noCdCliente, n0_nivel) {
    setSpinAtivo(true);
    let fatContas = [];
    const contasUsuario = await api.get(
      "m1/consultar/fat_conta/contas_usuario?cd_pav_usuario=" +
        decryptJWT("codigo")
    );
    await Promise.all(
      contasUsuario.data.dados.map((item) => {
        fatContas.push(item.cd_fat_conta);
      })
    );
    if (contasUsuario.data.dados.length == 0) {
      fatContas = [0];
    }
    const request =
      "/m1/servicos/alterar_token?login=" +
      decryptJWT("login") +
      "&email=" +
      decryptJWT("email") +
      "&codigo=" +
      decryptJWT("codigo") +
      "&codigoCliente=" +
      cdCliente +
      "&codigoClienteErpAntigo=" +
      noCdCliente +
      "&codigoClienteTexto=" +
      noCdCliente +
      "&nomeDeUsuario=" +
      decryptJWT("nomeDeUsuario") +
      "&perfil=" +
      decryptJWT("perfil") +
      "&cdSmsContaUsuario=" +
      decryptJWT("cdSmsContaUsuario") +
      "&id=" +
      decryptJWT("id") +
      "&fatContas=" +
      fatContas +
      "&clienteNivel=" +
      n0_nivel +
      "&tipoPerfil=" +
      decryptJWT("tipoPerfil") +
      "&clientePrimario=" +
      decryptJWT("clientePrimario") +
      "&clienteOriginal=" +
      decryptJWT("clienteOriginal");
    await api
      .get(request)
      .then(function (response) {
        localStorage.setItem("@veye-Token", response.data.dados.token);
      })
      .catch(function (error) {});

    localStorage.setItem("codigoClienteErpAntigo", noCdCliente);
    //Atualiza as permissoes do usuario
    await api
      .get(
        "/m1/servicos/token/atualizarLogin?cd_pav_usuario=" +
          decryptJWT("codigo")
      )
      .catch(function (error) {});

    localStorage.setItem("codigoClienteErpAntigo", noCdCliente);
    
    setRevelarMensagemSucesso(true);
    setSpinAtivo(false);
    despacho(mudarDoppleGangerCliente(false));
  }

  const handleClickFora = (e) => {
    if (!modal.current.contains(e.target)) {
      handleCancelar();
    }
  };

  async function handleCancelar() {
    ColetaClickstream(1418); // virtuTabelaClientesConsultarSimcardCancelar
    setRevelarMensagemErro(false);
    despacho(revelarConsultarSimcard(false));
  }

  async function handlePesquisar() {
    setSpinAtivo(true);
    const { data } = await api.get(
      "m1/consultar/simcard_geral?busca=" + dsConteudoBusca
    );
    setItens(data.dados);
    setSpinAtivo(false);
  }

  useEffect(() => {
    setSpinAtivo(true);
    document.addEventListener("mousedown", handleClickFora);
    setSpinAtivo(false);
    return () => document.removeEventListener("mousedown", handleClickFora);
  }, [renderizar]);

  function handleMensagemErro(confirmacao) {
    if (revelarMensagemErro === false) {
      setRevelarMensagemErro(true);
    } else {
      setRevelarMensagemErro(false);
      if (confirmacao === true) {
        //ALGUMA FUNÇÃO
      }
    }
  }

  function handleMensagemSucesso(confirmacao) {
    if (revelarMensagemSucesso === false) {
      setRevelarMensagemSucesso(true);
    } else {
      setRevelarMensagemSucesso(false);
      if (confirmacao === true) {
        window.location.reload();
        handleCancelar();
      }
    }
  }

  function botaoPressionado(event) {
    if (event.key === "Enter") {
      handlePesquisar();
      setDsConteudoBusca(event.target.value);
      event.preventDefault();
    }
  }

  function validarNumero(id, valor) {
    const filtro = /^[0-9]+$/;
    if (valor === "" || filtro.test(valor)) {
      if (id === "campo-texto-buscar-linha") {
        setDsConteudoBusca(valor);
      }
    }
  }

  return (
    <Form ref={modal}>
      <div className="modal-acao-consultar-simcard fundo-cor-6">
        {spinAtivo && (
          <div className="componente-spinner-overlay">
            <div className="componente-spinner-container">
              <SpinerVeye />
            </div>
          </div>
        )}
        <Form.Row>
          <Col>
            <img
              className="icone-label-janela nao-selecionavel"
              src={elipseVerde()}
              alt="elipse"
            />
            <Form.Label>
              <div className="fonte-cor-1 label-janela">Consultar simcard</div>
            </Form.Label>
            <Button
              className="botao-fechar-janela"
              variant="danger"
              onClick={() => handleCancelar()}
            >
              <img
                className="botao-fechar-janela-icone"
                src={fecharJanela()}
                alt="fechar-janela"
              />
            </Button>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <div className="grupo-consultar-simcard-1">
              <div className="coluna-consultar-simcard-1">
                <Form.Group>
                  <Form.Label>
                    <div className="fonte-cor-1 label-campo">Linha / ICCID</div>
                  </Form.Label>
                  <OverlayTrigger
                    delay={{ show: 500, hide: 250 }}
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        Informe uma linha/ICCID para pesquisar os simcards que
                        possuem estes dados
                      </Tooltip>
                    }
                  >
                    <Form.Control
                      id="campo-texto-buscar-linha"
                      name="dsConteudoBusca"
                      onKeyDown={(e) => botaoPressionado(e)}
                      value={dsConteudoBusca}
                      className="campo-texto-cor-3 campo-texto"
                      type="text"
                      placeholder="Digite o nº da linha com DDD ou ICCID"
                      onChange={(e) =>
                        validarNumero(
                          "campo-texto-buscar-linha",
                          e.target.value
                        )
                      }
                      ref={register({
                        validate: { minimo: (value) => value.length > 1 },
                      })}
                      maxlength="100"
                    />
                  </OverlayTrigger>
                  {errors.dsConteudoBusca && (
                    <p className="fonte-cor-3 label-alerta">
                      {iconeAlerta} O campo é obrigatorio.
                    </p>
                  )}
                </Form.Group>
              </div>
              <div className="coluna-consultar-simcard-2">
                <OverlayTrigger
                  delay={{ show: 500, hide: 250 }}
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-bottom`}>Pesquisar</Tooltip>}
                >
                  <Button
                    variant="secondary"
                    className="botao-pesquisar-redondo-acao-consultar-simcard"
                    onClick={() => handlePesquisar()}
                  >
                    <Image
                      className="icone-pesquisar-redondo-acao-consultar-simcard"
                      src={lupa}
                    />
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <div className="container-tabela-modal">
              <BootstrapTable
                classes="tabela-transparente-modais"
                hover
                keyField="id"
                data={itens}
                columns={colunas}
                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                bootstrap4={true}
              />
            </div>
          </Col>
        </Form.Row>
        <div className="modal-acao-consultar-simcard-espacador" />
      </div>
      {revelarMensagemErro && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemErro
              handleMensagemErro={handleMensagemErro}
              mensagemTitulo={mensagemErro}
              mensagemParagrafo1={"Erro"}
              mensagemParagrafo3={mensagemErro}
              mensagemBotao={"Retornar"}
            />
          </div>
        </div>
      )}
      {revelarMensagemSucesso && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemSucesso
              handleMensagemSucesso={handleMensagemSucesso}
              mensagemTitulo={"Sucesso"}
              mensagemParagrafo1={"O cliente " + dsCliente + ","}
              mensagemParagrafo2={"cód. " + cdCliente + " foi carregado."}
              mensagemBotao={"OK"}
            />
          </div>
        </div>
      )}
    </Form>
  );
}
