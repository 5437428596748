import styled from "styled-components";
import { Checkbox as CheckboxMantine } from "@mantine/core";

export const Checkbox = styled(CheckboxMantine)`
  .mantine-Checkbox-input {
    background-color: transparent !important;
    border-color: ${({ theme }) => theme.cor39} !important;
    border: 3px solid;

    :checked {
      background-color: ${({ theme }) => theme.cor11} !important;
      border-color: ${({ theme }) => theme.cor11} !important;
    }
  }

  .mantine-Checkbox-label {
    color: ${({ theme }) => theme.cor9} !important;
    font-size: 16px !important;
    background-color: transparent !important;
    font-weight: 500;
  }
`;
