const ESTADO_INICIAL = {
    revelarNovoUsuarioCliente: false,
    revelarEditarUsuarioCliente: false,
    revelarExcluirUsuarioCliente: false,
    revelarReenviarLinkPrimeiroAcessoUsuarioCliente: false,
};

export const menuUsuarios = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'REVELAR_NOVO_USUARIO_CLIENTE') {
        return {
            ...state, 
            revelarNovoUsuarioCliente: action.parametro
        }
    }
    else if (action.type === 'REVELAR_EDITAR_USUARIO_CLIENTE') {
        return {
            ...state, 
            revelarEditarUsuarioCliente: action.parametro
        }
    }
    else if (action.type === "REVELAR_EXCLUIR_USUARIO_CLIENTE") {
        return {
            ...state,
            revelarExcluirUsuarioCliente: action.parametro
        }
    }
    else if (action.type === "REVELAR_REENVIAR_LINK_PRIMEIRO_ACESSO_USUARIO_CLIENTE") {
        return {
            ...state,
            revelarReenviarLinkPrimeiroAcessoUsuarioCliente: action.parametro
        }
    }
    else {
        return state;
    }
}