const ESTADO_INICIAL = {
    revelarClienteNovo: false,
    revelarClienteEditar: false,
    revelarClienteExcluir: false,
    revelarCadastroClienteCarregar:false,
};

export const cadastrosReducer = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'REVELAR_CLIENTE_NOVO') {
        return {
            ...state, 
            revelarClienteNovo: action.parametro
        }
    }
    else if (action.type === 'REVELAR_CLIENTE_EDITAR') {
        return {
            ...state, 
            revelarClienteEditar: action.parametro
        }
    }
    else if (action.type === 'REVELAR_CLIENTE_EXCLUIR') {
        return {
            ...state, 
            revelarClienteExcluir: action.parametro
        }
    }
    else if (action.type === 'REVELAR_CLIENTE_CARREGAR') {
        return {
            ...state, 
            revelarCadastroClienteCarregar: action.parametro
        }
    }
    else {
        return state;
    }
}