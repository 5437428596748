import React from "react";

import { Botao } from "./styles";

const BotaoVerde = ({ children, ativo, ...props }) => (
  <Botao variant="contained" ativo={ativo} {...props}>
    {children}
  </Botao>
);

export default BotaoVerde;
