//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';/*eslint-disable*/
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectParametro from '../../componentesModais/selects/selectParametro'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectParametro, setRevelarSelectParametro] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">Parametro {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectParametro === false &&
                <Button name="cdParametro" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectParametro(!revelarSelectParametro)}>
                    <div className="fonte-cor-1 label-campo">{dsParametro}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectParametro === true &&
                <SelectParametro
                    setRevelarSelectParametro={setRevelarSelectParametro} //Hook para fechar a modal
                    setCdParametro={setCdParametro} //Código do Parametro
                    setDsParametro={setDsParametro} //Nome do Parametro
                    dsParametro={dsParametro} 
                    flRegraAutomacao={flRegraAutomacao}
                >
                </SelectParametro>
            }
        </Form.Group>*/

export default function SelectParametro(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [lsParametro, setLsParametro] = useState()
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleParametro(cdAutEventoParametro, cdAutEventoParametroValor, dsParametro, dsValor){
        props.setCdAutEventoParametro(cdAutEventoParametro)
        props.setCdAutEventoParametroValor(cdAutEventoParametroValor)
        props.setDsParametro(dsParametro)
        props.setDsValor(dsValor)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectParametro(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderParametro(){
            const {data} = await api.get('/m1/consultar/aut_evento_parametro?cd_aut_evento='+props.cdAutEvento);            
            if(data.dados.length > 0){
                let objetoParametro = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.cd_aut_evento_parametro}>
                            <div className="campo-select-opcao campo-select-opcao-2 campo-select-opcao-automacao" onClick={() => handleParametro(item.cd_aut_evento_parametro, item.cd_aut_evento_parametro_valor, item.PARAMETRO, item.ds_valor) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.PARAMETRO}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsParametro(objetoParametro)
            }else{
                let objetoParametro = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsParametro(objetoParametro)
            }
            setSpinAtivo(false)
        }
        renderParametro()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal} >
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => props.setRevelarSelectParametro(false)}>
                <div className="fonte-cor-1 label-campo">{props.dsParametro}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsParametro}
                </ul>
            </div>
        </Form>
    )
}

