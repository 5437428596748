import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import './GrupoMinMax.css';

function GrupoMinMax(props) {
    const { titulo, unidade='?', nomeCampoMinimo, nomeCampoMaximo } = props;
    const { filtroMinimo, setFiltroMinimo } = props;
    const { filtroMaximo, setFiltroMaximo } = props;

    const campoTextoMin = "campo-texto-minimo";
    const campoTextoMax = "campo-texto-maximo";

    const [permitirMinimo, setPermitirMinimo] = useState(true)
    const [permitirMaximo, setPermitirMaximo] = useState(true)

    const [aviso , setAviso] = useState(false)

    useEffect(() => { // handleLimparFiltros
        if(props.foiLimpado && aviso) {
            setAviso(false);
        }
    }, [props.foiLimpado, aviso])

    function validarNumero(id, valor) {
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if (id === campoTextoMin) {
                setPermitirMinimo(true)
                setFiltroMinimo(valor)
                validarValor(valor, id)
            }
            else if (id === campoTextoMax) {
                setPermitirMaximo(true)
                setFiltroMaximo(valor)
                validarValor(valor, id)
            }
        }
    }

    function validarValor(valor, campo){
        // valida se os valores do máximo é maior que os do mínimo
        let numInteiro = parseInt(valor, 10);
        let numInteiro2

        if (campo === campoTextoMin){
            numInteiro2 = parseInt(filtroMaximo, 10); 
            if(numInteiro2 < numInteiro){
                setAviso(true)
                props.setFoiSelecionado(false)
            }else{
                setAviso(false)
                props.setFoiSelecionado(true)
            }
        }
        else if(campo === campoTextoMax){
            numInteiro2 = parseInt(filtroMinimo, 10);  
            if(numInteiro < numInteiro2){
                setAviso(true)
                props.setFoiSelecionado(false)
            }else{
                setAviso(false)
                props.setFoiSelecionado(true)
            }
        }
    }

  return (
    <div className="div-grupo-filtro-min-max">
        <Form.Label className="fonte-cor-1 label-campo-filtro campo-dias-utilizados">{titulo}</Form.Label>
        <div className="break-3"></div>
        <div className="break-3"></div>
        <Form.Group>
            {filtroMinimo != "" ?
                <CampoMinimo
                    classCampo='filtros-dias'
                    nomeCampoMinimo={nomeCampoMinimo}
                    unidade={unidade}
                />
                : ""
            }
            <div className="filtros-texto-linha-margin">
                <div className="col-sm">
                    <div className="d-flex">
                        <InputMinMax
                            placeholder="Min."
                            nome={nomeCampoMinimo}
                            filtroMinMax={filtroMinimo}
                            permitirMinMax={permitirMinimo}
                            campoTexto={campoTextoMin}
                            validarNumero={validarNumero}
                        />
                    </div>
                </div>
                <div className="col-sm">
                    <label className="label-texto-valor-filtros fonte-cor-1"> </label>
                    <div className="d-flex">
                        {filtroMaximo != "" ?
                            <CampoMinimo
                                classCampo='filtros-dias-b'
                                nomeCampoMinimo={nomeCampoMinimo}
                                unidade={unidade}
                            />
                            : ""
                        }
                        <InputMinMax
                            placeholder="Máx."
                            nome={nomeCampoMaximo}
                            filtroMinMax={filtroMaximo}
                            permitirMinMax={permitirMaximo}
                            campoTexto={campoTextoMax}
                            validarNumero={validarNumero}
                        />
                    </div>
                    {aviso &&
                        <Form.Label className="label-campo-dias-invalido">Máx. deve ser maior que Min.</Form.Label>
                    }
                </div>
            </div>

        </Form.Group>
    </div>
  )
}

function CampoMinimo(props) {
    return (
        <Form.Control
            name={"UNIDADE_MIN_"+props.nomeCampoMinimo}
            className={`${props.classCampo} campo-texto-cor-3`}
            type="text"
            value={props.unidade}
            placeholder="Min."
            autoComplete='off'
        />
    )
}

function InputMinMax({
    placeholder,
    nome, 
    filtroMinMax,
    permitirMinMax,
    campoTexto,
    validarNumero
}) {
    const isMin = (placeholder === 'Min.');

    const classMin = filtroMinMax != "" ? "filtros-iccid-valor-min-2 campo-texto-cor-3" : "filtros-iccid-valor-min-2-b campo-texto-cor-3";
    
    const classMax = filtroMinMax != "" ? "filtros-iccid-valor-max-2-b campo-texto-cor-3" : "filtros-iccid-valor-max campo-texto-cor-3";

    const classNameInput = isMin ? classMin : classMax;

    return (
        <>
            <Form.Control
                name={nome}
                className={classNameInput}
                type="text"
                maxLength="4"
                value={permitirMinMax ? filtroMinMax : ""}
                onChange={e => validarNumero(campoTexto, e.target.value)}
                placeholder={placeholder}
                autoComplete='off'
            />
            <label className={`fonte-cor-1 campo-texto-cor-3 ${isMin ? 'break-3' : ''}`}></label>
        </>
    )
}

export default GrupoMinMax