import React, { useEffect, useState, useRef } from 'react';/*eslint-disable*/
import { Button, Form, Col } from 'react-bootstrap';/*eslint-disable*/
import moment from 'moment';

import {api} from '../../conexoes/api';/*eslint-disable*/
// IMPORTAÇÕES REDUX
// import { useDispatch } from 'react-redux';
// import { revelarTutorial, mudarManejador } from "../../redux/actions/index";
// import decryptJWT from '../../utils/decryptJWT'
// ICONES
import { elipseVerde, fecharJanela } from '../../utils/alternanciaIcones'
// ESTILIZAÇÃO
import '../../css/releaseNotes/releaseNotes.css'
// SPINNER
import SpinerVeye from '../spinnerVeye/spinnerVeye.js'
import iconeModalNovidades from '../../icones/iconesVersaoClara/novidades-modal-icone.svg'
import { Link } from 'react-router-dom';

const idConteudoDivStr = 'lista-novidades-versao';
const emailSuporte = 'veye@veye.com.br';

let conteudoStrHTML;

export default function ModalDetalhesVersao(props) { // Modal Novidades
    const [data, setData] = useState('');
    const [versao, setVersao] = useState('');
    const [imagemBase64, setImagemBase64] = useState(false);
    // const [conteudoStrHTML, setConteudoStrHTML] = useState('carregando...');

    // HOOKS REDUX
    // const despacho = useDispatch();
    // HOOKS SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    // HOOKS PRINCIPAIS
    const modal = useRef();
    // const clientePrimario = decryptJWT ('clientePrimario')

    function handleCancelar(){
        props.setRevelarMenuReleaseNotes(false);
    }

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        async function carregarDados() {
            conteudoStrHTML = '';
            let rotaGetVersao = 'm1/consultar/versionamento'

            if(props.cd_pav_release) { // Detalhes da versão
                rotaGetVersao += '?cd_pav_release='+props.cd_pav_release;
            }

            await api.get(rotaGetVersao)
                .then(async res => {
                    if(res?.status === 200) {
                        const dados = res?.data?.dados[0];
                        setVersao(dados.ds_release);
                        setData(moment(dados.dt_created_at).format('DD/MM/YYYY'));
                        setImagemBase64(dados.bl_imagem);
                        conteudoStrHTML = dados.ds_texto;

                        if(!props.cd_pav_release) { // novidades
                            let jaExiste = false;
                            const {lastReleaseNoteRead} = JSON.parse(localStorage.getItem('releaseNotes'))
                            if(lastReleaseNoteRead > 0) {
                                jaExiste = true;
                            }
                            const lastReleaseNotes = dados.cd_pav_release

                            if((lastReleaseNotes > lastReleaseNoteRead) || !lastReleaseNoteRead) {
                                api.post('m1/alterar/usuario_versionamento',{ cd_pav_release: lastReleaseNotes, jaExiste })
                                localStorage.setItem('releaseNotes', JSON.stringify({lastReleaseNotes, lastReleaseNoteRead: lastReleaseNotes}));
                            }
                        }
                        // setVersao('V1.1.1.1'); // exemplo
                        // setData('20/06/2023');
                        // conteudoStrHTML = '<div><strong>Teste Conteúdo</strong></div>';
                    }
                })
                .catch(err => {
                    console.error({ err })
                    conteudoStrHTML = 'Erro ao consultar dados!';
                })

            setSpinAtivo(false);
            let conteudoDiv = document.getElementById(idConteudoDivStr);
            conteudoDiv.innerHTML = conteudoStrHTML
        }

        carregarDados();

        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return (
        <Form ref={modal} className="modal-acao-novidades fundo-cor-6">
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="tela-painel-novidades">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{props.cd_pav_release ? 'Detalhes da Versão' : 'Novidades'}</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col>
                        <span className="fonte-cor-1">{data} - {versao}</span>
                    </Col>
                </Form.Row>

                <div className="div-corpo-novidades">
                    {((conteudoStrHTML?.indexOf('<img') === -1) || imagemBase64) && // se não tem imagem no corpo html vindo do banco OU tem imagem no campo 'bl_imagem'
                    <Form.Row>
                        <Col id="div-imagem-novidades">
                        {data && <img id="imagem-modal-novidades" src={imagemBase64 || iconeModalNovidades} alt="Novidades da versão"/>}
                        </Col>
                    </Form.Row>}

                    <Form.Row id={idConteudoDivStr} className="fonte-cor-1">
                        <span>carregando...</span>
                        {/* vai ter HTML escrito aqui pelo Javascript no useEffect */}
                        {/* <Col> //Exemplo
                            <ul>
                                {[1,2,3].map(item => (
                                    <li>
                                        <strong>Novidade xyz para clientes: </strong>
                                        Novidade xyz para clientes: sum dolor sit amet consectetur. Sapien mauris malesuada ac est massa interdum. Adipiscing iaculis faucibus non mauris purus. Penatibus commodo arcu nec at amet mollis morbi erat tristique. Faucibus felis leo ultrices ut sit a vitae 
                                    </li>
                                ))}
                            </ul>
                        </Col> */}
                    </Form.Row>
                </div>

                <Form.Row id="novidades-rodape">
                    <Col>
                        <a href={`mailto:${emailSuporte}`}>{emailSuporte}</a>
 
                        {!props.cd_pav_release && <>
                            <div id="risco-divisorio"></div>

                            <Link to="/veye/tabela/versoes">
                                <button onClick={handleCancelar}>HISTÓRICO DE VERSÕES</button>
                            </Link>
                        </>}
                    </Col>
                </Form.Row>
            </div>
        </Form>
    );
}