import React from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";

function Filtros(props) {

  return (
    <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <ul className="drop-acoes-lista-menu">
                    <li key={"menu-menu.cdMenu"}>
                        Não implementado
                        <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                        <div className="botao-central">
                            <Form.Row>
                                <Col>
                                    <Button disabled={props.foiSelecionado?false:true} type="submit" className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                        Filtrar
                                    </Button>
                                    <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                        Limpar
                                    </Button>
                                </Col>
                            </Form.Row>
                        </div>
                    </li>
                </ul>
            </div>
        </Form>
  );
}

export default Filtros;
