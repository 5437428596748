import { Menu } from "@mui/material";
import React from "react";

import { getCoresOpcoesMenuGrafico } from "../../cores";
import BotaoVerde from "../BotaoVerde/BotaoVerde";
import OpcoesMeses from "../CardGenerico/SelecionarCiclo/OpcoesMeses";

const OpcoesMenuCiclo = (props) => {
  const { backgroundColor, corTexto } = getCoresOpcoesMenuGrafico();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAlterarCiclo = (ciclo) => {
    props.alterarCiclo(ciclo);
    handleClose();
  };

  // correção da posição do menu quando abre
  const ajusteMenu = {
    "selecionar-ciclo": { marginTop: "45px", marginLeft: "18.5%" },
  };

  return (
    <div style={{ position: "relative" }}>
      <BotaoVerde
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        ativo={props.botaoHistoricoSelecionado}
        onClick={handleClick}
      >
        Selecionar ciclo
      </BotaoVerde>

      <Menu
        id="basic-menu"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              backgroundColor,
              color: corTexto,
              height: "auto",
              width: "190px",
              ...ajusteMenu[props.id],
            },
          },
        }}
      >
        <OpcoesMeses alterarCiclo={handleAlterarCiclo} />
      </Menu>
    </div>
  );
};

export default OpcoesMenuCiclo;
