//IMPORTAÇÕES
import React, {useEffect, useState} from 'react';
import {api} from '../../../conexoes/api'; /*eslint-disable*/
import {Button, Image} from 'react-bootstrap'; /*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, {PaginationProvider} from 'react-bootstrap-table2-paginator';
//COMPONENTES
import CadastroCliente from './cadastro/cadastro'
import { RenderMensagemAtencao } from '../../componentesModais/mensagens/mensagemAtencao'
import { RenderMensagemErro } from '../../componentesModais/mensagens/mensagemErro'
import { RenderMensagemSucesso } from '../../componentesModais/mensagens/mensagemSucesso'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import ImportarClientesUsuarios from './acoes/ImportarClientesUsuarios.js'
//REDUX
import {connect, useDispatch, useSelector} from 'react-redux';
import {
    desabilitarAcoes,
    limpadorFiltros,
    limpouBusca,
    mudarFiltros,
    mudarManejador,
    realizouBusca,
    realizouFiltro,
    revelarBarraFiltragem,
    revelarCadastroClienteCarregar,
    revelarCadastroClienteExcluir
} from "../../../redux/actions/index";
//SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelCadastros/cadCliente/pavCliente.css'
import decrypt from '../../../utils/decryptJWT';
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
import DetalhamentoCliente from "../../painelCadastros/cadCliente/detalhamentoClienteN2/detalhamentoCliente";
import GirarSetaDropdown from "../../../utils/girarSetaDropdown";
import {setaDropdown} from "../../../utils/alternanciaIcones";
import {isPortal} from "../../../utils/nivelCliente";

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function Clientes(props){ // Meus Clientes e Cadastros/Clientes
    const isTelaMeusClientes = () => !!window.location.href.includes('veye/portal/cliente');

    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    //HOOKS VARIÁVEIS

    const clientePortal = isPortal()
    const [row ,setRow] = useState();
    const [cdCliente ,setCdCliente] = useState("");
    const [dsCliente ,setDsCliente] = useState("");
    const [dsCpf ,setDsCpf] = useState("");
    const [dsCnpj ,setDsCnpj] = useState("");
    const [cdPessoa ,setCdPessoa] = useState("");
    const [dsNomeFantasia ,setDsNomeFantasia] = useState("");
    const [dsEmail ,setDsEmail] = useState("");
    const [dsTelefone ,setDsTelefone] = useState("");
    const [cdTipoPessoa ,setCdTipoPessoa] = useState("");
    const [dsTipoPessoa ,setDsTipoPessoa] = useState("");
    //HOOKS MENSAGENS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [mensagemSucesso, setMensagemSucesso] = useState("");
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    //HOOKS REDUX
    const despacho = useDispatch();
    const manejador = useSelector(state => state.manejadorState.manejador)
    const conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    const limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    const realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    const filtros = useSelector(state => state.filtrosState.filtros);
    const revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    const realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    const NovoCliente =  useSelector(state => state.cadastrosState.revelarClienteNovo);
    const EditarCliente =  useSelector(state => state.cadastrosState.revelarClienteEditar);
    const ExcluirCliente =  useSelector(state => state.cadastrosState.revelarClienteExcluir);
    const carregarCliente =  useSelector(state => state.cadastrosState.revelarCadastroClienteCarregar);
    let revelarImportarClientesUsuarios = useSelector(state => state.manejadorState.revelarImportarClientesUsuarios);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);

    const [tipoCliente, setTipoCliente] = useState([]);
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    //SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);

    let itensVar = []
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);

    function isTelaPortalCliente() { // Meus Clientes
        let url = window.location.href
        return url.includes('veye/portal/cliente')
    }

    useEffect(() => {
        api.get('/m1/consultar/cliente_tipo').then((response) => {
            setTipoCliente(transformarObjeto(response.data.dados));
        })
    }, []);

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(1428) // painelCadastroCliente
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(()=>{
        async function handlerCarregarCliente(){
            if(carregarCliente){
                despacho(revelarCadastroClienteCarregar(false))
                const request = "/m1/servicos/alterar_token?login=" +decrypt("login") +"&email=" +decrypt("email") +"&codigo=" +decrypt("codigo")
                            +"&codigoCliente=" +row.cd_pav_cliente +"&codigoClienteErpAntigo=" +row.n0_codigo_cliente +"&codigoClienteTexto=" +row.n0_codigo_cliente
                            +"&nomeDeUsuario=" +decrypt("nomeDeUsuario") +"&perfil=" +decrypt("perfil") +"&cdSmsContaUsuario=" +decrypt("cdSmsContaUsuario")
                            +"&id="+decrypt("id") +"&fatContas=" + decrypt("fatContas")+ "&clienteNivel="+row.n0_nivel+"&tipoPerfil=" +decrypt("tipoPerfil")
                            +"&clientePrimario=" +decrypt("clientePrimario");
                await api .get(request)
                .then(function (response) {
                    if(response.data.status == 200){
                        localStorage.setItem(
                            "@veye-Token",
                            response.data.dados.token
                        );

                        setMensagemSucesso('O cliente ' + row.ds_nome_fantasia + " foi carregado com sucesso " )
                        setRevelarMensagemSucesso(true);
                    }
                    else{
                        setRevelarMensagemErro(true)
                    }
                })
                .catch(()=>{
                    setRevelarMensagemErro(true)
                })
            }
            if(ExcluirCliente){
                despacho(revelarCadastroClienteExcluir(false))

                setRevelarMensagemAtencao(true)
            }
        }
        handlerCarregarCliente()
    },[carregarCliente,ExcluirCliente])


    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!props.filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
            despacho(revelarBarraFiltragem(false))
            despacho(limpadorFiltros(false))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        let data

        setSpinAtivo(true)
        despacho(mudarManejador("cadastroCliente"));

        async function carregarTabela(){
            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }


            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    if(isTelaPortalCliente()){
                        data = await api.get('m1/consultar/cliente?buscar='+conteudoBusca+'&cd_pav_cliente_not=true&telaClientePortal=true&separadorLinha='+separadorLinha)
                    }else {
                        data = await api.get('/m1/consultar/cliente?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+"&cd_pav_cliente_not=true&separadorLinha="+separadorLinha);
                    }
                    inserirData(data.data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    cd_pav_cliente_not:true
                }
                if (controladorPagina <= paginaAtual) {
                    data = await api.post("/m1/filtrar/cliente", dados);
                    inserirData(data.data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    if(isTelaPortalCliente()){
                        data = await api.get('m1/consultar/cliente?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar=&cd_pav_cliente_not=true&telaClientePortal=true')
                    }else {
                        data = await api.get('/m1/consultar/cliente?paginaAtual='+contador+'&carregarLimit='+valorLimite+"&cd_pav_cliente_not=true");
                    }
                    inserirData(data.data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                itensVar[k] = data[j]
                k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }

    async function contarItens(){
        let arrayItens = []
        let apiData;
        if (controladorPagina <= paginaAtual) {
            if (controladorPagina <= paginaAtual && filtros.vazio) {
                if (isTelaPortalCliente()) {
                    apiData = await api.get('/m1/consultar/cliente?contar=true&buscar=' + conteudoBusca + "&cd_pav_cliente_not=true&telaClientePortal=true&separadorLinha=" + separadorLinha);
                } else {
                    apiData = await api.get('/m1/consultar/cliente?contar=true&buscar=' + conteudoBusca + "&cd_pav_cliente_not=true&separadorLinha=" + separadorLinha);
                }
            } else{
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    contar: true,
                    cd_pav_cliente_not:true
                }
                if(isTelaPortalCliente()) {
                    dados.filtros.n0_cliente_nivel = 3
                }
                apiData = await api.post("/m1/filtrar/cliente", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalItens = apiData.data.dados[0].CONTADOR;
                totalPaginas = Math.ceil(totalItens / valorLimite)
                for( var i = 0 ; i < totalItens; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    function LabelTabela({children}) {
        return <p className="fonte-cor-1 label-tabela-1">{children}</p>
    }

    const formatter = (cell) => {
        return <LabelTabela>{cell || '-'}</LabelTabela>;
    }

    const colunas = [{
        dataField: 'cd_pav_cliente',
        text: 'ID',
        sort: true,
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        headerStyle: (colum, colIndex) => {
            return { width: '130px'}
        },
        formatter
    },
    {
        dataField: 'cd_pav_pessoa',
        headerClasses: 'nao-selecionavel',
        text: 'ID pessoa',
        hidden: true,
        formatter
    },
    {
        dataField: 'cd_pav_tipo_pessoa',
        headerClasses: 'nao-selecionavel',
        text: 'ID tipo pessoa', // TODO: ver se deve tirar essa coluna
        hidden: false,
        formatter
    },
    {
        dataField: 'ds_pav_tipo_pessoa',
        headerClasses: 'nao-selecionavel',
        text: 'Tipo pessoa',
        sort: true,
        formatter
    },
    {
        dataField: 'ds_cnpj',
        headerClasses: 'nao-selecionavel',
        text: 'CNPJ',
        sort: true,
        formatter
    },
    {
        dataField: 'ds_pav_cliente',
        headerClasses: 'nao-selecionavel',
        text: 'Razão social',
        sort: true,
        formatter: (cell, row) => {
            let valor = cell === 'undefined' || !cell ? '-' : cell;
            if( !row?.cd_pav_tipo_pessoa ) {
                return '-'
            } else {
                const pessoaJuridica = row.cd_pav_tipo_pessoa == 2;
                if(valor && !pessoaJuridica) valor = '-';

                return <LabelTabela>{valor}</LabelTabela>;
            }
        }
    },
    {
        dataField: 'ds_nome_fantasia',
        headerClasses: 'nao-selecionavel',
        text: 'Nome fantasia',
        sort: true,
        formatter
    },
    {
        dataField: 'ds_nome',
        headerClasses: 'nao-selecionavel',
        text: 'Nome',
        sort: true,
        formatter: (cell, row) => {
            let valor = cell === 'undefined' || !cell ? '-' : cell;
            if( !row?.cd_pav_tipo_pessoa ) {
                return '-'
            } else {
                const pessoaJuridica = row.cd_pav_tipo_pessoa == 2;
                if (valor && pessoaJuridica) valor = '-';

                return <LabelTabela>{valor}</LabelTabela>;
            }
        }
    },
    {
        dataField: 'ds_email',
        headerClasses: 'nao-selecionavel',
        text: 'E-mail',
        sort: true,
        formatter
    },
    {
        dataField: 'ds_telefone',
        headerClasses: 'nao-selecionavel',
        text: 'Telefone',
        sort: true,
        formatter: (cell) => {
            const valor = cell.trim() || '-'
            return (<LabelTabela>{valor}</LabelTabela>)
        }
    },
    {
        dataField: 'n0_nivel',
        headerClasses: 'nao-selecionavel',
        text: 'Nível Cliente',
        sort: true,
        formatter
    }]
    
    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                setRow(row)
                setCdCliente(row.cd_pav_cliente);
                setDsCliente(row.ds_pav_cliente);
                setDsCpf(row.ds_cpf);
                setDsCnpj(row.ds_cnpj);
                setCdPessoa(row.cd_pav_pessoa);
                setDsNomeFantasia(row.ds_nome_fantasia);
                setDsEmail(row.ds_email);
                setDsTelefone(row.ds_telefone);
                setCdTipoPessoa(row.cd_pav_tipo_pessoa);
                setDsTipoPessoa(row.ds_pav_tipo_pessoa)
                despacho(desabilitarAcoes(false));
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            // setCdCliente(row.cd_pav_cliente);
            // setDsCliente(row.ds_pav_cliente);
            // setDsCpf(row.ds_cpf);
            // setDsCnpj(row.ds_cnpj);
            // setCdPessoa(row.cd_pav_pessoa);
            // setDsNomeFantasia(row.ds_nome_fantasia);
            // setDsEmail(row.ds_email);
            // setDsTelefone(row.ds_telefone);
            // setCdTipoPessoa(row.cd_pav_tipo_pessoa);
            // setDsTipoPessoa(row.ds_pav_tipo_pessoa)
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function colunaAcoes(){
        return(
            <p className="acoes">Ações</p>
        );
    }


    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    async function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                ColetaClickstream(1438) // dropAcoesRevelarCadastroClienteExcluirConfirmar
                await api.get('/m1/deletar/cliente?cd_pav_cliente='+row.cd_pav_cliente)
                .then((response)=>{
                    if(response.data.status == 200){
                        setMensagemSucesso('O cliente ' + row.ds_nome_fantasia + ' foi deletado com sucesso')
                        setRevelarMensagemSucesso(true);
                    }
                    else{
                        setRevelarMensagemErro(true)
                    }
                })
                .catch(()=>{
                    setRevelarMensagemErro(true)
                })
            } else {
                ColetaClickstream(1437) // dropAcoesRevelarCadastroClienteExcluirCancelar
            }
        }
    }



    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
                setRenderizar(!renderizar)
                window.location.reload();
            }else{
                window.location.reload();
            }
        }
    }

    function validaNivelCliente(clientePortal) {
        if(!clientePortal){
            return {
                className: "fundo-cor-1",
                renderer: (row, rowIndex) => (
                    <div
                        className={
                            estadoExpansaoMenu === true
                                ? "expand-row-tabela-clientes-virtueyes-1"
                                : "expand-row-tabela-clientes-virtueyes-2"
                        }
                    >
                        <DetalhamentoCliente
                            noCdCliente={row.n0_codigo_cliente}
                            cdTipoCliente={row.cd_cliente_tipo}
                            cdCliente={row.cd_pav_cliente}
                            logradourocliente={row.logradouro}
                            bairrocliente={row.bairro}
                            ufcliente={row.uf}
                            cidadecliente={row.cidade}
                            cepcliente={row.cep}
                            vendedorcliente={row.ds_nome}
                            cpfVendedorCliente={row.ds_cpf}
                            emailVendedorCliente={row.ds_email}
                            telefoneVendedorCliente={row.ds_telefone}
                            // handleAtualizar={props.handleAtualizar}
                        />
                    </div>
                ),
                expandHeaderColumnRenderer: (row, rowIndex) => (
                    <div className="tela-background"></div>
                ),
                expandColumnRenderer: (rowKey) => {
                    return (
                        <Button
                            className="botao-expandir-row"
                            onClick={(e) => GirarSetaDropdown(rowKey.rowKey)}
                        >
                            <Image
                                id={rowKey.rowKey}
                                className="icone-botao-expandir-row nao-selecionavel"
                                src={setaDropdown()}
                            />
                        </Button>
                    );
                },
                showExpandColumn: true,
                expandByColumnOnly: true,
                headerClasses: "tabela-coluna-primeira-header",
            };
        }
    }

    function transformarObjeto(dados) {
        let novoObjeto = [];

        dados.forEach(item => {
            let novoItem = {
                id: item.cd_cliente_tipo,
                label: item.ds_nome
            };
            novoObjeto.push(novoItem);
        });

        return novoObjeto;
    }

    function getTipoCliente(clienteTipo) {
        let cliente = tipoCliente.filter((cliente) => cliente.id == clienteTipo)[0];
        return cliente
    }

    return(
        <>
            <BarraDadosCliente/>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            {revelarImportarClientesUsuarios === true && // !ultrapassouLimite &&
                <>
                    <div className="componente-modal-overlay">
                        <div className="componente-modal">
                            <ImportarClientesUsuarios
                                setRenderizar={setRenderizar}
                                renderizar={renderizar}
                                telaOrigem={isTelaMeusClientes() ? 'meusClientes' : 'cadastroCliente'}
                            >
                            </ImportarClientesUsuarios>
                        </div>
                    </div>
                </>
            }
            { NovoCliente === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroCliente
                            tituloJanela={'Cadastrar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            isN3={isTelaMeusClientes()}
                        >
                        </CadastroCliente>
                    </div>
                </div>
            }
            { EditarCliente === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroCliente
                            tituloJanela={'Editar'}
                            cdCliente={cdCliente}
                            tipoCliente={getTipoCliente(row.cd_cliente_tipo)}
                            dsCliente={dsCliente}
                            dsCpf={dsCpf}
                            dsCnpj={dsCnpj}
                            cdPessoa={cdPessoa}
                            dsNomeFantasia={dsNomeFantasia}
                            dsEmail={dsEmail}
                            dsTelefone={dsTelefone}
                            dsTipoPessoa={dsTipoPessoa}
                            cdTipoPessoa={cdTipoPessoa}
                            renderizar={renderizar}
                            row={row}
                            setRenderizar={setRenderizar}
                            isN3={isTelaMeusClientes()}
                            >
                        </CadastroCliente>
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_pav_cliente'
                                data={itens}
                                columns={colunas}
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                expandRow={ validaNivelCliente(clientePortal)}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    -
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemSucesso &&
                <RenderMensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={mensagemSucesso}
                    mensagemBotao={"Continuar"}
                />
            }
            { revelarMensagemErro &&
                <RenderMensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
            { revelarMensagemAtencao &&
                <RenderMensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Deseja remover cliente?"}
                    mensagemBotao={"Remover"}
                />
            }
        </>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
}
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(Clientes));
