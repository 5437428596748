import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
import Calendario from '../calendario/calendario'
import moment from 'moment';
import SelectOperadoraFaturas from '../componentesModais/selects/selectOperadoraFaturas'


import { useDispatch, useSelector} from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'

export default function FiltrosFatSolicitacoes(props) {
    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())
    //HOOKS DOS VALORES DOS FILTROS
    const [revelarSelectOperadoraFaturas, setRevelarSelectOperadoraFaturas] = useState(false)
    const [filtroDataInicial, setFiltroDataInicial] = useState('')
    const [filtroDataFinal, setFiltroDataFinal] = useState('')
    const [filtroOperadora, setFiltroOperadora] = useState('')
    const [filtroStatus, setFiltroStatus] = useState('')
    const [dsOperadora, setDsOperadora] = useState("Selecione")
    const [cdOperadora, setCdOperadora] = useState()

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []
    let filtrosSimcard = useSelector(state => state.filtrosState.arrayFiltrosSimcard)
    
    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            if(filtros.DATA_INICIO !='' && filtros.DATA_INICIO){
                let auxData = new Date(filtros.DATA_INICIO)
                auxData.setDate(auxData.getDate()+1)
                setFiltroDataInicial(auxData)
            }
            if(filtros.DATA_FIM != '' && filtros.DATA_FIM){
                let auxData = new Date(filtros.DATA_FIM)
                auxData.setDate(auxData.getDate()+1)
                setFiltroDataFinal(auxData)
            }
            if(filtros.OPERADORA && filtros.OPERADORA != ''){
               setDsOperadora(filtros.OPERADORA)
            }
            if(filtros.STATUS && filtros.STATUS != ''){
               setFiltroStatus(filtros.STATUS)
            }
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    function handleFuncaoEstadosRedux(){
        if(filtroDataInicial !== '' && filtroDataFinal !== '' && filtroDataInicial && filtroDataFinal && moment(filtroDataInicial)._isValid && moment(filtroDataFinal)._isValid){
            arrayFiltrosSimcard.push("Data: "+moment(filtroDataInicial).format("DD-MM-YYYY")+" até "+moment(filtroDataFinal).format("DD-MM-YYYY")+"?DATA_INICIO")
        }
        if(filtroStatus != '' && filtroStatus){
            arrayFiltrosSimcard.push("Status: "+filtroStatus+"?STATUS")
        }
        if(cdOperadora != '' && cdOperadora){
            arrayFiltrosSimcard.push("Operadora: "+dsOperadora+"?OPERADORA")
        }
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroDataInicial == '') verificador.push(1)
            if(filtroDataFinal =='') verificador.push(1)
            if(dsOperadora== 'Selecione') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 3) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()){
                props.setFoiSelecionado(true);
            }else{
                props.setFoiSelecionado(false);
            }
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setCdOperadora('-1')
            setDsOperadora('Seleciona')
            setFiltroDataInicial('')
            setFiltroDataFinal('')
            setFiltroStatus('')
            setCdOperadora(0)
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroDataInicial,filtroDataFinal,filtroStatus,filtroOperadora,cdOperadora,props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-operadora","imagem-filtro-operadora")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Operadora </Form.Label>
                        <img id={"imagem-filtro-operadora"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-operadora"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <Form.Group className="container-select-filtro">
                            <Form.Control id="OPERADORA" name="OPERADORA" type="text" value={dsOperadora == 'Selecione' ? '' : dsOperadora} className="d-none"/>
                            { revelarSelectOperadoraFaturas === false &&
                                <Button className="campo-texto-cor-3 label-elipses campo-select" onClick={() => setRevelarSelectOperadoraFaturas(!revelarSelectOperadoraFaturas)}>
                                    <div className="fonte-cor-1 label-campo">{dsOperadora}</div>
                                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                </Button>
                            }
                            { revelarSelectOperadoraFaturas === true &&
                                <SelectOperadoraFaturas
                                    setRevelarSelectOperadoraFaturas={setRevelarSelectOperadoraFaturas} //Hook para fechar a modal
                                    setDsOperadora={setDsOperadora} //Nome do estado
                                    dsOperadora={"Selecione"} //Placeholder para mostrar o valor original em caso de edição
                                    setCdOperadora={setCdOperadora}
                                >
                                </SelectOperadoraFaturas>
                            }
                        </Form.Group>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-status","imagem-filtro-status")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Status </Form.Label>
                        <img id={"imagem-filtro-status"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Aberta')}>Aberta
                            <input defaultChecked={handlerCheck("STATUS","Aberta")} type="radio" id="STATUS_ABERTA" name="STATUS" value="Aberta"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Concluída')}>Concluída
                            <input defaultChecked={handlerCheck("STATUS","Concluída")} type="radio" id="STATUS_CLARO" name="STATUS" value="Concluída"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Cancelada')}>Cancelada
                            <input defaultChecked={handlerCheck("STATUS","Cancelada")} type="radio" id="STATUS_CANCELADA" name="STATUS" value="Cancelada"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Em Andamento')}>Em andamento
                            <input defaultChecked={handlerCheck("STATUS","Em andamento")} type="radio" id="STATUS_EM_ANDAMENTO" name="STATUS" value="Em andamento"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-data","imagem-filtro-data")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data da solicitação </Form.Label>
                        <img id={"imagem-filtro-data"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_INICIO" type="text" value={filtroDataInicial ? moment(filtroDataInicial).format("YYYY-MM-DD hh:mm:ss") : filtroDataInicial} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataInicial} setData={setFiltroDataInicial} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DATA_FIM" type="text" value={filtroDataFinal ? moment(filtroDataFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataFinal} setData={setFiltroDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}