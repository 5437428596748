import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';
import Calendario from '../calendario/calendario'
import moment from 'moment';

export default function FiltrosPedidosSms(props) {
    //HOOKS DE DATAS BASICAS
    const [dataMin, setDataMin] = useState('1990')
    const [dataMax, setDataMax] = useState(new Date())
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroStatus, setFiltroStatus] = useState('')
    const [filtroQuantidadeSmsMinimo, setFiltroQuantidadeSmsMinimo] = useState('')
    const [filtroQuantidadeSmsMaximo, setFiltroQuantidadeSmsMaximo] = useState('')
    const [filtroDataInicio, setFiltroDataInicio] = useState('')
    const [filtroDataFinal, setFiltroDataFinal] = useState('')
    const [filtroDataInicioVencimento, setFiltroDataInicioVencimento] = useState('')
    const [filtroDataFinalVencimento, setFiltroDataFinalVencimento] = useState('')

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroStatus == '') verificador.push(1)
            if(filtroQuantidadeSmsMinimo =='') verificador.push(1)
            if(filtroQuantidadeSmsMaximo =='') verificador.push(1)
            if(filtroDataInicio =='') verificador.push(1)
            if(filtroDataInicioVencimento =='') verificador.push(1)
            if(filtroDataFinal =='') verificador.push(1)
            if(filtroDataFinalVencimento =='') verificador.push(1)
            
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 5) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroStatus('')
            setFiltroQuantidadeSmsMinimo('')
            setFiltroQuantidadeSmsMaximo('')
            setFiltroDataInicio('')
            setFiltroDataInicioVencimento('')
            setFiltroDataFinal('')
            setFiltroDataFinalVencimento('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroStatus, filtroQuantidadeSmsMinimo, filtroQuantidadeSmsMaximo, filtroDataInicio, filtroDataInicioVencimento, filtroDataFinal, filtroDataFinalVencimento, props.foiLimpado]);

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-data","imagem-filtro-data")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Emissão </Form.Label>
                        <img id={"imagem-filtro-data"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-data"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="ULTIMA_CONEXAO_INICIO" type="text" value={filtroDataInicio ? moment(filtroDataInicio).format("YYYY-MM-DD hh:mm:ss") : filtroDataInicio} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataInicio} setData={setFiltroDataInicio} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="ULTIMA_CONEXAO_FINAL" type="text" value={filtroDataFinal? moment(filtroDataFinal).format("YYYY-MM-DD hh:mm:ss") : filtroDataFinal} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataFinal} setData={setFiltroDataFinal} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                     {/* ------------------- */}
                     <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-vencimento","imagem-filtro-vencimento")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Vencimento </Form.Label>
                        <img id={"imagem-filtro-vencimento"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-vencimento"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="ULTIMA_CONEXAO_INICIO" type="text" value={filtroDataInicioVencimento ? moment(filtroDataInicioVencimento).format("YYYY-MM-DD hh:mm:ss") : filtroDataInicioVencimento} className="d-none"/>
                            <Calendario imputCustomisado='De:' data={filtroDataInicioVencimento} setData={setFiltroDataInicioVencimento} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="ULTIMA_CONEXAO_FINAL" type="text" value={filtroDataFinalVencimento? moment(filtroDataFinalVencimento).format("YYYY-MM-DD hh:mm:ss") : filtroDataFinalVencimento} className="d-none"/>
                            <Calendario imputCustomisado='Até:' data={filtroDataFinalVencimento} setData={setFiltroDataFinalVencimento} dataMax={dataMax} dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-valor","imagem-filtro-valor")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Valor </Form.Label>
                        <img id={"imagem-filtro-valor"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-valor"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="VALOR_MIN"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        // value={filtroValorMinimo}
                                        // onChange={e => setFiltroValorMinimo(e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3">MB</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="VALOR_MAX"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        // value={filtroValorMaximo}
                                        // onChange={e => setFiltroValorMaximo(e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3">MB</label>
                                </div>
                            </div>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-status","imagem-filtro-status")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Satus </Form.Label>
                        <img id={"imagem-filtro-status"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-status"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Pago')}>Pago
                            <input type="radio" id="OPERADORA_VIVO" name="OPERADORA" value="Vivo"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroStatus('Processado')}>Aberto
                            <input type="radio" id="OPERADORA_CLARO" name="OPERADORA" value="Claro"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-saldo","imagem-filtro-saldo")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Saldo </Form.Label>
                        <img id={"imagem-filtro-saldo"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-saldo"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="SALDO_MIN"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        // value={filtroSaldoMinimo}
                                        // onChange={e => setFiltroSaldoMinimo(e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="SALDO_MAX"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        // value={filtroSaldoMaximo}
                                        // onChange={e => setFiltroSaldoMaximo(e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3"></label>
                                </div>
                            </div>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>

                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}