import { formatarMilharSemDecimais } from "../../../../../utils/formatarMilhar";

export function getCicloFormatado(dadosDash) {
  if (!dadosDash?.length) return;

  if (dadosDash.length > 1) {
    return "Últimos 6 ciclos"; // historico
  }

  const cicloEscolhido = dadosDash[0]?.ciclo || "-";
  return formatarCiclo(cicloEscolhido);
}

export function getNomeMesPeloCiclo(ciclo) {
  return formatarCiclo(ciclo, true);
}

function formatarCiclo(ciclo, apenasMes) {
  const meses = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];

  if (!/^\d{4}$/.test(ciclo)) {
    return "-";
  }

  const cicloAno = ciclo.slice(0, 2);
  const cicloMes = ciclo.slice(2, 4);

  const mesNome = meses[parseInt(cicloMes, 10) - 1];

  if (apenasMes) return mesNome;

  const anoCompleto = "20" + cicloAno;

  return `${mesNome} de ${anoCompleto}`;
}

// Exemplos de uso:
// formatarCiclo('historico'); // Últimos 6 ciclos
// formatarCiclo('2401'); // janeiro de 2024
// formatarCiclo('2408'); // agosto de 2024
// formatarCiclo('2408'); // Ciclo atual (se o mês atual for agosto de 2024)
// formatarCiclo('abcd'); // -
// formatarCiclo('24'); // -

export function obterCiclo(monthIndex, year) {
  // Converte o year para os últimos dois dígitos
  const yearShort = year.toString().slice(-2);

  // Converte o monthIndex para um mês no formato 'MM'
  const month = (monthIndex + 1).toString().padStart(2, "0");

  const ciclo = yearShort + month;

  return ciclo;
}

// Exemplos de uso:
// obterCiclo(0, 2024); // '2401'
// obterCiclo(11, 2024); // '2412'
// obterCiclo(5, 2023); // '2306'

export function getTextoValor(valor) {
  return formatarMilharSemDecimais(valor);
}

export function getTextoPorcentagem(valor, totalLinhas, config) {
  const porcentagem = (valor * 100) / totalLinhas;

  if (isNaN(porcentagem)) return "-";

  if (config?.int) return `${parseInt(porcentagem)}%`;

  return `${formatNumber(porcentagem)}%`;
}

export function formatNumber(number) {
  return number.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
