export function systemTheme(data) {
    return {
        "type":"SYSTEM_THEME",
        "data":data
    }
}

export function currentScreenId(data) {
    return {
        "type":"CURRENT_SCREEN_ID",
        "data":data
    }
}

export function componentRendering(data) {
    return {
        "type":"COMPONENT_RENDERING",
        "data":data
    }
}

export function navigationExpansion(data) {
    return {
        "type":"NAVIGATION_EXPANSION",
        "data":data
    }
}