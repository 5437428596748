//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';
import { useForm } from "react-hook-form";
import { Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
//ICONES
import {elipseVerde, fecharJanela, exclamacao, setaDropdown} from '../../../utils/alternanciaIcones'
//MODAIS
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelAdministrativo/pavGit/pavGit.css'
import SelectGenerico from '../../componentesModais/selects/selectGenerico'

import { useDispatch } from 'react-redux';
import { revelarNovoRelease } from "../../../redux/actions/index";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

/*eslint-disable*/
export default function CadastroGit(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [dummy, setDummy] = useState();
    const [cdGitTagFinal, setCdGitTagFinal] = useState(null);
    const [dsGitTagNomeFinal, setDsGitTagNomeFinal] = useState('Selecione');
    const [cdGitTagInicial, setCdGitTagInicial] = useState(null);
    const [dsGitTagNomeInicial, setDsGitTagNomeInicial] = useState('Selecione');
    const [cdGitProjeto, setCdGitProjeto] = useState(null);
    const [gitProjetoNome, setGitProjetoNome] = useState('Selecione');
    const [dsGitReleaseDescricao, setDsGitReleaseDescricao] = useState('');
    const [dsGitReleaseNome, setDsGitReleaseNome] = useState('');
    //HOOKS MODAIS
    const [revelarSelectGitTagInicial, setRevelarSelectGitTagInicial] = useState(false)
    const [revelarSelectGitTagFinal, setRevelarSelectGitTagFinal] = useState(false)
    const [revelarSelectGitProjeto, setRevelarSelectGitProjeto] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const modal = useRef();
    const despacho = useDispatch();
    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;
    const [selected, setSelected] = useState();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);
    
    useEffect(() => {
        setSpinAtivo(true)
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    //FAZ O MANEJO 
    function handleSalvar(){
        ColetaClickstream(1334) // pavGitTabelaGridDropAcoesNovoReleaseCriar
        handleCadastrar()
    }

    async function handleCadastrar(){
        setSpinAtivo(true)
        await api.post('/m1/cadastrar/git/release', {
            projeto: cdGitProjeto,
            descricao: dsGitReleaseDescricao,
            nome: dsGitReleaseNome,
            nome_tag_inicial: cdGitTagInicial,
            nome_tag_final: cdGitTagFinal
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    function handleCancelar(){
        ColetaClickstream(1333) // pavGitTabelaGridDropAcoesNovoReleaseCancelar
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
        despacho(revelarNovoRelease(false))
        props.setRevelarCadastro(false)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }

    const onChange = (selectedOptions) => {setSelected(selectedOptions)};

    return(
        <Form aria-autocomplete="OFF" ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <div className="modal-cadastro-usuario fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Criar release</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-usuario-1">
                            <div className="coluna-cadastro-usuario-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Nome do Projeto</div>
                                    </Form.Label>
                                    {revelarSelectGitProjeto === false &&
                                        <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectGitProjeto(!revelarSelectGitProjeto)}>
                                            <div className="fonte-cor-1 label-campo">{gitProjetoNome}</div>
                                            <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    {revelarSelectGitProjeto === true &&
                                        <SelectGenerico
                                            paramRota={"git_projeto"}
                                            paramCampo1={null}
                                            paramCampo2={null}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdGitProjeto}
                                            setDescricao1={setGitProjetoNome}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectGitProjeto}
                                            placeholder={gitProjetoNome}
                                            sufixo={''}
                                        >
                                        </SelectGenerico>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                {cdGitProjeto &&
                <>
                    <Form.Row>
                        <Col>
                            <div className="grupo-cadastro-usuario-1">
                                <div className="coluna-cadastro-usuario-1">
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Nome da tag inicial</div>
                                        </Form.Label>
                                        {revelarSelectGitTagInicial === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectGitTagInicial(!revelarSelectGitTagInicial)}>
                                                <div className="fonte-cor-1 label-campo">{dsGitTagNomeInicial}</div>
                                                <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectGitTagInicial === true &&
                                            <SelectGenerico
                                                paramRota={"git_tag"}
                                                paramCampo1={cdGitProjeto}
                                                paramCampo2={null}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                setCodigo={setCdGitTagInicial}
                                                setDescricao1={setDsGitTagNomeInicial}
                                                setDescricao2={setDummy}
                                                setDescricao3={setDummy}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectGitTagInicial}
                                                placeholder={dsGitTagNomeInicial}
                                                sufixo={''}
                                            >
                                            </SelectGenerico>
                                        }
                                    </Form.Group>
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <div className="grupo-cadastro-usuario-1">
                                <div className="coluna-cadastro-usuario-1">
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Nome da tag final</div>
                                        </Form.Label>
                                        {revelarSelectGitTagFinal === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectGitTagFinal(!revelarSelectGitTagFinal)}>
                                                <div className="fonte-cor-1 label-campo">{dsGitTagNomeFinal}</div>
                                                <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        {revelarSelectGitTagFinal === true &&
                                            <SelectGenerico
                                                paramRota={"git_tag"}
                                                paramCampo1={cdGitProjeto}
                                                paramCampo2={null}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                setCodigo={setCdGitTagFinal}
                                                setDescricao1={setDsGitTagNomeFinal}
                                                setDescricao2={setDummy}
                                                setDescricao3={setDummy}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectGitTagFinal}
                                                placeholder={dsGitTagNomeFinal}
                                                sufixo={''}
                                            >
                                            </SelectGenerico>
                                        }
                                    </Form.Group>
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                </>
                }
                <Form.Group>
                    <Form.Label>
                        <div className="fonte-cor-1 label-campo">Nome da release</div>
                    </Form.Label>
                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Nome da release</Tooltip>}>
                        <Form.Control autocomplete="off" name="dsGitReleaseNome" value={dsGitReleaseNome} className="campo-texto-cor-3 campo-texto" type="text" placeholder="Digite o nome da release" onChange={e => setDsGitReleaseNome(e.target.value)} 
                        ref={register({validate: {minimo: value => value.length > 1}})} maxlength="100">
                        </Form.Control>
                    </OverlayTrigger>
                    {errors.dsGitReleaseNome && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <div className="fonte-cor-1 label-campo">Descrição da Release</div>
                    </Form.Label>
                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Descrição da Release</Tooltip>}>
                        <Form.Control autocomplete="off" name="dsGitReleaseNome" value={dsGitReleaseDescricao} className="campo-texto-cor-3 campo-texto" type="text" placeholder="Digite o nome da release" onChange={e => setDsGitReleaseDescricao(e.target.value)} 
                        ref={register({validate: {minimo: value => value.length > 1}})} maxlength="100">
                        </Form.Control>
                    </OverlayTrigger>
                    {errors.dsGitReleaseDescricao && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>}
                </Form.Group>
                <Form.Row>
                    <Col>
                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Criar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Release criado com sucesso!"}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}