const ESTADO_INICIAL = {
    dashboard: {}
}

export const migracaoPlanoReducer = (state = ESTADO_INICIAL, action, grafico) => {
    if(action.type === 'DADOS_DASHBOARD_MIGRACAO_PLANO') {
        return {
            ...state,
            dashboard: action.parametro
        };
    }
    else if(action.type === 'GRAFICO_ESPECIFICO_MIGRACAO_PLANO') {
        const novoState = {
            ...state,
            dashboard: {
                ...state.dashboard,
                ...action.parametro,
            }
        };

        return novoState
    }
    else{
        return state
    }
}