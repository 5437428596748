//IMPORTAÇÕES
import React, { useEffect, useRef } from "react";
import { separadorLinhaBusca } from "../redux/actions/index.js";
import { Form } from "react-bootstrap";
import { api } from "../conexoes/api";
import decryptJWT from "../utils/decryptJWT";
import { useDispatch, useSelector } from "react-redux";
import { useGlobal } from "../stores/useGlobal.js";

export default function DropFiltros(props) {
  const { setSeparadorLinhaBusca: setSeparadorLinhaBuscaZustand } = useGlobal();

  const modal = useRef();
  const despacho = useDispatch();

  let separadorLinha = useSelector(
    (state) => state.manejadorState.separadorLinha
  );

  const handleClickFora = (e) => {
    if (!modal.current.contains(e.target)) {
      props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha);
    }
  };

  useEffect(() => {
    async function pegarSeparador() {
      const separador = await api
        .get(
          "/m1/consultar/preferencias?cd_pav_usuario=" + decryptJWT("codigo")
        )
        .then(function (resposta) {
          return resposta.data.dados[0];
        })
        .catch(function (erro) {
          throw erro.stack;
        });

      setSeparadorLinhaBuscaZustand(separador.busca_separador);
      despacho(separadorLinhaBusca(separador.busca_separador));
    }

    pegarSeparador();

    document.addEventListener("mousedown", handleClickFora);
    return () => document.removeEventListener("mousedown", handleClickFora);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function alterarSeparador(separador) {
    await api
      .post("/m1/alterar/preferencias", {
        dados: {
          busca_separador: separador,
        },
        condicao: {
          cd_pav_usuario: decryptJWT("codigo"),
        },
      })
      .then(function (resposta) {
        return resposta.data.dados;
      })
      .catch(function (erro) {
        throw erro.stack;
      });

    setSeparadorLinhaBuscaZustand(separador);
    despacho(separadorLinhaBusca(separador));
  }

  function handlerCheck(nome) {
    if (separadorLinha != "") {
      if (separadorLinha == nome) {
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <Form ref={modal} className="drop-separador-linha-container">
      <div className="campo-select-triangulo-cor-2 triangulo-separador-linha-simcard-modal"></div>
      <div className="drop-acoes-corpo fundo-cor-7">
        <label
          className="label-radio-separador-linha fonte-cor-5"
          onClick={() => {
            alterarSeparador("|");
            props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha);
          }}
        >
          | (Pipe)
          <input
            defaultChecked={handlerCheck("|")}
            type="radio"
            id="Pipe"
            name="SEPARADOR-LINHA"
          />
          <span className="checkbox-filtros campo-texto-cor-3"></span>
        </label>
        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
        <label
          className="label-radio-separador-linha fonte-cor-5"
          onClick={() => {
            alterarSeparador("enter");
            props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha);
          }}
        >
          \n (Enter)
          <input
            defaultChecked={handlerCheck("enter")}
            type="radio"
            id="Enter"
            name="SEPARADOR-LINHA"
          />
          <span className="checkbox-filtros campo-texto-cor-3"></span>
        </label>
        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
        <label
          className="label-radio-separador-linha fonte-cor-5"
          onClick={() => {
            alterarSeparador(",");
            props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha);
          }}
        >
          , (Virgula)
          <input
            defaultChecked={handlerCheck(",")}
            type="radio"
            id="Virgula"
            name="SEPARADOR-LINHA"
          />
          <span className="checkbox-filtros campo-texto-cor-3"></span>
        </label>
        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
        <label
          className="label-radio-separador-linha fonte-cor-5"
          onClick={() => {
            alterarSeparador(";");
            props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha);
          }}
        >
          ; (Ponto e virgula)
          <input
            defaultChecked={handlerCheck(";")}
            type="radio"
            id="Pontovirgula"
            name="SEPARADOR-LINHA"
          />
          <span className="checkbox-filtros campo-texto-cor-3"></span>
        </label>
        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
        <label
          className="label-radio-separador-linha fonte-cor-5"
          onClick={() => {
            alterarSeparador("espaco");
            props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha);
          }}
        >
          Espaço
          <input
            defaultChecked={handlerCheck("espaco")}
            type="radio"
            id="espaco"
            name="SEPARADOR-LINHA"
          />
          <span className="checkbox-filtros campo-texto-cor-3"></span>
        </label>
      </div>
    </Form>
  );
}
