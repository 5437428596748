const ESTADO_INICIAL = {
    revelarNovaRegra: false,
    revelarEditarRegra: false,
    revelarExcluirRegra: false,
    revelarHabilitarRegra: false,
    revelarDesabilitarRegra: false
};

export const automacaoReducer = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'NOVA_REGRA_AUTOMACAO') {
        return {
            ...state, 
            revelarNovaRegra: action.parametro
        }
    }
    else if (action.type === 'EDITAR_REGRA_AUTOMACAO') {
        return {
            ...state, 
            revelarEditarRegra: action.parametro
        }
    }
    else if (action.type === "EXCLUIR_REGRA_AUTOMACAO") {
        return {
            ...state,
            revelarExcluirRegra: action.parametro
        }
    }
    else if (action.type === "HABILITAR_REGRA_AUTOMACAO") {
        return {
            ...state,
            revelarHabilitarRegra: action.parametro
        }
    }
    else if (action.type === "DESABILITAR_REGRA_AUTOMACAO") {
        return {
            ...state,
            revelarDesabilitarRegra: action.parametro
        }
    }
    else {
        return state;
    }
}