//IMPORTAÇÕES
import React, { useEffect, useRef, useState } from 'react';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Col, Form } from 'react-bootstrap';
//ICONES
import {elipseVerde, fecharJanela, setaDropdown} from '../../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
import { revelarEditarRegra } from "../../../redux/actions/index";
//COMPONENTES
import MensagemSucesso from "../../componentesModais/mensagens/mensagemSucesso"
import MensagemErro from "../../componentesModais/mensagens/mensagemErro"
import SelectRegraAutomacao from '../../componentesModais/selects/selectRegraAutomacao'
import SelectParametro from '../../componentesModais/selects/selectParametro'
import SelectNivelImportancia from '../../componentesModais/selects/selectNivelImportancia'
import SelectUsuarioAutomacao from '../../componentesModais/selects/selectUsuarioAutomacao'
import decryptJWT from '../../../utils/decryptJWT'
import SelectGenericoCheckbox from '../../componentesModais/selects/selectGenericoCheckbox';
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function EditarRegra(props) {
    //HOOKS VALIDAÇÂO
    const { handleSubmit } = useForm();
    //HOOKS PRINCIPAIS
    const [cdAcao, setCdAcao] = useState(props.cdAcao) // SWITCHES
    const [cdRegraAcao, setCdRegraAcao] = useState(props.cdRegraAcao)
    const [flAtivoRegraAcao, setFlAtivoRegraAcao] = useState(props.flAtivoRegraAcao)
    const [arrayCdUsuario, setArrayCdUsuario] = useState(props.cdUsuario)
    const [dsParametro, setDsParametro] = useState(props.dsParametro)
    const [cdAutEvento, setCdAutEvento] = useState(props.cdAutEvento)
    const [dsAutEvento, setDsAutEvento] = useState(props.dsAutEvento)
    const [cdAutEventoParametro, setCdAutEventoParametro] = useState(props.cdAutEventoParametro)
    const [cdAutEventoParametroValor, setCdAutEventoParametroValor] = useState(props.cdAutEventoParametroValor)
    const [dsValor, setDsValor] = useState(props.dsValor)
    const [cdAutRegra, setCdAutRegra] = useState(props.cdAutRegra)
    const [cdAutRegraNivel, setCdAutRegraNivel] = useState(props.cdAutRegraNivel)
    const [cdAutRegraParametroValor, setCdAutRegraParametroValor] = useState(props.cdAutRegraParametroValor)
    const [cdAutRegraUsuario, setCdAutRegraUsuario] = useState(props.cdAutRegraUsuario)
    const [flRegraAutomacao, setFlRegraAutomacao] = useState(props.flParametro)
    const [dsNivelImportancia, setDsNivelImportancia] = useState()
    const [renderizar, setRenderizar] = useState(true)
    const [dsUsuarioAutomacao, setDsUsuarioAutomacao] = useState()
    const [arrayDsUsuarioAutomacao, setArrayDsUsuarioAutomacao] = useState([])
    const [formaEnvioEmail, setFormaEnvioEmail] = useState()
    const [formaEnvioNotificacao, setFormaEnvioNotificacao] = useState()
    const [formaEnvioPlataforma, setFormaEnvioPlataforma] = useState()
    const [perfilPrecedencia, setPerfilPrecedencia] = useState(0)
    const [arrayDummy, setArrayDummy] = useState()
    //HOOKS SELECTS
    const [revelarSelectRegraAutomacao, setRevelarSelectRegraAutomacao] = useState(false)
    const [revelarSelectParametro, setRevelarSelectParametro] = useState(false)
    const [revelarSelectNivelImportancia, setRevelarSelectNivelImportancia] = useState(false)
    const [revelarSelectUsuarioAutomacao, setRevelarSelectUsuarioAutomacao] = useState(false)
    //HOOKS MENSAGENS
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();
    const [travarParametro, setTravarParametro] = useState(false)
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(()=>{
        if(arrayDsUsuarioAutomacao.length > 0){
            setDsUsuarioAutomacao(arrayDsUsuarioAutomacao[0])
        }
    },[arrayDsUsuarioAutomacao[0]])

    async function handleCancelar() {
        ColetaClickstream(1257) // 'dropAcoesRevelarEditarRegraCancelar'
        props.setRenderizar(props.renderizar+1)
        setRevelarMensagemErro(false)
        setRevelarMensagemSucesso(false)
        despacho(revelarEditarRegra(false))
    }

    async function handleSalvar() {
        ColetaClickstream(1258) // 'dropAcoesRevelarEditarRegraEditar'
        setSpinAtivo(true)
        let arrayCdAutRegraAcao = []
        cdAcao.map((item, i) => {
            arrayCdAutRegraAcao.push({
                "cd_aut_acao": cdAcao[i],
                "cd_aut_regra_acao": cdRegraAcao[i],
                "FL_ATIVO_REGRA_ACAO": flAtivoRegraAcao[i]
            })
        })
        
        let arrayCdPavUsuario = []
        if(arrayCdUsuario.length > cdAutRegraUsuario.length){
            arrayCdUsuario.map((item, i) => {
                arrayCdPavUsuario.push({
                    "cd_pav_usuario": arrayCdUsuario[i],
                    "cd_aut_regra_usuario": cdAutRegraUsuario[i]
                })
            })
        }else{
            cdAutRegraUsuario.map((item, i) => {
                arrayCdPavUsuario.push({
                    "cd_pav_usuario": arrayCdUsuario[i],
                    "cd_aut_regra_usuario": cdAutRegraUsuario[i]
                })
            })
        }
        
        await api.post('/m1/alterar/aut_regra', {
            "cd_aut_evento":cdAutEvento,
            "cd_aut_regra_parametro_valor": cdAutRegraParametroValor,
            "cd_aut_regra_nivel":cdAutRegraNivel,
            "cd_aut_regra":cdAutRegra,
            "cd_aut_regra_acao": arrayCdAutRegraAcao,
            "cd_aut_evento_parametro":cdAutEventoParametro,
            "cd_aut_evento_parametro_valor":cdAutEventoParametroValor,
            "ds_valor":dsValor,
            "cd_pav_usuario_alerta": arrayCdPavUsuario
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }
    
    useEffect(() => {
        if(arrayCdUsuario.length > 0){
            setDsUsuarioAutomacao((arrayCdUsuario.length) + " Selecionados")
        }else{
            setDsUsuarioAutomacao("Selecione")
        }
    }, [revelarSelectUsuarioAutomacao])

    useEffect(() => {
        let auxProps = props.cdUsuario.length
        setDsUsuarioAutomacao(auxProps + " Selecionados")
        handleCarregarSwitches(props.cdAcao)

        hendlerPerfil()

    }, [])

    async function hendlerPerfil(){
        await api.get('/m1/consultar/perfil?cd_pav_perfil='+ decryptJWT('perfil'))
        .then((response)=>{
            if(response.data.status == 200 && response.data.dados.length > 0){
                setPerfilPrecedencia(response.data.dados[0].n0_precedencia)
            }
        })
    } 


    function handleCarregarSwitches(){
        props.cdAcao.map((item, i) => {
            if(item === "6"){
                if(props.flAtivoRegraAcao[i] === "1"){
                    setFormaEnvioEmail(true)
                }
            }
            if(item === "5"){
                if(props.flAtivoRegraAcao[i] === "1"){
                    setFormaEnvioNotificacao(true)
                }
            }
            if(item === "3"){
                if(props.flAtivoRegraAcao[i] === "1"){
                    setFormaEnvioPlataforma(true)
                }
            }
        });
    }

    function handleAlterarSwitches(cdSwitch, flAtivo){
        let auxAtivo = "0"
        if(flAtivo === true){
            auxAtivo = "1"
        }
        cdAcao.map((item, i) => {
            if(item == cdSwitch){
                flAtivoRegraAcao[i] = auxAtivo
            }
        })
    }

    useEffect(() => {
        function handleMudarFlagRegra(){
            if(travarParametro === false){
                setDsParametro(props.dsParametro)
                setTravarParametro(true)
            }else{
                setDsParametro("Selecione")
                setCdAutEventoParametro("REMOVIDO")
                setCdAutEventoParametroValor("REMOVIDO")
                setDsValor("REMOVIDO")
            }
        }
        handleMudarFlagRegra()
    }, [cdAutEvento])

    useEffect(() => {
        function handleCriarNivelSelecionado(){
            if(cdAutRegraNivel === 3){
                setDsNivelImportancia(
                    <div className="select-nivel-importancia-container-esfera"><div className="select-nivel-importancia-esfera-verde"/><p className="fonte-cor-1 nao-selecionavel">Normal</p></div>
                )
            }else if(cdAutRegraNivel === 2){
                setDsNivelImportancia(
                    <div className="select-nivel-importancia-container-esfera"><div className="select-nivel-importancia-esfera-amarela"/><p className="fonte-cor-1 nao-selecionavel">Atenção</p></div>
                )
            }else if(cdAutRegraNivel ===1){
                setDsNivelImportancia(
                    <div className="select-nivel-importancia-container-esfera"><div className="select-nivel-importancia-esfera-vermelha"/><p className="fonte-cor-1 nao-selecionavel">Urgente</p></div>
                )
            }
        }
        handleCriarNivelSelecionado()
    }, [cdAutRegraNivel])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-nova-editar-regra-automacao fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Editar regra</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-nova-editar-regra-automacao-1">
                            <div className="coluna-nova-editar-regra-automacao-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Regra</div>
                                    </Form.Label>
                                    { revelarSelectRegraAutomacao === false &&
                                        <Button name="cdRegraAutomacao" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectRegraAutomacao(!revelarSelectRegraAutomacao)}>
                                            <div className="fonte-cor-1 label-campo">{dsAutEvento}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectRegraAutomacao === true &&
                                        <SelectRegraAutomacao
                                            setRevelarSelectRegraAutomacao={setRevelarSelectRegraAutomacao} //Hook para fechar a modal
                                            setCdRegraAutomacao={setCdAutEvento} //Código do RegraAutomacao
                                            setDsRegraAutomacao={setDsAutEvento} //Nome do RegraAutomacao
                                            setFlRegraAutomacao={setFlRegraAutomacao}
                                            dsRegraAutomacao={dsAutEvento} 
                                        >
                                        </SelectRegraAutomacao>
                                    }
                                </Form.Group>
                            </div>
                            <div className="coluna-nova-editar-regra-automacao-2">
                                {flRegraAutomacao != 0 &&
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Parâmetro</div>
                                        </Form.Label>
                                        { revelarSelectParametro === false &&
                                            <Button name="cdParametro" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectParametro(!revelarSelectParametro)}>
                                                <div className="fonte-cor-1 label-campo">{dsParametro}</div>
                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { revelarSelectParametro === true &&
                                            <SelectParametro
                                                setRevelarSelectParametro={setRevelarSelectParametro} //Hook para fechar a modal
                                                setDsParametro={setDsParametro} //Nome do Parametro
                                                dsParametro={dsParametro}
                                                cdAutEvento={cdAutEvento}
                                                setDsValor={setDsValor}
                                                setCdAutEventoParametro={setCdAutEventoParametro}
                                                setCdAutEventoParametroValor={setCdAutEventoParametroValor}
                                            >
                                            </SelectParametro>
                                        }
                                    </Form.Group>
                                }
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="campo-select-divisor-cor-2 nova-editar-regra-automacao-divisor"></div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-nova-editar-regra-automacao-1">
                            <div className="coluna-nova-editar-regra-automacao-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Nível de importância</div>
                                    </Form.Label>
                                    { revelarSelectNivelImportancia === false &&
                                        <Button name="cdAutRegraNivel" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectNivelImportancia(!revelarSelectNivelImportancia)}>
                                            <div className="fonte-cor-1 label-campo">{dsNivelImportancia}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectNivelImportancia === true &&
                                        <SelectNivelImportancia
                                            setRevelarSelectNivelImportancia={setRevelarSelectNivelImportancia} //Hook para fechar a modal
                                            setCdNivelImportancia={setCdAutRegraNivel} //Código do NivelImportancia
                                            dsNivelImportancia={dsNivelImportancia}
                                        >
                                        </SelectNivelImportancia>
                                    }
                                </Form.Group>
                            </div>
                            <div className="coluna-nova-editar-regra-automacao-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Usuários</div>
                                    </Form.Label>
                                    { revelarSelectUsuarioAutomacao === false &&
                                        <Button name="cdUsuarioAutomacao" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectUsuarioAutomacao(!revelarSelectUsuarioAutomacao)}>
                                            <div className="fonte-cor-1 label-campo">{dsUsuarioAutomacao}</div>
                                            <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    { revelarSelectUsuarioAutomacao === true &&
                                        <SelectGenericoCheckbox
                                            paramRota={"usuario_relacionamento"}
                                            paramCampo1={perfilPrecedencia}
                                            paramCampo2={decryptJWT('codigoCliente')}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            arrayCodigo={arrayCdUsuario}
                                            arrayDescricao1={arrayDsUsuarioAutomacao}
                                            arrayDescricao2={arrayDummy}
                                            arrayDescricao3={arrayDummy}
                                            arrayDescricao4={arrayDummy}
                                            setRevelarSelect={setRevelarSelectUsuarioAutomacao}
                                            placeholder={dsUsuarioAutomacao}
                                            sufixo={""}
                                        />
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="campo-select-divisor-cor-2 nova-editar-regra-automacao-divisor"></div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Label>
                            <div className="fonte-cor-1 label-campo">Forma de envio</div>
                        </Form.Label>
                        <div className="container-switch-nova-editar-regra-automacao">
                            <div class="flipswitch">
                                <input type="checkbox" defaultChecked={formaEnvioEmail} name="flipswitch" class="flipswitch-cb" id="switchEmail" onClick={e => handleAlterarSwitches("6", e.target.checked)}/>
                                <label class="flipswitch-label" for="switchEmail">
                                    <div class="flipswitch-inner flipswitch-cor-1"></div>
                                    <div class="flipswitch-switch"></div>
                                </label>
                            </div>
                            <div className="fonte-cor-1 label-campo label-termos-nova-editar-regra-automacao"> E-mail</div>
                        </div>
                    </Col>
                    <Col>
                        <div className="container-switch-nova-editar-regra-automacao-2">
                            <div class="flipswitch">
                                <input type="checkbox" defaultChecked={formaEnvioNotificacao} name="flipswitch" class="flipswitch-cb" id="switchNotificacao" onClick={e => handleAlterarSwitches("5", e.target.checked)}/>
                                <label class="flipswitch-label" for="switchNotificacao">
                                    <div class="flipswitch-inner flipswitch-cor-1"></div>
                                    <div class="flipswitch-switch"></div>
                                </label>
                            </div>
                            <div className="fonte-cor-1 label-campo label-termos-nova-editar-regra-automacao"> Notificação</div>
                        </div>
                    </Col>
                    <Col>
                        <div className="container-switch-nova-editar-regra-automacao-2">
                            <div class="flipswitch">
                                <input type="checkbox" defaultChecked={formaEnvioPlataforma} name="flipswitch" class="flipswitch-cb" id="swithPlataforma" onClick={e => handleAlterarSwitches("3", e.target.checked)}/>
                                <label class="flipswitch-label" for="swithPlataforma">
                                    <div class="flipswitch-inner flipswitch-cor-1"></div>
                                    <div class="flipswitch-switch"></div>
                                </label>
                            </div>
                            <div className="fonte-cor-1 label-campo label-termos-nova-editar-regra-automacao"> Plataforma</div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="campo-select-divisor-cor-2 nova-editar-regra-automacao-divisor"></div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Editar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Regra editada"}
                            mensagemBotao={"Finalizar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={mensagemErro}
                            mensagemParagrafo1={"Algo deu errado!"}
                            mensagemParagrafo2={"Por favor, tente mais tarde."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}