import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem, maximoTagsExibidas} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'


function Filtros(props) {
    const[nome,setNome] = useState('')
    const[id,setId] = useState('')
    const[descricao,setDescricao] = useState('')
    const[rota,setRota] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []


    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setNome(filtros.ds_pav_elemento)
            setId(filtros.cd_pav_elemento)
            setDescricao(filtros.ds_descricao)
            setRota(filtros.ds_rota)
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1329) /*pavElementoTabelaGridDropFiltrosFiltrar*/
        if(id !== '' && id)arrayFiltrosSimcard.push("Id: "+id+"?cd_pav_elemento")
        if(nome !== '' && nome)arrayFiltrosSimcard.push("Nome: "+nome+"?ds_pav_elemento")
        if(descricao !== '' && descricao)arrayFiltrosSimcard.push("Descrição: "+descricao+"?ds_descricao")
        if(rota !== '' && rota)arrayFiltrosSimcard.push("Rota: "+rota+"?ds_rota")

        despacho(maximoTagsExibidas(props.setRevelarDropFiltros))
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }


    
    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(nome == '') verificador.push(1)
            if(descricao =='') verificador.push(1)
            if(id =='') verificador.push(1)
            if(rota =='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 4) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setDescricao('')
            setNome('')
            setId('')
            setRota('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [nome,descricao,rota,id,props.foiLimpado]);

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-id-elemento"){
                setId(valor)
            }  
        }
    }

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
                <div className="campo-select-triangulo-menor-cor-2"></div>
                <div className="drop-acoes-corpo fundo-cor-7">
                    <div className="drop-acoes-lista-menu">
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-id","imagem-filtro-id"); ColetaClickstream(1325) /*pavElementoTabelaGridDropFiltrosIDdoElemento*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">ID do elemento </Form.Label>
                            <img id={"imagem-filtro-id"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div id={"filtro-id"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <Form.Control
                                name="cd_pav_elemento"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="45"
                                value={id}
                                placeholder="ID do elemento"
                                onChange={(e) => validarNumero("campo-texto-id-elemento", e.target.value)}
                            />
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        </div>
                        {/* ------------------- */}
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-elemento","imagem-filtro-elemento"); ColetaClickstream(1326) /*pavElementoTabelaGridDropFiltrosElemento*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Elemento </Form.Label>
                            <img id={"imagem-filtro-elemento"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div id={"filtro-elemento"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <Form.Control
                                name="ds_pav_elemento"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="45"
                                value={nome}
                                placeholder="Nome do elemento"
                                onChange={(e) => setNome(e.target.value)}
                            />
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        </div>
                        {/* ------------------- */}
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-descricao","imagem-filtro-descricao"); ColetaClickstream(1327) /*pavElementoTabelaGridDropFiltrosDescricao*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Descrição </Form.Label>
                            <img id={"imagem-filtro-descricao"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div id={"filtro-descricao"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <Form.Control
                                name="ds_descricao"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="45"
                                value={descricao}
                                placeholder="Descrição do elemento"
                                onChange={(e) => setDescricao(e.target.value)}
                            />
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        </div>
                        {/* ------------------- */}
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-rota","imagem-filtro-rota"); ColetaClickstream(1328) /*pavElementoTabelaGridDropFiltrosRota*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Rota </Form.Label>
                            <img id={"imagem-filtro-rota"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div id={"filtro-rota"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                            <Form.Control
                                name="ds_rota"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="45"
                                value={rota}
                                placeholder="Rota do elemento"
                                onChange={(e) => setRota(e.target.value)}
                            />
                        </div>
                        {/* ------------------- */}
                        <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                        <div className="botao-central-filtros">
                            <Form.Row>
                                <Col>
                                    <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                        Filtrar
                                    </Button>
                                    <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                        Limpar
                                    </Button>
                                </Col>
                            </Form.Row>
                        </div>
                    </div>
                </div>
            </Form>
    );
}

export default Filtros;
