import React, { useState, useEffect } from "react"; /*eslint-disable*/
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts"; /*eslint-disable*/

import { api_migracao } from "../../../../../conexoes/api";
import "./graficoConsumoCiclos.css";
import { getDetalhamentoGraficoBarra } from "../../cores";

export default function GraficoConsumoCiclos(props) {
  const [consumo, setConsumo] = useState([]);

  useEffect(() => {
    async function consumoMensal() {
      const { data } = await api_migracao.get(
        `/api/v2/migration/consumption?CdSimcard=${props.CdSimcard}`
      );
      setConsumo(data.consumo);
    }
    consumoMensal();
  }, []);

  function CustomTooltip({ payload, label, active }) {
    if (active && payload !== null && payload.length > 0) {
      const item = payload[0];
      const consumidoMb = parseFloat(`${item ? item.value : "00"}`).toFixed(2);

      const objPlano = item?.payload;
      const planoMb = getPlano(objPlano);
      const excedenteMb = objPlano?.ExcedenteMB?.toFixed(2);

      const Label = ({ children }) => (
        <p className="label-tooltip-grafico-ciclo-ultimo">{children}</p>
      );

      return (
        <div className="container-tooltip-grafico-ciclo-ultimo-migracaoPlano">
          {planoMb ? <Label>Plano: {planoMb} MB</Label> : null}
          <Label>Consumo: {consumidoMb} MB</Label>
          {excedenteMb && excedenteMb !== "0.00" ? (
            <Label>Excedente: {excedenteMb} MB</Label>
          ) : null}
        </div>
      );
    }
    return null;
  }

  function getPlano(item) {
    let planoMb = item?.PlanoMB || 0;
    return planoMb.toFixed(2);
  }

  const { corFundo, verde, vermelho, strokeColor } =
    getDetalhamentoGraficoBarra();

  return (
    <div
      className="migracaoPlano-container-grafico-ciclo-ultimo"
      style={{ width: (consumo.length / 6) * 100 + "%!important" }}
    >
      <ResponsiveContainer>
        <BarChart data={consumo}>
          <CartesianGrid stroke={strokeColor} strokeDasharray="0" />
          <XAxis stroke={strokeColor} dataKey="ciclo" />
          <YAxis stroke={strokeColor} dataKey="consumoMB" />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={<CustomTooltip />}
          />
          <Bar
            barSize={68}
            dataKey="consumoMB"
            fill={verde}
            background={{ fill: corFundo }}
            radius={(5, 0, 0, 5)}
          >
            {consumo.map((item_consumo, index) => {
              const consumoDentroDoLimite =
                item_consumo.consumoMB < getPlano(item_consumo);
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={consumoDentroDoLimite ? verde : vermelho}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
