import React, { useState, useEffect } from "react";/*eslint-disable*/
// import {api} from '../../conexoes/api';/*eslint-disable*/
import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";
// import { setaDropdown } from '../../utils/alternanciaIcones';
// import FiltroArquivo from './filtroArquivo'; 
import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'

export default function FiltroFebraban(props) {

    const [dsEmpresa, setDsEmpresa] = useState('')
    // const [renderizarFiltroArquivo, setRenderizarFiltroArquivo] = useState(false)
    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []
    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setDsEmpresa(filtros.EMPRESA)
        }
    }

    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    function handleFuncaoEstadosRedux(){
        if(dsEmpresa !== '' && dsEmpresa !== undefined)arrayFiltrosSimcard.push("Empresa: "+dsEmpresa+"?EMPRESA")
       
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(dsEmpresa == '') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 2) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setDsEmpresa("")
            props.setFoiLimpado(false)
        }
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [dsEmpresa,props.foiLimpado]);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }
    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                   {/* NÃO É PRA TER 'Filtro por arquivo' em Febraban ------------------- */}
                   {/* <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-por-arquivo","imagem-filtro-por-arquivo"); setRenderizarFiltroArquivo(true)}}> 
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Filtro por arquivo 3</Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-por-arquivo"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-por-arquivo"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        {renderizarFiltroArquivo &&
                            <div className="componente-modal-overlay">
                                <div className="componente-modal-filtro-arquivo-container">
                                    <FiltroArquivo
                                        renderizar={renderizarFiltroArquivo}
                                        setRenderizar={setRenderizarFiltroArquivo}
                                        handleFuncaoEstadosRedux={handleFuncaoEstadosRedux}
                                        enviarFiltros={props.enviarFiltros}
                                        arrayFiltrosSimcard={arrayFiltrosSimcard}
                                    />
                                </div>
                            </div>
                        }
                    </div> */}
                     {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}