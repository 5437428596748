import React, { useState, useEffect } from "react";/*eslint-disable*/
import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";
import { setaDropdown } from '../../utils/alternanciaIcones';
import Calendario from '../calendario/calendario'
import moment from 'moment';
import SelectGenerico from '../componentesModais/selects/selectGenerico'
import decryptJWT from '../../utils/decryptJWT'
import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'

export default function FiltrosLogAcesso(props) {
    //HOOKS DE DATAS BASICAS
    const [revelarSelectCliente, setRevelarSelectCliente] = useState(false)
    const [revelarSelectUsuario, setRevelarSelectUsuario] = useState(false)
    const [dummy, setDummy] = useState()
    const [dataMin, setDataMin] = useState(1990)
    const [dataMax, setDataMax] = useState(new Date())
    const [filtroCdCliente, setFiltroCdCliente] = useState('0')
    const [filtroDsCliente, setFiltroDsCliente] = useState('Selecione')
    const [filtroUsuario, setFiltroUsuario] = useState('Selecione')
    const [filtroDataInicio, setFiltroDataInicio] = useState('')
    const [filtroDataFim, setFiltroDataFim] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            if(filtros.DT_INICIO){
                let auxDataInicio = new Date(filtros.DT_INICIO)
                auxDataInicio.setDate(auxDataInicio.getDate()+1)
                setFiltroDataInicio(auxDataInicio)
            }
            if(filtros.DT_FIM){
                let auxDataFim = new Date(filtros.DT_FIM)
                auxDataFim.setDate(auxDataFim.getDate()+1)
                setFiltroDataFim(auxDataFim)
            }
            if(filtros.DS_USUARIO){
                setFiltroUsuario(filtros.DS_USUARIO)
            }
            if(filtros.CD_CLIENTE){
                setFiltroCdCliente(filtros.CD_CLIENTE)
            }
            if(filtros.DS_CLIENTE){
                setFiltroDsCliente(filtros.DS_CLIENTE)
            }
        }
    }

    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);
    
    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroDataInicio == '') verificador.push(1)
            if(filtroDataFim == '') verificador.push(1)
            if(filtroUsuario == 'Selecione') verificador.push(1)
            if(filtroCdCliente == '') verificador.push(1)
            if(filtroDsCliente == 'Selecione') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 5) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroDataInicio('')
            setFiltroDataFim('')
            setFiltroUsuario('Selecione')
            setFiltroCdCliente('')
            setFiltroDsCliente('Selecione')
            props.setFoiLimpado(false)
        }
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroDataInicio, filtroDataFim, filtroUsuario, filtroCdCliente, filtroDsCliente, props.foiLimpado]);

    function handleFuncaoEstadosRedux(){
        if(filtroDsCliente !== 'Selecione' && filtroDsCliente){
            arrayFiltrosSimcard.push("Cliente: "+filtroDsCliente+"?DS_CLIENTE")
        }
        if(filtroUsuario !== 'Selecione'){
            arrayFiltrosSimcard.push("Usuário: "+filtroUsuario+"?DS_USUARIO")
        }
        if(filtroDataInicio !== '' && filtroDataInicio && filtroDataFim !== '' && filtroDataFim ){
            arrayFiltrosSimcard.push("Data: Entre "+moment(filtroDataInicio).format("DD/MM/YYYY")+" e "+moment(filtroDataFim).format("DD/MM/YYYY")+"?DT_INICIO")
        }else if(filtroDataInicio !== '' && filtroDataInicio ){
            arrayFiltrosSimcard.push("A partir de: "+moment(filtroDataInicio).format("DD/MM/YYYY")+"?DT_INICIO")
        }else if(filtroDataFim !== '' && filtroDataFim ){
            arrayFiltrosSimcard.push("Até: "+moment(filtroDataFim).format("DD/MM/YYYY")+"?DT_FIM")
        }
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-cliente","imagem-filtro-cliente")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Cliente </Form.Label>
                        <img id={"imagem-filtro-cliente"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-cliente"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Group>
                            <Form.Control name="CD_CLIENTE" type="text" value={filtroCdCliente} className="d-none" onChange={(e)=> setFiltroCdCliente(e.target.value)}/>
                            <Form.Control name="DS_CLIENTE" type="text" value={filtroDsCliente} className="d-none" onChange={(e)=> setFiltroDsCliente(e.target.value)}/>
                            {revelarSelectCliente === false &&
                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                    <div className="fonte-cor-1 label-campo">{filtroDsCliente}</div>
                                    <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }

                            {revelarSelectCliente === true &&
                                <SelectGenerico
                                    paramRota={'cliente_relacionamento'}
                                    paramCampo1={decryptJWT('codigoCliente')}
                                    paramCampo2={null}
                                    paramCampo3={null}
                                    paramCampo4={null}
                                    setCodigo={setFiltroCdCliente}
                                    setDescricao1={setFiltroDsCliente}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectCliente}
                                    placeholder={filtroDsCliente}
                                    sufixo={''}
                                >
                                </SelectGenerico>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-nome","imagem-filtro-nome")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Usuario </Form.Label>
                        <img id={"imagem-filtro-nome"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-nome"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Group>
                            <Form.Control name="DS_USUARIO" type="text" value={filtroUsuario} className="d-none" onChange={(e)=> setFiltroUsuario(e.target.value)}/>
                            {revelarSelectUsuario === false &&
                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectUsuario(!revelarSelectUsuario)}>
                                    <div className="fonte-cor-1 label-campo">{filtroUsuario}</div>
                                    <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            }

                            {revelarSelectUsuario === true &&
                                <SelectGenerico
                                    paramRota={'email_usuario'}
                                    paramCampo1={null}
                                    paramCampo2={null}
                                    paramCampo3={null}
                                    paramCampo4={null}
                                    setCodigo={setDummy}
                                    setDescricao1={setFiltroUsuario}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectUsuario}
                                    placeholder={filtroUsuario}
                                    sufixo={''}
                                >
                                </SelectGenerico>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-vencimento","imagem-filtro-vencimento")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Data </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-vencimento"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-vencimento"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-selecione-calendario-filtros fonte-cor-1">Selecione o período:</label>
                        {/* Componente Calendário */}
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DT_INICIO" type="text" value={filtroDataInicio? moment(filtroDataInicio).format("YYYY-MM-DD") : filtroDataInicio} className="d-none"/>
                            <Calendario formato={"DD/MM/YYYY"} imputCustomisado='De:' data={filtroDataInicio} setData={setFiltroDataInicio} /* dataMax={dataMax} */ dataMin={dataMin}></Calendario>
                        </div>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control name="DT_FIM" type="text" value={filtroDataFim? moment(filtroDataFim).format("YYYY-MM-DD") : filtroDataFim} className="d-none"/>
                            <Calendario formato={"DD/MM/YYYY"} imputCustomisado='Até:' data={filtroDataFim} setData={setFiltroDataFim} /* dataMax={dataMax} */ dataMin={dataMin}></Calendario>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}
