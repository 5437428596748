//IMPORTAÇÕES
/*eslint-disable*/
import React, {useEffect, useRef, useState} from 'react'; /*eslint-disable*/
import {api, api_clientes} from '../../../../conexoes/api'; /*eslint-disable*/
import {useForm} from 'react-hook-form';
import {Button, Col, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {elipseVerde, fecharJanela, setaDropdown} from '../../../../utils/alternanciaIcones'
import { validarCnpj } from '../../../../utils/validarCpfCnpj.js'
//MODAIS
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import SelectEstadoCliente from '../../../componentesModais/selects/selectEstadoCliente'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import {useDispatch} from 'react-redux';
import '../../../../css/painelCadastros/cadCliente/estilo.css'
import {MascaraCep, MascaraCnpj, MascaraTelefone} from '../../../../utils/mascarasCampos'
import {revelarCadastroClienteEditar, revelarCadastroClienteNovo} from "../../../../redux/actions/index";
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {Step, StepLabel, Stepper} from "@mui/material";
import {Checkbox} from "@material-ui/core";
import {isClienteN2} from "../../../../utils/nivelCliente";

export default function CadastroCliente(props) {
    const modalEditar = props.tituloJanela === 'Editar';
    const modalCadastrar = props.tituloJanela === 'Cadastrar';
    
    let tituloModal = 'Cadastro de Cliente';
    if(modalEditar) {
        tituloModal = 'Editar Cliente';
    }

    const localTema = localStorage.getItem('tema')
    const temaEscuro = (localTema === 'escuro');

    const steps = ['Tipo de pessoa', 'Dados do cliente', 'Módulos de permissão', 'Confirmação']
    const stepsStyle = {
        "& .Mui-active": {
            "& .MuiStepIcon-root": {
                color: '#33CC99',
                fontSize: "1.5rem !important"
            },
        },
        "& .Mui-completed": {
            "& .MuiStepIcon-root": {
                color: '#33CC99',
                fontSize: "1.5rem !important"
            },
        },
        "& .Mui-disabled": {
            "& .MuiStepIcon-root": {
                fontSize: "1.5rem !important"
            }
        },
    }
    const stepLabelStyleEscuro = {
        "& .MuiStepLabel-label":{
            color: "#FFF !important",
            "& .Mui-active": {
                color: "#FFF !important"
            }
        }
    }
    const radioStyle = {
        color: '#33CC99 !important',
        '&.Mui-checked': {
            color: '#33CC99 !important',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '25px !important',
        },
    }

    const clienteN2 = isClienteN2()
    const {register, handleSubmit, errors} = useForm()
    const despacho = useDispatch()
    const modal = useRef()
    const [stepActive, setStepActive] = useState(0)/*eslint-disable*/
    const [modulos, setModulos] = useState([])/*eslint-disable*/
    const [spinAtivo, setSpinAtivo] = useState(true)
    const [erroForm, setErroForm] = useState('')
    const [cdPavCliente, setCdPavCliente] = useState(undefined)
    const [cdTipoPessoa, setCdTipoPessoa] = useState(undefined)
    const [dsNome, setDsNome] = useState(undefined)
    const [dsSobrenome, setDsSobrenome] = useState(undefined)
    const [dsRazaoSocial, setDsRazaoSocial] = useState(undefined)
    const [dsNomeFantasia, setDsNomeFantasia] = useState(undefined)
    const [dsCnpj, setDsCnpj] = useState(undefined)
    const [cnpjValido, setCnpjValido ] = useState(false)
    const [dsTelefone, setDsTelefone] = useState(undefined)
    const [dsEmail, setDsEmail] = useState(undefined)
    const [cdOperadoras, setCdOperadoras] = useState([])
    const [dsOperadoras, setDsOperadoras] = useState([])
    const [phOperadoras, setPhOperadoras] = useState('Selecione')
    const [dsLogradouro, setDsLogradouro] = useState(undefined)
    const [dsNumero, setDsNumero] = useState(undefined)
    const [dsCep, setDsCep] = useState(undefined)
    const [dsBairro, setDsBairro] = useState(undefined)
    const [dsCidade, setDsCidade] = useState(undefined)
    const [cdEstado, setCdEstado] = useState(undefined)
    const [dsEstado, setDsEstado] = useState('Selecione')
    const [dsInformacoesAdicionais, setDsInformacoesAdicionais] = useState(undefined)
    const [clienteTipoOptions, setClienteTipoOptions] = useState([])
    const [tipoCliente, setTipoCliente] = useState(props.tipoCliente || [])
    const [tipoChanged, setTipoChanged] = useState(false)

    const [revelarSelectEstado, setRevelarSelectEstado] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)

    // const [elementoAtivadoPrincipal, setElementoAtivadoPrincipal] = useState(1)
    // const [tabAtivadoPrincipal, setTabAtivadoPrincipal] = useState(1)
    const [dummy, setDummy] = useState()
    const [dsMensagemSucesso, setDsMensagemSucesso] = useState('Cadastro efetuado com sucesso.')
    const [dsMensagemErro, setDsMensagemErro] = useState('Algo deu errado, tente novamente. Se o erro persistir entre em contato com o suporte.')
    const isN3 = props.isN3;
    
    const pessoaFisica = cdTipoPessoa == 1;
    const pessoaJuridica = cdTipoPessoa == 2;
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    }

    const changeTipoClienteToPortal = () => setTipoCliente({id: 1, label: 'Portal'})

    const isTelaMeusClientes = () => window.location.href.includes('/veye/portal/cliente');

    useEffect(() => {
        if(isTelaMeusClientes()){
            changeTipoClienteToPortal()
            setTipoChanged(true);
        }
        setSpinAtivo(true)
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function setNomes(){
        if(pessoaFisica){
            const {ds_nome} = props.row
            if(!ds_nome) return;
            const idxEspacoSobrenome = ds_nome?.lastIndexOf(' ')

            setDsNome(ds_nome.substring(0, idxEspacoSobrenome))
            setDsSobrenome(ds_nome.substring(idxEspacoSobrenome+1)) 

        } else if( pessoaJuridica) {
            setDsRazaoSocial(props.row.ds_nome)
            setDsNomeFantasia(props.row.ds_nome_fantasia)
        }
    }

    useEffect(() => {
        async function handlerCarregarDados(){

            setNomes()
            setCdEstado(props.row.cd_pav_uf)
            setCdTipoPessoa(props.row.cd_pav_tipo_pessoa.toString())
            setDsEmail(props.row.ds_email)
            setDsCidade(props.row.cidade)
            setDsBairro(props.row.bairro)
            setDsLogradouro(props.row.logradouro)
            setDsCep(props.row.cep)
            setDsNumero(props.row.numero)
            setDsEstado(props.row.uf)
            setDsInformacoesAdicionais(props.row.complemento)
            setCdPavCliente(props.cdCliente)
            handleTelefone(props.row.ds_telefone)

            const cnpj = props.row.ds_cnpj;

            if(cnpj) {
                handleCnpj(cnpj)
                handleConsultarCnpj(cnpj)
            }
        }

        if (modalEditar && props.row !== undefined) {
            handlerCarregarDados()
        }
    }, [cdTipoPessoa]);

    function transformarObjeto(dados) {
        let novoObjeto = [];

        dados.forEach(item => {
            let novoItem = {
                id: item.cd_cliente_tipo,
                label: item.ds_nome
            };
            if(novoItem.label == 'Portal'){
                novoItem.label = 'Cliente'
            }
            novoObjeto.push(novoItem);
        });

        return novoObjeto;
    }

    useEffect(() => {
        async function fetchModuloData() {
            setSpinAtivo(true);
            let listaModulosCliente = []
            let getModulo = await api.get('/m1/consultar/modulo')
            let getClienteTipo = await api.get('/m1/consultar/cliente_tipo')
            getClienteTipo = transformarObjeto(getClienteTipo.data.dados);
            setClienteTipoOptions(getClienteTipo);
            setSpinAtivo(false)

            getModulo = getModulo.data.dados.filter(modulo => modulo.ds_pav_modulo !== 'Painel Administrativo' && modulo.ds_pav_modulo !== 'Virtueyes');

            if (clienteN2 && tipoCliente?.id == 1) {
                getModulo = getModulo.filter(modulo => modulo.ds_pav_modulo === 'Dashboard' || modulo.ds_pav_modulo === 'Dispositivos' || modulo.ds_pav_modulo === 'Relatórios' || modulo.ds_pav_modulo === 'Usuários' || modulo.ds_pav_modulo === 'Outros');
            }

            getModulo.map((modulo, moduloIndex) => {
                modulo.checked = false
            })
            if (modalEditar) {
                try {
                    listaModulosCliente = await api.get('/m1/consultar/modulo/modulo_cliente?clienteId=' + props.cdCliente)
                    if (listaModulosCliente.data.dados.length) {
                        listaModulosCliente.data.dados.map((moduloCliente, moduloClienteIndex) => {
                            getModulo.map((modulo, moduloIndex) => {
                                if (moduloCliente.cd_pav_modulo === modulo.cd_pav_modulo) {
                                    modulo.checked = true
                                }
                            })
                        })
                    }

                } catch (e) {
                    console.error('erro ao buscar modulos do cliente', e)
                }
            }


            getModulo.sort(function (a, b){
                let moduloA = a.ds_pav_modulo.toUpperCase()
                let moduloB = b.ds_pav_modulo.toUpperCase()

                if(moduloA < moduloB){
                    return -1
                }
                if(moduloA > moduloB){
                    return 1
                }
                return 0
            })
            setModulos(getModulo)
        }

        fetchModuloData();
    }, [tipoChanged]);

    async function handleCancelar() {
        ColetaClickstream(1433) // dropAcoesrevelarCadastroClienteNovoCancelar
        setSpinAtivo(false)
        if (modalCadastrar) {
            despacho(revelarCadastroClienteNovo(false))
        } else if (modalEditar) {
            despacho(revelarCadastroClienteEditar(false))
        }

    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if (confirmacao) {
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
        }
        if (confirmacao === true) {
            //ALGUMA FUNÇÃO
        }
    }

    // function handerAlterarTabPrincipal(k) {
    //     setElementoAtivadoPrincipal(k)
    //     setTabAtivadoPrincipal(k)
    // }


    function handleCnpj(valor) {
        setDsCnpj(MascaraCnpj(valor))
    }

    function handleTelefone(valor) {
        setDsTelefone(MascaraTelefone(valor))
    }

    function handleCep(valor) {
        setDsCep(MascaraCep(valor))
    }

    useEffect(() => {
        let placeholderAux = false
        cdOperadoras.forEach(function (item, index) {
            if (item === -1) {
                placeholderAux = true
            }
        })
        if (placeholderAux === true) {
            setPhOperadoras(cdOperadoras.length - 1 + " Selecionados")
        } else if (cdOperadoras.length === 1) {
            setPhOperadoras(dsOperadoras[0])
        } else if (cdOperadoras.length === 0) {
            setPhOperadoras("Selecione")
        } else {
            setPhOperadoras(cdOperadoras.length + " Selecionados")
        }
    }, [cdOperadoras.length, cdOperadoras[0], cdOperadoras[cdOperadoras.length - 1]])

    async function handlerConsultarCep(cep) {
        if (!cep) {
            return
        }
        let auxCep = cep?.replace('-', '')
        setSpinAtivo(true)
        await api.get('/m1/consultar/cep?cep=' + auxCep)
            .then(function (response) {
                if (response.data.status == 200) {
                    if (response.data.dados == 'Cep Invalido') {
                        setDsMensagemErro('Cep Invalido.')
                        setDsLogradouro('')
                        setDsBairro('')
                        setDsCidade('')
                        setDsEstado('Selecione')
                        setCdEstado('')
                        handleMensagemErro()
                    } else {
                        setDsLogradouro(response.data.dados.logradouro)
                        setDsBairro(response.data.dados.bairro)
                        setDsCidade(response.data.dados.localidade)
                        setDsEstado(response.data.dados.uf)
                        setCdEstado(response.data.dados.cd_pav_uf)
                    }
                }
                setSpinAtivo(false)
                return response
            })
            .catch(function (error) {
                setDsMensagemErro('Algo deu errado ao tentar consultar o CEP. Por favor, tente novamente mais tarde.')
                handleMensagemErro()
                setSpinAtivo(false)
                return error
            })

    }

    async function limparDadosCadastroClienteCnpj() {
        setDsNomeFantasia('')
        setDsRazaoSocial('')
        setDsEmail('')
        setDsTelefone('')
        setDsNumero('')
        setDsLogradouro('')
        setDsBairro('')
        setDsCidade('')
        setDsCep('')
    }

    function erroValidacaoCNPJ(msgErro) {
        setCnpjValido(false)
        limparDadosCadastroClienteCnpj()
        setErroForm(msgErro)
    }

    async function handleConsultarCnpj(cnpj) {
        if(!cnpj) {
            limparDadosCadastroClienteCnpj()
            setErroForm('')
            return;
        }
        setSpinAtivo(true)

        let auxCnpj = getNumerosDocumento(cnpj)
        const resposta = await validarCnpj(auxCnpj, false)
        let cnpjEmClienteConectividadeFaturas = []
        
        const cnpjJaCadastrado = await api_clientes.get(`/api/v2/clients/consult?field=cnpj&value=${auxCnpj}`)
            .then( (resposta) => { return resposta.data } )
            .catch( (erro) => {
                setSpinAtivo(false);
                setDsMensagemErro('Erro');
                handleMensagemErro(false);
                return erro
            })

        if(cnpjJaCadastrado?.length) {
            if(tipoCliente.label === 'Portal') {
                cnpjEmClienteConectividadeFaturas = cnpjJaCadastrado.filter( (cliente) => {
                    return cliente.clienteTipo === 'Portal'
                } )
            } else {
                cnpjEmClienteConectividadeFaturas = cnpjJaCadastrado.filter( (cliente) => {
                    return cliente.clienteTipo != 'Portal' 
                } )
            }

            if( cnpjEmClienteConectividadeFaturas.length > 0 && (auxCnpj !== getNumerosDocumento(props.row?.ds_cnpj))) {
                erroValidacaoCNPJ('CNPJ já cadastrado!')
                setSpinAtivo(false)
                return;
            }
        }

        if( !resposta ) {
            erroValidacaoCNPJ('CNPJ inválido')
        }
        else if(cnpjJaCadastrado === '') {
            erroValidacaoCNPJ('Erro ao validar CNPJ já existente!')
        }
        else {
            setCnpjValido(true)
            setErroForm('')

            if ( resposta?.data ) {
                const { data } = resposta;
                setDsNomeFantasia(data.nome_fantasia)
                setDsRazaoSocial(data.razao_social)
                setDsEmail(data.email || '')
                setDsTelefone(data.ddd_telefone_1 || '')
                setDsNumero(data.numero)
                setDsLogradouro(data.descricao_tipo_de_logradouro +' '+ data.logradouro)
                setDsBairro(data.bairro)
                setDsCidade(data.cidade)
    
                let auxCep = data.cep
                setDsCep(auxCep)
                handlerConsultarCep(auxCep)
            }
        }

        setSpinAtivo(false)
    }

    async function handleAlterarCliente(clienteObj) {
        let clienteAlterado = {
            dados: clienteObj,
            condicao: {
                cd_pav_cliente: props.cdCliente,
                cd_pav_pessoa: props.row.cd_pav_pessoa,
            }
        }

        await api.post('/m1/alterar/cliente', clienteAlterado).then(async function (response) {
            if (pessoaFisica) {
                setDsMensagemSucesso('Cliente ' + dsNome + ' ' + dsSobrenome + ' alterado com sucesso.')
            } else {
                setDsMensagemSucesso('Cliente ' + dsNomeFantasia + ' alterado com sucesso.')
            }
            handleMensagemSucesso(false)
        }).catch(function (error) {
            setSpinAtivo(false)
            setDsMensagemErro('Algo deu errado com o cadastro, tente novamente. Se o erro persistir entre em contato com o suporte.')
            handleMensagemErro(false)
        })

    }

    async function handleCadastraCliente(clienteObj) {
        await api.post('/m1/cadastrar/cliente', clienteObj)
            .then(async function (response) {

                if (pessoaFisica) {
                    setDsMensagemSucesso('Cliente ' + dsNome + ' ' + dsSobrenome + ' cadastrado com sucesso.')
                } else {
                    setDsMensagemSucesso('Cliente ' + dsNomeFantasia + ' cadastrado com sucesso.')
                }
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                if (error?.response?.status == 401 && error?.response?.data?.info) {
                    setDsMensagemErro(error.response.data.info)
                } else {
                    setDsMensagemErro('Algo deu errado com o cadastro, tente novamente. Se o erro persistir entre em contato com o suporte.')
                }
                handleMensagemErro(false)
            });
    }

    async function handleCadastrar() {

        const listaCdModulo = []
        //função que transcreve o modulo para array de numeros.
        modulos.forEach(function (item, index) {
            if (item.checked === true) {
                listaCdModulo.push(item.cd_pav_modulo)
            }
        })

        ColetaClickstream(1434) // dropAcoesrevelarCadastroClienteNovoCadastrar

        setSpinAtivo(true)
        let auxTelefone = dsTelefone?.replaceAll("(", "")
        auxTelefone = auxTelefone?.replaceAll(")", "")
        auxTelefone = auxTelefone?.replaceAll("-", "")

        let auxCep = dsCep?.replaceAll(".", "")
        auxCep = auxCep?.replaceAll("-", "")

        let auxNome
        let auxFantasia
        let auxCnpj
        let auxNumero = dsNumero ?? props.row.numero

        if (pessoaFisica) {
            auxNome =  `${dsNome?.trim()} ${dsSobrenome?.trim()}` 
        } else {
            auxNome = dsRazaoSocial?.trim()
            auxFantasia = dsNomeFantasia?.trim()
            auxCnpj = dsCnpj?.replaceAll(".", "")
            auxCnpj = auxCnpj?.replaceAll("/", "")
            auxCnpj = auxCnpj?.replaceAll("-", "")
        }

        const clienteObj = {
            "fl_ativo": true,
            "cd_pav_tipo_pessoa": cdTipoPessoa,
            "ds_pav_cliente": auxNome,
            "ds_nome": auxNome, 
            "ds_nome_fantasia": auxFantasia === undefined ? '' : auxFantasia,
            "ds_email": dsEmail?.trim(),
            "ds_telefone": auxTelefone,
            "ds_cnpj": auxCnpj,
            "modulos_cliente": listaCdModulo,
            "ds_pav_cidade": dsCidade?.trim(),
            "ds_pav_bairro": dsBairro?.trim(),
            "ds_pav_logradouro": dsLogradouro?.trim(),
            "ds_pav_cep": auxCep,
            "n0_numero": auxNumero,
            "cd_pav_uf": cdEstado,
            "cd_pav_pais": 1,
            "ds_informacoes_adicionais": dsInformacoesAdicionais,
            "cd_cliente_tipo": tipoCliente.id,
        }

        if (modalCadastrar) {
            handleCadastraCliente(clienteObj)
        } else if (modalEditar) {
            handleAlterarCliente(clienteObj)
        }

    }


    const handleTipoPessoa = (event) => {
        setCdTipoPessoa(event.target.value)

        if(isTelaMeusClientes()){
            changeTipoClienteToPortal()
        }
    }




    function parseNextBtnLabel() {
        if (stepActive < 3) {
            return 'Próximo'
        } else {
            return 'Confirmar'
        }
    }

    function parseBackBtnLabel() {
        if (stepActive > 0) {
            return 'Voltar'
        } else {
            return 'Cancelar'
        }
    }

    function handlePermissoes(value, moduloIndex) {
        modulos[moduloIndex].checked = !modulos[moduloIndex].checked
        setModulos([...modulos]);

    }

    function renderCheckboxList() {
        return modulos.map((modulo, moduloIndex) => {
            return (
                <div className='modulo-checkbox'>

                    <FormControlLabel
                        sx={
                            temaEscuro ? {
                                    "& .MuiFormControlLabel-label": {
                                        color: '#FFF'
                                    },
                                    '& .MuiSvgIcon-root': {
                                        fontSize: '25px !important',
                                        '&.Mui-checked': {color: '#33CC99 !important'}
                                    },

                                }
                                :
                                {
                                    "& .MuiFormControlLabel-label": {},
                                    '& .MuiSvgIcon-root': {
                                        fontSize: '25px !important',
                                    },
                                    '&.Mui-checked': {color: '#33CC99'}
                                }}
                        value={modulo.cd_pav_modulo}
                        control={<Checkbox
                            sx={radioStyle}
                            checked={modulo.checked}/>}
                        checked={modulo.checked}
                        label={modulo.ds_pav_modulo}
                        onChange={e => handlePermissoes(e.target.value, moduloIndex)}
                    />
                </div>
            )
        })
    }

    const camposPessoaFisicaNaoPreenchidos = () => {
        return (
            !dsNome ||
            !dsSobrenome 
        )
    }

    const camposPessoaJuridicaNaoPreenchidos = () => {
        return (
            !dsRazaoSocial ||
            !dsNomeFantasia ||
            !cnpjValido
            // !dsCnpj
        )
    }

    const demaisCamposNaoPreenchidos = () => {
        return (
            !dsEmail || 
            !dsTelefone ||
            !dsLogradouro ||
            !dsNumero ||
            !dsCep ||
            !dsBairro ||
            !dsCidade ||
            dsEstado === 'Selecione'
        )
    }

    function handleNextStep() {
        if (stepActive < 3) {
            if (stepActive === 0) {
                if (!cdTipoPessoa || cdTipoPessoa === '0') {
                    setDsMensagemErro('Selecione o tipo de pessoa.')
                    handleMensagemErro()
                    return
                } else if ((!tipoCliente && cdTipoPessoa != 1) || (isN3 && cdTipoPessoa != 1 && tipoCliente.length < 1)) {
                    setDsMensagemErro('Selecione o tipo de cliente')
                    handleMensagemErro()
                    return
                } else {
                    setStepActive(stepActive + 1)
                }
            } else if (stepActive === 1) {
                if (pessoaFisica) {
                    if (
                        camposPessoaFisicaNaoPreenchidos() ||
                        demaisCamposNaoPreenchidos()
                    ) {
                        setDsMensagemErro('Preencha todos os campos.')
                        handleMensagemErro()
                        return
                    } else {
                        setStepActive(stepActive + 1)
                    }
                } else if (pessoaJuridica) {
                    if (
                        camposPessoaJuridicaNaoPreenchidos() ||
                        demaisCamposNaoPreenchidos()
                    ) {
                        setDsMensagemErro('Preencha todos os campos.')
                        handleMensagemErro()
                        return

                    } else {
                        setStepActive(stepActive + 1)
                    }
                }
            } else if (stepActive === 2) {
                if (modulos.filter(modulo => modulo.checked === true).length) {
                    setStepActive(stepActive + 1)
                } else {
                    setDsMensagemErro('Selecione pelo menos um módulo.')
                    handleMensagemErro()
                    return
                }
            }
        } else {
            handleCadastrar()
        }

    }

    function handlePrevStep() {
        setErroForm('');
        if (stepActive > 0) {
            setStepActive(stepActive - 1)
        } else {
            handleCancelar()
        }
    }

    function enableNextBtn() {
        const getCondicaoBotaoDesabilitado = () => {
            switch(stepActive) {
                case 0:
                    return (!cdTipoPessoa || cdTipoPessoa === '0' || !tipoCliente || (isN3 && cdTipoPessoa != 1 && tipoCliente.length < 1))
    
                case 1:
                    if (pessoaFisica) {
                        return camposPessoaFisicaNaoPreenchidos() || demaisCamposNaoPreenchidos()
                    } else if (pessoaJuridica) {
                        return camposPessoaJuridicaNaoPreenchidos() || demaisCamposNaoPreenchidos()
                    }
    
                case 2:
                    return !(modulos.filter(modulo => modulo.checked === true).length)
    
                case 3:
                default:
                    return false;
            }
        }

        if (getCondicaoBotaoDesabilitado()) {
            return 'botao-confirmar-desabilitado'
        } else {
            return 'botao-confirmar'
        }
    }


    function parsePersonType() {
        if (pessoaFisica) {
            return 'Física'
        } else if (pessoaJuridica) {
            return 'Jurídica'
        }
    }

    function buildSummaryModules() {
        let selectedModules = modulos.filter(modulo => modulo.checked === true)
        return selectedModules.map((modulo, moduloIndex) => {
            return (
                <div
                    className={temaEscuro ? 'modulo-checkbox-step-3-escuro' : 'modulo-checkbox-step-3'}>{modulo.ds_pav_modulo}</div>
            )
        })
    }

    function parseCNPJ() {
        if(pessoaJuridica) {
            return 'CNPJ:' + ' ' + dsCnpj
        }
        
    }

    function parseNOMERAZAO() {
        if (pessoaFisica) {
            return 'Nome:' + ' ' + dsNome
        } else if (pessoaJuridica) {
            return 'Razão Social:' + ' ' + dsRazaoSocial
        }
    }

    function parseSOBRENOMEFANTASIA() {
        if (pessoaFisica) {
            return 'Sobrenome:' + ' ' + dsSobrenome
        } else if (pessoaJuridica) {
            return 'Nome Fantasia:' + ' ' + dsNomeFantasia
        }
    }

    function handleChangeType(newValue) {
        if (newValue == null) {
            setTipoCliente([])
            return
        }
        setTipoCliente(newValue)
        setTipoChanged(!tipoChanged)
    }

    const summaryBgClass = temaEscuro ? 'summary-bg-escuro' : 'summary-bg';
    const stepLabelClass = temaEscuro ? 'step-label-escuro' : 'step-label';

    function Label({texto}) {
        return <Form.Label>
            <div className='fonte-cor-1 label-campo'>{texto}</div>
        </Form.Label>
    }

    return (
        <Form ref={modal} onSubmit={handleSubmit(() => handleCadastrar())}>
            {spinAtivo && <div className='componente-spinner-overlay'>
                <div className='componente-spinner-container'><SpinerVeye/></div>
            </div>}
            <div className='backoffice-modal-cadastro-cliente fundo-cor-6'>
                <Form.Row>
                    <Col>
                        <img className='icone-label-janela nao-selecionavel' src={elipseVerde()} alt='elipse'/>
                        <Form.Label>
                            <div className='fonte-cor-1 label-janela'>{tituloModal}</div>
                        </Form.Label>
                        <Button className='botao-fechar-janela' variant='danger' onClick={() => handleCancelar()}>
                            <img className='botao-fechar-janela-icone' src={fecharJanela()} alt='fechar-janela'/>
                        </Button>
                    </Col>
                </Form.Row>

                <Stepper activeStep={stepActive} sx={stepsStyle}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        return (
                            <Step sx={temaEscuro ? stepLabelStyleEscuro : {}} key={label} {...stepProps}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                {stepActive === 0 &&
                    <div className='step-1-tipo-pessoa steps-client-container'>
                        <FormControl>
                            <div className={stepLabelClass}>
                                Selecione o tipo de pessoa
                            </div>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={cdTipoPessoa}
                                onChange={handleTipoPessoa}
                            >
                                <div
                                    className={temaEscuro ? 'select-row-tipo-pessoa escuro-radio-label' : 'select-row-tipo-pessoa'}>
                                    <FormControlLabel value='1'
                                                      control={<Radio checked={pessoaFisica} sx={radioStyle}/>}
                                                      label="Pessoa Física"/>
                                    <FormControlLabel value='2'
                                                      control={<Radio checked={pessoaJuridica} sx={radioStyle}/>}
                                                      label="Pessoa Jurídica"/>
                                </div>

                            </RadioGroup>

                        </FormControl>
                    </div>
                }
                {stepActive === 1 &&
                    <div className='step-2-dados-cliente steps-client-container'>
                        <div className='backoffice-cadastro-cliente-linha-1'>
                            <div className='backoffice-cadastro-cliente-coluna-0'>
                            </div>
                        </div>
                        {pessoaFisica &&
                            <div className='backoffice-cadastro-cliente-linha-3'>
                                <div className='backoffice-cadastro-cliente-coluna-15'>
                                    <Form.Group>
                                        <Label texto='Nome' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                                            Insira o nome do cliente</Tooltip>}>
                                            <Form.Control
                                                value={dsNome}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='text'
                                                placeholder='Insira o nome'
                                                onChange={e => setDsNome(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                                <div className='backoffice-cadastro-cliente-coluna-15'>
                                    <Form.Group>
                                        <Label texto='Sobrenome' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                                            Insira o sobrenome do cliente</Tooltip>}>
                                            <Form.Control
                                                value={dsSobrenome}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='text'
                                                placeholder='Insira sobrenome'
                                                onChange={e => setDsSobrenome(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                            </div>
                        }
                        {pessoaJuridica &&
                            <div className='backoffice-cadastro-cliente-linha-1'>
                                <div className='backoffice-cadastro-cliente-coluna-1'>
                                    <Form.Group>
                                        <Label texto='CNPJ' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                                            O CNPJ deve possuir no mínimo 14 caracteres</Tooltip>}>
                                            <Form.Control
                                                value={dsCnpj}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='text'
                                                placeholder='00.000.000/0000-00'
                                                onChange={e => handleCnpj(e.target.value)}
                                                onBlur={e => handleConsultarCnpj(e.target.value)}
                                                maxlength="18"
                                            />
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                                <div className='backoffice-cadastro-cliente-coluna-2'>
                                    <Form.Group>
                                        <Label texto='Nome Fantasia' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                                            Insira o nome fantasia do cliente</Tooltip>}>
                                            <Form.Control
                                                value={dsNomeFantasia}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='text'
                                                placeholder='Insira o nome fantasia'
                                                onChange={e => setDsNomeFantasia(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                                <div className='backoffice-cadastro-cliente-coluna-3'>
                                    <Form.Group>
                                        <Label texto='Razão Social' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                                            Insira a razão social do cliente</Tooltip>}>
                                            <Form.Control
                                                value={dsRazaoSocial}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='text'
                                                placeholder='Insira a razão social'
                                                onChange={e => setDsRazaoSocial(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </Form.Group>
                                </div>
                            </div>
                        }
                        {cdTipoPessoa && cdTipoPessoa !== '0' &&
                            <>
                                <div className='backoffice-cadastro-cliente-linha-2'>
                                    <div className='backoffice-cadastro-cliente-coluna-4'>
                                        <Label texto='E-mail' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                                            Insira o e-mail</Tooltip>}>
                                            <Form.Control
                                                value={dsEmail}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='email'
                                                placeholder='exemplo@email.com.br'
                                                onChange={e => setDsEmail(e.target.value)}
                                                ref={register({validate: {minimo: value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) == true}})}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='backoffice-cadastro-cliente-coluna-5'>
                                        <Label texto='Telefone' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>Insira o telefone</Tooltip>}>
                                            <Form.Control
                                                value={dsTelefone}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='tel'
                                                placeholder='(00) 00000-0000'
                                                onChange={e => handleTelefone(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className='backoffice-cadastro-cliente-linha-2'>
                                    <div className='backoffice-cadastro-cliente-coluna-7'>
                                        <Label texto='Endereço' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                                            Insira o logradouro</Tooltip>}>
                                            <Form.Control
                                                value={dsLogradouro}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='text'
                                                placeholder='Digite o logradouro'
                                                onChange={e => setDsLogradouro(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='backoffice-cadastro-cliente-coluna-8'>
                                        <Label texto='Número' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>Insira o número</Tooltip>}>
                                            <Form.Control
                                                value={dsNumero}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='text'
                                                placeholder='000'
                                                onChange={e => setDsNumero(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='backoffice-cadastro-cliente-coluna-9'>
                                        <Label texto='CEP' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                                            O CEP deve possuír no mínimo 8 caracteres</Tooltip>}>
                                            <Form.Control
                                                value={dsCep}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='text'
                                                placeholder='00000-000'
                                                onChange={e => handleCep(e.target.value)}
                                                onBlur={e => handlerConsultarCep(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className='backoffice-cadastro-cliente-linha-2'>
                                    <div className='backoffice-cadastro-cliente-coluna-10'>
                                        <Label texto='Bairro' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                                            Insira o bairro</Tooltip>}>
                                            <Form.Control
                                                value={dsBairro}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='text'
                                                placeholder='Digite o bairro'
                                                onChange={e => setDsBairro(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='backoffice-cadastro-cliente-coluna-11'>
                                        <Label texto='Cidade' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                                            Insira a cidade</Tooltip>}>
                                            <Form.Control
                                                value={dsCidade}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='text'
                                                placeholder='Digite a cidade'
                                                onChange={e => setDsCidade(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='backoffice-cadastro-cliente-coluna-12'>
                                        <Label texto='Estado' />
                                        {revelarSelectEstado === false &&
                                            <Button
                                                className='campo-texto-cor-3 campo-select-material select-btn-estado'
                                                onClick={() => setRevelarSelectEstado(!revelarSelectEstado)}>
                                                <div
                                                    className='fonte-cor-1 label-campo campo-texto-select-tamanho'>{dsEstado}</div>
                                                <img
                                                    className={revelarSelectEstado ? 'campo-select-icone-ativado nao-selecionavel' : 'campo-select-icone-desativado nao-selecionavel'}
                                                    src={setaDropdown()} alt='drop'/>
                                            </Button>
                                        }
                                        {revelarSelectEstado === true &&
                                            <SelectEstadoCliente
                                                paramRota={'pav_estado'}
                                                paramCampo1={null}
                                                paramCampo2={null}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                setCodigo={setCdEstado}
                                                setDescricao1={setDsEstado}
                                                setDescricao2={setDummy}
                                                setDescricao3={setDummy}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectEstado}
                                                placeholder={dsEstado}
                                                sufixo={''}
                                            >
                                            </SelectEstadoCliente>
                                        }
                                    </div>
                                </div>
                                <div className='backoffice-cadastro-cliente-linha-2'>
                                    <div className='backoffice-cadastro-cliente-coluna-13'>
                                        <Label texto='Informações adicionais (opcional)' />
                                        <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-bottom`}>Insira informações como
                                                            apartamento, sala comercial, pontos de referência,
                                                            etc</Tooltip>}>
                                            <Form.Control
                                                value={dsInformacoesAdicionais}
                                                className='campo-texto-cor-3 campo-texto-material'
                                                type='text'
                                                placeholder='Insira informações como apartamento, sala comercial, pontos de referência, etc'
                                                onChange={e => setDsInformacoesAdicionais(e.target.value)}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div><span className='msg-erro-rodape'>{erroForm}</span></div>
                            </>
                        }
                    </div>
                }

                {stepActive === 2 &&
                    <div>
                        <div className={'steps-client-container'}>
                            <div className={stepLabelClass}>Módulos de
                                permissão
                            </div>
                            <div className='step-3-modulos-permissao'>
                                {renderCheckboxList()}
                            </div>

                        </div>
                    </div>
                }

                {stepActive === 3 &&
                    <div className='step-4-dados-cliente steps-client-container'>
                        <div className={summaryBgClass}>
                            <div className={stepLabelClass}>Tipo de
                                pessoa: {parsePersonType(cdTipoPessoa)}</div>
                            <div className={stepLabelClass}>Tipo de
                                cliente: {tipoCliente.label}</div>
                        </div>

                        <div className={summaryBgClass}>
                            <div className={stepLabelClass}>Módulos de
                                permissão
                            </div>
                            <div className={'summary-module-list'}>
                                {buildSummaryModules()}
                            </div>
                        </div>

                        <div className={summaryBgClass}>
                            <div className={stepLabelClass}>Dados do
                                cliente
                            </div>
                            <div className={'summary-client-data-list'}>
                                <div
                                    className={temaEscuro ? 'summary-column-escuro left' : 'summary-column left'}>
                                    <div>{parseCNPJ()}</div>
                                    <div>{parseNOMERAZAO()}</div>
                                    <div>{parseSOBRENOMEFANTASIA()}</div>
                                    <div>Email: {dsEmail}</div>
                                    <div>Telefone: {dsTelefone}</div>
                                    <div>Endereço: {dsLogradouro}</div>
                                </div>

                                <div
                                    className={temaEscuro ? 'summary-column-escuro right' : 'summary-column right'}>
                                    <div>Número: {dsNumero}</div>
                                    <div>CEP: {dsCep}</div>
                                    <div>Bairro: {dsBairro}</div>
                                    <div>Cidade: {dsCidade}</div>
                                    <div>Estado: {dsEstado}</div>
                                    <div>Informações adicionais: {dsInformacoesAdicionais}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <Form.Row>
                    <Col>
                        <Button onClick={() => handleNextStep()} className={enableNextBtn()} variant='success'
                                size='sm'>
                            <div className='fonte-cor-4'>{parseNextBtnLabel()}</div>
                        </Button>
                        <Button className='botao-cancelar' variant='success' size='sm' onClick={() => handlePrevStep()}>
                            <div className='fonte-cor-2'>{parseBackBtnLabel()}</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            {revelarMensagemSucesso &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={'Sucesso'}
                            mensagemParagrafo1={dsMensagemSucesso}
                            mensagemBotao={'Continuar'}
                        />
                    </div>
                </div>
            }
            {revelarMensagemErro &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={'Erro'}
                            mensagemParagrafo1={dsMensagemErro}
                            mensagemBotao={'Retornar'}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}

function getNumerosDocumento(doc) { // CNPJ ou CPF
    let auxDoc = doc?.replaceAll('.', '')
    auxDoc = auxDoc?.replaceAll('/', '')
    return auxDoc?.replaceAll('-', '')
}
