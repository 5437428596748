import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import { corDash as cor } from "../../../../../utils/dashboard";

function MyPieChart({ data, MyTooltip }) {
  return (
    <ResponsiveContainer width="100%" height="100%" debounce={300}>
      <PieChart
        width={190}
        height={190}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <Pie
          dataKey="value"
          data={data}
          innerRadius="55%"
          outerRadius="80%"
          fill={cor.cinzaEscuro}
          paddingAngle={0}
          stroke={0}
        >
          {data.map((entry, index) => (
            <Cell fill={entry.cor} key={index} />
          ))}
        </Pie>
        <Tooltip content={<MyTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default MyPieChart;
