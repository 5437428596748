//IMPORTAÇÕES
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import {api, api_s3} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';/*eslint-disable*/
import SunEditor from 'suneditor-react';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import XLSX  from 'xlsx'
import Dropzone from "react-dropzone";
import {Checkbox} from "@material-ui/core";
import FormControlLabel from '@mui/material/FormControlLabel';
const localTema = localStorage.getItem('tema')

//ICONES
import {elipseVerde, lupa, adicionarMaisVerde, lixeiraVermelho, importarArquivo, fecharJanela, exclamacao, setaDropdown} from '../../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
import { revelarNovaMensagem } from "../../../redux/actions/index";
//COMPONENTES
import { RenderMensagemSucesso } from "../../componentesModais/mensagens/mensagemSucesso"
import { RenderMensagemErro } from "../../componentesModais/mensagens/mensagemErro"
import { RenderMensagemAtencao } from '../../componentesModais/mensagens/mensagemAtencao'
import { RenderMensagemInformacao } from '../../componentesModais/mensagens/mensagemInformacao'
import SelectMetodoSelecao from '../../componentesModais/selects/selectMetodoSelecao'
import SelectPerfilCheckbox from '../../componentesModais/selects/selectPerfilCheckbox'
import SelectUfCheckbox from '../../componentesModais/selects/selectUfCheckbox'
import SelectNivelImportancia from '../../componentesModais/selects/selectNivelImportancia'
import SelectOperadoraCheckbox from '../../componentesModais/selects/selectOperadoraCheckbox'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
import ColetaClickstream from '../../clickstreamAnalise/clickstreamColeta';

let itensTemp = []

export default function EditarRegra(props) {
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [itens, setItens] = useState([])
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(0)
    const [linhaRemover, setLinhaRemover] = useState()
    const [cdMetodoSelecao, setCdMetodoSelecao] = useState(0)
    const [arrayCdUsuario, setArrayCdUsuario] = useState([])
    const [arrayCdPerfis, setArrayCdPerfis] = useState([])
    const [arrayCdOperadora, setArrayCdOperadora] = useState([])
    const [arrayCdEstados, setArrayCdEstados] = useState([])
    const [renderizar, setRenderizar] = useState(false)
    const [dsUsuarioAutomacao, setDsUsuarioAutomacao] = useState('Selecione')
    const [dsPerfilCheckbox, setDsPerfilCheckbox] = useState('Selecione')
    const [dsOperadoraCheckbox, setDsOperadoraCheckbox] = useState('Selecione')
    const [dsEstadoCheckbox, setDsEstadoCheckbox] = useState('Selecione')
    const [dsMetodoSelecao, setDsMetodoSelecao] = useState('Selecione')
    const [formaEnvioEmail, setFormaEnvioEmail] = useState()
    const [formaEnvioNotificacao, setFormaEnvioNotificacao] = useState()
    const [formaEnvioPlataforma, setFormaEnvioPlataforma] = useState()
    const [clienteN3RecebeMensagem, setClienteN3RecebeMensagem] = useState(false)
    // const [modulos, setModulos] = useState([])/*eslint-disable*/
    const [mostrarAoEntrar, setMostrarAoEntrar] = useState(false)
    const [cdAutRegraNivel, setCdAutRegraNivel] = useState('')
    const [dsAssunto, setDsAssunto] = useState('')
    const [dsNivelImportancia, setDsNivelImportancia] = useState("Selecione")
    const [dsDescricao, setDsDescricao] = useState('');
    const [corpoMensagem, setCorpoMensagem] = useState('');
    const [loginPesquisa, setLoginPesquisa] = useState('');
    const [codigoClientePesquisa, setCodigoClientePesquisa] = useState('')
    const [razaoSocialClientePesquisa, setRazaoSocialClientePesquisa] = useState('');
    const [emailClientePesquisa, setEmailClientePesquisa] = useState('');
    const [nomeClientePesquisa, setNomeClientePesquisa] = useState('');
    const [sobrenomeClientePesquisa, setSobrenomeClientePesquisa] = useState('');
    const [telefoneClientePesquisa, setTelefoneClientePesquisa] = useState('');
    const [cdTemplate, setCdTemplate] = useState('');
    const [DsTemplate, setDsTemplate] = useState('');
    const [dummy, setDummy] = useState() 
    //HOOKS SELECTS
    const [revelarSelectMetodoSelecao, setRevelarSelectMetodoSelecao] = useState(false)
    const [revelarSelectUsuarioAutomacao, setRevelarSelectUsuarioAutomacao] = useState(false)
    const [revelarSelectPerfilCheckbox, setRevelarSelectPerfilCheckbox] = useState(false)
    const [revelarSelectOperadoraCheckbox, setRevelarSelectOperadoraCheckbox] = useState(false)
    const [revelarSelectUfCheckbox, setRevelarSelectUfCheckbox] = useState(false)
    const [revelarSelectNivelImportancia, setRevelarSelectNivelImportancia] = useState(false)
    const [revelarSelectTemplate, setRevelarSelectTemplate] = useState(false)
    //HOOKS MENSAGENS
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemSucessoTemplate, setRevelarMensagemSucessoTemplate] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [revelarMensagemConfirmarN3, setRevelarMensagemConfirmarN3] = useState(false)
    
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [clickFora, setClickFora] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [controladorPagina, setControladorPagina] = useState(0)
    const [caracteresMensagem, setCaracteresMensagem] = useState(41);
    const [quantidadeUsuariosGeral, setQuantidadeUsuariosGeral] = useState(0);
    const [arrayTemplates, setArrayTemplates] = useState([]);
    const [imgBase64, setImgBase64] = useState([]);

    /* async function getClientesFaturas() {
        let clientesFaturas  = []
        const consulta = await api.post("/m1/consultar/cliente/cliente_faturas_2")

        if(consulta.data.dados.length > 0){
            await consulta.data.dados.map((item)=>{
                clientesFaturas.push(item.cd_pav_cliente)
            })
        }
        return clientesFaturas;
    } */

    useEffect(() => {
        async function carregarTabelaGeral(){
            setSpinAtivo(true)

            // const clientes_Faturas = await getClientesFaturas()

            if( dsMetodoSelecao === 'Geral' ) {
                const urlGet = `?contar=true&painel_administrativo=true&origem=centralMensagensNovaMensagem&clienteN3RecebeMensagem=${clienteN3RecebeMensagem}`;
                if(cdMetodoSelecao === -1){
                    const apiData = await api.get("/m1/consultar/usuario"+urlGet);
                    setQuantidadeUsuariosGeral(apiData.data.dados[0].CONTADOR)

                }
            } else {
                const urlGet = `?contar=true&painel_administrativo=true&origem=centralMensagensNovaMensagem&clienteN3RecebeMensagem=false`; // &clientes_Faturas=clientes_Faturas

                if(cdMetodoSelecao === -1){
                    const apiData = await api.get("/m1/consultar/usuario"+urlGet);
                    setQuantidadeUsuariosGeral(apiData.data.dados[0].CONTADOR)
                
                }
            }
           
            setSpinAtivo(false)
        }
        carregarTabelaGeral()
    },[cdMetodoSelecao, clienteN3RecebeMensagem])

    function ativarParaN3() {
        setClienteN3RecebeMensagem(!clienteN3RecebeMensagem)
    }

    function handleChangeMetodoSelecao(metodoselecao, codigo) {
        setDsMetodoSelecao(metodoselecao)
        setCdMetodoSelecao(codigo)

        setClienteN3RecebeMensagem(false);
    }

    function renderCheckboxList(){
        return (
            <div className='modulo-checkbox-mensagem-n3'>
                <FormControlLabel
                    sx={
                        localTema === 'escuro' ? {
                                "& .MuiFormControlLabel-label": {
                                    color: '#FFF'
                                },
                                '& .MuiSvgIcon-root': {
                                    fontSize: '25px !important',
                                    '&.Mui-checked': {color: '#33CC99 !important'}
                                },

                            }
                            :
                            {
                                "& .MuiFormControlLabel-label": {},
                                '& .MuiSvgIcon-root': {
                                    fontSize: '25px !important',
                                },
                                '&.Mui-checked': {color: '#33CC99'}
                            }}
                    value={1} 
                    control={<Checkbox
                        // sx={radioStyle}
                        checked={clienteN3RecebeMensagem}/>} 
                    label={'Cliente N3'}
                    onChange={e => ativarParaN3()}
                />
            </div>
        )
    }

    function mostrarMensagemAtencao(titulo) {
        handleMensagemAtencao(false, {
            titulo,
            textoBotao: 'Retornar'
        })
        return false;
    }

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    async function handleCancelar() {
        ColetaClickstream(1401) // centralMensagensTabelaDropAcoesNovaMensagemCancelar
        setRevelarMensagemErro(false)
        setRevelarMensagemSucesso(false)
        despacho(revelarNovaMensagem(false))
    }

    function validacoes() {
        if(!formaEnvioEmail && !formaEnvioNotificacao && !formaEnvioPlataforma) {
            return mostrarMensagemAtencao('Selecione o tipo da mensagem');
        }

        if(!cdAutRegraNivel) {
            return mostrarMensagemAtencao('"Nível de Importância" é obrigatório');
        }
        if(!corpoMensagem || corpoMensagem == '' || corpoMensagem == '<p><br></p>') {
            return mostrarMensagemAtencao('Conteúdo da mensagem é obrigatório');
        }


        if(clienteN3RecebeMensagem) {
            return setRevelarMensagemConfirmarN3(true);
        }

        return true;
    }

    async function handleConfirmar() { 
        const continuar = validacoes();
        if(!continuar) return;

        await handleSalvar();
    }

    async function handleSalvar() { 
        if(revelarMensagemConfirmarN3) setRevelarMensagemConfirmarN3(false);

        ColetaClickstream(1402) // centralMensagensTabelaDropAcoesNovaMensagemConfirmar
        setSpinAtivo(true)

        let arrayEnvios = []
        if(itens.length > 0){
            itens.map((destinatario,i) => {
                arrayEnvios.push({
                    email:destinatario.ds_email, 
                    enviado: false,
                    razao_social: destinatario.ds_nome,
                    nome: destinatario.ds_pav_usuario,
                    sobrenome: destinatario.ds_pav_usuario_sobrenome,
                    telefone: destinatario.ds_telefone,
                    codigo: destinatario.n0_codigo_cliente
                })
            })
        }else if(dsMetodoSelecao === 'Geral') {
            const queryUsuariosDados = `?painel_administrativo=true&origem=centralMensagensNovaMensagem&clienteN3RecebeMensagem=${clienteN3RecebeMensagem}` 

            await api.get("/m1/consultar/usuario"+queryUsuariosDados)
            .then(function (response) {
                response.data.dados.map((item) => {
                    arrayEnvios.push({
                        email: item.ds_email, 
                        enviado: false,
                        razao_social: item.ds_nome,
                        nome: item.ds_pav_usuario,
                        sobrenome: item.ds_pav_usuario_sobrenome,
                        telefone: item.ds_telefone,
                        codigo: item.n0_codigo_cliente
                    })
                })
            })
            .catch(function (error) {
                setMensagemErro("Erro ao obter dados dos destinatários")
                handleMensagemErro(false)
            })
        } /* else {
            setMensagemErro("Erro no formulário")
            handleMensagemErro(false)
        } */
        
        let linkAwsImagem = '';
        
        let mensagem = corpoMensagem;
        let a = mensagem.split("figure")

        let auxVerifica = corpoMensagem.indexOf("<figure")

        if(auxVerifica > 1){
            let b = a[1].split('src=')
            let c = b[1].split('>')
            let base64 = c[0].split(',')[1]
            setImgBase64(base64)
    
            if(base64){
                let data = new FormData();
                data.append('file', base64);
                data.append("conteudo", JSON.stringify({texto: corpoMensagem, titulo: dsAssunto}));
                data.append('envios', JSON.stringify(arrayEnvios));
                data.append('mensagemReduzida', dsDescricao);
                data.append('ds_tag', "[IMG1]");
    
                await api_s3.post("s3/uploadImage/fila_mensagem", data)
                    .then(response => {
                        if (response.status === 200) {
                            linkAwsImagem = response.data.dados.Location
                            // handleMensagemSucesso(true);
                            setSpinAtivo(false)
                        }else{
                            setSpinAtivo(false)

                        }
                    })
                    .catch((error) => {
                        setSpinAtivo(false)

                    });
            }else{
                setMensagemErro("O arquivo não é do tipo .txt")
                setRevelarMensagemErro(true)
            }
        }

        let index1 = corpoMensagem.indexOf("<figure")
        let index2 = corpoMensagem.indexOf("</figure>")

        let novoCorpoMensagem = ''

        if(index1 != -1 && index2 != -1){
            novoCorpoMensagem = corpoMensagem.replace(corpoMensagem.substring(index1, index2+9),' \
            <figure style="margin: 0px;"> \
            <img src="'+linkAwsImagem+'" alt="" style=""></figure>');
            setCorpoMensagem(novoCorpoMensagem);
        } else{
            novoCorpoMensagem = corpoMensagem
        }

        if(formaEnvioEmail === true){
            setSpinAtivo(true)
            salvarEmail(arrayEnvios, novoCorpoMensagem)
        }
        if(formaEnvioNotificacao === true){
            setSpinAtivo(true)
            salvarNotificação(arrayEnvios, novoCorpoMensagem)
        }
        if(formaEnvioPlataforma === true){
            setSpinAtivo(true)
            salvarPlataforma(arrayEnvios, novoCorpoMensagem)
        }
    }
  
    async function salvarEmail(arrayEnvios, novoCorpoMensagem){
        
        await api.post("m1/cadastrar/fila_mensagem", {
            email_remetente: "veye@veye.com.br",
            conteudo:{texto: novoCorpoMensagem, titulo: dsAssunto},
            status: 1,
            nivel: cdAutRegraNivel,
            tipo: 1,
            envios: arrayEnvios,
            exibirAoEntrar: mostrarAoEntrar,
            mensagemReduzida: dsDescricao
        })
        .then(function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro("Erro na solicitação")
            handleMensagemErro(false)
        })
    }

    async function salvarNotificação(arrayEnvios, novoCorpoMensagem){
        await api.post("m1/cadastrar/fila_mensagem", {
            email_remetente: 'veye@veye.com.br',
            conteudo:{texto: novoCorpoMensagem, titulo: dsAssunto},
            status: 1,
            nivel: cdAutRegraNivel,
            tipo: 3,
            envios: arrayEnvios,
            exibirAoEntrar: mostrarAoEntrar,
            mensagemReduzida: dsDescricao
        })
        .then(function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro("Erro na solicitação")
            handleMensagemErro(false)
        })
    }

    async function salvarPlataforma(arrayEnvios, novoCorpoMensagem){
        await api.post("m1/cadastrar/fila_mensagem", {
            email_remetente: 'veye@veye.com.br',
            conteudo:{texto: novoCorpoMensagem, titulo: dsAssunto},
            status: 1,
            nivel: cdAutRegraNivel,
            tipo: 2,
            envios: arrayEnvios,
            exibirAoEntrar: mostrarAoEntrar,
            mensagemReduzida: dsDescricao
        })
        .then(function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro("Erro na solicitação")
            handleMensagemErro(false)
        })
    }

    useEffect(() => {
        setSpinAtivo(true)
        if(arrayCdUsuario.length > 0){
            setDsUsuarioAutomacao((arrayCdUsuario.length) + " Selecionados")
        }else{
            setDsUsuarioAutomacao("Selecione")
        }
        setSpinAtivo(false)
    }, [revelarSelectUsuarioAutomacao])

    useEffect(() => {
        setSpinAtivo(true)
        if(arrayCdPerfis.length > 0){
            setDsPerfilCheckbox((arrayCdPerfis.length) + " Selecionados")
        }else{
            setDsPerfilCheckbox("Selecione")
        }
        setSpinAtivo(false)
    }, [revelarSelectPerfilCheckbox])

    useEffect(() => {
        setSpinAtivo(true)
        if(arrayCdEstados.length > 0){
            setDsEstadoCheckbox((arrayCdEstados.length) + " Selecionados")
        }else{
            setDsEstadoCheckbox("Selecione")
        }        
        setSpinAtivo(false)
    }, [revelarSelectUfCheckbox])

    useEffect(() => {
        setSpinAtivo(true)
        if(arrayCdOperadora.length > 0){
            setDsOperadoraCheckbox((arrayCdOperadora.length) + " Selecionado(s)")
        }else{
            setDsOperadoraCheckbox("Selecione")
        }
        setSpinAtivo(false)
    }, [revelarSelectOperadoraCheckbox])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, []);

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                props.setRenderizar(!props.renderizar)
            }
        }
    }

    useEffect(() => {
        setSpinAtivo(true)
        function handleCriarNivelSelecionado(){
            if(cdAutRegraNivel === 3){
                setDsNivelImportancia(
                    <div className="select-nivel-importancia-container-esfera"><div className="select-nivel-importancia-esfera-verde"/><p className="fonte-cor-1 nao-selecionavel">Normal</p></div>
                )
            }else if(cdAutRegraNivel === 2){
                setDsNivelImportancia(
                    <div className="select-nivel-importancia-container-esfera"><div className="select-nivel-importancia-esfera-amarela"/><p className="fonte-cor-1 nao-selecionavel">Atenção</p></div>
                )
            }else if(cdAutRegraNivel ===1){
                setDsNivelImportancia(
                <div className="select-nivel-importancia-container-esfera"><div className="select-nivel-importancia-esfera-vermelha"/><p className="fonte-cor-1 nao-selecionavel">Urgente</p></div>
                )
            }
        }
        handleCriarNivelSelecionado()
        setSpinAtivo(false)
    }, [cdAutRegraNivel])

    useEffect(() => {
        setSpinAtivo(true)
        setArrayCdUsuario([])
        setDsUsuarioAutomacao('Selecione')
        setArrayCdPerfis([])
        setArrayCdOperadora([])
        setDsPerfilCheckbox('Selecione')
        setDsOperadoraCheckbox('Selecione')
        setItens([])
        setSpinAtivo(false)
    }, [cdMetodoSelecao])

    useEffect(() => {
        handleLinhasSelecionadas()
    }, [itens])
    
    useEffect(() => {
        // SETADO O ARRAY DE ITENS PARA VAZIO CADA VEZ QUE ENTRAR NO useEffect
        setItens([])
        let cdOperadora = []
        let cdUf = []
        let auxVerificador = false
        if(arrayCdOperadora.length > 0){
            if(arrayCdOperadora[0].cd_dsp_operadora === -1){
                arrayCdOperadora.shift()
            }
        }
        arrayCdOperadora.map((item)=>{
            cdOperadora.push(item.cd_dsp_operadora)
        })
        cdUf = arrayCdEstados.map((estado) => { return estado.cd_pav_uf } )
        async function carregarOperadora(){
            setSpinAtivo(true)
            const arrayItens = []
            const usuarios = await api.post('/m1/consultar/operadora/usuario_operadora',{
                "cd_dsp_operadora":cdOperadora,
                cd_pav_uf: cdUf,
                clienteN3RecebeMensagem
            })

            usuarios.data.dados.map((itemBusca,i) => { 
                arrayItens.push({
                    ds_nome: itemBusca.nome, 
                    ds_email: itemBusca.ds_email, 
                    ds_telefone: itemBusca.telefone,
                    n0_codigo_cliente: itemBusca.codigo,
                    ds_pav_usuario: itemBusca.usuario,
                    ds_pav_usuario_sobrenome: itemBusca.ds_pav_usuario_sobrenome
                })
            setItens(arrayItens)
            })

            setSpinAtivo(false)
            
        }
        
        if(arrayCdOperadora.length > 0){
            carregarOperadora()
        }
    },[dsOperadoraCheckbox, dsEstadoCheckbox, clienteN3RecebeMensagem]) // dsEstadoCheckbox, arrayCdEstados

    async function handlePesquisarLogin(){
        if(loginPesquisa != '' && loginPesquisa != undefined){
            setSpinAtivo(true)
            const dados = {
                paguinaAtual: 0,
                carregarLimit: 64000,
                temFiltros: true,
                buscar: loginPesquisa.trim(),
                filtros: {ds_pav_usuario: "", ds_email: loginPesquisa.trim()}
            }
            const { data } = await api.post("/m1/filtrar/usuario", dados)
            .then({})
            .catch({})
            let auxVerificador = false
            if(data != undefined){
                data.dados.map((itemBusca,i) => { //É PRECISO ADICIONAR O SOBRENOME AINDA, ELE NÃO EXISTE NA NOSSA BASE, ENTÃO MANTEREI EM BRANCO E A COLUNA APROPRIADA COMENTADA
                    auxVerificador = false
                    itens.map((itemArray, i) => {
                        if (itemArray.ds_email === itemBusca.ds_email){
                            auxVerificador = true
                        } 
                    })
                    if(auxVerificador === false){
                        itens.push({
                            ds_nome: itemBusca.ds_nome, 
                            ds_email: itemBusca.ds_email, 
                            ds_telefone: itemBusca.ds_telefone,
                            n0_codigo_cliente: itemBusca.n0_codigo_cliente,
                            ds_pav_usuario: itemBusca.ds_pav_usuario,
                            ds_pav_usuario_sobrenome: itemBusca.ds_pav_usuario_sobrenome
                        })
                    }
                    setItens(itens)
                })
                handleLinhasSelecionadas()
            }
            setSpinAtivo(false)
        }
    }

    async function handlePesquisarCliente(codigo){
        if(codigo != '' && codigo != undefined){
            setSpinAtivo(true)
            const data = await api.get("m1/consultar/cliente?pav31.n0_codigo_cliente="+codigo)
            .then({})
            .catch({})
            if(data.data.dados[0] != undefined && data.data.dados[0] != '' && data.data.dados[0] != null){
                setCodigoClientePesquisa(data.data.dados[0].n0_codigo_cliente)
                setRazaoSocialClientePesquisa(data.data.dados[0].ds_nome)
                setEmailClientePesquisa(data.data.dados[0].ds_email)
                setNomeClientePesquisa(data.data.dados[0].ds_pav_usuario)
                setSobrenomeClientePesquisa(data.data.dados[0].ds_pav_usuario_sobrenome)
                setTelefoneClientePesquisa(data.data.dados[0].ds_telefone)
            }
            setSpinAtivo(false)
       
        }
    }

    function handleAdicionarClienteTabela(){
        setSpinAtivo(true)
        if(codigoClientePesquisa != '' && razaoSocialClientePesquisa != '' && emailClientePesquisa != ''){
            let auxVerificador = false
            itens.map((itemArray, i) => {
                if (itemArray.ds_email === emailClientePesquisa){
                    auxVerificador = true
                } 
            })
            if(auxVerificador === false){
                itens.push({
                    ds_nome: razaoSocialClientePesquisa, 
                    ds_email: emailClientePesquisa, 
                    n0_codigo_cliente: codigoClientePesquisa,
                    ds_pav_usuario: nomeClientePesquisa,
                    ds_pav_usuario_sobrenome: sobrenomeClientePesquisa,
                    ds_telefone: telefoneClientePesquisa,
                })
            }
        }
        handleLinhasSelecionadas()
        setCodigoClientePesquisa('')
        setRazaoSocialClientePesquisa('')
        setEmailClientePesquisa('')
        setSpinAtivo(false)
     
    }

    function handleLerArquivo(files){
        files.forEach((file) => {
            const reader = new FileReader()
            
            reader.onload = (evt) => {
                const data = evt.target.result;
                //le os dados
                const readedData = XLSX.read(data, {type: 'array'});
                //transforma em um array de json
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                const dados = XLSX.utils.sheet_to_json(ws, {header:1});
                //verifica se o esta mandando sao numeros e um arquivo certos 
                if(!isNaN(parseInt(dados[0]))){
                    carregarTabelaPorArquivo(dados)
                }
            }
            reader.readAsArrayBuffer(file)
        })
    };

    async function carregarTabelaPorArquivo(dadosArquivo){
        if (dadosArquivo.length>0){
            setSpinAtivo(true)
          
            dadosArquivo.map((codigo) => {
                pesquisarClientesPorArquivo(codigo[0])
            })
            setSpinAtivo(false)
            
        }
        handleLinhasSelecionadas()
    }

    async function pesquisarClientesPorArquivo(codigo){
        const data = await api.get("m1/consultar/cliente?pav31.n0_codigo_cliente="+codigo)
        .then({})
        .catch({})
        let auxVerificador = false
        if(data.data.dados[0] != undefined && data.data.dados[0] != '' && data.data.dados[0] != null){
            itens.map((itemArray, i) => {
                if (itemArray.ds_email === data.data.dados[0].ds_email){
                    auxVerificador = true
                } 
            })
            if(auxVerificador === false){
                itens.push({ds_nome: data.data.dados[0].ds_nome, ds_email: data.data.dados[0].ds_email, n0_codigo_cliente: data.data.dados[0].n0_codigo_cliente})
            }
        }
        handleLinhasSelecionadas()
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                props.setRenderizar(!props.renderizar)
                handleCancelar()
            }
            handleCancelar()
        }
    }

    function handleDescricao(valor){
        setDsDescricao(valor)
        setCaracteresMensagem(41 - valor.length)
    }

    function handleAtualizarMensagem(mensagem){
        setCorpoMensagem(mensagem)
    }

    function handleRemoverLinha(ds_email){
        setRevelarMensagemAtencao(false)
        itensTemp = itens
        itens.splice(ds_email, 1);
        setItens(itensTemp)
        handleLinhasSelecionadas()
    }

    function handleMensagemAtencao(confirmacao, novoValor=true){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(novoValor)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(confirmacao === true ){
            handleRemoverLinha(linhaRemover)
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
        }
        if(confirmacao === true ){
        }
    }

    function handleMensagemSucessoTemplate(confirmacao){
        if(revelarMensagemSucessoTemplate === false){
            setRevelarMensagemSucessoTemplate(true)
        }else{
            setRevelarMensagemSucessoTemplate(false)
        }
        if(confirmacao === true ){
        }
    }

    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itens.length)
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(rowIndex);
        }
    }

    const formatter = (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
    }

    const colunasLogin = [{
        dataField: 'cd_pav_usuario',
        text: 'Cód. login',
        headerClasses: 'nao-selecionavel',
        hidden: true,
        formatter
    },
    {
        dataField: 'ds_nome',
        text: 'Nome',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter
    },
    {
        dataField: 'ds_email',
        headerClasses: 'nao-selecionavel',
        text: 'E-mail',
        sort: true,
        formatter
    },
    {
        dataField: 'ds_telefone',
        headerClasses: 'nao-selecionavel',
        text: 'Telefone',
        sort: true,
        formatter
    },
    {
        dataField: 'acoes',
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        text: '',
        sort: false,
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]

    const colunasCliente = [{
        dataField: 'n0_codigo_cliente',
        text: 'Cód. cliente',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter
    },
    {
        dataField: 'ds_nome',
        headerClasses: 'nao-selecionavel',
        text: 'Razão social',
        sort: true,
        formatter
    },
    {
        dataField: 'ds_email',
        headerClasses: 'nao-selecionavel',
        text: 'E-mail',
        sort: true,
        formatter
    },
    {
        dataField: 'acoes',
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        text: '',
        sort: false,
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]

    const colunasPerfil = [{
        dataField: 'DS_PERFIL',
        headerClasses: 'nao-selecionavel',
        text: 'Perfil',
        sort: true,
        formatter
    },
    {
        dataField: 'ESTADO',
        headerClasses: 'nao-selecionavel',
        text: 'Estado',
        sort: true,
        formatter
    },
    {
        dataField: 'EMAIL',
        headerClasses: 'nao-selecionavel',
        text: 'E-mail',
        sort: true,
        formatter
    },
    {
        dataField: 'acoes',
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        text: '',
        sort: false,
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]

    const colunasOperadora = [{
        dataField: 'ds_email',
        headerClasses: 'nao-selecionavel',
        text: 'E-mail',
        sort: true,
        formatter
    },
    {
        dataField: 'OPERADORA',
        headerClasses: 'nao-selecionavel',
        text: 'Operadora',
        hidden: true,
        sort: true,
        formatter
    },
    {
        dataField: 'acoes',
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        text: '',
        sort: false,
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]

    function botaoPressionado(event) {
        if(event.key === "Enter"){
            event.preventDefault(); // evitar enviar modal
            handlePesquisarLogin()
        }
    }

    function botaoPressionadoCdCliente(event) {
        if(event.key === "Enter"){
            event.preventDefault(); // evitar enviar modal
            handlePesquisarCliente(event.target.value)
        }
    }

    function retornarNomePerfil(cdPerfil){
        let auxNomePerfil = '' 
        arrayCdPerfis.map((item) => {
            if(item.cd_pav_perfil === cdPerfil){
                auxNomePerfil = item.ds_pav_perfil
            }
        })
        return auxNomePerfil
    } 

    // As tabelas de template de mensagem não existem no banco, porenquanto esta parte do código vai ter que ficar comentada.
    // Rudimar Engel, 03/2024.
/*
    useEffect(()=>{
        async function consultarTemplate(){
            const {data} = await api.get("/m1/consultar/fila_mensagem/mensagem_template");
            if(data.dados.length > 0){
                let objetoTemplate = data.dados.map((item,i) => {
                    return (
                            {
                                name: item.descricao1,
                                html: '<p>' + item.descricao2 +'</p>'
                            }
                    );
                })
                setArrayTemplates(objetoTemplate)
            }
        }
            consultarTemplate()
    },[renderizar])
*/
    async function handleSalvarTemplate(){
        await api.post("/m1/cadastrar/fila_mensagem/mensagem_template",{
            'ds_nome_template': dsAssunto,
            'ds_template':corpoMensagem
        }).then(async function(resposta) {
            setRenderizar(!renderizar)
            setRevelarMensagemSucessoTemplate(true)
        })
        .catch(function(erro) {
            handleMensagemInformacao(false)
            throw erro;
        })
    }

    function handleControladorPagina(val) {
        if(val === 1) {
            if((!dsMetodoSelecao || dsMetodoSelecao === 'Selecione')) {
                return mostrarMensagemAtencao('"Método de Seleção" obrigatório');
            }

            if(dsMetodoSelecao !== 'Geral' && !itens.length) {
                return mostrarMensagemAtencao('É obrigatório adicionar pelo menos um destinatário');
            }
            
        }
        setControladorPagina(val)
    }

    function BotaoConfirmar({disabled=false}) {
        const propsActive = { className:"botao-confirmar", variant: "success"};
        const propsInactive = { className:"botao-confirmar-desabilitado" };

        const props = disabled ? propsInactive : propsActive;

        
        let btnLabel = 'Próximo';
        let btnAction = { onClick: () => handleControladorPagina(1) }

        if(controladorPagina === 1) {
            btnLabel = 'Confirmar';
            btnAction = {type: "submit"}
        }

        return (
            <Button {...btnAction} {...props} size="sm">
                <div className="fonte-cor-4">{btnLabel}</div>
            </Button>
        )
    }
    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleConfirmar())}>
            <div className="modal-cadastro-mensagem fundo-cor-6">
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Nova mensagem</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-botoes-nova-mensagem-central-mensagens">
                            <div className="coluna-botoes-nova-mensagem-central-mensagens">
                                { controladorPagina === 0 ?
                                    <Button className="botao-tab-nova-mensagen-central-mensagens-ativado fonte-cor-1" onClick={() => handleControladorPagina(0)}>
                                        Usuários
                                    </Button>
                                :
                                    <Button className="botao-tab-nova-mensagen-central-mensagens fonte-cor-1" onClick={() => handleControladorPagina(0)}>
                                        Usuários
                                    </Button>
                                }
                            </div>
                            <div className="coluna-botoes-nova-mensagem-central-mensagens">
                                { controladorPagina === 1 ?
                                    <Button className="botao-tab-nova-mensagen-central-mensagens-ativado fonte-cor-1" onClick={() => handleControladorPagina(1)}>
                                        Mensagens
                                    </Button>
                                :
                                    <Button className="botao-tab-nova-mensagen-central-mensagens fonte-cor-1" onClick={() => handleControladorPagina(1)}>
                                        Mensagens
                                    </Button>
                                }
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Divisor />
                { controladorPagina === 0 &&
                    <Form.Row>
                        <Col>
                            <Form.Row>
                                <Col>
                                    <div className="grupo-nova-mensagem-central-mensagens">
                                        <div className="coluna-nova-mensagem-central-mensagens-1">
                                            <Form.Group>
                                                <FormLabel>Método de seleção</FormLabel>
                                                { revelarSelectMetodoSelecao === false &&
                                                    <Button name="cdMetodoSelecao" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectMetodoSelecao(!revelarSelectMetodoSelecao)}>
                                                        <Label>{dsMetodoSelecao}</Label>
                                                        <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                                    </Button>
                                                }
                                                { revelarSelectMetodoSelecao === true &&
                                                    <SelectMetodoSelecao
                                                        setRevelarSelectMetodoSelecao={setRevelarSelectMetodoSelecao} //Hook para fechar a modal
                                                        handleChangeMetodoSelecao={handleChangeMetodoSelecao}
                                                        dsMetodoSelecao={dsMetodoSelecao} 
                                                    >
                                                    </SelectMetodoSelecao>
                                                }
                                            </Form.Group>
                                        </div>
                                        { ( dsMetodoSelecao === 'Geral' || dsMetodoSelecao === 'Por operadora' ) &&
                                            renderCheckboxList()
                                        }
                                        { cdMetodoSelecao === -1 &&
                                            <div className="coluna-nova-mensagem-central-mensagens-2">
                                                <div className="coluna-nova-mensagem-central-mensagens-2-0">
                                                    <Form.Group>
                                                        
                                                        <div className="grupo-pesquisa-nova-mensagem-central-mensagens">
                                                                <div className="coluna-nova-mensagem-central-mensagens-2-1">
                                                                <Form.Row>
                                                                   <Col>
                                                                       <p className="fonte-cor-1 label-normal contador-usuarios-geral">{quantidadeUsuariosGeral} usuário(s) selecionado(s)</p>
                                                                   </Col>
                                                                </Form.Row>
                                                            </div>
                                                            <div className="coluna-nova-mensagem-central-mensagens-2-2">
                                                               
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="coluna-nova-mensagem-central-mensagens-3">
                                                    
                                                </div>
                                            </div>
                                        }
                                        { cdMetodoSelecao === 1 &&
                                            <div className="coluna-nova-mensagem-central-mensagens-2">
                                                <div className="coluna-nova-mensagem-central-mensagens-2-0">
                                                    <Form.Group>
                                                        <FormLabel>Login</FormLabel>
                                                        <div className="grupo-pesquisa-nova-mensagem-central-mensagens">
                                                            <div className="coluna-nova-mensagem-central-mensagens-2-1">
                                                                <TooltipContainer tooltipText='Pesquisar'>
                                                                    <Button className="botao-nova-mensagem-central-mensagens-pesquisar fundo-cor-10" onClick={() => handlePesquisarLogin()}>
                                                                        <img alt='lupa' className="icone-nova-mensagem-central-mensagens-pesquisar" src={lupa()} />
                                                                    </Button>
                                                                </TooltipContainer>
                                                            </div>
                                                            <div className="coluna-nova-mensagem-central-mensagens-2-2">
                                                                <TooltipContainer tooltipText='Digite o e-mail de login de um usuário'>
                                                                    <Form.Control onKeyDown={e => botaoPressionado(e)} name="login_usuario" placeholder={"Digite o e-mail"} className="campo-texto-cor-3 campo-pesquisa-nova-mensagem-central-mensagens" type="text"
                                                                    maxlength="100" onChange={(e) => setLoginPesquisa(e.target.value)}/>
                                                                </TooltipContainer>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="coluna-nova-mensagem-central-mensagens-3">
                                                    <Button className="botao-adicionar-pedidos-novo-simcard botao-adicionar-pedidos-novo-simcard-espacador" variant="success" size="sm" onClick={() => handlePesquisarLogin()}>
                                                        <img className="icone-botao-adicionar-pedidos-novo-simcard" src={adicionarMaisVerde()} alt="fechar-janela"/>
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                        { cdMetodoSelecao === 4 &&
                                            <div className="coluna-nova-mensagem-central-mensagens-9">
                                                <div className="coluna-nova-mensagem-central-mensagens-10">
                                                    <Form.Group>
                                                        <FormLabel>Operadora</FormLabel>
                                                        { revelarSelectOperadoraCheckbox === false &&
                                                            <Button name="cdUsuarioAutomacao" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadoraCheckbox(!revelarSelectOperadoraCheckbox)}>
                                                                <Label>{dsOperadoraCheckbox}</Label>
                                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                                            </Button>
                                                        }
                                                        { revelarSelectOperadoraCheckbox === true &&
                                                            <SelectOperadoraCheckbox
                                                                setRevelarSelectOperadoraCheckbox={setRevelarSelectOperadoraCheckbox} //Hook para fechar a modal
                                                                arrayCdOperadora={arrayCdOperadora}
                                                                dsOperadoraCheckbox={dsOperadoraCheckbox}
                                                            >
                                                            </SelectOperadoraCheckbox>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="coluna-nova-mensagem-central-mensagens-10">
                                                    <Form.Group>
                                                        <FormLabel>Estado</FormLabel>
                                                        { revelarSelectUfCheckbox === false &&
                                                            <Button name="cdUsuarioAutomacao" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectUfCheckbox(!revelarSelectUfCheckbox)}>
                                                                <Label>{dsEstadoCheckbox}</Label>
                                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                                            </Button>
                                                        }
                                                        { revelarSelectUfCheckbox === true &&
                                                            <SelectUfCheckbox
                                                                setRevelarSelectUfCheckbox={setRevelarSelectUfCheckbox} //Hook para fechar a modal
                                                                arrayCdEstados={arrayCdEstados}
                                                                dsEstadoCheckbox={dsEstadoCheckbox} 
                                                            >
                                                            </SelectUfCheckbox>
                                                        }
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    { cdMetodoSelecao === 2 &&
                                        <Form.Row>
                                            <Col>
                                                <div className="grupo-nova-mensagem-central-mensagens">
                                                    <div className="coluna-nova-mensagem-central-mensagens-5">
                                                        <Form.Group>
                                                            <FormLabel>Código do cliente</FormLabel>
                                                            <TooltipContainer tooltipText='Digite o código de um cliente para adicionar o mesmo a tabela'>
                                                                <Form.Control name="codigo_cliente" value={codigoClientePesquisa} className="campo-texto-cor-3 campo-texto" placeholder={"Digite o código"} type="text"
                                                                maxlength="100" onChange={(e) => setCodigoClientePesquisa(e.target.value)} onKeyDown={e => botaoPressionadoCdCliente(e)} />
                                                            </TooltipContainer>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="coluna-nova-mensagem-central-mensagens-6">
                                                        <Form.Group>
                                                            <FormLabel>Razão social</FormLabel>
                                                            <TooltipContainer tooltipText='Razão social do cliente'>
                                                                <Form.Control name="razao_social" value={razaoSocialClientePesquisa} className="campo-texto-cor-3 campo-texto" type="text"
                                                                maxlength="100"/>
                                                            </TooltipContainer>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="coluna-nova-mensagem-central-mensagens-7">
                                                        <Button className="botao-adicionar-pedidos-novo-simcard botao-adicionar-pedidos-novo-simcard-espacador" variant="success" size="sm" onClick={() => handleAdicionarClienteTabela()}>
                                                            <img className="icone-botao-adicionar-pedidos-novo-simcard" src={adicionarMaisVerde()} alt="fechar-janela"/>
                                                        </Button>
                                                    </div>
                                                    <div className="coluna-nova-mensagem-central-mensagens-8">
                                                    <Dropzone onDrop={acceptedFiles => handleLerArquivo(acceptedFiles)}>
                                                        {({getRootProps, getInputProps}) => (
                                                            <section>
                                                                <div {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    <Button className="botao-importar-nova-mensagem-central-mensagens">
                                                                        <spam className="label-importar-nova-mensagem-central-mensagens fonte-cor-4">Importar</spam>
                                                                        <img className="botao-importar-icone" src={importarArquivo()} alt="importar"/>
                                                                    </Button>
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    }
                                </Col>
                            </Form.Row>
                        </Col>
                    </Form.Row>
                }
                { controladorPagina === 0 && 
                    <Form.Row>
                        <Col>
                            {cdMetodoSelecao === 1 &&
                                <div className="container-tabela-nova-mensagem-central-mensagens">
                                    <BootstrapTable 
                                        classes="tabela-transparente-modais"
                                        keyField='ds_email'
                                        condensed={true}
                                        data={itens}
                                        columns={colunasLogin}
                                        rowEvents={eventosLinhas}
                                        noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                        bootstrap4={true}
                                    />
                                </div>
                            }
                            {cdMetodoSelecao === 2 &&
                                <div className="container-tabela-nova-mensagem-central-mensagens">
                                    <BootstrapTable 
                                        classes="tabela-transparente-modais"
                                        keyField='ds_email'
                                        condensed={true}
                                        data={itens}
                                        columns={colunasCliente}
                                        rowEvents={eventosLinhas}
                                        noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                        bootstrap4={true}
                                    />
                                </div>
                            }
                            {cdMetodoSelecao === 3 &&
                                <div className="container-tabela-nova-mensagem-central-mensagens">
                                    <BootstrapTable 
                                        classes="tabela-transparente-modais"
                                        keyField='EMAIL'
                                        condensed={true}
                                        data={itens}
                                        columns={colunasPerfil}
                                        rowEvents={eventosLinhas}
                                        noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                        bootstrap4={true}
                                    />
                                </div>
                            }
                            {cdMetodoSelecao === 4 &&
                                <div className="container-tabela-nova-mensagem-central-mensagens">
                                    <BootstrapTable 
                                        classes="tabela-transparente-modais"
                                        keyField='ds_email'
                                        condensed={true}
                                        data={itens}
                                        columns={colunasOperadora}
                                        rowEvents={eventosLinhas}
                                        noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                        bootstrap4={true}
                                    />
                                </div>
                            }
                        </Col>
                    </Form.Row>
                }
                 { itens.length > 0 && cdMetodoSelecao === -1 &&
                    <Form.Row>
                        <Col>
                            <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} usuário(s) selecionado(s)</span>
                        </Col>
                    </Form.Row>
                }
                { itens.length > 0 && cdMetodoSelecao === 1 &&
                    <Form.Row>
                        <Col>
                            <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} usuário(s) selecionado(s)</span>
                        </Col>
                    </Form.Row>
                }
                { itens.length > 0 && (cdMetodoSelecao === 2 || cdMetodoSelecao === 3) &&
                    <Form.Row>
                        <Col>
                            <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} cliente(s) selecionado(s)</span>
                        </Col>
                    </Form.Row>
                }
                { itens.length > 0 && (cdMetodoSelecao === 4) &&
                    <Form.Row>
                        <Col>
                            <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{ itens.length } usuario(s) selecionado(s)</span>
                        </Col>
                    </Form.Row>
                }
                { controladorPagina === 1 &&
                    <Form.Row>
                        <Col>
                            <Form.Row>
                                <Col>
                                    <div className="grupo-nova-mensagem-central-mensagens">
                                        <div className="coluna-switches-nova-mensagem-central-mensagens-1">
                                            <div className="container-switch-nova-editar-regra-automacao">
                                                <div class="flipswitch">
                                                    <input type="checkbox" defaultChecked={formaEnvioEmail} name="flipswitch" class="flipswitch-cb" id="switchEmail" onClick={e => setFormaEnvioEmail(e.target.checked)}/>
                                                    <label class="flipswitch-label" for="switchEmail">
                                                        <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                        <div class="flipswitch-switch"></div>
                                                    </label>
                                                </div>
                                                <div className="fonte-cor-1 label-campo label-termos-nova-editar-regra-automacao"> E-mail</div>
                                            </div>
                                        </div>

                                        <div className="coluna-switches-nova-mensagem-central-mensagens-2">
                                            <div className="container-switch-nova-editar-regra-automacao-2">
                                                <div class="flipswitch">
                                                    <input type="checkbox" defaultChecked={formaEnvioNotificacao} name="flipswitch" class="flipswitch-cb" id="switchNotificacao" onClick={e => setFormaEnvioNotificacao(e.target.checked)}/>
                                                    <label class="flipswitch-label" for="switchNotificacao">
                                                        <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                        <div class="flipswitch-switch"></div>
                                                    </label>
                                                </div>
                                                <div className="fonte-cor-1 label-campo label-termos-nova-editar-regra-automacao"> Notificação</div>
                                            </div>
                                        </div>

                                        <div className="coluna-switches-nova-mensagem-central-mensagens-3">
                                            <div className="container-switch-nova-editar-regra-automacao-2">
                                                <div class="flipswitch">
                                                    <input type="checkbox" defaultChecked={formaEnvioPlataforma} name="flipswitch" class="flipswitch-cb" id="swithPlataforma" onClick={e => setFormaEnvioPlataforma(e.target.checked)}/>
                                                    <label class="flipswitch-label" for="swithPlataforma">
                                                        <div class="flipswitch-inner flipswitch-cor-1"></div>
                                                        <div class="flipswitch-switch"></div>
                                                    </label>
                                                </div>
                                                <div className="fonte-cor-1 label-campo label-termos-nova-editar-regra-automacao"> Plataforma</div>
                                            </div>
                                        </div>
                                        { formaEnvioPlataforma === true &&
                                            <div className="coluna-switches-nova-mensagem-central-mensagens-4">
                                                <label class="container-checkbox-item-select">
                                                    <input type="checkbox" defaultChecked={mostrarAoEntrar} onChange={e => setMostrarAoEntrar(e.target.checked)}/>
                                                    <span class="chekmark-checkbox-item-select"></span>
                                                </label>
                                                <span className="fonte-cor-1 label-checkbox-nova-mensagem-central-mensagens nao-selecionavel">Mostrar ao entrar</span>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Form.Row>
                        </Col>
                    </Form.Row>
                }
                <Divisor />
                { controladorPagina === 1 &&
                    <Form.Row>
                        <Col>
                            <div className="grupo-nova-mensagem-central-mensagens">
                                <div className="coluna-nova-mensagem-central-mensagens-12">
                                    <Form.Group>
                                        <FormLabel>Assunto</FormLabel>
                                        <TooltipContainer tooltipText='Título da mensagem'>
                                            <Form.Control defaultValue={dsAssunto} name="ds_assunto" className="campo-texto-cor-3 campo-texto" type="text" onChange={e => setDsAssunto(e.target.value)} autoComplete='off'
                                            ref={register({validate: {minimo: value => value.length > 1}})} maxlength="100"/>
                                        </TooltipContainer>
                                    </Form.Group>
                                </div>
                                <div className="coluna-nova-mensagem-central-mensagens-13">
                                    <Form.Group>
                                        <FormLabel>Nível de importância</FormLabel>
                                        { revelarSelectNivelImportancia === false &&
                                            <Button name="cdAutRegraNivel" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectNivelImportancia(!revelarSelectNivelImportancia)}>
                                                <Label>{dsNivelImportancia}</Label>
                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { revelarSelectNivelImportancia === true &&
                                            <SelectNivelImportancia
                                                setRevelarSelectNivelImportancia={setRevelarSelectNivelImportancia} //Hook para fechar a modal
                                                setCdNivelImportancia={setCdAutRegraNivel} //Código do NivelImportancia
                                                dsNivelImportancia={dsNivelImportancia}
                                            >
                                            </SelectNivelImportancia>
                                        }
                                    </Form.Group>
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                }
                { controladorPagina === 1 &&
                    <Form.Row>
                        <Col>
                            <div className="grupo-nova-mensagem-central-mensagens-editor">
                                {renderizar == false ?
                                    <SunEditor
                                        width="100%" 
                                        height="25em"
                                        setContents={corpoMensagem}
                                        lang="pt_br"
                                        setOptions={{  buttonList:[
                                            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                            ["font", "fontColor", "hiliteColor"],
                                            ["outdent", "indent"],
                                            ["align", "horizontalRule"],
                                            ["fullScreen", "showBlocks", "codeView", "template"],
                                            "/",
                                            ["print"],
                                            ["link", "image"],
                                            ["undo", "redo"],
                                            ],
                                            templates:arrayTemplates
                                        }}
                                        onChange={e => handleAtualizarMensagem(e)}
                                    />
                                :
                                    <SunEditor
                                        width="100%" 
                                        height="25em"
                                        setContents={corpoMensagem}
                                        lang="pt_br"
                                        setOptions={{  buttonList:[
                                            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                            ["font"],
                                            ["outdent", "indent"],
                                            ["align", "horizontalRule"],
                                            ["fullScreen", "showBlocks", "codeView", "template"],
                                            "/",
                                            ["print"],
                                            ["link", "image"],
                                            ["undo", "redo"],
                                            ],
                                            templates:arrayTemplates
                                        }}
                                        onChange={e => handleAtualizarMensagem(e)}
                                    />
                                }
                            </div>
                        </Col>
                    </Form.Row>
                }
                { formaEnvioNotificacao === true && controladorPagina === 1 &&
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <FormLabel>Mensagem reduzida (somente para notificação)</FormLabel>
                                <TooltipContainer tooltipText='Mensagem usada nas notificações'>
                                    <Form.Control className="campo-texto-cor-3 campo-texto-area" as="textarea" rows="3" maxlength="41" defaultValue={dsDescricao} onChange={e => handleDescricao(e.target.value)}></Form.Control>
                                </TooltipContainer>
                                <Form.Label className="contador-carateres-restantes">
                                    <div className="fonte-cor-1 label-normal">{caracteresMensagem} caracteres restantes</div>
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                }
                { controladorPagina === 1 ?
                    <Form.Row>
                        <Col>
                            <TooltipContainer tooltipText='Preencha todos os dados necessários para continuar.'>
                             { corpoMensagem != '' && corpoMensagem != '<p><br></p>' && dsAssunto != '' && cdAutRegraNivel != '' && (formaEnvioEmail === true || formaEnvioNotificacao === true || formaEnvioPlataforma === true) ? // && (itens.length > 0 || cdMetodoSelecao === -1) ? 
                                    <BotaoConfirmar />
                                 : 
                                     <BotaoConfirmar disabled /> 
                                 }  
                            </TooltipContainer>
                            
                            <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2">Cancelar</div>
                            </Button>
                            {/* {dsAssunto ==='' && corpoMensagem === '' ?
                                <Button className="botao-confirmar-desabilitado" variant="success" size="sm">
                                    <div className="fonte-cor-4">Salvar Template</div>
                                </Button>
                                :
                                <Button className="botao-confirmar" variant="success" size="sm" onClick={()=> handleSalvarTemplate()}>
                                    <div className="fonte-cor-4">Salvar Template</div>
                                </Button>
                            } */}
                        </Col>
                    </Form.Row>
                :
                    <Form.Row>
                        <Col>
                            <TooltipContainer tooltipText='Preencha todos os dados necessários para continuar.'>
                            {/* { corpoMensagem != '' && dsAssunto != '' && cdAutRegraNivel != '' && (formaEnvioEmail === true || formaEnvioNotificacao === true || formaEnvioPlataforma === true) &&  */}
                            {(itens.length > 0 || cdMetodoSelecao === -1) ?
                                    <BotaoConfirmar />
                                :
                                    <BotaoConfirmar disabled />
                                } 
                            </TooltipContainer>
                            
                            <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2">Cancelar</div>
                            </Button>
                        </Col>
                    </Form.Row>
                }
            </div>
            { revelarMensagemSucesso &&
                <RenderMensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"Mensagem cadastrada"}
                    mensagemBotao={"Finalizar"}
                />
            }
            { revelarMensagemSucessoTemplate &&
                <RenderMensagemSucesso
                    handleMensagemSucesso={handleMensagemSucessoTemplate}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"Template cadastrado com sucesso!"}
                    mensagemBotao={"Finalizar"}
                />
            }
            { revelarMensagemErro &&
                <RenderMensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Algo deu errado!"}
                    mensagemParagrafo2={"Por favor, tente mais tarde."}
                    mensagemParagrafo3={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
            { revelarMensagemAtencao &&
                <RenderMensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={revelarMensagemAtencao.titulo || "Remover item da tabela?"}
                    mensagemBotao={revelarMensagemAtencao.textoBotao || "Remover"}
                />
            }

            { revelarMensagemConfirmarN3 &&
                <RenderMensagemAtencao
                    handleMensagemAtencao={() => setRevelarMensagemConfirmarN3(false)}
                    mensagemParagrafo1="Deseja enviar esta mensagem para clientes N3?"
                    mensagemBotao={"Confirmar"}
                    textoBtnCancelar='Voltar'
                    confirmar={handleSalvar}
                />
            }

            {revelarMensagemInformacao &&
                <RenderMensagemInformacao
                    handleMensagemInformacao={handleMensagemInformacao}
                    mensagemTitulo={"Já existe um template cadastrado com esse nome!"}
                    mensagemBotao={"Confirmar"}
                />
            }
        </Form>
    )
     
}

const Label = ({children}) => <div className="fonte-cor-1 label-campo">{children}</div>;

const FormLabel = ({children}) => (
    <Form.Label>
        <Label>{children}</Label>
    </Form.Label>
)

function TooltipContainer({tooltipText, children}) {
    return (
        <OverlayTrigger key="bottom" delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>{tooltipText}</Tooltip>}>
            {children}
        </OverlayTrigger>
    )
}

function Divisor() {
    return (
        <Form.Row>
            <Col>
                <div className="campo-select-divisor-cor-1 nova-editar-regra-automacao-divisor"></div>
            </Col>
        </Form.Row>
    )
}