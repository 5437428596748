import styled from "styled-components";

const statusColors = {
  Provisionado: "#33CC99",
  Falha: "#D32F2F",
  "Chip em branco": "#0288D1",
  "Em Branco": "#0288D1",
};

export const tabelaWrapper = styled.div`
  td {
    font-family: RobotoRegular !important;
    font-size: 16px !important;
    line-height: 1.5em !important;
    color: ${({ theme }) => theme.cor9}!important;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const StatusElipse = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ status }) => statusColors[status]};
`;

export const OverlaySpinner = styled.div`
  position: fixed !important;
  z-index: 1000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: grid;
  justify-items: center;
  align-items: center;
`;

export const ContainerAlerta = styled.div`
  width: 100%;
  display: grid;
  gap: 16px;
  justify-items: center;
`;
export const MensagemAlerta = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.cor9}!important;
`;
