//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Button, Form, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import XLSX  from 'xlsx'
import Dropzone from "react-dropzone";
//ICONES
import {elipseVerde, fecharJanela, lixeiraVermelho, importarArquivo, adicionarMaisVerdeSemEllipse} from '../../../../utils/alternanciaIcones';

//REDUX
import { useDispatch } from 'react-redux';
import { revelarSolicitacaEnviarAvisoVirtueyes } from "../../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'
import decryptJWT from '../../../../utils/decryptJWT'

export default function AtivarLinhas(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1);
    const [linhaRemover, setLinhaRemover] = useState('');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [validacaoCliente, setValidacaoCliente] = useState(false)
    const [itens, setItens] = useState([])
    const [cliente, setCliente] = useState('')
    const [arquivo, setArquivo] = useState([])
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarValidacao, setRevelarValidacao] = useState(false);
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();

    let itensTemporarios = []

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    const colunas = [{
        dataField: 'n0_codigo_cliente',
        text: 'Cód. cliente',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'ds_pav_cliente',
        text: 'Razão social',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'ds_email',
        text: 'E-mail',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'cd_pav_cliente',
        text: '',
        headerClasses: 'centralizar tabela-coluna-ultima-header nao-selecionavel',
        classes: 'botoes-barra-acoes',
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleRemoverLinha(cell)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }
]

    async function handlerConsultarArquivo(){
        if(arquivo.length > 0){
            setSpinAtivo(true)
            Promise.all(
                await arquivo.map(async (item_arquivo,i)=>{
                    await api.get('/m1/consultar/cliente?n0_codigo_cliente='+item_arquivo+'&flBloqueio=true')
                        .then(function (response) {
                            if(response.status == 200 && response.data.dados[0]){
                                itensTemporarios.push(response.data.dados[0])
                            }
                    })
                })
            )
                .then(async() => {
                    await Promise.all(
                        itensTemporarios.map((item_itensTemporarios,i)=>{
                            itens.push(item_itensTemporarios)  
                        })
                    )
                    handleLinhasSelecionadas()
                    setSpinAtivo(false)
                });
        }
    }
    useEffect(()=>{
        handlerConsultarArquivo()
    },[arquivo])
   

    
    //Funcao para ler os dados do arquivo
    function handleLerArquivo(files){
        files.forEach((file) => {
            const reader = new FileReader()
            
            reader.onload = (evt) => {
                const data = evt.target.result;
                //le os dados
                const readedData = XLSX.read(data, {type: 'array'});
                //transforma em um array de json
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                const dados = XLSX.utils.sheet_to_json(ws, {header:1});
                //verifica se o esta mandando sao numeros e um arquivo certos 
                if(!isNaN(parseInt(dados[0]))){
                    setArquivo(dados)
                }
            }
            reader.readAsArrayBuffer(file)
        })
    };
    
    
    async function handlerConsultarCliente(cdCliente){
        setSpinAtivo(true)
        await api.get(cdCliente === undefined ? '/m1/consultar/cliente?n0_codigo_cliente='+cliente+'&flBloqueio=true' : '/m1/consultar/cliente?n0_codigo_cliente='+cdCliente+'&flBloqueio=true')
        .then(function (response) {
            if(response.status == 200 && response.data.dados[0]){
                itens.push(response.data.dados[0])
                //apenas ira rendeziar se for uma consulta pelo input 
                setCliente('')    
                setValidacaoCliente(false)   
                setRevelarValidacao(false)    
            }
            else{
                setValidacaoCliente(true)
            }
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setValidacaoCliente(true)
            setSpinAtivo(false)
        });
        setRenderizar(!renderizar)
    }

    function handleSalvar(){
        if(itens.length > 0){
            ColetaClickstream(1382) /*dropAcoesSolicitacaEnviarAvisoVirtueyesConfirmar*/
            enviar_aviso()
        }
        else{
           setRevelarValidacao(true)
        }
    }
    
    async function enviar_aviso(){
        setSpinAtivo(true)
        async function funcao(item_itens) {
                const resposta = await api.post('/m1/servico/enviar_email', {
                    ds_email: item_itens.ds_email,
                    tipoEmail :  "aviso_bloqueio_cliente",
                    ds_nome :  item_itens.ds_nome,
                })
                return resposta;
        }
        const getFuncaoRetorno = async () => {
            return await Promise.all(itens.map(item_itens => funcao(item_itens)));
        } 
        getFuncaoRetorno()
        .then(function(dados){
            setSpinAtivo(false)
           
            handleMensagemSucesso(false)         
        })
        .catch(function(erro){
            setSpinAtivo(false)
            setMensagemErro("Erro na solicitação")
            handleMensagemErro(false)
        })
    }

    function botaoPressionado(event) {
        if(event.key === "Enter"){
            event.preventDefault()
            handlerConsultarCliente()
        }
      }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.CD_SIMCARD);
        }
    }
    
    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itens.length)
    }
    
    function handleRemoverLinha(cd_pav_cliente){
        setRevelarMensagemAtencao(false)
        itens.forEach(function(e, index){
            if(cd_pav_cliente == e.cd_pav_cliente){
                itens.splice(index, 1);
            }
        })
        setItens(itens)
        handleLinhasSelecionadas()
    }


    async function handleCancelar() {
        ColetaClickstream(1381) /*dropAcoesSolicitacaEnviarAvisoVirtueyesCancelar*/
        itensTemporarios = []
        setSpinAtivo(false)
        despacho(revelarSolicitacaEnviarAvisoVirtueyes(false))
    }



    useEffect(() => {    
        handleLinhasSelecionadas()

        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(confirmacao ===true){
            handleRemoverLinha(linhaRemover)
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }
    
    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-codigo"){
                setCliente(valor)
            }  
        }
    }

    return(
        <Form ref={modal}>
            <div className="modal-bloquear-cliente fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Enviar aviso</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="nova-solicitacao-select-operadora">       
                            <Form.Label><div className="fonte-cor-1 label-campo">Cód. cliente</div></Form.Label>
                            <div className="bloqueio-input-adicionar-cliente">
                                <Form.Control 
                                    className="campo-texto-cor-3 campo-texto"
                                    placeholder="Digite o código" 
                                    value={cliente} 
                                    onKeyDown={e => botaoPressionado(e)}
                                    onChange={(e) => validarNumero("campo-texto-codigo", e.target.value)}

                                />
                                {cliente.length > 0 &&
                                    <Button className="btn-img-bloqueio-input-adicionar-cliente" onClick={() => handlerConsultarCliente()}>
                                        <img className="img-bloqueio-input-adicionar-cliente" src={adicionarMaisVerdeSemEllipse()} />
                                    </Button>
                                }
                            </div>
                            {validacaoCliente &&
                                <p className='bloqueio-input-adicionar-cliente-alerta label-normal fonte-cor-3'>
                                    Cliente invalido
                                </p>
                            }
                            {revelarValidacao &&
                                <span className="span-validacao-buscar-simcards-solicitacoes">Selecione um cliente</span>
                            }
                        </div>
                     </Col>
                     <Col>
                        <div className="novo-bloqueio-importar-cliente">
                            <Dropzone onDrop={acceptedFiles => handleLerArquivo(acceptedFiles)}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Button className="btn-importar-cliente">
                                            <div className="fonte-cor-4">Importar</div>
                                            <img className="img-importar-cliente" src={importarArquivo()} />
                                        </Button>
                                    </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                     </Col>
                </Form.Row>
                {itens.length > 0 &&
                    <>
                        <Form.Row>
                            <Col>
                                <div className="container-tabela-modal-bloqueio">
                                    <BootstrapTable
                                        classes="tabela"
                                        condensed={true}
                                        keyField='cd_pav_cliente' 
                                        data={itens} 
                                        columns={colunas} 
                                        rowEvents={eventosLinhas}
                                        bootstrap4={true}
                                    />
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} cliente(s) selecionado(s)</span>
                            </Col>
                        </Form.Row>
                    </>
                }
                <Form.Row className="espaco-ativar-linha">
                    <Col>
                        <Button className="botao-confirmar" variant="success" size="sm" onClick={() => handleSalvar()}>
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
