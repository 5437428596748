//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import { api } from '../../../conexoes/api';/*eslint-disable*/
import { useForm, ErrorMessage } from "react-hook-form";/*eslint-disable*/
import { identificarValidacaoAcoes } from '../../../utils/identificarValidacaoAcoes';/*eslint-disable*/
import { Card, ProgressBar, Button, Form, ButtonToolbar, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BootstrapTable from 'react-bootstrap-table-next';
//ICONES
import { elipseVerde, fecharJanela, lixeiraVermelho, alertaAtencao } from '../../../utils/alternanciaIcones';
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector } from 'react-redux';
import { revelarAdicionarPacoteExtra } from "../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemAtencaoSelect from '../../componentesModais/mensagens/mensagemAtencaoSelect'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
import '../../../css/classesGenericas.css'
import RetornarParametro from '../../../utils/decryptJWT'
import decryptJWT from '../../../utils/decryptJWT'

const valorLimite = 50
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let simcardSelecionados = [];
let itensVar = []

const routeM1FiltrarSimcard = '/m1/filtrar/simcard';

export default function AdicionarPacoteExtra(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [dsPlanoMb, setDsPlanoMb] = useState(0);
    const [dsValorTotal, setDsValorTotal] = useState("0,00");
    const [renderizar, setRenderizar] = useState(false);
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1)
    const [linhaRemover, setLinhaRemover] = useState('');
    const [permitirConfirmacao, setPermitirConfirmacao] = useState(true);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [simcards, setSimcards] = useState([]);
    //HOOKS MODAIS
    const [revelarTooltipMb, setRevelarTooltipMb] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] = useState(false)
    const [revelarMensagemAtencaoContemItemInvalido, setRevelarMensagemAtencaoContemItemInvalido] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [itens, setItens] = useState([])
    const [itensInvalidos, setItensInvalidos] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensInvalidosBusca, setItensInvalidosBusca] = useState(false);
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();
    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    const mensagemLinhasRecusadas = "Algumas linhas já tem uma solicitação igual em aberto ou o contrato delas não permite esta ação!"

    const codigoCliente = RetornarParametro('codigoClienteErpAntigo');
    const codigosClientes = RetornarParametro('codigoCliente')

    let itensTemp = []
    let itensInvalidosTemp = []

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    const colunasItensInvalidos = [{
        dataField: 'CALLERID',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'ds_motivo',
        text: 'Motivo',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },]

    const colunas = [{
        dataField: 'CD_SIMCARD',
        text: 'Código',
        headerClasses: 'nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'OPERADORA',
        text: 'Operadora',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'CALLERID',
        headerClasses: 'nao-selecionavel',
        text: 'Linha',
        classes: 'linha',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ICCID',
        headerClasses: 'nao-selecionavel',
        text: 'ICCID',
        classes: 'iccid',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'PLANO',
        headerClasses: 'nao-selecionavel',
        text: 'Plano',
        classes: 'plano',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell} MB</p>;
        }
    },
    {
        dataField: 'SALDO_MB',
        headerClasses: 'nao-selecionavel',
        text: 'Saldo',
        classes: 'saldo',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell === undefined || cell === null || isNaN(cell) ? '0.00' : cell.toFixed(2)} MB</p>;
        }
    },
    {
        dataField: 'CONSUMO_PERCENTUAL',
        headerClasses: 'centralizar nao-selecionavel',
        text: 'Consumo (%)',
        sort: true,
        classes: 'consumo',
        formatter: (cell, row) => {
            let styleProgressBar = '';
            if (!cell || cell == '')
                cell = 0;

            styleProgressBar = '#36D896'

            return (
                <ProgressBar className='barra-progresso-container'>
                    <ProgressBar style={{ 'backgroundColor': styleProgressBar }} now={cell} />
                    <span className="texto-barra-progresso fonte-cor-1">{cell === undefined ? '0.00%' : cell.toFixed(2) + '%'}</span>
                </ProgressBar>
            )
        },
    },
    {
        dataField: 'acoes',
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        text: '',
        sort: false,
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha" />
                </Button>
            )
        }
    }]

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.CD_SIMCARD);
        }
    }

    function handleLinhasSelecionadas() {
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if (itensTemp.length === 0) {
            handleCancelar()
        }
    }

    //verifica se o usuario selecionou linhas extras que nao sao permitidas na acao e aciona um alerta
    function handleLinhasSelecionadasIncapacitadas() {
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if (itensInvalidosTemp.length > 0) {
            setRevelarMensagemAtencaoInvalido(true)
        }
    }

    async function handleSalvar() {
        ColetaClickstream(1069) // 'AdicionarPacoteExtraConfirmar'

        let tempSimcards = simcards;
        setSpinAtivo(true)
        if (modoSelecaoSimcard === true) {
            tempSimcards = [];

            const urlGet = `${routeM1FiltrarSimcard}?acao=true&buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false&itensInvalidosBusca=${itensInvalidosBusca}`
            const consultaSimcards = await api.get(urlGet)
                .then(function (resposta) {
                    return resposta.data;
                })
                .catch(function (erro) {
                    throw erro;
                })

            // const consultaSimcards = await api.post(routeM1FiltrarSimcard,{
            //     "itensInvalidosBusca":itensInvalidosBusca,
            //     "buscar":conteudoBusca,
            //     "separador":separadorLinha,
            //     "filtros":filtros,
            //     "contar":false,
            //     "acao":true
            // })
            // .then(function(resposta){
            //     return resposta.data;
            // })
            // .catch(function(erro){
            //     throw erro.stack;
            // })

            if (consultaSimcards.dados.length > 0 && consultaSimcards.status === 200) {
                await Promise.all(
                    consultaSimcards.dados.map((item, i) => {
                        tempSimcards.push(item.CD_SIMCARD)
                    })
                )
            } 
            /* 
            Else comentado para evitar que a funcionalidade do Adicionar Pacote Extra ficasse atrelado 
            ao funcionamento do servidor do Protheus.
            As solicitações que foram feitas enquanto o Protheus estava offline podem ser encontradas
            na tabela dsp_protheus_nao_enviado */
               
            /* 
            else {
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            } 
            */ 
        }

        let temporario = new Date();
        let dataHora = temporario.toISOString().substring(0, 10) + ' ' + temporario.getHours() + ':' + temporario.getMinutes() + ':' + temporario.getSeconds();

        await api.post('/m1/cadastrar/solicitacao', {
            'fl_operadora': 0,
            'simcards': tempSimcards,
            'valor': dsValorTotal,
            'quantidade': dsPlanoMb,
            'cd_crm_solicitacao_tipo': 1,
            'cd_crm_solicitacao_status': 2,
            'ds_crm_solicitacao': '',
            'dt_inicio': dataHora,
            'dt_fim': undefined,
            'dt_previsao': undefined,
            ds_rota_permissao: "veye/dispositivos/acoes/adicionarPacoteExtra",
        }, { timeout: 20000 })
            .then(function (response) {
                setSpinAtivo(false)
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                /*
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
                */
            });
    }

    async function handleCancelar() {
        ColetaClickstream(1070) // AdicionarPacoteExtraCancelar
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
        simcardSelecionados = [];
        itensVar = []
        setSpinAtivo(false)
        despacho ( revelarAdicionarPacoteExtra ( false ))
    }

    function inserirData(data) {
        // setItens('')
        if (itensVar.length == totalItens) {
            for (let i = 0; i < totalItens; i++) {
                if (contador === i) {
                    let k = i
                    for (let j = 0; j < data.length; j++) {
                        itensVar[k] = data[j]
                        k++
                    }
                }
                if (i == (totalItens - 1)) {
                    itensTemp = JSON.parse(JSON.stringify(itensVar))
                    setItens(JSON.parse(JSON.stringify(itensVar)))
                    handleIdSimcards()
                }
            }
            handleLinhasSelecionadas()
        }
    }


    function handleValorTotal(tamanho) {
        setDsPlanoMb(tamanho)
        let valor = (parseFloat(itens.length * (tamanho * 2.5)).toFixed(2)).replace('.', ",")
        setDsValorTotal(valor) //(tamanho * 2)
    }

    function handleIdSimcards() {
        let tempSimcards = []
        for (let i = 0; i < itensTemp.length; i++) {
            tempSimcards.push(itensTemp[i].CD_SIMCARD)
        }
        simcardSelecionados = tempSimcards
        setSimcards(tempSimcards)
    }

    async function handlerConsultarSimcadsBusca() {
        setSpinAtivo(true)
        if (totalPaginas == 0) {
            contador = 0;
            itensVar = [];
            setItens([])
            await contarItens();
        }
        else {
            contador = (paginaAtual - 1) * valorLimite;
        }
        if (controladorPagina <= paginaAtual) {
            const urlGet = `${routeM1FiltrarSimcard}?paguinaAtual=${contador}&carregarLimit=${valorLimite}&acao=true&buscar=${conteudoBusca}&ordenador=${props.ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false&cd_pav_usuario=${decryptJWT('codigo')}&itensInvalidosBusca=${itensInvalidosBusca}`
            const consultaSimcards = await api.get(urlGet)
                .then(function (resposta) {
                    return resposta.data.dados;
                })
                .catch(function (erro) {
                    throw erro;
                })

            // const consultaSimcards = await api.post(routeM1FiltrarSimcard,{
            //     "itensInvalidosBusca":itensInvalidosBusca,
            //     "buscar":conteudoBusca,
            //     "separador":separadorLinha,
            //     "carregarLimit":valorLimite,
            //     "paguinaAtual":contador,
            //     "filtros":filtros,
            //     "contar":false,
            //     "acao":true
            // },{timeout:20000})
            // .then(function(resposta){
            //     return resposta.data.dados;
            // })
            // .catch(function(erro){
            //     throw erro.stack;
            // })

            inserirData(consultaSimcards)
            // setSpinAtivo(false)
        }
    }
    useEffect(() => {
        if (modoSelecaoSimcard && itensInvalidosBusca) {
            handlerConsultarSimcadsBusca()
        }
    }, [itensInvalidosBusca, paginaAtual])


    function handleFecharMensagemItensInvalidos() {
        setRevelarMensagemAtencaoInvalido(!revelarMensagemAtencaoInvalido)
        if (modoSelecaoSimcard) {
            if (itens <= 0) {
                handleCancelar()
            }
        }
        else {
            if (quantidadeLinhasSelecionados <= 0) {
                handleCancelar()
            }
        }
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function preencherTabela() {
            const validacao = await identificarValidacaoAcoes(props.itensSelecionados, 1, {filtros, buscaArquivo}, modoSelecaoSimcard, conteudoBusca, separadorLinha)
            if (modoSelecaoSimcard) {
                itensTemp = validacao.itensValidos
                itensInvalidosTemp = validacao.itensInvalidos
                setItensInvalidos(validacao.itensInvalidos)
                if (itensInvalidosTemp.length > 0) {
                    await Promise.all(
                        validacao.itensInvalidos.map((item_validacao, i) => {
                            simcardSelecionados += item_validacao.CD_SIMCARD + ','
                        })
                    )
                    setItensInvalidosBusca(simcardSelecionados)
                    setSimcards(validacao.itensValidos)
                }
                else {
                    handlerConsultarSimcadsBusca()
                }
            }
            else {
                setItens(validacao.itensValidos)
                setItensInvalidos(validacao.itensInvalidos)
                itensTemp = validacao.itensValidos
                itensInvalidosTemp = validacao.itensInvalidos
                handleIdSimcards();
                setItensInvalidosBusca(true)
            }
            handleLinhasSelecionadasIncapacitadas();
            setSpinAtivo(false)
        }
        preencherTabela()

        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    async function contarItens() {
        let arrayItens = []
        let apiData = [];

        if (controladorPagina <= paginaAtual) {
            const urlGet = `${routeM1FiltrarSimcard}?acao=true&buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=true&itensInvalidosBusca=${itensInvalidosBusca}`
            apiData = await api.get(urlGet)
                .then(function (resposta) {
                    return resposta;
                })
                .catch(function (erro) {
                    throw erro;
                })

            // apiData = await api.post(routeM1FiltrarSimcard,{
            //     "itensInvalidosBusca":itensInvalidosBusca,
            //     "buscar":conteudoBusca,
            //     "separador":separadorLinha,
            //     "filtros":filtros,
            //     "contar":true,
            //     "acao":true,
            // })
        }

        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for (var i = 0; i < apiData.data.dados[0].CONTADOR; i++) {
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange: (page) => { setPaginaAtual(page) }
    }

    function handleMensagemAtencao(confirmacao) {
        if (revelarMensagemAtencao === false) {
            setRevelarMensagemAtencao(true)
        } else {
            setRevelarMensagemAtencao(false)
        }
        if (confirmacao === true) {
            handleRemoverLinha(linhaRemover)
        }
    }

    async function handleRemoverLinha(CD_SIMCARD) {
        ColetaClickstream(1071) // 'AdicionarPacoteExtraRemoverLinha'
        setRevelarMensagemAtencao(false)
        if (modoSelecaoSimcard) {
            let simcardNaoSelecionados = itensInvalidosBusca;
            if (itensInvalidosBusca) {
                simcardNaoSelecionados = itensInvalidosBusca;
            }
            let simcardSelecionados = simcards
            if (simcardNaoSelecionados === undefined || simcardNaoSelecionados === null || simcardNaoSelecionados === false) {
                simcardNaoSelecionados = CD_SIMCARD + ","
            } else {
                simcardNaoSelecionados += CD_SIMCARD + ","
            }
            setItensInvalidosBusca(simcardNaoSelecionados)


            for (let i = contador; i < simcardSelecionados.length; i++) {
                if (simcardSelecionados[i] == CD_SIMCARD) {
                    simcardSelecionados.splice(i, 1)
                }
            }

            await Promise.all(
                itensVar.map((item_itensVar, i) => {
                    if (CD_SIMCARD == item_itensVar.CD_SIMCARD) {
                        itensVar.splice(i, 1);
                    }
                })
            )
            await contarItens();
            setItens(itensVar)
            setSimcards(simcardSelecionados)
        }
        else {
            itensTemp = itens
            itens.forEach(function (e, index) {
                if (CD_SIMCARD == e.CD_SIMCARD) {
                    itens.splice(index, 1);
                }
            })
            setItens(itensTemp)
            handleIdSimcards()
            handleLinhasSelecionadas()
        }

    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if (confirmacao) {
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
        }
        if (confirmacao === true) {
            //ALGUMA FUNÇÃO
        }
    }

    function handleOnBlurMb() {
        if (dsPlanoMb > 20) {
            setRevelarTooltipMb(true)
            handleValorTotal(20)
            setTimeout(() => {
                setRevelarTooltipMb(false)
            }, 3000)
        }
    }

    useEffect(() => {
        document.getElementById("mbInput").onkeypress = function (e) {
            // which é o CD da tecla apertada
            let caracter = String.fromCharCode(e.which);
            if ("1234567890".indexOf(caracter) < 0)
                return false;
        };
        //bloqueia o CRTV  V NO CAMPO DE TEXTO
        document.getElementById('mbInput').onpaste = function () {
            return false;
        }
    }, [])

    return (
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-adicionar-pacote-extra fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse" />
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Adicionar pacote extra</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela" />
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-tabela-modal">
                            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <>
                                            <BootstrapTable
                                                classes="tabela-transparente-modais"
                                                condensed={true}
                                                keyField='id'
                                                data={itens}
                                                columns={colunas}
                                                rowEvents={eventosLinhas}
                                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                bootstrap4={true}
                                                {...paginationTableProps}
                                            />
                                            {modoSelecaoSimcard &&
                                                <div className="paguinacao-tabela-modal">
                                                    <PaginacaoTabela
                                                        extrato={true}
                                                        setPaginaAtual={setPaginaAtual}
                                                        totalPaginas={totalPaginas}
                                                        totalItens={totalItens}
                                                        valorLimite={valorLimite}
                                                        paginaAtual={paginaAtual}
                                                        page={paginationProps.page}
                                                        onPageChange={paginationProps.onPageChange}
                                                    />
                                                </div>
                                            }
                                        </>
                                    )
                                }
                            </PaginationProvider>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="label-atencao-pacote-extra">
                            <span><span className="fonte-cor-3 label-normal negrito">Atenção:</span><span className="fonte-cor-1 label-normal"> o plano de dados dos SIM cards renova-se no dia 1° de cada mês.</span></span>
                        </div>
                        <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} linhas selecionadas</span>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-adicionar-pacote-extra-1">
                            <div className="coluna-adicionar-pacote-extra-1">
                                <Form.Label>
                                    <div className="fonte-cor-1 label-campo">Quantidade de MB por linha</div>
                                </Form.Label>
                                <input type="range" class="custom-range" defaultValue="0" min="0" max="20" step="1" id="customRange" onChange={e => handleValorTotal(e.target.value)} />
                            </div>
                            <div className="coluna-adicionar-pacote-extra-2">
                                <input data-tip="tooltip" data-delay-hide='1000' maxLength="4" id="mbInput" type="text" value={dsPlanoMb} onChange={event => handleValorTotal(event.target.value)} onBlur={handleOnBlurMb()} className="campo-texto-cor-3 label-mb-adicionar-pacote-extra fonte-cor-1"></input>
                                <label for="mbInput" className="mb-text fonte-cor-1">MB</label>
                                {revelarTooltipMb &&
                                    <span className="span-validacao-mb">Limite de 20MB</span>
                                }
                            </div>
                            <div className="coluna-adicionar-pacote-extra-3">
                                <div className="campo-texto-valor-adicionar-pacote-extra campo-texto-cor-3 campo-texto">
                                    <span className="label-valor-adicionar-pacote-extra-1 fonte-cor-1 negrito">Valor Total</span>
                                    <span className="label-valor-adicionar-pacote-extra-2 fonte-cor-3 negrito"> R$ {dsValorTotal} </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-switch-adicionar-pacote-extra">
                            <div class="flipswitch">
                                <input type="checkbox" name="flipswitch" class="flipswitch-cb" id="permitirConfirmacao" onChange={() => setPermitirConfirmacao(!permitirConfirmacao)} />
                                <label class="flipswitch-label" for="permitirConfirmacao">
                                    <div class="flipswitch-inner flipswitch-cor-1"></div>
                                    <div class="flipswitch-switch"></div>
                                </label>
                            </div>
                            <div className="fonte-cor-1 label-normal label-termos-adicionar-pacote-extra"> Aceito os valores dos Pacotes Extras, conforme consta no Termo de Adesão, os quais serão incluídos na fatura do mês subsequente.</div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Aceite os termos para continuar.</Tooltip>}>
                            {permitirConfirmacao === true ?
                                <Button className="botao-confirmar-desabilitado" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                                :
                                <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                            }

                        </OverlayTrigger>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
            {revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemAtencaoInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso" />
                            </div>
                            <div className="fonte-cor-1 label-janela">{itensInvalidos.length} linhas foram retiradas</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensInvalidos}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4={true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensInvalidos()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
    )
}