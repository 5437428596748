//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

//ICONES
import {elipseVerde, fecharJanela} from '../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
//CSS
import '../css/modalBloqueioFinanceiro/bloqueioFinanceiro.css'

import decryptJWT from './../utils/decryptJWT';
import { api } from '../conexoes/api';
import { revelarImportarFebrabans, revelarAssociarFatura } from '../redux/actions';

const telefoneSuporte = '555135621777'; // telefone VirtuEyes do Suporte que pode redirecionar para Financeiro
const emailFinanceiro = 'financeiro@virtueyes.com.br';

const changeBloqueioLido = (boolVal) => localStorage.setItem('jaLeuBloqueioFinaneiroCliente', boolVal)

export default function RenderModalBloqueioFinanceiro({ manejador }) {
    const [renderizarModalBloqueioFinanceiro, setRenderizarModalBloqueioFinanceiro] = useState(false)

    useEffect(() => {
        async function verificaBloqueioFinanceiroCliente () {
            const recebeuMensagemLogin = localStorage.getItem('recebeuMensagemLogin') === 'true';
            if(!recebeuMensagemLogin) changeBloqueioLido(false)

            if(!manejador) return;
    
            if(manejador === 'virtueyesClientes') { // pra mostrar somente ao logar e não quando 'carregar cliente'
                return changeBloqueioLido(true);
            }

            let clienteComBloqueioFinanceiro = false;
            const bloqueioFinanceiro = await api.get('/m1/consultar/cliente_bloqueio?cd_pav_cliente=' + decryptJWT('codigoCliente') + "&fl_acao=1");

            if (bloqueioFinanceiro?.data?.dados?.length > 0) {
                clienteComBloqueioFinanceiro = true;

                const jaLeuBloqueioFinaneiroCliente = localStorage.getItem('jaLeuBloqueioFinaneiroCliente') === 'true';

                if(clienteComBloqueioFinanceiro && (!jaLeuBloqueioFinaneiroCliente || manejador === 'dspDispositivos' || manejador === 'fatDispositivos')) {
                    setRenderizarModalBloqueioFinanceiro(true);
                }
            }
            // else {
            //     clienteComBloqueioFinanceiro = false
            // }
        }
        verificaBloqueioFinanceiroCliente();
    }, [manejador]);

    return (
        <div id={"filtro-por-arquivo"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
            {renderizarModalBloqueioFinanceiro && 

                <div className="componente-modal-overlay-filtro-arquivo">
                    <div className="componente-modal-bloqueio-financeiro-container">
                        <ModalBloqueioFinanceiro
                            renderizar={renderizarModalBloqueioFinanceiro}
                            setRenderizar={setRenderizarModalBloqueioFinanceiro}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

function ModalBloqueioFinanceiro(props) {
    //HOOKS VALIDAÇÂO
    // const { handleSubmit } = useForm();
    
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    // async function handleSalvar(e){
    //     e.preventDefault();
    // }

    async function handleCancelar() {
        changeBloqueioLido(true)

        props.setRenderizar(!props.renderizar)
        despacho(revelarImportarFebrabans(false))
        despacho(revelarAssociarFatura(false))
    }

    return(
        <Form ref={modal} /*onSubmit={handleSubmit(() => handleSalvar())}*/>
            <div className="modal-bloqueio-financeiro fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Bloqueio financeiro</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                {/* CORPO */}

                <p className="fonte-cor-1 bloqueio-texto-1">
                    Identificamos que você possui pendências financeiras junto à VIRTUEYES. 
                    Por esse motivo, seu tráfego de dados encontra-se momentaneamente indisponibilizado, conforme previsto em seu contrato.
                </p>

                <p className="fonte-cor-1 bloqueio-texto-1">
                    Para restabelecimento do tráfego de dados de sua conta, por favor, regularize sua situação junto ao nosso setor financeiro.
                </p>
                
                <p className="fonte-cor-1">
                    Caso esteja com dificuldades, necessite esclarecimentos ou já tenha regularizado sua situação há mais de 24 horas, 
                    entre em contato pelo Telefone/Whatsapp <a href={`https://api.whatsapp.com/send/?phone=${telefoneSuporte}`} target="_blank" rel="noopener noreferrer" id="link-tel-suporte">51 35621777</a> ou 
                    pelo e-mail <a href={`mailto:${emailFinanceiro}`} target="_blank" rel="noopener noreferrer" id="link-email-suporte">{emailFinanceiro}</a>. 
                </p>

                <Form.Row className="espaco-reativar-linha">
                    <Col>
                        <Link to="/veye/financeiro" className={"botao-link-financeiro"} variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">VERIFIQUE SUAS PENDÊNCIAS</div>
                        </Link>
                    </Col>
                </Form.Row>
            </div>
        </Form>
    )
}