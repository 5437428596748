import React, { useRef, useEffect, useState } from "react";

export default function Countdown(props) {
    
  const [contar, setContar] = useState(true);
  const [num, setNum] = useState(props.segundosEspera);
  const [pause, setPause] = useState(false);

  let intervalRef = useRef();

  const decreaseNum = () => setNum((prev) => prev - 1);

  useEffect(() => {
    intervalRef.current = setInterval(decreaseNum, 1000);

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (num <= 0 && contar) {
      const handleClick = () => {
        if (!pause) {
          clearInterval(intervalRef.current);
        } else {
          intervalRef.current = setInterval(decreaseNum, 1000);
        }
        setPause((prev) => !prev);
      };

      handleClick();
      setContar(false);
      props.setSegundosEspera(0);
    }
  }, [num, contar, pause, props]);

  if(props.invisible) return <></>;

  // return (
  //   <div>
  //     {num}
  //     {/* {num >=0 && num} */}
  //   </div>
  // );
}
