import React, { useState, useEffect } from "react";/*eslint-disable*/
import {api} from '../../conexoes/api';/*eslint-disable*/
import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";
import { setaDropdown } from '../../utils/alternanciaIcones';
import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
import SelectGenerico from '../componentesModais/selects/selectGenerico'
import decryptJWT from '../../utils/decryptJWT'
//COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

export default function FiltroFaturasCicloVida(props) {

    const [cdCliente, setCdCliente] = useState(decryptJWT("codigo"))
    const [filtroOperadora, setFiltroOperadora] = useState('')
    const [revelarSelectEmpresa, setRevelarSelectEmpresa] = useState(false);
    const [itensEvento, setItensEvento] = useState([])
    const [dsEmpresa, setDsEmpresa] = useState('')
    const [cdEmpresa, setCdEmpresa] = useState('')
    const [dummy, setDummy] = useState()
    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []
    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setFiltroOperadora(filtros.OPERADORA)
            setDsEmpresa(filtros.EMPRESA)
        }
    }

    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    useEffect(() => {
        handlerCarregarEventos()
    }, []);

    async function handlerCarregarEventos(){
        await api.get("/m1/consultar/aut_evento")
            .then((response)=>{
                if(response.data.status == 200){
                    setItensEvento(response.data.dados)
                }
            })
    }

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1288) /*fatContasTabelaDropFiltrosFiltrar*/
        if(filtroOperadora !== '' && filtroOperadora !== undefined)arrayFiltrosSimcard.push("Operadora: "+filtroOperadora+"?OPERADORA")
        if(dsEmpresa !== '' && dsEmpresa !== undefined)arrayFiltrosSimcard.push("Empresa: "+dsEmpresa+"?EMPRESA")
       
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroOperadora == '') verificador.push(1)
            if(dsEmpresa == '') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 2) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroOperadora("")
            setDsEmpresa("")
            props.setFoiLimpado(false)
        }
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroOperadora,dsEmpresa,props.foiLimpado]);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }
    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                   {/* ------------------- */}
                   <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-operadora","imagem-filtro-operadora"); ColetaClickstream(1286) /*fatContasTabelaDropFiltrosOperadora*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Operadora </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-operadora"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-operadora"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperadora('Vivo')}>Vivo
                            <input defaultChecked={handlerCheck("OPERADORA","Vivo")} type="radio" id="OPERADORA_VIVO" name="OPERADORA" value="Vivo"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperadora('Claro')}>Claro
                            <input defaultChecked={handlerCheck("OPERADORA","Claro")} type="radio" id="OPERADORA_CLARO" name="OPERADORA" value="Claro"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperadora('Tim')}>Tim
                            <input defaultChecked={handlerCheck("OPERADORA","Tim")} type="radio" id="OPERADORA_TIM" name="OPERADORA" value="Tim"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroOperadora('Algar')}>Algar
                            <input defaultChecked={handlerCheck("OPERADORA","Algar")} type="radio" id="OPERADORA_ALGAR" name="OPERADORA" value="Algar"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                     {/* ------------------- */}
                     <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-empresa","imagem-filtro-empresa"); ColetaClickstream(1287) /*fatContasTabelaDropFiltrosEmpresa*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Empresa </Form.Label>
                        <img alt="setaDropdown" id={"imagem-filtro-empresa"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-empresa"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-texto-conta-filtros fonte-cor-1">Selecione a empresa</label>
                        <div className="conteudo-calendario-filtro">
                            <Form.Control id="EMPRESA" name="EMPRESA" type="text" value={dsEmpresa == 'Selecione' ? '': dsEmpresa} className="d-none"/>
                            {revelarSelectEmpresa === false &&
                            <>
                                <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectEmpresa(!revelarSelectEmpresa)}>
                                    <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsEmpresa === ""?"Selecione":dsEmpresa}</div>
                                    <img className={revelarSelectEmpresa?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                </Button>
                            </>
                            }
                            {revelarSelectEmpresa === true &&
                                <SelectGenerico
                                    paramRota={"empresas_fatContas"}
                                    paramCampo1={cdCliente}
                                    paramCampo2={null}
                                    paramCampo3={null}
                                    paramCampo4={null}
                                    setCodigo={setCdEmpresa}
                                    setDescricao1={setDsEmpresa}
                                    setDescricao2={setDummy}
                                    setDescricao3={setDummy}
                                    setDescricao4={setDummy}
                                    setRevelarSelect={setRevelarSelectEmpresa}
                                    placeholder={dsEmpresa}
                                    sufixo={""}
                                >
                                </SelectGenerico>
                            }
                        </div>
                    </div>
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}