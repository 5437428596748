//IMPORTAÇÕES
import React, { useEffect, useRef, useState } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import { Button, Col, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador2, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {formatarData} from '../../../utils/formatadorDataTabela'
//ICONES
import {elipseVerde, fecharJanela} from '../../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
import { revelarHistoricoAcessos } from "../../../redux/actions/index";
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'

import PaginacaoTabela from '../../../utils/paginacaoTabela'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contadormodal = 0
let controladorPaginaModal = 1
let totalPaginasModal = 0
let totalItensModal = 0
let itensVarModal = []

export default function HistoricoAcessos(props) {
    //HOOKS PRINCIPAIS
    const [itens, setItens] = useState([])
    const [renderizar, setRenderizar] = useState(true) // eslint-disable-next-line
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [valorLimite, setValorLimite] = useState(50); // eslint-disable-next-line
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const colunas = [{
        dataField: 'cd_request_log',
        text: 'ID',
        headerClasses: 'nao-selecionavel',
        hidden:true,
    },
    {
        dataField: 'usuario',
        text: 'Acessado por',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell !== undefined ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'dt_created_at',
        text: 'Data',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            if(cell){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                    cell = cell[0]
                }
            }
            return formatarData(cell);
        }
    },
    {
        dataField: 'descricao',
        text: 'Descrição',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell !== '' && cell !== undefined ? cell : "-"}</p>;
        }
    }]

    async function handleCancelar() {
        ColetaClickstream(1419) // virtuTabelaClientesHistoricoAcessosCancelar
        contadormodal = 0
        controladorPaginaModal = 1
        totalPaginasModal = 0
        totalItensModal = 0
        itensVarModal = []
        despacho(revelarHistoricoAcessos(false))
    }

    useEffect(() => {
        restaurarConfigsIniciaisPagina()
    }, [renderizar])

    useEffect(() => {
        setSpinAtivo(true)
        async function carregarTabela(){
            if(totalPaginasModal === 0){
                contadormodal = 0;
                itensVarModal = [];
                setItens([])
                await contarItens();
            }else{
                contadormodal = (paginaAtual - 1) * valorLimite;
            }
            if (controladorPaginaModal <= paginaAtual) {
                const { data } = await api.get('/m1/log/consultar/requestLog?paginaAtual='+contadormodal+'&carregarLimit='+valorLimite+'&codigo_cliente='+props.codigo_cliente+'&virtueyes=false');
                inserirData(data.dados)
            }
        }
        carregarTabela()
        setSpinAtivo(false)
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar, paginaAtual]);

    function inserirData(data){
        for(let i=0;i<totalItensModal;i++){
            if (contadormodal === i){
                let k = i
                for(let j=0;j<data.length;j++){
                itensVarModal[k] = data[j]
                k++
                }
            }
            if(i === (totalItensModal-1)){
                setItens(JSON.parse(JSON.stringify(itensVarModal)))
            }
        }
    }

    async function contarItens(){
        let apiData = []
        let arrayItens = []
        if (controladorPaginaModal <= paginaAtual) { 
            apiData = await api.get('/m1/log/consultar/requestLog?contar=true&codigo_cliente='+props.codigo_cliente+'&virtueyes=false');
        }
        itensVarModal = []
        totalPaginasModal = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItensModal = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVarModal = arrayItens
    }

    function restaurarConfigsIniciaisPagina(){
        itensVarModal = []
        totalPaginasModal = 0
        contadormodal = 0
        controladorPaginaModal = 1
        totalItensModal = 0
        setPaginaAtual(1)
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    return(
        <Form ref={modal}>
            <div className="modal-acao-historico-acesso fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Histórico de acessos</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-tabela-historico-acesso">
                            <PaginationProvider pagination={Paginador2(opcoesPaginacao)} >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <>
                                            <BootstrapTable
                                                hover
                                                keyField='cd_request_log'
                                                data={ itens }
                                                columns={ colunas }
                                                bootstrap4 = {true}
                                                classes="tabela-transparente-modais"
                                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                { ...paginationTableProps }
                                            />
                                            <div className="grupo-botao-paginacao-tabela-historico-acessos">
                                                <PaginacaoTabela
                                                    modal={true}
                                                    setPaginaAtual={setPaginaAtual}
                                                    totalPaginas={totalPaginasModal}
                                                    totalItens={totalItensModal}
                                                    valorLimite={valorLimite}
                                                    paginaAtual={paginaAtual}
                                                    page={paginationProps.page}
                                                    onPageChange={paginationProps.onPageChange}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                            </PaginationProvider>
                        </div>
                    </Col>
                </Form.Row>
                <text className="itens-exibidos-tabela-historico-acessos fonte-cor-1">
                        Mostrando 
                    <span className="label-tabela-itens-exibidos">{contadormodal + 1}</span>
                        - 
                    <span className="label-tabela-itens-exibidos">{ contadormodal + valorLimite <= totalItensModal ? contadormodal + valorLimite : totalItensModal }</span>
                        de 
                    <span className="label-tabela-itens-exibidos">{totalItensModal}</span>
                </text>
                <div className="modal-acao-historico-acessos-espacador"/>
            </div>
        </Form>
    )
}