//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import { api, api_opencellid, api_svdispositivos } from '../../../conexoes/api';/*eslint-disable*/
import moment from 'moment';/*eslint-disable*/
import { Button, Image, Dropdown, Accordion, Form, Col, } from 'react-bootstrap';
import { validadorPermissaoElemento } from '../../../utils/verificadorPermissaoElemento'
import { useSelector } from 'react-redux';
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js' 
//ICONES
import { iconeLocalizacaoAtivado, iconeDescricaoAtivado, iconeConexoesAtivado, iconeConsumoAtivado, elipseVerde, calendario, iconeLocalizacao, iconeConexoes, iconeConsumo, iconeDescricao } from '../../../utils/alternanciaIcones'
//ESTILIZACAO
import '../../../css/painelDispositivos/dspDetalhamentoLinha/detalhamentoLinha.css'
//COMPONENTES
import Timeline from './timeline/timeline'
import Historico from '../../calendario/calendario'
import DescricaoCliente from './dspDescricaoCliente/descricaoCliente'
import GraficoConsumoCiclos from './graficoConsumoCiclos/graficoConsumoCiclos'
import GraficoConsumoCicloAtual from './graficoConsumoCicloAtual/graficoConsumoCicloAtual'
import ExtratoConexao from './dspExtrato/extratoConexao'
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'

import MapaGoogleCluster from '../../googleMap/googleMapCluster'
import MapaGoogle from '../../googleMap/googleMap'

import decryptJWT from '../../../utils/decryptJWT'

import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
// import { Sector, Label, Cell, Bar, Pie, PieChart, AreaChart, Area, XAxis, YAxis, CartesianGrid, BarChart, Tooltip, Legend, ResponsiveContainer } from 'recharts';


// import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
// import { initial } from 'lodash';

/* TEMPLATE BÁSICA PARA CRIAÇÃO DE MODAIS. */

let valorLimite = 10
let itensVar = []
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let contador = 0
export default function DetalhamentoLinha(props) {

    const [idMenu, setIdMenu] = useState('-1')
    const [dataInicio, setDataInicio] = useState('')
    const [dataFinal, setDataFinal] = useState('')
    const [dataMin, setDataMin] = useState(moment().subtract(90, "days")._d)
    const [dataMax, setDataMax] = useState(new Date())
    const [dataMinHistorico, setDataMinHistorico] = useState(moment().subtract(30, "days")._d)
    const [revelarFiltroPeriodo, setRevelarFiltroPeriodo] = useState(false)
    const [revelarTela, setRevelarTela] = useState()
    const [ciclo, setCiclo] = useState('Selecione')
    const [phCiclo, setPhCiclo] = useState('Selecione')
    const [filtroRadio, setFiltroRadio] = useState()
    const [renderizar, setRenderizar] = useState(false);
    const [itensHistorico, setItensHistorico] = useState([]);
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [cicloFim, setCicloFim] = useState();
    const cdSimcard = props.cdSimcard;
    const [objLatLongMapaHistorico, setObjLatLongMapaHistorico] = useState([])
    const [itensTimeline, setItensTimeline] = useState([])
    const [validadorMudandoMapaParaHistorico, setValidadorMudandoMapaParaHistorico] = useState(false)
    const [bloquearMapaHistorico, setBloquearMapaHistorico] = useState(false)
    const [realizouFiltragemTimeline, setRealizouFiltragemTimeline] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)

    const [permissaoDescricao, setPermissaoDescricao] = useState()
    const [permissaoHistoricoLocalizacao, setPermissaoHistoricoLocalizacao] = useState()
    const [permissaoUltimaLocalizacao, setPermissaoUltimaLocalizacao] = useState()
    const [permissaoTimeline, setPermissaoTimeline] = useState()
    const [permissaoExtrato, setPermissaoExtrato] = useState()
    const [permissaoCicloAtual, setPermissaoCicloAtual] = useState()
    const [permissaoUltimo6Ciclo, setPermissaoUltimo6Ciclo] = useState()

    const [horasOffline, setHorasOffline] = useState(0); // horasOffline horasOnline horasTotais
    const [horasOnline, setHorasOnline] = useState(0);
    const [horasTotais, setHorasTotais] = useState(0);
    const [disponibilidadeOnline, setDisponibilidadeOnline] = useState('0,00');
    const [disponibilidadeOffline, setDisponibilidadeOffline] = useState('100,00');
    const [disponibilidade, setDisponibilidade] = useState([
        { value: 0, cor: '#B1B1B1' },
        { value: 100, cor: '#B1B1B1' }
    ]);

    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    let validadorCount = true;
    let btnCarregarDisabled = false;
    let ativarBotaoConfirmar = false;
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);
    const buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    const [objLatLong, setObjLatLong] = useState([])

    const [mostrarBotaoDownload, setMostrarBotaoDownload] = useState([]) // botão download "relatório""

    useEffect(() => {
        async function handlerVerificarPermissaoElementos() {
            setPermissaoDescricao(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento/descricao"))
            setPermissaoHistoricoLocalizacao(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento/historicoLocalizacao"))
            setPermissaoUltimaLocalizacao(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento/ultimaConexao"))
            setPermissaoTimeline(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento/timeline"))
            setPermissaoExtrato(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento/extratoConexoes"))
            setPermissaoCicloAtual(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento/graficoConsumoCiclo"))
            setPermissaoUltimo6Ciclo(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento/graficoConsumoUltimo6Ciclos"))
        }

        handlerVerificarPermissaoElementos()
    }, [])


    useEffect(() => {
        setSpinAtivo(true)
        async function carregarMapaHistorico() {

            setRealizouFiltragemTimeline(false)
            if (revelarTela === 'Extrato' && validadorMudandoMapaParaHistorico === true) {
                contador = 0;
                valorLimite = 7;
                totalPaginas = 0
                setValidadorMudandoMapaParaHistorico(false)
            }

            if (totalPaginas == 0) {
                setPaginaAtual(1)
                validadorCount = true
                contador = 0;
                itensVar = [];
                setItens([])
            } else {
                contador = (paginaAtual - 1) * valorLimite;
            }

            if (revelarTela === 'Histórico' && validadorMudandoMapaParaHistorico === false) {
                setObjLatLongMapaHistorico([])
                setPaginaAtual(1)
                contador = undefined;
                valorLimite = undefined;
                itensVar = [];
                totalPaginas = 0
                validadorCount = true
                setValidadorMudandoMapaParaHistorico(true)
            }

            if (filtroRadio) {
                let dadosApi;
                setObjLatLongMapaHistorico([])
                setItensTimeline([])

                if (revelarTela === 'Histórico') {
                    if (filtroRadio === 'Periodo') {
                        dadosApi = await api_svdispositivos.get(`/api/v2/devices/simcard/${props.gsm}/geolocation?datainicio=${moment(dataInicio).format('YYYY-MM-DD')}&horainicio=${moment(dataInicio).format('HH:mm:ss')}&datafim=${moment(dataFinal).format('YYYY-MM-DD')}&horafim=${moment(dataFinal).format('HH:mm:ss')}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}&ultima=false`)
                    } else {
                        const datafim = moment().format('YYYY-MM-DD')
                        const horafim = moment().format('HH:mm:ss')
                        const horainicio = horafim
                        let datainicio
                        if (filtroRadio === '1dia') datainicio = moment().subtract(1, "days").format('YYYY-MM-DD')
                        else if (filtroRadio === '2dias') datainicio = moment().subtract(2, "days").format('YYYY-MM-DD')
                        else if (filtroRadio === '7dias') datainicio = moment().subtract(7, "days").format('YYYY-MM-DD')
                        else if (filtroRadio === '15dias') datainicio = moment().subtract(15, "days").format('YYYY-MM-DD')
                        else if (filtroRadio === '30dias') datainicio = moment().subtract(30, "days").format('YYYY-MM-DD')

                        dadosApi = await api_svdispositivos.get(`/api/v2/devices/simcard/${props.gsm}/geolocation?datainicio=${datainicio}&horainicio=${horainicio}&datafim=${datafim}&horafim=${horafim}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}&ultima=false`)
                    }
                } else {

                    if (filtroRadio === 'Periodo') {
                        if (revelarTela === 'Timeline') {
                            // dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_periodo?periodo_inicio=' + moment(dataInicio).format('YYYYMMDD') + "&periodo_fim=" + moment(dataFinal).format('YYYYMMDD') + "&linha=" + props.gsm + '&timeline=true')
                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/timeline?datainicio=${moment(dataInicio).format('YYYY-MM-DD')}&horainicio=${moment(dataInicio).format('HH:mm:ss')}&datafim=${moment(dataFinal).format('YYYY-MM-DD')}&horafim=${moment(dataFinal).format('HH:mm:ss')}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)
                        } else {
                            // dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_periodo?periodo_inicio=' + moment(dataInicio).format('YYYYMMDD') + "&periodo_fim=" + moment(dataFinal).format('YYYYMMDD') + "&linha=" + props.gsm + "&paginaAtual=" + contador + '&carregarLimit=' + valorLimite + '&count=' +
                            //     validadorCount + '&hora_inicio=' + moment(dataInicio).format('HH:mm') + '&hora_fim=' + moment(dataFinal).format('HH:mm'))
                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/connections?datainicio=${moment(dataInicio).format('YYYY-MM-DD')}&horainicio=${moment(dataInicio).format('HH:mm:ss')}&datafim=${moment(dataFinal).format('YYYY-MM-DD')}&horafim=${moment(dataFinal).format('HH:mm:ss')}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}&count=${validadorCount}&pagina=${paginaAtual}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)
                        }
                    } else if (filtroRadio === 'Ciclo') { // 

                        if (revelarTela === 'Timeline') {
                            dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_ciclo?ciclo_inicio=' + ciclo + "&ciclo_fim=" + cicloFim + "&linha=" + props.gsm + '&timeline=true');
                        } else {
                            dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_ciclo?ciclo_inicio=' + ciclo + "&ciclo_fim=" + cicloFim + "&linha=" + props.gsm + "&paginaAtual=" + contador + '&carregarLimit=' + valorLimite + '&count=' + validadorCount);
                        }
                    }
                    else if (filtroRadio === '1dia') {
                        if (revelarTela === 'Timeline') {
                            // dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_dias?dias=1&linha=' + props.gsm + '&timeline=true');

                            const datafim = moment().format('YYYY-MM-DD')
                            const horafim = moment().format('HH:mm:ss')
                            const datainicio = moment().subtract(1, "days").format('YYYY-MM-DD')
                            const horainicio = horafim

                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/timeline?datainicio=${datainicio}&horainicio=${horainicio}&datafim=${datafim}&horafim=${horafim}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)
                        } else {
                            // const url_get = '/m1/consultar/detalhamento_simcard/extrato_conexoes_dias?dias=1&linha=' + props.gsm + '&paginaAtual=' + contador + '&carregarLimit=' + valorLimite + '&count=' + validadorCount

                            const datafim = moment().format('YYYY-MM-DD')
                            const horafim = moment().format('HH:mm:ss')
                            const datainicio = moment().subtract(1, "days").format('YYYY-MM-DD')
                            const horainicio = horafim

                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/connections?datainicio=${datainicio}&horainicio=${horainicio}&datafim=${datafim}&horafim=${horafim}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}&count=true&pagina=${paginaAtual}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)
                        }
                        // --------------------------------------------------------------------------------------------------
                        // --------------------------------------------------------------------------------------------------
                    } else if (filtroRadio === '2dias') {

                        if (revelarTela === 'Timeline') {
                            // dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_dias?dias=2&linha=' + props.gsm + '&timeline=true');
                            const datafim = moment().format('YYYY-MM-DD')
                            const horafim = moment().format('HH:mm:ss')
                            const datainicio = moment().subtract(2, "days").format('YYYY-MM-DD')
                            const horainicio = horafim

                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/timeline?datainicio=${datainicio}&horainicio=${horainicio}&datafim=${datafim}&horafim=${horafim}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)

                        } else {
                            // dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_dias?dias=2&linha=' + props.gsm + '&paginaAtual=' + contador + '&carregarLimit=' + valorLimite + '&count=' + validadorCount);
                            const datafim = moment().format('YYYY-MM-DD')
                            const horafim = moment().format('HH:mm:ss')
                            const datainicio = moment().subtract(2, "days").format('YYYY-MM-DD')
                            const horainicio = horafim

                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/connections?datainicio=${datainicio}&horainicio=${horainicio}&datafim=${datafim}&horafim=${horafim}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}&count=true&pagina=${paginaAtual}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)
                        }
                    } else if (filtroRadio === '7dias') {
                        if (revelarTela === 'Timeline') {
                            // dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_dias?dias=7&linha=' + props.gsm + '&timeline=true');
                            const datafim = moment().format('YYYY-MM-DD')
                            const horafim = moment().format('HH:mm:ss')
                            const datainicio = moment().subtract(7, "days").format('YYYY-MM-DD')
                            const horainicio = horafim

                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/timeline?datainicio=${datainicio}&horainicio=${horainicio}&datafim=${datafim}&horafim=${horafim}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)
                        } else {
                            // dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_dias?dias=7&linha=' + props.gsm + '&paginaAtual=' + contador + '&carregarLimit=' + valorLimite + '&count=' + validadorCount);
                            const datafim = moment().format('YYYY-MM-DD')
                            const horafim = moment().format('HH:mm:ss')
                            const datainicio = moment().subtract(7, "days").format('YYYY-MM-DD')
                            const horainicio = horafim

                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/connections?datainicio=${datainicio}&horainicio=${horainicio}&datafim=${datafim}&horafim=${horafim}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}&count=true&pagina=${paginaAtual}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)
                        }
                    } else if (filtroRadio === '15dias') {
                        if (revelarTela === 'Timeline') {
                            // dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_dias?dias=15&linha=' + props.gsm + '&timeline=true');
                            const datafim = moment().format('YYYY-MM-DD')
                            const horafim = moment().format('HH:mm:ss')
                            const datainicio = moment().subtract(15, "days").format('YYYY-MM-DD')
                            const horainicio = horafim

                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/timeline?datainicio=${datainicio}&horainicio=${horainicio}&datafim=${datafim}&horafim=${horafim}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)
                        } else {
                            // dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_dias?dias=15&linha=' + props.gsm + '&paginaAtual=' + contador + '&carregarLimit=' + valorLimite + '&count=' + validadorCount);
                            const datafim = moment().format('YYYY-MM-DD')
                            const horafim = moment().format('HH:mm:ss')
                            const datainicio = moment().subtract(15, "days").format('YYYY-MM-DD')
                            const horainicio = horafim

                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/connections?datainicio=${datainicio}&horainicio=${horainicio}&datafim=${datafim}&horafim=${horafim}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}&count=true&pagina=${paginaAtual}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)
                        }
                    } else if (filtroRadio === '30dias') {
                        if (revelarTela === 'Timeline') {
                            // dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_dias?dias=30&linha=' + props.gsm + '&timeline=true');
                            const datafim = moment().format('YYYY-MM-DD')
                            const horafim = moment().format('HH:mm:ss')
                            const datainicio = moment().subtract(30, "days").format('YYYY-MM-DD')
                            const horainicio = horafim

                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/timeline?datainicio=${datainicio}&horainicio=${horainicio}&datafim=${datafim}&horafim=${horafim}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)
                        } else {
                            // dadosApi = await api.get('/m1/consultar/detalhamento_simcard/extrato_conexoes_dias?dias=30&linha=' + props.gsm + '&paginaAtual=' + contador + '&carregarLimit=' + valorLimite + '&count=' + validadorCount);
                            const datafim = moment().format('YYYY-MM-DD')
                            const horafim = moment().format('HH:mm:ss')
                            const datainicio = moment().subtract(30, "days").format('YYYY-MM-DD')
                            const horainicio = horafim

                            const url_get_sv = `/api/v2/devices/simcard/${props.gsm}/connections?datainicio=${datainicio}&horainicio=${horainicio}&datafim=${datafim}&horafim=${horafim}&codigocliente=${decryptJWT('codigoClienteErpAntigo')}&count=true&pagina=${paginaAtual}`

                            dadosApi = await api_svdispositivos.get(url_get_sv)
                        }
                    }

                    if (dadosApi?.data?.connections?.length > 0) { // EXTRATO

                        setDisponibilidade(
                            [
                                { value: +parseFloat(dadosApi.data.disponibilidade.porcentagemOnline).toFixed(0), cor: '#36D896' },
                                { value: +parseFloat(dadosApi.data.disponibilidade.porcentagemOffline).toFixed(0), cor: '#CD2D37' }
                            ]
                        )

                        setDisponibilidadeOnline(dadosApi.data.disponibilidade.porcentagemOnline)
                        setDisponibilidadeOffline(dadosApi.data.disponibilidade.porcentagemOffline)
                        setHorasOffline(+parseFloat(dadosApi.data.disponibilidade.horasOffline).toFixed(0))
                        setHorasOnline(+parseFloat(dadosApi.data.disponibilidade.horasOnline).toFixed(0))
                        setHorasTotais(+parseFloat(dadosApi.data.disponibilidade.horasTotais).toFixed(0))
                        // } else if (dadosApi?.data?.dados?.length > 0) { // FICA COMENTADO PARA QUANDO FOR IMPLEMENTADO O GRAFICO DE DISPONIBILIDADE OPERACIONAL NA TIMELINE
                        //     TIMELINE
                        //     setDisponibilidade(
                        //         [
                        //             { value: +parseFloat(dadosApi.data.dados[0].disponibilidade.porcentagemOnline).toFixed(0), cor: '#36D896' },
                        //             { value: +parseFloat(dadosApi.data.dados[0].disponibilidade.porcentagemOffline).toFixed(0), cor: '#CD2D37' }
                        //         ]
                        //     )
                        //     setDisponibilidadeOnline(dadosApi.data.dados[0].disponibilidade.porcentagemOnline)
                        //     setDisponibilidadeOffline(dadosApi.data.dados[0].disponibilidade.porcentagemOffline)

                        //     setHorasOffline(dadosApi.data.dados[0].disponibilidade.horasOffline.toFixed(0))
                        //     setHorasOnline(dadosApi.data.dados[0].disponibilidade.horasOnline.toFixed(0))
                        //     setHorasTotais(dadosApi.data.dados[0].disponibilidade.horasTotais.toFixed(0))
                    } else {
                        setDisponibilidade(
                            [
                                { value: 0, cor: '#36D896' },
                                { value: 100, cor: '#CD2D37' }
                            ]
                        )
                        let tempoHorasTotais
                        if (filtroRadio === 'Periodo') {
                            tempoHorasTotais = ((dataFinal.getTime() - dataInicio.getTime()) / 1000) / 3600
                        } else if (filtroRadio === '1dia') {
                            tempoHorasTotais = 24
                        } else if (filtroRadio === '2dias') {
                            tempoHorasTotais = 48
                        } else if (filtroRadio === '7dias') {
                            tempoHorasTotais = 168
                        } else if (filtroRadio === '15dias') {
                            tempoHorasTotais = 360
                        } else if (filtroRadio === '30dias') {
                            tempoHorasTotais = 720
                        }

                        setDisponibilidadeOnline('0,00')
                        setDisponibilidadeOffline('100,00')
                        setHorasOffline(tempoHorasTotais)
                        setHorasOnline(0)
                        setHorasTotais(tempoHorasTotais)
                    }
                }
                if (!dadosApi.data.connections && revelarTela !== 'Timeline' && revelarTela !== 'Histórico') {
                    setItensTimeline([]);
                    setRealizouFiltragemTimeline(true)
                    setItensHistorico([])
                } else {

                    { revelarTela === 'Histórico' ? setBloquearMapaHistorico(true) : setBloquearMapaHistorico(false) }
                    if (totalPaginas == 0) {
                        validadorCount = false

                        await contarItens(dadosApi.data.item_count);
                    }
                    if (revelarTela === 'Timeline') {

                        let auxTimeline = []

                        dadosApi.data.timeline.map((parte1) => {
                            auxTimeline.push(parte1)
                        })
                        setItensTimeline(JSON.parse(JSON.stringify(auxTimeline)));
                    }
                    if (revelarTela === 'Histórico') {
                        setItensHistorico(dadosApi.data.geolocations)
                        formatadorDeDadosMapa(dadosApi.data.geolocations);
                        // formatadorDeDadosMapa(dadosApi.data.dados.extratos);
                    }
                    // {(bloquearMapaHistorico === false && revelarTela != 'Histórico') || (bloquearMapaHistorico === true && revelarTela === 'Histórico' ) && 
                    // COMENTADO PARA RESOLVER O BUG ONDE O MAPA DE HISTÓRICO SÓ É CARREGADO NO SEGUNDO CLICK
                    // }

                    inserirData(await formatadorDeDados((dadosApi.data.connections)))
                    // inserirData(dadosApi.data.connections)
                }
                setSpinAtivo(false);
            }
            setSpinAtivo(false);
        }

        carregarMapaHistorico();

    }, [renderizar, paginaAtual, revelarTela])

    useEffect(() => {
        async function limparGraficoDisponibilidadeOperacional() {
            setDisponibilidade(
                [
                    { value: 0, cor: '#36D896' },
                    { value: 100, cor: '#B1B1B1' }
                ]
            )
            setDisponibilidadeOnline('')
            setDisponibilidadeOffline('')
            setHorasOffline('-')
            setHorasOnline('-')
            setHorasTotais(0)
        }
        limparGraficoDisponibilidadeOperacional()
    }, [revelarTela])

    function formatadorDeDados(dados) {
        let informacao = [];
        // if (dados !== undefined && revelarTela !== "Timeline") {
        //     informacao = dados.map((item, i) => {
        //         item.extrato['geolocalizacao'] = item.geolocalizacao.geolocalizacaoLink
        //         return item.extrato
        //     })
        // }

        if (dados !== undefined && revelarTela !== "Timeline") {
            informacao = dados.map((item, i) => {
                item['geolocalizacao'] = item.geolocalizacaoLink
                return item
            })
        }

        return informacao;
    }

    function inserirData(data) {
        for (let i = 0; i < totalItens; i++) {
            if (contador === i) {
                let k = i
                for (let j = 0; j < data.length; j++) {
                    itensVar[k] = data[j]
                    k++
                }
            }
            if (i == (totalItens - 1)) {
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }

    async function contarItens(contador) {
        let arrayItens = []
        if (controladorPagina <= paginaAtual) {
            totalPaginas = Math.ceil(contador / valorLimite)
            totalItens = contador;
            for (var i = 0; i < contador; i++) {
                arrayItens.push(i)
            }
            itensVar = arrayItens
        }
    }

    function formatadorDeDadosMapa(dados) {
        let mapaHistorico = [];
        if (dados !== undefined && revelarTela !== "Timeline") { //  && revelarTela === 'Histórico'
            dados.map((item, i) => {
                let arrayLatLong = item.geolocalizacao.split(",");
                mapaHistorico.push({
                    latitude: parseFloat(arrayLatLong[0]),
                    longitude: parseFloat(arrayLatLong[1]),
                    radio: arrayLatLong[2],
                    mcc: parseInt(arrayLatLong[3]),
                    mnc: parseInt(arrayLatLong[4]),
                    lac: parseInt(arrayLatLong[5]),
                    cid: parseInt(arrayLatLong[6]),
                    status: props.row[0]['ON_OFF'],
                    data: props.row[0]['ULTIMO_EVENTO'],
                    ds_linha: props.row[0]['CALLERID'],
                    operadora: '' // item.LAT_LONG_OPERADORA
                })
            })
        }
        setObjLatLongMapaHistorico(mapaHistorico)
    }

    function handleRenderTela(tela) {
        if (filtroRadio == 'Ciclo') {
            setFiltroRadio(undefined);
            let radio = document.getElementById('ciclo');
            radio.checked = false;
        }
        else if (filtroRadio == 'Periodo') {
            setFiltroRadio(undefined);
            let radio = document.getElementById('periodo');
            radio.checked = false;
        }
        else if (filtroRadio == '1dia') {
            setFiltroRadio(undefined);
            let radio = document.getElementById('ultimas24horas');
            radio.checked = false;
        }
        else if (filtroRadio == '2dias') {
            setFiltroRadio(undefined);
            let radio = document.getElementById('ultimas48horas');
            radio.checked = false;
        }
        else if (filtroRadio == '7dias') {
            setFiltroRadio(undefined);
            let radio = document.getElementById('ultimos7dias');
            radio.checked = false;
        }
        else if (filtroRadio == '15dias') {
            setFiltroRadio(undefined);
            let radio = document.getElementById('ultimos15dias');
            radio.checked = false;
        }
        else if (filtroRadio == '30dias') {
            setFiltroRadio(undefined);
            let radio = document.getElementById('ultimos30dias');
            radio.checked = false;
        }
        if (tela === 'Última localização') {
            setRevelarFiltroPeriodo(false)
            contrairConteudoLinha(false)
            setRevelarTela('Última localização')
        } else if (tela === 'Histórico') {
            setObjLatLongMapaHistorico([])
            setDataInicio('')
            setDataFinal('')
            setCiclo("Selecione")
            setPhCiclo("Selecione")
            setRevelarFiltroPeriodo(true)
            contrairConteudoLinha(true)
            setRevelarTela('Histórico')
        } else if (tela === 'Descrição') {
            setRevelarFiltroPeriodo(false)
            contrairConteudoLinha(false)
            setRevelarTela('Descrição')
        } else if (tela === 'Timeline') {
            setItensTimeline('')
            setDataInicio('')
            setDataFinal('')
            setCiclo("Selecione")
            setPhCiclo("Selecione")
            setRevelarFiltroPeriodo(true)
            contrairConteudoLinha(true)
            setRevelarTela('Timeline')
        } else if (tela === 'Extrato') {
            setItens('')
            setDataInicio('')
            setDataFinal('')
            setCiclo("Selecione")
            setPhCiclo("Selecione")
            setRevelarFiltroPeriodo(true)
            contrairConteudoLinha(true)
            setRevelarTela('Extrato')
        } else if (tela === 'Ciclo atual') {
            setRevelarFiltroPeriodo(false)
            contrairConteudoLinha(false)
            setRevelarTela('Ciclo atual')
        } else if (tela === 'Últimos 6 ciclos') {
            setRevelarFiltroPeriodo(false)
            contrairConteudoLinha(false)
            setRevelarTela('Últimos 6 ciclos')
        } else {
            setRevelarFiltroPeriodo(false)
            contrairConteudoLinha(false)
            setRevelarTela('NULL')
        }
    }

    const contrairConteudoLinha = (validador) => {
        // if(validador === true){
        //     containerConteudoDetalhamentoLinha.current.classList.remove("conteudo-detalhamento-linha-retrair")
        //     containerConteudoDetalhamentoLinha.current.classList.add("conteudo-detalhamento-linha-contrair")
        // }else{
        //     containerConteudoDetalhamentoLinha.current.classList.remove("conteudo-detalhamento-linha-contrair")
        //     containerConteudoDetalhamentoLinha.current.classList.add("conteudo-detalhamento-linha-retrair")
        // }
    }

    async function atualizarChamada() {

        await setRenderizar(!renderizar)
        totalPaginas = 0;
    }

    function escondeDropdown(el) {
        el.classList.remove("detalhamento-linha-expandir");
        el.classList.add("detalhamento-linha-contrair");
    }

    function handleDropTelas(elementoId) {

        if(elementoId === '1') { // Localização
            if(mostrarBotaoDownload) setMostrarBotaoDownload(false);
        } else if(!mostrarBotaoDownload) {
            setMostrarBotaoDownload(true);
        }

        if (idMenu === elementoId + props.row[0].CALLERID) {
            setIdMenu(elementoId + props.row[0].CALLERID)
        } else {
            setIdMenu(elementoId + props.row[0].CALLERID)
        }
        setSpinAtivo(true)
        let elementosAtivos = document.querySelectorAll(".detalhamento-linha-expandir");
        let elemento = document.getElementById(elementoId + props.row[0].CALLERID);
        elemento.classList.toggle("detalhamento-linha-expandir")
        if (elementosAtivos.length > 0) {
            elementosAtivos.forEach(escondeDropdown)
        }
        setSpinAtivo(false)
    }

    function handleEstadoRadio(Estado) {
        ativarBotaoConfirmar = true;
        setSpinAtivo(true)
        setFiltroRadio(Estado)
        if (Estado !== "Ciclo") {
            setPhCiclo("Selecione")
        }
        if (Estado !== "Periodo") {
            setDataInicio('')
            setDataFinal('')
        }
        setSpinAtivo(false)
    }

    async function buscarUltimaLocalizacao(tela) {

        const { data } = await api_svdispositivos.get(`/api/v2/devices/simcard/${props.gsm}/geolocation?codigocliente=${decryptJWT('codigoClienteErpAntigo')}&ultima=true`)

        // let arrayLatLong = resposta.data.dados[0].geo_concat.split(",")

        setObjLatLong([{
            latitude: parseFloat(data.geolocation?.lat),
            longitude: parseFloat(data.geolocation?.long),
            radio: data.geolocation?.radio, // arrayLatLong[2],
            mcc: data.geolocation?.mcc, // parseInt(arrayLatLong[3]),
            mnc: data.geolocation?.mnc, // parseInt(arrayLatLong[4]),
            lac: data.geolocation?.lac, // parseInt(arrayLatLong[5]),
            cid: data.geolocation?.cid, // parseInt(arrayLatLong[6]),
            status: data.geolocation?.status,
            data: data.geolocation?.ULTIMO_EVENTO, // item['ULTIMO_EVENTO'],
            ds_linha: data.geolocation?.CALLERID, // item['CALLERID'],
            operadora: data.geolocation?.LAT_LONG_OPERADORA, // resposta.data.dados[0].LAT_LONG_OPERADORA
        }])

        handleRenderTela(tela)

        /*
        let verificadorLocalizacao = false
        let accuracyAux = 0
    
        if (objLatLong[0].latitude === 0 && objLatLong[0].longitude === 0) {
            // await api.post('https://www.googleapis.com/geolocation/v1/geolocate?key=GOOGLE_MAPS_JAVASCRIPT_API', {
            //     "cellTowers":[
            //         {
            //             "mobileCountryCode":objLatLong[0].mcc,
            //             "mobileNetworkCode":objLatLong[0].mnc,
            //             "locationAreaCode":objLatLong[0].lac,
            //             "cellId":objLatLong[0].cid
            //         }
            //     ]
            // })
            // .then(function (response) {
            //     objLatLong[0].latitude = response.data.location.lat
            //     objLatLong[0].longitude = response.data.location.lng
            //     accuracyAux = response.data.accuracy
            //     verificadorLocalizacao = true
            // })
            // .catch(function (error) {
            // });
            await api.get('/m1/consultar/geolocation/opencellid?mcc=' + objLatLong[0].mcc + '&mnc=' + objLatLong[0].mnc + "&lac=" + objLatLong[0].lac + "&cid=" + objLatLong[0].cid)
                .then(function (response) {
                    if (response.data.status === 200) {
                        objLatLong[0].latitude = response.data.dados.lat
                        objLatLong[0].longitude = response.data.dados.lon
                        accuracyAux = response.data.dados.accuracy
                        verificadorLocalizacao = true
                    }
                })
                .catch(function (error) {
                });
        }
        handleRenderTela(tela)
        if (verificadorLocalizacao) {
            handleAtualizarGeolocation(accuracyAux)
        }
        */
    }

    function handleBtnCarregar() {
        if (!filtroRadio) {
            btnCarregarDisabled = true;
            ativarBotaoConfirmar = true;
            return true
        }
        else if (filtroRadio == "Ciclo") {
            if (phCiclo == "Selecione") {
                btnCarregarDisabled = true;
                ativarBotaoConfirmar = true;
                return true;
            }
        }
        else if (filtroRadio == "Periodo") {
            if (dataInicio == '' || dataFinal == '') {
                btnCarregarDisabled = true;
                ativarBotaoConfirmar = true;
                return true;
            }
        }
        else {
            return false;
        }
    }

    function handleSolicitarRelatorio(formato) {
        document.getElementById('botao-formatos').click();

        let auxDias = filtroRadio.replace(/\D/g, "")
        let auxLinha = props.gsm
        let auxPeriodoInicio
        if (dataInicio !== undefined) {
            auxPeriodoInicio = moment(dataInicio).format('YYYYMMDD')
        }
        let auxPeriodoFim
        if (dataFinal !== undefined) {
            auxPeriodoFim = moment(dataFinal).format('YYYYMMDD')
        }

        const dados = {
            codigo_cliente: decryptJWT('codigoCliente'),
            codigo_usuario: decryptJWT('codigo'),
            descricao: 'Relatório de extrato de conexões',
            tipo_arquivo: formato,
            tipo_relatorio: 16,
            filtros: {
                "dias": auxDias,
                "linha": auxLinha,
                "periodo_inicio": auxPeriodoInicio,
                "periodo_fim": auxPeriodoFim
            },
            buscaArquivo,
        }
        api.post('/m1/cadastrar/relatorios', dados)
        handleMensagemInformacao(true)
    }

    function handleMensagemInformacao(confirmacao) {
        if (revelarMensagemInformacao === false) {
            setRevelarMensagemInformacao(true)
        } else {
            setRevelarMensagemInformacao(false)
            if (confirmacao === true) {
                //ALGUMA FUNÇÃO
            }
        }
    }

    function graficoDisponibilidadeOperacional() {
        return (
            <div className="container-tela-corpo-disponibilidade fundo-cor-7" >

                <Col>
                    <Form.Label className="titulo-grafico-disponibilidade">
                        <div className="label-janela-disponibilidade fonte-cor-1">Disponibilidade operacional</div>
                    </Form.Label>

                    <div className="horas-totais-grafico-disponibilidade fonte-cor-1">{horasTotais + 'h'}</div>
                    <div className="box-total-grafico-disponibilidade fonte-cor-1">Total</div>

                    <div className="grupo-dash-grafico-pizza-grande-disponibilidade">
                        <Col>
                            <div className="container-dash-grafico-pizza-grande-disponibilidade">
                                <ResponsiveContainer height="100%" width="100%">
                                    <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }} className='container-disponibilidade-grafico-disponibilidade'>
                                        <Pie
                                            data={disponibilidade}
                                            innerRadius="55%"
                                            outerRadius="100%"
                                            fill="#505050"
                                            paddingAngle={0}
                                            stroke={0}
                                        >
                                            <Label content={<LabelGraficosPizzaComposto />} />
                                            {
                                                disponibilidade.map((entry, index) => <Cell fill={entry.cor} />)
                                            }
                                        </Pie>

                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="container-legenda-disponibilidade">
                                <div className="linha-legenda-status">
                                    <div className="coluna-legenda-status-1"><div className="esfera-legenda-disponibilidade" style={{ backgroundColor: (horasOnline === '-' ? '#B1B1B1' : '#36D896') }} /></div>
                                    <div className="coluna-legenda-status-2 fonte-cor-1"><span className="texto-legenda-disponibilidade">Online</span></div>
                                    <div className="coluna-legenda-status-3-disponibilidade"><span className="fonte-cor-1 texto-legenda-box-disponibilidade">{disponibilidadeOnline.toString().replace('.', ',') + (horasOnline === '-' ? '-' : '% (' + horasOnline + 'h)')}</span></div>
                                </div>
                                <div className="linha-legenda-status">
                                    <div className="coluna-legenda-status-1"><div className="esfera-legenda-disponibilidade" style={{ backgroundColor: (horasOffline === '-' ? '#B1B1B1' : "#CD2D37") }} /></div>
                                    <div className="coluna-legenda-disponibilidade-2 fonte-cor-1"><span className="texto-legenda-disponibilidade">Offline</span></div>
                                    <div className="coluna-legenda-status-3-disponibilidade"><span className="fonte-cor-1 texto-legenda-box-disponibilidade">{disponibilidadeOffline.toString().replace('.', ',') + (horasOffline === '-' ? '-' : ' % (' + horasOffline + 'h)')}</span></div>
                                </div>

                            </div>
                        </Col>
                    </div>
                </Col>
            </div>
        )
    }

    /* GERA A LABEL COM O VALOR TOTAL DENTRO DOS GRAFICOS COMPOSTOS */
    function LabelGraficosPizzaComposto({ viewBox, valorPercentual }) {

        const { cx, cy } = viewBox;
        let auxTooltipTotal
        if (parseFloat(valorPercentual) > 2000000) {
            auxTooltipTotal = parseFloat(valorPercentual / 1000000).toFixed(1) + " milhões"
        } else if (parseFloat(valorPercentual) > 1000000) {
            auxTooltipTotal = parseFloat(valorPercentual / 1000000).toFixed(1) + " milhão"
        } else if (parseFloat(valorPercentual) > 1000) {
            auxTooltipTotal = parseFloat(valorPercentual / 1000).toFixed(1) + " mil"
        } else {
            auxTooltipTotal = valorPercentual //formatarMilharSemDecimais(valorPercentual)
        }
        return (
            <>
                <text className="texto-titulo-legenda-grafico-pizza-grande-dash" x={cx} y="40%" fill={'#FFFFFF'} textAnchor="middle" dominantBaseline="central">
                    {/* Total */}
                </text>
                <text className="label-percentual-graficos-pizza-composto-dash" x={cx} y="50%" fill={'#FFFFFF'} textAnchor="middle" dominantBaseline="central">
                    {/* {auxTooltipTotal} */}
                </text>
            </>
        )
    }

    return (
        <Form>
            {revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"O arquivo está sendo gerado"}
                            mensagemParagrafo1={"Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível"}
                            mensagemBotao={"Voltar"}
                        />
                    </div>
                </div>
            }
            <div className="container-detalhamento-linha fundo-cor-1">
                {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
                <Form.Row>
                    <div className="container-navegacao-detalhamento-linha">
                        <div className="triangulo-navegacao-detalhamento-linha campo-select-triangulo-cor-3"></div>
                        <div className="corpo-navegacao-detalhamento-linha fundo-cor-5">
                            <ul className="lista-navegacao-detalhamento-linha">
                                {(permissaoUltimaLocalizacao || permissaoHistoricoLocalizacao) &&
                                    <li key={"1"}>
                                        {idMenu === '1' + props.row[0].CALLERID ?
                                            <div className="container-item-navegacao-detalhamento-linha-ativado detalhamento-hover fundo-cor-7" onClick={e => handleDropTelas("1")}>
                                                <div className="coluna-item-navegacao-detalhamento-linha-1">
                                                    <Image className="nao-selecionavel icone-item-navegacao-detalhamento-linha" src={iconeLocalizacaoAtivado()} />
                                                </div>
                                                <div className="coluna-item-navegacao-detalhamento-linha-2">
                                                    <span className="label-item-navegacao-detalhamento-linha nao-selecionavel fonte-campo fonte-cor-1">Localização</span>
                                                </div>
                                            </div>
                                            :
                                            <div className="container-item-navegacao-detalhamento-linha-desativado detalhamento-hover" onClick={e => handleDropTelas("1")}>
                                                <div className="coluna-item-navegacao-detalhamento-linha-1">
                                                    <Image className="nao-selecionavel icone-item-navegacao-detalhamento-linha" src={iconeLocalizacao()} />
                                                </div>
                                                <div className="coluna-item-navegacao-detalhamento-linha-2">
                                                    <span className="label-item-navegacao-detalhamento-linha nao-selecionavel fonte-campo fonte-cor-1">Localização</span>
                                                </div>
                                            </div>
                                        }

                                        <ul id={"1" + props.row[0].CALLERID} className="lista-navegacao-detalhamento-linha detalhamento-linha-contrair">
                                            {permissaoUltimaLocalizacao &&
                                                <li>
                                                    {revelarTela === 'Última localização' ?
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha-2" onClick={() => buscarUltimaLocalizacao('Última localização')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-ativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Última</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha" onClick={() => buscarUltimaLocalizacao('Última localização')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-desativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Última</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            }
                                            {permissaoHistoricoLocalizacao &&
                                                <li>
                                                    {revelarTela === 'Histórico' ?
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha-2" onClick={() => handleRenderTela('Histórico')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-ativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Histórico</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha" onClick={() => handleRenderTela('Histórico')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-desativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Histórico</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                                {permissaoDescricao &&
                                    <li key={"2"} onClick={() => handleRenderTela('Descrição')}>
                                        {idMenu === '2' + props.row[0].CALLERID ?
                                            <div className="container-item-navegacao-detalhamento-linha-ativado detalhamento-hover fundo-cor-7" onClick={e => handleDropTelas("2")}>
                                                <div className="coluna-item-navegacao-detalhamento-linha-1">
                                                    <Image className="nao-selecionavel icone-item-navegacao-detalhamento-linha" src={iconeDescricaoAtivado()} />
                                                </div>
                                                <div className="coluna-item-navegacao-detalhamento-linha-2">
                                                    <span className="label-item-navegacao-detalhamento-linha nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('Descrição')}>Descrição</span>
                                                </div>
                                            </div>
                                            :
                                            <div className="container-item-navegacao-detalhamento-linha-desativado detalhamento-hover " onClick={e => handleDropTelas("2")}>
                                                <div className="coluna-item-navegacao-detalhamento-linha-1">
                                                    <Image className="nao-selecionavel icone-item-navegacao-detalhamento-linha" src={iconeDescricao()} />
                                                </div>
                                                <div className="coluna-item-navegacao-detalhamento-linha-2">
                                                    <span className="label-item-navegacao-detalhamento-linha nao-selecionavel fonte-campo fonte-cor-1" onClick={() => handleRenderTela('Descrição')}>Descrição</span>
                                                </div>
                                            </div>
                                        }
                                        <ul id={"2" + props.row[0].CALLERID} className="lista-navegacao-detalhamento-linha detalhamento-linha-contrair">
                                        </ul>
                                    </li>
                                }
                                {(permissaoTimeline || permissaoExtrato) &&
                                    <li key={"3"}>
                                        {idMenu === '3' + props.row[0].CALLERID ?
                                            <div className="container-item-navegacao-detalhamento-linha-ativado detalhamento-hover fundo-cor-7" onClick={e => handleDropTelas("3")}>
                                                <div className="coluna-item-navegacao-detalhamento-linha-1">
                                                    <Image className="nao-selecionavel icone-item-navegacao-detalhamento-linha" src={iconeConexoesAtivado()} />
                                                </div>
                                                <div className="coluna-item-navegacao-detalhamento-linha-2">
                                                    <span className="label-item-navegacao-detalhamento-linha nao-selecionavel fonte-campo fonte-cor-1">Conexões</span>
                                                </div>
                                            </div>
                                            :
                                            <div className="container-item-navegacao-detalhamento-linha-desativado detalhamento-hover" onClick={e => handleDropTelas("3")}>
                                                <div className="coluna-item-navegacao-detalhamento-linha-1">
                                                    <Image className="nao-selecionavel icone-item-navegacao-detalhamento-linha" src={iconeConexoes()} />
                                                </div>
                                                <div className="coluna-item-navegacao-detalhamento-linha-2">
                                                    <span className="label-item-navegacao-detalhamento-linha nao-selecionavel fonte-campo fonte-cor-1">Conexões</span>
                                                </div>
                                            </div>
                                        }
                                        <ul id={"3" + props.row[0].CALLERID} className="lista-navegacao-detalhamento-linha detalhamento-linha-contrair">
                                            {permissaoTimeline &&
                                                <li>
                                                    {revelarTela === 'Timeline' ?
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha-2" onClick={() => handleRenderTela('Timeline')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-ativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Timeline</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha" onClick={() => handleRenderTela('Timeline')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-desativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Timeline</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            }
                                            {permissaoExtrato &&
                                                <li>
                                                    {revelarTela === 'Extrato' ?
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha-2" onClick={() => handleRenderTela('Extrato')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-ativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Extrato</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha" onClick={() => handleRenderTela('Extrato')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-desativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Extrato</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                                {(permissaoUltimo6Ciclo || permissaoCicloAtual) &&
                                    <li key={"4"}>
                                        {idMenu === '4' + props.row[0].CALLERID ?
                                            <div className="container-item-navegacao-detalhamento-linha-ativado detalhamento-hover fundo-cor-7" onClick={e => handleDropTelas("4")}>
                                                <div className="coluna-item-navegacao-detalhamento-linha-1">
                                                    <Image className="nao-selecionavel icone-item-navegacao-detalhamento-linha" src={iconeConsumoAtivado()} />
                                                </div>
                                                <div className="coluna-item-navegacao-detalhamento-linha-2">
                                                    <span className="label-item-navegacao-detalhamento-linha nao-selecionavel fonte-campo fonte-cor-1">Consumo</span>
                                                </div>
                                            </div>
                                            :
                                            <div className="container-item-navegacao-detalhamento-linha-desativado detalhamento-hover" onClick={e => handleDropTelas("4")}>
                                                <div className="coluna-item-navegacao-detalhamento-linha-1">
                                                    <Image className="nao-selecionavel icone-item-navegacao-detalhamento-linha" src={iconeConsumo()} />
                                                </div>
                                                <div className="coluna-item-navegacao-detalhamento-linha-2">
                                                    <span className="label-item-navegacao-detalhamento-linha nao-selecionavel fonte-campo fonte-cor-1">Consumo</span>
                                                </div>
                                            </div>
                                        }
                                        <ul id={"4" + props.row[0].CALLERID} className="lista-navegacao-detalhamento-linha detalhamento-linha-contrair">
                                            {permissaoCicloAtual &&
                                                <li>
                                                    {revelarTela === 'Ciclo atual' ?
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha-2" onClick={() => handleRenderTela('Ciclo atual')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-ativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Ciclo atual</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha" onClick={() => handleRenderTela('Ciclo atual')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-desativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Ciclo atual</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            }
                                            {permissaoUltimo6Ciclo &&
                                                <li>
                                                    {revelarTela === 'Últimos 6 ciclos' ?
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha-2" onClick={() => handleRenderTela('Últimos 6 ciclos')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-ativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Últimos 6 ciclos</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="detalhamento-hover container-subitem-navegacao-detalhamento-linha" onClick={() => handleRenderTela('Últimos 6 ciclos')}>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-1">
                                                                <div className="nao-selecionavel icone-subitem-navegacao-detalhamento-linha-desativado" />
                                                            </div>
                                                            <div className="coluna-subitem-navegacao-detalhamento-linha-2">
                                                                <span className="label-subitem-navegacao-detalhamento-linha nao-selecionavel fonte-normal fonte-cor-1">Últimos 6 ciclos</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>



                    {revelarFiltroPeriodo === true &&
                        <div className="container-filtro-detalhamento-linha">
                            <div className="corpo-filtro-detalhamento-linha fundo-cor-7">
                                <Form.Row>
                                    <Col>
                                        <div className={"container-titulo-filtro-detalhamento-linha"}>
                                            <div className={"container-label-titulo-filtro-detalhamento-linha"}>
                                                <img className="icone-label-filtro-detalhamento-linha" src={elipseVerde()} alt="elipse" />
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-filtro-detalhamento-linha">Filtros</div>
                                                </Form.Label>
                                            </div>
                                            {revelarTela === 'Extrato' && filtroRadio !== undefined ?
                                                <Dropdown alignRight variant="success">
                                                    <Accordion className="botao-download-relatorio-detalhamento-linha" defaultActiveKey="0">
                                                        <Accordion.Toggle id="botao-formatos" className="bt-dropdown-formato-preview" as={Button} variant="link" eventKey="1"></Accordion.Toggle>
                                                        <Accordion.Collapse className="menu-dropdown-formatos-preview" eventKey="1">
                                                            <>
                                                                <div className="triangulo-dropdown-configuracao-cabecalho-sistema triangulo-dropdown-template-detalhamento"></div>
                                                                <div className="drop-formato-preview-opcao drop-formato-preview-hover fonte-cor-1" onClick={() => { handleSolicitarRelatorio("CSV") }}>
                                                                    CSV
                                                                </div>
                                                                <Dropdown.Divider className="template-border" />
                                                                <div className="drop-formato-preview-opcao drop-formato-preview-hover fonte-cor-1" onClick={() => { handleSolicitarRelatorio("XLS") }}>
                                                                    XLS
                                                                </div>
                                                                <Dropdown.Divider className="template-border" />
                                                                <div className="drop-formato-preview-opcao drop-formato-preview-hover fonte-cor-1" onClick={() => { handleSolicitarRelatorio("XLSX") }}>
                                                                    XLSX
                                                                </div>
                                                            </>
                                                        </Accordion.Collapse>
                                                    </Accordion>
                                                </Dropdown>
                                                :
                                                <>
                                                    {mostrarBotaoDownload ? <div className="bt-dropdown-formato-preview-false" /> : null}
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="espacador-filtro-detalhamento-linha">
                                    <Col>
                                        <label className="container-radio-button-extrato fonte-cor-1">
                                            <input checked={filtroRadio === 'Periodo' ? true : false} name="periodoRadio" type="radio" id='periodo' onClick={() => handleEstadoRadio('Periodo')} />
                                            <span className="radio-button-extrato radio-button-cor-1"></span>Por período
                                        </label>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="espacador-filtro-detalhamento-linha">
                                    <Col>
                                        {filtroRadio === 'Periodo' ?
                                            <>
                                                <div className="container-calendario-detalhemento-linha">
                                                    <Historico imputCustomisado='De:' data={dataInicio} setData={setDataInicio} dataMax={dataMax} dataMin={revelarTela === 'Histórico' ? dataMinHistorico : dataMin} formato={"DD/MM/YYYY"}></Historico>
                                                </div>
                                                <div className="container-calendario-detalhemento-linha">
                                                    <Historico imputCustomisado='Até:' data={dataFinal} setData={setDataFinal} dataMax={dataMax} dataMin={revelarTela === 'Histórico' ? dataMinHistorico : dataMin} formato={"DD/MM/YYYY"}></Historico>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="container-calendario-detalhemento-linha">
                                                    <Button className="campo-calendario campo-texto-cor-3">
                                                        De:
                                                        <img className="icone-calendario" src={calendario()} alt="calendario" />
                                                    </Button>
                                                </div>
                                                <div className="container-calendario-detalhemento-linha">
                                                    <Button className="campo-calendario campo-texto-cor-3">
                                                        Até:
                                                        <img className="icone-calendario" src={calendario()} alt="calendario" />
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                    </Col>
                                </Form.Row>
                                <Form.Row className="espacador-filtro-detalhamento-linha">
                                    <Col>
                                        <label className="container-radio-button-extrato fonte-cor-1">
                                            <input className="cicloHoras" type="radio" name="periodoRadio" id='ultimas24horas' onClick={() => handleEstadoRadio('1dia')} />
                                            <span className="radio-button-extrato radio-button-cor-1"></span>Últimas 24 horas
                                        </label>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="espacador-filtro-detalhamento-linha">
                                    <Col>
                                        <label className="container-radio-button-extrato fonte-cor-1">
                                            <input className="cicloHoras" type="radio" name="periodoRadio" id='ultimas48horas' onClick={() => handleEstadoRadio('2dias')} />
                                            <span className="radio-button-extrato radio-button-cor-1"></span>Últimas 48 horas
                                        </label>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="espacador-filtro-detalhamento-linha">
                                    <Col>
                                        <label className="container-radio-button-extrato fonte-cor-1">
                                            <input className="cicloHoras" type="radio" name="periodoRadio" id='ultimos7dias' onClick={() => handleEstadoRadio('7dias')} />
                                            <span className="radio-button-extrato radio-button-cor-1"></span>Últimos 7 dias
                                        </label>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="espacador-filtro-detalhamento-linha">
                                    <Col>
                                        <label className="container-radio-button-extrato fonte-cor-1">
                                            <input className="cicloHoras" type="radio" name="periodoRadio" id='ultimos15dias' onClick={() => handleEstadoRadio('15dias')} />
                                            <span className="radio-button-extrato radio-button-cor-1"></span>Últimos 15 dias
                                        </label>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="espacador-filtro-detalhamento-linha">
                                    <Col>
                                        <label className="container-radio-button-extrato fonte-cor-1">
                                            <input className="cicloHoras" type="radio" name="periodoRadio" id='ultimos30dias' onClick={() => handleEstadoRadio('30dias')} />
                                            <span className="radio-button-extrato radio-button-cor-1"></span>Últimos 30 dias
                                        </label>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="espacador-filtro-detalhamento-linha">
                                    <Col>
                                        <div className="container-botao-filtro-detalhamento-linha">
                                            <Button id="botaoCarregar" disabled={handleBtnCarregar()} className={ativarBotaoConfirmar == false ? "botao-confirmar-filtro-detalhamento-linha fonte-cor-1" : "botao-confirmar-desabilitado-detalhamento-linha fonte-cor-1"} variant="sucess" size="sm" onClick={() => atualizarChamada()}>
                                                Carregar
                                            </Button>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </div>
                        </div>
                    }

                    {/* LOCALIZACAO */}
                    {revelarTela === 'Última localização' &&
                        <div className="container-tela-sem-filtro-detalhamento-linha">
                            <div className="container-tela-conteudo-detalhamento-linha">
                                {/* <Form.Row>
                                    <Col>
                                        <img className="icone-label-filtro-detalhamento-linha" src={elipseVerde()} alt="elipse"/>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-janela">Última localização</div>
                                        </Form.Label>
                                    </Col>
                                </Form.Row> */}
                                {objLatLong[0].latitude === 0 && objLatLong[0].longitude === 0 ?
                                    <div className="container-tela-corpo-detalhamento-localizacao">
                                        <div className="container-mensagem-geo-nao-encontrado">
                                            <spam className="mensagem-geo-nao-encontrado fonte-cor-1">Não foi possível encontrar dados de última localização da linha.</spam>
                                        </div>
                                    </div>
                                    :
                                    <div className="container-tela-corpo-detalhamento-localizacao">
                                        <MapaGoogle array={objLatLong} tipo={"localizacao"} />
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {revelarTela === 'Histórico' &&
                        <div className="container-tela-com-filtro-detalhamento-linha">
                            <div className="container-tela-conteudo-detalhamento-linha">
                                {/* <Form.Row>
                                    <Col>
                                        <img className="icone-label-filtro-detalhamento-linha" src={elipseVerde()} alt="elipse"/>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-janela">Histórico</div>
                                        </Form.Label>
                                    </Col>
                                </Form.Row> */}
                                <div className="container-tela-corpo-detalhamento-historico">
                                    {objLatLongMapaHistorico.length == 0 &&
                                        <div className="container-mensagem-geo-nao-encontrado">
                                            <spam className="mensagem-geo-nao-encontrado fonte-cor-1">Por favor, faça uma filtragem.</spam>
                                        </div>
                                    }
                                    { ( objLatLongMapaHistorico.length == 1 && isNaN(objLatLongMapaHistorico[0].latitude) ) &&
                                        <div className="container-mensagem-geo-nao-encontrado">
                                            <spam className="mensagem-geo-nao-encontrado fonte-cor-1">Não foram encontrados dados de localização da linha.</spam>
                                        </div>
                                    }
                                    { ( objLatLongMapaHistorico.length > 0 && !(isNaN(objLatLongMapaHistorico[0].latitude)) ) &&
                                        <MapaGoogleCluster array={objLatLongMapaHistorico} tipo={"historico"} />                                            
                                    }
                                    {/* {objLatLongMapaHistorico.length===0&&
                                        <MapaSituacao array={[]} mapa={false}/>
                                    } */}
                                </div>
                            </div>
                        </div>
                    }

                    {/* DESCRICAO */}
                    {revelarTela === 'Descrição' &&
                        <div className="container-tela-sem-filtro-detalhamento-linha">
                            <div className="container-tela-conteudo-detalhamento-linha">
                                {/* <Form.Row>
                                    <Col>
                                        <img className="icone-label-filtro-detalhamento-linha" src={elipseVerde()} alt="elipse"/>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-janela">Descrição</div>
                                        </Form.Label>
                                    </Col>
                                </Form.Row> */}
                                <div className="container-tela-corpo-detalhamento">
                                    <DescricaoCliente
                                        renderConsultaColunas={props.renderConsultaColunas}
                                        setRenderConsultaColunas={props.setRenderConsultaColunas}
                                        IMEI={props.row[0].IMEI} setRenderizar={props.setRenderizar}
                                        renderizar={props.renderizar}
                                        cdSimcard={cdSimcard}
                                        row={props.row}>
                                    </DescricaoCliente>
                                </div>
                            </div>
                        </div>
                    }

                    {/* CONEXOES */}
                    {revelarTela === 'Timeline' &&
                        <div className="container-tela-com-filtro-detalhamento-linha">
                            {/* {graficoDisponibilidadeOperacional()} */}
                            <div className="container-tela-conteudo-detalhamento-linha">
                                {/* <Form.Row>
                                    <Col>
                                        <img className="icone-label-filtro-detalhamento-linha" src={elipseVerde()} alt="elipse"/>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-janela">Timeline</div>
                                        </Form.Label>
                                    </Col>
                                </Form.Row> */}
                                <div className="container-tela-corpo-detalhamento">
                                    <div className="container-timeline">
                                        {itensTimeline != '' ?
                                            <Timeline
                                                dados={itensTimeline}
                                                tecnologia={props.row[0].TECNOLOGIA.split(',')[2]}
                                                imei={props.row[0].IMEI}
                                            />
                                            :
                                            <>
                                                {realizouFiltragemTimeline ?
                                                    <div className="container-mensagem-geo-nao-encontrado">
                                                        <spam className="mensagem-geo-nao-encontrado fonte-cor-1">Não foram encontrados dados no período selecionado. Por favor, faça uma nova filtragem.</spam>
                                                    </div>
                                                    :
                                                    <div className="container-mensagem-geo-nao-encontrado">
                                                        <spam className="mensagem-geo-nao-encontrado fonte-cor-1">Por favor, faça uma filtragem.</spam>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="container-legenda-timeline">
                                        <div className="legenda-timeline">
                                            <div className="coluna-legenda-timeline-1">
                                                <div className="elipse-verde-timeline"></div>
                                            </div>
                                            <div className="coluna-legenda-timeline-2">
                                                <span className="label-normal nao-selecionavel fonte-cor-1">Online</span>
                                            </div>
                                        </div>
                                        <div className="legenda-timeline">
                                            <div className="coluna-legenda-timeline-1">
                                                <div className="elipse-vermelho-timeline"></div>
                                            </div>
                                            <div className="coluna-legenda-timeline-2">
                                                <span className="label-normal nao-selecionavel fonte-cor-1">Offline</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {revelarTela === 'Extrato' &&
                        <div className="container-tela-com-filtro-detalhamento-linha">
                            {graficoDisponibilidadeOperacional()}
                            <div className="container-tela-conteudo-detalhamento-linha-extrato">
                                {/* <Form.Row>
                                    <Col>
                                        <img className="icone-label-filtro-detalhamento-linha" src={elipseVerde()} alt="elipse"/>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-janela">Extrato de conexões</div>
                                        </Form.Label>
                                    </Col>
                                </Form.Row> */}
                                <div className="container-tela-corpo-detalhamento-extrato">
                                    <ExtratoConexao
                                        spinAtivo={spinAtivo}
                                        dataInicio={dataInicio}
                                        dataFinal={dataFinal}
                                        ciclo={ciclo}
                                        gsm={props.gsm}
                                        renderizar={renderizar}
                                        setRenderizar={setRenderizar}
                                        itensHistorico={itensHistorico}
                                        valorLimite={valorLimite}
                                        contador={contador}
                                        paginaAtual={paginaAtual}
                                        setPaginaAtual={setPaginaAtual}
                                        itens={itens}
                                        totalItens={totalItens}
                                        totalPaginas={totalPaginas}
                                    ></ExtratoConexao>
                                </div>
                            </div>
                        </div>
                    }

                    {/* CONSUMO */}
                    {revelarTela === 'Ciclo atual' &&
                        <div className="container-tela-sem-filtro-detalhamento-linha">
                            <div className="container-tela-conteudo-detalhamento-linha">
                                {/* <Form.Row>
                                    <Col>
                                        <img className="icone-label-filtro-detalhamento-linha" src={elipseVerde()} alt="elipse"/>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-janela">Consumo no ciclo atual</div>
                                        </Form.Label>
                                    </Col>
                                </Form.Row> */}
                                <div className="container-tela-corpo-detalhamento">
                                    <GraficoConsumoCicloAtual consumoContratado={props.plano} gsm={props.gsm} previsaoConsumo={props.previsaoConsumo} clienteDoSimcard={props.row[0].CLIENTE_CODIGO} />
                                    <div className="container-legenda-grafico-ciclo-atual">
                                        <div className="legenda-grafico-ciclo-atual">
                                            <div className="coluna-legenda-grafico-ciclo-atual-1">
                                                <div className="elipse-verde-grafico-ciclo-atual"></div>
                                            </div>
                                            <div className="coluna-legenda-grafico-ciclo-atual-2">
                                                <span className="label-normal nao-selecionavel fonte-cor-1">Consumo</span>
                                            </div>
                                        </div>
                                        <div className="legenda-grafico-ciclo-atual">
                                            <div className="coluna-legenda-grafico-ciclo-atual-1">
                                                <div className="elipse-amarelo-grafico-ciclo-atual"></div>
                                            </div>
                                            <div className="coluna-legenda-grafico-ciclo-atual-2">
                                                <span className="label-normal nao-selecionavel fonte-cor-1">Plano contratado</span>
                                            </div>
                                        </div>
                                        <div className="legenda-grafico-ciclo-atual">
                                            <div className="coluna-legenda-grafico-ciclo-atual-1">
                                                <div className="elipse-vermelho-grafico-ciclo-atual"></div>
                                            </div>
                                            <div className="coluna-legenda-grafico-ciclo-atual-2">
                                                <span className="label-normal nao-selecionavel fonte-cor-1">Limite adicional</span>
                                            </div>
                                        </div>
                                        <div className="legenda-grafico-ciclo-atual">
                                            <div className="coluna-legenda-grafico-ciclo-atual-1">
                                                <div className="elipse-azul-grafico-ciclo-atual"></div>
                                            </div>
                                            <div className="coluna-legenda-grafico-ciclo-atual-2">
                                                <span className="label-normal nao-selecionavel fonte-cor-1">Previsão de consumo</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {revelarTela === 'Últimos 6 ciclos' &&
                        <div className="container-tela-sem-filtro-detalhamento-linha">
                            <div className="container-tela-conteudo-detalhamento-linha">
                                {/* <Form.Row>
                                    <Col>
                                        <img className="icone-label-filtro-detalhamento-linha" src={elipseVerde()} alt="elipse"/>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-janela">Consumo nos últimos 6 ciclos</div>
                                        </Form.Label>
                                    </Col>
                                </Form.Row> */}
                                <div className="container-tela-corpo-detalhamento">
                                    <GraficoConsumoCiclos consumoContratado={props.plano} gsm={props.gsm} clientedono={!props.row[0].CLIENTE_CODIGO ? props.row[0].CLIENTE_CODIGO_N3 : props.row[0].CLIENTE_CODIGO} />
                                </div>
                            </div>
                        </div>
                    }

                </Form.Row>
            </div >
        </Form >
    )
}