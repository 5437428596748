//BIBLIOTECAS
import React from 'react';
import { Form, Col } from 'react-bootstrap';
//ICONES
import { elipseVerde } from '../../../../utils/alternanciaIcones';

export default function DetalhamentoFinanceiro(props) {
    return(
        <>
            <div className="triangulo-detalhamento-fat-fornecedores campo-select-triangulo-cor-2"></div>
            <div className="container-itens-expandido">
                <div className="detalhamento-fat-fornecedores-3 fundo-cor-7">
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                            <img className="icone-label-filtro-financeiro" src={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela-financeiro"> Endereço </div>
                            </Form.Label>
                        </Col>
                    </Form.Row>
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                            <div className="linha-detalhamento-fat-fornecedores">
                                <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-detalhamento-cliente-1">
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">Endereço: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor"> {props.dsLogradouro+", "+props.dsNumero}</span>
                                            </div>
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">Bairro: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor-2"> {props.dsBairro}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-detalhamento-cliente-1">
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">Estado: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor"> {props.dsUf}</span>
                                            </div>
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">Cidade: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor-2"> {props.dsCidade}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>
                                <Form.Row>
                                    <Col>
                                        <div className="grupo-detalhamento-cliente-1">
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">CEP: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor-3"> {props.dsCep}</span>
                                            </div>
                                            <div className="coluna-detalhamento-cliente-1">
                                                <Form.Label className="fonte-cor-1 label-normal negrito">E-mail: </Form.Label>
                                                <span className="fonte-cor-1 label-normal label-dados-fornecedor-2"> {props.dsEmail}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <div className="divisoria-detalhamento-cliente campo-select-divisor-cor-1"/>
                            </div>
                        </Col>
                    </Form.Row> 
                </div>
            </div>
        </>
    )
}