import React, { useState } from "react";
import {api, api_s3} from '../../../../conexoes/api';
import decryptJWT from '../../../../utils/decryptJWT'

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'

import ModalImportarDescricao from "../../../componentesModais/modalImportarArquivo/importarDescricao";

let idInserido = '';

const dadosJSONXLS_fat = [
  ['linha',' cliente', 'endereco', 'cidade', 'cep', 'uf','observacoes','info_coluna','marca_dispositivo','modelo_dispositivo','numero_serial_dispositivo',  ],
  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]
]

const dadosJSONCSV_fat = [
  [
    "linha",
    "cliente",
    "endereco",
    "cidade",
    "cep",
    "uf",
    "observacoes",
    "info_coluna",
    "marca_dispositivo",
    "modelo_dispositivo",
    "numero_serial_dispositivo",
  ],
  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]
]

const cssClassesListaArquivosFat = {
  div: "Container",
  p: "tituloLista",
  li: "lista",
  li_p: "listaNome",
}

export default function ImportarDescricaoFat(props) {
  const [spinAtivo, setSpinAtivo] = useState(false);

  async function processUploadFat(uploadedFile, thisObj) {
    setSpinAtivo(true);
    let arquivos = uploadedFile
    let data = new FormData();
    data.append('file', arquivos.file);
    data.append('cd_usuario', decryptJWT('codigo'));
    data.append('cd_pav_evento_parametro_tipo', 2);

    await api_s3.post("s3/leitura/arquivo", data)
      .then(response => {
          setSpinAtivo(false);
            if (response.status === 200) {
            idInserido = response.data.idInserido
            thisObj.updateFile(uploadedFile.id, {
              uploaded: true,
              id: response.data._id,
              url: response.data.url
            });
            thisObj.setState({
              dadosArquivo: thisObj.state.dadosArquivo.concat(response.data.dados),
            });
          }else{
            setSpinAtivo(false);
          }
      })
      .catch((error) => {
        setSpinAtivo(false);
      });
  }

  async function uploadTabelaFat() {
    return /*await*/ api.post("m1/alterar/fatImportarDescricao", {
      idInserido,
      'n0_codigo_cliente':  decryptJWT('codigoClienteErpAntigo'),
    })
  }

  return (
  <ModalImportarDescricao // FAT
    faturas
    titulo="Importar descrição"
    setSpinAtivo={setSpinAtivo}
    spinAtivo={spinAtivo}
    dadosJSONXLS={dadosJSONXLS_fat}
    dadosJSONCSV={dadosJSONCSV_fat}
    processUpload={processUploadFat}
    uploadTabela={uploadTabelaFat}
    cssClassesListaArquivos={cssClassesListaArquivosFat}
    renderizar={props.renderizar}
    setRenderizar={props.setRenderizar}
    onConfirmarUpload={() => ColetaClickstream(1444) /*FaturasDispositivosDropAcoesImportarDescricao*/} 
  />
  )
}