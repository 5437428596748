//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {api} from '../../../../../conexoes/api';/*eslint-disable*/
import { Button, Dropdown, Accordion, Form, Col } from 'react-bootstrap';/*eslint-disable*/
//IMPORTAÇÕES ESTILIZÇÃO
import {elipseVerde, importarArquivo} from '../../../../../utils/alternanciaIcones'
import {formatarMilhar} from '../../../../../utils/formatarMilhar'
// SPINNER
import SpinerVeye from '../../../../spinnerVeye/spinnerVeye'
//COMPONENTES
import {formatarData} from '../../../../../utils/formatadorDataTabela'
import ColetaClickstream from '../../../../clickstreamAnalise/clickstreamColeta.js'
import { useDispatch, useSelector, connect } from 'react-redux';
import { revelarRelatorioPreviewFatura, revelarRelatorioNotaFiscal } from "../../../../../redux/actions/index";
import decryptJWT from '../../../../../utils/decryptJWT'
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../../utils/paginacaoTabela'

let contadorPreview = 0
let totalPaginasPreview = 1
let totalItensPreview = 10

export default function Preview(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS TABELA
    let itensVarPreview = []
    let itensVarNotasFiscais = []
    const [itensPreview, setItensPreview] = useState([])
    const [valorLimite, setValorLimite] = useState(10);
    const [paginaAtualPreview, setPaginaAtualPreview] = useState(1);
    const [refazerChamadasPreview, setRefazerChamadasPreview] = useState(true);
    const despacho = useDispatch();
    
    let controladorRelatorioPreviewFatura;

    useEffect(()=>{
        setSpinAtivo(true)
        contadorPreview = (paginaAtualPreview - 1) * valorLimite;
        async function carregarHistorico(){
            if(refazerChamadasPreview === true){
                const contagem = await api.post('/m1/consultar/fat_faturas_preview',{
                    "cd_fat_fatura":props.cdFatura,
                    "carregarLimit":valorLimite,
                    "paginaAtual":contadorPreview,
                    "contar":true
                })
                totalPaginasPreview = Math.ceil(contagem.data.dados[0].CONTADOR / valorLimite)
                totalItensPreview = contagem.data.dados[0].CONTADOR;
                setRefazerChamadasPreview(false)
            }
            const preview = await api.post('/m1/consultar/fat_faturas_preview',{
                "cd_fat_fatura":props.cdFatura,
                "carregarLimit":valorLimite,
                "paginaAtual":contadorPreview,
                "contar":false
            })
            inserirDataPreview(preview.data.dados)
        }
        carregarHistorico()
    },[paginaAtualPreview])


    useEffect(()=>{
        async function buscarDadosRelatorioPreview(){
            if(controladorRelatorioPreviewFatura === "XLS" || controladorRelatorioPreviewFatura ===  "XLSX" || controladorRelatorioPreviewFatura ===  "CSV"){
                const dados = {
                    codigo_cliente: decryptJWT('codigoCliente'),
                    codigo_usuario: decryptJWT('codigo'),
                    descricao: 'Relatório de preview de faturas',
                    tipo_arquivo: controladorRelatorioPreviewFatura,
                    tipo_relatorio: 8,
                    cdFatura: props.cdFatura
                }
                api.post('/m1/cadastrar/relatorios',dados)
                props.setMensagemInformacaoTitulo("O arquivo está sendo gerado")
                props.setMensagemInformacaoCorpo("Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível")
                props.handleMensagemInformacao(true)
                despacho(revelarRelatorioPreviewFatura(false))
            }else{
                despacho(revelarRelatorioPreviewFatura(false))
            }
        }
        buscarDadosRelatorioPreview()
    }, [controladorRelatorioPreviewFatura])

    function inserirDataPreview(data){
        for(let i=0;i<totalItensPreview;i++){
            if (contadorPreview === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVarPreview[k] = data[j]
                    k++
                }
            }
            if(i == (totalItensPreview-1)){
                setItensPreview(JSON.parse(JSON.stringify(itensVarPreview)))
            }
        }
        setSpinAtivo(false)
    }

    const colunasPreview = [
        {
            dataField: 'cd_fat_fat_preview',
            text: 'Cód. preview',
            hidden: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'n0_msisdn',
            text: 'Linha',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_iccid',
            text: 'ICCID',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_plano',
            text: 'Plano',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'n2_valor_plano',
            text: 'Valor do plano',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-simcard"> {(!valor || isNaN(valor)) ? 'R$ 0.00' : <><p className="valor-rs-detalhamento-historico">R$</p> <p className="fonte-cor-1 valor-detalhamento-historico"> {formatarMilhar(valor)}</p></>} </p>;
            }
        },
        {
            dataField: 'dt_compra',
            text: 'Data de compra',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {                         
                return formatarData(cell,true);
            },
        },
        {
            dataField: 'n0_dias',
            text: 'Dias na fatura',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'n2_valor',
            text: 'Valor previsto',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-simcard"> {(!valor || isNaN(valor)) ? 'R$ 0.00' : <><p className="valor-rs-detalhamento-historico">R$</p> <p className="fonte-cor-1 valor-detalhamento-historico"> {formatarMilhar(valor)}</p></>} </p>;
            }
        },
    ]

    const opcoesPaginacaoPreview = {
        page: paginaAtualPreview,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtualPreview(page)}
    }

    async function selecionarFormato(formato, tipo){
        props.setCdFatura(props.cdFatura)
            despacho(revelarRelatorioPreviewFatura(formato))
        
        const exportar = document.getElementById('botao-formatos')
        exportar.click();
    }

    return(
        <>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
            <div className="container-itens-expandido-faturas">
                <div className="detalhamento-fat-faturas-1 fundo-cor-7">
                    <Form.Row className="form-row-sem-margem">
                        <Col>
                        <div className="container-preview-fatura-titulo">
                            <div className="coluna-preview-fatura-titulo">
                                <img className="icone-label-filtro-financeiro" src={elipseVerde()} alt="elipse"/>
                                <Form.Label>
                                    <div className="fonte-cor-1 label-janela-financeiro"> Preview </div>
                                </Form.Label>
                            </div>
                            <div className="coluna-preview-fatura-titulo">
                                <Dropdown alignRight variant="success">
                                    <Accordion className="coluna-preview-fatura-titulo" defaultActiveKey="0">
                                        <Accordion.Toggle id="botao-formatos"className="bt-dropdown-formato-preview" as={Button} variant="link" eventKey="1"></Accordion.Toggle>
                                        <Accordion.Collapse className="menu-dropdown-formatos-preview" eventKey="1">
                                        <>
                                                <div className="triangulo-dropdown-configuracao-cabecalho-sistema triangulo-dropdown-template-detalhamento"></div>
                                                <div className="drop-formato-preview-opcao drop-formato-preview-hover fonte-cor-1" onClick={() => selecionarFormato("CSV")}> CSV </div>
                                                    <Dropdown.Divider className="template-border"/>
                                                <div className="drop-formato-preview-opcao drop-formato-preview-hover fonte-cor-1" onClick={() => selecionarFormato("XLS")}> XLS </div>
                                                    <Dropdown.Divider className="template-border"/>
                                                <div className="drop-formato-preview-opcao drop-formato-preview-hover fonte-cor-1" onClick={() => selecionarFormato("XLSX")}> XLSX </div>
                                            </>
                                        </Accordion.Collapse>
                                    </Accordion>
                                </Dropdown>
                            </div>
                        </div>
                        </Col>
                    </Form.Row>
                    <Form.Row className="form-row-sem-margem">
                        <div className="linha-detalhamento-fat-faturas">
                            <PaginationProvider pagination={Paginador(opcoesPaginacaoPreview)} >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <div className="tabela-preview">
                                            <BootstrapTable
                                                classes="tabela-transparente-header-fixo"
                                                hover={true}
                                                condensed={true}
                                                data={itensPreview}
                                                keyField={"cd_fat_fat_preview"}
                                                columns={colunasPreview} 
                                                bootstrap4={true}
                                                bordered={false}
                                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                { ...paginationTableProps }
                                            />
                                            <PaginacaoTabela
                                                preview={true}
                                                setPaginaAtual={setPaginaAtualPreview}
                                                totalPaginas={totalPaginasPreview}
                                                totalItens={totalItensPreview}
                                                valorLimite={valorLimite}
                                                paginaAtual={paginaAtualPreview}
                                                page={paginationProps.page}
                                                onPageChange={paginationProps.onPageChange}
                                            />
                                        </div>
                                    )
                                }
                            </PaginationProvider>
                            <text className="itens-exibidos-tabela-faturas fonte-cor-1">
                                Mostrando 
                                <span className="label-tabela-itens-exibidos">{contadorPreview + 1}</span>
                                    - 
                                <span className="label-tabela-itens-exibidos">{ contadorPreview + valorLimite <= totalItensPreview ? contadorPreview + valorLimite : totalItensPreview }</span>
                                    de 
                                <span className="label-tabela-itens-exibidos">{totalItensPreview}</span>
                            </text>
                        </div>
                    </Form.Row> 
                </div>
            </div>
        </>
    )
}