//IMPORTAÇÕES
/*eslint-disable*/
import React from "react"; /*eslint-disable*/
import { elipseVerde } from "../../../utils/alternanciaIcones";
import { Form, Col } from "react-bootstrap"; /*eslint-disable*/
//COMPONENTES
import Preview from "./detalhamento/preview";
// import Alteracao from './detalhamento/alteracao'

export default function Detalhamento(props) {
  return (
    <>
      <Form.Row className="form-row-sem-margem">
        <Col>
          <img
            className="icone-label-detalhamento-solicitacao"
            src={elipseVerde()}
            alt="elipse"
          />
          <Form.Label>
            <div className="fonte-cor-1 label-janela-financeiro">
              Detalhamento
            </div>
          </Form.Label>
        </Col>
      </Form.Row>
      <Preview
        cdSolicitacao={props.cdSolicitacao}
        dataFim={props.dataFim}
        dataInicio={props.dataInicio}
        dsStatus={props.dsStatus}
        motivoEdicao={props.motivoEdicao}
        protocolo={props.protocolo}
        usuario={props.usuario}
        cdUsuario={props.cdUsuario}
      />
    </>
  );
}
