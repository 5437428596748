import React, { useEffect, useState } from "react";
import Modal from "../../Mantine/Modal";
import MultiSelect from "../../Mantine/MultiSelect";
import DatePicker from "../../Mantine/DatePicker";
import Select from "../../Mantine/Select";
import Input from "../../Mantine/Input";
import { useGlobal } from "../../../stores/useGlobal";
import * as S from "./styles";
import Grid from "../../Mantine/Grid";
import { SimcardsVirgensService } from "../../../services/http/simcardVirgens.service";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { filtrosArrayBarra } from "../../../redux/actions";
import dayjs from "dayjs";

const REACT_QUERY_OPTIONS = {
  keepPreviousData: true,
  refetchOnWindowFocus: false,
};

const WIDTH_COLUMNS = [3, 3, 3, 3];

const DEFAULT_FILTERS_VALUES = {
  carriers: [],
  status: [],
  preActivationDeadLine: null,
  plans: [],
  preProvisioning: {
    start: null,
    end: null,
  },
  acquisitionDate: {
    start: null,
    end: null,
  },
  provisioning: {
    start: null,
    end: null,
  },
  iccidNumber: {
    start: "",
    end: "",
  },
};

const formatArrayToString = (array) => {
  return array.join(",");
};

const formatStringToNumberArray = (string) => {
  return string.split(",").map(Number);
};

const formatDateToFilter = (date) => {
  return dayjs(date).format("DD-MM-YYYY");
};

const formatDateToDatePicker = (date) => {
  return dayjs(date, "YYYY-MM-DD").toDate();
};

const formatDateToGetFilter = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export default function FiltroSimcardsVirgens({ opened, close }) {
  const { filtros, setFiltros, setRevelarBarraFiltro } = useGlobal();
  const [filterOptions, serFilterOptions] = useState({
    carriers: [],
    status: [],
    preActivationDeadLine: [],
    plans: [],
  });
  const [localFilters, setLocalFilters] = useState({
    ...DEFAULT_FILTERS_VALUES,
  });

  let arraySimcardFilters = [];

  const despacho = useDispatch();

  useEffect(() => {
    if (!filtros.vazio) {
      const auxFilter = {
        ...DEFAULT_FILTERS_VALUES,
      };

      if (filtros?.CdOperadora) {
        auxFilter.carriers = formatStringToNumberArray(filtros.CdOperadora);
      }

      if (filtros?.CdPlano) {
        auxFilter.plans = formatStringToNumberArray(filtros.CdPlano);
      }

      if (filtros?.CdStatus) {
        auxFilter.status = formatStringToNumberArray(filtros.CdStatus);
      }

      if (filtros?.PrazoPreAtivacao) {
        auxFilter.preActivationDeadLine = filtros.PrazoPreAtivacao;
      }

      if (filtros?.PreProvisionamentoInicio && filtros?.PreProvisionamentoFim) {
        auxFilter.preProvisioning = {
          start: formatDateToDatePicker(filtros?.PreProvisionamentoInicio),
          end: formatDateToDatePicker(filtros?.PreProvisionamentoFim),
        };
      }

      if (filtros?.DataAquisicaoInicio && filtros?.DataAquisicaoFim) {
        auxFilter.acquisitionDate = {
          start: formatDateToDatePicker(filtros.DataAquisicaoInicio),
          end: formatDateToDatePicker(filtros.DataAquisicaoFim),
        };
      }

      if (
        filtros?.DataDeProvisionamentoInicio &&
        filtros?.DataDeProvisionamentoFim
      ) {
        auxFilter.provisioning = {
          start: formatDateToDatePicker(filtros.DataDeProvisionamentoInicio),
          end: formatDateToDatePicker(filtros.DataDeProvisionamentoFim),
        };
      }

      if (filtros?.IccidInicial && filtros?.IccidFinal) {
        auxFilter.iccidNumber = {
          start: filtros.IccidInicial,
          end: filtros.IccidFinal,
        };
      }

      setLocalFilters(auxFilter);
    }
  }, [filtros]);

  const {
    getCarriersFilter,
    getStatusFilter,
    getStatusPlan,
    getPreActivationDeadLineFilter,
  } = SimcardsVirgensService;

  const getFilters = async () => {
    const [carriers, status, PreActivation, plans] = await Promise.all([
      getCarriersFilter(),
      getStatusFilter(),
      getPreActivationDeadLineFilter(),
      getStatusPlan(),
    ]);

    serFilterOptions((prev) => ({
      ...prev,
      carriers: carriers?.map((item) => ({
        value: item.CdOperadora,
        label: item.Operadora,
      })),
      status: status?.map((item) => ({
        value: item.CdStatus,
        label: item.Status,
      })),
      preActivationDeadLine: PreActivation?.map((item) => ({
        value: String(item.PrazoPreAtivacao),
        label: `${String(item.PrazoPreAtivacao)} dias`,
      })),
      plans: plans?.map((item) => ({
        value: item.CdPlano,
        label: item.Plano,
      })),
    }));
  };

  useQuery(["whitechip/filters"], async () => await getFilters(), {
    ...REACT_QUERY_OPTIONS,
  });

  const handleLocalFiltroChange = (fieldPath, value) => {
    const [parent, child] = fieldPath.split(".");

    setLocalFilters((prev) => {
      if (!child) {
        return {
          ...prev,
          [parent]: value,
        };
      }

      return {
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value,
        },
      };
    });
  };

  const handleCleanFilters = () => {
    setLocalFilters({ ...DEFAULT_FILTERS_VALUES });
    setFiltros({ vazio: "vazio" });
    despacho(filtrosArrayBarra([]));
    setRevelarBarraFiltro(false);
  };

  const formattedFilter = () => {
    const addFilters = (localFilterKey, filterOptionKey, prefix = "") => {
      const filteredItems = filterOptions[filterOptionKey].filter((item) =>
        localFilters[localFilterKey].includes(item.value)
      );

      const formattedItems = filteredItems.map(
        (item) => `${prefix}${item.label?.trim()} _${item.value}`
      );

      arraySimcardFilters.push(...formattedItems);
    };

    const addDateRangeFilter = (filterKey, label) => {
      const { start, end } = localFilters[filterKey] || {};
      if (start && end) {
        arraySimcardFilters.push(
          `${label}: ${formatDateToFilter(start)} e ${formatDateToFilter(end)}`
        );
      }
    };

    if (localFilters.carriers.length > 0)
      addFilters("carriers", "carriers", "carrierWhiteChip: ");
    if (localFilters.status.length > 0)
      addFilters("status", "status", "statusWhiteChip: ");
    if (localFilters.plans.length > 0)
      addFilters("plans", "plans", "planWhiteChip: ");

    if (localFilters.preActivationDeadLine) {
      arraySimcardFilters.push(
        `preActivationWhiteChip: ${localFilters.preActivationDeadLine} Dias`
      );
    }

    addDateRangeFilter("preProvisioning", "preProvisioningWhiteChip");
    addDateRangeFilter("acquisitionDate", "acquisitionDateWhiteChip");
    addDateRangeFilter("provisioning", "provisioningWhiteChip");

    if (localFilters.iccidNumber?.start && localFilters.iccidNumber?.end) {
      arraySimcardFilters.push(
        `iccidWhiteChip: ${localFilters.iccidNumber.start} e ${localFilters.iccidNumber.end}`
      );
    }

    despacho(filtrosArrayBarra(arraySimcardFilters));
  };

  const formatarValor = (value, formatter) => {
    return value && value.length ? formatter(value) : undefined;
  };

  const formatarData = (value) => {
    return value ? formatDateToGetFilter(value) : undefined;
  };

  const handleFilter = () => {
    const filteredData = {
      CdOperadora: formatarValor(localFilters.carriers, formatArrayToString),
      CdPlano: formatarValor(localFilters.plans, formatArrayToString),
      CdStatus: formatarValor(localFilters.status, formatArrayToString),
      DataAquisicaoInicio: formatarData(localFilters.acquisitionDate.start),
      DataAquisicaoFim: formatarData(localFilters.acquisitionDate.end),
      PreProvisionamentoInicio: formatarData(
        localFilters.preProvisioning.start
      ),
      PreProvisionamentoFim: formatarData(localFilters.preProvisioning.end),
      DataDeProvisionamentoInicio: formatarData(
        localFilters.provisioning.start
      ),
      DataDeProvisionamentoFim: formatarData(localFilters.provisioning.end),
      PrazoPreAtivacao: localFilters.preActivationDeadLine || undefined,
      IccidInicial: localFilters.iccidNumber.start || undefined,
      IccidFinal: localFilters.iccidNumber.end || undefined,
    };

    const finalFiltros = Object.fromEntries(
      Object.entries(filteredData).filter(([, value]) => value !== undefined)
    );

    setFiltros(finalFiltros);
    setRevelarBarraFiltro(true);

    formattedFilter();
    close();
  };

  return (
    <Modal
      width="1100px"
      type="filtro"
      titulo="Filtros"
      opened={opened}
      close={close}
      onConfirm={handleFilter}
      handleCleanFilters={handleCleanFilters}
    >
      <S.Container>
        <S.Card>
          <Grid colSize={WIDTH_COLUMNS}>
            <MultiSelect
              label="Operadora"
              options={filterOptions.carriers}
              value={localFilters.carriers}
              onChange={(value) => handleLocalFiltroChange("carriers", value)}
            />
            <MultiSelect
              label="Status"
              options={filterOptions.status}
              value={localFilters.status}
              onChange={(value) => handleLocalFiltroChange("status", value)}
            />

            <MultiSelect
              label="Plano"
              options={filterOptions.plans}
              value={localFilters.plans}
              onChange={(value) => handleLocalFiltroChange("plans", value)}
            />
            <Select
              label="Prazo pré-ativação"
              options={filterOptions.preActivationDeadLine}
              placeholder="Selecione"
              value={localFilters.preActivationDeadLine}
              onChange={(value) =>
                handleLocalFiltroChange("preActivationDeadLine", value)
              }
            />
          </Grid>
        </S.Card>
        <S.Card>
          <Grid colSize={WIDTH_COLUMNS}>
            <S.FieldsWrapper>
              <DatePicker
                label="Pré-provisionamento"
                value={localFilters.preProvisioning.start}
                prefixo="De:"
                onChange={(date) =>
                  handleLocalFiltroChange("preProvisioning.start", date)
                }
              />
              <DatePicker
                prefixo="Até:"
                minDate={localFilters.preProvisioning.start}
                value={localFilters.preProvisioning.end}
                onChange={(date) =>
                  handleLocalFiltroChange("preProvisioning.end", date)
                }
              />
            </S.FieldsWrapper>
            <S.FieldsWrapper>
              <DatePicker
                label="Data da aquisição"
                prefixo="De:"
                value={localFilters.acquisitionDate.start}
                onChange={(date) =>
                  handleLocalFiltroChange("acquisitionDate.start", date)
                }
              />
              <DatePicker
                prefixo="Até:"
                minDate={localFilters.acquisitionDate.start}
                value={localFilters.acquisitionDate.end}
                onChange={(date) =>
                  handleLocalFiltroChange("acquisitionDate.end", date)
                }
              />
            </S.FieldsWrapper>
            <S.FieldsWrapper>
              <DatePicker
                label="Data de provisionamento"
                prefixo="De:"
                value={localFilters.provisioning.start}
                onChange={(date) =>
                  handleLocalFiltroChange("provisioning.start", date)
                }
              />
              <DatePicker
                prefixo="Até:"
                minDate={localFilters.provisioning.start}
                value={localFilters.provisioning.end}
                onChange={(date) =>
                  handleLocalFiltroChange("provisioning.end", date)
                }
              />
            </S.FieldsWrapper>
            <S.FieldsWrapper>
              <Input
                label="ICCID por intervalo"
                placeholder="Número inicial"
                value={localFilters.iccidNumber.start}
                onChange={(e) =>
                  handleLocalFiltroChange("iccidNumber.start", e.target.value)
                }
              />
              <Input
                placeholder="Número final"
                value={localFilters.iccidNumber.end}
                onChange={(e) =>
                  handleLocalFiltroChange("iccidNumber.end", e.target.value)
                }
              />
            </S.FieldsWrapper>
          </Grid>
        </S.Card>
      </S.Container>
    </Modal>
  );
}
