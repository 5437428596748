//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../conexoes/api';/*eslint-disable*/
import { Form, Button, Image } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import {elipseVerde, setaDropdown} from '../utils/alternanciaIcones'
import { validadorPermissaoElemento } from '../utils/verificadorPermissaoElemento'

// COLETA CLICKSTREAM
import ColetaClickstream from './clickstreamAnalise/clickstreamColeta.js'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector } from 'react-redux';
import { paginaDashboardFaturas } from "../redux/actions/index";

export default function DropAcoes(props) {
    //HOOKS VARIÁVEIS
    const [mainAcoes, setMainAcoes] = useState([])
    const [subAcoes, setSubAcoes] = useState([])
    //HOOKS MODAL
    const [clickFora, setClickFora] = useState(false);
    const [permissaoDashboardSms, setPermissaoDashboardSms] = useState(false);
    const [permissaoDashboardDispositivos, setPermissaoDashboardDispositivos] = useState(false);
    const [permissaoDashboardFaturas, setPermissaoDashboardFaturas] = useState(false);
    const modal = useRef();
    //HOOKS REDUX
    const despacho = useDispatch();
    const manejador = useSelector(state => state.manejadorState.manejador)
    const desabilitarAcoes = useSelector(state => state.manejadorState.desabilitarAcoes)
    const desabilitarAcoesCliente = useSelector(state => state.virtueyesState.desabilitarAcoesCliente)
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            props.toggleRevelarDropAcoes(false)
        }
    };

    const handleClickDentro = () => setClickFora(false);
    
    useEffect(() => {
        async function buscarAcoes(){
            // setMainAcoes(mainMenu)
            // setSubAcoes(subMenu)
            setPermissaoDashboardSms(await validadorPermissaoElemento(permissaoElemento,"veye/dashboard/sms"))
            setPermissaoDashboardDispositivos(await validadorPermissaoElemento(permissaoElemento,"veye/dashboard/dispositivos"))
            setPermissaoDashboardFaturas(await validadorPermissaoElemento(permissaoElemento,"veye/dashboard/faturas"))
        }
        buscarAcoes()

        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function dropAcoes(event, elementoId, imagemId){
        let elemento = document.getElementById(elementoId);
        let imagem = document.getElementById(imagemId);

        let imagemClasses = document.querySelectorAll('.drop-acoes-menu-icone-ativado');
        let classes =  document.querySelectorAll('.dropdown-ativado');

        if(elemento.classList.contains("dropdown-desativado")){
            if(classes.length > 0){
                classes.forEach(item => item.classList.remove("dropdown-desativado"));
                classes.forEach(item => item.classList.add("dropdown-ativado"));

                imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-desativado"));
                imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-ativado"));
            }
            elemento.classList.add("dropdown-ativado")
            elemento.classList.remove("dropdown-desativado")

            imagem.classList.add("drop-acoes-menu-icone-ativado")
            imagem.classList.remove("drop-acoes-menu-icone-desativado")
        }
        // se o filtro estiver ativado e for clicado em cima do mesmo filtro ele fecha
        else if(elemento.classList.contains("dropdown-ativado")){
            if(classes.length > 0){
                classes.forEach(item => item.classList.add("dropdown-desativado"));
                classes.forEach(item => item.classList.remove("dropdown-ativado"));

                imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-desativado"));
                imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-ativado"));
            }
            elemento.classList.add("dropdown-desativado")
            elemento.classList.remove("dropdown-ativado")

            imagem.classList.add("drop-acoes-menu-icone-desativado")
            imagem.classList.remove("drop-acoes-menu-icone-ativado")
        }

        if(elemento.classList.contains("dropdown-ativado")){
            if(classes.length > 0){
                classes.forEach(item => item.classList.add("dropdown-desativado"));
                classes.forEach(item => item.classList.remove("dropdown-ativado"));

                imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-desativado"));
                imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-ativado"));
            }
            classes.forEach(item => item.classList.add("dropdown-desativado"));
            classes.forEach(item => item.classList.remove("dropdown-ativado"));
        }
    }

    async function controleFaturas(pagina){
        if(manejador != "dashboardFaturas"){
            const abrir = document.getElementById("abrir-faturas")
            abrir.click();
            despacho(paginaDashboardFaturas(pagina))
        }else{
            despacho(paginaDashboardFaturas(pagina))
        }
    }

    return(
        <Form ref={modal} className="drop-acoes-container">
            <NavLink id="abrir-faturas" className='d-none' to={"/veye/faturas/dashboard"}>Faturas</NavLink>
            <Button className="botao-filtros-acoes-cabecalho-sistema fundo-cor-7" data-place="bottom" onClick={() => {props.setRevelarDropAcoes(false) }}>
                <Form.Label className="label-filtros-acoes-cabecalho-sistema fonte-cor-1">Painel</Form.Label>
                <Image className="icone-acoes-cabecalho-sistema" src={setaDropdown()} />
            </Button>
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <ul className="drop-acoes-lista-menu">
                    { permissaoDashboardDispositivos &&
                        <li>
                            <div className="drop-acoes-menu item-drop-acoes-hover">
                                <Link onClick={()=>ColetaClickstream(1194) /*dropPainelDispositivos*/} className='label-item-menu-dropdown-cabecalho-sistema-dasboard-sms fonte-cor-1' to={"/veye/dashboard/dispositivos"}>Dispositivos</Link>
                                <img id={"imagem-menu-1"} className="drop-acoes-menu-icone-desativado nao-selecionavel"/>
                            </div>
                            <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                        </li>
                    }
                    { permissaoDashboardSms &&
                        <li>
                            <div className="drop-acoes-menu item-drop-acoes-hover">
                                <NavLink onClick={()=>ColetaClickstream(1195) /*dropPainelSMS*/} className='label-item-menu-dropdown-cabecalho-sistema-dasboard-sms fonte-cor-1' to={"/veye/dashboard/sms"}>SMS</NavLink>
                                <img id={"imagem-menu-2"} className="drop-acoes-menu-icone-desativado nao-selecionavel"/>
                            </div>
                            <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                        </li>
                    }
                    { permissaoDashboardFaturas &&
                        <li>
                            <div className="drop-acoes-menu item-drop-acoes-hover" onClick={e => dropAcoes(e, "menu-3", "imagem-menu-3")}>
                                <span onClick={()=>ColetaClickstream(1196) /*dropPainelFaturas*/} className="drop-acoes-menu-label fonte-cor-1">Faturas</span>
                                <img id={"imagem-menu-3"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} alt="drop" />
                            </div>
                            <ul id={"menu-3"}  className="drop-acoes-lista-submenu dropdown-desativado">
                                {/* <li key={"subMenu-1"}>
                                    <div className="drop-acoes-submenu item-drop-acoes-hover" onClick={() => controleFaturas(1)}>
                                        <span className="drop-acoes-submenu-label fonte-cor-1">Contestações</span>
                                    </div>
                                    <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                                </li> */}
                                <li key={"subMenu-2"}>
                                    <div className="drop-acoes-submenu item-drop-acoes-hover" onClick={() => {controleFaturas(2); ColetaClickstream(1197) /*dropPainelFaturasSIMcards*/}}>
                                        <span className="drop-acoes-submenu-label fonte-cor-1">SIM cards</span>
                                    </div>
                                    <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                                </li>
                                <li key={"subMenu-3"}>
                                    <div className="drop-acoes-submenu item-drop-acoes-hover" onClick={() => {controleFaturas(0); ColetaClickstream(1198) /*dropPainelFaturasFaturas*/}}>
                                        <span className="drop-acoes-submenu-label fonte-cor-1">Faturas</span>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    }
                </ul>
            </div>
        </Form>
    )
}