import React from "react";
import { useSelector } from "react-redux";
import { getCoresGraficoMigracaoPlano } from "../cores";

import CardGraficoGenerico from "./CardGenerico";
import { getCicloFormatado } from "./CardGenerico/funcoes";

const nomeOpcao = "PlanoReal";

export default function GraficoPlanoReal({ revelarOpcoesGrafico }) {
  const arrDash = useSelector(
    (state) => state.migracaoPlanoState.dashboard[nomeOpcao]
  );
  const ciclo = getCicloFormatado(arrDash);

  const isUltimos6Ciclos = ciclo === "Últimos 6 ciclos";

  const cores = getCoresGraficoMigracaoPlano(nomeOpcao, isUltimos6Ciclos);

  const configDash = [
    {
      titulo: "Plano original",
      key: "PlanoReal",
      cor: cores.azul,
      numBarra: 1,
    },
    {
      titulo: "Plano migrado",
      key: "PlanoMigracao",
      cor: cores.roxo,
      numBarra: 2,
    },
    {
      titulo: "Histórico insuficiente",
      key: "HistoricoInsuficiente",
      cor: cores.amarelo,
      numBarra: 3,
    },
  ];

  return (
    <CardGraficoGenerico
      titulo="Status dos planos"
      nomeOpcao={nomeOpcao}
      id={1}
      key={1}
      revelarOpcoesGrafico={revelarOpcoesGrafico}
      configDash={configDash}
    />
  );
}
