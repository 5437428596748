/** ARQUIVO PARA GERAR UMA BARRA DE BREADCRUMBS COM  ARRAY DE JSONS ENVIADO POR PROPS
 * OS JSONS AGUARDADOS DEVEM SER ESTRUTURADOS COMO {'titulo': ?, 'valor': ?}
 * 
 * CRIADO POR: MATEUS FOLLETTO
 * EM: 2021-04-09
 * 
 * ULTIMA ALTERAÇÃO: 2021-04-09
 * POR: MATEUS FOLLETTO
 * MOTIVO: Criação do arquivo
 */

import React, {useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip } from "react-bootstrap";
import '../../../css/componentesModais/barras/barraGenerica.css'

/*eslint-disable*/
export default function BarraGenerica(props){
    const [itens, setItens] = useState([])

    useEffect(() => {
        /** AO RENDERIZAR O COMPONENTE OS DADOS SÃO MAPEADOS PARA GERAR OS BREADCRUMBS
         * NECESSÁRIOS AUTOMATICAMENTE
         */
        let objeto = props.dados.map((item,i) => {
            return (
                <>
                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{item.valor}</Tooltip>}>
                        <div className="barra-de-dados-generica-container-dado">
                            <span className="barra-de-dados-generica-titulo fonte-cor-1">{item.titulo}: </span>
                            <span className="barra-de-dados-generica-valor fonte-cor-1">{item.valor}</span>
                        </div>
                    </OverlayTrigger>
                    {props.dados[i+1] != undefined && <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>}
                </>
            )
        })
        setItens(objeto)
    },[])

    return(
        <div className="barra-de-dados-generica fundo-cor-5">
            {itens}
        </div>
    )
}