//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../../conexoes/api';
import { Button, Form, Col} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
//ICONES
import {elipseVerde, fecharJanela, lixeiraVermelho} from '../../../../utils/alternanciaIcones';

//REDUX
import { useDispatch } from 'react-redux';
import { revelarSolicitacaRemoverBloqueioVirtueyes } from "../../../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'
import decryptJWT from '../../../../utils/decryptJWT'

let atualizarParent = false;

export default function AtivarLinhas(props) {
    /*eslint-disable*/
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(0);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [itens, setItens] = useState([])
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemErroValidacao, setRevelarMensagemErroValidacao] = useState(false)
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const colunas = [{
        dataField: 'cd_pav_cliente',
        text: 'Cód. cliente',
        hidden: true,
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'n0_codigo_cliente',
        text: 'Cód. cliente',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: 'ds_pav_cliente',
        text: 'Razão social',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
    },
    {
        dataField: '',
        text: '',
        headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleRemoverLinha(cell)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    },]
    
    function handleLinhasSelecionadas(tamanho){
        setQuantidadeLinhasSelecionados(tamanho)
    }

    function handleUltimaLinha(tamanho){
        if(tamanho == 0){
            handleMensagemAtencao(false)
        }
    }
    
    function handleRemoverLinha(cd_pav_cliente){
        itens.forEach(function(e, index){
            if(cd_pav_cliente == e.cd_pav_cliente){
                itens.splice(index, 1);
            }
        })
        handleLinhasSelecionadas(itens.length)
        handleUltimaLinha(itens.length)
    }

    async function handleSalvar(){
        let clientesArray = []
        setSpinAtivo(true)
        Promise.all(
            itens.map(async(item_cliente,i)=>{
                clientesArray.push(item_cliente.cd_pav_cliente)
            })
        ).then(async ()=>{
            //FL_ACAO 0 E DE DESBLOQUEIO
            await api.post('/m1/cadastrar/cliente_bloqueio', {
                'cd_pav_cliente' : clientesArray,
                'cd_pav_usuario' :  decryptJWT('codigo'),
                "fl_acao":'0'
            })
            .then(function (response) {
                setSpinAtivo(false)
                atualizarParent = true;
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            });
        })
    }

    async function handleCancelar() {
        ColetaClickstream(1379) // dropAcoesSolicitacaRemoverBloqueioVirtueyesCancelar
        setSpinAtivo(false)
        if(atualizarParent === true){
            props.setRenderizar(!props.renderizar)
        } 
        despacho(revelarSolicitacaRemoverBloqueioVirtueyes(false))
    }
     

    useEffect(() => {
        setSpinAtivo(true)
        async function preencherTabela (){
            setItens(JSON.parse(JSON.stringify(props.dadosSelecionados)))
            setSpinAtivo(false)
            handleLinhasSelecionadas(props.dadosSelecionados.length)
         }
         preencherTabela()
        
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(confirmacao ===true){
            handleCancelar()
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    function handleValidacao(){
        if(itens.length > 0){
            ColetaClickstream(1380) // dropAcoesSolicitacaRemoverBloqueioVirtueyesConfirmar
            handleSalvar()
        }
        else{
           handleMensagemErroValidacao(false)
        }
    }

    function handleMensagemErroValidacao(confirmacao){
        if(revelarMensagemErroValidacao === false){
            setRevelarMensagemErroValidacao(true)
        }else{
            setRevelarMensagemErroValidacao(false)
        }
        if(confirmacao){
            if(itens.length < 0){

            }
        }
    }
    
    return(
        <Form ref={modal} /* onSubmit={handleSubmit(() => handleSalvar())} */>
            <div className="modal-bloquear-cliente fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Remover bloqueio</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="container-tabela-modal-bloqueio">
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_pav_cliente' 
                                data={itens} 
                                columns={colunas} 
                                bootstrap4={true}
                            />
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} cliente(s) selecionado(s)</span>
                    </Col>
                </Form.Row>
                <Form.Row className="espaco-ativar-linha">
                    <Col>
                        <Button className="botao-confirmar" variant="success" size="sm" onClick={() => handleValidacao()}>
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErroValidacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErroValidacao}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Nenhum cliente selecionado"}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
